import { useState } from 'react';
import { Operator } from '../../../@types';
import * as S from './styles';
import PageTitle from '../../../components/PageTitle';
import Loader from '../../../components/Loader';
import UserCard from '../../../components/UserCard';
import { CgUserList } from 'react-icons/cg';
import { useAuth } from '../../../hooks/useAuth';
import * as TableStyle from '../../../components/Table/TableStyles';
import { cpfMask } from '../../../utils/masks';
import { EmptyContent } from '../../../components/EmptyContent';
import { Pagination } from '../../../components/Pagination';
import { Filter } from '../../../components/Filter';
import {
	getFilteredOperators,
	getOperators,
	OperatorsListReturn,
} from '../../../services/queries/Operators';
import { useQuery, useQueryClient } from 'react-query';
import { showErrorMessage } from '../../../utils/ErrorHandler';
import { useHistoryNonMatchCallback } from '../../../hooks/useHistoryNonMatchCallback';
import { useOperatorsListStore } from '../../../stores/useOperatorsListStore';
import { useSortColumnHook } from '../../../hooks/useSortColumnHook';
import { SortColumnButton } from '../../../components/SortColumnButton';

export default function Operators() {
	const { user } = useAuth();
	const [showingTable, setShowingTable] = useState<string>();
	const queryClient = useQueryClient();

	const [operatorsCurrentPage, setOperatorsCurrentPage] = useState(1);
	const { currentSortColumn, toggleSort } = useSortColumnHook();
	const [filtersParams, setFiltersParams, resetFilters] = useOperatorsListStore(
		(state) => [state.filtersParams, state.setFiltersParams, state.resetFilters]
	);

	useHistoryNonMatchCallback('operators', resetFilters);

	const getOperatorsListQuery = useQuery<OperatorsListReturn, Error>(
		['operatorsList', operatorsCurrentPage, currentSortColumn],
		() => {
			return getOperators(operatorsCurrentPage, currentSortColumn);
		},
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível buscar os operadores. '
				);
			},
			refetchOnWindowFocus: false,
			keepPreviousData: true,
		}
	);

	const filteredOperators = useQuery<OperatorsListReturn, Error>(
		[
			'filteredOperators',
			filtersParams,
			operatorsCurrentPage,
			currentSortColumn,
		],
		() => {
			return getFilteredOperators(
				filtersParams,
				operatorsCurrentPage,
				currentSortColumn
			);
		},
		{
			onError: (error) => {
				showErrorMessage(
					error as Error,
					'Não foi possível buscar os operadores. '
				);
			},
			enabled: !!filtersParams.find((f) => !!f.value) && !!operatorsCurrentPage,
		}
	);

	function getTableTotalRecords() {
		if (filtersParams.find((f) => !!f.value)) {
			return filteredOperators.data?.totalUsersOperator;
		}
		return getOperatorsListQuery.data?.totalUsersOperator;
	}

	function refreshPage() {
		setOperatorsCurrentPage(1);
		queryClient.resetQueries('operatorsList');
		queryClient.resetQueries('filteredOperators');
	}

	const operatorsPageContent = () => {
		if (filtersParams.find((f) => !!f.value)) {
			return filteredOperators.data?.users;
		}
		return getOperatorsListQuery!.data?.users;
	};

	function generateRow(operator: Operator) {
		return (
			<TableStyle.TableRow key={operator.id}>
				<TableStyle.TableData>
					<UserCard
						name={operator.name}
						bottomInfo={operator.office}
						avatar_url={operator.avatar_url}
					/>
				</TableStyle.TableData>
				<TableStyle.TableData>
					{operator.cpf ? cpfMask(operator.cpf) : ''}
				</TableStyle.TableData>
				<TableStyle.TableData>{operator.office}</TableStyle.TableData>
				<TableStyle.TableData style={{ textAlign: 'center' }}>
					{user.access_level === 'admin' &&
						operator.access_level === 'operator' && (
							<TableStyle.OptionLink
								data-rh='Detalhes do operador'
								to={`/home/operators/manage/${operator.id}`}
							>
								<CgUserList />
							</TableStyle.OptionLink>
						)}
				</TableStyle.TableData>
			</TableStyle.TableRow>
		);
	}

	function getTable(tableName: string, operators?: Operator[]) {
		if (!operators?.length) {
			return (
				<>
					<TableStyle.TitleWrapper>
						<PageTitle title='Operadores do sistema' />
						<Filter
							filterParams={filtersParams}
							onFiltersChanged={(updatedFilters) => {
								setFiltersParams(updatedFilters);
							}}
						/>
						<TableStyle.ReloadIcon type='reload' onClick={refreshPage} />
					</TableStyle.TitleWrapper>

					<EmptyContent text='Não há ítens a serem exibidos.' />
				</>
			);
		}

		return (
			<>
				<TableStyle.TableHeaderContainer>
					<TableStyle.TitleWrapper>
						<PageTitle
							title='Operadores do sistema'
							totalRecords={getTableTotalRecords() ?? 0}
						/>
						<Filter
							filterParams={filtersParams}
							onFiltersChanged={(updatedFilters) => {
								setFiltersParams(updatedFilters);
							}}
						/>
						{showingTable === tableName ? (
							<TableStyle.SeeLess
								onClick={() =>
									showingTable === tableName
										? setShowingTable('')
										: setShowingTable(tableName)
								}
							>
								Ver menos
							</TableStyle.SeeLess>
						) : (
							<TableStyle.SeeMore
								onClick={() =>
									showingTable === tableName
										? setShowingTable('')
										: setShowingTable(tableName)
								}
							>
								Ver mais
							</TableStyle.SeeMore>
						)}
					</TableStyle.TitleWrapper>

					{showingTable === tableName ? (
						<Pagination
							onPageChange={(page) => setOperatorsCurrentPage(page)}
							currentPage={operatorsCurrentPage}
							totalCount={
								filtersParams.some((f) => !!f.value)
									? filteredOperators.data?.totalUsersOperator!
									: getOperatorsListQuery!.data?.totalUsersOperator!
							}
						/>
					) : (
						<></>
					)}

					<TableStyle.ReloadIcon type='reload' onClick={refreshPage} />
				</TableStyle.TableHeaderContainer>

				<TableStyle.Table>
					<TableStyle.TableHeader>
						<TableStyle.TableRow>
							<TableStyle.TableHeaderCell>
								<SortColumnButton
									order={
										currentSortColumn?.name === 'name'
											? currentSortColumn.order
											: null
									}
									onToggleSort={() => toggleSort('name')}
								/>
								Nome
							</TableStyle.TableHeaderCell>
							<TableStyle.TableHeaderCell>CPF</TableStyle.TableHeaderCell>
							<TableStyle.TableHeaderCell>
								<SortColumnButton
									order={
										currentSortColumn?.name === 'office'
											? currentSortColumn.order
											: null
									}
									onToggleSort={() => toggleSort('office')}
								/>
								CARGO
							</TableStyle.TableHeaderCell>
							<TableStyle.TableHeaderCell>OPÇÕES</TableStyle.TableHeaderCell>
						</TableStyle.TableRow>
					</TableStyle.TableHeader>

					<TableStyle.TableBody>
						{showingTable === tableName
							? operators.map((operator) => generateRow(operator))
							: generateRow(operators[0])}
					</TableStyle.TableBody>
				</TableStyle.Table>
			</>
		);
	}

	if (
		getOperatorsListQuery.isLoading ||
		filteredOperators.isLoading ||
		getOperatorsListQuery.isPreviousData
	) {
		return (
			<S.Container>
				<PageTitle title={'Operadores do sistema'} />
				<Loader />
			</S.Container>
		);
	}
	return (
		<S.Container>
			{getTable('Operadores do sistema', operatorsPageContent())}
			<TableStyle.Button to={`/home/operators/create`}>
				Criar operador
			</TableStyle.Button>
		</S.Container>
	);
}
