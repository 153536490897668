import {
	AddressBody,
	CardBatch,
	CardBatchStats,
	DeliveredExtraInfo,
	DeliveryAddressTypes,
} from '../../@types';
import {
	FilterParams,
	parseFilterParamsToParams,
} from '../../components/Filter';
import {
	SortColumn,
	parseSortColumnToParams,
} from '../../hooks/useSortColumnHook';
import api from '../api';

export type CreateCardBatchCollaboratorBody = {
	collaborators: string[];
	delivery_address_type: DeliveryAddressTypes;
};

export type CardsBatchesListReturn = {
	concludedCardsBatches?: CardBatch[];
	totalConcludedCardsBatches?: number;

	pendingCardsBatches?: CardBatch[];
	totalPendingCardsBatches?: number;
};

interface CardsBatchesPendingListReturn extends CardsBatchesListReturn {
	stats: CardBatchStats;
}

export interface FilteredCardbatchesReturn {
	totalCardBatches: number;
	cardBatches: CardBatch[];
}

export async function createCardsBatchCollaborators(
	body: CreateCardBatchCollaboratorBody,
	companyId?: string
): Promise<void> {
	const { data } = await api.post(
		`api/v1/rh/companies/${companyId}/cardbatch`,
		body
	);

	return data;
}

export type CreateCardBatchUnamedBody = {
	delivery_address_type: DeliveryAddressTypes;
	card_qtde: number;
	custom_address?: AddressBody;
};
export async function createCardsBatchUnamed(
	body: CreateCardBatchUnamedBody,
	companyId: string
): Promise<CardBatch> {
	const { data } = await api.post(
		`api/v1/rh/companies/${companyId}/cardbatch_unamed`,
		body
	);

	return data;
}

export async function getConcludedCardsBatches(
	concludedCardsBatchesCurrentPage: number,
	companyId?: string,
	sortColumn: SortColumn | null = null
): Promise<CardsBatchesListReturn> {
	const { data } = await api.get(
		`api/v1/rh/companies/${companyId}/cardbatch/q?page_other=${concludedCardsBatchesCurrentPage}`,
		{
			params: {
				...(sortColumn && { sort_by_other: sortColumn.name }),
				...(sortColumn && { sort_order_other: sortColumn.order }),
			},
		}
	);

	const concludedCardsBatches = data.othersCardBatchs;
	const totalConcludedCardsBatches = data.totalOthersCardBatchs;

	return {
		concludedCardsBatches,
		totalConcludedCardsBatches,
	};
}

export async function getPendingCardsBatches(
	pendingCardsBatchesCurrentPage: number,
	companyId?: string,
	sortColumn: SortColumn | null = null
): Promise<CardsBatchesPendingListReturn> {
	const { data } = await api.get(
		`api/v1/rh/companies/${companyId}/cardbatch/q?page_pending=${pendingCardsBatchesCurrentPage}`,
		{
			params: {
				...(sortColumn && { sort_by_pending: sortColumn.name }),
				...(sortColumn && { sort_order_pending: sortColumn.order }),
			},
		}
	);

	const pendingCardsBatches = data.pendingCardBatchs;
	const totalPendingCardsBatches = data.totalPendingCardBatchs;

	return {
		stats: data.stats,
		pendingCardsBatches,
		totalPendingCardsBatches,
	};
}

export async function getFilteredCardBatches(
	filterParams: FilterParams[],
	companyId?: string,
	pending?: boolean,
	page?: number,
	sortColumn: SortColumn | null = null
): Promise<FilteredCardbatchesReturn> {
	const { data } = await api.get(
		`api/v1/rh/companies/${companyId}/cardbatch/filter/q?page=${page}`,
		{
			params: {
				...parseFilterParamsToParams(filterParams),
				...parseSortColumnToParams(sortColumn),
				status: pending ? 'processing,pending' : 'done',
			},
		}
	);

	const totalCardBatches = data.totalCardBatches;
	const cardBatches = data.cardBatches;

	return {
		totalCardBatches,
		cardBatches,
	};
}

export async function fetchCardBatch(
	cardBatchId: string,
	companyId?: string
): Promise<CardBatch> {
	const { data } = await api.get<CardBatch>(
		`/api/v1/rh/companies/${companyId}/cardbatch/${cardBatchId}`
	);

	return data;
}

export async function trackItem(
	cardBatchId: string,
	cardBatchItemId: string,
	companyId?: string
) {
	const { data } = await api.get(
		`/api/v1/rh/companies/${companyId}/cardbatch/${cardBatchId}/item/${cardBatchItemId}/track`
	);

	return data.data;
}

export async function trackBatch(cardBatchId: string, companyId?: string) {
	const { data } = await api.get(
		`/api/v1/rh/companies/${companyId}/cardbatch/${cardBatchId}/track`
	);

	return data.data;
}

export async function finishBatch(id: string, companyId: string) {
	return await api.put(
		`/api/v1/rh/companies/${companyId}/cardbatch/${id}/status/done`
	);
}

export async function removeBatch(id: string, companyId: string) {
	return await api.delete(`/api/v1/rh/companies/${companyId}/cardbatch/${id}`);
}

export async function removeItemFromBatch(
	id: string,
	itemId: string,
	companyId: string
) {
	return await api.delete(
		`/api/v1/rh/companies/${companyId}/cardbatch/${id}/item/${itemId}`
	);
}

export async function finishItemFromBatch(
	id: string,
	itemId: string,
	delivered_extra_info: DeliveredExtraInfo,
	companyId: string
) {
	return await api.post(
		`/api/v1/rh/companies/${companyId}/cardbatch/${id}/item/${itemId}`,
		{
			delivered_extra_info,
		}
	);
}
