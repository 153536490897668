import { useEffect, useLayoutEffect } from 'react';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { Card as CardType } from '../../../../../@types/CorporateExpenses/Card';
import { AsynchronousContent } from '../../../../../componentsV2/AsynchronousContent';
import { Carousel } from '../../../../../componentsV2/Carousel';
import { Tabs } from '../../../../../componentsV2/Tab';
import { useAuth } from '../../../../../hooks/useAuth';
import {
	CORPWAY_CARDS_KEY,
	getCards,
} from '../../../../../services/queries/Corpway/Cards';
import {
	CardListStatus,
	useCardSelectStore,
} from '../../../../../stores/corpway/useCardSelectStore';
import { showErrorMessage } from '../../../../../utils/ErrorHandler';
import { Card } from './Card';

export function CardSelect() {
	const cardState = useLocation().state as CardType | undefined;
	const [
		selectedCard,
		activeCardsNumber,
		costCenterFilter,
		selectedTabFilter,
		setCardSelectStoreState,
		resetCardSelectStoreState,
	] = useCardSelectStore((state) => [
		state.selectedCard,
		state.activeCardsNumber,
		state.costCenterFilter,
		state.selectedTabFilter,
		state.setState,
		state.reset,
	]);
	const { currentCompany, user } = useAuth();

	const getCardsQuery = useQuery(
		[CORPWAY_CARDS_KEY, user.id, currentCompany?.id],
		() => getCards(currentCompany!.id, {
			name: "alias",
			order: "ASC"
		}),
		{
			onError: (err) => {
				showErrorMessage(err as Error, 'Não foi possível buscar os cartões. ');
			},
			refetchOnWindowFocus: false,
		}
	);

	const activeCards = getCardsQuery.data?.cards_active
		? getCardsQuery.data?.cards_active.filter(
				(c) =>
					!costCenterFilter?.id ||
					c.cost_center_data?.id === costCenterFilter?.id
		  )
		: [];

	const blockedCards = getCardsQuery.data?.cards_blocked
		? getCardsQuery.data?.cards_blocked.filter(
				(c) =>
					!costCenterFilter?.id ||
					c.cost_center_data?.id === costCenterFilter?.id
		  )
		: [];

	const canceledCards = getCardsQuery.data?.cards_blocked
		? getCardsQuery.data?.cards_canceled.filter(
				(c) =>
					!costCenterFilter?.id ||
					c.cost_center_data?.id === costCenterFilter?.id
		  )
		: [];

	const CARDS_LISTS: Record<CardListStatus, CardType[]> = {
		Ativos: activeCards,
		Bloqueados: blockedCards,
		Cancelados: canceledCards,
	};

	function isCardSelected(id: string) {
		return selectedCard?.id === id;
	}

	function handleSelectCard(c: CardType) {
		setCardSelectStoreState({ selectedCard: c });
	}

	function handleChangeTab(clickedTab: CardListStatus) {
		if (
			selectedCard &&
			CARDS_LISTS[clickedTab].find((c) => selectedCard.id === c.id)
		) {
			setCardSelectStoreState({ selectedTabFilter: clickedTab });
		} else {
			setCardSelectStoreState({
				selectedTabFilter: clickedTab,
				selectedCard: CARDS_LISTS[clickedTab][0] ?? null,
			});
		}
	}

	const isEmptyList =
		(selectedTabFilter === 'Ativos' && !activeCards.length) ||
		(selectedTabFilter === 'Bloqueados' && !blockedCards.length) ||
		(selectedTabFilter === 'Cancelados' && !canceledCards.length);

	// reset on unmount
	useEffect(() => resetCardSelectStoreState, []); // eslint-disable-line

	useEffect(() => {
		if (!(getCardsQuery.status === 'success')) return;

		if (!cardState) {
			// no card state, select the first active card
			setCardSelectStoreState({
				selectedCard: activeCards[0] ?? null,
				activeCardsNumber: activeCards.length ?? 0,
			});
			return;
		}

		// select the card passed through state and also its tab
		if (CARDS_LISTS['Ativos'].find((c) => cardState.id === c.id)) {
			setCardSelectStoreState({ selectedCard: cardState });
		} else if (CARDS_LISTS['Bloqueados'].find((c) => cardState.id === c.id)) {
			setCardSelectStoreState({
				selectedCard: cardState,
				selectedTabFilter: 'Bloqueados',
			});
		} else if (CARDS_LISTS['Cancelados'].find((c) => cardState.id === c.id)) {
			setCardSelectStoreState({
				selectedCard: cardState,
				selectedTabFilter: 'Cancelados',
			});
		}
	}, [cardState, getCardsQuery.status]); // eslint-disable-line react-hooks/exhaustive-deps

	useLayoutEffect(() => {
		if (
			!CARDS_LISTS[selectedTabFilter].find((c) => selectedCard?.id === c.id)
		) {
			setCardSelectStoreState({
				selectedCard: CARDS_LISTS[selectedTabFilter][0] ?? null,
			});
		}
	}, [costCenterFilter]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<AsynchronousContent
			status={getCardsQuery.status}
			emptyContent={
				activeCards.length === 0 &&
				blockedCards.length === 0 &&
				canceledCards.length === 0
			}
		>
			<Tabs
				style={{ paddingLeft: '0rem', paddingRight: '0rem' }}
				onChange={(e: any) => handleChangeTab(e)}
				selectedTab={selectedTabFilter}
				emptyContent={isEmptyList}
				tabs={[
					{
						name: 'Ativos',
						component: (
							<Carousel mode='draggable'>
								{activeCards?.map(
									(card) => (
										<Card
											onSelect={handleSelectCard}
											key={card.id}
											isActive={isCardSelected(card.id)}
											card={card}
										/>
									)
								)}
							</Carousel>
						),
					},
					{
						name: 'Bloqueados',
						component: (
							<Carousel mode='draggable'>
								{blockedCards?.map((card) => (
									<Card
										onSelect={handleSelectCard}
										key={card.id}
										isActive={isCardSelected(card.id)}
										card={card}
									/>
								))}
							</Carousel>
						),
					},
					{
						name: 'Cancelados',
						component: (
							<Carousel mode='draggable'>
								{canceledCards?.map((card) => (
									<Card
										onSelect={handleSelectCard}
										key={card.id}
										isActive={isCardSelected(card.id)}
										card={card}
									/>
								))}
							</Carousel>
						),
					},
				]}
			/>
		</AsynchronousContent>
	);
}
