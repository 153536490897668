import { useEffect, useState } from 'react';
import { Button } from '../../../../../componentsV2/ui/Button';
import Modal from '../../../../../componentsV2/ui/Modal';
import { ReactComponent as FileIcon } from '../../../../../assets/file-arrow-up-down.svg';
import styled from 'styled-components';
import { Typography } from '../../../../../componentsV2/ui/Typography';
import { Card } from '../../../../../@types/CorporateExpenses/Card';
import { InfoContent } from '../../../../../componentsV2/InfoContent';
import { CurrencyInput } from '../../../../../componentsV2/ui/Form/CurrencyInput';
import {
	convertCentsToFormattedReais,
	convertReaisToCents,
} from '../../../../../utils/CurrencyConvert';
import { useForm } from 'react-hook-form';
import {
	parseCurrencyStrToNumber,
	parseCurrencyToBRLStandard,
} from '../../../../../utils/parseCurrency';
import { useMutation, useQueryClient } from 'react-query';
import {
	UpdateCardBalanceActions,
	updateCardBalance,
} from '../../../../../services/queries/Corpway/Cards';
import {
	CORPWAY_EXTRACT_KEY,
	CORPWAY_CARD_BALANCE_KEY,
} from '../../../../../services/queries/Corpway/Extract';

import { toast } from 'react-toastify';
import { showErrorMessage } from '../../../../../utils/ErrorHandler';
import { useAuth } from '../../../../../hooks/useAuth';
import { MdCheck } from 'react-icons/md';
import { PasswordInput } from '../../../../../componentsV2/ui/Form/PasswordInput';
import { useValidateCorpwayUserPassword } from '../../../../../hooks/useValidateCorpwayUserPassword';
import { formatCardNumber } from '../../../../../utils/formatCardNumber';
import {
	HeaderText,
	ModalHeaderTextProps,
	IconContainer,
} from '../../../../../componentsV2/ui/Modal/InformationModal';
import { IoIosArrowRoundDown, IoIosArrowRoundUp } from 'react-icons/io';
import { CardInfo } from '../CardInfo';

interface FormData {
	value: string;
	password: string;
}

interface Props {
	card: Card;
	isModalOpen: boolean;
	setModalOpen: (isModalOpen: boolean) => void;
}

interface UpdateBalanceProps {
	value: number;
	action: UpdateCardBalanceActions;
}

export function UpdateCardBalanceModal({
	card,
	isModalOpen,
	setModalOpen,
}: Props) {
	const queryClient = useQueryClient();
	const { companyBalance, currentCompany, user } = useAuth();
	const [action, setAction] = useState<UpdateCardBalanceActions>('add');
	const [currentStep, setCurrentStep] = useState<0 | 1 | 2>(0);
	const {
		formState: { errors },
		setError,
		clearErrors,
	} = useForm({
		defaultValues: {
			password: '',
		},
	});
	const [values, setValues] = useState({
		ActionValue: 0,
		newCardValue: 0,
		newBalanceValue: 0,
	});

	const { validateUserPasswordAsync, isPasswordValidationLoading } =
		useValidateCorpwayUserPassword({
			onFailure: () =>
				setError('password', {
					type: 'manual',
					message: 'Senha incorreta, por favor digite novamente',
				}),
		});

	useEffect(() => {
		if (isModalOpen) {
			setAction('add');
			setCurrentStep(0);
			setValues({
				ActionValue: 0,
				newBalanceValue: 0,
				newCardValue: 0,
			});
		}
	}, [isModalOpen]); // eslint-disable-line react-hooks/exhaustive-deps

	const updateBalanceMutation = useMutation(
		({ value, action }: UpdateBalanceProps) =>
			updateCardBalance(card.id, card.company_id, value, action),
		{
			onSuccess: () => {
				setCurrentStep(2);
			},
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível alterar o saldo do cartão. '
				);
			},
		}
	);

	function submitValue(value: number, balanceValue: number, cardValue: number) {
		setValues({
			ActionValue: value,
			newBalanceValue: balanceValue,
			newCardValue: cardValue,
		});

		setCurrentStep(1);
	}

	function handleback() {
		setValues({
			ActionValue: 0,
			newBalanceValue: 0,
			newCardValue: 0,
		});

		if (currentStep === 0) setModalOpen(false);

		setCurrentStep(0);
	}

	async function handleSubmitForm(value: number, password: string) {
		try {
			const valueInCents = convertReaisToCents(value);

			if (valueInCents === 0) {
				toast.error('Valor inválido!');
				return;
			}

			const passwordIsValid = await validateUserPasswordAsync(password);

			if (!passwordIsValid) {
				return;
			}
			updateBalanceMutation.mutate({ value: valueInCents, action: action! });
		} catch (err) {
			return;
		}
	}

	async function closeAfterSuccess() {
		queryClient.resetQueries('corpway-balances');
		queryClient.resetQueries([CORPWAY_EXTRACT_KEY, currentCompany?.id]);
		queryClient.resetQueries([CORPWAY_CARD_BALANCE_KEY, currentCompany?.id]);
		setModalOpen(false);
	}

	const Header = ({
		title,
		icon,
	}: {
		title: ModalHeaderTextProps[];
		icon?: React.ReactNode;
	}) => {
		const Title: React.ReactNode = title.map((text, index) => (
			<>
				<HeaderText
					key={text.text ?? '' + index}
					color={text.color}
					isBold={text.isBold}
				>
					{text.text}
				</HeaderText>
				{text.text?.includes('\n') && <br />}
			</>
		));

		const headerDescriptionAlignment = 'space-between';
		const flexDirection = 'column';

		return (
			<HeaderContainer>
				{icon && (
					<IconContainer color={'var(--primary-blue)'}>{icon}</IconContainer>
				)}

				<p style={{ textAlign: 'center' }}>{Title}</p>

				<HeaderDescription
					style={{
						justifyContent: headerDescriptionAlignment,
						flexDirection: flexDirection,
					}}
				></HeaderDescription>
			</HeaderContainer>
		);
	};

	const InitialContent = () => {
		const { register, watch, formState } = useForm<{ value: string }>({
			defaultValues: {
				value: '0,00',
			},
		});

		const inputCurrentValue = parseCurrencyStrToNumber(watch('value'));

		const newCardBalanceCents = (() => {
			const currentValue = convertReaisToCents(inputCurrentValue);

			if (action === 'add') return currentValue + card.balance;
			return card.balance - currentValue;
		})();

		const newCorpwayBalanceReais = (() => {
			if (action === 'add')
				return companyBalance.corpway_balance - inputCurrentValue;
			return companyBalance.corpway_balance + inputCurrentValue;
		})();

		const isReverse = action === 'reverse' && formState.isDirty;
		const isAdd = action === 'add' && formState.isDirty;

		// Adding value to a card can't exceed corpway balance
		const isCorpwayLimitReached =
			action === 'add' && newCorpwayBalanceReais < 0;

		// Chargeback value to a card can't exceed card current balance
		const isCardChargebackLimitReached =
			action === 'reverse' && newCardBalanceCents < 0;

		return (
			<Container>
				<InformationContainer
					style={{ width: '90%', justifyContent: 'space-between' }}
				>
					<Typography style={{ alignItems: 'flex-start' }}>
						Cartão:{' '}
						<CardInfo
							icon={false}
							alias={card.alias}
							pan={formatCardNumber(card.pan)!}
						/>
					</Typography>

					<Typography style={{ alignItems: 'flex-end' }}>
						Centro de custo: <span>{card.cost_center_data?.title}</span>
					</Typography>
				</InformationContainer>
				<OptionsContainer>
					<Typography
						style={{ justifyContent: 'center' }}
						color='var(--mid-gray)'
						size='1.5rem'
					>
						Qual operação deseja realizar?
					</Typography>
					<BottomOptionsContainer>
						<ActionOption
							isActive={action === 'add'}
							onClick={() => setAction('add')}
						>
							Adicionar saldo
						</ActionOption>
						<ActionOption
							isActive={action === 'reverse'}
							onClick={() => setAction('reverse')}
						>
							Estornar saldo
						</ActionOption>
					</BottomOptionsContainer>
					<InputContainer>
						<CurrencyInput
							required
							id='value'
							name='value'
							register={register}
							label='Digite o valor que deseja alterar'
						/>
						<InfoContent
							label='Saldo do cartão:'
							orientation='horizontal'
							sameSize
							sizeInRems={1.4}
						>
							<TextStyle
								className={
									isReverse ? 'limited' : isAdd ? 'updated' : 'balance'
								}
							>
								{isReverse ? (
									<IoIosArrowRoundDown />
								) : isAdd ? (
									<IoIosArrowRoundUp />
								) : (
									''
								)}
								{convertCentsToFormattedReais(newCardBalanceCents)}
							</TextStyle>
						</InfoContent>
						<InfoContent
							label='Saldo disponível em carteira:'
							orientation='horizontal'
							sameSize
							sizeInRems={1.4}
						>
							<TextStyle className={isCorpwayLimitReached ? 'limited' : ''}>
								{' '}
								{parseCurrencyToBRLStandard(newCorpwayBalanceReais)}
							</TextStyle>
						</InfoContent>
						<Typography
							size='1.2rem'
							color='var(--primary-red)'
							style={{
								textDecoration: 'underline',
								textAlign: 'center',
								justifyContent: 'center',
							}}
						>
							{isCorpwayLimitReached &&
								'O valor solicitado é maior que o saldo disponível'}
							{isCardChargebackLimitReached &&
								'O valor solicitado é maior que o disponível no cartão'}
						</Typography>
					</InputContainer>
				</OptionsContainer>
				<BottomOptionsContainer>
					<Button
						intent='terciary'
						$outline
						roundness='lg'
						width='adjusted'
						onClick={handleback}
					>
						Voltar
					</Button>
					<Button
						intent='primary'
						roundness='lg'
						disabled={
							isCorpwayLimitReached ||
							isCardChargebackLimitReached ||
							!formState.isDirty
						}
						onClick={() =>
							submitValue(
								inputCurrentValue,
								newCorpwayBalanceReais,
								newCardBalanceCents
							)
						}
					>
						Continuar
					</Button>
				</BottomOptionsContainer>
			</Container>
		);
	};

	const PasswordContent = () => {
		const { register, watch, formState } = useForm<FormData>({
			defaultValues: {
				password: '',
			},
		});
		const passwordInputValue = watch('password');

		return (
			<Container>
				<InformationContainer
					style={{ width: '90%', justifyContent: 'space-between' }}
				>
					<Typography style={{ alignItems: 'flex-start' }}>
						Cartão:{' '}
						<CardInfo
							icon={false}
							alias={card.alias}
							pan={formatCardNumber(card.pan)!}
						/>
					</Typography>

					<Typography style={{ alignItems: 'flex-end' }}>
						Centro de custo: <span>{card.cost_center_data?.title}</span>
					</Typography>
				</InformationContainer>
				<OptionsContainer>
					<InputContainer>
						<PasswordInput
							label='Insira a sua senha'
							id='password'
							name='password'
							autoComplete='new-password'
							register={register}
							errorMessage={errors.password?.message}
						/>
					</InputContainer>
					<InformationContainer
						style={{ width: '100%', justifyContent: 'space-between' }}
					>
						<Typography size='1.2rem' style={{ alignItems: 'flex-start' }}>
							Novo saldo do cartão:{' '}
							<TextStyle className='balance'>
								{convertCentsToFormattedReais(values.newCardValue)}
								{action === 'add' ? (
									<IoIosArrowRoundUp size={20} color='#00a22d' />
								) : (
									<IoIosArrowRoundDown size={20} color='var(--primary-red)' />
								)}
							</TextStyle>
						</Typography>

						<Typography size='1.2rem' style={{ alignItems: 'flex-end' }}>
							Novo saldo em carteira:{' '}
							<TextStyle className='balance'>
								{action === 'add' ? (
									<IoIosArrowRoundDown size={20} color='var(--primary-red)' />
								) : (
									<IoIosArrowRoundUp size={20} color='#00a22d' />
								)}
								{parseCurrencyToBRLStandard(values.newBalanceValue)}
							</TextStyle>
						</Typography>
					</InformationContainer>
				</OptionsContainer>
				<BottomOptionsContainer>
					<Button
						intent='terciary'
						$outline
						roundness='lg'
						width='adjusted'
						onClick={() => {
							clearErrors('password');
							handleback();
						}}
					>
						Voltar
					</Button>
					<Button
						intent='primary'
						roundness='lg'
						disabled={!formState.isDirty}
						loading={
							isPasswordValidationLoading || updateBalanceMutation.isLoading
						}
						onClick={() =>
							handleSubmitForm(values.ActionValue, passwordInputValue)
						}
						shrinkOnLoading={false}
					>
						Continuar
					</Button>
				</BottomOptionsContainer>
			</Container>
		);
	};

	function SuccessContent() {
		return (
			<Container>
				<Header
					title={[{ text: 'Saldo alterado com sucesso' }]}
					icon={<MdCheck size={30} color={'white'} />}
				/>

				<BottomOptionsContainer>
					<Button
						onClick={() => closeAfterSuccess()}
						intent='terciary'
						roundness='lg'
						$outline
					>
						Fechar
					</Button>
				</BottomOptionsContainer>
			</Container>
		);
	}

	const RenderContent = () => {
		const CONTENT = {
			0: <InitialContent />,
			1: <PasswordContent />,
			2: <SuccessContent />,
		};

		return CONTENT[currentStep];
	};

	return (
		<div>
			<Modal
				isOpen={isModalOpen}
				onRequestClose={() =>
					currentStep === 2 ? closeAfterSuccess() : setModalOpen(false)
				}
			>
				<Container>
					{(currentStep === 0 || currentStep === 1) && (
						<Header
							icon={<FileIcon color={'white'} />}
							title={[
								{ text: 'Gerenciar ' },
								{ text: 'saldo do cartão', isBold: true },
							]}
						/>
					)}
					<RenderContent />
				</Container>
			</Modal>
		</div>
	);
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	border-radius: 1rem;
	background-color: var(--white);
	width: 50rem;
	padding-bottom: 1rem;
	align-items: center;
	gap: 2rem;
	@media (max-width: 1024px) {
		width: 100%;
	}
`;

const InformationContainer = styled.div`
	display: flex;
	justify-content: space-around;
	width: 50rem;

	& > div:first-child {
		flex-direction: column;
		align-items: flex-start;
	}

	& > div:last-child {
		flex-direction: column;
		align-items: flex-end;
	}

	& > p {
		flex-direction: column;

		span {
			font-weight: 600;
		}
	}
`;

const OptionsContainer = styled.div`
	display: flex;
	align-self: center;
	gap: 2rem;
	width: 90%;
	border: 2px solid var(--primary-blue);
	padding: 2rem;
	border-radius: 1.6rem;
	flex-direction: column;
`;

export const BottomOptionsContainer = styled.div`
	display: flex;
	gap: 2rem;
	justify-content: center;
`;

const HeaderContainer = styled.div`
	background-color: #f0f3f8;
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 15rem;
	align-items: center;
	justify-content: center;
	border-radius: 1rem;
	padding: 0 4rem;
`;

const HeaderDescription = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	margin-top: 1rem;
	flex-wrap: wrap;
	align-items: center;
	& > p {
		text-decoration: underline;
	}
`;

const ActionOption = styled.div<{ isActive: boolean }>`
	display: flex;
	align-items: center;
	height: 3rem;
	justify-content: center;
	padding: 1rem;
	border-radius: 2rem;
	border: 1px solid
		${({ isActive }) => (isActive ? 'var(--primary-blue)' : 'var(--dark-gray)')};
	width: 15rem;
	text-align: center;
	background-color: ${({ isActive }) =>
		isActive ? 'var(--primary-blue)' : 'transparent'};
	color: ${({ isActive }) => (isActive ? 'var(--white)' : 'var(--dark-gray)')};
	font-size: 1.2rem;
	cursor: pointer;
`;

const InputContainer = styled.form`
	display: flex;
	flex-direction: column;
	gap: 1rem;
	width: 100%;

	& > div {
		justify-content: space-between;
	}
`;

const TextStyle = styled.p`
	font-size: 1.4rem;
	font-weight: 600;
	color: var(--dark-gray);
	display: flex;

	&.balance {
		color: var(--primary-blue);
	}

	&.updated {
		display: flex;
		align-items: center;
		color: #00a22d;

		> svg {
			width: 3rem;
			height: 2rem;
		}
	}

	&.limited {
		color: var(--primary-red);

		> svg {
			width: 3rem;
			height: 2rem;
		}
	}
`;
