import {
	FaCheckCircle,
	FaExclamationCircle,
	FaQuestionCircle,
	FaRegClock,
} from 'react-icons/fa';

// parse CHARGEBACK and REFUNDS status

export function parseStatusString(status: string) {
	if (status === 'pending') return 'Aguardando';
	if (status === 'approved') return 'Aprovado';
	if (status === 'canceled') return 'Reprovado';
	return '?????';
}

export function getStatusIcon(status: string) {
	if (status === 'approved') return <FaCheckCircle color='#1CCA0D' />;

	if (status === 'canceled')
		// return   <FaTimesCircle color="#000"/>
		return <FaExclamationCircle color='#FB1313' />;

	if (status === 'pending') return <FaRegClock color='#E0E414' />;

	return <FaQuestionCircle />;
}

export function parseTransactionStatus(status: string) {
	return status === 'Approved' ? 'APROVADO' : 'NEGADO';
}
