import { useLayoutEffect, useRef, useState } from 'react';
import * as S from './styles';
import { MdNavigateBefore, MdNavigateNext } from 'react-icons/md';

interface CarouselProps {
	children: React.ReactNode;
	mode: 'draggable' | 'option';
	gap?: string;
}

export function Carousel({ children, mode, gap = '4rem' }: CarouselProps) {
	const contentRef = useRef<HTMLDivElement>(null);
	const [isOverflowing, setIsOverflowing] = useState(false);
	/*
	Although, it's not good practice to use normal variables instead of states,
	the performance was much better without using states.
	
	// const [isDragging, setIsDragging] = useState(false);
	// const [startX, setStartX] = useState(0);
	// const [scrollLeft, setScrollLeft] = useState(0);
	*/
	let isDragging = false;
	let startX = 0;
	let scrollLeft = 0;

	useLayoutEffect(() => {
		if (contentRef.current) {
			setIsOverflowing(checkOverflow(contentRef.current));
		}
	}, [contentRef]);

	function checkOverflow(el: HTMLDivElement) {
		var isOverflowing =
			el.clientWidth < el.scrollWidth || el.clientHeight < el.scrollHeight;

		return isOverflowing;
	}

	const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
		if (mode !== 'draggable') return;
		isDragging = true;

		startX = event.pageX - contentRef.current!.offsetLeft;
		scrollLeft = contentRef.current!.scrollLeft;
	};

	const stopDragging = () => {
		isDragging = false;
	};

	const handleMouseMove = (event: React.MouseEvent<HTMLDivElement>) => {
		if (!isDragging) return;
		const x = event.pageX - contentRef.current!.offsetLeft;
		const walk = (x - startX) * 1; //  scroll speed
		contentRef.current!.scrollLeft = scrollLeft - walk;
	};

	const handlePrev = () => {
		const step = 200;
		contentRef.current!.scrollLeft -= step;
	};

	const handleNext = () => {
		const step = 200;
		contentRef.current!.scrollLeft += step;
	};

	const showActionButton = mode === 'option' && isOverflowing;

	return (
		<S.Container>
			{showActionButton && (
				<S.ActionButton onClick={handlePrev}>
					<MdNavigateBefore />
				</S.ActionButton>
			)}

			<S.ContentContainer
				ref={contentRef}
				onMouseDown={handleMouseDown}
				onMouseUp={stopDragging}
				onMouseMove={handleMouseMove}
				onMouseLeave={stopDragging}
				style={
					isOverflowing && mode === 'draggable'
						? { cursor: 'grab', gap: gap }
						: { gap: gap }
				}
			>
				{children}
			</S.ContentContainer>

			{showActionButton && (
				<S.ActionButton onClick={handleNext}>
					<MdNavigateNext />
				</S.ActionButton>
			)}
		</S.Container>
	);
}
