import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Benefit } from '../../../@types';
import { showCancelableToast } from '../../../components/CancelableToast';
import Loader from '../../../components/Loader';
import { NotApprovedCollabsModal } from '../../../components/NotApprovedCollabsModal';
import PageTitle from '../../../components/PageTitle';
import { PaymentHeader } from '../../../components/PaymentHeader';
import { PaymentLogs } from '../../../components/PaymentLogs';
import PreventTransitionPrompt from '../../../components/PreventTransitionPrompt';
import { useAuth } from '../../../hooks/useAuth';
import { useDialogModal } from '../../../hooks/useDialogModal';
import { usePayment } from '../../../hooks/usePaymentV2';
import {
	fetchPayment,
	operatorReviewPayment,
	PreReleaseReviewOperatorData,
	GroupReleaseReviewOperatorData,
} from '../../../services/queries/PaymentsV2';
import { convertReaisToCents } from '../../../utils/CurrencyConvert';
import { parseCurrencyStrToNumber } from '../../../utils/parseCurrency';
import { parsePayment } from '../../../utils/parsePayment';
import { PaymentSummary } from './components/PaymentSummary';
import PaymentTableReviewOperator from './components/PaymentTable/PaymentTableReviewOperator';
import * as S from './styles';
import {
	getBenefitsInputs,
	getBenefitValue,
} from './utils/getBenefitValuesInputs';
import { showErrorMessage } from '../../../utils/ErrorHandler';

interface ReviewPaymentOperatorParams {
	id: string;
}

interface ReviewPaymentParams {
	preReleases: PreReleaseReviewOperatorData[];
	groupReleases: GroupReleaseReviewOperatorData[];
}
export function ReviewPaymentOperator() {
	const { id } = useParams<ReviewPaymentOperatorParams>();
	const history = useHistory();
	const { currentCompany } = useAuth();
	const { beneficiaries, setBeneficiaries, setBenefits, setSubmitProgress } =
		usePayment();
	const { openConfirmDialog } = useDialogModal();
	const [showSummary, setShowSummary] = useState(false);

	const [allowNavigation, setAllowNavigation] = useState(false);

	const fetchPaymentQuery = useQuery(
		['fetchPayment', currentCompany?.id, id],
		() => {
			return fetchPayment(id!, currentCompany!.id);
		},
		{
			onSuccess: (data) => {
				const { beneficiariesParsed, benefitsParsed } = parsePayment(data);

				setBeneficiaries([
					...beneficiariesParsed.map((beneficiary) => {
						let beneficiaryBenefits = benefitsParsed.map((benefit) => {
							const foundBenefit = beneficiary.benefits.find(
								(b) => b.id === benefit.id
							);

							if (foundBenefit) {
								return foundBenefit;
							}
							// included {} empty benefit if not present (otherwise the table cells are going to break)
							return {} as Benefit;
						});

						return {
							...beneficiary,
							benefits: beneficiaryBenefits,
							selected: true,
						};
					}),
				]);
				setBenefits(benefitsParsed);
			},
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao buscar o lançamento. '
				);
			},
			refetchOnWindowFocus: false,
			refetchOnReconnect: false,
		}
	);

	const reviewPaymentMutation = useMutation(
		({ preReleases, groupReleases }: ReviewPaymentParams) =>
			operatorReviewPayment(preReleases, groupReleases, id, currentCompany!.id),
		{
			onSuccess: () => {
				setSubmitProgress(undefined);
				setAllowNavigation(true);
				openConfirmDialog(
					`Correção de pagamento enviada.
          Um usuário ADMINISTRADOR deverá aprová-la para que os pagamentos sejam efetivados`,
					'Entendi',
					() => {
						history.push('/home/payments');
					}
				);
			},
			onError: (err) => {
				setSubmitProgress(undefined);
				showErrorMessage(
					err as Error,
					'Não foi possível corrigir o lançamento. '
				);
			},
		}
	);

	function showSummaryPayment() {
		if (
			getBenefitsInputs().some((i) => parseCurrencyStrToNumber(i.value) <= 0)
		) {
			toast.error('Benefício com valor abaixo do mínimo');
			return;
		}

		setShowSummary(true);
	}

	async function showConfirmationLoading() {
		setSubmitProgress('confirmation_loading'); // start loading

		const canceled = !(await showCancelableToast(
			'Enviando correção',
			submitReview
		));
		if (canceled) {
			setSubmitProgress(undefined); // stop loading
		}
	}

	async function submitReview() {
		const release = fetchPaymentQuery.data!.release!;

		let preReleases: PreReleaseReviewOperatorData[] = [];
		let groupReleases: GroupReleaseReviewOperatorData[] = [];

		// parsing collaborators
		release.preReleases.forEach((r) => {
			const collabBenefits = beneficiaries.find(
				(c) => c.id === r.collaborator.id
			)?.benefits;
			const currentBenefit = collabBenefits?.find(
				(b) => b.id === r.release_payment.benefit.id
			);
			const currentBenefitValue = getBenefitValue(
				r.collaborator.id!,
				currentBenefit!.id
			);

			preReleases.push({
				preRelease_id: r.release_payment.id,
				value: convertReaisToCents(currentBenefitValue),
			}); // converting to cents
		});

		// parsing groups
		release.groupReleases.forEach((r) => {
			const collabBenefits = beneficiaries.find(
				(c) => c.id === r.group.id
			)?.benefits;
			const currentBenefit = collabBenefits?.find(
				(b) => b.id === r.group_payment.benefit.id
			);
			const currentBenefitValue = getBenefitValue(
				r.group.id!,
				currentBenefit!.id
			);

			groupReleases.push({
				groupRelease_id: r.group_payment.id,
				value: convertReaisToCents(currentBenefitValue),
			}); // converting to cents
		});

		reviewPaymentMutation.mutate({ preReleases, groupReleases });
	}

	function goBack() {
		history.push('/home/payments');
	}

	if (!fetchPaymentQuery.data || fetchPaymentQuery.isFetching) {
		return (
			<S.Container>
				<PageTitle title='Detalhes do pagamento para correção' />
				<Loader />
			</S.Container>
		);
	}
	if (fetchPaymentQuery.data.release.status !== 'disapproved') {
		history.replace(`/home/payments/details/${id}`);
	}
	return (
		<>
			<S.Container>
				{!allowNavigation && <PreventTransitionPrompt />}

				<PaymentHeader
					title='Detalhes do pagamento para correção'
					release={fetchPaymentQuery.data!}
				/>

				{/* TABLE COMPONENT THAT RENDERS COLLABORATORS AND THEIR BENEFITS */}
				<div style={{ overflowX: 'auto', maxWidth: '80vw' }}>
					<PaymentTableReviewOperator />
				</div>

				<S.FooterContainer>
					<S.SubmitionOptionsContainer>
						<S.CancellButton onClick={goBack}>Cancelar edições</S.CancellButton>
						<S.SubmitButton onClick={showSummaryPayment}>
							Enviar correção
						</S.SubmitButton>
					</S.SubmitionOptionsContainer>
				</S.FooterContainer>

				<PaymentLogs paymentId={id} />
			</S.Container>

			{/* MODALS */}
			<PaymentSummary
				onSubmit={showConfirmationLoading}
				isOpenSummary={showSummary}
				closeSummary={() => setShowSummary(false)}
			/>

			<NotApprovedCollabsModal
				collabsReproved={reviewPaymentMutation.data?.unpaidPayments ?? []}
			/>
		</>
	);
}
