import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const Container = styled.header`
	background: var(--primary-red);
	height: 6rem;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 1.5rem;
	& > div {
		display: flex;
		align-items: center;
		column-gap: 2rem;
		height: 100%;
		width: 100%;
		margin: 0 2rem;
	}
`;

export const LogoImage = styled.img`
	width: 10rem;
	object-fit: contain;
	padding: 1rem 0;
`;

export const DropdownContainerStyles = css`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 1.5rem 1rem;
	margin-top: 0.5rem;
	background-color: var(--white);
	border-radius: 0.8rem;
	min-width: 25rem;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);

	& a,
	button {
		font-family: 'Poppins', sans-serif;
		font-size: 1.4rem;
		&:hover {
			filter: none;
		}
	}
`;

export const DropdownContainer = styled.div`
	${DropdownContainerStyles}
`;

export const DropdownNotificationsContainer = styled.div`
	${DropdownContainerStyles}
	padding: 0;
	background-color: #f5f5f5;

	padding-right: 0.2rem;
	border: 0.24rem solid #85d6ff;
`;

export const OptionLink = styled(Link)`
	text-decoration: none;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;
	text-align: start;
	padding: 0.5rem;
	border-radius: 0.4rem;
	color: var(--black);
	font-size: 1.4rem;

	& svg {
		margin-right: 1rem;
	}
`;

export const Separator = styled.div`
	height: 0.1rem;
	width: 100%;
	background-color: var(--dark-gray);
	margin: 1rem 0;
`;

export const CompanyDropdowBtn = styled.button`
	background: none;
	display: flex;
	align-items: center;
	color: var(--white);
	font-size: 1.8rem;
	margin-left: 20%;
`;

export const CompanyLogo = styled.img`
	border-radius: 50%;
	min-width: 4rem;
	max-width: 4rem;
	min-height: 4rem;
	max-height: 4rem;
	object-fit: cover;
	margin-right: 1.5rem;
`;

export const CompaniesListTitle = styled.h2`
	width: 100%;
	margin-top: 1.2rem;
	padding: 0 1.2rem;
`;

export const CompaniesList = styled.ul`
	width: 100%;
	display: flex;
	flex-direction: column;
	list-style: none;
	margin-top: 1rem;
`;

export const CompanyContainer = styled.li`
	width: 100%;
	border-top: 0.1rem solid var(--dark-gray);
	/* border-bottom: 0.1rem solid var(--dark-gray); */
	& button {
		display: flex;
		align-items: center;
		width: 100%;
		padding: 1.2rem;
		color: var(--dark-gray);
		background: none;
		& svg {
			width: 1.4rem;
			height: 1.4rem;
			margin-right: 1rem;
		}
	}
`;

interface CompanyBalanceContainerProps {
	showBalance: boolean;
}
export const CompanyBalanceContainer = styled.div<CompanyBalanceContainerProps>`
	font-size: 1.8rem;
	height: 100%;
	display: flex;
	align-items: center;
	background-color: var(--primary-blue);
	color: #3d3e40;
	padding: 0 2rem;
	margin-right: 2rem;
	& span {
		width: 12rem;
		height: 2rem;
		border-radius: 0.4rem;
		font-weight: bold;
		background-color: ${({ showBalance }) =>
			showBalance ? 'transparent' : '#2196DE'};
		font-size: ${({ showBalance }) => (showBalance ? '1.8rem' : '0px')};
		margin-left: 0.5rem;
	}
`;

export const ToggleBalanceVisibilityBtn = styled.button`
	background: none;
	display: flex;
	align-items: center;
	margin-left: 0.6rem;
	& svg {
		width: 2rem;
		height: 2rem;
		transition: filter 0.2s;
		color: #3d3e40;
		&:hover {
			filter: opacity(0.7);
		}
	}
`;

export const IconBtn = styled.button`
	display: flex;
	background: none;
	border: none;
	border-radius: 50%;
	color: var(--white);
	font-size: 2.5rem;
	padding: 0.6rem;
	position: relative;
	& span {
		position: absolute;
		font-size: 1.4rem;
		font-weight: bolder;
		right: 0;
		background-color: var(--primary-blue);
		min-width: 2.2rem;
		max-width: 3rem;
		aspect-ratio: 1 / 1;
		padding: 0.2rem;
		border-radius: 50%;
		border: 0.1rem solid var(--white);
		margin-right: -1rem;
		top: 0;
		margin-top: -0.8rem;
		display: flex;
		align-items: center;
		justify-content: center;
	}
`;

export const OptionsContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

export const ProfileBtn = styled.button`
	display: flex;
	background: none;
	border: none;
	color: var(--dark-gray);
	font-size: 2.5rem;
`;
export const UserDetails = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	height: 100%;
	margin: auto 1rem;
`;

export const TextName = styled.span`
	color: var(--white);
	font-size: 1.4rem;
	width: max-content;
`;

export const TextAccessLevel = styled.span`
	color: var(--white);
	font-size: 1.2rem;
	align-self: end;
	margin: 0.2rem 0rem;
`;

export const UserImageSmall = styled.img`
	border-radius: 50%;
	min-width: 4rem;
	max-width: 4rem;
	min-height: 4rem;
	max-height: 4rem;
	margin-left: 0.5rem;
	object-fit: cover;
`;

export const UserImage = styled.img`
	/* border: 0.2rem solid var(--primary-blue); */
	border-radius: 50%;
	min-width: 6rem;
	max-width: 6rem;
	min-height: 6rem;
	max-height: 6rem;
	object-fit: cover;
`;

export const ProfileTextNameDrop = styled.span`
	color: var(--primary);
	font-size: 1.6rem;
	font-weight: 500;
	align-self: center;
	margin-top: 1rem;
`;
export const ProfileTextDrop = styled.span`
	color: var(--primary);
	font-size: 1.4rem;
	align-self: center;
	margin: 0.4rem 0;
`;
export const ProfileTextEmailDrop = styled.span`
	color: var(--primary);
	font-size: 1.2rem;
	align-self: center;
	margin-bottom: 1rem;
`;

export const ProfileOptionButton = styled.button`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;
	text-align: start;
	padding: 0.5rem;
	border-radius: 0.4rem;
	color: var(--black);
	font-size: 1.4rem;

	& svg {
		margin-right: 1rem;
	}
`;
export const OpenProductOptionsBtn = styled.button`
	display: flex;
	align-items: center;
	column-gap: 1.6rem;
	border-radius: 0.8rem;
	background: #e5eaf2;
	padding: 1.3rem 1.5rem 1.3rem 2.4rem;
	margin-left: 16.1rem;
	color: var(--dark-gray);
	font-size: 1.6rem;
	line-height: 1rem;
	font-weight: 600;
	font-family: 'Poppins', sans-serif;
	&:hover {
		filter: none;
	}
	& > svg {
		color: var(--primary-blue);
		width: 3rem;
		height: 3rem;
	}
`;

export const ProductDropdowBtn = styled.button`
	background: none;
	display: flex;
	align-items: center;
	color: var(--white);
	font-size: 1.8rem;
	margin-left: 4rem;
`;
