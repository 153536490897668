import { useQuery } from 'react-query';
import { TitleTypography } from '../../../../componentsV2/ui/Typography';
import { useAuth } from '../../../../hooks/useAuth';
import { showErrorMessage } from '../../../../utils/ErrorHandler';
import {
	CORPWAY_COST_CENTER_KEY,
	getCostCenters,
} from '../../../../services/queries/Corpway/CostCenters';
import { Table } from '../../../../componentsV2/Table';
import { CreateCostCenterModal } from './components/CreateModal';
import { createColumnHelper } from '@tanstack/react-table';
import { Breadcrumb } from '../../../../componentsV2/BreadCrumb';
import { SectionContainer } from '../../../../componentsV2/ui/SectionContainer';
import { CostCenter } from '../../../../@types/CorporateExpenses/CostCenter';
import { usePagination } from '../../../../hooks/usePagination';
import { useSortColumnHook } from '../../../../hooks/useSortColumnHook';
import { CostCenterDetailsModal } from './components/DetailsModal';
import styled from 'styled-components';
import { useEffect, useState } from 'react';

const columnHelper = createColumnHelper<CostCenter>();
const columns = [
	columnHelper.accessor('title', {
		cell: (info) =>
			info.getValue().charAt(0).toUpperCase() +
			info.getValue().slice(1).toLowerCase(),
		header: 'Nome',
		enableSorting: true,
	}),
	columnHelper.accessor('number_of_users', {
		cell: (info) => info.getValue(),
		header: 'Qtd. de usuários',
		meta: {
			align: 'center',
		},
		enableSorting: true,
	}),
	columnHelper.accessor('number_of_cards', {
		cell: (info) => info.getValue(),
		header: 'Qtd. de cartões',
		meta: {
			align: 'center',
		},
		enableSorting: true,
	}),
	columnHelper.display({
		header: 'Ações',
		cell: (props) => <CostCenterDetailsModal costCenter={props.row.original} />,
		meta: {
			align: 'center',
		},
	}),
];

export function CostCenters() {
	const { currentCompany } = useAuth();
	const { pagination, setPagination } = usePagination();
	const { currentSortColumn, toggleSort } = useSortColumnHook({
		name: 'title',
		order: 'ASC',
	});

	const getCostCentersQuery = useQuery(
		[
			CORPWAY_COST_CENTER_KEY,
			pagination.page,
			currentSortColumn,
			currentCompany?.id,
		],
		() =>
			getCostCenters(currentCompany!.id, pagination.page, currentSortColumn),
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível buscar os centros de custo. '
				);
			},
			keepPreviousData: true,
		}
	);

	const [windowWidth, setWindowWidth] = useState(window.innerWidth);

	useEffect(() => {
	  const handleResize = () => {
		setWindowWidth(window.innerWidth);
	  };
  
	  window.addEventListener('resize', handleResize);
  
	  return () => {
		window.removeEventListener('resize', handleResize);
	  };
	}, []);

	const tableHeight = windowWidth > 600 ? '45rem' : '60rem';


	return (
		<div>
			<SectionContainer>
				<Table
					data={getCostCentersQuery.data?.costCenters ?? []}
					asyncStatus={getCostCentersQuery.status}
					columns={columns}
					leftComponent={
						<div>
							<TitleStyle size='2.2rem' primaryText='Centros'>
								de custo ativos -{' '}
								{getCostCentersQuery.data?.total_costCenters ?? ''}
							</TitleStyle>
							<p
								style={{
									fontSize: '14px',
									wordBreak: 'keep-all', 
									whiteSpace: 'normal'
								}}
							>
								Nesta seção, você cria e gerencia os centros de custos
								ativos, de acordo com os tipos de despesas.
							</p>
						</div>
					}
					rightComponent={<CreateCostCenterModal />}
					pagination={pagination}
					setPagination={setPagination}
					totalItems={getCostCentersQuery.data?.total_costCenters}
					currentSortColumn={currentSortColumn}
					toggleSort={toggleSort}
					containerHeight={tableHeight}
				/>
			</SectionContainer>
		</div>
	);
}

const Container = styled.div`
	& > div {
		padding: 4rem;

		& div {
			row-gap: 3rem;
		}
	}
`;

const TitleStyle = styled(TitleTypography)`
	@media (max-width: 850px) {
		font-size: 1.6rem;
	}
`;
