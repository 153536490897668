import { useEffect, useState, useCallback } from 'react';
import { usePayment } from '../../../../../hooks/usePaymentV2';
import { calculateBenefitsTotals } from '../../utils/calculateBenefitsTotals';
import { getBenefitsInputs } from '../../utils/getBenefitValuesInputs';
import {
	addBenefitInputEventListener,
	removeBenefitInputEventListener,
} from '../../utils/setBenefitInputEventListener';
import { TableData } from '../PaymentTable/styles';
import { Props } from './types';
import { parseCurrencyToBRLStandard } from '../../../../../utils/parseCurrency';

export interface BenefitTotal {
	title: string;
	totalValue: number;
}

export function BenefitsListTotals({ filterDisapproved = false }: Props) {
	const { beneficiaries } = usePayment();
	const [benefits, setBenefits] = useState<BenefitTotal[]>([]);

	useEffect(() => {
		if (beneficiaries.length === 0) return;

		setBenefitsTotals();

		const inputs = getBenefitsInputs();

		inputs.forEach((input) => {
			addBenefitInputEventListener(input, setBenefitsTotals);
		});

		return () => {
			inputs.forEach((input) => {
				removeBenefitInputEventListener(input, setBenefitsTotals);
			});
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [beneficiaries]);

	const setBenefitsTotals = useCallback(() => {
		const benefitsTotalsList = calculateBenefitsTotals(
			beneficiaries,
			filterDisapproved
		).benefitsTotalsList;

		setBenefits(benefitsTotalsList);
	}, [beneficiaries, filterDisapproved]);

	return (
		<>
			{benefits.map((benefit) => (
				<TableData
					key={benefit.title}
					id={benefit.title}
					style={{ fontWeight: 'bold' }}
				>
					{parseCurrencyToBRLStandard(benefit.totalValue)}
				</TableData>
			))}
		</>
	);
}
