import { useState } from 'react';
import PageTitle from '../../../../../components/PageTitle';
import Dropzone from 'react-dropzone';
import * as Excel from 'exceljs';

import * as S from './styles';
import { importFileSupportedExtenstions } from '../../../../..//utils/supportedImportExtensions';
import { useQuery } from 'react-query';
import { useAuth } from '../../../../..//hooks/useAuth';
import { getBenefits } from '../../../../..//services/queries/Benefits';
import { Benefit } from '../../../../..//@types';

interface ImportFileProps {
	onFileUpload(file: File): void;
}

export default function ImportFile({ onFileUpload }: ImportFileProps) {
	const { currentCompany } = useAuth();
	const [paymentModelURL, setPaymentModelURL] = useState<string | null>(null);

	useQuery(
		['benefits', currentCompany?.id],
		() => {
			return getBenefits(currentCompany?.id);
		},
		{
			onSuccess: generateModelFileURL,
			enabled: !paymentModelURL,
		}
	);

	async function generateModelFileURL(benefits: Benefit[]) {
		if (!benefits.length) return;

		const workbook = new Excel.Workbook();

		const worksheet = workbook.addWorksheet('Pagamento Modelo');

		// headers
		worksheet.columns = [
			{ header: 'CPF', key: 'cpf', width: 15 },
			{ header: 'NOME', key: 'name', width: 20 },
			...benefits.map((benefit) => {
				return { header: benefit.title, key: benefit.id, width: 16 };
			}),
		];

		// set benefits dropdown list (validation)
		benefits.forEach((benefit) => {
			worksheet.getColumnKey(benefit.id).eachCell((cell) => {
				cell.dataValidation = {
					type: 'list',
					allowBlank: true,
					// formulae: ['"One,Two,Three,Four"'],
					formulae: [`"${benefits.map((b) => b.title).join(',')}"`],
				};
			});
		});

		const firstBenefitKey = benefits[0].id;
		const secondBenefitKey = benefits[1] ? benefits[1].id : undefined;

		let exampleRow = {
			cpf: "'33194731076'",
			name: 'José Colaborador',
			[firstBenefitKey]: 15.99,
		};
		if (secondBenefitKey) {
			exampleRow = {
				...exampleRow,
				[secondBenefitKey]: 20,
			};
		}

		worksheet.addRow(exampleRow);

		const buffer = await workbook.xlsx.writeBuffer();
		const fileType =
			'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

		const blob = new Blob([buffer], { type: fileType });

		const fileURL = window.URL.createObjectURL(blob);
		setPaymentModelURL(fileURL);
	}

	const renderDragMessage = (isDragActive: boolean, isDragReject: boolean) => {
		if (!isDragActive) {
			return (
				<S.UploadMessage>
					Arraste o arquivo de pagamento para cá
					<span>ou</span>
					<S.UploadButton>Carregue o arquivo do seu PC</S.UploadButton>
				</S.UploadMessage>
			);
		}

		if (isDragReject) {
			return (
				<S.UploadMessage type='error'>Arquivo não suportado</S.UploadMessage>
			);
		}

		return (
			<S.UploadMessage type='success'>Solte o arquivo aqui</S.UploadMessage>
		);
	};

	return (
		<S.Container>
			<PageTitle title='Importar pagamentos' />

			<S.UploadHeader>Pagamento por planilha</S.UploadHeader>
			<Dropzone
				accept={importFileSupportedExtenstions}
				onDropAccepted={(files) => onFileUpload(files[0])}
				multiple={false}
			>
				{({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
					<S.DropContainer
						{...getRootProps()}
						isDragActive={isDragActive}
						isDragReject={isDragReject}
					>
						<input {...getInputProps()} />
						{renderDragMessage(isDragActive, isDragReject)}
					</S.DropContainer>
				)}
			</Dropzone>

			<S.ExtensionsInfo>
				<S.InfoIcon />
				Extensões válidas: .xls, .xlsx, .csv.
			</S.ExtensionsInfo>
			<S.ExtensionsInfo>
				<S.InfoIcon />
				<S.ModelLink
					href={
						paymentModelURL ??
						`${window.location.origin}/Modelo-Lancamento.xlsx`
					}
					download={'Modelo-Lancamento.xlsx'}
				>
					Baixar modelo de planilha de pagamento
				</S.ModelLink>
			</S.ExtensionsInfo>

			<S.UploadHeader>Pagamento manual</S.UploadHeader>
			<S.LinkButton to='/home/payment/create'>Avançar</S.LinkButton>
		</S.Container>
	);
}
