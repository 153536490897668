import avatarImg from '../../../../../assets/avatar_icon.svg';
import * as S from './styles';
import { MdAddAPhoto } from 'react-icons/md';
import React from 'react';
import { CameraIcon } from './styles';

interface AvatarProps {
	size?: number;
	image?: string | File | null;
	cameraIcon?: boolean;
	children?: React.ReactNode;
}

export function Avatar({
	image = avatarImg,
	size = 14,
	cameraIcon = true,
	children,
}: AvatarProps) {
	const avatarSrc = image ? image : avatarImg;

	return (
		<S.AvatarLabelContainer
			background={
				typeof avatarSrc === 'string'
					? avatarSrc
					: URL.createObjectURL(avatarSrc)
			}
			size={size}
		>
			{children}
			<CameraIcon size={size}>{cameraIcon && <MdAddAPhoto />}</CameraIcon>
		</S.AvatarLabelContainer>
	);
}
