import { useForm } from 'react-hook-form';
import { Operator } from '../../@types';
import { useAuth } from '../../hooks/useAuth';
import { cpfMask } from '../../utils/masks';
import {
	ObrigatoryFieldsIndication,
	ObrigatoryIndicator,
} from '../ObrigatoryFieldsIndicator';
import * as FormStyle from '../Form/FormStyles';
import { useEffect, useState } from 'react';
import PreventTransitionPrompt from '../PreventTransitionPrompt';
import { cpfPattern, emailPattern } from '../../utils/patterns';
import { trimObjectData } from '../../utils/trimObjectData';

export interface OperatorFormProps {
	operator?: Operator;
	onSubmit(data: Operator): Promise<void>;
	changeStatus?(): Promise<void>;
	// deleteOperator?(): void
}

const OperatorForm = ({
	operator,
	onSubmit,
	changeStatus,
}: OperatorFormProps) => {
	const { user } = useAuth();
	// const [avatarImg, setAvatarImg] = useState<File>()
	const { register, handleSubmit, reset, formState } = useForm({
		defaultValues: {
			...operator,
			cpf: cpfMask(operator?.cpf || ''),
		},
	});

	const { isDirty } = formState;
	const [allowNavigation, setAllowNavigation] = useState(!isDirty);

	useEffect(() => {
		setAllowNavigation(!isDirty);
	}, [isDirty]);

	useEffect(() => {
		reset({
			...operator,
			cpf: cpfMask(operator?.cpf || ''),
		});
	}, [operator]); //eslint-disable-line

	async function handleSubmitForm(data: Operator) {
		setAllowNavigation(true);
		await onSubmit(trimObjectData(data)); // Send to the parent (AddOperator Page or EditOperator)
		setAllowNavigation(false);
		// const success = await onSubmit(data, avatarImg) // Send to the parent (AddOperator Page or EditOperator)
	}

	// function onAvatarUpload(event: any) {
	// setAvatarImg(event.target.files[0])
	// }

	return (
		<FormStyle.Form
			onSubmit={handleSubmit(handleSubmitForm)}
			data-testid='operator_form_test_id'
		>
			{!allowNavigation && <PreventTransitionPrompt />}

			<FormStyle.FormContainer>
				{/* COMPANY DATA FIELDSET */}
				<FormStyle.FieldSet>
					<FormStyle.FieldGroup>
						<FormStyle.Field
							style={{ maxWidth: '50%', paddingRight: '0.5rem' }}
						>
							<FormStyle.Label htmlFor='name'>
								Nome completo <ObrigatoryIndicator />
							</FormStyle.Label>
							<FormStyle.Input
								type='text'
								{...register('name')}
								name='name'
								data-testid='nameInput_test_id'
								required
							/>
						</FormStyle.Field>

						<FormStyle.Field>
							<FormStyle.Label htmlFor='cpf'>
								CPF <ObrigatoryIndicator />
							</FormStyle.Label>
							<FormStyle.Input
								type='text'
								pattern={cpfPattern}
								placeholder='Ex: 999.999.999-99'
								{...register('cpf')}
								name='cpf'
								data-testid='cpfInput_test_id'
								required
								onChange={(event) => {
									const { value } = event.target;
									event.target.value = cpfMask(value);
								}}
							/>
						</FormStyle.Field>
					</FormStyle.FieldGroup>

					<FormStyle.FieldGroup>
						<FormStyle.Field>
							<FormStyle.Label htmlFor='office'>
								Cargo <ObrigatoryIndicator />
							</FormStyle.Label>
							<FormStyle.Input
								type='text'
								{...register('office')}
								name='office'
								data-testid='officeInput_test_id'
								required
							/>
						</FormStyle.Field>

						<FormStyle.Field>
							<FormStyle.Label htmlFor='contract_type'>
								Nível de Acesso <ObrigatoryIndicator />
							</FormStyle.Label>
							<FormStyle.SelectInput
								{...register('access_level')}
								disabled={!!operator}
								name='access_level'
								data-testid='accessInput_test_id'
								required
							>
								<option value='operator' data-testid='access_select_option'>
									Operador
								</option>
								<option
									value='admin'
									disabled={user.access_level !== 'super_admin'}
									data-testid='access_select_option'
								>
									Admin
								</option>
							</FormStyle.SelectInput>
						</FormStyle.Field>
					</FormStyle.FieldGroup>
				</FormStyle.FieldSet>

				<FormStyle.Divider />

				{/* CONTACT FIELDSET */}
				<FormStyle.FieldSet>
					<FormStyle.FieldGroup>
						<FormStyle.Field
							style={{ maxWidth: '50%', paddingRight: '0.5rem' }}
						>
							<FormStyle.Label htmlFor='email'>
								Email <ObrigatoryIndicator />
							</FormStyle.Label>
							<FormStyle.Input
								type='email'
								{...register('email')}
								placeholder='Ex: exemplo@email.com'
								pattern={emailPattern}
								name='email'
								data-testid='emailInput_test_id'
								required
							/>
						</FormStyle.Field>
					</FormStyle.FieldGroup>
				</FormStyle.FieldSet>
			</FormStyle.FormContainer>

			<ObrigatoryFieldsIndication />
			<FormStyle.ButtonsContainer>
				<FormStyle.LeftButtonsContainer>
					<FormStyle.FormButton type='submit'>Salvar</FormStyle.FormButton>
					{changeStatus && (
						<>
							<FormStyle.Option
								onClick={(e) => {
									e.preventDefault();
									changeStatus();
								}}
								data-testid='changeStatusBtn'
							>
								{operator!.status ? 'Desativar' : 'Ativar'} operador
							</FormStyle.Option>
						</>
					)}
				</FormStyle.LeftButtonsContainer>
			</FormStyle.ButtonsContainer>
		</FormStyle.Form>
	);
};

export default OperatorForm;
