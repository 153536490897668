import styled from 'styled-components';

export const Container = styled.div`
	flex: 1;
	padding: 4rem 7rem 2rem 7rem;
	overflow: auto;
`;

export const Header = styled.div`
	display: flex;
	align-items: center;
	font-size: 1.4rem;
	> svg {
		width: 2rem;
		height: 2rem;
		margin-left: 6rem;
		margin-right: 1rem;
	}
`;

export const InfoTop = styled.div`
	display: flex;
	align-items: flex-start;
	margin-top: 3rem;
	column-gap: 4rem;
	flex-wrap: wrap;
`;

export const InfoContainer = styled.div`
	display: flex;
	flex-direction: column;
	font-size: 1.6rem;
	margin-bottom: 3rem;
	max-width: 80%;
	width: fit-content;
	overflow: auto;
`;

export const InfoTitle = styled.h2`
	font-weight: 500;
	font-size: 1.8rem;
	color: var(--mid-gray);
	margin-bottom: 1rem;
	text-transform: uppercase;
`;

export const OptionsContainer = styled.div`
	display: flex;
	column-gap: 1rem;
	align-items: center;
	justify-content: center;
`;

export const RemoveCollaboratorBtn = styled.button`
	background-color: var(--primary-red);
	padding: 1rem;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	& svg {
		width: 2rem;
		height: 2rem;
		color: var(--white);
	}
`;

export const FooterOptions = styled.div`
	display: flex;
	column-gap: 2rem;
	margin-top: 2rem;
`;
