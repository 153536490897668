import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	max-width: 60rem;
	width: 70vw;
	min-height: 10rem;
	justify-content: center;
	background-color: #fff;
	padding: 2rem;
	border-radius: 0.6rem;
	row-gap: 2rem;

	@media (max-width: 1024px) {
		width: 100%;
	}
`;

export const CostCenterListContainer = styled.ul`
	margin: 2rem 0;
	max-height: 60vh;
	overflow-y: auto;
`;

export const CostCenterContainer = styled.li`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 1rem;
	padding-bottom: 1rem;
	border-bottom: 0.1rem solid var(--primary-blue);
`;

interface BenefitSelectionProps {
	selected: boolean;
}
export const Selector = styled.button<BenefitSelectionProps>`
	width: 3.5rem;
	height: 3.5rem;
	background: none;
	& svg {
		width: 100%;
		height: 100%;
		border-radius: 50%;
		color: ${({ selected }) =>
			selected ? 'var(--light-gray)' : 'var(--primary-blue)'};
	}
`;
