import {
	getCoreRowModel,
	useReactTable,
	ColumnDef,
	PaginationState,
	RowData,
	ExpandedState,
} from '@tanstack/react-table';
import * as S from './styles';
import { QueryStatus } from 'react-query';
import {
	MdFirstPage,
	MdKeyboardArrowLeft,
	MdKeyboardArrowRight,
	MdLastPage,
} from 'react-icons/md';
import { Typography } from '../ui/Typography';
import { SortColumn } from '../../hooks/useSortColumnHook';
import { useState } from 'react';
import UsersTable from './UsersTable';
import DefaultTable from './DefaultTable';
import LightTable from './LightTable';
import LightGrayTable from './LightGrayTable';

declare module '@tanstack/table-core' {
	// eslint-disable-next-line
	interface ColumnMeta<TData extends RowData, TValue> {
		align?: 'center' | 'start' | 'end';
	}
}

export interface TableProps<T> {
	data: T[];
	columns: ColumnDef<T, any>[];
	leftComponent?: React.ReactNode;
	rightComponent?: React.ReactNode;
	multipleComponents?: React.ReactNode[];
	asyncStatus?: QueryStatus;
	idPath?: string;
	maxHeight?: string;
	containerHeight?: string;
	showHeader?: boolean;
	headerBackground?: string;

	variant?: 'default' | 'light' | 'lightgray' | 'users';

	// pagination related properties
	totalItems?: number;
	pagination?: PaginationState;
	setPagination?: React.Dispatch<React.SetStateAction<PaginationState>>;

	// sorting
	currentSortColumn?: SortColumn | null;
	toggleSort?: (column: string) => void;

	//expandableContent
	useExpandableContent?: boolean;
	expandableRenderItem?: (data: T) => React.ReactNode;
}

export function Table<T>(props: TableProps<T>) {
	const {
		data,
		leftComponent,
		rightComponent,
		multipleComponents,
		idPath,
		containerHeight,
		variant = 'default',

		// pagination related properties
		totalItems,
		pagination,
		setPagination,
	} = props;

	let { columns } = props;

	// default columns to disable sorting
	columns = columns.map((c) => {
		return { ...c, enableSorting: c.enableSorting ?? false };
	});

	const [expanded, setExpanded] = useState<ExpandedState>({});

	const table = useReactTable({
		data,
		columns,
		getCoreRowModel: getCoreRowModel(),
		getRowId(originalRow: any) {
			// For better consistency, use the unique ID from the data and not the one generated by ReactTable.
			// Sometimes this ID property has a different name (e.g. 'user_id') or it's in a nested object, like user_data: {id: '...'}
			// In these cases, just pass the prop idPath, with the path to the desired ID.
			if (!idPath) {
				if (originalRow.id) return originalRow.id;
				throw new Error(`All rows from data needs an unique 'id'`);
			}

			const properties = idPath.split('.');
			let currentObj = { ...originalRow };

			for (const property of properties) {
				currentObj = currentObj[property];
			}

			if (currentObj) return currentObj;

			throw new Error(`All rows from data needs an unique 'id'`);
		},

		// pagination related config
		manualPagination: true,
		pageCount:
			totalItems !== undefined && pagination !== undefined
				? Math.ceil(totalItems / pagination.pageSize)
				: 1,
		state: {
			pagination,
			expanded,
		},
		onPaginationChange: setPagination,

		//Expanding row
		onExpandedChange: setExpanded,
	});

	return (
		<S.Container style={{ maxHeight: containerHeight }} variant={variant}>
			{(leftComponent || rightComponent || multipleComponents) && (
				<S.Header
					variant={variant}
					style={{
						...(leftComponent || rightComponent
							? { paddingBottom: '1rem' }
							: {}),
					}}
				>
					{leftComponent}
					{rightComponent}
					{multipleComponents?.map((component) => component)}
				</S.Header>
			)}

			{variant === 'users' && <UsersTable table={table} />}
			{variant === 'default' && <DefaultTable {...props} table={table} />}
			{variant === 'light' && <LightTable {...props} table={table} />}
			{variant === 'lightgray' && <LightGrayTable {...props} table={table} />}

			{pagination !== undefined && (
				<S.PaginationContainer>
					<Typography color='#393939'>
						{pagination.pageIndex + 1} de {table.getPageCount()}
					</Typography>

					<S.PaginationButtonsContainer>
						<S.PaginationButton
							onClick={() => table.setPageIndex(0)}
							disabled={!table.getCanPreviousPage()}
						>
							<MdFirstPage />
						</S.PaginationButton>
						<S.PaginationButton
							onClick={() => table.previousPage()}
							disabled={!table.getCanPreviousPage()}
						>
							<MdKeyboardArrowLeft />
						</S.PaginationButton>
						<S.PaginationButton
							onClick={() => table.nextPage()}
							disabled={!table.getCanNextPage()}
						>
							<MdKeyboardArrowRight />
						</S.PaginationButton>
						<S.PaginationButton
							onClick={() => table.setPageIndex(table.getPageCount() - 1)}
							disabled={!table.getCanNextPage()}
						>
							<MdLastPage />
						</S.PaginationButton>
					</S.PaginationButtonsContainer>
				</S.PaginationContainer>
			)}
		</S.Container>
	);
}
