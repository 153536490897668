import { FormEvent, useState } from 'react';
import { FaInfoCircle } from 'react-icons/fa';
import Modal from '../Modal';

import * as S from './styles';

export interface FeedbackModalProps {
	description: string;
	isFeedbackModalOpen: boolean;
	handleCloseFeedbackModal(): void;
	submit(feedback: string): void;
}

export default function FeedbackModal({
	description,
	isFeedbackModalOpen,
	handleCloseFeedbackModal,
	submit,
}: FeedbackModalProps) {
	const [feedbackText, setFeedbackText] = useState('');

	function handleSubmitFeedbackText(e: FormEvent) {
		e.preventDefault();
		submit(feedbackText);
		handleCloseFeedbackModal();
	}

	return (
		<Modal
			isOpen={isFeedbackModalOpen}
			onRequestClose={handleCloseFeedbackModal}
			enableClose
		>
			<S.ModalContainer data-testid='modal_container'>
				<FaInfoCircle />
				<span>{description}</span>

				<S.DisapprovalForm onSubmit={(e) => handleSubmitFeedbackText(e)}>
					<S.DisapprovalInput
						data-testid='feedbackInput_test_id'
						value={feedbackText}
						required
						onChange={(e) => {
							setFeedbackText(e.target.value);
						}}
					/>
					<S.ConfirmButton type='submit'>Enviar</S.ConfirmButton>
				</S.DisapprovalForm>
			</S.ModalContainer>
		</Modal>
	);
}
