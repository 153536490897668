import { memo, useRef } from 'react';
import { FaTimes } from 'react-icons/fa';
import { moneyMask } from '../../../../../utils/masks';
import { INPUT_CHANGE_EVENT } from '../../utils/setBenefitInputEventListener';
import * as S from './styles';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
	beneficiaryId: string;
	benefitId: string;
	initialValue: number;
	showReset?: boolean;
	displayOnly?: boolean;
	chargeback_status?: boolean;
	color?: string;
}

function BenefitInputComponent({
	beneficiaryId,
	benefitId,
	initialValue,
	showReset = true,
	displayOnly = false,
	chargeback_status = false,
	color = 'inherit',
	...rest
}: Props) {
	const ref = useRef<HTMLInputElement | null>(null);

	function handleChangeValue(e: React.ChangeEvent<HTMLInputElement>) {
		e.target.value = moneyMask(e.target.value);
	}

	function handleResetValue() {
		if (!ref.current) return;
		ref.current.value = moneyMask('0');
		ref.current.dispatchEvent(new Event(INPUT_CHANGE_EVENT));
	}

	if (displayOnly)
		return (
			<S.Container
				chargeback={chargeback_status}
				data-rh={chargeback_status ? 'Valor estornado do colaborador' : null}
				color={color}
			>
				R$
				<S.DetailsInput
					type='text'
					id={`${beneficiaryId}-${benefitId}`}
					disabled={true}
					value={moneyMask(initialValue.toFixed(2))}
					chargeback={chargeback_status}
					color={color}
				/>
			</S.Container>
		);

	return (
		<S.Container>
			R$
			<S.Input
				ref={ref}
				id={`${beneficiaryId}-${benefitId}`}
				onChange={handleChangeValue}
				defaultValue={moneyMask(initialValue.toFixed(2))}
				{...rest}
			/>
			{showReset && (
				<S.ResetInputBtn
					onClick={handleResetValue}
					data-rh='Zerar benefício'
					data-testid={`reset-${beneficiaryId}-${benefitId}`}
				>
					<FaTimes />
				</S.ResetInputBtn>
			)}
		</S.Container>
	);
}

export const BenefitInput = memo(BenefitInputComponent);
