import styled from 'styled-components';

export const Container = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	padding: 4rem 7rem 2rem 7rem;
`;

export const CurrentQuantitative = styled.span`
	margin-top: 2rem;
	margin-left: 1rem;
	font-size: 1.6rem;
`;
