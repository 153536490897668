import { Button } from '../../../../../componentsV2/ui/Button';
import { BeneficiaryResponse } from '../../../../../@types/CorporateExpenses/Transfer';
import React, { useState } from 'react';
import Modal from '../../../../../componentsV2/ui/Modal';
import * as S from './styles';
import { Typography } from '../../../../../componentsV2/ui/Typography';
import { cnpjMask, cpfMask } from '../../../../../utils/masks';
import { FaCheck, FaQuestion } from 'react-icons/fa';
import { useMutation, useQueryClient } from 'react-query';
import { toggleBeneficiaryStatus } from '../../../../../services/queries/Corpway/Transfers';
import { useAuth } from '../../../../../hooks/useAuth';
import NewBeneficiaryModal from './NewBeneficiaryModal';
import { showErrorMessage } from '../../../../../utils/ErrorHandler';
import NewTransferModal from './NewTransferModal';


const DeleteModal = ({ beneficiary, onDeleteSuccess }: {
	beneficiary: BeneficiaryResponse,
	onDeleteSuccess: () => void
}) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [successModal, setSuccessModal] = useState(false);
	const queryClient = useQueryClient();
	const { currentCompany } = useAuth();

	const onClose = () => {
		setIsModalOpen(false);
	};

	const deleteBeneficiaryMutation = useMutation(
		['deleteBeneficiary', currentCompany?.id, beneficiary.id],
		() => toggleBeneficiaryStatus(beneficiary.id, currentCompany?.id!, false),
		{
			onSuccess: () => {
				setSuccessModal(true);
			},
			onError: (err) => {
				showErrorMessage(err as Error, 'Não foi possível deletar o favorecido.');
			},
		},
	);

	const onSuccessClose = () => {
		queryClient.invalidateQueries([
			'beneficiaries',
			currentCompany?.id,
		])
		setIsModalOpen(false);
		onDeleteSuccess();
	};

	function handleDelete() {
		deleteBeneficiaryMutation.mutate();
	}

	return (
		<>
			<Button intent={'link'} onClick={() => setIsModalOpen(true)}>
				Excluir favorecido
			</Button>
			<Modal isOpen={isModalOpen} onRequestClose={successModal ? onSuccessClose : onClose}>
				<S.GeneralContainer>
					<S.Title>Excluir favorecido</S.Title>
					{successModal ? (
							<>
								<div style={{ display: 'flex', flexDirection: 'column', gap: '2.4rem', alignItems: 'center' }}>
									<S.ModalIconContainer>
										<FaCheck size={24} color={'white'} />
									</S.ModalIconContainer>
									<S.Description>
										<span>Favorecido excluído </span>
										<br />
										<span className={'bold'}> com sucesso</span>
									</S.Description>
								</div>
								<S.ButtonsContainer>
									<Button roundness={'lg'} intent={'primary'} onClick={onSuccessClose}>
										Fechar
									</Button>
								</S.ButtonsContainer>
							</>
						)
						:
						<>
							<div style={{ display: 'flex', flexDirection: 'column', gap: '2.4rem', alignItems: 'center' }}>
								<S.ModalIconContainer>
									<FaQuestion size={24} color={'white'} />
								</S.ModalIconContainer>
								<S.Description>
									<span>Você tem certeza que deseja</span>
									<span className={'bold'}> excluir</span>
									<span> o favorecido</span>
									<span className={'bold'}> {beneficiary.name}?</span>
								</S.Description>
							</div>
							<S.ButtonsContainer>
								<Button roundness={'lg'} intent={'terciary'} $outline onClick={onClose}>
									Voltar
								</Button>
								<Button roundness={'lg'} intent={'primary'} onClick={() => {
									handleDelete();
								}}>
									Excluir
								</Button>
							</S.ButtonsContainer>
						</>
					}
				</S.GeneralContainer>

			</Modal>
		</>
	);
};

export default function BeneficiaryDetailsModal({ beneficiaryResponse, setIsLimitModalOpen } :
{ beneficiaryResponse: BeneficiaryResponse,
	setIsLimitModalOpen: (isOpen: boolean) => void,
}) {
	const [isOpen, setIsOpen] = useState(false);
	const [animationModal, setAnimationModal] = useState(false);
	const [beneficiary, setBeneficiary] = useState(beneficiaryResponse);
	const queryClient = useQueryClient();
	const { currentCompany } = useAuth();

	const onClose = () => {
		setIsOpen(false);
	};

	return (
		<>
			<Button
				intent={'primary'}
				$outline
				roundness={'lg'}
				style={{ padding: '0.8rem 1.2rem 0.8rem 1.2rem' }}
				onClick={() => {
					setIsOpen(true);
					setAnimationModal(true);
				}}
			>
				Transferir
			</Button>
			<Modal
				isOpen={isOpen}
				onRequestClose={onClose}
				runAfterClosingAnimation={() => {
					queryClient.invalidateQueries(['beneficiaries', currentCompany?.id,])
				}}
				position="right"
				titleTheme="dark"
				closeBtnStyles={{ zIndex: 3 }}
				animateOnClose={animationModal}>
				<S.SliderContainer>
					<S.SliderHeaderContent>
						<Typography
							style={{ maxWidth: "50%", marginTop: "3.3rem"}}
							color={"white"}
							weight={"600"}
							size={"2.4rem"}>
							{beneficiary.name}
						</Typography>
						<Typography
							style={{marginTop: "auto"}}
							color={"white"}
							weight={"600"}
							size={"1.4rem"}>
							{
								beneficiary.type === 'pf' ? "CPF:" : "CNPJ:"
							}
						</Typography>
						<Typography color={"white"}>
							{
								beneficiary.type === 'pf' ? cpfMask(beneficiary.document) : cnpjMask(beneficiary.document)
							}
						</Typography>

					</S.SliderHeaderContent>
					<S.SliderBodyContent>
						<S.SliderSectionContent>
							<S.Title>Detalhes bancários:</S.Title>
							<div>
								<S.Row>
									<Typography>Banco:</Typography>
									<Typography>{beneficiary.bank_code}</Typography>
								</S.Row>
								<S.Row>
									<Typography>Agência:</Typography>
									<Typography>{beneficiary.branch}</Typography>
								</S.Row>
								<S.Row>
									<Typography>Conta:</Typography>
									<Typography>{beneficiary.account_number}</Typography>
								</S.Row>
								<S.Row>
									<Typography>Tipo de conta:</Typography>
									<Typography>
										{
											beneficiary.account_type === 'checking' ? 'Conta corrente' : 'Conta poupança'
										}
									</Typography>
								</S.Row>
							</div>
						</S.SliderSectionContent>
						<S.SliderSectionContent>
							<S.Title>Detalhes do favorecido:</S.Title>
							<div>
								<S.Row>
									<Typography>
										{
											beneficiary.type === 'pf' ? 'Nome completo:' : 'Razão social:'
										}
									</Typography>
									<Typography>{beneficiary.name}</Typography>
								</S.Row>
								<S.Row>
									<Typography>
										{
											beneficiary.type === 'pf' ? 'CPF:' : 'CNPJ:'
										}
									</Typography>
									<Typography>{
										beneficiary.type === 'pf' ? cpfMask(beneficiary.document) : cnpjMask(beneficiary.document)
									}</Typography>
								</S.Row>
							</div>
						</S.SliderSectionContent>
						<S.SliderButtonsContainer>
						  <NewTransferModal beneficiary={beneficiary} />
							<NewBeneficiaryModal beneficiary={beneficiary} onUpdate={(newBeneficiary) => {
								//exclude the id from the newBeneficiary object
								const { id, ...rest } = newBeneficiary;
								setBeneficiary({ ...beneficiary, ...rest });
 							}} />
							<DeleteModal beneficiary={beneficiary} onDeleteSuccess={() => setIsOpen(false)} />
						</S.SliderButtonsContainer>
					</S.SliderBodyContent>
				</S.SliderContainer>
			</Modal>
		</>
	);
}