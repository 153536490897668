import { InputHTMLAttributes } from 'react';
import { UseFormRegister } from 'react-hook-form';
import styled from 'styled-components';

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
	label?: string | JSX.Element;
	name?: string;
	labelWidth?: string;
	register?: UseFormRegister<any>;
}

export function CheckboxField({
	label,
	name,
	labelWidth,
	register,
	...props
}: CheckboxProps) {
	return (
		<Label style={{ width: labelWidth }}>
			{label}
			<Input
				type='checkbox'
				{...(register ? { ...register(name!) } : {})}
				name={name}
				{...props}
			/>
			<Checkmark />
		</Label>
	);
}

export function StaticCheckbox({ checked }: CheckboxProps) {
	return <StaticCheckmark checked={checked} />;
}

const Label = styled.label<CheckboxProps>`
	width: 50%;
	display: block;
	position: relative;
	padding-left: 28px;
	cursor: pointer;
	font-size: 1.5rem;
	font-weight: 500;
	user-select: none;

	&:hover input ~ span {
		background-color: var(--primary-blue);
	}

	&:hover input:disabled ~ span {
		background-color: var(--white);
		cursor: not-allowed;
	}
`;

const Input = styled.input`
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;

	&:checked ~ span {
		background-color: var(--primary-blue);
	}

	&:disabled ~ span {
		background-color: var(--white);
		border-color: var(--light-gray);
	}
`;

const Checkmark = styled.span`
	position: absolute;
	top: -4px;
	left: 0;
	height: 20px;
	width: 20px;
	border-radius: 3px;
	border-color: var(--primary-blue);
	border-width: 2px;
	border-style: solid;

	transition: all 0.2s;

	&:after {
		content: '';
		position: absolute;
		display: none;
		left: 4px;
		top: 0;
		width: 5px;
		height: 10px;
		border: solid white;
		border-width: 0 3px 3px 0;
		transform: rotate(45deg);
	}

	${Input}:checked ~ &::after {
		display: block;
	}
`;

const StaticCheckmark = styled.div<{ checked?: boolean }>`
	height: 15px;
	width: 15px;
	border-color: ${({ checked }) => (checked ? 'none' : '#575757')};
	border-width: ${({ checked }) => (checked ? '0px' : '2px')};
	border-style: solid;
	background: ${({ checked }) => (checked ? '#00A22D' : '#FFFFFF')};

	&:after {
		display: block;
		content: '';
		margin-left: 5px;
		width: 3px;
		height: 9px;
		border: ${({ checked }) => (checked ? 'solid white' : 'none')};
		border-width: 0 2px 2px 0;
		transform: rotate(45deg);
	}
`;
