import { Notification } from '../../contexts/NotificationsContext';
import api from '../api';

interface NotificationsListReturn {
	notificationsOperator: Notification[];
	totalNotifications: number;
	totalNotificationsUnread: number;
}

export async function getNotifications(
	origin: 'multiflex' | 'corpway',
	pageNumber?: number
): Promise<NotificationsListReturn> {
	const { data } = await api.get(`/api/v1/notifications/operators/q`, {
		params: {
			page: pageNumber,
			origin,
		},
	});
	const {
		notificationsOperator,
		totalNotifications,
		totalNotificationsUnread,
	} = data;
	return {
		notificationsOperator,
		totalNotifications,
		totalNotificationsUnread,
	};
}

export async function toggleNotificationStatus(
	notificationId: string,
	status: boolean
): Promise<void> {
	return await api.patch(`/api/v1/notifications/${notificationId}/status`, {
		status,
	});
}

export async function sendNotificationToCollaborator(notificationId: string) {
	return await api.post(`/api/v1/rh/notification/send/${notificationId}`);
}

export async function sendCloseChatNotificationToCollaborator(
	notificationId: string
) {
	return await api.post(
		`/api/v1/rh/notification/send/close_chat/${notificationId}`
	);
}

export async function readAllNotifications(): Promise<void> {
	return await api.put(`/api/v1/notifications/all/status`, { status: true });
}

export async function sendNotificationChatDelegation(
	company_id: string,
	new_operator_email: string,
	old_operator_email?: string
) {
	return await api.post('/api/v1/rh/notification/delegate_chat', {
		company_id,
		old_operator_email,
		new_operator_email,
	});
}
