import { NotificationPreferenceType } from '../../../../../@types/CorporateExpenses/Preferences';

export const notificationPreferenteTypeParsed: Record<
	NotificationPreferenceType,
	string
> = {
	email: 'E-mail',
	notification: 'Plataforma',
	both: 'Ambos',
	none: 'Nenhum',
};
