import styled from 'styled-components';

export const Container = styled.div`
	flex: 1;
	padding: 4rem 7rem 2rem 7rem;
`;
export const OptionsContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

export const TotalValuesContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	margin-top: -2rem;
	margin-bottom: 2rem;
	row-gap: 1rem;
`;

export const TotalValue = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	font-size: 1.5rem;
`;
export const TotalValueLabel = styled.span`
	font-size: 1.7rem;
`;
export const TotalValueNumber = styled.h3`
	font-size: 1.9rem;
`;

export const ReportContainer = styled.div`
	display: flex;
	column-gap: 2rem;
	margin-bottom: 2rem;
`;
