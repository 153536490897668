import styled from 'styled-components';

interface StatusColor {
	background: string;
	text: string;
	dot: string;
}

const statusColors: { [key: string]: StatusColor } = {
	'Cobrança Parcial': {
		background: '#D4EFFF',
		text: '#27ADFF',
		dot: '#27ADFF',
	},
	Debitada: {
		background: '#ECFDF3',
		text: '#00A22D',
		dot: '#00A22D',
	},
	Creditada: {
		background: '#ECFDF3',
		text: '#00A22D',
		dot: '#00A22D',
	},
	Cancelada: {
		background: '#FFEBF0',
		text: '#FF0037',
		dot: '#FF0037',
	},
	Processando: {
		background: '#FFF6C7',
		text: '#FEBE14',
		dot: '#FEBE14',
	},
	default: {
		background: '#ffffff',
		text: '#000000',
		dot: '#000000',
	},
};

const StatusComponent = styled.div<{ statusColor: StatusColor }>`
	display: flex;
	align-items: center;
	padding: 0rem 0.8rem;
	border-radius: 50px;
	background-color: ${(props) => props.statusColor.background};
	color: ${(props) => props.statusColor.text};
	font-size: 1.4rem;
	font-weight: 500;
	width: max-content;
`;

const Dot = styled.div<{ statusColor: StatusColor }>`
	width: 10px;
	height: 10px;
	border-radius: 50%;
	margin-right: 10px;
	background-color: ${(props) => props.statusColor.dot};
`;

export function parseTransactionStatusToVisualComponent(status: string) {
	const statusColor = statusColors[status] || statusColors.default;

	return (
		<StatusComponent statusColor={statusColor}>
			<Dot statusColor={statusColor} />
			<p>{status}</p>
		</StatusComponent>
	);
}
