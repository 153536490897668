import { toast } from 'react-toastify';
import * as S from './styles';

function showCancelableToast(
	message: string,
	submitCallback: () => Promise<void>
) {
	toast.dismiss();
	let canceledSubmition = false;
	let closedToast = false;

	toast.info(
		({ closeToast }) => {
			return (
				<S.NotificationContainer>
					{message}
					<S.CancelSubmitionButton
						onClick={() => {
							canceledSubmition = true;
							closedToast = true;
							closeToast!();
						}}
					>
						Desfazer
					</S.CancelSubmitionButton>
				</S.NotificationContainer>
			);
		},
		{
			onClose: () => {
				closedToast = true;
				!canceledSubmition && submitCallback();
			}, // onClose, if the submition wasn't canceled then submit
			closeOnClick: false,
			autoClose: 5000,
		}
	);

	return new Promise((resolve, reject) => {
		let timer = setInterval(() => {
			// When the toast has disappeared, return FALSE if the submition has been canceled and TRUE if not
			if (closedToast && !canceledSubmition) {
				clearInterval(timer);
				resolve(true);
			} else if (closedToast && canceledSubmition) {
				clearInterval(timer);
				resolve(false);
			}
		}, 5);
	});
}

export { showCancelableToast };
