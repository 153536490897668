import { Prompt, useHistory } from 'react-router';
import cuid from 'cuid';
import React, { useEffect, useState } from 'react';
import Modal from '../Modal';
import * as S from './styles';

interface PreventTransitionPromptProps {
	title?: string;
	message?: string;
}

// Define a unique global symbol to store
// a dialog trigger reference accessible via
// a string key. Use cuid for unique ID.
const __trigger = Symbol.for(`__PreventTransitionPrompt_${cuid()}`);

function PreventTransitionPrompt({
	title,
	message,
}: PreventTransitionPromptProps) {
	const history = useHistory();
	const [open, setOpen] = useState(false);
	const [allowNavigation, setAllowNavigation] = useState(false);
	const [intendedPath, setIntendedPath] = useState('');

	useEffect(() => {
		(window as any)[__trigger] = show;
		// window[__trigger] = show

		return () => {
			delete (window as any)[__trigger];
			// delete window[__trigger]
		};
	}, []);

	useEffect(() => {
		if (allowNavigation) {
			setOpen(false);
			history.push(intendedPath);
		}
		//  eslint-disable-next-line
	}, [allowNavigation]);

	const show = (allowTransitionCallback: (ok: boolean) => void) => {
		// we are immediately preventing any transitions here
		// but could just as easily base this off a user interaction
		// or other state
		setOpen(true);
		allowTransitionCallback(false);
		// this.setState({ open: true }, () => allowTransitionCallback(false))
	};

	const handleClose = () => {
		setOpen(false);
	};

	/**
	 * Handles the Router transition. Returns true if allowed
	 * or the dialog trigger key to enable the dialog.
	 *
	 * This would be a good candidate to allow optionally
	 * being passed as a callback prop to let
	 * caller decide if transition is allowed.
	 */
	function handleTransition(location: any) {
		// example: allow transitions to /two
		// if (location.pathname === "/two") {
		// return true
		// }
		setIntendedPath(location.pathname);

		return Symbol.keyFor(__trigger)!!;
	}

	return (
		<React.Fragment>
			{/* React Router prompt, callback will return true to allow transition or dialog key to prevent */}
			<Prompt when={!allowNavigation} message={handleTransition} />

			<Modal isOpen={open} enableClose={true} onRequestClose={handleClose}>
				<S.Container>
					<S.Title>{title ?? 'Confirmar navegação'}</S.Title>
					<S.Message>
						{message ??
							'As modificações serão perdidas. Tem certeza que deseja sair?'}
					</S.Message>

					<S.ActionsContainer>
						<S.MainAction onClick={() => setAllowNavigation(true)} autoFocus>
							Sim
						</S.MainAction>
						<S.SecondaryAction onClick={handleClose}>
							Cancelar
						</S.SecondaryAction>
					</S.ActionsContainer>
				</S.Container>
			</Modal>
		</React.Fragment>
	);
}

export default PreventTransitionPrompt;
