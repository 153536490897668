import styled from 'styled-components';

export const Container = styled.div`
	flex: 1;
	padding: 4rem 7rem 2rem 7rem;
	margin-bottom: 5rem;
`;

export const PaymentDate = styled.span`
	display: flex;
	flex-direction: column;
	font-weight: 500;
	& span {
		font-size: 1.2rem;
		color: var(--light-gray);
	}
`;

export const StatusIconContainer = styled.div`
	width: 2rem;
	height: 2rem;
	border-radius: 50%;
	margin-right: 1rem;

	& svg {
		width: 100%;
		height: 100%;
	}
`;

export const OptionsContainer = styled.div`
	display: flex;
	/* align-items: center; */
	width: fit-content;
	column-gap: 1rem;
	/* margin: 0 auto; */
	/* justify-content: space-between; */
`;

export const ScheduledDate = styled.span`
	font-size: 1rem;
`;
