import styled from 'styled-components';
import corpwayExtractHeader from '../../../../../assets/corpway-beneficiary-modal-header.png';
import { InputField } from '../../../../../componentsV2/ui/Form/InputField';

export const TypePartner = styled.div`
    height: 43.9rem;
    width: 28.6rem;
    border-radius: 8px;
    border: 2px solid #F4F4F4;
    background: #FFF;
    flex-shrink: 0;

    & img {
        height: 50%;
    }
`;

export const IconContainer = styled.div`
    display: flex;
    align-items: center;
    align-self: stretch;
    justify-content: center;
    height: 17.1rem;
    background: #E5EAF2;
`;
export const ModalIconContainer = styled.div`
    width: 48px;
    height: 48px;
    flex-shrink: 0;
    border-radius: 48px;
    background: #27ADFF;
    align-items: center;
    display: flex;
    justify-content: center;
`;

export const Description = styled.p`
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    text-align: center;

    .bold {
        font-weight: 600;
    }
`;

export const PartnerInformation = styled.div`
    height: 26.8rem;
    padding: 3.2rem 2.4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2.4rem;
    color: var(--dark-gray);

    & h1 {
        font-size: 2rem;
    }

    & p {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        height: 7rem;
        min-height: 7rem;
    }

    & > div {
        border-top: 2px solid #f0f3f8;
    }
`;

export const ModalHeader = styled.div`
    display: flex;
    padding: 24px 32px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-bottom: 1px solid #F4F4F4;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;

    @media (max-width: 800px) {
        padding: 24px 24px;
    }
`;

export const Step1Container = styled.div`
    overflow-x: auto;
    overflow-y: hidden;
    display: flex;
    flex-direction: row;
    gap: 1.6rem;
    padding: 3.2rem;
`;


export const GeneralContainer = styled.div`
    width: 47rem;
    padding: 3.2rem;
    gap: 3.2rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 800px) {
        width: 100%;
        overflow: hidden;
    }

`;

export const FormContainer = styled.div`
    padding-left: 2.4rem;
    padding-right: 2.4rem;
    width: 100%;
    display: flex;
    gap: 2.4rem;
    flex-direction: column;

    @media (max-width: 800px) {
        padding-left: 0;
        padding-right: 0;
    }
`;

export const Title = styled.span`
    color: #393939;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    width: 100%;
`;


export const ButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 1.6rem;
    flex-direction: row;
    width: 100%;
    justify-content: center;
`;

export const Row = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2.4rem;
    width: 100%;
`;


export const InputContainerRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 2.4rem;
    width: 100%;

    @media (max-width: 800px) {
        flex-direction: column;
    }
`;

export const InputBranchContainer = styled.div`
    width: 40%;
		max-width: 40%;

    @media (max-width: 800px) {
        width: 100%;
				max-width: 100%;
    }
`;

export const RowInformation = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    width: 100%;

    & > div {
        & > p {
            font-size: 1.2rem;
        }
    }
`;

export const MonthSelector = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    height: 4rem;
    border-radius: 4px;
    gap: 1rem;
    padding: 8px 0 8px 0;
    min-height: 4.8rem;


    position: relative;

    & > svg {
        position: absolute;
        width: 4rem;
        color: var(--primary-blue);
        top: 1.2rem;
        left: 0.6rem;
        right: 0;
        z-index: 2;
    }

    & input[type='date'] {
        width: 100%;
        position: relative;
        font-family: 'Poppins', sans-serif;
        padding-left: 5rem;
    }

    & input[type='date']::-webkit-calendar-picker-indicator {
        position: absolute;
        top: 1rem;
        left: 1rem;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: 4;
        filter: invert(0.5);
        opacity: 0;
    }
`;

export const BorderContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.6rem;
    flex-shrink: 0;
    border-radius: 8px;
    padding: 2.4rem;
    border: 1px solid #27ADFF;
    width: 100%;
`;

export const SliderContainer = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 1rem 0 0 1rem;
    width: 38rem;
    height: 100vh;
    font-size: 1.6rem;
    font-weight: 600;
    min-height: 50rem;

    @media (max-width: 800px) {
        width: 35rem;
    }
`;


export const SliderHeaderContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    min-height: 35%;
    width: 100%;
    padding: 24px;
    background: url(${corpwayExtractHeader}) no-repeat center/cover;

    @media (max-width: 550px) {
        background: url(${corpwayExtractHeader}) no-repeat 80% 50% / cover;
    }

    @media (max-width: 1050px) {
        background: url(${corpwayExtractHeader}) no-repeat 85% 50% / cover;
    }
`;

export const SliderBodyContent = styled.div`
    display: flex;
    padding: 3.2rem 2.4rem;
    flex-direction: column;
    align-items: center;
    gap: 3.2rem;
    height: 100%;
`;

export const SliderSectionContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.6rem;
    align-self: stretch;

    & > div {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 0.8rem;
    }
`;

export const SliderButtonsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    gap: 2.4rem;
    height: 100%;
    align-self: stretch;
`;

export const CustomButton = styled.button`
    color: #27ADFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%; /* 22.4px */
    text-decoration-line: underline;
    background: white;
		text-align: center;
		width: 100%;
`;

export const TokenInput = styled.div`
    width: 100%;
    display: flex;
    gap: 0.8rem;
    flex-direction: row;

    & > input {
        z-index: 0;
        position: absolute;
        width: 75%;
        height: 10rem;
        opacity: 0;
        font-size: 5rem; 
        letter-spacing: 3rem;
				padding-left: 1rem;
    }

    & > div {
        z-index: 1;
        display: flex;
        padding: 8px 16px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 24px;
        flex: 1 0 0;
        align-self: stretch;
        pointer-events: none;
        border-radius: 4px;
        background: #F0F3F8;
        width: 2.2rem;
        height: 10rem;

        color: #575757;


        font-size: 3.6rem;
        font-style: normal;
        font-weight: 600;
        line-height: 2.4rem
    }

    .focus {
        border: 2px solid #27ADFF;
    }
`;

export const InputFieldWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;


    @media (max-width: 800px) {
        width: 90%;
        align-self: flex-start;
    }

    & > div {
        width: 10rem;
        position: absolute;
        top: 3.5rem;
        left: calc(100% - 11rem);
        cursor: pointer;
        transition: filter 0.2s;


        @media (max-width: 800px) {
            top: 5rem;
            left: calc(100% + 1rem);
        }
    }
`;


export const DescriptionLabel = styled.span`
    color: #575757;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    text-align: center;
    line-height: 160%; /* 22.4px */
    text-decoration-line: underline;
`;


export const StyledInput = styled(InputField)`
    padding: 0 1.6rem;
`;