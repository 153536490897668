import { Link } from 'react-router-dom';
import styled from 'styled-components';

interface MessageStatusProps {
	sending: boolean;
}

export const Container = styled.div`
	flex: 1;
	padding: 4rem 7rem 2rem 7rem;
`;

export const FormContainer = styled.div`
	display: flex;
	width: 100%;
`;

export const FieldSet = styled.fieldset`
	display: flex;
	border: none;
`;

export const FormMessage = styled.form`
	width: 100%;
	min-height: fit-content;
	padding: 1.5rem 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border-radius: 0 0 0.8rem 0.8rem;
	background-color: var(--primary-blue);
`;

export const InputMessage = styled.textarea`
	resize: none;
	height: 6rem;
	width: 80%;
	border-radius: 0.8rem;
	border: none;
	padding: 1rem;
	outline: none;
	font-family: 'Ubuntu', sans-serif;
`;

export const InputAttachments = styled.input`
	display: none;
`;

export const AttachmentsLabel = styled.label`
	display: flex;
	align-items: center;
	background-color: var(--primary-blue);
	color: white;
	padding: 1rem;
	border-radius: 50%;
	cursor: pointer;

	width: 6rem;
	height: 6rem;
	> svg {
		width: 3.5rem;
		height: 3.5rem;
	}

	&:hover {
		filter: brightness(0.9);
	}
`;
export const SendMessageButton = styled.button`
	display: flex;
	align-items: center;
	background-color: var(--primary-blue);
	color: white;
	padding: 1rem;
	border-radius: 50%;
	cursor: pointer;

	> svg {
		width: 4rem;
		height: 4rem;
	}
`;

export const ChatContainer = styled.div`
	position: relative;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 1.5rem;
	background-color: #eff7fc;
	border-radius: 0.8rem;
`;

export const MessagesContainer = styled.div`
	width: 100%;
	height: 60vh;
	display: flex;
	flex-direction: column-reverse;
	overflow-y: scroll;
	padding-right: 15px;
	padding-top: 1rem;
	padding: 1rem 2rem;
	&::-webkit-scrollbar {
		width: 1.2rem;
	}

	&::-webkit-scrollbar-track {
		border-radius: 1rem;
		background-color: #e3e8eb;
	}

	&::-webkit-scrollbar-thumb {
		background-color: var(--light-gray);
		border-radius: 1rem;
	}
`;

export const ImageMessage = styled.img`
	max-height: 160px;
	max-width: 300px;
	border-radius: 0.8rem;
	cursor: pointer;
`;

export const TextContainer = styled.div`
	color: white;
	max-width: 50vw;
	padding: 1rem;

	display: flex;
	align-items: center;
	column-gap: 10px;
`;

export const MessageText = styled.p`
	max-width: 94.3%;
	font-size: 1.5rem;
	overflow-wrap: break-word;
	white-space: pre-wrap;
	word-wrap: break-word;
`;
export const MessageTimeSent = styled.span`
	align-self: flex-end;
	font-size: 1.1rem;
`;

export const Avatar = styled.img`
	width: 4rem;
	height: 4rem;
	border-radius: 50%;
	margin-right: 1rem;
	margin-left: 1rem;
	border: 0.2rem solid var(--primary-blue);
	object-fit: cover;
`;

export const Message = styled.div<MessageStatusProps>`
	display: flex;
	align-self: ${({ sending }) => (sending ? 'flex-end' : 'flex-start')};
	& + & {
		margin-bottom: 1rem;
	}

	flex-direction: ${({ sending }) => (sending ? 'row-reverse' : 'row')};
	> div {
		background-color: ${({ sending }) =>
			sending ? 'var(--light-gray)' : 'var(--primary-blue)'};
		border-radius: ${({ sending }) =>
			sending ? '0.8rem 0 0.8rem 0.8rem' : '0px 0.8rem 0.8rem 0.8rem'};
	}
`;

export const AutomatedMessage = styled.div`
	display: flex;
	align-self: center;
	margin-bottom: 1rem;
	margin-top: 1rem;

	background-color: var(--light-gray);
	border-radius: 0.8rem;
`;
export const HeaderButtons = styled.div`
	display: flex;
	justify-content: space-between;
`;
export const NavButton = styled(Link)`
	text-decoration: none;
	display: flex;
	align-items: center;
	font-size: 1.8rem;
	margin-top: 1rem;
	color: var(--primary-blue);
	cursor: pointer;

	> svg {
		width: 1.8rem;
		height: 1.8rem;
	}

	&:hover {
		filter: brightness(0.9);
	}
`;

export const CloseChatButton = styled.div`
	display: flex;
	align-items: center;
	font-size: 1.8rem;
	margin-top: 1rem;
	color: var(--primary-blue);
	cursor: pointer;

	> svg {
		width: 1.8rem;
		height: 1.8rem;
	}

	&:hover {
		filter: brightness(0.9);
	}
`;

export const ChatHeader = styled.div`
	width: 100%;
	height: 10rem;
	background-color: var(--primary-blue);
	display: flex;
	padding: 2rem 2rem;
	border-radius: 0.8rem 0.8rem 0 0;
`;
export const CollabInfoContainer = styled.div`
	font-size: 2rem;
	color: white;
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

export const AvatarHeaderContainer = styled.div`
	display: flex;
	align-items: center;

	> img {
		width: 5rem;
		height: 5rem;
	}
`;

export const StatusContainer = styled.div`
	display: flex;
	align-items: center;
	margin-left: auto;
	font-size: 1.4rem;
	color: --dark-gray;
`;
export const OnlineIcon = styled.div`
	width: 15px;
	height: 15px;
	background-color: #00ff00;
	border-radius: 100%;
	margin-left: 1rem;
`;

export const OfflineIcon = styled.div`
	width: 15px;
	height: 15px;
	background-color: red;
	border-radius: 100%;
	margin-left: 1rem;
`;

export const StatusFlag = styled.div`
	display: flex;
	padding: 1rem;
	justify-content: space-around;
	background-color: white;
	border-radius: 0.8rem;

	> p {
		font-weight: bold;
	}
`;

export const CollabDetail = styled.p`
	font-size: 1.4rem;
`;

export const DifferentDaySeparator = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
	margin: 1rem 0 0.5rem 0;
	color: white;
	font-size: 1.3rem;
	> p {
		padding: 0.6rem;
		border-radius: 10px;
		background-color: var(--primary-blue);
	}
`;

export const DragContainer = styled.div`
	display: flex;
	position: absolute;
	align-items: center;
	justify-content: center;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	border-radius: 0.8rem;

	border: 10px solid #27adff;
	background-color: #fff;
	opacity: 0.5;
	padding: 20px;
	color: #27adff;

	font-size: 4rem;
`;

export const ImagePreview = styled.img`
	height: 6rem;
	width: 6rem;
	border-radius: 0.8rem;
	object-fit: contain;
`;

export const InputsContainer = styled.div`
	display: flex;
	width: 100%;
	flex-direction: row;

	align-items: center;
	justify-content: space-evenly;
`;

export const ImgsContainer = styled.div`
	padding: 0 1.5rem;
	display: flex;
	width: 100%;
	flex-direction: row;

	align-items: center;
	margin-bottom: 1rem;
`;

export const AttachmentIcon = styled.div`
	height: 6rem;
	width: 6rem;
	background-color: var(--primary-blue);
	border-radius: 0.8rem;
	filter: brightness(0.9);
	display: flex;
	align-items: center;
	justify-content: center;

	& ~ & {
		margin-left: 1rem;
	}

	> svg {
		width: 4rem;
		height: 4rem;
	}
`;

export const CloseIcon = styled.div`
	width: 2.5rem;
	height: 2.5rem;
	background-color: white;
	border-radius: 50px;
	position: absolute;

	right: -5px;
	top: -5px;
	> svg {
		color: var(--primary-red);
		width: 2.5rem;
		height: 2.5rem;
	}

	&:hover {
		filter: brightness(0.9);
	}
`;
