import logoImg from '../../assets/Bounty.svg';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import Loader from '../../components/Loader';
import { useParams } from 'react-router-dom';
import * as S from './styles';
import { useMutation } from 'react-query';
import { ChangePasswordSuccess } from './ChangePasswordSuccess';
import { changePassword } from '../../services/queries/Collaborators';
import { PasswordInput } from '../../components/PasswordInput';
import { showErrorMessage } from '../../utils/ErrorHandler';

type FormData = {
	password: string;
	password_confirmation: string;
};

export function ChangeCollaboratorPassword() {
	const { register, handleSubmit } = useForm<FormData>();
	const { token } = useParams<{ token: string }>();

	const changePasswordQuery = useMutation(
		(password: string) => {
			return changePassword(password, token);
		},
		{
			onSuccess: () => {
				toast.info('Senha atualizada com sucesso');
			},
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao tentar atualizar a senha. '
				);
			},
		}
	);

	async function handleChangePassword({
		password,
		password_confirmation,
	}: FormData) {
		if (password !== password_confirmation) {
			toast.error('As senhas precisam ser iguais');
			return;
		}
		changePasswordQuery.mutateAsync(password);
	}

	if (changePasswordQuery.isSuccess) return <ChangePasswordSuccess />;

	return (
		<S.Container>
			<S.LogoImage src={logoImg} alt='Logo do Bounty' />
			<S.Form onSubmit={handleSubmit(handleChangePassword)} autoComplete='off'>
				<S.Title>Redefinir senha - Colaborador</S.Title>

				<S.ContentForm>
					<PasswordInput
						style={{ height: '3.6rem' }}
						placeholder='Nova senha'
						name='password'
						register={register}
						minLength={6}
						required
					/>
					<PasswordInput
						style={{ height: '3.6rem' }}
						placeholder='Confirme a senha'
						name='password_confirmation'
						register={register}
						minLength={6}
						required
					/>
				</S.ContentForm>

				<S.SubmitButton disabled={changePasswordQuery.isLoading} type='submit'>
					{!changePasswordQuery.isLoading ? (
						'ENVIAR'
					) : (
						<Loader color='#fff' size={20} />
					)}
				</S.SubmitButton>
			</S.Form>
		</S.Container>
	);
}
