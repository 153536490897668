import { useAuth } from '../../../../../hooks/useAuth';
import * as Excel from 'exceljs';
import { showErrorMessage } from '../../../../../utils/ErrorHandler';
import { useMutation } from 'react-query';
import {
	MovementsReportProps,
	getBalanceMovementsToReport,
} from '../../../../../services/queries/Corpway/Funds';
import { cnpjMask } from '../../../../../utils/masks';
import {
	convertCentsToFormattedReais,
	convertCentsToPlainReais,
} from '../../../../../utils/CurrencyConvert';
import {
	parseMovementDetailsToTextOnly,
	parseMovementStatusToTextOnly,
	parseMovementType,
} from '../../utils/parseBalanceMovements';
import { parseCurrencyStrToNumber } from '../../../../../utils/parseCurrency';

export function SpreadsheetReportGenerator() {
	const { user, currentCompany } = useAuth();

	const getBalanceMovementsQuery = useMutation(
		({
			start_date,
			end_date,
			operationTypes,
			operationStatuses,
			fundsInOrigin,
		}: MovementsReportProps) =>
			getBalanceMovementsToReport(
				currentCompany!.id,
				start_date,
				end_date,
				operationTypes,
				operationStatuses,
				fundsInOrigin
			)
	);

	async function handleGenerateXLSX({
		start_date,
		end_date,
		operationTypes,
		operationStatuses,
		fundsInOrigin,
	}: MovementsReportProps) {
		try {
			let blob: Blob;
			blob = await generateSheetContent(
				start_date,
				end_date,
				operationTypes,
				operationStatuses,
				fundsInOrigin
			);

			const startDate = new Date(start_date);
			const endDate = new Date(end_date);

			// Create a temporary URL for the blob
			const url = URL.createObjectURL(blob);
			const link = document.createElement('a');
			link.href = url;
			link.download = `Bounty_Control_Gestao_${startDate.toLocaleDateString(
				'pt-BR'
			)}_${endDate.toLocaleDateString('pt-BR')}.xlsx`;

			// Simulate a click event to trigger the download
			link.dispatchEvent(new MouseEvent('click'));

			// Clean up the temporary URL
			URL.revokeObjectURL(url);
		} catch (error: any) {
			if (error.message === 'empty_movements') {
				throw error;
			}

			showErrorMessage(
				error as Error,
				'Ocorreu um erro ao tentar gerar o arquivo de relatório. Tente novamente'
			);
		}
	}

	async function generateSheetContent(
		start_date: number,
		end_date: number,
		operationTypes: MovementsReportProps['operationTypes'],
		operationStatuses: MovementsReportProps['operationStatuses'],
		fundsInOrigin?: MovementsReportProps['fundsInOrigin']
	) {
		const workbook = new Excel.Workbook();

		const worksheet = workbook.addWorksheet(
			'Relatório de movimentações da carteira'
		);

		const movements = await getBalanceMovementsQuery.mutateAsync({
			start_date,
			end_date,
			operationTypes,
			operationStatuses,
			fundsInOrigin,
		});

		if (movements.balanceMovements.length === 0) {
			throw new Error('empty_movements');
		}

		const currentTime = new Date();
		worksheet.addRow([
			`Gerado em ${currentTime.toLocaleDateString()} às ${currentTime.toLocaleTimeString(
				'pt-BR',
				{
					timeZone: 'America/Sao_Paulo',
					hour: '2-digit',
					minute: '2-digit',
				}
			)} no Bounty Control.`,
		]);
		worksheet.addRow([`Usuário: ${user.email}.`]);
		worksheet.addRow([
			`Intervalo de busca: ${Intl.DateTimeFormat('pt-BR', {
				day: '2-digit',
				month: '2-digit',
				year: 'numeric',
			}).format(new Date(start_date))} - ${Intl.DateTimeFormat('pt-BR', {
				day: '2-digit',
				month: '2-digit',
				year: 'numeric',
			}).format(new Date(end_date))}`,
		]);
		worksheet.addRow([`N° de pedido: ${movements.totalReports}`]);

		worksheet.addRow([]);
		worksheet.addRow([`Empresa: ${currentCompany!.corporateName}`]);
		worksheet.addRow([`CNPJ: ${cnpjMask(currentCompany!.cnpj)}`]);
		worksheet.addRows([[], []]); //Space for total value that is added afterwards

		let columns = [
			'DATA',
			'USUÁRIO',
			'OPERAÇÃO',
			'DETALHES DA OPERAÇÃO',
			'STATUS',
			'VALOR',
		];

		// list headers
		worksheet.addRow(columns);

		const columnsIndexes = [1, 2, 3, 4, 5, 6];

		columnsIndexes.forEach((colIndex) => {
			const col = worksheet.getColumn(colIndex);
			col.width = 25;
		});

		worksheet.getColumn(6).numFmt = '[$R$ ]#,##0.00'; // Valor column formatting

		const totalValues = {
			funds_in: 0,
			wallet_refund: 0,
			card_funds: 0,
			funds_out: 0,
		};

		movements.balanceMovements.forEach((movement) => {
			if (movement.type === 'billing' || movement.type === 'pix')
				totalValues.funds_in += Number(movement.amount);
			if (movement.type === 'card_fund') {
				if (movement.action === 'add')
					totalValues.card_funds += Number(movement.amount);
				if (movement.action === 'reverse')
					totalValues.wallet_refund += Number(movement.amount);
			}
			if (movement.type === 'funds_out') totalValues.funds_out += Number(movement.amount);

			const row = [
				new Date(movement.created_at).toLocaleString(),
				movement.operator?.name,
				parseMovementType(movement.type, movement.action),
				parseMovementDetailsToTextOnly(movement),
				parseMovementStatusToTextOnly(movement.status!),
				parseCurrencyStrToNumber(convertCentsToPlainReais(movement.amount)),
			];
			worksheet.addRow(row);
		});

		let rowToBeInserted = 9;
		if (operationTypes.funds_in) {
			worksheet.insertRow(rowToBeInserted, [
				`Total de Recarga de Carteira: ${convertCentsToFormattedReais(
					totalValues.funds_in
				)}`,
			]);
			rowToBeInserted++;
		}

		if (operationTypes.card_funds) {
			worksheet.insertRow(rowToBeInserted, [
				`Total de Repasse para Cartão: ${convertCentsToFormattedReais(
					totalValues.card_funds
				)}`,
			]);
			rowToBeInserted++;
		}

		if (operationTypes.wallet_refund) {
			worksheet.insertRow(rowToBeInserted, [
				`Total de Estorno para Carteira: ${convertCentsToFormattedReais(
					totalValues.wallet_refund
				)}`,
			]);
			rowToBeInserted++;
		}

		if (operationTypes.funds_out) {
			worksheet.insertRow(rowToBeInserted, [
				`Total de Transferências Externas: ${convertCentsToFormattedReais(totalValues.funds_out)}`,
			]);
			rowToBeInserted++;
		}

		worksheet.insertRow(rowToBeInserted, [
			`Total de Transações: ${movements.totalMovements}`,
		]);

		const buffer = await workbook.xlsx.writeBuffer();
		const fileType =
			'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

		const blob = new Blob([buffer], { type: fileType });

		return blob;
	}

	return {
		generateSpreadsheet: handleGenerateXLSX,
	};
}
