import { create } from 'zustand';
import { CostCenter } from '../../@types/CorporateExpenses/CostCenter';
import { Card } from '../../@types/CorporateExpenses/Card';

export type CardListStatus = 'Ativos' | 'Bloqueados' | 'Cancelados';

export const CARD_STATUS_MAPPING = {
	active: 'Ativos',
	blocked: 'Bloqueados',
	canceled: 'Cancelados',
} as const;

type Store = {
	selectedCard: Card | null;
	activeCardsNumber: number | null;
	costCenterFilter: CostCenter | null;
	selectedTabFilter: CardListStatus;
};

type Actions = {
	setState: (p: Partial<Store>) => void;
	reset: () => void;
	// selectCostCenterFilter: (costCenter: CostCenter | null) => void
};

const initialState: Store = {
	selectedCard: null,
	activeCardsNumber: null,
	costCenterFilter: null,
	selectedTabFilter: 'Ativos',
};

const useCardSelectStore = create<Store & Actions>()((set, get) => ({
	...initialState,
	setState: (p) => set(p),
	reset: () => set(initialState),
}));

export { useCardSelectStore };
