import { Text, View } from '@react-pdf/renderer';

interface StatusColor {
	background: string;
	text: string;
	dot: string;
}

const statusColors: { [key: string]: StatusColor } = {
	pending: { background: '#FFFFFF', text: '#364254', dot: '#6C778B' },
	approved: { background: '#defdea', text: '#037847', dot: '#14BA6D' },
	canceled: { background: '#ffd3d3', text: '#b30000', dot: '#fd2929' },
};

export function parseRefundStatusToReport(status: string, fontSize = 9) {
	const statusText = {
		pending: 'Pendente',
		approved: 'Aprovado',
		canceled: 'Cancelado',
	};

	const statusColor = statusColors[status] || statusColors.default;

	return (
		<View
			style={{
				display: 'flex',
				flexDirection: 'row',
				columnGap: 1,
				alignItems: 'center',
				paddingVertical: '1',
				paddingHorizontal: '3',
				borderRadius: '50px',
				width: 'auto',
				backgroundColor: statusColor.background,
				color: statusColor.text,
			}}
		>
			<View
				style={{
					width: '6px',
					height: '6px',
					borderRadius: '50%',
					marginRight: '3px',
					backgroundColor: statusColor.dot,
				}}
			/>
			<Text
				style={{
					fontFamily: 'Poppins',
					fontSize,
				}}
			>
				{statusText[status as keyof typeof statusText]}
			</Text>
		</View>
	);
}
