import axios from 'axios';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { ReactNode, createContext, useEffect, useState } from 'react';
import {
	UseQueryResult,
	useMutation,
	useQuery,
	useQueryClient,
} from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from '../services/api';
import { firebaseAuth } from '../services/firebase';
import { getCompanyBalance } from '../services/queries/Companies';
import { getCorpwayBalances } from '../services/queries/Corpway/Balance';
import { getOperatorsAccessData } from '../services/queries/Operators';
import {
	deleteToken,
	refreshToken,
	session,
} from '../services/queries/Session';
import { socket } from '../services/socket';
import { getMessageError } from '../utils/DefaultErrors';
import { showErrorMessage } from '../utils/ErrorHandler';
import { Notification } from './NotificationsContext';
import {
	CorpwayUserAccessLevel,
	CorpwayUserRoles,
} from '../@types/CorporateExpenses/User';

type Props = {
	children: ReactNode;
};

export type User = {
	id: string;
	name: string;
	email: string;
	access_level: string;
	status_password: boolean;
	office?: string;
	avatar_url?: string;
	flexible_benefits_access: boolean;
	corporate_expenses_access: boolean;
	corpway_access_level: CorpwayUserAccessLevel;
	roles: CorpwayUserRoles[];
	term_of_use_accepted_corpway: boolean;
	privacy_policy_accepted_corpway: boolean;
	term_of_use_accepted_multiflex: boolean;
	privacy_policy_accepted_multiflex: boolean;
};
export type Company = {
	id: string;
	corporateName: string;
	image: string;
	avatar_url: string;
	name: string;
	// office?: string;
	email_domain?: string;
	cnpj: string;
	address: string;
	district: string;
	number: string;
	uf: string;
	city: string;
	cep: string;
	website: string;
	first_phone: string;
	second_phone: string;
	company_products: {
		product_corporate_expenses_enabled: boolean;
		product_flexible_benefits_enabled: boolean;
	};
	financial_sector_email: string;
};

type AuthState = {
	user: User;
	companies: Company[];
	termsNotifications: Notification[];
	currentCompany: Company | null;
};

export type LoginResult = {
	concludedLogin: boolean;
	message?: string;
	super_admin?: boolean;
	termsNotifications?: Notification[];
};

type SignInCredentials = {
	email: string;
	password: string;
};

export type AuthContextData = {
	user: User;
	currentCompany: Company | null;
	companies: Company[];
	termsNotifications: Notification[];
	changeCurrentCompany: (id: string) => void;
	signIn(credentials: SignInCredentials): Promise<LoginResult>;
	signOut(): void;
	updateUserInfo(user: User): void;
	setNewTermNotificationsNotRead(termsNotifications: Notification[]): void;
	companyBalance: {
		multiflex_balance: number;
		corpway_balance: number;
		corpway_cards_balance: number;
		corpway_account_balance: number;
	};
	updateCompanyBalance: UseQueryResult;
	firebaseAuthConcluded: boolean;
	currentProduct: 'corpway' | 'multiflex' | null;
};

export const AuthContext = createContext({} as AuthContextData);

export function AuthProvider({ children }: Props) {
	const queryClient = useQueryClient();
	const history = useHistory();
	const [firebaseAuthConcluded, setFirebaseAuthConcluded] = useState(() => {
		const firebaseAuth = localStorage.getItem('@Firebase:auth');
		return firebaseAuth === 'true';
	});
	const token = localStorage.getItem('@Bounty:token');

	const [data, setData] = useState(() => {
		return {} as AuthState;
	});

	const clearToken = useMutation(deleteToken);
	const refreshTokenQuery = useMutation((refreshTokenParam: string) =>
		refreshToken(refreshTokenParam)
	);

	// already loggedin user (set the tokens interceptor)
	useEffect(() => {
		if (token) {
			setInterceptorResponseOnApi(token);
			userDataQuery.refetch();
		}
	}, []); //eslint-disable-line

	useEffect(() => {
		/* NOTE on storage event:
			This won't work on the same page that is making the changes — it is really a way for other pages on the domain using
			the storage to sync any changes that are made. Pages on other domains can't access the same storage objects. */

		window.addEventListener('storage', (e) => {
			// storageArea is empty indicates the localstorage has been cleared (user logged out in another tab)
			if (e.storageArea?.length === 0) {
				signOut();
			}

			// updated user (probably logged in with another account in another tab)
			if (e.key === '@Bounty:token') {
				window.location.reload();
			}
		});
	}, []); //eslint-disable-line

	const signInQuery = useMutation(({ email, password }: SignInCredentials) => {
		return session(email, password);
	});

	const isUsingCorpway = useLocation().pathname.includes('/corporate-expenses');
	const isUsingMultiFlex = useLocation().pathname.includes('/home');

	useEffect(() => {
		socket.connect();
		socket.on('connect', () => {
			socket.emit('company_connection', {
				company_id: data.currentCompany?.id,
			});
		});

		socket.on('balance_update', async (operator_id: string | undefined) => {
			if (
				isUsingCorpway &&
				data.user.roles.includes('visualize-balances') &&
				operator_id &&
				operator_id !== data.user.id
			) {
				await getCorpwayBalancesQuery.refetch();
			} else if (isUsingMultiFlex) {
				await updateCompanyBalance.refetch();
			}
		});

		return () => {
			socket.disconnect();
			socket.off('balance_update');
		};
	}, [data.currentCompany, isUsingCorpway, isUsingMultiFlex]); //eslint-disable-line

	// user data query (fetch logged in user data)
	const userDataQuery = useQuery(
		['fetchOperatorsAccessData'],
		getOperatorsAccessData,
		{
			onSuccess: (data) => {
				let cachedCurrentCompany = localStorage.getItem(
					'@Bounty:currentCompanyId'
				);

				let currentCompany =
					data.companies.find(
						(company) => company.id === cachedCurrentCompany
					) || data.companies[0];

				setData({
					...data,
					currentCompany: currentCompany ?? null,
				});
			},
			onError: (err) => {
				console.log(err);
				toast.error('Ocorreu um problema ao buscar as informações do usuário');
				signOut();
			},
			enabled: false,
			retry: false,
			staleTime: Infinity,
		}
	);

	// multiflex balance query
	const updateCompanyBalance = useQuery(
		['fetchCompanyBalance', data.currentCompany?.id],
		() => getCompanyBalance(data.currentCompany?.id),
		{
			onError: (err) => {
				console.log(err);
				toast.error('Ocorreu um problema ao buscar o saldo da empresa');
			},
			enabled: !!data.user && !!data.currentCompany && isUsingMultiFlex,
			refetchOnWindowFocus: false,
		}
	);

	// corpway balance query
	const getCorpwayBalancesQuery = useQuery(
		['corpway-balances', data.currentCompany?.id],
		() => getCorpwayBalances(data.currentCompany!.id),
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao buscar o saldo da empresa'
				);
			},
			enabled:
				!!data.user &&
				!!data.currentCompany &&
				isUsingCorpway &&
				data.user.roles.includes('visualize-balances'),
			refetchOnWindowFocus: false,
		}
	);

	function changeCurrentCompany(id: string) {
		const company = data.companies.find((c: Company) => c.id === id);
		localStorage.setItem('@Bounty:currentCompanyId', id);
		setData({ ...data, currentCompany: company ?? null });
	}

	async function signIn({ email, password }: SignInCredentials) {
		try {
			const response = await signInQuery.mutateAsync({ email, password });

			let {
				token,
				user,
				companies,
				refreshToken,
				notifications: termsNotifications,
			} = response;

			localStorage.setItem('@Bounty:token', token);
			localStorage.setItem('@Bounty:refreshToken', refreshToken);

			setInterceptorResponseOnApi(token);

			setData({
				user,
				companies,
				termsNotifications,
				currentCompany: null,
			});

			signInWithEmailAndPassword(firebaseAuth, email, email)
				.then(() => {
					localStorage.setItem('@Firebase:auth', 'true');
					setFirebaseAuthConcluded(true);
				})
				.catch((err) => {
					console.log(err);
					localStorage.setItem('@Firebase:auth', 'false');
					setFirebaseAuthConcluded(false);
				});

			if (user.access_level === 'super_admin') {
				return {
					concludedLogin: true,
					super_admin: true,
					termsNotifications,
				};
			} else {
				return {
					concludedLogin: true,
					super_admin: false,
					termsNotifications,
				};
			}
		} catch (err) {
			if (axios.isAxiosError(err)) {
				let resultMessage = getMessageError(err.response?.data);

				return {
					concludedLogin: false,
					message: 'Ocorreu um erro ao fazer login. ' + resultMessage,
				};
			} else {
				console.log(err);
				return {
					concludedLogin: false,
					message: 'Ocorreu um erro ao fazer login.',
				};
			}
		}
	}

	async function clearAuthState() {
		setData({} as AuthState);

		localStorage.clear();
		setFirebaseAuthConcluded(false);

		try {
			await clearToken.mutateAsync();
		} catch (err) {
			console.log(err);
		}
	}

	async function signOut() {
		queryClient.cancelQueries();
		clearAuthState();
		history.push('/session');
	}

	function updateUserInfo(user: User) {
		setData({ ...data, user });
	}

	function setNewTermNotificationsNotRead(termsNotifications: Notification[]) {
		setData({ ...data, termsNotifications });
	}

	// This function sets a response interceptor at the api, so on each request if the token expires, refresh it)
	function setInterceptorResponseOnApi(token: string) {
		api.defaults.headers!!.authorization = `Bearer ${token}`;
		api.interceptors.response.use(
			(response) => {
				return response;
			},

			async (err: any) => {
				if (axios.isAxiosError(err)) {
					if (err.response?.data.errorTypeId === 3) {
						// Disabled user, access denied
						signOut();
						return Promise.reject(err);
					}
				}
				const originalConfig = err.config;
				let refreshToken = localStorage.getItem('@Bounty:refreshToken');

				if (
					err.response.status === 400 &&
					originalConfig.url === '/api/v1/rh/operators/refresh-token'
				) {
					signOut();
					return Promise.reject(err);
				}

				// Access Token was expired
				if (
					refreshToken &&
					err.response.status === 401 &&
					!originalConfig._retry
				) {
					originalConfig._retry = true;
					try {
						const data = await refreshTokenQuery.mutateAsync(refreshToken);
						const newToken = data.token;
						const newRefreshToken = data.refreshToken;

						api.defaults.headers.authorization = `Bearer ${newToken}`;
						localStorage.setItem('@Bounty:token', newToken);

						if (newRefreshToken)
							// refreshToken was expired as well, so update it with the new one
							localStorage.setItem('@Bounty:refreshToken', newRefreshToken);

						return api({
							...originalConfig,
							headers: { authorization: `Bearer ${newToken}` },
						});
					} catch (_error) {
						console.log(
							'SAINDO, deu ERRO ATUALIZANDO O TOKEN, catch',
							err.response
						);
						signOut();
						return Promise.reject(_error);
					}
				}
				return Promise.reject(err);
			}
		);
	}

	const companyBalance = (() => {
		const defaultValues = {
			multiflex_balance: 0,
			corpway_balance: 0,
			corpway_cards_balance: 0,
			corpway_account_balance: 0,
		};

		if (isUsingCorpway) {
			if (getCorpwayBalancesQuery.data) {
				return {
					multiflex_balance: 0,
					corpway_balance: getCorpwayBalancesQuery.data.corpway_balance / 100,
					corpway_cards_balance:
						getCorpwayBalancesQuery.data.cards_balance / 100,
					corpway_account_balance:
						getCorpwayBalancesQuery.data.account_balance / 100,
				};
			}
		} else {
			if (updateCompanyBalance.data) {
				return {
					...defaultValues,
					multiflex_balance: updateCompanyBalance.data.multiflex_balance / 100,
				};
			}
		}
		return defaultValues;
	})();
	const currentProduct = (() => {
		if (isUsingCorpway) return 'corpway';
		if (isUsingMultiFlex) return 'multiflex';
		return null;
	})();

	// there is a cached user (token found) and it is being fetched by userDataQuery
	if (!data.user?.id && token && !userDataQuery.isError) {
		return null;
	}

	return (
		<AuthContext.Provider
			value={{
				user: data.user,
				currentCompany: data.currentCompany,
				companies: data.companies,
				termsNotifications: data.termsNotifications,
				currentProduct,
				changeCurrentCompany,
				signIn,
				signOut,
				updateUserInfo,
				setNewTermNotificationsNotRead,
				companyBalance,
				updateCompanyBalance,
				firebaseAuthConcluded,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
}
