import { Redirect, Switch } from 'react-router';
import { ReactRoute as Route } from './Route';
import FlexibleBenefits from '../pages/FlexibleBenefits';
import NotFound from '../pages/NotFound';
import SignIn from '../pages/Session/SignIn';
import MainSuperAdmin from '../pages/SuperAdmin/Main';
import RecoverPassword from '../pages/RecoverPassword';
import { ChangePassword } from '../pages/RecoverPassword/ChangePassword';
import { useAuth } from '../hooks/useAuth';
import { FirstAccess } from '../pages/Session/FirstAccess';
import { ChangeCollaboratorPassword } from '../pages/ChangeCollaboratorPassword';
import { TermsCorpway } from '../pages/CorpwayTermsAndPrivacy';
import { ServiceUnavailable } from '../pages/ServiceUnavailable';
import { SelectProduct } from '../pages/Session/SelectProduct';
import { CorporateExpenses } from '../pages/CorporateExpenses';
import { Support } from '../pages/Support';
import { PublicPrivacyPolicy } from '../pages/PublicPrivacy';
import { PublicTerms } from '../pages/PublicTerms';
import { TermsMultiflex } from '../pages/FlexibleBenefits/Terms/Terms';

export default function Routes() {
	const { user } = useAuth();

	return (
		<Switch>
			<Route exact path='/' component={FlexibleBenefits}>
				<Redirect
					to={
						user && user.access_level === 'super_admin'
							? '/super-admin'
							: '/select-product'
					}
				/>
			</Route>
			<Route path='/recover-password' component={RecoverPassword} />
			<Route exact path='/change-password/:token' component={ChangePassword} />
			<Route
				path='/change-password/collaborator/:token'
				component={ChangeCollaboratorPassword}
			/>
			<Route path='/service-unavailable' component={ServiceUnavailable} />

			<Route exact path='/session' component={SignIn}>
				{user && (
					<Redirect
						to={
							user.access_level === 'super_admin'
								? '/super-admin'
								: '/select-product'
						}
					/>
				)}
			</Route>

			{!user?.status_password && (
				<Route path='/first-access' component={FirstAccess} />
			)}

			<Route
				path='/select-product'
				component={SelectProduct}
				isPrivateOperator
			/>

			<Route path='/support' component={Support} isPrivateOperator />
			<Route
				path='/home'
				component={FlexibleBenefits}
				isPrivateOperator
				isFlexibleBenefitsRoute
			/>
			<Route
				exact
				path='/privacy'
				component={PublicPrivacyPolicy}
				isCorporateExpensesRoute
			/>
			<Route
				path='/privacy/:privacyType'
				component={PublicPrivacyPolicy}
				isCorporateExpensesRoute
			/>
			<Route exact path='/terms' component={PublicTerms} />
			<Route exact path='/terms/accept' component={TermsMultiflex} />
			<Route exact path='/terms/:termsType' component={PublicTerms} />
			<Route
				path='/corporate-expenses/terms'
				component={TermsCorpway}
				isPrivateOperator
				isCorporateExpensesRoute
			/>

			<Route
				path='/corporate-expenses'
				component={CorporateExpenses}
				isPrivateOperator
				isCorporateExpensesRoute
			/>
			<Route
				path='/super-admin'
				component={MainSuperAdmin}
				isPrivateSuperAdmin={true}
			/>

			<Route path='/404' component={NotFound} />
			<Redirect to='/404' />
		</Switch>
	);
}
