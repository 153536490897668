import * as S from './styles';
import Loader from '../../../components/Loader';
import PageTitle from '../../../components/PageTitle';
import { Benefit } from '../../../@types';
import { parseDate } from '../../../utils/parseDate';
import * as TableStyle from '../../../components/Table/TableStyles';
import { useAuth } from '../../../hooks/useAuth';
import { useQuery } from 'react-query';
import { getBenefits } from '../../../services/queries/Benefits';
import { getBenefitIcon } from '../../../utils/benefits/getBenefitIcon';
import { getBenefitParsedTitle } from '../../../utils/benefits/getBenefitParsedTitle';
import { EmptyContent } from '../../../components/EmptyContent';
import { sortBenefitsByAlfabeticalOrder } from '../../../utils/sortArrayByAlfabeticalOrder';
import { showErrorMessage } from '../../../utils/ErrorHandler';

export default function Benefits() {
	const { currentCompany } = useAuth();
	const { data, isLoading } = useQuery<Benefit[], Error>(
		['benefits', currentCompany?.id],
		() => getBenefits(currentCompany?.id, true),
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível buscar os benefícios. '
				);
			},
		}
	);

	if (isLoading || !data) {
		return (
			<S.Container>
				<PageTitle title='Benefícios' />
				<Loader />
			</S.Container>
		);
	}
	if (!data.length) {
		return (
			<S.Container>
				<PageTitle title='Benefícios' />
				<EmptyContent text='Nenhum benefício cadastrado para a empresa' />
			</S.Container>
		);
	}
	return (
		<S.Container>
			<PageTitle title='Benefícios' />
			<TableStyle.Table>
				<TableStyle.TableHeader>
					<TableStyle.TableRow>
						<TableStyle.TableHeaderCell>TÍTULO</TableStyle.TableHeaderCell>
						<TableStyle.TableHeaderCell>CRIAÇÃO</TableStyle.TableHeaderCell>
					</TableStyle.TableRow>
				</TableStyle.TableHeader>

				<TableStyle.TableBody>
					{sortBenefitsByAlfabeticalOrder(data).map((benefit) => (
						<TableStyle.TableRow key={benefit.id}>
							<TableStyle.TableData>
								<S.TitleContainer>
									{getBenefitIcon(benefit.title)}{' '}
									{getBenefitParsedTitle(benefit.title)}
								</S.TitleContainer>
							</TableStyle.TableData>
							<TableStyle.TableData>
								{parseDate(benefit.created_at!, 2)}
							</TableStyle.TableData>
						</TableStyle.TableRow>
					))}
				</TableStyle.TableBody>
			</TableStyle.Table>
		</S.Container>
	);
}
