import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 44rem;
	padding: 32px;
	gap: 15px;
	border-radius: 16px 0px 0px 0px;
	opacity: 0px;
`;

export const Header = styled.div`
	& > img {
		height: 4rem;
		width: 15rem;
		margin-bottom: 1rem;
	}
	h2 {
		font-family: Poppins, SemiBold;
		font-weight: 600;
		font-size: 1.6rem;
		margin-bottom: 0.5rem;
	}
	p {
		font-weight: 400;
		font-size: 1.4rem;
	}
`;

export const SubHeader = styled.div`
	display: flex;
	justify-content: space-between;
	font-size: 1.4rem;
`;

export const Label = styled.div`
	display: flex;
	flex-direction: column;
`;

export const Value = styled.div`
	display: flex;
	flex-direction: column;
	text-align: right;
	font-family: Poppins;
`;

export const Currency = styled.p`
	color: var(--primary-blue);
`;

export const InfoContainer = styled.div`
	display: flex;
	flex-direction: column;
	font-weight: 400;
`;

export const InfoHeader = styled.div`
	display: flex;
	gap: 0.5rem;

	h2 {
		font-size: 1.4rem;
	}
`;

export const InfoContent = styled.div`
	display: flex;
	justify-content: space-between;
	font-size: 1.2rem;
`;

export const ButtonContainer = styled.div`
	display: flex;
	justify-content: space-between;
	margin-top: 3.2rem;
	margin-right: 1rem;
	margin-left: 1rem;
	gap: 1.6rem;

	button {
		border-radius: 2.5rem;
		flex-grow: 1;
		width: 18rem;
		white-space: nowrap;
		height: 5rem;
	}
`;
