import styled from 'styled-components';

export const OptionsContainer = styled.div`
	width: 100%;
	display: flex;
	column-gap: 2rem;
	justify-content: center;

	a {
		&:hover {
			filter: none;
		}
	}

	.costCenterActive {
		img {
			width: 12rem;
			margin-right: 1rem;
			// margin-right: 2rem;
		}
	}

	.usersActive {
		img {
			width: 12rem;
			margin-right: 1rem;
		}
	}

	.cardsActive {
		img {
			width: 12rem;
			margin-right: 0.5rem;
		}
	}

	.contentWrapper {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: 3rem 0rem;
		width: 65%;
		gap: 1rem;

		h2,
		p {
			color: var(--primary-blue);
			line-height: 32px;
		}

		h2 {
			font-weight: 600;
			font-size: 2rem;
		}

		p {
			font-size: 2.4rem;
			display: flex;
			gap: 1rem;
			color: var(--dark-gray2);
			font-weight: 700;
		}
	}

	@media (max-width: 1024px) {
		flex-direction: column;
		gap: 2rem;
	}
`;

export const OptionInfoContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	/* background-color: red; */
	padding: 2rem 0;
	align-items: center;
	row-gap: 1rem;
`;
