import { Chargeback } from '../../@types';
import {
	FilterParams,
	parseFilterParamsToParams,
} from '../../components/Filter';
import {
	SortColumn,
	parseSortColumnToParams,
} from '../../hooks/useSortColumnHook';
import api from '../api';

export interface ChargebacksListReturn {
	nonPendingChargebacks?: Chargeback[];
	totalNonPendingChargebacks?: number;

	pendingChargebacks?: Chargeback[];
	totalPendingChargebacks?: number;
}

export interface ChargebacksFilterReturn {
	totalChargebacks?: number;
	chargebacks?: Chargeback[];
}

export interface chargebackBodyQuery {
	value: number;
	justification: string;
	benefit_ids: string[];
	collaborator_id: string | undefined;
}

export interface CreateChargebackResponse {
	chargebackBenefits: {
		title: string;
	}[];
	chargebackError: {
		title: string;
	}[];
	chargebackSuccess: {
		title: string;
	}[];
}

export async function getNonPendingChargebacks(
	nonPendingChargebacksCurrentPage: number,
	companyId?: string,
	sortColumn: SortColumn | null = null
): Promise<ChargebacksListReturn> {
	const { data } = await api.get(
		`api/v1/rh/companies/${companyId}/chargebacks/q?page_other=${nonPendingChargebacksCurrentPage}`,
		{
			params: {
				...(sortColumn && { sort_by_other: sortColumn.name }),
				...(sortColumn && { sort_order_other: sortColumn.order }),
			},
		}
	);

	const nonPendingChargebacks = data.otherChargebacks;
	const totalNonPendingChargebacks = data.totalOtherChargebacks;

	return {
		nonPendingChargebacks,
		totalNonPendingChargebacks,
	};
}

export async function getPendingChargebacks(
	pendingChargebacksCurrentPage: number,
	companyId?: string,
	sortColumn: SortColumn | null = null
): Promise<ChargebacksListReturn> {
	const { data } = await api.get(
		`api/v1/rh/companies/${companyId}/chargebacks/q?page_pending=${pendingChargebacksCurrentPage}`,
		{
			params: {
				...(sortColumn && { sort_by_pending: sortColumn.name }),
				...(sortColumn && { sort_order_pending: sortColumn.order }),
			},
		}
	);

	const pendingChargebacks = data.pendingChargebacks;
	const totalPendingChargebacks = data.totalPendingChargebacks;

	return {
		pendingChargebacks,
		totalPendingChargebacks,
	};
}

export async function getFilteredChargebacks(
	filterParams: FilterParams[],
	pending?: boolean,
	companyId?: string,
	page?: number,
	sortColumn: SortColumn | null = null
): Promise<ChargebacksFilterReturn> {
	const { data } = await api.get<ChargebacksFilterReturn>(
		`/api/v1/rh/companies/${companyId}/chargebacks/filter/q?page=${page}`,
		{
			params: {
				...parseFilterParamsToParams(filterParams),
				...parseSortColumnToParams(sortColumn),
				status: pending ? 'pending' : 'approved,canceled',
			},
		}
	);

	return data;
}

export async function createChargeback(
	body: chargebackBodyQuery,
	companyId?: string
): Promise<CreateChargebackResponse> {
	const { data } = await api.post(
		`api/v1/rh/companies/${companyId}/chargebacks`,
		body
	);

	return data;
}

export async function createChargebackToAllbenefits(
	justification: string,
	companyId?: string,
	collaboratorId?: string
): Promise<CreateChargebackResponse> {
	const { data } = await api.post(
		`api/v1/rh/companies/${companyId}/chargebacks/all/collaborators/${collaboratorId}`,
		{ justification }
	);

	return data;
}

export async function fetchChargeback(
	chargebackId: string,
	companyId?: string
): Promise<Chargeback> {
	const { data } = await api.get<Chargeback>(
		`/api/v1/rh/companies/${companyId}/chargebacks/${chargebackId}`
	);

	return data;
}

export async function reproveChargeback(
	feedback: string,
	chargebackId: string,
	companyId?: string
): Promise<Chargeback> {
	const { data } = await api.put(
		`/api/v1/rh/companies/${companyId}/chargebacks/${chargebackId}/status`,
		{
			status: 'canceled',
			reason_disapproval: feedback,
		}
	);

	return data;
}

export async function cancelChargeback(
	feedback: string,
	chargebackId: string,
	companyId?: string
): Promise<Chargeback> {
	const { data } = await api.put(
		`/api/v1/rh/companies/${companyId}/chargebacks/${chargebackId}/cancel`,
		{
			reason_disapproval: feedback,
		}
	);

	return data;
}

export async function approveChargeback(
	chargebackId: string,
	companyId?: string
): Promise<CreateChargebackResponse> {
	const { data } = await api.put(
		`/api/v1/rh/companies/${companyId}/chargebacks/${chargebackId}/status`,
		{
			status: 'approved',
		}
	);

	return data;
}
