import * as S from './styles';

interface PageTitleProps {
	title: string;
	totalRecords?: number;
}

export default function PageTitle({ title, totalRecords }: PageTitleProps) {
	return (
		<S.Container>
			<S.Title>{title}</S.Title>

			{!!totalRecords && (
				<S.TotalRecords data-testid='total-records'>
					Total: <b>{totalRecords}</b> registros
				</S.TotalRecords>
			)}
		</S.Container>
	);
}
