import { useState, useEffect } from 'react';
import * as S from './styles';
import PageTitle from '../../../components/PageTitle';
import { useForm } from 'react-hook-form';
import ToggleSwitch from '../../../components/ToggleSwitch';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { SettingsPreferences } from '../../../@types';
import { toast } from 'react-toastify';
import {
	getPreferences,
	updatePreferences,
} from '../../../services/queries/NotificationsPreferences';
import Loader from '../../../components/Loader';
import PreventTransitionPrompt from '../../../components/PreventTransitionPrompt';
import { showErrorMessage } from '../../../utils/ErrorHandler';
import { useAuth } from '../../../hooks/useAuth';

interface days_week {
	sunday: string;
	monday: string;
	tuesday: string;
	wednesday: string;
	thursday: string;
	friday: string;
	saturday: string;
	holidays: string;
}

export interface PreferencesProps {
	notification_chargeback: boolean;
	notification_release: boolean;
	notification_schedule: boolean;
	notification_kyc: boolean;
	notification_card: boolean;
	notification_chat: boolean;
	notification_refund: boolean;
	email_chargeback: boolean;
	email_schedule: boolean;
	email_release: boolean;
	email_kyc: boolean;
	email_chat: boolean;
	chat_delegation: boolean;
	days_of_week: {
		sunday: boolean;
		monday: boolean;
		tuesday: boolean;
		wednesday: boolean;
		thursday: boolean;
		friday: boolean;
		saturday: boolean;
		holidays: boolean;
	};
	default_initial_hours: string;
	default_final_hours: string;
	use_custom_hours: boolean;
	custom_initial_hours: days_week;
	custom_final_hours: days_week;
}

export const daysOfWeek = {
	sunday: 'Domingo',
	monday: 'Segunda',
	tuesday: 'Terça',
	wednesday: 'Quarta',
	thursday: 'Quinta',
	friday: 'Sexta',
	saturday: 'Sábado',
	holidays: 'Feriados',
};

export const emailsPreferences = {
	email_chargeback: 'Estornos',
	email_schedule: 'Agendamentos',
	email_release: 'Pagamentos',
	email_kyc: 'Atualização de status KYC',
	email_chat: 'Chat de suporte',
};

export const notificationsPreferences = {
	notification_chargeback: 'Estornos',
	notification_release: 'Agendamentos',
	notification_schedule: 'Pagamentos',
	notification_kyc: 'Atualização de status KYC',
	notification_card: 'Cartões',
	notification_chat: 'Chat de suporte',
	notification_refund: 'Reembolsos',
};

export function Settings() {
	const updatePreferencesQuery = useMutation((preferences: PreferencesProps) =>
		updatePreferences(preferences, currentCompany?.id!)
	);
	const queryClient = useQueryClient();
	const { register, handleSubmit, reset, formState } = useForm();
	const { user, currentCompany } = useAuth();

	const { isDirty } = formState;
	const [allowNavigation, setAllowNavigation] = useState(!isDirty);

	const [customHoursByDay, setCustomHoursByDay] = useState(false);
	const [days, setDays] = useState({
		sunday: false,
		monday: false,
		tuesday: false,
		wednesday: false,
		thursday: false,
		friday: false,
		saturday: false,
		holidays: false,
	});

	useEffect(() => {
		setAllowNavigation(!isDirty);
	}, [isDirty]);

	async function submitPreferences(dataForm: PreferencesProps) {
		try {
			await updatePreferencesQuery.mutateAsync(dataForm);
			queryClient.resetQueries('fetchPreferences');
			toast.info('Configurações do sistema atualizadas.');
		} catch (err) {
			showErrorMessage(
				err as Error,
				'Não foi possível atualizar suas preferências. '
			);
		}
	}

	const { data, isLoading } = useQuery<SettingsPreferences, Error>(
		'fetchPreferences',
		() => {
			return getPreferences(currentCompany?.id!);
		},
		{
			onSuccess: (data) => {
				if (data.chatOperatingHoursConfig) {
					setCustomHoursByDay(data.chatOperatingHoursConfig.use_custom_hours);
					setDays({
						sunday: data.chatOperatingHoursConfig.days_of_week.sunday,
						monday: data.chatOperatingHoursConfig.days_of_week.monday,
						tuesday: data.chatOperatingHoursConfig.days_of_week.tuesday,
						wednesday: data.chatOperatingHoursConfig.days_of_week.wednesday,
						thursday: data.chatOperatingHoursConfig.days_of_week.thursday,
						friday: data.chatOperatingHoursConfig.days_of_week.friday,
						saturday: data.chatOperatingHoursConfig.days_of_week.saturday,
						holidays: data.chatOperatingHoursConfig.days_of_week.holidays,
					});
					reset();
				}
			},
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível buscar as configurações de notificações. '
				);
			},
			refetchOnWindowFocus: false,
		}
	);

	function isAnyDayChecked() {
		return Object.values(days).some((value) => value === true);
	}

	if (!data || isLoading || updatePreferencesQuery.isLoading) {
		return (
			<S.Container>
				<PageTitle title='Configurações do sistema' />
				<Loader />
			</S.Container>
		);
	}

	return (
		<S.Container>
			{!allowNavigation && <PreventTransitionPrompt />}

			<PageTitle title='Configurações do sistema' />

			<S.PreferencesForm onSubmit={handleSubmit(submitPreferences)}>
				<S.PreferencesSectionContainer>
					<S.PreferencesSectionTitle>
						Notificações via dashboard web
					</S.PreferencesSectionTitle>
					<S.ToggleButtonsContainer>
						{Object.entries(notificationsPreferences).map(
							([notificationPref, value]) => {
								return (
									<S.FieldContainer key={`${notificationPref}:${value}`}>
										<p>{value}</p>
										<ToggleSwitch
											name={notificationPref}
											register={register}
											defaultChecked={
												data.operatorPreferences[
													notificationPref as keyof typeof data.operatorPreferences
												]
											}
											data-testid={`toggleButton_${notificationPref}`}
										/>
									</S.FieldContainer>
								);
							}
						)}
					</S.ToggleButtonsContainer>
				</S.PreferencesSectionContainer>

				<S.PreferencesSectionContainer>
					<S.PreferencesSectionTitle>
						Notificações via email
					</S.PreferencesSectionTitle>
					<S.ToggleButtonsContainer>
						{Object.entries(emailsPreferences).map(([emailPref, value]) => {
							return (
								<S.FieldContainer key={`${emailPref}:${value}`}>
									<p>{value}</p>
									<ToggleSwitch
										name={emailPref}
										register={register}
										defaultChecked={
											data.operatorPreferences[
												emailPref as keyof typeof data.operatorPreferences
											]
										}
										data-testid={`toggleButton_${emailPref}`}
									/>
								</S.FieldContainer>
							);
						})}
					</S.ToggleButtonsContainer>
				</S.PreferencesSectionContainer>

				<S.PreferencesSectionContainer>
					<S.PreferencesSectionTitle>Chat de suporte</S.PreferencesSectionTitle>
					<S.ToggleButtonsContainer>
						<S.FieldContainer>
							<p>Receber delegação de chat</p>
							<ToggleSwitch
								name={'chat_delegation'}
								register={register}
								defaultChecked={data.operatorPreferences.chat_delegation}
								data-testid='toggleButton_chat_delegation'
							/>
						</S.FieldContainer>

						{user.access_level !== 'operator' && (
							<S.FieldsGroup>
								<p>Dias e horário de atendimento</p>
								<S.DaysAndHoursContainer>
									<S.DaysGrid>
										{Object.entries(daysOfWeek).map(([day, dayPT]) => {
											return (
												<S.DayContainer key={`${day}:${dayPT}`}>
													<S.DayLabel htmlFor={`days_of_week.${day}`}>
														{dayPT}
													</S.DayLabel>
													<ToggleSwitch
														name={`days_of_week.${day}`}
														register={register}
														checked={days[day as keyof typeof daysOfWeek]}
														data-testid={`toggleButton_${day}`}
														onChange={(e) =>
															setDays({ ...days, [day]: e.target.checked })
														}
													/>
													{customHoursByDay && days[day as keyof typeof days] && (
														<S.CustomHoursPickerContainer>
															<S.PickerContainer>
																<S.PickerLabel
																	htmlFor={`custom_initial_hours.${day}`}
																>
																	Horário inicial:
																</S.PickerLabel>
																<S.TimePicker
																	{...register(`custom_initial_hours.${day}`, {
																		shouldUnregister: true,
																	})}
																	required
																	defaultValue={
																		data.chatOperatingHoursConfig!
																			.custom_initial_hours[
																			day as keyof typeof daysOfWeek
																		] ??
																		data.chatOperatingHoursConfig!
																			.default_initial_hours
																	}
																	type='time'
																	id={`custom_initial_hours.${day}`}
																	data-testid={`custom_initial_hours.${day}`}
																/>
															</S.PickerContainer>

															<S.PickerContainer>
																<S.PickerLabel
																	htmlFor={`custom_final_hours.${day}`}
																>
																	Horário final:
																</S.PickerLabel>
																<S.TimePicker
																	{...register(`custom_final_hours.${day}`, {
																		shouldUnregister: true,
																	})}
																	required
																	defaultValue={
																		data.chatOperatingHoursConfig!
																			.custom_final_hours[
																			day as keyof typeof daysOfWeek
																		] ??
																		data.chatOperatingHoursConfig!
																			.default_final_hours
																	}
																	type='time'
																	id={`custom_final_hours.${day}`}
																	data-testid={`custom_final_hours.${day}`}
																/>
															</S.PickerContainer>
														</S.CustomHoursPickerContainer>
													)}
												</S.DayContainer>
											);
										})}
									</S.DaysGrid>
									{isAnyDayChecked() && (
										<>
											{!customHoursByDay && (
												<S.FieldContainer>
													<p>Horários</p>
													<S.HoursPickerContainer>
														<S.PickerContainer>
															<S.PickerLabel htmlFor='default_initial_hours'>
																Horário inicial:
															</S.PickerLabel>
															<S.TimePicker
																{...register(`default_initial_hours`, {
																	shouldUnregister: true,
																})}
																defaultValue={
																	data.chatOperatingHoursConfig!
																		.default_initial_hours
																}
																type='time'
																id='default_initial_hours'
																data-testid={`default_initial_hours`}
																required
															/>
														</S.PickerContainer>

														<S.PickerContainer>
															<S.PickerLabel htmlFor='default_final_hours'>
																Horário final:
															</S.PickerLabel>
															<S.TimePicker
																{...register(`default_final_hours`, {
																	shouldUnregister: true,
																})}
																defaultValue={
																	data.chatOperatingHoursConfig!
																		.default_final_hours
																}
																type='time'
																id='default_final_hours'
																data-testid={`default_final_hours`}
																required
															/>
														</S.PickerContainer>
													</S.HoursPickerContainer>
												</S.FieldContainer>
											)}

											<S.FieldContainer>
												<p>Customizar horários por dia</p>
												<ToggleSwitch
													name={'use_custom_hours'}
													checked={customHoursByDay && isAnyDayChecked()}
													register={register}
													data-testid={`toggleButton_use_custom_hours`}
													onChange={(e) =>
														setCustomHoursByDay(e.target.checked)
													}
												/>
											</S.FieldContainer>
										</>
									)}
								</S.DaysAndHoursContainer>
							</S.FieldsGroup>
						)}
					</S.ToggleButtonsContainer>
				</S.PreferencesSectionContainer>

				<S.FormButton type='submit'>Salvar</S.FormButton>
			</S.PreferencesForm>
		</S.Container>
	);
}
