import { useQuery, useQueryClient } from 'react-query';
import { useAuth } from '../../hooks/useAuth';
import {
	CORPWAY_COST_CENTER_KEY,
	CostCentersListReturn,
	getCostCenters,
} from '../../services/queries/Corpway/CostCenters';
import { showErrorMessage } from '../../utils/ErrorHandler';
import Modal from '../ui/Modal';
import React, { useEffect, useState } from 'react';
import { Button } from '../ui/Button';
import * as S from './styles';
import { TitleTypography, Typography } from '../ui/Typography';
import { RiAddCircleLine, RiCloseCircleLine } from 'react-icons/ri';
import { CostCenter } from '../../@types/CorporateExpenses/CostCenter';
import { useDialogModal } from '../../hooks/useDialogModal';
import { AsynchronousContent } from '../AsynchronousContent';
import { MdAdd } from 'react-icons/md';
import { Pagination } from '../../components/Pagination';
import { toast } from 'react-toastify';
import styled from 'styled-components';

interface Props {
	alreadyAddedCostCenters: CostCenter[];
	onAddNewCostCenters(c: CostCenter[]): void;
	blockDeselectAll?: boolean;
}

const MobileButton = styled(Button)`
	display: none;
	width: 3.9rem;
	height: 3.9rem;
	margin: 0;
	padding: 0;
	@media (max-width: 1024px) {
		display: flex;
	}
`;

const WebButton = styled(Button)`
	display: flex;
	@media (max-width: 1024px) {
		display: none;
	}
`;

export function CostCenterSelect({
	alreadyAddedCostCenters,
	onAddNewCostCenters,
	blockDeselectAll,
}: Props) {
	const { currentCompany } = useAuth();
	const { openOptionsDialog } = useDialogModal();
	const [page, setPage] = useState(1);
	const [isOpen, setOpen] = useState(false);
	const [selectedCostCenters, setSelectedCostCenters] = useState<CostCenter[]>(
		[]
	);
	const [hasMadeChanges, setHasMadeChanges] = useState(false);
	const queryClient = useQueryClient();

	const getCostCentersQuery = useQuery(
		[CORPWAY_COST_CENTER_KEY, page, currentCompany?.id],
		() => getCostCenters(currentCompany!.id, page, {
			name: "title",
			order: "ASC"
		}),
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível buscar os centros de custo. '
				);
			},
			keepPreviousData: true,
			enabled: isOpen,
		}
	);

	const allCostCenters = queryClient.getQueryData<CostCentersListReturn>([
		CORPWAY_COST_CENTER_KEY,
		currentCompany?.id,
	]);

	useEffect(() => {
		if (isOpen) {
			// reseting
			setHasMadeChanges(false);
			setSelectedCostCenters(alreadyAddedCostCenters);
		}
	}, [isOpen, alreadyAddedCostCenters]);

	function handleClose() {
		if (!hasMadeChanges) {
			setOpen(false);
			return;
		}

		openOptionsDialog(
			'Alterações não salvas. Tem certeza que deseja sair?',
			'Confirmar',
			() => setOpen(false),
			'Cancelar',
			() => {}
		);
	}

	function handleSubmit() {
		onAddNewCostCenters(selectedCostCenters);
		setOpen(false);
	}

	function isCostCenterSelected(id: string) {
		return !!selectedCostCenters.find((costCenter) => costCenter.id === id);
	}

	function handleSelectCostCenter(costCenter: CostCenter) {
		setHasMadeChanges(true);
		setSelectedCostCenters([...selectedCostCenters, costCenter]);
	}

	function handleDeselectCostCenter(costCenter: CostCenter) {
		if (blockDeselectAll && selectedCostCenters.length === 1) {
			toast.error(
				'Não é possível remover todos os centros de custo do usuário.'
			);
			return;
		}
		setHasMadeChanges(true);
		setSelectedCostCenters(
			selectedCostCenters.filter((b) => b.id !== costCenter.id)
		);
	}

	function selectAll() {
		setHasMadeChanges(true);
		setSelectedCostCenters(allCostCenters?.costCenters || []);
	}

	function unselectAll() {
		setHasMadeChanges(true);
		setSelectedCostCenters([]);
	}

	const hasAllCostCenters =
		allCostCenters?.total_costCenters === selectedCostCenters.length ||
		allCostCenters?.total_costCenters === alreadyAddedCostCenters.length;

	return (
		<div>
			<MobileButton
				intent='secondary'
				onClick={() => setOpen(true)}
				type='button'
				roundness={'lg'}
				width={'contain'}
			>
				<MdAdd size={30} color={'white'} />
			</MobileButton>

			<WebButton intent='link' onClick={() => setOpen(true)} type='button'>
				+{' '}
				{hasAllCostCenters
					? 'Selecionar centros de custo'
					: 'Adicionar centro de custo'}
			</WebButton>

			<Modal
				isOpen={isOpen}
				showClose
				onRequestClose={handleClose}
				closeBtnStyles={{ marginTop: '1rem' }}
			>
				<S.Container>
					<TitleTypography size='3rem'>
						Selecione os Centros de Custo
					</TitleTypography>

					<AsynchronousContent
						status={getCostCentersQuery.status}
						emptyContent={getCostCentersQuery.data?.total_costCenters === 0}
					>
						<div style={{ display: 'flex', justifyContent: 'space-between' }}>
							<Button
								type={'button'}
								style={{ fontSize: '1rem', padding: '0.auto5rem 0.5rem' }}
								intent={'terciary'}
								$outline
								width={'contain'}
								onClick={hasAllCostCenters ? unselectAll : selectAll}
							>
								{hasAllCostCenters ? 'Desmarcar tudo' : 'Selecionar tudo'}
							</Button>
							<Pagination
								onPageChange={(page) => setPage(page)}
								currentPage={page}
								totalCount={getCostCentersQuery.data?.total_costCenters ?? 1}
							/>
						</div>

						<S.CostCenterListContainer>
							{getCostCentersQuery.data?.costCenters.map((costCenter) => (
								<S.CostCenterContainer key={costCenter.id}>
									<Typography>{costCenter.title}</Typography>
									{!isCostCenterSelected(costCenter.id) ? (
										<S.Selector
											data-testid={`${costCenter.id}-select`}
											selected={isCostCenterSelected(costCenter.id)}
											onClick={() => handleSelectCostCenter(costCenter)}
										>
											<RiAddCircleLine />
										</S.Selector>
									) : (
										<S.Selector
											data-testid={`${costCenter.id}-unselect`}
											selected={isCostCenterSelected(costCenter.id)}
											onClick={() => handleDeselectCostCenter(costCenter)}
										>
											<RiCloseCircleLine />
										</S.Selector>
									)}
								</S.CostCenterContainer>
							))}
						</S.CostCenterListContainer>
					</AsynchronousContent>

					<Button onClick={handleSubmit} style={{ margin: '0 auto' }}>
						Salvar
					</Button>
				</S.Container>
			</Modal>
		</div>
	);
}
