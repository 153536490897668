import styled from 'styled-components';

export const DocumentContainer = styled.div`
	display: flex;
	flex-direction: column;
	/* padding: 0.2rem; */
	/* border: 0.15rem solid var(--dark-gray); */
`;

export const DocumentStatusContainer = styled.div`
	display: flex;
	column-gap: 0.5rem;
	align-items: center;
	& > svg {
		width: 1.4rem;
		height: 1.4rem;
	}
`;

export const ModelLink = styled.a`
	color: var(--primary-red);
	text-decoration: underline;
	font-style: italic;
	margin-left: 0.3rem;
`;

export const EmailInputContainer = styled.div`
	display: flex;
	align-items: center;

	> svg {
		width: 2.5rem;
		height: 2.5rem;
		margin-left: 2rem;
	}

	> input {
		width: 49.5%;
	}
`;

export const CheckBoxContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	left: 8rem;
	bottom: 0.1rem;
`;

export const CheckBoxLabel = styled.label`
	font-size: 1.1rem;
	width: 15rem;
	margin-left: 0.5rem;
`;

interface OptionInterface {
	cancel?: boolean;
}
export const Option = styled.button<OptionInterface>`
	background-color: transparent;
	text-decoration: underline;
	color: var(--dark-gray);
	transition: all 0.2s;
	margin-top: 2.6rem;

	&:hover {
		color: var(--primary-red);
	}
`;
