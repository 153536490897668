import { useMutation } from 'react-query';
import { getAddressByCEP } from '../../services/viaCEP';
import { toast } from 'react-toastify';
import { AddressBody } from '../../@types';
import {
	addressesFieldsCollaboratorSWAPRawRegex,
	onlyLettersAndSpacesRawRegex,
} from '../../utils/patterns';

interface Props {
	onAddressFoundCallback: (address: AddressBody) => void;
}
export function useGetAddressByCEP({ onAddressFoundCallback }: Props) {
	const getAddressByCEPQuery = useMutation(
		(cep: string) => getAddressByCEP(cep),
		{
			onSuccess: (data) => {
				if (!data.cep) {
					toast.error('Não foi possível buscar o CEP inserido.');
					return;
				}

				const regAddressesFieldsCollaboratorSWAP = new RegExp(
					`[^${addressesFieldsCollaboratorSWAPRawRegex}]+`,
					'g'
				);
				const regOnlyLettersAndSpaces = new RegExp(
					`[^${onlyLettersAndSpacesRawRegex}]+`,
					'g'
				);

				// remove all chars that are not allowed to that field
				const address: AddressBody = {
					cep: data.cep,
					address: data.logradouro.replace(
						regAddressesFieldsCollaboratorSWAP,
						''
					),
					uf: data.uf,
					city: data.localidade.replace(regOnlyLettersAndSpaces, ''),
					complement: data.complemento.replace(
						regAddressesFieldsCollaboratorSWAP,
						''
					),
					district: data.bairro.replace(regAddressesFieldsCollaboratorSWAP, ''),
					number: '',
				};

				onAddressFoundCallback(address);
			},
			onError: () => {
				toast.error('Ocorreu um problema ao buscar o CEP, tente novamente.');
			},
		}
	);

	function searchAddressByCEP(cep: string) {
		const parsedCEP = cep.replace('-', '');
		if (parsedCEP.length === 8) {
			getAddressByCEPQuery.mutate(parsedCEP);
		} else {
			toast.error('CEP inválido.');
		}
	}

	return { searchAddressByCEP, isSearchingCEP: getAddressByCEPQuery.isLoading };
}
