import styled from 'styled-components';
import { OptionsButton } from '../../../../../components/Button';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	font-size: 1.6rem;
	width: 40rem;
`;

export const Header = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	& svg {
		width: 4rem;
		height: 4rem;
		border-radius: 50%;
		color: var(--terciary);
		margin-bottom: 1rem;
	}
`;

export const BenefitsList = styled.ul`
	list-style: none;
	margin: 2rem 0;
`;

export const InfoContainer = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 2rem 0;
`;

export const BenefitContainer = styled.li`
	display: flex;
	justify-content: space-between;
	margin-top: 1rem;
`;

export const InfoTitle = styled.span`
	font-weight: 500;
`;

export const InfoValue = styled.span``;

export const OptionsContainer = styled.div`
	display: flex;
	width: 80%;
	align-self: center;
	margin: 2rem 0;
	justify-content: space-around;
`;

export const MainOption = styled.button`
	${OptionsButton}
`;
export const SecondOption = styled.button`
	${OptionsButton}
	background-color: var(--white);
	color: var(--primary-blue);
`;
