import { CollaboratorFormStatus } from '../../../../@types';
import * as S from '../styles';
import { FaCheckCircle, FaExclamationCircle, FaRegClock } from 'react-icons/fa';

export const FormStatus = ({
	formStatus,
}: {
	formStatus: CollaboratorFormStatus;
}) => {
	if (formStatus === 'completed')
		return (
			<S.StatusContainer>
				<FaCheckCircle color='#1CCA0D' />
				<span>Finalizado</span>
			</S.StatusContainer>
		);
	if (formStatus === 'incomplete')
		return (
			<S.StatusContainer>
				<FaExclamationCircle color='#FB1313' />
				<span>Não finalizado</span>
			</S.StatusContainer>
		);
	if (formStatus === 'in_analysis')
		return (
			<S.StatusContainer>
				<FaRegClock color='#E0E414' />
				<span>Sob análise</span>
			</S.StatusContainer>
		);
	return (
		<S.StatusContainer>
			<span>-</span>
		</S.StatusContainer>
	);
};
