import { useEffect, useState } from 'react';
import { Benefit, Collaborator, Group } from '../../../../../@types';
import { BenefitsSelector } from '../../../../../components/BenefitsSelector';
import { CollabsSelector } from '../../../../../components/CollabsSelector';
import { FilteredCollabsSelector } from '../../../../../components/CollabsSelector/FilteredCollabsSelector';
import { GroupSelector } from '../../../../../components/GroupSelector';
import { Beneficiary } from '../../../../../contexts/PaymentContextV2';
import { usePayment } from '../../../../../hooks/usePaymentV2';
import { MAX_PAYMENTS } from '../../utils/MAX_PAYMENTS';
import * as S from './styles';
import { GenerateQRCodeModal } from '../GenerateQRCodeModal';

interface Props {
	paymentsAmount: number;
}
export function AddToTableOptions({ paymentsAmount }: Props) {
	const {
		beneficiaries,
		benefits,
		selectedBenefits,
		setBeneficiaries,
		setBenefits,
		setSelectedBenefits,
	} = usePayment();

	const [maxCollaboratorsAllowed, setMaxCollaboratorsAllowed] =
		useState(MAX_PAYMENTS);

	useEffect(() => {
		const paymentsLeftAmount = MAX_PAYMENTS - paymentsAmount;
		const beneficiariesAddedAmount = beneficiaries.filter(
			(b) => !b.isGroup
		).length;

		// if the amount of collaborators added to the payment is bigger than the amount of payments
		// then, setMaxCollaboratorsAllowed as the remaining amount of collaborators that can be added
		if (beneficiariesAddedAmount > paymentsAmount) {
			setMaxCollaboratorsAllowed(MAX_PAYMENTS - beneficiariesAddedAmount);
		} else {
			// there are more payments than added collaborators, use the remaing paymentsLeftAmount as maximum
			setMaxCollaboratorsAllowed(paymentsLeftAmount);
		}
	}, [paymentsAmount, beneficiaries]);

	function handleAddNewCollaborators(collaborators: Collaborator[]) {
		setBeneficiaries([
			...beneficiaries,
			...collaborators.map((c) => {
				return {
					...c,
					name: c.first_name + ' ' + c.last_name,
					selected: true,
					isGroup: false,
					benefits: selectedBenefits,
				} as Beneficiary;
			}),
		]);
	}

	function handleAddNewGroups(groups: Group[]) {
		setBeneficiaries([
			...beneficiaries,
			...groups.map((g) => {
				return {
					id: g.id,
					name: g.title,
					isGroup: true,
					collaboratorsAmount: g.total_users,
					selected: true,
					benefits: selectedBenefits,
				} as Beneficiary;
			}),
		]);
	}

	function handleAddNewBenefits(benefitsSelected: Benefit[]) {
		let newBenefits: Benefit[] = [
			...benefitsSelected.map((benefitSelected) => {
				return {
					id: benefitSelected.id,
					title: benefitSelected.title,
					value: parseInt(benefitSelected.value_default!),
				} as Benefit;
			}),
		];

		setBenefits(newBenefits);
		setSelectedBenefits(newBenefits);
	}

	return (
		<S.OptionsContainer>
			<CollabsSelector
				alreadyAddedCollabs={beneficiaries.map((b) => {
					return { ...b } as Collaborator;
				})}
				onAddNewCollaborators={handleAddNewCollaborators}
				onAddCollaboratorsToRemove={() => {}}
				onAddAndRemoveCollabs={() => {}}
				maxCollaboratorsAllowed={maxCollaboratorsAllowed}
			/>
			{/* TEMPORARILY HIDE */}
			{false && (
				<GroupSelector
					alreadyAddedGroups={
						beneficiaries.filter((g) => g.isGroup).map((g) => g.id) as string[]
					}
					onAddNewGroups={handleAddNewGroups}
					onRemoveGroups={() => {}}
				/>
			)}
			<FilteredCollabsSelector
				alreadyAddedCollabs={beneficiaries.map((b) => {
					return { ...b } as Collaborator;
				})}
				onAddNewCollaborators={handleAddNewCollaborators}
				maxCollaboratorsAllowed={maxCollaboratorsAllowed}
				buttonText='Adicionar por filtro'
			/>
			<BenefitsSelector
				alreadyAddedBenefits={benefits}
				onAddNewBenefits={handleAddNewBenefits}
			/>

			<GenerateQRCodeModal />
		</S.OptionsContainer>
	);
}
