import styled from 'styled-components';
import { OptionsButton } from '../../../../components/Button';
import { CurrencyInput as CurrencyInputComponent } from '../../../../components/CurrencyInput';

export const Container = styled.div`
	flex: 1;
	padding: 4rem 7rem 2rem 7rem;
`;

export const Form = styled.form`
	display: flex;
	flex-direction: column;
	margin-top: 2rem;
`;

export const FieldGroupTop = styled.div`
	display: flex;
	column-gap: 3rem;
`;

export const Field = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 2rem;
	width: fit-content;
`;

export const Label = styled.label`
	font-size: 1.6rem;
	margin-bottom: 1rem;
`;

export const CurrencyInput = styled(CurrencyInputComponent)`
	width: 12rem;
	height: 3rem;
	color: var(--dark-gray);
	background-color: rgba(198, 198, 198, 0.33);
	border-radius: 0.4rem;
	border: none;
	padding: 0 1rem;

	&:disabled {
		opacity: 0.7;
		cursor: not-allowed;
	}
`;

export const Submit = styled.button`
	${OptionsButton}
	padding: 0;
	width: 16rem;
	height: 4rem;
`;
