import { useState } from 'react';
import { FaExclamationTriangle } from 'react-icons/fa';
import { Benefit } from '../../../../../../@types';
import Modal from '../../../../../../components/Modal';
import UserCard from '../../../../../../components/UserCard';
import { IndividualFromGroupRepproved } from '../../../../../../contexts/PaymentContextV2';
import { getBenefitParsedTitle } from '../../../../../../utils/benefits/getBenefitParsedTitle';
import { cpfMask } from '../../../../../../utils/masks';
import * as S from './styles';

interface Props {
	individualsRepproved: IndividualFromGroupRepproved[];
	currentBenefit: Benefit;
}
export function GroupCollaboratorsRepproved({
	individualsRepproved,
	currentBenefit,
}: Props) {
	const [open, setOpen] = useState(false);

	return (
		<>
			<S.OpenModalBtn
				onClick={() => setOpen(true)}
				data-rh='Alguns colaboradores do grupo foram reprovados. Clique para ver.'
			>
				<FaExclamationTriangle color='#FF5900' />
			</S.OpenModalBtn>

			<Modal isOpen={open} enableClose onRequestClose={() => setOpen(false)}>
				<S.Container>
					<S.Title>
						Colaboradores do grupo que foram reprovados -{' '}
						{getBenefitParsedTitle(currentBenefit.title)}
					</S.Title>

					<S.Table>
						<thead>
							<S.Row>
								<S.Head>COLABORADOR</S.Head>
								<S.Head>MOTIVO</S.Head>
							</S.Row>
						</thead>

						<tbody>
							{individualsRepproved
								.filter((i) => i.benefitId === currentBenefit.id)
								.map((i) => (
									<S.Row key={i.id}>
										<S.Data>
											<UserCard
												name={i.collaborator.name}
												bottomInfo={cpfMask(i.collaborator.cpf)}
											/>
										</S.Data>
										<S.ErrorData>{i.reason_disapproval}</S.ErrorData>
									</S.Row>
								))}
						</tbody>
					</S.Table>
				</S.Container>
			</Modal>
		</>
	);
}
