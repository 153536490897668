import styled from 'styled-components';
import { OptionsButton } from '../../../../components/Button';

export const Container = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	padding: 4rem 7rem 2rem 7rem;
`;

export const CollaboratorsListTitle = styled.h2`
	font-size: 1.8rem;
	font-weight: 500;
	margin-bottom: 1rem;
	margin-top: 3rem;
`;

export const CollaboratorsList = styled.ul`
	list-style: none;
	display: flex;
	flex-direction: column;
	max-width: 50%;
	max-height: 40rem;
	overflow-y: auto;
	border: 0.15rem solid black;
	margin-bottom: 3rem;
`;

export const CollaboratorCard = styled.li`
	display: flex;
	align-items: center;
	column-gap: 2rem;
	padding: 1rem;
	border-bottom: 0.15rem solid black;
	& > div {
		flex: 1;
	}
	&:last-child {
		border: none;
	}
`;

export const RemoveCollaboratorBtn = styled.button`
	background-color: var(--primary-red);
	padding: 1rem;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	& svg {
		width: 2rem;
		height: 2rem;
		color: var(--white);
	}
`;

export const BottomOptionsContainer = styled.div`
	display: flex;
	align-items: center;
	column-gap: 1rem;
`;

export const AddAllCollaboratorsBtn = styled.button`
	${OptionsButton}
`;
