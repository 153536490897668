import styled from 'styled-components';

export const Container = styled.div`
	flex: 1;
	height: 100vh;
	display: flex;
	background: linear-gradient(#2dd1fc 0%, #27adff 100%);
	font-family: 'Poppins', sans-serif;
	font-weight: 400;
	& > div {
		flex: 1;
	}

	@media (max-width: 1124px) {
		flex-direction: column;
		height: 100%;
	}
`;

export const LeftContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;

	& > img {
		width: 70rem;
	}

	.ResponsiveModelImage {
		display: none;
		margin-top: 2.5rem;
		margin-bottom: -16rem;
		clip-path: inset(o o.2rem 0 0);
	}

	@media (max-width: 1024px) {
		& > img {
			width: 26rem;
		}

		.modelImage {
			display: none;
		}

		.ResponsiveModelImage {
			display: block;
		}
	}
`;

export const RightContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	border-top-left-radius: 3.2rem;
	border-bottom-left-rarius: 3.2rem;
	background-color: var(--white);
	z-index: 1;

	@media (max-width: 1024px) {
		border-bottom-left-radius: 0rem;
		border-top-right-radius: 3.2rem;
	}
`;

export const MainContainer = styled.main`
	width: 40rem;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 2rem;
	padding: 2rem;

	& > img {
		width: 17.2rem;
	}

	& h2 {
		color: var(--dark-gray2);
		font-size: 3.2rem;
		font-weight: 600;
	}

	& p {
		color: var(--dark-gray2);
		font-size: 1.5rem;
	}

	@media (max-width: 1024px) {
		padding: 4rem 2rem 0rem;
		gap: 2rem;
	}
`;

export const Form = styled.form`
	display: flex;
	flex-direction: column;
	row-gap: 2.4rem;

	.passwordWrapper {
		display: flex;
		flex-direction: column;
		gap: 1.6rem;
		align-items: right;

		label {
			color: #929292;
		}

		@media (max-width: 1024px) {
			& > a {
				margin-left: 0 !important;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
			}
		}
	}
`;

export const BottomOptionsContainer = styled.div`
	display: flex;
	column-gap: 2rem;
	justify-content: center;
	padding-top: 2rem;
`;

export const ContentForm = styled.div`
	width: 100%;

	& > div + div {
		margin-top: 1.5rem;
	}
`;
