import { useState } from 'react';
import { FaRegQuestionCircle } from 'react-icons/fa';
import Modal from '../../../../../components/Modal';
import KYC_RESULTS from './KYC_RESULTS.json';
import * as S from './styles';

export interface KycModalProps {
	kycDetails: string | undefined;
}

export function ModalKycReprovedDetails({ kycDetails }: KycModalProps) {
	const [isOpen, setIsOpen] = useState(false);

	function getModalContent() {
		if (kycDetails === 'Divergent Basic Data') {
			return (
				<>
					<S.ModalTitle>Detalhamento: Dados Básicos Divergentes</S.ModalTitle>
					<S.Divider />
					<S.ModalText>
						Você deve checar os seguintes campos para realizar a correção:
					</S.ModalText>
					<S.ModalList>
						<S.ListItem>CPF</S.ListItem>
						<S.ListItem>Primeiro nome</S.ListItem>
						<S.ListItem>Sobrenome</S.ListItem>
						<S.ListItem>Data de nascimento</S.ListItem>
					</S.ModalList>
					<S.ModalText>
						Também verifique se o CPF está regular na receita federal. Consulta
						através do{' '}
						<a
							href='https://servicos.receita.fazenda.gov.br/Servicos/CPF/ConsultaSituacao/ConsultaPublica.asp'
							target={'_blank'}
							rel='noreferrer noopener'
						>
							link
						</a>
						.
					</S.ModalText>
				</>
			);
		} else if (kycDetails === 'Divergent Complementary Data') {
			return (
				<>
					<S.ModalTitle>
						Detalhamento: Dados Complementares Divergentes
					</S.ModalTitle>
					<S.Divider />
					<S.ModalText>
						Você deve checar os seguintes campos para realizar a correção:
					</S.ModalText>
					<S.ModalList>
						<S.ListItem>Nome da mãe</S.ListItem>
						<S.ListItem>Endereço</S.ListItem>
					</S.ModalList>
				</>
			);
		} else {
			const kyc_result =
				KYC_RESULTS[String(kycDetails) as keyof typeof KYC_RESULTS];

			if (!kyc_result) {
				return (
					<>
						<S.ModalTitle>
							Detalhamento: Rejeitado por políticas internas
						</S.ModalTitle>
						<S.Divider />
						<S.ModalText>
							Mais informações devem ser enviadas via ticket de suporte.
						</S.ModalText>
					</>
				);
			}

			return (
				<>
					<S.ModalTitle>
						Detalhamento: Campo(s) de '{kyc_result.param}' inválido(s).
					</S.ModalTitle>
					<S.Divider />
					<S.ModalText>{kyc_result.msg}.</S.ModalText>

					{kyc_result.param === 'CPF' && (
						<S.ModalText>
							Consultar CPF através do{' '}
							<a
								href='https://servicos.receita.fazenda.gov.br/Servicos/CPF/ConsultaSituacao/ConsultaPublica.asp'
								target={'_blank'}
								rel='noreferrer noopener'
							>
								link
							</a>
							.
						</S.ModalText>
					)}
				</>
			);
		}
	}

	return (
		<>
			<FaRegQuestionCircle onClick={() => setIsOpen(true)} />

			<Modal
				isOpen={isOpen}
				onRequestClose={() => setIsOpen(false)}
				enableClose
			>
				<S.ModalContainer>{getModalContent()}</S.ModalContainer>
			</Modal>
		</>
	);
}
