import { View, Text } from '@react-pdf/renderer';
import styled from 'styled-components';
import { BalanceMovement } from '../../../../services/queries/Corpway/Funds';
import {
	formatCardNumber,
	maskCardNumber,
} from '../../../../utils/formatCardNumber';

export function parseMovementType(type: string, action?: string) {
	switch (type) {
		case 'billing':
			return 'Recarga de carteira';
		case 'pix':
			return 'Recarga de carteira';
		case 'card_fund':
			return action === 'reverse'
				? 'Estorno para carteira'
				: 'Repasse para cartão';
		case 'funds_out':
			return "Transferência externa"
		default:
			return type;
	}
}

export function parseMovementDescription(movement: BalanceMovement) {
	const movementType = parseMovementType(movement.type, movement.action);

	switch (movementType) {
		case 'Recarga de carteira':
			return {
				operationType: movementType,
				operationDetail: `Recebido via ${
					movement.type === 'billing' ? 'boleto' : 'pix'
				}`,
			};
		case 'Repasse para cartão':
			return {
				operationType: movementType,
				operationDetail: `Transferido para cartão ${formatCardNumber(
					movement.card!.pan
				)}`,
			};
		case 'Estorno para carteira':
			return {
				operationType: movementType,
				operationDetail: `Recebido do cartão ${formatCardNumber(
					movement.card!.pan
				)}`,
			};
		case 'Transferência externa':
			return {
				operationType: movementType,
				operationDetail: `${movement.beneficiary?.name} - ${movement.transfer_type?.toUpperCase()}`
			}
		default:
			return {
				operationType: movementType,
				operationDetail: '',
			};
	}
}

export function parseMovementFinancialImpact(type: string, action?: string) {
	switch (type) {
		case 'billing':
		case 'pix':
			return 'CREDIT';
		case 'card_fund':
			return action === 'reverse' ? 'CREDIT' : 'DEBIT';
		default:
			return type;
	}
}

export function parseMovementDetailsToTextOnly(movement: BalanceMovement) {
	switch (movement.type) {
		case 'billing':
			return 'Transação via Boleto';
		case 'pix':
			return 'Transação via Pix';
		case 'card_fund':
			return `Cartão: ${movement.card?.alias} | ${maskCardNumber(
				movement.card!.pan
			)}`;
		default:
			return movement.type;
	}
}

export function parseMovementDetailsToPDFReport(movement: BalanceMovement) {
	let secondaryText = '';
	let mainText = '';

	switch (movement.type) {
		case 'billing':
			secondaryText = 'Transação via ';
			mainText = 'Boleto';
			break;
		case 'pix':
			secondaryText = 'Transação via ';
			mainText = 'Pix';
			break;
		case 'card_fund':
			secondaryText = 'Cartão: ';
			mainText = `${
				movement.card?.alias.length! >= 9
					? movement.card?.alias.substring(0, 8).trim() + '...'
					: movement.card?.alias
			} | **** ${movement.card?.pan.slice(-4)}`;
			break;
		default:
			secondaryText = movement.type;
	}

	return (
		<View style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
			<Text>{secondaryText}</Text>
			<Text style={{ fontWeight: 'bold' }}>{mainText}</Text>
		</View>
	);
}

interface StatusColor {
	background: string;
	text: string;
	dot: string;
	widthToReport: string;
}

const statusColors: { [key: string]: StatusColor } = {
	created: {
		background: '#D4EFFF',
		text: '#0067A7',
		dot: '#0067A7',
		widthToReport: '50px',
	},
	paid: {
		background: '#ECFDF3',
		text: '#006F1F',
		dot: '#006F1F',
		widthToReport: '60px',
	},
	error: {
		background: '#FFEBF0',
		text: '#CC002C',
		dot: '#CC002C',
		widthToReport: '35px',
	},
	processing: {
		background: '#FFF6C7',
		text: '#7B6700',
		dot: '#7B6700',
		widthToReport: '73px',
	},
	enqueued: {
		background: '#FFF6C7',
		text: '#7B6700',
		dot: '#7B6700',
		widthToReport: '73px',
	},
	initiated: {
		background: '#FFF6C7',
		text: '#7B6700',
		dot: '#7B6700',
		widthToReport: '73px',
	},
	processing_payment: {
		background: '#FFF6C7',
		text: '#7B6700',
		dot: '#7B6700',
		widthToReport: '73px',
	},
	overdue: {
		background: '#fdcab9',
		text: '#fd591d',
		dot: '#fd723f',
		widthToReport: '55px',
	},
	scheduled: {
		background: '#D4EFFF',
		text: '#0067A7',
		dot: '#0067A7',
		widthToReport: '50px',
	},
	pending: {
		background: '#FFF6C7',
		text: '#7B6700',
		dot: '#7B6700',
		widthToReport: '73px',
	},
	default: {
		background: '#ffffff',
		text: '#000000',
		dot: '#000000',
		widthToReport: '50px',
	},
};

const StatusComponent = styled.div<{ statusColor: StatusColor }>`
	width: 100%;
	max-width: 140px;
	max-height: 29px;
	justify-content: center;
	display: flex;
	align-items: center;
	padding: 0.4rem 1rem;
	border-radius: 50px;
	background-color: ${(props) => props.statusColor.background};
	color: ${(props) => props.statusColor.text};
`;

const Dot = styled.div<{ statusColor: StatusColor }>`
	width: 10px;
	height: 10px;
	border-radius: 50%;
	margin-right: 10px;
	background-color: ${(props) => props.statusColor.dot};
`;

export function parseMovementStatus(status: string) {
	const statusColor = statusColors[status] || statusColors.default;

	return (
		<StatusComponent statusColor={statusColor}>
			<Dot statusColor={statusColor} />
			{parseMovementStatusToTextOnly(status)}
		</StatusComponent>
	);
}

export function parseMovementStatusToReport(status: string) {
	const statusColor = statusColors[status] || statusColors.default;

	const statusText = parseMovementStatusToTextOnly(status);

	return (
		<View
			style={{
				display: 'flex',
				flexDirection: 'row',
				columnGap: 1,
				alignItems: 'center',
				paddingVertical: '1',
				paddingHorizontal: '3',
				borderRadius: '50px',
				width: statusColor.widthToReport,
				backgroundColor: statusColor.background,
				color: statusColor.text,
			}}
		>
			<View
				style={{
					width: '6px',
					height: '6px',
					borderRadius: '50%',
					marginRight: '3px',
					backgroundColor: statusColor.dot,
				}}
			/>
			<Text
				style={{
					fontFamily: 'Poppins',
					fontSize: 9,
				}}
			>
				{statusText}
			</Text>
		</View>
	);
}

export function parseMovementStatusToTextOnly(status: string) {
	switch (status) {
		case 'created':
			return 'Emitido';
		case 'paid':
			return 'Finalizado';
		case 'error':
			return 'Erro';
		case 'processing':
		case 'enqueued':
		case 'initiated':
		case 'processing_payment':
			return 'Processando';
		case 'overdue':
			return 'Expirado';
		case 'scheduled':
			return "Agendado"
		case 'pending':
			return "Processando"
		default:
			return '????';
	}
}
