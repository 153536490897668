import { useEffect, useState } from 'react';
import { FaUsers } from 'react-icons/fa';
import { Beneficiary } from '../../contexts/PaymentContextV2';
import { cpfMask } from '../../utils/masks';
import Modal from '../Modal';
import UserCard from '../UserCard';
import * as S from './styles';

export interface Props {
	beneficiaries: Beneficiary[];
}

function BeneficiariesWithBenefitsWithoutValueModal({ beneficiaries }: Props) {
	const [open, setOpen] = useState(false);

	useEffect(() => {
		if (!beneficiaries.length) return;

		setOpen(true);
	}, [beneficiaries]);

	function handleClose() {
		setOpen(false);
	}

	return (
		<Modal isOpen={open} enableClose onRequestClose={handleClose}>
			<S.Container>
				<S.Title>
					Beneficiados selecionados que possuem todos os benefícios com valor R$
					0,00
				</S.Title>

				<S.Table>
					<tbody>
						{beneficiaries.map((beneficiary) => (
							<S.Row key={beneficiary.id}>
								<S.Data>
									{beneficiary.isGroup ? (
										<S.GroupCard>
											<FaUsers />
											<S.GroupInfoContainer>
												<span>{beneficiary.name}</span>
												<S.GroupCollaborators>
													{beneficiary.collaboratorsAmount} colaboradores
												</S.GroupCollaborators>
											</S.GroupInfoContainer>
										</S.GroupCard>
									) : (
										<UserCard
											name={beneficiary.name}
											bottomInfo={cpfMask(beneficiary.cpf!)}
											avatar_url={beneficiary.avatar_url}
										/>
									)}
								</S.Data>
							</S.Row>
						))}
					</tbody>
				</S.Table>

				<S.HintText>
					DICA: Inclua um valor em pelo menos um benefício ou não inclua o
					beneficiado no pagamento
				</S.HintText>

				<S.ConfirmButton onClick={handleClose}>Entendi</S.ConfirmButton>
			</S.Container>
		</Modal>
	);
}

export { BeneficiariesWithBenefitsWithoutValueModal };
