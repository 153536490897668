import styled from 'styled-components';
import { Card } from '../../../../@types/CorporateExpenses/Card';
import { Typography } from '../../../../componentsV2/ui/Typography';
import { convertCentsToFormattedReais } from '../../../../utils/CurrencyConvert';
import { CardInfo } from './CardInfo';

interface Props {
	cards: Card[];
	height?: string;
}
export function CardsList({ cards, height, ...rest }: Props) {
	return (
		<CardsListContainer height={height}>
			{cards.map((card) => (
				<CardInfoContainer key={card.id}>
					<CardInfo alias={card.alias} pan={card.pan} small />

					<Typography color='var(--dark-gray)' weight='600'>
						{convertCentsToFormattedReais(card.balance)}
					</Typography>
				</CardInfoContainer>
			))}
		</CardsListContainer>
	);
}

export const CardsListContainer = styled.ul<{ height?: string }>`
	margin-top: 1rem;
	list-style: none;
	max-height: ${(props) => props.height || '4rem'};
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	row-gap: 2rem;

	&::-webkit-scrollbar {
		width: 1rem;
		height: 1rem;
	}
	&::-webkit-scrollbar-track {
		background-color: #e9eaeb;
		border-radius: 0.6rem;
	}
	&::-webkit-scrollbar-thumb {
		background-color: #258bdb;
		border-radius: 0.6rem;
	}
`;
export const CardInfoContainer = styled.li`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-bottom: 0.6rem;
	padding-right: 1rem;
	border-bottom: 0.2rem solid #f2f2f2;
`;
