import styled from 'styled-components';
import { Card as CardType } from '../../../../@types/CorporateExpenses/Card';
import Modal from '../../../../componentsV2/ui/Modal';
import { InfoContent } from '../../../../componentsV2/InfoContent';
import { Button } from '../../../../componentsV2/ui/Button';
import { PasswordInput } from '../../../../componentsV2/ui/Form/PasswordInput';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { useValidateCorpwayUserPassword } from '../../../../hooks/useValidateCorpwayUserPassword';
import { useAuth } from '../../../../hooks/useAuth';
import { useMutation, useQueryClient } from 'react-query';
import {
	CORPWAY_CARDS_KEY,
	updateCardStatus,
} from '../../../../services/queries/Corpway/Cards';
import { CORPWAY_COST_CENTER_KEY } from '../../../../services/queries/Corpway/CostCenters';
import { showErrorMessage } from '../../../../utils/ErrorHandler';
import {
	TitleTypography,
	Typography,
} from '../../../../componentsV2/ui/Typography';
import { convertCentsToFormattedReais } from '../../../../utils/CurrencyConvert';
import { FaCheck, FaLock } from 'react-icons/fa';
import { formatCardNumber } from '../../../../utils/formatCardNumber';
import { Field } from '../../../../componentsV2/ui/Form/Field';
import { Carousel } from '../../../../componentsV2/Carousel';

interface Props {
	card: CardType;
}

interface FieldsRowProps {
	align?: boolean;
}

interface FormData {
	password: string;
	reverseValue: boolean | null;
}

interface Action {
	label: string;
	value: string;
}

const Actions: Action[] = [
	{
		label: 'Estornar saldo',
		value: 'Estornar saldo',
	},
	{
		label: 'Manter saldo',
		value: 'Manter saldo',
	},
];

export function BlockCardConfirmationModal(props: Props) {
	const { currentCompany, user } = useAuth();
	const [isOpen, setOpen] = useState(false);
	const [isSuccess, setSuccess] = useState(false);
	const queryClient = useQueryClient();
	const [currentStep, setCurrentStep] = useState<0 | 1 | 2>(0);
	const [selectAction, setSelectAction] = useState<Action>(Actions[0]);

	const accessLevel = user.roles.includes('manage-balances');

	const {
		register,
		watch,
		reset,
		setValue,
		formState: { errors },
		setError,
		clearErrors,
	} = useForm<FormData>({
		defaultValues: {
			password: '',
			reverseValue: null,
		},
	});
	const passwordInputValue = watch('password');
	const reverseValue = watch('reverseValue');

	const { validateUserPassword, isPasswordValidationLoading } =
		useValidateCorpwayUserPassword({
			onSuccess: onPasswordValidationSuccess,
			onFailure: () =>
				setError('password', {
					type: 'manual',
					message: 'Senha incorreta, por favor digite novamente',
				}),
		});

	const updateStatusMutation = useMutation(
		() =>
			updateCardStatus(
				props.card.id,
				'blocked',
				reverseValue!,
				currentCompany!.id
			),
		{
			onSuccess: () => {
				setSuccess(true);
			},
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível alterar o status do cartão. '
				);
			},
		}
	);

	function handleClose() {
		setOpen(false);
		setSuccess(false);

		reset();
	}

	function handleCloseSuccess() {
		queryClient.resetQueries(CORPWAY_CARDS_KEY);
		queryClient.invalidateQueries(CORPWAY_COST_CENTER_KEY);
		setOpen(false);
		setSuccess(false);

		reset();
	}

	function setReverseValue(value: boolean | null) {
		setValue('reverseValue', value, { shouldTouch: true });
	}

	function onPasswordValidationSuccess() {
		updateStatusMutation.mutate();
	}

	function handleSubmit() {
		validateUserPassword(passwordInputValue);
	}

	const SucessContent = () => {
		return (
			<Container>
				<HeaderContainer>
					<IconContainer>
						<FaCheck size={28} color='white' />
					</IconContainer>

					<TitleTypography size='2rem' primaryText='' weight='400'>
						Cartão&nbsp;<span style={{ fontWeight: 600 }}>bloqueado</span>
						&nbsp;com sucesso
					</TitleTypography>
				</HeaderContainer>
				<BodyContainer style={{ height: '12rem' }}>
					<BottomOptionsContainer style={{ paddingTop: '3.5rem' }}>
						<Button
							intent='terciary'
							roundness='lg'
							$outline
							onClick={handleCloseSuccess}
							width='adjusted'
						>
							Voltar
						</Button>
					</BottomOptionsContainer>
				</BodyContainer>
			</Container>
		);
	};

	const Header = () => {
		return (
			<HeaderContainer>
				<IconContainer>
					<FaLock size={28} color='white' />
				</IconContainer>

				<TitleTypography
					size='2rem'
					color_primary='inherit'
					primaryText='Bloquear'
				>
					<span style={{ fontWeight: 400 }}>cartão</span>
				</TitleTypography>
			</HeaderContainer>
		);
	};

	const One = () => {
		return (
			<>
				<Header />

				<BodyContainer>
					<FieldsRow align={true}>
						<Field label='Escolha o que fazer'>
							<Carousel mode='draggable' gap='1rem'>
								{Actions.map((e) => (
									<ChooseButtonsContainer
										key={e.label}
										isActive={selectAction.value === e.value}
										onClick={() => {
											setSelectAction(e);
											if (e.label === 'Estornar saldo') {
												setReverseValue(true);
											} else {
												setReverseValue(false);
											}
										}}
									>
										{e.label}
									</ChooseButtonsContainer>
								))}
							</Carousel>
						</Field>
					</FieldsRow>

					<InformationContainer>
						<CardInfoContainer>
							<InfoContent label='Nome do cartão'>
								{props.card.alias}
							</InfoContent>
							<InfoContent label='Número do cartão'>
								{formatCardNumber(props.card.pan)}
							</InfoContent>
						</CardInfoContainer>

						<Typography
							color='var(--primary-blue)'
							weight='600'
							style={{ textDecoration: 'underline' }}
						>
							Limite atual do cartão:{' '}
							{convertCentsToFormattedReais(props.card.balance)}
						</Typography>
						<WarningMessage style={{ textAlign: 'left' }}>
							Obs: Você pode escolher entre{' '}
							<span style={{ fontWeight: 'bold', color: '#575757' }}>
								manter o saldo
							</span>{' '}
							alocado ao cartão bloqueado se desejar desbloqueá-lo no futuro, ou
							optar por{' '}
							<span style={{ fontWeight: 'bold', color: '#575757' }}>
								estornar automaticamente
							</span>{' '}
							esse valor para a conta principal
						</WarningMessage>
					</InformationContainer>

					<BottomOptionsContainer>
						<Button
							intent='terciary'
							$outline
							roundness='lg'
							onClick={() => {
								reset();
								setOpen(false);
							}}
						>
							Voltar
						</Button>

						<Button
							intent='primary'
							roundness='lg'
							onClick={() => {
								setCurrentStep(1);
							}}
						>
							Continuar
						</Button>
					</BottomOptionsContainer>
				</BodyContainer>
			</>
		);
	};

	const Two = () => {
		const formatNumber = (pan: string) => {
			const format = pan.slice(-8);
			return format;
		};
		return (
			<>
				<Header />

				<BodyContainer style={{ height: '30rem' }}>
					<ConfirmationModal>
						<WarningMessage>
							Seu cartão final {formatNumber(props.card.pan)} será bloqueado e o
							saldo restante será{' '}
							<span>
								{reverseValue ? 'estornado para a carteira' : 'manter o saldo'}.
							</span>{' '}
							Você tem certeza que deseja continuar?
						</WarningMessage>
						<PasswordInput
							label='Insira a sua senha'
							id='password'
							name='password'
							register={register}
							showLeftIcon={true}
							autoFocus
							autoComplete='new-password'
							errorMessage={errors.password?.message}
						/>
					</ConfirmationModal>
					<BottomOptionsContainer>
						<Button
							intent='terciary'
							roundness='lg'
							$outline
							width='adjusted'
							onClick={() => {
								clearErrors('password');
								setCurrentStep(0);
							}}
						>
							Voltar
						</Button>
						<Button
							loading={
								isPasswordValidationLoading || updateStatusMutation.isLoading
							}
							roundness='lg'
							onClick={handleSubmit}
							width='full'
							style={{
								paddingLeft: '2rem',
								paddingRight: '2rem',
								width: '17rem',
							}}
							disabled={!passwordInputValue}
						>
							Efetuar bloqueio
						</Button>
					</BottomOptionsContainer>
				</BodyContainer>
			</>
		);
	};

	const Confirmation = () => {
		return (
			<>
				<Header />

				<BodyContainer style={{ height: '27rem' }}>
					<div
						style={{
							paddingLeft: '0',
							paddingRight: '0',
							paddingTop: '2rem',
							paddingBottom: '3rem',
							rowGap: '3rem',
							display: 'flex',
							flexDirection: 'column',
							width: '85%',
						}}
					>
						<WarningMessage>
							Você tem certeza que deseja bloquear o cartão?
						</WarningMessage>

						<PasswordInput
							label='Insira a sua senha'
							id='password'
							name='password'
							register={register}
							showLeftIcon={true}
							autoComplete='new-password'
							autoFocus
							errorMessage={errors.password?.message}
						/>
					</div>
					<BottomOptionsContainer>
						{props.card.balance !== 0 ? (
							<Button
								intent='terciary'
								roundness='lg'
								$outline
								onClick={() => {
									setCurrentStep(1);
									clearErrors('password');
								}}
							>
								Voltar
							</Button>
						) : (
							<Button
								intent='terciary'
								roundness='lg'
								$outline
								onClick={() => {
									clearErrors('password');
									setOpen(false);
								}}
								width='contain'
							>
								Cancelar
							</Button>
						)}
						<Button
							loading={
								isPasswordValidationLoading || updateStatusMutation.isLoading
							}
							roundness='lg'
							onClick={() => handleSubmit()}
							width='full'
							style={{
								paddingLeft: '2rem',
								paddingRight: '2rem',
								width: '17rem',
							}}
							disabled={!passwordInputValue}
						>
							Efetuar bloqueio
						</Button>
					</BottomOptionsContainer>
				</BodyContainer>
			</>
		);
	};

	const RenderContent = () => {
		const CONTENT = {
			0: <One />,
			1: <Two />,
			2: <Confirmation />,
		};

		return CONTENT[currentStep];
	};

	return (
		<>
			{accessLevel ? (
				<Button
					intent='link'
					roundness='lg'
					onClick={() => {
						setOpen(true);
						setReverseValue(true);
					}}
				>
					Bloquear cartão
				</Button>
			) : (
				<Button
					intent='primary'
					roundness='lg'
					onClick={() => {
						setOpen(true);
						setReverseValue(true);
					}}
				>
					Bloquear cartão
				</Button>
			)}

			<Modal
				isOpen={isOpen}
				onRequestClose={() => {
					if (isSuccess) {
						handleCloseSuccess();
					} else {
						handleClose();
						setCurrentStep(0);
						setReverseValue(null);
						setSelectAction(Actions[0]);
					}
				}}
				position='center'
			>
				{isSuccess ? (
					<SucessContent />
				) : (
					<Container>
						{props.card.balance !== 0 ? <RenderContent /> : <Confirmation />}
					</Container>
				)}
			</Modal>
		</>
	);
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	height: fit-content;
	max-height: 90vh;
	width: 47rem;
	align-items: center;
	background: #f0f3f8;

	@media (max-width: 768px) {
		width: 100%;
	}
`;

const HeaderContainer = styled.div`
	padding: 3rem 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	row-gap: 1.5rem;
	width: 100%;
	height: 18.6rem;
`;

export const IconContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 4.5rem;
	height: 4.5rem;
	background-color: var(--primary-blue);
	border-radius: 50px;
`;

const BodyContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: center;
	background-color: var(--white);
	height: 41rem;
	flex-grow: 3rem;
`;

const FieldsRow = styled.div<FieldsRowProps>`
	display: flex;
	width: 100%;
	column-gap: 1rem;
	justify-content: space-between;

	label {
		color: #929292;
		padding-top: 2rem;
	}

	& + & {
		margin-top: 1rem;
	}

	&.information {
		& fieldset:last-child {
			width: 50%;
			align-items: flex-end;

			p {
				text-align: right;
			}
		}
	}

	${(props) =>
		props.align &&
		`
			fieldset {
			align-items: center;

			label {
				font-size: 1.5rem;
			}
		}

		div {
			justify-content: center;
		}
		`}
`;

const ChooseButtonsContainer = styled.div<{ isActive: boolean }>`
	padding: 0.8rem 2rem;
	border-radius: 2rem;
	border: 1px solid
		${({ isActive }) => (isActive ? 'var(--primary-blue)' : 'var(--dark-gray)')};
	width: max-content;
	background-color: ${({ isActive }) =>
		isActive ? 'var(--primary-blue)' : 'transparent'};
	color: ${({ isActive }) => (isActive ? 'var(--white)' : 'var(--dark-gray)')};
	font-size: 1.2rem;
	cursor: pointer;
`;

const ConfirmationModal = styled.div`
	display: flex;
	flex-direction: column;
	padding: 3rem 4rem 3rem 4rem;
	gap: 2.4rem;
`;

const InformationContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding: 1rem 4rem 3rem 4rem;
	gap: 1rem;
`;

const CardInfoContainer = styled.div`
	display: flex;
	justify-content: space-between;

	& div:last-child {
		align-items: flex-end;
	}
`;
const WarningMessage = styled.span`
	font-size: 1.4rem;
	font-family: Poppins;
	font-weight: 400;
	line-height: 2.1rem;
	text-align: center;

	& > span {
		font-weight: 600;
		color: var(--primary-blue);
	}
`;
const BottomOptionsContainer = styled.div`
	display: flex;
	column-gap: 2rem;
	justify-content: center;
	margin-bottom: 2rem;
`;
