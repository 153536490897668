import { useCallback, useEffect, useState } from 'react';
import Modal from '../../../../../components/Modal';
import PageTitle from '../../../../../components/PageTitle';
import * as S from './styles';
import { toast } from 'react-toastify';
import { useMutation } from 'react-query';
import Loader from '../../../../../components/Loader';
import {
	generateQRCode,
	sendQRCodeByEmail,
} from '../../../../../services/queries/Tranfers';
import { useAuth } from '../../../../../hooks/useAuth';
import { convertReaisToCents } from '../../../../../utils/CurrencyConvert';
import { showErrorMessage } from '../../../../../utils/ErrorHandler';
import { parseCurrencyToBRLStandard } from '../../../../../utils/parseCurrency';
import { usePayment } from '../../../../../hooks/usePaymentV2';
import { calculateBenefitsTotals } from '../../utils/calculateBenefitsTotals';
import { generateQRCodeImage } from '../../../../../utils/generateQRcodeImage';

export function GenerateQRCodeModal() {
	const { currentCompany } = useAuth();
	const { schedule, beneficiaries } = usePayment();
	const [isOpen, setOpen] = useState(false);
	const [qrCodeImg, setQRCodeImg] = useState('');
	const [value, setValue] = useState(0);

	const generateQRcode = useCallback(() => {
		const benefitsTotalValue = calculateBenefitsTotals(
			beneficiaries,
			true
		).total;

		if (benefitsTotalValue === 0) {
			toast.error('Valor abaixo do mínimo.');
			return;
		}
		setValue(benefitsTotalValue);
		const amountInCents = convertReaisToCents(benefitsTotalValue);
		generateQRCodeMutation.mutate(amountInCents);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [beneficiaries]);

	useEffect(() => {
		if (isOpen) {
			setQRCodeImg('');

			generateQRcode();
		}
	}, [isOpen, generateQRcode]);

	const generateQRCodeMutation = useMutation(
		async (amount: number) =>
			generateQRCode(amount, 'multiflex', currentCompany!.id),
		{
			onError: () => {
				toast.error(
					'Ocorreu um problema ao tentar gerar o QRCode. Tente novamente.'
				);
			},
			onSuccess: async (data) => {
				setQRCodeImg(await generateQRCodeImage(data.emv));
			},
		}
	);

	const sendQRCodeByEmailQuery = useMutation(
		() =>
			sendQRCodeByEmail(
				generateQRCodeMutation.data?.emv!,
				value,
				currentCompany!.id
			),
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao tentar enviar o QRCode. '
				);
			},
			onSuccess: () => {
				toast.info(
					'QR code enviado com sucesso via email para o setor financeiro.'
				);
			},
		}
	);

	function handleCopyQRCode() {
		navigator.clipboard.writeText(generateQRCodeMutation.data!.emv);

		toast.info('Código PIX copiado!', {
			autoClose: 3500,
		});
	}

	return (
		<>
			<S.OpenButton onClick={() => setOpen(true)} disabled={!schedule}>
				Gerar QRCode para pagamento
			</S.OpenButton>

			<Modal isOpen={isOpen} onRequestClose={() => setOpen(false)} enableClose>
				<S.Container>
					<PageTitle title='Gerar QRCode para pagamento' />

					{!!qrCodeImg ? (
						<S.Content>
							<S.QRCodeFigure>
								<S.QRCodeImg src={qrCodeImg} alt='QRcode gerado' />
								<S.QRcodeCaption>
									Solicitação de pagamento* gerado por QR Code no valor de{' '}
									<b>{parseCurrencyToBRLStandard(value)}</b>
								</S.QRcodeCaption>
							</S.QRCodeFigure>

							<S.QRCodeButtonsContainer>
								<S.CopyQRCodeBtn onClick={handleCopyQRCode}>
									Copiar código
								</S.CopyQRCodeBtn>
								<S.CopyQRCodeBtn
									onClick={() => sendQRCodeByEmailQuery.mutate()}
								>
									{sendQRCodeByEmailQuery.isLoading ? (
										<Loader color='#fff' size={15} />
									) : (
										'Enviar via email'
									)}
								</S.CopyQRCodeBtn>
							</S.QRCodeButtonsContainer>
							<S.Caption>
								<span>*</span>Pagamento efetuado via Pix
							</S.Caption>
						</S.Content>
					) : (
						<Loader />
					)}
				</S.Container>
			</Modal>
		</>
	);
}
