import styled from "styled-components";
import { OptionsButton } from "../../Button";

export const OpenModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.8rem;
  align-items: center;
`;

export const OpenModalButton = styled.button`
  ${OptionsButton}
  padding: 1.2rem 3rem;
  background-color: var(--white);
  color: var(--dark-gray);
  border: 0.2rem solid var(--dark-gray);
`;

export const ShareholdersAmountInfo = styled.span`
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  & svg {
    color: var(--primary-blue);
    margin-right: 0.6rem;
    width: 1.5rem;
    height: 1.5rem;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CloseModalBtn = styled.button`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  border: 0;
  border-radius: 0.2rem;
  /* padding: 0.5rem; */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-red);
  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.9);
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 65rem;
  margin-top: 1rem;
  min-height: 40rem;
  height: 50rem;
  max-height: 50rem;
  justify-content: space-between;
  margin-bottom: 2rem;
`;

export const EmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 3rem;
  border-bottom: 0.2rem solid var(--light-gray);
  border-top: 0.2rem solid var(--light-gray);
`;

export const AddNewShareholders = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 1rem auto;
  width: 6.25rem;
  height: 6.25rem;
  background-color: var(--white);
  color: var(--primary-blue);
  border-radius: 50%;
  border: 0.2rem solid var(--primary-blue);
  font-weight: 600;
  & svg {
    width: 2.5rem;
    height: 2.5rem;
    padding-bottom: 0.5rem;
  }
`;

export const ShareholdersList = styled.ul`
  border-top: 0.2rem solid var(--light-gray);
  display: flex;
  flex-direction: column;
  font-size: 1.4rem;
  max-height: 36rem;
  margin-bottom: 2rem;
  overflow-y: auto;
  & span {
    font-weight: 600;
  }
`;

export const ShareholderContainer = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.1rem solid var(--light-gray);
  padding: 1rem 2rem;
`;

export const ShareholdersOptions = styled.div`
  display: flex;
  column-gap: 2rem;
`;

export const ShareholdersOption = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: none;
  color: var(--light-gray);
  & div {
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--light-gray);
    border-radius: 50%;
    margin-bottom: 0.6rem;
    & svg {
      color: var(--white);
    }
  }
`;

export const BottomOptionsContainer = styled.div`
  display: flex;
  margin-top: auto;
  justify-content: end;
  column-gap: 1rem;
`;

export const SaveButton = styled.button`
  ${OptionsButton}
  /* width: min-content; */
  padding: 0.6rem 6rem;
`;

export const BackButton = styled.button`
  ${OptionsButton}
  background-color: var(--white);
  color: var(--primary-blue);
  padding: 0.6rem 6rem;
`;
