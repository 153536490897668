import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { EmptyContent } from '../../../../components/EmptyContent';
import Loader from '../../../../components/Loader';
import PageTitle from '../../../../components/PageTitle';
import { useAuth } from '../../../../hooks/useAuth';
import { getPartnerQuantitative } from '../../../../services/queries/Partners';
import { showErrorMessage } from '../../../../utils/ErrorHandler';
import * as S from './styles';
import * as TableStyle from '../../../../components/Table/TableStyles';
import { parseDateToMonthYear } from '../../../../utils/parseDate';
import { useSortColumnHook } from '../../../../hooks/useSortColumnHook';
import { SortColumnButton } from '../../../../components/SortColumnButton';

interface PartnerQuantitativeURL {
	partner_id: string;
}

export function PartnerQuantitative() {
	const { partner_id } = useParams<PartnerQuantitativeURL>();
	const { currentCompany } = useAuth();
	const { currentSortColumn, sortList, toggleSort } = useSortColumnHook();

	const fetchQuantitativeQuery = useQuery(
		['fetch-quantitative', partner_id, currentCompany?.id],
		() => getPartnerQuantitative(partner_id, currentCompany!.id),
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível buscar o quantitativo do parceiro. '
				);
			},
			refetchOnWindowFocus: false,
			enabled: !!partner_id && !!currentCompany?.id,
		}
	);

	if (!fetchQuantitativeQuery.data) {
		return (
			<S.Container>
				<PageTitle title='Quantitativo de parceiro' />
				<Loader />
			</S.Container>
		);
	}
	return (
		<S.Container>
			<PageTitle title='Quantitativo de parceiro' />

			<S.CurrentQuantitative>
				Quantitativo atual: {fetchQuantitativeQuery.data.currentRecord}
			</S.CurrentQuantitative>

			{fetchQuantitativeQuery.data.partnerRecords.length === 0 ? (
				<EmptyContent text='Não há ítens a serem exibidos.' />
			) : (
				<TableStyle.Table>
					<TableStyle.TableHeader>
						<TableStyle.TableRow>
							<TableStyle.TableHeaderCell>
								<SortColumnButton
									order={
										currentSortColumn?.name === 'registration_date'
											? currentSortColumn.order
											: null
									}
									onToggleSort={() => toggleSort('registration_date')}
								/>
								PERIODO
							</TableStyle.TableHeaderCell>
							<TableStyle.TableHeaderCell>
								<SortColumnButton
									order={
										currentSortColumn?.name === 'total_collaborator'
											? currentSortColumn.order
											: null
									}
									onToggleSort={() => toggleSort('total_collaborator')}
								/>
								COLABORADORES
							</TableStyle.TableHeaderCell>
						</TableStyle.TableRow>
					</TableStyle.TableHeader>

					<TableStyle.TableBody>
						{sortList(fetchQuantitativeQuery.data.partnerRecords).map(
							(quantitative) => (
								<TableStyle.TableRow key={quantitative.partner_id}>
									<TableStyle.TableData>
										{parseDateToMonthYear(quantitative.registration_date)}
									</TableStyle.TableData>
									<TableStyle.TableData>
										{quantitative.total_collaborator}
									</TableStyle.TableData>
								</TableStyle.TableRow>
							)
						)}
					</TableStyle.TableBody>
				</TableStyle.Table>
			)}
		</S.Container>
	);
}
