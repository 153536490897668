import styled from 'styled-components';
import { MONTHS } from './MONTHS';

const PaymentDate = styled.span`
	display: flex;
	font-weight: 500;
	& span {
		font-size: 1.2rem;
		color: var(--mid-gray);
	}
`;

const DateContainer = styled.div`
	display: flex;
	flex-direction: column;

	& + & {
		margin-left: 2.5rem;
	}
`;

export function parseDate(
	date: string | number,
	returnType: number,
	monthRef?: number
) {
	const dateObj = new Date(date);

	if (returnType === 1) {
		if (monthRef) {
			return (
				<PaymentDate>
					<DateContainer>
						<span>Referência</span>
						<p>{`${MONTHS[monthRef! - 1]} de ${dateObj.getUTCFullYear()}`}</p>
					</DateContainer>

					<DateContainer>
						<span>Data</span>
						<p>{`${dateObj.getUTCDate()} de ${
							MONTHS[dateObj.getUTCMonth()]
						}`}</p>
					</DateContainer>
				</PaymentDate>
			);
		} else {
			return (
				<PaymentDate>
					<DateContainer>
						{`${dateObj.getUTCDate()} de ${MONTHS[dateObj.getUTCMonth()]}`}
						<span>{dateObj.getUTCFullYear()}</span>
					</DateContainer>
				</PaymentDate>
			);
		}
	} else {
		return `${dateObj.getUTCDate()} de ${
			MONTHS[dateObj.getUTCMonth()]
		} de ${dateObj.getUTCFullYear()}`;
	}
}

export function subtractYears(date: Date, years: number) {
	date.setFullYear(date.getFullYear() - years);
	return date;
}

export function parseDateToMonthYear(date: string) {
	var parts = date.split('/');

	var month = parseInt(parts[1], 10) - 1;
	var year = parseInt(parts[2], 10);

	return `${MONTHS[month]} de ${year}`;
}

export function parseInputToDateMMDD(date: string) {
	const length = date.length;
	try {
		const dateStr = date.substring(length - 2, length);
		const monthStr = date.substring(length - 5, length - 3);
		const yearStr = date.substring(0, 4);

		return `${monthStr}/${dateStr}/${yearStr}`;
	} catch (err) {
		return date;
	}
}
