import { useState, memo } from 'react';
import { FaUsers } from 'react-icons/fa';
import { usePayment } from '../../../../../hooks/usePaymentV2';
import { FaTrashAlt } from 'react-icons/fa';
import * as S from './styles';
import { useDialogModal } from '../../../../../hooks/useDialogModal';
import {
	sortByName,
	SortIconButton,
	SortOrder,
} from '../../../../../components/SortIconButton';
import { getBenefitParsedTitle } from '../../../../../utils/benefits/getBenefitParsedTitle';
import { getBenefitIcon } from '../../../../../utils/benefits/getBenefitIcon';
import { ChangeAllBenefitValuesModal } from './ChangeAllBenefitValuesModal';
import { EmptyContent } from '../../../../../components/EmptyContent';
import UserCard from '../../../../../components/UserCard';
import { cpfMask } from '../../../../../utils/masks';
import { CheckGroupCollaborators } from '../../../../../components/CheckGroupCollaborators';
import { ScheduleInput } from '../../../../../components/ScheduleInput';
import { BenefitInput } from '../BenefitInput';
import { PAYMENT_TABLE_ID } from '../../utils/getBenefitValuesInputs';
import { BenefitsListTotals } from '../BenefitTotals/BenefitsListTotals';
import { BenefitsTotals } from '../BenefitTotals/BenefitsTotal';

export interface PaymentTableEditProps {
	showSchedule?: boolean;
}
function PaymentTableComponent({ showSchedule = true }: PaymentTableEditProps) {
	const {
		beneficiaries,
		setBeneficiaries,
		selectedBenefits,
		isBenefitSelected,
		schedule,
		setSchedule,
	} = usePayment();

	const { openOptionsDialog } = useDialogModal();

	const [beneficiariesSort, setBeneficiariesSort] = useState<SortOrder>('asc');

	const shoudShowDeleteBtn = beneficiaries.some(
		(collaborator) => collaborator.selected
	);

	function handleToggleSelectBeneficiary(beneficiaryId: string) {
		setBeneficiaries(
			beneficiaries.map((beneficiary) => {
				if (beneficiary.id === beneficiaryId) {
					beneficiary.selected = !beneficiary.selected;
				}
				return beneficiary;
			})
		);
	}

	function handleToggleSelectAllBeneficiaries() {
		const allSelected = beneficiaries.every(
			(beneficiary) => beneficiary.selected
		);

		setBeneficiaries(
			beneficiaries.map((beneficiary) => ({
				...beneficiary,
				selected: !allSelected,
			}))
		);
	}

	function removeSelectedBeneficiaries() {
		openOptionsDialog(
			'Deseja excluir os beneficiados selecionados?',
			'Sim',
			() =>
				setBeneficiaries(
					beneficiaries.filter((beneficiary) => beneficiary.selected !== true)
				),
			'Não',
			() => {}
		);
	}

	return (
		<div>
			<S.DeleteButton>
				{shoudShowDeleteBtn && (
					<FaTrashAlt
						onClick={() => removeSelectedBeneficiaries()}
						color='#F27474'
						data-testid='remove-beneficiaries'
					/>
				)}
			</S.DeleteButton>
			<S.Container>
				<S.Table>
					<S.TableHeader>
						<S.TableRow>
							<S.TableHeaderCell>
								<S.UserContainer>
									<S.SelectCollabButton
										name='select-all-beneficiaries'
										title='Selecionar todos os beneficiados'
										type='checkbox'
										onChange={() => {
											handleToggleSelectAllBeneficiaries();
										}}
										checked={beneficiaries.every(
											(beneficiary) => beneficiary.selected
										)}
										data-testid='select-all-beneficiaries'
									/>
									<S.NumberSpan>Nº </S.NumberSpan>
									COLABORADOR
									<SortIconButton
										sort={beneficiariesSort}
										onToggleSort={() => {
											setBeneficiariesSort((old) =>
												old === 'asc' ? 'desc' : 'asc'
											);
										}}
									/>
								</S.UserContainer>
							</S.TableHeaderCell>
							{/* HEADER DISPLAYS ALL SELECTED BENEFITS */}
							{selectedBenefits.map((benefit) => (
								<S.TableHeaderCell key={benefit.id}>
									<div>
										{getBenefitParsedTitle(benefit.title.toUpperCase())}
										{getBenefitIcon(benefit.title.toUpperCase())}
										<ChangeAllBenefitValuesModal benefit={benefit} />
									</div>
								</S.TableHeaderCell>
							))}
						</S.TableRow>
					</S.TableHeader>
					<S.TableBody id={PAYMENT_TABLE_ID}>
						{!beneficiaries.length ? (
							<S.TableRow>
								<S.TableData>
									<EmptyContent text='Nenhum beneficiado adicionado' />{' '}
								</S.TableData>
							</S.TableRow>
						) : (
							sortByName(beneficiariesSort, beneficiaries).map(
								(beneficiary, index) => (
									<S.TableRow key={beneficiary.id}>
										<S.TableData>
											<S.UserContainer>
												<S.SelectCollabButton
													name={`select-${beneficiary.id}`}
													title={`Selecionar ${beneficiary.name}`}
													type='checkbox'
													onChange={() => {
														handleToggleSelectBeneficiary(beneficiary.id);
													}}
													checked={beneficiary.selected}
												/>
												<S.NumberSpan>{index + 1}</S.NumberSpan>
												{beneficiary.isGroup ? (
													<S.GroupCard>
														<FaUsers />
														<S.GroupInfoContainer>
															<span>{beneficiary.name}</span>
															<S.GroupCollaborators>
																{beneficiary.collaboratorsAmount} colaboradores
																<span>
																	(
																	<CheckGroupCollaborators
																		groupId={beneficiary.id!!}
																	/>
																	)
																</span>
															</S.GroupCollaborators>
														</S.GroupInfoContainer>
													</S.GroupCard>
												) : (
													<UserCard
														name={beneficiary.name}
														bottomInfo={cpfMask(beneficiary.cpf!)}
														avatar_url={beneficiary.avatar_url}
													/>
												)}
											</S.UserContainer>
										</S.TableData>
										{beneficiary.benefits.map((benefit) => {
											if (isBenefitSelected(benefit.id)) {
												return (
													<S.TableData key={beneficiary.id + benefit.id}>
														<BenefitInput
															beneficiaryId={beneficiary.id}
															benefitId={benefit.id}
															initialValue={benefit.value}
															data-testid={`input-${beneficiary.id}-${benefit.id}`}
														/>
													</S.TableData>
												);
											}
											return (
												<S.TableData
													key={beneficiary.id + benefit.id}
												></S.TableData>
											);
										})}
									</S.TableRow>
								)
							)
						)}
					</S.TableBody>
					<S.TableFooter>
						<S.TableRow>
							<S.TableData style={{ fontWeight: 'bold' }}>
								Valor total por benefício
							</S.TableData>

							<BenefitsListTotals />
						</S.TableRow>
					</S.TableFooter>
				</S.Table>
			</S.Container>

			<S.BottomInfoContainer>
				<BenefitsTotals />

				{showSchedule && (
					<S.SchedulingContainer>
						<label>Agendamento*</label>
						<ScheduleInput
							value={schedule}
							onChange={(e) => setSchedule(e.target.value)}
						/>
						<S.ScheduleCaption>
							<span>*</span>Opcional
						</S.ScheduleCaption>
					</S.SchedulingContainer>
				)}
			</S.BottomInfoContainer>
		</div>
	);
}

export default memo(PaymentTableComponent);
