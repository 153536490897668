import { Link } from 'react-router-dom';
import styled from 'styled-components';

interface chatButtonProp {
	disabled?: boolean;
}
export const Container = styled.div`
	flex: 1;
	padding: 4rem 7rem 2rem 7rem;
`;

export const FormContainer = styled.div`
	display: flex;
	width: 100%;
`;

export const ChatButton = styled(Link)<chatButtonProp>`
	text-decoration: none;
	display: flex;
	align-items: center;
	justify-content: space-around;

	min-width: 39rem;
	height: 8rem;
	margin-top: 2rem;
	margin-right: 2rem;
	padding: 2rem;
	border-radius: 0.8rem;
	font-weight: bold;
	overflow-wrap: break-word;
	border: 2px solid;
	border-color: var(--primary-blue);
	text-decoration: none;
	color: var(--primary-blue);

	&:hover {
		cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
		box-shadow: 0px 5px 13px 0px rgba(0, 0, 0, 0.2);
	}
`;

export const ChatContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-around;

	min-width: 39rem;
	height: 8rem;
	margin-top: 2rem;
	margin-right: 2rem;
	padding: 2rem;
	border-radius: 0.8rem;
	font-weight: bold;
	overflow-wrap: break-word;
	border: 2px solid;
	border-color: var(--primary-blue);
	color: var(--primary-blue);

	&:hover {
		cursor: not-allowed;
		box-shadow: 0px 5px 13px 0px rgba(0, 0, 0, 0.2);
	}
`;

export const ChatsList = styled.div`
	display: flex;
	flex-wrap: wrap;
`;

export const ChatsListsContainer = styled.div`
	margin-top: 2rem;
`;

export const ChatsDivider = styled.div`
	margin-top: 3rem;
`;

export const ListTitle = styled.h2`
	color: var(--dark-gray);
	font-weight: 400;
	font-size: 2rem;
`;

export const ChatInfoContainer = styled.div`
	display: flex;
	justify-content: space-around;
	align-items: center;
	font-size: 2rem;
	margin: 0 1rem 0 1rem;

	svg {
		color: '#27ADFF';
		height: 2rem;
		width: 2rem;
		margin-left: 0.5rem;
	}
`;

export const ButtonsContainer = styled.div`
	display: flex;
	align-items: flex-end;
	margin-top: 3rem;
`;
