import styled from 'styled-components';

export const UserData = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1.6rem;
	align-items: center;
	width: 32rem;
	justify-self: center;
`;

interface DividerProps extends React.HTMLAttributes<HTMLDivElement> {
	direction?: 'vertical' | 'horizontal';
	color?: string;
}
export const Divider = styled.div<DividerProps>`
	width: ${(props) => (props.direction === 'horizontal' ? '100%' : '1px')};
	height: ${(props) => (props.direction === 'vertical' ? '100%' : '1px')};
	background-color: ${(props) => props.color || 'var(--light-gray)'};
	align-self: flex-end;
`;
