import { useEffect, useState } from 'react';
import { RiAddCircleLine, RiCloseCircleLine } from 'react-icons/ri';
import { useQuery } from 'react-query';
import { Benefit } from '../../@types';
import { useAuth } from '../../hooks/useAuth';
import { getBenefits } from '../../services/queries/Benefits';
import { getBenefitIcon } from '../../utils/benefits/getBenefitIcon';
import { getBenefitParsedTitle } from '../../utils/benefits/getBenefitParsedTitle';
import { sortBenefitsByAlfabeticalOrder } from '../../utils/sortArrayByAlfabeticalOrder';
import { EmptyContent } from '../EmptyContent';
import Loader from '../Loader';
import Modal from '../Modal';
import * as S from './styles';
import { useDialogModal } from '../../hooks/useDialogModal';

interface BenefitsSelectorProps {
	alreadyAddedBenefits: Benefit[];
	onAddNewBenefits(benefits: Benefit[]): void;
}

export function BenefitsSelector({
	alreadyAddedBenefits,
	onAddNewBenefits,
}: BenefitsSelectorProps) {
	const { currentCompany } = useAuth();
	const { openOptionsDialog } = useDialogModal();
	const [open, setOpen] = useState(false);
	const [selectedBenefits, setSelectedBenefits] = useState<Benefit[]>([]);
	const [hasMadeChanges, setHasMadeChanges] = useState(false);
	const fetchBenefitsQuery = useQuery<Benefit[]>(
		['benefits', currentCompany?.id],
		() => {
			return getBenefits(currentCompany?.id);
		},
		{
			enabled: open,
			refetchOnWindowFocus: false,
		}
	);

	useEffect(() => {
		if (open) {
			// reseting
			setSelectedBenefits(alreadyAddedBenefits);
			setHasMadeChanges(false);
		}
	}, [open, alreadyAddedBenefits]);

	function handleSubmit() {
		if (selectedBenefits.length) {
			onAddNewBenefits(selectedBenefits);
		}
		setOpen(false);
	}

	function handleClose() {
		if (!hasMadeChanges) {
			setOpen(false);
			return;
		}

		openOptionsDialog(
			'Alterações não salvas. Tem certeza que deseja sair?',
			'Confirmar',
			() => setOpen(false),
			'Cancelar',
			() => {}
		);
	}

	function getFilteredBenefitsList() {
		// filter benefits that haven't been added to payment list already
		let filteredBenefitsList: Benefit[] = [];
		fetchBenefitsQuery.data?.forEach((benefit) => {
			if (!filteredBenefitsList.find((b) => b.title === benefit.title)) {
				filteredBenefitsList.push(benefit);
			}
		});
		return sortBenefitsByAlfabeticalOrder(filteredBenefitsList);
	}

	function isBenefitSelected(id: string, title: string) {
		return !!selectedBenefits.find(
			(benefit) => benefit.id === id || benefit.title === title
		);
	}

	function handleSelectBenefit(benefit: Benefit) {
		setHasMadeChanges(true);
		setSelectedBenefits([...selectedBenefits, benefit]);
	}

	function handleDeselectBenefit(benefit: Benefit) {
		if (selectedBenefits.length > 1) {
			setHasMadeChanges(true);
			setSelectedBenefits(
				selectedBenefits.filter(
					(b) =>
						getBenefitParsedTitle(b.title) !==
						getBenefitParsedTitle(benefit.title)
				)
			);
		}
	}

	if (fetchBenefitsQuery.isLoading || fetchBenefitsQuery.error) {
		return (
			<div>
				<S.OpenModalButton onClick={() => setOpen(true)}>
					Gerenciar Benefícios
				</S.OpenModalButton>
				<Modal isOpen={open} enableClose onRequestClose={handleClose}>
					<S.Container>
						{fetchBenefitsQuery.isLoading && <Loader />}
						{fetchBenefitsQuery.error && (
							<S.ErrorLine>
								Ocorreu um erro ao buscar os benefícios. Tente novamente.
							</S.ErrorLine>
						)}
					</S.Container>
				</Modal>
			</div>
		);
	}
	return (
		<>
			<S.OpenModalButton onClick={() => setOpen(true)}>
				Gerenciar Benefícios
			</S.OpenModalButton>

			<Modal isOpen={open} enableClose onRequestClose={handleClose}>
				<S.Container data-testid='BenefitsSelector-modal-container'>
					<S.BenefitsList>
						{getFilteredBenefitsList().map((benefit) => (
							<S.BenefitContainer key={benefit.id}>
								<S.BenefitCard>
									<S.BenefitInfoContainer>
										<S.BenefitTitle>
											{getBenefitIcon(benefit.title)}
											{getBenefitParsedTitle(benefit.title)}
										</S.BenefitTitle>
									</S.BenefitInfoContainer>
								</S.BenefitCard>

								{!isBenefitSelected(benefit.id!, benefit.title) ? (
									<S.Selector
										data-testid={`${benefit.id}-select`}
										selected={isBenefitSelected(benefit.id!, benefit.title)}
										onClick={() => handleSelectBenefit(benefit)}
									>
										<RiAddCircleLine />
									</S.Selector>
								) : (
									<S.Selector
										data-testid={`${benefit.id}-unselect`}
										selected={isBenefitSelected(benefit.id!, benefit.title)}
										onClick={() => handleDeselectBenefit(benefit)}
									>
										<RiCloseCircleLine />
									</S.Selector>
								)}
							</S.BenefitContainer>
						))}
						{!getFilteredBenefitsList().length && (
							<EmptyContent text='Nenhum benefício disponível' />
						)}
					</S.BenefitsList>
					<S.MainButton onClick={handleSubmit} style={{ width: '16rem' }}>
						Salvar
					</S.MainButton>
				</S.Container>
			</Modal>
		</>
	);
}
