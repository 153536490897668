import { Operator } from '../../@types';
import {
	FilterParams,
	parseFilterParamsToParams,
} from '../../components/Filter';
import { Company, User } from '../../contexts/AuthContext';
import { Notification } from '../../contexts/NotificationsContext';
import {
	SortColumn,
	parseSortColumnToParams,
} from '../../hooks/useSortColumnHook';
import api from '../api';

export interface OperatorsListReturn {
	users: Operator[];
	totalUsersOperator: number;
}

export interface UpdatePasswordProps {
	oldPassword: string;
	newPassword: string;
	confirmationPassword: string;
}

export interface OperatorUpdate {
	name: string;
}

export async function getOperators(
	pageNumber?: number,
	sortColumn: SortColumn | null = null
): Promise<OperatorsListReturn> {
	const { data } = await api.get(`/api/v1/rh/operators/q?page=${pageNumber}`, {
		params: {
			...parseSortColumnToParams(sortColumn),
		},
	});
	const users = data.users;
	const totalUsersOperator = data.totalUsersOperator;

	return { users, totalUsersOperator };
}

export async function getOperatorsChat(
	pageNumber?: number
): Promise<OperatorsListReturn> {
	const { data } = await api.get(
		`/api/v1/rh/operators/chat/q?page=${pageNumber}`
	);
	const users = data.users;
	const totalUsersOperator = data.totalUsersOperator;

	return { users, totalUsersOperator };
}

export async function getOperator(id: string): Promise<Operator> {
	const { data } = await api.get(`/api/v1/rh/operators/${id}`);
	return data;
}

export async function createOperator(operator: Operator): Promise<Operator> {
	const { data } = await api.post(`/api/v1/rh/operators`, operator);
	return data;
}

export async function updateOperator(
	id: string,
	updatedOperatorData: Operator
): Promise<Operator> {
	const { data } = await api.put(
		`/api/v1/rh/operators/${id}`,
		updatedOperatorData
	);
	return data;
}

export async function toggleStatus(id: string, status: boolean): Promise<void> {
	return await api.patch(`/api/v1/rh/operators/${id}/status`, { status });
}

export async function getFilteredOperators(
	filtersParams: FilterParams[],
	page: number,
	sortColumn: SortColumn | null = null
): Promise<OperatorsListReturn> {
	const { data } = await api.get(`/api/v1/rh/operators/filter/q?page=${page}`, {
		params: {
			...parseFilterParamsToParams(filtersParams),
			...parseSortColumnToParams(sortColumn),
		},
	});

	return data;
}

export async function getFilteredOperatorsChat(
	filtersParams: FilterParams[],
	page: number
): Promise<OperatorsListReturn> {
	const { data } = await api.get(
		`/api/v1/rh/operators/chat/filter/q?page=${page}`,
		{
			params: parseFilterParamsToParams(filtersParams),
		}
	);

	return data;
}

export async function fetchOperatorProfile(): Promise<Operator> {
	const { data } = await api.get<Operator>(`/api/v1/rh/operators/profile`);

	return data;
}

export async function updateOperatorProfile(
	updatedColaboratorData: OperatorUpdate
) {
	await api.put(`/api/v1/rh/operators/profile`, updatedColaboratorData);
}

export async function updateOperatorAvatar(avatarFormData: FormData) {
	await api.patch(`api/v1/operators/profile/avatar`, avatarFormData);
}

export async function updateOperatorPassword(
	passwordProps: UpdatePasswordProps
) {
	await api.patch('api/v1/rh/operators/profile/password', {
		old_password: passwordProps.oldPassword,
		password: passwordProps.newPassword,
		password_confirmation: passwordProps.confirmationPassword,
	});
}

export async function validateOperatorPassword(password: string) {
	const { data } = await api.post<{ validation: boolean }>(
		'api/v1/operators/validate-password',
		{
			password,
		}
	);
	return data;
}

interface OperatorAccessData {
	user: User;
	termsNotifications: Notification[];
	companies: Company[];
}
export async function getOperatorsAccessData() {
	const { data } = await api.get<OperatorAccessData>('/api/v1/operators/me');
	return data;
}
