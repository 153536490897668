import styled from 'styled-components';
import { OptionsButton } from '../../../../../components/Button';

interface CollabSelectionProps {
	selected: boolean;
}

export const MainButton = styled.button`
	${OptionsButton}
`;

export const SelectOperatorButton = styled.button`
	display: flex;
	justify-content: space-around;
	align-items: center;
	font-size: 2rem;
	margin: 0 1rem 0 1rem;
	background-color: var(--primary-blue);

	width: 4.5rem;
	height: 4.5rem;
	border: 2px solid white;
	border-radius: 50%;
	padding: 5px;

	color: white;
	svg {
		height: 2rem;
		width: 2rem;
		margin-left: 0.5rem;
	}

	&:hover {
		box-shadow: 0px 5px 13px 0px rgba(0, 0, 0, 0.2);
	}
`;

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	max-width: 80rem;
	width: 80vw;
	padding-right: 1rem;
	min-height: 10rem;
	justify-content: center;
`;

export const CollabsList = styled.ul`
	display: flex;
	flex-direction: column;
	max-height: 60vh;
	overflow-y: auto;
	margin-top: 2rem;
`;

export const CollabContainer = styled.li`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 1rem;
	padding-bottom: 1rem;
	border-bottom: 0.1rem solid var(--primary-blue);
`;

export const CollabCard = styled.div`
	display: flex;
	align-items: center;
`;

export const CollabAvatar = styled.img`
	border: 0.2rem solid var(--primary-blue);
	border-radius: 50%;
	height: 5rem;
	width: 5rem;
	margin-right: 1rem;
	object-fit: cover;
`;

export const CollabInfoContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

export const CollabName = styled.span`
	color: var(--primary-blue);
	font-size: 1.6rem;
`;

export const CollabInfo = styled.span`
	font-size: 1.4rem;
	color: var(--mid-gray);
`;

export const Selector = styled.button<CollabSelectionProps>`
	width: 3.5rem;
	height: 3.5rem;
	background: none;
	& svg {
		width: 100%;
		height: 100%;
		border-radius: 50%;
		color: ${({ selected }) =>
			selected ? 'var(--light-gray)' : 'var(--primary-blue)'};

		cursor: ${({ selected }) => (selected ? 'not-allowed' : 'pointer')};
	}
`;
