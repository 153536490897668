import { MAX_PAYMENTS } from '../../utils/MAX_PAYMENTS';
import * as S from './styles';

interface Props {
	paymentsAmount: number;
}
export function PaymentsAmount({ paymentsAmount }: Props) {
	return (
		<S.AmountsText error={paymentsAmount > MAX_PAYMENTS}>
			{paymentsAmount}/{MAX_PAYMENTS} pagamentos
			{paymentsAmount > MAX_PAYMENTS &&
				`. O Bounty tem um limite de ${MAX_PAYMENTS} pagamentos por lançamento.
    Entende-se por pagamento cada benefício que um colaborador irá receber.`}
		</S.AmountsText>
	);
}
