import { toast } from 'react-toastify';
import axios from 'axios';
import { getMessageError } from './DefaultErrors';

export function showErrorMessage(
	error: Error,
	defaultMessage = '',
	showDefaultMessage = true
) {
	if (axios.isAxiosError(error)) {
		let errorMessage = getMessageError(error.response?.data);

		const toastMessage = showDefaultMessage
			? `${defaultMessage} ${errorMessage}`
			: `${errorMessage}`;
		toast.error(toastMessage);
	} else {
		toast.error(`${defaultMessage}`);
	}
}
