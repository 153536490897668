import styled from 'styled-components';
import { OptionsButton } from '../../../../components/Button';

export const Container = styled.div`
	flex: 1;
	padding: 4rem 7rem 2rem 7rem;
`;

export const Description = styled.p`
	margin-top: 1rem;
	font-size: 1.4rem;
`;

export const CollaboratorsContainer = styled.div`
	margin: 4rem 0;
	width: 100%;
	max-width: 80rem;
`;

export const CollaboratorsTitle = styled.h2`
	color: #713232;
	font-size: 1.8rem;
	font-weight: 500;
	margin-bottom: 1rem;
`;

export const CollaboratorsList = styled.ul`
	list-style: none;
	display: flex;
	flex-direction: column;
	border: 0.15rem solid black;
	& :last-child {
		border: none;
	}
`;

export const CollaboratorCard = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0.5rem 1rem;
	border-bottom: 0.15rem solid black;
`;

export const AvatarContainer = styled.div`
	display: flex;
	align-items: center;

	flex: 1;
`;

export const Avatar = styled.img`
	min-width: 4rem;
	max-width: 4rem;
	min-height: 4rem;
	max-height: 4rem;
	border-radius: 50%;
	border: 0.2rem solid var(--terciary);
	margin-right: 1rem;
	object-fit: cover;
`;

export const CollabName = styled.span`
	font-size: 1.4rem;
	margin-left: 1rem;
`;

export const CollabOffice = styled.span`
	font-size: 1.4rem;
	flex: 1;
	color: var(--mid-gray);
`;

export const RemoveCollabBtn = styled.button`
	background-color: var(--primary-red);
	padding: 1rem;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	& svg {
		width: 2rem;
		height: 2rem;
		color: var(--white);
	}
`;

export const OptionsContainer = styled.div`
	margin-top: 2rem;
	display: flex;
	align-items: center;
	column-gap: 2rem;
`;
export const SecondaryBtn = styled.button`
	${OptionsButton}
	background-color: var(--white);
	color: var(--primary-blue);
`;
