import { toast } from 'react-toastify';
import { Operator } from '../../../../../@types';
import PageTitle from '../../../../../components/PageTitle';
import * as S from './styles';
import { useHistory } from 'react-router-dom';
import ModalLoader from '../../../../../components/ModalLoader';
import { cpf as cpfValidator } from 'cpf-cnpj-validator';
import OperatorForm from '../../../../../components/OperatorForm';
import { parseMaskedCPFToRaw } from '../../../../../utils/masks';
import { useMutation, useQueryClient } from 'react-query';
import { createOperator } from '../../../../../services/queries/Operators';
import { showErrorMessage } from '../../../../../utils/ErrorHandler';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { firebaseAuth } from '../../../../../services/firebase';

export function CreateOperator() {
	const history = useHistory();
	const queryClient = useQueryClient();

	const createOperatorMutation = useMutation(
		(operator: Operator) => {
			return createOperator(operator);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('operatorsList');
				history.push('/super-admin/operators');
				toast.info('Operador criado com sucesso');
			},
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu algum problema ao tentar criar o operador. '
				);
			},
		}
	);

	async function handleCreateOperator(operator: Operator) {
		operator.cpf = parseMaskedCPFToRaw(operator.cpf!);

		if (validateOperator(operator)) {
			const operator_created = await createOperatorMutation.mutateAsync(
				operator
			);

			await createUserWithEmailAndPassword(
				firebaseAuth,
				operator_created.email,
				operator_created.email
			).catch((err) => {
				console.log(err.message);
			});
		}
	}

	function validateOperator(operator: Operator) {
		const email_mask = /\S+@\S+\.\S+/; // Email format: Text@text.text

		const operatorEmail =
			typeof operator.email === 'undefined' ? '' : operator.email;
		const operatorCPF = operator.cpf;

		if (!cpfValidator.isValid(operatorCPF)) {
			toast.error(`CPF inválido`);
			return false;
		} else if (!email_mask.test(operatorEmail)) {
			toast.error(`Formato de EMAIL inválido`);
			return false;
		}

		return true;
	}

	return (
		<S.Container>
			<S.Content>
				<PageTitle title='Criar Operador' />
				<OperatorForm onSubmit={handleCreateOperator} />
			</S.Content>
			<ModalLoader loading={createOperatorMutation.isLoading} />
		</S.Container>
	);
}
