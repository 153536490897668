import { Company, Shareholder } from "../../@types";
import api from "../api";

export interface UpdateShareholderParams {
    shareholderToUpdate: object,
    shareholderId: string, 
    companyId?: string
}

export async function addShareholderToCompany(shareholder: object, companyId?: string) {
    const { data } = await api.put<Company>(`/api/v1/companies/${companyId}/shareholders`, shareholder);

    return data
}


export async function updateShareholderQuery(shareholderToUpdate: object, shareholderId: string, companyId?: string) {
    const { data } = await api.put<Shareholder>(`/api/v1/companies/${companyId}/shareholders/${shareholderId}`,
        shareholderToUpdate
    );

    return data
}

export async function deleteShareholderQuery(shareholderId: string, companyId?: string) {
    await api.delete(`/api/v1/companies/${companyId}/shareholders/${shareholderId}`);
}
