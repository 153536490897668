import styled from 'styled-components';
import { OptionsButton } from '../Button';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 35vw;
	padding: 0 0.5rem 0.5rem 0.5rem;
	row-gap: 2rem;
`;

export const Title = styled.h1`
	color: var(--primary-blue);
`;

export const TableContainer = styled.div`
	max-height: 50vh;
	overflow-y: auto;
`;

export const Table = styled.table`
	font-size: 1.4rem;
	width: 100%;
`;
export const TableBody = styled.tbody``;
export const Row = styled.tr``;

export const Head = styled.th`
	text-align: left;
	text-transform: uppercase;
`;

export const Data = styled.td`
	padding: 0.5rem 0;
	border-bottom: 1px solid black;
`;

export const Information = styled.span`
	font-size: 1.6rem;
`;

export const OptionsContainer = styled.div`
	display: flex;
	justify-content: space-between;
`;

export const MainOption = styled.button`
	${OptionsButton}
`;
export const CancelOption = styled.button`
	${OptionsButton}
	background-color: var(--white);
	color: var(--primary-blue);
`;
