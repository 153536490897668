import styled from 'styled-components';

export const ChangesList = styled.ul`
	list-style: none;
	width: fit-content;
	max-height: 10rem;
	overflow-y: auto;
`;

export const Change = styled.li`
	font-size: 1.4rem;
	margin: 1rem 0;
	width: 100%;
	padding-bottom: 1.2rem;
	border-bottom: 0.1rem solid var(--black);
	& > span {
		font-weight: bold;
	}
`;
