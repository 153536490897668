import { useEffect, useState, useRef } from 'react';
import { RiAddCircleLine, RiCloseCircleLine } from 'react-icons/ri';
import { Collaborator } from '../../../../../@types';
import { EmptyContent } from '../../../../../components/EmptyContent';
import Loader from '../../../../../components/Loader';
import Modal from '../../../../../components/Modal';
import avatarImg from '../../../../../assets/avatar.svg';
import * as TableStyle from '../../../../../components/Table/TableStyles';
import * as S from './styles';
import UserCard from '../../../../../components/UserCard';
import { useAuth } from '../../../../../hooks/useAuth';
import { useQuery } from 'react-query';
import {
	FilterCollabsReturn,
	getActiveCollaborators,
	getFilteredCollabs,
} from '../../../../../services/queries/Collaborators';
import { Pagination } from '../../../../../components/Pagination';
import { Filter, FilterParams } from '../../../../../components/Filter';
import PageTitle from '../../../../../components/PageTitle';
import { showErrorMessage } from '../../../../../utils/ErrorHandler';
import { ContractTypeOptionsFilters } from '../../../../../components/Filter/utils/SelectFilters';

export interface CollaboratorSelectorProps {
	alreadyAddedCollaborator: Collaborator | null; // already added collaborators if selected
	onSelectCollaborator(c: Collaborator | null): void;
}
export function SingleCollaboratorSelector({
	alreadyAddedCollaborator,
	onSelectCollaborator,
}: CollaboratorSelectorProps) {
	const { currentCompany } = useAuth();
	const [open, setOpen] = useState(false);
	const listContainer = useRef<HTMLUListElement | null>(null);

	const [collabsCurrentPage, setCollabsCurrentPage] = useState(1);

	const [filtersParams, setFiltersParams] = useState<FilterParams[]>([
		{
			name: 'Nome',
			filter: 'name',
			value: '',
			type: 'text',
			selected: false,
		},
		{
			name: 'Nome da mãe',
			filter: 'mother_name',
			value: '',
			type: 'text',
			selected: false,
		},
		{ name: 'CPF', filter: 'cpf', value: '', type: 'cpf', selected: false },
		{
			name: 'Email',
			filter: 'email',
			value: '',
			type: 'email',
			selected: false,
		},
		{
			name: 'Cargo',
			filter: 'office',
			value: '',
			type: 'text',
			selected: false,
		},
		{
			name: 'Setor de atuação',
			filter: 'section',
			value: '',
			type: 'text',
			selected: false,
		},
		{ name: 'UF', filter: 'uf', value: '', type: 'uf', selected: false },
		{
			name: 'Contrato',
			filter: 'contract_type',
			value: '',
			type: 'select',
			selectOptions: ContractTypeOptionsFilters,
			selected: false,
		},
		{
			name: 'Centro de custo',
			filter: 'cost_center',
			value: '',
			type: 'cost_center',
			selected: false,
		},
	]);

	useEffect(() => {
		listContainer.current?.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
	}, [collabsCurrentPage, listContainer]);

	const activeCollabsQuery = useQuery(
		['activeCollabsList', currentCompany?.id, collabsCurrentPage],
		() => {
			return getActiveCollaborators(collabsCurrentPage, currentCompany?.id);
		},
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível buscar os colaboradores. '
				);
			},
			enabled: !!currentCompany && open,
			keepPreviousData: true,
		}
	);

	const filteredCollabsQuery = useQuery<FilterCollabsReturn, Error>(
		[
			'filteredCollabsQuery',
			filtersParams,
			true,
			currentCompany?.id,
			collabsCurrentPage,
		],
		() => {
			return getFilteredCollabs(
				filtersParams,
				true,
				currentCompany?.id,
				collabsCurrentPage
			);
		},
		{
			onError: (error) => {
				showErrorMessage(
					error as Error,
					'Não foi possível buscar os colaboradores. '
				);
			},
			enabled: filtersParams.some((f) => !!f.value) && !!collabsCurrentPage,
		}
	);

	function handleSelectCollaborator(collaborator: Collaborator) {
		onSelectCollaborator(collaborator);
		setOpen(false);
	}
	function handleDeselectCollaborator() {
		onSelectCollaborator(null);
	}

	function checkSelectedCollaborator(collaboratorId: string) {
		return !!(
			alreadyAddedCollaborator &&
			alreadyAddedCollaborator.id! === collaboratorId
		);
	}

	function selectCollaboratorButton() {
		return (
			<>
				{alreadyAddedCollaborator ? (
					<S.SelectCollabButtonWithSelection
						onClick={() => {
							setOpen(true);
						}}
						type='button'
					>
						<UserCard
							name={`${alreadyAddedCollaborator.first_name} ${alreadyAddedCollaborator.last_name}`}
							bottomInfo={alreadyAddedCollaborator.office}
							avatar_url={alreadyAddedCollaborator.avatar_url}
						/>
					</S.SelectCollabButtonWithSelection>
				) : (
					<S.SelectCollaboratorButton
						onClick={() => {
							setOpen(true);
						}}
						type='button'
					>
						Selecionar colaborador
					</S.SelectCollaboratorButton>
				)}
			</>
		);
	}

	function updateFilters(filters: FilterParams[]) {
		setCollabsCurrentPage(1);
		setFiltersParams(filters);
	}

	function getCollaboratorsList() {
		return !!filtersParams.find((f) => !!f.value)
			? filteredCollabsQuery.data?.collaborators
			: activeCollabsQuery.data?.collaboratorStatusActive;
	}

	function getTotalRecords() {
		if (filtersParams.find((f) => !!f.value)) {
			return filteredCollabsQuery.data?.totalCount;
		}
		return activeCollabsQuery.data?.totalActive;
	}

	if (
		activeCollabsQuery.isLoading ||
		filteredCollabsQuery.isLoading ||
		activeCollabsQuery.isPreviousData ||
		filteredCollabsQuery.isPreviousData
	) {
		return (
			<div>
				{selectCollaboratorButton()}
				<Modal isOpen={open} enableClose onRequestClose={() => setOpen(false)}>
					<S.Container>
						<Loader />
					</S.Container>
				</Modal>
			</div>
		);
	}
	return (
		<div>
			{selectCollaboratorButton()}
			<Modal isOpen={open} enableClose onRequestClose={() => setOpen(false)}>
				<S.Container data-testid='CollabsSelector-modal-container'>
					<TableStyle.TableHeaderContainer>
						<PageTitle
							title='Selecione um colaborador'
							totalRecords={getTotalRecords() ?? 0}
						/>
						<Filter
							filterParams={filtersParams}
							onFiltersChanged={(updatedFilters) => {
								updateFilters(updatedFilters);
							}}
						/>
						{filtersParams.some((f) => !!f.value) ? (
							<Pagination
								onPageChange={(page) => setCollabsCurrentPage(page)}
								currentPage={collabsCurrentPage}
								totalCount={
									filteredCollabsQuery.data
										? filteredCollabsQuery.data.totalCount!
										: 1
								}
							/>
						) : (
							<Pagination
								onPageChange={(page) => setCollabsCurrentPage(page)}
								currentPage={collabsCurrentPage}
								totalCount={
									activeCollabsQuery.data
										? activeCollabsQuery.data.totalActive!
										: 1
								}
							/>
						)}
					</TableStyle.TableHeaderContainer>

					<S.CollaboratorsList ref={listContainer}>
						{getCollaboratorsList()?.map((collaborator) => (
							<S.CollaboratorContainer key={collaborator.id}>
								<S.CollaboratorCard>
									<S.CollaboratorAvatar
										src={
											collaborator.avatar ? collaborator.avatar_url : avatarImg
										}
									/>

									<S.CollaboratorInfoContainer>
										<S.CollaboratorName>{`${collaborator.first_name} ${collaborator.last_name}`}</S.CollaboratorName>
										<S.CollaboratorInfo>
											{collaborator.office}
										</S.CollaboratorInfo>
										<S.CollaboratorInfo>
											{collaborator.section}
										</S.CollaboratorInfo>
										<S.CollaboratorInfo>
											{collaborator.email}
										</S.CollaboratorInfo>
									</S.CollaboratorInfoContainer>
								</S.CollaboratorCard>

								{!checkSelectedCollaborator(collaborator.id!) ? (
									<S.Selector
										selected={checkSelectedCollaborator(collaborator.id!)}
										onClick={() => handleSelectCollaborator(collaborator)}
										data-testid={`${collaborator.id}-select`}
									>
										<RiAddCircleLine />
									</S.Selector>
								) : (
									<S.Selector
										selected={checkSelectedCollaborator(collaborator.id!)}
										onClick={handleDeselectCollaborator}
										data-testid={`${collaborator.id}-remove`}
									>
										<RiCloseCircleLine />
									</S.Selector>
								)}
							</S.CollaboratorContainer>
						))}
						{!getCollaboratorsList()?.length && (
							<EmptyContent text='Nenhum colaborador disponível encontrado' />
						)}
					</S.CollaboratorsList>
				</S.Container>
			</Modal>
		</div>
	);
}
