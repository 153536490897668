import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	min-width: 60rem;
	min-height: 20rem;
	max-height: 80vh;
`;

export const Title = styled.h1`
	color: var(--primary-blue);
	margin-bottom: 1rem;
`;

export const Table = styled.table`
	width: 65vw;
	max-height: 80vh;
	overflow-y: auto;
	max-width: 140rem;
	font-size: 1.4rem;
	padding: 0.5rem;
`;

export const Row = styled.tr``;

export const Head = styled.th`
	text-align: left;
	text-transform: uppercase;
	padding: 1rem;
	border-bottom: 2px solid black;
`;

export const Data = styled.td`
	padding: 1rem;
	/* padding-bottom: 0.5rem; */
	border-bottom: 1px solid black;
	/* border-left: 1px solid black; */
	/* border: 1px solid black; */
`;

export const ErrorListContainer = styled.div`
	overflow: auto;
	max-height: 70px;
	width: 45rem;

	> li + li {
		margin-top: 1rem;
	}

	> li:only-child {
		list-style: none;
	}
`;
