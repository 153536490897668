import React, { useEffect } from 'react';
import {
	TitleTypography,
	Typography,
} from '../../../../../componentsV2/ui/Typography';
import ToggleSwitch from '../../../../../components/ToggleSwitch';
import { CurrencyInput } from '../../../../../componentsV2/ui/Form/CurrencyInput';
import { FormStepProps } from './FormStep1';
import { InputWithDescription } from './InputWithDescription';
import * as S from './styles';

export function FormStep2({ form }: FormStepProps) {
	const { register, setValue, watch, formState, clearErrors } = form;
	const use_initial_value = watch('use_initial_value');
	const limit_notifications = watch('limit_value_alert');

	useEffect(() => {
		if (!setValue) return;

		if (!use_initial_value) {
			clearErrors('initial_value');
			setValue('initial_value', '0,00');
		}
	}, [use_initial_value]); // eslint-disable-line

	useEffect(() => {
		if (!setValue) return;

		if (!limit_notifications) {
			clearErrors('limit_value');
			setValue('limit_value', '0,00');
		}
	}, [limit_notifications]); // eslint-disable-line

	return (
		<>
			<TitleTypography weight='600' size='2.4rem' primaryText='Informações'>
				do cartão
			</TitleTypography>
			<InputWithDescription
				description={
					'Determine o valor que ficará disponível para uso após a ativação deste cartão' +
					' ou desative a opção “atribuir valor inicial” para liberar o cartão sem saldo.'
				}
				marginTop={'4rem'}
			>
				<S.Column style={{ gap: '1rem' }}>
					<S.SwitchContainer>
						<ToggleSwitch
							shouldUnregister={false}
							name={'use_initial_value'}
							register={register}
						/>
						<Typography size={'1.25rem'} color={'#929292'} weight={'400'}>
							Atribuir valor inicial
						</Typography>
					</S.SwitchContainer>

					<CurrencyInput
						errorMessage={formState.errors.initial_value?.message}
						useCurrencyLabel={false}
						disabled={!use_initial_value}
						name='initial_value'
						id='initial_value'
						register={register}
						required
					/>
				</S.Column>
			</InputWithDescription>

			<InputWithDescription
				description={
					'Insira o valor mínimo para alerta de uso neste cartão. Qualquer gasto ' +
					'que ultrapasse esse limite irá gerar uma notificação via plataforma ou e-mail.'
				}
				marginTop={'4rem'}
			>
				<S.Column style={{ gap: '1rem' }}>
					<S.SwitchContainer>
						<ToggleSwitch
							shouldUnregister={false}
							name={'limit_value_alert'}
							register={register}
						/>
						<Typography size={'1.25rem'} color={'#929292'} weight={'400'}>
							Receber alertas à partir de:
						</Typography>
					</S.SwitchContainer>
					<CurrencyInput
						errorMessage={formState.errors.limit_value?.message}
						useCurrencyLabel={false}
						disabled={!limit_notifications}
						name='limit_value'
						id='limit_value'
						register={register}
						required
					/>
				</S.Column>
			</InputWithDescription>
		</>
	);
}
