import { NotificationsPreferences } from '../../../@types/CorporateExpenses/Preferences';
import api from '../../api';

export const CORPWAY_PREFERENCES_KEY = 'CORPWAY_PREFERENCES_SERVICE_KEY';

interface GetPreferencesReturn {
	operatorPreferences: {
		id: string;
		operator_id: string;
	} & NotificationsPreferences;
}

export async function getPreferences() {
	const { data } = await api.get<GetPreferencesReturn>(
		`/api/v1/rh/corpway/operators/preferences`
	);

	return data;
}

export async function updatePreferences(preferences: NotificationsPreferences) {
	await api.patch(`/api/v1/rh/corpway/operators/preferences`, preferences);
}
