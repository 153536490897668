import { useQuery } from 'react-query';
import { getAntiFraud } from '../../../../services/queries/Terms';
import { showErrorMessage } from '../../../../utils/ErrorHandler';
import { SectionContainer } from '../../../../componentsV2/ui/SectionContainer';
import { Breadcrumb } from '../../../../componentsV2/BreadCrumb';
import { TitleTypography } from '../../../../componentsV2/ui/Typography';
import styled from 'styled-components';
import { EmptyContent } from '../../../../componentsV2/EmptyContent';
import { useEffect } from 'react';

const AntiFraudPolicy = () => {
	const AntiFraudPolicyQuery = useQuery(
		['antiFraudPolicy'],
		() => {
			return getAntiFraud();
		},
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível buscar as políticas de antifraude. '
				);
			},
			refetchOnWindowFocus: false,
		}
	);

	useEffect(() => {
		const cells = document.querySelectorAll('td, th');

		const textColorMap: Record<string, string> = {
			'Transações não autorizadas': '#DBE1E9',
			'Empresa em lista VIP': '#D6DBE3',
			'Transações em group id limit list key': '#DBE1E9',
			'Transações em cardholder id limit list key': '#D6DBE3',
			'Merchant de risco': '#DBE1E9',
			'Transações acima do limite': '#D6DBE3',
			Bloqueios: '#DBE1E9',
			'Transações por e-commerce': '#DBE1E9',
			'Velocity de Transações': '#D6DBE3',
			'Transações por merchant que não está em lista permissiva': '#DBE1E9',
			'Total de transações nas últimas 48 horas': '#D6DBE3',
			'Transações por contactless': '#DBE1E9',
			'Lista de bloqueio - Merchant ID fraudulento': '#D6DBE3',
			'Transação aprovada': '#DBE1E9',
		};

		cells.forEach((cell) => {
			const cellText = cell.textContent?.trim();
			if (cellText) {
				const color = Object.keys(textColorMap).find((key) =>
					cellText.includes(key)
				);
				if (color) {
					(cell as HTMLElement).style.backgroundColor = textColorMap[color];
				}
			}
		});
	}, [AntiFraudPolicyQuery.data]);

	if (!AntiFraudPolicyQuery.data || AntiFraudPolicyQuery.isLoading) {
		return (
			<div>
				<SectionContainer>
					<Breadcrumb
						routes={[
							{ title: 'Suporte', route: '/corporate-expenses/support' },
							{
								title: 'Política Antifraude',
								route: '/corporate-expenses/support/anti-fraude-policy',
							},
						]}
					/>
					<TitleTypography image>Política Antifraude</TitleTypography>
				</SectionContainer>

				<EmptyContent />
			</div>
		);
	}

	return (
		<div>
			<SectionContainer>
				<Breadcrumb
					routes={[
						{ title: 'Suporte', route: '/corporate-expenses/support' },
						{
							title: 'Política Antifraude',
							route: '/corporate-expenses/support/anti-fraude-policy',
						},
					]}
				/>
				<TitleTypography image>Política Antifraude</TitleTypography>
			</SectionContainer>

			<TermsContainer
				dangerouslySetInnerHTML={{
					__html: AntiFraudPolicyQuery.data ? AntiFraudPolicyQuery.data : '',
				}}
			/>
		</div>
	);
};

export default AntiFraudPolicy;

export const TermsContainer = styled.div`
	text-align: justify;
	padding: 2rem 4rem;
	margin-top: 1.5rem;
	margin-bottom: 3.5rem;
	width: 100%;
	background-color: white;
	border-radius: 10px;
	font-size: 1.6rem;
	overflow-x: auto;

	& > table,
	td {
		text-align: start;
		padding: 3rem 3rem;
		margin-top: 3rem;
		margin-bottom: 3rem;
		border-collapse: collapse;
	}

	table:first-of-type {
		tr:first-child {
			background-color: var(--dark-gray);
			height: 7.5rem;
			width: 80rem;

			th p strong {
				color: #ffffff;
				text-align: center;
				display: block;
			}

			th:nth-child(1) {
				border-radius: 0rem 0 0 0;
				width: 40rem;
				border-radius: 20px 0 0 0;
			}

			th:nth-child(2) {
				border-radius: 0rem 0 0 0;
				width: 40rem;
			}

			th:nth-last-child(1) {
				border-radius: 0 0rem 0 0;
				width: 40rem;
				border-radius: 0 20px 0 0;
			}

			p {
				color: white;
			}
		}

		tr:not(:first-of-type):nth-child(2n) {
			th:first-child[rowspan] {
				background-color: #f5f9ff;
			}

			th {
				background-color: #e5eaf2;
			}
		}

		tr:not(:first-of-type):nth-child(2n + 1) {
			th:first-child[rowspan] {
				background-color: #d6dbe3;
			}

			th {
				background-color: #f5f9ff;
			}
		}
	}

	tr {
		p {
			font-weight: normal;
		}
		h3 {
			font-weight: normal;
		}

		th {
			padding: 3rem 2rem;
			text-align: left;
		}
	}

	a {
		color: var(--dark-gray);
		text-decoration: underline;
		transition: all 0.2s;
	}

	a:hover {
		color: var(--light-gray);
	}

	& > ol,
	ul {
		margin-left: 2rem;
		li {
			margin-left: 2rem;
		}
	}

	& > h1,
	h2 {
		font-size: 2rem;
		margin-top: 2rem;
	}

	&::-webkit-scrollbar {
		width: 1.2rem;
	}

	&::-webkit-scrollbar-track {
		border-radius: 1rem;
		background-color: #e3e8eb;
	}

	&::-webkit-scrollbar-thumb {
		background-color: var(--light-gray);
		border-radius: 1rem;
	}
`;
