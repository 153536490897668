import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
`;

export const Title = styled.h1`
	color: var(--dark-gray);
	font-weight: 500;
	font-size: 2.2rem;
`;

export const TotalRecords = styled.span`
	font-size: 1.4rem;
`;
