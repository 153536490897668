import {
	Container,
	AvatarImg,
	CostCentersList,
	InfoContainer,
	MainContent,
	ProfileUser,
} from './styles';
import { useState } from 'react';
import { Button } from '../../../../../../componentsV2/ui/Button';
import Modal from '../../../../../../componentsV2/ui/Modal';
import { useQuery } from 'react-query';
import { showErrorMessage } from '../../../../../../utils/ErrorHandler';
import { Typography } from '../../../../../../componentsV2/ui/Typography';
import { MdNotes } from 'react-icons/md';
import {
	CORPWAY_USERS_KEY,
	getUserDetails,
} from '../../../../../../services/queries/Corpway/User';
import { AsynchronousContent } from '../../../../../../componentsV2/AsynchronousContent';
import avatarImg from '../../../../../../assets/avatar_icon.svg';
import { parseUserAccessLevel } from '../../../../../../utils/corpway/parseUserAccessLevel';
import { CorpwayUserAccessLevel } from '../../../../../../@types/CorporateExpenses/User';
import { capitalizeFirstLetter } from '../../../../../../utils/masks';

export function UserDetailsModal({ id }: { id: string }) {
	const [isOpen, setOpen] = useState(false);
	const [animationModal, setAnimationModal] = useState(false);

	const getUserDetailsQuery = useQuery(
		[CORPWAY_USERS_KEY, id],
		() => getUserDetails(id),
		{
			onError: (err) => {
				showErrorMessage(err as Error, 'Não foi possível buscar o usuário. ');
			},
			enabled: isOpen,
		}
	);

	const showCostCenters =
		getUserDetailsQuery.data?.role !== 'general_admin' &&
		getUserDetailsQuery.data?.role !== 'finance';

	function truncateString(str: string) {
		if (str.length <= 20) {
			return str;
		}
		return str.slice(0, 20) + '...';
	}
	return (
		<div>
			<Button
				style={{
					color: 'var(--primary-blue)',
					border: 'none',
					padding: '0',
				}}
				intent='ghost'
				onClick={() => {
					setOpen(true);
					setAnimationModal(true);
				}}
				data-rh='Detalhes do usuário'
			>
				<MdNotes />
			</Button>

			<Modal
				isOpen={isOpen}
				onRequestClose={() => setOpen(false)}
				position='right'
				headerTitle='Detalhes do usuário'
				titleTheme='light'
				animateOnClose={animationModal}
			>
				<Container>
					<AsynchronousContent status={getUserDetailsQuery.status}>
						<MainContent>
							<ProfileUser>
								<AvatarImg
									src={
										getUserDetailsQuery.data?.operator.avatarUrl || avatarImg
									}
									alt='Avatar do usuário'
								/>
								<Typography
									size='1.6rem'
									weight='500'
									color='var(--primary-blue)'
								>
									{getUserDetailsQuery.data?.name}
								</Typography>
								<Typography
									size='1.4rem'
									weight='600'
									color='var(--dark-gray)'
									className='accessRole'
								>
									Acesso:{' '}
									{parseUserAccessLevel(
										getUserDetailsQuery.data?.role as CorpwayUserAccessLevel
									)}
								</Typography>
							</ProfileUser>

							<InfoContainer>
								<Typography size='1.6rem' weight='600' color='var(--dark-gray)'>
									Centros de custo
								</Typography>
								<CostCentersList>
									{showCostCenters ? (
										getUserDetailsQuery.data?.corpway_cost_centers.map(
											(costCenter, index) => {
												if (index < 5) {
													return (
														<li key={costCenter.id}>
															{capitalizeFirstLetter(
																truncateString(costCenter.title)
															)}
														</li>
													);
												}
												if (index === 5) {
													const remainingCount =
														getUserDetailsQuery.data.corpway_cost_centers
															.length - 5;
													return (
														<li key='remaining-cost-centers'>
															+ {remainingCount} centros de custo
														</li>
													);
												}
												return null;
											}
										)
									) : (
										<li>Todos</li>
									)}
								</CostCentersList>
							</InfoContainer>
							<InfoContainer>
								<Typography size='1.6rem' weight='600' color='var(--dark-gray)'>
									Sobre
								</Typography>
								<Typography
									size='1.3rem'
									style={{ lineHeight: '1' }}
									color='var(--dark-gray)'
								>
									Email: {getUserDetailsQuery.data?.email}
								</Typography>
							</InfoContainer>

							<Button
								as='link'
								to={`/corporate-expenses/management/users/${getUserDetailsQuery.data?.id}`}
								roundness='lg'
								className='actionButton'
							>
								Mais detalhes
							</Button>
						</MainContent>
					</AsynchronousContent>
				</Container>
			</Modal>
		</div>
	);
}
