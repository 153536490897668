import React from 'react';
import * as S from './styles';
import { Button } from '../../../../../componentsV2/ui/Button';
import styled from 'styled-components';

export function InputWithEditButton({
	children,
	marginTop = '3.5rem',
	enableFields,
}: {
	children: React.ReactNode;
	marginTop?: string;
	enableFields: () => void;
}) {
	return (
		<S.InputContainer2>
			<S.Row style={{ width: '100%' }}>{children}</S.Row>
			<S.Column style={{ marginTop: marginTop }}>
				<EditButton type={'button'} onClick={enableFields} intent='link'>
					Editar
				</EditButton>
			</S.Column>
		</S.InputContainer2>
	);
}
const EditButton = styled(Button)`
	@media (max-width: 1024px) {
		position: absolute;
		right: 2.5rem;
		top: 50%;
		// transform: translateY(-20%);
	}
`;