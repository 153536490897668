import styled from 'styled-components';
import corpwayRecargaDeCarteira from '../../../../../assets/movementDetailsHeaders/recarga_de_carteira.png';
import corpwayEstornoParaCarteira from '../../../../../assets/movementDetailsHeaders/estorno_p_carteira.png';
import corpwayRepasseParaCartao from '../../../../../assets/movementDetailsHeaders/repasse_p_cartao.png';
import corpwayTransferenciaExterna from '../../../../../assets/movementDetailsHeaders/transferencia_externa.png';
import { SectionContainer } from '../../../../../componentsV2/ui/SectionContainer';

export const CursorPointerStyle = `
	&:hover {
			cursor: pointer;
			user-select: none; /* Non-prefixed version, currently*/
			-webkit-touch-callout: none; /* iOS Safari */
			-webkit-user-select: none; /* Safari */
			-moz-user-select: none; /* Old versions of Firefox */
			-ms-user-select: none; /* Internet Explorer/Edge */
	}

	img {
			-webkit-user-drag: none;
	}
`;

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	border-radius: 1rem 0 0 1rem;
	width: 38rem;
	height: 100vh;
	font-size: 1.6rem;
	font-weight: 600;
	min-height: 50rem;
`;

const getHeaderIcon = (movementType: string) => {
	if (['Repasse para cartão', 'Recebimento via chargeback'].includes(movementType)) {
		return corpwayRepasseParaCartao;
	}

	if (movementType === 'Estorno para carteira') {
		return corpwayEstornoParaCarteira;
	}

	if (movementType === 'Recarga de carteira') {
		return corpwayRecargaDeCarteira;
	}

	if (movementType === 'Transferência externa') {
		return corpwayTransferenciaExterna;
	}
};

export const HeaderContent = styled.div<{ movementType: string }>`
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	row-gap: 0.5rem;
	min-height: 35%;
	width: 100%;
	padding: 24px;

	background: url(${({ movementType }) => getHeaderIcon(movementType)})
		no-repeat center/cover;

	@media (max-width: 550px) {
		background: url(${({ movementType }) => getHeaderIcon(movementType)})
			no-repeat 80% 50% / cover;
	}

	@media (max-width: 1050px) {
		background: url(${({ movementType }) => getHeaderIcon(movementType)})
			no-repeat 85% 50% / cover;
	}
`;

export const HeaderMainContent = styled.div`
	display: flex;

	& > div {
		width: 50%;
	}

	& > div.movementIcon {
		flex: 1;
		display: flex;
		justify-content: center;
	}
`;

export const HeaderIcon = styled.img`
	filter: grayscale(1) invert(10%) sepia(10%) saturate(4323%) hue-rotate(170deg)
		brightness(105%) contrast(105%);
	width: 100%;
	height: 100%;
`;

export const TransactionCategory = styled.div``;
export const TransactionAmount = styled.div`
	background-color: rgba(25, 101, 148, 46.67%);
	width: max-content;
	padding: 0.3rem 1.5rem;
	border-radius: 0 20px 20px 20px;
	margin-top: 0.5rem;
`;

export const HeaderDetailsContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	margin-top: 1.6rem;
`;

export const HeaderDetail = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	row-gap: 0.2rem;
`;

export const ModalContentButton = styled.div`
	cursor: pointer;
	width: fit-content;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding-right: 1.3rem;
	border-bottom: 1px solid #fff;

	position: relative;
	& > svg {
		position: absolute;
		right: -0.5rem;
	}
`;

export const MainContent = styled.div`
	display: flex;
	flex-direction: column;
	padding: 32px 24px 32px 24px;
`;

export const FieldsContainer = styled.div`
	width: 100%;
	height: fit-content;
	display: flex;
	flex-direction: column;
	margin-top: 25px;
	row-gap: 10px;
`;

export const HistoryContainer = styled.div`
	min-height: 218px;
	margin-bottom: 3.2rem;
`;
export const Field = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`;

export const SubTitleRow = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	font-weight: 400;

	& > div {
		max-width: 45%;
	}
`;
export const InfoContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	row-gap: 1rem;
	padding: 2.4rem 0;
`;

export const IconContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 3.5rem;
	height: 3.5rem;
	background-color: var(--primary-blue);
	border-radius: 50px;

	& > img {
		width: 65%;
		height: 65%;
	}
`;

export const SectionContainerStyle = styled(SectionContainer)`
	margin: 0;
	padding: 0;
	width: 100%;
	height: 28rem;

	table {
		margin-left: -0.5rem;
		padding-top: 1rem;
		width: 100%;
		background: #fff !important;
		height: 100%;
		border-collapse: separate;
		border-spacing: 0 0.5rem;

		thead {
			background: #fff !important;
			box-shadow: none !important;
			border: none !important;

			tr {
				width: 100%;
				table-layout: fixed;
			}

			th {
				color: #575757;
				font-weight: 600;
				font-size: 1.2rem;
				line-height: 18px;
				text-align: left;
				padding: 1rem;
				padding-left: 1rem;
				margin-bottom: 0.5rem;
			}
			th:nth-child(1) {
				width: 25%;
				min-width: 100px;
				padding-left: 0.5rem;
			}
			th:nth-child(2) {
				width: 35%;
				min-width: 120px;
				padding-left: 0.5rem;
			}
			th:nth-child(3) {
				width: 40%;
				min-width: 100px;
				padding-left: 0.5rem;
				text-align: center;
			}
		}

		tbody {
			display: block;
			max-height: calc(28rem - 5rem);
			width: 100%;

			tr {
				font-weight: normal;
				display: table;
				width: 100%;
				table-layout: fixed;
				font-size: 1.2rem;
			}
			td {
				padding: 0.5rem;
				vertical-align: bottom;
				text-align: left;
				border-bottom: 1px solid #ddd;
				padding-left: 0.5rem;
			}
			td:nth-child(1) {
				width: 25%;
				min-width: 100px;
				padding-left: 0.5rem;
			}
			td:nth-child(2) {
				width: 35%;
				min-width: 120px;
				padding-left: 0.5rem;
			}
			td:nth-child(3) {
				width: 45%;
				min-width: 100px;
				padding-left: 0.5rem;
			}
		}

		thead,
		tbody tr {
			display: table;
			width: 100%;
			table-layout: fixed;
		}
	}
	.table-footer {
		border-bottom: 1px solid #ccc;
		width: 100%;
		margin-top: -1px;
	}
`;


export const MessageContainer = styled.div`
    border-radius: 8px;
    background: #F0F3F8;
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    align-self: stretch;
`;

export const InfoHeader = styled.div`
	display: flex;
	gap: 0.5rem;
`;

