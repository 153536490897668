import { useEffect, useState } from 'react';
import PageTitle from '../../../../components/PageTitle';
import * as FormStyles from '../../../../components/Form/FormStyles';
import * as S from './styles';
import { SegmentationModal } from './SegmentationModal';
import { useAuth } from '../../../../hooks/useAuth';
import { FaCheckCircle } from 'react-icons/fa';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import {
	createMessage,
	CreateMessageBody,
} from '../../../../services/queries/Messages';
import { useHistory } from 'react-router-dom';
import Loader from '../../../../components/Loader';
import PreventTransitionPrompt from '../../../../components/PreventTransitionPrompt';
import { showErrorMessage } from '../../../../utils/ErrorHandler';
import { TextEditor } from '../../../../components/TextEditor';
import { UnprivilegedEditor } from 'react-quill';

export type SegmentationOptions = 'group' | 'company' | 'custom';
type FormData = {
	title: string;
};

export function CreateMessage() {
	const { currentCompany } = useAuth();
	const history = useHistory();

	const segmentationOptionString = {
		group: {
			title: 'Grupo corporativo',
			description:
				'Todos os colaboradores de todas as empresas do grupo receberão a mensagem',
		},
		company: {
			title: `Empresa - ${currentCompany?.name}`,
			description:
				'Todos os colaboradores da empresa que está sendo gerenciada no momento receberão a mensagem',
		},
		custom: {
			title: 'Customizado',
			description:
				'Apenas o grupo de colaboradores manualmente selecionados receberão a mensagem',
		},
	};

	const { register, handleSubmit, formState } = useForm<FormData>({
		defaultValues: {
			title: '',
		},
	});

	const [bodyEditorRef, setBodyEditorRef] = useState<UnprivilegedEditor | null>(
		null
	);

	const [segmentationOption, setSegmentationOption] =
		useState<SegmentationOptions>('group');
	const [collabsIds, setCollabsIds] = useState<string[]>([]);

	const { isDirty } = formState;
	const [allowNavigation, setAllowNavigation] = useState(!isDirty);

	useEffect(() => {
		setAllowNavigation(!(isDirty || collabsIds.length));
	}, [isDirty, collabsIds]);

	const createMessageQuery = useMutation(
		(body: CreateMessageBody) => {
			return createMessage(body, currentCompany?.id);
		},
		{
			onSuccess: () => {
				setAllowNavigation(true);
				toast.info('Mensagem enviada com sucesso!');
				history.push('/home/messages');
			},
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao tentar enviar a mensagem. '
				);
			},
		}
	);

	function handleSegmentationOptionSelection(
		option: SegmentationOptions,
		selectedCollaboratorsIds: string[]
	) {
		setSegmentationOption(option);
		if (option === 'custom') {
			setCollabsIds(selectedCollaboratorsIds);
		}
	}

	function handleSubmitForm(data: FormData) {
		if (bodyEditorRef?.getText().length === 1) {
			toast.error('Corpo da mensagem não pode ser vazio');
			return;
		}
		createMessageQuery.mutate({
			title: data.title.trim(),
			body: bodyEditorRef?.getHTML() ?? '',
			segmentation: segmentationOption,
			recipients_ids: collabsIds,
		});
	}

	return (
		<S.Container>
			{!allowNavigation && <PreventTransitionPrompt />}

			<PageTitle title='Nova mensagem' />

			<S.FormContainer onSubmit={handleSubmit(handleSubmitForm)}>
				<S.Field>
					<FormStyles.Label htmlFor='title'>Título</FormStyles.Label>
					<FormStyles.Input
						placeholder='Ex: Título'
						id='title'
						{...register('title')}
						required
					/>
				</S.Field>

				<S.Field>
					<S.FieldHeader>
						<FormStyles.Label>Corpo da mensagem</FormStyles.Label>
					</S.FieldHeader>
					<TextEditor setEditorRef={setBodyEditorRef} />
				</S.Field>

				<S.Field>
					<SegmentationModal
						onChangeSegmentation={handleSegmentationOptionSelection}
					/>

					<S.SegmentationOptionContainer>
						<FaCheckCircle color='#1CCA0D' />

						<div>
							<S.SegmentationOptionTitle>
								{segmentationOptionString[segmentationOption].title}
							</S.SegmentationOptionTitle>
							<span>
								{segmentationOptionString[segmentationOption].description}
							</span>
						</div>
					</S.SegmentationOptionContainer>
				</S.Field>

				<S.SubmitForm type='submit' disabled={createMessageQuery.isLoading}>
					{createMessageQuery.isLoading ? (
						<Loader size={20} color='var(--white)' />
					) : (
						'Enviar'
					)}
				</S.SubmitForm>
			</S.FormContainer>
		</S.Container>
	);
}
