import styled from 'styled-components';

export const Container = styled.div`
	flex: 1;
	padding: 4rem 7rem 2rem 7rem;
`;

export const StatusContainer = styled.div`
	display: flex;
	align-items: center;
	> svg {
		width: 2rem;
		height: 2rem;
		margin-right: 1rem;
	}
	white-space: nowrap;
`;

export const BottomOptionsContainer = styled.div`
	display: flex;
	column-gap: 2rem;
`;
