import { FiFileText } from 'react-icons/fi';
import { Breadcrumb } from '../../../componentsV2/BreadCrumb';
import { SectionContainer } from '../../../componentsV2/ui/SectionContainer';
import { TitleTypography } from '../../../componentsV2/ui/Typography';
import HelpCard from './components/HelpCard';
import * as S from './styles';
import { FaUserLock } from 'react-icons/fa';
import { BsFillShieldLockFill, BsJournalBookmarkFill } from 'react-icons/bs';

export function Support() {
	return (
		<div>
			<SectionContainer>
				<Breadcrumb
					routes={[{ title: 'Suporte', route: '/corporate-expenses/support' }]}
				/>
				<TitleTypography image>Suporte</TitleTypography>
			</SectionContainer>

			<S.ContentContainer>
				<S.TitleStyle size='3rem' primaryText='Ajuda'>
					e Informações
				</S.TitleStyle>
				<S.CardsContent>
					<HelpCard
						title='Termos de uso'
						description='Entenda as regras de conduta e outras informações legais necessárias para usar a plataforma.'
						icon={<FiFileText />}
						link='/corporate-expenses/support/terms-of-use'
						color='var(--primary-blue)'
					/>
					<HelpCard
						title='Aviso de privacidade'
						description='Entenda como usamos e gerenciamos os seus dados para garantir a sua privacidade e segurança.'
						icon={<FaUserLock />}
						link='/corporate-expenses/support/privacy-policy'
						color='var(--primary-blue)'
					/>

					<HelpCard
						title='Política antifraude	'
						description='Conheça todas as medidas utilizadas para prevenir, detectar e reagir a atividades suspeitas.'
						icon={<BsFillShieldLockFill />}
						link='/corporate-expenses/support/anti-fraude-policy'
						color='var(--primary-blue)'
					/>
					<HelpCard
						title='Guia de uso	'
						description='Acesse tutoriais passo a passo e aprenda a usar todas as funcionalidades da plataforma com facilidade.'
						icon={<BsJournalBookmarkFill />}
						link='https://www.youtube.com/playlist?list=PLDVkLh04ypC8gD6Brbj5JokpCL_VmWhkh'
						color='var(--primary-blue)'
						isExternalLink={true}
					/>
				</S.CardsContent>
			</S.ContentContainer>
		</div>
	);
}
