import styled from 'styled-components';

export const NotificationBellButton = styled.button`
	position: relative;
	width: 3.8rem;
	height: 3.8rem;
	border-radius: 50%;
	background: none;
	& > svg {
		width: 100%;
		height: 100%;
		color: var(--primary-blue);
	}
	& > span {
		position: absolute;
		top: 0;
		right: 0;
		font-size: 1rem;
		color: var(--white);
		background-color: var(--primary-red);
		width: 2rem;
		height: 2rem;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
`;

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 2rem;
	width: 50rem;
	padding: 2rem;
	background-color: var(--white);
	border-radius: 1rem;
	margin-right: 2rem;
	margin-top: 1rem;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);

	@media (max-width: 1024px) {
		margin-top: -6rem;
		margin-right: 0;
		padding: 3rem;
		width: 100%;
		box-shadow: 50rem 100rem 100rem 100rem rgba(0, 0, 0, 0.5);
	}
`;

export const Header = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

export const CloseModalBtn = styled.button`
	background: none;
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;
`;

export const List = styled.ul`
	display: flex;
	flex-direction: column;
	max-height: 60vh;
	padding-right: 0.4rem;
	overflow-y: auto;
	gap: 1rem;

	&::-webkit-scrollbar {
		width: 1rem;
	}

	&::-webkit-scrollbar-track {
		border-radius: 1rem;
		background-color: #e3e8eb;
		margin-top: 0.2rem;
	}

	&::-webkit-scrollbar-thumb {
		background-color: var(--light-gray);
		border-radius: 1rem;
	}
`;

export const NotificationContainer = styled.li<{ clickable: boolean }>`
	position: relative;
	display: grid;
	grid-template-columns: min-content 1fr min-content;
	margin-right: 0.4rem;
	gap: 0.8rem;
	border: 0.1rem solid #e5eaf2;
	border-radius: 0.8rem;
	border-left: 0.8rem solid var(--primary-blue);
	padding: 1.4rem;
	transition: background-color ease 0.3s;

	${({ clickable }) =>
		clickable
			? `
		cursor: pointer;
		&:hover {
			background-color: #e5eaf2;
		}
		`
			: ''}
`;

export const UnreadMarker = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	width: 4rem;
	height: 4rem;
	overflow: hidden;
	border-radius: inherit;
	/* background-color: red; */

	& > div {
		border-top-right-radius: inherit;
		position: absolute;
		top: -2rem;
		right: -2rem;
		width: 4rem;
		height: 4rem;
		transform: rotateZ(45deg);
		background-color: #c2cbd7;
	}
`;
