import * as S from './styles';
import { FaCheck, FaQuestion } from 'react-icons/fa';
import { Button } from '../../../../../componentsV2/ui/Button';
import React, { useEffect, useMemo, useState } from 'react';
import Modal from '../../../../../componentsV2/ui/Modal';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useAuth } from '../../../../../hooks/useAuth';
import {
	getLimits,
	updateLimit,
} from '../../../../../services/queries/Corpway/Transfers';
import { InputField } from '../../../../../componentsV2/ui/Form/InputField';
import { Typography } from '../../../../../componentsV2/ui/Typography';
import { parseCurrencyStrToNumber } from '../../../../../utils/parseCurrency';
import { useForm, UseFormReturn } from 'react-hook-form';
import { Limits } from '../../../../../@types/CorporateExpenses/Transfer';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { showErrorMessage } from '../../../../../utils/ErrorHandler';
import { moneyMask } from '../../../../../utils/masks';
import {
	convertCentsToFormattedReais,
	convertReaisToCents,
} from '../../../../../utils/CurrencyConvert';
import {
	MobileContainer,
	DesktopContainer,
} from '../../../../../componentsV2/ui/Utils';
import { RiPencilFill } from 'react-icons/ri';

const FormStep = ({
	onClose,
	onNext,
	form,
	currentLimits,
	limitsEditEnabled,
	setLimitsEditEnabled,
}: {
	onClose: () => void;
	onNext: () => void;
	form: UseFormReturn<Limit>;
	currentLimits?: Limits;
	limitsEditEnabled: InputsStatus;
	setLimitsEditEnabled: (limits: InputsStatus) => void;
}) => {
	const { register, setValue } = form;
	const dayAmount = form.watch('ted.day.amount');

	const nextDisabled =
		dayAmount === '' ||
		parseCurrencyStrToNumber(dayAmount) ===
			(currentLimits?.ted.day.amount ?? 0) / 100;

	return (
		<>
			<S.BorderContainer>
				<S.InputFieldWrapper>
					<InputField
						label={'Digite o valor do limite máximo que gostaria de obter:'}
						name={'ted.day.amount'}
						register={register}
						placeholder={'R$ 5.000,00'}
						disabled={limitsEditEnabled.ted.day}
						onChange={(e) => {
							e.target.value = moneyMask(e.target.value);
						}}
						onBlur={(e) => {
							setValue('ted.day.amount', e.target.value);
						}}
					/>
					{limitsEditEnabled.ted.day && (
						<>
							<DesktopContainer>
								<Button
									roundness='lg'
									intent={'link'}
									onClick={() => {
										setLimitsEditEnabled({
											...limitsEditEnabled,
											ted: { day: false },
										});
									}}
									style={{
										color: 'var(--primary-blue)',
										borderBottomColor: 'var(--primary-blue)',
									}}
								>
									Alterar limite
								</Button>
							</DesktopContainer>
							<MobileContainer>
								<Button
									style={{ width: '30px', height: '30px', padding: '0' }}
									onClick={() => {
										setLimitsEditEnabled({
											...limitsEditEnabled,
											ted: { day: false },
										});
									}}
									width={'contain'}
									roundness={'sm'}
									intent={'primary'}
								>
									<RiPencilFill size={20} color={'white'} />
								</Button>
							</MobileContainer>
						</>
					)}
				</S.InputFieldWrapper>

				<S.Row>
					<Typography>Limite máximo atual:</Typography>
					<Typography color={'var(--primary-blue)'} weight={'600'}>
						{convertCentsToFormattedReais(currentLimits?.ted.day.amount ?? 0)}
					</Typography>
				</S.Row>
			</S.BorderContainer>

			<S.ButtonsContainer>
				<Button roundness={'lg'} intent={'terciary'} $outline onClick={onClose}>
					Voltar
				</Button>
				<Button
					roundness={'lg'}
					intent={'primary'}
					disabled={nextDisabled}
					onClick={onNext}
				>
					Avançar
				</Button>
			</S.ButtonsContainer>
		</>
	);
};

const ConfirmationStep = ({
	onBack,
	onConfirm,
	isLoading,
}: {
	onBack: () => void;
	onConfirm: () => void;
	isLoading: boolean;
}) => {
	return (
		<>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					gap: '2.4rem',
					alignItems: 'center',
				}}
			>
				<S.ModalIconContainer>
					<FaQuestion size={24} color={'white'} />
				</S.ModalIconContainer>
				<S.Description>
					<span>
						Sua solicitação levará alguns instantes para ser processada.
					</span>
					<span className={'bold'}> Deseja continuar?</span>
				</S.Description>
			</div>
			<S.ButtonsContainer>
				<Button roundness={'lg'} $outline intent={'terciary'} onClick={onBack}>
					Voltar
				</Button>
				<Button
					roundness={'lg'}
					intent={'primary'}
					onClick={onConfirm}
					shrinkOnLoading={false}
					loading={isLoading}
				>
					Continuar
				</Button>
			</S.ButtonsContainer>
		</>
	);
};

const SuccessStep = ({ onClose }: { onClose: () => void }) => {
	return (
		<>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					gap: '2.4rem',
					alignItems: 'center',
				}}
			>
				<S.ModalIconContainer>
					<FaCheck size={24} color={'white'} />
				</S.ModalIconContainer>
				<S.Description>
					<span>Você receberá no seu</span>
					<span className={'bold'}> e-mail de acesso</span>
					<span> a confirmação da alteração do limite</span>
				</S.Description>
			</div>
			<S.ButtonsContainer>
				<Button roundness={'lg'} intent={'primary'} onClick={onClose}>
					Fechar
				</Button>
			</S.ButtonsContainer>
		</>
	);
};

const LimitSchema = z.object({
	ted: z.object({
		day: z.object({
			amount: z.string(),
		}),
	}),
});

const initialState = {
	ted: {
		day: {
			amount: '',
		},
	},
};

type InputsStatus = {
	ted: {
		day: boolean;
	};
};

const limitInputsStatus: InputsStatus = {
	ted: {
		day: true,
	},
};

type Limit = z.infer<typeof LimitSchema>;

export default function LimitSettingsModal({
	isModalOpen,
	setIsModalOpen,
	hiddeButton = false,
}: {
	isModalOpen: boolean;
	setIsModalOpen: (isOpen: boolean) => void;
	hiddeButton?: boolean;
}) {
	const { currentCompany } = useAuth();
	const [step, setStep] = useState<'form' | 'confirmation' | 'success'>('form');
	const [limitEditEnabled, setLimitEditEnabled] = useState(limitInputsStatus);
	const queryClient = useQueryClient();

	const form = useForm<Limit>({
		defaultValues: initialState,
		resolver: zodResolver(LimitSchema),
	});

	const getLimitQuery = useQuery(
		['getLimits', currentCompany?.id],
		() => getLimits(currentCompany!.id),
		{
			enabled: !!currentCompany,
			staleTime: 0,
			refetchOnWindowFocus: false,
			refetchOnMount: false,
		}
	);

	const { setValue } = form;

	useEffect(() => {
		if (getLimitQuery.data) {
			setValue(
				'ted.day.amount',
				moneyMask((getLimitQuery.data?.ted?.day?.amount ?? 0).toString())
			);
		}
	}, [getLimitQuery.data, setValue]);

	const updateLimitMutation = useMutation(
		['updateLimit', currentCompany?.id],
		({ id, amount }: { id: string; amount: number }) =>
			updateLimit(id, currentCompany!.id, amount),
		{
			onSuccess: () => {
				setStep('success');
			},
			onError: (error) =>
				showErrorMessage(error as Error, 'Erro ao atualizar limite'),
		}
	);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const handleSubmit = () => {
		const values = form.getValues();
		for (const key in values) {
			const value = values[key as keyof Limit];
			const dayAmount = convertReaisToCents(
				parseCurrencyStrToNumber(value.day.amount)
			);

			if (dayAmount !== (getLimitQuery.data?.ted.day.amount ?? 0)) {
				updateLimitMutation.mutate({
					id: getLimitQuery.data?.ted.day.id!,
					amount: dayAmount,
				});
			}
		}
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const onClose = () => {
		setIsModalOpen(false);
		setStep('form');
		setLimitEditEnabled(limitInputsStatus);
		queryClient.invalidateQueries(['getLimits', currentCompany?.id]);
		setValue(
			'ted.day.amount',
			moneyMask((getLimitQuery.data?.ted?.day?.amount ?? 0).toString())
		);
	};

	const Parts = useMemo(
		() => ({
			form: (
				<FormStep
					onClose={onClose}
					form={form}
					currentLimits={getLimitQuery.data}
					onNext={() => setStep('confirmation')}
					limitsEditEnabled={limitEditEnabled}
					setLimitsEditEnabled={setLimitEditEnabled}
				/>
			),
			confirmation: (
				<ConfirmationStep
					isLoading={updateLimitMutation.isLoading}
					onConfirm={handleSubmit}
					onBack={() => setStep('form')}
				/>
			),
			success: <SuccessStep onClose={onClose} />,
		}),
		[
			onClose,
			form,
			getLimitQuery.data,
			limitEditEnabled,
			setLimitEditEnabled,
			updateLimitMutation.isLoading,
			handleSubmit,
		]
	);

	return (
		<>
			<Button
				roundness='lg'
				intent={'link'}
				onClick={() => setIsModalOpen(true)}
				style={{
					color: 'var(--primary-blue)',
					borderBottomColor: 'var(--primary-blue)',
					display: hiddeButton ? 'none' : 'block',
				}}
			>
				Configurar limite &gt;
			</Button>
			<Modal zIndex={1002} isOpen={isModalOpen} onRequestClose={onClose}>
				<S.GeneralContainer style={{ width: '44rem' }}>
					<S.Title>Configuração de limite de TED</S.Title>
					{Parts[step]}
				</S.GeneralContainer>
			</Modal>
		</>
	);
}
