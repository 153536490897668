import { useState } from 'react';
import PageTitle from '../../../components/PageTitle';
import UserCard from '../../../components/UserCard';
import { CgUserList } from 'react-icons/cg';
import * as S from './styles';
import { Collaborator } from '../../../@types';
import Loader from '../../../components/Loader';
import * as TableStyle from '../../../components/Table/TableStyles';
import {
	getStatusIcon,
	parseStatusString,
} from '../../../utils/parseKycStatus';
import { cpfMask } from '../../../utils/masks';
import { EmptyContent } from '../../../components/EmptyContent';
import { useAuth } from '../../../hooks/useAuth';
import { Pagination } from '../../../components/Pagination';
import { Filter, FilterParams } from '../../../components/Filter';
import { useQuery, useQueryClient } from 'react-query';
import {
	FilterCollabsReturn,
	getActiveCollaborators,
	getFilteredCollabs,
	getInactiveCollaborators,
} from '../../../services/queries/Collaborators';
import { TextWithHint } from '../../../components/TextWithHint';
import { showErrorMessage } from '../../../utils/ErrorHandler';
import { FormStatus } from './components/FormStatus';
import { useCollaboratorsListStore } from '../../../stores/useCollaboratorsListStore';
import { useHistoryNonMatchCallback } from '../../../hooks/useHistoryNonMatchCallback';
import { CardsStats } from './components/CardsStats';
import { useSortColumnHook } from '../../../hooks/useSortColumnHook';
import { SortColumnButton } from '../../../components/SortColumnButton';

export default function Collaborators() {
	const { currentCompany } = useAuth();
	const queryClient = useQueryClient();
	const [showingTable, setShowingTable] = useState<string>();
	const [collaboratorsCurrentPage, setCollaboratorsCurrentPage] = useState(1);
	const [
		inactiveCollaboratorsCurrentPage,
		setInactiveCollaboratorsCurrentPage,
	] = useState(1);
	const { currentSortColumn, toggleSort } = useSortColumnHook();
	const {
		currentSortColumn: currentSortColumnDesactive,
		toggleSort: toggleSortDesactive,
	} = useSortColumnHook();
	const [
		activeCollabsFiltersParams,
		setActiveCollabsFiltersParams,
		inactiveCollabsFiltersParams,
		setInactiveCollabsFiltersParams,
		resetFilters,
	] = useCollaboratorsListStore((state) => [
		state.activeCollabsFiltersParams,
		state.setActiveCollabsFiltersParams,
		state.inactiveCollabsFiltersParams,
		state.setInactiveCollabsFiltersParams,
		state.resetFilters,
	]);

	useHistoryNonMatchCallback('collaborators', resetFilters);

	const activeCollabsQuery = useQuery(
		[
			'activeCollabsList',
			currentCompany?.id,
			collaboratorsCurrentPage,
			currentSortColumn,
		],
		() => {
			return getActiveCollaborators(
				collaboratorsCurrentPage,
				currentCompany?.id,
				currentSortColumn
			);
		},
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível buscar os colaboradores. '
				);
			},
			keepPreviousData: true,
		}
	);

	const inactiveCollabsQuery = useQuery(
		[
			'inactiveCollabsList',
			currentCompany?.id,
			inactiveCollaboratorsCurrentPage,
			currentSortColumnDesactive,
		],
		() => {
			return getInactiveCollaborators(
				inactiveCollaboratorsCurrentPage,
				currentCompany?.id,
				currentSortColumnDesactive
			);
		},
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível buscar os colaboradores. '
				);
			},
			keepPreviousData: true,
		}
	);

	function reloadPage() {
		setInactiveCollaboratorsCurrentPage(1);
		setCollaboratorsCurrentPage(1);
		queryClient.resetQueries('activeCollabsList');
		queryClient.resetQueries('inactiveCollabsList');
		queryClient.resetQueries('filteredCollabs');
	}

	function updateFilters(filters: FilterParams[], activeFilter: boolean) {
		if (activeFilter) {
			setCollaboratorsCurrentPage(1);
			setActiveCollabsFiltersParams(filters);
		} else {
			setInactiveCollaboratorsCurrentPage(1);
			setInactiveCollabsFiltersParams(filters);
		}
	}

	const filteredCollabsActive = useQuery<FilterCollabsReturn, Error>(
		[
			'filteredCollabs',
			activeCollabsFiltersParams,
			true,
			currentCompany?.id,
			collaboratorsCurrentPage,
			currentSortColumn,
		],
		() => {
			return getFilteredCollabs(
				activeCollabsFiltersParams,
				true,
				currentCompany?.id,
				collaboratorsCurrentPage,
				currentSortColumn
			);
		},
		{
			onError: (error) => {
				showErrorMessage(
					error as Error,
					'Não foi possível buscar os colaboradores. '
				);
			},
			enabled:
				!!activeCollabsFiltersParams.find((f) => !!f.value) &&
				!!collaboratorsCurrentPage,
		}
	);

	const filteredCollabsInactive = useQuery<FilterCollabsReturn, Error>(
		[
			'filteredCollabs',
			inactiveCollabsFiltersParams,
			false,
			currentCompany?.id,
			inactiveCollaboratorsCurrentPage,
			currentSortColumnDesactive,
		],
		() => {
			return getFilteredCollabs(
				inactiveCollabsFiltersParams,
				false,
				currentCompany?.id,
				inactiveCollaboratorsCurrentPage,
				currentSortColumnDesactive
			);
		},
		{
			onError: (error) => {
				showErrorMessage(
					error as Error,
					'Não foi possível buscar os colaboradores. '
				);
			},
			enabled:
				!!inactiveCollabsFiltersParams.find((f) => !!f.value) &&
				!!inactiveCollaboratorsCurrentPage,
		}
	);

	function isIndividualTableLoading(tableName: string) {
		if (tableName === 'Colaboradores')
			return (
				filteredCollabsActive.isLoading || activeCollabsQuery.isPreviousData
			);
		else if (tableName !== 'Colaboradores')
			return (
				filteredCollabsInactive.isLoading || inactiveCollabsQuery.isPreviousData
			);

		return false;
	}
	function getTableTotalRecords(tableName: string) {
		if (tableName === 'Colaboradores') {
			if (activeCollabsFiltersParams.find((f) => !!f.value)) {
				return filteredCollabsActive.data?.totalCount;
			}
			return activeCollabsQuery.data?.totalActive;
		}

		if (inactiveCollabsFiltersParams.find((f) => !!f.value)) {
			return filteredCollabsInactive.data?.totalCount;
		}
		return inactiveCollabsQuery.data?.totalDesactive;
	}

	function renderCardsStats() {
		if (
			!activeCollabsFiltersParams.find((f) => f.filter === 'card' && f.value) &&
			!inactiveCollabsFiltersParams.find((f) => f.filter === 'card' && f.value)
		)
			return null;

		return <CardsStats />;
	}

	function generateRow(collaborator: Collaborator) {
		return (
			<TableStyle.TableRow key={collaborator.cpf + collaborator.id}>
				<TableStyle.TableData style={{ minWidth: '28rem' }}>
					<UserCard
						name={`${collaborator.first_name} ${collaborator.last_name}`}
						bottomInfo={collaborator.office}
						avatar_url={collaborator.avatar_url}
					/>
				</TableStyle.TableData>
				<TableStyle.TableData style={{ whiteSpace: 'nowrap' }}>
					{cpfMask(collaborator.cpf)}
				</TableStyle.TableData>
				<TableStyle.TableData style={{ minWidth: '16rem' }}>
					<TextWithHint text={collaborator.office} width='14rem' />
				</TableStyle.TableData>
				<TableStyle.TableData style={{ minWidth: '14rem' }}>
					<TextWithHint text={collaborator.section!} width='12rem' />
				</TableStyle.TableData>
				<TableStyle.TableData>
					<S.StatusContainer>
						{getStatusIcon(collaborator.kyc_status!)}
						<span>{parseStatusString(collaborator.kyc_status!)}</span>
					</S.StatusContainer>
				</TableStyle.TableData>
				<TableStyle.TableData>
					<FormStatus formStatus={collaborator.form_status} />
				</TableStyle.TableData>
				<TableStyle.TableData style={{ textAlign: 'center' }}>
					<TableStyle.OptionLink
						data-rh='Detalhes do colaborador'
						to={`collaborators/manage/${collaborator.id}`}
					>
						<CgUserList />
					</TableStyle.OptionLink>
				</TableStyle.TableData>
			</TableStyle.TableRow>
		);
	}

	function getSortColumnOrder(columnName: string, tableName: string) {
		if (tableName === 'Colaboradores') {
			return currentSortColumn?.name === columnName
				? currentSortColumn.order
				: null;
		}
		return currentSortColumnDesactive?.name === columnName
			? currentSortColumnDesactive.order
			: null;
	}
	function handleToggleSortColumn(columnName: string, tableName: string) {
		if (tableName === 'Colaboradores') toggleSort(columnName);
		else toggleSortDesactive(columnName);
	}

	function getTable(tableName: string, collaborators?: Collaborator[]) {
		if (isIndividualTableLoading(tableName) || !collaborators?.length) {
			return (
				<>
					<TableStyle.TitleWrapper>
						{tableName === 'Colaboradores' ? (
							<>
								<PageTitle title={tableName} />
								<Filter
									filterParams={activeCollabsFiltersParams}
									onFiltersChanged={(updatedFilters) => {
										updateFilters(updatedFilters, true);
									}}
								/>
								<TableStyle.ReloadIcon type='reload' onClick={reloadPage} />
							</>
						) : (
							<>
								<PageTitle title={tableName} />
								<Filter
									filterParams={inactiveCollabsFiltersParams}
									onFiltersChanged={(updatedFilters) => {
										updateFilters(updatedFilters, false);
									}}
								/>
							</>
						)}
					</TableStyle.TitleWrapper>
					{isIndividualTableLoading(tableName) ? (
						<Loader />
					) : (
						<EmptyContent text='Nenhum colaborador para ser exibido.' />
					)}
				</>
			);
		}

		return (
			<>
				<TableStyle.TableHeaderContainer>
					<TableStyle.TitleWrapper>
						<PageTitle
							title={tableName}
							totalRecords={getTableTotalRecords(tableName) ?? 0}
						/>
						{tableName === 'Colaboradores' ? (
							<Filter
								filterParams={activeCollabsFiltersParams}
								onFiltersChanged={(updatedFilters) => {
									updateFilters(updatedFilters, true);
								}}
							/>
						) : (
							<Filter
								filterParams={inactiveCollabsFiltersParams}
								onFiltersChanged={(updatedFilters) => {
									updateFilters(updatedFilters, false);
								}}
							/>
						)}
						{showingTable === tableName ? (
							<TableStyle.SeeLess
								onClick={() =>
									showingTable === tableName
										? setShowingTable('')
										: setShowingTable(tableName)
								}
							>
								Ver menos
							</TableStyle.SeeLess>
						) : (
							<TableStyle.SeeMore
								onClick={() =>
									showingTable === tableName
										? setShowingTable('')
										: setShowingTable(tableName)
								}
							>
								Ver mais
							</TableStyle.SeeMore>
						)}
					</TableStyle.TitleWrapper>

					{showingTable === tableName ? (
						tableName === 'Colaboradores' ? (
							activeCollabsFiltersParams.find((f) => !!f.value) ? (
								<>
									<Pagination
										onPageChange={(page) => setCollaboratorsCurrentPage(page)}
										currentPage={collaboratorsCurrentPage}
										totalCount={
											filteredCollabsActive.data
												? filteredCollabsActive.data?.totalCount!
												: 1
										}
									/>
								</>
							) : (
								<>
									<Pagination
										onPageChange={(page) => setCollaboratorsCurrentPage(page)}
										currentPage={collaboratorsCurrentPage}
										totalCount={
											activeCollabsQuery.data
												? activeCollabsQuery.data.totalActive!
												: 1
										}
									/>
								</>
							)
						) : inactiveCollabsFiltersParams.find((f) => !!f.value) ? (
							<Pagination
								onPageChange={(page) =>
									setInactiveCollaboratorsCurrentPage(page)
								}
								currentPage={inactiveCollaboratorsCurrentPage}
								totalCount={
									filteredCollabsInactive.data
										? filteredCollabsInactive.data.totalCount
										: 1
								}
							/>
						) : (
							<Pagination
								onPageChange={(page) =>
									setInactiveCollaboratorsCurrentPage(page)
								}
								currentPage={inactiveCollaboratorsCurrentPage}
								totalCount={
									inactiveCollabsQuery.data
										? inactiveCollabsQuery.data.totalDesactive
										: 1
								}
							/>
						)
					) : (
						<></>
					)}
					{tableName === 'Colaboradores' && (
						<TableStyle.ReloadIcon type='reload' onClick={reloadPage} />
					)}
				</TableStyle.TableHeaderContainer>

				<TableStyle.Table>
					<TableStyle.TableHeader>
						<TableStyle.TableRow>
							<TableStyle.TableHeaderCell>
								<SortColumnButton
									order={getSortColumnOrder('name', tableName)}
									onToggleSort={() => handleToggleSortColumn('name', tableName)}
								/>
								Nome
							</TableStyle.TableHeaderCell>
							<TableStyle.TableHeaderCell>CPF</TableStyle.TableHeaderCell>
							<TableStyle.TableHeaderCell>
								<SortColumnButton
									order={getSortColumnOrder('office', tableName)}
									onToggleSort={() =>
										handleToggleSortColumn('office', tableName)
									}
								/>
								CARGO
							</TableStyle.TableHeaderCell>
							<TableStyle.TableHeaderCell>
								<SortColumnButton
									order={getSortColumnOrder('section', tableName)}
									onToggleSort={() =>
										handleToggleSortColumn('section', tableName)
									}
								/>
								SETOR
							</TableStyle.TableHeaderCell>
							<TableStyle.TableHeaderCell>
								KYC STATUS
							</TableStyle.TableHeaderCell>
							<TableStyle.TableHeaderCell>
								STATUS DO FORMULÁRIO
							</TableStyle.TableHeaderCell>
							<TableStyle.TableHeaderCell>OPÇÕES</TableStyle.TableHeaderCell>
						</TableStyle.TableRow>
					</TableStyle.TableHeader>

					<TableStyle.TableBody>
						{showingTable === tableName
							? collaborators.map((collab) => generateRow(collab))
							: generateRow(collaborators[0])}
					</TableStyle.TableBody>
				</TableStyle.Table>
			</>
		);
	}

	if (inactiveCollabsQuery.isLoading || activeCollabsQuery.isLoading) {
		return (
			<S.Container>
				<PageTitle title={'Colaboradores'} />
				<Loader />
			</S.Container>
		);
	}
	return (
		<S.Container>
			{renderCardsStats()}

			{getTable(
				'Colaboradores',
				activeCollabsFiltersParams.find((f) => !!f.value)
					? filteredCollabsActive.data?.collaborators
					: activeCollabsQuery.data?.collaboratorStatusActive
			)}

			{getTable(
				'Colaboradores Desativados',
				inactiveCollabsFiltersParams.find((f) => !!f.value)
					? filteredCollabsInactive.data?.collaborators
					: inactiveCollabsQuery.data?.collaboratorStatusDesactive
			)}

			<S.BottomOptionsContainer>
				<TableStyle.Button to='/home/collaborators/create'>
					Criar colaborador
				</TableStyle.Button>
				<TableStyle.Button to='/home/collaborators/import'>
					Importar colaboradores
				</TableStyle.Button>
			</S.BottomOptionsContainer>
		</S.Container>
	);
}
