import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { usePayment } from '../../../../../hooks/usePaymentV2';
import { calculateBenefitsTotals } from '../../utils/calculateBenefitsTotals';
import { getBenefitsInputs } from '../../utils/getBenefitValuesInputs';
import {
	addBenefitInputEventListener,
	removeBenefitInputEventListener,
} from '../../utils/setBenefitInputEventListener';
import { Props } from './types';
import { parseCurrencyToBRLStandard } from '../../../../../utils/parseCurrency';

export function BenefitsTotals({ filterDisapproved = false }: Props) {
	const { beneficiaries } = usePayment();
	const [totalValue, setTotalValue] = useState(0);

	useEffect(() => {
		if (beneficiaries.length === 0) return;

		setBenefitsTotals();

		const inputs = getBenefitsInputs();

		inputs.forEach((input) => {
			addBenefitInputEventListener(input, setBenefitsTotals);
		});

		return () => {
			inputs.forEach((input) => {
				removeBenefitInputEventListener(input, setBenefitsTotals);
			});
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [beneficiaries]);

	const setBenefitsTotals = useCallback(() => {
		const benefitsTotal = calculateBenefitsTotals(
			beneficiaries,
			filterDisapproved
		).total;

		setTotalValue(benefitsTotal);
	}, [beneficiaries, filterDisapproved]);

	return (
		<TotalValue style={{ fontWeight: 'bold' }}>
			{`Valor total do lote de pagamento: ${parseCurrencyToBRLStandard(
				totalValue
			)}`}
		</TotalValue>
	);
}

const TotalValue = styled.p`
	font-size: 1.7rem;
	font-weight: bold;
	color: var(--black);
`;
