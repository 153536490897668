import { FaWallet } from 'react-icons/fa';
import { ReactComponent as AlimentacaoIcon } from '../../assets/benefits/alimentacao.svg';
import { ReactComponent as CausasIcon } from '../../assets/benefits/causas.svg';
import { ReactComponent as CulturaIcon } from '../../assets/benefits/cultura.svg';
import { ReactComponent as EducacaoIcon } from '../../assets/benefits/educacao.svg';
import { ReactComponent as GamesIcon } from '../../assets/benefits/games.svg';
import { ReactComponent as MaternidadeIcon } from '../../assets/benefits/maternidade.svg';
import { ReactComponent as HomeIcon } from '../../assets/benefits/home.svg';
import { ReactComponent as JusIcon } from '../../assets/benefits/jus.svg';
import { ReactComponent as PremioIcon } from '../../assets/benefits/premio.svg';
import { ReactComponent as RefeicaoIcon } from '../../assets/benefits/refeicao.svg';
import { ReactComponent as SaudeIcon } from '../../assets/benefits/saude.svg';
import { ReactComponent as TransIcon } from '../../assets/benefits/trans.svg';
import { ReactComponent as ViagemIcon } from '../../assets/benefits/viagem.svg';

export function getBenefitIcon(benefitTitle: string) {
	switch (benefitTitle) {
		case 'ALIMENTAÇÃO':
			return <AlimentacaoIcon />;
		case 'PREMIO':
			return <PremioIcon />;
		case 'PREMIAÇÃO':
			return <PremioIcon />;
		case 'PREMIO_SAQUE':
			return <PremioIcon />;
		case 'PREMIAÇÃO COM SAQUE':
			return <PremioIcon />;
		case 'CAUSAS':
			return <CausasIcon />;
		case 'DOAÇÃO E CAUSAS':
			return <CausasIcon />;
		case 'REFEIÇÃO':
			return <RefeicaoIcon />;
		case 'TRANSPORTE':
			return <TransIcon />;
		case 'SAÚDE':
			return <SaudeIcon />;
		case 'HOME_OFFICE':
			return <HomeIcon />;
		case 'HOME OFFICE':
			return <HomeIcon />;
		case 'JUS':
			return <JusIcon />;
		case 'ASSISTÊNCIA JURÍDICA':
			return <JusIcon />;
		case 'CULTURA':
			return <CulturaIcon />;
		case 'EDUCAÇÃO':
			return <EducacaoIcon />;
		case 'VIAGENS':
			return <ViagemIcon />;
		case 'GAMES':
			return <GamesIcon />;
		case 'MATERNIDADE':
			return <MaternidadeIcon />;
		case 'MATERNIDADE E PATERNIDADE':
			return <MaternidadeIcon />;

		default:
			return <FaWallet />;
	}
}
