import { parseCurrencyToBRLStandard } from './parseCurrency';
import cc from 'currency-codes';

export function convertReaisToCents(value: number) {
	return parseFloat((value * 100).toFixed(2));
}

export function convertCentsToPlainReais(amount: number) {
	return parseCurrencyToBRLStandard(amount / 100, false);
}

export function convertCentsToFormattedReais(value: number) {
	return parseCurrencyToBRLStandard(value / 100);
}

export function convertCentsToCurrency(currencyNumber: string, amount: number) {
	const valueWithoutCode = Number(amount / 100).toFixed(2);

	const currencyCode = cc.number(currencyNumber.padStart(3, '0'))?.code; // 003, 036, 252, etc.

	if (currencyCode === undefined) return valueWithoutCode;

	const currencySymbol = new Intl.NumberFormat('pt-br', {
		style: 'currency',
		currency: currencyCode,
		currencyDisplay: 'symbol',
	});

	return currencySymbol.format(amount / 100);
}
