import { useEffect, useState } from 'react';
import { RiAddCircleLine, RiCloseCircleLine } from 'react-icons/ri';
import { HiWallet } from 'react-icons/hi2';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { Benefit, BenefitsGroup } from '../../../../@types';
import { EmptyContent } from '../../../../components/EmptyContent';
import Modal from '../../../../components/Modal';
import { useAuth } from '../../../../hooks/useAuth';
import { useDialogModal } from '../../../../hooks/useDialogModal';
import {
	UpdateBenefitParams,
	updateGroupsBenefits,
} from '../../../../services/queries/BenefitsGroups';
import { getBenefitIcon } from '../../../../utils/benefits/getBenefitIcon';
import { getBenefitParsedTitle } from '../../../../utils/benefits/getBenefitParsedTitle';
import { showErrorMessage } from '../../../../utils/ErrorHandler';
import * as S from './styles';

interface Props {
	benefitsGroup: BenefitsGroup;
}

interface SelectedBenefits {
	id: string;
	id_baas: string;
}
export function ManageGroupBenefitsModal({ benefitsGroup }: Props) {
	const { currentCompany } = useAuth();
	const { openOptionsDialog } = useDialogModal();
	const [isOpen, setIsOpen] = useState(false);
	const [hasMadeChanges, setHasMadeChanges] = useState(false);
	const [selectedBenefitsIds, setSelectedBenefitsIds] = useState<
		SelectedBenefits[]
	>([]);
	const queryClient = useQueryClient();

	useEffect(() => {
		setSelectedBenefitsIds(
			benefitsGroup.benefits
				.filter((benefit) => benefit.status!)
				.map((b) => {
					return { id: b.id, id_baas: b.id_baas! };
				})
		);
	}, [benefitsGroup]);

	const updateGroupBenefitsQuery = useMutation(
		({ benefits, company_id, benefits_group_id }: UpdateBenefitParams) => {
			return updateGroupsBenefits({ benefits, company_id, benefits_group_id });
		},
		{
			onSuccess: () => {
				queryClient.resetQueries('benefitsGroupsList');
				toast.info('Benefícios do grupo atualizados.');
			},
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao tentar atualizar os benefícios do grupo. '
				);
			},
		}
	);

	async function updateGroupBenefits(selectedBenefits: SelectedBenefits[]) {
		const benefits = selectedBenefits.map((b) => {
			return { id: b.id, id_baas: b.id_baas };
		});

		await updateGroupBenefitsQuery.mutateAsync({
			benefits,
			company_id: currentCompany!.id,
			benefits_group_id: benefitsGroup.id,
		});

		setHasMadeChanges(false);
	}

	function handleSubmit() {
		if (!benefitsGroup.benefits.length) {
			return;
		}

		if (hasMadeChanges) {
			updateGroupBenefits(selectedBenefitsIds);
		}

		setIsOpen(false);
	}

	function handleClose() {
		if (!hasMadeChanges) {
			setIsOpen(false);
			return;
		}

		openOptionsDialog(
			'Alterações não salvas. Tem certeza que deseja sair?',
			'Confirmar',
			() => setIsOpen(false),
			'Cancelar',
			() => {}
		);
	}

	function checkBenefitSelection(id: string) {
		return !!selectedBenefitsIds.find((b) => b.id === id);
	}

	function handleToggleBenefitSelection(benefit: Benefit) {
		if (checkBenefitSelection(benefit.id)) {
			setSelectedBenefitsIds([
				...selectedBenefitsIds.filter((b) => b.id !== benefit.id),
			]);
		} else {
			setSelectedBenefitsIds([
				...selectedBenefitsIds,
				{ id: benefit.id, id_baas: benefit.id_baas! },
			]);
		}
		setHasMadeChanges(true);
	}

	if (!benefitsGroup.benefits.length) {
		return (
			<>
				<S.OpenModalButtonSmall
					onClick={(e) => {
						e.preventDefault();
						setIsOpen(true);
					}}
					data-rh={'Gerenciar benefícios'}
					data-testid='openButton_id'
				>
					<HiWallet />
				</S.OpenModalButtonSmall>

				<Modal
					isOpen={isOpen}
					enableClose={true}
					onRequestClose={() => {
						setIsOpen(false);
					}}
				>
					<S.Container>
						<EmptyContent text='Nenhum benefício encontrado para o grupo.' />
					</S.Container>
				</Modal>
			</>
		);
	}

	return (
		<>
			<S.OpenModalButtonSmall
				onClick={(e) => {
					e.preventDefault();
					setIsOpen(true);
				}}
				data-rh={'Gerenciar benefícios'}
				data-testid='openButton_id'
			>
				<HiWallet />
			</S.OpenModalButtonSmall>

			<Modal isOpen={isOpen} enableClose={true} onRequestClose={handleClose}>
				<S.Container>
					<S.Title>Selecione os benefícios para remover do grupo</S.Title>

					<S.BenefitsList>
						{benefitsGroup.benefits.map((benefit) => (
							<S.BenefitContainer key={benefit.id}>
								<S.TitleContainer>
									{getBenefitIcon(benefit.label ?? benefit.title)}{' '}
									{getBenefitParsedTitle(benefit.label ?? benefit.title)}
								</S.TitleContainer>
								<S.SelectBenefit
									onClick={() => handleToggleBenefitSelection(benefit)}
									selected={!checkBenefitSelection(benefit.id)}
									data-testid={
										checkBenefitSelection(benefit.id)
											? `uncheck-${benefit.id}`
											: `check-${benefit.id}`
									}
								>
									{checkBenefitSelection(benefit.id) ? (
										<RiCloseCircleLine />
									) : (
										<RiAddCircleLine />
									)}
								</S.SelectBenefit>
							</S.BenefitContainer>
						))}
					</S.BenefitsList>
					<S.MainButton onClick={handleSubmit} style={{ width: '16rem' }}>
						Salvar
					</S.MainButton>
				</S.Container>
			</Modal>
		</>
	);
}
