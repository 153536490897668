import { FaArrowDown, FaArrowUp } from 'react-icons/fa';
import styled from 'styled-components';
import { Beneficiary } from '../../contexts/PaymentContextV2';

export type SortOrder = 'asc' | 'desc';

interface SortIconButtonProps {
	sort: SortOrder;
	onToggleSort: () => void;
}
export function SortIconButton({ sort, onToggleSort }: SortIconButtonProps) {
	return (
		<Container onClick={onToggleSort} data-rh={`Ordenar`} data-testid='sortBtn'>
			{sort === 'asc' ? <FaArrowUp /> : <FaArrowDown />}
		</Container>
	);
}

const Container = styled.button`
	background: none;
	margin-left: 1rem;
	& > svg {
		width: 1.5rem;
		height: 1.5rem;
		color: var(--dark-gray);
	}
`;

export function sortByName(sort: SortOrder, beneficiaries: Beneficiary[]) {
	beneficiaries = beneficiaries?.sort((a, b) => a.name.localeCompare(b.name));
	return sort === 'asc' ? beneficiaries : beneficiaries?.reverse();
}
