import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Operator } from '../../../../@types';
import Loader from '../../../../components/Loader';
import OperatorForm from '../../../../components/OperatorForm';
import PageTitle from '../../../../components/PageTitle';
import { useDialogModal } from '../../../../hooks/useDialogModal';
import {
	getOperator,
	toggleStatus,
	updateOperator,
} from '../../../../services/queries/Operators';
import { parseMaskedCPFToRaw } from '../../../../utils/masks';
import * as S from './styles';
import { showErrorMessage } from '../../../../utils/ErrorHandler';

interface ManageOperatorsParams {
	id: string;
}

export function ManageOperators() {
	const { id } = useParams<ManageOperatorsParams>();
	const { openConfirmDialog } = useDialogModal();
	const history = useHistory();
	const queryClient = useQueryClient();

	const fetchOperatorQuery = useQuery<Operator, Error>(
		['fetchOperator', id],
		() => {
			return getOperator(id);
		},
		{
			onSuccess: (data) => {
				if (data.access_level === 'admin') {
					// an ADMIN can not EDIT another ADMIN
					history.push('/404');
				}
			},
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao tentar buscar o operador.'
				);
			},
			enabled: !!id,
			refetchOnWindowFocus: false,
		}
	);

	const updateOperatorMutation = useMutation(
		(operator: Operator) => {
			return updateOperator(id, operator);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries(['fetchOperator', id]);
				toast.info('Operador editado com sucesso');
			},
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu algum problema ao tentar atualizar o operador. '
				);
			},
		}
	);

	async function handleUpdateOperator(operator: Operator) {
		let { cpf, email, name, office } = operator;
		// alert(JSON.stringify(data))
		const updatedColaboratorData = {
			cpf: parseMaskedCPFToRaw(cpf),
			name,
			email,
			office,
		} as Operator;

		await updateOperatorMutation.mutateAsync(updatedColaboratorData);
	}

	const toggleStatusMutation = useMutation(
		(status: boolean) => {
			return toggleStatus(id, status);
		},
		{
			onSuccess: () => {
				toast.info(`Status do operador atualizado!`);
				queryClient.invalidateQueries(['fetchOperator', id]);
			},
			onError: (err) => {
				console.log(err);
				toast.error(
					'Ocorreu algum problema ao mudar o status do operador. Tente novamente'
				);
			},
		}
	);

	async function handleToggleStatus() {
		openConfirmDialog(
			`Tem certeza que deseja ${
				fetchOperatorQuery.data!.status ? 'desativar' : 'ativar'
			} o operador?`,
			'Sim',
			async () => {
				const status = !fetchOperatorQuery.data!.status;
				await toggleStatusMutation.mutateAsync(status);
			}
		);
	}

	if (
		!fetchOperatorQuery.data ||
		toggleStatusMutation.isLoading ||
		updateOperatorMutation.isLoading
	) {
		return (
			<S.Container>
				<PageTitle title='Gerenciar Operador' />
				<Loader />
			</S.Container>
		);
	}
	return (
		<S.Container>
			<PageTitle title='Gerenciar Operador' />
			<S.Content>
				<OperatorForm
					operator={fetchOperatorQuery.data!!}
					onSubmit={handleUpdateOperator}
					changeStatus={handleToggleStatus}
				/>
			</S.Content>
		</S.Container>
	);
}
