import {
	FaCalendar,
	FaCheckCircle,
	FaExclamationCircle,
	FaExclamationTriangle,
	FaQuestionCircle,
	FaRegClock,
	FaTimesCircle,
} from 'react-icons/fa';

import { RiExchangeDollarLine } from 'react-icons/ri';

export function parseStatusString(status: string) {
	if (status === 'canceled') return 'Cancelado';
	if (status === 'disapproved') return 'Reprovado';
	if (status === 'requested') return 'Aguardando';
	if (status === 'scheduled') return 'Agendado';
	if (status === 'approved') return 'Finalizado';
	if (status === 'processing') return 'Processando';
	if (status === 'finished error') return 'Finalizado com erro(s)';
	if (status === 'chargeback-processing') return 'Processando estorno';
	if (status === 'chargeback-success') return 'Estornado';
	if (status === 'chargeback-partial') return 'Estorno parcial';
	if (status === 'chargeback-error') return 'Estornado com erro';
	return '?????';
}
// 'requested' | 'disapproved' | 'canceled' | 'approved'
export function getStatusIcon(status: string) {
	if (status === 'approved') return <FaCheckCircle color='#1CCA0D' />;

	if (status === 'disapproved') return <FaExclamationCircle color='#FB1313' />;

	if (status === 'canceled') return <FaTimesCircle color='#000' />;

	if (status === 'requested') return <FaRegClock color='#E0E414' />;

	if (status === 'finished error')
		return <FaExclamationTriangle color='#FF5900' />;

	if (status === 'processing') return <FaRegClock color='#C6C6C6' />;

	if (status === 'chargeback-processing') return <FaRegClock color='#C6C6C6' />;

	if (status === 'chargeback-success')
		return <RiExchangeDollarLine color='#1CCA0D' />;

	if (status === 'chargeback-partial')
		return <RiExchangeDollarLine color='#FF5900' />;

	if (status === 'chargeback-error')
		return <RiExchangeDollarLine color='#FB1313' />;

	if (status === 'scheduled') return <FaCalendar color='#656C80' />;

	return <FaQuestionCircle />;
}
