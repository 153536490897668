import styled from 'styled-components';
import { ModalFooter } from '../../../../../../componentsV2/ui/Modal/styles';

export const Row = styled.div<{ flex?: number }>`
	display: flex;
	flex-direction: row;
	flex: ${({ flex }) => flex ?? 1};
	width: 100%;
`;

export const Container = styled.div`
	display: flex;
	flex-direction: row;
	width: 100rem;
	gap: 1rem;

	@media (max-width: 1024px) {
		width: 100%;
		flex-direction: column;
		align-items: center;
		gap: 0.75rem;
	}
`;

export const ButtonsContainer = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	flex: 1;
	align-items: flex-end;
	justify-content: flex-end;
	gap: 2rem;

	@media (max-width: 1024px) {
		align-items: center;
		justify-content: center;
		margin: 0;
	}
`;

export const FormContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	flex-grow: 0;
	max-width: 100%;
	gap: 1rem;
`;

export const CostCenterContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 0.75rem;
`;

export const AddButtonContainer = styled.div`
	display: flex;
	flex-direction: column;

	@media (max-width: 1024px) {
		flex-direction: row;
		width: 100%;
		justify-content: space-between;
		align-items: center;
	}
`;

export const CostCenterScrollView = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 0.5rem;
	overflow: auto;

	@media (max-width: 1024px) {
		flex-direction: row;
		gap: 0;
	}

	& > div {
		padding-bottom: 0.25rem;
		display: flex;
		flex-direction: row;
		gap: 1rem;
		overflow: auto;
		flex-shrink: 0;

		&::-webkit-scrollbar {
			width: 1rem;
			height: 1rem;
		}

		&::-webkit-scrollbar-track {
			background-color: #e9eaeb;
			border-radius: 0.6rem;
		}

		&::-webkit-scrollbar-thumb {
			background-color: #258bdb;
			border-radius: 0.6rem;
		}
	}
`;

export const CostCenterLabel = styled.span`
	font-family: 'Poppins', sans-serif;
	font-weight: 400;
	color: var(--dark-gray2);
	font-size: 1.2rem;
`;

export const CardBanner = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	align-items: center;
	border-radius: 1.5rem;
	gap: 1rem;
	padding: 2.5rem 2.5rem 0 2.5rem;
	text-align: center;
`;

export const AccessCard = styled.div`
	display: flex;
	flex-direction: column;
	width: 264px;
	height: 353px;
	border: 1px solid #d2d7e4;
	border-radius: 1.6rem;

	& > img {
		width: auto;
		height: 20rem;
		padding: 2rem;
		object-fit: contain;

		@media (max-width: 1024px) {
			flex-direction: row-reverse;
			height: 12rem;
			width: 12rem;
			align-items: center;
		}

		@media (max-width: 500px) {
			flex-direction: row-reverse;
			height: 10rem;
			width: 10rem;
			align-items: center;
		}
	}

	& > div {
		padding: 1rem;
		font-size: 1.25rem;
		border-radius: 1.6rem;
		background-color: #f0f3f8;
		@media (max-width: 1024px) {
			display: flex;
			align-items: flex-start;
			text-align: start;
			height: 13rem;
			color: #ffffff;
			background-color: #393939;
			border-radius: 1rem 0 0 1rem;
		}
	}

	@media (max-width: 1024px) {
		width: 35rem;
		flex-direction: row-reverse;
		height: auto;
		align-items: center;
	}
`;

export const WarningDescription = styled.p<{ bold?: boolean }>`
	font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
	width: 100%;
	font-size: 1.3rem;
	text-align: justify;
	text-justify: inter-word;
`;

export const SmallModalContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 2rem;
	width: 40rem;

	@media (max-width: 1024px) {
		width: 100%;
	}
`;

export const ButtonsContainerSmallModal = styled(ModalFooter)`
	margin: 0;
`;
