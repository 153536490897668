import styled from 'styled-components';
import { OptionsButton } from '../../../../../../components/Button';

export const OpenModalBtn = styled.button`
	background-color: var(--primary-blue);
	padding: 1rem;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	& svg {
		width: 2rem;
		height: 2rem;
		color: var(--white);
	}
`;

export const Container = styled.div`
	display: flex;
	width: 40rem;
	flex-direction: column;
`;

export const Title = styled.h1`
	font-size: 1.8rem;
	font-weight: 500;
	text-align: center;
	margin-bottom: 2rem;
`;

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	row-gap: 2rem;
	font-size: 1.6rem;
`;

export const OptionsContainer = styled.div`
	display: flex;
	justify-content: space-between;
`;

interface OptionProps {
	error: boolean;
}
export const Option = styled.button<OptionProps>`
	${OptionsButton}
	display: flex;
	align-items: center;
	column-gap: 0.5rem;

	background-color: ${({ error }) =>
		error ? 'var(--primary-red)' : '#1CCA0D'};
	border-color: ${({ error }) => (error ? 'var(--primary-red)' : '#1CCA0D')};

	& > svg {
		width: 1.4rem;
		height: 1.4rem;
	}
`;
