import { SettingsPreferences } from '../../@types';
import { PreferencesProps } from '../../pages/FlexibleBenefits/Settings';
import api from '../api';

export async function getPreferences(
	company_id: string
): Promise<SettingsPreferences> {
	const { data } = await api.get(`/api/v1/rh/config/${company_id}/preferences`);

	return data;
}

export async function updatePreferences(
	preferences: PreferencesProps,
	company_id: string
) {
	await api.put(`/api/v1/rh/config/${company_id}/preferences`, preferences);
}
