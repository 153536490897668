import { createColumnHelper } from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { UserDetails } from '../../../../../@types/CorporateExpenses/User';
import { InfoContent } from '../../../../../componentsV2/InfoContent';
import { Table } from '../../../../../componentsV2/Table';
import { Button } from '../../../../../componentsV2/ui/Button';
import { PasswordInput } from '../../../../../componentsV2/ui/Form/PasswordInput';
import Modal from '../../../../../componentsV2/ui/Modal';
import { Typography } from '../../../../../componentsV2/ui/Typography';
import { useValidateCorpwayUserPassword } from '../../../../../hooks/useValidateCorpwayUserPassword';
import {
	CORPWAY_USERS_KEY,
	CardsOnlyUsers,
	deleteUser,
	editUserCard,
	getCardsOnlyUsers,
} from '../../../../../services/queries/Corpway/User';
import { showErrorMessage } from '../../../../../utils/ErrorHandler';
import { formatCardNumber } from '../../../../../utils/formatCardNumber';
import SelectCardsNewUser from './SelectCardsNewUser';
import HeaderModal from '../../../../../componentsV2/ui/Modal/HeaderModal';
import { FaQuestion } from 'react-icons/fa';
import { IoPerson } from 'react-icons/io5';
import { MdCancel } from 'react-icons/md';
import { Loader } from '../../../../../componentsV2/ui/Loader';

interface Props {
	user: UserDetails;
}

export interface UsersNewCard {
	card_id: string;
	new_user_id: string;
}

const cardsColumnHelper = createColumnHelper<CardsOnlyUsers>();

interface FormData {
	password: string;
}

const DeleteUserModal = ({ user }: Props) => {
	const queryClient = useQueryClient();
	const history = useHistory();
	const [isOpen, setOpen] = useState(false);
	const [isVerify, setVerify] = useState(false);
	const [isAllAllocated, setAllAllocated] = useState(false);
	const [allocatedCardsConfirmation, setAllocatedCardsConfirmation] =
		useState(false);
	const [usersNewCard, setUsersNewCard] = useState<UsersNewCard[]>([]);
	const {
		register,
		watch,
		reset,
		formState: { errors },
		setError,
	} = useForm<FormData>({
		defaultValues: {
			password: '',
		},
	});
	const passwordInputValue = watch('password');
	const { validateUserPassword, isPasswordValidationLoading } =
		useValidateCorpwayUserPassword({
			onSuccess: onPasswordValidationSuccess,
			onFailure: () => {
				setError('password', {
					type: 'manual',
					message: 'Senha incorreta, por favor digite novamente',
				});
			},
		});

	function onPasswordValidationSuccess() {
		setOpen(false);
		editUserCardMutation.mutate(usersNewCard);
		deleteUserMutation.mutate(user.id);
	}

	const getCardsOnlyUsersQuery = useQuery(
		[CORPWAY_USERS_KEY, user.id, 'cards-only'],
		() => getCardsOnlyUsers(user.id),
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao buscar as informações do usuário. '
				);
			},
		}
	);

	const editUserCardMutation = useMutation(
		(data: UsersNewCard[]) => {
			return editUserCard(data, user.id);
		},
		{
			onSuccess: () => {
				queryClient.resetQueries([CORPWAY_USERS_KEY]);
			},
			onError: (error) => {
				showErrorMessage(error as Error, 'Não foi possível deletar o Usuário');
			},
		}
	);

	const deleteUserMutation = useMutation(
		(userId: string) => {
			return deleteUser(userId);
		},
		{
			onSuccess: () => {
				queryClient.resetQueries([CORPWAY_USERS_KEY]);
				history.push('/corporate-expenses/management');
				handleClose();
			},
			onError: (error) => {
				showErrorMessage(error as Error, 'Não foi possível deletar o Usuário');
			},
		}
	);

	function handleClose() {
		setOpen(false);
		setVerify(false);
		setAllocatedCardsConfirmation(false);
		setUsersNewCard([]);
	}

	const areThereCardsAllocated = getCardsOnlyUsersQuery.data?.length! > 0;

	function setUserNewCard(CardId: string, userId: string | null) {
		if (userId) {
			setUsersNewCard([
				...usersNewCard.filter((u) => u.card_id !== CardId),
				{ card_id: CardId, new_user_id: userId },
			]);
		} else {
			setUsersNewCard(usersNewCard.filter((u) => u.card_id !== CardId));
		}
	}

	useEffect(() => {
		setAllAllocated(
			usersNewCard.length === getCardsOnlyUsersQuery.data?.length
		);
	}, [usersNewCard]); // eslint-disable-line

	const cardsColumns = useMemo(
		() => [
			cardsColumnHelper.accessor('card_pan', {
				header: () => (
					<div style={{ backgroundColor: 'white', width: '100%' }}>Cartão</div>
				),
				cell: (info) => formatCardNumber(info.getValue()),
			}),
			cardsColumnHelper.accessor('card_alias', {
				cell: (info) => info.getValue(),

				header: () => (
					<div style={{ backgroundColor: 'white', width: '100%' }}>Apelido</div>
				),
			}),
			cardsColumnHelper.display({
				id: 'configurar',
				header: () => (
					<div
						style={{
							backgroundColor: 'white',
							width: '100%',
							textAlign: 'right',
						}}
					>
						Configurar
					</div>
				),
				meta: {
					align: 'end',
				},
				cell: (props) => (
					<SelectCardsNewUser
						user={{ ...props.row.original, user_id: user.id }}
						newUserId={
							usersNewCard.find((u) => u.card_id === props.row.original.card_id)
								?.new_user_id ?? null
						}
						cost_center_id={props.row.original.cost_center_id!}
						onCardSelection={(c, u) => setUserNewCard(u, c)}
					/>
				),
			}),
		],
		[usersNewCard] // eslint-disable-line
	);

	const VerifyContent = () => {
		return (
			<ContentContainer>
				<HeaderModal
					icon={{
						icon: <FaQuestion size={28} color='white' />,
						background: 'var(--primary-red)',
					}}
					title={[
						{ text: 'Você tem certeza que deseja \n', isBold: false },
						{ text: 'excluir este usuário?', isBold: true },
					]}
				/>

				<Typography
					size='1.4rem'
					style={{ textAlign: 'center', display: 'block', padding: '2rem' }}
				>
					Após excluir um usuário,{' '}
					<SpanStyle>não será possível reativá-lo.</SpanStyle> Você tem certeza
					que deseja continuar?

					{user.isUniqueAdmin && getCardsOnlyUsersQuery.data?.length! === 0 && (
						<Typography
							size="1.2rem"
							color="var(--primary-red)"
							style={{
								width: 'auto',
								textAlign: 'center',	
								fontWeight: 600,
								marginTop: '1rem'
							}}
						>
							Atenção: Você é o único administrador. Adicione outro administrador antes de prosseguir.
						</Typography>
					)}
				</Typography>

				<OptionsBottonContainer>
					<Button
						intent='terciary'
						roundness='lg'
						$outline
						onClick={handleClose}
					>
						Voltar
					</Button>
					<Button
						intent='secondary'
						roundness='lg'
						onClick={() => setVerify(true)}
						disabled={user.isUniqueAdmin}
					>
						Continuar
					</Button>
				</OptionsBottonContainer>
			</ContentContainer>
		);
	};

	const UserCardsContent = () => {
		function handleBack() {
			setVerify(false);
			setUsersNewCard([]);
		}

		return (
			<ContentContainer>
				<HeaderModal
					icon={{
						icon: <MdCancel size={50} color={'red'} />,
						background: '#f0f3f8',
					}}
					title={[
						{ text: 'Os seguintes cartões se encontrarão ', isBold: false },
						{ text: 'sem responsável', isBold: true },
					]}
				/>
				<InformationContainer>
					<Typography
						size='1.4rem'
						style={{ textAlign: 'justify', display: 'block' }}
					>
						Ao excluir este usuário, identificamos que um ou mais cartões
						ficarão sem um usuário responsável.{' '}
						<span style={{ fontWeight: 'bolder' }}>
							Configure manualmente um responsável
						</span>{' '}
						antes de prosseguir com a exclusão
					</Typography>

					{user.isUniqueAdmin && (
						<Typography
							size="1.4rem"
							color="var(--primary-red)"
							style={{
								textAlign: 'justify',
								fontWeight: 'bold',
							}}
						>
							Atenção: Você é o único administrador. Adicione outro administrador antes de prosseguir.
						</Typography>
					)}

					<Table
						variant={'light'}
						columns={cardsColumns}
						data={getCardsOnlyUsersQuery.data ?? []}
						maxHeight='22rem'
						idPath='card_id'
						rightComponent={
							<div style={{ width: '100%' }}>
								<Typography size='1.6rem ' weight='600'>
									Total de cartões alocados -{' '}
									{getCardsOnlyUsersQuery.data?.length ?? ''}
								</Typography>
							</div>
						}
					/>

					<OptionsBottonContainer>
						<Button
							intent='terciary'
							roundness='lg'
							$outline
							onClick={handleBack}
						>
							Voltar
						</Button>
						<Button
							intent='secondary'
							roundness='lg'
							disabled={!isAllAllocated || user.isUniqueAdmin}
							onClick={() => {
								setAllocatedCardsConfirmation(true);
							}}
						>
							Continuar
						</Button>
					</OptionsBottonContainer>
				</InformationContainer>
			</ContentContainer>
		);
	};

	const ConfirmationContent = () => {
		function handleBack() {
			reset();
			if (areThereCardsAllocated) {
				setAllocatedCardsConfirmation(false);
			} else {
				handleClose();
			}
		}

		return (
			<ContentContainer>
				<HeaderModal
					icon={{
						icon: <IoPerson size={28} color='white' />,
						background: 'var(--primary-red)',
					}}
					title={[
						{ text: 'Confirmação de ', isBold: false },
						{ text: 'exclusão do usuário', isBold: true },
					]}
				/>
				<InformationContainer>
					<InfoContent label='Nome do usuário'>{user.name}</InfoContent>
					<InfoContent label='Email'>{user.email}</InfoContent>
					<PasswordInput
						label='Insira a sua senha'
						id='password'
						name='password'
						register={register}
						autoFocus
						autoComplete='new-password'
						errorMessage={errors.password?.message}
					/>

					<OptionsBottonContainer>
						<Button
							intent='terciary'
							roundness='lg'
							$outline
							onClick={handleBack}
						>
							Voltar
						</Button>
						<Button
							intent='secondary'
							roundness='lg'
							shrinkOnLoading={false}
							onClick={() => {
								validateUserPassword(passwordInputValue);
							}}
							loading={
								isPasswordValidationLoading ||
								editUserCardMutation.isLoading ||
								deleteUserMutation.isLoading
							}
							disabled={!passwordInputValue}
						>
							Excluir usuário
						</Button>
					</OptionsBottonContainer>
				</InformationContainer>
			</ContentContainer>
		);
	};

	const Content = () => {
		if (!isVerify) {
			return <VerifyContent />;
		} else if (
			isVerify &&
			areThereCardsAllocated &&
			!allocatedCardsConfirmation
		) {
			return <UserCardsContent />;
		} else if (isVerify) {
			return <ConfirmationContent />;
		}
	};

	return (
		<div className='deleteUser'>
			<Button intent='link' onClick={() => setOpen(true)} type='button'>
				Deletar usuário
			</Button>

			<Modal isOpen={isOpen} onRequestClose={handleClose}>
				{getCardsOnlyUsersQuery.isLoading || !getCardsOnlyUsersQuery.data ? (
					<LoadingContainer>
						<Loader />
					</LoadingContainer>
				) : (
					Content()
				)}
			</Modal>
		</div>
	);
};

export default DeleteUserModal;

const ContentContainer = styled.div`
	width: 40rem;
	display: flex;
	flex-direction: column;
	row-gap: 1rem;

	& > p {
		span {
			font-weight: 600;
		}
	}

	@media (max-width: 1024px) {
		width: 100%;
	}
`;

const LoadingContainer = styled.div`
	width: 40rem;
	min-height: 20rem;
	display: flex;
	align-items: center;
	justify-content: center;

	@media (max-width: 1024px) {
		width: 100%;
	}
`;

const InformationContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding: 2rem;
	gap: 2.8rem;
`;
const OptionsBottonContainer = styled.div`
	display: flex;
	justify-content: space-around;
	padding: 2rem;
`;

const SpanStyle = styled.span`
	color: var(--primary-red);
`;
