import { FaCreditCard } from 'react-icons/fa';
import styled from 'styled-components';
import { Typography } from '../../../../componentsV2/ui/Typography';

interface Props {
	alias: string;
	pan: string;
	small?: boolean;
	icon?: boolean;
}

export function CardInfo({ alias, pan, small = false, icon = true }: Props) {
	alias = alias.charAt(0).toUpperCase() + alias.slice(1).toLowerCase();

	if (small)
		return (
			<Typography size='1.6rem' color='var(--dark-gray)' weight='600'>
				{alias}&nbsp;
				<Typography size='1.2rem'> - Final {pan.slice(-4)}</Typography>
			</Typography>
		);

	return (
		<Container>
			<span>
				{icon && <FaCreditCard />} {pan}
			</span>{' '}
			| <span>{alias}</span>
		</Container>
	);
}

const Container = styled.div`
	display: flex;
	align-items: center;
	column-gap: 1rem;
	& > span {
		display: flex;
		align-items: center;
		column-gap: 1rem;
		& > svg {
			width: 2rem;
			height: 2rem;
		}
	}
`;
