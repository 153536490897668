import { useParams } from 'react-router-dom';
import PageTitle from '../../../../components/PageTitle';
import avatarImg from '../../../../assets/avatar.svg';
import * as S from './styles';
import Loader from '../../../../components/Loader';
import { EmptyContent } from '../../../../components/EmptyContent';
import { CostCenter } from '../../../../@types';
import { useAuth } from '../../../../hooks/useAuth';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
	CostCenterCreationParams,
	fetchCostCenterQuery,
	updateCostCenter,
} from '../../../../services/queries/CostCenters';
import { showErrorMessage } from '../../../../utils/ErrorHandler';
import * as FormStyle from '../../../../components/Form/FormStyles';
import {
	ObrigatoryFieldsIndication,
	ObrigatoryIndicator,
} from '../../../../components/ObrigatoryFieldsIndicator';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import PreventTransitionPrompt from '../../../../components/PreventTransitionPrompt';

interface CostCenterDetailsParams {
	id: string;
}

export function CostCentersDetails() {
	const { currentCompany } = useAuth();
	const { id } = useParams<CostCenterDetailsParams>();

	const queryClient = useQueryClient();

	const { register, handleSubmit, reset, formState } = useForm();

	const isDirty = formState.isDirty;
	const [allowNavigation, setAllowNavigation] = useState(!isDirty);

	useEffect(() => {
		setAllowNavigation(!isDirty);
	}, [isDirty]);

	const fetchCostCenter = useQuery<CostCenter, Error>(
		['fetchCostCenter', currentCompany?.id, id],
		() => {
			return fetchCostCenterQuery(id, currentCompany?.id);
		},
		{
			onSuccess: (data) => {
				reset({
					...data,
				});
			},
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao buscar o centro de custo. '
				);
			},
			enabled: !!currentCompany && !!id,
		}
	);

	const updateCostCenterQuery = useMutation(
		({ title, description }: CostCenterCreationParams) => {
			return updateCostCenter(
				title,
				description,
				fetchCostCenter.data!.id,
				currentCompany?.id
			);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('fetchCostCenter');
				toast.info('Centro de custo atualizado com sucesso!');
			},
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível atualizar o centro de custo. '
				);
			},
		}
	);

	function handleUpdate(costCenter: CostCenter) {
		if (!isDirty) {
			toast.error(
				'Modifique alguma informação para atualizar o centro de custo.'
			);
			return;
		}

		updateCostCenterQuery.mutate({
			title: costCenter.title,
			description: costCenter.description,
		});
	}

	if (
		fetchCostCenter.isLoading ||
		!fetchCostCenter.data ||
		updateCostCenterQuery.isLoading
	) {
		return (
			<S.Container>
				<Loader />
			</S.Container>
		);
	}
	return (
		<S.Container>
			{!allowNavigation && <PreventTransitionPrompt />}

			<PageTitle title={'Detalhamento de centro de custo'} />

			<FormStyle.Form onSubmit={handleSubmit(handleUpdate)}>
				<FormStyle.FormContainer>
					<FormStyle.FieldGroup>
						<FormStyle.Field>
							<FormStyle.Label htmlFor='title'>
								Título <ObrigatoryIndicator />
							</FormStyle.Label>
							<FormStyle.Input
								data-testid='titleInput_test_id'
								type='text'
								id='title'
								{...register('title')}
								name='title'
								required
							/>
						</FormStyle.Field>
					</FormStyle.FieldGroup>

					<FormStyle.FieldGroup>
						<FormStyle.Field>
							<FormStyle.Label htmlFor='description'>
								Descrição <ObrigatoryIndicator />
							</FormStyle.Label>
							<FormStyle.InputTextArea
								data-testid='descriptionInput_test_id'
								id='description'
								{...register('description')}
								name='description'
								required
							/>
						</FormStyle.Field>
					</FormStyle.FieldGroup>

					<>
						<ObrigatoryFieldsIndication />
						<FormStyle.ButtonsContainer>
							<FormStyle.FormButton type='submit'>Salvar</FormStyle.FormButton>
						</FormStyle.ButtonsContainer>
					</>
				</FormStyle.FormContainer>
			</FormStyle.Form>

			<S.CollaboratorsContainer>
				<S.CollaboratorsTitle>
					Colaboradores nesse centro de custo (
					{fetchCostCenter.data.users.length})
				</S.CollaboratorsTitle>
				<S.CollaboratorsList>
					{!fetchCostCenter.data.users.length ? (
						<EmptyContent text='Nenhum colaborador adicionar ao centro de custo' />
					) : (
						fetchCostCenter.data.users.map((collaborator) => (
							<S.CollaboratorCard key={collaborator.id}>
								<S.AvatarContainer>
									<S.Avatar
										src={
											collaborator.avatar ? collaborator.avatar_url : avatarImg
										}
									/>
									<S.CollabName>{`${collaborator.first_name} ${collaborator.last_name}`}</S.CollabName>
								</S.AvatarContainer>

								<S.CollabOffice>{collaborator.office}</S.CollabOffice>
							</S.CollaboratorCard>
						))
					)}
				</S.CollaboratorsList>
			</S.CollaboratorsContainer>
		</S.Container>
	);
}
