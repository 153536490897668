import { CardBaID } from '../../../../@types/CorporateExpenses/Card';

const BA_ID_MAP: Record<CardBaID, string> = {
	1: 'Software',
	2: 'Viagens',
	3: 'Geral',
};
export function parseCardBaID(ba_id: CardBaID) {
	return BA_ID_MAP[ba_id];
}
