import { Message } from '../../@types';
import {
	FilterParams,
	parseFilterParamsToParams,
} from '../../components/Filter';
import {
	SortColumn,
	parseSortColumnToParams,
} from '../../hooks/useSortColumnHook';
import api from '../api';

export type CreateMessageBody = {
	title: string;
	body: string;
	segmentation: string;
	recipients_ids: string[];
};

export interface ListMessagesResponse {
	totalMessages: number;
	messages: Message[];
}

export async function createMessage(
	body: CreateMessageBody,
	companyId?: string
): Promise<void> {
	const { data } = await api.post(`api/v1/rh/messages`, {
		...body,
		company_id: body.segmentation === 'company' ? companyId : null,
	});

	return data;
}

export async function getMessages(
	page: number,
	sortColumn: SortColumn | null
): Promise<ListMessagesResponse> {
	const { data } = await api.get(`api/v1/rh/messages/q?page=${page}`, {
		params: {
			...parseSortColumnToParams(sortColumn),
		},
	});
	return data;
}

export async function getMessage(id: string): Promise<Message> {
	const { data } = await api.get(`api/v1/rh/message/${id}`);
	return data;
}

export async function getFilteredMessages(
	filtersParams: FilterParams[],
	page?: number,
	sortColumn: SortColumn | null = null
): Promise<ListMessagesResponse> {
	const { data } = await api.get(`api/v1/rh/messages/filter/q?page=${page}`, {
		params: {
			...parseFilterParamsToParams(filtersParams),
			...parseSortColumnToParams(sortColumn),
		},
	});

	return data;
}
