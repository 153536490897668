import styled from 'styled-components';
import HeaderBg from '../../../assets/profile_header_bg.png';

export const HeaderImage = styled.div`
	background-image: url(${HeaderBg});
	background-size: cover;
	background-position: center;
	border-top-left-radius: 1.6rem;
	border-top-right-radius: 1.6rem;
	height: 13rem;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const Content = styled.div`
	display: flex;
	flex-direction: row;
	height: 100%;
`;

export const LeftNavigator = styled.div`
	display: flex;
	flex-direction: column;
	padding-top: 10rem;
	border-right: 1px solid var(--light-gray);
	width: 30rem;
	& > div {
		display: grid;
	}

	@media (max-width: 850px) {
		display: none;
	}
`;

export const TabLinkContainer = styled.div`
	gap: 3rem;
`;

export const TabLink = styled.button<{ active: boolean }>`
	color: ${(props) =>
		props.active ? 'var(--primary-blue)' : 'var(--dark-gray)'};
	font-size: 1.6rem;
	font-weight: ${(props) => (props.active ? 'bold' : 'normal')};
	border-bottom: ${(props) =>
		props.active ? '.2rem solid var(--primary-blue)' : 'none'};
	padding-bottom: 1rem;
	margin: 2rem 4rem;
	background: none;
	text-align: start;
	width: 14.3rem;

	@media (max-width: 850px) {
		text-decoration: underline;
		margin: 0;
		text-align: center;
		width: auto;
	}
`;

export const SubTabLink = styled.button<{ active: boolean }>`
	margin: 1rem 4rem;
	color: ${(props) =>
		props.active ? 'var(--primary-blue)' : 'var(--dark-gray)'};
	font-size: 1.4rem;
	font-weight: ${(props) => (props.active ? 'bold' : 'normal')};
	background: none;
	display: flex;
	align-items: center;
	&::before {
		content: '';
		width: 0.5rem;
		height: 0.5rem;
		background-color: ${(props) =>
			props.active ? 'var(--primary-blue)' : 'none'};
		border-radius: 50%;
		margin-right: 1rem;
		margin-left: 1rem;

		@media (max-width: 850px) {
			display: none;
		}
	}

	@media (max-width: 850px) {
		${(props) =>
			props.active && 'border-bottom: 2px solid var(--primary-blue);'}
		width: 100%;
		justify-content: center;
		padding-bottom: 1.6rem;
	}
`;

export const UserFormContainer = styled.div`
	display: flex;
	width: 100%;
	justify-content: center;
`;

export const MainContent = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	transform: translateY(-7rem);
	gap: 4rem;
	width: 100%;
`;

export const UserInfo = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
	align-items: center;
	width: 32rem;
	@media (max-width: 850px) {
		width: 90%;
	}
`;

export const RoleContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 1rem;
	border: 0.1rem solid #c6c6c6;
	padding: 1rem 2rem;
	width: 100%;
`;

export const MobileContainer = styled.div`
	display: none;

	@media (max-width: 850px) {
		display: block;
		width: 100%;
		display: flex;
		justify-content: center;
	}
`;

export const NotificationTabs = styled.div`
	@media (max-width: 850px) {
		display: flex;
		justify-content: space-between;
		width: 100%;
		padding-top: 2rem;

		button {
			margin: 0;
		}
	}
`;
