import { useEffect, useState } from 'react';
import styled from 'styled-components';
import Modal from '../../../../../../componentsV2/ui/Modal';
import { Loader } from '../../../../../../componentsV2/ui/Loader';
import { Button } from '../../../../../../componentsV2/ui/Button';
import { toast } from 'react-toastify';
import { UploadedInvoice } from '../reducer';
import { S3File } from '../../../../../../@types/CorporateExpenses/Transaction';

interface Props {
	setFile: (file: UploadedInvoice | undefined) => void;
	deleteFile: (file: UploadedInvoice | undefined) => void;
	file_data?: UploadedInvoice;
}

export function UploadViewerModal({ file_data, setFile, deleteFile }: Props) {
	const [isOpen, setIsOpen] = useState(false);
	const [fileBlob, setFileBlob] = useState<Blob | undefined>();

	useEffect(() => {
		if (file_data) {
			(async () => {
				try {
					const isFileURL = typeof file_data.file === 'string';

					let blob: Blob;

					if (isFileURL) {
						const url = String(file_data.file);
						blob = await fetch(url).then((r) => r.blob());
					} else {
						const S3File = file_data.file as S3File;

						const buff = Buffer.from(S3File.Body.data);
						blob = new Blob([buff], { type: S3File.ContentType });
					}

					setFileBlob(blob);
				} catch (err) {
					toast.error(
						'Ocorreu um problema ao carregar o anexo. Tente novamente em instantes.'
					);
				}
			})();

			setIsOpen(true);
		} else {
			setIsOpen(false);
		}
	}, [file_data]);

	function closeModal() {
		setFile(undefined);
		setIsOpen(false);
	}

	function downloadFile() {
		const url = URL.createObjectURL(fileBlob!);
		const link = document.createElement('a');
		link.href = url;
		link.download = file_data!.parsedName;

		link.dispatchEvent(new MouseEvent('click'));

		URL.revokeObjectURL(url);
	}

	function formatBytes(bytes: number, decimals = 2) {
		if (!+bytes) return '0 B';

		const k = 1000;
		const dm = decimals < 0 ? 0 : decimals;
		const sizes = ['B', 'KB', 'MB'];

		const i = Math.floor(Math.log(bytes) / Math.log(k));

		return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
	}

	return (
		<Modal isOpen={isOpen} onRequestClose={closeModal} position='center'>
			<Container>
				{fileBlob ? (
					<>
						<iframe
							title={'invoice_viewer'}
							src={URL.createObjectURL(fileBlob)}
							width={'100%'}
							height={'80%'}
						/>
						<FileInfos>
							<FileInfo>
								<InfoField>Nome do arquivo:</InfoField>
								<InfoData>{file_data?.parsedName}</InfoData>
							</FileInfo>
							<FileInfo style={{ alignItems: 'flex-end' }}>
								<InfoField>Peso do arquivo:</InfoField>
								<InfoData>{formatBytes(fileBlob?.size)}</InfoData>
							</FileInfo>
						</FileInfos>
						<ButtonsContainer>
							<Button
								intent='terciary'
								$outline
								roundness='lg'
								onClick={() => deleteFile(file_data)}
							>
								Excluir Anexo
							</Button>

							<Button
								intent='primary'
								roundness='lg'
								onClick={() => downloadFile()}
							>
								Baixar Anexo
							</Button>
						</ButtonsContainer>
					</>
				) : (
					<Loader />
				)}
			</Container>
		</Modal>
	);
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 50vw;
	height: 80vh;
	padding-bottom: 3rem;
	padding-top: 7rem;

	@media (max-width: 650px) {
		width: 100vw;
	}
`;

const FileInfos = styled.div`
	display: flex;
	justify-content: space-around;
	width: 100%;
	padding: 3rem;
`;

const FileInfo = styled.div`
	width: 50%;
	display: flex;
	flex-direction: column;
`;

const InfoField = styled.p`
	font-size: 1.7rem;
`;

const InfoData = styled.p`
	font-weight: 600;
	font-size: 1.7rem;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const ButtonsContainer = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
	column-gap: 2rem;
	margin-top: auto;
	margin-bottom: 1rem;
`;
