import { Benefit, Release } from '../@types';
import {
	Beneficiary,
	IndividualFromGroupRepproved,
} from '../contexts/PaymentContextV2';
import { sortBenefitsByAlfabeticalOrder } from './sortArrayByAlfabeticalOrder';

/* 
	Parse the payment data to Beneficiaries format, extract the benefits, the collaborators and groups 
	and properly combine them
*/
export function parsePayment(data: Release) {
	let beneficiariesParsed: Beneficiary[] = [];
	let benefitsParsed: Benefit[] = [];

	// extract from Payment data, benefits and Collaborators (with their respective benefits)
	data.release.preReleases.forEach((r, index: number) => {
		let currentBenefitId = r.release_payment.benefit.id;

		// benefits insertion
		const foundBenefit = benefitsParsed.find((b) => b.id === currentBenefitId);
		if (!foundBenefit) {
			benefitsParsed.push({
				id: currentBenefitId,
				title: r.release_payment.benefit.title,
				value: 0,
				chargeback_value: r.release_payment.chargeback_value,
				chargeback_status: r.release_payment.chargeback_status,
			});
		} else {
			currentBenefitId = foundBenefit.id;
		}

		// collaborators insertion
		let collaboratorIndex = beneficiariesParsed.findIndex(
			(c) => c.id === r.collaborator.id
		);

		let collabBenefits: Benefit[] = [];
		collabBenefits.push({
			id: currentBenefitId,
			title: r.release_payment.benefit.title,
			value: Number(r.release_payment.benefit.value) / 100,
			reason_disapproval: r.release_payment.reason_disapproval,
			chargeback_value: r.release_payment.chargeback_value,
			chargeback_status: r.release_payment.chargeback_status,
		}); // converting to reais

		if (collaboratorIndex === -1) {
			// Collaborator not added yet
			collabBenefits = sortBenefitsByAlfabeticalOrder(collabBenefits);

			beneficiariesParsed.push({
				...r.collaborator,
				benefits: collabBenefits,
			} as Beneficiary);
		} else {
			// found collaborator
			collabBenefits = [
				...beneficiariesParsed[collaboratorIndex].benefits,
				...collabBenefits,
			]; // include benefits to his benefits list
			collabBenefits = sortBenefitsByAlfabeticalOrder(collabBenefits);

			beneficiariesParsed[collaboratorIndex].benefits = collabBenefits;
		}
	});

	// extract from Payment data, benefits and Groups (with their respective benefits)
	data.release.groupReleases.forEach((r, index: number) => {
		let currentBenefitId = r.group_payment.benefit.id;

		// benefits insertion
		const foundBenefit = benefitsParsed.find((b) => b.id === currentBenefitId);
		if (!foundBenefit) {
			benefitsParsed.push({
				id: currentBenefitId,
				title: r.group_payment.benefit.title,
				value: 0,
			});
		} else {
			currentBenefitId = foundBenefit.id;
		}

		// groups insertion
		let groupIndex = beneficiariesParsed.findIndex((c) => c.id === r.group.id);

		let groupBenefits: Benefit[] = [];

		// if not finished error, then it could have reason disapproval from ADMIN, set reason to each benefit
		let reason_disapproval: string | undefined = undefined;
		if (data.release.status !== 'finished error') {
			reason_disapproval = r.group_payment.reason_disapproval;
		}

		if (groupIndex === -1) {
			// Group not added yet
			groupBenefits.push({
				id: currentBenefitId,
				title: r.group_payment.benefit.title,
				value: Number(r.group_payment.benefit.value) / 100,
				reason_disapproval,
			});

			groupBenefits = sortBenefitsByAlfabeticalOrder(groupBenefits);

			// some collaborators from the group could be repproved from SWAP, get them
			let individualsRepproved: IndividualFromGroupRepproved[] | undefined =
				undefined;
			if (data.release.status === 'finished error') {
				const individualsGroupReleasesRepproved = data.release.groupReleases
					.filter(
						(g) =>
							g.group.id === r.group.id && !!g.group_payment.reason_disapproval
					)
					.map((r) => {
						return {
							id: r.group_payment.id,
							benefitId: r.group_payment.benefit.id,
							collaborator: r.group_payment.collaborator,
							reason_disapproval: r.group_payment.reason_disapproval,
						};
					});

				if (individualsGroupReleasesRepproved.length > 0)
					individualsRepproved = individualsGroupReleasesRepproved;
			}

			beneficiariesParsed.push({
				id: r.group.id,
				collaboratorsAmount: r.group.total_users,
				name: r.group.title,
				isGroup: true,
				benefits: groupBenefits,
				individualsRepproved, // grabbed all collaborators from this group that have been repproved from SWAP
			} as Beneficiary);
		} else {
			// found group
			if (
				!beneficiariesParsed[groupIndex].benefits.find(
					(b) => b.id === currentBenefitId
				)
			) {
				groupBenefits.push({
					id: currentBenefitId,
					title: r.group_payment.benefit.title,
					value: Number(r.group_payment.benefit.value) / 100,
					reason_disapproval,
				}); // converting to reais
			}

			groupBenefits = [
				...beneficiariesParsed[groupIndex].benefits,
				...groupBenefits,
			]; // include benefits to his benefits list
			groupBenefits = sortBenefitsByAlfabeticalOrder(groupBenefits);

			beneficiariesParsed[groupIndex].benefits = groupBenefits;
		}
	});

	// Setting formated benefits and benefits to their states variables
	benefitsParsed = sortBenefitsByAlfabeticalOrder(benefitsParsed);

	return { beneficiariesParsed, benefitsParsed };
}
