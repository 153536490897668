import { useState } from 'react';
import { toast } from 'react-toastify';
import { SegmentationOptions } from '..';
import { Collaborator } from '../../../../../@types';
import Modal from '../../../../../components/Modal';
import * as S from './styles';
import { CollabsSelector } from '../../../../../components/CollabsSelector';
import { CollaboratorsListModal } from './CollaboratorsListModal';
import { useDialogModal } from '../../../../../hooks/useDialogModal';

export interface SegmentationModalProps {
	onChangeSegmentation: (
		option: SegmentationOptions,
		selectedCollabs: string[]
	) => void;
}
export function SegmentationModal({
	onChangeSegmentation,
}: SegmentationModalProps) {
	const { openOptionsDialog } = useDialogModal();
	const [isOpen, setIsOpen] = useState(false);
	const [lastOption, setLastOption] = useState<SegmentationOptions>('group');
	const [selectedOption, setSelectedOption] =
		useState<SegmentationOptions>(lastOption);
	const [selectedCollaborators, setSelectedCollaborators] = useState<
		Collaborator[]
	>([]);

	function handleSelectOption(option: SegmentationOptions) {
		setSelectedOption(option);
	}

	function handleOpen() {
		setSelectedOption(lastOption);
		setIsOpen(true);
	}

	function handleClose() {
		if (lastOption === selectedOption) {
			setIsOpen(false);
			return;
		}

		setIsOpen(false);

		openOptionsDialog(
			'Alterações não salvas. Tem certeza que deseja sair?',
			'Confirmar',
			() => {},
			'Cancelar',
			() => setIsOpen(true)
		);
	}

	function handleSubmit() {
		if (selectedOption === 'custom' && !selectedCollaborators.length) {
			toast.error('Selecione os colaboradores para o envio customizado.');
			return;
		}

		onChangeSegmentation(
			selectedOption,
			selectedCollaborators.map((c) => c.id!)
		);
		setLastOption(selectedOption);
		setIsOpen(false);
	}

	function handleAddCollaborators(collaborators: Collaborator[]) {
		setSelectedCollaborators([...selectedCollaborators, ...collaborators]);
	}
	// remove all selected collaborators
	function handleRemoveCollaborators(collaboratorsToRemove: Collaborator[]) {
		const idsToRemove = collaboratorsToRemove.map((c) => c.id!);

		setSelectedCollaborators(
			selectedCollaborators.filter((c) => !idsToRemove.includes(c.id!))
		);
	}

	function handleAddAndRemoveCollaborators(
		collabsToAdd: Collaborator[],
		collabsToRemove: Collaborator[]
	) {
		const idsToRemove = collabsToRemove.map((c) => c.id!);
		let remainingSelectedCollabs = selectedCollaborators.filter(
			(c) => !idsToRemove.includes(c.id!)
		);

		setSelectedCollaborators([...remainingSelectedCollabs, ...collabsToAdd]);
	}

	return (
		<>
			<S.MainButton type='button' onClick={handleOpen}>
				Definir segmentação
			</S.MainButton>

			<Modal isOpen={isOpen} enableClose onRequestClose={handleClose}>
				<S.ModalContainer>
					<S.ModalHeader>Selecione a segmentação desejada</S.ModalHeader>

					<S.SegmentationOptionsList>
						<S.SegmentationOption
							onClick={() => handleSelectOption('group')}
							selected={selectedOption === 'group'}
						>
							<S.SegmentationHeader>Grupo corporativo</S.SegmentationHeader>
							<span>
								Todos os colaboradores de todas as empresas do grupo receberão a
								mensagem
							</span>
						</S.SegmentationOption>

						<S.SegmentationOption
							onClick={() => handleSelectOption('company')}
							selected={selectedOption === 'company'}
						>
							<S.SegmentationHeader>Empresa</S.SegmentationHeader>
							<span>
								Todos os colaboradores da empresa que está sendo gerenciada no
								momento receberão a mensagem
							</span>
						</S.SegmentationOption>

						<S.SegmentationOption
							onClick={() => handleSelectOption('custom')}
							selected={selectedOption === 'custom'}
						>
							<S.SegmentationHeader>
								{selectedOption === 'custom' ? (
									<>
										<CollabsSelector
											buttonText={`Customizar colaboradores ${
												selectedCollaborators.length > 0
													? `- (${selectedCollaborators.length})`
													: ''
											}`}
											buttonStyle={{
												background: 'none',
												border: 'none',
												color: 'var(--primary-blue)',
												padding: 0,
												fontSize: '1.8rem',
											}}
											alreadyAddedCollabs={selectedCollaborators}
											onAddNewCollaborators={handleAddCollaborators}
											onAddCollaboratorsToRemove={handleRemoveCollaborators}
											onAddAndRemoveCollabs={handleAddAndRemoveCollaborators}
											allowRemoveCollaborators
										/>
										<CollaboratorsListModal
											selectedCollaborators={selectedCollaborators}
											setSelectedCollaborators={setSelectedCollaborators}
										/>
									</>
								) : (
									'Customizar colaboradores'
								)}
							</S.SegmentationHeader>
							<span>Definir manualmente um grupo de colaboradores</span>
						</S.SegmentationOption>
					</S.SegmentationOptionsList>

					<S.Submit onClick={handleSubmit}>Confirmar</S.Submit>
				</S.ModalContainer>
			</Modal>
		</>
	);
}
