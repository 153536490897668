import styled from 'styled-components';
import corpwayExtractHeader from '../../../../../assets/corpway-extract-header.svg';

export const CursorPointerStyle = `
	&:hover {
			cursor: pointer;
			user-select: none; /* Non-prefixed version, currently*/
			-webkit-touch-callout: none; /* iOS Safari */
			-webkit-user-select: none; /* Safari */
			-moz-user-select: none; /* Old versions of Firefox */
			-ms-user-select: none; /* Internet Explorer/Edge */
	}

	img {
			-webkit-user-drag: none;
	}
`;

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	border-radius: 1rem 0 0 1rem;
	width: 38rem;
	height: 100vh;
	font-size: 1.6rem;
	font-weight: 600;
	min-height: 50rem;
`;

export const HeaderContent = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	row-gap: 0.5rem;
	min-height: 35%;
	width: 100%;
	padding: 24px;
	background: url(${corpwayExtractHeader}) no-repeat center/cover;

	@media (max-width: 550px) {
		background: url(${corpwayExtractHeader}) no-repeat 80% 50% / cover;
	}

	@media (max-width: 1050px) {
		background: url(${corpwayExtractHeader}) no-repeat 85% 50% / cover;
	}
`;

export const TransactionCategory = styled.div``;
export const TransactionAmount = styled.div`
	background-color: #196594;
	width: max-content;
	padding: 0.3rem 1.5rem;
	border-radius: 0 20px 20px 20px;
`;

export const HeaderDetailsContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	margin-top: 1.6rem;
`;

export const HeaderDetail = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
`;
export const MainContent = styled.div`
	display: flex;
	flex-direction: column;
	padding: 32px 24px 32px 24px;
`;

export const FieldsContainer = styled.div`
	width: 100%;
	height: fit-content;
	display: flex;
	flex-direction: column;
	margin-top: 25px;
	row-gap: 10px;
`;

export const Field = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`;

export const SubTitleRow = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
`;
export const InfoContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	row-gap: 1rem;
	padding: 2.4rem 0;
`;

export const InvoiceUploadSection = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	margin-top: 2rem;
	margin-bottom: 2rem;
	row-gap: 2rem;
`;

interface DragContainerProps {
	drag: boolean;
	disabled: boolean;
}

export const DragContainer = styled.div<DragContainerProps>`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 260px;
	height: 160px;
	row-gap: 16px;
	border-radius: 8px;
	border-style: dashed;
	border-width: 2px;
	border-color: #e5eaf2;
	font-size: 2rem;

	background-color: ${({ drag }) => (drag ? '#E5EAF2' : '#F0F3F8')};

	transition: all 0.3s;

	&:hover {
		background-color: #e5eaf2;
	}

	${CursorPointerStyle};

	img {
		-webkit-user-drag: none;
	}
  
	filter: grayscale(${({ disabled }) => (disabled ? '100%' : '0')});
`;

export const DragTitle = styled.p`
	color: var(--dark-gray);
	font-size: 1.5rem;
`;

export const DragSubtitle = styled.span`
	color: var(--mid-gray);
	font-size: 1.3rem;
	width: 55%;
	text-align: center;
`;

export const IconContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 3.5rem;
	height: 3.5rem;
	background-color: var(--primary-blue);
	border-radius: 50px;

	& > img {
		width: 65%;
		height: 65%;
	}
`;
export const IconHeader = styled.img``;

export const UploadedFileContainer = styled.div<{ disabled?: boolean }>`
	display: flex;
	align-items: center;
	align-self: center;
	column-gap: 1rem;
	width: 80%;
	padding: 1rem;
	border-radius: 5px;

	transition: all 0.3s;

	${CursorPointerStyle};

	filter: grayscale(${({ disabled }) => (disabled ? '100%' : '0')});

	&:hover {
		background-color: ${({ disabled }) => (disabled ? 'transparent' : '#f0f0f0')}; 
		border: ${({ disabled }) => (disabled ? 'none' : '1px solid #ccc')}; 
	}
`;

export const UploadIcon = styled.img`
	color: var(--primary-blue);
	width: 2.5rem;
`;

export const DeleteIcon = styled.img`
	width: 2rem;

	transition: all 0.1s;
	&:hover {
		opacity: 0.5;
	}
`;
export const UploadFileText = styled.p`
	width: 100%;
	color: var(--dark-gray);
	font-size: 1.4rem;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

export const ObservationBttn = styled.div<{ observation?: boolean }>`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 2.2rem;
	height: 2.2rem;
	border-radius: 3px;
	background-color: ${({ observation }) =>
		observation ? 'var(--green)' : 'var(--primary-blue)'};

	transition: all 0.2s;
	&:hover {
		background-color: ${({ observation }) =>
			observation ? 'var(--dark-green)' : 'var(--primary-dark-blue)'};
	}

	${CursorPointerStyle};
`;
