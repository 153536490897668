import { useQuery } from 'react-query';
import { getPublicTerms } from '../../services/queries/Terms';
import * as S from './styles';
import LogoControl from '../../assets/BountyControl.svg';
import LogoBounty from '../../assets/BountyBlue.svg';
import Loader from '../../components/Loader';
import { showErrorMessage } from '../../utils/ErrorHandler';
import { CorporateExpensesTheme } from '../../styles/CorporateExpensesTheme';
import { useParams } from 'react-router-dom';

interface PublicTermsParams {
	termsType?: string;
}

export function PublicTerms() {
	const { termsType } = useParams<PublicTermsParams>();
	let type: string;
	if (termsType == 'multiflex') {
		type = 'multiflex';
	} else {
		type = 'corpway';
	}

	const termsQuery = useQuery(
		['public-terms'],
		() => {
			return getPublicTerms(type);
		},
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível buscar os termos de uso '
				);
			},
			refetchOnWindowFocus: false,
		}
	);

	const logo = termsType === 'multiflex' ? LogoBounty : LogoControl;

	if (termsQuery.isLoading) {
		return (
			<S.Container>
				<S.LogoImage src={logo} alt='Logo do Bounty' />
				<S.TextTitle>Termos de uso</S.TextTitle>
				<div style={{ marginTop: '3rem' }} />
				<Loader />
			</S.Container>
		);
	}
	return (
		<S.Container>
			<CorporateExpensesTheme />
			<S.LogoImage
				className='responsiveImage'
				src={logo}
				alt='Logo do Bounty'
			/>
			<S.TextsContainer>
				<S.InformationContainer>
					<S.TextTitle>Termos de uso</S.TextTitle>
					<S.LogoImage
						className='desktopImage'
						src={logo}
						alt='Logo do Bounty'
					/>
				</S.InformationContainer>
				<S.TermsContainer
					dangerouslySetInnerHTML={{
						__html: termsQuery.data ? termsQuery.data : '',
					}}
				/>
			</S.TextsContainer>
		</S.Container>
	);
}
