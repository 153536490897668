import React, { useEffect, useRef, useState } from 'react';
import { CheckboxField } from './CheckboxField';
import { Button } from '../Button';
import styled from 'styled-components';

export default function TermsAndPrivacy (
	{ content, isTermsOfUse, isLoading, onNext, logo }: { isTermsOfUse: boolean, isLoading: boolean, content: string, onNext: () => void, logo: string }
) {
	const [termsAccept, setTermsAccept] = useState(false);

	const termsContainerRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (termsContainerRef.current) {
			termsContainerRef.current.scrollTop = 0;
		}
	}, [content]);

	return (
		<TextsContainer>
			<InformationContainer>
				<TextTitle>{
					isTermsOfUse ?
						"Termos de uso"
						:
						"Aviso de privacidade"
				}</TextTitle>
				<LogoImage
					className="desktopImage"
					src={logo}
					alt="Logo do Bounty"
				/>
			</InformationContainer>
			<TermsContainer
				ref={termsContainerRef}
				dangerouslySetInnerHTML={{
					__html: content,
				}}
			/>
			<AcceptContainer>
				<TextAccept
					{...(!termsAccept && {
						'data-rh':
							isTermsOfUse ?
								'Por favor, leia nossos termos de uso antes de continuar'
								: 'Por favor, leia nossos avisos de privacidade antes de continuar',
					})}
				>
					<CheckboxField
						labelWidth="100%"
						label={
							<LabelText>
								<span> Eu li e estou ciente </span>
								{
									isTermsOfUse ?
										"do teor destes Termos de Uso"
										:
										"do teor deste Aviso de privacidade"
								}
							</LabelText>
						}
						checked={termsAccept}
						name="termsPolicy"
						onChange={ () => setTermsAccept(!termsAccept)}
						disabled={isLoading}
					/>
					<p>
						Ao clicar na caixa{' '}
						<span>{
							isTermsOfUse ?
								"“Eu li e estou ciente do teor destes Termos de Uso”"
								:
								"“Eu li e estou ciente do teor deste Aviso de privacidade”"
						}</span>,
						você concorda e aceita expressa e integralmente as condições aqui
						descritas.
					</p>
				</TextAccept>

				<Button
					roundness="lg"
					width="adjusted"
					type="button"
					disabled={!termsAccept}
					intent="primary"
					style={{ width: '20rem', height: '7rem' }}
					onClick={() => {
						if (termsAccept) {
							onNext();
							setTermsAccept(false);
						}
					}}
				>
					Avançar
				</Button>
			</AcceptContainer>
		</TextsContainer>
	);
};


const LabelText = styled.div`
	width: 100%;
	font-size: 1.8rem;

	span {
		color: var(--primary-blue);
		font-weight: 600;
	}

	@media (max-width: 650px) {
		font-size: 1.5rem;
		text-align: center;
	}
`;

const AcceptContainer = styled.div`
	display: flex;
	justify-content: space-between;

	@media (max-width: 650px) {
		flex-direction: column;
		align-items: center;
		gap: 1rem;
	}
`;

const TextAccept = styled.div`
	& p {
		font-size: 1.3rem;
		max-width: 52rem;
		padding-left: 2.7rem;
		padding-top: 0.5rem;

		& > span {
			font-weight: 600;
		}
	}

	& label {
		& span {
			top: 4px;
		}
	}

	@media (max-width: 650px) {
		text-align: center;

		& p {
			padding: 0;
		}
	}
`;


const InformationContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;

	@media (max-width: 650px) {
		text-align: center;
	}
`;

const TextsContainer = styled.div`
	display: flex;
	flex-direction: column;
	background-color: var(--white);
	padding: 2rem 4rem;
	border-radius: 1.6rem;
	width: 80%;
`;

const TextTitle = styled.h2`
	font-weight: 600;
	font-size: 2.8rem;
	color: var(--primary-blue);
	width: 100%;

	@media (max-width: 650px) {
		font-size: 2rem;
	}
`;

const LogoImage = styled.img`
	width: 20rem;
`;

export const TermsContainer = styled.div`
	text-align: justify;
	padding: 2rem;
	margin-top: 1.5rem;
	margin-bottom: 3.5rem;
	max-height: 50vh;
	width: 100%;
	background-color: var(--white);
	border-radius: 10px;
	overflow-y: auto;
	font-size: 1.4rem;
	outline: 2px solid var(--primary-blue);
	outline-offset: 0.5rem;

	& > table,
	td {
		text-align: start;
		padding: 1rem;
		margin-top: 3rem;
		margin-bottom: 3rem;
		border: solid;
		border-collapse: collapse;
	}

	tr {
		p {
			font-weight: normal;
		}
		h3 {
			font-weight: normal;
		}

		th {
			border: 2px solid;
			padding: 1rem;
			text-align: start;
		}
	}

	a {
		color: var(--dark-gray);
		text-decoration: underline;
		transition: all 0.2s;
	}

	a:hover {
		color: var(--light-gray);
	}

	& > ol,
	ul {
		margin-left: 2rem;
		li {
			margin-left: 2rem;
		}
	}

	& > h1,
	h2 {
		font-size: 2rem;
		margin-top: 2rem;
	}

	&::-webkit-scrollbar {
		width: 1.2rem;
	}

	&::-webkit-scrollbar-track {
		border-radius: 1rem;
		background-color: #e3e8eb;
	}

	&::-webkit-scrollbar-thumb {
		background-color: var(--light-gray);
		border-radius: 1rem;
	}
`;