import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { SidebarLink } from '../../../../componentsV2/SidebarLink';
import {
	FaBuilding,
	FaChartArea,
	FaCreditCard,
	FaDollarSign,
	FaEye,
	FaEyeSlash,
	FaHome,
	FaInfoCircle,
	FaCircle,
} from 'react-icons/fa';
import { useState } from 'react';
import { useAuth } from '../../../../hooks/useAuth';
import { Popover } from 'react-tiny-popover';
import { MdMenu, MdOutlineKeyboardArrowDown } from 'react-icons/md';
import * as S from './styles';
import { parseCurrencyToBRLStandard } from '../../../../utils/parseCurrency';
import { Typography } from '../../../../componentsV2/ui/Typography';
import { useGlobalState } from '../../../../hooks/useGlobalState';
import { Button } from '../../../../componentsV2/ui/Button';
import Modal from '../../../../componentsV2/ui/Modal';

export function Sidebar() {
	const history = useHistory();
	const { path } = useRouteMatch();
	const { pathname } = useLocation();
	const {
		user,
		currentCompany,
		companies,
		changeCurrentCompany,
		companyBalance,
	} = useAuth();
	const { setShowBalance, showBalance, toggleBalanceVisibility } =
		useGlobalState();
	const [isCompanyPopoverOpen, setCompanyPopoverOpen] = useState(false);
	const [isSideBarOpen, setSideBarOpen] = useState(false);
	const hasAccessToManagePage =
		user.roles.includes('manage-users') &&
		user.roles.includes('manage-cost-centers') &&
		user.roles.includes('manage-balances');

	const companiesWithCorporateExpensesAvailable = companies.filter(
		(c) =>
			c.id !== currentCompany?.id &&
			c.company_products.product_corporate_expenses_enabled
	);

	// const isFlexibleBenefitsAvailable =
	// 	user.flexible_benefits_access &&
	// 	currentCompany?.company_products.product_flexible_benefits_enabled;

	function onToggleBalanceVisibility() {
		toggleBalanceVisibility();
	}

	function handleCompanyBalanceVisibillity() {
		return showBalance ? (
			parseCurrencyToBRLStandard(companyBalance.corpway_balance)
		) : (
			<div>
				<p> R$ </p>
				<FaCircle size={20} />
				<FaCircle size={20} />
				<FaCircle size={20} />
				<FaCircle size={20} />
			</div>
		);
	}

	function handleToggleOpenCompanyPopover() {
		setCompanyPopoverOpen((oldState) => {
			return !oldState;
		});
	}

	function handleChangeCompany(companyId: string) {
		changeCurrentCompany(companyId);
		setShowBalance(false);
		history.push('/corporate-expenses');
	}

	function SidebarContent() {
		return (
			<S.SidebarContainer>
				{companiesWithCorporateExpensesAvailable.length > 1 ? (
					<Popover
						isOpen={isCompanyPopoverOpen}
						positions={['bottom']} // preferred positions by priority
						onClickOutside={handleToggleOpenCompanyPopover}
						content={
							<S.DropdownContainer>
								{/* <S.CompanyLink
                        to={
                            user.access_level === 'super_admin'
                                ? '/super-admin/company'
                                : '/home/company'
                        }
                    >
                        <FaEdit />
                        Ver detalhes
                    </S.CompanyLink> */}

								{companiesWithCorporateExpensesAvailable.length > 0 && (
									<>
										<S.CompaniesListTitle>
											Selecione uma empresa
										</S.CompaniesListTitle>

										<S.CompaniesList>
											{companiesWithCorporateExpensesAvailable.map(
												(company) => (
													<S.CompanyContainer key={company.id}>
														<button
															onClick={() => {
																handleChangeCompany(company.id);
															}}
														>
															<FaBuilding />
															{company.name}
														</button>
													</S.CompanyContainer>
												)
											)}
										</S.CompaniesList>
									</>
								)}
							</S.DropdownContainer>
						}
					>
						<S.OpenCompanyOptionsBtn onClick={handleToggleOpenCompanyPopover}>
							<S.CompanyLogo>
								<img
									src={currentCompany?.avatar_url}
									alt={`Logo da ${currentCompany?.name}`}
								/>
							</S.CompanyLogo>

							<span>{currentCompany?.corporateName}</span>

							<MdOutlineKeyboardArrowDown />
						</S.OpenCompanyOptionsBtn>
					</Popover>
				) : (
					<S.CompanyDisplay>
						<S.CompanyLogo>
							<img
								src={currentCompany?.avatar_url}
								alt={`Logo da ${currentCompany?.name}`}
							/>
						</S.CompanyLogo>

						<span>{currentCompany?.corporateName}</span>
					</S.CompanyDisplay>
				)}

				{user.roles.includes('visualize-balances') && (
					<S.CompanyBalanceContainer>
						<div>
							<div>
								<Typography weight='600' size='1.6rem'>
									Saldo&nbsp;
									<span style={{ color: 'var(--primary-blue)' }}>
										disponível:
									</span>
								</Typography>
								<S.Balance showBalance={showBalance}>
									{handleCompanyBalanceVisibillity()}
								</S.Balance>
							</div>

							<S.SmallBalanceContainer>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='16'
									height='14'
									viewBox='0 0 16 14'
									fill='none'
								>
									<path
										d='M14.85 3.21V1.5C14.85 0.675 14.1075 0 13.2 0H1.65C0.73425 0 0 0.675 0 1.5V12C0 12.825 0.73425 13.5 1.65 13.5H13.2C14.1075 13.5 14.85 12.825 14.85 12V10.29C15.3368 10.0275 15.675 9.555 15.675 9V4.5C15.675 3.945 15.3368 3.4725 14.85 3.21ZM14.025 4.5V9H8.25V4.5H14.025ZM1.65 12V1.5H13.2V3H8.25C7.3425 3 6.6 3.675 6.6 4.5V9C6.6 9.825 7.3425 10.5 8.25 10.5H13.2V12H1.65Z'
										fill='#929292'
									/>
								</svg>
								<Typography color='var(--mid-gray)' weight='600'>
									Saldo em carteira -&nbsp;
									<S.SmallBalance showBalance={showBalance}>
										{showBalance
											? parseCurrencyToBRLStandard(
													companyBalance.corpway_account_balance
											  )
											: 'R$ ----'}
									</S.SmallBalance>
								</Typography>
							</S.SmallBalanceContainer>

							<S.SmallBalanceContainer>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='16'
									height='15'
									viewBox='0 0 16 15'
									fill='none'
								>
									<path
										d='M14.4 0.5H1.6C0.712 0.5 0.00799999 1.27875 0.00799999 2.25L0 12.75C0 13.7212 0.712 14.5 1.6 14.5H14.4C15.288 14.5 16 13.7212 16 12.75V2.25C16 1.27875 15.288 0.5 14.4 0.5ZM14.4 12.75H1.6V7.5H14.4V12.75ZM14.4 4H1.6V2.25H14.4V4Z'
										fill='#929292'
									/>
								</svg>
								<Typography color='var(--mid-gray)' weight='600'>
									Saldo nos cartões -&nbsp;
									<S.SmallBalance showBalance={showBalance}>
										{showBalance
											? parseCurrencyToBRLStandard(
													companyBalance.corpway_cards_balance
											  )
											: 'R$ ----'}
									</S.SmallBalance>
								</Typography>
							</S.SmallBalanceContainer>
						</div>
						<S.ToggleBalanceVisibilityBtn onClick={onToggleBalanceVisibility}>
							{showBalance ? <FaEye /> : <FaEyeSlash />}
						</S.ToggleBalanceVisibilityBtn>
					</S.CompanyBalanceContainer>
				)}

				<div>
					<SidebarLink
						icon={FaHome}
						title='Início'
						path={`${path}`}
						current_page={pathname === path}
					/>
					{hasAccessToManagePage && (
						<SidebarLink
							icon={FaChartArea}
							title='Gestão'
							path={`${path}/management`}
							current_page={pathname.includes('management')}
						/>
					)}
					{user.roles.includes('manage-balances') && (
						<SidebarLink
							icon={FaDollarSign}
							title='Carteira'
							path={`${path}/wallet`}
							current_page={pathname.includes('wallet')}
						/>
					)}
					{(user.roles.includes('use-all-cards') ||
						user.roles.includes('use-own-cards') ||
						user.roles.includes('visualize-all-extracts')) && (
						<SidebarLink
							icon={FaCreditCard}
							title='Cartões'
							path={`${path}/cards`}
							current_page={pathname.includes('cards')}
						/>
					)}
					<SidebarLink
						icon={FaInfoCircle}
						title='Suporte'
						path={`${path}/support`}
						current_page={pathname.includes('support')}
					/>
				</div>
			</S.SidebarContainer>
		);
	}

	return (
		<>
			<S.DesktopContainer>
				<SidebarContent />
			</S.DesktopContainer>
			<S.MobileContainer>
				<Button
					$outline
					width='contain'
					as='link'
					intent='ghost'
					onClick={() => setSideBarOpen(true)}
				>
					<MdMenu color='var(--primary-blue)' />
				</Button>

				<Modal
					onRequestClose={() => setSideBarOpen(false)}
					isOpen={isSideBarOpen}
					showClose={false}
					position='left'
				>
					<div>
						<SidebarContent />
					</div>
				</Modal>
			</S.MobileContainer>
		</>
	);
}
