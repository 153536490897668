import { useState } from 'react';
import styled from 'styled-components';
import { TextAreaField } from '../../../../../../componentsV2/ui/Form/TextAreaField';
import { Button } from '../../../../../../componentsV2/ui/Button';
import { useMutation } from 'react-query';
import { updateTransactionObservation } from '../../../../../../services/queries/Corpway/Extract';
import { showErrorMessage } from '../../../../../../utils/ErrorHandler';
import { Transaction } from '../../../../../../@types/CorporateExpenses/Transaction';
import { Loader } from '../../../../../../componentsV2/ui/Loader';
import Modal from '../../../../../../componentsV2/ui/Modal';
import { FaPen } from 'react-icons/fa';

interface Props {
	returnToDetails: React.Dispatch<React.SetStateAction<0 | 1>>;
	transaction: Transaction;
}

export function ObservationForm({ transaction, returnToDetails }: Props) {
	const [confirmationModal, setConfirmationModal] = useState(false);
	const [observation, setObservation] = useState(
		transaction.transactionInvoices?.observation ?? ''
	);

	const updateObservationMutation = useMutation(
		() =>
			updateTransactionObservation(
				transaction.internal_transaction_id,
				observation
			),
		{
			onSuccess: (data) => {
				transaction.transactionInvoices = data;
				setConfirmationModal(true);
			},
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível atualizar as informações da empresa. '
				);
			},
		}
	);

	async function submit() {
		await updateObservationMutation.mutateAsync();
	}

	return (
		<Container>
			<TextAreaField
				label='Observação'
				placeholder='Escreva sua observação'
				onChange={(e) => setObservation(e.target.value)}
				value={observation}
				style={{ height: '160px' }}
			/>

			<ButtonsContainer>
				<SaveBttn
					intent='primary'
					roundness='lg'
					onClick={submit}
					disabled={
						observation === '' ||
						observation === transaction.transactionInvoices?.observation
					}
				>
					{updateObservationMutation.isLoading ? (
						<Loader size={17} />
					) : (
						'Salvar'
					)}
				</SaveBttn>
				<Button intent='link' type='button' onClick={() => returnToDetails(0)}>
					Voltar
				</Button>
			</ButtonsContainer>

			<Modal
				isOpen={confirmationModal}
				onRequestClose={() => setConfirmationModal(false)}
				position='center'
			>
				<ConfirmationModalContainer>
					<TitleContainer>
						<IconContainer>
							<FaPen color='#fff' size={20} />
						</IconContainer>

						<ModalTitle>
							<p style={{ fontWeight: 600 }}>Observação salva</p>
							<p>&nbsp;com sucesso</p>
						</ModalTitle>
					</TitleContainer>

					<CloseBttnContainer>
						<SaveBttn
							onClick={() => setConfirmationModal(false)}
							intent='primary'
							roundness='lg'
						>
							Fechar
						</SaveBttn>
					</CloseBttnContainer>
				</ConfirmationModalContainer>
			</Modal>
		</Container>
	);
}

const Container = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const ButtonsContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	row-gap: 1rem;
	width: 100%;
	margin-top: 3rem;
`;

const ConfirmationModalContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 40rem;
	height: 23rem;
	padding: 3rem;
`;

const TitleContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	row-gap: 0.8rem;
	font-size: 2rem;
`;

const ModalTitle = styled.div`
	display: flex;

	& > p {
		font-size: 1.7rem;
	}
`;

const IconContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: var(--primary-blue);
	width: 4rem;
	height: 4rem;
	border-radius: 50px;
`;

const CloseBttnContainer = styled.div`
	margin-top: auto;
`;

const SaveBttn = styled(Button)`
	width: 220px;
	height: 45px;
	padding: 12px 32px 12px 32px;
	border-radius: 500px;
	border-color: #c9c9c9;

	&:disabled {
		background-color: #c9c9c9;
	}
`;
