import { create } from 'zustand';
import { FilterParams } from '../components/Filter';
import { MessageTypeOptionsFilters } from '../components/Filter/utils/SelectFilters';

type Store = {
	filtersParams: FilterParams[];
	setFiltersParams: (f: FilterParams[]) => void;
	resetFilters: () => void;
};

const filtersParams: FilterParams[] = [
	{
		name: 'Título',
		filter: 'title',
		value: '',
		type: 'text',
		selected: false,
	},
	{
		name: 'Corpo da mensagem',
		filter: 'body',
		value: '',
		type: 'text',
		selected: false,
	},
	{
		name: 'Segmentação',
		filter: 'segmentation',
		value: '',
		type: 'select',
		selectOptions: MessageTypeOptionsFilters,
		selected: false,
	},
];

const useMessagesListStore = create<Store>()((set) => ({
	filtersParams,
	setFiltersParams: (f) => set({ filtersParams: f }),
	resetFilters: () => set({ filtersParams }),
}));

export { useMessagesListStore };
