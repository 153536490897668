import { ChangeEvent, useState, useRef } from 'react';
import { importFileSupportedExtenstions } from '../../../../../../utils/supportedImportExtensions';
import { FileParser } from '../../../../../../utils/FileParser';
import { toast } from 'react-toastify';
import { Collaborator, LineError } from '../../../../../../@types';
import { cpf as cpfValidator } from 'cpf-cnpj-validator';
import { LineErrorsCollaboratorsImported } from '../../../../../../components/LineErrorsPaymentTable';
import Modal from '../../../../../../components/Modal';
import { COLLABORATORS_BATCH_LIMIT } from '../../../utils/COLLABORATORS_BATCH_LIMIT';
import { useQuery } from 'react-query';
import { useAuth } from '../../../../../../hooks/useAuth';
import { getCollaboratorsFilteredByCPFs } from '../../../../../../services/queries/Collaborators';
import ModalLoader from '../../../../../../components/ModalLoader';
import * as S from './styles';

interface Props {
	alreadyAddedCollaborators: Collaborator[];
	onAddedCollaborators: (c: Collaborator[]) => void;
}
interface ImportedCollaborator extends Collaborator {
	line: number;
}

export function ImportCardBatchCollaborators({
	alreadyAddedCollaborators,
	onAddedCollaborators,
}: Props) {
	const { currentCompany } = useAuth();
	const [importedCollaborators, setImportedCollaborators] = useState<
		ImportedCollaborator[]
	>([]);
	const [errors, setErrors] = useState<LineError[]>([]);
	const inputRef = useRef<HTMLInputElement | null>(null);

	const importedCollaboratorsCPFs = importedCollaborators.map((c) => c.cpf);
	const getCollaboratorsFromCPFQuery = useQuery(
		['cpfsCollabsList', importedCollaboratorsCPFs, currentCompany?.id],
		() =>
			getCollaboratorsFilteredByCPFs(
				importedCollaboratorsCPFs,
				currentCompany!.id,
				true
			),
		{
			enabled: importedCollaborators.length > 0,
			onSuccess: (data) => {
				let collaboratorsNotFoundErrors: LineError[] = [];
				importedCollaborators.forEach((importedCollaborator) => {
					// if not found include to errors array
					if (
						!data.collaborators.find((c) => c.cpf === importedCollaborator.cpf)
					) {
						collaboratorsNotFoundErrors.push({
							collaborator: importedCollaborator,
							error:
								'Colaborador importado não foi encontrado nos colaboradores ativos da empresa',
							line: importedCollaborator.line,
						});
					}
				});

				onAddedCollaborators(data.collaborators);

				setErrors([...errors, ...collaboratorsNotFoundErrors]);
				setImportedCollaborators([]);
			},
			onError: () => {
				toast.error(
					'Ocorreu um problema ao buscar informações dos colaboradores importados.'
				);
			},
			refetchOnWindowFocus: false,
			refetchOnReconnect: false,
		}
	);

	function handleCollaboratorsFileUpload(e: ChangeEvent<HTMLInputElement>) {
		if (!e.target.files?.length) return;

		try {
			const fileParser = new FileParser(e.target.files[0]);

			fileParser.parseFile(parseCollaboratorsFile);
		} catch (err) {
			toast.error('Ocorreu um erro ao tentar ler o arquivo. Tente novamente');
		}
	}

	function parseCollaboratorsFile(convertedFile: any[][]) {
		let collaboratorsArray: ImportedCollaborator[] = [];

		// FIRST ELEMENT FROM ARRAY IS THE FIELDS ARRAY (CPF, NOME)
		let fields: string[] = convertedFile[0];

		const correctFormat = checkFileFields(fields);
		if (!correctFormat) {
			return;
		}

		for (let index = 0; index < convertedFile.length; index++) {
			if (index === 0)
				// Ignore fields
				continue;

			// collaboratorsArray is the collab row with its values ("000.000.000-11", "José da Silva")
			if (convertedFile[index].length) {
				try {
					collaboratorsArray.push(
						parseCollaborator(convertedFile[index], index + 1)
					);
					let collaboratorsWithoutLast = collaboratorsArray.slice(
						0,
						collaboratorsArray.length - 1
					);
					let lastCollaborator =
						collaboratorsArray[collaboratorsArray.length - 1];

					if (!validateCollaborator(lastCollaborator, index + 1)) {
						collaboratorsArray.pop();
					} else if (
						!checkUniqueCollaborator(
							collaboratorsWithoutLast,
							lastCollaborator,
							index + 1
						)
					) {
						collaboratorsArray.pop();
					}
				} catch (err) {
					console.log(err, 'linha: ', index + 1);
				}
			}
		}

		const maxCollaboratorsAllowed =
			COLLABORATORS_BATCH_LIMIT - alreadyAddedCollaborators.length;
		if (collaboratorsArray.length > maxCollaboratorsAllowed) {
			toast.error(
				`Só é possível adicionar mais ${maxCollaboratorsAllowed} colaboradores. O limite foi ultrapassado em ${
					collaboratorsArray.length - maxCollaboratorsAllowed
				}.`
			);
			return;
		}

		setImportedCollaborators(collaboratorsArray);
		inputRef.current!.value = ''; // reset input
	}

	function checkFileFields(fields: string[]) {
		// 2 fields includes all collaborator's data and at least one benefit collumn
		if (fields.length < 2) {
			toast.error('Arquivo não está conforme o modelo aceito. Faltam campos.');
			return false;
		}

		const EXPECTED_FIELDS = ['CPF', 'NOME'];
		const collabFields = fields.slice(0, 2);

		for (let index = 0; index < collabFields.length; index++) {
			if (
				!(collabFields[index].trim().toUpperCase() === EXPECTED_FIELDS[index])
			) {
				toast.error('Arquivo não está conforme o modelo aceito.');
				return false;
			}
		}

		return true;
	}

	// get the last added collaborator and compare with the ones already added if they are the repeated
	function checkUniqueCollaborator(
		collaborators: Collaborator[],
		lastCollaborator: Collaborator,
		collabRow: number
	) {
		let error = false;

		if (
			collaborators.find(
				(collaborator) => collaborator.cpf === lastCollaborator.cpf
			)
		) {
			setErrors((oldErrors) => [
				...oldErrors,
				{
					error: `CPF ${lastCollaborator.cpf} duplicado.`,
					line: collabRow,
					collaborator: lastCollaborator,
				},
			]);
			error = true;
		}
		return !error;
	}

	// Check if fields data are filled in
	function checkRequiredFieldsData(collaboratorData: any[], collabRow: number) {
		// data fields CPF and NAME
		const requiredFieldsIndex = [0, 1];

		if (typeof collaboratorData[0] !== 'string') {
			setErrors((oldErrors) => [
				...oldErrors,
				{
					error: "CPF precisa estar entre áspas. Ex: '01234567890'",
					line: collabRow,
					collaborator: {
						cpf: collaboratorData[0],
						name: collaboratorData[1],
					} as Collaborator,
				},
			]);
			throw new Error('Invalid CPF Format');
		}

		const isEmptyRow = collaboratorData.every((col) => col.trim() === '');
		if (isEmptyRow) throw new Error('Empty row');

		requiredFieldsIndex.forEach((index) => {
			if (collaboratorData[index].toString().length === 0) {
				setErrors((oldErrors) => [
					...oldErrors,
					{
						error: 'Um ou mais campos obrigatórios em branco.',
						line: collabRow,
						collaborator: {
							cpf: collaboratorData[0],
							name: collaboratorData[1],
						} as Collaborator,
					},
				]);
				throw new Error('Required field blank');
			}
		});
	}

	function validateCollaborator(collaborator: Collaborator, collabRow: number) {
		const collabCPF = collaborator.cpf;
		let error = false;

		if (!cpfValidator.isValid(collabCPF)) {
			error = true;
			setErrors((oldErrors) => [
				...oldErrors,
				{
					error: `Formato de CPF inválido.`,
					line: collabRow,
					collaborator: collaborator,
				},
			]);
		}

		return !error;
	}

	// parse collaborator row (array of values) to Collaborator object
	function parseCollaborator(
		collaboratorDataArray: Array<any>,
		collabRow: number
	) {
		let collaborator: ImportedCollaborator = {} as ImportedCollaborator;

		checkRequiredFieldsData(collaboratorDataArray, collabRow); // Check if fields data are filled in

		collaborator.cpf = cpfValidator
			.format(collaboratorDataArray[0])
			.replaceAll('.', '')
			.replaceAll('-', '')
			.toString();
		collaborator.name = collaboratorDataArray[1].toString();
		collaborator.id = collaborator.cpf;

		return { ...collaborator, line: collabRow };
	}

	return (
		<>
			<S.ImportButton htmlFor='import-collaborators-cards-input'>
				Importar colaboradores
				<input
					type='file'
					id='import-collaborators-cards-input'
					onChange={handleCollaboratorsFileUpload}
					ref={inputRef}
					accept={importFileSupportedExtenstions}
					multiple={false}
				/>
			</S.ImportButton>

			<Modal
				isOpen={errors.length > 0}
				enableClose
				onRequestClose={() => {
					setErrors([]);
				}}
			>
				<LineErrorsCollaboratorsImported errors={errors} includeEmail={false} />
			</Modal>

			<ModalLoader loading={getCollaboratorsFromCPFQuery.isLoading} />
		</>
	);
}
