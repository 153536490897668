import styled from 'styled-components';
import { ModalHeaderTextProps } from './InformationModal';
import React from 'react';

const HeaderModal = ({
	title,
	icon,
}: {
	title: ModalHeaderTextProps[];
	icon: {
		icon: React.ReactNode;
		background?: string;
	};
}) => {
	const Title: React.ReactNode = title.map((text, index) => (
		<>
			<HeaderText
				key={text.text ?? '' + index}
				color={text.color}
				isBold={text.isBold}
			>
				{text.text}
			</HeaderText>
			{text.text?.includes('\n') && <br />}
		</>
	));

	return (
		<HeaderContainer>
			{icon && (
				<IconContainer
					color={icon.background ? icon.background : 'var(--primary-blue)'}
				>
					{icon.icon}
				</IconContainer>
			)}

			<p style={{ textAlign: 'center' }}>{Title}</p>

			<HeaderDescription
				style={{
					justifyContent: 'center',
					flexDirection: 'column',
				}}
			></HeaderDescription>
		</HeaderContainer>
	);
};

export default HeaderModal;

export const HeaderContainer = styled.div`
	background-color: #f0f3f8;
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 15rem;
	align-items: center;
	justify-content: center;
	border-radius: 1rem;
	padding: 0 4rem;
`;

const HeaderText = styled.span<{ isBold?: boolean; color?: string }>`
	color: ${(props) => props.color};
	font-size: 2rem;
	font-weight: ${(props) => (props.isBold ? 'bold' : 'normal')};
`;

export const HeaderDescription = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	margin-top: 1rem;
	flex-wrap: wrap;
	align-items: center;
	& > p {
		text-decoration: underline;
	}
`;

const IconContainer = styled.div<{ color?: string }>`
	display: flex;
	align-items: center;
	justify-content: center;
	background: ${(props) => props.color};
	border-radius: 50%;
	padding: 1rem;
	margin-bottom: 1rem;
`;
