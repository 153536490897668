import { Card } from '../../../../../@types/CorporateExpenses/Card';
import { useAuth } from '../../../../../hooks/useAuth';
import { useQuery } from 'react-query';
import { getCardNotificationsPreferences } from '../../../../../services/queries/Corpway/Cards';
import { useState } from 'react';
import { CardDetailsModal } from './index';
import { toast } from 'react-toastify';
import {
	MdCreditCard,
	MdKeyboardArrowRight,
	MdNotifications,
} from 'react-icons/md';
import { Button } from '../../../../../componentsV2/ui/Button';
import { CardNotifications } from './CardNotifications';
import { CardTransactions } from './CardTransactions';
import ContentTransition from './ContentTransition';

export function CardNotificationsAndTransactionsModals({
	card,
}: {
	card: Card;
}) {
	const { currentCompany } = useAuth();

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [animationModal, setAnimationModal] = useState(false);
	const [showCardDetails, setShowCardDetails] = useState(false);
	const [currentView, setCurrentView] = useState<
		'transactions' | 'notifications'
	>('transactions');

	function showNotifications() {
		setCurrentView('notifications');
	}

	function showTransactions() {
		setCurrentView('transactions');
	}

	const { data = { limit_value: 0, limit_value_alert: false } } = useQuery(
		['fetchCardNotifications', currentCompany!.id, card.id],
		() => getCardNotificationsPreferences(currentCompany!.id, card.id),
		{
			onError: () => {
				toast.error('Ocorreu um problema ao buscar as informações do cartão');
			},
			refetchOnWindowFocus: false,
			refetchOnMount: false,
		}
	);

	return (
		<>
			<Button
				intent='primary'
				roundness='lg'
				style={{
					columnGap: '0.6rem',
					fontWeight: 550,
					fontFamily: 'poppins',
					fontSize: '1.5rem',
					padding: '1rem 3.5rem',
					paddingRight: '2.4rem',
				}}
				onClick={() => {
					setIsModalOpen(true);
					setCurrentView('transactions');
					setAnimationModal(true);
				}}
			>
				Gerenciar
				<MdKeyboardArrowRight
					style={{ fontSize: '2.3rem', width: 'auto', height: 'auto' }}
				/>
			</Button>
			<CardDetailsModal
				showCardDetails={showCardDetails}
				toggleShowCardDetails={setShowCardDetails}
				showToggle={false}
				card={card}
				isOpen={isModalOpen}
				animationModal={animationModal}
				notificationsIcon={
					<Button
						style={{
							borderColor: 'transparent',
							color:
								currentView === 'notifications'
									? '#FFF'
									: data.limit_value_alert
									? '#FEBE14'
									: '#FFF',
						}}
						onClick={
							currentView === 'notifications'
								? showTransactions
								: showNotifications
						}
						iconRight={
							currentView === 'notifications' ? (
								<MdCreditCard />
							) : (
								<MdNotifications />
							)
						}
						intent='link'
					/>
				}
				modalToggle={() => {
					setIsModalOpen(false);
					setShowCardDetails(false);
				}}
			>
				<ContentTransition keyProp={currentView}>
					{currentView === 'notifications' ? (
						<CardNotifications card={card} cardSettings={data} />
					) : (
						<CardTransactions card={card} />
					)}
				</ContentTransition>
			</CardDetailsModal>
		</>
	);
}
