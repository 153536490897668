import { FaInfoCircle } from 'react-icons/fa';
import styled from 'styled-components';
import { Typography } from '../ui/Typography';

const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 2rem 0;
	& svg {
		color: var(--light-gray);
		width: 2rem;
		height: 2rem;
		margin: 0 1rem;
	}
`;
interface EmptyContentProp {
	text?: string;
}

export function EmptyContent({
	text = 'Não há ítens a serem exibidos.',
}: EmptyContentProp) {
	return (
		<Container>
			<FaInfoCircle />
			<Typography size='1.6rem'>{text}</Typography>
		</Container>
	);
}
