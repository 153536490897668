import styled from 'styled-components';

const Container = styled.div`
	display: flex;
	align-items: center;
	width: 80%;
`;

const Item = styled.div<{ isActive: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ isActive }) =>
			isActive ? 'var(--primary-blue)' : 'transparent'};
    border: ${({ isActive }) => (!isActive ? '0.3rem solid #F0F3F8;' : '0px')}
    text-align: center;
    min-width: 3rem;
    min-height: 3rem;
    border-radius: 50%;

    & > span {
        cursor: none;
        font-size: 1.5rem;
        color: ${({ isActive }) => (isActive ? 'white;' : '#C6C6C6;')}
    }
`;

const HorizontalDivider = styled.div<{ isActive: boolean }>`
	background-color: ${({ isActive }) =>
		isActive ? 'var(--primary-blue)' : '#F0F3F8'};
	text-align: center;
	width: 100%;
	height: 0.3rem;
`;

export function StepHeader({ activeUntil = 1 }: { activeUntil: number }) {
	return (
		<Container>
			<Item isActive={activeUntil >= 1}>
				<span>1</span>
			</Item>
			<HorizontalDivider isActive={activeUntil >= 2} />
			<Item isActive={activeUntil >= 2}>
				<span>2</span>
			</Item>
			<HorizontalDivider isActive={activeUntil >= 3} />
			<Item isActive={activeUntil >= 3}>
				<span>3</span>
			</Item>
		</Container>
	);
}
