import { createColumnHelper } from '@tanstack/table-core';
import { ChangeEvent, useState } from 'react';
import { FaArrowRight, FaCircle, FaCreditCard, FaSearch } from 'react-icons/fa';
import { useQuery } from 'react-query';
import arrowUpImg from '../../../assets/arrow-up.png';
import cardsIllustrationImg from '../../../assets/cards-illustration.png';
import walletImg from '../../../assets/wallet.png';
import { Breadcrumb } from '../../../componentsV2/BreadCrumb';
import { Progress } from '../../../componentsV2/Progress';
import { Table } from '../../../componentsV2/Table';
import { SectionContainer } from '../../../componentsV2/ui/SectionContainer';
import {
	TitleTypography,
	Typography,
} from '../../../componentsV2/ui/Typography';
import { useAuth } from '../../../hooks/useAuth';
import { useGlobalState } from '../../../hooks/useGlobalState';
import { convertCentsToFormattedReais } from '../../../utils/CurrencyConvert';
import { showErrorMessage } from '../../../utils/ErrorHandler';
import { parseCurrencyToBRLStandard } from '../../../utils/parseCurrency';
import { FundsInModal } from './components/FundsInModal';
import * as S from './styles';
import styled from 'styled-components';
import {
	IoIosArrowRoundDown,
	IoIosArrowRoundUp,
	IoIosWallet,
	IoMdCalendar,
} from 'react-icons/io';
import { useSortColumnHook } from '../../../hooks/useSortColumnHook';
import { InputComp } from '../../../componentsV2/ui/Form/InputStyle';
import {
	BalanceMovement,
	getBalanceMovements,
} from '../../../services/queries/Corpway/Funds';
import {
	parseMovementDescription,
	parseMovementStatus,
	parseMovementStatusToTextOnly,
	parseMovementType,
} from '../Management/utils/parseBalanceMovements';
import { CORPWAY_MANAGEMENT_KEY } from '../../../services/queries/Corpway/Management';
import { ReportGeneratorModal } from '../Management/ReportGeneratorModal';
import { IoFilter } from 'react-icons/io5';
import { InputField } from '../../../componentsV2/ui/Form/InputField';
import { Carousel } from '../../../componentsV2/Carousel';
import { BiTransfer } from 'react-icons/bi';
import { MovementDetailsModal } from './components/MovementDetailsModal';
import { TbArrowBack } from 'react-icons/tb';
import { BsFillSendFill } from 'react-icons/bs';
import { Button } from '../../../componentsV2/ui/Button';

const columnHelper = createColumnHelper<BalanceMovement>();
const columns = [
	columnHelper.accessor('created_at', {
		cell: (info) => {
			const date = new Date(info.getValue());

			return `${date.getDate().toString().padStart(2, '0')}/${(
				date.getMonth() + 1
			)
				.toString()
				.padStart(2, '0')}/${date.getFullYear()}`;
		},
		header: 'Data',
		enableSorting: true,
	}),
	columnHelper.accessor((row) => row, {
		id: 'type',
		cell: (info) => {
			const movementDetails = parseMovementDescription(info.getValue());

			const operationTypeIcons = {
				'Recarga de carteira': <IoIosWallet />,
				'Repasse para cartão': <FaCreditCard />,
				'Estorno para carteira': <TbArrowBack />,
				'Recebimento via chargeback': <TbArrowBack />,
				'Transferência externa': <BsFillSendFill />,
			};

			return (
				<div style={{ display: 'flex', gap: '0.8rem' }}>
					<S.OperationTypeIcon>
						{
							operationTypeIcons[
								movementDetails.operationType as keyof typeof operationTypeIcons
							]
						}
					</S.OperationTypeIcon>
					<div style={{ display: 'flex', flexDirection: 'column' }}>
						<p style={{ fontSize: '1.4rem' }}>
							{movementDetails.operationType}
						</p>
						<span style={{ fontSize: '1rem' }}>
							{movementDetails.operationDetail}
						</span>
					</div>
				</div>
			);
		},
		header: 'Descrição',
		enableSorting: true,
	}),
	columnHelper.accessor('status', {
		header: 'Status',
		enableSorting: true,
		cell: (info) => {
		  const status = parseMovementStatus(info.row.original.error_msg ?? info.getValue()!);
	  
		  return (
			<span style={{
				whiteSpace: 'nowrap',
			}}>
			  {status}
			</span>
		  );
		},
	  }),	  
	columnHelper.accessor((row) => row, {
		cell: (info) => {
			const movementType = parseMovementType(
				info.getValue().type,
				info.getValue().action
			);

			if (movementType === 'Transferência externa') {
				return (
					<span style={{ display: 'flex', gap: '1rem' }}>
						{convertCentsToFormattedReais(info.getValue().amount)}{' '}
						<IoIosArrowRoundDown size={20} color='var(--primary-red)' />
					</span>
				);
			}

			if (movementType === 'Recarga de carteira') {
				return (
					<span style={{ display: 'flex', gap: '1rem' }}>
						{convertCentsToFormattedReais(info.getValue().amount)}{' '}
						<IoIosArrowRoundUp size={20} color='#00a22d' />
					</span>
				);
			}

			return (
				<span style={{ display: 'flex', gap: '1rem' }}>
					{convertCentsToFormattedReais(info.getValue().amount)}{' '}
					<BiTransfer size={20} color='var(--primary-blue)' />
				</span>
			);
		},
		header: 'Valor',
		enableSorting: true,
	}),
	columnHelper.display({
		header: 'Detalhes',
		meta: {
			align: 'center',
		},
		cell: (props) => <MovementDetailsModal movement={props.row.original} />,
	}),
];

export function Wallet() {
	const { currentCompany, companyBalance, user } = useAuth();
	const { showBalance } = useGlobalState();
	const { currentSortColumn, toggleSort } = useSortColumnHook({
		name: 'created_at',
		order: 'DESC',
	});
	const [search, setSearch] = useState('');

	// Show fundsOut to all users admin OR show it only to Bounty company users admins
	const shouldShowFundsOut =
		(process.env.REACT_APP_FUNDS_OUT_ENABLED === 'true' ||
			process.env.REACT_APP_BOUNTY_COMPANY_ID === currentCompany?.id) && user.corpway_access_level === "general_admin";

	const [transactionFilters, setTransactionFilters] = useState({
		fundsIn: {
			label: 'Recarga de carteira',
			isActive: false,
		},
		cardRecharge: {
			label: 'Repasse para cartão',
			isActive: false,
		},
		...(shouldShowFundsOut && {
			externalTransaction: {
				label: 'Transferência externa',
				isActive: false,
			},
		}),
		chargeback: {
			label: 'Estorno para carteira',
			isActive: false,
		},
		external_chargeback: {
			label: 'Recebimento via chargeback',
			isActive: false,
		},
	});



	const currentDate = new Date();
	currentDate.setMonth(currentDate.getMonth());
	const firstDayOfMonth = new Date(
		currentDate.getFullYear(),
		currentDate.getMonth(),
		1
	);

	const eighteenMonthsAgo = new Date();
	eighteenMonthsAgo.setMonth(eighteenMonthsAgo.getMonth() - 17);
	const minDate = new Date(2024, 0);

	const [startDate, setStartDate] = useState(firstDayOfMonth);
	const [endDate, setEndDate] = useState(new Date());

	const [dateSelectorFormat, setDateSelectorFormat] = useState<
		'month' | 'custom'
	>('month');

	const getBalanceMovementsQuery = useQuery(
		[
			CORPWAY_MANAGEMENT_KEY,
			user.id,
			currentCompany?.id,
			startDate,
			endDate,
			currentSortColumn,
		],
		() =>
			getBalanceMovements(
				currentCompany!.id,
				startDate.valueOf(),
				endDate.valueOf(),
				currentSortColumn
			),
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível buscar as movimentações de saldo. '
				);
			},
			enabled: user.roles.includes('manage-balances'),
		}
	);

	function checkMinDate() {
		if (eighteenMonthsAgo < minDate) {
			return minDate;
		} else {
			return eighteenMonthsAgo;
		}
	}

	const handleStartDateChange = (e: ChangeEvent<HTMLInputElement>) => {
		const newDate = new Date(e.target.value);
		if (!isNaN(newDate.getTime())) {
			setStartDate(newDate);
		}
	};

	const handleEndDateChange = (e: ChangeEvent<HTMLInputElement>) => {
		const newDate = new Date(e.target.value);
		if (!isNaN(newDate.getTime())) {
			setEndDate(newDate);
		}
	};

	function handleAccountBalanceVisibillity() {
		return showBalance ? (
			parseCurrencyToBRLStandard(companyBalance.corpway_account_balance)
		) : (
			<div className='hiddenBalance'>
				<p> R$ </p>
				<FaCircle size={12} />
				<FaCircle size={12} />
				<FaCircle size={12} />
				<FaCircle size={12} />
			</div>
		);
	}

	function handleCompanyBalanceVisibillity() {
		return showBalance ? (
			parseCurrencyToBRLStandard(companyBalance.corpway_balance)
		) : (
			<div className='hiddenBalance'>
				<p> R$ </p>
				<FaCircle size={12} />
				<FaCircle size={12} />
				<FaCircle size={12} />
				<FaCircle size={12} />
			</div>
		);
	}

	function handleCardsBalanceVisibillity() {
		return showBalance ? (
			parseCurrencyToBRLStandard(companyBalance.corpway_cards_balance)
		) : (
			<div className='hiddenBalance'>
				<p> R$ </p>
				<FaCircle size={12} />
				<FaCircle size={12} />
				<FaCircle size={12} />
				<FaCircle size={12} />
			</div>
		);
	}

	const movementTransactions =
		getBalanceMovementsQuery.data?.balanceMovements.filter((c) => {
			const movementType = parseMovementType(c.type, c.action);

			let filterActive = false;
			let filterTransactionOut = false;
			Object.entries(transactionFilters).forEach(
				([filter, { isActive, label }]) => {
					if (isActive) filterActive = isActive;
					if (label === movementType && !isActive) filterTransactionOut = true;
				}
			);

			if (filterActive && filterTransactionOut) return false;

			return (
				c.card?.alias.toLowerCase().includes(search.toLowerCase()) ||
				String(c.amount).toLowerCase().includes(search.toLowerCase()) ||
				parseMovementType(c.type, c.action)
					.toLowerCase()
					.includes(search.toLowerCase()) ||
				parseMovementStatusToTextOnly(c.status!)
					.toLowerCase()
					.includes(search.toLowerCase()) ||
				c.card?.pan.toLowerCase().includes(search.toLowerCase())
			);
		}) ?? [];

	return (
		<GeneralContainer>
			<SectionContainer style={{ padding: '4rem', height: '168px' }}>
				<Breadcrumb
					routes={[{ title: 'Carteira', route: '/corporate-expenses/wallet' }]}
				/>
				<TitleTypography image>Carteira</TitleTypography>
			</SectionContainer>

			<UpperContainer>
				<HeaderContainer style={{ marginBottom: '3rem' }}>
					<TitleTypography size='2.4rem' primaryText='Saldo'>
						da carteira
					</TitleTypography>
				</HeaderContainer>

				<S.TopContainer>
					<S.InfoContainerCard>
						<div
							className='accountBalance'
							style={{
								gap: '1.5rem',
								width: '17.5rem',
								marginLeft: '1.5rem',
							}}
						>
							<div>
								<Typography weight='600' size='2rem'>
									Saldo&nbsp;
									<span
										style={{
											color: 'var(--primary-blue)',
											whiteSpace: 'nowrap',
										}}
									>
										em carteira
									</span>
								</Typography>
								<Typography weight='700' color='var(--dark-gray)' size='2.4rem'>
									{handleAccountBalanceVisibillity()}
								</Typography>
							</div>

							<S.TypographyDescription>
								Soma do saldo disponível e do <br /> saldo nos cartões.
							</S.TypographyDescription>
						</div>
						<img src={walletImg} alt='Carteira Ilustração' />
					</S.InfoContainerCard>

					<S.InfoContainerCard>
						<div
							className='accountBalance'
							style={{
								gap: '1rem',
								width: '17.5rem',
								marginLeft: '1.5rem',
							}}
						>
							<div>
								<Typography weight='600' size='1.8rem'>
									Saldo&nbsp;
									<span style={{ color: '#00A22D' }}>disponível</span>
								</Typography>
								<Typography weight='700' color='var(--dark-gray)' size='2.4rem'>
									{handleCompanyBalanceVisibillity()}
								</Typography>
							</div>

							<S.TypographyDescription>
								Saldo que está liberado para ser <br /> alocado nos cartões.
							</S.TypographyDescription>
						</div>
						<img src={arrowUpImg} alt='Seta pra cima Ilustração' />
					</S.InfoContainerCard>

					<S.InfoContainerCard>
						<div
							className='accountBalance'
							style={{
								gap: '1rem',
								width: '17.5rem',
								marginLeft: '1.5rem',
							}}
						>
							<div>
								<Typography weight='600' size='1.8rem'>
									Saldo&nbsp;
									<span
										style={{
											color: 'var(--primary-red)',
											whiteSpace: 'nowrap',
										}}
									>
										nos cartões
									</span>
								</Typography>
								<Typography weight='700' color='var(--dark-gray)' size='2.4rem'>
									{handleCardsBalanceVisibillity()}
								</Typography>
							</div>
							<S.TypographyDescription>
								Saldo que já está distribuído nos cartões.
							</S.TypographyDescription>
						</div>
						<img
							src={cardsIllustrationImg}
							alt='Cartões Ilustração'
							style={{
								height: '21rem',
							}}
						/>
					</S.InfoContainerCard>
				</S.TopContainer>

				<S.BalanceContainer>
					<div>
						<S.BalanceInfoContainer>
							<TypographyStyledTitle color='var(--white)'>
								<S.Indicator primary /> Saldo
								disponível&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
							</TypographyStyledTitle>
							<TypographyStyled size='3rem' weight='600' color='var(--white)'>
								{handleCompanyBalanceVisibillity()}
							</TypographyStyled>
						</S.BalanceInfoContainer>

						<S.Separator />

						<S.BalanceInfoContainer style={{ alignItems: 'flex-end' }}>
							<TypographyStyledTitle color='var(--white)'>
								<S.Indicator
									primary={false}
									style={{ background: 'var(--white)' }}
								/>{' '}
								Saldo em carteira
							</TypographyStyledTitle>
							<TypographyStyled size='3rem' weight='600' color='var(--white)'>
								{handleAccountBalanceVisibillity()}
							</TypographyStyled>
						</S.BalanceInfoContainer>
					</div>

					<Progress
						value={
							companyBalance.corpway_balance /
							companyBalance.corpway_account_balance
						}
					/>
				</S.BalanceContainer>

				<S.ButtonsContainer>
					{shouldShowFundsOut && (
						<Button
							roundness='lg'
							$outline
							intent={'terciary'}
							as='link'
							to='/corporate-expenses/wallet/transfers'
						>
							Transferir Saldo
						</Button>
					)}

					<FundsInModal />
				</S.ButtonsContainer>
			</UpperContainer>

			<S.ContentContainer>
				<S.TableContainer>
					{user.roles.includes('manage-balances') && (
						<>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									marginBottom: '2rem',
								}}
							>
								<TitleTypography
									size='2rem'
									primaryText='Extrato'
									style={{
										marginRight: '10px',
										flexWrap: 'nowrap',
										whiteSpace: 'nowrap',
									}}
								>
									da carteira
								</TitleTypography>
								{!!getBalanceMovementsQuery.data?.balanceMovements.length && (
									<ReportGeneratorModal
										startMonth={startDate}
										endMonth={endDate}
									/>
								)}
							</div>

							<Table
								showHeader
								variant={'lightgray'}
								data={movementTransactions}
								asyncStatus={getBalanceMovementsQuery.status}
								columns={columns}
								multipleComponents={[
									<S.DateFiltersContainer>
										{dateSelectorFormat === 'month' ? (
											<S.MonthSelector className='month'>
												<IoMdCalendar size={30} />

												<InputComp
													type='month'
													value={startDate.toISOString().slice(0, 7)}
													defaultValue={currentDate
														.toISOString()
														.split('-')
														.slice(0, 2)
														.join('-')}
													min={checkMinDate()
														.toISOString()
														.split('-')
														.slice(0, 2)
														.join('-')}
													max={currentDate
														.toISOString()
														.split('-')
														.slice(0, 2)
														.join('-')}
													onChange={(e) => {
														const selectedMonth = e.target.value;
														const year = parseInt(selectedMonth.split('-')[0]);
														const month = parseInt(selectedMonth.split('-')[1]);
														setStartDate(new Date(year, month - 1, 1));
														setEndDate(new Date(year, month, 0));
													}}
												/>
											</S.MonthSelector>
										) : (
											<S.DatesContainer>
												<InputComp
													type='date'
													defaultValue={startDate.toISOString().split('T')[0]}
													min={checkMinDate().toISOString().split('T')[0]}
													max={currentDate.toISOString().split('T')[0]}
													onChange={handleStartDateChange}
													style={{ width: 'fit-content' }}
												/>
												<FaArrowRight
													style={{
														width: '2rem',
														height: '2rem',
														color: 'var(--primary-blue)',
													}}
												/>
												<InputComp
													type='date'
													defaultValue={endDate.toISOString().split('T')[0]}
													min={checkMinDate().toISOString().split('T')[0]}
													max={currentDate.toISOString().split('T')[0]}
													onChange={handleEndDateChange}
													style={{ width: 'fit-content' }}
												/>
											</S.DatesContainer>
										)}

										<S.DateFilterFormatSelector
											onClick={() =>
												setDateSelectorFormat(
													dateSelectorFormat === 'month' ? 'custom' : 'month'
												)
											}
										>
											<IoFilter size={20} />

											<Typography
												style={{
													fontSize: '1.4rem',
													textDecoration: 'underline',
												}}
											>
												Período{' '}
												{dateSelectorFormat === 'month'
													? 'personalizado'
													: 'por mês'}
											</Typography>
										</S.DateFilterFormatSelector>
									</S.DateFiltersContainer>,

									<S.SearchContainer>
										<InputField
											placeholder='Pesquisar por transação'
											style={{ paddingRight: '4rem' }}
											value={search}
											onChange={(e) => setSearch(e.target.value)}
											spellCheck={false}
										/>
										<FaSearch />
									</S.SearchContainer>,

									<Carousel mode='draggable' gap='1rem'>
										{Object.entries(transactionFilters).map(
											([filter, { isActive, label }]) => (
												<S.TransactionFilterChipContainer
													isActive={isActive}
													onClick={() => {
														setTransactionFilters((prevFilters) => ({
															...prevFilters,
															[filter]: {
																label,
																isActive: !isActive,
															},
														}));
													}}
												>
													{label}
												</S.TransactionFilterChipContainer>
											)
										)}
									</Carousel>,
								]}
								currentSortColumn={currentSortColumn}
								toggleSort={toggleSort}
							/>
						</>
					)}
				</S.TableContainer>
			</S.ContentContainer>
		</GeneralContainer>
	);
}

const TypographyStyledTitle = styled(Typography)`
	@media (max-width: 1024px) {
		align-items: center;
		font-size: 1.3rem;

		// & > div {
		// 	background: var(--white) !important;
		// }
	}
`;

const TypographyStyled = styled(Typography)`
	@media (max-width: 1024px) {
		font-size: 2.5rem;
	}
`;

const HeaderContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

const GeneralContainer = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	row-gap: 2.4rem;
	background-color: #f0f4f9;
`;

const UpperContainer = styled.div`
	flex: 1;
	background-color: var(--white);
	border-radius: 1.6rem;
	padding: 4rem;
	@media (max-width: 650px) {
		padding: 1.6rem;
	}
`;
