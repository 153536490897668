import { flexRender, Table as ReactTable } from '@tanstack/react-table';
import * as S from "./styles"
import { parseUserAccessLevel } from '../../../utils/corpway/parseUserAccessLevel';
import { CorpwayUserAccessLevel } from '../../../@types/CorporateExpenses/User';

export default function UsersTable({table}: {table : ReactTable<any>}) {
	const rows = table.getRowModel().rows?.map((row) => ({
		original: row.original,
		visibleCells: row
			.getVisibleCells()
			.filter((cell) => ['user_name', 'Ações'].includes(cell.column.id)),
	}));

	const roleUsers = rows.reduce(
		(acc: { [key: string]: typeof rows }, row) => {
			if (!acc[row.original.user_role]) {
				acc[row.original.user_role] = [];
			}
			acc[row.original.user_role].push(row);

			return acc;
		},
		{}
	);

	console.log(Object.entries(roleUsers).length)

	return (
		<S.TableContainer>
			{Object.entries(roleUsers).map(([role, rows], index) => (
				<S.TableComp key={role} style={{ marginBottom: (index  == Object.entries(roleUsers).length - 1) ? 0 : '3rem' }} >
					<S.TR>
						<S.TH >
							{parseUserAccessLevel(role as CorpwayUserAccessLevel)}
						</S.TH>
					</S.TR>
					<tbody>
					{rows.map((row) => (
						<S.TR key={row.original.user_id}>
							{row.visibleCells.map((cell, index) => (
								<S.TD key={index}>
									{flexRender(
										cell.column.columnDef.cell,
										cell.getContext()
									)}
								</S.TD>
							))}
						</S.TR>
					))}
					</tbody>
				</S.TableComp>
			))}
		</S.TableContainer>
	)
}