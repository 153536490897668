import styled from 'styled-components';

export const ModalContainer = styled.div`
	display: flex;
	min-width: 30rem;
	flex-direction: column;
	align-items: center;
	font-size: 1.4rem;
	text-align: center;
`;

export const ModalForm = styled.form`
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: flex-start;
	flex: 1;

	> button {
		margin-top: 2rem;
		align-self: center;
	}
`;

export const ModalLabel = styled.label`
	margin-bottom: 0.5rem;
	color: var(--dark-gray);
	font-weight: 600;
	font-size: 1.5rem;

	& ~ & {
		margin-top: 1.5rem;
	}
`;
