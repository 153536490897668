import { create } from 'zustand';
import { FilterParams } from '../components/Filter';

type Store = {
	filtersParams: FilterParams[];
	setFiltersParams: (f: FilterParams[]) => void;
	resetFilters: () => void;
};

const filtersParams: FilterParams[] = [
	{ name: 'Nome', filter: 'title', value: '', type: 'text', selected: false },
	{
		name: 'Descrição',
		filter: 'description',
		value: '',
		type: 'text',
		selected: false,
	},
];

const useCostCentersListStore = create<Store>()((set) => ({
	filtersParams,
	setFiltersParams: (f) => set({ filtersParams: f }),
	resetFilters: () => set({ filtersParams }),
}));

export { useCostCentersListStore };
