import styled from 'styled-components';

interface Props {
	value: number;
}
export function Progress({ value }: Props) {
	return (
		<Container>
			<ProgressContainer value={value} />
		</Container>
	);
}

const Container = styled.div`
	width: 100%;
	height: 1rem;
	border-radius: 1rem;
	background-color: var(--light-gray);
`;
const ProgressContainer = styled.div<{ value: number }>`
	width: ${({ value }) => `${value * 100}%`};
	height: 100%;
	border-radius: 1rem;
	background-color: var(--primary-blue);
`;
