import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { OptionsButton } from '../../../components/Button';

export const Container = styled.div`
	flex: 1;
	padding: 4rem 7rem 2rem 7rem;
	margin-bottom: 2rem;
`;

export const CreateButton = styled(Link)`
	text-decoration: none;
	${OptionsButton}
	font-size: 1.4rem;
`;

export const CardStatContainerDetails = styled.details`
	font-size: 2rem;
	font-weight: 500;
	margin-bottom: 1rem;
	cursor: default;
`;

export const CardStatsList = styled.ul`
	display: flex;
	flex-direction: column;
	margin-left: 2rem;
`;

export const CardStatItem = styled.li`
	list-style: none;
	font-size: 1.4rem;
`;
