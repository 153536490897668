import styled from 'styled-components';

export const Details = styled.details`
	& > summary {
		cursor: pointer;
		list-style: none;
	}
	& svg {
		color: var(--primary-blue);
		width: 3rem;
		height: 3rem;
		transition: all 0.5s ease-in-out;
	}
	&[open] svg {
		transform: rotate(180deg);
		transition: all 0.5s ease-in-out;
	}

	&[open] summary {
		padding-bottom: 2rem;
		border-bottom: 1px solid #f0f4f9;
	}
	& summary::-webkit-details-marker {
		display: none;
	}
`;

export const SectionHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;
export const CompanyInfoContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-template-rows: repeat(2, 1fr);
	margin-top: 2rem;
	gap: 2rem;

	@media (max-width: 1040px) {
		display: flex;
		flex-direction: column;
		gap: 0.2rem;
	}
`;
export const SideBySideContainer = styled.div`
	display: flex;
	column-gap: 2rem;
	& > div {
		flex: 1;
	}

	@media (max-width: 1040px) {
		flex-direction: column;
		gap: 2rem;
	}
`;
export const BalanceContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 100%;
	padding: 0 2rem 2rem 4rem;
		
	& img {
		height: 18rem;
		object-fit: cover;

		@media (max-width: 650px) {
			width: 13rem;
			height: 16rem;
		}
	}

	.hiddenBalance {
		display: flex;
		align-items: center;
		color: var(--dark-gray);
		gap: 0.4rem;
	}

	@media (max-width: 650px) {
		flex-direction: column-reverse;
		padding: 0;
	}
`;
export const BalanceInfoContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 2.4rem;

	@media (max-width: 650px) {
		width: 100%;
		align-items: center;
		gap: 0.8rem;
	}
`;

export const DatesContainer = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;

	& input[type='month'] {
		position: relative;
		font-family: 'Poppins', sans-serif;
		padding-left: 5rem;
	}

	& input[type='month']::-webkit-calendar-picker-indicator {
		position: absolute;
		top: 1rem;
		left: 1rem;
		right: 0;
		bottom: 0;
		width: auto;
		height: 2rem;
		filter: invert(0.5);
	}

	& input[type='month']::-webkit-datetime-edit-month-field {
		text-transform: capitalize;
	}
`;
