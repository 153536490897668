import { StyleSheet } from '@react-pdf/renderer';
import styled from 'styled-components';
import { OptionsButton } from '../Button';

export const PDFstyles = StyleSheet.create({
	page: {
		flexDirection: 'row',
		padding: 10,
	},
	section: {
		display: 'flex',
		alignItems: 'center',
		flexGrow: 1,
	},
	heading: {
		fontSize: 24,
		marginBottom: 10,
		fontWeight: 'bold',
		fontFamily: 'Ubuntu',
	},
	filterParamsContainer: {
		display: 'flex',
		width: '100%',
		marginBottom: 10,
	},
	filterParamsTitle: {
		fontSize: 14,
		fontFamily: 'Ubuntu',
		color: '#474747',
	},
	filterParamsValues: {
		fontSize: 12,
		fontFamily: 'Ubuntu',
		color: '#474747',
		marginLeft: 30,
	},
	resultsRow: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignSelf: 'center',
		columnGap: 10,
		marginTop: 10,
	},
	collabColumn: {
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		width: 170,
		fontSize: 12,
		height: 15,
		color: '#474747',
	},
	content: {
		fontSize: 12,
		marginTop: 10,
	},
	content_data: {
		maxWidth: 'auto',
		fontSize: 12,
		marginTop: 10,
		marginLeft: 50,
		alignSelf: 'flex-start',
	},
	footer: {
		marginTop: 'auto',
		fontSize: 12,
		width: '100%',
		display: 'flex',
		color: '#474747',
		alignItems: 'center',
	},
	totalValues_container: {
		marginTop: 20,
		display: 'flex',
		alignItems: 'center',
		width: '100%',
		flexDirection: 'column',
		rowGap: 10,
		color: '#474747',
	},
	totalValue: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		width: '90%',
		fontSize: 12,
	},
	logo: {
		width: 80,
		marginLeft: 'auto',
		marginRight: 'auto',
		marginBottom: 30,
	},
	headers: {
		flexDirection: 'row',
		justifyContent: 'flex-start',
		marginTop: 5,
		width: '100%',
		fontSize: 12,
		fontFamily: 'Ubuntu',
		color: '#474747',
	},
	columnTitleStyle: {
		paddingBottom: 7,
		width: 'auto',
		fontWeight: 'bold',
		fontFamily: 'Ubuntu',
	},
	columnDataStyle: {
		width: 'auto',
		maxHeight: 60,
		color: '#474747',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		fontFamily: 'Ubuntu',
		paddingBottom: 7,
		marginLeft: 20,
	},
});

export function getColumnTitleStyle(width: number | string) {
	const column = StyleSheet.create({
		style: {
			paddingBottom: 5,
			borderBottomColor: '#C6C6C6',
			borderBottomStyle: 'solid',
			borderBottomWidth: 2,
			width: width,
			fontWeight: 'bold',
			fontFamily: 'Ubuntu',
		},
	});

	return column.style;
}

export function getColumnDataStyle(width: number | string) {
	const column = StyleSheet.create({
		style: {
			width: width,
			maxHeight: 60,
			color: '#474747',
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			fontFamily: 'Ubuntu',
			paddingBottom: 5,
		},
	});

	return column.style;
}

export const ReportButton = styled.button`
	${OptionsButton}
	width: 14rem;
`;
