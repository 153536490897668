import ReactHintFactory from 'react-hint';
import React, { useState } from 'react';
import styled from 'styled-components';
import { HiOutlineInformationCircle } from 'react-icons/hi';

const ReactHint = ReactHintFactory(React);


const TooltipContainer = styled.div`
    display: flex;
    width: 221px;
    max-width: 350px;
    padding: 5px 14px;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    border-radius: 5px;
    background: #393939;
    color: #FFF;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;`;

export default function CustomTooltip(
	{
		title = 'Definição de gasto:',
		description = 'Considera a diferença entre os débitos (compras e taxas) e os estornos (quando creditados no mesmo mês em que a compra foi efetuada).',
	}) {
	return (
		<>
			<ReactHint
				attribute="data-custom-1"
				events
				autoPosition
				onRenderContent={(target) => (
					<TooltipContainer>
						<p>
							<b>{target.getAttribute('data-custom-1-title')}</b>
							<br />
							<span>
								{target.getAttribute('data-custom-1-description')}
							</span>
						</p>
					</TooltipContainer>)}
			/>
			<HiOutlineInformationCircle size={"16px"} style={{transform : 'translateY(5px)'}}
				data-custom-1
				data-custom-1-description={description}
				data-custom-1-title={title} />
		</>
	);
}