import styled from 'styled-components';
import { Typography } from './ui/Typography';

interface StyledProps {
	orientation?: 'vertical' | 'horizontal';
	sizeInRems?: number;
	sameSize?: boolean;
}

interface Props extends StyledProps {
	label: string;
	children: React.ReactNode;
	color?: string;
}
export function InfoContent({
	label,
	children,
	orientation = 'vertical',
	sizeInRems = 1.6,
	sameSize = false,
	color = 'var(--dark-gray)',
}: Props) {
	const labelSmallerSizeAmount = sameSize ? 0 : 0.2;

	const Content = () =>
		typeof children === 'object' ? (
			<>{children}</>
		) : (
			<Typography size={`${sizeInRems}rem`} weight='600' color={color}>
				{children}
			</Typography>
		);

	return (
		<Container orientation={orientation}>
			<Typography
				size={`${sizeInRems - labelSmallerSizeAmount}rem`}
				color={color}
			>
				{label}
			</Typography>
			<Content />
		</Container>
	);
}

const Container = styled.div<StyledProps>`
	display: flex;
	flex-direction: ${(props) =>
		props.orientation === 'vertical' ? 'column' : 'row'};
	gap: 0.4rem;
	align-items: ${(props) =>
		props.orientation === 'vertical' ? 'initial' : 'center'};
`;
