import { useQuery } from 'react-query';
import CostCenter3D from '../../../assets/3d-Cost-Center.png';
import User3D from '../../../assets/3d-user-management.png';
import Card3D from '../../../assets/3d-card-management.png';
import { AsynchronousContent } from '../../../componentsV2/AsynchronousContent';
import { Breadcrumb } from '../../../componentsV2/BreadCrumb';
import { ContentCard } from '../../../componentsV2/ContentCard';
import { SectionContainer } from '../../../componentsV2/ui/SectionContainer';
import { TitleTypography } from '../../../componentsV2/ui/Typography';
import { useAuth } from '../../../hooks/useAuth';
import {
	CORPWAY_MANAGEMENT_KEY,
	getManagementInfo,
} from '../../../services/queries/Corpway/Management';
import { showErrorMessage } from '../../../utils/ErrorHandler';
import * as S from './styles';
import { Users } from './Users';
import { CostCenters } from './CostCenters';
import styled from 'styled-components';

export function Management() {
	const { currentCompany, user } = useAuth();

	const hasAccessToManageUsersAndCostCentersAndCards =
		user.roles.includes('manage-users') &&
		user.roles.includes('manage-cost-centers') &&
		user.roles.includes('manage-cards');

	const getManagementInfoQuery = useQuery(
		[CORPWAY_MANAGEMENT_KEY, user.id, currentCompany?.id],
		() => getManagementInfo(currentCompany!.id),
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível buscar os detalhes de gerenciamento. '
				);
			},
			enabled: hasAccessToManageUsersAndCostCentersAndCards,
		}
	);

	return (
		<GeneralContainer>
			<SectionContainer style={{ padding: '4rem', height: '168px' }}>
				<Breadcrumb
					routes={[
						{ title: 'Gestão', route: '/corporate-expenses/management' },
					]}
				/>
				<TitleTypography image>Gestão</TitleTypography>
			</SectionContainer>

			{hasAccessToManageUsersAndCostCentersAndCards && (
				<AsynchronousContent status={getManagementInfoQuery.status}>
					<S.OptionsContainer>
						<ContentCard
							className='costCenterActive'
							image={CostCenter3D}
							staticContent
						>
							<div className='contentWrapper'>
								<h2>Centros de Custo</h2>
								<p>
									Ativos:
									<TitleTypography size='2.4rem'>
										{getManagementInfoQuery.data?.cost_centers_quantity
											.toString()
											.padStart(2, '0')}
									</TitleTypography>
								</p>
							</div>
						</ContentCard>

						<ContentCard className='usersActive' image={User3D} staticContent>
							<div className='contentWrapper'>
								<h2>Usuários</h2>
								<p>
									Ativos:
									<TitleTypography size='2.4rem'>
										{getManagementInfoQuery.data?.users_quantity
											.toString()
											.padStart(2, '0')}
									</TitleTypography>
								</p>
							</div>
						</ContentCard>

						<ContentCard className='cardsActive' image={Card3D} staticContent>
							<div className='contentWrapper'>
								<h2>Cartões</h2>
								<p>
									Ativos:
									<TitleTypography size='2.4rem'>
										{getManagementInfoQuery.data?.cards_quantity
											.toString()
											.padStart(2, '0')}
									</TitleTypography>
								</p>
							</div>
						</ContentCard>
					</S.OptionsContainer>
				</AsynchronousContent>
			)}

			<Users />

			<CostCenters />
		</GeneralContainer>
	);
}

const GeneralContainer = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	row-gap: 2.4rem;
	background-color: #f0f4f9;
`;
