import React, { useEffect, useRef, useState } from 'react';
import {
	db,
	firebaseAuth,
	firebaseStorage,
} from '../../../../services/firebase';
import Dropzone from 'react-dropzone';
import {
	ref,
	onValue,
	push,
	update,
	serverTimestamp,
	query,
	equalTo,
	DatabaseReference,
	get,
	remove,
	off,
} from 'firebase/database';
import {
	getDownloadURL,
	ref as storageRef,
	uploadBytesResumable,
} from 'firebase/storage';
import * as S from './styles';
import { toast } from 'react-toastify';
import avatarImg from '../../../../assets/avatar.svg';
import { BiArrowBack } from 'react-icons/bi';
import { IoDocumentAttachOutline } from 'react-icons/io5';
import { IoMdSend } from 'react-icons/io';
import { AiFillCloseCircle, AiOutlineCheck } from 'react-icons/ai';
import { GrDocumentWord, GrDocumentExcel, GrDocumentPdf } from 'react-icons/gr';
import ImagesSlider from '../../../../components/ImagesSlider';
import { useForm } from 'react-hook-form';
import { useAuth } from '../../../../hooks/useAuth';
import { ChatProps, CollabInfo } from '../ChatListPage/index';
import { useParams, useHistory } from 'react-router-dom';
import Loader from '../../../../components/Loader';
import { useDialogModal } from '../../../../hooks/useDialogModal';
import { useMutation } from 'react-query';
import {
	sendCloseChatNotificationToCollaborator,
	sendNotificationToCollaborator,
} from '../../../../services/queries/Notifications';

interface MessageProps {
	message: string;
}

interface MessageInfo {
	attachmentPath: string;
	attachmentType: string;
	attachmentName: string;
	viewed: boolean;
	message: string;
	receiverId: string;
	senderId: string;
	timestamp: number;
	senderAvatar?: string;
	senderName?: string;
}

interface ChatPageParams {
	id: string;
}

interface OperatorFirebase {
	email: string;
	jobDescription: string;
	name: string;
	profileUrl: string;
	status: string;
}

interface CollaboratorFirebase {
	id: string;
	email: string;
	job_description: string;
	name: string;
	online_at: string;
	online_status: string;
	section: string;
	picture_url?: string;
}

export const ChatPage = () => {
	const history = useHistory();
	const { user, currentCompany } = useAuth();
	const { openOptionsDialog } = useDialogModal();
	const { register, handleSubmit, reset, setFocus } = useForm<MessageProps>();

	const [attachments, setAttachments] = useState<File[]>([]);
	const [messages, setMessages] = useState<MessageInfo[]>([]);
	const [fileDragActive, setFileDragActive] = useState(false);
	const [collab, setCollab] = useState<CollaboratorFirebase>();
	const [loadingMessages, setLoadingMessages] = useState(true);
	const [uploadingAttachments, setUploadingAttachments] = useState(false);
	const [imageToOpen, setImageToOpen] = useState<string | null>(null);
	const [shouldSendNotificationToCollab, setShouldSendNotificationToCollab] =
		useState(true);

	const { id } = useParams<ChatPageParams>();
	const formRef = useRef<HTMLFormElement>(null);
	const operatorResponsible = useRef(false);
	const refToUnsubscribe = useRef<DatabaseReference>();
	const messagesRef = useRef<DatabaseReference>();

	const chatId = id;
	let imgs: string[] = [];

	useEffect(() => {
		loadChat();
	}, [currentCompany]); //eslint-disable-line

	const sendNotificationToCollaboratorQuery = useMutation(
		() => {
			return sendNotificationToCollaborator(id);
		},
		{
			onSuccess: () => {
				setShouldSendNotificationToCollab(false);
			},
		}
	);

	const sendCloseChatNotificationToCollaboratorQuery = useMutation(() => {
		return sendCloseChatNotificationToCollaborator(id);
	});

	async function loadChat() {
		const chatRef = ref(db, `${currentCompany?.id}/chats/${id}`);
		const chatQuery = query(chatRef, equalTo(`${id}`));
		let chatExistInTheCompany = true;

		refToUnsubscribe.current = chatRef;
		onValue(
			chatQuery.ref,
			(snapshot) => {
				if (!snapshot.val()) {
					chatExistInTheCompany = false;
				}
			},
			(err) => {
				console.log(err);
			}
		);

		if (!chatExistInTheCompany) {
			history.goBack();
			return;
		}

		try {
			const messagesActualRef = ref(
				db,
				`${currentCompany?.id}/chats/${id}/messages`
			);
			messagesRef.current = messagesActualRef;
			const collabRef = ref(db, `${currentCompany?.id}/collaborators/${id}`);

			let chatValues: ChatProps = {} as ChatProps;

			//Getting the messages
			onValue(
				messagesActualRef,
				(snapshot) => {
					setLoadingMessages(true);

					let messagesData: MessageInfo[] = [];

					snapshot.forEach((snap) => {
						const messageData = snap.val();
						const messageRef = ref(
							db,
							`${currentCompany?.id}/chats/${id}/messages/${snap.key}`
						);

						if (
							!messageData.viewed &&
							messageData.senderId !== firebaseAuth.currentUser?.uid
						) {
							messagesData.push({ ...messageData, viewed: true });
							update(messageRef, { viewed: true });
						} else {
							messagesData.push(messageData);
						}
					});

					setMessages(messagesData.reverse());
					setLoadingMessages(false);
				},
				(err) => {
					console.log(err);
				}
			);

			//Getting collaborator info
			onValue(
				collabRef,
				(snapShot) => {
					const collab: CollaboratorFirebase = snapShot.val();
					collab.id = id;
					setCollab(collab);
				},
				(err) => {
					console.log(err);
				}
			);

			//Getting chat info to put an responsible operator
			onValue(
				chatRef,
				(snapShot) => {
					chatValues = snapShot.val();
					if (
						chatValues.responsable_operator &&
						chatValues.responsable_operator === firebaseAuth.currentUser?.uid!
					) {
						operatorResponsible.current = true;
					} else if (
						chatValues.responsable_operator &&
						operatorResponsible.current &&
						chatValues.responsable_operator !== firebaseAuth.currentUser?.uid!
					) {
						history.push('/home/chat');
						toast.error('O chat foi delegado para outro operador!', {
							toastId: 'invalid_chat',
						});
					}
				},
				(err) => {
					console.log(err);
				}
			);

			if (chatValues.messages && !chatValues.responsable_operator) {
				await setResponsableOperator(chatRef);
				operatorResponsible.current = true;
			}

			await setStatus('online');
		} catch (err) {
			console.log(err);
			toast.error(
				'Ocorreu um erro ao entrar no chat. Por favor, tente novamente.'
			);
		}
	}

	function handleFileUpload(file: File) {
		const storage = storageRef(
			firebaseStorage,
			`${currentCompany?.id}/${chatId}/attachments/${file.name}`
		);

		const uploadTask = uploadBytesResumable(storage, file);

		return uploadTask;
	}

	async function handleSubmitMessage(dataMessage: MessageProps) {
		reset();
		let checkNotification = false;

		try {
			if (attachments && attachments.length > 0) {
				setUploadingAttachments(true);
				await Promise.all(
					attachments.map(async (file) => handleFileUpload(file))
				).then(async (uploads) => {
					uploads.forEach(async (upload, index) => {
						await getDownloadURL(upload.ref).then((url) => {
							let fileType;

							if (attachments[index].type.includes('document')) {
								fileType = 'document';
							} else if (attachments[index].type.includes('image')) {
								fileType = 'image';
							} else if (attachments[index].type.includes('sheet')) {
								fileType = 'sheet';
							} else if (attachments[index].type.includes('pdf')) {
								fileType = 'pdf';
							}

							push(ref(db, `${currentCompany?.id}/chats/${chatId}/messages`), {
								attachmentPath: url,
								attachmentType: fileType,
								attachmentName: attachments[index].name,
								receiverId: currentCompany?.id,
								senderId: firebaseAuth.currentUser?.uid!,
								senderName: user.name,
								senderAvatar: user.avatar_url ? user.avatar_url : '',
								timestamp: serverTimestamp(),
							});
						});
					});
				});

				checkNotification = true;
				setUploadingAttachments(false);
				setAttachments([]);
			}

			if (dataMessage.message) {
				push(ref(db, `${currentCompany?.id}/chats/${chatId}/messages`), {
					viewed: false,
					message: dataMessage.message,
					receiverId: currentCompany?.id,
					senderId: firebaseAuth.currentUser?.uid!,
					senderName: user.name,
					senderAvatar: user.avatar_url ? user.avatar_url : '',
					timestamp: serverTimestamp(),
				});
				checkNotification = true;
			}

			// send notification to collaborator only once after the first reply from the OPERATOR
			if (shouldSendNotificationToCollab && checkNotification)
				sendNotificationToCollaboratorQuery.mutate();
		} catch (err) {
			console.log(err);
			toast.error(
				'Ocorreu um erro ao enviar a mensagem. Por favor, tente novamente.'
			);
		}

		//Not working without the timeout I dont know why
		setTimeout(() => {
			setFocus('message');
		}, 400);
	}

	async function closeChat(id: string) {
		try {
			const chatRef = ref(db, `${currentCompany?.id}/chats/${id}`);
			await update(chatRef, {
				opened: false,
			});

			const responsableRef = ref(
				db,
				`${currentCompany?.id}/chats/${id}/responsable_operator`
			);
			await remove(responsableRef);

			setMessages([]);
			// setChatId('')
			setCollab({} as CollabInfo);

			history.push('/home/chat');
			sendCloseChatNotificationToCollaboratorQuery.mutate();
		} catch (err) {
			console.log(err);
			toast.error(
				'Ocorreu um erro ao tentar fechar o chat. Por favor, tente novamente.'
			);
		}
	}

	//Set operator's status when entering chat
	async function setStatus(status: string) {
		try {
			const operatorRef = ref(
				db,
				`${currentCompany?.id}/operators/${firebaseAuth.currentUser?.uid!}`
			);

			let operator: OperatorFirebase = {} as OperatorFirebase;
			await get(operatorRef)
				.then((snapShot) => {
					operator = snapShot.val();
				})
				.catch((err) => {
					console.log(err);
				});

			if (operator.status && operator.status !== 'online') {
				await update(operatorRef, {
					status: status,
				});
			}
		} catch (err) {
			console.log(err);
			toast.error(
				'Ocorreu um erro ao mudar status do operador. Por favor, tente novamente.'
			);
		}
	}

	async function setResponsableOperator(chatRef: DatabaseReference) {
		try {
			await update(chatRef, {
				responsable_operator: firebaseAuth.currentUser?.uid!,
			});
		} catch (err) {
			console.log(err);
		}
	}

	//Open attachments related functions
	function handleOpenImg(imgId: string) {
		setImageToOpen(imgId);
	}

	function handleCloseImg() {
		setImageToOpen(null);
	}

	function getConfirmationAndCloseChat(id: string) {
		openOptionsDialog(
			`Tem certeza que deseja concluir e fechar o chat?`,
			'Sim',
			() => {
				closeChat(id);
			},
			'Não',
			() => {
				return;
			}
		);
	}

	function isDifferentDayThanLastMessage(
		lastMessageTimestamp: Date,
		currentMessageTimestamp: Date
	) {
		return (
			lastMessageTimestamp.toLocaleDateString() !==
			currentMessageTimestamp.toLocaleDateString()
		);
	}

	function onEnterPressed(e: any) {
		if (e.code === 'Enter' && e.shiftKey === false) {
			formRef.current?.requestSubmit();
		}
	}

	function parseDateToDayInTheWeek(dateToParse: Date) {
		const daysOfWeek = [
			'Domingo',
			'Segunda',
			'Terça',
			'Quarta',
			'Quinta',
			'Sexta',
			'Sábado',
		];
		const todayDate = new Date();
		const sevenDaysAgo = todayDate.getDate() - 7;

		if (todayDate.getDate() === dateToParse.getDate()) {
			return 'Hoje';
		} else if (
			sevenDaysAgo < dateToParse.getDate() &&
			dateToParse.getDate() < todayDate.getDate()
		) {
			return daysOfWeek[dateToParse.getDay()];
		} else {
			return dateToParse.toLocaleDateString();
		}
	}

	function removeAttachment(index: number) {
		const newAttachs = [...attachments!];
		newAttachs?.splice(index, 1);
		setAttachments(newAttachs);
	}

	function handlePaste(event: React.ClipboardEvent) {
		const acceptedFiles = /(.jpg|.jpeg|.png|.docx|.xls|.xlsx|.csv|.pdf)$/;
		const clipboardData = event.clipboardData;

		const files: File[] = [];
		Array.from(clipboardData.files).forEach((file: any) => {
			if (acceptedFiles.test(file.name)) {
				files.push(file);
			}
		});

		setAttachments((prevState) => [...prevState, ...files]);
	}

	useEffect(() => {
		return () => {
			off(refToUnsubscribe.current!);
			off(messagesRef.current!);
		};
	}, []);

	if (loadingMessages) {
		return (
			<S.Container>
				<S.HeaderButtons>
					<S.NavButton to={'/home/chat'}>
						<BiArrowBack />
						Retornar
					</S.NavButton>

					<S.CloseChatButton
						onClick={(e) => {
							e.preventDefault();
							getConfirmationAndCloseChat(chatId);
						}}
					>
						<AiOutlineCheck />
						Concluir e fechar chat
					</S.CloseChatButton>
				</S.HeaderButtons>

				<Loader />
			</S.Container>
		);
	}

	return (
		<S.Container>
			<S.HeaderButtons>
				<S.NavButton to={'/home/chat'}>
					<BiArrowBack />
					Retornar
				</S.NavButton>

				<S.CloseChatButton
					onClick={(e) => {
						e.preventDefault();
						getConfirmationAndCloseChat(chatId);
					}}
				>
					<AiOutlineCheck />
					Concluir e fechar chat
				</S.CloseChatButton>
			</S.HeaderButtons>

			<S.ChatContainer onDragEnter={() => setFileDragActive(true)}>
				<S.ChatHeader>
					<S.AvatarHeaderContainer>
						<S.Avatar
							src={collab?.picture_url ? collab.picture_url : avatarImg}
							alt='Avatar'
						/>
					</S.AvatarHeaderContainer>

					<S.CollabInfoContainer>
						<p>{collab?.name}</p>
						<S.CollabDetail>{collab?.email}</S.CollabDetail>
						<S.CollabDetail>{collab?.section}</S.CollabDetail>
					</S.CollabInfoContainer>

					<S.StatusContainer>
						{collab?.online_status === 'online' ? (
							<S.StatusFlag>
								<p>Online</p>
								<S.OnlineIcon />
							</S.StatusFlag>
						) : (
							<S.StatusFlag>
								<p>Offline</p>
								<S.OfflineIcon />
							</S.StatusFlag>
						)}
					</S.StatusContainer>
				</S.ChatHeader>

				<S.MessagesContainer>
					{messages.map((message, index) => {
						//First in the array = last message sent
						//Last in array = first message sent
						let currentMessageTimestamp = new Date(message.timestamp);
						let lastMessageTimestamp;
						if (index + 1 >= messages.length) {
							//The last in array is the first message, as it doesnt have a last message at this point
							//I put a timestamp from the past to compare with later and get the day it was sent
							lastMessageTimestamp = new Date(1640111560000);
						} else {
							lastMessageTimestamp = new Date(messages[index + 1].timestamp);
						}

						const sending = message.senderId !== collab?.id;
						const automatedMsg = message.senderId === currentCompany?.id;

						let avatar;

						if (sending && message.senderAvatar) {
							avatar = message.senderAvatar;
						} else if (!sending && collab?.picture_url) {
							avatar = collab.picture_url;
						} else {
							avatar = avatarImg;
						}

						if (automatedMsg) {
							return (
								<React.Fragment key={`${message.timestamp}${index}`}>
									<S.AutomatedMessage>
										<S.TextContainer>
											<S.MessageText>{message.message}</S.MessageText>
											<S.MessageTimeSent>
												{currentMessageTimestamp
													.toLocaleTimeString()
													.substring(0, 5)}
											</S.MessageTimeSent>
										</S.TextContainer>
									</S.AutomatedMessage>
									{isDifferentDayThanLastMessage(
										lastMessageTimestamp,
										currentMessageTimestamp
									) && (
										<S.DifferentDaySeparator>
											<p>{parseDateToDayInTheWeek(currentMessageTimestamp)}</p>
										</S.DifferentDaySeparator>
									)}
								</React.Fragment>
							);
						}

						if (message.attachmentType) {
							const attachmentType = message.attachmentType;
							imgs.push(message.attachmentPath);

							return (
								<React.Fragment key={`${message.timestamp}${index}`}>
									<S.Message sending={sending}>
										<S.Avatar
											src={avatar}
											alt='Avatar'
											data-rh={sending ? `${message.senderName}` : null}
										/>
										<S.TextContainer>
											{attachmentType === 'image' ? (
												<>
													<S.ImageMessage
														src={message.attachmentPath}
														onClick={(e) => {
															e.preventDefault();
															handleOpenImg(message.attachmentPath);
														}}
													/>
													<ImagesSlider
														initialImg={imageToOpen}
														closeSlide={handleCloseImg}
														images={imgs}
													/>
												</>
											) : (
												<>
													<a
														href={message.attachmentPath}
														rel='noopener noreferrer'
														target='_blank'
														download
													>
														{message.attachmentName}
													</a>
												</>
											)}
											<S.MessageTimeSent>
												{currentMessageTimestamp
													.toLocaleTimeString()
													.substring(0, 5)}
											</S.MessageTimeSent>
										</S.TextContainer>
									</S.Message>
									{isDifferentDayThanLastMessage(
										lastMessageTimestamp,
										currentMessageTimestamp
									) && (
										<S.DifferentDaySeparator>
											<p>{currentMessageTimestamp.toLocaleDateString()}</p>
										</S.DifferentDaySeparator>
									)}
								</React.Fragment>
							);
						}

						return (
							<React.Fragment key={`${message.timestamp}${index}`}>
								<S.Message sending={sending}>
									<S.Avatar
										src={avatar}
										alt='Avatar'
										data-rh={sending ? `${message.senderName}` : null}
									/>
									<S.TextContainer>
										<S.MessageText>{message.message}</S.MessageText>
										<S.MessageTimeSent>
											{currentMessageTimestamp
												.toLocaleTimeString()
												.substring(0, 5)}
										</S.MessageTimeSent>
									</S.TextContainer>
								</S.Message>
								{isDifferentDayThanLastMessage(
									lastMessageTimestamp,
									currentMessageTimestamp
								) && (
									<S.DifferentDaySeparator>
										<p>{parseDateToDayInTheWeek(currentMessageTimestamp)}</p>
									</S.DifferentDaySeparator>
								)}
							</React.Fragment>
						);
					})}
				</S.MessagesContainer>

				<S.FormContainer>
					<S.FormMessage
						onSubmit={handleSubmit(handleSubmitMessage)}
						ref={formRef}
					>
						<S.ImgsContainer>
							{attachments &&
								attachments?.length > 0 &&
								attachments.map((attachment, index) => {
									function getIcon() {
										if (attachment.type.includes('.document'))
											return <GrDocumentWord />;
										if (attachment.type.includes('.sheet'))
											return <GrDocumentExcel />;
										if (attachment.type.includes('pdf'))
											return <GrDocumentPdf />;
										if (attachment.type.includes('image'))
											return (
												<S.ImagePreview src={URL.createObjectURL(attachment)} />
											);
									}

									return (
										<S.AttachmentIcon
											key={index}
											data-rh={`${attachment.name}`}
										>
											{uploadingAttachments ? (
												<Loader color='white' />
											) : (
												<>
													<S.CloseIcon onClick={() => removeAttachment(index)}>
														<AiFillCloseCircle />
													</S.CloseIcon>

													{getIcon()}
												</>
											)}
										</S.AttachmentIcon>
									);
								})}
						</S.ImgsContainer>

						<S.InputsContainer>
							<S.InputMessage
								onPaste={handlePaste}
								{...register('message')}
								placeholder={'Mensagem'}
								onKeyPress={onEnterPressed}
							/>

							<S.AttachmentsLabel htmlFor='attachments' tabIndex={0}>
								<IoDocumentAttachOutline />
								<S.InputAttachments
									type='file'
									accept='.jpg, .jpeg, .png, .docx, .xls, .xlsx, .csv, .pdf'
									name='attachments'
									id='attachments'
									multiple
									onChange={(e: any) =>
										setAttachments((prevState) => [
											...prevState,
											...e.target.files,
										])
									}
								/>
							</S.AttachmentsLabel>

							<S.SendMessageButton type='submit'>
								<IoMdSend />
							</S.SendMessageButton>
						</S.InputsContainer>
					</S.FormMessage>
				</S.FormContainer>

				{fileDragActive && (
					<Dropzone
						accept={'.jpg, .jpeg, .png, .docx, .xls, .xlsx, .csv, .pdf'}
						onDropAccepted={(acceptedFiles) =>
							setAttachments((prevState) => [...prevState, ...acceptedFiles])
						}
						onDrop={() => setFileDragActive(false)}
						onDropRejected={() => toast.error('Arquivo(s) não suportado(s).')}
						onDragEnter={() => setFileDragActive(true)}
						onDragLeave={() => setFileDragActive(false)}
					>
						{({ getRootProps, getInputProps }) => (
							<div {...getRootProps()}>
								<S.DragContainer>Solte os arquivos aqui</S.DragContainer>
								<input {...getInputProps()} />
							</div>
						)}
					</Dropzone>
				)}
			</S.ChatContainer>
		</S.Container>
	);
};

export default ChatPage;
