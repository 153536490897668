import { RiDeleteBin5Fill } from 'react-icons/ri';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Collaborator } from '../../../../@types';
import { CollabsSelector } from '../../../../components/CollabsSelector';
import { FilteredCollabsSelector } from '../../../../components/CollabsSelector/FilteredCollabsSelector';
import Loader from '../../../../components/Loader';
import PageTitle from '../../../../components/PageTitle';
import UserCard from '../../../../components/UserCard';
import { useAuth } from '../../../../hooks/useAuth';
import { showErrorMessage } from '../../../../utils/ErrorHandler';
import { cpfMask } from '../../../../utils/masks';
import { Option as FormStyleOption } from '../../../../components/Form/FormStyles';
import * as S from './styles';
import {
	getBenefitsGroup,
	manageAllBenefitsGroupCollaborators,
	manageBenefitsGroupCollaborators,
} from '../../../../services/queries/BenefitsGroups';
import { ManageAllPartnerCollaboratorsAction } from '../../../../services/queries/Partners';
import { EmptyContent } from '../../../../components/EmptyContent';

export function ManageBenefitsGroupCollaborators() {
	const { benefits_group_id } = useParams<{ benefits_group_id: string }>();
	const queryClient = useQueryClient();
	const { currentCompany } = useAuth();

	const getBenefitsGroupCollaboratorsQuery = useQuery(
		['getBenefitsGroup', benefits_group_id, currentCompany?.id],
		() => getBenefitsGroup(currentCompany?.id!, benefits_group_id),
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível buscar os colaboradores do grupo de benefícios. '
				);
			},
		}
	);

	const managePartnerCollaboratorsMutation = useMutation(
		({ collaborators }: { collaborators: string[] }) =>
			manageBenefitsGroupCollaborators(
				currentCompany?.id!,
				benefits_group_id,
				collaborators
			),
		{
			onError: (err) => {
				console.log(err);
				showErrorMessage(
					err as Error,
					'Não foi possível atualizar os colaboradores do grupo de benefícios. '
				);
			},
			onSuccess: onCollaboratorsListUpdated,
		}
	);

	const manageAllCollaboratorsMutation = useMutation(
		({ action }: { action: ManageAllPartnerCollaboratorsAction }) =>
			manageAllBenefitsGroupCollaborators(
				currentCompany?.id!,
				benefits_group_id,
				action
			),
		{
			onError: (err) => {
				console.log(err);
				showErrorMessage(
					err as Error,
					'Não foi possível atualizar os colaboradores do grupo de benefícios. '
				);
			},
			onSuccess: onCollaboratorsListUpdated,
		}
	);

	function onCollaboratorsListUpdated() {
		toast.info('Colaboradores atualizados com sucesso!');
		queryClient.resetQueries([
			'getBenefitsGroup',
			benefits_group_id,
			currentCompany?.id,
		]);
	}

	function getCollaboratorsToAddIds(collabsToAdd: Collaborator[]) {
		const collabsIds = [
			...collabsToAdd.map((c) => {
				return c.id!;
			}),
			...getBenefitsGroupCollaboratorsQuery.data!.users.map((c) => {
				return c.id!;
			}),
		];

		return collabsIds;
	}

	function removeCollaboratorsById(
		collaborators: Collaborator[],
		collabsToRemove: Collaborator[]
	) {
		collabsToRemove.forEach((collabToRemove) => {
			const indexToRemove = collaborators!.findIndex(
				(collab) => collab.id === collabToRemove.id
			);

			if (indexToRemove > -1) {
				collaborators!.splice(indexToRemove, 1);
			}
		});

		return collaborators;
	}

	function handleManageCollaborators(collaboratorsToAdd: Collaborator[]) {
		const collaborators = getCollaboratorsToAddIds(collaboratorsToAdd);

		managePartnerCollaboratorsMutation.mutate({
			collaborators,
		});
	}

	async function handleRemoveCollaborators(collabsToRemove: Collaborator[]) {
		let collabs = getBenefitsGroupCollaboratorsQuery.data!.users;
		collabs = removeCollaboratorsById(collabs, collabsToRemove);
		const collabsIds = collabs.map((c) => c.id!);

		managePartnerCollaboratorsMutation.mutate({ collaborators: collabsIds });
	}

	async function handleAddAndRemoveCollabs(
		collabsToAdd: Collaborator[],
		collabsToRemove: Collaborator[]
	) {
		let collabsIds = getCollaboratorsToAddIds(collabsToAdd);

		collabsToRemove.forEach((collabToRemove) => {
			const indexToRemove = collabsIds!.findIndex(
				(collabsIds) => collabsIds === collabToRemove.id
			);

			if (indexToRemove > -1) {
				collabsIds!.splice(indexToRemove, 1);
			}
		});

		managePartnerCollaboratorsMutation.mutate({ collaborators: collabsIds });
	}

	function handleManageAllCollaborators(
		action: ManageAllPartnerCollaboratorsAction
	) {
		manageAllCollaboratorsMutation.mutate({ action });
	}

	if (
		!getBenefitsGroupCollaboratorsQuery.data ||
		getBenefitsGroupCollaboratorsQuery.isLoading ||
		managePartnerCollaboratorsMutation.isLoading ||
		manageAllCollaboratorsMutation.isLoading
	)
		return (
			<S.Container>
				<PageTitle title={`Gerenciar Colaboradores`} />
				<Loader />
			</S.Container>
		);

	return (
		<S.Container>
			<PageTitle
				title={`Gerenciar Colaboradores - ${getBenefitsGroupCollaboratorsQuery.data.title}`}
			/>

			<S.CollaboratorsListTitle>
				Colaboradores ({getBenefitsGroupCollaboratorsQuery.data.total_users})
			</S.CollaboratorsListTitle>

			<S.CollaboratorsList>
				{!getBenefitsGroupCollaboratorsQuery.data!.users.length ? (
					<EmptyContent text='Nenhum colaborador adicionado ao grupo' />
				) : (
					getBenefitsGroupCollaboratorsQuery.data?.users.map((collaborator) => (
						<S.CollaboratorCard key={collaborator.id}>
							<div>
								<UserCard
									name={`${collaborator.first_name} ${collaborator.last_name}`}
									nameWidth='100%'
									avatar_url={collaborator.avatar ?? undefined}
									bottomInfo={cpfMask(collaborator.cpf)}
								/>
							</div>

							<S.RemoveCollaboratorBtn
								data-rh='Remover colaborador'
								data-testid={`remove-${collaborator.id}`}
								onClick={() => {
									handleRemoveCollaborators([collaborator as Collaborator]);
								}}
							>
								<RiDeleteBin5Fill />
							</S.RemoveCollaboratorBtn>
						</S.CollaboratorCard>
					))
				)}
			</S.CollaboratorsList>

			<S.BottomOptionsContainer>
				<CollabsSelector
					alreadyAddedCollabs={
						getBenefitsGroupCollaboratorsQuery.data?.users as Collaborator[]
					}
					onAddNewCollaborators={handleManageCollaborators}
					onAddAndRemoveCollabs={(toAdd, toRemove) =>
						handleAddAndRemoveCollabs(toAdd, toRemove)
					}
					onAddCollaboratorsToRemove={handleRemoveCollaborators}
					allowRemoveCollaborators
					buttonText='Adicionar ou Remover Colaborador'
				/>

				<FilteredCollabsSelector
					alreadyAddedCollabs={
						getBenefitsGroupCollaboratorsQuery.data?.users as Collaborator[]
					}
					onAddNewCollaborators={handleManageCollaborators}
				/>

				<S.AddAllCollaboratorsBtn
					onClick={() => handleManageAllCollaborators('add')}
				>
					Adicionar todos os colaboradores da empresa
				</S.AddAllCollaboratorsBtn>

				<FormStyleOption
					cancel
					onClick={() => handleManageAllCollaborators('remove')}
					disabled={getBenefitsGroupCollaboratorsQuery.data.users.length === 0}
				>
					Remover todos os colaboradores
				</FormStyleOption>
			</S.BottomOptionsContainer>
		</S.Container>
	);
}
