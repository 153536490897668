import styled from 'styled-components';

export const HorizontalDivider = styled.div`
	width: 100%;
	height: 0.1rem;
	background: var(--light-gray);
`;

export const VerticalDivider = styled.div`
	width: 0.1rem;
	height: 100%;
	background: var(--light-gray);
`;
