import { S3File } from '../../../../../@types/CorporateExpenses/Transaction';

export interface UploadedInvoice {
	name: string;
	parsedName: string;
	file: string | S3File;
}

interface InvoiceState {
	invoices: string[];
	invoiceView: UploadedInvoice | undefined;
	invoiceDelete: UploadedInvoice | undefined;
	uploadingFiles: File[];
	uploadingError: string | undefined;
}

export function InvoicesReducer(
	state: InvoiceState,
	action: Partial<InvoiceState>
) {
	return {
		...state,
		...action,
	};
}
