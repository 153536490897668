import { ChangeEvent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AddressBody, DeliveryAddressTypes } from '../../../../@types';
import Modal from '../../../../components/Modal';
import { useDialogModal } from '../../../../hooks/useDialogModal';
import { CustomAddressModal } from './CustomAddressModal';
import { Input, Field, Label } from '../../../../components/Form/FormStyles';
import * as S from './styles';
import {
	CreateCardBatchUnamedBody,
	createCardsBatchUnamed,
} from '../../../../services/queries/CardsBatchs';
import { useAuth } from '../../../../hooks/useAuth';
import { useMutation, useQueryClient } from 'react-query';
import { showErrorMessage } from '../../../../utils/ErrorHandler';

export function CreateCardBatchModal() {
	const { currentCompany } = useAuth();
	const queryClient = useQueryClient();
	const history = useHistory();
	const [isOpen, setOpen] = useState(false);
	const { openOptionsDialog, openConfirmDialog } = useDialogModal();

	const [addressType, setAddressType] =
		useState<DeliveryAddressTypes>('collaborators');
	const [cardsAmount, setCardsAmount] = useState<number>(1);
	const cardAmountMax = 100000;

	const [customAddress, setCustomAddress] = useState({} as AddressBody);

	const createCardsBatchQuery = useMutation(
		(body: CreateCardBatchUnamedBody) =>
			createCardsBatchUnamed(body, currentCompany!.id!),
		{
			onSuccess: (data) => {
				toast.info(
					'Solicitação de envio de remessa de cartões enviada com sucesso!'
				);
				queryClient.invalidateQueries('pendingCardsBatchesList');

				if (data.max_qtde_exceeded) {
					openConfirmDialog(
						`A solicitação de remessa passará por uma análise pois excedeu o limite de cartões por pedido para a sua empresa.`,
						'Entendido',
						() => {}
					);
				}
			},
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao tentar enviar a remessa de cartões. '
				);
			},
		}
	);

	function closeModal() {
		setOpen(false);

		// reset
		setAddressType('collaborators');
		setCustomAddress({} as AddressBody);
		setCardsAmount(1);
	}

	function handleChangeCardsAmount(e: ChangeEvent<HTMLInputElement>) {
		const amount = Number(e.currentTarget.value);
		if (amount <= cardAmountMax) setCardsAmount(amount);
	}

	// going from 1st step to 2nd
	function handleSubmit() {
		if (addressType === 'collaborators') {
			history.push('/home/cards/create');
			return;
		}
		if (addressType === 'custom' && !customAddress.address) {
			toast.error('Defina o endereço customizado para o envio');
			return;
		}
		if (!cardsAmount || cardsAmount <= 0) {
			toast.error('Defina a quantidade de cartões para o envio');
			return;
		}

		closeModal();

		openOptionsDialog(
			`Tem certeza que deseja enviar a remessa de ${cardsAmount} cartões para o ${parseAddressTypeToString()}?`,
			'Confirmar',
			() =>
				createCardsBatchQuery.mutate({
					card_qtde: cardsAmount,
					delivery_address_type: addressType,
					custom_address: customAddress,
				}),
			'Cancelar',
			() => {}
		);
	}

	function parseAddressTypeToString() {
		if (addressType === 'company') return 'endereço da empresa';
		return 'endereço customizado';
	}

	return (
		<>
			<S.MainBtn onClick={() => setOpen(true)}>Nova solicitação</S.MainBtn>

			<Modal isOpen={isOpen} onRequestClose={closeModal} enableClose>
				<S.Container>
					<S.Title>Definição de endereço para envios</S.Title>
					<S.OptionsContainer data-testid='options-container'>
						<S.Option
							selected={addressType === 'collaborators'}
							onClick={() => setAddressType('collaborators')}
						>
							<S.OptionHeader>Endereços dos colaboradores</S.OptionHeader>
							<span>
								Cada cartão será entregue no endereço dos colaboradores
								selecionados.
							</span>
						</S.Option>

						<S.Option
							selected={addressType === 'company'}
							onClick={() => setAddressType('company')}
						>
							<S.OptionHeader>Endereço da empresa</S.OptionHeader>
							<span>
								Todo o lote será enviado para o endereço cadastrado da empresa.
							</span>
						</S.Option>

						<S.Option
							selected={addressType === 'custom'}
							onClick={() => setAddressType('custom')}
						>
							<S.OptionHeader>
								Endereço customizado
								{addressType === 'custom' && (
									<CustomAddressModal
										address={customAddress}
										onSubmit={(addrss) => {
											setCustomAddress(addrss);
										}}
									/>
								)}
							</S.OptionHeader>
							<span>
								Todo o lote será enviado para um endereço a ser definido.
							</span>
						</S.Option>
					</S.OptionsContainer>

					{addressType !== 'collaborators' && (
						<Field>
							<Label>Quantidade de cartões</Label>
							<Input
								type='number'
								style={{ width: 'fit-content' }}
								value={cardsAmount}
								onChange={handleChangeCardsAmount}
								required
								min={1}
								max={cardAmountMax}
							/>
						</Field>
					)}

					<S.MainBtn onClick={handleSubmit}>Enviar</S.MainBtn>
				</S.Container>
			</Modal>
		</>
	);
}
