import { createContext, ReactNode, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { Company, Shareholder } from '../@types';
import { useAuth } from '../hooks/useAuth';
import {
	getCompany,
	handleUpdateCompanyAvatarQuery,
	updateCompany,
} from '../services/queries/Companies';
import {
	addShareholderToCompany,
	deleteShareholderQuery,
	UpdateShareholderParams,
	updateShareholderQuery,
} from '../services/queries/Shareholders';
import {
	parseInputToDate,
	parseMaskedCEPToRaw,
	parseMaskedCPFToRaw,
	parseMaskedPhoneToRaw,
} from '../utils/masks';
import { showErrorMessage } from '../utils/ErrorHandler';

type Props = {
	children: ReactNode;
};

export interface ShareholderFormData
	extends Omit<Shareholder, 'first_name' | 'last_name'> {
	full_name: string;
}

export type CompanyFormData = {
	loading: boolean;
	company?: Company;
	handleUpdateCompany: (company: Company) => Promise<void>;
	handleUpdateAvatar: (logo: File) => Promise<void>;
	addShareholder: (shareholder: ShareholderFormData) => Promise<boolean>;
	updateShareholder: (shareholder: ShareholderFormData) => Promise<boolean>;
	deleteShareholder: (id: string) => Promise<boolean>;
};
export const CompanyFormContext = createContext({} as CompanyFormData);

interface UpdateCompanyFields {
	corporate_name: string;
	name: string;
	email: string;
	financial_sector_email?: string;
	hr_sector_email?: string;
	email_domain?: string;
	cep: string;
	address: string;
	number: string;
	complement?: string;
	reference?: string;
	district: string;
	city: string;
	uf: string;
	first_phone: string;
	second_phone?: string;
	website?: string;
	founding_date: string;
	business_type: string;
	main_activity: string;
}

export function CompanyFormProvider({ children }: Props) {
	const { currentCompany } = useAuth();
	const [company, setCompany] = useState<Company>();

	const addShareholderQuery = useMutation((shareholder: object) =>
		addShareholderToCompany(shareholder, company!.id)
	);

	const handleUpdateShareholderQuery = useMutation(
		({ shareholderToUpdate, shareholderId }: UpdateShareholderParams) => {
			return updateShareholderQuery(
				shareholderToUpdate,
				shareholderId,
				company!.id
			);
		}
	);

	const handleDeleteShareholderQuery = useMutation((shareholderId: string) =>
		deleteShareholderQuery(shareholderId, company!.id)
	);

	useQuery<Company, Error>(
		['fetchCompany', currentCompany?.id],
		() => {
			return getCompany(currentCompany?.id);
		},
		{
			onSuccess: (data) => {
				setCompany({ ...data });
			},
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao tentar buscar a empresa. '
				);
			},
			refetchOnWindowFocus: false,
		}
	);

	const updateCompanyAvatarQuery = useMutation(
		(avatar: FormData) => {
			return handleUpdateCompanyAvatarQuery(avatar, company!.id);
		},
		{
			onSuccess: (data) => {
				setCompany({ ...company, ...data });
			},
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível atualizar o avatar da empresa. '
				);
			},
		}
	);

	const updateCompanyQuery = useMutation(
		(updatedCompany: object) => {
			return updateCompany(updatedCompany, company!.id);
		},
		{
			onSuccess: (data) => {
				setCompany({ ...company, ...data });
			},
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível atualizar as informações da empresa. '
				);
			},
		}
	);

	function extractEmailDomain(email: string) {
		const email_domain = email.substring(email.indexOf('@'), email.length);
		return email_domain;
	}

	async function handleUpdateCompany(companyToUpdate: Company) {
		const {
			corporate_name,
			name,
			email,
			financial_sector_email,
			hr_sector_email,
			email_domain,
			cep,
			address,
			number,
			complement,
			reference,
			district,
			city,
			uf,
			first_phone,
			second_phone,
			website,
			founding_date,
			business_type,
			main_activity,
		}: UpdateCompanyFields = {
			...companyToUpdate,
			email_domain: extractEmailDomain(companyToUpdate.email),
			first_phone: parseMaskedPhoneToRaw(companyToUpdate.first_phone),
			second_phone: parseMaskedPhoneToRaw(companyToUpdate.second_phone!),
			cep: parseMaskedCEPToRaw(companyToUpdate.cep),
			founding_date: parseInputToDate(companyToUpdate.founding_date),
		};

		const updatedCompany = {
			corporate_name,
			name,
			email,
			financial_sector_email,
			hr_sector_email,
			email_domain,
			cep,
			address,
			number,
			complement,
			reference,
			district,
			city,
			uf,
			first_phone,
			second_phone,
			website,
			founding_date,
			business_type,
			main_activity,
		};

		await updateCompanyQuery.mutateAsync(updatedCompany);
	}

	async function handleUpdateAvatar(logo: File) {
		const companyAvatarFormData = new FormData();
		companyAvatarFormData.append('avatar', logo);

		await updateCompanyAvatarQuery.mutateAsync(companyAvatarFormData);
	}

	/* SHAREHOLDERS FUNCTIONS */

	async function addShareholder(newShareholder: ShareholderFormData) {
		const {
			cpf,
			full_name,
			type,
			mother_name,
			birth_date,
			email,
			phone_number,
			cep,
			address,
			number,
			complement,
			district,
			city,
			uf,
		} = {
			...newShareholder,
			birth_date: parseInputToDate(newShareholder.birth_date),
			phone_number: parseMaskedPhoneToRaw(newShareholder.phone_number),
			cep: parseMaskedCEPToRaw(newShareholder.cep),
			cpf: parseMaskedCPFToRaw(newShareholder.cpf),
		};

		let [first_name, ...last_name_array] = full_name.split(' ');
		const last_name = last_name_array.join(' ');

		if (!first_name.trim() || !last_name.trim()) {
			toast.error('Insira o nome completo.');
			return false;
		}

		const shareholder = {
			cpf,
			first_name,
			last_name,
			type,
			mother_name,
			birth_date,
			email,
			phone_number,
			cep,
			address,
			number,
			complement,
			district,
			city,
			uf,
		};

		try {
			const data = await addShareholderQuery.mutateAsync(shareholder);

			// const updatedShareholdersList = [...company!.shareholders, data]
			setCompany({ ...company!, shareholders: data.shareholders });
			toast.info(`O sócio ${full_name} foi criado com sucesso!`);
			return true;
		} catch (err) {
			showErrorMessage(err as Error, 'Não foi possível adicionar novo sócio. ');
			return false;
		}
	}

	async function updateShareholder(shareholder: ShareholderFormData) {
		const {
			cpf,
			full_name,
			type,
			mother_name,
			birth_date,
			email,
			phone_number,
			cep,
			address,
			number,
			complement,
			district,
			city,
			uf,
		} = {
			...shareholder,
			birth_date: parseInputToDate(shareholder.birth_date),
			phone_number: parseMaskedPhoneToRaw(shareholder.phone_number),
			cep: parseMaskedCEPToRaw(shareholder.cep),
			cpf: parseMaskedCPFToRaw(shareholder.cpf),
		};

		let [first_name, ...last_name_array] = full_name.split(' ');
		const last_name = last_name_array.join(' ');

		if (!first_name.trim() || !last_name.trim()) {
			toast.error('Insira o nome completo.');
			return false;
		}

		const shareholderToUpdate = {
			cpf,
			first_name,
			last_name,
			type,
			mother_name,
			birth_date,
			email,
			phone_number,
			cep,
			address,
			number,
			complement,
			district,
			city,
			uf,
		};

		try {
			const data = await handleUpdateShareholderQuery.mutateAsync({
				shareholderToUpdate,
				shareholderId: shareholder.id!,
			});

			const updatedShareholdersList = company!.shareholders.map((s) => {
				if (s.id === shareholder.id) {
					return data;
				}
				return s;
			});
			setCompany({ ...company!, shareholders: updatedShareholdersList });
			toast.info(`Sócio ${full_name} atualizado com sucesso!`);
			return true;
		} catch (err) {
			showErrorMessage(
				err as Error,
				'Não foi possível atualizar as informações de um sócio. '
			);
			return false;
		}
	}

	async function deleteShareholder(id: string) {
		try {
			await handleDeleteShareholderQuery.mutateAsync(id);

			const updatedShareholdersList = company!.shareholders.filter(
				(s) => s.id !== id
			);
			setCompany({ ...company!, shareholders: updatedShareholdersList });
			toast.info(`Sócio removido com sucesso!`);
			return true;
		} catch (err) {
			showErrorMessage(err as Error, 'Não foi possível remover o sócio. ');
			return false;
		}
	}

	return (
		<CompanyFormContext.Provider
			value={{
				loading:
					handleDeleteShareholderQuery.isLoading ||
					updateCompanyQuery.isLoading ||
					handleUpdateShareholderQuery.isLoading ||
					addShareholderQuery.isLoading ||
					updateCompanyAvatarQuery.isLoading ||
					!company,
				company,
				handleUpdateCompany,
				handleUpdateAvatar,
				addShareholder,
				updateShareholder,
				deleteShareholder,
			}}
		>
			{children}
		</CompanyFormContext.Provider>
	);
}
