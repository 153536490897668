import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { OptionsButton } from '../../../../components/Button';

export const Container = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	padding: 4rem 7rem 2rem 7rem;
`;

export const Content = styled.div`
	display: flex;
	flex-direction: column;
`;

export const OptionsContainer = styled.div`
	display: flex;
`;

export const Option = styled.button`
	${OptionsButton}
	background-color: transparent;
	color: var(--primary-blue);
	margin-right: 2rem;
	transition: all 0.2s;
	&:hover {
		background-color: var(--primary-blue);
		color: var(--white);
	}
`;

export const HeaderContainer = styled.div`
	display: flex;
	max-width: 80rem;
	justify-content: space-between;
`;

export const StatusContainer = styled.div`
	display: flex;
	align-items: center;
	font-size: 1.6rem;
`;

export const KycInfoContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	> svg {
		width: 2rem;
		height: 2rem;
		margin-left: 1rem;

		&:hover {
			filter: brightness(0.4);
		}
	}
`;

export const StatusCardContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	> svg {
		width: 2rem;
		height: 2rem;
		margin-right: 0.5rem;
	}
`;

export const StatusKYCContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 1.5rem;

	> svg {
		width: 2rem;
		height: 2rem;
		margin-right: 0.5rem;
	}
`;

export const RequestedCardLink = styled(Link)`
	text-decoration: none;
	color: var(--primary-blue);
`;
