import styled from 'styled-components';

export const OpenModalBtn = styled.button`
	display: inline-flex;
	float: right;
	margin-top: 0.4rem;
	position: relative;
	place-items: center;
	background: none;
	width: min-content;
	& > svg {
		width: 2rem;
		height: 2rem;
	}
`;

export const Container = styled.div``;

export const Title = styled.h1`
	color: var(--primary-blue);
	margin-bottom: 1rem;
`;

export const Table = styled.table`
	width: 65vw;
	max-height: 80vh;
	overflow-y: auto;
	max-width: 70rem;
	font-size: 1.4rem;
	padding: 0.5rem;
`;

export const Row = styled.tr``;

export const Head = styled.th`
	text-align: left;
	text-transform: uppercase;
`;

export const Data = styled.td`
	padding: 0.5rem 0;
	border-bottom: 1px solid black;
`;
export const ErrorData = styled.td`
	padding: 0.5rem 0;
	color: var(--primary-red);
	border-bottom: 1px solid black;
`;
