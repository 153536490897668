import { Beneficiary } from '../../../../contexts/PaymentContextV2';
import { parseCurrencyStrToNumber } from '../../../../utils/parseCurrency';
import { getBenefitsInputs } from './getBenefitValuesInputs';

export const calculatePaymentsAmount = (beneficiaries: Beneficiary[]) => {
	let paymentsAmountAccumulator = 0;

	const benefitsInputs = getBenefitsInputs();
	beneficiaries.forEach((beneficiary) => {
		if (beneficiary.selected) {
			const currentBeneficiaryPayments = benefitsInputs.filter(
				(i) =>
					i.id.startsWith(beneficiary.id) &&
					parseCurrencyStrToNumber(i.value) > 0
			).length;

			paymentsAmountAccumulator += currentBeneficiaryPayments;
		}
	});

	return paymentsAmountAccumulator;
};
