import styled from 'styled-components';

interface Props {
	text: string;
	width?: string;
}

// Component that shows a text hint on text hover and is also responsible to collapse text that overflows the `width`
export function TextWithHint({ text, width = '20rem' }: Props) {
	return (
		<TextContainer>
			<span style={{ maxWidth: width }} data-rh={text}>
				{text}
			</span>
		</TextContainer>
	);
}

export const TextContainer = styled.div`
	display: flex;
	width: fit-content;
	& > span {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
`;
