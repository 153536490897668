import { useEffect, useState } from 'react';
import { useAuth } from '../../../../../hooks/useAuth';
import { Button } from '../../../../../componentsV2/ui/Button';
import Modal from '../../../../../componentsV2/ui/Modal';
import { Card } from '../../../../../componentsV2/Card';
import styled from 'styled-components';
import { InfoContent } from '../../../../../componentsV2/InfoContent';
import { CostCenterOnlyUsers } from '../../../../../@types/CorporateExpenses/CostCenter';
import { useForm } from 'react-hook-form';
import { SelectField } from '../../../../../componentsV2/ui/Form/SelectField';
import { useQuery } from 'react-query';
import {
	CORPWAY_COST_CENTER_KEY,
	getCostCenters,
} from '../../../../../services/queries/Corpway/CostCenters';
import { showErrorMessage } from '../../../../../utils/ErrorHandler';
import { RiCloseLine } from 'react-icons/ri';
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';
import costCenterIconModal from '../../../../../assets/cost-center.svg'

interface Props {
	user: {
		costCenterId: string;
	} & CostCenterOnlyUsers;
	newCostCenterId: string | null;
	onCostCenterSelection: (c: string | null, u: string) => void;
}

interface FormData {
	newCostCenterId: string;
}

export function SelectUsersNewCostCenter({
	user,
	newCostCenterId,
	onCostCenterSelection,
}: Props) {
	const { currentCompany } = useAuth();
	const [isOpen, setOpen] = useState(false);
	const { register, handleSubmit, reset } = useForm<FormData>({
		defaultValues: {
			newCostCenterId: newCostCenterId ?? user.costCenterId,
		},
	});

	useEffect(() => {
		if (isOpen) {
			reset({
				newCostCenterId: newCostCenterId ?? user.costCenterId,
			});
		}
	}, [isOpen, newCostCenterId]); // eslint-disable-line react-hooks/exhaustive-deps

	const getCostCentersSelectQuery = useQuery(
		[CORPWAY_COST_CENTER_KEY, currentCompany?.id],
		() => getCostCenters(currentCompany!.id),
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível buscar os centros de custo. '
				);
			},
		}
	);

	const costCenters = getCostCentersSelectQuery.data?.costCenters;

	function handleSubmitForm(d: FormData) {
		if (d.newCostCenterId !== user.costCenterId) {
			onCostCenterSelection(d.newCostCenterId, user.user_id);
		}
		setOpen(false);
	}

	const OpenButton = () => {
		if (newCostCenterId === null) {
			return (
				<SelectedCostCenterContainer>
					<MdOutlineKeyboardArrowRight size={20} color='var(--primary-red)' />
					<Button
						onClick={() => setOpen(true)}
						intent='link'
						style={{
							color: 'var(--primary-red)',
							borderColor: 'var(--primary-red)',
						}}
					>
						S/ centro de custo
					</Button>
				</SelectedCostCenterContainer>
			);
		}
		return (
			<SelectedCostCenterContainer>
				<MdOutlineKeyboardArrowRight size={20} color='var(--primary-blue)' />
				<Button
					onClick={() => setOpen(true)}
					intent='link'
					style={{
						color: 'var(--primary-blue)',
						borderColor: 'var(--primary-blue)',
					}}
				>
					{costCenters?.find((c) => c.id === newCostCenterId)?.title}
				</Button>
				<RiCloseLine
					size={20}
					style={{ cursor: 'pointer' }}
					color='var(--primary-red)'
					onClick={() => onCostCenterSelection(null, user.user_id)}
				/>
			</SelectedCostCenterContainer>
		);
	};

	return (
		<div>
			<OpenButton />

			<Modal
				isOpen={isOpen}
				onRequestClose={() => setOpen(false)}
				showClose={false}
			>
				<ModalContainer>
					<CardContainer
						titleComponent= {
							<TitleWrapper>
								<div>
									<span>Escolha um&nbsp;</span>
									<span style={{ fontWeight: 600 }}>novo centro de custo</span>
								</div>
								<span>para o colaborador</span>
							</TitleWrapper>
						}
						direction='vertical'
						iconSrc={costCenterIconModal}
					>
						<div style={{ backgroundColor: '#FFFFFF'}}>
							<ContentContainer>
								<InfoContent label='Usuário'>{user.user_name}</InfoContent>
								<form
									onSubmit={(e) => {
										e.preventDefault();
										handleSubmit(handleSubmitForm)();
										e.stopPropagation(); // do not submit the parent form
									}}
								>
									<SelectField
										label='Novo Centro de Custo'
										name='newCostCenterId'
										id='newCostCenterId'
										register={register}
										placeholderText={false}
										style={{
											paddingLeft: '2rem'
										}}
										required
									>
										<option value={user.costCenterId} disabled>
											{costCenters?.find((c) => c.id === user.costCenterId)?.title}
										</option>
										{costCenters
											?.filter((c) => c.id !== user.costCenterId)
											.map((costCenter) => (
												<option key={costCenter.id} value={costCenter.id}>
													{costCenter.title}
												</option>
											))}
									</SelectField>
									<OptionsContainer>
										<Button
											type='button'
											onClick={() => setOpen(false)}
											intent='terciary'
											$outline
											roundness='lg'
										>
											Voltar
										</Button>
										<Button type='submit' roundness='lg'>
											Continuar
										</Button>
									</OptionsContainer>
								</form>
							</ContentContainer>
						</div>
					</CardContainer>
				</ModalContainer>
			</Modal>
		</div>
	);
}

const ModalContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 44rem;
	height: 47rem;
	radius: 1.6rem;
	padding: 0rem 0rem 3.2rem 0rem;
	gap: 3.2rem;
	background: #FFFFFF;
	overflow: hidden;
`;

const CardContainer = styled(Card)`
	width: 100%;
	background: #FFFFFF;
	& > div {
		height: 19rem;
		background-color: #F0F3F8;
	}
`;

const ContentContainer = styled.div`
	display: grid;
	width: 40rem;
	padding: 0rem 3rem 0rem 3rem;
	row-gap: 2rem;
	& > form {
		width: 100%;
		display: grid;
		row-gap: 2rem;
	}
`;
const SelectedCostCenterContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 2rem;
`;
const OptionsContainer = styled.div`
	display: flex;
	justify-content: space-around;
`;

const TitleWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;

	& > div, & > span {
	  font-family: Poppins;
	  font-size: 2rem;
	  font-weight: 400;
	  line-height: 3rem;
	  text-align: center;
	}

	& > div {
	  display: flex;
	  justify-content: center;
	  align-items: center;
	}
`;
