import styled from 'styled-components';
import { OptionsButton } from '../Button';

export const IconBtn = styled.button`
	display: flex;
	background: none;
	border: none;
	border-radius: 50%;
	color: var(--white);
	font-size: 2.5rem;
	padding: 0.6rem;
`;

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	padding: 1.5rem 1rem;
	margin-top: 0.5rem;
	background-color: var(--white);
	border-radius: 0.8rem;
	min-width: 36rem;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	border: 0.24rem solid #85d6ff;
`;

export const Title = styled.h2`
	font-size: 1.8rem;
	color: var(--dark-gray);
`;

export const List = styled.ul`
	display: flex;
	flex-direction: column;
	list-style: none;
	margin-top: 2rem;
	width: 100%;
	max-height: 30rem;
	overflow-y: auto;
	& li {
		display: flex;
		column-gap: 1rem;
		font-size: 1.4rem;
		align-items: center;
		border-bottom: 0.1rem solid var(--light-gray);
	}
`;

export const FileName = styled.span`
	color: var(--dark-gray);
	width: 100%;
	padding-left: 0.5rem;
`;

export const Progress = styled.div`
	background-color: var(--light-gray);
	padding: 0 4rem;
	height: 3.4rem;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 600;
`;

export const RemoveFileBtn = styled.button`
	background: none;
	& svg {
		width: 2rem;
		height: 2rem;
		background-color: var(--white);
		border-radius: 50%;
	}
`;

export const RemoveAllFilesBtn = styled.button`
	${OptionsButton}
	margin: 2rem 2rem 0 2rem;
	display: flex;
	width: 80%;
	align-items: center;
	justify-content: center;
	align-self: center;
`;
