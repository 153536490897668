import styled from 'styled-components';

export const Container = styled.div`
	width: auto;
	align-self: center;
	display: grid;
	gap: 4rem;
	margin: 0 9rem;

	@media (max-width: 850px) {
		.description {
			display: none;
		}

		.headerText {
			display: none;
		}
		width: 90%;
	}
`;

export const SectionDescriptionContainer = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
	@media (max-width: 850px) {
		display: none;
	}
`;

export const SectionContainer = styled.div`
	display: flex;
	justify-content: space-between;
	gap: 3rem;
	& p {
		font-size: 1.4rem;
		margin-top: 0.5rem;
		max-width: 39rem;
	}

	@media (max-width: 850px) {
		flex-direction: column;
		gap: 1rem;
	}
`;

export const OptionContainer = styled.div`
	width: 23rem;
	min-width: 23rem;
	@media (max-width: 850px) {
		width: 100%;
		height: 100%;
	}
`;
