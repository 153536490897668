import { CorpwayUserAccessLevel } from '../../@types/CorporateExpenses/User';

const ACCESS_LEVELS: Record<CorpwayUserAccessLevel, string> = {
	general_admin: 'Administrador',
	account_manager: 'Gerente',
	finance: 'Assistente',
	card_responsible: 'Colaborador',
	custom: 'Customizado',
};

export function parseUserAccessLevel(accessLevel: CorpwayUserAccessLevel) {
	return ACCESS_LEVELS[accessLevel] || '???';
}
