import styled from 'styled-components';

interface AmountsTextProps {
	error: boolean;
}
export const AmountsText = styled.span<AmountsTextProps>`
	min-height: 4rem;
	display: flex;
	align-items: center;
	justify-content: ${({ error }) => (error ? 'center' : 'flex-start')};
	border-radius: 0.4rem;
	font-size: 1.4rem;
	font-weight: bold;
	background-color: ${({ error }) =>
		error ? 'var(--primary-red)' : 'var(--white)'};
	color: ${({ error }) => (error ? 'var(--white)' : 'var(--dark-gray)')};
`;
