import styled from 'styled-components';
import { OptionsButton } from '../../../../../../components/Button';
import { FormCurrencyInput } from '../../../../../../components/Form/FormStyles';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	max-width: 40rem;
	width: 70vw;
	justify-content: center;
	align-items: center;
	row-gap: 3rem;
	padding: 1rem 2rem;
`;

export const OpenModalButton = styled.button`
	background-color: var(--primary-blue);
	border-radius: 50%;
	min-width: 2.6rem;
	min-height: 2.6rem;
	margin-left: 1rem;
	display: flex;
	justify-content: center;
	align-items: center;
	& > svg {
		min-height: 1.5rem;
		min-width: 1.5rem;
		color: var(--white);
	}
`;

export const Title = styled.h2`
	text-align: center;
	& > span {
		color: var(--primary-blue);
	}
`;

export const CurrencyInput = styled(FormCurrencyInput)`
	/* width: 12rem; */
	width: 100%;
	height: 3rem;
	/* color: var(--dark-gray); */
	background-color: rgba(198, 198, 198, 0.33);
	border-radius: 0.4rem;
	border: none;
	padding: 0 1rem;
`;

export const SubmitButton = styled.button`
	${OptionsButton}
	width: 100%;
`;
