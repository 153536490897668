import { useState } from 'react';
import { Collaborator } from '../../@types';
import Modal from '../Modal';
import avatarImg from '../../assets/avatar.svg';
import * as S from './styles';
import Loader from '../Loader';
import { EmptyContent } from '../EmptyContent';
import * as TableStyle from '../Table/TableStyles';
import { useAuth } from '../../hooks/useAuth';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import {
	FilterCollabsReturn,
	getFilteredCollabs,
} from '../../services/queries/Collaborators';
import { Filter, FilterParams } from '../Filter';
import PageTitle from '../PageTitle';
import { cpfMask } from '../../utils/masks';
import { showErrorMessage } from '../../utils/ErrorHandler';
import { ContractTypeOptionsFilters } from '../Filter/utils/SelectFilters';

export interface FilteredCollabsSelectorProps {
	alreadyAddedCollabs: Collaborator[]; // list of already added collaborators to forbid selecting again
	onAddNewCollaborators(c: Collaborator[]): void;
	maxCollaboratorsAllowed?: number;
	buttonText?: string;
}

export function FilteredCollabsSelector({
	alreadyAddedCollabs,
	onAddNewCollaborators,
	maxCollaboratorsAllowed,
	buttonText,
}: FilteredCollabsSelectorProps) {
	const { currentCompany } = useAuth();
	const [open, setOpen] = useState(false);

	const [collabsFiltersParams, setCollabsFiltersParams] = useState<
		FilterParams[]
	>([
		{
			name: 'Cargo',
			filter: 'office',
			value: '',
			type: 'text',
			selected: false,
		},
		{
			name: 'Setor de atuação',
			filter: 'section',
			value: '',
			type: 'text',
			selected: false,
		},
		{ name: 'UF', filter: 'uf', value: '', type: 'uf', selected: false },
		{
			name: 'Contrato',
			filter: 'contract_type',
			value: '',
			type: 'select',
			selectOptions: ContractTypeOptionsFilters,
			selected: false,
		},
		{
			name: 'Centro de custo',
			filter: 'cost_center',
			value: '',
			type: 'cost_center',
			selected: false,
		},
	]);

	const alreadyAddedCollabsFilter = (
		fetchedCollab: Collaborator,
		collabsList: Collaborator[]
	) =>
		!collabsList.find((c) => c.cpf === fetchedCollab.cpf) &&
		fetchedCollab.kyc_status === 'approved';

	const filteredCollabsQuery = useQuery<FilterCollabsReturn, Error>(
		['filteredCollabsQuery', collabsFiltersParams, true, currentCompany?.id],
		() => {
			return getFilteredCollabs(collabsFiltersParams, true, currentCompany?.id);
		},
		{
			onError: (error) => {
				showErrorMessage(
					error as Error,
					'Não foi possível buscar os colaboradores. '
				);
			},
			enabled: collabsFiltersParams.some((f) => !!f.value),
		}
	);

	// add functionality to fetch collaborators filtered that are in other pages
	// handle pagination as well (list can be empty and pagination on)
	function submitCollaborators() {
		const filteredCollaboratorsLength = getFilteredCollabsQueryList()!.length;
		if (
			maxCollaboratorsAllowed !== undefined &&
			filteredCollaboratorsLength > maxCollaboratorsAllowed
		) {
			toast.error(
				`Só é possível adicionar mais ${maxCollaboratorsAllowed} colaboradores. O limite foi ultrapassado em ${
					filteredCollaboratorsLength - maxCollaboratorsAllowed
				}.`
			);

			return;
		}

		onAddNewCollaborators(getFilteredCollabsQueryList()!);
		setOpen(false);
	}

	function getFilteredCollabsQueryList() {
		if (collabsFiltersParams.some((f) => !!f.value)) {
			return filteredCollabsQuery.data?.collaborators.filter((fetchedCollab) =>
				alreadyAddedCollabsFilter(fetchedCollab, alreadyAddedCollabs)
			);
		}
		return [];
	}

	if (filteredCollabsQuery.isLoading || filteredCollabsQuery.isPreviousData) {
		return (
			<div>
				<S.MainButton onClick={() => setOpen(true)}>
					Adicionar colaboradores por grupo
				</S.MainButton>
				<Modal isOpen={open} enableClose onRequestClose={() => setOpen(false)}>
					<S.Container>
						<TableStyle.TableHeaderContainer>
							<PageTitle title='Adicionar filtrados' />
							<Filter
								filterParams={collabsFiltersParams}
								onFiltersChanged={(updatedFilters) => {
									setCollabsFiltersParams(updatedFilters);
								}}
							/>
						</TableStyle.TableHeaderContainer>
						<div style={{ marginTop: '2rem' }}>
							<Loader />
						</div>
					</S.Container>
				</Modal>
			</div>
		);
	}
	return (
		<div>
			<S.MainButton onClick={() => setOpen(true)}>
				{buttonText ?? 'Adicionar colaboradores por grupo'}
			</S.MainButton>

			<Modal isOpen={open} enableClose onRequestClose={() => setOpen(false)}>
				<S.Container>
					<TableStyle.TableHeaderContainer>
						<PageTitle
							title='Adicionar por filtro'
							totalRecords={getFilteredCollabsQueryList()?.length ?? 0}
						/>
						<Filter
							filterParams={collabsFiltersParams}
							onFiltersChanged={(updatedFilters) => {
								setCollabsFiltersParams(updatedFilters);
							}}
						/>
					</TableStyle.TableHeaderContainer>

					<S.CollaboratorsList>
						{getFilteredCollabsQueryList()?.map((collaborator) => (
							<S.CollaboratorContainer key={collaborator.id}>
								<S.CollaboratorCard>
									<S.CollaboratorAvatar
										src={
											collaborator.avatar ? collaborator.avatar_url : avatarImg
										}
									/>

									<S.CollaboratorInfoContainer>
										<S.CollaboratorName>{`${collaborator.first_name} ${collaborator.last_name}`}</S.CollaboratorName>
										<S.CollaboratorInfo>
											{collaborator.office}
										</S.CollaboratorInfo>
										<S.CollaboratorInfo>
											{cpfMask(collaborator.cpf)}
										</S.CollaboratorInfo>
										<S.CollaboratorInfo>
											{collaborator.email}
										</S.CollaboratorInfo>
									</S.CollaboratorInfoContainer>
								</S.CollaboratorCard>
							</S.CollaboratorContainer>
						))}
						{getFilteredCollabsQueryList()?.length === 0 &&
							filteredCollabsQuery.isSuccess && (
								<EmptyContent
									text={'Nenhum colaborador disponível encontrado'}
								/>
							)}
					</S.CollaboratorsList>

					<S.MainButton
						style={{ marginTop: '2rem' }}
						onClick={submitCollaborators}
						disabled={
							!getFilteredCollabsQueryList()?.length ||
							(maxCollaboratorsAllowed !== undefined &&
								maxCollaboratorsAllowed <= 0)
						}
						title={
							maxCollaboratorsAllowed !== undefined &&
							maxCollaboratorsAllowed <= 0
								? 'Limite de colaboradores atingido.'
								: ''
						}
					>
						AVANÇAR
					</S.MainButton>
				</S.Container>
			</Modal>
		</div>
	);
}
