import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.header`
	display: flex;
	align-items: center;
	padding: 2.4rem 3.6rem;
	background-color: #f0f4f9;

	@media (max-width: 768px) {
		padding: 2rem 2rem;
	}
`;

export const LogoContainer = styled(Link)`
	text-decoration: none;
	& > div {
		display: flex;
		align-items: center;
		column-gap: 1.6rem;
		margin-right: 50%;
	}
	& > span {
		color: #1f59a4;
		font-size: 1.6rem;
		font-weight: 600;
		letter-spacing: 0.25rem;
	}

	@media (max-width: 768px) {
		display: none;
	}
`;

export const BoomerangSVG = styled.img`
	width: 1.6rem;
	transform: rotate(90deg);
`;

export const ContextContainer = styled.div`
	width: 100%;
	display: flex;
	flex: 1;
	justify-content: center;
	align-items: center;
	padding-left: 20.5rem;

	@media (max-width: 768px) {
		padding: 0rem;
	}
`;

export const MainContent = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	max-width: 1200px;
	gap: 2rem;
	align-items: center;
`;

export const ActionsButton = styled.div`
	display: flex;
	flex-direction: row;
	gap: 1.5rem;

	@media (max-width: 768px) {
		flex-direction: row-reverse;
	}
`;

export const DropdownContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 0.4rem;
	background-color: var(--white);
	border-radius: 0.8rem;
	min-width: 21rem;
	box-shadow: -1px 2px 3px 0px rgba(0, 0, 0, 0.1);
	border: 1px solid var(--primary-blue);
	font-family: 'Poppins', sans-serif;

	& a,
	button {
		text-align: center;
		font-family: 'Poppins', sans-serif;
		background: none;
		width: 100%;
		color: var(--dark-gray);
		font-size: 1.6rem;
		font-weight: 600;
		&:hover {
			filter: none;
		}
	}
`;

export const PopoverContainer = styled.div`
	@media (max-width: 1024px) {
		display: none;
	}
`;

export const MenuAction = styled.div`
	display: none;

	@media (max-width: 768px) {
		display: block;
	}
`;

export const DropdownItem = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 1.6rem 1.6rem;
	width: 100%;
`;

export const Divider = styled.div`
	width: 100%;
	height: 1px;
	background-color: var(--primary-blue);
`;

export const OpenProductOptionsBtn = styled.button`
	display: flex;
	align-items: center;
	column-gap: 1.6rem;
	border-radius: 0.8rem;
	background: #e5eaf2;
	padding: 1.3rem 1.5rem 1.3rem 2.4rem;
	color: var(--dark-gray);
	font-size: 1.6rem;
	line-height: 1rem;
	font-weight: 600;
	font-family: 'Poppins', sans-serif;
	&:hover {
		filter: none;
	}
	& > svg {
		color: var(--primary-blue);
		width: 3rem;
		height: 3rem;
	}

	@media (max-width: 950px) {
		line-height: 1.3rem;
	}
`;

export const UserContainer = styled.div`
	display: flex;
	gap: 1.6rem;
	justify-content: flex-end;
	align-items: center;

	p {
		font-size: 1.6rem;
	}

	@media (max-width: 950px) {
		width: 100%;
	}
`;

export const UserAvatarBtn = styled.button`
	background: none;
	border: none;
	display: flex;
	align-items: center;
	justify-content: center;

	& > img {
		width: 4rem;
		height: 4rem;
		border-radius: 50%;
		border: 0.2rem solid var(--primary-blue);
		object-fit: cover;
	}
`;
