import styled from 'styled-components';
import { OptionsButton } from '../../../../components/Button';

export const Container = styled.div`
	flex: 1;
	padding: 4rem 7rem 2rem 7rem;
`;

export const BillingContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	row-gap: 2rem;

	& > span {
		font-size: 1.6rem;
		font-weight: 600;
	}
`;

export const DownloadBillingButton = styled.a`
	${OptionsButton}
	width: 10rem;
	height: 2rem;
	display: flex;
	align-items: center;
	justify-content: center;
`;
