import { useEffect } from 'react';
import { useQuery } from 'react-query';
import Loader from '../../components/Loader';
import PageTitle from '../../components/PageTitle';
import { useAuth } from '../../hooks/useAuth';
import { useNotifications } from '../../hooks/useNotifications';
import {
	getMultiflexPrivacyPolicy,
	getMultiflexTerms,
} from '../../services/queries/Terms';
import * as S from './styles';
import { showErrorMessage } from '../../utils/ErrorHandler';

export function Terms() {
	const { termsNotifications } = useAuth();
	const { toggleNotificationsStatus } = useNotifications();

	useEffect(() => {
		if (termsNotifications.length > 0) {
			const termsNotificationsIds = termsNotifications.map((n) => {
				return n.id;
			});
			toggleNotificationsStatus(termsNotificationsIds, true);
		}
	}, [termsNotifications]); //eslint-disable-line

	const termsQuery = useQuery<string, Error>(
		['operatorTerm'],
		() => {
			return getMultiflexTerms();
		},
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível buscar os termos de uso. '
				);
			},
			refetchOnWindowFocus: false,
		}
	);

	const privacyQuery = useQuery(
		['privacyPolicy'],
		() => {
			return getMultiflexPrivacyPolicy();
		},
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível buscar os avisos de privacidade. '
				);
			},
			refetchOnWindowFocus: false,
		}
	);

	if (termsQuery.isLoading || privacyQuery.isLoading) {
		return (
			<S.Container>
				<PageTitle title='Termos de uso e aviso de privacidade' />
				<div style={{ marginTop: '3rem' }} />
				<Loader />
			</S.Container>
		);
	}
	return (
		<S.Container>
			<PageTitle title='Termos de uso e aviso de privacidade' />
			<S.TextsContainer>
				<S.TextTitle>Termos de uso</S.TextTitle>
				<S.TermsContainer
					dangerouslySetInnerHTML={{
						__html: termsQuery.data ? termsQuery.data : '',
					}}
				/>

				<S.TextTitle>Aviso de privacidade</S.TextTitle>
				<S.TermsContainer
					dangerouslySetInnerHTML={{
						__html: privacyQuery.data ? privacyQuery.data : '',
					}}
				/>
			</S.TextsContainer>
		</S.Container>
	);
}
