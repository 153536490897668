import { UseFormRegister } from 'react-hook-form';
import styled from 'styled-components';
import { Label } from './Label';

export interface BaseFieldProps {
	label?: string;
	register?: UseFormRegister<any>;
	errorMessage?: string;
}

interface Props {
	children: React.ReactNode;
	label?: string;
	name?: string;
}

export function Field(props: Props) {
	return (
		<Fieldset>
			{props.label && <Label htmlFor={props.name}>{props.label}</Label>}
			{props.children}
		</Fieldset>
	);
}

const Fieldset = styled.fieldset`
	border: none;
	display: flex;
	flex-direction: column;
	width: 100%;

	& span {
		display: flex;
		align-items: center;
		color: var(--toastify-color-error);
		font-size: 1.2rem;
		margin-top: 0.5rem;
		gap: 0.7rem;
	}
`;
