import styled from 'styled-components';

export const Container = styled.div`
	flex: 1;
	min-height: 100vh;
	background-color: var(--white);
`;

export const TopContainer = styled.div`
	width: 100%;
	height: 16rem;
	background-color: var(--primary-red);
	overflow: hidden;
	display: flex;
	justify-content: center;
	border-radius: 0 0 3.2rem 3.2rem;
	& > img {
		height: 30rem;
		margin-top: -2rem;
	}
`;

export const MainContainer = styled.main`
	display: flex;
	flex-direction: column;
	row-gap: 3rem;
	margin: 4rem 10rem;
`;

export const SignOutBtn = styled.button`
	background: none;
	display: flex;
	column-gap: 1rem;
	align-items: center;
	color: #9c9c9c;
	font-weight: 600;
	font-size: 1.6rem;
	width: fit-content;
	& > svg {
		width: 2.2rem;
		height: 2.2rem;
	}
`;

export const Title = styled.h1`
	font-size: 3.2rem;
`;

export const CompaniesList = styled.ul`
	display: flex;
	column-gap: 3rem;
`;

export const CompanyCard = styled.div`
	display: flex;
	flex-direction: column;
	width: 26rem;
	background-color: #f0f0f0;
	border: 2px solid black;
	border-radius: 1.6rem;
`;

export const CompanyAvatarContainer = styled.div`
	background-color: #fff;
	height: 16rem;
	border-radius: 1.4rem;
	overflow: hidden;
	& > img {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
`;

export const CompanyCardContentContainer = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 1rem;
	margin: 2rem;
`;

export const CompanyLabel = styled.span`
	display: block;
	font-size: 1.4rem;
`;

export const CompanyTitle = styled.span`
	display: block;
	font-size: 2rem;
	font-weight: 600;
	margin: 1rem 0;
`;
