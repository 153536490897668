import styled from 'styled-components';

export const NotificationContainer = styled.div`
	font-size: 1.2rem;
	display: flex;
	align-items: center;
`;

export const CancelSubmitionButton = styled.button`
	width: min-content;
	padding: 0.5rem 1rem;
	border-radius: 0.4rem;
	margin-left: 1rem;
	font-weight: bold;
	background-color: transparent;
	color: var(--primary-blue);
	transition: 0.3s background-color, 0.3s color;

	&:hover {
		background-color: var(--primary-blue);
		color: var(--white);
	}
`;
