import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	column-gap: 4rem;
	overflow-x: hidden;
`;

export const ActionButton = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 6rem;
	height: 6rem;
	background: none;
	& > svg {
		width: 100%;
		height: 100%;
		color: var(--mid-gray2);
	}
`;

export const ContentContainer = styled.div`
	display: flex;
	width: 100%;
	overflow-x: hidden;
	padding: 1rem;
	& > div {
		flex-shrink: 0;
		user-select: none;
	}
	@media (max-width: 768px) {
		overflow: auto;
	}
`;
