import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { RiUploadLine } from 'react-icons/ri';
import { toast } from 'react-toastify';
import { Operator } from '../../../@types';
import Loader from '../../../components/Loader';
import {
	ObrigatoryFieldsIndication,
	ObrigatoryIndicator,
} from '../../../components/ObrigatoryFieldsIndicator';
import PageTitle from '../../../components/PageTitle';
import { useAuth } from '../../../hooks/useAuth';
import * as S from './styles';
import * as FormStyle from '../../../components/Form/FormStyles';
import { useMutation, useQuery } from 'react-query';
import {
	fetchOperatorProfile,
	OperatorUpdate,
	updateOperatorAvatar,
	updateOperatorPassword,
	updateOperatorProfile,
	UpdatePasswordProps,
} from '../../../services/queries/Operators';
import ChangePasswordModal from '../../../components/ChangePasswordModal';
import { db, firebaseAuth } from '../../../services/firebase';
import { child, get, ref, set, update } from 'firebase/database';
import PreventTransitionPrompt from '../../../components/PreventTransitionPrompt';
import { trimObjectData } from '../../../utils/trimObjectData';
import { showErrorMessage } from '../../../utils/ErrorHandler';

export function EditProfile() {
	const { user, updateUserInfo, companies, firebaseAuthConcluded } = useAuth();
	const [avatarImg, setAvatarImg] = useState<File>();
	const { register, handleSubmit, reset, formState } = useForm();

	const isDirty = formState.isDirty;
	const [allowNavigation, setAllowNavigation] = useState(!isDirty);

	useEffect(() => {
		setAllowNavigation(!(isDirty || avatarImg));
	}, [isDirty, avatarImg]);

	const updateProfileQuery = useMutation(
		(operatorData: OperatorUpdate) => {
			return updateOperatorProfile(operatorData);
		},
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao tentar atualizar o operador. '
				);
			},
		}
	);

	const updateAvatarQuery = useMutation(
		(avatarFormData: FormData) => {
			return updateOperatorAvatar(avatarFormData);
		},
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao tentar atualizar o avatar do operador. '
				);
			},
		}
	);

	const updatePasswordQuery = useMutation(
		({ oldPassword, newPassword, confirmationPassword }: UpdatePasswordProps) =>
			updateOperatorPassword({ oldPassword, newPassword, confirmationPassword })
	);

	const operatorProfile = useQuery<Operator, Error>(
		['operatorProfile'],
		() => {
			return fetchOperatorProfile();
		},
		{
			onSuccess: async (data) => {
				setAllowNavigation(true);

				user.avatar_url = data.avatar_url;
				user.name = data.name;
				if (data.avatar_url || data.name)
					await updateFirebaseAvatar(data.avatar_url);
				updateUserInfo(user);

				reset({
					...data,
				});
			},
			onError: (err) => {
				showErrorMessage(err as Error, 'Não foi possível buscar o operador. ');
			},
			enabled: !!user,
			refetchOnWindowFocus: false,
		}
	);

	function onAvatarUpload(event: any) {
		setAvatarImg(event.target.files[0]);
	}

	async function handleUpdateOperator(operator: Operator) {
		if (isDirty) {
			let { name } = operator;

			// alert(JSON.stringify(data))
			const updatedColaboratorData = {
				name,
			};

			await updateProfileQuery.mutateAsync(
				trimObjectData(updatedColaboratorData)
			);
		}

		if (avatarImg) {
			const avatarFormData = new FormData();
			avatarFormData.append('avatar', avatarImg);

			await updateAvatarQuery.mutateAsync(avatarFormData);
		}

		if (isDirty || avatarImg) {
			toast.info('Operador editado com sucesso');
			operatorProfile.refetch();
		} else {
			toast.error(
				'Modifique alguma informação antes de requisitar a atualização!'
			);
		}
	}

	async function handlePasswordUpdate(
		oldPassword: string,
		newPassword: string,
		confirmationPassword: string
	) {
		if (newPassword !== confirmationPassword) {
			toast.error('Nova senha e senha de confirmação precisam ser iguais!');
			return;
		}
		if (oldPassword.length < 6 || newPassword.length < 6) {
			toast.error('As senhas precisam ter mais de 5 caracteres!');
			return;
		}

		try {
			await updatePasswordQuery.mutateAsync({
				oldPassword,
				newPassword,
				confirmationPassword,
			});
			toast.info('Senha atualizada com sucesso!');
		} catch (err) {
			showErrorMessage(
				err as Error,
				'Ocorreu um problema ao tentar atualizar a senha do operador. '
			);
		}
	}

	async function updateFirebaseAvatar(avatarUrl: string) {
		if (!firebaseAuthConcluded) return;

		await Promise.allSettled(
			companies.map(async (company) => {
				await setFirebaseProfilePicture(avatarUrl, company.id);
				return true;
			})
		);
	}

	async function setFirebaseProfilePicture(url: string, companyId: string) {
		const operatorsRef = ref(db, `${companyId}/operators`);
		try {
			await get(operatorsRef).then((snapshot) => {
				if (!snapshot.hasChild(firebaseAuth.currentUser?.uid!)) {
					const newOperatorRef = child(
						operatorsRef,
						firebaseAuth.currentUser?.uid!
					);
					set(newOperatorRef, {
						name: user.name,
						email: user.email,
						jobDescription: user.office,
						profileUrl: url,
						status: 'offline',
					});
				} else {
					const newOperatorRef = child(
						operatorsRef,
						firebaseAuth.currentUser?.uid!
					);
					update(newOperatorRef, {
						profileUrl: url,
						name: user.name,
					});
				}
			});
		} catch (err) {
			console.log(err);
		}
	}

	if (
		operatorProfile.isLoading ||
		updateAvatarQuery.isLoading ||
		updateProfileQuery.isLoading
	) {
		return (
			<S.Container>
				<PageTitle title='Editar Perfil' />
				<Loader />
			</S.Container>
		);
	}

	return (
		<S.Container>
			{!allowNavigation && <PreventTransitionPrompt />}

			<PageTitle title='Editar Perfil' />

			<FormStyle.Form onSubmit={handleSubmit(handleUpdateOperator)}>
				<FormStyle.FormContainer>
					<FormStyle.FieldSet>
						<FormStyle.FieldGroup>
							<FormStyle.Field>
								<FormStyle.Label htmlFor='name'>
									Nome completo <ObrigatoryIndicator />
								</FormStyle.Label>
								<FormStyle.Input
									data-testid='nameInput_test_id'
									type='text'
									{...register('name')}
									name='name'
									required
								/>
							</FormStyle.Field>

							<FormStyle.Field>
								<FormStyle.ImageUploadContainer>
									<FormStyle.Label>Imagem de perfil</FormStyle.Label>
									{
										// PREVIEW IMAGEM
										avatarImg !== undefined ? (
											<FormStyle.ImagePreview
												src={URL.createObjectURL(avatarImg)}
											/>
										) : (
											operatorProfile.data?.avatar_url && (
												<FormStyle.ImagePreview
													src={operatorProfile.data.avatar_url}
												/>
											)
										)
									}

									<>
										<FormStyle.UploadIcon htmlFor='logo'>
											<RiUploadLine />
										</FormStyle.UploadIcon>
										<input
											data-testid='imageInput_test_id'
											type='file'
											id='logo'
											onChange={onAvatarUpload}
											name='logo'
											multiple={false}
											accept='.jpg, .jpeg, .png'
											style={{ display: 'none' }}
										/>
									</>
								</FormStyle.ImageUploadContainer>
							</FormStyle.Field>
						</FormStyle.FieldGroup>
					</FormStyle.FieldSet>
				</FormStyle.FormContainer>

				<ObrigatoryFieldsIndication />
				<FormStyle.ButtonsContainer>
					<FormStyle.FormButton type='submit'>Salvar</FormStyle.FormButton>
					<ChangePasswordModal
						submit={async (oldPassword, newPassword, confirmNewPassword) => {
							await handlePasswordUpdate(
								oldPassword,
								newPassword,
								confirmNewPassword
							);
						}}
					/>
				</FormStyle.ButtonsContainer>
			</FormStyle.Form>
		</S.Container>
	);
}
