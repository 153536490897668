import api from '../api';

export async function getCorpwayTerms() {
	const { data } = await api.get('/api/v1/corpway/term-of-use', {
		params: {
			type: 'corpway',
		},
	});
	return data;
}

export async function getCorpwayPrivacyPolicy() {
	const { data } = await api.get('/api/v1/corpway/privacy-policy', {
		params: {
			type: 'corpway',
		},
	});
	return data;
}

export async function getMultiflexTerms() {
	const { data } = await api.get('/api/v1/multiflex/term-of-use');
	return data;
}

export async function getMultiflexPrivacyPolicy() {
	const { data } = await api.get('/api/v1/multiflex/privacy-policy');
	return data;
}

export async function getPublicPrivacyPolicy(type: string) {
	const { data } = await api.get('/api/v1/privacy/public', {
		params: {
			type: type,
		},
	});
	return data;
}

export async function getPublicTerms(type: string) {
	const { data } = await api.get('/api/v1/terms/public', {
		params: {
			type: type,
		},
	});
	return data;
}

export async function getAntiFraud() {
	const { data } = await api.get('/api/v1/anti-fraud-policy');
	return data;
}

export async function ApproveCorpwayTermsAndPrivacyPolicy() {
	const { data } = await api.post(
		'/api/v1/corpway/terms-of-use-privacy-policy'
	);
	return data;
}

export async function ApproveMultiflexTermsAndPrivacyPolicy() {
	const { data } = await api.post(
		'/api/v1/multiflex/terms-of-use-privacy-policy'
	);
	return data;
}
