import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	max-height: 80vh;
`;

export const Title = styled.h1`
	color: var(--primary-blue);
	margin-bottom: 1rem;
`;

export const Table = styled.table`
	width: 65vw;
	max-width: 140rem;
	font-size: 1.4rem;
	padding: 0.5rem;
`;
export const TableBody = styled.tbody``;
export const Row = styled.tr``;

export const Head = styled.th`
	text-align: left;
	text-transform: uppercase;
`;

export const Data = styled.td`
	padding: 0.5rem 0;
	/* padding-bottom: 0.5rem; */
	border-bottom: 1px solid black;
	/* border-left: 1px solid black; */
	/* border: 1px solid black; */
`;
export const ErrorData = styled.td`
	padding: 0.5rem 0;
	/* padding-bottom: 0.5rem; */
	color: var(--red);
	border-bottom: 1px solid black;
	max-width: 40rem;

	/* border-left: 1px solid black; */
	/* border: 1px solid black; */
`;
