import { useState } from 'react';
import { BsFileEarmarkText } from 'react-icons/bs';
import { MdNotes } from 'react-icons/md';
import { CardBatch } from '../../../@types';
import { EmptyContent } from '../../../components/EmptyContent';
import PageTitle from '../../../components/PageTitle';
import * as TableStyle from '../../../components/Table/TableStyles';
import { useAuth } from '../../../hooks/useAuth';
import { parseDate } from '../../../utils/parseDate';
import {
	getStatusIcon,
	parseStatusString,
} from '../../../utils/parseCardBatchStatus';
import Loader from '../../../components/Loader';
import { Pagination } from '../../../components/Pagination';
import { Filter, FilterParams } from '../../../components/Filter';
import { useQuery, useQueryClient } from 'react-query';
import * as S from './styles';
import {
	FilteredCardbatchesReturn,
	getConcludedCardsBatches,
	getFilteredCardBatches,
	getPendingCardsBatches,
} from '../../../services/queries/CardsBatchs';
import { CreateCardBatchModal } from './CreateCardBatchModal';
import { showErrorMessage } from '../../../utils/ErrorHandler';
import { parseDeliveryAddressType } from './utils/parseCardBatchInfo';
import { useCardsListStore } from '../../../stores/useCardsListStore';
import { useHistoryNonMatchCallback } from '../../../hooks/useHistoryNonMatchCallback';
import { useSortColumnHook } from '../../../hooks/useSortColumnHook';
import { SortColumnButton } from '../../../components/SortColumnButton';
import IconHover from '../../../components/IconHover';
import { FaInfoCircle } from 'react-icons/fa';

export function CardsBatches() {
	const { currentCompany } = useAuth();
	const queryClient = useQueryClient();
	const [pendingCardsBatchesCurrentPage, setPendingCardsBatchesCurrentPage] =
		useState(1);
	const [
		concludedCardsBatchesCurrentPage,
		setConcludedCardsBatchesCurrentPage,
	] = useState(1);
	const {
		currentSortColumn: currentSortColumnPending,
		toggleSort: toggleSortPending,
	} = useSortColumnHook();
	const {
		currentSortColumn: currentSortColumnNonPending,
		toggleSort: toggleSortNonPending,
	} = useSortColumnHook();
	const [showingTable, setShowingTable] = useState<string>();

	const [
		pendingCardsBatchesFiltersParams,
		setPendingCardsBatchesFiltersParams,
		concludedCardsBatchesFiltersParams,
		setConcludedCardsBatchesFiltersParams,
		resetFilters,
	] = useCardsListStore((state) => [
		state.pendingCardsBatchesFiltersParams,
		state.setPendingCardsBatchesFiltersParams,
		state.concludedCardsBatchesFiltersParams,
		state.setConcludedCardsBatchesFiltersParams,
		state.resetFilters,
	]);
	useHistoryNonMatchCallback('cards', resetFilters);

	const filteredPendingCardBatchesQuery = useQuery<
		FilteredCardbatchesReturn,
		Error
	>(
		[
			'filteredPendingCardBatches',
			pendingCardsBatchesFiltersParams,
			true,
			pendingCardsBatchesCurrentPage,
			currentSortColumnPending,
		],
		() => {
			return getFilteredCardBatches(
				pendingCardsBatchesFiltersParams,
				currentCompany?.id,
				true,
				pendingCardsBatchesCurrentPage,
				currentSortColumnPending
			);
		},
		{
			onError: (error) => {
				showErrorMessage(
					error as Error,
					'Não foi possível buscar as remessas de cartões. '
				);
			},
			enabled:
				!!pendingCardsBatchesFiltersParams.find((f) => !!f.value) &&
				!!pendingCardsBatchesCurrentPage,
		}
	);

	const filteredConcludedCardBatchesQuery = useQuery<
		FilteredCardbatchesReturn,
		Error
	>(
		[
			'filteredConcludedCardbatches',
			concludedCardsBatchesFiltersParams,
			false,
			concludedCardsBatchesCurrentPage,
			currentSortColumnNonPending,
		],
		() => {
			return getFilteredCardBatches(
				concludedCardsBatchesFiltersParams,
				currentCompany?.id,
				false,
				concludedCardsBatchesCurrentPage,
				currentSortColumnNonPending
			);
		},
		{
			onError: (error) => {
				showErrorMessage(
					error as Error,
					'Não foi possível buscar as remessas de cartões. '
				);
			},
			enabled:
				!!concludedCardsBatchesFiltersParams.find((f) => !!f.value) &&
				!!concludedCardsBatchesCurrentPage,
		}
	);

	const pendingCardsBatchesQuery = useQuery(
		[
			'pendingCardsBatchesList',
			currentCompany?.id,
			pendingCardsBatchesCurrentPage,
			currentSortColumnPending,
		],
		() => {
			return getPendingCardsBatches(
				pendingCardsBatchesCurrentPage,
				currentCompany?.id,
				currentSortColumnPending
			);
		},
		{
			onError: (err) => {
				showErrorMessage(err as Error, 'Não foi possível buscar as remessas. ');
			},
			keepPreviousData: true,
		}
	);

	const concludedCardsBatchesQuery = useQuery(
		[
			'concludedCardsBatchesList',
			currentCompany?.id,
			concludedCardsBatchesCurrentPage,
			currentSortColumnNonPending,
		],
		() => {
			return getConcludedCardsBatches(
				concludedCardsBatchesCurrentPage,
				currentCompany?.id,
				currentSortColumnNonPending
			);
		},
		{
			onError: (err) => {
				showErrorMessage(err as Error, 'Não foi possível buscar as remessas. ');
			},
			keepPreviousData: true,
		}
	);

	function refreshPage() {
		setConcludedCardsBatchesCurrentPage(1);
		setPendingCardsBatchesCurrentPage(1);
		queryClient.resetQueries('pendingCardsBatchesList');
		queryClient.resetQueries('concludedCardsBatchesList');
		queryClient.resetQueries('filteredPendingCardBatches');
		queryClient.resetQueries('filteredConcludedCardbatches');
	}

	function isIndividualTableLoading(tableName: string) {
		if (tableName === 'Solicitações de remessa pendentes')
			return (
				filteredPendingCardBatchesQuery.isLoading ||
				pendingCardsBatchesQuery.isPreviousData
			);
		else if (tableName === 'Solicitações de remessa concluídas')
			return (
				filteredConcludedCardBatchesQuery.isLoading ||
				concludedCardsBatchesQuery.isPreviousData
			);

		return false;
	}
	function getTableTotalRecords(tableName: string) {
		if (tableName === 'Solicitações de remessa pendentes') {
			if (pendingCardsBatchesFiltersParams.find((f) => !!f.value)) {
				return filteredPendingCardBatchesQuery.data?.totalCardBatches;
			}
			return pendingCardsBatchesQuery.data?.totalPendingCardsBatches;
		}

		if (concludedCardsBatchesFiltersParams.find((f) => !!f.value)) {
			return filteredConcludedCardBatchesQuery.data?.totalCardBatches;
		}
		return concludedCardsBatchesQuery.data?.totalConcludedCardsBatches;
	}

	function updateFilters(filters: FilterParams[], pending: boolean) {
		if (pending) {
			setPendingCardsBatchesCurrentPage(1);
			setPendingCardsBatchesFiltersParams(filters);
		} else {
			setConcludedCardsBatchesCurrentPage(1);
			setConcludedCardsBatchesFiltersParams(filters);
		}
	}

	function getSortColumnOrder(columnName: string, tableName: string) {
		if (tableName === 'Solicitações de remessa pendentes') {
			return currentSortColumnPending?.name === columnName
				? currentSortColumnPending.order
				: null;
		}
		return currentSortColumnNonPending?.name === columnName
			? currentSortColumnNonPending.order
			: null;
	}
	function handleToggleSortColumn(columnName: string, tableName: string) {
		if (tableName === 'Solicitações de remessa pendentes')
			toggleSortPending(columnName);
		else toggleSortNonPending(columnName);
	}

	function generateCardBatchRow(cardBatch: CardBatch) {
		return (
			<TableStyle.TableRow key={cardBatch.id}>
				<TableStyle.TableData>
					<TableStyle.DocumentContainer>
						<TableStyle.IconContainer>
							<BsFileEarmarkText />
						</TableStyle.IconContainer>
						{cardBatch.order_id}
					</TableStyle.DocumentContainer>
				</TableStyle.TableData>

				<TableStyle.TableData>{cardBatch.card_qtde}</TableStyle.TableData>

				<TableStyle.TableData>
					{parseDate(cardBatch.created_at, 1)}
				</TableStyle.TableData>

				<TableStyle.TableData>
					{parseDeliveryAddressType(cardBatch.delivery_address_type)}
				</TableStyle.TableData>

				<TableStyle.TableData>
					<TableStyle.StatusContainer>
						{getStatusIcon(cardBatch.status)}
						<span>{parseStatusString(cardBatch.status)}</span>
						{cardBatch.max_qtde_exceeded && (
							<IconHover
								Icon={FaInfoCircle}
								textHover='Limite de cartões por pedido excedido.'
								colorIcon='var(--primary-red)'
							/>
						)}
					</TableStyle.StatusContainer>
				</TableStyle.TableData>

				<TableStyle.TableData>
					<TableStyle.OptionLink
						data-rh='Detalhamento'
						to={`/home/cards/${cardBatch.id}`}
					>
						<MdNotes />
					</TableStyle.OptionLink>
				</TableStyle.TableData>
			</TableStyle.TableRow>
		);
	}

	function getTable(tableName: string, cardsBatches?: CardBatch[]) {
		if (isIndividualTableLoading(tableName) || !cardsBatches?.length) {
			return (
				<>
					<TableStyle.TitleWrapper>
						<PageTitle title={tableName} />
						{tableName === 'Solicitações de remessa pendentes' ? (
							<>
								<Filter
									filterParams={pendingCardsBatchesFiltersParams}
									onFiltersChanged={(updatedFilters) => {
										updateFilters(updatedFilters, true);
									}}
								/>
							</>
						) : (
							<>
								<Filter
									filterParams={concludedCardsBatchesFiltersParams}
									onFiltersChanged={(updatedFilters) => {
										updateFilters(updatedFilters, false);
									}}
								/>
							</>
						)}
						{tableName === 'Solicitações de remessa pendentes' && (
							<TableStyle.ReloadIcon
								type='reload'
								onClick={refreshPage}
								data-testid='refresh'
							/>
						)}
					</TableStyle.TitleWrapper>
					{isIndividualTableLoading(tableName) ? (
						<Loader />
					) : (
						<EmptyContent text='Não há ítens a serem exibidos.' />
					)}
				</>
			);
		}

		return (
			<>
				<TableStyle.TableHeaderContainer>
					<TableStyle.TitleWrapper>
						<PageTitle
							title={tableName}
							totalRecords={getTableTotalRecords(tableName) ?? 0}
						/>
						{tableName === 'Solicitações de remessa pendentes' ? (
							<>
								<Filter
									filterParams={pendingCardsBatchesFiltersParams}
									onFiltersChanged={(updatedFilters) => {
										updateFilters(updatedFilters, true);
									}}
								/>
							</>
						) : (
							<>
								<Filter
									filterParams={concludedCardsBatchesFiltersParams}
									onFiltersChanged={(updatedFilters) => {
										updateFilters(updatedFilters, false);
									}}
								/>
							</>
						)}
						{showingTable === tableName ? (
							<TableStyle.SeeLess
								onClick={() =>
									showingTable === tableName
										? setShowingTable('')
										: setShowingTable(tableName)
								}
							>
								Ver menos
							</TableStyle.SeeLess>
						) : (
							<TableStyle.SeeMore
								onClick={() =>
									showingTable === tableName
										? setShowingTable('')
										: setShowingTable(tableName)
								}
							>
								Ver mais
							</TableStyle.SeeMore>
						)}
					</TableStyle.TitleWrapper>

					{showingTable === tableName ? (
						tableName === 'Solicitações de remessa pendentes' ? (
							pendingCardsBatchesFiltersParams.find((f) => !!f.value) ? (
								<Pagination
									onPageChange={(page) =>
										setPendingCardsBatchesCurrentPage(page)
									}
									currentPage={pendingCardsBatchesCurrentPage}
									totalCount={
										filteredPendingCardBatchesQuery.data
											? filteredPendingCardBatchesQuery.data?.totalCardBatches!
											: 1
									}
								/>
							) : (
								<Pagination
									onPageChange={(page) =>
										setPendingCardsBatchesCurrentPage(page)
									}
									currentPage={pendingCardsBatchesCurrentPage}
									totalCount={
										pendingCardsBatchesQuery.data
											? pendingCardsBatchesQuery.data.totalPendingCardsBatches!
											: 1
									}
								/>
							)
						) : concludedCardsBatchesFiltersParams.find((f) => !!f.value) ? (
							<Pagination
								onPageChange={(page) =>
									setConcludedCardsBatchesCurrentPage(page)
								}
								currentPage={concludedCardsBatchesCurrentPage}
								totalCount={
									filteredConcludedCardBatchesQuery.data
										? filteredConcludedCardBatchesQuery.data.totalCardBatches!
										: 1
								}
							/>
						) : (
							<Pagination
								onPageChange={(page) =>
									setConcludedCardsBatchesCurrentPage(page)
								}
								currentPage={concludedCardsBatchesCurrentPage}
								totalCount={
									concludedCardsBatchesQuery.data
										? concludedCardsBatchesQuery.data
												.totalConcludedCardsBatches!
										: 1
								}
							/>
						)
					) : (
						<></>
					)}
					{tableName === 'Solicitações de remessa pendentes' && (
						<TableStyle.ReloadIcon
							type='reload'
							onClick={refreshPage}
							data-testid='refresh'
						/>
					)}
				</TableStyle.TableHeaderContainer>

				<TableStyle.Table>
					<TableStyle.TableHeader>
						<TableStyle.TableRow>
							<TableStyle.TableHeaderCell>
								CÓDIGO DO LOTE
							</TableStyle.TableHeaderCell>
							<TableStyle.TableHeaderCell>
								<SortColumnButton
									order={getSortColumnOrder('card_quantity', tableName)}
									onToggleSort={() =>
										handleToggleSortColumn('card_quantity', tableName)
									}
								/>
								Nº DE CARTÕES
							</TableStyle.TableHeaderCell>
							<TableStyle.TableHeaderCell>
								<SortColumnButton
									order={getSortColumnOrder('created_at', tableName)}
									onToggleSort={() =>
										handleToggleSortColumn('created_at', tableName)
									}
								/>
								DATA DA SOLICITAÇÃO
							</TableStyle.TableHeaderCell>
							<TableStyle.TableHeaderCell>
								ENDEREÇO DE ENTREGA
							</TableStyle.TableHeaderCell>
							<TableStyle.TableHeaderCell>STATUS</TableStyle.TableHeaderCell>
							<TableStyle.TableHeaderCell>OPÇÕES</TableStyle.TableHeaderCell>
						</TableStyle.TableRow>
					</TableStyle.TableHeader>

					<TableStyle.TableBody>
						{showingTable === tableName
							? cardsBatches.map((cardBatch) => generateCardBatchRow(cardBatch))
							: generateCardBatchRow(cardsBatches[0])}
					</TableStyle.TableBody>
				</TableStyle.Table>
			</>
		);
	}

	if (
		pendingCardsBatchesQuery.isLoading ||
		concludedCardsBatchesQuery.isLoading
	) {
		return (
			<S.Container>
				<PageTitle title='Solicitações de remessa' />
				<Loader />

				<CreateCardBatchModal />
			</S.Container>
		);
	}

	return (
		<S.Container>
			{getTable(
				'Solicitações de remessa pendentes',
				pendingCardsBatchesFiltersParams.find((f) => !!f.value)
					? filteredPendingCardBatchesQuery.data?.cardBatches
					: pendingCardsBatchesQuery.data?.pendingCardsBatches
			)}
			{getTable(
				'Solicitações de remessa concluídas',
				concludedCardsBatchesFiltersParams.find((f) => !!f.value)
					? filteredConcludedCardBatchesQuery.data?.cardBatches
					: concludedCardsBatchesQuery.data?.concludedCardsBatches
			)}

			<CreateCardBatchModal />
		</S.Container>
	);
}
