import { ContentRenderer, Popover } from 'react-tiny-popover';

interface HeaderPopoverProps {
	isOpen: boolean;
	content: JSX.Element | ContentRenderer;
	onClose: () => void;
	children: JSX.Element;
	zIndex?: string | undefined;
}
export function HeaderPopover({
	children,
	isOpen,
	onClose,
	content,
	zIndex,
}: HeaderPopoverProps) {
	return (
		<Popover
			isOpen={isOpen}
			positions={['bottom', 'left', 'right']} // preferred positions by priority
			onClickOutside={onClose}
			containerStyle={{ zIndex: zIndex }}
			content={content}
		>
			{children}
		</Popover>
	);
}
