import { Switch, useRouteMatch, Route, Redirect } from 'react-router-dom';
import { Management } from '../pages/CorporateExpenses/Management';
import { UserDetails } from '../pages/CorporateExpenses/Management/Users/Details';
import { CreateUser } from '../pages/CorporateExpenses/Management/Users/Create';
import { Cards } from '../pages/CorporateExpenses/Cards';
import { CreateCard } from '../pages/CorporateExpenses/Cards/Create';
import { Home } from '../pages/CorporateExpenses/Home';
import { Wallet } from '../pages/CorporateExpenses/Wallet';
import { Support } from '../pages/CorporateExpenses/Support';
import { CostCenterDetails } from '../pages/CorporateExpenses/Management/CostCenters/Details';
import { CardDetails } from '../pages/CorporateExpenses/Cards/Details';
import { useAuth } from '../hooks/useAuth';
import PrivacyPolicy from '../pages/CorporateExpenses/Support/PrivacyPolicy';
import AntiFraudPolicy from '../pages/CorporateExpenses/Support/AntiFraudPolicy';
import { Profile } from '../pages/CorporateExpenses/Profile';
import TermsOfUse from '../pages/CorporateExpenses/Support/TermsOfUse';
import Transfers from '../pages/CorporateExpenses/Wallet/Transfers';

export function CorporateExpensesRoutes() {
	const { user, currentCompany } = useAuth();
	const { path } = useRouteMatch();

	const isUserManager =
		user.roles.includes('manage-users') &&
		user.roles.includes('manage-cost-centers');

	// Show fundsOut to all users admin OR show it only to Bounty company users admins
	const shouldShowFundsOut =
		(process.env.REACT_APP_FUNDS_OUT_ENABLED === 'true' ||
		process.env.REACT_APP_BOUNTY_COMPANY_ID === currentCompany?.id) && user.corpway_access_level === "general_admin";

	return (
		<Switch>
			<Route exact path={path} component={Home} />

			{(isUserManager || user.roles.includes('manage-balances')) && (
				<Route exact path={`${path}/management`} component={Management} />
			)}

			{isUserManager && (
				<Route path={`${path}/management`}>
					<Switch>
						<Route
							path={`${path}/management/users/create`}
							component={CreateUser}
						/>
						<Route
							path={`${path}/management/users/:id`}
							component={UserDetails}
						/>

						<Route
							path={`${path}/management/cost-centers/:id`}
							component={CostCenterDetails}
						/>
					</Switch>
				</Route>
			)}

			{(user.roles.includes('use-all-cards') ||
				user.roles.includes('use-own-cards') ||
				user.roles.includes('visualize-all-extracts')) && (
				<Route exact path={`${path}/cards`} component={Cards} />
			)}

			{user.roles.includes('manage-cards') && (
				<Route exact path={`${path}/cards/create`} component={CreateCard} />
			)}
			<Route exact path={`${path}/cards/:id`} component={CardDetails} />

			{user.roles.includes('manage-balances') && (
				<Route path={`${path}/wallet`}>
					<Switch>
						<Route
							exact
							path={`${path}/wallet`}
							component={Wallet}
						/>
						{shouldShowFundsOut && (
							<Route
								exact
								path={`${path}/wallet/transfers`}
								component={Transfers}
							/>
						)}
					</Switch>
				</Route>
			)}

			<Route path={`${path}/support`}>
				<Switch>
					<Route
						exact
						path={`${path}/support`}
						render={Support} />

					<Route
						path={`${path}/support/privacy-policy`}
						component={PrivacyPolicy}
					/>
					<Route
						path={`${path}/support/anti-fraude-policy`}
						component={AntiFraudPolicy}
					/>
					<Route path={`${path}/support/terms-of-use`} component={TermsOfUse} />
				</Switch>
			</Route>

			<Route path={`${path}/profile`} component={Profile} />

			<Redirect to='/404' />
		</Switch>
	);
}
