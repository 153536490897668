import React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styled from 'styled-components';

interface ContentTransitionProps {
	keyProp: string;
	children: React.ReactNode;
}

const ContentTransition: React.FC<ContentTransitionProps> = ({
	keyProp,
	children,
}) => (
	<ContentWrapper>
		<TransitionGroup>
			<CSSTransition key={keyProp} classNames='slide' timeout={300}>
				<div>{children}</div>
			</CSSTransition>
		</TransitionGroup>
	</ContentWrapper>
);

const ContentWrapper = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
	.slide-enter {
		position: absolute;
		transform: translateX(100%);
	}
	.slide-enter-active {
		transform: translateX(0%);
		transition: transform 300ms ease-in-out;
	}
	.slide-exit {
		position: absolute;
		transform: translateX(0%);
	}
	.slide-exit-active {
		transform: translateX(-100%);
		transition: transform 300ms ease-in-out;
	}
`;

export default ContentTransition;
