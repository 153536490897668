import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { OptionsButton } from '../../../../components/Button';

export const Container = styled.div`
	flex: 1;
	padding: 4rem 7rem 2rem 7rem;
	width: 100%;
	max-width: 95rem;
`;

export const Header = styled.div`
	display: flex;
	align-items: center;
`;

export const EditButton = styled(Link)`
	text-decoration: none;
	${OptionsButton}
	padding: 0.2rem 1.5rem;
	background-color: var(--white);
	color: var(--primary-blue);
	margin-left: 2rem;
`;

export const CompanyInfoContainer = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 3rem;
	/* width: 74%; */
	margin-top: 1rem;
	padding-top: 1rem;
	border-top: 0.2rem solid var(--light-gray);
`;

export const InfoTopContainer = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	font-size: 1.6rem;
	margin-top: 2.5rem;

	&:last-child {
		margin-top: 1rem;
		padding-left: 2rem;
		padding-top: 1.5rem;
		border-left: 0.1rem solid var(--light-gray);
	}
`;

export const CompanyLogo = styled.img`
	min-width: 18rem;
	max-width: 18rem;
	min-height: 18rem;
	max-height: 18rem;
	object-fit: cover;
	background-color: red;
	margin-right: 2rem;
	border-radius: 2rem;
`;

export const InfoGroupContainer = styled.div`
	display: flex;
	/* width: 80%; */
`;

export const InfoContainer = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
`;

export const Label = styled.span`
	color: var(--mid-gray);
	font-size: 1.2rem;
	margin-bottom: 0.6rem;
`;

export const Info = styled.span`
	color: var(--dark-gray);
	font-weight: 500;
	font-size: 1.6rem;
`;
