import styled from 'styled-components';
import { OptionsButton } from '../../../../components/Button';
import * as FormStyles from '../../../../components/Form/FormStyles';

export const Container = styled.div`
	flex: 1;
	padding: 4rem 7rem 2rem 7rem;
`;

export const FormContainer = styled(FormStyles.Form)`
	max-width: 55rem;
	margin-top: 2rem;
`;

export const Field = styled(FormStyles.Field)`
	margin: 2rem 0;
	&:first-child {
		margin: 1rem 0;
	}
`;

export const MainButton = styled.button`
	${OptionsButton}
`;

export const SegmentationOptionContainer = styled.div`
	display: flex;
	align-items: center;
	padding: 1rem;
	border-radius: 0.4rem;
	border: 0.1rem solid var(--dark-gray);
	column-gap: 2rem;
	width: 40rem;
	text-align: justify;
	margin-top: 3rem;
	& > svg {
		width: 4rem;
		height: 4rem;
	}
	& > div {
		display: flex;
		flex-direction: column;
	}
`;

export const SegmentationOptionTitle = styled.h3`
	color: var(--dark-gray);
`;

export const BodyCharsCounter = styled.span`
	font-size: 1.4rem;
	margin-left: auto;
`;

export const SubmitForm = styled(FormStyles.FormButton)`
	margin-top: 4rem;
`;

export const FieldHeader = styled.div`
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	margin-bottom: 0.7rem;
`;
