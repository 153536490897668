import React, { useState } from 'react';
import { useAuth } from '../../../../hooks/useAuth';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { SectionTitle } from '..';
import { Typography } from '../../../../componentsV2/ui/Typography';
import { InputComp } from '../../../../componentsV2/ui/Form/InputStyle';
import { AsynchronousContent } from '../../../../componentsV2/AsynchronousContent';
import styled from 'styled-components';
import { getUserTopExpenses } from '../../../../services/queries/Corpway/User';
import { FaTrophy } from 'react-icons/fa';
import avatarImg from '../../../../assets/avatar_icon.svg';
import { capitalizeFirstLetter } from '../../../../utils/masks';
import { MdOutlineCreditCard } from 'react-icons/md';
import { convertCentsToFormattedReais } from '../../../../utils/CurrencyConvert';
import { EmptyContent } from '../../../../componentsV2/EmptyContent';
import { parseUserAccessLevel } from '../../../../utils/corpway/parseUserAccessLevel';
import { CorpwayUserAccessLevel } from '../../../../@types/CorporateExpenses/User';
import { CORPWAY_CARDS_KEY } from '../../../../services/queries/Corpway/Cards';
import { DatesContainer } from '../styles';
import CustomTooltip from './components/CustomTooltip';

export function UserTopExpenses() {
	const { user, currentCompany } = useAuth();
	const january2024 = new Date('January 1, 2024'); // start of bounty control
	const currentDate = new Date();

	const [selectedDate, setSelectedDate] = useState(currentDate);

	const getUserTopExpensesQuery = useQuery(
		[
			CORPWAY_CARDS_KEY,
			'user-top-expenses',
			user.id,
			currentCompany?.id,
			selectedDate,
		],
		() => getUserTopExpenses(currentCompany!.id, selectedDate),
		{
			onError: () => {
				toast.error(
					'Ocorreu um problema ao buscar o relatório de usuário com mais gastos.'
				);
			},
			refetchOnWindowFocus: false,
		}
	);

	const topUser = getUserTopExpensesQuery.data?.usersTopExpenses[0]
		? {
				name: getUserTopExpensesQuery.data?.usersTopExpenses[0].name,
				email: getUserTopExpensesQuery.data?.usersTopExpenses[0].email,
				role: getUserTopExpensesQuery.data?.usersTopExpenses[0].role,
				avatar_url:
					getUserTopExpensesQuery.data?.usersTopExpenses[0].avatar_url,
				cards: getUserTopExpensesQuery.data?.usersTopExpenses[0].cards,
				id: getUserTopExpensesQuery.data?.usersTopExpenses[0].id,
				expenses: getUserTopExpensesQuery.data?.usersTopExpenses[0].expenses,
		  }
		: null;

	function getCardList() {
		const cardListLength =
			getUserTopExpensesQuery.data?.usersTopExpenses[0].cards.length ?? 0;

		if (cardListLength > 3) {
			return (
				<>
					<li
						key={getUserTopExpensesQuery.data?.usersTopExpenses[0].cards[0].id}
					>
						<MdOutlineCreditCard size={20} />
						{capitalizeFirstLetter(
							getUserTopExpensesQuery.data?.usersTopExpenses[0].cards[0].alias!
						)}
					</li>
					<li
						key={getUserTopExpensesQuery.data?.usersTopExpenses[0].cards[1].id}
					>
						<MdOutlineCreditCard size={20} />
						{capitalizeFirstLetter(
							getUserTopExpensesQuery.data?.usersTopExpenses[0].cards[1].alias!
						)}
					</li>
					<li
						key={getUserTopExpensesQuery.data?.usersTopExpenses[0].cards[2].id}
					>
						<MdOutlineCreditCard size={20} />
						{capitalizeFirstLetter(
							getUserTopExpensesQuery.data?.usersTopExpenses[0].cards[2].alias!
						)}
					</li>
					<li
						key={getUserTopExpensesQuery.data?.usersTopExpenses[0].cards[2].id}
					>
						<MdOutlineCreditCard size={20} />+{cardListLength - 3}
					</li>
				</>
			);
		}

		return getUserTopExpensesQuery.data?.usersTopExpenses[0].cards.map(
			(card) => (
				<li key={card.id}>
					<MdOutlineCreditCard size={20} />
					{capitalizeFirstLetter(card.alias)}
				</li>
			)
		);
	}
	return (
		<Container>
			<HeaderContainer>
				<style>
					{`
						@media (max-width: 768px) {
							.mobileStyle {
								text-align: center !important;
							}
						
						}
					`}
				</style>
				<div style={{ display: "flex" }}>
					<div>
						<SectionTitle primaryText="Usuário"> top gastos</SectionTitle>
						<Typography
							className="mobileStyle"
							size="1.4rem"
							color="var(--mid-gray)"
							style={{ width: '24rem', marginTop: '0.1rem' }}
						>
							Gastos mensais dos usuários que mais transacionaram
						</Typography>
					</div>
					<CustomTooltip />
				</div>

				<div
					style={{
						display: 'flex',
						justifyContent: 'flex-start',
						alignItems: 'center',
						marginLeft: '2rem',
					}}
				>
					<DatesContainer>
						<InputComp
							type='month'
							min={january2024.toISOString().split('-').slice(0, 2).join('-')}
							max={currentDate.toISOString().split('-').slice(0, 2).join('-')}
							value={selectedDate
								.toISOString()
								.split('-')
								.slice(0, 2)
								.join('-')}
							style={{ marginTop: '0.6rem', paddingRight: '1rem' }}
							onChange={(e) => {
								const selectedMonth = e.target.value;
								const year = parseInt(selectedMonth.split('-')[0]);
								const month = parseInt(selectedMonth.split('-')[1]);
								setSelectedDate(new Date(year, month - 1, 1));
							}}
						/>
					</DatesContainer>
				</div>
			</HeaderContainer>

			<AsynchronousContent status={getUserTopExpensesQuery.status}>
				<MainContent>
					{!topUser ? (
						<EmptyContent text='Não há usuário a ser exibido.' />
					) : (
						<>
							<UserPresentation>
								<AvatarContainer>
									<AvatarImg src={topUser.avatar_url || avatarImg} />
									<TrophyIcon size={14}>
										<FaTrophy />
									</TrophyIcon>
								</AvatarContainer>

								<UserInfo>
									<Typography
										size='2.4rem'
										color='var(--primary-blue)'
										weight='600'
									>
										{topUser.name}
									</Typography>
									<Typography size='1.4rem' color='var(--gray)' weight='600'>
										{parseUserAccessLevel(
											topUser.role as CorpwayUserAccessLevel
										)}
									</Typography>
									<Typography size='1.2rem' color='var(--gray)'>
										{topUser.email}
									</Typography>
								</UserInfo>
							</UserPresentation>

							<CardList>{getCardList()}</CardList>

							<TotalExpensesContainer>
								<Typography size='2.5rem' color='var(--dark-gray)' weight='600'>
									Total:
								</Typography>

								<Typography
									size='2.5rem'
									color='var(--primary-blue)'
									weight='600'
								>
									{convertCentsToFormattedReais(topUser.expenses ?? 0)}
								</Typography>
							</TotalExpensesContainer>
						</>
					)}
				</MainContent>
			</AsynchronousContent>
		</Container>
	);
}

const Container = styled.div`
	display: grid;
	gap: 2rem;
	min-height: 32.5rem;
	@media (max-width: 650px) {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		gap: 0rem;
	}
`;

const HeaderContainer = styled.div`
	display: flex;
	gap: 5rem;
	align-items: flex-start;
	justify-content: space-between;
	@media (max-width: 650px) {
		flex-direction: column;
		gap: 0.1rem;
	}
`;

const MainContent = styled.div`
	display: flex;
	flex-direction: column;
	gap: 2.4rem;
	margin-top: 1.5rem;
`;

const UserPresentation = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	@media (max-width: 650px) {
		flex-direction: column;
	}
`;

const AvatarContainer = styled.div`
	position: relative;
	width: 140.5px;
	height: 140.5px;
	overflow: hidden;
`;

export const AvatarImg = styled.img`
	width: 100%;
	height: 100%;
	border-radius: 100%;
	border: 0.4rem solid var(--primary-blue);
	background-color: #f0f3f8;
	object-fit: cover;
`;

export const TrophyIcon = styled.div<{ size: number }>`
	width: 100%;
	height: 100%;

	& > svg {
		position: absolute;
		top: 0;
		left: 0;
		width: ${({ size }) => size / 3}rem;
		height: ${({ size }) => size / 3}rem;
		border-radius: 100%;
		border: 0.3rem solid #febe14;
		background-color: #fff6c7;
		color: #febe14;
		padding: 0.8rem;
	}
`;

const UserInfo = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	gap: 0.5rem;
	width: 70%;
	padding: 1rem 0rem 1rem 4rem;
	@media (max-width: 650px) {
		width: 100%;
		padding: 0rem;
		align-items: center;
	}
`;

const CardList = styled.div`
	list-style: none;
	display: flex;
	width: 100%;
	flex-wrap: wrap;
	gap: 1rem;
	justify-content: flex-end;

	& > li {
		display: flex;
		align-items: center;
		gap: 0.5rem;
		min-width: max-content;
		padding: 0.6rem 1.2rem;
		border: 0.1rem solid var(--light-gray);
		border-radius: 10rem;
		font-size: 1.2rem;
		line-height: 2rem;
		font-weight: 400;

		background-color: var(--dark-gray);
		color: white;
	}
	@media (max-width: 650px) {
		justify-content: flex-start;
	}
`;

const TotalExpensesContainer = styled.div`
	display: flex;
	width: 100%;
	justify-content: flex-end;
	gap: 1rem;
	@media (max-width: 650px) {
		justify-content: center;
	}
`;
