import { Benefit } from '../../@types';
import { BenefitWithValue } from '../../pages/FlexibleBenefits/Chargebacks/CreateChargeback/BenefitSelectorToChargeback';
import api from '../api';

export interface UpdateBenefitParams {
	benefits: {
		id: string;
	}[];
	collaboratorId: string;
}

export async function getBenefits(
	companyId?: string,
	includeRefund = false
): Promise<Benefit[]> {
	const { data } = await api.get(
		`api/v1/rh/companies/${companyId}/benefits/q?refund=${includeRefund}`
	);
	return data;
}

export async function getBenefitsWithValue(
	userId: string,
	companyId?: string
): Promise<BenefitWithValue[]> {
	const { data } = await api.get(
		`api/v1/rh/companies/${companyId}/balances/collaborators/${userId}`
	);
	return data;
}

export async function getCollaboratorBenefits(
	collaboratorId?: string
): Promise<Benefit[]> {
	const { data } = await api.get(
		`api/v1/rh/collaborators/${collaboratorId}/benefits`
	);
	return data;
}

export async function updateCollaboratorBenefits({
	collaboratorId,
	benefits,
}: UpdateBenefitParams): Promise<Benefit[]> {
	const { data } = await api.patch(
		`api/v1/rh/collaborators/${collaboratorId}/benefits`,
		{ benefits }
	);
	return data;
}
