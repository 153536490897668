import { MdNavigateBefore, MdNavigateNext } from 'react-icons/md';
import styled from 'styled-components';

interface CarouselProps {
	content: JSX.Element[];
	onChange: (index: number) => void;
	currentIndex: number;
	className?: string;
	infinity?: boolean;
}

export function Carousel({
	content,
	currentIndex,
	onChange,
	className,
	infinity = false,
}: CarouselProps) {
	function handleChangeIndex(index: number) {
		onChange(index);
	}

	const nextItem = () => {
		if (!infinity) return currentIndex + 1;

		if (currentIndex + 1 === content.length) return 0;

		return currentIndex + 1;
	};

	const previousItem = () => {
		if (!infinity) return currentIndex - 1;

		if (currentIndex === 0) return content.length - 1;

		return currentIndex - 1;
	};

	return (
		<Container className={className}>
			<CarouselContainer>
				<ActionButton
					disabled={currentIndex === 0 && !infinity}
					onClick={() => handleChangeIndex(previousItem())}
					type='button'
				>
					<MdNavigateBefore />
				</ActionButton>
				<ContentContainer>
					{content.slice(currentIndex, currentIndex + 1)}
				</ContentContainer>
				<ActionButton
					disabled={currentIndex === content.length - 1 && !infinity}
					onClick={() => handleChangeIndex(nextItem())}
					type='button'
				>
					<MdNavigateNext />
				</ActionButton>
			</CarouselContainer>

			{!infinity && (
				<div style={{ display: 'flex', gap: '.5rem', alignItems: 'center' }}>
					{Array.from({ length: content.length }).map((_, i) => (
						<Dot key={i} active={i === currentIndex} />
					))}
				</div>
			)}
		</Container>
	);
}

export const Container = styled.div`
	display: grid;
	row-gap: 1rem;
	justify-items: center;
`;
export const CarouselContainer = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	column-gap: 1rem;
	@media (max-width: 1024px) {
		column-gap: 0;
	}
`;

export const ActionButton = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 6rem;
	height: 6rem;
	background: none;
	& > svg {
		width: 100%;
		height: 100%;
		color: var(--primary-blue);
	}

	@media (max-width: 1024px) {
		width: 4rem;
	}
`;
export const ContentContainer = styled.div`
	display: flex;
	justify-content: space-around;
	align-items: flex-start;
	width: 100%;
`;
export const Dot = styled.div<{ active: boolean }>`
	width: ${({ active }) => (active ? '.8rem' : '0.6rem')};
	height: ${({ active }) => (active ? '.8rem' : '0.6rem')};
	border-radius: 50%;
	transition: all 0.1s;
	background-color: var(--primary-blue);
`;
