import styled from 'styled-components';
import { OptionsButton } from '../Button';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	min-width: 50rem;
	min-height: 20rem;
	max-height: 80vh;
	row-gap: 1rem;
`;

export const Title = styled.h1`
	color: var(--primary-blue);
`;

export const HintText = styled.span`
	font-size: 1.4rem;
	font-weight: 500;
`;

export const Table = styled.table`
	width: 100%;
	max-height: 80vh;
	overflow-y: auto;
	font-size: 1.4rem;
	padding: 0.5rem;
`;

export const Row = styled.tr``;

export const Head = styled.th`
	text-align: left;
	text-transform: uppercase;
`;

export const Data = styled.td`
	padding: 0.5rem 0;
	border-bottom: 1px solid black;
`;
export const ErrorData = styled.td`
	padding: 0.5rem 0;
	color: var(--red);
	border-bottom: 1px solid black;
`;

export const GroupCard = styled.div`
	display: flex;
	align-items: center;
	font-size: 1.6rem;
	& svg {
		width: 4rem;
		height: 4rem;
		border-radius: 50%;
		margin-right: 1rem;
		color: var(--white);
		background-color: var(--primary-blue);
		padding: 0.4rem;
	}
`;

export const GroupInfoContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

export const GroupCollaborators = styled.span`
	font-size: 1.4rem;
	color: var(--light-gray);
	& span {
		color: var(--primary-blue);
	}
`;

export const ConfirmButton = styled.button`
	${OptionsButton}
	margin: 0 auto;
`;
