import { create } from 'zustand';
import { FilterParams } from '../components/Filter';

type Store = {
	filtersParams: FilterParams[];
	setFiltersParams: (f: FilterParams[]) => void;
	resetFilters: () => void;
};

const filtersParams: FilterParams[] = [
	{ name: 'Nome', filter: 'name', value: '', type: 'text', selected: false },
	{ name: 'CPF', filter: 'cpf', value: '', type: 'cpf', selected: false },
	{
		name: 'Email',
		filter: 'email',
		value: '',
		type: 'email',
		selected: false,
	},
	{
		name: 'Cargo',
		filter: 'office',
		value: '',
		type: 'text',
		selected: false,
	},
];

const useOperatorsListStore = create<Store>()((set) => ({
	filtersParams,
	setFiltersParams: (f) => set({ filtersParams: f }),
	resetFilters: () => set({ filtersParams }),
}));

export { useOperatorsListStore };
