import { FaCheckCircle } from 'react-icons/fa';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { Message } from '../../../../@types';
import Loader from '../../../../components/Loader';
import PageTitle from '../../../../components/PageTitle';
import { getMessage } from '../../../../services/queries/Messages';
import { cnpjMask } from '../../../../utils/masks';
import * as S from './styles';
import avatarImg from '../../../../assets/avatar.svg';
import { showErrorMessage } from '../../../../utils/ErrorHandler';

interface MessageDetailsParams {
	id: string;
}
export function MessageDetails() {
	const { id } = useParams<MessageDetailsParams>();

	const { data, isLoading } = useQuery(
		['message', id],
		() => {
			return getMessage(id);
		},
		{
			onError: (err) => {
				showErrorMessage(err as Error, 'Não foi possível buscar a mensagen. ');
			},
		}
	);

	function parseSegmentationTitle(message: Message) {
		if (message.segmentation === 'custom') {
			return 'Customizado';
		} else if (message.segmentation === 'group') {
			return 'Grupo corporativo';
		} else if (message.segmentation === 'company') {
			return message.company_data?.name;
		}
		return '';
	}

	if (isLoading || !data) {
		return (
			<S.Container>
				<PageTitle title='Detalhes da mensagem' />
				<Loader />
			</S.Container>
		);
	}
	return (
		<S.Container>
			<PageTitle title='Detalhes da mensagem' />
			<S.Content>
				<S.InfoContainer>
					<S.InfoTitle>Título</S.InfoTitle>
					{data.title}
				</S.InfoContainer>

				<S.InfoContainer>
					<S.InfoTitle>Corpo da mensagem</S.InfoTitle>
					<S.BodyContainer
						dangerouslySetInnerHTML={{
							__html: data.body,
						}}
					></S.BodyContainer>
				</S.InfoContainer>

				<S.InfoContainer>
					<S.InfoTitle>Segmentação</S.InfoTitle>
					<S.SegmentationContainer>
						<FaCheckCircle color='#1CCA0D' />
						<S.SegmentationTypeContainer>
							<h4>{parseSegmentationTitle(data!)}</h4>
							{data.company_data && (
								<span>{cnpjMask(data!.company_data.cnpj)}</span>
							)}
						</S.SegmentationTypeContainer>
					</S.SegmentationContainer>

					{data.segmentation === 'custom' && data.message_items?.length && (
						<S.CollaboratorsList>
							{data.message_items.map((message) => (
								<S.CollaboratorCard key={message.collaborator.id}>
									<S.AvatarContainer>
										<S.Avatar
											src={
												message.collaborator.avatar
													? message.collaborator.avatar_url
													: avatarImg
											}
										/>
										<S.CollabName>{`${message.collaborator.first_name} ${message.collaborator.last_name}`}</S.CollabName>
									</S.AvatarContainer>

									<S.CollabOffice>{message.collaborator.office}</S.CollabOffice>
								</S.CollaboratorCard>
							))}
						</S.CollaboratorsList>
					)}
				</S.InfoContainer>
			</S.Content>
		</S.Container>
	);
}
