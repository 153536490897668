import styled from 'styled-components';
import { OptionsButton } from '../../../../../components/Button';

export const OpenButton = styled.button`
	${OptionsButton}
	width: fit-content;
`;

export const Container = styled.div`
	width: 36rem;
	min-height: 15rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	row-gap: 2rem;
`;

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const QRCodeFigure = styled.figure`
	display: flex;
	flex-direction: column;
	align-items: center;
`;
export const QRCodeImg = styled.img``;

export const CopyQRCodeBtn = styled.button`
	${OptionsButton}
	width: 15rem;
	height: 4rem;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const QRCodeButtonsContainer = styled.div`
	display: flex;
	gap: 1.5rem;
`;

export const QRcodeCaption = styled.figcaption`
	margin-bottom: 1rem;
	color: var(--dark-gray);
	font-size: 1.5rem;
	text-align: center;
`;

export const Caption = styled.span`
	width: 100%;
	& > span {
		font-size: 1.5rem;
	}
`;
