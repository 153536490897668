import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Modal from '../../../../../components/Modal';
import * as FormStyle from '../../../../../components/Form/FormStyles';
import * as S from '../styles';
import {
	ObrigatoryFieldsIndication,
	ObrigatoryIndicator,
} from '../../../../../components/ObrigatoryFieldsIndicator';
import { toast } from 'react-toastify';
import { cepMask } from '../../../../../utils/masks';
import { UF_List } from '../../../../../utils/CheckUF';
import { AddressBody } from '../../../../../@types';
import {
	cepPattern,
	onlyLettersAndSpacesPattern,
	addressesFieldsCollaboratorSWAPPattern,
} from '../../../../../utils/patterns';
import { trimObjectData } from '../../../../../utils/trimObjectData';
import { SearchCEPButton } from '../../../../../components/SearchCEPButton';
import { useGetAddressByCEP } from '../../../../../hooks/useGetAddressByCEP';

export interface CustomAddressModalProps {
	address: AddressBody;
	onSubmit: (address: AddressBody) => void;
}
export function CustomAddressModal({
	address,
	onSubmit,
}: CustomAddressModalProps) {
	const [isOpen, setIsOpen] = useState(false);
	const { register, reset, handleSubmit, getValues } = useForm();

	useEffect(() => {
		if (isOpen) {
			reset({ ...address });
		}
	}, [isOpen, reset, address]);

	const { searchAddressByCEP, isSearchingCEP } = useGetAddressByCEP({
		onAddressFoundCallback: (address) => {
			reset({ ...getValues(), ...address }); // reset the form filling with the fetched address
		},
	});

	function handleSubmitForm(address: AddressBody) {
		setIsOpen(false);

		const data: AddressBody = trimObjectData(address);
		onSubmit({
			...data,
			complement: data.complement || undefined,
			reference: data.reference || undefined,
		});
	}

	function onInvalidInput(message: string) {
		toast.error(message);
	}

	return (
		<>
			<S.CustomAddressOpenBtn
				onClick={() => {
					setIsOpen(true);
				}}
			>
				DEFINIR
			</S.CustomAddressOpenBtn>
			<Modal
				isOpen={isOpen}
				enableClose
				onRequestClose={() => setIsOpen(false)}
			>
				<S.Container style={{ width: '70rem' }}>
					<S.Title>Endereço de entrega</S.Title>
					<FormStyle.Form
						onSubmit={handleSubmit(handleSubmitForm)}
						autoCorrect='off'
						autoComplete='off'
					>
						<FormStyle.FieldSet>
							<FormStyle.FieldGroup>
								<FormStyle.Field>
									<FormStyle.Label>
										Endereço <ObrigatoryIndicator />
									</FormStyle.Label>
									<FormStyle.Input
										maxLength={50}
										type='text'
										{...register('address')}
										pattern={addressesFieldsCollaboratorSWAPPattern}
										onInvalid={(e) => {
											if (e.currentTarget.value === '') return;
											onInvalidInput(
												"Utilize apenas letras, espaços e (.)(,)(')(/)(-) no campo de endereço"
											);
										}}
										name='address'
										required
										data-testid='addressInput_test_id'
									/>
								</FormStyle.Field>

								<FormStyle.Field style={{ maxWidth: '10%' }}>
									<FormStyle.Label>
										Número <ObrigatoryIndicator />
									</FormStyle.Label>
									<FormStyle.Input
										type='number'
										{...register('number')}
										name='number'
										onInput={(e) => {
											const input = e.target as HTMLInputElement;
											if (input.value.length > 10) {
												input.value = input.value.slice(0, 10);
											}
										}}
										required
									/>
								</FormStyle.Field>

								<FormStyle.Field>
									<FormStyle.Label>
										Bairro <ObrigatoryIndicator />
									</FormStyle.Label>
									<FormStyle.Input
										maxLength={30}
										type='text'
										{...register('district')}
										name='district'
										pattern={addressesFieldsCollaboratorSWAPPattern}
										onInvalid={(e) => {
											if (e.currentTarget.value === '') return;
											onInvalidInput(
												"Utilize apenas letras, espaços e (.)(,)(')(/)(-) no campo de bairro"
											);
										}}
										required
										data-testid='districtInput_test_id'
									/>
								</FormStyle.Field>
							</FormStyle.FieldGroup>

							<FormStyle.FieldGroup>
								<FormStyle.Field style={{ maxWidth: '30%' }}>
									<FormStyle.Label>Complemento</FormStyle.Label>
									<FormStyle.Input
										maxLength={30}
										type='text'
										{...register('complement')}
										name='complement'
										data-testid='complementInput_test_id'
									/>
								</FormStyle.Field>

								<FormStyle.Field style={{ maxWidth: '30%' }}>
									<FormStyle.Label>Referência</FormStyle.Label>
									<FormStyle.Input
										maxLength={30}
										type='text'
										{...register('reference')}
										name='reference'
									/>
								</FormStyle.Field>

								<FormStyle.Field>
									<FormStyle.Label>
										Cidade <ObrigatoryIndicator />
									</FormStyle.Label>
									<FormStyle.Input
										maxLength={30}
										type='text'
										{...register('city')}
										name='city'
										pattern={onlyLettersAndSpacesPattern}
										onInvalid={(e) => {
											if (e.currentTarget.value === '') return;
											onInvalidInput(
												'Utilize apenas letras e espaços no campo de cidade'
											);
										}}
										required
										data-testid='cityInput_test_id'
									/>
								</FormStyle.Field>

								<FormStyle.Field style={{ maxWidth: '10%' }}>
									<FormStyle.Label>
										Estado <ObrigatoryIndicator />
									</FormStyle.Label>
									<FormStyle.SelectInput
										{...register('uf')}
										name='uf'
										defaultValue=''
										required
										data-testid='ufInput_test_id'
									>
										<option disabled value=''>
											Selecione uma opção
										</option>
										{UF_List.map((uf) => (
											<option key={uf} value={uf}>
												{uf}
											</option>
										))}
									</FormStyle.SelectInput>
								</FormStyle.Field>
							</FormStyle.FieldGroup>
						</FormStyle.FieldSet>
						<FormStyle.FieldGroup>
							<FormStyle.Field style={{ maxWidth: '15%' }}>
								<FormStyle.Label>
									CEP <ObrigatoryIndicator />
								</FormStyle.Label>
								<FormStyle.Input
									type='text'
									{...register('cep')}
									name='cep'
									pattern={cepPattern}
									placeholder='Ex: 99999-999'
									required
									data-testid='cepInput_test_id'
									onChange={(e) => {
										const { value } = e.target;
										e.target.value = cepMask(value);
									}}
								/>
							</FormStyle.Field>
							<SearchCEPButton
								isLoading={isSearchingCEP}
								onClick={() => searchAddressByCEP(getValues().cep)}
							/>
						</FormStyle.FieldGroup>

						<ObrigatoryFieldsIndication />

						<S.FooterOptionsContainer style={{ margin: '2rem 0' }}>
							<S.BackBtn
								type='button'
								onClick={() => {
									setIsOpen(false);
								}}
							>
								Voltar
							</S.BackBtn>
							<S.MainBtn type='submit'>Confirmar</S.MainBtn>
						</S.FooterOptionsContainer>
					</FormStyle.Form>
				</S.Container>
			</Modal>
		</>
	);
}
