import { useQuery } from 'react-query';
import * as S from './styles';
import { getCardsStats } from '../../../../../services/queries/Collaborators';
import { useAuth } from '../../../../../hooks/useAuth';
import { showErrorMessage } from '../../../../../utils/ErrorHandler';

export function CardsStats() {
	const { currentCompany } = useAuth();

	const activeCollabsCardsStatsQuery = useQuery(
		['getCardsStats', currentCompany?.id],
		() => getCardsStats(currentCompany!.id),
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível buscar os quantitativos de cartões dos colaboradores. '
				);
			},
		}
	);

	if (!activeCollabsCardsStatsQuery.data) return null;
	return (
		<div style={{ marginBottom: '2rem' }}>
			<S.StatsContainer>
				<h3>Total de cartões solicitados para endereços da empresa:</h3>
				<span>{activeCollabsCardsStatsQuery.data?.totalCardsRequested}</span>
			</S.StatsContainer>
			<S.StatsContainer>
				<h3>Colaboradores ativos com cartões:</h3>
				<span>{activeCollabsCardsStatsQuery.data?.collabsWithCard}</span>
			</S.StatsContainer>
			<S.StatsContainer>
				<h3>Colaboradores ativos sem cartões:</h3>
				<span>{activeCollabsCardsStatsQuery.data?.collabsWithoutCard}</span>
			</S.StatsContainer>
			<S.StatsContainer>
				<h3>Colaboradores ativos com cartões pendentes:</h3>
				<span>{activeCollabsCardsStatsQuery.data?.collabsWithCardPending}</span>
			</S.StatsContainer>
			<S.StatsContainer>
				<h3>Colaboradores ativos com cartões processando:</h3>
				<span>
					{activeCollabsCardsStatsQuery.data?.collabsWithCardProcessing}
				</span>
			</S.StatsContainer>
		</div>
	);
}
