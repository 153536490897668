import styled from 'styled-components';
import { BaseInputStyle } from './BaseStyle';

interface InputCompProps {
	error?: boolean;
}

export const InputComp = styled.input<InputCompProps>`
	-webkit-appearance: none;
	${BaseInputStyle}
	font-family: 'Poppins', sans-serif;
	font-size: 1.4rem;
	border: ${(props) =>
		props.error ? '2px solid var(--toastify-color-error)' : 'initial'};
	&::placeholder {
		color: var(--light-gray);
	}
	&:focus {
		outline: 1px solid var(--light-gray);
	}
`;
