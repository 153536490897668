export function formatCardNumber(cardNumber: string) {
	if (!cardNumber) {
		return null;
	}

	const fourDigits = cardNumber.slice(-4);
	return `**** ${fourDigits}`;
}

export function maskCardNumber(cardNumber: string) {
	return (
		cardNumber
			.slice(0, -4)
			.replace(/\d/g, '*')
			.replace(/(.{4})/g, '$1 ') + cardNumber.slice(-4)
	);
}
