import { InputHTMLAttributes } from 'react';
import { UseFormRegister } from 'react-hook-form';
import * as S from './styles';

interface ToggleSwitchProps extends InputHTMLAttributes<HTMLInputElement> {
	name?: string;
	register?: UseFormRegister<any>;
	shouldUnregister?: boolean;
}

export default function ToggleSwitch({
	name,
	register,
	shouldUnregister = true,
	...props
}: ToggleSwitchProps) {
	return (
		<S.CheckBoxWrapper>
			<S.CheckBox
				id={name}
				type='checkbox'
				{...(register
					? { ...register(name!, { shouldUnregister: shouldUnregister }) }
					: {})}
				{...props}
			/>
			<S.CheckBoxLabel htmlFor={name} />
		</S.CheckBoxWrapper>
	);
}
