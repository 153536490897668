import { zodResolver } from '@hookform/resolvers/zod';
import { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { MdCameraAlt, MdCheck, MdClose } from 'react-icons/md';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { z } from 'zod';
import { CorpwayUserAccessLevel } from '../../../../../@types/CorporateExpenses/User';
import avatarImg from '../../../../../assets/avatar_icon.svg';
import { Breadcrumb } from '../../../../../componentsV2/BreadCrumb';
import { Button } from '../../../../../componentsV2/ui/Button';
import { InputField } from '../../../../../componentsV2/ui/Form/InputField';
import { SectionContainer } from '../../../../../componentsV2/ui/SectionContainer';
import {
	TitleTypography,
	Typography,
} from '../../../../../componentsV2/ui/Typography';
import { useAuth } from '../../../../../hooks/useAuth';
import { CORPWAY_MANAGEMENT_KEY } from '../../../../../services/queries/Corpway/Management';
import {
	CORPWAY_USERS_KEY,
	createUser,
} from '../../../../../services/queries/Corpway/User';
import { CostCenterSelect } from '../components/CostCenterSelect';
import { Carousel } from './components/Carousel';
import * as S from './styles';
import InformationModal from '../../../../../componentsV2/ui/Modal/InformationModal';
import { ACCESS_LEVELS } from '../../../../../utils/corpway/accessLevelsMapping';
import { CreateCostCenterModal } from '../../CostCenters/components/CreateModal';

const userAccessFormSchema = z
	.object({
		avatar: z.any(),
		name: z.string().min(1, { message: 'Nome deve ser preenchido' }),
		email: z
			.string()
			.min(1, { message: 'Email deve ser preenchido' })
			.email({ message: 'Email inválido' }),
		confirm_email: z.string().min(1, { message: 'Email deve ser preenchido' }),
		cost_center_ids: z.array(z.string()),
	})
	.refine(({ email, confirm_email }) => email === confirm_email, {
		message: 'Os campos de email e confirmar email devem ser iguais',
		path: ['confirm_email'],
	});

type UserAccessFormData = z.infer<typeof userAccessFormSchema>;

const apiErrorSchema = z.object({
	message: z.string(),
});

type SetErrorType = (
	name: keyof UserAccessFormData,
	error: { type: string; message: string }
) => void;

export function CreateUser() {
	const TOTAL_STEPS = 2;
	const { currentCompany } = useAuth();
	const queryClient = useQueryClient();
	const [currentStep, setCurrentStep] = useState(0);
	const [selectedAccessLevel, setSelectedAccessLevel] =
		useState<CorpwayUserAccessLevel>('general_admin');

	const [modalVisible, setModalVisible] = useState(false);
	const history = useHistory();

	const createUserMutation = useMutation(
		({ formData, setError }: { formData: FormData; setError: SetErrorType }) =>
			createUser(formData, currentCompany!.id),
		{
			onSuccess: () => {
				queryClient.invalidateQueries([CORPWAY_USERS_KEY]);
				queryClient.invalidateQueries([CORPWAY_MANAGEMENT_KEY]);
				setModalVisible(true);
			},
			onError: (err: any, variables) => {
				const parsedError = apiErrorSchema.safeParse(err.response.data);

				if (parsedError.success) {
					const message = 'Esse e-mail já está em uso!';
					variables.setError('email', {
						type: 'manual',
						message: message,
					});
				} else {
					console.log(err);
				}
			},
		}
	);

	const handleSubmitForm = (
		userData: UserAccessFormData,
		reset: any,
		setError: SetErrorType
	) => {
		const formData = new FormData();
		formData.append('name', userData.name);
		formData.append('email', userData.email);
		formData.append('cost_center_ids', userData.cost_center_ids.toString());
		formData.append('corpway_access_level', selectedAccessLevel);

		if (userData.avatar) {
			formData.append('avatar', userData.avatar);
		}
		createUserMutation.mutate({ formData, setError });
	};

	function highlightSecondWord(sentence: string) {
		const words = sentence.split(' ');

		words[1] = words[1].charAt(0).toUpperCase() + words[1].slice(1);

		return (
			<>
				{words.slice(0, 1).join(' ')}{' '}
				<span
					style={{
						color: 'var(--primary-blue)',
						fontFamily: 'Poppins',
						fontSize: '12px',
						fontWeight: 600,
						lineHeight: '18px',
						textAlign: 'left',
					}}
				>
					{words[1]}
				</span>{' '}
				{words.slice(2).join(' ')}
			</>
		);
	}

	const activeAccessLevel = ACCESS_LEVELS.find(
		(level) => level.value === selectedAccessLevel
	);

	const UserAccessForm = () => (
		<S.FormContainer
			onSubmit={(e) => {
				e.preventDefault();
				setCurrentStep((state) => state + 1);
			}}
		>
			<TitleTypography weight='600' size='2.4rem'>
				Qual o{' '}
				<span style={{ color: 'var(--primary-blue)' }}>
					&nbsp;nível de acesso&nbsp;
				</span>{' '}
				do novo usuário?
			</TitleTypography>
			<div
				style={{
					display: 'flex',
					alignItems: 'flex-start',
					gap: '2rem',
				}}
			>
				<S.CarouselContainer>
					<Carousel
						infinity
						currentIndex={ACCESS_LEVELS.findIndex(
							(level) => level.value === selectedAccessLevel
						)}
						onChange={(index) =>
							setSelectedAccessLevel(
								ACCESS_LEVELS[index].value as CorpwayUserAccessLevel
							)
						}
						content={ACCESS_LEVELS.map((level) => (
							<S.AccessCard key={level.title}>
								<img src={level.image} alt={level.title} />
								<div
									style={{
										display: 'flex',
										flex: 1,
										flexDirection: 'column',
										alignItems: 'center',
										padding: '2.4rem 2.4rem 0 2.4rem',
										gap: '2rem',
									}}
								>
									<Typography weight='600' size='1.6rem'>
										{level.title}
									</Typography>
									<p
										style={{
											fontFamily: 'Poppins',
											width: '216px',
											height: '76px',
											fontSize: '12px',
											fontWeight: 400,
											lineHeight: '18px',
											textAlign: 'center',
										}}
									>
										{level.description[0]}
									</p>
								</div>
							</S.AccessCard>
						))}
					></Carousel>
				</S.CarouselContainer>

				<S.InfoContainer>
					{activeAccessLevel && (
						<>
							<p>
								{highlightSecondWord(activeAccessLevel.description[0])}{' '}
								{activeAccessLevel.description[1]}
							</p>

							<ul>
								{activeAccessLevel.topics.map((topic, index) => (
									<li key={index}>{topic}</li>
								))}
							</ul>
						</>
					)}
				</S.InfoContainer>
			</div>

			<S.SubmitContainer>
				<Button width='adjusted' roundness='lg' type='submit'>
					Avançar
				</Button>
			</S.SubmitContainer>
		</S.FormContainer>
	);

	const ConfirmationModalDescription = () => {
		return (
			<S.DescriptionContainer>
				<p>
					As informações de acesso foram encaminhadas{' '}
					<strong>para o e-mail:</strong>{' '}
					<span>{createUserMutation.data?.data.email}</span>
				</p>
			</S.DescriptionContainer>
		);
	};

	const Form = useCallback(() => {
		const COMPONENTS = {
			0: <UserAccessForm />,
			1: (
				<UserDataForm
					onSubmit={handleSubmitForm}
					onBack={() => setCurrentStep(0)}
					selectedAccess={selectedAccessLevel}
					isLoading={createUserMutation.isLoading}
				/>
			),
		} as const;

		return (
			<>
				{COMPONENTS[currentStep as keyof typeof COMPONENTS]};
				<InformationModal
					title={[
						{ text: 'Usuário criado ', isBold: true },
						{ text: 'com sucesso!' },
					]}
					titleIcon={<MdCheck size={30} color={'white'} />}
					color={'primary'}
					backButtonText={'Fechar'}
					onBack={() => {
						setModalVisible(false);
						history.push('/corporate-expenses/management');
					}}
					isOpen={modalVisible}
					onRequestClose={() => {
						setModalVisible(false);
						history.push('/corporate-expenses/management');
					}}
					width='44rem'
				>
					<ConfirmationModalDescription />
				</InformationModal>
			</>
		);
	}, [selectedAccessLevel, currentStep, createUserMutation.isSuccess]);

	// disable all steps after current step
	const disabledSteps = Array.from({ length: TOTAL_STEPS }, (_, i) => i).filter(
		(s) => s > currentStep
	);

	return (
		<div>
			<SectionContainer>
				<Breadcrumb
					routes={[
						{ title: 'Gestão', route: '/corporate-expenses/management' },
						{
							title: 'Cadastro de novo usuário',
							route: '/corporate-expenses/management/users/create',
						},
					]}
				/>
				<S.TitleContainer>
					<TitleTypography image>Cadastro de novo usuário</TitleTypography>
				</S.TitleContainer>
			</SectionContainer>
			<S.ResponsiveSectionContainer>
				<Form />
			</S.ResponsiveSectionContainer>
		</div>
	);
}

interface UserDataFormProps {
	onSubmit: (
		data: UserAccessFormData,
		reset: any,
		setError: (
			name: keyof UserAccessFormData,
			error: { type: string; message: string }
		) => void
	) => void;
	onBack: () => void;
	selectedAccess: CorpwayUserAccessLevel;
	isLoading: boolean;
}

const UserDataForm = ({
	onSubmit,
	onBack,
	selectedAccess,
	isLoading,
}: UserDataFormProps) => {
	const [costCenterWarningVisible, setCostCenterWarningVisible] =
		useState(false);
	const {
		register,
		handleSubmit,
		watch,
		setValue,
		formState: { errors, isSubmitted },
		setError,
		reset,
	} = useForm<UserAccessFormData>({
		resolver: zodResolver(userAccessFormSchema),
		defaultValues: {
			avatar: undefined,
			name: '',
			email: '',
			confirm_email: '',
			cost_center_ids: [],
		},
	});

	const uploadedAvatar = watch('avatar');
	const selectedCostCenterIds = watch('cost_center_ids');
	const avatarURL = useMemo(
		() => (uploadedAvatar ? URL.createObjectURL(uploadedAvatar) : avatarImg),
		[uploadedAvatar]
	);
	const adminOrAssistant =
		selectedAccess === 'general_admin' || selectedAccess === 'finance';

	function handleSelectCostCenters(ids: string[]) {
		setValue('cost_center_ids', ids);

		if (ids.length > 0) {
			setError('cost_center_ids', {
				message: undefined,
			});
		} else if (isSubmitted) {
			setError('cost_center_ids', {
				message: 'Selecione pelo menos um centro de custo',
			});
		}
	}

	const handleSubmitForm = (data: UserAccessFormData) => {
		if (!adminOrAssistant && selectedCostCenterIds.length === 0) {
			setCostCenterWarningVisible(true);
			return;
		}
		onSubmit(
			{
				...data,
			},
			reset,
			setError
		);
		// Reset the form without resetting the errors
		reset(data, { keepErrors: true, keepValues: true });
	};

	const CenterCostDescriptionWarningModal = () => {
		return (
			<S.DescriptionWarningContainer>
				<p>
					Caso o centro de custo ainda não exista, é possível criá-lo fechando
					esta janela e selecionando o botão <br />
					<span>“Criar centro de custo”</span> na tela anterior
				</p>
			</S.DescriptionWarningContainer>
		);
	};

	return (
		<>
			<S.FormContainer onSubmit={handleSubmit(handleSubmitForm)}>
				<TitleTypography weight='600' size='2.4rem'>
					Preencha os{' '}
					<span style={{ color: 'var(--primary-blue)' }}>
						&nbsp;acessos&nbsp;
					</span>{' '}
					do novo usuário
				</TitleTypography>

				<S.InnerFormContainer>
					<S.AvatarContainer background={avatarURL}>
						<MdCameraAlt style={{ position: 'absolute' }} />
						<InputField
							type='file'
							onChange={(e) => {
								e.target.files && setValue('avatar', e.target.files[0]);
							}}
							multiple={false}
							accept='.jpg, .jpeg, .png'
							style={{ display: 'none' }}
						/>
					</S.AvatarContainer>

					<S.FormInputsContainer>
						<S.Column>
							<S.Row>
								<S.InputGroup>
									<InputField
										label='Nome completo'
										id='name'
										required
										name='name'
										register={register}
										errorMessage={errors.name?.message}
									/>
									<InputField
										label='E-mail de acesso'
										name='email'
										id='email'
										type='email'
										required
										register={register}
										errorMessage={errors.email?.message}
									/>
									<InputField
										label='Confirmar e-mail'
										name='confirm_email'
										id='confirm_email'
										type='email'
										required
										register={register}
										errorMessage={errors.confirm_email?.message}
									/>
								</S.InputGroup>
								<div>
									<CostCenterSelect
										allSelected={adminOrAssistant}
										selectedCostCenterIds={selectedCostCenterIds}
										onChangeSelection={handleSelectCostCenters}
										errorMessage={(errors.cost_center_ids as any)?.message}
									/>
								</div>
							</S.Row>

							<S.CreateCostCenterButtonContainers>
								<CreateCostCenterModal
									openBtn={
										<Button
											style={{
												color: 'var(--primary-blue)',
												borderColor: 'var(--primary-blue)',
												marginLeft: 'auto',
												paddingLeft: 0,
											}}
											intent='link'
										>
											+ Criar centro de custo
										</Button>
									}
								/>
							</S.CreateCostCenterButtonContainers>
						</S.Column>
					</S.FormInputsContainer>
				</S.InnerFormContainer>

				<S.SubmitContainer>
					<S.WebButton>
						<Button
							width='adjusted'
							roundness='lg'
							intent='terciary'
							$outline
							type='button'
							onClick={onBack}
						>
							Voltar
						</Button>
						<Button
							width='adjusted'
							roundness='lg'
							type='submit'
							shrinkOnLoading={false}
							loading={isLoading}
						>
							Finalizar
						</Button>
					</S.WebButton>
					<S.MobileButton>
						<Button
							style={{ width: '10rem' }}
							intent={'link'}
							type='button'
							onClick={onBack}
						>
							Voltar
						</Button>
						<Button
							width='full'
							roundness='lg'
							type='submit'
							shrinkOnLoading={false}
							loading={isLoading}
						>
							Finalizar
						</Button>
					</S.MobileButton>
				</S.SubmitContainer>
			</S.FormContainer>

			{costCenterWarningVisible && (
				<>
					<InformationModal
						title={[
							{ text: 'É obrigatória a ' },
							{ text: 'definição de ao menos um ', isBold: true },
							{ text: 'centro de custo ', isBold: true },
							{ text: 'para esse acesso' },
						]}
						titleIcon={<MdClose size={30} color={'white'} />}
						color={'secondary'}
						backButtonText={'Fechar'}
						titleWidth='100%'
						onBack={() => {
							setCostCenterWarningVisible(false);
						}}
						isOpen={costCenterWarningVisible}
						onRequestClose={() => {
							setCostCenterWarningVisible(false);
						}}
						width='48rem'
					>
						<CenterCostDescriptionWarningModal />
					</InformationModal>
				</>
			)}
		</>
	);
};
