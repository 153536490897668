import { HTMLAttributes, ReactNode } from 'react';
import { ContentCardContainer } from './styles';

interface Props extends HTMLAttributes<HTMLDivElement> {
	children?: ReactNode;
	className?: string;
	image?: string;
	productScreen?: boolean;
	staticContent?: boolean;
}

export function ContentCard({
	children,
	image,
	className,
	productScreen,
	staticContent,
	...rest
}: Props) {
	return (
		<ContentCardContainer
			className={className}
			productScreen={productScreen}
			static={staticContent}
			{...rest}
		>
			{children}
			<div className='imgWrapper'>{image && <img src={image} alt='' />}</div>
		</ContentCardContainer>
	);
}
