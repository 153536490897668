import { useAuth } from '../../hooks/useAuth';
import * as S from './styles';

export default function Footer() {
	const { user, currentProduct } = useAuth();

	return (
		<S.Container>
			{(user && currentProduct) || user?.access_level === 'super_admin' ? (
				<S.Copyright>
					&copy; {new Date().getFullYear()} Bounty - Bounty Tecnologia e
					Pagamentos LTDA {}
					{/*currentProduct !== 'corpway' && (
						<S.TermsLink to={getTermsLink()}>
							- Termos de uso e Aviso de privacidade
						</S.TermsLink>
					)*/}
				</S.Copyright>
			) : (
				<S.Copyright>
					&copy; {new Date().getFullYear()} Bounty - Bounty Tecnologia e
					Pagamentos LTDA
					{/* - { }
					<S.TermsLink to={'/terms'}>Termos de uso</S.TermsLink>
					{ } - { }
			<S.TermsLink to={'/privacy'}>Aviso de privacidade</S.TermsLink> */}
				</S.Copyright>
			)}
		</S.Container>
	);
}
