import { CorpwayUserAccessLevel } from '../../@types/CorporateExpenses/User';
import { ModalHeaderTextProps } from '../../componentsV2/ui/Modal/InformationModal';
import adminLogo from '../../assets/corpwayAccess/admin-logo.svg';
import gerenteLogo from '../../assets/corpwayAccess/gerente-logo.svg';
import assistenteLogo from '../../assets/corpwayAccess/assistente-logo.svg';
import colaboradorLogo from '../../assets/corpwayAccess/colaborador-logo.svg';

export interface IAccessLevelAction {
	from: CorpwayUserAccessLevel;
	to: CorpwayUserAccessLevel;
	message: ModalHeaderTextProps[];
	upgrade: boolean;
}

const downgradeToAssistantMessage = [
	{ text: 'Agora, este usuário só poderá ' },
	{ text: 'acessar extratos e gerenciar saldos ', isBold: true },
	{ text: 'em carteiras e cartões.' },
];

const downgradeToManagerMessage = [
	{ text: 'Agora, este usuário só poderá ' },
	{ text: 'acessar, bloquear e solicitar ', isBold: true },
	{ text: 'extratos de cartões que estão ' },
	{ text: 'sob sua responsabilidade.', isBold: true },
];

const downgradeToCollaboratorMessage = [
	{ text: 'Agora, este usuário só poderá ' },
	{ text: 'acessar, bloquear e solicitar ', isBold: true },
	{ text: 'extratos do ' },
	{ text: 'seu próprio cartão.', isBold: true },
];

const upgradeToAdminMessage = [
	{ text: 'Agora, este usuário terá ' },
	{ text: 'acesso total ', isBold: true },
	{ text: 'a todos os centros de custo e cartões, podendo usar ' },
	{ text: 'todas as suas funcionalidades.', isBold: true },
];

const upgradeToAssistantMessage = [
	{ text: 'Agora, este usuário poderá ' },
	{ text: 'acessar extratos e gerenciar saldos ', isBold: true },
	{ text: 'em carteiras e cartões.' },
];

const upgradeToManagerMessage = [
	{ text: 'Agora, este usuário poderá ' },
	{ text: 'acessar, bloquear e solicitar ', isBold: true },
	{ text: 'extratos de cartões que estão ' },
	{ text: 'sob sua responsabilidade.', isBold: true },
];

export const ACCESS_LEVELS_CHANGE_ACTIONS: IAccessLevelAction[] = [
	{
		from: 'general_admin',
		to: 'account_manager',
		message: downgradeToManagerMessage,
		upgrade: false,
	},
	{
		from: 'general_admin',
		to: 'finance',
		message: downgradeToAssistantMessage,
		upgrade: false,
	},
	{
		from: 'general_admin',
		to: 'card_responsible',
		message: downgradeToCollaboratorMessage,
		upgrade: false,
	},
	{
		from: 'account_manager',
		to: 'general_admin',
		message: upgradeToAdminMessage,
		upgrade: true,
	},
	{
		from: 'account_manager',
		to: 'finance',
		message: downgradeToAssistantMessage,
		upgrade: false,
	},
	{
		from: 'account_manager',
		to: 'card_responsible',
		message: downgradeToCollaboratorMessage,
		upgrade: false,
	},
	{
		from: 'finance',
		to: 'general_admin',
		message: upgradeToAdminMessage,
		upgrade: true,
	},
	{
		from: 'finance',
		to: 'account_manager',
		message: upgradeToManagerMessage,
		upgrade: true,
	},
	{
		from: 'finance',
		to: 'card_responsible',
		message: downgradeToCollaboratorMessage,
		upgrade: false,
	},
	{
		from: 'card_responsible',
		to: 'general_admin',
		message: upgradeToAdminMessage,
		upgrade: true,
	},

	{
		from: 'card_responsible',
		to: 'account_manager',
		message: upgradeToManagerMessage,
		upgrade: true,
	},

	{
		from: 'card_responsible',
		to: 'finance',
		message: upgradeToAssistantMessage,
		upgrade: true,
	},
];

export const ACCESS_LEVELS = [
	{
		value: 'general_admin',
		title: 'Administrador',
		image: adminLogo,
		topics: [
			'Criar e atualizar centros de custo;',
			'Incluir, editar e excluir usuários;',
			'Criar e atualizar cartões e responsáveis;',
			'Gerar boletos e QR Codes para pagamentos via Pix;',
			'Gerenciar e alocar saldos;',
			'Consultar extratos gerais dos centros de custo e dos cartões;',
			'Acessar dados sensíveis de todos os cartões e centros de custo;',
			'Bloquear cartões.',
		],
		description: [
			'O administrador possui o maior nível de acesso e liberdade para usar todas funcionalidades da plataforma.',
			'Usuários definidos como administradores podem:',
		],
	},
	{
		value: 'account_manager',
		title: 'Gerente',
		image: gerenteLogo,
		topics: [
			'Acessar dados sensíveis apenas dos cartões e centros de custo pelos quais é responsável;',
			'Bloquear apenas cartões que estão atrelados aos seus centros de custo;',
			'Consultar o extrato apenas de cartões atrelados aos seus centros de custo.',
		],
		description: [
			'O gerente possui o segundo nível mais alto de acesso à plataforma, com algumas restrições.',
			'Usuários definidos como gerentes podem:',
		],
	},
	{
		value: 'finance',
		title: 'Assistente',
		image: assistenteLogo,
		topics: [
			'Gerar boletos e QR Codes para pagamentos via pix;',
			'Realocar saldos de cartões e carteiras;',
			'Consultar extratos gerais, dos centros de custo e dos cartões.',
		],
		description: [
			'O assistente possui acesso limitado à plataforma e está restrito a ações operacionais.',
			'Usuários definidos como assistentes podem:',
		],
	},
	{
		value: 'card_responsible',
		title: 'Colaborador',
		image: colaboradorLogo,
		topics: [
			'Acessar dados sensíveis apenas dos seus próprios cartões;',
			'Bloquear apenas seus pŕoprios cartões;',
			'Consultar extrato apenas dos seus próprios cartões.',
		],
		description: [
			'O colaborador tem acesso restrito à plataforma e acessa apenas seus próprios cartões.',
			'Usuários definidos como colaboradores podem:',
		],
	},
	// {
	// 	value: 'custom',
	// 	title: 'Customizável',
	// 	image: customLogo,
	// 	topics: [],
	// },
];
