import { FormEvent, useEffect, useState } from 'react';
import { Collaborator } from '../../../../@types';
import PageTitle from '../../../../components/PageTitle';
import { SingleCollaboratorSelector } from './SingleCollaboratorSelector';
import * as FormStyles from '../../../../components/Form/FormStyles';
import * as S from './styles';
import {
	BenefitWithValue,
	BenefitSelectorToChargeback,
} from './BenefitSelectorToChargeback';
import { toast } from 'react-toastify';
import Loader from '../../../../components/Loader';
import { useAuth } from '../../../../hooks/useAuth';
import { useHistory } from 'react-router-dom';
import { useDialogModal } from '../../../../hooks/useDialogModal';
import { useMutation } from 'react-query';
import {
	chargebackBodyQuery,
	createChargeback,
	createChargebackToAllbenefits,
} from '../../../../services/queries/Chargebacks';
import { getBenefitParsedTitle } from '../../../../utils/benefits/getBenefitParsedTitle';
import PreventTransitionPrompt from '../../../../components/PreventTransitionPrompt';
import { benefitRepresentingAllID } from './BenefitSelectorToChargeback';
import { convertReaisToCents } from '../../../../utils/CurrencyConvert';
import { showErrorMessage } from '../../../../utils/ErrorHandler';

export function CreateChargeback() {
	const { user, currentCompany, updateCompanyBalance } = useAuth();
	const history = useHistory();
	const { openConfirmDialog, openOptionsDialog } = useDialogModal();
	const [selectedCollaborator, setSelectedCollaborator] =
		useState<Collaborator | null>(null);
	const [selectedBenefits, setSelectedBenefits] = useState<BenefitWithValue[]>(
		[]
	);
	const [value, setValue] = useState(0);
	const [justification, setJustification] = useState('');
	const [allowNavigation, setAllowNavigation] = useState(true);

	useEffect(() => {
		setAllowNavigation(
			!(
				value ||
				justification ||
				selectedBenefits.length ||
				selectedCollaborator
			)
		);
	}, [value, justification, selectedBenefits, selectedCollaborator]);

	useEffect(() => {
		setSelectedBenefits([]);
	}, [selectedCollaborator]);

	useEffect(() => {
		if (
			selectedBenefits.length &&
			selectedBenefits[0].benefit_id === benefitRepresentingAllID
		) {
			setValue(0);
		}
	}, [selectedBenefits]);

	function handleSubmitForm(e: FormEvent<HTMLFormElement>) {
		e.preventDefault();
		if (!selectedCollaborator || !selectedBenefits.length) {
			toast.error('Selecione um colaborador e um benefício pelo menos');
			return;
		}

		if (selectedBenefits[0].benefit_id === 'allBenefitsID') {
			openOptionsDialog(
				'Você tem certeza que deseja estornar todos os benefícios do colaborador?',
				'Sim',
				() => {
					createChargebackToAllBenefitsQuery.mutate(justification);
				},
				'Não',
				() => {
					return;
				}
			);
		} else {
			const benefit_ids = selectedBenefits.map((benefit) => benefit.benefit_id);

			const body = {
				value: convertReaisToCents(value),
				justification,
				benefit_ids,
				collaborator_id: selectedCollaborator!.id,
			};

			createChargebackQuery.mutate(body);
		}
	}

	function showSuccessMessage() {
		if (user.access_level === 'admin') {
			updateCompanyBalance.refetch();
		}
		const confirmationMessage =
			user.access_level === 'admin'
				? 'O estorno foi realizado com sucesso.'
				: `A solicitação de estorno foi criada.
      Um usuário ADMINISTRADOR deverá aprová-la para que os pagamentos sejam efetivados.`;

		openConfirmDialog(confirmationMessage, 'Entendi', () => {});
		history.push('/home/chargebacks');
	}

	function showReprovedChargebackBenefits(benefits: { title: string }[]) {
		const titles = benefits.map((benefit) => {
			return getBenefitParsedTitle(benefit.title);
		});
		let message =
			'Não foi possível realizar o estorno para tais benefícios: ' +
			titles.join(',');

		toast.warn(message);
	}

	const createChargebackQuery = useMutation(
		(body: chargebackBodyQuery) => {
			return createChargeback(body, currentCompany?.id);
		},
		{
			onSuccess: (data) => {
				setAllowNavigation(true);
				if (data.chargebackError.length) {
					showReprovedChargebackBenefits(data.chargebackError);
				}
				showSuccessMessage();
			},
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao tentar criar o estorno. '
				);
			},
		}
	);

	const createChargebackToAllBenefitsQuery = useMutation(
		(justification: string) => {
			return createChargebackToAllbenefits(
				justification,
				currentCompany?.id,
				selectedCollaborator?.id
			);
		},
		{
			onSuccess: (data) => {
				setAllowNavigation(true);
				if (data.chargebackError.length) {
					showReprovedChargebackBenefits(data.chargebackError);
				}
				showSuccessMessage();
			},
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao tentar criar o estorno. '
				);
			},
		}
	);

	return (
		<S.Container>
			{!allowNavigation && <PreventTransitionPrompt />}

			<PageTitle title='Solicitar estorno' />
			<S.Form onSubmit={(e) => handleSubmitForm(e)}>
				<S.FieldGroupTop>
					<S.Field>
						<S.Label>Colaborador</S.Label>
						<SingleCollaboratorSelector
							alreadyAddedCollaborator={selectedCollaborator}
							onSelectCollaborator={(c) => setSelectedCollaborator(c)}
						/>
					</S.Field>
					<S.Field>
						<S.Label>Benefícios</S.Label>
						<BenefitSelectorToChargeback
							alreadyAddedBenefits={selectedBenefits}
							onSelectBenefits={(benefits) => setSelectedBenefits(benefits)}
							selectedCollab={selectedCollaborator}
						/>
					</S.Field>
				</S.FieldGroupTop>

				<S.Field>
					<S.Label>Valor</S.Label>
					<S.CurrencyInput
						data-testid='valueInput_test_id'
						value={value ? value : 0}
						onValueChange={({ floatValue }) => setValue(floatValue)}
						disabled={selectedBenefits[0]?.benefit_id === 'allBenefitsID'}
						required={selectedBenefits[0]?.benefit_id === 'allBenefitsID'}
					/>
				</S.Field>
				<S.Field>
					<S.Label>Justificativa</S.Label>
					<FormStyles.InputTextArea
						data-testid='justificationInput_test_id'
						value={justification}
						onChange={(e) => setJustification(e.target.value)}
						required
					/>
				</S.Field>

				<S.Submit
					type='submit'
					disabled={
						createChargebackQuery.isLoading ||
						createChargebackToAllBenefitsQuery.isLoading
					}
				>
					{!createChargebackQuery.isLoading &&
					!createChargebackToAllBenefitsQuery.isLoading ? (
						'Enviar'
					) : (
						<Loader size={20} color='var(--white)' />
					)}
				</S.Submit>
			</S.Form>
		</S.Container>
	);
}
