import { Dispatch, useState } from 'react';
import { FaTrashAlt } from 'react-icons/fa';
import { Collaborator } from '../../../../../../@types';
import { EmptyContent } from '../../../../../../components/EmptyContent';
import Modal from '../../../../../../components/Modal';
import UserCard from '../../../../../../components/UserCard';
import * as S from '../styles';

export interface CollaboratorsListModalProps {
	selectedCollaborators: Collaborator[];
	setSelectedCollaborators: Dispatch<React.SetStateAction<Collaborator[]>>;
}
export function CollaboratorsListModal({
	selectedCollaborators,
	setSelectedCollaborators,
}: CollaboratorsListModalProps) {
	const [isOpen, setIsOpen] = useState(false);

	function handleRemoveCollaborator(id: string) {
		setSelectedCollaborators(selectedCollaborators.filter((c) => c.id !== id));
	}

	function getCollabName(firstName: string, lastName: string) {
		const name = `${firstName} ${lastName}`;

		return name.substring(0, 24) + (name.length > 24 ? '...' : '');
	}

	return (
		<>
			<S.CollaboratorsListButton onClick={() => setIsOpen((state) => !state)}>
				COLABORADORES
			</S.CollaboratorsListButton>

			<Modal
				isOpen={isOpen}
				onRequestClose={() => setIsOpen(false)}
				enableClose
			>
				<S.CollaboratorsListContainer data-testid='CollaboratorsListContainer-modal-container'>
					<S.CollaboratorsList>
						{selectedCollaborators.map((collab) => (
							<S.SelectedCollaborator key={collab.id}>
								<UserCard
									name={getCollabName(collab.first_name!, collab.last_name!)}
									bottomInfo={collab.office}
									avatar_url={collab.avatar_url}
								/>
								<S.DeleteButton
									data-testid={`remove-${collab.id}`}
									onClick={() => handleRemoveCollaborator(collab.id!)}
									data-rh='Remover colaborador'
								>
									<FaTrashAlt color='#F27474' />
								</S.DeleteButton>
							</S.SelectedCollaborator>
						))}

						{!selectedCollaborators.length && (
							<EmptyContent text='Nenhum colaborador selecionado' />
						)}
					</S.CollaboratorsList>
				</S.CollaboratorsListContainer>
			</Modal>
		</>
	);
}
