import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { OptionsButton } from '../../../components/Button';

export const Container = styled.div`
	flex: 1;
	padding: 4rem 7rem 2rem 7rem;
`;

export const CreateButton = styled(Link)`
	text-decoration: none;
	${OptionsButton}
	font-size: 1.4rem;
`;

export const SegmentationContainer = styled.div`
	display: flex;
	align-items: center;
	column-gap: 1rem;

	& > svg {
		width: 2rem;
		height: 2rem;
	}
`;

export const SegmentationTypeContainer = styled.div`
	display: flex;
	flex-direction: column;

	& > h4 {
		font-weight: 400;
		color: var(--dark-gray);
	}
	& > span {
		color: var(--mid-gray);
	}
`;
