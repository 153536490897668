import styled from 'styled-components';

export const OpenAddressModalBtn = styled.button`
	background-color: var(--primary-blue);
	padding: 1rem;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	width: min-content;
	& svg {
		width: 2rem;
		height: 2rem;
		color: var(--white);
	}
`;

export const Container = styled.div`
	min-width: 60rem;
`;

export const Title = styled.h1`
	text-align: center;
`;
export const Content = styled.div`
	display: flex;
	column-gap: 5rem;
	flex-wrap: wrap;
	margin: 1rem 0;
`;
export const InfoContainer = styled.div`
	margin: 1.5rem 0;
	display: flex;
	flex-direction: column;
`;
export const Label = styled.span`
	font-weight: 500;
	font-size: 1.6rem;
	color: var(--dark-gray);
`;
export const Information = styled.span`
	font-size: 1.4rem;
	color: var(--mid-gray);
`;
