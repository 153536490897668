import { useState } from 'react';
import { MdNotes } from 'react-icons/md';
import { EmptyContent } from '../../../components/EmptyContent';
import Loader from '../../../components/Loader';
import PageTitle from '../../../components/PageTitle';
import { GroupCreationModal } from './GroupCreationModal';
import * as S from './styles';
import * as TableStyle from '../../../components/Table/TableStyles';
import { useAuth } from '../../../hooks/useAuth';
import { Pagination } from '../../../components/Pagination';
import { Filter } from '../../../components/Filter';
import { useQuery, useQueryClient } from 'react-query';
import {
	getFilteredGroups,
	getGroups,
	GroupsFilterReturn,
	GroupsListReturn,
} from '../../../services/queries/Groups';
import { showErrorMessage } from '../../../utils/ErrorHandler';
import { useGroupsListStore } from '../../../stores/useGroupsListStore';
import { useHistoryNonMatchCallback } from '../../../hooks/useHistoryNonMatchCallback';
import { useSortColumnHook } from '../../../hooks/useSortColumnHook';
import { SortColumnButton } from '../../../components/SortColumnButton';

export function Groups() {
	const { currentCompany } = useAuth();
	const queryClient = useQueryClient();

	const [groupsCurrentPage, setGroupsCurrentPage] = useState(1);
	const { currentSortColumn, toggleSort } = useSortColumnHook();
	const [filtersParams, setFiltersParams, resetFilters] = useGroupsListStore(
		(state) => [state.filtersParams, state.setFiltersParams, state.resetFilters]
	);

	useHistoryNonMatchCallback('groups', resetFilters);

	const getGroupsQuery = useQuery<GroupsListReturn, Error>(
		['groupsList', currentCompany?.id, groupsCurrentPage, currentSortColumn],
		() => {
			return getGroups(
				currentCompany?.id,
				groupsCurrentPage,
				currentSortColumn
			);
		},
		{
			onError: (err) => {
				showErrorMessage(err as Error, 'Não foi possível buscar os grupos. ');
			},
			keepPreviousData: true,
		}
	);

	const filteredGroupsQuery = useQuery<GroupsFilterReturn, Error>(
		[
			'filteredGroups',
			currentCompany?.id,
			filtersParams,
			groupsCurrentPage,
			currentSortColumn,
		],
		() => {
			return getFilteredGroups(
				filtersParams,
				currentCompany?.id,
				groupsCurrentPage,
				currentSortColumn
			);
		},
		{
			onError: (err) => {
				showErrorMessage(err as Error, 'Não foi possível buscar os grupos. ');
			},
			enabled: !!filtersParams.find((f) => !!f.value) && !!groupsCurrentPage,
		}
	);
	function getTableTotalRecords() {
		if (filtersParams.find((f) => !!f.value)) {
			return filteredGroupsQuery.data?.totalGroups;
		}
		return getGroupsQuery.data?.groupsTotalCount;
	}

	function refreshPage() {
		setGroupsCurrentPage(1);
		queryClient.resetQueries('groupsList');
		queryClient.resetQueries('filteredGroups');
	}

	function getGroupContent() {
		if (filtersParams.find((f) => !!f.value))
			return filteredGroupsQuery.data?.groups;
		return getGroupsQuery.data?.groups;
	}

	if (
		getGroupsQuery.isLoading ||
		filteredGroupsQuery.isLoading ||
		getGroupsQuery.isPreviousData
	) {
		return (
			<S.Container>
				<PageTitle title='Grupos' />
				<Loader />
			</S.Container>
		);
	}
	if (!getGroupsQuery.data?.groups.length) {
		return (
			<S.Container>
				<TableStyle.TableHeaderContainer>
					<TableStyle.TitleWrapper>
						<PageTitle title='Grupos' />
						<Filter
							filterParams={filtersParams}
							onFiltersChanged={(updatedFilters) => {
								setGroupsCurrentPage(1);
								setFiltersParams(updatedFilters);
							}}
						/>
						<TableStyle.ReloadIcon type='reload' onClick={refreshPage} />
					</TableStyle.TitleWrapper>
				</TableStyle.TableHeaderContainer>
				<EmptyContent text='Nenhum grupo para ser exibido' />
				<GroupCreationModal />
			</S.Container>
		);
	}
	return (
		<S.Container>
			<TableStyle.TableHeaderContainer>
				<TableStyle.TitleWrapper>
					<PageTitle
						title='Grupos'
						totalRecords={getTableTotalRecords() ?? 0}
					/>
					<Filter
						filterParams={filtersParams}
						onFiltersChanged={(updatedFilters) => {
							setGroupsCurrentPage(1);
							setFiltersParams(updatedFilters);
						}}
					/>
				</TableStyle.TitleWrapper>

				{filtersParams.find((f) => !!f.value) ? (
					<Pagination
						onPageChange={(page) => setGroupsCurrentPage(page)}
						currentPage={groupsCurrentPage}
						totalCount={
							filteredGroupsQuery.data
								? filteredGroupsQuery.data.totalGroups!
								: 1
						}
					/>
				) : (
					<Pagination
						onPageChange={(page) => setGroupsCurrentPage(page)}
						currentPage={groupsCurrentPage}
						totalCount={
							getGroupsQuery.data ? getGroupsQuery.data.groupsTotalCount : 1
						}
					/>
				)}
				<TableStyle.ReloadIcon type='reload' onClick={refreshPage} />
			</TableStyle.TableHeaderContainer>
			<TableStyle.Table>
				<TableStyle.TableHeader>
					<TableStyle.TableRow>
						<TableStyle.TableHeaderCell
							style={{ width: '28rem', minWidth: '20rem' }}
						>
							<SortColumnButton
								order={
									currentSortColumn?.name === 'title'
										? currentSortColumn.order
										: null
								}
								onToggleSort={() => toggleSort('title')}
							/>
							NOME
						</TableStyle.TableHeaderCell>
						<TableStyle.TableHeaderCell
							style={{ width: '28rem', minWidth: '20rem', overflow: 'auto' }}
						>
							<SortColumnButton
								order={
									currentSortColumn?.name === 'total_users'
										? currentSortColumn.order
										: null
								}
								onToggleSort={() => toggleSort('total_users')}
							/>
							NÚMERO DE COLABORADORES
						</TableStyle.TableHeaderCell>
						<TableStyle.TableHeaderCell>
							<SortColumnButton
								order={
									currentSortColumn?.name === 'description'
										? currentSortColumn.order
										: null
								}
								onToggleSort={() => toggleSort('description')}
							/>
							DESCRIÇÃO
						</TableStyle.TableHeaderCell>
						<TableStyle.TableHeaderCell>OPÇÕES</TableStyle.TableHeaderCell>
					</TableStyle.TableRow>
				</TableStyle.TableHeader>

				<TableStyle.TableBody>
					{getGroupContent()?.map((group) => (
						<TableStyle.TableRow key={group.id}>
							<TableStyle.TableData style={{ maxWidth: '24rem' }}>
								{group.title}
							</TableStyle.TableData>
							<TableStyle.TableData>
								{group.total_users ? group.total_users : 0}
							</TableStyle.TableData>
							<TableStyle.TableData>{group.description}</TableStyle.TableData>
							<TableStyle.TableData style={{ textAlign: 'center' }}>
								<TableStyle.OptionLink
									data-rh='Detalhes do grupo'
									to={`groups/manage/${group.id}`}
								>
									<MdNotes />
								</TableStyle.OptionLink>
							</TableStyle.TableData>
						</TableStyle.TableRow>
					))}
				</TableStyle.TableBody>
			</TableStyle.Table>

			<GroupCreationModal />
		</S.Container>
	);
}
