import styled from 'styled-components';
import { OptionsButton } from '../Button';

export const OpenModalButton = styled.button`
	${OptionsButton}
`;

// MODAL STYLES
interface GroupsSelectionProps {
	selected: boolean;
	remove?: boolean;
}

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	max-width: 80rem;
	width: 70vw;
	padding-right: 1rem;
	min-height: 10rem;
	justify-content: center;
`;

export const GroupsList = styled.ul`
	display: flex;
	flex-direction: column;
	max-height: 70vh;
	overflow-y: auto;
	margin-top: 2rem;
`;

export const GroupContainer = styled.li`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 1rem;
	padding-bottom: 1rem;
	border-bottom: 0.1rem solid var(--primary-blue);
`;

export const GroupCard = styled.div`
	display: flex;
	align-items: center;
	& svg {
		color: var(--white);
		background-color: var(--primary-blue);
		border-radius: 50%;
		padding: 1rem;
		height: 5rem;
		width: 5rem;
		margin-right: 1rem;
	}
`;

export const GroupInfoContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

export const GroupName = styled.span`
	color: var(--primary-blue);
	font-size: 1.6rem;
`;

export const GroupInfo = styled.span`
	font-size: 1.4rem;
	color: var(--mid-gray);
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 42vw;
`;

export const Selector = styled.button<GroupsSelectionProps>`
	width: 3.5rem;
	height: 3.5rem;
	background: none;
	& svg {
		width: 100%;
		height: 100%;
		border-radius: 50%;
		color: ${({ selected, remove }) =>
			selected
				? 'var(--light-gray)'
				: remove
				? 'var(--primary-red)'
				: 'var(--primary-blue)'};
	}
	&:disabled {
		opacity: 50%;
		cursor: not-allowed;
	}
`;

export const ErrorLine = styled.span`
	text-align: center;
	font-size: 1.4rem;
	color: var(--red);
`;
