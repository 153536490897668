import {
	TitleTypography,
	Typography,
} from '../../../componentsV2/ui/Typography';
import { Breadcrumb } from '../../../componentsV2/BreadCrumb';
import { SectionContainer } from '../../../componentsV2/ui/SectionContainer';
import styled from 'styled-components';
import { Extract } from '../Extract';

export function Cards() {
	return (
		<div>
			<SectionContainer>
				<Breadcrumb
					routes={[{ title: 'Cartões', route: '/corporate-expenses/cards' }]}
				/>
				<TitleTypography image>Cartões</TitleTypography>
			</SectionContainer>

			<Extract />
		</div>
	);
}

export const Description = styled(Typography)`
	@media (max-width: 768px) {
		display: none;
	}
`;
