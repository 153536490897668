import styled from 'styled-components';
import { OptionsButton } from '../Button';

export const OpenBtn = styled.button`
	background-color: var(--primary-blue);
	padding: 1rem;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	width: min-content;
	& svg {
		width: 2rem;
		height: 2rem;
		color: var(--white);
	}
`;

export const Container = styled.div`
	display: flex;
	min-width: 50rem;
	flex-direction: column;
	padding: 0 1rem;
`;

export const Title = styled.h1`
	text-align: center;
`;

export const TrackingContent = styled.div`
	display: flex;
	flex-direction: column;
	max-height: 36rem;
	overflow-y: auto;
	margin: 2rem 0;

	row-gap: 3rem;
`;

export const TrackContainer = styled.div`
	display: flex;
	column-gap: 2rem;
	align-items: center;
	/* background-color: red; */
`;

const LINE_HEIGHT = 6;
interface IconContainerProps {
	lastOne: boolean;
}
export const IconContainer = styled.div<IconContainerProps>`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 4rem;
	min-height: 4rem;
	border-radius: 50%;
	background-color: var(--primary-blue);
	padding: 1rem;
	& > svg {
		width: 100%;
		height: 100%;
		color: var(--white);
	}

	&::before {
		content: '';
		position: absolute;
		bottom: -${LINE_HEIGHT - 0.2}rem;
		min-width: 0.2rem;
		height: ${({ lastOne }) => !lastOne && `${LINE_HEIGHT}rem`};
		background-color: ${({ lastOne }) => !lastOne && 'var(--primary-blue)'};
	}
`;

export const StatusMessage = styled.h3`
	font-size: 1.4rem;
	font-weight: 500;
`;

export const StatusDate = styled.span`
	color: var(--light-gray);
	font-size: 1.2rem;
`;

export const TrackInfoContainer = styled.div`
	display: flex;
	column-gap: 1rem;
	margin-bottom: 0.5rem;

	font-size: 1.4rem;
`;

export const TrackInfo = styled.span`
	color: var(--primary-blue);
`;

export const ExternalLink = styled.a`
	${OptionsButton}
	text-align: center;
	font-size: 1.4rem;
	width: 20rem;
	align-self: center;
	margin-top: 2rem;
`;
