import { InputHTMLAttributes } from 'react';
import { moneyMask } from '../../../../utils/masks';
import { BaseFieldProps, Field } from '../Field';
import { InputComp } from '../InputStyle';
import { MdErrorOutline } from 'react-icons/md';

interface CurrencyInputProps {
	useCurrencyLabel?: boolean;
	errorMessage?: string;
}

interface Props
	extends InputHTMLAttributes<HTMLInputElement>,
		BaseFieldProps,
		CurrencyInputProps {}
export function CurrencyInput({
	label,
	register,
	useCurrencyLabel = true,
	...props
}: Props) {
	function handleChangeValue(e: React.ChangeEvent<HTMLInputElement>) {
		const value = moneyMask(e.target.value);
		e.target.value = value;
		if (register) register(props.name!).onChange(e);
	}

	return (
		<Field label={label} name={props.name}>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					fontSize: '1.4rem',
					columnGap: '0.2rem',
				}}
			>
				{useCurrencyLabel ? (
					<p style={{ position: 'absolute', paddingLeft: '0.5rem' }}>R$</p>
				) : (
					''
				)}
				<InputComp
					inputMode={'numeric'}
					{...(register ? { ...register(props.name!) } : {})}
					onChange={handleChangeValue}
					{...props}
					style={{ paddingLeft: useCurrencyLabel ? '2.5rem' : '' }}
				/>
			</div>
			{props.errorMessage && (
				<span>
					<MdErrorOutline /> {props.errorMessage}
				</span>
			)}
		</Field>
	);
}
