import {
	FaCheckCircle,
	FaExclamationCircle,
	FaQuestionCircle,
	FaRegClock,
} from 'react-icons/fa';

export function getStatusIcon(status: string, withError?: boolean) {
	if (status === 'done' && !withError) return <FaCheckCircle color='#1CCA0D' />;

	if (status === 'canceled' || withError)
		return <FaExclamationCircle color='#FB1313' />;

	if (status === 'pending') return <FaRegClock color='#E0E414' />;

	if (status === 'processing') return <FaRegClock color='#C6C6C6' />;

	if (status === 'reproved') return <FaExclamationCircle color='#FB1313' />;

	return <FaQuestionCircle />;
}

export function parseStatusString(status: string) {
	if (status === 'done') return 'Concluído';

	if (status === 'canceled') return 'Cancelado';

	if (status === 'pending') return 'Aguardando';

	if (status === 'processing') return 'Processando';

	if (status === 'reproved') return 'Reprovado';

	return '?????';
}

export function parseCardStatusString(status: string, withError?: boolean) {
	if (status === 'done') {
		if (withError !== undefined && withError === true)
			return 'Finalizado com erro';

		return 'Finalizado';
	}
	if (status === 'canceled') return 'Cancelado';

	if (status === 'pending') return 'Aguardando';

	if (status === 'processing') return 'Processando';

	if (status === 'reproved') return 'Reprovado';

	return '?????';
}

export function getCardStatusIconToCollabProfile(status: string) {
	if (status === 'linked') return <FaCheckCircle color='#1CCA0D' />;

	if (status === 'none') return <FaExclamationCircle color='#000' />;

	if (status === 'requested') return <FaRegClock color='#E0E414' />;

	return <FaQuestionCircle />;
}

export function parseCardStatusStringToCollabProfile(status: string) {
	if (status === 'none') return 'Sem cartão';

	if (status === 'linked') return 'Cartão vinculado';

	if (status === 'requested') return 'Cartão solicitado';

	return '?????';
}
