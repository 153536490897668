import { Payment, Release, ReleaseLog } from '../../@types';
import {
	FilterParams,
	parseFilterParamsToParams,
} from '../../components/Filter';
import { ReprovedCollaborators } from '../../components/NotApprovedCollabsModal';
import {
	SortColumn,
	parseSortColumnToParams,
} from '../../hooks/useSortColumnHook';
import api from '../api';

export interface ReleasesFilterReturn {
	totalRelease: number;
	requestedReleases: Payment[];
}

export async function getFilteredReleases(
	filterParams: FilterParams[],
	companyId?: string,
	page?: number,
	sortColumn: SortColumn | null = null
): Promise<ReleasesFilterReturn> {
	const { data } = await api.get<ReleasesFilterReturn>(
		`/api/v1/rh/companies/${companyId}/releases/filter/q?page=${page}`,
		{
			params: {
				...parseFilterParamsToParams(filterParams),
				...parseSortColumnToParams(sortColumn),
			},
		}
	);

	return {
		totalRelease: data.totalRelease,
		requestedReleases: data.requestedReleases,
	};
}

export async function getFilteredReleasesPendingAdmin(
	filterParams: FilterParams[],
	companyId?: string,
	Pending?: boolean,
	page?: number,
	sortColumn: SortColumn | null = null
): Promise<ReleasesFilterReturn> {
	const { data } = await api.get<ReleasesFilterReturn>(
		`/api/v1/rh/companies/${companyId}/releases/filter/q?page=${page}`,
		{
			params: {
				...parseFilterParamsToParams(filterParams),
				...parseSortColumnToParams(sortColumn),
				status: Pending ? 'requested' : '',
			},
		}
	);

	return {
		totalRelease: data.totalRelease,
		requestedReleases: data.requestedReleases,
	};
}

export async function getFilteredReleasesPendingOperator(
	filterParams: FilterParams[],
	companyId?: string,
	Pending?: boolean,
	page?: number,
	sortColumn: SortColumn | null = null
): Promise<ReleasesFilterReturn> {
	const { data } = await api.get<ReleasesFilterReturn>(
		`/api/v1/rh/companies/${companyId}/releases/filter/q?page=${page}`,
		{
			params: {
				...parseFilterParamsToParams(filterParams),
				...parseSortColumnToParams(sortColumn),
				status: Pending ? 'disapproved' : '',
			},
		}
	);

	return {
		totalRelease: data.totalRelease,
		requestedReleases: data.requestedReleases,
	};
}

export async function getFilteredScheduledReleases(
	filterParams: FilterParams[],
	companyId?: string,
	Scheduled?: boolean,
	page?: number,
	sortColumn: SortColumn | null = null
): Promise<ReleasesFilterReturn> {
	const { data } = await api.get<ReleasesFilterReturn>(
		`/api/v1/rh/companies/${companyId}/releases/filter/q?page=${page}`,
		{
			params: {
				...parseFilterParamsToParams(filterParams),
				...parseSortColumnToParams(sortColumn),
				status: Scheduled ? 'scheduled' : '',
			},
		}
	);

	return {
		totalRelease: data.totalRelease,
		requestedReleases: data.requestedReleases,
	};
}

export interface PreReleaseBenefit {
	benefit_id: string;
	value: number;
}

export interface PreReleaseData {
	user_id: string;
	benefits: PreReleaseBenefit[];
}
export interface GroupReleaseData {
	group_id: string;
	benefits: PreReleaseBenefit[];
}

interface SubmitPaymentReturn {
	release: unknown; // not being used
	unpaidPayments: ReprovedCollaborators[];
}
export async function createPayment(
	preReleases: PreReleaseData[],
	groupReleases: GroupReleaseData[],
	scheduleDate: string,
	companyId: string
): Promise<SubmitPaymentReturn> {
	const { data } = await api.post(
		`/api/v1/rh/companies/${companyId}/releases/batches`,
		{
			preReleases,
			groupReleases,
			...(!!scheduleDate && { scheduleDate: new Date(scheduleDate) }),
		}
	);

	return data;
}

export async function fetchPayment(
	paymentId: string,
	companyId: string
): Promise<Release> {
	const { data } = await api.get<Release>(
		`/api/v1/rh/companies/${companyId}/releases/${paymentId}`
	);

	return data;
}

export async function fetchPaymentToReport(
	paymentId: string,
	companyId: string
): Promise<Release> {
	const { data } = await api.get<Release>(
		`/api/v1/rh/companies/${companyId}/releases/${paymentId}?report=true`
	);

	return data;
}

export async function fetchLogs(
	paymentId: string,
	companyId: string
): Promise<ReleaseLog> {
	const { data } = await api.get<ReleaseLog>(
		`/api/v1/rh/companies/${companyId}/releases/${paymentId}/logs`
	);

	return data;
}

export async function cancelPayment(
	reason_disapproval: string,
	paymentId: string,
	companyId: string
): Promise<void> {
	await api.patch(
		`/api/v1/rh/companies/${companyId}/releases/${paymentId}/cancel`,
		{
			reason_disapproval,
		}
	);
}

export interface PreReleaseReviewAdminData {
	preRelease_id: string;
	reason_disapproval?: string;
	value?: number;
}
export interface GroupReleaseReviewAdminData {
	groupRelease_id: string;
	reason_disapproval?: string;
	value?: number;
}
export async function adminReviewPayment(
	preReleases: PreReleaseReviewAdminData[],
	groupReleases: GroupReleaseReviewAdminData[],
	paymentId: string,
	companyId: string
): Promise<SubmitPaymentReturn> {
	const { data } = await api.put(
		`/api/v1/rh/companies/${companyId}/releases/${paymentId}/batches/revision`,
		{
			preReleases,
			groupReleases,
		}
	);

	return data;
}

export interface PreReleaseReviewOperatorData {
	preRelease_id: string;
	value: number;
}

export interface GroupReleaseReviewOperatorData {
	groupRelease_id: string;
	value: number;
}
export async function operatorReviewPayment(
	preReleases: PreReleaseReviewOperatorData[],
	groupReleases: GroupReleaseReviewOperatorData[],
	paymentId: string,
	companyId: string
): Promise<SubmitPaymentReturn> {
	const { data } = await api.put(
		`/api/v1/rh/companies/${companyId}/releases/${paymentId}/relaunch`,
		{
			preReleases,
			groupReleases,
		}
	);

	return data;
}

export async function editPayment(
	id: string,
	preReleases: PreReleaseData[],
	groupReleases: GroupReleaseData[],
	scheduleDate: string,
	companyId: string
): Promise<SubmitPaymentReturn> {
	const { data } = await api.put(
		`/api/v1/rh/companies/${companyId}/releases/${id}/update`,
		{
			preReleases,
			groupReleases,
			...(!!scheduleDate && { scheduleDate: new Date(scheduleDate) }),
		}
	);

	return data;
}

export type CheckGroupsRecentPaymentsList = {
	id: string;
};
export type CheckCollaboratorsRecentPaymentsList = {
	cpf: string;
};
interface CollaboratorsRecentPaymentsResponse {
	recentPayments: {
		id: string;
		name: string;
		cpf: string;
	}[];
}
export async function checkCollaboratorsMonthPayments(
	collaboratorsCPFs: CheckCollaboratorsRecentPaymentsList[],
	groupsIds: CheckGroupsRecentPaymentsList[],
	scheduleDate: string | undefined,
	companyId: string
) {
	if (!scheduleDate) {
		scheduleDate = undefined;
	}

	const { data } = await api.post<CollaboratorsRecentPaymentsResponse>(
		`/api/v1/rh/companies/${companyId}/releases/check/collaborators`,
		{
			collaborators: collaboratorsCPFs,
			groups: groupsIds,
			scheduleDate,
		}
	);

	return data;
}

export interface PaymentsListReturn {
	otherReleases?: Payment[];
	totalOther?: number;

	requestedReleases?: Payment[];
	totalRequested?: number;

	disapprovedReleases?: Payment[];
	totalDisapproved?: number;

	scheduledReleases?: Payment[];
	totalScheduled?: number;
}

export async function getOtherPayments(
	filteredPaymentsCurrentPage: number,
	companyId?: string,
	sortColumn: SortColumn | null = null
): Promise<PaymentsListReturn> {
	const { data } = await api.get(
		`/api/v1/rh/companies/${companyId}/releases/admin/q?page_other=${filteredPaymentsCurrentPage}`,
		{
			params: {
				...(sortColumn && { sort_by_other: sortColumn.name }),
				...(sortColumn && { sort_order_other: sortColumn.order }),
			},
		}
	);

	const otherReleases = data.otherReleases;
	const totalOther = data.totalOther;

	return {
		otherReleases,
		totalOther,
	};
}

export async function getOtherPaymentsOperator(
	filteredPaymentsCurrentPage: number,
	companyId?: string,
	sortColumn: SortColumn | null = null
): Promise<PaymentsListReturn> {
	const { data } = await api.get(
		`/api/v1/rh/companies/${companyId}/releases/q?page_other=${filteredPaymentsCurrentPage}`,
		{
			params: {
				...(sortColumn && { sort_by_other: sortColumn.name }),
				...(sortColumn && { sort_order_other: sortColumn.order }),
			},
		}
	);

	const otherReleases = data.otherReleases;
	const totalOther = data.totalOther;

	return {
		otherReleases,
		totalOther,
	};
}

export async function getRequestedPayments(
	requestedPaymentsCurrentPage: number,
	companyId?: string,
	sortColumn: SortColumn | null = null
): Promise<PaymentsListReturn> {
	const { data } = await api.get(
		`/api/v1/rh/companies/${companyId}/releases/admin/q?page_requested=${requestedPaymentsCurrentPage}`,
		{
			params: {
				...(sortColumn && { sort_by_requested: sortColumn.name }),
				...(sortColumn && { sort_order_requested: sortColumn.order }),
			},
		}
	);

	const requestedReleases = data.requestedReleases;
	const totalRequested = data.totalRequested;

	return {
		requestedReleases,
		totalRequested,
	};
}

export async function getDisapprovedPayments(
	disapprovedPaymentsCurrentPage: number,
	companyId?: string,
	sortColumn: SortColumn | null = null
): Promise<PaymentsListReturn> {
	const { data } = await api.get(
		`/api/v1/rh/companies/${companyId}/releases/q?page_disapproved=${disapprovedPaymentsCurrentPage}`,
		{
			params: {
				...(sortColumn && { sort_by_disapproved: sortColumn.name }),
				...(sortColumn && { sort_order_disapproved: sortColumn.order }),
			},
		}
	);

	const disapprovedReleases = data.disapprovedReleases;
	const totalDisapproved = data.totalDisapproved;

	return {
		disapprovedReleases,
		totalDisapproved,
	};
}

export async function getScheduledPayments(
	scheduledPaymentsCurrentPage: number,
	companyId?: string,
	sortColumn: SortColumn | null = null
): Promise<PaymentsListReturn> {
	const { data } = await api.get(
		`/api/v1/rh/companies/${companyId}/releases/q?page_disapproved=${scheduledPaymentsCurrentPage}`,
		{
			params: {
				...(sortColumn && { sort_by_scheduled: sortColumn.name }),
				...(sortColumn && { sort_order_scheduled: sortColumn.order }),
			},
		}
	);

	const scheduledReleases = data.scheduledReleases;
	const totalScheduled = data.totalScheduled;

	return {
		scheduledReleases,
		totalScheduled,
	};
}

export async function chargebackPayment(
	release_id: string,
	companyId: string
): Promise<SubmitPaymentReturn> {
	const { data } = await api.post(
		`/api/v1/rh/companies/${companyId}/releases/${release_id}/chargeback`
	);

	return data;
}
