import axios from 'axios';
import { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Collaborator, Group } from '../../../../@types';
import CollaboratorForm from '../../../../components/CollaboratorForm';
import Loader from '../../../../components/Loader';
import PageTitle from '../../../../components/PageTitle';
import { useAuth } from '../../../../hooks/useAuth';
import { useDialogModal } from '../../../../hooks/useDialogModal';
import {
	CollabDetailsReturn,
	UpdateCollaboratorData,
	// deleteCollaborator,
	fetchCollaborator,
	requestDeleteUserCollaborator,
	toggleStatus,
	updateCollaborator,
} from '../../../../services/queries/Collaborators';
import {
	setGroupCollaborators,
	getActiveGroups,
} from '../../../../services/queries/Groups';
import {
	parseMaskedCEPToRaw,
	parseMaskedCPFToRaw,
	parseMaskedPhoneToRaw,
} from '../../../../utils/masks';
import {
	getCardStatusIconToCollabProfile,
	parseCardStatusStringToCollabProfile,
} from '../../../../utils/parseCardBatchStatus';
import {
	getStatusIcon,
	parseStatusStringProfile,
} from '../../../../utils/parseKycStatus';
import { ModalKycReprovedDetails } from './KycReprovedDetails/ModalKycReprovedDetails';
import * as S from './styles';
import { showErrorMessage } from '../../../../utils/ErrorHandler';
import { validateCollaborator } from '../../../../utils/validateCollaborator';
import FeedbackModal from '../../../../components/FeedbackModal';

interface ManageCollaboratorsParams {
	id: string;
}

export function ManageCollaborators() {
	const { id } = useParams<ManageCollaboratorsParams>();
	const history = useHistory();
	const { currentCompany } = useAuth();
	const queryClient = useQueryClient();
	const [groups, setGroups] = useState<Group[]>([]);
	const [groupsToRemove, setGroupsToRemove] = useState<Group[]>([]);
	const [addedGroups, setAddedGroups] = useState<Group[]>([]);
	const { openConfirmDialog, openOptionsDialog } = useDialogModal();
	const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);

	const fetchCollabQuery = useQuery<CollabDetailsReturn, Error>(
		['fetchCollaborator', currentCompany?.id, id],
		() => {
			return fetchCollaborator(id, currentCompany?.id);
		},
		{
			onSuccess: (data) => {
				if (data.collaborator.deleted) {
					history.push('/home/collaborators');
					toast.error('Colaborador não encontrado!');
				}
			},
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao tentar buscar o colaborador. '
				);
			},
			refetchOnWindowFocus: false,
		}
	);

	const updateCollabQuery = useMutation(
		(updatedColaboratorData: UpdateCollaboratorData) => {
			return updateCollaborator(id, updatedColaboratorData, currentCompany?.id);
		},
		{
			onSuccess: () => {
				toast.info('Colaborador editado com sucesso');
				queryClient.resetQueries('fetchCollaborator');
			},
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao tentar atualizar o colaborador. '
				);
			},
		}
	);

	const toggleStatusCollabQuery = useMutation(
		(status: boolean) => {
			return toggleStatus(id, status, currentCompany?.id);
		},
		{
			onSuccess: (data) => {
				const newStatus = data.status;
				queryClient.invalidateQueries('fetchCollaborator');
				toast.info(
					`Status do colaborador ${newStatus ? 'ativado' : 'desativado'}`
				);
			},
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao mudar o status do colaborador. '
				);
			},
		}
	);

	// const deleteCollaboratorMutation = useMutation(
	// 	(deletionReason: string) => {
	// 		return deleteCollaborator(id, deletionReason, currentCompany?.id);
	// 	},
	// 	{
	// 		onSuccess: () => {
	// 			toast.info(`Colaborador removido com sucesso.`);
	// 			history.push('/home/collaborators');
	// 		},
	// 		onError: (err) => {
	// 			showErrorMessage(
	// 				err as Error,
	// 				'Ocorreu um problema ao tentar deletar o colaborador. '
	// 			);
	// 		},
	// 	}
	// );

	const requestDeleteUserCollaboratorMutation = useMutation(
		(deletionReason: string) => {
			return requestDeleteUserCollaborator(
				id,
				deletionReason,
				currentCompany?.id
			);
		},
		{
			onSuccess: () => {
				toast.info(`Pedido de remoção de colaborador realizado com sucesso.`);
				history.push('/home/collaborators');
			},
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao tentar solicitar remoção do colaborador. '
				);
			},
		}
	);

	useQuery<Group[], Error>(
		['getActiveGroups', currentCompany, fetchCollabQuery.data?.collaborator.id],
		() => {
			return getActiveGroups(currentCompany?.id);
		},
		{
			onSuccess: (data) => {
				setAddedGroups(
					data.filter((element: Group) => {
						return element.users.find(
							(collab) => collab.id === fetchCollabQuery.data?.collaborator.id
						);
					})
				);
			},
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao tentar listar os grupos. '
				);
			},
			enabled: !!fetchCollabQuery.data?.collaborator,
			refetchOnWindowFocus: false,
		}
	);

	const addCollabToGroupsQuery = useMutation(
		async ({ groupId, collaboratorsIds }: any) => {
			return setGroupCollaborators(
				groupId,
				collaboratorsIds,
				currentCompany?.id
			);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('getActiveGroups');
			},
			onError: (err) => {
				if (axios.isAxiosError(err)) {
					console.log(err.response?.data);
				} else {
					console.log(err);
				}
			},
		}
	);

	function parseDate(date: string) {
		const length = date.length;
		try {
			const dateStr = date.substring(length - 2, length);
			const monthStr = date.substring(length - 5, length - 3);
			const yearStr = date.substring(0, 4);

			return `${dateStr}/${monthStr}/${yearStr}`;
		} catch (err) {
			return date;
		}
	}

	async function handleUpdateCollaborator(collaborator: Collaborator) {
		const fetchedCollaborator = fetchCollabQuery.data!.collaborator;

		let {
			address,
			birth_date,
			cep,
			city,
			cpf,
			complement,
			reference,
			contract_type,
			id_cost_center,
			district,
			email,
			first_phone,
			first_name,
			last_name,
			mother_name,
			number,
			office,
			branch_office,
			second_phone,
			section,
			uf,
			document,
			responsible,
		} = collaborator;
		// alert(JSON.stringify(data))
		let rawData: any = {
			address,
			birth_date: parseDate(birth_date!),
			cep: parseMaskedCEPToRaw(cep!),
			city,
			cpf,
			complement,
			reference,
			contract_type,
			district,
			email,
			first_phone: parseMaskedPhoneToRaw(first_phone!),
			first_name,
			last_name,
			mother_name,
			number,
			office,
			branch_office,
			second_phone: parseMaskedPhoneToRaw(second_phone!),
			section,
			uf,
		};

		if (!validateCollaborator(rawData as Collaborator)) return;

		delete rawData.cpf;
		let data: any = {};

		Object.keys(rawData).forEach((key) => {
			if (fetchedCollaborator[key as keyof Collaborator] !== rawData[key]) {
				data[key] = rawData[key];
			}
		});

		const updateCollabData: UpdateCollaboratorData = {
			data,
		};

		// changed cost_center
		if (id_cost_center !== fetchedCollaborator.cost_center?.id)
			updateCollabData.id_cost_center = id_cost_center;

		// another file was uploaded
		if (document instanceof File) {
			updateCollabData.document = document;
		}

		if (responsible) {
			(Object.keys(responsible) as Array<keyof typeof responsible>).forEach(
				(key) => {
					// responsible has changed
					if (
						!fetchedCollaborator.responsible ||
						responsible![key] !== fetchedCollaborator.responsible![key]
					) {
						updateCollabData.responsible = {
							cpf: parseMaskedCPFToRaw(responsible!.cpf),
							full_name: responsible!.full_name,
							phone: parseMaskedPhoneToRaw(responsible!.phone),
							relatedness_degree: responsible!.relatedness_degree,
						};

						return;
					}
				}
			);
		}

		updateCollabQuery.mutate(updateCollabData);
	}

	async function handleToggleStatus() {
		openConfirmDialog(
			`Tem certeza que deseja ${
				fetchCollabQuery.data?.collaborator.status ? 'desativar' : 'ativar'
			} o colaborador?`,
			'Sim',
			async () => {
				toggleStatusCollabQuery.mutate(
					!fetchCollabQuery.data?.collaborator!.status
				);
			}
		);
	}

	async function handleDeleteCollaborator() {
		openOptionsDialog(
			`Tem certeza de que deseja excluir o colaborador? Esta ação é reversível. Recomendamos editar informações quando possível.`,
			'Sim',
			() => setFeedbackModalOpen(true),
			'Cancelar',
			() => {}
		);
	}

	useEffect(() => {
		if (!groups.length) return;

		groups.forEach((group) => {
			const newCollabsIds = [
				...(group.users.map((u) => {
					return u.id;
				}) as string[]),
				fetchCollabQuery.data?.collaborator!.id!,
			];
			try {
				addCollabToGroupsQuery.mutate({
					groupId: group.id,
					collaboratorsIds: newCollabsIds,
				});
			} catch (err) {
				toast.error(
					`Ocorreu um problema ao tentar adicionar o colaborador ao grupo ${group.title}. `
				);
			}
		});
		toast.info('Colaborador adicionado aos grupos selecionados.');
		setGroups([]);
	}, [groups]); //eslint-disable-line

	useEffect(() => {
		if (!groupsToRemove.length) return;

		groupsToRemove.forEach((group) => {
			const collabsToKeep = [
				...group.users
					.filter((u) => u.id !== fetchCollabQuery.data?.collaborator!.id!)
					.map((u) => u.id),
			];
			try {
				addCollabToGroupsQuery.mutate({
					groupId: group.id,
					collaboratorsIds: collabsToKeep,
				});
			} catch (err) {
				toast.error(
					`Ocorreu um problema ao tentar remover o colaborador do grupo ${group.title}. `
				);
			}
		});
		toast.info('Colaborador removido dos grupos selecionados.');
		setGroupsToRemove([]);
	}, [groupsToRemove]); //eslint-disable-line

	if (
		!fetchCollabQuery.data ||
		updateCollabQuery.isLoading ||
		toggleStatusCollabQuery.isLoading ||
		// deleteCollaboratorMutation.isLoading ||
		requestDeleteUserCollaboratorMutation.isLoading
	) {
		return (
			<S.Container>
				<PageTitle title='Gerenciar Colaborador' />
				<Loader />
			</S.Container>
		);
	}
	return (
		<S.Container>
			<S.HeaderContainer>
				<PageTitle title='Gerenciar Colaborador' />
				<S.StatusContainer>
					<S.StatusCardContainer>
						{getCardStatusIconToCollabProfile(
							fetchCollabQuery.data?.collaborator.card_info.card_status
						)}
						{fetchCollabQuery.data?.collaborator.card_info.card_batch_id ? (
							<S.RequestedCardLink
								to={`/home/cards/${fetchCollabQuery.data?.collaborator.card_info.card_batch_id}`}
							>
								{parseCardStatusStringToCollabProfile(
									fetchCollabQuery.data?.collaborator.card_info.card_status
								)}
							</S.RequestedCardLink>
						) : (
							<span>
								{parseCardStatusStringToCollabProfile(
									fetchCollabQuery.data?.collaborator.card_info.card_status
								)}
							</span>
						)}
					</S.StatusCardContainer>

					<S.KycInfoContainer>
						<S.StatusKYCContainer>
							{getStatusIcon(fetchCollabQuery.data?.collaborator.kyc_status!)}
							<span>
								{parseStatusStringProfile(
									fetchCollabQuery.data?.collaborator.kyc_status!
								)}
							</span>
						</S.StatusKYCContainer>
						{fetchCollabQuery.data?.collaborator.kyc_status === 'reproved' && (
							<ModalKycReprovedDetails
								kycDetails={
									fetchCollabQuery.data?.collaborator.kyc_result_detail
								}
							/>
						)}
					</S.KycInfoContainer>
				</S.StatusContainer>
			</S.HeaderContainer>
			<S.Content>
				<CollaboratorForm
					collaborator={{
						...fetchCollabQuery.data?.collaborator,
						document: fetchCollabQuery.data.document,
					}}
					onSubmit={handleUpdateCollaborator}
					changeStatus={handleToggleStatus}
					addedGroups={addedGroups}
					addCollabToTheGroups={(groups) => setGroups(groups)}
					removeCollabFromGroups={(groups) => setGroupsToRemove(groups)}
					onDelete={() => handleDeleteCollaborator()}
				/>

				<FeedbackModal
					description={'Motivo da exclusão?'}
					isFeedbackModalOpen={feedbackModalOpen}
					handleCloseFeedbackModal={() => setFeedbackModalOpen(false)}
					submit={(feedback) =>
						requestDeleteUserCollaboratorMutation.mutate(feedback)
					}
				/>
			</S.Content>
		</S.Container>
	);
}
