import * as S from './styles';
import PageTitle from '../../../../components/PageTitle';
import Loader from '../../../../components/Loader';
import { useAuth } from '../../../../hooks/useAuth';
import { Company } from '../../../../@types';
import { cepMask, cnpjMask, phoneMask } from '../../../../utils/masks';
import { useQuery } from 'react-query';
import { getCompany } from '../../../../services/queries/Companies';
import { showErrorMessage } from '../../../../utils/ErrorHandler';

export function CompanyDetails() {
	const { user, currentCompany } = useAuth();

	const company = useQuery<Company, Error>(
		['fetchCompany', currentCompany?.id],
		() => {
			return getCompany(currentCompany?.id);
		},
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao tentar buscar a empresa. '
				);
			},
			enabled: !!currentCompany,
			refetchOnWindowFocus: false,
		}
	);

	function getShareholdersNames() {
		const shareholders = company.data!.shareholders.map(
			(shareholder) => `${shareholder.first_name} ${shareholder.last_name}`
		);

		return shareholders.join(', ');
	}

	if (company.isLoading || !company.data) {
		return (
			<S.Container>
				<PageTitle title='Detalhamento da empresa' />
				<Loader />
			</S.Container>
		);
	}

	return (
		<S.Container>
			<S.Header>
				<PageTitle title='Detalhamento da Empresa' />
				{user.access_level === 'admin' && (
					<S.EditButton to='/home/company/update'>Editar</S.EditButton>
				)}
			</S.Header>

			<S.CompanyInfoContainer>
				<S.InfoGroupContainer>
					<S.CompanyLogo
						src={company.data.avatar_url}
						alt={company.data.name}
					/>
					<S.InfoTopContainer>
						<S.Label>RAZÃO SOCIAL</S.Label>
						<S.Info>{company.data.name}</S.Info>
					</S.InfoTopContainer>

					<S.InfoTopContainer>
						<S.Label>SÓCIOS</S.Label>
						<S.Info>{getShareholdersNames()}</S.Info>
					</S.InfoTopContainer>
				</S.InfoGroupContainer>

				<S.InfoGroupContainer>
					<S.InfoContainer>
						<S.Label>NOME FANTASIA</S.Label>
						<S.Info>{company.data.corporate_name}</S.Info>
					</S.InfoContainer>
				</S.InfoGroupContainer>

				<S.InfoGroupContainer>
					<S.InfoContainer>
						<S.Label>CNPJ</S.Label>
						<S.Info>{cnpjMask(company.data.cnpj)}</S.Info>
					</S.InfoContainer>
					<S.InfoContainer>
						<S.Label>DATA DE FUNDAÇÃO</S.Label>
						<S.Info>{company.data.founding_date}</S.Info>
					</S.InfoContainer>
				</S.InfoGroupContainer>

				<S.InfoGroupContainer>
					<S.InfoContainer>
						<S.Label>TIPO DE NEGÓCIO</S.Label>
						<S.Info>{company.data.business_type}</S.Info>
					</S.InfoContainer>
					<S.InfoContainer>
						<S.Label>PRINCIPAL ATIVIDADE</S.Label>
						<S.Info>{company.data.main_activity}</S.Info>
					</S.InfoContainer>
				</S.InfoGroupContainer>

				<S.InfoGroupContainer>
					<S.InfoContainer>
						<S.Label>WEBSITE</S.Label>
						<S.Info>{company.data.website}</S.Info>
					</S.InfoContainer>
					<S.InfoContainer>
						<S.Label>TELEFONE</S.Label>
						<S.Info>{phoneMask(company.data.first_phone)}</S.Info>
					</S.InfoContainer>
				</S.InfoGroupContainer>

				<S.InfoContainer>
					<S.Label>ENDEREÇO</S.Label>
					<S.Info>
						{`${company.data.address}, ${company.data.number} - ${
							company.data.district
						}, ${company.data.city} - ${company.data.uf}, ${cepMask(
							company.data.cep
						)}`}
					</S.Info>
				</S.InfoContainer>
			</S.CompanyInfoContainer>
		</S.Container>
	);
}
