import styled from "styled-components";

export const Title = styled.p`
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    margin-top: 0.5rem;
`
export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 10rem;
`

export const IconContainer = styled.div`
    display: flex;
    align-self: center;
    > svg {
        width: 4rem;
        height: 4rem;
    }
`