import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	border-radius: 1rem;
	background-color: var(--white);
	width: 48rem;
	padding-bottom: 3rem;
	align-items: center;
	gap: 3rem;
	@media (max-width: 1024px) {
		flex-direction: column;
		gap: 2rem;
		width: 100%;
	}
`;

export const Header = styled.div`
	background-color: #f0f3f8;
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 15rem;
	align-items: center;
	justify-content: center;
	border-radius: 1rem;
	padding: 0 4rem;

	& svg {
		width: 28px;
		height: 28px;
	}

	&.pixResult {
		height: 30rem;

		@media (max-width: 768px) {
			height: 40rem;
		}
	}
`;

export const OptionsContainer = styled.div`
	display: flex;
	align-self: center;
	gap: 2rem;
	width: 90%;
	border: 2px solid var(--primary-blue);
	padding: 2rem;
	border-radius: 1.6rem;
	flex-direction: column;
`;

export const InformationContainer = styled.div`
	display: flex;
	justify-content: space-between;
	width: 90%;

	& > div:first-child {
		flex-direction: column;
		align-items: flex-start;
	}

	& > div:last-child {
		flex-direction: column;
		align-items: flex-end;
	}

	& > p {
		flex-direction: column;

		span {
			font-weight: 600;
		}
	}
`;

export const InputContainer = styled.form`
	display: flex;
	flex-direction: column;
	gap: 1rem;
	width: 100%;

	& > div {
		justify-content: space-between;
	}
`;

export const RecipientEmailContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	& > label {
		font-weight: normal;
		font-size: 1.3rem;
		display: flex;
		gap: 0.4rem;
		margin-bottom: 0.5rem;
		& > div {
			display: flex;
			flex-direction: column;
		}
	}

	& > p {
		font-size: 1.4rem;
		font-weight: 600;
		margin-bottom: 1.5rem;
	}
`;

export const EmailContainer = styled.p`
    color: #606060;
    font-style: italic;
    line-height: normal;
`

export const BottomOptionsContainer = styled.div`
	display: flex;
	gap: 2rem;
	justify-content: center;
`;

export const PaymentOption = styled.div<{ isActive: boolean }>`
	display: flex;
	align-items: center;
	height: 3rem;
	justify-content: center;
	padding: 1rem;
	border-radius: 2rem;
	border: 1px solid
		${({ isActive }) => (isActive ? 'var(--primary-blue)' : 'var(--dark-gray)')};
	width: 15rem;
	text-align: center;
	background-color: ${({ isActive }) =>
		isActive ? 'var(--primary-blue)' : 'transparent'};
	color: ${({ isActive }) => (isActive ? 'var(--white)' : 'var(--dark-gray)')};
	font-size: 1.2rem;
	cursor: pointer;
`;

export const HeaderDescription = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	margin-top: 1rem;
	flex-wrap: wrap;
	align-items: center;
	& > p {
		text-decoration: underline;
	}
`;

export const TextStyle = styled.p`
	font-size: 1.4rem;
	font-weight: 600;
	color: var(--dark-gray);

	&.balance {
		color: var(--primary-blue);
	}

	&.updated {
		display: flex;
		align-items: center;
		color: #00a22d;

		> svg {
			width: 3rem;
			height: 2rem;
		}
	}
`;
