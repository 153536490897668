import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Benefit, Collaborator as CollaboratorType } from '../../../@types';
import { CancelPaymentModal } from '../../../components/CancelPaymentModal';
import Loader from '../../../components/Loader';
import PageTitle from '../../../components/PageTitle';
import { PaymentHeader } from '../../../components/PaymentHeader';
import { PaymentLogs } from '../../../components/PaymentLogs';
import { useAuth } from '../../../hooks/useAuth';
import { usePayment } from '../../../hooks/usePaymentV2';
import {
	cancelPayment,
	chargebackPayment,
	fetchPayment,
} from '../../../services/queries/PaymentsV2';
import { isPaymentEditable } from '../../../utils/isPaymentEditable';
import { parsePayment } from '../../../utils/parsePayment';
import PaymentTableStatic from './components/PaymentTable/PaymentTableStatic';
import * as S from './styles';
import { showErrorMessage } from '../../../utils/ErrorHandler';
import { NotApprovedCollabsModal } from '../../../components/NotApprovedCollabsModal';
import { useDialogModal } from '../../../hooks/useDialogModal';
import { calculatePaymentsAmount } from './utils/calculatePaymentsAmout';
import { useEffect, useState } from 'react';
import { AiOutlineArrowDown, AiOutlineArrowUp } from 'react-icons/ai';

interface DetailPaymentParams {
	id: string;
}

interface Collaborator extends CollaboratorType {
	benefits: Benefit[];
}

export function DetailPayment() {
	const { id } = useParams<DetailPaymentParams>();
	const history = useHistory();
	const queryClient = useQueryClient();
	const { openConfirmDialog } = useDialogModal();
	const { user, currentCompany } = useAuth();
	const { beneficiaries, setBeneficiaries, setBenefits, setSelectedBenefits } =
		usePayment();
	const [paymentsAmount, setPaymentsAmount] = useState(0);

	const fetchPaymentQuery = useQuery(
		['fetchPayment', currentCompany?.id, id],
		() => {
			return fetchPayment(id!, currentCompany!.id);
		},
		{
			onSuccess: (data) => {
				const { beneficiariesParsed, benefitsParsed } = parsePayment(data);

				setBeneficiaries([
					...beneficiariesParsed.map((beneficiary) => ({
						...beneficiary,
						selected: true,
					})),
				]);
				setBenefits(benefitsParsed);
				setSelectedBenefits(benefitsParsed);
			},
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao buscar o lançamento. '
				);
			},
			refetchOnWindowFocus: false,
			refetchOnReconnect: false,
		}
	);

	const cancelPaymentMutation = useMutation(
		(reason_disapproval: string) => {
			return cancelPayment(reason_disapproval, id, currentCompany!.id);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries(['fetchPayment', currentCompany?.id, id]);
				toast.info('Pagamento cancelado');
			},
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao cancelar o agendamento. '
				);
				console.log(err);
			},
		}
	);

	const chargebackPaymentMutation = useMutation(
		() => {
			return chargebackPayment(id, currentCompany!.id);
		},
		{
			onSuccess: (data) => {
				if (data.unpaidPayments.length === 0) {
					openConfirmDialog(
						'O pagamento foi estornado com sucesso. Aguarde o processo finalizar.',
						'Entendido',
						() => {
							history.push('/home/payments');
						},
						false
					);
				} else if (data.unpaidPayments.length < paymentsAmount) {
					toast.info('Pagamento estornado parcialmente.');
				}
			},
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao estornar o pagamento. '
				);
				console.log(err);
			},
		}
	);

	useEffect(() => {
		setCalculatedPaymentsAmount();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [beneficiaries]);

	const setCalculatedPaymentsAmount = () => {
		setPaymentsAmount(calculatePaymentsAmount(beneficiaries));
	};

	function isPaymentCancellable() {
		return (
			(fetchPaymentQuery.data?.release.status === 'requested' ||
				fetchPaymentQuery.data?.release.status === 'scheduled') &&
			(fetchPaymentQuery.data?.release.userOperatorCreator.id === user.id ||
				user.access_level === 'admin')
		);
	}

	function collaboratorsFinishedWithErrors() {
		const release = fetchPaymentQuery.data!.release;

		let collaborators: Collaborator[] = [];
		// get all collaborators with repproved benefits
		release.preReleases.forEach((r) => {
			if (r.release_payment.reason_disapproval) {
				const collaboratorIndex = collaborators.findIndex(
					(c) => c.id === r.collaborator.id
				);
				const benefit = {
					...r.release_payment.benefit,
					value: Number(r.release_payment.benefit.value) / 100,
				};

				if (collaboratorIndex > -1) {
					collaborators[collaboratorIndex].benefits = [
						...collaborators[collaboratorIndex].benefits,
						benefit,
					];
				} else {
					collaborators.push({
						...r.collaborator,
						benefits: [benefit],
					});
				}
			}
		});

		// get all collaborators from group that have been repproved
		beneficiaries
			.filter((b) => b.isGroup)
			.forEach((group) => {
				if (group.individualsRepproved !== undefined) {
					group.individualsRepproved.forEach((i) => {
						const alreadyInsertedCollaboratorIndex = collaborators.findIndex(
							(c) => c.id === i.collaborator.id
						);
						const benefit = group.benefits.find((b) => b.id === i.benefitId)!;

						if (alreadyInsertedCollaboratorIndex > -1) {
							const benefits = [
								{ ...benefit },
								...collaborators[alreadyInsertedCollaboratorIndex].benefits,
							];
							collaborators[alreadyInsertedCollaboratorIndex].benefits =
								benefits;
						} else {
							const collaborator = { ...i.collaborator, benefits: [benefit] };
							collaborators.push(collaborator);
						}
					});
				}
			});

		return collaborators;
	}

	function relaunchCollaboratorsWithErrors() {
		history.push('/home/payments/relaunch/', {
			collaborators: collaboratorsFinishedWithErrors(),
		});
	}

	// BUTTON LGOIC
	function scrollPageToBottom() {
		window.scrollTo(0, document.body.scrollHeight);
	}

	function scrollPageToTop() {
		window.scrollTo(0, 0);
	}

	if (
		!fetchPaymentQuery.data ||
		fetchPaymentQuery.isFetching ||
		cancelPaymentMutation.isLoading ||
		chargebackPaymentMutation.isLoading
	) {
		return (
			<S.Container>
				<PageTitle title='Detalhamento' />
				<Loader />
			</S.Container>
		);
	}
	return (
		<>
			<S.Container>
				<S.UnderlinedButton
					id='scrollToBottomButton'
					onClick={scrollPageToBottom}
				>
					Ir para o Final
					<AiOutlineArrowDown />
				</S.UnderlinedButton>
				<PaymentHeader title='Detalhamento' release={fetchPaymentQuery.data!} />

				{/* TABLE COMPONENT THAT RENDERS COLLABORATORS AND THEIR BENEFITS */}
				<div style={{ overflowX: 'auto', maxWidth: '80vw' }}>
					<PaymentTableStatic />
				</div>

				<S.FooterContainer>
					{(fetchPaymentQuery.data.release.status === 'approved' ||
						fetchPaymentQuery.data.release.status === 'chargeback-error') &&
						user.access_level === 'admin' && (
							<S.RelaunchPaymentButton
								onClick={() => chargebackPaymentMutation.mutate()}
							>
								Estornar pagamento
							</S.RelaunchPaymentButton>
						)}
					{isPaymentCancellable() && (
						<CancelPaymentModal
							onCancel={async (reason) => cancelPaymentMutation.mutate(reason)}
						/>
					)}
					{isPaymentEditable(
						fetchPaymentQuery.data.release.status,
						fetchPaymentQuery.data.release.userOperatorCreator.id,
						user.id
					) && (
						<S.EditPaymentLink to={`/home/payments/edit/${id}`}>
							Editar pagamento
						</S.EditPaymentLink>
					)}

					{fetchPaymentQuery.data.release.status === 'finished error' && (
						<S.RelaunchPaymentButton onClick={relaunchCollaboratorsWithErrors}>
							Relançar pagamentos com erros
						</S.RelaunchPaymentButton>
					)}
				</S.FooterContainer>

				<S.UnderlinedButton id='scrollToBottomButton' onClick={scrollPageToTop}>
					<AiOutlineArrowUp />
					Voltar ao topo
				</S.UnderlinedButton>

				<PaymentLogs paymentId={id} />

				<NotApprovedCollabsModal
					collabsReproved={chargebackPaymentMutation.data?.unpaidPayments ?? []}
					title={'Estornos que não passaram no lançamento'}
				/>
			</S.Container>
		</>
	);
}
