import { useState } from 'react';
import { BsFillEmojiSmileFill } from 'react-icons/bs';
import styled from 'styled-components';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';

interface EmojiPickerProps {
	onPickEmoji: (emoji: any) => void;
}
export function EmojiPicker({ onPickEmoji }: EmojiPickerProps) {
	const [openEmojiPicker, setOpenEmojiPicker] = useState(false);

	return (
		<Container>
			<EmojiButton>
				<BsFillEmojiSmileFill
					data-testid='open_picker_btn'
					onClick={() => setOpenEmojiPicker(!openEmojiPicker)}
				/>
			</EmojiButton>

			<PickerContainer>
				{openEmojiPicker && (
					<Picker
						data={data}
						onClickOutside={() => setOpenEmojiPicker(false)}
						onEmojiSelect={onPickEmoji}
						locale={'pt'}
					/>
				)}
			</PickerContainer>
		</Container>
	);
}

const Container = styled.div`
	position: relative;
`;
const EmojiButton = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	height: 2.5rem;
	width: 2.5rem;
	padding: 0.2rem;

	border-width: 1px;
	border-radius: 50px;
	border-style: solid;
	cursor: pointer;
	transition: filter 0.2s;
	&:hover {
		filter: brightness(0.9);
	}
`;

const PickerContainer = styled.div`
	position: absolute;
	z-index: 1;
`;
