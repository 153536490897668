import { Redirect, Route, Switch, useRouteMatch } from 'react-router';
import { CompanyDetails } from '../pages/FlexibleBenefits/Company/CompanyDetails';
import Operators from '../pages/SuperAdmin/Main/Operators';
import { CreateOperator } from '../pages/SuperAdmin/Main/Operators/CreateOperator';
import { ManageOperators } from '../pages/SuperAdmin/Main/Operators/ManageOperators';
import { EditProfile } from '../pages/FlexibleBenefits/EditProfile';
import { Terms } from '../pages/TermsAndPrivacy';

export default function SuperAdminRoutes() {
	const { path } = useRouteMatch();

	return (
		<Switch>
			<Route exact path={path}>
				<Redirect to={`${path}/operators`} />
			</Route>

			<Route exact path={`${path}/operators`} component={Operators} />
			<Route
				path={`${path}/operators/manage/:id`}
				component={ManageOperators}
			/>
			<Route path={`${path}/operators/create`} component={CreateOperator} />

			<Route path={`${path}/company`} component={CompanyDetails} />
			<Route path={`${path}/profile`} component={EditProfile} />
			<Route path={`${path}/terms`} component={Terms} />
		</Switch>
	);
}
