import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { OptionsButton } from '../Button';

export const NotificationsList = styled.ul`
	display: flex;
	flex-direction: column;
	max-width: 50rem;
	min-width: 50rem;
	max-height: 80vh;
	padding-right: 0.4rem;
	overflow-y: auto;

	&::-webkit-scrollbar {
		width: 1rem;
	}

	&::-webkit-scrollbar-track {
		border-radius: 1rem;
		background-color: #e3e8eb;
		margin-top: 0.2rem;
	}

	&::-webkit-scrollbar-thumb {
		background-color: var(--light-gray);
		border-radius: 1rem;
	}
`;

export const LoadingContainer = styled.div`
	margin: 1rem 0;
`;

interface NotificationSeenProps {
	$unread: boolean;
}

export const Notification = styled.li<NotificationSeenProps>`
	display: flex;
	position: relative;
	flex-direction: column;
	row-gap: 1rem;
	padding: 2rem 3rem 0 3rem;

	background: ${({ $unread }) =>
		$unread ? 'linear-gradient(180deg, #27ADFF 0%, #008CE1 100%)' : ''};

	color: ${({ $unread }) => ($unread ? 'var(--white) !important' : '#a4a4a4')};
`;

export const NotificationTop = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

export const NotificationOptionBtn = styled.button<NotificationSeenProps>`
	background: none;
	width: 1.6rem;
	height: 1.6rem;
	position: relative;

	& > div {
		position: absolute;
		/* background-color: red; */
		width: 100%;
		height: 100%;
	}
	& > svg {
		width: 100%;
		height: 100%;

		color: ${({ $unread }) => ($unread ? '#1f59a4' : 'var(--light-gray)')};
	}
`;

export const Divider = styled.div<NotificationSeenProps>`
	margin-top: 1rem;
	width: 100%;
	border-bottom: solid 1px
		${({ $unread }) => ($unread ? 'var(--white)' : 'var(--light-gray)')};
	align-self: center;
	height: min-content;
	margin-bottom: 2rem;
`;

export const NotificationDate = styled.span`
	font-size: 1.2rem;
`;

export const NotificationCompany = styled.span`
	font-size: 1.4rem;
	font-weight: 700;
`;

export const NotificationType = styled.span`
	font-size: 1.4rem;
	font-weight: 500;
`;

export const NotificationMessage = styled.span<NotificationSeenProps>`
	color: ${({ $unread }) => ($unread ? 'var(--white)' : 'var(--dark-gray)')};

	font-size: 1.6rem;
	font-weight: 700;
`;

export const SeeDetailsLink = styled(Link)<NotificationSeenProps>`
	color: ${({ $unread }) => ($unread ? 'var(--white)' : 'var(--primary-blue)')};
	display: flex;
	flex-direction: column;
	font-size: 1.4rem;

	& > span {
		display: flex;
		align-items: center;
		& > svg {
			width: 2rem;
			height: 2rem;
		}
	}
`;

export const Footer = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 0 3rem;
`;

export const FooterBtn = styled.button`
	${OptionsButton}
	width: 20rem;
	padding: 0.5rem 0;
	align-self: center;
	margin: 2rem auto;
`;
