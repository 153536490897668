import { useMutation } from 'react-query';
import { validateOperatorPassword } from '../services/queries/Operators';
import { toast } from 'react-toastify';

interface Props {
	onSuccess?: () => void;
	onFailure?: () => void;
}

/*
  Validate corpway user password.
  If onSuccess callback is passed then when validateUserPassword or validateUserPasswordAsync are called
  and the password is valid onSuccess callback is called.

  Alternatively, the user can wait for validateUserPasswordAsync promise to resolve with a boolean value.
*/
export function useValidateCorpwayUserPassword(props: Props) {
	const validatePasswordMutation = useMutation(
		(password: string) => validateOperatorPassword(password),
		{
			onSuccess: (data) => {
				if (data.validation) {
					props.onSuccess?.();
				} else {
					props.onFailure?.();
				}
			},
			onError: () => {
				toast.error(
					'Ocorreu um problema ao tentar validar a senha do operador. Tente novamente.'
				);
			},
		}
	);

	const validateUserPasswordAsync = async (password: string) => {
		const data = await validatePasswordMutation.mutateAsync(password);

		return data.validation;
	};

	return {
		validateUserPassword: (password: string) =>
			validatePasswordMutation.mutate(password),
		validateUserPasswordAsync,
		isPasswordValidationLoading: validatePasswordMutation.isLoading,
	};
}
