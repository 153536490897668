import { useMutation, useQuery } from 'react-query';
import {
	ApproveCorpwayTermsAndPrivacyPolicy,
	getCorpwayPrivacyPolicy,
	getCorpwayTerms,
} from '../../services/queries/Terms';
import * as S from './styles';
import LogoControl from '../../assets/BountyControl.svg';
import LogoControlWhite from '../../assets/BountyControlWhite.svg';
import Loader from '../../components/Loader';
import { showErrorMessage } from '../../utils/ErrorHandler';
import { CorporateExpensesTheme } from '../../styles/CorporateExpensesTheme';
import React, { useState } from 'react';
import { useAuth } from '../../hooks/useAuth';
import { useHistory } from 'react-router-dom';
import TermsAndPrivacy from '../../componentsV2/ui/Form/TermsAndPrivacy';

export function TermsCorpway() {
	const [isFirstStep, setIsFirstStep] = useState(true);
	const { user, updateUserInfo } = useAuth();
	const { push } = useHistory();

	const termsQuery = useQuery<string, Error>(
		['corpwayTerm'],
		() => {
			return getCorpwayTerms();
		},
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível buscar os termos de uso. ',
				);
			},
			refetchOnWindowFocus: false,
			refetchOnMount: false,
		},
	);

	const privacyQuery = useQuery(
		['privacyPolicyCorpway'],
		() => {
			return getCorpwayPrivacyPolicy();
		},
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível buscar os avisos de privacidade. ',
				);
			},
			refetchOnWindowFocus: false,
			refetchOnMount: false,
		},
	);

	const approvedMutation = useMutation(ApproveCorpwayTermsAndPrivacyPolicy, {
		onSuccess() {
			const updateUser = {
				privacy_policy_accepted_corpway: true,
				term_of_use_accepted_corpway: true,
			};
			updateUserInfo({ ...user, ...updateUser });
			push('/corporate-expenses/');
		},
	});

	if (termsQuery.isLoading || privacyQuery.isLoading) {
		return (
			<S.Container>
				<CorporateExpensesTheme />
				<S.LogoImage
					className="responsiveImage"
					src={LogoControlWhite}
					alt="Logo do Bounty"
				/>
				<S.TermsContainer>
					<S.TextTitle>
						{termsQuery.isLoading ? 'Termos de uso' : 'Aviso de privacidade'}
					</S.TextTitle>
				</S.TermsContainer>
				<div style={{ marginTop: '3rem' }} />
				<Loader color="white" />
			</S.Container>
		);
	}

	return (
		<>
			<S.Container>
				<CorporateExpensesTheme />
				<S.LogoImage
					className="responsiveImage"
					src={LogoControlWhite}
					alt="Logo do Bounty"
				/>
				<TermsAndPrivacy
					isLoading={isFirstStep ? privacyQuery.isLoading : termsQuery.isLoading}
					isTermsOfUse={isFirstStep}
					logo={LogoControl}
					onNext={() => {
						if(isFirstStep){
							setIsFirstStep(false)
							return
						}

						approvedMutation.mutate();
					}}
 					content={isFirstStep ? (termsQuery.data ?? "") : (privacyQuery.data ?? "") }
				/>
			</S.Container>
		</>
	);
}
