import styled from 'styled-components';
import { OptionsButton } from '../../Button';

export const ModalContainer = styled.div`
	width: 60rem;
`;
export const OpenModalButtonLarge = styled.button`
	width: 30%;
	font-weight: bold;
	background-color: var(--white);
	color: var(--primary-blue);
	border: 0.2rem solid var(--primary-blue);
	padding: 0.5rem 1.3rem;
	border-radius: 0.4rem;
`;

export const OpenModalButtonSmall = styled.button`
	margin-left: 1rem;
	display: flex;
	align-items: center;
	justify-content: center;
	background: none;
	border: 0.2rem solid var(--primary-blue);
	border-radius: 2rem;
	padding: 0.3rem;

	& > svg {
		width: 3rem;
		height: 3rem;
		color: var(--primary-blue);
	}
`;

export const BottomOptionsContainer = styled.div`
	display: flex;
	margin-top: auto;
	justify-content: end;
	column-gap: 1rem;
`;

export const SaveButton = styled.button`
	${OptionsButton}
	/* width: min-content; */
  padding: 0.6rem 6rem;
`;
