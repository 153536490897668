import { Transaction } from '../../../../../../../@types/CorporateExpenses/Transaction';
import { getTransactionStatus } from '../../../../utils/transactionUtils';
import { Text } from '@react-pdf/renderer';

export function checkDeclineAndStrikeText(
	response_code: number,
	data?: string
) {
	if (!data) return '--';

	const status = getTransactionStatus(response_code);

	if (status === 'Declined') {
		return <Text style={{ textDecoration: 'line-through' }}>{data}</Text>;
	}

	return data;
}

function isExternalRefund(transaction: Transaction) {
	let status = getTransactionStatus(transaction.response_code)

	if (status === 'Declined' || status === '--') return false

	switch (transaction.txn_type) {
		case 55:
		case 0:
			if (transaction.msg_type === 1400 || transaction.msg_type === 1420) return true // External refund
			return false
		case 13:
		case 14:
		case 68:
			if (transaction.msg_type === 8900 || transaction.msg_type === 1220) return false // IOF or Internal refund
			return true
		default:
			return false
	}
}

function isPurchase(transaction: Transaction) {
	switch (transaction.txn_type) {
		case 55:
		case 0:
			if (transaction.msg_type === 1400 || transaction.msg_type === 1420) return false // External refund
			return true // purchases
		default:
			return false
	}
}


function isFeeCredit(transaction: Transaction) {
	return transaction.txn_type === 12 && transaction.msg_type === 8900
}

function isFeeDebit(transaction: Transaction) {
	return transaction.txn_type === 13 && transaction.msg_type === 8900
}

function isValidStatus(response_code: number) {
	return getTransactionStatus(response_code) === 'Approved'
}

export function calculateCreditValue(transactions: Transaction[]) {
  let total = 0
  transactions.forEach((transaction) => {
    // Only sums external refund
    if ((isExternalRefund(transaction) || isFeeCredit(transaction)) && isValidStatus(transaction.response_code)) {
      total += transaction.billing_amount
    }
  })
  return total
}

export function calculateDebitValue(transactions: Transaction[]) {
  let total = 0
  transactions.forEach((transaction) => {
    if ((isPurchase(transaction) || isFeeDebit(transaction)) && isValidStatus(transaction.response_code)) {
      total += transaction.billing_amount
    }
  })
  return total
}