import { useAuth } from '../../hooks/useAuth';
import { CorporateExpensesRoutes } from '../../routes/CorporateExpensesRoutes';
import * as S from './styles';
import { Sidebar } from './components/Sidebar';
import { Header } from './components/Header';
import { useEffect } from 'react';
import { CorporateExpensesTheme } from '../../styles/CorporateExpensesTheme';
import { GlobalStateContextProvider } from '../../contexts/GlobalStateContext';
import { Redirect } from 'react-router-dom';

const CORPWAY_PAGE_TITLE = 'Bounty | Control';

export function CorporateExpenses() {
	useEffect(() => {
		document.title = CORPWAY_PAGE_TITLE;
	}, []);

	const { currentCompany, user } = useAuth();

	if (
		!user.term_of_use_accepted_corpway ||
		!user.privacy_policy_accepted_corpway
	) {
		return <Redirect to='/corporate-expenses/terms' />;
	}

	return (
		<S.Container>
			<GlobalStateContextProvider>
				<CorporateExpensesTheme />
				<Header />

				<S.MainContent>
					<Sidebar />
					<S.PagesContainer>
						{currentCompany && <CorporateExpensesRoutes />}
					</S.PagesContainer>
				</S.MainContent>
			</GlobalStateContextProvider>
		</S.Container>
	);
}
