import * as S from './styles';
import logoImg from '../../assets/Bounty.svg';

export function ServiceUnavailable() {
	return (
		<S.Container>
			<S.LogoImage src={logoImg} alt='Logo do Bounty' />

			<S.Content>
				<span>
					Estamos em manutenção. Entre em contato conosco via os endereços
				</span>
				<a href='mailto:suporte@cartaobounty.com.br'>
					suporte@cartaobounty.com.br
				</a>
				<span>ou</span>
				<a href='mailto:contato@cartaobounty.com.br'>
					contato@cartaobounty.com.br
				</a>
			</S.Content>
		</S.Container>
	);
}
