import styled from 'styled-components';
import { Button } from '../../../../../../componentsV2/ui/Button';
import { FaQuestion } from 'react-icons/fa';
import Modal from '../../../../../../componentsV2/ui/Modal';
import { useEffect, useState } from 'react';
import { UploadedInvoice } from '../reducer';
import { S3File } from '../../../../../../@types/CorporateExpenses/Transaction';

interface Props {
	deleteFile(invoice: string): void;
	file_data?: { name: string; parsedName: string; file: string | S3File };
	setFile: (file: UploadedInvoice | undefined) => void;
}
export function DeleteInvoiceModal({ file_data, setFile, deleteFile }: Props) {
	const [isOpen, setIsOpen] = useState(false);
	const [currentStep, setCurrentStep] = useState<0 | 1>(0);

	useEffect(() => {
		if (file_data) {
			setIsOpen(true);
		}
	}, [file_data]);

	function closeModal() {
		setIsOpen(false);
		setFile(undefined);
		setCurrentStep(0);
	}

	const DeleteConfirmation = () => {
		return (
			<DeleteFileContainer>
				<MessageContainer>
					<IconContainer>
						<FaQuestion color='#fff' size={25} />
					</IconContainer>

					<ModalTitle>
						<p style={{ fontWeight: 600 }}>Anexo excluído</p>
						<p>&nbsp;com sucesso</p>
					</ModalTitle>
				</MessageContainer>

				<ButtonsContainer>
					<Button intent='primary' roundness='lg' onClick={closeModal}>
						Fechar
					</Button>
				</ButtonsContainer>
			</DeleteFileContainer>
		);
	};

	const DeleteFile = () => {
		return (
			<DeleteFileContainer>
				<MessageContainer>
					<IconContainer>
						<FaQuestion color='#fff' size={25} />
					</IconContainer>

					<ModalTitle>
						<p style={{ fontWeight: 600 }}>Você tem certeza</p>
						<p>&nbsp;que deseja excluir:</p>
					</ModalTitle>
					<ModalMessage>
						<b>"{file_data?.parsedName}"</b> desta transação?
					</ModalMessage>
				</MessageContainer>

				<ButtonsContainer>
					<Button
						onClick={closeModal}
						intent='terciary'
						roundness='lg'
						$outline
					>
						Voltar
					</Button>

					<Button
						intent='primary'
						roundness='lg'
						onClick={() => {
							deleteFile(file_data!.name);
							setCurrentStep(1);
						}}
					>
						Confirmar
					</Button>
				</ButtonsContainer>
			</DeleteFileContainer>
		);
	};

	return (
		<Modal isOpen={isOpen} onRequestClose={closeModal} position='center'>
			{currentStep === 0 && <DeleteFile />}
			{currentStep === 1 && <DeleteConfirmation />}
		</Modal>
	);
}

const DeleteFileContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	width: 440px;
	min-height: 250px;
	padding-top: 3rem;
	padding-bottom: 2rem;
`;

const MessageContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	row-gap: 0.8rem;
	font-size: 2rem;
`;

const ModalTitle = styled.div`
	display: flex;

	& > p {
		font-size: 1.8rem;
	}
`;

const ModalMessage = styled.p`
	text-align: center;
	width: 80%;
	font-size: 1.5rem;
	color: var(--gray);
	margin-top: 4rem;
	margin-bottom: 2.5rem;
	word-break: break-all;
`;

const IconContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: var(--primary-blue);
	width: 4rem;
	height: 4rem;
	border-radius: 50px;
`;

const ButtonsContainer = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
	column-gap: 2rem;
	margin-top: auto;
	margin-bottom: 1rem;
`;
