import { TitleTypography } from '../../componentsV2/ui/Typography';
import { CorporateExpensesTheme } from '../../styles/CorporateExpensesTheme';

import * as S from './styles';
import ModelImage from '../../assets/ModeloSignIn.png';
import { MdKeyboardReturn } from 'react-icons/md';

import { useAuth } from '../../hooks/useAuth';

export function Support() {
	const { signOut } = useAuth();

	return (
		<S.PageWrapper>
			<CorporateExpensesTheme />
			<S.TopContainer>
				<img src={ModelImage} alt='Men with computer' />
			</S.TopContainer>

			<S.Btn>
				<S.SignOutBtn onClick={signOut}>
					<MdKeyboardReturn /> Algum problema? Voltar para login.
				</S.SignOutBtn>
			</S.Btn>
			<S.ContentWrapper>
				<S.Container>
					<S.Content>
						<TitleTypography>Nenhuma empresa encontrada</TitleTypography>
						<span>
							Nenhuma empresa está associada a este usuário. Por favor, contate
							o Suporte através do email{' '}
							<S.Link href='mailto:suporte@cartaobounty.com.br'>
								suporte@cartaobounty.com.br
							</S.Link>{' '}
							.
						</span>
					</S.Content>
				</S.Container>
			</S.ContentWrapper>
		</S.PageWrapper>
	);
}
