import { useForm, UseFormWatch } from 'react-hook-form';
import {
	EditUserDetails,
	UserDetails,
} from '../../../../../../@types/CorporateExpenses/User';
import { useState } from 'react';
import { Typography } from '../../../../../../componentsV2/ui/Typography';
import { PasswordInput } from '../../../../../../componentsV2/ui/Form/PasswordInput';
import { Button } from '../../../../../../componentsV2/ui/Button';
import { useValidateCorpwayUserPassword } from '../../../../../../hooks/useValidateCorpwayUserPassword';
import * as S from './styles';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

const schema = z.object({
	password: z.string().min(1, 'Informe sua senha para prosseguir'),
});

export default function PasswordValidation({
	watch,
	setFormStep,
	user,
	onNext,
	isLoading,
}: {
	watch: UseFormWatch<EditUserDetails>;
	setFormStep: (step: 1 | 2 | 3 | 4) => void;
	user: UserDetails;
	isLoading: boolean;
	onNext: () => void;
}) {
	const [password, setPassword] = useState('');
	const selectedAccessLevel = watch('access_level');
	const {
		formState: { errors },
		setError,
	} = useForm({
		resolver: zodResolver(schema),
	});
	const { validateUserPassword, isPasswordValidationLoading } =
		useValidateCorpwayUserPassword({
			onSuccess: onNext,
			onFailure: () =>
				setError('password', {
					type: 'manual',
					message: 'Senha incorreta, por favor digite novamente!',
				}),
		});

	return (
		<>
			<S.SmallModalContainer>
				<Typography style={{ width: '100%' }} weight={'bold'}>
					Confirme a sua senha para efetivar as alteracões:
				</Typography>

				<PasswordInput
					placeholder={'*********'}
					value={password}
					required
					showLeftIcon
					autoComplete='new-password'
					errorMessage={errors.password?.message}
					onChange={(e) => setPassword(e.target.value)}
				/>

				<S.ButtonsContainerSmallModal>
					<Button
						onClick={() =>
							setFormStep(selectedAccessLevel !== user.role ? 2 : 1)
						}
						intent='terciary'
						roundness='lg'
						$outline
					>
						Voltar
					</Button>

					<Button
						intent={'primary'}
						width='adjusted'
						roundness='lg'
						shrinkOnLoading={false}
						loading={isLoading || isPasswordValidationLoading}
						onClick={() => {
							validateUserPassword(password);
						}}
						disabled={!password}
					>
						Confirmar
					</Button>
				</S.ButtonsContainerSmallModal>
			</S.SmallModalContainer>
		</>
	);
}
