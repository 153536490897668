import styled from 'styled-components';
import { OptionsButton } from '../../../../components/Button';

export const Container = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	padding: 4rem 7rem 2rem 7rem;
`;

export const Content = styled.div`
	display: flex;
	flex-direction: column;
`;

export const OptionsContainer = styled.div`
	display: flex;
`;

export const Option = styled.button`
	${OptionsButton}
	background-color: transparent;
	color: var(--primary-blue);
	margin-right: 2rem;
	transition: all 0.2s;
	&:hover {
		background-color: var(--primary-blue);
		color: var(--white);
	}
`;
