import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Operator } from '../../../../../@types';
import Loader from '../../../../../components/Loader';
import OperatorForm from '../../../../../components/OperatorForm';
import PageTitle from '../../../../../components/PageTitle';
import { useDialogModal } from '../../../../../hooks/useDialogModal';
import {
	getOperator,
	toggleStatus,
	updateOperator,
} from '../../../../../services/queries/Operators';
import { parseMaskedCPFToRaw } from '../../../../../utils/masks';
import * as S from './styles';
import { showErrorMessage } from '../../../../../utils/ErrorHandler';
import { useAuth } from '../../../../../hooks/useAuth';
import {
	child,
	equalTo,
	get,
	orderByChild,
	Query,
	query,
	ref,
	update,
} from 'firebase/database';
import { db } from '../../../../../services/firebase';

interface ManageOperatorsParams {
	id: string;
}

export function ManageOperators() {
	const { id } = useParams<ManageOperatorsParams>();
	const queryClient = useQueryClient();
	const { openConfirmDialog } = useDialogModal();
	const { companies, firebaseAuthConcluded } = useAuth();

	const fetchOperatorQuery = useQuery<Operator, Error>(
		['fetchOperator', id],
		() => {
			return getOperator(id);
		},
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao tentar buscar o operador.'
				);
			},
		}
	);

	const updateOperatorMutation = useMutation(
		(operator: Operator) => {
			return updateOperator(id, operator);
		},
		{
			onSuccess: async (data) => {
				await updateFirebaseOperator(data.name, data.email, data.office);
				queryClient.invalidateQueries(['fetchOperator', id]);
				toast.info('Operador editado com sucesso');
			},
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu algum problema ao tentar atualizar o operador. '
				);
			},
		}
	);

	async function handleSubmitUpdate(data: Operator) {
		let { cpf, email, name, office } = data;
		// alert(JSON.stringify(data))
		const updatedOperatorData = {
			cpf: parseMaskedCPFToRaw(cpf),
			email,
			name,
			office,
		} as Operator;

		await updateOperatorMutation.mutateAsync(updatedOperatorData);
	}

	const toggleStatusMutation = useMutation(
		(status: boolean) => {
			return toggleStatus(id, status);
		},
		{
			onSuccess: () => {
				toast.info(`Status do operador atualizado!`);
				queryClient.invalidateQueries(['fetchOperator', id]);
			},
			onError: (err) => {
				console.log(err);
				toast.error(
					'Ocorreu algum problema ao mudar o status do operador. Tente novamente'
				);
			},
		}
	);

	async function handleToggleStatus() {
		openConfirmDialog(
			`Tem certeza que deseja ${
				fetchOperatorQuery.data!.status ? 'desativar' : 'ativar'
			} o operador?`,
			'Sim',
			async () => {
				await toggleStatusMutation.mutateAsync(
					!fetchOperatorQuery.data?.status
				);
			}
		);
	}

	async function updateFirebaseOperator(
		name: string,
		email: string,
		office: string
	) {
		if (!firebaseAuthConcluded) return;

		await Promise.allSettled(
			companies.map(async (company) => {
				await setFirebaseOperatorInfo(name, email, office, company.id);
				return true;
			})
		);
	}

	async function getOperatorData(searchQuery: Query) {
		try {
			const snapshot = await get(searchQuery);
			return snapshot.val();
		} catch (error) {
			console.log(error);
			return null;
		}
	}

	async function setFirebaseOperatorInfo(
		name: string,
		email: string,
		office: string,
		companyId: string
	) {
		const operatorsRef = ref(db, `${companyId}/operators`);
		const searchOperatorId = query(
			operatorsRef,
			orderByChild('id'),
			equalTo(fetchOperatorQuery.data?.id!)
		);

		const searchOperatorEmail = query(
			operatorsRef,
			orderByChild('email'),
			equalTo(fetchOperatorQuery.data?.email!)
		);

		const searchOperatorName = query(
			operatorsRef,
			orderByChild('name'),
			equalTo(fetchOperatorQuery.data?.name!)
		);

		try {
			const [operatorDataByID, operatorDataByEmail, operatorDataByName] =
				await Promise.all([
					getOperatorData(searchOperatorId),
					getOperatorData(searchOperatorEmail),
					getOperatorData(searchOperatorName),
				]);

			const operatorData =
				operatorDataByID || operatorDataByEmail || operatorDataByName;

			const operatorToUpdateRef = child(
				operatorsRef,
				Object.keys(operatorData)[0]
			);

			await update(operatorToUpdateRef, {
				name: name,
				email: email,
				jobDescription: office,
			});
		} catch (err) {
			console.log(err);
		}
	}

	if (
		!fetchOperatorQuery.data ||
		updateOperatorMutation.isLoading ||
		toggleStatusMutation.isLoading ||
		fetchOperatorQuery.error
	) {
		return (
			<S.Container>
				<S.Content>
					<PageTitle title='Gerenciar Operador' />
					<Loader />
				</S.Content>
			</S.Container>
		);
	}
	return (
		<S.Container>
			<S.Content>
				<PageTitle title='Gerenciar Operador' />
				<OperatorForm
					operator={fetchOperatorQuery.data}
					onSubmit={handleSubmitUpdate}
					changeStatus={handleToggleStatus}
				/>
			</S.Content>
		</S.Container>
	);
}
