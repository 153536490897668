import styled from 'styled-components';

export const Container = styled.div`
	flex: 1;
	height: 100vh;
	display: flex;
	background: linear-gradient(#2dd1fc 0%, #27adff 100%);
	& > div {
		flex: 1;
	}

	@media (max-width: 910px) {
		flex-direction: column;
		height: 100%;
	}
`;

export const LeftContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;

	& > img {
		width: 70rem;
	}

	.ResponsiveModelImage {
		display: none;
		margin-top: 2.5rem;
		margin-bottom: -16rem;
		clip-path: inset(0 0.2rem 0 0);
	}

	@media (max-width: 810px) {
		& > img {
			width: 26rem;
		}

		.modelImage {
			display: none;
		}

		.ResponsiveModelImage {
			display: block;
		}
	}
`;

export const RightContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	border-top-left-radius: 3.2rem;
	border-bottom-left-radius: 3.2rem;
	background-color: var(--white);
	z-index: 1;

	@media (max-width: 810px) {
		border-bottom-left-radius: 0rem;
		border-top-right-radius: 3.2rem;
	}
`;

export const MainContainer = styled.main`
	width: 40rem;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 1rem;

	& > img {
		width: 17.2rem;
	}

	& div {
		& h2 {
			color: var(--dark-gray2);
			font-size: 3.2rem;
			font-weight: 600;
		}

		.textDetails {
			color: var(--dark-gray2);
			font-size: 1.5rem;
		}
	}

	.responsiveButton {
		display: none;
	}

	@media (max-width: 810px) {
		padding: 4rem 2rem 0rem;
		gap: 2rem;

		.responsiveButton {
			display: flex;
			margin-top: 0rem;
			border-bottom: 1px solid;
		}

		.backButton {
			display: none;
		}
	}
`;
export const SignOutBtn = styled.button`
	background: none;
	display: flex;
	column-gap: 1rem;
	align-items: center;
	color: var(--black);
	font-weight: 600;
	font-size: 1.6rem;
	width: fit-content;
	margin-top: 2rem;
	& > svg {
		width: 2.2rem;
		height: 2.2rem;
	}
`;
export const OptionsContainer = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 1.6rem;

	.cardBenefitsContainer {
		background-color: var(--primary-red);
		border: 2px solid #f5f5f5;
		height: 16rem;
		width: 100%;

		img {
			width: 14rem;
		}

		> .contentWrapper {
			color: var(--white);
			padding: 6.4rem 0.4rem;

			@media (max-width: 650px) {
				padding: 6.4rem 3rem 6.4rem 0.4rem;
			}

			.access {
				display: flex;
				margin-top: 1.5rem;
				border-bottom: 1px solid;
				margin-right: 4rem;
				font-weight: 600;
			}
		}

		span {
			font-size: 1.6rem;
			font-weight: 400;
		}

		p {
			font-size: 2.4rem;
			font-weight: 600;
			line-height: 120%;
		}
	}

	.cardControlContainer {
		background-color: var(--primary-blue);
		border: 2px solid #f5f5f5;
		height: 16rem;
		width: 100%;

		img {
			width: 11rem;
			margin-right: 3rem;
		}

		> .contentWrapper {
			color: var(--white);
			padding: 6.4rem 0.4rem;

			@media (max-width: 650px) {
				padding: 6.4rem 3rem 6.4rem 0.4rem;
			}

			.access {
				display: flex;
				margin-top: 1.5rem;
				border-bottom: 1px solid;
				font-weight: 600;
			}
		}

		span {
			font-size: 1.6rem;
			font-weight: 400;
		}

		p {
			font-size: 2.4rem;
			font-weight: 600;
			line-height: 120%;
		}
	}

	@media (max-width: 650px) {
		flex-direction: row;
		gap: 1rem;
		overflow-y: hidden;

		&::-webkit-scrollbar {
			width: 1rem;
			height: 0.5rem;
		}

		&::-webkit-scrollbar-track {
			border-radius: 1rem;
			background-color: #e3e8eb;
		}

		&::-webkit-scrollbar-thumb {
			background-color: var(--primary-blue);
			border-radius: 1rem;
		}
	}
`;

export const BenefitsImage = styled.img`
	position: absolute;
	bottom: 3rem;
	/* left: 0; */
	width: 100%;
	height: 5rem;
`;
