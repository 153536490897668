import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { InfoContent } from '../../../../../componentsV2/InfoContent';
import { Button } from '../../../../../componentsV2/ui/Button';
import { CurrencyInput } from '../../../../../componentsV2/ui/Form/CurrencyInput';
import { InputField } from '../../../../../componentsV2/ui/Form/InputField';
import Modal from '../../../../../componentsV2/ui/Modal';
import { Typography } from '../../../../../componentsV2/ui/Typography';
import { useAuth } from '../../../../../hooks/useAuth';
import {
	generateBilling,
	generateQRCode,
	sendQRCodeByEmail,
} from '../../../../../services/queries/Tranfers';
import {
	convertCentsToFormattedReais,
	convertReaisToCents,
} from '../../../../../utils/CurrencyConvert';
import { showErrorMessage } from '../../../../../utils/ErrorHandler';
import { generateQRCodeImage } from '../../../../../utils/generateQRcodeImage';
import {
	parseCurrencyStrToNumber,
	parseCurrencyToBRLStandard,
} from '../../../../../utils/parseCurrency';
import * as S from './styles';
import {
	HeaderText,
	IconContainer,
	ModalHeaderTextProps,
} from '../../../../../componentsV2/ui/Modal/InformationModal';
import { MdCheck } from 'react-icons/md';
import { IoIosArrowRoundUp } from 'react-icons/io';

import { ReactComponent as FileIcon } from '../../../../../assets/file-arrow-up-down.svg';

type RecipientEmailOption = 'financial' | 'operator' | 'custom';
type PaymentOption = 'PIX' | 'BILLING';

export function FundsInModal() {
	const { companyBalance, currentCompany, user } = useAuth();
	const [isOpen, setOpen] = useState(false);
	const [currentStep, setCurrentStep] = useState<0 | 1 | 2 | 3>(0);
	const [paymentOption, setPaymentOption] = useState<PaymentOption | undefined>(
		'PIX'
	);
	const [qrCodeImg, setQRCodeImg] = useState('');
	const [value, setValueBalance] = useState(0);
	const [successMessage, setSuccessMessage] = useState<
		ModalHeaderTextProps[] | []
	>([]);

	const [recipientEmailOption, setRecipientEmailOption] =
		useState<RecipientEmailOption>('operator');
	const customEmailForm = useForm<{ customEmail: string }>({
		defaultValues: {
			customEmail: '',
		},
	});

	const EMAILS = {
		financial: undefined,
		operator: user.email,
		custom: customEmailForm.getValues('customEmail'),
	};
	const recipientEmail = EMAILS[recipientEmailOption as keyof typeof EMAILS];

	useEffect(() => {
		if (isOpen) {
			setCurrentStep(0);
			setQRCodeImg('');
			setValueBalance(0);
			setRecipientEmailOption('operator');
			customEmailForm.reset();
		}
	}, [isOpen]); // eslint-disable-line  react-hooks/exhaustive-deps

	const generateQRCodeMutation = useMutation(
		async (amount: number) =>
			generateQRCode(amount, 'corpway', currentCompany!.id),
		{
			onError: () => {
				toast.error(
					'Ocorreu um problema ao tentar gerar o QRCode. Tente novamente.'
				);
			},
			onSuccess: async (data) => {
				setQRCodeImg(await generateQRCodeImage(data.emv));
				setCurrentStep(1);
			},
		}
	);

	const sendQRCodeByEmailQuery = useMutation(
		() => {
			const email =
				recipientEmailOption === 'custom'
					? customEmailForm.getValues('customEmail')
					: EMAILS[recipientEmailOption];

			return sendQRCodeByEmail(
				generateQRCodeMutation.data?.emv!,
				value / 100,
				currentCompany!.id,
				email,
				'corpway'
			);
		},
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao tentar enviar o QRCode. '
				);
			},
			onSuccess: () => {
				setSuccessMessage([
					{ text: 'Pedido de pagamento \n' },
					{ text: 'enviado com sucesso', isBold: true },
				]);
				setCurrentStep(3);
			},
		}
	);

	const generateBillingMutation = useMutation(
		async (amount: number) =>
			generateBilling(amount, 'corpway', currentCompany!.id, recipientEmail),
		{
			onSuccess: () => {
				setSuccessMessage([
					{ text: 'Pedido de pagamento \n' },
					{ text: 'enviado com sucesso', isBold: true },
				]);
				setCurrentStep(3);
			},
			onError: () => {
				toast.error(
					'Ocorreu um problema ao tentar gerar o boleto. Tente novamente.'
				);
			},
		}
	);

	const isPaymentGenerationLoading =
		generateQRCodeMutation.isLoading || generateBillingMutation.isLoading;

	function handleBack() {
		if (paymentOption === 'PIX') {
			setCurrentStep(1);
		} else {
			setCurrentStep(0);
		}
		setRecipientEmailOption('operator');
		customEmailForm.reset();
	}

	function handleGeneratePayment(
		valueInCents: number,
		paymentOption: PaymentOption
	) {
		if (valueInCents === 0) {
			toast.error('Insira um valor!');
			return;
		}
		setPaymentOption(paymentOption);
		setValueBalance(valueInCents);
		if (paymentOption === 'PIX') {
			generateQRCodeMutation.mutate(valueInCents);
		} else {
			setCurrentStep(2);
		}
	}

	function handleCopyQRCode() {
		navigator.clipboard.writeText(generateQRCodeMutation.data!.emv);
		setSuccessMessage([
			{ text: 'Código ' },
			{ text: 'copiado com sucesso', isBold: true },
		]);
		setCurrentStep(3);
	}

	const Header = ({
		title,
		icon,
	}: {
		title: ModalHeaderTextProps[];
		icon?: React.ReactNode;
	}) => {
		const Title: React.ReactNode = title.map((text, index) => (
			<>
				<HeaderText
					key={text.text ?? '' + index}
					color={text.color}
					isBold={text.isBold}
				>
					{text.text}
				</HeaderText>
				{text.text?.includes('\n') && <br />}
			</>
		));

		const headerDescriptionAlignment =
			currentStep === 0 ? 'center' : 'space-between';
		const flexDirection =
			recipientEmailOption === 'custom' && currentStep >= 2 ? 'column' : 'row';

		return (
			<S.Header>
				{icon && (
					<IconContainer color={'var(--primary-blue)'}>{icon}</IconContainer>
				)}

				<p style={{ textAlign: 'center' }}>{Title}</p>

				<S.HeaderDescription
					style={{
						justifyContent: headerDescriptionAlignment,
						flexDirection: flexDirection,
					}}
				></S.HeaderDescription>
			</S.Header>
		);
	};

	// 1st step
	const InitialContent = () => {
		const { register, watch, formState } = useForm<{ value: string }>({
			defaultValues: {
				value: '0,00',
			},
		});

		const [Payment, setPayment] = useState<PaymentOption>('PIX');

		const inputCurrencyValue = watch('value');
		const inputValueInCents = convertReaisToCents(
			parseCurrencyStrToNumber(inputCurrencyValue)
		);
		const updatedBalanceValue =
			parseCurrencyStrToNumber(inputCurrencyValue) +
			companyBalance.corpway_balance;

		return (
			<S.Container>
				<Header
					icon={<FileIcon color={'white'} />}
					title={[
						{ text: 'Adicionar ' },
						{ text: 'saldo na carteira', isBold: true },
					]}
				/>
				<S.OptionsContainer>
					<Typography
						style={{ justifyContent: 'center' }}
						color='var(--mid-gray)'
						size='1.3rem'
					>
						Qual operação deseja realizar?
					</Typography>
					<S.BottomOptionsContainer>
						<S.PaymentOption
							isActive={Payment === 'PIX'}
							onClick={() => {
								setPayment('PIX');
							}}
						>
							PIX
						</S.PaymentOption>
						<S.PaymentOption
							isActive={Payment === 'BILLING'}
							onClick={() => {
								setPayment('BILLING');
							}}
						>
							Boleto
						</S.PaymentOption>
					</S.BottomOptionsContainer>
					<S.InputContainer>
						<CurrencyInput
							required
							id='value'
							name='value'
							value={inputCurrencyValue}
							register={register}
							label='Digite o valor que deseja adicionar'
						/>
						<InfoContent
							label='Saldo disponível:'
							orientation='horizontal'
							sameSize
							sizeInRems={1.4}
						>
							<S.TextStyle className='balance'>
								{parseCurrencyToBRLStandard(companyBalance.corpway_balance)}
							</S.TextStyle>
						</InfoContent>
						{formState.isDirty && (
							<InfoContent
								label='Saldo após pagamento:'
								orientation='horizontal'
								sameSize
								sizeInRems={1.4}
							>
								<S.TextStyle className='updated'>
									<IoIosArrowRoundUp />{' '}
									{parseCurrencyToBRLStandard(updatedBalanceValue)}
								</S.TextStyle>
							</InfoContent>
						)}
					</S.InputContainer>
				</S.OptionsContainer>
				<S.BottomOptionsContainer>
					<Button
						onClick={() => setOpen(false)}
						intent='terciary'
						roundness='lg'
						$outline
					>
						Voltar
					</Button>
					<Button
						onClick={() => handleGeneratePayment(inputValueInCents, Payment)}
						intent='primary'
						roundness='lg'
						disabled={!formState.isDirty}
						loading={isPaymentGenerationLoading}
						shrinkOnLoading={false}
					>
						Continuar
					</Button>
				</S.BottomOptionsContainer>
			</S.Container>
		);
	};

	// 2nd step - PIX
	const PixResultContent = () => {
		const updatedBalance = value / 100 + companyBalance.corpway_balance;

		return (
			<S.Container>
				<S.Header className='pixResult'>
					<img style={{ height: '90%' }} src={qrCodeImg} alt='QRCode gerado' />
				</S.Header>

				<Typography size='1.8rem'>
					QR Code gerado no valor de:
					<span style={{ fontWeight: 'bold', paddingLeft: '0.5rem' }}>
						{convertCentsToFormattedReais(value)}
					</span>
				</Typography>

				<S.InformationContainer>
					<InfoContent
						label='Saldo disponível:'
						orientation='horizontal'
						sameSize
						sizeInRems={1.4}
					>
						<S.TextStyle className='balance'>
							{parseCurrencyToBRLStandard(companyBalance.corpway_balance)}
						</S.TextStyle>
					</InfoContent>
					<InfoContent
						label='Saldo após pagamento:'
						orientation='horizontal'
						sameSize
						sizeInRems={1.4}
					>
						<S.TextStyle className='updated'>
							<IoIosArrowRoundUp /> {parseCurrencyToBRLStandard(updatedBalance)}
						</S.TextStyle>
					</InfoContent>
				</S.InformationContainer>

				<S.BottomOptionsContainer>
					<Button
						onClick={() => setCurrentStep(2)}
						intent='terciary'
						roundness='lg'
						$outline
						style={{ padding: '1.2rem 3rem' }}
					>
						Enviar p/ email
					</Button>

					<Button
						onClick={handleCopyQRCode}
						intent='primary'
						roundness='lg'
						style={{ padding: '1.2rem 3rem' }}
					>
						Copiar código
					</Button>
				</S.BottomOptionsContainer>
			</S.Container>
		);
	};

	// 3nd step
	const RecipientEmailContent = () => {
		const handleNextContent = () => {
			if (recipientEmailOption === 'custom') {
				const customEmail = customEmailForm.getValues('customEmail');

				const validEmail = yup.string().email().isValidSync(customEmail);

				if (!customEmail || !validEmail) {
					toast.error('Insira um email válido!');
					return;
				}
			}
			if (paymentOption === 'PIX') {
				sendQRCodeByEmailQuery.mutate();
			} else {
				generateBillingMutation.mutate(value);
			}
		};

		return (
			<S.Container>
				<Header
					icon={<FileIcon color={'white'} />}
					title={[
						{ text: 'Adicionar ' },
						{ text: 'saldo na carteira', isBold: true },
					]}
				/>
				<S.InformationContainer
					style={{ width: '90%', justifyContent: 'space-between' }}
				>
					{paymentOption && (
						<>
							<Typography style={{ alignItems: 'flex-start' }}>
								Operação:{' '}
								<span>
									{paymentOption === 'BILLING' ? 'Boleto' : paymentOption}
								</span>
							</Typography>

							<Typography style={{ alignItems: 'flex-end' }}>
								Valor: <span>{convertCentsToFormattedReais(value)}</span>
							</Typography>
						</>
					)}
				</S.InformationContainer>
				<S.OptionsContainer>
					<S.RecipientEmailContainer>
						<p>Qual o destinatário do pagamento?</p>
						<label>
							<input
								type='radio'
								checked={recipientEmailOption === 'operator'}
								onChange={() => {
									setRecipientEmailOption('operator');
									customEmailForm.reset();
								}}
							/>
							<div>
								<p>Você</p>
								<S.EmailContainer>({user.email})</S.EmailContainer>
							</div>
						</label>
						<label>
							<input
								type='radio'
								checked={recipientEmailOption === 'financial'}
								onChange={() => {
									setRecipientEmailOption('financial');
									customEmailForm.reset();
								}}
							/>
							<div>
								<p>Financeiro da sua empresa</p>
								<S.EmailContainer>
									({currentCompany?.financial_sector_email})
								</S.EmailContainer>
							</div>
						</label>
						<label>
							<input
								type='radio'
								checked={recipientEmailOption === 'custom'}
								onChange={() => setRecipientEmailOption('custom')}
							/>
							Enviar para um e-mail alternativo
						</label>
						<InputField
							type='email'
							placeholder='email_proprio@exemplo.com'
							disabled={recipientEmailOption !== 'custom'}
							autoFocus={recipientEmailOption === 'custom'}
							name='customEmail'
							register={customEmailForm.register}
							// value={customEmail}
							// onChange={(e) => setCustomEmail(e.target.value)}
						/>
					</S.RecipientEmailContainer>
				</S.OptionsContainer>

				<S.BottomOptionsContainer>
					<Button
						onClick={handleBack}
						intent='terciary'
						roundness='lg'
						$outline
					>
						Voltar
					</Button>

					<Button
						loading={isPaymentGenerationLoading}
						onClick={handleNextContent}
						intent='primary'
						roundness='lg'
						shrinkOnLoading={false}
					>
						Continuar
					</Button>
				</S.BottomOptionsContainer>
			</S.Container>
		);
	};

	//4th step
	function SuccessContent() {
		return (
			<S.Container>
				<Header
					title={successMessage}
					icon={<MdCheck size={30} color={'white'} />}
				/>

				<S.BottomOptionsContainer>
					<Button
						onClick={() => setOpen(false)}
						intent='terciary'
						roundness='lg'
						$outline
					>
						Fechar
					</Button>
				</S.BottomOptionsContainer>
			</S.Container>
		);
	}

	const RenderContent = () => {
		const CONTENT = {
			0: <InitialContent />,
			1: <PixResultContent />,
			2: <RecipientEmailContent />,
			3: <SuccessContent />,
		};

		return CONTENT[currentStep];
	};

	return (
		<div>
			<Button
				as='button'
				intent='primary'
				roundness='lg'
				onClick={() => {
					setOpen(true);
				}}
			>
				Adicionar saldo
			</Button>

			<Modal isOpen={isOpen} onRequestClose={() => setOpen(false)}>
				<RenderContent />
			</Modal>
		</div>
	);
}
