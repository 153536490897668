import { useEffect, useState } from 'react';
import { getTranslatedErrorMessage } from '../../utils/getTranslatedErrorMessage';
import { cpfMask } from '../../utils/masks';
import Modal from '../Modal';
import * as S from './styles';
import { TextWithHint } from '../TextWithHint';
import { DIALOG_MODAL_CONTAINER_ID } from '../../contexts/DialogModalContext';

export type ReprovedCollaborators = {
	id?: string;
	cpf?: string;
	name?: string;
	email?: string;
	error: string[] | string;
};

export interface NotApprovedCollabsModalProps {
	collabsReproved: ReprovedCollaborators[];
	title?: string;
}

function NotApprovedCollabsModal({
	collabsReproved,
	title = 'Pagamentos que não passaram no lançamento',
}: NotApprovedCollabsModalProps) {
	const [open, setOpen] = useState(false);

	useEffect(() => {
		if (!collabsReproved.length) return;

		setOpen(true);
	}, [collabsReproved]);

	return (
		<Modal
			isOpen={open}
			enableClose
			onRequestClose={() => setOpen(false)}
			parentId={DIALOG_MODAL_CONTAINER_ID}
		>
			<S.Container>
				<S.Title>{title}</S.Title>

				<S.Table>
					<thead>
						<S.Row>
							<S.Head>CPF</S.Head>
							<S.Head>NOME</S.Head>
							{collabsReproved[0]?.email && <S.Head>EMAIL</S.Head>}
							<S.Head>ERRO(s)</S.Head>
						</S.Row>
					</thead>

					<tbody>
						{collabsReproved.map((collab) => (
							<S.Row key={collab.cpf}>
								<S.Data>
									<TextWithHint text={cpfMask(collab.cpf!)} />
								</S.Data>
								<S.Data>
									<TextWithHint text={collab.name!} />
								</S.Data>
								{collab.email && (
									<S.Data>
										<TextWithHint text={collab.email} />
									</S.Data>
								)}
								<S.Data>
									<S.ErrorListContainer>
										{typeof collab.error === 'string'
											? getTranslatedErrorMessage(collab.error)
											: collab.error.map((error) => {
													return (
														<li key={`${error}${collab.id}`}>
															<>{getTranslatedErrorMessage(error)}</>
														</li>
													);
											  })}
									</S.ErrorListContainer>
								</S.Data>
							</S.Row>
						))}
					</tbody>
				</S.Table>
			</S.Container>
		</Modal>
	);
}

export { NotApprovedCollabsModal };
