import React, { SelectHTMLAttributes, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { BaseFieldProps, Field } from './Field';
import { BaseInputStyle } from './BaseStyle';
import { MdErrorOutline } from "react-icons/md";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";


interface OptionType {
	value: string;
	label: string;
}

interface Props extends SelectHTMLAttributes<HTMLSelectElement>,
	BaseFieldProps {
	errorMessage?: string;
	options: OptionType[];
	onItemSelect: (item: string) => void;
}

export function SearchableSelectField(
	{
		placeholder = 'Selecione uma opção',
		name,
		label,
		options,
		errorMessage,
		onItemSelect,
		value,
	}: Props) {

	const [filterValue, setFilterValue] = useState<string>('');
	const [showDropdown, setShowDropdown] = useState<boolean>(false);
	const containerRef = useRef<HTMLDivElement>(null);

	const filteredOptions = options.filter((option) => option.label.toLowerCase().includes(filterValue.toLowerCase()));

	const selectedOption = options.find(e => e.value === value);

	useEffect(() => {
		if (selectedOption) {
			setFilterValue(selectedOption.label);
		}
	}, [selectedOption]);

	const handleOptionClick = (option: OptionType) => {
		setFilterValue(option.label);
		onItemSelect(option.value);
		setShowDropdown(false);
	};

	const handleClickOutside = (event: MouseEvent) => {
		if (!showDropdown) return;

		if (
			containerRef.current &&
			!containerRef.current.contains(event.target as Node)
		) {
			const foundOption = options.find(option => option.label === filterValue);

			if (!foundOption && value) {
				setFilterValue(selectedOption?.label ?? "");
			} else {
				setFilterValue("");
				onItemSelect('');
			}

			setShowDropdown(false);
		}
	};

	useEffect(() => {
		if (showDropdown) {
			document.addEventListener('mousedown', handleClickOutside);
			return () => {
				document.removeEventListener('mousedown', handleClickOutside);
			};
		}
	}, [showDropdown]);

	return (
		<Field label={label} name={name}>
			<div ref={containerRef} style={{ position: 'relative' }}>
				<InputContainer>
					<Input
						value={filterValue}
						onChange={e => setFilterValue(e.target.value)}
						onFocus={() => setShowDropdown(true)}
						placeholder={placeholder}
					/>
					<IconContainer onClick={() => setShowDropdown(!showDropdown)}>
						{showDropdown ? <IoIosArrowUp color={"var(--dark-gray2)"} size={15} /> : <IoIosArrowDown color={"var(--dark-gray2)"} size={15} />}
					</IconContainer>
				</InputContainer>
				{showDropdown && (
					<ListContainer>
						{filteredOptions.length > 0 ? (
							filteredOptions.map((option, index) => (
								<li
									key={index + option.value}
									onClick={() => handleOptionClick(option)}
									style={{
										padding: '8px',
										cursor: 'pointer',
									}}
									onMouseOver={(e) =>
										((e.currentTarget as HTMLElement).style.backgroundColor = '#f2f2f2')
									}
									onMouseOut={(e) =>
										((e.currentTarget as HTMLElement).style.backgroundColor = '#fff')
									}
								>
									{option.label}
								</li>
							))
						) : (
							<li>Nenhum resultado encontrado</li>
						)}
					</ListContainer>
				)}
			</div>
			{errorMessage && (
				<span>
          <MdErrorOutline /> {errorMessage}
        </span>
			)}
		</Field>
	);
}

const InputContainer = styled.div`
    position: relative;
    width: 100%;
`;

const ListContainer = styled.ul`
    position: absolute;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    background-color: white;
    margin-top: 0;
    padding-left: 0;
    list-style-type: none;
    z-index: 1;
    font-size: 12px;
    border: 1px solid #ccc;
`;

const Input = styled.input`
  ${BaseInputStyle};
  padding: 0 1.6rem;
  width: 100%;
  height: 40px;
  box-sizing: border-box;
`;

const IconContainer = styled.div`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 1.5rem;
  color: #999;
  pointer-events: all; /* Habilita clique no ícone */
`;

