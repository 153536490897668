import styled from 'styled-components';
import background from '../../assets/profile_header_bg.png';

export const Container = styled.div`
	flex: 1;
	height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 2rem 7rem;
	background: linear-gradient(rgba(39, 173, 255, 0.8), rgba(73, 211, 255, 0.5)),
		url(${background}) no-repeat center/cover;

	.responsiveImage {
		display: none;
	}

	@media (max-width: 650px) {
		padding: 2rem 0rem;
		height: 100%;
		.desktopImage {
			display: none;
		}

		.responsiveImage {
			display: block;
			padding: 2rem;
		}
	}
`;

export const InformationContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;

	@media (max-width: 650px) {
		text-align: center;
	}
`;

export const TextsContainer = styled.div`
	display: flex;
	flex-direction: column;
	background-color: var(--white);
	padding: 4rem;
	border-radius: 1.6rem;
	width: 80%;
`;

export const TermsContainer = styled.div`
	text-align: justify;
	padding: 2rem;
	margin-top: 1.5rem;
	margin-bottom: 3.5rem;
	max-height: 50vh;
	width: 100%;
	background-color: var(--white);
	border-radius: 10px;
	overflow-y: auto;
	font-size: 1.4rem;
	outline: 2px solid var(--primary-blue);
	outline-offset: 0.5rem;

	& > table,
	td {
		text-align: start;
		padding: 1rem;
		margin-top: 3rem;
		margin-bottom: 3rem;
		border: solid;
		border-collapse: collapse;
	}

	tr {
		p {
			font-weight: normal;
		}
		h3 {
			font-weight: normal;
		}

		th {
			border: 2px solid;
			padding: 1rem;
			text-align: start;
		}
	}

	a {
		color: var(--dark-gray);
		text-decoration: underline;
		transition: all 0.2s;
	}

	a:hover {
		color: var(--light-gray);
	}

	& > ol,
	ul {
		margin-left: 2rem;
		li {
			margin-left: 2rem;
		}
	}

	& > h1,
	h2 {
		font-size: 2rem;
		margin-top: 2rem;
	}

	&::-webkit-scrollbar {
		width: 1.2rem;
	}

	&::-webkit-scrollbar-track {
		border-radius: 1rem;
		background-color: #e3e8eb;
	}

	&::-webkit-scrollbar-thumb {
		background-color: var(--light-gray);
		border-radius: 1rem;
	}
`;

export const LabelText = styled.div`
	width: 100%;
	font-size: 1.8rem;

	span {
		color: var(--primary-blue);
		font-weight: 600;
	}

	@media (max-width: 650px) {
		font-size: 1.5rem;
		text-align: center;
	}
`;

export const AcceptContainer = styled.div`
	display: flex;
	justify-content: space-between;

	@media (max-width: 650px) {
		flex-direction: column;
		align-items: center;
		gap: 1rem;
	}
`;

export const TextAccept = styled.div`
	& p {
		font-size: 1.3rem;
		max-width: 52rem;
		padding-left: 2.7rem;
		padding-top: 0.5rem;

		& > span {
			font-weight: 600;
		}
	}

	@media (max-width: 650px) {
		text-align: center;

		& p {
			padding: 0;
		}
	}
`;

export const TextTitle = styled.h2`
	font-weight: 600;
	font-size: 2.8rem;
	color: var(--primary-blue);
	width: 100%;

	@media (max-width: 650px) {
		font-size: 2rem;
	}
`;

export const LogoImage = styled.img`
	width: 20rem;
`;
