import { SectionTitle } from '..';
import { Typography } from '../../../../componentsV2/ui/Typography';
import styled from 'styled-components';
import { useQuery } from 'react-query';
import {
	CORPWAY_CARDS_KEY,
	getCardsYearExpenses,
} from '../../../../services/queries/Corpway/Cards';
import { useAuth } from '../../../../hooks/useAuth';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { AsynchronousContent } from '../../../../componentsV2/AsynchronousContent';
import { convertCentsToFormattedReais } from '../../../../utils/CurrencyConvert';
import { SelectField } from '../../../../componentsV2/ui/Form/SelectField';
import CustomTooltip from './components/CustomTooltip';

export function YearExpenses() {
	const { user, currentCompany } = useAuth();
	const currentYear = new Date().getFullYear();
	const startOfControlYear = 2024;
	const years = Array.from(
		{ length: currentYear - startOfControlYear + 1 },
		(_, i) => startOfControlYear + i
	);

	const [selectedYear, setSelectedYear] = useState(currentYear);
	const [hoveredBar, setHoveredBar] = useState<string | null>(null);

	const getCardsYearExpensesQuery = useQuery(
		[CORPWAY_CARDS_KEY, 'expenses', user.id, currentCompany?.id, selectedYear],
		() => getCardsYearExpenses(currentCompany!.id, selectedYear),
		{
			onError: () => {
				toast.error(
					'Ocorreu um problema ao buscar o relatório de total de pagamentos.'
				);
			},
			refetchOnWindowFocus: false,
		}
	);

	function getMonthName(monthRef: number) {
		const monthName = new Date(selectedYear, monthRef, 1)
			.toLocaleString('default', {
				month: 'short',
			})
			.replace('.', '');

		return monthName.charAt(0).toUpperCase() + monthName.slice(1);
	}

	const data = Object.keys(getCardsYearExpensesQuery.data || {}).map((key) => ({
		name: getMonthName(parseInt(key)),
		value: getCardsYearExpensesQuery.data
			? getCardsYearExpensesQuery.data[key]
			: 0,
	}));

	const total = data.reduce((acc, curr) => acc + curr.value, 0);

	return (
		<div style={{ minHeight: '45rem' }}>
			<style>
				{`
						@media (max-width: 768px) {
							.mobileStyle {
								text-align: center !important;
							}
						}
					`}
			</style>
			<HeaderContainer>
				<div style={{display: "flex"}}>
					<TitleContainer>
						<SectionTitle primaryText="Total">
          						<span style={{ paddingRight: '0.5rem', whiteSpace: 'nowrap' }}>
          							{' '}
												de pagamentos
          						</span>
						</SectionTitle>
						<Typography
							className="mobileStyle"
							size="1.4rem"
							color="var(--mid-gray)"
							style={{ marginTop: '0.2rem' }}
						>
							Gastos mensais consolidados
						</Typography>
					</TitleContainer>
					<CustomTooltip />
				</div>

				<YearSelectField
					value={selectedYear}
					onChange={(e) => setSelectedYear(Number(e.target.value))}
					placeholderText={false}
				>
					{years.map((year) => (
						<option key={year} value={year}>
							{year}
						</option>
					))}
				</YearSelectField>
			</HeaderContainer>

			<AsynchronousContent status={getCardsYearExpensesQuery.status}>
				<ChartContainer>
					{data.map((month) => (
						<BarContainer
							key={month.name}
							onMouseEnter={() => setHoveredBar(month.name)}
							onMouseLeave={() => setHoveredBar(null)}
						>
							<Bar value={month.value / total} />
							<Typography>{month.name}</Typography>
							{hoveredBar === month.name && (
								<TooltipContainer value={month.value / total}>
									{convertCentsToFormattedReais(month.value)}
								</TooltipContainer>
							)}
						</BarContainer>
					))}
				</ChartContainer>

				<SideLegendContainer>
					<Typography
						color='var(--light-gray)'
						style={{ justifyContent: 'flex-end' }}
					>
						Total Gasto:
					</Typography>
					<Typography
						color='var(--dark-gray)'
						size='2rem'
						weight='600'
						style={{ justifyContent: 'flex-end' }}
					>
						{convertCentsToFormattedReais(total)}
					</Typography>
				</SideLegendContainer>
			</AsynchronousContent>
		</div>
	);
}

const HeaderContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	margin-bottom: 2rem;

	@media (max-width: 650px) {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin-bottom: 1rem;
	}
`;
const ChartContainer = styled.div`
	display: flex;
	gap: 1rem;
	height: 30rem;
	width: 100%;
	@media (max-width: 650px) {
		overflow-x: scroll;
	}
`;
const BarContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-end;
	width: 100%;
	position: relative;
	@media (max-width: 650px) {
		min-width: 8rem;
	}
`;
const Bar = styled.div<{ value: number }>`
	background-color: var(--primary-blue);
	border-radius: 1.2rem 1.2rem 0 0;
	width: 100%;
	height: ${({ value }) => value * 100}%;
	@media (max-width: 650px) {
		border-radius: 0.8rem 0.8rem 0 0;
	}
`;
const TitleContainer = styled.div`
	width: 100%;
	@media (max-width: 650px) {
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
	}
`;
const TooltipContainer = styled.div<{ value: number }>`
	position: absolute;
	top: ${({ value }) => 76 - value * 100}%;
	z-index: 10000;
	width: 14rem;
	background-color: var(--dark-gray);
	color: #fff;
	font-size: 1.4rem;
	text-align: center;
	border-radius: 0.6rem;
	padding: 1rem;
	pointer-events: none;

	&::after {
		content: '';
		position: absolute;
		top: 100%; /* At the bottom of the tooltip */
		left: 50%;
		margin-left: -5px;
		border-width: 5px;
		border-style: solid;
		border-color: var(--dark-gray) transparent transparent transparent;
	}
`;
const SideLegendContainer = styled.div`
	display: grid;
	justify-content: flex-end;
	margin-top: 2rem;
`;
const YearSelectField = styled(SelectField)`
	width: fit-content;
	margin-left: auto;
	margin-top: 0.6rem;
	@media (max-width: 650px) {
		width: auto;
		margin-left: 0;
		margin-top: 1rem;
		align-self: center;
	}
`;
