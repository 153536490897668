import { Collaborator } from '../@types';
import { cpf as cpfValidator } from 'cpf-cnpj-validator';
import { toast } from 'react-toastify';
import { addressNumberPattern } from './patterns';

export function validateCollaborator(collaborator: Collaborator) {
	const email_mask = /\S+@\S+\.\S+/; // Email format: Text@text.text

	const collabEmail =
		typeof collaborator.email === 'undefined' ? '' : collaborator.email;
	const collabCPF = collaborator.cpf;

	if (!cpfValidator.isValid(collabCPF)) {
		toast.error(`CPF inválido!`);
		return false;
	}
	if (!email_mask.test(collabEmail)) {
		toast.error(`Formato de EMAIL inválido!`);
		return false;
	}
	if (!checkOnlyNumbersInComplement(collaborator.complement)) {
		toast.error(invalidOnlyNumbersComplementMessage);
		return false;
	}

	return true;
}

/* CHECK JUSTIFICATION: 
		Algumas pessoas não estão especificando no complemento se é bloco, andar ou apartamento. 
		Estão simplesmente colocando um número e o correios não entrega o cartão!
*/
export function checkOnlyNumbersInComplement(complement: string | undefined) {
	if (complement && /^\d+$/.test(complement.replaceAll(' ', ''))) {
		return false;
	}
	return true;
}

export function checkNumberField(number: string | undefined) {
	if (number) {
		return new RegExp(addressNumberPattern).test(number);
	}

	return false;
}

export const invalidOnlyNumbersComplementMessage =
	'Complemento inserido é inválido e não contém informações suficientes para a entrega do cartão do colaborador';
export const warningOnlyNumbersComplementMessage =
	'Verifique se o complemento inserido é válido e contém informações suficientes para a entrega do cartão do colaborador';
export const invalidNumberFieldMessage =
	'O colaborador possui caracteres inválidos no campo "Número". Verifique se o número do endereço possui somente caracteres numéricos. Qualquer outro caractere deve ser inserido no campo “Complemento”.';
export const invalidReferenceFieldMessage =
	'O campo de "Referência" do colaborador possui mais de 30 caracteres. Por favor tente abreviar o texto."';
