import { CardStatus } from '../../../../@types/CorporateExpenses/Card';

const STATUS_STRING = {
	active: 'Ativo',
	blocked: 'Bloqueado',
	canceled: 'Cancelado',
};
export function parseCardStatus(status: CardStatus) {
	return STATUS_STRING[status];
}

export function getCardStatusIcon(status: CardStatus) {
	const color = {
		active: '#1CCA0D',
		blocked: '#FB1313',
		canceled: '#C6C6C6',
	};

	return (
		<div
			style={{
				background: color[status],
				width: '1rem',
				height: '1rem',
				borderRadius: '50%',
			}}
		/>
	);

	// switch (status) {
	// case 'active':
	// return <MdCheckCircleOutline style={{ color: '#1CCA0D' }} />;
	// case 'blocked':
	// 	return <MdBlock style={{ color: '#FB1313' }} />;
	// case 'canceled':
	// 	return <MdCancel style={{ color: '#C6C6C6' }} />;
	// }
}
