import { useQuery } from 'react-query';
import {
	CORPWAY_COST_CENTER_KEY,
	getCostCenters,
} from '../../../../../services/queries/Corpway/CostCenters';
import { useAuth } from '../../../../../hooks/useAuth';
import { showErrorMessage } from '../../../../../utils/ErrorHandler';
import { CostCenter } from '../../../../../@types/CorporateExpenses/CostCenter';
import styled from 'styled-components';
import { Carousel } from '../../../../../componentsV2/Carousel';
import { InputField } from '../../../../../componentsV2/ui/Form/InputField';
import { FaSearch } from 'react-icons/fa';
import { useState } from 'react';
import { sortArrayObjByAlfabeticalOrder } from '../../../../../utils/sortArrayByAlfabeticalOrder';

function CostCenterChip({
	costCenter,
	isActive,
	onSelect,
}: {
	costCenter: CostCenter;
	isActive: boolean;
	onSelect: (costCenter: CostCenter) => void;
}) {
	return (
		<CostCenterChipContainer
			isActive={isActive}
			onClick={() => onSelect(costCenter)}
		>
			{costCenter.title.charAt(0).toUpperCase() +
				costCenter.title.slice(1).toLowerCase()}
		</CostCenterChipContainer>
	);
}

interface Props {
	selectedCostCenter: CostCenter | null;
	onSelect: (costCenter: CostCenter | null) => void;
}
export function CostCenterSelect({ onSelect, selectedCostCenter }: Props) {
	const { currentCompany } = useAuth();
	const [search, setSearch] = useState('');
	const getCostCentersQuery = useQuery(
		[
			CORPWAY_COST_CENTER_KEY,
			// pagination.page,
			// currentSortColumn,
			currentCompany?.id,
		],
		() => getCostCenters(currentCompany!.id, undefined, {
			name : "title",
			order : "ASC"
		}),
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível buscar os centros de custo. '
				);
			},
		}
	);

	const costCenters =
		getCostCentersQuery.data?.costCenters.filter((c) =>
			c.title.toLowerCase().includes(search.toLowerCase())
		) ?? [];

	return (
		<>
			<div
				style={{ display: 'flex', justifyContent: 'flex-start', width: '100%' }}
			>
				<InputContainer>
					<InputField
						placeholder='Pesquisar centro de custo'
						style={{ paddingRight: '4rem' }}
						value={search}
						onChange={(e) => setSearch(e.target.value)}
					/>
					<FaSearch />
				</InputContainer>
			</div>

			<Container >
				<Carousel mode='draggable' gap='1rem'>
					<CostCenterChipContainer
						isActive={selectedCostCenter === null}
						onClick={() => onSelect(null)}
					>
						Todos
					</CostCenterChipContainer>
					{costCenters.map(
						(costCenter) => (
							<CostCenterChip
								key={costCenter.id}
								costCenter={costCenter}
								isActive={selectedCostCenter?.id === costCenter.id}
								onSelect={onSelect}
							/>
						)
					)}
				</Carousel>
			</Container>
		</>
	);
}

const CostCenterChipContainer = styled.div<{ isActive: boolean }>`
	padding: 0.4rem 1rem;
	border-radius: 2rem;
	border: 2px solid
		${({ isActive }) => (isActive ? 'var(--primary-blue)' : 'var(--dark-gray)')};
	width: max-content;
	background-color: ${({ isActive }) =>
		isActive ? 'var(--primary-blue)' : 'transparent'};
	color: ${({ isActive }) => (isActive ? 'var(--white)' : 'var(--dark-gray)')};
	font-size: 1.2rem;
	cursor: pointer;
`;
const InputContainer = styled.div`
	position: relative;
	min-width: 40rem;
	& > svg {
		position: absolute;
		right: 1rem;
		top: 50%;
		width: 2rem;
		height: 2rem;
		color: var(--dark-gray);
		transform: translateY(-50%);
	}

	@media (max-width: 768px) {
		min-width: 100%;
	}
`;

const Container = styled.div`
	justify-content: 'end';
	width: 65%;
	@media (max-width: 768px){
		width: 100%;
	}
`
