import styled from 'styled-components';
import { OptionsButton } from '../../../../components/Button';
import { FaInfoCircle } from 'react-icons/fa';

export const Container = styled.div`
	flex: 1;
	padding: 4rem 7rem 2rem 7rem;
`;

export const HeaderInfoContainer = styled.div`
	display: flex;
	column-gap: 4rem;
	margin: 2rem 0;
`;

export const InfoContainer = styled.div`
	display: flex;
	flex-direction: column;
	font-size: 1.6rem;
	width: fit-content;
`;

export const InfoTitle = styled.h2`
	font-weight: 500;
	font-size: 1.8rem;
	color: var(--mid-gray);
	margin-bottom: 1rem;
	text-transform: uppercase;
`;

export const FieldName = styled.h2`
	margin: 2.5rem 0;
`;

export const DeliveryOptionContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin: 2rem 0;
	width: fit-content;
`;

export const RemoveCollaboratorBtn = styled.button`
	background-color: var(--primary-red);
	padding: 1rem;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	& svg {
		width: 2rem;
		height: 2rem;
		color: var(--white);
	}
`;

export const FooterOptionsContainer = styled.div`
	display: flex;
	justify-content: space-between;
	& div {
		display: flex;
		column-gap: 2rem;
	}
`;

export const SubmitBtn = styled.button`
	${OptionsButton}
`;

export const AmountsText = styled.span`
	min-height: 4rem;
	display: flex;
	align-items: center;
	border-radius: 0.4rem;
	font-size: 1.4rem;
	font-weight: bold;
	background-color: var(--dark-gray);
	color: var(--white);
	justify-content: center;
	margin: 2rem 0;
`;

export const ExtensionsInfo = styled.div`
	margin: 2rem 0;
	display: flex;
	align-items: center;
	color: var(--dark-gray);
	font-size: 1.4rem;
	font-style: italic;
`;

export const InfoIcon = styled(FaInfoCircle)`
	margin-right: 0.8rem;
	color: var(--primary-blue);
`;

export const ModelLink = styled.a`
	color: var(--primary-red);
	text-decoration: underline;
	font-style: italic;
`;
