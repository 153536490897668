import { Beneficiary } from '../../../../contexts/PaymentContextV2';
import { BenefitTotal } from '../components/BenefitTotals/BenefitsListTotals';
import { getBenefitValue } from './getBenefitValuesInputs';

export const calculateBenefitsTotals = (
	beneficiaries: Beneficiary[],
	filterDisapproved = false
) => {
	let benefitsTotalsList: BenefitTotal[] = [];
	let total = 0;
	beneficiaries.forEach((beneficiary) => {
		// only collaborators
		if (!beneficiary.isGroup) {
			beneficiary.benefits.forEach((benefit) => {
				if (!benefit.id) return;

				// if beneficiary is unselected
				// or FilterDisapproved is set and the benefit is repproved don't increase the total value
				let benefitValue = 0;
				if (beneficiary.selected) {
					if (
						!filterDisapproved ||
						(filterDisapproved && !benefit.reason_disapproval)
					) {
						benefitValue = getBenefitValue(beneficiary.id, benefit.id);
					}
				}

				const benefitIndex = benefitsTotalsList.findIndex(
					(b) => b.title === benefit.title
				);

				if (benefitIndex > -1) {
					benefitsTotalsList[benefitIndex].totalValue += benefitValue;
				} else {
					benefitsTotalsList.push({
						title: benefit.title,
						totalValue: benefitValue,
					});
				}

				total += benefitValue * 100;
			});
		}
		// only groups
		else if (beneficiary.selected && beneficiary.isGroup) {
			beneficiary.benefits.forEach((benefit) => {
				if (!benefit.id) return;

				// if beneficiary is unselected
				// or FilterDisapproved is set and the benefit is repproved don't increase the total value
				let benefitValue = 0;
				if (beneficiary.selected) {
					if (
						!filterDisapproved ||
						(filterDisapproved && !benefit.reason_disapproval)
					) {
						benefitValue = getBenefitValue(beneficiary.id, benefit.id);
					}
				}

				const benefitIndex = benefitsTotalsList.findIndex(
					(b) => b.title === benefit.title
				);

				if (benefitIndex > -1) {
					benefitsTotalsList[benefitIndex].totalValue +=
						benefitValue * Number(beneficiary.collaboratorsAmount);
				} else {
					benefitsTotalsList.push({
						title: benefit.title,
						totalValue: benefitValue * Number(beneficiary.collaboratorsAmount),
					});
				}

				total += benefitValue * Number(beneficiary.collaboratorsAmount) * 100;
			});
		}
	});

	return { benefitsTotalsList, total: total / 100 };
};
