import styled from 'styled-components';
import { OptionsButton } from '../../../../components/Button';

export const ModalButton = styled.button`
	${OptionsButton}
`;

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 50rem;
`;

export const Title = styled.h2`
	color: var(--primary-blue);
	margin-bottom: 1rem;
`;

export const BenefitsList = styled.ul`
	display: flex;
	flex-direction: column;
	list-style: none;
	margin-bottom: 1rem;
`;

export const BenefitContainer = styled.li`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0.5rem 2rem;
	margin-bottom: 2px;
	border-bottom: solid 0.2rem var(--primary-blue);

	font-size: 1.2rem;
	color: var(--primary-blue);
	font-weight: bold;
`;

interface BenefitSelectionProps {
	selected: boolean;
}
export const SelectBenefit = styled.button<BenefitSelectionProps>`
	width: 3rem;
	height: 3rem;
	background: none;
	& svg {
		width: 100%;
		height: 100%;
		border-radius: 50%;
		color: ${({ selected }) =>
			selected ? 'var(--light-gray)' : 'var(--primary-blue)'};
	}
`;

export const TitleContainer = styled.div`
	display: flex;
	align-items: center;
	column-gap: 1rem;
	& > img,
	svg {
		width: 2rem;
		height: 2rem;

		//putting color to the svg('color: var()' didn't work, changing the 'fill' attribute in svg file didn't work as well)
		filter: invert(45%) sepia(71%) saturate(610%) hue-rotate(166deg)
			brightness(114%) contrast(100%);
	}
`;

export const MainButton = styled.button`
	${OptionsButton}
	margin: 0 auto;
`;

export const OpenModalButtonSmall = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	background: var(--primary-blue);
	border: 0.2rem solid var(--primary-blue);
	border-radius: 2rem;
	padding: 0.3rem;
	margin: 0 auto;
	& > svg {
		color: white;
		width: 3rem;
		height: 3rem;
	}
`;
