import { create } from 'zustand';
import { FilterParams } from '../components/Filter';

type Store = {
	pendingChargebacksFiltersParams: FilterParams[];
	setPendingChargebacksFiltersParams: (f: FilterParams[]) => void;
	nonPendingChargebacksFiltersParams: FilterParams[];
	setNonPendingChargebacksFiltersParams: (f: FilterParams[]) => void;
	resetFilters: () => void;
};

const pendingChargebacksFiltersParams: FilterParams[] = [
	{
		name: 'Justificativa',
		filter: 'justification',
		value: '',
		type: 'text',
		selected: false,
	},
	{
		name: 'Valor',
		filter: 'value',
		value: '',
		type: 'currency',
		selected: false,
	},
	{
		name: 'Nome',
		filter: 'name',
		value: '',
		type: 'text',
		selected: false,
	},
];

const nonPendingChargebacksFiltersParams: FilterParams[] = [
	{
		name: 'Justificativa',
		filter: 'justification',
		value: '',
		type: 'text',
		selected: false,
	},
	{
		name: 'Valor',
		filter: 'value',
		value: '',
		type: 'currency',
		selected: false,
	},
	{
		name: 'Motivo da reprovação',
		filter: 'reason_disapproval',
		value: '',
		type: 'text',
		selected: false,
	},
	{
		name: 'Valor estornado',
		filter: 'reversed_value',
		value: '',
		type: 'currency',
		selected: false,
	},
	{
		name: 'Nome',
		filter: 'name',
		value: '',
		type: 'text',
		selected: false,
	},
];

const useChargebacksListStore = create<Store>()((set) => ({
	pendingChargebacksFiltersParams,
	setPendingChargebacksFiltersParams: (f) =>
		set({ pendingChargebacksFiltersParams: f }),
	nonPendingChargebacksFiltersParams,
	setNonPendingChargebacksFiltersParams: (f) =>
		set({ nonPendingChargebacksFiltersParams: f }),
	resetFilters: () =>
		set({
			pendingChargebacksFiltersParams,
			nonPendingChargebacksFiltersParams,
		}),
}));

export { useChargebacksListStore };
