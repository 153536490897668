import { useAuth } from '../../../hooks/useAuth';
import PaymentsAdmin from './PaymentsAdmin';
import PaymentsOperator from './PaymentsOperator';
import { Benefit, Group, Release } from '../../../@types';
import { Beneficiary } from '../../../contexts/PaymentContextV2';
import { useMutation } from 'react-query';
import { fetchPaymentToReport } from '../../../services/queries/PaymentsV2';
import { fetchGroup } from '../../../services/queries/Groups';
import { getBenefitParsedTitle } from '../../../utils/benefits/getBenefitParsedTitle';
import { pdf } from '@react-pdf/renderer';
import { toast } from 'react-toastify';
import * as Excel from 'exceljs';
import { parseDateAndTime } from './util/reportUtils';
import { PDFReport } from './PDFReport';
import { cnpjMask } from '../../../utils/masks';

export interface GroupReleases {
	group: Group;
	group_payment: {
		id: string;
		benefit: Benefit;
		status: boolean;
		reason_disapproval: string;
	};
}

// used in PaymentsOperator and Admin
export interface PaymentsProps {
	handleDownloadPayment(
		paymentId: string,
		pdfReport: boolean,
		detailed?: boolean
	): Promise<void>;
}

export default function Payments() {
	const { user, currentCompany } = useAuth();
	const fetchReleaseQuery = useMutation((paymentId: string) =>
		fetchPaymentToReport(paymentId, currentCompany?.id!)
	);
	const fetchGroupQuery = useMutation((groupId: string) =>
		fetchGroup(groupId, currentCompany?.id)
	);

	const { generatePDF } = PDFReport();

	async function getPaymentsTableData(data: Release) {
		let collaborators: Beneficiary[] = [];
		let groupsAndBenefits: GroupReleases[] = [];
		let groups: Group[] = [];
		let parsedJson: any = [];
		let benefitsTotals: any[] = [];

		data.release.preReleases.forEach((preRelease) => {
			let indexCollab = collaborators.findIndex(
				(element) => element.cpf === preRelease.collaborator.cpf
			);

			const benefitObj: Benefit = {
				id: preRelease.release_payment.id,
				value: preRelease.release_payment.benefit.value / 100,
				title: preRelease.release_payment.benefit.title,
			};

			let indexBenefit = benefitsTotals.findIndex(
				(benefit) => benefit.title === benefitObj.title
			);
			if (indexBenefit > -1) {
				benefitsTotals[indexBenefit].value += benefitObj.value;
			} else {
				let benefitCopy = {
					id: benefitObj.id,
					value: benefitObj.value,
					title: benefitObj.title,
					parsedTitle: getBenefitParsedTitle(benefitObj.title),
				};

				benefitsTotals.push(benefitCopy);
			}

			if (indexCollab !== -1) {
				collaborators[indexCollab].benefits.push(benefitObj);
			} else {
				const collab: Beneficiary = {
					id: preRelease.collaborator.id!,
					cpf: preRelease.collaborator.cpf,
					name: preRelease.collaborator.name,
					email: preRelease.collaborator.email,
					office: preRelease.collaborator.office,
					benefits: [benefitObj],
				};

				collaborators.push(collab);
			}
		});

		collaborators.forEach((collab) => {
			let collaboratorRow = {
				NOME: collab.name,
				EMAIL: collab.email,
				CPF: collab.cpf,
			};

			collab.benefits.forEach((benefit) => {
				Object.defineProperty(
					collaboratorRow,
					getBenefitParsedTitle(benefit.title).toUpperCase(),
					{
						value: benefit.value,
						writable: true,
						enumerable: true,
						configurable: true,
					}
				);
			});

			parsedJson.push(collaboratorRow);
		});

		data.release.groupReleases.forEach((groupRelease) => {
			const benefitGroupIndex = groupsAndBenefits.findIndex(
				(g) =>
					g.group.id === groupRelease.group.id &&
					g.group_payment.benefit.title ===
						groupRelease.group_payment.benefit.title
			);
			if (benefitGroupIndex === -1) {
				groupsAndBenefits.push(groupRelease);
			}

			const groupsIndex = groups.findIndex(
				(g) => g.id === groupRelease.group.id
			);
			if (groupsIndex === -1) {
				groups.push(groupRelease.group);
			}
		});

		let firstGroup = true;
		for (const group of groups) {
			let groupRow = {
				NOME: group.title.toUpperCase(),
			};

			if (firstGroup) {
				firstGroup = false;
			} else {
				parsedJson.push('');
			}

			parsedJson.push(groupRow);

			const data = await fetchGroupQuery.mutateAsync(group.id);

			data.users.forEach((user) => {
				let collaboratorRow = {
					NOME: `${user.first_name} ${user.last_name}`,
					EMAIL: user.email,
					CPF: user.cpf,
				};

				groupsAndBenefits.forEach((groupAndBenefit) => {
					let indexBenefit = benefitsTotals.findIndex(
						(benefit) =>
							benefit.title === groupAndBenefit.group_payment.benefit.title
					);
					if (indexBenefit > -1) {
						benefitsTotals[indexBenefit].value +=
							groupAndBenefit.group_payment.benefit.value / 100;
					} else {
						let benefitCopy = {
							id: groupAndBenefit.group_payment.benefit.id,
							value: groupAndBenefit.group_payment.benefit.value / 100,
							title: groupAndBenefit.group_payment.benefit.title,
						};

						benefitsTotals.push(benefitCopy);
					}

					if (groupAndBenefit.group.id === group.id) {
						Object.defineProperty(
							collaboratorRow,
							getBenefitParsedTitle(
								groupAndBenefit.group_payment.benefit.title
							).toUpperCase(),
							{
								value: groupAndBenefit.group_payment.benefit.value / 100,
								writable: true,
								enumerable: true,
								configurable: true,
							}
						);
					}
				});

				parsedJson.push(collaboratorRow);
			});
		}

		return { tableData: parsedJson, benefitsTotals };
	}

	async function downloadPayment(
		paymentId: string,
		pdfReport: boolean,
		detailed?: boolean
	) {
		try {
			const data = await fetchReleaseQuery.mutateAsync(paymentId);
			const { tableData, benefitsTotals } = await getPaymentsTableData(data);
			let blob: Blob;

			if (pdfReport) {
				const PDFGenerated = await generatePDF(data, tableData, !!detailed);
				blob = await pdf(PDFGenerated).toBlob();
			} else {
				blob = await generateSpreadSheet(data, tableData, benefitsTotals);
			}

			// Create a temporary URL for the blob
			const url = URL.createObjectURL(blob!);

			// Create a link element
			const link = document.createElement('a');
			link.href = url;
			link.download = `relatorio_de_pagamento.${pdfReport ? 'pdf' : 'xlsx'}`;

			// Simulate a click event to trigger the download
			link.dispatchEvent(new MouseEvent('click'));

			// Clean up the temporary URL
			URL.revokeObjectURL(url);
		} catch (error) {
			toast.error(
				'Ocorreu um erro ao tentar gerar o arquivo de relatório. Tente novamente'
			);
		}
	}

	function getPaymentInfoParsed(data: Release) {
		let message: string;
		const messageForEachStatus = {
			requested: 'Lançamento criado em',
			approved: 'Lançamento realizado em',
			disapproved: 'Lançamento reprovado em',
			canceled: 'Lançamento cancelado em',
			scheduled: 'Lançamento agendado para',
			processing: 'Lançamento processando em',
			'finished error': 'Lançamento finalizado com erros em',
			'chargeback-processing': 'Estorno processando em',
			'chargeback-partial': 'Estorno parcial realizado em',
			'chargeback-success': 'Lançamento estornado em',
			'chargeback-error': 'Estornado com erro realizado em',
		};

		const { date, time } = parseDateAndTime(
			data.release.status === 'scheduled'
				? data.release.scheduledDate!
				: data.release.updatedAt
		);

		type status =
			| 'requested'
			| 'approved'
			| 'disapproved'
			| 'canceled'
			| 'processing'
			| 'finished error'
			| 'scheduled'
			| 'chargeback-processing'
			| 'chargeback-success'
			| 'chargeback-partial'
			| 'chargeback-error';

		message =
			(messageForEachStatus[data.release.status as status] ??
				data.release.status) + ` ${date} às ${time}`;

		return message;
	}

	async function generateSpreadSheet(
		data: Release,
		tableData: any,
		benefitsTotals: {
			id: string;
			value: number;
			title: string;
			parsedTitle: string;
		}[]
	) {
		const workbook = new Excel.Workbook();

		const worksheet = workbook.addWorksheet('Relatório de lançamento');

		const currentTime = new Date();
		worksheet.addRow([
			`Gerado em ${currentTime.toLocaleDateString()} às ${currentTime.toLocaleTimeString(
				'pt-BR',
				{
					timeZone: 'America/Sao_Paulo',
					hour: '2-digit',
					minute: '2-digit',
				}
			)} no Benefícios Flexíveis. Usuário: ${user.email}.`,
		]);
		worksheet.addRow([`N° de pedido: ${data.totalReports}`]);

		worksheet.addRow([]);
		worksheet.addRow([`Empresa: ${currentCompany!.corporateName}`]);
		worksheet.addRow([`CNPJ: ${cnpjMask(currentCompany!.cnpj)}`]);
		worksheet.addRow([]);

		const tableColumns = new Set<string>();

		tableData.forEach((obj: {}) => {
			Object.keys(obj).forEach((key) => {
				tableColumns.add(key);
			});
		});

		worksheet.addRow(Array.from(tableColumns));

		// Add data rows to the worksheet using tableColumns to keep benefits columns order
		tableData.forEach((rowData: { [x: string]: any }) => {
			const rowValues = Array.from(tableColumns).map(
				(column) => rowData[column]
			);
			worksheet.addRow(rowValues);
		});

		let totalValues_row: any[] = ['Valores totais', '', ''];

		tableColumns.forEach((column) => {
			if (column !== 'NOME' && column !== 'CPF' && column !== 'EMAIL') {
				const benefitTotal = benefitsTotals.find(
					(benefit) => benefit.parsedTitle === column
				);

				if (benefitTotal) totalValues_row.push(benefitTotal.value);
			}
		});

		worksheet.addRow([]);
		worksheet.addRow(totalValues_row);
		worksheet.addRow([]);
		worksheet.addRow([getPaymentInfoParsed(data)]);

		Array.from(tableColumns).forEach((_, index) => {
			const col = worksheet.getColumn(index + 1);

			col.width = index === 2 ? 15 : 25;
		});

		const buffer = await workbook.xlsx.writeBuffer();
		const fileType =
			'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

		const blob = new Blob([buffer], { type: fileType });

		return blob;
	}

	if (user.access_level === 'admin') {
		return <PaymentsAdmin handleDownloadPayment={downloadPayment} />;
	}

	return <PaymentsOperator handleDownloadPayment={downloadPayment} />;
}
