import * as S from "./styles"
import { flexRender, Table } from '@tanstack/react-table';
import { SortColumnButton } from '../../SortColumnButton';
import { EmptyContent } from '../../EmptyContent';
import { AsynchronousContent } from '../../AsynchronousContent';
import { TableProps } from '../index';

interface Props extends TableProps<any> {
	table : Table<any>
}

export default function DefaultTable ({asyncStatus, maxHeight, toggleSort, currentSortColumn, useExpandableContent, data, showHeader, expandableRenderItem, table} : Props) {
	const hasItems = data.length > 0;

	return  (
		<AsynchronousContent status={asyncStatus ?? 'success'}>
			<S.TableContainer style={{ maxHeight }}>
				<S.TableComp>
					<S.TableHeader>
						{hasItems &&
							table.getHeaderGroups().map((headerGroup) => (
								<tr key={headerGroup.id}>
									{headerGroup.headers.map((header) => (
										<S.TH
											key={header.id}
											onClick={() => {
												header.column.getCanSort() &&
												toggleSort!(header.column.id);
											}}
											style={{
												cursor: header.column.getCanSort()
													? 'pointer'
													: 'default',
											}}
										>
											<div
												style={{
													display: 'flex',
													justifyContent:
														header.column.columnDef.meta?.align || 'start',
												}}
											>
												{header.isPlaceholder
													? null
													: flexRender(
														header.column.columnDef.header,
														header.getContext()
													)}

												{header.column.getCanSort() && (
													<SortColumnButton
														order={
															currentSortColumn?.name === header.column.id
																? currentSortColumn.order
																: null
														}
														onToggleSort={() =>
															toggleSort!(header.column.id)
														}
														isActive={
															currentSortColumn?.name === header.column.id
														}
													/>
												)}
											</div>
										</S.TH>
									))}
								</tr>
							))}
					</S.TableHeader>
					<tbody>
					{table.getRowModel().rows.map((row) => (
						<>
							<S.TR key={row.id}>
								{row.getVisibleCells().map((cell) => (
									<S.TD key={cell.id}>
										<div
											style={{
												display: 'flex',
												justifyContent:
													cell.column.columnDef.meta?.align || 'start',
											}}
										>
											{flexRender(
												cell.column.columnDef.cell,
												cell.getContext()
											)}
										</div>
									</S.TD>
								))}
							</S.TR>
							{useExpandableContent && row.getIsExpanded() && (
								<tr>
									<td colSpan={100}>
										{expandableRenderItem!(row.original)}
									</td>
								</tr>
							)}
						</>
					))}

					{!hasItems && (
						<>
							{showHeader && (
								<S.TableHeader
									style={{ background: '#fff !important', width: '100%' }}
								>
									{table.getHeaderGroups().map((headerGroup) => (
										<tr key={headerGroup.id}>
											{headerGroup.headers.map((header) => (
												<S.TH
													key={header.id}
													onClick={() => {
														header.column.getCanSort() &&
														toggleSort!(header.column.id);
													}}
													style={{
														cursor: header.column.getCanSort()
															? 'pointer'
															: 'default',
														backgroundColor: '#fff !important',
														textAlign: 'left',
														width: header.getSize() + 5,
													}}
												>
													<div
														style={{
															display: 'flex',
															justifyContent: 'flex-start',
															alignItems: 'center',
															width: '100%',
															background: '#fff !important',
															marginBottom: '5rem',
														}}
													>
														{header.isPlaceholder
															? null
															: flexRender(
																header.column.columnDef.header,
																header.getContext()
															)}

														{header.column.getCanSort() && (
															<SortColumnButton
																order={
																	currentSortColumn?.name ===
																	header.column.id
																		? currentSortColumn.order
																		: null
																}
																onToggleSort={() =>
																	toggleSort!(header.column.id)
																}
																isActive={
																	currentSortColumn?.name ===
																	header.column.id
																}
															/>
														)}
													</div>
												</S.TH>
											))}
										</tr>
									))}
								</S.TableHeader>
							)}
							<tr>
								<td>
									<p style={{ borderBottom: '1px solid #ccc' }}>
										<EmptyContent />
									</p>
								</td>
							</tr>
						</>
					)}
					</tbody>
				</S.TableComp>
			</S.TableContainer>
		</AsynchronousContent>
	)
}