import styled from 'styled-components';

export const Container = styled.div`
	flex: 1;
	padding: 4rem 7rem 2rem 7rem;
`;
export const OptionsContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	column-gap: 1rem;
`;

export const DeleteCostCenterBtn = styled.button`
	background-color: var(--primary-red);
	padding: 1rem;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	margin: 0 auto;
	& svg {
		width: 2rem;
		height: 2rem;
		color: var(--white);
	}
`;
