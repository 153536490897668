import { FaArrowDown, FaArrowUp } from 'react-icons/fa';
import styled from 'styled-components';
import { Order } from '../../hooks/useSortColumnHook/index';

interface Props {
	order: Order | null;
	onToggleSort: () => void;
}
export function SortColumnButton({ order, onToggleSort }: Props) {
	function getIcon() {
		if (order === null)
			return <FaArrowUp style={{ color: 'var(--light-gray)' }} />;
		if (order === 'ASC') return <FaArrowUp />;
		return <FaArrowDown />;
	}

	return (
		<Container onClick={onToggleSort} data-rh={`Ordenar`}>
			{getIcon()}
		</Container>
	);
}

const Container = styled.button`
	background: none;
	margin-right: 1rem;
	& > svg {
		width: 1.5rem;
		height: 1.5rem;
		color: var(--black);
	}
`;
