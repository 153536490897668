import styled from 'styled-components';

export const Container = styled.div`
	flex: 1;
	padding: 4rem 7rem 2rem 7rem;
`;
export const OptionsContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;
