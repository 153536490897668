import { useEffect, useState } from 'react';
import { Button } from '../../../../componentsV2/ui/Button';
import Modal from '../../../../componentsV2/ui/Modal';
import * as S from './styles';
import {
	TitleTypography,
	Typography,
} from '../../../../componentsV2/ui/Typography';
import fileIcon from '../../../../assets/file-arrow-up-down.svg';
import { SelectField } from '../../../../componentsV2/ui/Form/SelectField';
import { Field } from '../../../../componentsV2/ui/Form/Field';
import { CheckboxField } from '../../../../componentsV2/ui/Form/CheckboxField';
import { useForm } from 'react-hook-form';
import { InputComp } from '../../../../componentsV2/ui/Form/InputStyle';
import { FaArrowRight } from 'react-icons/fa';
import { Carousel } from '../../../../componentsV2/Carousel';
import { SpreadsheetReportGenerator } from './ReportGenerators/SpreadsheetGenerator';
import { PdfReportGenerator } from './ReportGenerators/PDFGenerator';
import { OFXReportGenerator } from './ReportGenerators/OFXGenerator';

export interface Props {
	startMonth: Date;
	endMonth: Date;
}

export interface TimePeriod {
	label: string;
	days: string | number;
}

const TimePeriods: TimePeriod[] = [
	{
		label: 'Mensal',
		days: 30,
	},
	{
		label: 'Personalizado',
		days: 'custom',
	},
];

const OperationTypes = {
	funds_in: 'Recarga de Carteira',
	//funds_out: 'Transferência Externa',
	wallet_refund: 'Estorno para Carteira',
	card_funds: 'Repasse para Cartão',
};

const OperationStatusesLabels = {
	all_statuses: 'Todos',
	created: 'Emitido',
	overdue: 'Expirado',
	paid: 'Finalizado',
	processing: 'Processando',
};

const ReportFormats = ['pdf', 'xlsx', 'ofx'];

export function ReportGeneratorModal({ startMonth, endMonth }: Props) {
	const { generateSpreadsheet } = SpreadsheetReportGenerator();
	const { generatePDF } = PdfReportGenerator();
	const { generateOFX } = OFXReportGenerator();

	const [isOpen, setOpen] = useState(false);
	const [currentStep, setCurrentStep] = useState<0 | 1 | 2 | 3>(0);

	const [selectedTimePeriod, setSelectedTimePeriod] = useState(
		greaterThanOneMonth(startMonth, endMonth) ? TimePeriods[1] : TimePeriods[0]
	);
	const [selectedReportFormat, setSelectedReportFormat] = useState(
		ReportFormats[0]
	);

	const [isLoading, setIsLoading] = useState(false);

	function greaterThanOneMonth(date1: Date, date2: Date) {
		const millisecondsDiff = date2.getTime() - date1.getTime();
		const monthMilliseconds = 2629800000;
		const monthDiffs = Math.abs(millisecondsDiff / monthMilliseconds);
		return monthDiffs > 1;
	}

	const currentDate = new Date();

	let defaultStartDate = startMonth;
	let defaultEndDate = endMonth;

	const eighteenMonthsAgo = new Date();
	const minDate = new Date(2024, 0);
	eighteenMonthsAgo.setMonth(eighteenMonthsAgo.getMonth() - 17);

	function checkMinDate() {
		if (eighteenMonthsAgo < minDate) {
			return minDate;
		} else {
			return eighteenMonthsAgo;
		}
	}

	const [startDate, setStartDate] = useState(defaultStartDate);
	const [endDate, setEndDate] = useState(defaultEndDate);

	const { register, reset, getValues, setValue, watch } = useForm({
		defaultValues: {
			operationTypes: {
				funds_in: false,
				//funds_out: false,
				wallet_refund: false,
				card_funds: false,
			},
			operationStatuses: {
				all_statuses: false,
				created: false,
				overdue: false,
				paid: false,
				processing: false,
			},
			fundsOrigin: 'all_origins',
		},
	});

	const operationTypes = watch('operationTypes');
	const operationStatuses = watch('operationStatuses');

	function handleStatusCheck(status: string, statusInput: any) {
		if (status === 'all_statuses') {
			Object.keys(operationStatuses).forEach((key) => {
				setValue(
					`operationStatuses.${key as keyof typeof OperationStatusesLabels}`,
					statusInput.checked
				);
			});

			return;
		}

		if (statusInput.checked) {
			let allStatusesChecked = true;
			Object.entries(operationStatuses).map(([key, value]) => {
				if (key === status) value = statusInput.checked;
				if (key !== 'all_statuses' && !value) {
					allStatusesChecked = false;
				}
			});

			if (allStatusesChecked) setValue('operationStatuses.all_statuses', true);
		} else {
			setValue('operationStatuses.all_statuses', false);
		}
	}

	useEffect(() => {
		if (isOpen) {
			setCurrentStep(0);
		}
	}, [isOpen]); // eslint-disable-line  react-hooks/exhaustive-deps

	function handleCloseModal() {
		setOpen(false);
		reset();
		setStartDate(defaultStartDate);
		setEndDate(defaultEndDate);
		setSelectedTimePeriod(TimePeriods[0]);
		setSelectedReportFormat(ReportFormats[0]);
	}

	function checkOperationsValues(operations: object) {
		for (let key in operations) {
			if (operations[key as keyof typeof operations]) {
				return true;
			}
		}
		return false;
	}

	function getOperationsSelected(operations: object) {
		const operationsSelected = [];

		for (let key in operations) {
			if (operations[key as keyof typeof operations]) {
				operationsSelected.push(
					OperationTypes[key as keyof typeof OperationTypes]
				);
			}
		}

		return operationsSelected;
	}

	function handleTimePeriodSelection(period: TimePeriod) {
		setSelectedTimePeriod(period);

		if (period.days !== 'custom') {
			setStartDate(defaultStartDate);
			setEndDate(defaultEndDate);
		}
	}

	function handleReportFormatStatusCheck() {
		if (selectedReportFormat === 'ofx') {
			setValue('operationStatuses.paid', true);
			Object.keys(operationStatuses).forEach((key) => {
				if (key !== 'paid') {
					setValue(
						`operationStatuses.${key as keyof typeof OperationStatusesLabels}`,
						false
					);
				}
			});
		} else {
			Object.keys(operationStatuses).forEach((key) => {
				setValue(
					`operationStatuses.${key as keyof typeof OperationStatusesLabels}`,
					false
				);
			});
		}
	}

	async function handleSubmitModal() {
		setIsLoading(true);
		try {
			const selectedFundsOrigin = getValues('fundsOrigin') as
				| 'pix'
				| 'billing'
				| 'all_origins';
			if (selectedReportFormat === 'xlsx')
				await generateSpreadsheet({
					start_date: startDate.valueOf(),
					end_date: endDate.valueOf(),
					operationTypes,
					operationStatuses,
					fundsInOrigin: selectedFundsOrigin,
				});
			else if (selectedReportFormat === 'pdf')
				await generatePDF({
					start_date: startDate.valueOf(),
					end_date: endDate.valueOf(),
					operationTypes,
					operationStatuses,
					fundsInOrigin: selectedFundsOrigin,
				});
			else if (selectedReportFormat === 'ofx')
				await generateOFX({
					start_date: startDate.valueOf(),
					end_date: endDate.valueOf(),
					operationTypes,
					operationStatuses,
					fundsInOrigin: selectedFundsOrigin,
				});
			setCurrentStep(2);
		} catch (error: any) {
			if (error.message === 'empty_movements') {
				setCurrentStep(3);
			}
		}
		setIsLoading(false);
	}

	const InitialFilters = () => {
		return (
			<>
				<S.HeaderContainer>
					<S.IconContainer>
						<S.IconHeader src={fileIcon} alt='' />
					</S.IconContainer>

					<TitleTypography
						primaryText='Exportar'
						color_primary='inherit'
						size='1.8rem'
					>
						<Typography size='1.8rem'>relatório</Typography>
					</TitleTypography>
					<Typography size='1.8rem' style={{ marginTop: '-1.5rem' }}>
						{' '}
						de movimentações da carteira
					</Typography>
				</S.HeaderContainer>

				<S.ReportInfoContainer>
					<S.FieldsRow align={true}>
						<Field label='Período do relatório:'>
							<Carousel mode='draggable' gap='1rem'>
								{TimePeriods.map((period) => (
									<S.TimePeriodChipContainer
										key={period.label}
										isActive={selectedTimePeriod?.days === period.days}
										onClick={() => handleTimePeriodSelection(period)}
									>
										{period.label}
									</S.TimePeriodChipContainer>
								))}
							</Carousel>
						</Field>
					</S.FieldsRow>

					<S.FieldsRow>
						{selectedTimePeriod.days === 'custom' ? (
							<S.DatesContainer>
								<InputComp
									disabled={selectedTimePeriod.days !== 'custom'}
									type='date'
									defaultValue={startDate.toISOString().split('T')[0]}
									min={checkMinDate().toISOString().split('T')[0]}
									max={currentDate.toISOString().split('T')[0]}
									onBlur={(e) =>
										setStartDate(new Date(e.target.value.replace('-', '/')))
									}
									style={{ width: 'fit-content' }}
								/>
								<FaArrowRight
									style={{
										width: '2rem',
										height: '2rem',
										color: 'var(--primary-blue)',
									}}
								/>
								<InputComp
									disabled={selectedTimePeriod.days !== 'custom'}
									type='date'
									defaultValue={endDate.toISOString().split('T')[0]}
									min={checkMinDate().toISOString().split('T')[0]}
									max={currentDate.toISOString().split('T')[0]}
									onBlur={(e) =>
										setEndDate(new Date(e.target.value.replace('-', '/')))
									}
									style={{ width: 'fit-content' }}
								/>
							</S.DatesContainer>
						) : (
							<S.DatesContainer className='month'>
								<InputComp
									type='month'
									value={startDate.toISOString().slice(0, 7)}
									defaultValue={currentDate
										.toISOString()
										.split('-')
										.slice(0, 2)
										.join('-')}
									min={checkMinDate()
										.toISOString()
										.split('-')
										.slice(0, 2)
										.join('-')}
									max={currentDate
										.toISOString()
										.split('-')
										.slice(0, 2)
										.join('-')}
									onChange={(e) => {
										const selectedMonth = e.target.value;
										const year = parseInt(selectedMonth.split('-')[0]);
										const month = parseInt(selectedMonth.split('-')[1]);
										setStartDate(new Date(year, month - 1, 1));
										setEndDate(new Date(year, month, 0));
									}}
								/>
							</S.DatesContainer>
						)}
					</S.FieldsRow>

					<S.MainContentContainer>
						<S.FieldsRow align>
							<Field label='Selecione um ou mais tipos de operações:'>
								<S.CheckboxesContainer>
									{Object.entries(OperationTypes).map(
										([key, value], index, array) => (
											<CheckboxField
												key={key}
												label={value}
												name={`operationTypes.${key}`}
												register={register}
												checked={getValues(
													`operationTypes.${key as keyof typeof OperationTypes}`
												)}
												labelWidth={
													index === array.length - 1 && array.length % 2 !== 0
														? '100%'
														: '50%'
												}
											/>
										)
									)}
								</S.CheckboxesContainer>
							</Field>
						</S.FieldsRow>
					</S.MainContentContainer>

					<S.FieldsRow style={{ margin: '1rem 0 1rem 0' }}>
						<S.FieldMessage style={{ width: '100%' }}>
							Escolha o formato que você deseja exportar o relatório.
						</S.FieldMessage>
						<S.FormatSelectContainer>
							<SelectField
								label='Formato:'
								onChange={(e) => setSelectedReportFormat(e.target.value)}
								defaultValue={selectedReportFormat}
								required
								style={{ padding: '1rem' }}
							>
								{ReportFormats.map((format) => (
									<option key={format} value={format}>
										{format.toUpperCase()}
									</option>
								))}
							</SelectField>
						</S.FormatSelectContainer>
					</S.FieldsRow>

					<S.ButtonsContainer>
						<Button
							intent='terciary'
							$outline
							roundness='lg'
							onClick={handleCloseModal}
						>
							Voltar
						</Button>

						<Button
							intent='primary'
							roundness='lg'
							onClick={() => {
								setCurrentStep(1);
								handleReportFormatStatusCheck();
							}}
							disabled={!checkOperationsValues(operationTypes)}
						>
							Continuar
						</Button>
					</S.ButtonsContainer>
				</S.ReportInfoContainer>
			</>
		);
	};

	const SecondFilters = ({ isLoading }: { isLoading: boolean }) => {
		return (
			<>
				<S.HeaderContainer>
					<S.IconContainer>
						<S.IconHeader src={fileIcon} alt='' />
					</S.IconContainer>

					<TitleTypography
						primaryText='Exportar'
						color_primary='inherit'
						size='1.8rem'
					>
						<Typography size='1.8rem'>relatório</Typography>
					</TitleTypography>
					<Typography size='1.8rem' style={{ marginTop: '-1.5rem' }}>
						{' '}
						de movimentações da carteira
					</Typography>
				</S.HeaderContainer>

				<S.ReportInfoContainer>
					<S.FieldsRow className='information'>
						<Field label='Período:'>
							<S.FieldValue>
								{startDate.toLocaleDateString('pt-BR')} -{' '}
								{endDate.toLocaleDateString('pt-BR')}
							</S.FieldValue>
						</Field>

						<Field label='Operações:'>
							<S.OperationsSelected>
								{getOperationsSelected(operationTypes).map((operation) => (
									<S.FieldValue key={operation}>{operation}</S.FieldValue>
								))}
							</S.OperationsSelected>
						</Field>
					</S.FieldsRow>

					<S.MainContentContainer>
						<S.FieldsRow align>
							<Field label='Selecione um ou mais tipos de status:'>
								<S.CheckboxesContainer className='operation'>
									{Object.entries(OperationStatusesLabels).map(
										([key, value]) => (
											<CheckboxField
												key={key}
												label={value}
												name={`operationStatuses.${key}`}
												register={register}
												checked={getValues(
													`operationStatuses.${
														key as keyof typeof OperationStatusesLabels
													}`
												)}
												disabled={
													selectedReportFormat === 'ofx' && key !== 'paid'
												}
												onClick={(e) => handleStatusCheck(key, e.target)}
											/>
										)
									)}
								</S.CheckboxesContainer>
							</Field>
						</S.FieldsRow>
					</S.MainContentContainer>

					{!!operationTypes.funds_in && (
						<S.FieldsRow style={{ margin: '1rem 0 1rem 0' }}>
							<S.FieldMessage style={{ width: '100%' }}>
								Escolha o tipo de operação que você deseja exportar no relatóro.
							</S.FieldMessage>
							<S.FormatSelectContainer>
								<SelectField
									label='Operação:'
									name={'fundsOrigin'}
									register={register}
									required
									style={{ paddingLeft: '1.2rem' }}
								>
									<option key={'all_origins'} value={'all_origins'}>
										Todas operações
									</option>
									<option key={'billing'} value={'billing'}>
										Boleto
									</option>
									<option key={'pix'} value={'pix'}>
										Pix
									</option>
								</SelectField>
							</S.FormatSelectContainer>
						</S.FieldsRow>
					)}

					<S.ButtonsContainer>
						<Button
							intent='terciary'
							$outline
							roundness='lg'
							onClick={() => setCurrentStep(0)}
						>
							Voltar
						</Button>

						<Button
							intent='primary'
							roundness='lg'
							onClick={handleSubmitModal}
							loading={isLoading}
							shrinkOnLoading={false}
							disabled={!checkOperationsValues(operationStatuses)}
						>
							Exportar relatório
						</Button>
					</S.ButtonsContainer>
				</S.ReportInfoContainer>
			</>
		);
	};

	const ConclusionMessage = ({
		mainMessage,
		secondaryMessage,
	}: {
		mainMessage: string;
		secondaryMessage: string;
	}) => {
		return (
			<>
				<S.HeaderContainer>
					<S.IconContainer>
						<S.IconHeader src={fileIcon} alt='' />
					</S.IconContainer>

					<TitleTypography
						primaryText={mainMessage}
						color_primary='inherit'
						size='1.8rem'
					>
						<Typography size='1.8rem'>{secondaryMessage}</Typography>
					</TitleTypography>
				</S.HeaderContainer>

				<S.ReportInfoContainer>
					<S.ButtonsContainer>
						<Button intent='primary' roundness='lg' onClick={handleCloseModal}>
							Fechar
						</Button>
					</S.ButtonsContainer>
				</S.ReportInfoContainer>
			</>
		);
	};

	const EmptyContent = () => (
		<>
			<S.HeaderContainer>
				<S.IconContainer>
					<S.IconHeader src={fileIcon} alt='' />
				</S.IconContainer>

				<TitleTypography color='var(--primary-red)' size='1.8rem'>
					Nenhum relatório disponível
				</TitleTypography>
			</S.HeaderContainer>
			<Typography size='1.4rem'>
				Nenhuma movimentação encontrada para o período escolhido!
			</Typography>

			<S.ReportInfoContainer>
				<S.ButtonsContainer>
					<Button intent='primary' roundness='lg' onClick={handleCloseModal}>
						Fechar
					</Button>
				</S.ButtonsContainer>
			</S.ReportInfoContainer>
		</>
	);

	const RenderContent = () => {
		const CONTENT = {
			0: <InitialFilters />,
			1: <SecondFilters isLoading={isLoading} />,
			2: (
				<ConclusionMessage mainMessage='Download' secondaryMessage='iniciado' />
			),
			3: <EmptyContent />,
		};

		return CONTENT[currentStep];
	};

	return (
		<>
			<Button
				intent='primary'
				roundness='lg'
				onClick={() => setOpen(true)}
				style={{ marginLeft: 'auto' }}
			>
				Exportar Relatório
			</Button>

			<Modal
				isOpen={isOpen}
				onRequestClose={handleCloseModal}
				position='center'
			>
				<S.Container>
					<RenderContent />
				</S.Container>
			</Modal>
		</>
	);
}
