import { InputHTMLAttributes, useState } from 'react';
import { UseFormRegister } from 'react-hook-form';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import styled from 'styled-components';
import { Input } from '../Form/FormStyles';

interface PasswordInputProps extends InputHTMLAttributes<HTMLInputElement> {
	name?: string;
	register?: UseFormRegister<any>;
}
export function PasswordInput({
	name,
	register,
	...props
}: PasswordInputProps) {
	const [showPassword, setShowPassword] = useState(false);

	const handleToggleShow = () => {
		setShowPassword((oldState) => !oldState);
	};

	return (
		<PasswordContainer style={{ width: props.style?.width }}>
			<Input
				type={showPassword ? 'text' : 'password'}
				placeholder='Senha'
				{...(register ? { ...register(name!) } : {})}
				{...props}
			/>
			{!showPassword ? (
				<FaEyeSlash onClick={handleToggleShow} data-testid='eye-slash' />
			) : (
				<FaEye onClick={handleToggleShow} data-testid='eye' />
			)}
		</PasswordContainer>
	);
}

const PasswordContainer = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	width: 100%;
	& > svg {
		position: absolute;
		left: calc(100% - 3rem);
		width: 2rem;
		height: 2rem;

		cursor: pointer;
		transition: filter 0.2s;
		&:hover {
			filter: brightness(0.9);
		}
	}
`;
