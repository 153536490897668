import ModelImage from '../../../assets/ModelSigInMaleComputer.png';
import ResponsiveModelImage from '../../../assets/ModelSignInFemale.png';
import BountyLogo from '../../../assets/BountyRed.svg';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import Loader from '../../../components/Loader';
import { useHistory, useParams } from 'react-router-dom';
import * as S from './styles';
import { useMutation } from 'react-query';
import {
	resetPassword,
	ResetPasswordParams,
} from '../../../services/queries/Session';
import { PasswordInput } from '../../../components/PasswordInput';
import { showErrorMessage } from '../../../utils/ErrorHandler';
import { Button } from '../../../componentsV2/ui/Button';
import { CorporateExpensesTheme } from '../../../styles/CorporateExpensesTheme';

type FormData = {
	password: string;
	password_confirmation: string;
};

type ChangePasswordParams = {
	token: string;
};

export function ChangePassword() {
	const { register, handleSubmit, formState } = useForm<FormData>();
	const { isSubmitting } = formState;
	const history = useHistory();
	const { token } = useParams<ChangePasswordParams>();

	const resetPasswordQuery = useMutation(
		(resetPasswordParams: ResetPasswordParams) => {
			return resetPassword(resetPasswordParams);
		}
	);

	async function handleChangePassword({
		password,
		password_confirmation,
	}: FormData) {
		if (password !== password_confirmation) {
			toast.error('As senhas precisam ser iguais!');
			return;
		}

		try {
			await resetPasswordQuery.mutateAsync({ password, token });

			toast.info('Senha atualizada com sucesso!');
			history.push('/session');
			return true;
		} catch (err) {
			showErrorMessage(
				err as Error,
				'Ocorreu um problema ao tentar atualizar a senha. '
			);
			return false;
		}
	}

	return (
		<S.Container data-testid='changePassword-container'>
			<CorporateExpensesTheme />
			<S.LeftContainer>
				<img src={ModelImage} className='modelImage' alt='Men With Computer' />
				<img
					src={ResponsiveModelImage}
					className='ResponsiveModelImage'
					alt='Girl'
				/>
			</S.LeftContainer>

			<S.RightContainer>
				<S.MainContainer>
					<img src={BountyLogo} alt='Logo do Bounty' />

					<div>
						<h2>Redefinir senha</h2>
						<p>Preencha os dados para criar uma nova senha de acesso.</p>
					</div>
					<S.Form
						onSubmit={handleSubmit(handleChangePassword)}
						autoComplete='off'
					>
						<PasswordInput
							data-testid='passwordInput_test_id'
							style={{ height: '4.8rem', backgroundColor: '#f0f3f8' }}
							placeholder='Nova senha'
							name='password'
							register={register}
							required
						/>
						<PasswordInput
							data-testid='confirmationInput_test_id'
							style={{ height: '4.8rem', backgroundColor: '#f0f3f8' }}
							placeholder='Confirme a senha'
							name='password_confirmation'
							register={register}
							required
						/>

						<div className='ButtonWrapper'>
							<Button
								disabled={isSubmitting}
								type='button'
								as='link'
								to={'/session'}
								roundness='lg'
								$outline
								width='full'
								intent='terciary'
							>
								Voltar
							</Button>
							<Button
								disabled={isSubmitting}
								type='submit'
								roundness='lg'
								width='full'
								className='loginButton'
								intent='primary'
							>
								{!isSubmitting ? 'ENVIAR' : <Loader color='#fff' size={20} />}
							</Button>
						</div>
					</S.Form>
				</S.MainContainer>
			</S.RightContainer>
		</S.Container>
	);
}
