import {
	Card,
	CardTokenReturn,
	CardStatus,
	CreateCardData,
	CardLastTransactionsReturn,
	CardPreferences,
} from '../../../@types/CorporateExpenses/Card';
import {
	SortColumn,
	parseSortColumnToParams,
} from '../../../hooks/useSortColumnHook';
import api from '../../api';

export const CORPWAY_CARDS_KEY = 'CORPWAY_CARDS_SERVICE_KEY';

interface CardsListReturn {
	cards_active: Card[];
	cards_blocked: Card[];
	cards_canceled: Card[];
}
export async function getCards(
	companyId: string,
	sortColumn: SortColumn | null = null
) {
	const { data } = await api.get<CardsListReturn>(
		`api/v1/rh/corpway/companies/${companyId}/cards/select`,
		{
			params: {
				...parseSortColumnToParams(sortColumn),
			},
		}
	);
	return data;
}

export async function getCardToken(id: string, companyId: string) {
	const { data } = await api.get<CardTokenReturn>(
		`api/v1/rh/corpway/companies/${companyId}/cards/${id}`
	);
	return data;
}

export async function getCardLastTransactions(id: string, companyId: string) {
	const { data } = await api.get<CardLastTransactionsReturn>(
		`api/v1/rh/corpway/companies/${companyId}/cards/${id}/last-transactions`
	);
	return data;
}

export async function createCard(data: CreateCardData, companyId: string) {
	return await api.post(`api/v1/rh/corpway/companies/${companyId}/cards`, {
		...data,
		ba_id: 3,
	});
}

export async function updateCardStatus(
	id: string,
	status: Omit<CardStatus, 'canceled'>,
	reversed_value: boolean,
	companyId: string
) {
	return await api.patch(
		`api/v1/rh/corpway/companies/${companyId}/cards/${id}/status`,
		{
			status: status,
			reversed_value,
		}
	);
}

export type UpdateCardBalanceActions = 'add' | 'reverse';
export async function updateCardBalance(
	id: string,
	companyId: string,
	value: number,
	action: UpdateCardBalanceActions
): Promise<void> {
	return await api.put(
		`/api/v1/rh/corpway/companies/${companyId}/cards/${id}/update-balance`,
		{
			value,
			action,
		}
	);
}

export interface CardRechargeHistory {
	date: string;
	amount: number;
	responsible_user_name: string;
	card_pan: string;
	id: string;
	action: string;
}
export async function getCardsRechargeHistory(companyId: string) {
	const { data } = await api.get<CardRechargeHistory[]>(
		`/api/v1/rh/corpway/companies/${companyId}/cards/recharge-history`
	);
	return data;
}

export async function updateCardAlias(
	companyId: string,
	cardId: string,
	alias: string
) {
	return await api.patch(
		`/api/v1/rh/corpway/companies/${companyId}/cards/${cardId}/update-alias`,
		{
			alias,
		}
	);
}

export async function updateCardNotifications(
	companyId: string,
	cardId: string,
	{ limit_value, limit_value_alert }: CardPreferences
) {
	return await api.put(
		`/api/v1/rh/corpway/companies/${companyId}/cards/${cardId}/limit-alert`,
		{
			limit_value,
			limit_value_alert,
		}
	);
}

export async function getCardNotificationsPreferences(
	companyId: string,
	cardId: string
) {
	const { data } = await api.get<CardPreferences>(
		`/api/v1/rh/corpway/companies/${companyId}/cards/${cardId}/limit-alert`
	);

	return data;
}

export async function getCardsYearExpenses(companyId: string, year: number) {
	const { data } = await api.get<Record<string, {ted: number, card: number }>>(
		`/api/v1/rh/corpway/companies/${companyId}/cards/expenses/${year}`
	);
	return data;
}
interface CardsTopExpensesReturn {
	cardTopExpenses: {
		id: string;
		alias: string;
		pan: string;
		expenses: number;
	}[];
}
export async function getCardsTopExpenses(companyId: string, date: Date) {
	const { data } = await api.get<CardsTopExpensesReturn>(
		`/api/v1/rh/corpway/companies/${companyId}/cards/top-expenses/${date.getFullYear()}/${date.getMonth()}`
	);
	return data;
}
