import { useState } from 'react';
import { useForm } from 'react-hook-form';
import Modal from '../../Modal';
import * as S from './styles';
import * as FormStyles from '../../Form/FormStyles';
import {
	ObrigatoryFieldsIndication,
	ObrigatoryIndicator,
} from '../../ObrigatoryFieldsIndicator';
import PageTitle from '../../PageTitle';
import { cpfMask, phoneMask } from '../../../utils/masks';
import { UnderageResponsible } from '../../../@types';
import { RiParentLine } from 'react-icons/ri';
import {
	cpfPattern,
	onlyLettersAndSpacesPattern,
	phonePattern,
} from '../../../utils/patterns';

interface UnderageResponsibleModalProp {
	responsible?: UnderageResponsible;
	onSubmit(responsible: UnderageResponsible): void;
	smallButton: boolean;
	editable: boolean;
}

export function UnderageResponsibleModal({
	responsible,
	onSubmit,
	smallButton,
	editable,
}: UnderageResponsibleModalProp) {
	const [isOpen, setIsOpen] = useState(false);
	const { register, handleSubmit } = useForm({
		defaultValues: {
			...responsible,
			cpf: cpfMask(responsible?.cpf || ''),
			phone: phoneMask(responsible?.phone || ''),
		},
	});

	function handleSubmitForm(data: UnderageResponsible) {
		onSubmit(data);
		setIsOpen(false);
	}

	return (
		<>
			{smallButton ? (
				<S.OpenModalButtonSmall
					onClick={(e) => {
						e.preventDefault();
						setIsOpen(true);
					}}
					data-rh={'Detalhar responsável'}
					data-testid='openButton_id'
				>
					<RiParentLine />
				</S.OpenModalButtonSmall>
			) : (
				<S.OpenModalButtonLarge
					onClick={(e) => {
						e.preventDefault();
						setIsOpen(true);
					}}
				>
					Detalhar responsável
				</S.OpenModalButtonLarge>
			)}

			<Modal
				isOpen={isOpen}
				enableClose={true}
				onRequestClose={() => setIsOpen(false)}
			>
				<S.ModalContainer>
					<PageTitle title='Responsável por colaborador menor de idade' />
					<FormStyles.Form
						onSubmit={(e) => {
							e.preventDefault();
							handleSubmit(handleSubmitForm)();
							e.stopPropagation();
						}}
					>
						<FormStyles.FieldGroup>
							<FormStyles.Field>
								<FormStyles.Label>
									Nome completo <ObrigatoryIndicator />
								</FormStyles.Label>
								<FormStyles.Input
									type='text'
									pattern={onlyLettersAndSpacesPattern}
									{...register('full_name')}
									required
									data-testid='fullNameInput_test_id'
									disabled={!editable}
								/>
							</FormStyles.Field>

							<FormStyles.Field>
								<FormStyles.Label>
									CPF <ObrigatoryIndicator />
								</FormStyles.Label>
								<FormStyles.Input
									type='text'
									{...register('cpf')}
									pattern={cpfPattern}
									placeholder='Ex: 999.999.999-99'
									required
									onChange={(event) => {
										const { value } = event.target;
										event.target.value = cpfMask(value);
									}}
									data-testid='cpfInput_test_id'
									disabled={!editable}
								/>
							</FormStyles.Field>
						</FormStyles.FieldGroup>

						<FormStyles.FieldGroup>
							<FormStyles.Field>
								<FormStyles.Label>
									Telefone <ObrigatoryIndicator />
								</FormStyles.Label>
								<FormStyles.Input
									type='tel'
									{...register('phone')}
									placeholder='Ex: +55 99 99999-9999'
									pattern={phonePattern}
									required
									onChange={(event) => {
										const { value } = event.target;
										event.target.value = phoneMask(value);
									}}
									data-testid='phoneInput_test_id'
									disabled={!editable}
								/>
							</FormStyles.Field>

							<FormStyles.Field>
								<FormStyles.Label>
									Grau de parentesco <ObrigatoryIndicator />
								</FormStyles.Label>
								<FormStyles.Input
									type='text'
									{...register('relatedness_degree')}
									pattern={onlyLettersAndSpacesPattern}
									required
									data-testid='relatednessInput_test_id'
									disabled={!editable}
								/>
							</FormStyles.Field>
						</FormStyles.FieldGroup>
						{editable && (
							<>
								<ObrigatoryFieldsIndication />
								<S.BottomOptionsContainer>
									<S.SaveButton type='submit'>Salvar</S.SaveButton>
								</S.BottomOptionsContainer>
							</>
						)}
					</FormStyles.Form>
				</S.ModalContainer>
			</Modal>
		</>
	);
}
