import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import Modal from '../../../../components/Modal';
import { useAuth } from '../../../../hooks/useAuth';
import {
	createBenefitsGroup,
	createBenefitsGroupParams,
} from '../../../../services/queries/BenefitsGroups';
import { showErrorMessage } from '../../../../utils/ErrorHandler';
import { trimObjectData } from '../../../../utils/trimObjectData';
import * as S from './styles';
import * as FormStyle from '../../../../components/Form/FormStyles';
import {
	ObrigatoryFieldsIndication,
	ObrigatoryIndicator,
} from '../../../../components/ObrigatoryFieldsIndicator';

export function BenefitGroupCreationModal() {
	const { currentCompany } = useAuth();
	const queryClient = useQueryClient();
	const [modalOpen, setModalOpen] = useState(false);
	const [title, setTitle] = useState('');
	const [description, setDescription] = useState('');

	async function handleSubmitBenefitGroupCreation() {
		if (!title.length || !description.length) {
			toast.error('Preencha todos os campos');
			return;
		}
		setModalOpen(false);

		createBenefitGroupQuery.mutate(trimObjectData({ title, description }));
	}

	const createBenefitGroupQuery = useMutation(
		({ title, description }: createBenefitsGroupParams) => {
			return createBenefitsGroup(title, description, currentCompany?.id);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('benefitsGroupsList');
				toast.info('Grupo de benefícios criado com sucesso');
			},
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao tentar criar o grupo de benefícios. '
				);
			},
		}
	);

	return (
		<>
			<S.AddGroup
				onClick={() => {
					setModalOpen(true);
				}}
			>
				Adicionar grupo
			</S.AddGroup>

			<Modal
				isOpen={modalOpen}
				onRequestClose={() => {
					setModalOpen(false);
				}}
				enableClose
			>
				<FormStyle.FormContainer data-testid='benefitGroupCreation-modal-form'>
					<FormStyle.FieldGroup>
						<FormStyle.Field>
							<FormStyle.Label style={{ fontSize: '1.4em' }}>
								Título do Grupo <ObrigatoryIndicator />
							</FormStyle.Label>
							<FormStyle.Input
								type='text'
								value={title}
								data-testid='title_test_id'
								onChange={(e) => {
									setTitle(e.target.value);
								}}
							/>
						</FormStyle.Field>
					</FormStyle.FieldGroup>

					<FormStyle.FieldGroup>
						<FormStyle.Field>
							<FormStyle.Label style={{ fontSize: '1.4em' }}>
								Descrição <ObrigatoryIndicator />
							</FormStyle.Label>
							<FormStyle.InputTextArea
								value={description}
								data-testid='description_test_id'
								onChange={(e) => {
									setDescription(e.target.value);
								}}
							/>
						</FormStyle.Field>
					</FormStyle.FieldGroup>

					<ObrigatoryFieldsIndication />
					<FormStyle.ButtonsContainer>
						<FormStyle.FormButton onClick={handleSubmitBenefitGroupCreation}>
							Salvar
						</FormStyle.FormButton>
						<S.CancelOption
							onClick={() => {
								setModalOpen(false);
							}}
						>
							Cancelar
						</S.CancelOption>
					</FormStyle.ButtonsContainer>
				</FormStyle.FormContainer>
			</Modal>
		</>
	);
}
