import styled from 'styled-components';

export const ModalContainer = styled.div`
	width: 60rem;
	display: flex;
	flex-direction: column;
	padding-bottom: 1rem;
`;

export const ModalText = styled.p`
	font-size: 1.7rem;

	& + & {
		margin-top: 2rem;
	}
`;

export const ModalTitle = styled.h1``;

export const ModalList = styled.ul`
	list-style-position: inside;
	font-size: 1.7rem;
	font-style: italic;
	margin: 1rem 0 2rem 2rem;
`;

export const ListItem = styled.li`
	font-style: italic;
`;

export const Divider = styled.div`
	width: 100%;
	height: 0.1rem;
	background: var(--light-gray);
	margin: 1rem 0 1rem 0;
`;
