import styled from 'styled-components';

export const Container = styled.div`
	width: 60rem;
`;

export const Title = styled.h1`
	text-align: left;
`;

export const ListTitle = styled.h2`
	font-size: 1.8rem;
`;
export const Content = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 1rem;
	padding: 0 3rem;
`;

export const ListContainer = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 2rem;
	margin-top: 2rem;
	margin-bottom: 4rem;
`;

export const InfoContainer = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-between;
`;

export const Label = styled.span`
	font-size: 1.6rem;
	color: var(--dark-gray);
`;

export const Information = styled.span`
	font-size: 1.4rem;
	color: var(--light-gray);
`;

export const BtnContainer = styled.div`
	display: flex;
	width: fit-content;
	column-gap: 1rem;
	margin-left: auto;
`;

export const FormatSelectorContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	column-gap: 2rem;
	margin-top: 1rem;
`;

export const FormatSelectorMessage = styled.p`
	font-size: 1.3rem;
	text-align: justify;
`;
