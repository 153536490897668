import * as S from './styles';
import avatarImg from '../../assets/avatar.svg';
import { TextWithHint } from '../TextWithHint';

interface UserCardProps {
	name: string;
	bottomInfo: string;
	avatar_url?: string;
	nameWidth?: string;
	id?: string;
}

export default function UserCard({
	name,
	bottomInfo,
	avatar_url,
	nameWidth = '21rem',
	id,
}: UserCardProps) {
	// when id is passed turn name into link to CollaboratorsDetails page
	const NameContainer = ({ children }: { children: JSX.Element }) => {
		if (id)
			return (
				<S.NameLink
					to={`/home/collaborators/manage/${id}`}
					target='_blank'
					rel='noopener noreferrer'
				>
					{children}
				</S.NameLink>
			);
		return <S.Name>{children}</S.Name>;
	};

	return (
		<S.Container>
			<S.Avatar src={avatar_url ? avatar_url : avatarImg} alt='Avatar' />

			<S.InfoContainer>
				<NameContainer>
					<TextWithHint text={name} width={nameWidth} />
				</NameContainer>

				<S.BottomInfo>
					<TextWithHint text={bottomInfo} />
				</S.BottomInfo>
			</S.InfoContainer>
		</S.Container>
	);
}
