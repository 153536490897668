import { ACCESS_LEVELS } from '../../../../../../utils/corpway/accessLevelsMapping';
import { InputField } from '../../../../../../componentsV2/ui/Form/InputField';
import {
	CorpwayUserAccessLevel,
	EditUserDetails,
	UserDetails,
} from '../../../../../../@types/CorporateExpenses/User';
import { Carousel } from '../../Create/components/Carousel';
import { Typography } from '../../../../../../componentsV2/ui/Typography';
import { Button } from '../../../../../../componentsV2/ui/Button';
import { RiCloseLine } from 'react-icons/ri';
import { CostCenterSelect } from '../../../../../../componentsV2/CostCenterSelect';
import React, { useEffect, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { toast } from 'react-toastify';
import { CostCenter } from '../../../../../../@types/CorporateExpenses/CostCenter';
import PreventTransitionPrompt from '../../../../../../components/PreventTransitionPrompt';
import * as S from './styles';
import { capitalizeFirstLetter } from '../../../../../../utils/masks';
import {
	CORPWAY_COST_CENTER_KEY,
	CostCentersListReturn,
} from '../../../../../../services/queries/Corpway/CostCenters';
import { useAuth } from '../../../../../../hooks/useAuth';
import { useQueryClient } from 'react-query';
import styled from 'styled-components';

interface Props {
	user: UserDetails;
	selectedCostCenters: CostCenter[];
	setSelectedCostCenters: (costCenters: CostCenter[]) => void;
	userDetailsForm: UseFormReturn<EditUserDetails>;
	setFormStep: (step: 1 | 2 | 3 | 4) => void;
	hideModal: () => void;
}

const Chip = styled(Button)`
	height: 3.4rem;
	max-height: 3.4rem;
	padding: 0.5rem 1rem;
	flex-wrap: nowrap;
	flex-shrink: 0;
`;

export default function EditUserForm({
	user,
	selectedCostCenters,
	setSelectedCostCenters,
	userDetailsForm,
	setFormStep,
	hideModal,
}: Props) {
	const [blockNavigation, setBlockNavigation] = useState(false);
	const queryClient = useQueryClient();
	const { currentCompany } = useAuth();

	const {
		formState: { isDirty },
		setValue,
		trigger,
		watch,
	} = userDetailsForm;

	const currentAccess = watch('access_level');

	function removeCostCenter(id: string) {
		if (selectedCostCenters.length === 1) {
			toast.error(
				'Não é possível remover todos os centros de custo do usuário.'
			);
			return;
		}

		setBlockNavigation(true);
		setSelectedCostCenters(
			selectedCostCenters.filter((costCenter) => costCenter.id !== id)
		);
	}

	useEffect(() => {
		setBlockNavigation(isDirty);
	}, [isDirty]);

	async function onNextPressed() {
		const validationResult = await trigger(['name', 'email', 'confirm_email', 'access_level']);

		if (!validationResult) {
			return;
		}

		if (currentAccess !== user.role) {
			if (currentAccess !== 'general_admin' && currentAccess !== 'finance') {
				if (selectedCostCenters.length < 1) {
					toast.error('É necessário selecionar ao menos 1 centro de custo.');
					return;
				}
			}
			setFormStep(2);
		} else {
			setFormStep(3);
		}
	}

	const showCostCenters =
		currentAccess !== 'general_admin' && currentAccess !== 'finance';

	const allCostCenters = queryClient.getQueryData<CostCentersListReturn>([
		CORPWAY_COST_CENTER_KEY,
		currentCompany?.id,
	]);

	const hasAllCostCenters =
		allCostCenters?.total_costCenters === selectedCostCenters.length;

	return (
		<>
			{blockNavigation && <PreventTransitionPrompt />}

			<S.Container>
				<S.Row flex={0.35} style={{ width: 'auto' }}>
					<S.Row flex={0.35} style={{ width: 'auto', flexDirection: 'column' }}>
						<Carousel
							infinity
							currentIndex={ACCESS_LEVELS.findIndex(
								(level) => level.value === currentAccess
							)}
							onChange={(index) => {
								const newAccessLevel = ACCESS_LEVELS[index].value as CorpwayUserAccessLevel;

								setValue('access_level', newAccessLevel, { shouldValidate: true });

								if (user.isUniqueAdmin && newAccessLevel !== 'general_admin') {
									userDetailsForm.setError('access_level', {
										type: 'manual',
										message: 'É o único admin da sua empresa, não é possível editar o cargo ou excluir. Caso deseje prosseguir, defina outro admin!',
									});
								} else {
									userDetailsForm.clearErrors('access_level');
								}
							}}

							content={ACCESS_LEVELS.map((level) => (
								<S.AccessCard key={level.title}>
									<img src={level.image} alt={level.title} />
									<S.CardBanner>
										<Typography weight="600" size="1.6rem">
											{level.title}
										</Typography>
										<Typography weight={'400'} size={'1.25rem'}>
											{level.description[0]}
										</Typography>
									</S.CardBanner>
								</S.AccessCard>
							))}
						/>
						{userDetailsForm.formState.errors.access_level && (
							<div
								style={{
									width: '80%',
									textAlign: 'left',
									marginTop: '0.5rem',
									marginLeft: '7rem'
								}}
							>
								<Typography size="1.2rem" weight={'600'} color="var(--primary-red)">
									{userDetailsForm.formState.errors.access_level.message}
								</Typography>
							</div>
						)}
					</S.Row>
				</S.Row>
				<S.Row flex={0.65} style={{ overflow: 'hidden' }}>
					<S.FormContainer>
						<InputField
							label='Nome completo'
							id='name'
							required
							name='name'
							register={userDetailsForm.register}
							errorMessage={userDetailsForm.formState.errors.name?.message}
						/>

						<InputField
							label='E-mail'
							id='email'
							required
							name='email'
							register={userDetailsForm.register}
							errorMessage={userDetailsForm.formState.errors.email?.message}
						/>

						<InputField
							label='Confirmar e-mail'
							id='confirm_email'
							name='confirm_email'
							register={userDetailsForm.register}
							errorMessage={
								userDetailsForm.formState.errors.confirm_email?.message
							}
						/>

						<S.CostCenterContainer>
							<S.CostCenterLabel>Centros de custo</S.CostCenterLabel>
							<S.AddButtonContainer>
								<S.CostCenterScrollView>
									<div>
										{hasAllCostCenters || !showCostCenters ? (
											<Chip roundness={'lg'}>Todos</Chip>
										) : (
											selectedCostCenters.map((costCenter) => (
												<Chip
													key={costCenter.id}
													onClick={() => removeCostCenter(costCenter.id)}
													roundness={'lg'}
													iconRight={<RiCloseLine color={'white'} />}
												>
													{capitalizeFirstLetter(costCenter.title)}
												</Chip>
											))
										)}
									</div>
								</S.CostCenterScrollView>
								{showCostCenters && (
									<CostCenterSelect
										alreadyAddedCostCenters={selectedCostCenters}
										onAddNewCostCenters={(c) => {
											setSelectedCostCenters(c);
											setBlockNavigation(true);
										}}
										blockDeselectAll
									/>
								)}
							</S.AddButtonContainer>
						</S.CostCenterContainer>

						<S.ButtonsContainer>
							<Button
								intent='terciary'
								roundness='lg'
								$outline
								onClick={hideModal}
							>
								Voltar
							</Button>
							<Button
								intent={'primary'}
								width='adjusted'
								roundness='lg'
								onClick={onNextPressed}
								disabled={user.isUniqueAdmin && currentAccess !== 'general_admin'}
							>
								Continuar
							</Button>
						</S.ButtonsContainer>
					</S.FormContainer>
				</S.Row>
			</S.Container>
		</>
	);
}
