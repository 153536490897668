import styled from "styled-components"


const Text = styled.span`
  color: var(--primary-red);
  font-size: 1.2rem;
  font-weight: normal;
`

export function ObrigatoryFieldsIndication() {
  return (
    <Text>* Campo obrigatório</Text>
  )
}

export function ObrigatoryIndicator() {
  return <Text>*</Text>
}