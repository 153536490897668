import { css } from 'styled-components';

export const BaseInputStyle = css`
	width: 100%;
	height: 4.8rem;
	color: var(--dark-gray2);
	background-color: #f0f3f8;
	border-radius: 4px;
	border: none;
	padding: 0 3.2rem;
	/* &:disabled {
		background: none;
		color: inherit;
		cursor: default;
	} */
`;
