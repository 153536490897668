export function trimObjectData(object: any) {
	const object_fields = Object.keys(object) as Array<keyof typeof object>;

	object_fields.forEach((key) => {
		if (!object[key]) return;
		else if (typeof object[key] === 'string') object[key] = object[key].trim();
		else if (typeof object[key] === 'object')
			object[key] = trimObjectData(object[key]);
	});

	return object;
}
