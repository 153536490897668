import ModelImage from '../../../assets/ModeloSignIn.png';
import * as S from './styles';
import { useAuth } from '../../../hooks/useAuth';
import { MdKeyboardReturn, MdOutlineCreditCard } from 'react-icons/md';
import { Button } from '../../../componentsV2/ui/Button';
import { Carousel } from '../../../componentsV2/Carousel';
import { useHistory } from 'react-router-dom';
import { SelectProductSingleCompany } from './SingleCompanyPage';
import { CorporateExpensesTheme } from '../../../styles/CorporateExpensesTheme';
import { useEffect, useState } from 'react';

export function SelectProduct() {
	const history = useHistory();
	const { user, signOut, companies, changeCurrentCompany } = useAuth();
	const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);

	function handleFlexibleBenefitsSelection(companyId: string) {
		changeCurrentCompany(companyId);
		history.push('/home');
	}
	function handleCorporateExpensesSelection(companyId: string) {
		changeCurrentCompany(companyId);
		history.push('/corporate-expenses');
	}

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < 1024);
		};
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	useEffect(() => {
		if (isMobile && user.corporate_expenses_access) {
			const defaultCompany = companies.find(
				(company) => company.company_products.product_corporate_expenses_enabled
			);
			if (defaultCompany) {
				changeCurrentCompany(defaultCompany.id);
				history.push('/corporate-expenses');
			}
		}
	}, [
		isMobile,
		user.corporate_expenses_access,
		companies,
		changeCurrentCompany,
		history,
	]);

	if (companies.length === 1) return <SelectProductSingleCompany />;

	return (
		<S.Container>
			<CorporateExpensesTheme />

			<S.TopContainer>
				<img src={ModelImage} alt='Men with computer' />
			</S.TopContainer>

			<S.MainContainer>
				<S.SignOutBtn onClick={signOut}>
					<MdKeyboardReturn /> Algum problema? Voltar para login.
				</S.SignOutBtn>

				<S.Title>
					Qual empresa você <br /> gostaria de administrar?
				</S.Title>

				<S.CompaniesList>
					<Carousel mode='option'>
						{companies.map((company, i) => (
							<S.CompanyCard key={company.id}>
								<S.CompanyAvatarContainer>
									<img src={company.avatar_url} alt={company.name} />
								</S.CompanyAvatarContainer>

								<S.CompanyCardContentContainer>
									<div>
										<S.CompanyLabel>Empresa:</S.CompanyLabel>
										<S.CompanyTitle>{company.name}</S.CompanyTitle>
									</div>

									{user.flexible_benefits_access && !isMobile && (
										<Button
											onClick={() =>
												handleFlexibleBenefitsSelection(company.id)
											}
											intent='secondary'
											iconRight={<MdOutlineCreditCard />}
											width='full'
											style={{
												paddingLeft: '2rem',
												justifyContent: 'space-between',
											}}
											disabled={
												!company.company_products
													.product_flexible_benefits_enabled
											}
										>
											Benefícios Flexíveis
										</Button>
									)}
									{user.corporate_expenses_access && (
										<Button
											onClick={() =>
												handleCorporateExpensesSelection(company.id)
											}
											width='full'
											style={{
												paddingLeft: '2rem',
												justifyContent: 'space-between',
											}}
											disabled={
												!company.company_products
													.product_corporate_expenses_enabled
											}
											iconRight={<MdOutlineCreditCard />}
										>
											Bounty Control
										</Button>
									)}
								</S.CompanyCardContentContainer>
							</S.CompanyCard>
						))}
					</Carousel>
				</S.CompaniesList>
			</S.MainContainer>
		</S.Container>
	);
}
