import styled from 'styled-components';
import { OptionsButton } from '../../../components/Button';

export const Container = styled.div`
	flex: 1;
	padding: 4rem 7rem 2rem 7rem;
`;

export const PreferencesSectionTitle = styled.h2`
	font-size: 1.8rem;
	font-weight: 400;
	margin-bottom: 1.5rem;
`;

export const PreferencesForm = styled.form`
	flex: 1;
	display: flex;
	flex-direction: column;
`;
export const PreferencesSectionContainer = styled.div`
	margin-top: 2rem;
	margin-bottom: 3rem;
	width: 100%;
`;

export const ToggleButtonsContainer = styled.div`
	margin-right: 3rem;
	margin-left: 3rem;
`;

export const FieldContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	font-size: 1.6rem;
	border-radius: 50px;
	margin: 0.5rem 0rem;

	padding: 0.3rem;
	justify-content: space-between;
`;

export const FieldsGroup = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	font-size: 1.6rem;
	border-radius: 50px;
	margin: 0.5rem 0rem;

	padding: 0.3rem;
	gap: 2rem;
`;
export const FormButton = styled.button`
	${OptionsButton}
	max-width: 10rem;
	padding: 0.8rem 3rem;
	margin-top: 2.5rem;
`;

export const DaysAndHoursContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 2rem;
`;
export const DayContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	align-self: baseline;
`;

export const DayLabel = styled.label`
	margin-bottom: 1rem;
`;

export const DaysGrid = styled.div`
	display: grid;
	grid-auto-flow: dense;
	grid-template-columns: repeat(8, minmax(90px, 1fr));
	grid-column-gap: 1rem;
`;

export const CustomHoursPickerContainer = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 2rem;
`;
export const HoursPickerContainer = styled.div`
	display: flex;
	gap: 2rem;
`;
export const PickerContainer = styled.div`
	width: min-content;
`;

export const PickerLabel = styled.label`
	font-size: 1.2rem;
`;

export const TimePicker = styled.input`
	height: 3rem;
	color: var(--dark-gray);
	background-color: rgba(198, 198, 198, 0.33);
	border-radius: 0.4rem;
	border: solid 1px var(--light-gray);
	padding: 0 0.9rem;
`;
