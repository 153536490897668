import styled from 'styled-components';
import { SectionContainer } from '../../../../../componentsV2/ui/SectionContainer';

export const StepsContainer = styled.div`
	width: 80rem;
	margin: 0 auto;
	@media (max-width: 1025px) {
		display: none;
	}
`;

export const FormContainer = styled.form`
	display: flex;
	flex-direction: column;
	align-items: center;
	row-gap: 5.9rem;

	@media (max-width: 1024px) {
		flex-direction: column;
		align-items: center;
		gap: 2rem;
		width: 100%;
		h1:first-child {
			font-size: 1.8rem !important;
			padding: 0;
		}
	}
`;

export const InnerFormContainer = styled.div`
	display: flex;
	justify-content: center;
	gap: 6rem;

	@media (max-width: 1024px) {
		flex-direction: column;
		align-items: center;
		gap: 2rem;
		width: 100%;
	}
`;

export const FormInputsContainer = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 2rem;
	@media (max-width: 1024px) {
		flex-direction: column;
		align-items: center;
		gap: 2rem;
		width: 100%;
	}
`;

export const SubmitContainer = styled.div`
	display: flex;
	column-gap: 2rem;
	margin-left: auto;
	margin-top: 2rem;

	@media (max-width: 1024px) {
		flex-direction: column-reverse;
		align-items: center;
		gap: 2rem;
		width: 100%;
	}
`;

export const AccessCard = styled.div`
	display: flex;
	flex-direction: column;
	width: 264px;
	height: 35rem;
	background-color: #fff;
	border: 1px solid #d2d7e4;
	border-radius: 1.6rem;
	& > img {
		width: auto;
		height: 20rem;
		padding: 2rem;
		object-fit: contain;
	}
	& > div {
		padding: 1rem;
		border-radius: 1.6rem;
		background-color: #f0f3f8;
		ul {
			margin: 2rem 0 2rem 2rem;
			font-size: 1.4rem;
			display: grid;
			gap: 1rem;
		}
	}
`;

export const AvatarContainer = styled.label<{ background: string }>`
	width: 14rem;
	height: 14rem;
	border-radius: 100%;
	border: 0.4rem solid var(--primary-blue);
	background-origin: content-box;
	position: relative;
	background: #f0f3f8 url(${({ background }) => background}) no-repeat center
		center;
	cursor: pointer;

	& > svg {
		position: absolute;
		bottom: 0.6rem;
		right: 0;
		width: 4rem;
		height: 4rem;
		border-radius: 100%;
		border: 0.2rem solid var(--primary-blue);
		background-color: #f0f3f8;
		color: var(--primary-blue);
		padding: 0.6rem;
	}
`;

export const CarouselContainer = styled.div`
	display: flex;
	flex: 1;
	gap: 4rem;
	height: 353px;
`;

export const InfoContainer = styled.div`
	gap: 4rem;
	width: 37rem;

	p {
		text-align: left;
		margin-right: 1rem;
		font-family: 'Poppins';
		font-size: 12px;
		font-weight: 400;
		line-height: 18px;
		color: #575757;
	}

	ul {
		margin: 1rem 1rem 1rem 2rem;

		li {
			margin-bottom: 1rem;
			width: 80%;
			font-family: 'Poppins';
			font-size: 12px;
			font-weight: 400;
			line-height: 18px;
			text-align: left;
		}
	}

	@media (max-width: 1024px) {
		display: none;
	}
`;

export const TitleContainer = styled.div`
	padding: 0;
	margin: 0;
	@media (max-width: 1024px) {
		padding: 0;
		h1:first-of-type {
			font-size: 3rem;
		}
	}
`;

export const DescriptionContainer = styled.div`
	width: 100%;

	p {
		font-family: 'Poppins', sans-serif;
		font-size: 1.5rem;
		font-weight: 400;
		line-height: 2.1rem;
		text-align: center;
	}

	& > p > span {
		font-family: 'Poppins', sans-serif;
		font-size: 1.5rem;
		font-weight: 600;
		line-height: 2.1rem;
		text-align: center;
		color: var(--primary-blue);
	}
`;

export const DescriptionWarningContainer = styled.div`
	width: 100%;
	p {
		font-family: 'Poppins', sans-serif;
		font-size: 1.5rem;
		font-weight: 400;
		line-height: 2.1rem;
		text-align: center;
		width: 100%;

		span {
			font-weight: 600;
		}
	}
`;

export const Column = styled.div`
	display: flex;
	flex-direction: column;
	gap: 2.4rem;
	@media (max-width: 1024px) {
		width: 100%;
	}
`;

export const InputGroup = styled.div`
	display: flex;
	flex-direction: column;
	gap: 2.4rem;
	width: 30rem;
	@media (max-width: 1024px) {
		width: 100%;
	}
`;

export const Row = styled.div`
	display: flex;
	gap: 4rem;

	@media (max-width: 1024px) {
		flex-direction: column;
		width: 100%;
	}
`;

export const ResponsiveSectionContainer = styled(SectionContainer)`
	align-items: center;
	justify-content: center;
	height: 73rem;

	@media (max-width: 1024px) {
		height: auto;
	}
`;

export const WebButton = styled.div`
	display: flex;
	gap: 2rem;

	@media (max-width: 1024px) {
		display: none;
	}
`;

export const MobileButton = styled.div`
	display: none;

	@media (max-width: 1024px) {
		display: flex;
		flex-direction: column-reverse;
		gap: 2rem;
		width: 100%;
		align-items: center;
	}
`;

export const CreateCostCenterButtonContainers = styled.div`
	margin-right: 0;
	@media (max-width: 1024px) {
		margin-right: auto;
	}
`;
