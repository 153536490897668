import * as S from "./styles"
import { AsynchronousContent } from '../../AsynchronousContent';
import { flexRender, Table } from '@tanstack/react-table';
import { SortColumnButton } from '../../SortColumnButton';
import { TableProps } from '../index';
import noItems from "../../../assets/no_items_table.svg";
import { Typography } from '../../ui/Typography';


interface Props extends TableProps<any> {
	table : Table<any>
}

const EmptyContentComponent = () => (
	<S.NoContentContainer>
		<img src={noItems} alt="Não há itens a serem exibidos!" height={"130"} />
		<Typography weight={'600'}>
			Não há itens a serem exibidos!
		</Typography>
	</S.NoContentContainer>
)

export default function LightGrayTable ({asyncStatus, maxHeight, toggleSort, currentSortColumn, useExpandableContent, data, showHeader = true, expandableRenderItem, table} : Props) {
	const hasItems = data.length > 0;

	return (
		<AsynchronousContent status={asyncStatus ?? 'success'}>
			<S.TableContainer style={{ maxHeight }}>
				<S.TableComp>
					<S.TableHeader>
						{showHeader &&
							table.getHeaderGroups().map((headerGroup) => (
								<tr key={headerGroup.id}>
									{headerGroup.headers.map((header) => (
										<S.TH
											key={header.id}
											onClick={() => {
												header.column.getCanSort() &&
												toggleSort!(header.column.id);
											}}
											style={{
												cursor: header.column.getCanSort()
													? 'pointer'
													: 'default',
											}}
										>
											<div
												style={{
													display: 'flex',
													justifyContent:
														header.column.columnDef.meta?.align || 'start',
												}}
											>
												{header.isPlaceholder
													? null
													: flexRender(
														header.column.columnDef.header,
														header.getContext()
													)}

												{header.column.getCanSort() && (
													<SortColumnButton
														order={
															currentSortColumn?.name === header.column.id
																? currentSortColumn.order
																: null
														}
														onToggleSort={() =>
															toggleSort!(header.column.id)
														}
														isActive={
															currentSortColumn?.name === header.column.id
														}
													/>
												)}
											</div>
										</S.TH>
									))}
								</tr>
							))}
					</S.TableHeader>
					<tbody>
						{table.getRowModel().rows.map((row) => (
							(useExpandableContent && row.getIsExpanded()) ? (
								<tr key={`expandable-${row.id}`}>
									<td colSpan={100}>
										{expandableRenderItem!(row.original)}
									</td>
								</tr>)
								:
								(
									<S.TR key={'body-' + row.id}>
										{row.getVisibleCells().map((cell) => (
											<S.TD key={'body-cell-' + cell.id}>
												<div
													style={{
														display: 'flex',
														justifyContent:
															cell.column.columnDef.meta?.align || 'start',
													}}
												>
													{flexRender(
														cell.column.columnDef.cell,
														cell.getContext(),
													)}
												</div>
											</S.TD>
										))}
									</S.TR>
								)
						))}
					</tbody>
				</S.TableComp>
				{!hasItems && <EmptyContentComponent />}
			</S.TableContainer>
		</AsynchronousContent>
	)
}