import { useEffect, useState } from 'react';
import { db, firebaseAuth } from '../../../../services/firebase';
import {
	ref,
	onValue,
	get,
	query,
	orderByChild,
	equalTo,
	update,
	set,
	orderByKey,
	serverTimestamp,
	push,
	startAt,
	Query,
} from 'firebase/database';
import * as S from './styles';
import PageTitle from '../../../../components/PageTitle';
import { useAuth } from '../../../../hooks/useAuth';
import UserCard from '../../../../components/UserCard';
import { toast } from 'react-toastify';
import { EmptyContent } from '../../../../components/EmptyContent';
import { useHistory } from 'react-router-dom';
import avatarImg from '../../../../assets/avatar.svg';
import Loader from '../../../../components/Loader';
import { FaCommentDots } from 'react-icons/fa';
import { OperatorSelectorModal } from './OperatorSelectorModal';
import { useMutation, useQuery } from 'react-query';
import { sendNotificationChatDelegation } from '../../../../services/queries/Notifications';
import { getPreferences } from '../../../../services/queries/NotificationsPreferences';
import { showErrorMessage } from '../../../../utils/ErrorHandler';
import { IoIosTime } from 'react-icons/io';
import { CollaboratorSelectorModal } from './CollaboratorSelectorModal';
import { Collaborator, Operator } from '../../../../@types';

export interface ChatProps {
	responsable_operator: string;
	messages?: any;
	opened: boolean;
	last_opened: number;
}

export interface OperatorInfo {
	id: string;
	name: string;
	email: string;
}
export interface CollabInfo {
	id: string;
	email: string;
	job_description: string;
	name: string;
	online_at: string;
	online_status: string;
	section: string;
	picture_url: string;
	started_at: Date;
	messages_sent?: number;
	responsible_operator?: OperatorInfo;
}

interface DelegationParams {
	company_id: string;
	new_operator_email: string;
	old_operator_email?: string;
}

interface ChatPreferences {
	operatorPreferences: {
		chat_delegation: boolean;
	};
}

export function ChatListPage() {
	const { user, currentCompany, firebaseAuthConcluded } = useAuth();
	const history = useHistory();

	const [allChats, setAllChats] = useState<ChatProps[]>();
	const [allChatsIds, setAllChatsIds] = useState<string[]>();

	const [yourChats, setYourChats] = useState<CollabInfo[]>();
	const [openChats, setOpenChats] = useState<CollabInfo[]>();
	const [chatsWithResponsible, setChatsWithResponsible] =
		useState<CollabInfo[]>();

	const [loadingChats, setLoadingChats] = useState(true);

	const sendNotificationToOperators = useMutation(
		({
			company_id,
			new_operator_email,
			old_operator_email,
		}: DelegationParams) => {
			return sendNotificationChatDelegation(
				company_id,
				new_operator_email,
				old_operator_email
			);
		}
	);

	useEffect(() => {
		if (!firebaseAuthConcluded) {
			toast.error(
				'Chat indisponível no momento, para usa-lo saia da aplicação e tente novamente em instantes.'
			);
			history.goBack();
			return;
		}

		try {
			const chatsRef = ref(db, `${currentCompany?.id}/chats`);

			let allChatsIds: string[] = [];
			let allChats: ChatProps[] = [];
			onValue(
				chatsRef,
				(snapshot) => {
					snapshot.forEach((snap) => {
						if (snap.val().opened === true) {
							allChats.push(snap.val());
							allChatsIds.push(snap.key!);
						}
					});
					setAllChats(allChats);
					setAllChatsIds(allChatsIds);
					allChats = [];
					allChatsIds = [];
				},
				(err) => {
					console.log(err);
				}
			);
		} catch (err) {
			console.log(err);
			toast.error(
				'Ocorreu um erro ao buscar as informações sobre os chats. Por favor, tente novamente.'
			);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user, currentCompany, firebaseAuthConcluded]);

	useEffect(() => {
		if (!allChatsIds || !allChats) {
			return;
		}

		let collabsInfoOpenChats: CollabInfo[] = [];
		let collabsInfoYourChats: CollabInfo[] = [];
		let collabsInfoChatsWithResponsible: CollabInfo[] = [];

		//let responsible_operator = {} as OperatorInfo;
		//let messages_sent: number = 0;

		if (allChats.length > 0 && allChatsIds.length > 0) {
			(async () => {
				try {
					for (let i = 0; i < allChatsIds.length; i++) {
						let responsible_operator = {} as OperatorInfo;
						let messages_sent: number = 0;
						let collabInfo = {} as CollabInfo;
						const collaboratorRef = ref(
							db,
							`${currentCompany?.id}/collaborators/${allChatsIds[i]}`
						);
						await get(collaboratorRef).then((snap) => {
							collabInfo = { id: allChatsIds[i], ...snap.val() };
						});

						collabInfo = {
							...collabInfo,
							started_at: new Date(allChats[i].last_opened),
						};

						if (allChats[i].responsable_operator) {
							const operatorsRef = ref(
								db,
								`${currentCompany?.id}/chats/${allChatsIds[i]}/responsable_operator`
							);
							let operatorId;
							await get(operatorsRef).then((snap) => {
								operatorId = snap.val();
							});

							const operatorRef = ref(
								db,
								`${currentCompany?.id}/operators/${operatorId}`
							);

							const snap = await get(operatorRef);

							responsible_operator = {
								id: snap.val().id,
								name: snap.val().name,
								email: snap.val().email,
							};

							if (
								allChats[i].responsable_operator ===
								firebaseAuth.currentUser?.uid!
							) {
								// COUNT MESSAGE
								const msgsRef = ref(
									db,
									`${currentCompany?.id}/chats/${allChatsIds[i]}/messages`
								);

								const searchMessages = query(
									msgsRef,
									orderByChild('viewed'),
									equalTo(false)
								);

								onValue(searchMessages, (snap) => {
									messages_sent = 0;

									snap.forEach((messageSnap) => {
										const message = messageSnap.val();
										if (message && message.senderId === collabInfo.id) {
											messages_sent++;
										}
									});
								});
								collabInfo = {
									...collabInfo,
									messages_sent,
									responsible_operator,
								};
								collabsInfoYourChats.push(collabInfo);
							} else {
								collabInfo = {
									...collabInfo,
									messages_sent,
									responsible_operator,
								};

								collabsInfoChatsWithResponsible.push(collabInfo);
							}
						} else {
							const msgsRef = ref(
								db,
								`${currentCompany?.id}/chats/${allChatsIds[i]}/messages`
							);

							const searchMessages = query(
								msgsRef,
								orderByChild('timestamp'),
								startAt(allChats[i].last_opened - 3000) // subtracting 3 sec to get the message that actually opened the chat
							);

							onValue(searchMessages, (snap) => {
								messages_sent = snap.size;
								if (snap.size > 1) messages_sent -= 1; //Because 1 message is 'automated'
							});
							collabInfo = { ...collabInfo, messages_sent };
							collabsInfoOpenChats.push(collabInfo);
						}
					}
					setYourChats([...collabsInfoYourChats]);
					setChatsWithResponsible([...collabsInfoChatsWithResponsible]);
					setOpenChats([...collabsInfoOpenChats]);
				} catch (err) {
					console.log(err);
					toast.error(
						'Ocorreu um erro ao buscar as informações sobre os chats. Por favor, tente novamente.'
					);
				}
			})();

			setLoadingChats(false);
		} else {
			setYourChats([...collabsInfoYourChats]);
			setChatsWithResponsible([...collabsInfoChatsWithResponsible]);
			setOpenChats([...collabsInfoOpenChats]);
			setLoadingChats(false);
		}
	}, [allChats, allChatsIds, currentCompany?.id]);

	async function getOperatorData(searchQuery: Query) {
		try {
			const snapshot = await get(searchQuery);
			return snapshot.val();
		} catch (error) {
			console.log(error);
			return null;
		}
	}

	async function delegateChatToOtherOperator(
		operator: Operator,
		chatId: string,
		oldOperatorEmail?: string
	) {
		const operatorsRef = ref(db, `${currentCompany?.id}/operators`);
		const searchOperatorID = query(
			operatorsRef,
			orderByChild('id'),
			equalTo(operator.id!)
		);

		const searchOperatorEmail = query(
			operatorsRef,
			orderByChild('email'),
			equalTo(operator.email)
		);

		const searchOperatorName = query(
			operatorsRef,
			orderByChild('name'),
			equalTo(operator.name)
		);

		const [operatorDataByID, operatorDataByEmail, operatorDataByName] =
			await Promise.all([
				getOperatorData(searchOperatorID),
				getOperatorData(searchOperatorEmail),
				getOperatorData(searchOperatorName),
			]);

		const operatorData =
			operatorDataByID || operatorDataByEmail || operatorDataByName;

		if (!operatorData) {
			toast.error('Ocorreu um erro ao buscar operador!');
			return;
		}

		const operatorFirebaseId = Object.keys(operatorData)[0];

		if (!chatId) {
			toast.error('Ocorreu um erro ao buscar o id do chat!');
			return;
		}

		const chatRef = ref(db, `${currentCompany?.id}/chats/${chatId}`);

		await update(chatRef, {
			responsable_operator: operatorFirebaseId,
		});

		await sendNotificationToOperators.mutateAsync({
			company_id: currentCompany!.id,
			old_operator_email: oldOperatorEmail,
			new_operator_email: operator.email,
		});
		toast.info('Chat transferido com sucesso!');
	}

	async function startChatWithCollab(collab: Collaborator) {
		try {
			const collabRef = ref(db, `${currentCompany?.id}/collaborators`);
			const searchCollab = query(collabRef, orderByKey(), equalTo(collab.id!));

			const collabData = await get(searchCollab)
				.then((snap) => {
					return snap.val();
				})
				.catch((err) => {
					console.log(err);
				});

			if (!collabData) {
				const newCollabRef = ref(
					db,
					`${currentCompany?.id}/collaborators/${collab.id}`
				);

				await set(newCollabRef, {
					email: collab.email,
					job_description: collab.office,
					name: `${collab.first_name} ${collab.last_name}`,
					online_at: '',
					online_status: 'offline',
					section: collab.section,
				});
			}

			const newChatRef = ref(db, `${currentCompany?.id}/chats/${collab.id}`);

			const chatsRef = ref(db, `${currentCompany?.id}/chats`);
			const searchChat = query(chatsRef, orderByKey(), equalTo(collab.id!));

			const chatData = await get(searchChat)
				.then((snap) => {
					return snap.val();
				})
				.catch((err) => {
					console.log(err);
				});

			if (!chatData) {
				await set(newChatRef, {
					messages: [
						{
							message: `Mensagem automática: Conversa iniciada manualmente pelo operador ${user.name}.`,
							receiverId: collab.id,
							senderId: currentCompany?.id,
							timestamp: serverTimestamp(),
						},
					],
					responsable_operator: firebaseAuth.currentUser?.uid,
					opened: true,
					last_opened: serverTimestamp(),
				});
			} else {
				await update(newChatRef, {
					responsable_operator: firebaseAuth.currentUser?.uid,
					opened: true,
					last_opened: serverTimestamp(),
				});

				push(ref(db, `${currentCompany?.id}/chats/${collab.id}/messages`), {
					message: `Mensagem automática: Conversa iniciada manualmente pelo operador ${user.name}.`,
					receiverId: collab.id,
					senderId: currentCompany?.id,
					timestamp: serverTimestamp(),
				});
			}

			history.push(`/home/chat/${collab.id}`);
		} catch (err) {
			toast.error(
				'Ocorreu um erro ao tentar iniciar novo chat. Tente novamente em alguns instantes.'
			);
		}
	}

	const preferencesQuery = useQuery<ChatPreferences, Error>(
		'fetchPreferences',
		() => {
			return getPreferences(currentCompany?.id!);
		},
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível buscar as preferências relacionadas ao chat. '
				);
			},
		}
	);

	if (
		loadingChats ||
		!openChats ||
		!yourChats ||
		!chatsWithResponsible ||
		preferencesQuery.isLoading
	) {
		return (
			<S.Container>
				<PageTitle title={'Chat'} />
				<Loader />
			</S.Container>
		);
	}

	return (
		<S.Container>
			<PageTitle title={'Chat'} />
			{!openChats.length &&
				!yourChats.length &&
				!chatsWithResponsible.length && (
					<EmptyContent text={'Não há chats no momento'} />
				)}

			<S.ChatsListsContainer>
				{yourChats.length > 0 && (
					<>
						<S.ListTitle>Seus chats</S.ListTitle>
						<S.ChatsList>
							{yourChats.map((collab, index) => {
								const hours = collab.started_at
									.getHours()
									.toString()
									.padStart(2, '0');
								const minutes = collab.started_at
									.getMinutes()
									.toString()
									.padStart(2, '0');
								const seconds = collab.started_at
									.getSeconds()
									.toString()
									.padStart(2, '0');
								const formattedTime = `${hours}:${minutes}:${seconds}`;
								return (
									<S.ChatButton
										to={`/home/chat/${collab.id}`}
										key={`${collab.email}${index}`}
									>
										<UserCard
											name={collab ? collab.name : ''}
											bottomInfo={collab ? collab.section : ''}
											avatar_url={collab ? collab.picture_url : avatarImg}
										/>

										<S.ChatInfoContainer
											data-rh={`Conversa iniciada em ${collab.started_at.getDate()} de ${collab.started_at.toLocaleString(
												'pt-br',
												{ month: 'long' }
											)} de ${collab.started_at.getFullYear()} às ${formattedTime}`}
										>
											<IoIosTime />
										</S.ChatInfoContainer>

										<S.ChatInfoContainer
											data-rh={`${collab.messages_sent} mensagens não lidas`}
										>
											{collab.messages_sent}
											<FaCommentDots />
										</S.ChatInfoContainer>

										<span
											onClick={(e) => {
												e.stopPropagation();
											}}
										>
											<OperatorSelectorModal
												alreadyAddedOperator={
													collab.responsible_operator?.email!
												}
												onSelectOperator={(operator) =>
													delegateChatToOtherOperator(
														operator,
														collab.id,
														collab.responsible_operator?.email!
													)
												}
											/>
										</span>
									</S.ChatButton>
								);
							})}
						</S.ChatsList>
					</>
				)}

				{openChats.length > 0 && (
					<>
						<S.ChatsDivider />
						<S.ListTitle>Chats abertos</S.ListTitle>
						<S.ChatsList>
							{openChats.map((collab, index) => {
								const hours = collab.started_at
									.getHours()
									.toString()
									.padStart(2, '0');
								const minutes = collab.started_at
									.getMinutes()
									.toString()
									.padStart(2, '0');
								const seconds = collab.started_at
									.getSeconds()
									.toString()
									.padStart(2, '0');
								const formattedTime = `${hours}:${minutes}:${seconds}`;
								return (
									<S.ChatButton
										to={`/home/chat/${collab.id}`}
										key={`${collab.email}${index}`}
										disabled={
											!preferencesQuery.data?.operatorPreferences
												.chat_delegation
										}
										onClick={(e) =>
											!preferencesQuery.data?.operatorPreferences
												.chat_delegation
												? e.preventDefault()
												: null
										}
									>
										<UserCard
											name={collab ? collab.name : ''}
											bottomInfo={collab ? collab.section : ''}
											avatar_url={collab ? collab.picture_url : avatarImg}
										/>

										<S.ChatInfoContainer
											data-rh={`Conversa iniciada em ${collab.started_at.getDay()} de ${collab.started_at.toLocaleString(
												'pt-br',
												{ month: 'long' }
											)} de ${collab.started_at.getFullYear()} às ${formattedTime}`}
										>
											<IoIosTime />
										</S.ChatInfoContainer>

										<S.ChatInfoContainer
											data-rh={`${collab.messages_sent} mensagens não lidas`}
										>
											{collab.messages_sent}
											<FaCommentDots />
										</S.ChatInfoContainer>
										{user.access_level === 'admin' && (
											<span
												onClick={(e) => {
													e.stopPropagation();
												}}
											>
												<OperatorSelectorModal
													alreadyAddedOperator={
														collab.responsible_operator?.email!
													}
													onSelectOperator={(operator) =>
														delegateChatToOtherOperator(operator, collab.id)
													}
												/>
											</span>
										)}
									</S.ChatButton>
								);
							})}
						</S.ChatsList>
					</>
				)}

				{chatsWithResponsible.length > 0 && (
					<>
						<S.ChatsDivider />
						<S.ListTitle>Chats de outros operadores</S.ListTitle>
						<S.ChatsList>
							{chatsWithResponsible.map((collab, index) => {
								const hours = collab.started_at
									.getHours()
									.toString()
									.padStart(2, '0');
								const minutes = collab.started_at
									.getMinutes()
									.toString()
									.padStart(2, '0');
								const seconds = collab.started_at
									.getSeconds()
									.toString()
									.padStart(2, '0');
								const formattedTime = `${hours}:${minutes}:${seconds}`;
								return (
									<S.ChatContainer
										key={`${collab.email}${index}`}
										data-rh={`Operador responsável: ${collab.responsible_operator?.name}`}
									>
										<UserCard
											name={collab ? collab.name : ''}
											bottomInfo={collab ? collab.section : ''}
											avatar_url={collab ? collab.picture_url : avatarImg}
										/>

										<S.ChatInfoContainer
											data-rh={`Conversa iniciada em ${collab.started_at.getDay()} de ${collab.started_at.toLocaleString(
												'pt-br',
												{ month: 'long' }
											)} de ${collab.started_at.getFullYear()} às ${formattedTime}`}
										>
											<IoIosTime />
										</S.ChatInfoContainer>

										{user.access_level === 'admin' && (
											<span
												onClick={(e) => {
													e.stopPropagation();
												}}
											>
												<OperatorSelectorModal
													alreadyAddedOperator={
														collab.responsible_operator?.email!
													}
													onSelectOperator={(operator) =>
														delegateChatToOtherOperator(
															operator,
															collab.id,
															collab.responsible_operator?.email!
														)
													}
												/>
											</span>
										)}
									</S.ChatContainer>
								);
							})}
						</S.ChatsList>
					</>
				)}
			</S.ChatsListsContainer>

			<S.ButtonsContainer>
				<CollaboratorSelectorModal
					onSelectCollaborator={(collab) => {
						startChatWithCollab(collab);
					}}
					chatsOpened={allChatsIds!}
				/>
			</S.ButtonsContainer>
		</S.Container>
	);
}
