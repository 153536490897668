import XLSX from 'xlsx';

export class FileParser {
	file: File;
	fileName: string;

	constructor(file: File) {
		this.file = file;
		this.fileName = file.name;
	}

	// sets the sheet range to be within cells with data only
	update_sheet_range(ws: XLSX.WorkSheet) {
		var range = { s: { r: 20000000, c: 20000000 }, e: { r: 0, c: 0 } };
		Object.keys(ws)
			.filter(function (x) {
				return x.charAt(0) !== '!';
			})
			.map(XLSX.utils.decode_cell)
			.forEach(function (x) {
				range.s.c = Math.min(range.s.c, x.c);
				range.s.r = Math.min(range.s.r, x.r);
				range.e.c = Math.max(range.e.c, x.c);
				range.e.r = Math.max(range.e.r, x.r);
			});
		return XLSX.utils.encode_range(range);
	}

	parseFile(
		parseToFormatCallback: (convertedFile: any[][]) => void,
		raw = false
	) {
		let reader = new FileReader();

		reader.onload = (e: ProgressEvent<FileReader>) => {
			let data = e.target!!.result;
			data = new Uint8Array(data as ArrayBuffer);

			// CELL DATES TO PARSE DATES, OTHERWISE DATES WILL BE CONVERTED TO NUMBER
			const workbook = XLSX.read(data, { type: 'array', cellDates: true, raw });

			let result: any = {};
			workbook.SheetNames.forEach((sheetName) => {
				workbook.Sheets[sheetName]['!ref'] = this.update_sheet_range(
					workbook.Sheets[sheetName]
				);
				// this takes time if the sheet is long
				const roa = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {
					header: 1,
					blankrows: true, // set to false if update_sheet_range has not been called (otherwise it will take a lot of time reading every empty line)
					defval: '',
				});

				// see the result, caution: it works after reader event is done.
				if (roa.length) result[sheetName] = roa;
			});

			// When converted to JSON, the xls page name is used as the key name from the object
			// Since we can't predict which name that will be, we extract it like this
			let fileConvertedToArray = result[Object.keys(result)[0]];
			if (this.fileName.endsWith('.csv')) {
				// check is it's a CSV file
				fileConvertedToArray = this.decodeCSVSpecialChars(fileConvertedToArray); //This function decodes the cells that have special chars
			}

			/*
				fileConvertedToArray is an array of arrays of the possible values filled in, 
				each array is a line from the file, each line is an array of values (like strings, numbers, dates etc)
			*/

			// Callback function that will parse the fileConvertedToArray obj to the desired object format
			parseToFormatCallback(fileConvertedToArray);
		};

		reader.readAsArrayBuffer(this.file);
	}

	// CSV files aren't decoding special chars (á, ã,...). This function decodes the cells that have special chars
	decodeCSVSpecialChars(fields: any[][]) {
		fields = fields.map((row) => {
			return row.map((cell) => {
				try {
					return decodeURIComponent(escape(cell));
				} catch (err) {
					return cell;
				}
			});
		});
		return fields;
	}
}
