import styled, { css } from 'styled-components';

export const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: calc(100vw - 100px);
	height: calc(100vh - 100px);
	padding: 2rem 0;
`;

export const ArrowButton = css`
	width: 4rem;
	height: 4rem;
	border-radius: 50%;
	background-color: var(--primary-blue);
	padding: 1rem;
	> svg {
		width: 100%;
		height: 100%;
		color: var(--white);
	}
`;
export const RightArrow = styled.button`
	${ArrowButton}
`;
export const LeftArrow = styled.button`
	${ArrowButton}
`;

export const ImageContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 1rem;
	position: relative;
	cursor: pointer;
	max-height: 90%;
`;

export const Image = styled.img`
	width: 80%;
	border-radius: 0.6rem;
	margin: 0 8rem;
	user-select: none;
	object-fit: contain;
`;

export const ZoomOptionsContainer = styled.div`
	position: absolute;
	bottom: -4.5rem;
	display: flex;
	width: max-content;
	column-gap: 0.5rem;
`;

export const ZoomOption = styled.button`
	${ArrowButton}
	padding: 0.75rem;
`;
