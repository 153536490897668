import { Transaction, Transfer } from '../../@types';
import {
	FilterParams,
	parseFilterParamsToParams,
} from '../../components/Filter';
import {
	SortColumn,
	parseSortColumnToParams,
} from '../../hooks/useSortColumnHook';
import { parseCustomFieldsToParams } from '../../pages/FlexibleBenefits/TransfersV2/utils/parseTransferData';
import api from '../api';

export interface TransactionsListReturn {
	transactions: Transaction[];
}

export interface TransfersListReturn {
	transfers: Transfer[];
	totalTransfers: number;
	entrances_totalValue: number;
	exits_totalValue: number;
}

export interface TransferToReportReturn {
	transfers: Transfer[];
	totalTransfers: number;
	entrances_totalValue: number;
	exits_totalValue: number;
}

export async function getTranfers(
	startPeriod?: string,
	endPeriod?: string,
	limit?: string,
	companyId?: string
): Promise<TransactionsListReturn> {
	const { data } = await api.get(
		`/api/v1/rh/companies/${companyId}/transactions/q`,
		{
			params: {
				start_period: startPeriod,
				end_period: endPeriod,
				limit: limit,
			},
		}
	);

	return data;
}

export async function getTransfersV2(
	filterParams: FilterParams[],
	companyId: string,
	page = 1,
	sortColumn: SortColumn | null = null
): Promise<TransfersListReturn> {
	const { data } = await api.get(
		`/api/v1/rh/companies/${companyId}/transfers/q?page=${page}`,
		{
			params: {
				...parseFilterParamsToParams(filterParams),
				...parseSortColumnToParams(sortColumn),
			},
		}
	);

	return data;
}

export async function generateTransfersReport(
	filterParams: FilterParams[],
	companyId: string,
	sortColumn: SortColumn | null = null,
	fields: object
): Promise<TransferToReportReturn> {
	const { data } = await api.get(
		`/api/v1/rh/companies/${companyId}/transfers/report/q`,
		{
			params: {
				...parseFilterParamsToParams(filterParams),
				...parseSortColumnToParams(sortColumn),
				fields: parseCustomFieldsToParams(fields),
			},
		}
	);

	return data;
}

export async function generateTransfersReportExcel(
	filterParams: FilterParams[],
	companyId: string,
	sortColumn: SortColumn | null = null,
	fields: object
): Promise<TransferToReportReturn> {
	const { data } = await api.get(
		`/api/v1/rh/companies/${companyId}/transfers/report-excel/q`,
		{
			params: {
				...parseFilterParamsToParams(filterParams),
				...parseSortColumnToParams(sortColumn),
				fields: parseCustomFieldsToParams(fields),
			},
		}
	);

	return data;
}

interface GenerateQRCodeResponse {
	amount: number;
	emv: string;
	id: string;
	pma_id: string;
	transaction_identification: string;
}
export async function generateQRCode(
	amount: number,
	origin: 'multiflex' | 'corpway',
	companyId: string
) {
	const { data } = await api.post<GenerateQRCodeResponse>(
		`/api/v1/companies/${companyId}/qrcode`,
		{
			amount,
			origin,
		}
	);

	return data;
}

interface GenerateBillingResponse {
	amount: number;
	company_id: string;
	status: string;
	id_swap: string;
	id_swap_external: string;
}
export async function generateBilling(
	amount: number,
	origin: 'multiflex' | 'corpway',
	companyId: string,
	recipientEmail?: string
) {
	const { data } = await api.post<GenerateBillingResponse>(
		`/api/v1/companies/${companyId}/generate-billing`,
		{
			amount,
			origin,
			custom_email: recipientEmail,
		}
	);
	return data;
}

export async function downloadBilling(swapId: string, companyId: string) {
	const { data } = await api.get<string>(
		`/api/v1/companies/${companyId}/download-billing/${swapId}`,
		{
			responseType: 'blob',
		}
	);

	return data;
}

export async function sendQRCodeByEmail(
	emv: string,
	value: number,
	companyId: string,
	recipientEmail?: string,
	origin = 'multiflex'
): Promise<void> {
	return await api.post(`/api/v1/companies/${companyId}/send-qrcode`, {
		emv,
		value,
		custom_email: recipientEmail,
		origin,
	});
}
