import { useQuery } from 'react-query';
import { EmptyContent } from '../../../components/EmptyContent';
import PageTitle from '../../../components/PageTitle';
import { useAuth } from '../../../hooks/useAuth';
import { getCompanyPartners } from '../../../services/queries/Partners';
import * as TableStyle from '../../../components/Table/TableStyles';
import * as S from './styles';
import Loader from '../../../components/Loader';
import { showErrorMessage } from '../../../utils/ErrorHandler';
import { BsPeopleFill } from 'react-icons/bs';
import { RiNumbersFill } from 'react-icons/ri';
import { useSortColumnHook } from '../../../hooks/useSortColumnHook';
import { SortColumnButton } from '../../../components/SortColumnButton';

export function Partners() {
	const { currentCompany } = useAuth();
	const { currentSortColumn, sortList, toggleSort } = useSortColumnHook();

	const getCompanyPartnersQuery = useQuery(
		['fetch-partners', currentCompany?.id],
		() => getCompanyPartners(currentCompany!.id),
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível buscar os parceiros da empresa. '
				);
			},
			enabled: !!currentCompany?.id,
		}
	);

	if (getCompanyPartnersQuery.isLoading || !getCompanyPartnersQuery.data)
		return (
			<S.Container>
				<PageTitle title='Parceiros disponíveis' />
				<Loader />
			</S.Container>
		);

	return (
		<S.Container>
			<PageTitle title='Parceiros disponíveis' />
			{getCompanyPartnersQuery.data.length === 0 ? (
				<EmptyContent text='Não há ítens a serem exibidos.' />
			) : (
				<TableStyle.Table>
					<TableStyle.TableHeader>
						<TableStyle.TableRow>
							<TableStyle.TableHeaderCell>
								<SortColumnButton
									order={
										currentSortColumn?.name === 'partner_name'
											? currentSortColumn.order
											: null
									}
									onToggleSort={() => toggleSort('partner_name')}
								/>
								PARCEIRO
							</TableStyle.TableHeaderCell>
							<TableStyle.TableHeaderCell>
								<SortColumnButton
									order={
										currentSortColumn?.name === 'partner_description'
											? currentSortColumn.order
											: null
									}
									onToggleSort={() => toggleSort('partner_description')}
								/>
								DESCRIÇÃO
							</TableStyle.TableHeaderCell>
							<TableStyle.TableHeaderCell>CATEGORIA</TableStyle.TableHeaderCell>
							<TableStyle.TableHeaderCell>OPÇÕES</TableStyle.TableHeaderCell>
							{/* <TableStyle.TableHeaderCell>ATRIBUIDO DESDE</TableStyle.TableHeaderCell> */}
						</TableStyle.TableRow>
					</TableStyle.TableHeader>

					<TableStyle.TableBody>
						{sortList(getCompanyPartnersQuery.data).map((partner) => (
							<TableStyle.TableRow key={partner.partner_id}>
								<TableStyle.TableData>
									{partner.partner_name}
								</TableStyle.TableData>
								<TableStyle.TableData>
									{partner.partner_description}
								</TableStyle.TableData>
								<TableStyle.TableData>
									{partner.partner_category}
								</TableStyle.TableData>
								<TableStyle.TableData>
									<S.OptionsContainer>
										<TableStyle.OptionLink
											data-rh='Ver quantitativo de colaboradores'
											to={`partners/quantitative/${partner.partner_id}`}
										>
											<RiNumbersFill />
										</TableStyle.OptionLink>

										<TableStyle.OptionLink
											data-rh='Colaboradores'
											to={`partners/${partner.partner_id}`}
										>
											<BsPeopleFill />
										</TableStyle.OptionLink>
									</S.OptionsContainer>
								</TableStyle.TableData>
							</TableStyle.TableRow>
						))}
					</TableStyle.TableBody>
				</TableStyle.Table>
			)}
		</S.Container>
	);
}
