import styled, { css } from 'styled-components';
import { BaseInputStyle } from '../../../componentsV2/ui/Form/BaseStyle';
import { renderToStaticMarkup as rtsm } from 'react-dom/server';
import { IoIosArrowDown } from 'react-icons/io';
import { Typography } from '../../../componentsV2/ui/Typography';

export const arrowIcon = css`
	${(rtsm(IoIosArrowDown({ size: 20, color: 'black' })) as string).replace(
		/"/g,
		"'"
	)}
`;

export const TopContainer = styled.div`
	display: flex;
	column-gap: 2rem;
	width: 100%;
	@media (max-width: 1024px) {
		flex-direction: column;
		gap: 2rem;
		align-items: center;
	}
`;

export const InfoContainerCard = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	background-color: var(--white);
	align-items: center;
	opacity: 0px;
	height: 21rem;

	padding-right: 1.5rem;
	padding-left: 1.5rem;
	gap: 2rem;
	border-radius: 16px;
	border: 3px solid #f1f2fa;
	background-color: #ffffff;

	& > div.accountBalance {
		width: fit-content;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		text-align: center;
		row-gap: 2rem;
		flex: 1;
		padding: 4.4rem -6rem;
		gap: 0rem;
		opacity: 0rem;
	}

	& > img {
		width: 10rem;
		height: 15rem;
		@media (max-width: 1024px) {
			width: 10rem;
			padding-right: 2rem;
		}
	}

	@media (max-width: 1024px) {
		.accountBalance {
			padding: 2rem 0rem;
		}
		min-width: 270px;
	}

	.hiddenBalance {
		display: flex;
		align-items: center;
		color: var(--dark-gray);
		gap: 0.4rem;
	}
`;

export const ContentContainer = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 2rem;
	padding: 4rem;
	background-color: var(--white);
	border-radius: 1.6rem;

	@media (max-width: 1024px) {
		flex-direction: column;
		gap: 2rem;
		// align-items: center;
		h2 {
			text-align: center !important;
		}
	}
	@media (max-width: 650px) {
		padding: 1.6rem;
		gap: 1rem;
		overflow-x: hidden;
		padding: 1rem;
		button {
			padding: 0.7rem;
			border-radius: 2rem;
		}
	}
`;

export const BalanceContainer = styled.div`
	padding: 3rem 5rem;
	display: flex;
	flex-direction: column;
	border-radius: 1.6rem;
	row-gap: 2rem;
	background-color: var(--dark-gray2);
	margin-top: 2rem;
	& > div {
		display: flex;
		justify-content: space-between;
	}
	@media (max-width: 650px){
		padding: 1rem 2rem;
	}
`;

export const BalanceInfoContainer = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 1rem;
	.hiddenBalance {
		display: flex;
		align-items: center;
		gap: 0.4rem;
	}
	@media (max-width: 1024px) {
		// align-items: center;
	}
`;

export const Indicator = styled.div<{ primary: boolean }>`
	width: 1rem;
	height: 1rem;
	border-radius: 50%;
	margin-right: 1rem;
	background-color: ${(props) =>
		props.primary ? 'var(--primary-blue)' : 'var(--light-gray)'};
`;
export const Separator = styled.div`
	height: 6rem;
	width: 0.2rem;
	background-color: var(--light-gray);
`;

export const TableContainer = styled.div`
	.sortUp,
	.sortDown,
	.notSort {
		color: var(--primary-blue) !important;
	}
`;

export const TooltipContainer = styled.div`
	position: absolute;
	top: 30rem;
	z-index: 0.5;
	width: 20rem;
	background-color: var(--dark-gray);
	color: #fff;
	font-size: 1.2rem;
	text-align: center;
	border-radius: 0.6rem;
	padding: 1rem;
	pointer-events: none;
	font-weight: 400;

	&::after {
		content: '';
		position: absolute;
		top: 100%; /* At the bottom of the tooltip */
		left: 50%;
		margin-left: -5px;
		border-width: 5px;
		border-style: solid;
		border-color: var(--dark-gray) transparent transparent transparent;
	}
`;

export const ButtonsContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 3.2rem;
    @media (max-width: 650px) {
        justify-content: center;
        flex-direction: column;
        width: 100%;
        align-items: center;
    }
    gap: 1.6rem;
`;

export const ExpandRowBttn = styled.button`
	border-radius: 50px;
	display: flex;
`;

export const TrExpanded = styled.tr`
	background: white;
	border-radius: 1rem;
	display: flex;
	width: 70%;
	justify-content: space-between;
	& span {
		font-weight: 600;
	}
`;

export const TdExpanded = styled.td`
	padding: 1.4rem 1.8rem;
	overflow-wrap: anywhere;
	vertical-align: top;
`;

export const TrExpandedContainer = styled.td`
	display: flex;
	justify-content: center;
	padding: 1.3rem;
`;

export const DateFiltersContainer = styled.div`
	display: flex;
	height: 40px;
	justify-content: center;
	align-items: center;
	gap: 16px;

	@media (max-width: 950px) {
		height: fit-content;
		justify-content: center;
		flex-direction: column;
	}
	@media (max-width: 650px) {
		min-width: 0;
		margin: 0;
		padding: 0;
		width: 100%;
	}
`;

export const DateFilterFormatSelector = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 1rem;

	&:hover {
		cursor: pointer;
	}

	@media (max-width: 1024px) {
		width: fit-content;
	}
`;

export const DatesContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 1rem;
`;

export const MonthSelector = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	height: 4rem;
	border-radius: 4px;
	gap: 1rem;
	padding: 8px 0px 8px 0px;
	min-height: 4.8rem;

	background-color: pink /* #F0F3F8 */;

	position: relative;
	& > svg {
		position: absolute;
		width: 4rem;
		color: var(--primary-blue);
		top: 0.9rem;
		left: 0.6rem;
		right: 0;
		z-index: 2;
	}

	&::after {
		${/* // eslint-disable-next */ ''}
		content: url("data:image/svg+xml; utf8,${arrowIcon}");
		position: absolute;
		pointer-events: none;
		top: 1.5rem;
		right: 1rem;
		z-index: 3;
		filter: invert(0.3);
	}

	& input[type='month'] {
		width: 100%;
		position: relative;
		font-family: 'Poppins', sans-serif;
		padding-left: 5rem;
	}

	& input[type='month']::-webkit-calendar-picker-indicator {
		position: absolute;
		top: 1rem;
		left: 1rem;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		z-index: 4;
		filter: invert(0.5);
		opacity: 0;
	}

	& input[type='month']::-webkit-datetime-edit-month-field {
		text-transform: capitalize;
	}
`;

export const SelectInput = styled.select`
	${BaseInputStyle}
	padding-right: 3.5rem;
	padding-left: 0rem;
	border-right: 1.6rem solid transparent;
	font-weight: 400;
`;

export const SearchContainer = styled.div`
	height: 40px;
	max-width: 50%;
	flex: 1;
	display: flex;
	padding: 4px 8px;
	gap: 1.2rem;
	justify-content: center;
	align-items: center;
	border-radius: 4px;

	position: relative;

	& > svg {
		position: absolute;
		right: 1.8rem;
		top: 50%;
		width: 2rem;
		height: 2rem;
		color: var(--dark-gray);
		transform: translateY(-50%);
	}
	@media (max-width: 650px){
		min-width: 100%;
		padding: 0px;
	}
`;

export const SearchInput = styled.input`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	flex: 1 0 0;

	border-style: none;
	padding: 0.5rem 1rem;

	font-family: poppins;

	outline: none;
`;

export const TransactionFilterChipContainer = styled.div<{ isActive: boolean }>`
	padding: 0.4rem 1rem;
	border-radius: 2rem;
	border: 2px solid
		${({ isActive }) => (isActive ? 'var(--primary-blue)' : 'var(--dark-gray)')};
	width: max-content;
	background-color: ${({ isActive }) =>
		isActive ? 'var(--primary-blue)' : 'transparent'};
	color: ${({ isActive }) => (isActive ? 'var(--white)' : 'var(--dark-gray)')};
	font-size: 1.2rem;
	cursor: pointer;
`;

export const OperationTypeIcon = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #393939;
	width: 3.2rem;
	height: 3.2rem;
	border-radius: 50px;

	& > svg {
		width: 16px;
		height: 16px;
		color: #f0f3f8;
	}
`;

export const TypographyDescription = styled(Typography)`
	color: var(--mid-gray);
	font-size: 1rem;
	font-weight: 400;
	text-align: left;
	font-family: Poppins;
	@media (max-width: 650px){
		font-size: 1.4rem;
	}
`

