import { Benefit, BenefitsGroup } from '../../@types';
import {
	FilterParams,
	parseFilterParamsToParams,
} from '../../components/Filter';
import {
	parseSortColumnToParams,
	SortColumn,
} from '../../hooks/useSortColumnHook';
import api from '../api';
import { ManageAllPartnerCollaboratorsAction } from './Partners';

export interface BenefitsGroupsReturn {
	totalBenefitsGroups?: number;
	benefitsGroups?: BenefitsGroup[];
}

export interface createBenefitsGroupParams {
	title: string;
	description: string;
}

export interface UpdateBenefitParams {
	benefits: {
		id: string;
	}[];
	company_id: string;
	benefits_group_id: string;
}

export async function getBenefitsGroups(
	filtersParams: FilterParams[],
	page?: number,
	companyId?: string,
	sortColumn: SortColumn | null = null
): Promise<BenefitsGroupsReturn> {
	const { data } = await api.get(
		`api/v1/rh/companies/${companyId}/benefits-groups/q?page=${page}`,
		{
			params: {
				...parseFilterParamsToParams(filtersParams),
				...parseSortColumnToParams(sortColumn),
			},
		}
	);

	return data;
}

export async function createBenefitsGroup(
	title: string,
	description: string,
	companyId?: string
): Promise<BenefitsGroup> {
	const { data } = await api.post(
		`/api/v1/rh/companies/${companyId}/benefits-groups`,
		{
			title,
			description,
		}
	);

	return data;
}

export async function updateGroupsBenefits({
	company_id,
	benefits_group_id,
	benefits,
}: UpdateBenefitParams): Promise<Benefit[]> {
	const { data } = await api.patch(
		`api/v1/rh/companies/${company_id}/benefits-groups/${benefits_group_id}`,
		{ benefits }
	);
	return data;
}

export async function getBenefitsGroup(
	company_id: string,
	benefits_group_id: string
): Promise<BenefitsGroup> {
	const { data } = await api.get(
		`api/v1/rh/companies/${company_id}/benefits-groups/${benefits_group_id}`
	);
	return data;
}

export async function manageBenefitsGroupCollaborators(
	companyId: string,
	benefits_group_id: string,
	collaborators: string[]
): Promise<void> {
	return await api.put(
		`api/v1/rh/companies/${companyId}/benefits-groups/${benefits_group_id}/collaborators`,
		{
			collaborators,
		}
	);
}

export async function manageAllBenefitsGroupCollaborators(
	companyId: string,
	benefits_group_id: string,
	action: ManageAllPartnerCollaboratorsAction
): Promise<void> {
	return await api.put(
		`api/v1/rh/companies/${companyId}/benefits-groups/${benefits_group_id}/collaborators`,
		{
			all_collaborators: action,
		}
	);
}
