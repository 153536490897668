import { useMutation, useQuery } from 'react-query';
import {
	ApproveMultiflexTermsAndPrivacyPolicy,
	getMultiflexPrivacyPolicy,
	getMultiflexTerms,
} from '../../../services/queries/Terms';
import { showErrorMessage } from '../../../utils/ErrorHandler';
import * as S from './styles';
import Loader from '../../../components/Loader';
import LogoBounty from '../../../assets/BountyBlue.svg';
import React, { useState } from 'react';
import { useAuth } from '../../../hooks/useAuth';
import { useHistory } from 'react-router-dom';
import TermsAndPrivacy from '../../../componentsV2/ui/Form/TermsAndPrivacy';
import { CorporateExpensesTheme } from '../../../styles/CorporateExpensesTheme';

export function TermsMultiflex() {
	const [isFirstStep, setIsFirstStep] = useState(true);
	const { user, updateUserInfo } = useAuth();
	const { push } = useHistory();

	const termsQuery = useQuery<string, Error>(
		['multiflexTerm'],
		() => {
			return getMultiflexTerms();
		},
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível buscar os termos de uso. '
				);
			},
			refetchOnWindowFocus: false,
		}
	);

	const privacyQuery = useQuery(
		['privacyPolicyMultiflex'],
		() => {
			return getMultiflexPrivacyPolicy();
		},
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível buscar os avisos de privacidade. '
				);
			},
			refetchOnWindowFocus: false,
		}
	);

	const approvedMutation = useMutation(ApproveMultiflexTermsAndPrivacyPolicy, {
		onSuccess() {
			const updateUser = {
				privacy_policy_accepted_multiflex: true,
				term_of_use_accepted_multiflex: true,
			};
			updateUserInfo({ ...user, ...updateUser });
			push('/home/');
		},
	});

	if (termsQuery.isLoading || privacyQuery.isLoading) {
		return (
			<S.Container>
				<S.LogoImage
					className='responsiveImage'
					src={LogoBounty}
					alt='Logo do Bounty'
				/>
				<S.TermsContainer>
					<S.TextTitle>
						{termsQuery.isLoading ? 'Termos de uso' : 'Avisos de privacidade'}
					</S.TextTitle>
				</S.TermsContainer>
				<div style={{ marginTop: '3rem' }} />
				<Loader color='white' />
			</S.Container>
		);
	}

	return (
		<>
			<S.Container>
				<CorporateExpensesTheme />

				<S.LogoImage
					className='responsiveImage'
					src={LogoBounty}
					alt='Logo do Bounty'
				/>
				<TermsAndPrivacy
					isLoading={isFirstStep ? privacyQuery.isLoading : termsQuery.isLoading}
					isTermsOfUse={isFirstStep}
					logo={LogoBounty}
					onNext={() => {
						if(isFirstStep){
							setIsFirstStep(false)
							return
						}

						approvedMutation.mutate();
					}}
					content={isFirstStep ? (termsQuery.data ?? "") : (privacyQuery.data ?? "") }
				/>
			</S.Container>
		</>
	);
}
