export enum DialogModalActions {
	RESET = 'RESET',
	SET_STATE = 'SET_STATE',
}

interface DialogModalState {
	isOpen: boolean;
	message: string;
	mainOptMessage: string;
	secondOptMessage: string;
	enableClose: boolean;
}

interface DialogModalAction {
	type: DialogModalActions;
	payload?: Partial<DialogModalState>;
}

export const initialState: DialogModalState = {
	isOpen: false,
	message: '',
	mainOptMessage: '',
	secondOptMessage: '',
	enableClose: true,
};

export function DialogModalReducer(
	state: DialogModalState,
	action: DialogModalAction
) {
	const { type, payload } = action;

	switch (type) {
		case DialogModalActions.SET_STATE:
			return {
				...state,
				...payload,
			};
		case DialogModalActions.RESET:
			return initialState;
		default:
			return state;
	}
}
