import Loader from '../../../../components/Loader';
import { useQuery } from 'react-query';
import { FilterParams } from '../../../../components/Filter';
import {
	generateTransfersReport,
	generateTransfersReportExcel,
	TransferToReportReturn,
} from '../../../../services/queries/Tranfers';
import { toast } from 'react-toastify';
import { ReportButton } from '../../../../components/PDFReportStyles';
import { SortColumn } from '../../../../hooks/useSortColumnHook';
import Modal from '../../../../components/Modal';
import { useState } from 'react';
import * as S from './styles';
import {
	CheckBox,
	CheckBoxLabel,
	CheckBoxWrapper,
} from '../../../../components/ToggleSwitch/styles';
import { SelectField } from '../../../../componentsV2/ui/Form/SelectField';

interface ReportProps {
	company_id: string;
	filterParams: FilterParams[];
	currentSortColumn: SortColumn | null;
}

export const customFields = {
	section: 'Setor do colaborador',
	office: 'Cargo',
	branch_office: 'Filial',
	cpf: 'CPF',
	cost_center: 'Centro de custo',
	contract_type: 'Tipo de vínculo',
};

const defaultFields = {
	section: false,
	office: false,
	branch_office: false,
	cpf: false,
	cost_center: false,
	contract_type: false,
};

export function ReportGenerator({
	company_id,
	filterParams,
	currentSortColumn,
}: ReportProps) {
	const [isOpen, setIsOpen] = useState(false);

	const [fields, setFields] = useState(defaultFields);
	const [reportFormat, setReportFormat] = useState('pdf');

	const generateTransfersReportQuery = useQuery<TransferToReportReturn>(
		[
			'transfersListToReport',
			company_id,
			filterParams,
			currentSortColumn,
			fields,
		],
		() => {
			return generateTransfersReport(
				filterParams,
				company_id,
				currentSortColumn,
				fields
			);
		},
		{
			onSuccess: () => {
				toast.info(
					'Relatório em processamento. Em instantes, você o receberá por email.'
				);
			},
			onError: () => {
				toast.error(
					'Ocorreu um erro ao tentar gerar o arquivo de relatório. Tente novamente'
				);
			},
			enabled: false,
			refetchOnWindowFocus: false,
			refetchOnReconnect: false,
		}
	);

	const generateTransfersReportExcelQuery = useQuery<TransferToReportReturn>(
		[
			'transfersListToReportExcel',
			company_id,
			filterParams,
			currentSortColumn,
			fields,
		],
		() => {
			return generateTransfersReportExcel(
				filterParams,
				company_id,
				currentSortColumn,
				fields
			);
		},
		{
			onSuccess: () => {
				toast.info(
					'Relatório em processamento. Em instantes, você o receberá por email.'
				);
			},
			onError: () => {
				toast.error(
					'Ocorreu um erro ao tentar gerar o arquivo de relatório. Tente novamente'
				);
			},
			enabled: false,
			refetchOnWindowFocus: false,
			refetchOnReconnect: false,
		}
	);

	async function handleGeneratePDF() {
		await generateTransfersReportQuery.refetch();

		closeModal();
	}

	async function handleGenerateXLSX() {
		await generateTransfersReportExcelQuery.refetch();

		closeModal();
	}

	function closeModal() {
		setIsOpen(false);
		setFields(defaultFields);
		setReportFormat('pdf');
	}

	function handleFormatSelection(format: string) {
		if (format === 'pdf') {
			setFields(defaultFields);
		}

		setReportFormat(format);
	}

	return (
		<>
			<ReportButton onClick={() => setIsOpen(true)}>
				Gerar relatório
			</ReportButton>

			<Modal isOpen={isOpen} onRequestClose={closeModal} enableClose>
				<S.Container>
					<S.Title>Relatório de transferências</S.Title>
					<S.Content>
						<S.FormatSelectorContainer>
							<S.FormatSelectorMessage>
								Por favor, selecione as configurações de exportação. Escolha o
								tipo de arquivo e os campos adicionais apropriados, lembrando
								que, para o formato PDF, apenas dois campos adicionais são
								permitidos:
							</S.FormatSelectorMessage>

							<SelectField
								label='Formato:'
								onChange={(e) => handleFormatSelection(e.target.value)}
								defaultValue={reportFormat}
								required
							>
								<option key={'pdf'} value={'pdf'}>
									PDF
								</option>
								<option key={'xlsx'} value={'xlsx'}>
									XLSX
								</option>
							</SelectField>
						</S.FormatSelectorContainer>

						<S.ListContainer>
							{Object.entries(customFields).map(([field, value]) => {
								return (
									<S.InfoContainer key={`${field}:${value}`}>
										<S.Label>{value}</S.Label>
										<CheckBoxWrapper>
											<CheckBox
												id={field}
												type='checkbox'
												onChange={(e) => {
													const isPdfSelected = reportFormat === 'pdf';
													const hasLessThanTwoCheckedFields =
														Object.values(fields).filter((value) => value)
															.length < 2;
													const fieldAlreadyChecked =
														fields[field as keyof typeof customFields];

													if (
														!isPdfSelected ||
														hasLessThanTwoCheckedFields ||
														fieldAlreadyChecked
													) {
														setFields({ ...fields, [field]: e.target.checked });
													}
												}}
												checked={fields[field as keyof typeof customFields]}
											/>
											<CheckBoxLabel htmlFor={field} />
										</CheckBoxWrapper>
									</S.InfoContainer>
								);
							})}
						</S.ListContainer>
					</S.Content>

					<S.BtnContainer>
						<ReportButton
							onClick={
								reportFormat === 'pdf' ? handleGeneratePDF : handleGenerateXLSX
							}
							style={{ width: '17rem' }}
						>
							{generateTransfersReportQuery.isLoading ||
							generateTransfersReportExcelQuery.isLoading ? (
								<Loader color='#fff' size={16} />
							) : (
								'Gerar relatório'
							)}
						</ReportButton>
					</S.BtnContainer>
				</S.Container>
			</Modal>
		</>
	);
}
