import styled from 'styled-components';

export const TableContainer = styled.div`
	max-height: 50rem;
	overflow: auto;
	width: 100%;
	padding-right: 0.1rem;
	&::-webkit-scrollbar {
		width: 1rem;
		height: 1rem;
	}

	&::-webkit-scrollbar-track {
		background-color: #e9eaeb;
		border-radius: 0.6rem;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #258bdb;
		border-radius: 0.6rem;
	}
`;

export const TableComp = styled.table`
	width: 100%;
	font-size: 1.4rem;
	border-collapse: collapse;
`;

export const TableHeader = styled.thead`
	position: sticky;
	top: 0;
`;

export const TH = styled.th`
	background: #F0F3F8;
	text-align: start;
	font-weight: 600;
	padding: 2.4rem;
		
	&:nth-child(1) {
			border-radius: 0.8rem 0 0 0.8rem;
	}
	&:nth-last-child(1) {
			border-radius: 0 0.8rem 0.8rem 0 ;
	}

	& > div {
		display: flex;
		align-items: center;
		column-gap: 1rem;
	}

	&:hover button {
		visibility: visible;
	}
`;

export const TD = styled.td`
	color: #575757;
	padding: 1.4rem 2.4rem;

	& > div {
		display: flex;
		justify-content: flex-end;
	}
`;

export const TR = styled.tr`
	& ~ & {
		border-top: 0.1rem solid #E5EAF2;
	}

	&:last-child {
		border-bottom: 0.1rem solid #E5EAF2;
	}
`;

export const NoContentContainer = styled.div`
	display: flex;
	height: 40rem;
		width: 100%;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	gap: 4rem;
`;
