import * as S from './styles'
import {FaInfoCircle} from 'react-icons/fa'

interface InfoCardProps {
  message: string;
}

export default function InfoCard({ message }: InfoCardProps) {
  return (
    <S.Container>
      <S.IconContainer>
        <FaInfoCircle color="#2DB9C5" />
      </S.IconContainer>
      <S.Title>{message}</S.Title>
    </S.Container>
  );
}
