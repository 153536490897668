import { useEffect, useState } from 'react';
import styled, { CSSProperties } from 'styled-components';
import { EmptyContent } from './EmptyContent';
import { SectionContainer } from './ui/SectionContainer';

interface Props {
	tabs: {
		name: string;
		component: React.ReactNode;
	}[];
	onChange?: (tab: string) => void;
	style?: CSSProperties;
	selectedTab?: string; // controlled by parent
	emptyContent?: boolean;
}

export function Tabs({
	tabs,
	onChange,
	selectedTab,
	emptyContent,
	style,
}: Props) {
	const [activeTab, setActiveTab] = useState(selectedTab ?? tabs[0].name);

	useEffect(() => {
		if (selectedTab) setActiveTab(selectedTab);
	}, [selectedTab]);

	return (
		<TabContainer>
			<SectionContainer style={style}>
				<TabHeader>
					{tabs.map((tab) => (
						<Tab
							isActive={tab.name === activeTab}
							key={tab.name}
							onClick={() => {
								setActiveTab(tab.name);
								onChange && onChange(tab.name);
							}}
						>
							{tab.name}
						</Tab>
					))}
				</TabHeader>

				<TabContent>
					{tabs.find((t) => t.name === activeTab)?.component}
					{emptyContent && <EmptyContent />}
				</TabContent>
			</SectionContainer>
		</TabContainer>
	);
}

const TabContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

const TabHeader = styled.ul`
	display: flex;
	width: 100%;
	justify-content: center;
	column-gap: 4rem;
	border-bottom: 1px solid #d4d4d4;
	margin-bottom: 1rem;
`;

const Tab = styled.button<{ isActive: boolean }>`
	padding: 1rem 0;
	width: 16rem;
	background-color: var(--white);
	white-space: nowrap;
	border-bottom: ${(props) =>
		props.isActive ? '.2rem solid var(--primary-blue)' : 'none'};
	color: ${(props) =>
		props.isActive ? 'var(--primary-blue)' : 'var(--light-gray2)'};
	font-size: 1.4rem;
	font-weight: ${(props) => (props.isActive ? '600' : 'normal')};
	border-radius: 0.4rem 0.4rem 0 0;
`;

const TabContent = styled.div``;
