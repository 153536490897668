import { useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import Loader from '../../../components/Loader';
import PageTitle from '../../../components/PageTitle';
import { useAuth } from '../../../hooks/useAuth';
import { useHistoryNonMatchCallback } from '../../../hooks/useHistoryNonMatchCallback';
import { useSortColumnHook } from '../../../hooks/useSortColumnHook';
import {
	BenefitsGroupsReturn,
	getBenefitsGroups,
} from '../../../services/queries/BenefitsGroups';
import { useBenefitGroupsListStore } from '../../../stores/useBenefitGroupsListStore';
import { showErrorMessage } from '../../../utils/ErrorHandler';
import * as TableStyle from '../../../components/Table/TableStyles';
import * as S from './styles';
import { Filter } from '../../../components/Filter';
import { EmptyContent } from '../../../components/EmptyContent';
import { Pagination } from '../../../components/Pagination';
import { SortColumnButton } from '../../../components/SortColumnButton';
import { BenefitGroupCreationModal } from './BenefitGroupCreationModal';
import { ManageGroupBenefitsModal } from './ManageGroupBenefitsModal';
import { BsPeopleFill } from 'react-icons/bs';

export function BenefitGroups() {
	const { currentCompany } = useAuth();
	const queryClient = useQueryClient();

	const [benefitsgroupsCurrentPage, setBenefitsgroupsCurrentPage] = useState(1);
	const { currentSortColumn, toggleSort } = useSortColumnHook();
	const [filtersParams, setFiltersParams, resetFilters] =
		useBenefitGroupsListStore((state) => [
			state.filtersParams,
			state.setFiltersParams,
			state.resetFilters,
		]);

	useHistoryNonMatchCallback('benefits-groups', resetFilters);

	const getBenefitsGroupsQuery = useQuery<BenefitsGroupsReturn, Error>(
		[
			'benefitsGroupsList',
			currentCompany?.id,
			benefitsgroupsCurrentPage,
			currentSortColumn,
			filtersParams,
		],
		() => {
			return getBenefitsGroups(
				filtersParams,
				benefitsgroupsCurrentPage,
				currentCompany?.id,
				currentSortColumn
			);
		},
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível buscar os grupos de benefícios. '
				);
			},
			keepPreviousData: true,
		}
	);

	function getTableTotalRecords() {
		return getBenefitsGroupsQuery.data?.totalBenefitsGroups;
	}

	function refreshPage() {
		setBenefitsgroupsCurrentPage(1);
		queryClient.resetQueries('benefitsGroupsList');
	}

	if (
		getBenefitsGroupsQuery.isLoading ||
		getBenefitsGroupsQuery.isPreviousData
	) {
		return (
			<S.Container>
				<PageTitle title='Remover benefícios' />
				<Loader />
			</S.Container>
		);
	}

	if (!getBenefitsGroupsQuery.data?.benefitsGroups?.length) {
		return (
			<S.Container>
				<TableStyle.TableHeaderContainer>
					<TableStyle.TitleWrapper>
						<PageTitle title='Remover benefícios' />
						<Filter
							filterParams={filtersParams}
							onFiltersChanged={(updatedFilters) => {
								setBenefitsgroupsCurrentPage(1);
								setFiltersParams(updatedFilters);
							}}
						/>
						<TableStyle.ReloadIcon type='reload' onClick={refreshPage} />
					</TableStyle.TitleWrapper>
				</TableStyle.TableHeaderContainer>
				<EmptyContent text='Nenhum grupo de benefícios para ser exibido.' />
				<BenefitGroupCreationModal />
			</S.Container>
		);
	}

	return (
		<S.Container>
			<TableStyle.TableHeaderContainer>
				<TableStyle.TitleWrapper>
					<PageTitle
						title='Remover benefícios'
						totalRecords={getTableTotalRecords() ?? 0}
					/>
					<Filter
						filterParams={filtersParams}
						onFiltersChanged={(updatedFilters) => {
							setBenefitsgroupsCurrentPage(1);
							setFiltersParams(updatedFilters);
						}}
					/>
				</TableStyle.TitleWrapper>

				<Pagination
					onPageChange={(page) => setBenefitsgroupsCurrentPage(page)}
					currentPage={benefitsgroupsCurrentPage}
					totalCount={getTableTotalRecords() ?? 0}
				/>
				<TableStyle.ReloadIcon type='reload' onClick={refreshPage} />
			</TableStyle.TableHeaderContainer>
			<TableStyle.Table>
				<TableStyle.TableHeader>
					<TableStyle.TableRow>
						<TableStyle.TableHeaderCell
							style={{ width: '28rem', minWidth: '20rem' }}
						>
							<SortColumnButton
								order={
									currentSortColumn?.name === 'title'
										? currentSortColumn.order
										: null
								}
								onToggleSort={() => toggleSort('title')}
							/>
							TÍTULO
						</TableStyle.TableHeaderCell>
						<TableStyle.TableHeaderCell
							style={{ width: '28rem', minWidth: '20rem', overflow: 'auto' }}
						>
							<SortColumnButton
								order={
									currentSortColumn?.name === 'total_users'
										? currentSortColumn.order
										: null
								}
								onToggleSort={() => toggleSort('total_users')}
							/>
							NÚMERO DE COLABORADORES
						</TableStyle.TableHeaderCell>
						<TableStyle.TableHeaderCell>
							<SortColumnButton
								order={
									currentSortColumn?.name === 'description'
										? currentSortColumn.order
										: null
								}
								onToggleSort={() => toggleSort('description')}
							/>
							DESCRIÇÃO
						</TableStyle.TableHeaderCell>
						<TableStyle.TableHeaderCell>OPÇÕES</TableStyle.TableHeaderCell>
					</TableStyle.TableRow>
				</TableStyle.TableHeader>

				<TableStyle.TableBody>
					{getBenefitsGroupsQuery.data.benefitsGroups.map((benefitsGroup) => (
						<TableStyle.TableRow key={benefitsGroup.id}>
							<TableStyle.TableData style={{ maxWidth: '24rem' }}>
								{benefitsGroup.title}
							</TableStyle.TableData>
							<TableStyle.TableData>
								{benefitsGroup.total_users ? benefitsGroup.total_users : 0}
							</TableStyle.TableData>
							<TableStyle.TableData>
								{benefitsGroup.description}
							</TableStyle.TableData>
							<TableStyle.TableData>
								<S.OptionsContainer>
									<ManageGroupBenefitsModal benefitsGroup={benefitsGroup} />

									<TableStyle.OptionLink
										data-rh='Colaboradores'
										to={`remove-benefit/${benefitsGroup.id}`}
									>
										<BsPeopleFill />
									</TableStyle.OptionLink>
								</S.OptionsContainer>
							</TableStyle.TableData>
						</TableStyle.TableRow>
					))}
				</TableStyle.TableBody>
			</TableStyle.Table>

			<BenefitGroupCreationModal />
		</S.Container>
	);
}
