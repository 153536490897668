import { create } from 'zustand';
import { FilterParams } from '../components/Filter';
import {
	TransferCategoryOptionsFilters,
	TransferTypeOptionsFilters,
} from '../components/Filter/utils/SelectFilters';

type Store = {
	filtersParams: FilterParams[];
	setFiltersParams: (f: FilterParams[]) => void;
	resetFilters: () => void;
};

const filtersParams: FilterParams[] = [
	{
		name: 'Tipo',
		filter: 'type',
		value: '',
		type: 'select',
		selectOptions: TransferTypeOptionsFilters,
		selected: false,
	},
	{
		name: 'Categoria',
		filter: 'category',
		value: '',
		type: 'select',
		selectOptions: TransferCategoryOptionsFilters,
		selected: false,
	},
	{
		name: 'Data',
		filter: 'date',
		value: '',
		type: 'date_interval',
		selected: false,
	},
	{
		name: 'Nome',
		filter: 'name',
		value: '',
		type: 'text',
		selected: false,
	},
	{
		name: 'Email',
		filter: 'email',
		value: '',
		type: 'email',
		selected: false,
	},
	{
		name: 'CPF',
		filter: 'cpf',
		value: '',
		type: 'cpf',
		selected: false,
	},
	{
		name: 'Centro de Custo',
		filter: 'cost_center',
		value: '',
		type: 'cost_center',
		selected: false,
	},
	{
		name: 'ID do pagamento',
		filter: 'order_id',
		value: '',
		type: 'text',
		selected: false,
	},
];

const useTransfersListStore = create<Store>()((set) => ({
	filtersParams,
	setFiltersParams: (f) => set({ filtersParams: f }),
	resetFilters: () => set({ filtersParams }),
}));

export { useTransfersListStore };
