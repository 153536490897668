import { useMutation, useQuery } from 'react-query';
import {
	ApproveCorpwayTermsAndPrivacyPolicy,
	getCorpwayPrivacyPolicy,
	getCorpwayTerms,
} from '../../services/queries/Terms';
import * as S from './styles';
import LogoControl from '../../assets/BountyControl.svg';
import LogoControlWhite from '../../assets/BountyControlWhite.svg';
import Loader from '../../components/Loader';
import { showErrorMessage } from '../../utils/ErrorHandler';
import { CorporateExpensesTheme } from '../../styles/CorporateExpensesTheme';
import { CheckboxField } from '../../componentsV2/ui/Form/CheckboxField';
import { Button } from '../../componentsV2/ui/Button';
import React, { useState } from 'react';
import { useAuth } from '../../hooks/useAuth';
import { useHistory } from 'react-router-dom';


const TermsPart = (
	{ content, isFirstStep, isLoading, onNext }: { isFirstStep: boolean, isLoading: boolean, content: string, onNext: () => void }
) => {
	const [termsAccept, setTermsAccept] = useState(false);

	return (
		<S.TextsContainer>
			<S.InformationContainer>
				<S.TextTitle>{
					isFirstStep ?
						"Termos de uso"
						:
						"Avisos de privacidade"
				}</S.TextTitle>
				<S.LogoImage
					className="desktopImage"
					src={LogoControl}
					alt="Logo do Bounty"
				/>
			</S.InformationContainer>
			<S.TermsContainer
				dangerouslySetInnerHTML={{
					__html: content,
				}}
			/>
			<S.AcceptContainer>
				<S.TextAccept
					{...(!termsAccept && {
						'data-rh':
						isFirstStep ?
							'Por favor, leia nossos termos de uso antes de continuar'
						: 'Por favor, leia nossos avisos de privacidade antes de continuar',
					})}
				>
					<CheckboxField
						labelWidth="100%"
						label={
							<S.LabelText>
								<span> Eu li e estou ciente </span>
								{
									isFirstStep ?
										"do teor destes Termos de Uso"
										:
										"do teor deste aviso de privacidade"
								}
							</S.LabelText>
						}
						checked={termsAccept}
						name="termsPolicy"
						onChange={ () => setTermsAccept(!termsAccept)}
						disabled={isLoading}
					/>
					<p>
						Ao clicar na caixa{' '}
						<span>{
							isFirstStep ?
								"“Eu li e estou ciente do teor destes Termos de Uso”"
								:
								"“Eu li e estou ciente do teor deste Aviso de privacidade”"
						}</span>,
						você concorda e aceita expressa e integralmente as condições aqui
						descritas.
					</p>
				</S.TextAccept>

				<Button
					roundness="lg"
					width="adjusted"
					type="button"
					disabled={!termsAccept}
					intent="primary"
					style={{ width: '20rem', height: '7rem' }}
					onClick={() => {
						if (termsAccept) {
							onNext();
							setTermsAccept(false);
						}
					}}
				>
					Avançar
				</Button>
			</S.AcceptContainer>
		</S.TextsContainer>
	);
};

export function TermsCorpway() {
	const [isFirstStep, setIsFirstStep] = useState(true);
	const { user, updateUserInfo } = useAuth();
	const { push } = useHistory();

	const termsQuery = useQuery<string, Error>(
		['corpwayTerm'],
		() => {
			return getCorpwayTerms();
		},
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível buscar os termos de uso. ',
				);
			},
			refetchOnWindowFocus: false,
			refetchOnMount: false,
		},
	);

	const privacyQuery = useQuery(
		['privacyPolicyCorpway'],
		() => {
			return getCorpwayPrivacyPolicy();
		},
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível buscar os avisos de privacidade. ',
				);
			},
			refetchOnWindowFocus: false,
			refetchOnMount: false,
		},
	);

	const approvedMutation = useMutation(ApproveCorpwayTermsAndPrivacyPolicy, {
		onSuccess() {
			const updateUser = {
				privacy_policy_accepted_corpway: true,
				term_of_use_accepted_corpway: true,
			};
			updateUserInfo({ ...user, ...updateUser });
			push('/corporate-expenses/');
		},
	});

	if (termsQuery.isLoading || privacyQuery.isLoading) {
		return (
			<S.Container>
				<CorporateExpensesTheme />
				<S.LogoImage
					className="responsiveImage"
					src={LogoControlWhite}
					alt="Logo do Bounty"
				/>
				<S.TermsContainer>
					<S.TextTitle>
						{termsQuery.isLoading ? 'Termos de uso' : 'Avisos de privacidade'}
					</S.TextTitle>
				</S.TermsContainer>
				<div style={{ marginTop: '3rem' }} />
				<Loader color="white" />
			</S.Container>
		);
	}

	return (
		<>
			<S.Container>
				<CorporateExpensesTheme />
				<S.LogoImage
					className="responsiveImage"
					src={LogoControlWhite}
					alt="Logo do Bounty"
				/>
				<TermsPart
					isLoading={isFirstStep ? privacyQuery.isLoading : termsQuery.isLoading}
					isFirstStep={isFirstStep}
					onNext={() => {
						if(isFirstStep){
							setIsFirstStep(false)
							return
						}

						approvedMutation.mutate();
					}}
 					content={isFirstStep ? (termsQuery.data ?? "") : (privacyQuery.data ?? "") }
				/>
			</S.Container>
		</>
	);
}
