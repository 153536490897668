import styled from 'styled-components';
import { OptionsButton } from '../../../../components/Button';

export const AddGroup = styled.button`
	${OptionsButton}
`;

export const CancelOption = styled.button`
	${OptionsButton}
	color: var(--primary-blue);
	background-color: var(--white);
`;
