import styled from 'styled-components';
import { OptionsButton } from '../Button';

export const MainButton = styled.button`
	${OptionsButton}
	margin: 0 auto;
`;

// MODAL STYLES
interface CollaboratorSelectionProps {
	selected: boolean;
	remove?: boolean;
}

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	max-width: 60rem;
	width: 70vw;
	padding-right: 1rem;
	min-height: 20rem;
`;

export const CollaboratorsList = styled.ul`
	display: flex;
	flex-direction: column;
	max-height: 50vh;
	overflow-y: auto;
	margin: 2rem 0;
`;

export const CollaboratorContainer = styled.li`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 1rem;
	padding-bottom: 1rem;
	border-bottom: 0.1rem solid var(--primary-blue);
`;

export const CollaboratorCard = styled.div`
	display: flex;
	align-items: center;
`;

export const CollaboratorAvatar = styled.img`
	border: 0.2rem solid var(--primary-blue);
	border-radius: 50%;
	height: 5rem;
	width: 5rem;
	margin-right: 1rem;
	object-fit: cover;
`;

export const CollaboratorInfoContainer = styled.div`
	display: flex;
	flex-direction: column;
	max-width: 50rem;
`;

export const CollaboratorName = styled.span`
	color: var(--primary-blue);
	font-size: 1.6rem;
	max-width: 100%;
	word-break: break-word;
`;

export const CollaboratorInfo = styled.span`
	font-size: 1.4rem;
	color: var(--mid-gray);
`;

export const Selector = styled.button<CollaboratorSelectionProps>`
	width: 3.5rem;
	height: 3.5rem;
	background: none;
	& svg {
		width: 100%;
		height: 100%;
		border-radius: 50%;
		color: ${({ selected, remove }) =>
			selected
				? 'var(--light-gray)'
				: remove
				? 'var(--primary-red)'
				: 'var(--primary-blue)'};
	}
	&:disabled {
		opacity: 50%;
		cursor: not-allowed;
	}
`;

export const ErrorLine = styled.span`
	text-align: center;
	font-size: 1.4rem;
	color: var(--red);
`;
