import { Button } from '../../../../../../componentsV2/ui/Button';
import React, { useEffect, useState } from 'react';
import { MdCheck, MdLock, MdPerson } from 'react-icons/md';
import {
	CorpwayUserAccessLevel,
	EditUserDetails,
	UserDetails,
} from '../../../../../../@types/CorporateExpenses/User';
import EditUserForm from './EditUserForm';
import InformationModal from '../../../../../../componentsV2/ui/Modal/InformationModal';
import { CostCenter } from '../../../../../../@types/CorporateExpenses/CostCenter';
import { useMutation, useQueryClient } from 'react-query';
import {
	CORPWAY_USERS_KEY,
	editUser,
} from '../../../../../../services/queries/Corpway/User';
import { showErrorMessage } from '../../../../../../utils/ErrorHandler';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import WarningAccessChanged from './WarningAccessChanged';
import PasswordValidation from './PasswordValidation';
import styled from 'styled-components';

const schema = z
	.object({
		name: z.string().min(1, { message: 'Nome deve ser preenchido' }),
		email: z
			.string()
			.min(1, { message: 'Email deve ser preenchido' })
			.email({ message: 'Email inválido' }),
		confirm_email: z
			.string()
			.min(1, { message: 'Confirmar email deve ser preenchido' }),
	})
	.refine(({ email, confirm_email }) => email === confirm_email, {
		message: 'Os campos de email e confirmar email devem ser iguais',
		path: ['confirm_email'],
	})
	.optional();

const EditButton = styled(Button)`
	@media (max-width: 1024px) {
		width: 100%;
	}
`;

export default function EditModal({ user }: { user: UserDetails }) {
	const { id } = useParams<{ id: string }>();
	const queryClient = useQueryClient();
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [formStep, setFormStep] = useState<1 | 2 | 3 | 4>(1);
	const [selectedCostCenters, setSelectedCostCenters] = useState<CostCenter[]>(
		[]
	);

	const userDetailsForm = useForm<EditUserDetails>({
		resolver: zodResolver(schema),
	});

	useEffect(() => {
		setSelectedCostCenters(user.corpway_cost_centers);

		userDetailsForm.reset({
			name: user.name,
			email: user.email,
			confirm_email: user.email,
			access_level: user.role as CorpwayUserAccessLevel,
		});
	}, [user]); // eslint-disable-line react-hooks/exhaustive-deps

	function handleEditUser() {
		const formData = userDetailsForm.getValues();
		const data = new FormData();

		data.append('name', formData.name);
		data.append('email', formData.email);

		if (formData.email !== user?.email) {
			data.append('confirm_email', formData.confirm_email);
		}

		if (formData.access_level !== user?.role) {
			data.append('access_level', formData.access_level);
		}

		if (selectedCostCenters.length) {
			let access = formData.access_level as CorpwayUserAccessLevel;
			// Only append cost center if necessary
			if (access !== 'general_admin' && access !== 'finance') {
				data.append(
					'cost_center_ids',
					selectedCostCenters.map((c) => c.id).toString()
				);
			}
		}
		editUserMutation.mutate(data);
	}

	const editUserMutation = useMutation((data: FormData) => editUser(data, id), {
		onSuccess: () => {
			queryClient.invalidateQueries([CORPWAY_USERS_KEY]);
			setFormStep(4);
		},
		onError: (err) => {
			showErrorMessage(
				err as Error,
				'Não foi possível editar as informações do usuário do usuário. '
			);
		},
	});

	function onRequestClose() {
		setIsModalVisible(false);
		setFormStep(1);
		userDetailsForm.reset({
			name: user.name,
			email: user.email,
			confirm_email: user.email,
			access_level: user.role as CorpwayUserAccessLevel,
		});
		setSelectedCostCenters(user.corpway_cost_centers);
	}

	const FormContent = () => {
		const Parts = {
			1: (
				<EditUserForm
					user={user}
					selectedCostCenters={selectedCostCenters}
					setSelectedCostCenters={setSelectedCostCenters}
					userDetailsForm={userDetailsForm}
					setFormStep={setFormStep}
					hideModal={onRequestClose}
				/>
			),
			2: (
				<WarningAccessChanged
					user={user}
					setFormStep={setFormStep}
					watch={userDetailsForm.watch}
				/>
			),
			3: (
				<PasswordValidation
					watch={userDetailsForm.watch}
					setFormStep={setFormStep}
					isLoading={editUserMutation.isLoading}
					user={user}
					onNext={handleEditUser}
				/>
			),
			4: (
				<div
					style={{
						width: '40rem',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						margin: '2.5rem 0',
					}}
				>
					<Button
						intent='terciary'
						roundness='lg'
						$outline
						onClick={onRequestClose}
					>
						Fechar
					</Button>
				</div>
			),
		};

		return Parts[formStep];
	};

	const messages = {
		1: [{ text: 'Alterar ', isBold: true }, { text: 'dados do usuário' }],
		2: [{ text: 'Alteração de ' }, { text: 'acesso:', isBold: true }],
		3: [{ text: 'Alterar ', isBold: true }, { text: 'dados do usuário' }],
		4: [{ text: 'Usuário ' }, { text: 'alterado com sucesso.', isBold: true }],
	};

	const icons = {
		1: <MdPerson size={30} color={'white'} />,
		2: <MdPerson size={30} color={'white'} />,
		3: <MdLock size={30} color={'white'} />,
		4: <MdCheck size={30} color={'white'} />,
	};

	return (
		<>
			<EditButton
				type='submit'
				width='adjusted'
				roundness='lg'
				onClick={() => setIsModalVisible(true)}
			>
				Editar informações
			</EditButton>

			<InformationModal
				titleWidth={'85%'}
				width={'auto'}
				title={messages[formStep]}
				titleIcon={icons[formStep]}
				isOpen={isModalVisible}
				color={formStep === 2 ? 'secondary' : 'primary'}
				onRequestClose={onRequestClose}
			>
				<FormContent />
			</InformationModal>
		</>
	);
}
