import { CostCenter } from '../../@types';
import {
	FilterParams,
	parseFilterParamsToParams,
} from '../../components/Filter';
import {
	SortColumn,
	parseSortColumnToParams,
} from '../../hooks/useSortColumnHook';
import api from '../api';

export interface CostCentersListReturn {
	costCenters: CostCenter[];
	costCentersTotalCount: number;
}

export interface CostCenterCreationParams {
	title: string;
	description: string;
}

export interface CostCentersFilterReturn {
	totalCostCenters?: number;
	costCenters?: CostCenter[];
}

export async function getCostCenters(
	pageNumber?: number,
	companyId?: string,
	sortColumn: SortColumn | null = null
): Promise<CostCentersListReturn> {
	const { data } = await api.get(
		`/api/v1/rh/companies/${companyId}/cost-center/q?page=${pageNumber}`,
		{
			params: {
				...parseSortColumnToParams(sortColumn),
			},
		}
	);
	const costCenters = data.costCenters;
	const costCentersTotalCount = data.totalCostCenters;

	return { costCenters, costCentersTotalCount };
}

export async function getCostCentersSelect(
	companyId?: string
): Promise<CostCenter[]> {
	const { data } = await api.get(
		`/api/v1/rh/companies/${companyId}/cost-center/select`
	);
	return data;
}

export async function getFilteredCostCenters(
	filtersParams: FilterParams[],
	companyId?: string,
	page?: number,
	sortColumn: SortColumn | null = null
): Promise<CostCentersFilterReturn> {
	const { data } = await api.get(
		`/api/v1/rh/companies/${companyId}/cost-center/filter/q?page=${page}`,
		{
			params: {
				...parseFilterParamsToParams(filtersParams),
				...parseSortColumnToParams(sortColumn),
			},
		}
	);

	return data;
}

export async function createCostCenter(
	title: string,
	description: string,
	companyId?: string
): Promise<CostCenter[]> {
	const { data } = await api.post(
		`/api/v1/rh/companies/${companyId}/cost-center`,
		{
			title,
			description,
		}
	);

	return data;
}

export async function fetchCostCenterQuery(
	costCenterId: string,
	companyId?: string
): Promise<CostCenter> {
	const { data } = await api.get<CostCenter>(
		`/api/v1/rh/companies/${companyId}/cost-center/${costCenterId}`
	);

	return data;
}

export async function deleteCostCenter(
	costCenterId: string,
	companyId?: string
) {
	await api.delete(
		`/api/v1/rh/companies/${companyId}/cost-center/${costCenterId}`
	);
}

export async function updateCostCenter(
	title: string,
	description: string,
	costCenterId: string,
	companyId?: string
) {
	const { data } = await api.put<CostCenter>(
		`/api/v1/rh/companies/${companyId}/cost-center/${costCenterId}`,
		{
			title,
			description,
		}
	);

	return data;
}
