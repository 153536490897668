import styled from 'styled-components';
import { Order } from '../../hooks/useSortColumnHook/index';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';

interface Props {
	order: Order | null;
	onToggleSort: () => void;
	isActive: boolean;
}
export function SortColumnButton({ order, onToggleSort, isActive }: Props) {
	function getIcon() {
		if (order === null) return <MdKeyboardArrowUp className='notSort' />;
		if (order === 'ASC') return <MdKeyboardArrowUp className='sortUp' />;
		return <MdKeyboardArrowDown className='sortDown' />;
	}

	return (
		<Container onClick={onToggleSort} data-rh={`Ordenar`} isActive={isActive}>
			{getIcon()}
		</Container>
	);
}

const Container = styled.button<{ isActive: boolean }>`
	background: none;
	color: var(--white);
	visibility: ${({ isActive }) => (isActive ? 'visible' : 'hidden')};
	& > svg {
		width: 2.4rem;
		height: 2.4rem;
	}

	&:hover {
		visibility: visible;
	}
`;
