import { useState } from 'react';
import { FaRegAddressBook } from 'react-icons/fa';
import { AddressBody } from '../../@types';
import { cepMask } from '../../utils/masks';
import Modal from '../Modal';
import * as S from './styles';

export interface AddressModalProps {
	address: AddressBody;
	name?: string;
}
export function AddressModal({ address, name }: AddressModalProps) {
	const [isOpen, setIsOpen] = useState(false);
	return (
		<>
			<S.OpenAddressModalBtn
				onClick={() => setIsOpen(true)}
				data-testid='address-modal-btn'
			>
				<FaRegAddressBook />
			</S.OpenAddressModalBtn>

			<Modal
				isOpen={isOpen}
				onRequestClose={() => setIsOpen(false)}
				enableClose
			>
				<S.Container>
					<S.Title>
						Endereço de entrega
						{name && (
							<span data-rh={name}>
								: {name.slice(0, 24)}
								{name.length > 24 && '...'}
							</span>
						)}
					</S.Title>
					<S.Content>
						<S.InfoContainer>
							<S.Label>Endereço</S.Label>
							<S.Information>{address.address}</S.Information>
						</S.InfoContainer>

						<S.InfoContainer>
							<S.Label>Número</S.Label>
							<S.Information>{address.number}</S.Information>
						</S.InfoContainer>

						<S.InfoContainer>
							<S.Label>Complemento</S.Label>
							<S.Information>{address.complement}</S.Information>
						</S.InfoContainer>

						<S.InfoContainer>
							<S.Label>Referência</S.Label>
							<S.Information>{address.reference}</S.Information>
						</S.InfoContainer>

						<S.InfoContainer>
							<S.Label>Cidade</S.Label>
							<S.Information>{address.city}</S.Information>
						</S.InfoContainer>

						<S.InfoContainer>
							<S.Label>Estado</S.Label>
							<S.Information>{address.uf}</S.Information>
						</S.InfoContainer>

						<S.InfoContainer>
							<S.Label>Bairro</S.Label>
							<S.Information>{address.district}</S.Information>
						</S.InfoContainer>

						<S.InfoContainer>
							<S.Label>CEP</S.Label>
							<S.Information>{cepMask(address.cep)}</S.Information>
						</S.InfoContainer>
					</S.Content>
				</S.Container>
			</Modal>
		</>
	);
}
