import api from '../api';

export interface FirstAccessParams {
	password: string;
	password_confirmation: string;
}

export interface ResetPasswordParams {
	password: string;
	token: string;
}

export async function session(email: string, password: string) {
	const response = await api.post('api/v1/rh/session', { email, password });

	return response.data;
}

export async function deleteToken() {
	await api.delete('/api/v1/operators/notification/token');
}

export async function refreshToken(refreshToken: string) {
	const { data } = await api.post('/api/v1/rh/operators/refresh-token', {
		refresh_token: refreshToken,
	});

	return data;
}

export async function firstAccess({
	password,
	password_confirmation,
}: FirstAccessParams) {
	await api.patch('/api/v1/rh/operators/first-access', {
		password,
		password_confirmation,
	});
}

export async function forgotPassword(email: string) {
	await api.post('api/v1/rh/operators/password/forgot', { email });
}
export async function resetPassword({ password, token }: ResetPasswordParams) {
	await api.post(
		'/api/v1/rh/operators/password/reset',
		{ password },
		{
			params: { token },
		}
	);
}
