import styled from 'styled-components';
import { Input as FormInput } from '../../../../../components/Form/FormStyles';

export const Container = styled.div<DetailsInputProps>`
	display: flex;
	align-items: center;
	column-gap: 0.2rem;
	max-width: 20rem;
	min-width: 10rem;
	font-size: 1.6rem;
	color: ${({ color }) => color};
`;

export const Input = styled(FormInput)`
	&:disabled {
		cursor: default;
		background: none;
		color: inherit;
		opacity: 1;
	}
`;
interface DetailsInputProps {
	chargeback?: boolean;
	color?: string;
}

export const DetailsInput = styled.input<DetailsInputProps>`
	color: var(--dark-gray);
	border: none;
	font-size: inherit;
	&:disabled {
		cursor: default;
		background: none;
		color: ${({ color }) => color};
		opacity: 1;
		width: ${({ chargeback }) => (chargeback ? '6rem' : 'inherit')};
	}
`;

export const ResetInputBtn = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: var(--primary-red);
	min-width: 2.2rem;
	min-height: 2.2rem;
	border-radius: 50%;
	& svg {
		width: 1.4rem;
		height: 1.4rem;
		color: var(--white);
	}
`;
