import { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';

// when this hook is called it subscribes to navigation changes, and when the next location change does
// not includes the passed matchLocation it calls the callback function

// Use example: when the user is navigating outside the Payments page to one of the PaymentsDetails we would
// want to keep the filters used in the Payments page (in a store), but when the user is navigating outside
// to a completely different route, like Collaborators page, we would want to reset the filters, this can be
// achieved by using this hook
export function useHistoryNonMatchCallback(
	matchLocation: string,
	callback: () => void
) {
	const history = useHistory();
	const callbackRef = useRef(callback);

	useEffect(() => {
		const unlisten = history.listen((l) => {
			if (!l.pathname.includes(matchLocation)) {
				unlisten(); // unlisten to history changes
				callbackRef.current();
			}
		});
	}, [matchLocation, history]);
}
