import {
	Document,
	Font,
	Image,
	Page,
	Text,
	View,
	pdf,
} from '@react-pdf/renderer';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { useAuth } from '../../../../../../../hooks/useAuth';
import { getCardExtractByPeriodToReport } from '../../../../../../../services/queries/Corpway/Extract';
import {
	convertCentsToCurrency,
	convertCentsToFormattedReais,
} from '../../../../../../../utils/CurrencyConvert';
import { showErrorMessage } from '../../../../../../../utils/ErrorHandler';
import { parseTransactionType } from '../../../../utils/transactionUtils';
import { Card } from '../../../../../../../@types/CorporateExpenses/Card';
import controlLogo from '../../../../../../../assets/corpwayExtractReport/ControlLogo.png';
import extractIcon from '../../../../../../../assets/corpwayExtractReport/extractIcon.png';
import { cnpjMask } from '../../../../../../../utils/masks';
import { parseCardStatusToReport } from '.././utils/parseCardStatusToReport';
import {
	calculateCreditValue,
	calculateDebitValue,
	checkDeclineAndStrikeText,
} from '.././utils/transactionUtilsToReport';
import { controlReportStyle } from './styles';
import { ReportProps } from '../..';
import { maskCardNumber } from '../../../../../../../utils/formatCardNumber';

export interface Props {
	card: Card;
	start_date: number;
	end_date: number;
	filters: ReportProps['filters'];
}

Font.register({
	family: 'Poppins',
	fonts: [
		{
			src: 'https://fonts.gstatic.com/s/poppins/v1/hlvAxH6aIdOjWlLzgm0jqg.ttf',
		},
		{
			src: 'https://fonts.gstatic.com/s/poppins/v1/4WGKlFyjcmCFVl8pRsgZ9vesZW2xOQ-xsNqO47m55DA.ttf',
			fontWeight: 500,
		},
		{
			src: 'https://fonts.gstatic.com/s/poppins/v1/-zOABrCWORC3lyDh-ajNnPesZW2xOQ-xsNqO47m55DA.ttf',
			fontWeight: 600,
		},
	],
});

export function PdfReportGenerator() {
	const { currentCompany } = useAuth();

	const getExtractQuery = useMutation(
		({ card, start_date, end_date, filters }: Props) =>
			getCardExtractByPeriodToReport(
				card!.id,
				currentCompany!.id,
				start_date,
				end_date,
				filters
			),
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao buscar as transações para o relatório.'
				);
			},
		}
	);

	async function handleGeneratePDF({
		card,
		start_date,
		end_date,
		filters,
	}: Props) {
		try {
			const PDFGenerated = await generatePDFContent(
				card,
				start_date,
				end_date,
				filters
			);
			const blob = await pdf(PDFGenerated).toBlob();

			// Create a temporary URL for the blob
			const url = URL.createObjectURL(blob);

			const startDate = new Date(start_date);
			const endDate = new Date(end_date);

			// Create a link element
			const link = document.createElement('a');
			link.href = url;
			link.download = `Bounty_Control_${card?.alias}_${card?.pan.slice(
				-4
			)}_${startDate.toLocaleDateString('pt-BR', {
				timeZone: 'UTC',
			})}_${endDate.toLocaleDateString('pt-BR', { timeZone: 'UTC' })}.pdf`;

			// Simulate a click event to trigger the download
			link.dispatchEvent(new MouseEvent('click'));

			// Clean up the temporary URL
			URL.revokeObjectURL(url);
		} catch (error: any) {
			if (error.message === 'empty_transactions') {
				throw error; // let parent handle
			}
			toast.error(
				'Ocorreu um erro ao tentar gerar o arquivo de relátorio. Tente novamente'
			);
		}
	}

	async function generatePDFContent(
		card: Card,
		start_date: number,
		end_date: number,
		filters: ReportProps['filters']
	) {
		const { transactions, responsible, totalExtracts } =
			await getExtractQuery.mutateAsync({
				card,
				start_date,
				end_date,
				filters,
			});

		if (transactions.length === 0) {
			throw new Error('empty_transactions');
		}

		return (
			<Document>
				<Page size='A4' style={controlReportStyle.page} orientation='landscape'>
					<View style={controlReportStyle.header} fixed>
						<Image src={controlLogo} style={controlReportStyle.logo} />
						<View style={{ ...controlReportStyle.headerInfo, width: '120%' }}>
							<Text style={controlReportStyle.headerInfoLabel}>Nome:</Text>
							<Text style={controlReportStyle.headerInfoData}>
								{currentCompany?.corporateName}
							</Text>
						</View>
						<View style={controlReportStyle.headerInfo}>
							<Text style={controlReportStyle.headerInfoLabel}>CNPJ:</Text>
							<Text style={controlReportStyle.headerInfoData}>
								{cnpjMask(currentCompany?.cnpj!)}
							</Text>
						</View>
						<View style={{ ...controlReportStyle.headerInfo, width: '70%' }}>
							<Text style={controlReportStyle.headerInfoLabel}>N° Página:</Text>
							<Text
								style={controlReportStyle.headerInfoData}
								render={({ pageNumber, totalPages }) =>
									`${String(pageNumber).padStart(2, '0')}/${String(
										totalPages
									).padStart(2, '0')}`
								}
							/>
						</View>
						<View style={controlReportStyle.headerInfo}>
							<Text style={controlReportStyle.headerInfoLabel}>
								Data do pedido:
							</Text>
							<Text style={controlReportStyle.headerInfoData}>
								{Intl.DateTimeFormat('pt-BR', {
									day: '2-digit',
									month: '2-digit',
									year: 'numeric',
									hour: '2-digit',
									minute: '2-digit',
									hour12: false, // 24-hour format
								})
									.format(new Date())
									.replace('  ', ', ')}
							</Text>
						</View>
						<View style={{ ...controlReportStyle.headerInfo, width: '120%' }}>
							<Text style={controlReportStyle.headerInfoLabel}>
								Intervalo de busca:
							</Text>
							<Text style={controlReportStyle.headerInfoData}>
								{Intl.DateTimeFormat('pt-BR', {
									timeZone: 'UTC',
									day: '2-digit',
									month: '2-digit',
									year: 'numeric',
								}).format(new Date(start_date))}
								{' - '}
								{Intl.DateTimeFormat('pt-BR', {
									timeZone: 'UTC',
									day: '2-digit',
									month: '2-digit',
									year: 'numeric',
								}).format(new Date(end_date))}
							</Text>
						</View>
						<View style={{ ...controlReportStyle.headerInfo, width: '60%' }}>
							<Text style={controlReportStyle.headerInfoLabel}>
								N° do pedido:
							</Text>
							<Text style={controlReportStyle.headerInfoData}>
								{String(totalExtracts).padStart(7, '0')}
							</Text>
						</View>
					</View>
					<View style={controlReportStyle.subHeader}>
						<View style={controlReportStyle.extractTitleContainer}>
							<Image src={extractIcon} style={controlReportStyle.extractLogo} />
							<Text style={controlReportStyle.extractTitle}>Extrato</Text>
						</View>

						<View
							style={{
								...controlReportStyle.subheaderDataContainer,
								flexDirection: 'row',
							}}
						>
							<View style={{ ...controlReportStyle.subheaderInfo }}>
								<Text style={{ ...controlReportStyle.subheaderLabel }}>
									Apelido do cartão:
								</Text>
								<Text style={controlReportStyle.subheaderData}>
									{card.alias}
								</Text>
							</View>

							<View
								style={{
									...controlReportStyle.subheaderInfo,
									width: '38%',
									flexDirection: 'row',
								}}
							>
								<Text
									style={{
										...controlReportStyle.subheaderLabel,
										flexDirection: 'row',
									}}
								>
									Responsável:
								</Text>
								<Text style={controlReportStyle.subheaderData}>
									{responsible.name}
								</Text>
							</View>

							<View
								style={{
									...controlReportStyle.cardCreditContainer,
									flexDirection: 'row',
									alignContent: 'center',
									alignItems: 'center',
								}}
							>
								<Text
									style={{
										...controlReportStyle.cardCreditText,
										fontWeight: 600,
										flexDirection: 'row',
									}}
								>
									Crédito:
								</Text>
								<Text style={controlReportStyle.cardCreditText}>
									{convertCentsToFormattedReais(
										calculateCreditValue(transactions)
									)}
								</Text>
							</View>

							<View
								style={{
									...controlReportStyle.subheaderInfo,
									flexDirection: 'row',
									alignItems: 'center',
								}}
							>
								<Text
									style={{
										...controlReportStyle.subheaderLabel,
										flexDirection: 'row',
										alignItems: 'center',
									}}
								>
									Número do cartão:
								</Text>
								<Text
									style={{
										...controlReportStyle.subheaderData,
										flexDirection: 'row',
									}}
								>
									{maskCardNumber(card.pan)}
								</Text>
							</View>

							<View
								style={{
									...controlReportStyle.subheaderInfo,
									width: '19%',
									flexDirection: 'row',
									alignItems: 'center',
								}}
							>
								<Text
									style={{
										...controlReportStyle.subheaderLabel,
										flexDirection: 'row',
									}}
								>
									Status:
								</Text>
								{parseCardStatusToReport(card.status)}
							</View>

							<View
								style={{
									...controlReportStyle.subheaderInfo,
									width: '18%',
									flexDirection: 'row',
									alignItems: 'center',
								}}
							>
								<Text
									style={{
										...controlReportStyle.subheaderLabel,
										flexDirection: 'row',
										alignItems: 'center',
									}}
								>
									Saldo:
								</Text>
								<Text
									style={{
										...controlReportStyle.subheaderData,
										flexDirection: 'row',
									}}
								>
									{convertCentsToFormattedReais(card.balance)}
								</Text>
							</View>

							<View
								style={{
									...controlReportStyle.cardDebitContainer,
									flexDirection: 'row',
									alignItems: 'center',
									alignContent: 'center',
								}}
							>
								<Text
									style={{
										...controlReportStyle.cardDebitText,
										fontWeight: 600,
										flexDirection: 'row',
									}}
								>
									Débito:
								</Text>
								<Text style={controlReportStyle.cardDebitText}>
									{convertCentsToFormattedReais(
										calculateDebitValue(transactions)
									)}
								</Text>
							</View>
						</View>
					</View>

					<View style={controlReportStyle.tableContainer}>
						<View style={controlReportStyle.tableHeader} fixed>
							<View style={controlReportStyle.columnTitleContainer}>
								<Text style={controlReportStyle.columnTitle}>Data</Text>
							</View>
							<View style={controlReportStyle.columnTitleContainer}>
								<Text style={controlReportStyle.columnTitle}>Lançamento</Text>
							</View>
							<View style={controlReportStyle.columnTitleContainer}>
								<Text style={controlReportStyle.columnTitle}>
									Estabelecimento
								</Text>
							</View>
							<View style={controlReportStyle.columnTitleContainer}>
								<Text style={controlReportStyle.columnTitle}>
									Centro de Custo
								</Text>
							</View>
							<View style={controlReportStyle.columnTitleContainer}>
								<Text style={controlReportStyle.columnTitle}>Valor</Text>
							</View>
						</View>

						{transactions.map((transaction) => (
							<View
								style={controlReportStyle.tableRow}
								key={transaction.internal_transaction_id}
								wrap={false}
							>
								<Text style={controlReportStyle.columnData}>
									{Intl.DateTimeFormat('pt-BR', {
										day: '2-digit',
										month: '2-digit',
										year: 'numeric',
										hour: '2-digit',
										minute: '2-digit',
										hour12: false, // 24-hour format
									})
										.format(transaction.added_time)
										.replace(', ', ' - ')}
								</Text>

								<Text style={controlReportStyle.columnData}>
									{parseTransactionType(
										transaction.txn_type,
										transaction.msg_type,
										transaction.currency,
										transaction.merchant_country
									)}
								</Text>

								<Text style={controlReportStyle.columnData}>
									{checkDeclineAndStrikeText(
										transaction.response_code,
										transaction.merchant_name
									)}
								</Text>
								<Text style={controlReportStyle.columnData}>
									{card.cost_center_data?.title}
								</Text>
								<View style={controlReportStyle.amountContainer}>
									<Text style={controlReportStyle.columnData}>
										{checkDeclineAndStrikeText(
											transaction.response_code,
											convertCentsToFormattedReais(transaction.billing_amount)
										)}
									</Text>
									{transaction.currency !== 986 && (
										<Text style={controlReportStyle.originalAmount}>
											{'('}
											{checkDeclineAndStrikeText(
												transaction.response_code,
												convertCentsToCurrency(
													String(transaction.currency),
													transaction.txn_amount
												)
											)}
											{')'}
										</Text>
									)}
								</View>
							</View>
						))}
					</View>
				</Page>
			</Document>
		);
	}

	return {
		generatePDF: handleGeneratePDF,
		loading: getExtractQuery.isLoading,
	};
}
