import { Breadcrumb } from '../../../../componentsV2/BreadCrumb';
import {
	TitleTypography,
	Typography,
} from '../../../../componentsV2/ui/Typography';
import { SectionContainer } from '../../../../componentsV2/ui/SectionContainer';
import * as S from './styles';
import { Table } from '../../../../componentsV2/Table';
import { useMemo, useState } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import {
	cnpjMask,
	cpfMask,
	parseMaskedCnpjToRaw,
	parseMaskedCPFToRaw,
} from '../../../../utils/masks';
import { InputField } from '../../../../componentsV2/ui/Form/InputField';
import { FaSearch } from 'react-icons/fa';
import NewTransferModal from './components/NewTransferModal/index';
import NewBeneficiaryModal from './components/NewBeneficiaryModal';
import { useQuery } from 'react-query';
import { useAuth } from '../../../../hooks/useAuth';
import { getBeneficiaries } from '../../../../services/queries/Corpway/Transfers';
import { BeneficiaryResponse } from '../../../../@types/CorporateExpenses/Transfer';
import BeneficiaryDetailsModal from './components/BeneficiaryDetailsModal';
import LimitSettingsModal from './components/LimitSettingsModal';
import { DesktopContainer } from '../../components/Sidebar/styles';
import { MobileContainer } from '../../Profile/styles';

const columnHelper = createColumnHelper<BeneficiaryResponse>();

export default function Transfers() {
	const [search, setSearch] = useState('');
	const { currentCompany } = useAuth();
	const [isLimitModalOpen, setIsLimitModalOpen] = useState(false);

	const getBeneficiariesQuery = useQuery(
		['beneficiaries', currentCompany?.id],
		() => getBeneficiaries(currentCompany?.id!),
		{
			refetchOnMount: false,
			refetchOnWindowFocus: false,
		}
	);

	const filteredBeneficiaries =
		getBeneficiariesQuery.data?.filter((e) => {
			const tempSearch = search.trim().toLowerCase();
			if (!tempSearch) return true;

			const parseToRaw =
				e.type === 'pf' ? parseMaskedCPFToRaw : parseMaskedCnpjToRaw;
			return (
				e.name.toLowerCase().includes(tempSearch) ||
				e.document.toLowerCase().includes(parseToRaw(tempSearch))
			);
		}) ?? [];

	const columns = useMemo(
		() => [
			columnHelper.accessor('name', {
				cell: (info) => info.getValue(),
				header: 'Nome',
			}),
			columnHelper.accessor((row) => row, {
				cell: (info) => {
					const { document, type } = info.getValue();
					return type === 'pf' ? cpfMask(document) : cnpjMask(document);
				},
				header: 'CPF / CNPJ',
			}),
			columnHelper.accessor((row) => row, {
				cell: (info) => {
					const { bank_code, bank_name } = info.getValue();

					return `${bank_code} - ${bank_name}`.slice(0, 20);
				},
				header: 'Banco',
			}),
			columnHelper.accessor('branch', {
				cell: (info) => {
					return info.getValue();
				},
				header: 'Agência',
				meta: {
					align: 'center',
				},
			}),
			columnHelper.accessor('account_number', {
				cell: (info) => {
					return info.getValue();
				},
				header: 'Conta',
				meta: {
					align: 'center',
				},
			}),
			columnHelper.display({
				cell: (props) => {
					const beneficiary = props.row.original;
					return (
						<BeneficiaryDetailsModal
							beneficiaryResponse={beneficiary}
							setIsLimitModalOpen={setIsLimitModalOpen}
						/>
					);
				},
				meta: {
					align: 'center',
				},
				header: 'Transferir',
			}),
		],
		[]
	);

	return (
		<div>
			<SectionContainer>
				<Breadcrumb
					routes={[
						{ title: 'Carteira', route: '/corporate-expenses/wallet' },
						{
							title: 'Transferir saldo',
							route: '#',
						},
					]}
				/>
				<TitleTypography image>Transferir saldo</TitleTypography>
			</SectionContainer>

			<MobileContainer>
				<SectionContainer>
					<S.HeaderContainer>
						<S.DescriptionContainer>
							<TitleTypography size='2rem' primaryText='Transferir'>
								para outro banco
							</TitleTypography>
							<Typography
								style={{ textAlign: 'center' }}
								color='var(--mid-gray)'
								size='1.4rem'
								weight='400'
							>
								Transferências do tipo TED podem ser realizadas de <b>segunda a
								sexta-feira, entre 07:00 e 17:00</b>. Solicitações realizadas fora
								desse período ou em feriados serão processadas no próximo horário disponível
								ou no dia útil seguinte.
							</Typography>
						</S.DescriptionContainer>
					</S.HeaderContainer>
					<S.ButtonContainer>
						<NewTransferModal beneficiaries={getBeneficiariesQuery.data} />

						<LimitSettingsModal
							isModalOpen={isLimitModalOpen}
							setIsModalOpen={setIsLimitModalOpen}
						/>
					</S.ButtonContainer>
				</SectionContainer>
			</MobileContainer>

			<SectionContainer style={{ gap: '4rem' }}>
				<DesktopContainer>
					<S.HeaderContainer>
						<S.DescriptionContainer>
							<TitleTypography size='2rem' primaryText='Transferir'>
								para outro banco
							</TitleTypography>
							<Typography color='var(--mid-gray)' size='1.4rem' weight='400'>
								<span>
									Você pode fazer transferências via TED de{' '}
									<b>segunda a sexta-feira, entre 7h e 17h</b>. Solicitações
									realizadas fora desse período ou em feriados serão processadas no próximo horário disponível
									ou no dia útil seguinte.
								</span>
							</Typography>
						</S.DescriptionContainer>

						<LimitSettingsModal
							isModalOpen={isLimitModalOpen}
							setIsModalOpen={setIsLimitModalOpen}
						/>
					</S.HeaderContainer>
				</DesktopContainer>

				<S.SearchContainer>
					<TitleTypography size='2rem' primaryText='Contas'>
						favoritas
					</TitleTypography>
					<S.InputContainer>
						<InputField
							placeholder='Pesquisar por Nome, CPF ou CNPJ'
							style={{ paddingRight: '4rem' }}
							value={search}
							name={"search"}
							onChange={(e) => setSearch(e.target.value)}
						/>
						<FaSearch />
					</S.InputContainer>
					<DesktopContainer>
						<NewTransferModal beneficiaries={getBeneficiariesQuery.data} />
					</DesktopContainer>
				</S.SearchContainer>

				<Table
					data={filteredBeneficiaries}
					showHeader
					columns={columns}
					variant={'lightgray'}
				/>

				<S.ButtonContainer>
					<NewBeneficiaryModal />
				</S.ButtonContainer>
			</SectionContainer>
		</div>
	);
}
