export function parseCurrencyToBRLStandard(
	valueToBeParsed: number,
	symbol = true
) {
	const numberFormatter = new Intl.NumberFormat('pt-BR', {
		style: symbol ? 'currency' : 'decimal',
		currency: 'BRL',
		currencyDisplay: 'symbol',
	});
	return numberFormatter.format(valueToBeParsed);
}

export function parseCurrencyStrToNumber(currencyStr: string) {
	return Number(currencyStr.replaceAll('.', '').replace(',', '.'));
}
