import styled from "styled-components"


const Text = styled.span`
  color: #575747;
  font-size: 1.2rem;
  font-weight: normal;
  width: 50%;
  margin-top: 1rem;
  text-align: justify;
`

export function KYCFieldsIndication() {
  return (
    <Text>* Os campos indicados influenciam na análise de compliance do colaborador, portanto certifique-se que os mesmos estão rigorosamente de acordo com os documentos que ele forneceu.</Text>
  )
}

export function KYCFieldsIndicator() {
  return <Text style={{margin: '0 0.4rem'}}>*</Text>
}
