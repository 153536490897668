import React, { useEffect, useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import {
	MdBlock,
	MdCheckBox,
	MdDelete,
	MdEdit,
	MdKeyboardArrowRight,
	MdNotes,
} from 'react-icons/md';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { Card } from '../../../../../@types/CorporateExpenses/Card';
import { Button } from '../../../../../componentsV2/ui/Button';
import { InputComp } from '../../../../../componentsV2/ui/Form/InputStyle';
import { Label } from '../../../../../componentsV2/ui/Form/Label';
import Modal from '../../../../../componentsV2/ui/Modal';
import {
	TitleTypography,
	Typography,
} from '../../../../../componentsV2/ui/Typography';
import { useAuth } from '../../../../../hooks/useAuth';
import {
	CORPWAY_CARDS_KEY,
	getCardToken,
	updateCardAlias,
} from '../../../../../services/queries/Corpway/Cards';
import { showErrorMessage } from '../../../../../utils/ErrorHandler';
import { ActivateCardConfirmationModal } from '../ActivateCardConfirmationModal';
import { BlockCardConfirmationModal } from '../BlockCardConfirmationModal';
import * as S from './styles';
import { UpdateCardBalanceModal } from '../UpdateCardBalanceModal';

declare function swap(arg: { token: string }): void;

interface Props {
	card: Card;
	children: React.ReactNode;
	isOpen: boolean;
	modalToggle: (show: boolean) => void;
	showToggle?: boolean;
	showCardDetails: boolean;
	toggleShowCardDetails: (show: boolean) => void;
	notificationsIcon: JSX.Element;
	isNotificationsOpen?: boolean;
	isTransactionsOpen?: boolean;
	animationModal?: boolean;
}

export function CardDetailsModal({
	card,
	isOpen,
	modalToggle,
	children,
	showToggle = true,
	showCardDetails,
	toggleShowCardDetails,
	notificationsIcon,
	animationModal,
}: Props) {
	const { currentCompany, user } = useAuth();
	const queryClient = useQueryClient();
	const [cardAlias, setCardAlias] = useState(card.alias);
	const [showEditButton, setShowEditButton] = useState(false);
	const [isEditing, setIsEditing] = useState(false);
	const [isAddBalanceModalOpen, setAddBalanceModalOpen] = useState(false);

	useQuery(
		[CORPWAY_CARDS_KEY, user.id, 'token', card.id],
		() => getCardToken(card.id, currentCompany!.id),
		{
			onSuccess: (data) => {
				// calling swap function from script loaded in App.tsx
				try {
					if (isOpen) {
						swap({
							token: data.tokenization.access_token,
						});
					}
				} catch (err) {
					toast.error(
						'Ocorreu um problema ao buscar as informações do cartão. Tente novamente.'
					);
				}
			},
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível buscar os detalhes do cartão.'
				);
			},
			enabled: isOpen,
			refetchOnWindowFocus: false,
		}
	);

	const updateCardAliasMutation = useMutation(
		() => updateCardAlias(card.company_id, card.id, cardAlias),
		{
			onSuccess: () => {
				setIsEditing(false);
				queryClient.invalidateQueries(CORPWAY_CARDS_KEY);
				modalToggle(true);
			},
		}
	);

	function formatPan(pan: string): string {
		const cleanedPan = pan.replace(/\D/g, '');
		const formattedPan = cleanedPan.match(/.{1,4}/g)?.join(' ') || '';
		return formattedPan;
	}

	useEffect(() => {
		const expiryDateElement = document.getElementById('card-expiry-date');
		const expiryDateHiddenElement = document.getElementById(
			'card-expiry-date-hidden'
		);

		const pan = document.getElementById('card-number');

		if (pan) {
			const formattedPan = formatPan(pan.innerText);
			pan.innerText = formattedPan;
		}

		if (expiryDateElement && expiryDateElement.innerText) {
			const date = expiryDateElement.innerText;
			const [month, year] = date.split('/');
			if (year) {
				const formattedDate = `${month}/${year.slice(-2)}`;
				expiryDateElement.innerText = formattedDate;
			}
			if (expiryDateHiddenElement) {
				expiryDateHiddenElement.innerText = `--/--`;
			}
		}
	}, [isOpen, showCardDetails]);
	return (
		<Container>
			{showToggle && (
				<Button
					style={{ color: 'var(--primary-blue)', border: 'none', padding: '0' }}
					intent='ghost'
					onClick={() => {
						modalToggle(true);
						setIsEditing(false);
					}}
					data-rh='Detalhes do cartão'
				>
					<MdNotes />
				</Button>
			)}

			<Modal
				isOpen={isOpen}
				onRequestClose={() => modalToggle(false)}
				position='right'
				titleTheme='dark'
				closeBtnStyles={{ zIndex: 3 }}
				animateOnClose={animationModal}
			>
				<S.Container>
					<S.HeaderContainer
						className={
							card.status === 'blocked'
								? 'blocked'
								: card.status === 'canceled'
								? 'canceled'
								: ''
						}
					>
						<div
							onMouseLeave={() => setShowEditButton(false)}
							onMouseEnter={() => setShowEditButton(true)}
						>
							<TitleTypography
								color='#FFF'
								weight='600'
								size='3.2rem'
								style={{ gap: '0.5rem', marginTop: '4rem' }}
							>
								{!isEditing ? (
									<div style={{ display: 'flex', flexDirection: 'column' }}>
										{cardAlias.charAt(0).toUpperCase() +
											cardAlias.slice(1).toLowerCase()}
										{card.status === 'blocked' && (
											<Typography
												size='1.4rem'
												color='#FFF'
												weight='300'
												style={{
													fontFamily: 'Poppins',
													fontStyle: 'italic',
													lineHeight: '2.1rem',
												}}
											>
												(Bloqueado)
											</Typography>
										)}
									</div>
								) : (
									<>
										<InputFieldStyle
											autoFocus
											maxLength={20}
											value={cardAlias}
											onChange={(e) =>
												setCardAlias(e.target.value.slice(0, 20))
											}
										/>
										<StyledButton
											name='check'
											iconRight={<MdCheckBox />}
											onClick={() => updateCardAliasMutation.mutate()}
											intent='link'
										/>
										<StyledButton
											iconRight={<MdDelete />}
											onClick={() => {
												setIsEditing(false);
												setCardAlias(card.alias);
											}}
											intent='link'
										/>
									</>
								)}

								{user.roles.includes('manage-cards') &&
									!isEditing &&
									showEditButton &&
									card.status === 'active' && (
										<Button
											style={{
												padding: '0.3rem',
												background: '#27ADFF',
											}}
											as='button'
											onClick={() => {
												setIsEditing(true);
											}}
										>
											<MdEdit />
										</Button>
									)}
							</TitleTypography>
						</div>
						<div>
							{card.status !== 'active' && <MdBlock className='icon-wrapper' />}
							<S.CardDataContainer id='card-data' style={{ height: '8rem' }}>
								<Typography
									size='2rem'
									color='#FFF'
									weight='600'
									id='card-number'
									style={{
										display: showCardDetails ? 'block' : 'none',
										minHeight: '3rem',
									}}
								>
									.... .... .... ....
								</Typography>

								<Typography
									size='2rem'
									color='#FFF'
									weight='600'
									id='card-number-hidden'
									style={{
										display: showCardDetails ? 'none' : 'block',
										minHeight: '3rem',
									}}
								>
									.... .... .... ....
								</Typography>

								<Typography
									color='#FFF'
									style={{ fontSize: '1.4rem', gap: '1rem' }}
								>
									{card.company_data?.name || '-'}
								</Typography>

								<S.CardDataBottom>
									<div>
										<Label style={{ fontSize: '1.4rem' }}>Validade:</Label>
										<Typography
											color='#FFF'
											size='1.4rem'
											id='card-expiry-date'
											style={{
												display: showCardDetails ? 'block' : 'none',
												marginRight: '1.1rem',
											}}
										>
											--/--
										</Typography>
										<Typography
											color='#FFF'
											size='2rem'
											id='card-expiry-date-hidden'
											style={{
												display: showCardDetails ? 'none' : 'block',
												marginRight: '2.5rem',
											}}
										>
											--/--
										</Typography>

										<Label style={{ fontSize: '1.4rem', marginLeft: '1rem' }}>
											CVV:
										</Label>
										<Typography
											color='#FFF'
											id='card-cvv'
											style={{
												display: showCardDetails ? 'block' : 'none',
												marginRight: '1rem',
											}}
										>
											...
										</Typography>
										<Typography
											color='#FFF'
											id='card-cvv-hidden'
											style={{
												display: showCardDetails ? 'none' : 'block',
												marginRight: '1rem',
											}}
										>
											...
										</Typography>
									</div>
								</S.CardDataBottom>
							</S.CardDataContainer>

							<S.HeaderButtons
								style={{
									display: 'flex',
									flexDirection: 'initial',
									margin: '0',
									gap: '1rem',
								}}
							>
								<Button
									style={{
										color: '#FFF',
										borderColor: '#FFF',
										borderBottom: 'none',
									}}
									as='link'
									iconRight={showCardDetails ? <FaEye /> : <FaEyeSlash />}
									intent='link'
									onClick={() => toggleShowCardDetails(!showCardDetails)}
								></Button>
								<div>{notificationsIcon}</div>
							</S.HeaderButtons>
						</div>
					</S.HeaderContainer>
					<S.Children>{children}</S.Children>

					<S.BottomExtractOptions>
						{user.roles.includes('manage-balances') &&
							card.status === 'active' && (
								<Button
									intent='primary'
									roundness='lg'
									style={{
										columnGap: '1.2rem',
										fontWeight: 600,
									}}
									onClick={() => setAddBalanceModalOpen(true)}
								>
									Gerenciar Saldo
								</Button>
							)}

						{isAddBalanceModalOpen && (
							<UpdateCardBalanceModal
								card={card}
								isModalOpen={isAddBalanceModalOpen}
								setModalOpen={setAddBalanceModalOpen}
							/>
						)}

						{isOpen && card.status === 'blocked' && (
							<ActivateCardConfirmationModal card={card as unknown as Card} />
						)}

						{isOpen && card.status === 'active' && (
							<BlockCardConfirmationModal card={card as unknown as Card} />
						)}
					</S.BottomExtractOptions>
				</S.Container>
			</Modal>
		</Container>
	);
}

const Container = styled.div`
	@media (max-width: 500px) {
		flex-direction: column;
	}
`;

const InputFieldStyle = styled(InputComp)`
	font-weight: 600;
	width: 50%;
	height: 27px;
	color: white;
	background: none;
	padding: 0;
	font-size: 1.8rem;

	&:focus {
		outline: none;
	}
`;

const StyledButton = styled(Button)`
	color: #fff;
	border-color: #fff;
	border-bottom: none;

	&:hover {
		color: ${(props) =>
			props.name === 'check' ? 'var(--accent-green)' : 'var(--primary-red)'};
	}
`;
