import { parseCurrencyStrToNumber } from '../../../../utils/parseCurrency';

export const PAYMENT_TABLE_ID = 'table-inputs';

export const getBenefitsInputs = () => {
	const table = document.getElementById('table-inputs');
	const inputsHTMLCollection = table?.getElementsByTagName('input');

	if (inputsHTMLCollection === undefined) return [];

	const inputs = Array.from(inputsHTMLCollection).filter(
		(i) => i.type === 'text'
	);

	return inputs;
};

export const getBenefitValue = (beneficiaryId: string, benefitId: string) => {
	return parseCurrencyStrToNumber(
		getBenefitsInputs().find((i) => i.id === `${beneficiaryId}-${benefitId}`)
			?.value ?? '0,00'
	);
};
