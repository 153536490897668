import styled from 'styled-components';

export const AvatarLabelContainer = styled.label<{
	background: string;
	size: number;
}>`
	width: ${({ size }) => size}rem;
	height: ${({ size }) => size}rem;
	border-radius: 50%;
	border: 0.4rem solid var(--primary-blue);
	background-image: url('${({ background }) => background}');
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	background-color: #f0f3f8;
	cursor: pointer;
	background-origin: content-box;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const CameraIcon = styled.div<{ size: number }>`
	position: absolute;
  	bottom: 0;
	right: 0;
	width: ${({ size }) => size / 3.5}rem;
	height: ${({ size }) => size / 3.5}rem;
	border-radius: 50%;
	border: 0.2rem solid var(--primary-blue);
	background-color: #f0f3f8;
	color: var(--primary-blue);
	display: flex;
	align-items: center;
	justify-content: center;

	& > svg {
		width: 60%;
		height: 60%;
	}
`;
