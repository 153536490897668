import { useQuery } from 'react-query';
import { TitleTypography } from '../../../../componentsV2/ui/Typography';
import {
	CORPWAY_USERS_KEY,
	getUsersList,
} from '../../../../services/queries/Corpway/User';
import { showErrorMessage } from '../../../../utils/ErrorHandler';
import { createColumnHelper } from '@tanstack/react-table';
import { Table } from '../../../../componentsV2/Table';
import {
	CorpwayUserAccessLevel,
	User,
} from '../../../../@types/CorporateExpenses/User';
import { SectionContainer } from '../../../../componentsV2/ui/SectionContainer';
import { Link } from '../../../../componentsV2/ui/Link';
import { usePagination } from '../../../../hooks/usePagination';
import { useSortColumnHook } from '../../../../hooks/useSortColumnHook';
import { UserDetailsModal } from './components/UserDetailsModal';
import { parseUserAccessLevel } from '../../../../utils/corpway/parseUserAccessLevel';
import styled from 'styled-components';
import { Button } from '../../../../componentsV2/ui/Button';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { useEffect, useState } from 'react';

const columnHelper = createColumnHelper<User>();
const columns = [
	columnHelper.accessor('name', {
		cell: (info) => info.getValue(),
		header: 'Nome',
		enableSorting: true,
	}),
	columnHelper.accessor('email', {
		cell: (info) => info.getValue(),
		header: 'Email',
		enableSorting: true,
	}),
	columnHelper.accessor('role', {
		cell: (info) =>
			parseUserAccessLevel(info.getValue() as CorpwayUserAccessLevel),
		header: 'Perfil',
		enableSorting: true,
	}),
	columnHelper.display({
		header: 'Detalhes',
		cell: (props) => <UserDetailsModal id={props.row.original.id} />,
	}),
];

export function Users() {
	const { pagination, setPagination } = usePagination();
	const { currentSortColumn, toggleSort } = useSortColumnHook({
		name: 'name',
		order: 'ASC',
	});

	const getUsersQuery = useQuery(
		[CORPWAY_USERS_KEY, pagination.page, currentSortColumn],
		() => getUsersList(pagination.page, currentSortColumn),
		{
			onError: (err) => {
				showErrorMessage(err as Error, 'Não foi possível buscar os usuários. ');
			},
			keepPreviousData: true,
		}
	);

	const [windowWidth, setWindowWidth] = useState(window.innerWidth);

	useEffect(() => {
		const handleResize = () => {
			setWindowWidth(window.innerWidth);
		};

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const tableHeight = windowWidth > 600 ? '45rem' : '60rem';

	return (
		<div>
			<SectionContainer>
				<Table
					asyncStatus={getUsersQuery.status}
					data={getUsersQuery.data?.users ?? []}
					columns={columns}
					leftComponent={
						<div>
							<TitleTypographyContainer size='2.2rem' primaryText='Usuários'>
								Ativos - {getUsersQuery.data?.total_users ?? ''}
							</TitleTypographyContainer>
							<p
								style={{
									fontSize: '14px',
									wordBreak: 'keep-all',
									whiteSpace: 'normal',
								}}
							>
								Nesta seção, você cria e gerencia todos os usuários ativos que{' '}
								<br />
								possuem acesso ao Bounty Control, de acordo com o perfil.
							</p>
						</div>
					}
					rightComponent={
						<Button
							as='link'
							to={'/corporate-expenses/management/users/create'}
							intent='primary'
							roundness='lg'
							style={{
								columnGap: '0.6rem',
								fontWeight: 550,
								fontFamily: 'poppins',
								fontSize: '1.5rem',
								padding: '1rem 3.5rem',
								paddingRight: '2.4rem',
							}}
						>
							Novo usuário{' '}
							<MdKeyboardArrowRight
								style={{ fontSize: '2.3rem', width: 'auto', height: 'auto' }}
							/>
						</Button>
					}
					pagination={pagination}
					setPagination={setPagination}
					totalItems={getUsersQuery.data?.total_users}
					currentSortColumn={currentSortColumn}
					toggleSort={toggleSort}
					containerHeight={tableHeight}
				/>
			</SectionContainer>
		</div>
	);
}

const TitleTypographyContainer = styled(TitleTypography)`
	@media (max-width: 1024px) {
		font-size: 1.5rem !important;
	}
`;
