import { IoIosArrowDown } from 'react-icons/io';
import { renderToStaticMarkup as rtsm } from 'react-dom/server';
import styled, { css } from 'styled-components';

interface FieldsRowProps {
	align?: boolean;
}

const arrowIcon = css`
	${(rtsm(IoIosArrowDown({ size: 20, color: 'black' })) as string).replace(
		/"/g,
		"'"
	)}
`;

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	height: fit-content;
	max-height: 90vh;
	width: 55rem;
	align-items: center;
	padding: 1rem 3rem;

	@media (max-width: 768px) {
		width: 100%;
	}
`;

export const HeaderContainer = styled.div`
	padding: 3rem 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	row-gap: 1.5rem;
	width: 100%;
`;

export const IconContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 4.5rem;
	height: 4.5rem;
	background-color: var(--primary-blue);
	border-radius: 50px;
`;
export const IconHeader = styled.img``;

export const ReportInfoContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: center;
`;

export const FieldsRow = styled.div<FieldsRowProps>`
	display: flex;
	width: 100%;
	column-gap: 1rem;
	justify-content: space-between;

	& + & {
		margin-top: 1rem;
	}

	&.information {
		& fieldset:last-child {
			width: 50%;
			align-items: flex-end;

			p {
				text-align: right;
			}
		}
	}

	${(props) =>
		props.align &&
		`
    fieldset {
        align-items: center;
		
		label{
			font-size: 1.5rem;
		}
    }

	div {
		justify-content: center;
	}
    `}
`;

export const FieldLabel = styled.label`
	font-family: 'Poppins', sans-serif;
	font-weight: 400;
	color: var(--dark-gray2);
	font-size: 1.4rem;
`;

export const ChipsSelectionContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	column-gap: 1rem;
	justify-content: center;
`;

export const FieldValue = styled.p`
	font-size: 1.4rem;
	font-weight: 500;
`;

export const OperationsSelected = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;
`;

export const FieldMessage = styled.p`
	margin-top: 2rem;
	font-size: 1.2rem;
	text-align: justify;
`;

export const TimePeriodChipContainer = styled.div<{ isActive: boolean }>`
	padding: 1rem 2rem;
	border-radius: 2rem;
	border: 1px solid
		${({ isActive }) => (isActive ? 'var(--primary-blue)' : 'var(--dark-gray)')};
	width: max-content;
	background-color: ${({ isActive }) =>
		isActive ? 'var(--primary-blue)' : 'transparent'};
	color: ${({ isActive }) => (isActive ? 'var(--white)' : 'var(--dark-gray)')};
	font-size: 1.2rem;
	cursor: pointer;
`;

export const DatesContainer = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;

	& input[type='month'] {
		position: relative;
		font-family: 'Poppins', sans-serif;
		padding-left: 5rem;
	}

	& input[type='month']::-webkit-calendar-picker-indicator {
		position: absolute;
		top: 1rem;
		left: 1rem;
		right: 0;
		bottom: 0;
		width: auto;
		height: 2rem;
		filter: invert(0.5);
	}
		
	& input[type='month']::-webkit-datetime-edit-month-field {
		text-transform: capitalize;
	}

	&.month {
		&::before {
			${/* // eslint-disable-next */ ''}
			content: url("data:image/svg+xml; utf8,${arrowIcon}");
			position: absolute;
			z-index: 1;
			pointer-events: none;
			right: 5rem;
			top: 30rem;
			filter: invert(0.3);
		}
	}
`;

export const ButtonsContainer = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
	column-gap: 2rem;
	margin-top: 2rem;
	margin-bottom: 2rem;
`;

export const MainContentContainer = styled.div`
	margin-top: 2rem;
	margin-bottom: 0.7rem;
	width: 100%;
	padding: 24px;
	border-style: solid;
	border-width: 1px;
	border-color: var(--primary-blue);
	border-radius: 8px;
`;

export const CheckboxesContainer = styled.div`
	margin-top: 1rem;
	width: 100%;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	row-gap: 2rem;

	& > div {
	}

	&.operation {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;

		@media (max-width: 768px) {
			grid-template-columns: 1fr 1fr;
		}
	}
`;

export const SelectedData = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: space-between;
`;

export const FormatSelectContainer = styled.div`
	width: 70%;
	margin-left: 5rem;
	position: relative;

	& select {
		position: relative;
		appearance: none;
	}

	&::before {
		${/* // eslint-disable-next */ ''}
		content: url("data:image/svg+xml; utf8,${arrowIcon}");
		position: absolute;
		z-index: 1;
		pointer-events: none;
		top: 4rem;
		right: 2rem;
		filter: invert(0.3);
	}

	@media (max-width: 768px) {
		width: 100%;
		margin: 0;
	}
`;
