import * as S from './styles';
import { Label } from '../../../../../componentsV2/ui/Form/Label';
import { useParams } from 'react-router-dom';
import { Breadcrumb } from '../../../../../componentsV2/BreadCrumb';
import { SectionContainer } from '../../../../../componentsV2/ui/SectionContainer';
import {
	TitleTypography,
	Typography,
} from '../../../../../componentsV2/ui/Typography';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
	CORPWAY_USERS_KEY,
	editUser,
	getUserDetails,
} from '../../../../../services/queries/Corpway/User';
import { showErrorMessage } from '../../../../../utils/ErrorHandler';
import { AsynchronousContent } from '../../../../../componentsV2/AsynchronousContent';
import { InputField } from '../../../../../componentsV2/ui/Form/InputField';
import avatarImg from '../../../../../assets/avatar_icon.svg';
import { CorpwayUserAccessLevel } from '../../../../../@types/CorporateExpenses/User';
import { MdCameraAlt } from 'react-icons/md';
import { toast } from 'react-toastify';
import { parseUserAccessLevel } from '../../../../../utils/corpway/parseUserAccessLevel';
import DeleteUserModal from '../components/DeleteModal';
import EditModal from './EditModal';
import { ACCESS_LEVELS } from '../../../../../utils/corpway/accessLevelsMapping';
import { HorizontalDivider } from '../../../../../componentsV2/ui/Divider';
import { capitalizeFirstLetter } from '../../../../../utils/masks';
import {
	CORPWAY_COST_CENTER_KEY,
	getCostCenters,
} from '../../../../../services/queries/Corpway/CostCenters';
import { useAuth } from '../../../../../hooks/useAuth';

export function UserDetails() {
	const { id } = useParams<{ id: string }>();
	const queryClient = useQueryClient();
	const { currentCompany } = useAuth();

	const editUserMutation = useMutation((data: FormData) => editUser(data, id), {
		onSuccess: () => {
			queryClient.invalidateQueries([CORPWAY_USERS_KEY]);
			toast.info('Usuário editado com sucesso.');
		},
		onError: (err) => {
			showErrorMessage(
				err as Error,
				'Não foi possível editar as informações do usuário. '
			);
		},
	});

	const getUserDetailsQuery = useQuery(
		[CORPWAY_USERS_KEY, id],
		() => getUserDetails(id),
		{
			onError: (err) => {
				showErrorMessage(err as Error, 'Não foi possível buscar o usuário. ');
			},

			refetchOnWindowFocus: false,
			refetchOnReconnect: false,
		}
	);

	// This query is used across the CostCenterSelect and EditUserForm components
	const allCostCentersQuery = useQuery(
		[CORPWAY_COST_CENTER_KEY, currentCompany?.id],
		() => getCostCenters(currentCompany!.id),
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível buscar os centros de custo. '
				);
			},
			keepPreviousData: true,
		}
	);

	const userDetails = getUserDetailsQuery.data;
	const showCostCenters =
		userDetails?.role !== 'general_admin' && userDetails?.role !== 'finance';

	function handleEditAvatar(avatar: File) {
		const data = new FormData();

		data.append('avatar', avatar);

		editUserMutation.mutate(data);
	}

	const hasAllCostCenters =
		allCostCentersQuery.data?.total_costCenters ===
		getUserDetailsQuery.data?.corpway_cost_centers.length;

	return (
		<S.Container>
			<SectionContainer>
				<Breadcrumb
					routes={[
						{ title: 'Gestão', route: '/corporate-expenses/management' },
						{
							title: 'Detalhes do usuário',
							route: `/corporate-expenses/management/users/${id}`,
						},
					]}
				/>
				<TitleTypography image>Detalhes do usuário</TitleTypography>
			</SectionContainer>

			<AsynchronousContent status={getUserDetailsQuery.status}>
				<S.ContentContainer>
					<S.CardContainer>
						<S.CardHeaderContainer>
							<S.AvatarContainer
								background={
									userDetails?.operator.avatarUrl
										? encodeURI(userDetails.operator.avatarUrl)
										: avatarImg
								}
							>
								<MdCameraAlt />
								<InputField
									type='file'
									onChange={(e) => {
										e.target.files && handleEditAvatar(e.target.files[0]);
									}}
									multiple={false}
									accept='.jpg, .jpeg, .png'
									style={{ display: 'none' }}
								/>
							</S.AvatarContainer>
							<S.NameContainer>
								<Typography
									size='1.6rem'
									weight='500'
									color='var(--primary-blue)'
								>
									{getUserDetailsQuery.data?.name}
								</Typography>

								<Typography
									size='1.4rem'
									weight='600'
									color='var(--dark-gray)'
									className='accessRole'
								>
									Acesso:{' '}
									{parseUserAccessLevel(
										getUserDetailsQuery.data?.role as CorpwayUserAccessLevel
									)}
								</Typography>
							</S.NameContainer>
						</S.CardHeaderContainer>

						<S.CardContentContainer>
							<Typography size='1.6rem' weight='600' color='var(--dark-gray)'>
								Centros de custo
							</Typography>
							<S.CostCentersList>
								{hasAllCostCenters || !showCostCenters ? (
									<li>Todos</li>
								) : (
									getUserDetailsQuery.data?.corpway_cost_centers.map(
										(costCenter) => (
											<li key={costCenter.id}>
												{capitalizeFirstLetter(costCenter.title)}
											</li>
										)
									)
								)}
							</S.CostCentersList>
						</S.CardContentContainer>
					</S.CardContainer>

					<SectionContainer
						style={{
							width: '100%',
							height: '64rem',
							overflow: 'hidden',
							padding: '2rem 0',
						}}
						className='sectionContainer'
					>
						<S.TitleContainer>
							<div>
								<TitleTypography
									primaryText={'Dados'}
									size='2.4rem'
									weight='600'
								>
									pessoais
								</TitleTypography>
							</div>

							<HorizontalDivider style={{ margin: 0 }} />
						</S.TitleContainer>

						<S.PersonalDataContainer>
							<S.Row>
								<InputField
									label='Nome completo'
									value={userDetails?.name}
									disabled
								/>

								<InputField
									label='E-mail'
									value={userDetails?.email}
									disabled
								/>
							</S.Row>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									width: '100%',
									gap: '0.1rem',
								}}
							>
								<Label>Centros de custo</Label>

								<S.CostCenterSelectorContainer>
									<ul>
										{hasAllCostCenters || !showCostCenters ? (
											<S.CostCenterChip>Todos</S.CostCenterChip>
										) : (
											userDetails?.corpway_cost_centers.map((costCenter) => (
												<S.CostCenterChip key={costCenter.id}>
													{capitalizeFirstLetter(costCenter.title)}
												</S.CostCenterChip>
											))
										)}
									</ul>
								</S.CostCenterSelectorContainer>
							</div>
							<S.Row>
								<InputField
									disabled
									label={'Acesso'}
									value={parseUserAccessLevel(
										userDetails?.role as CorpwayUserAccessLevel
									)}
								/>
								<S.AccessDescription>
									<Typography weight={'400'} size={'1.2rem'}>
										{
											ACCESS_LEVELS.find((e) => e.value === userDetails?.role)
												?.description[0]
										}
									</Typography>
								</S.AccessDescription>
							</S.Row>
						</S.PersonalDataContainer>
						<S.ActionsButtons>
							<div>
								<DeleteUserModal user={userDetails!} />
								<EditModal user={userDetails!} />
							</div>
						</S.ActionsButtons>
					</SectionContainer>
				</S.ContentContainer>
			</AsynchronousContent>
		</S.Container>
	);
}
