import { TextareaHTMLAttributes } from 'react';
import { TextAreaComp } from './TextAreaStyle';
import { BaseFieldProps, Field } from './Field';
import { MdErrorOutline } from 'react-icons/md';

interface Props
	extends TextareaHTMLAttributes<HTMLTextAreaElement>,
		BaseFieldProps {
	errorMessage?: string;
}

export function TextAreaField(props: Props) {
	if (props.register && !props.name)
		throw new Error(`If register is passed it needs the 'name' property!`);

	return (
		<Field label={props.label} name={props.name}>
			<TextAreaComp
				{...(props.register ? { ...props.register(props.name!) } : {})}
				{...props}
			/>
			{props.errorMessage && (
				<span>
					<MdErrorOutline /> {props.errorMessage}
				</span>
			)}
		</Field>
	);
}
