import styled from 'styled-components';

const MobileContainer = styled.div`
	display: none;

	@media (max-width: 800px) {
		display: block;
	}
`;

const DesktopContainer = styled.div`
    display: block;
    @media (max-width: 800px) {
        display: none;
    }
`

export {
	MobileContainer,
	DesktopContainer
}