import { useState } from 'react';
import FeedbackModal from '../FeedbackModal';
import * as FormStyles from '../Form/FormStyles';

interface CancelPaymentModalProps {
	onCancel: (reason: string) => Promise<void>;
}
export function CancelPaymentModal({ onCancel }: CancelPaymentModalProps) {
	const [isCancelPaymentModalOpen, setIsCancelPaymentModalOpen] =
		useState(false);

	return (
		<>
			<FormStyles.Option
				cancel={true}
				onClick={() => setIsCancelPaymentModalOpen(true)}
			>
				Cancelar pagamento
			</FormStyles.Option>
			<FeedbackModal
				description='Motivo do cancelamento'
				isFeedbackModalOpen={isCancelPaymentModalOpen}
				handleCloseFeedbackModal={() => setIsCancelPaymentModalOpen(false)}
				submit={onCancel}
			/>
		</>
	);
}
