import { Header } from '../../../components/Header'
import { useAuth } from '../../../hooks/useAuth'
import styled from 'styled-components'

import avatarImg from '../../../assets/avatar.svg'
import SuperAdminRoutes from '../../../routes/SuperAdminRoutes'


export default function Main() {
  const { user } = useAuth()

  return (
    <>    
      <Header name={'SUPER ADMIN'} email={user.email} avatar={avatarImg} />
      <Container>
        <SuperAdminRoutes />
      </Container>      
    </>
  )
}

const Container = styled.div`
  min-height: 100vh;
`