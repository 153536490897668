import { useHistory, useParams } from 'react-router-dom';
import { Breadcrumb } from '../../../../../componentsV2/BreadCrumb';
import { SectionContainer } from '../../../../../componentsV2/ui/SectionContainer';
import {
	TitleTypography,
	Typography,
} from '../../../../../componentsV2/ui/Typography';
import CostCenterImage from '../../../../../assets/cost-center-modal.png';
import styled from 'styled-components';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useAuth } from '../../../../../hooks/useAuth';
import {
	CORPWAY_COST_CENTER_KEY,
	getCostCenterDetails,
	updateCostCenter,
} from '../../../../../services/queries/Corpway/CostCenters';
import { showErrorMessage } from '../../../../../utils/ErrorHandler';
import { AsynchronousContent } from '../../../../../componentsV2/AsynchronousContent';
import { Tabs } from '../../../../../componentsV2/Tab';
import { Table } from '../../../../../componentsV2/Table';
import { createColumnHelper } from '@tanstack/react-table';
import { Card } from '../../../../../@types/CorporateExpenses/Card';
import { InputField } from '../../../../../componentsV2/ui/Form/InputField';
import {
	CostCenterDetails as CostCenterDetailsType,
	CreateUpdateCostCenterData,
} from '../../../../../@types/CorporateExpenses/CostCenter';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { TextAreaField } from '../../../../../componentsV2/ui/Form/TextAreaField';
import { Button } from '../../../../../componentsV2/ui/Button';
import { CORPWAY_MANAGEMENT_KEY } from '../../../../../services/queries/Corpway/Management';
import { toast } from 'react-toastify';
import { InfoContent } from '../../../../../componentsV2/InfoContent';
import { UserDetailsModal } from '../../Users/components/UserDetailsModal';
import { DeleteCostCenterModal } from '../components/DeleteModal';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { formatCardNumber } from '../../../../../utils/formatCardNumber';
import PreventTransitionPrompt from '../../../../../components/PreventTransitionPrompt';
import AddUserModal from '../components/AddUserModal';
import Modal from '../../../../../componentsV2/ui/Modal';
import { Card as card } from '../../../../../componentsV2/Card';
import costDeleteCenterCostConfirmation from '../../../../../assets/cost-center-confirmation.svg';
import { CardNotificationsAndTransactionsModals } from '../../../Cards/components/CardDetailsModal/CardNotificationsAndTransactionsModals';

const schema = z.object({
	title: z.string().min(1, 'O nome deve ser preenchido'),
	description: z.string().min(1, 'A descrição deve ser preenchida'),
});

const cardsColumnHelper = createColumnHelper<Card>();
const cardsColumns = [
	cardsColumnHelper.accessor('pan', {
		header: 'Cartão',
		cell: (info) => formatCardNumber(info.getValue()),
	}),
	cardsColumnHelper.accessor('alias', {
		cell: (info) =>
			info.getValue().charAt(0).toUpperCase() +
			info.getValue().slice(1).toLowerCase(),
		header: 'Apelido',
	}),
	cardsColumnHelper.display({
		header: 'Ações',
		meta: {
			align: 'center',
		},
		cell: (props) => (
			<CardNotificationsAndTransactionsModals card={props.row.original} />
		),
	}),
];
// type UsersInCostCenterType =
const usersColumnHelper =
	createColumnHelper<CostCenterDetailsType['users_in_cost_center'][number]>();
const usersColumns = [
	usersColumnHelper.accessor('user_name', {
		header: 'Nome',
		cell: (info) => info.getValue(),
	}),
	usersColumnHelper.accessor('user_role', {
		header: 'Função',
		cell: (info) => info.getValue(),
		id: 'role',
	}),
	usersColumnHelper.display({
		header: 'Ações',
		cell: (props) => <UserDetailsModal id={props.row.original.user_id} />,
	}),
];

export function CostCenterDetails() {
	const { id } = useParams<{ id: string }>();
	const [isEditable, setIsEditable] = useState(false);
	const { currentCompany } = useAuth();
	const queryClient = useQueryClient();
	const history = useHistory();
	const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);

	const { register, handleSubmit, setValue, reset, formState } =
		useForm<CreateUpdateCostCenterData>({
			defaultValues: {
				title: '',
				description: '',
			},
			resolver: zodResolver(schema),
			mode: 'onBlur',
		});

	const { isDirty } = formState;
	const [blockNavigation, setBlockNavigation] = useState(false);

	useEffect(() => {
		setBlockNavigation(isDirty);
	}, [isDirty]);

	const toggleEdit = () => {
		setIsEditable(!isEditable);
	};

	const getCostCenterDetailsQuery = useQuery(
		[CORPWAY_COST_CENTER_KEY, id, currentCompany?.id],
		() => getCostCenterDetails(id, currentCompany!.id),
		{
			onSuccess: (data) => {
				setValue('title', data.cost_center_data.title);
				setValue('description', data.cost_center_data.description);
			},
			// onError: (err) => {
			// 	showErrorMessage(
			// 		err as Error,
			// 		'Não foi possível buscar o centro de custo. '
			// 	);
			// },
			refetchOnWindowFocus: false,
			refetchOnReconnect: false,
		}
	);

	const costCenter = getCostCenterDetailsQuery.data;

	const handleCancel = () => {
		reset({
			title: costCenter?.cost_center_data?.title || '',
			description: costCenter?.cost_center_data?.description || '',
		});
		setIsEditable(false);
	};

	const editCostCenterMutation = useMutation(
		(data: CreateUpdateCostCenterData) =>
			updateCostCenter(id, data, currentCompany!.id),
		{
			onSuccess: () => {
				queryClient.resetQueries([
					CORPWAY_COST_CENTER_KEY,
					id,
					currentCompany?.id,
				]);
				queryClient.invalidateQueries([
					CORPWAY_COST_CENTER_KEY,
					currentCompany?.id,
				]);
				queryClient.invalidateQueries([
					CORPWAY_MANAGEMENT_KEY,
					currentCompany?.id,
				]);
				toast.info('Centro de custo editado com sucesso');
				setIsEditable(false);
			},
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível editar o centro de custo. '
				);
			},
		}
	);

	const onCostCenterDelete = () => {
		setConfirmationModalOpen(true);
	};

	const onConfirmDeletion = () => {
		history.push('/corporate-expenses/management');
		setConfirmationModalOpen(false);
	};

	useEffect(() => {
		if (!costCenter?.cost_center_data) return;
		setValue('title', costCenter.cost_center_data.title);
		setValue('description', costCenter.cost_center_data.description);
	}, [costCenter]); // eslint-disable-line react-hooks/exhaustive-deps

	function handleSubmitUpdateCostCenter(d: CreateUpdateCostCenterData) {
		editCostCenterMutation.mutate(d);
	}

	const QuantitiesContent = () => (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between',
				minHeight: '35rem',
			}}
		>
			<QuantitiesContainer>
				<TableContainer>
					<Typography color='var(--dark-gray)' size='1.7rem ' weight='700'>
						Total de cartões ativos -{' '}
						<span
							style={{
								color: 'var(--primary-blue)',
								marginLeft: '0.5rem',
							}}
						>
							{getCostCenterDetailsQuery.data?.cards_in_cost_center.length
								?.toString()
								.padStart(2, '0') ?? ''}
						</span>
					</Typography>

					<Table
						headerBackground={'#F0F3F8'}
						containerHeight={'25rem'}
						variant={'light'}
						columns={cardsColumns}
						data={getCostCenterDetailsQuery.data?.cards_in_cost_center ?? []}
					/>
				</TableContainer>
				<TableContainer>
					<Typography color='var(--dark-gray)' size='1.7rem ' weight='700'>
						Total de usuários alocados - {'  '}
						<span
							style={{
								color: 'var(--primary-blue)',
								marginLeft: '0.5rem',
							}}
						>
							{getCostCenterDetailsQuery.data?.users_in_cost_center.length
								?.toString()
								.padStart(2, '0') ?? ' '}
						</span>
					</Typography>
					<Table
						containerHeight='25rem'
						variant={'users'}
						columns={usersColumns}
						data={getCostCenterDetailsQuery.data?.users_in_cost_center ?? []}
						idPath='user_id'
					/>
				</TableContainer>
			</QuantitiesContainer>
			<AddUserModal costCenter={costCenter!} />
		</div>
	);

	const ConfirmationModal = () => {
		return (
			<div>
				<Modal
					isOpen={isConfirmationModalOpen}
					onRequestClose={onConfirmDeletion}
				>
					<ConfirmationModalStyle>
						<ConfirmationCard
							titleComponent={
								<TitleWrapper>
									<span>Centro de custo</span>
									<span style={{ fontWeight: 600 }}>excluído com sucesso</span>
								</TitleWrapper>
							}
							direction='vertical'
							iconSrc={costDeleteCenterCostConfirmation}
						>
							<Button
								onClick={onConfirmDeletion}
								intent='terciary'
								$outline
								width='adjusted'
								type='button'
								style={{
									width: '11.3rem',
									height: '4.5rem',
									gap: '1.2rem',
									border: '1px solid #393939',
									borderRadius: '50rem',
									padding: '12px 32px 12px 32px',
								}}
							>
								Fechar
							</Button>
						</ConfirmationCard>
					</ConfirmationModalStyle>
				</Modal>
			</div>
		);
	};

	const EditFormContent = () => (
		<Form onSubmit={handleSubmit(handleSubmitUpdateCostCenter)}>
			<InputField
				label='Nome do centro de custo'
				id='title'
				name='title'
				disabled={!isEditable}
				register={register}
				errorMessage={formState.errors.title?.message}
				onChange={(e) => {
					setValue('title', e.target.value);
				}}
				style={{
					width: '70%',
					cursor: isEditable ? 'text' : 'default',
					color: isEditable ? '#000' : '#a0a0a0',
				}}
			/>
			<TextAreaField
				label='Descrição'
				id='description'
				name='description'
				disabled={!isEditable}
				register={register}
				errorMessage={formState.errors.description?.message}
				style={{
					height: '14rem',
					cursor: isEditable ? 'text' : 'default',
					color: isEditable ? '#000' : '#a0a0a0',
				}}
			/>
			<BottomOptions>
				<DeleteCostCenterModal
					costCenter={costCenter!.cost_center_data}
					costCenterCards={costCenter!.cards_in_cost_center}
					onDeleted={onCostCenterDelete}
					// costCenterCards={[]}
				/>
				{isEditable ? (
					<BottonEditOptions>
						<Button
							type='button'
							onClick={handleCancel}
							width='adjusted'
							roundness='lg'
						>
							Cancelar
						</Button>
						<Button
							type='submit'
							width='adjusted'
							roundness='lg'
							shrinkOnLoading={false}
							loading={editCostCenterMutation.isLoading}
							style={{ padding: '1.2rem 3.2rem 1.2rem 3.2rem' }}
						>
							Salvar alterações
						</Button>
					</BottonEditOptions>
				) : (
					<Button
						onClick={toggleEdit}
						type='button'
						width='adjusted'
						roundness='lg'
					>
						Editar centro de custo
					</Button>
				)}
			</BottomOptions>
		</Form>
	);

	return (
		<div>
			{blockNavigation && <PreventTransitionPrompt />}
			<SectionContainer>
				<Breadcrumb
					routes={[
						{ title: 'Gestão', route: '/corporate-expenses/management' },
						{
							title: 'Detalhes do centro de custo',
							route: `/corporate-expenses/management/cost-centers/${id}`,
						},
					]}
				/>
				<TitleStyle image>Detalhes do centro de custo</TitleStyle>
			</SectionContainer>

			<AsynchronousContent status={getCostCenterDetailsQuery.status}>
				{costCenter?.cost_center_data !== undefined && (
					<ContentContainer>
						<CardContainer>
							<CardHeaderContainer>
								<Typography color='#FFF'>Nome:</Typography>
								<Typography color='#FFF' size='1.8rem' weight='600'>
									{costCenter.cost_center_data.title}
								</Typography>
							</CardHeaderContainer>

							<CardContentContainer>
								<InfoContent label='Total de cartões ativos'>
									<CardQuantityContainer>
										{costCenter.cards_in_cost_center.length
											.toString()
											.padStart(2, '0')}
									</CardQuantityContainer>
								</InfoContent>
								<InfoContent label='Total de usuários alocados'>
									<CardQuantityContainer>
										{costCenter.users_in_cost_center.length
											.toString()
											.padStart(2, '0')}
									</CardQuantityContainer>
								</InfoContent>
							</CardContentContainer>
						</CardContainer>

						<SectionContainer
							style={{ width: '100%', minHeight: '58rem', overflow: 'hidden' }}
						>
							<TabsContainer>
								<Tabs
									tabs={[
										{
											component: <EditFormContent />,
											name: 'Informações',
										},
										{
											component: <QuantitiesContent />,
											name: 'Quantitativos',
										},
									]}
								/>
							</TabsContainer>
						</SectionContainer>
					</ContentContainer>
				)}
			</AsynchronousContent>
			{isConfirmationModalOpen && (
				<ConfirmationModal
				// onConfirm={onConfirmDeletion}
				/>
			)}
		</div>
	);
}

const ContentContainer = styled.div`
	display: flex;
	column-gap: 2rem;

	@media (max-width: 850px) {
		flex-direction: column;
		gap: 2rem;
	}
`;
const CardContainer = styled.div`
	border-radius: 1rem;
	width: 40rem;
	background-color: var(--white);
	& > div {
		padding: 2rem;
	}

	@media (max-width: 850px) {
		width: 100%;
	}
`;
const CardHeaderContainer = styled.div`
	display: flex;
	flex-direction: column;
	background-color: black;
	height: 22rem;
	border-radius: 1rem 1rem 0 0;
	justify-content: flex-end;
	row-gap: 1rem;
	position: relative;
	z-index: 0;
	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border-radius: 1rem 1rem 0rem 0rem;
		background: url(${CostCenterImage}) no-repeat center/cover;
		filter: opacity(1);
		filter: contrast(125%);
		z-index: -1;
	}

	@media (max-width: 850px) {
		height: 15rem;
	}
`;
const CardContentContainer = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 2rem;
	align-items: center;
	margin-top: 2rem;
	& > div {
		width: 100%;
	}

	& p {
		font-size: 1.5rem;
		text-align: center;
	}

	@media (max-width: 850px) {
		flex-direction: row;
		gap: 2rem;
	}
`;
const CardQuantityContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 1rem;
	border: 0.1rem solid var(--dark-gray);
	border-radius: 1rem;
	font-size: 1.6rem;
	font-weight: 600;
`;
const Form = styled.form`
	display: flex;
	flex-direction: column;
	row-gap: 2rem;

	& label {
		color: var(--mid-gray);
		font-size: 1.4rem;
	}

	@media (max-width: 850px) {
		& input {
			width: 100% !important;
		}
	}
`;
const BottomOptions = styled.div`
	display: flex;
	align-items: baseline;
	justify-content: space-between;
	margin-top: 11rem;

	@media (max-width: 850px) {
		flex-direction: column-reverse;
		gap: 2rem;
		align-items: center;
	}
`;

const BottonEditOptions = styled.div`
	display: flex;
	gap: 2rem;
`;

const ConfirmationModalStyle = styled.div`
	width: 100%;
	height: 30.5rem;
	radius: 1.6rem;
	padding: 0rem 0rem 3.2rem 0rem;
	gap: 3.2rem;
	background: #ffffff;
	overflow: hidden;
`;

const ConfirmationCard = styled(card)`
	width: 100%;
	& > div {
		height: 19.6rem;
		background-color: #f0f3f8;
	}
`;

const TitleWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	width: 44rem;
	& > span {
		font-family: Poppins;
		font-size: 2rem;
		font-weight: 400;
		line-height: 3rem;
		text-align: center;
	}
`;

const QuantitiesContainer = styled.div`
	display: flex;
	overflow: auto;
	gap: 2rem;
	&::-webkit-scrollbar {
		width: 1rem;
		height: 1rem;
	}

	&::-webkit-scrollbar-track {
		background-color: #e9eaeb;
		border-radius: 0.6rem;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #258bdb;
		border-radius: 0.6rem;
	}

	@media (max-width: 850px) {
		flex-direction: column;

		p {
			font-size: 1.5rem;
		}
	}
`;

const TabsContainer = styled.div`
	& ul {
		& button {
			font-size: 1.6rem;
		}
	}
`;

const TableContainer = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	padding: 3.2rem;
	gap: 3.2rem;
	min-width: 32rem;
	max-height: 37.2rem;
	overflow-x: hidden;
	overflow-y: hidden;
	background: #f0f3f8;
	border-radius: 1.6rem;
`;

const TitleStyle = styled(TitleTypography)`
	@media (max-width: 850px) {
		font-size: 3rem;
	}
`;
