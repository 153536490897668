import { Card } from '../../../@types/CorporateExpenses/Card';
import { User, UserDetails } from '../../../@types/CorporateExpenses/User';
import {
	SortColumn,
	parseSortColumnToParams,
} from '../../../hooks/useSortColumnHook';
import { UsersNewCard } from '../../../pages/CorporateExpenses/Management/Users/components/DeleteModal';
import api from '../../api';

export const CORPWAY_USERS_KEY = 'CORPWAY_USERS_SERVICE_KEY';

type UsersListReturn = {
	users: User[];
	total_users: number;
};

export type CardsOnlyUsers = {
	card_id: string;
	card_pan: string;
	card_alias: string;
	cost_center_id: string
};

export async function getUsersList(
	page?: number,
	sortColumn: SortColumn | null = null
) {
	const { data } = await api.get<UsersListReturn>(
		`/api/v1/rh/corpway/users/select`,
		{
			params: {
				page,
				...parseSortColumnToParams(sortColumn),
			},
		}
	);
	return data;
}

export async function getCorpwayUsersByCostCenter(
	cost_center_id: string,
) {
	const { data } = await api.get<User[]>(
		`/api/v1/rh/corpway/users/select-by-cost-center`,
		{
			params: {
				cost_center_id,
			},
		}
	);
	return data;
}

export async function createUser(data: FormData, companyId: string) {
	return await api.post(
		`/api/v1/rh/corpway/companies/${companyId}/users`,
		data
	);
}

export async function deleteUser(user_id: string) {
	return await api.delete(`/api/v1/rh/corpway/users/${user_id}`);
}

export async function getUserDetails(id: string) {
	const { data } = await api.get<UserDetails>(`/api/v1/rh/corpway/users/${id}`);
	return data;
}

export async function editUserPassword(
	old_password: string,
	password: string,
	confirm_password: string
) {
	return await api.put(`/api/v1/rh/corpway/users/profile/password`, {
		old_password,
		password,
		confirm_password,
	});
}

export async function updateProfileAvatar(formData: FormData): Promise<string> {
	const { data } = await api.put(
		`/api/v1/rh/corpway/users/profile/avatar`,
		formData
	);
	return data;
}

export async function editUser(data: FormData, user_id: string) {
	return await api.put(`/api/v1/rh/corpway/users/${user_id}`, data);
}

export async function getCardsOnlyUsers(user_id: string) {
	const { data } = await api.get<CardsOnlyUsers[]>(
		`/api/v1/rh/corpway/users/${user_id}/cards-only-users`
	);
	return data;
}

export async function editUserCard(users: UsersNewCard[], user_id: string) {
	const { data } = await api.patch(
		`/api/v1/rh/corpway/users/${user_id}/update-user-card`,
		{
			users,
		}
	);

	return data;
}

interface UsersTopExpensesReturn {
	usersTopExpenses: {
		id: string;
		name: string;
		email: string;
		avatar_url: string;
		role: string;
		cards: Card[];
		expenses: number;
	}[];
}
export async function getUserTopExpenses(companyId: string, date: Date) {
	const { data } = await api.get<UsersTopExpensesReturn>(
		`/api/v1/rh/corpway/companies/${companyId}/users/top-expenses/${date.getFullYear()}/${date.getMonth()}`
	);
	return data;
}
