import { ReactNode, createContext, useState } from "react";

interface GlobalStateContextProps{
    showBalance: boolean;
    setShowBalance: React.Dispatch<React.SetStateAction<boolean>>;
    toggleBalanceVisibility: () => void
};

interface GlobalStateProviderProps{
    children: ReactNode
};

export const GlobalStateContext = createContext({} as GlobalStateContextProps );

export function GlobalStateContextProvider({children}: GlobalStateProviderProps ){
    const [showBalance, setShowBalance] = useState(false);

    function toggleBalanceVisibility() {
		setShowBalance((oldState) => {
			return !oldState;
		});
	}

    return(
        <GlobalStateContext.Provider value={{
            showBalance,
            setShowBalance,
            toggleBalanceVisibility
        }}>
            {children}
        </GlobalStateContext.Provider>
    );
};