import { Beneficiary, BeneficiaryResponse, Transfer } from '../../../../../../@types/CorporateExpenses/Transfer';
import React, { useEffect, useMemo, useState } from 'react';
import { useAuth } from '../../../../../../hooks/useAuth';
import { useMutation, useQueryClient } from 'react-query';
import { useForm, UseFormReturn } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { createBeneficiary, createTransfer } from '../../../../../../services/queries/Corpway/Transfers';
import { showErrorMessage } from '../../../../../../utils/ErrorHandler';
import { cnpjMask, cpfMask, parseMaskedCnpjToRaw, parseMaskedCPFToRaw } from '../../../../../../utils/masks';
import { Button } from '../../../../../../componentsV2/ui/Button';
import Modal from '../../../../../../componentsV2/ui/Modal';
import * as S from '../styles';
import { z } from 'zod';
import { cnpj as cnpjValidator, cpf as cpfValidator } from 'cpf-cnpj-validator';
import { BankDataForm, Confirmation, InitialStep, MainDataForm, TokenValidation, TransferData, Success } from './Steps';
import { HourWarning, LimitWarning, SaveAccount, SaveSuccess } from './ExtraSteps';
import { AxiosError } from 'axios';
import { convertReaisToCents } from '../../../../../../utils/CurrencyConvert';
import { parseCurrencyStrToNumber } from '../../../../../../utils/parseCurrency';
import LimitSettingsModal from '../LimitSettingsModal';


export const defaultTransferValues: Transfer = {
	origin: 'corpway',
	type: 'ted',
	category: '',
	description: '',
	sender_name: '',
	pix_key: '',
	beneficiary: {
		name: '',
		document: '',
		type: undefined,
	},
	amount: '',
	token: '',
};

export interface StepProps {
	form: UseFormReturn<Transfer>;
	setStep: (value: 0 | 1 | 2 | 3 | 4 | 'Limit warning' | 'Hour Warning' | 5 | 6 | 'Save account' | 'Save success') => void;
}

const newTransferSchema = z
	.object({
		origin: z.enum(['corpway', 'benefits']),
		type: z.enum(['ted', 'pix']),
		description: z.string().min(1, { message: 'Selecione uma descrição' }),
		//ADD WHEN CUSTOM, DEFAULT IS COMPANY NAME
		//sender_name: z.string().min(1),

		//PIX RELATED
		//pix_key: z.string().min(1),
		//category: z.string().min(1),
		beneficiary: z.object({
			name: z.string().min(1, { message: 'O nome do favorecido é obrigatório' }),
			document: z.string().min(1, { message: 'CPF/CNPJ é obrigatório' }).refine(
				e => e.includes('/') ? cnpjValidator.isValid(e) : cpfValidator.isValid(e), { message: 'CPF/CNPJ inválido' }),
			bank_code: z.string()
				.min(3, { message: 'O código do banco é obrigatório' })
				.max(3, { message: 'O código do banco deve ter 3 dígitos' })
				.regex(/^\d+$/, 'O campo deve conter apenas números'),
			branch: z.string()
				.min(4, { message: 'O código completo da agência  é obrigatória' })
				.max(4, { message: 'A agência deve ter 4 dígitos' })
				.regex(/^\d+$/, 'O campo deve conter apenas números'),
			type: z.enum(['pf', 'pj']),
			account_number: z.string().min(2, { message: 'O número completo da conta é obrigatório' })
				.max(16, 'O número da conta deve ter no máximo 16 caracteres')
				.regex(/^[0-9-]+$/, 'O campo deve conter apenas números e um hífen')
				.refine(e => e.split("-")[1] ?? false, "Conta deve conter o dígito verificador"),
			account_type: z.string().min(1, { message: 'O tipo de conta é obrigatório' }),
		}),
		token: z.string().min(6, { message: 'O token é obrigatório' }),
		amount: z.string().min(1, { message: 'O valor é obrigatório' }),
		scheduled_date: z.string().optional(),
	});


export default function NewTransferModal({ beneficiaries, beneficiary }: {
	beneficiaries?: BeneficiaryResponse[],
	beneficiary?: Beneficiary
}) {
	const [step, setStep] = useState<0 | 1 | 2 | 3 | 4 | 'Limit warning' | 'Hour Warning' | 5 | 6 | 'Save account' | 'Save success'>(0);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isLimitModalOpen, setIsLimitModalOpen] = useState(false);
	const { currentCompany } = useAuth();
	const [schedule, setSchedule] = useState(false);
	const queryClient = useQueryClient();

	const form = useForm<Transfer>({
		defaultValues: defaultTransferValues,
		resolver: zodResolver(newTransferSchema),
	});

	const { watch } = form;
	const type = watch('beneficiary.type');
	const scheduled_date = watch('scheduled_date');

	const { setValue, reset, setError, clearErrors } = form;

	useEffect(() => {
		if (beneficiary) {
			setValue('beneficiary', beneficiary);
			setValue('beneficiary.document',
				beneficiary.type === 'pf' ?
					cpfMask(beneficiary.document!) : cnpjMask(beneficiary.document!));
			
			setStep(3);
		}
	}, [beneficiary, setValue]);

	const createBeneficiaryMutation = useMutation(
		['createBeneficiary', currentCompany?.id],
		(data: Beneficiary) => createBeneficiary(currentCompany?.id!, data), {
			onSuccess: () => {
				setStep('Save success');
				queryClient.invalidateQueries([
					'beneficiaries',
					currentCompany?.id,
				]);
			},
			onError: (err) => {
				showErrorMessage(err as Error, 'Não foi possível criar o favorecido.');
			},
		},
	);


	// eslint-disable-next-line react-hooks/exhaustive-deps
	function handleCreateBeneficiary() {
		const beneficiary = form.getValues('beneficiary');
		beneficiary.document = beneficiary.type === 'pf' ? parseMaskedCPFToRaw(beneficiary.document!) : parseMaskedCnpjToRaw(beneficiary.document!);
		createBeneficiaryMutation.mutate(beneficiary);
	}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const handleCloseAfterTransfer = () => {
		const beneficiaryToSave = form.getValues('beneficiary');
		beneficiaryToSave.document = beneficiaryToSave.type === 'pf' ? parseMaskedCPFToRaw(beneficiaryToSave.document!) : parseMaskedCnpjToRaw(beneficiaryToSave.document!);
		const isAlreadyAdded = beneficiaries?.some(b => b.document === beneficiaryToSave.document && b.bank_code === beneficiaryToSave.bank_code && b.account_number === beneficiaryToSave.account_number);
		const exists = beneficiaries?.find(b =>  type === 'pf' ?  parseMaskedCPFToRaw(b.document) === beneficiaryToSave.document : parseMaskedCnpjToRaw(b.document) === beneficiaryToSave.document)

		if (beneficiary || isAlreadyAdded || (exists && beneficiaryToSave.name?.toLowerCase() !== exists.name?.toLowerCase())) {
			resetForm();
		} else {
			setStep('Save account');
		}
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const transferDataOnBackPressed = () => {
		if (beneficiary) {
			resetForm();
		} else {
			setStep(2);
		}
	};

	const createTransferMutation = useMutation(
		['createTransfer', currentCompany?.id],
		(data: Transfer) => createTransfer(currentCompany!.id, data),
		{
			onSuccess: () => {
				setStep(6);
			},
			onError: (err) => {
				const error = err as AxiosError;
				if (error.response?.data.errorEntityId === 4100 && error.response?.data.messageId === 3) {
					setError('token', { message: 'Código incorreto. Verifique e tente novamente.' });
				} else {
					clearErrors('token');
					showErrorMessage(err as Error, 'Não foi possível realizar a transferência.');
				}
			},
		},
	);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const onSubmit = (data: Transfer) => {
		data.amount = convertReaisToCents(parseCurrencyStrToNumber(data.amount)).toString();
		data.beneficiary.document = data.beneficiary.type === 'pf' ? parseMaskedCPFToRaw(data.beneficiary.document!) : parseMaskedCnpjToRaw(data.beneficiary.document!);
		if (data.scheduled_date) {
			data.scheduled_date = new Date(data.scheduled_date);
		}
		if (data.scheduled_date == ""){
			data.scheduled_date = undefined;
		}
		createTransferMutation.mutate(data);
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const resetForm = () => {
		if (beneficiary) {
			reset(defaultTransferValues);
			setStep(3);
			setSchedule(false);
			setIsModalOpen(false);
			setValue('beneficiary', beneficiary);
			setValue('beneficiary.document',
				beneficiary.type === 'pf' ?
					cpfMask(beneficiary.document!) : cnpjMask(beneficiary.document!));
		} else {
			setStep(0);
			setIsModalOpen(false);
			reset(defaultTransferValues);
			setSchedule(false);
			setValue('scheduled_date', undefined);
		}
	};

	const Parts = useMemo(() => ({
		0: <InitialStep onPf={() => {
			setValue('beneficiary.type', 'pf');
			setStep(1);
		}} onPj={() => {
			setValue('beneficiary.type', 'pj');
			setStep(1);
		}} />,
		1: <MainDataForm form={form} setStep={setStep} />,
		2: <BankDataForm form={form} setStep={setStep} />,
		3: <TransferData
			form={form} setStep={setStep}
			onBack={transferDataOnBackPressed}
			schedule={schedule}
			setSchedule={setSchedule} />,
		4: <Confirmation
			form={form}
			setStep={setStep} />,
		5: <TokenValidation
			isLoading={createTransferMutation.isLoading}
			onSubmit={onSubmit}
			form={form}
			setStep={setStep} />,
		6: <Success onClose={handleCloseAfterTransfer} form={form} />,
		'Save account': <SaveAccount
			onConfirm={handleCreateBeneficiary}
			onCancel={resetForm}
			isNextLoading={createBeneficiaryMutation.isLoading} />,
		'Limit warning': <LimitWarning
			form={form}
			setStep={setStep}
			openLimit={() => setIsLimitModalOpen(true)} />,
		'Hour Warning': <HourWarning form={form} setStep={setStep} />,
		'Save success': <SaveSuccess onClose={resetForm} />,
	}), [
		form,
		handleCreateBeneficiary,
		handleCloseAfterTransfer,
		onSubmit,
		resetForm,
		schedule,
		setSchedule,
		setStep,
		setValue,
		transferDataOnBackPressed,
		createBeneficiaryMutation.isLoading,
		createTransferMutation.isLoading,
	]);

	const getTitle = useMemo(() => {
		if (step === 4 || step == 5) return scheduled_date ? 'Confirmação de agendamento' : 'Confirmação de transferência';
		if (step === 'Save account' || step === 'Save success') return 'Cadastro de novo favorecido';
		switch (type) {
			case undefined:
				return 'Nova transferência';
			case 'pf':
				return 'Transferência pessoa física';
			case 'pj':
				return 'Transferência pessoa jurídica';
		}
	}, [step, type]);

	return (
		<>
			<Button
				onClick={() => setIsModalOpen(true)}
				intent={'primary'}
				roundness={'lg'}>
				Nova transferência
			</Button>
			<LimitSettingsModal hiddeButton={true} isModalOpen={isLimitModalOpen} setIsModalOpen={setIsLimitModalOpen} />
			<Modal isOpen={isModalOpen} onRequestClose={resetForm}>
				<S.ModalHeader>{getTitle}</S.ModalHeader>
				<form>
					{Parts[step]}
				</form>
			</Modal>
		</>
	);
}