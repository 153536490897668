import styled from 'styled-components';
import { TitleTypography } from '../../../componentsV2/ui/Typography';

export const ContentContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 5rem;
	padding: 4.5rem 8rem 9rem 9rem;
	background-color: var(--white);
	border-radius: 1.6rem;
`;

export const CardsContent = styled.div`
	display: flex;
	width: 100%;
	flex-wrap: wrap;
	gap: 2rem;
`;

export const TitleStyle = styled(TitleTypography)`
	@media (max-width: 768px) {
		font-size: 2.5rem;
		white-space: nowrap;
	}
`;
