import styled from 'styled-components';
import { Card as CardType } from '../../../../@types/CorporateExpenses/Card';
import Modal from '../../../../componentsV2/ui/Modal';
import { InfoContent } from '../../../../componentsV2/InfoContent';
import { Button } from '../../../../componentsV2/ui/Button';
import { PasswordInput } from '../../../../componentsV2/ui/Form/PasswordInput';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { useValidateCorpwayUserPassword } from '../../../../hooks/useValidateCorpwayUserPassword';
import { useAuth } from '../../../../hooks/useAuth';
import { useMutation, useQueryClient } from 'react-query';
import {
	CORPWAY_CARDS_KEY,
	updateCardStatus,
} from '../../../../services/queries/Corpway/Cards';
import { CORPWAY_COST_CENTER_KEY } from '../../../../services/queries/Corpway/CostCenters';
import { showErrorMessage } from '../../../../utils/ErrorHandler';
import { FaCheck } from 'react-icons/fa';
import { formatCardNumber } from '../../../../utils/formatCardNumber';
import { TitleTypography } from '../../../../componentsV2/ui/Typography';

interface Props {
	card: CardType;
}

interface FormData {
	password: string;
}
export function ActivateCardConfirmationModal(props: Props) {
	const { currentCompany } = useAuth();
	const [isOpen, setOpen] = useState(false);
	const [isSuccess, setSuccess] = useState(false);
	const queryClient = useQueryClient();
	const {
		register,
		watch,
		reset,
		formState: { errors },
		setError,
	} = useForm<FormData>({
		defaultValues: {
			password: '',
		},
	});
	const passwordInputValue = watch('password');

	const { validateUserPassword, isPasswordValidationLoading } =
		useValidateCorpwayUserPassword({
			onSuccess: onPasswordValidationSuccess,
			onFailure: () =>
				setError('password', {
					type: 'manual',
					message: 'Senha incorreta, por favor digite novamente',
				}),
		});

	const updateStatusMutation = useMutation(
		() => updateCardStatus(props.card.id, 'active', false, currentCompany!.id),
		{
			onSuccess: () => {
				setSuccess(true);
			},
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível alterar o status do cartão. '
				);
			},
		}
	);

	function handleClose() {
		setOpen(false);
		reset();
	}

	function handleCloseSuccess() {
		queryClient.resetQueries(CORPWAY_CARDS_KEY);
		queryClient.invalidateQueries(CORPWAY_COST_CENTER_KEY);
		setSuccess(false);
		setOpen(false);
		reset();
	}

	function onPasswordValidationSuccess() {
		updateStatusMutation.mutate();
	}

	function handleSubmit() {
		validateUserPassword(passwordInputValue);
	}

	const SucessContent = () => {
		return (
			<Container>
				<HeaderContainer>
					<IconContainer>
						<FaCheck size={28} color='white' />
					</IconContainer>

					<TitleTypography size='2rem' primaryText='' weight='400'>
						Cartão&nbsp;<span style={{ fontWeight: 600 }}>desbloqueado</span>
						&nbsp;com sucesso
					</TitleTypography>
				</HeaderContainer>
				<BodyContainer style={{ height: '12rem' }}>
					<BottomOptionsContainer style={{ paddingTop: '3.5rem' }}>
						<Button
							intent='terciary'
							roundness='lg'
							$outline
							onClick={handleCloseSuccess}
							width='adjusted'
						>
							Voltar
						</Button>
					</BottomOptionsContainer>
				</BodyContainer>
			</Container>
		);
	};

	return (
		<>
			<Button roundness='lg' intent='primary' onClick={() => setOpen(true)}>
				Desbloquear cartão
			</Button>
			<Modal
				isOpen={isOpen}
				onRequestClose={() => {
					if (isSuccess) {
						handleCloseSuccess();
					} else {
						handleClose();
					}
				}}
			>
				{isSuccess ? (
					<SucessContent />
				) : (
					<Container>
						<HeaderContainer>
							<IconContainer>
								<FaCheck size={28} color='white' />
							</IconContainer>
							<TitleTypography
								size='2rem'
								color_primary='inherit'
								primaryText='Ativar'
							>
								<span style={{ fontWeight: 400 }}>cartão</span>
							</TitleTypography>
						</HeaderContainer>
						<BodyContainer>
							<CardInfoContainer>
								<InfoContent label='Nome do cartão'>
									{props.card.alias}
								</InfoContent>
								<InfoContent label='Número do cartão'>
									{formatCardNumber(props.card.pan)}
								</InfoContent>
							</CardInfoContainer>

							<ConfirmationModal>
								<PasswordInput
									label='Insira a sua senha'
									id='password'
									name='password'
									autoComplete='new-password'
									register={register}
									autoFocus
									errorMessage={errors.password?.message}
								/>
							</ConfirmationModal>

							<BottomOptionsContainer>
								<Button
									intent='terciary'
									roundness='lg'
									$outline
									onClick={handleClose}
								>
									Voltar
								</Button>
								<Button
									loading={
										isPasswordValidationLoading ||
										updateStatusMutation.isLoading
									}
									disabled={!passwordInputValue}
									roundness='lg'
									width='contain'
									style={{
										paddingLeft: '2rem',
										paddingRight: '2rem',
										width: '17rem',
									}}
									onClick={handleSubmit}
								>
									Efetuar ativação
								</Button>
							</BottomOptionsContainer>
						</BodyContainer>
					</Container>
				)}
			</Modal>
		</>
	);
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	height: fit-content;
	max-height: 90vh;
	width: 47rem;
	align-items: center;
	background: #f0f3f8;

	@media (max-width: 768px) {
		width: 100%;
	}
`;

const HeaderContainer = styled.div`
	padding: 3rem 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	row-gap: 1rem;
	width: 100%;
	height: 18.6rem;
`;

const BodyContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: center;
	background-color: var(--white);
	height: 30rem;
	flex-grow: 3rem;
	padding-top: 2rem;
`;

const BottomOptionsContainer = styled.div`
	display: flex;
	column-gap: 2rem;
	justify-content: center;
`;

const CardInfoContainer = styled.div`
	display: flex;
	justify-content: space-between;
	width: 80%;

	& div:last-child {
		align-items: flex-end;
	}
`;

const ConfirmationModal = styled.div`
	display: flex;
	flex-direction: column;
	padding: 3rem 0 3rem 0;
	width: 80%;
	gap: 2.4rem;
`;
const IconContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 4.5rem;
	height: 4.5rem;
	background-color: var(--primary-blue);
	border-radius: 50px;
`;
