import { useState } from 'react';
import { EmptyContent } from '../../../components/EmptyContent';
import Loader from '../../../components/Loader';
import PageTitle from '../../../components/PageTitle';
import * as S from './styles';
import * as TableStyle from '../../../components/Table/TableStyles';
import { CostCenterCreationModal } from '../../../components/CostCenterCreationModal';
import { useAuth } from '../../../hooks/useAuth';
import { Pagination } from '../../../components/Pagination';
import { Filter } from '../../../components/Filter';
import {
	CostCentersFilterReturn,
	CostCentersListReturn,
	deleteCostCenter,
	getCostCenters,
	getFilteredCostCenters,
} from '../../../services/queries/CostCenters';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { MdNotes } from 'react-icons/md';
import { showErrorMessage } from '../../../utils/ErrorHandler';
import { RiDeleteBin5Fill } from 'react-icons/ri';
import { toast } from 'react-toastify';
import { useCostCentersListStore } from '../../../stores/useCostCentersListStore';
import { useHistoryNonMatchCallback } from '../../../hooks/useHistoryNonMatchCallback';
import { useSortColumnHook } from '../../../hooks/useSortColumnHook';
import { SortColumnButton } from '../../../components/SortColumnButton';

export function CostCenters() {
	const { currentCompany } = useAuth();
	const queryClient = useQueryClient();
	const [costCentersCurrentPage, setCostCentersCurrentPage] = useState(1);
	const { currentSortColumn, toggleSort } = useSortColumnHook();
	const [filtersParams, setFiltersParams, resetFilters] =
		useCostCentersListStore((state) => [
			state.filtersParams,
			state.setFiltersParams,
			state.resetFilters,
		]);
	useHistoryNonMatchCallback('cost-centers', resetFilters);

	const getCostCentersQuery = useQuery<CostCentersListReturn, Error>(
		[
			'costCentersList',
			currentCompany?.id,
			costCentersCurrentPage,
			currentSortColumn,
		],
		() => {
			return getCostCenters(
				costCentersCurrentPage,
				currentCompany?.id,
				currentSortColumn
			);
		},
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um erro ao buscar os centros de custo. '
				);
			},
			keepPreviousData: true,
			refetchOnWindowFocus: false,
		}
	);

	const filteredCostCenters = useQuery<CostCentersFilterReturn, Error>(
		[
			'filteredCostCenters',
			currentCompany?.id,
			filtersParams,
			costCentersCurrentPage,
			currentSortColumn,
		],
		() => {
			return getFilteredCostCenters(
				filtersParams,
				currentCompany?.id,
				costCentersCurrentPage,
				currentSortColumn
			);
		},
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível buscar os centros de custos. '
				);
			},
			enabled:
				!!filtersParams.find((f) => !!f.value) && !!costCentersCurrentPage,
			refetchOnWindowFocus: false,
		}
	);

	function getTableTotalRecords() {
		if (filtersParams.find((f) => !!f.value)) {
			return filteredCostCenters.data?.totalCostCenters;
		}
		return getCostCentersQuery.data?.costCentersTotalCount;
	}

	function refreshPage() {
		setCostCentersCurrentPage(1);
		queryClient.resetQueries('costCentersList');
		queryClient.resetQueries('filteredCostCenters');
	}

	function getCostCentersList() {
		if (filtersParams.find((f) => !!f.value)) {
			return filteredCostCenters.data?.costCenters;
		}
		return getCostCentersQuery.data?.costCenters;
	}

	const deleteCostCenterMutation = useMutation(
		(costCenterId: string) =>
			deleteCostCenter(costCenterId, currentCompany?.id!),
		{
			onSuccess: () => {
				queryClient.invalidateQueries([
					'filteredCostCenters',
					currentCompany?.id,
					filtersParams,
					costCentersCurrentPage,
				]);
				queryClient.invalidateQueries([
					'costCentersList',
					currentCompany?.id,
					costCentersCurrentPage,
				]);
				toast.info('Centro de custo deletado com sucesso!');
			},
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao tentar deletar o centro de custo. '
				);
			},
		}
	);

	if (
		getCostCentersQuery.isLoading ||
		filteredCostCenters.isLoading ||
		getCostCentersQuery.isPreviousData
	) {
		return (
			<S.Container>
				<PageTitle title='Centros de custo' />
				<Loader />
			</S.Container>
		);
	}
	if (!getCostCentersList()?.length) {
		return (
			<S.Container>
				<TableStyle.TableHeaderContainer>
					<TableStyle.TitleWrapper>
						<PageTitle title='Centros de custo' />
						<Filter
							filterParams={filtersParams}
							onFiltersChanged={(updatedFilters) => {
								setCostCentersCurrentPage(1);
								setFiltersParams(updatedFilters);
							}}
						/>
						<TableStyle.ReloadIcon type='reload' onClick={refreshPage} />
					</TableStyle.TitleWrapper>
				</TableStyle.TableHeaderContainer>
				<EmptyContent text='Nenhum centro de custo para ser exibido' />
				<CostCenterCreationModal />
			</S.Container>
		);
	}
	return (
		<S.Container>
			<TableStyle.TableHeaderContainer>
				<TableStyle.TitleWrapper>
					<PageTitle
						title='Centros de custo'
						totalRecords={getTableTotalRecords() ?? 0}
					/>
					<Filter
						filterParams={filtersParams}
						onFiltersChanged={(updatedFilters) => {
							setCostCentersCurrentPage(1);
							setFiltersParams(updatedFilters);
						}}
					/>
				</TableStyle.TitleWrapper>
				{filtersParams.find((f) => !!f.value) ? (
					<Pagination
						onPageChange={(page) => setCostCentersCurrentPage(page)}
						currentPage={costCentersCurrentPage}
						totalCount={
							filteredCostCenters.data
								? filteredCostCenters.data.totalCostCenters!
								: 1
						}
					/>
				) : (
					<Pagination
						onPageChange={(page) => setCostCentersCurrentPage(page)}
						currentPage={costCentersCurrentPage}
						totalCount={
							getCostCentersQuery.data
								? getCostCentersQuery.data.costCentersTotalCount
								: 1
						}
					/>
				)}
				<TableStyle.ReloadIcon type='reload' onClick={refreshPage} />
			</TableStyle.TableHeaderContainer>
			<TableStyle.Table>
				<TableStyle.TableHeader>
					<TableStyle.TableRow>
						<TableStyle.TableHeaderCell>
							<SortColumnButton
								order={
									currentSortColumn?.name === 'title'
										? currentSortColumn.order
										: null
								}
								onToggleSort={() => toggleSort('title')}
							/>
							TÍTULO
						</TableStyle.TableHeaderCell>
						<TableStyle.TableHeaderCell>
							<SortColumnButton
								order={
									currentSortColumn?.name === 'collabs_quantity'
										? currentSortColumn.order
										: null
								}
								onToggleSort={() => toggleSort('collabs_quantity')}
							/>
							COLABORADORES
						</TableStyle.TableHeaderCell>
						<TableStyle.TableHeaderCell>
							<SortColumnButton
								order={
									currentSortColumn?.name === 'description'
										? currentSortColumn.order
										: null
								}
								onToggleSort={() => toggleSort('description')}
							/>
							DESCRIÇÃO
						</TableStyle.TableHeaderCell>
						<TableStyle.TableHeaderCell>OPÇÕES</TableStyle.TableHeaderCell>
					</TableStyle.TableRow>
				</TableStyle.TableHeader>

				<TableStyle.TableBody>
					{getCostCentersList()?.map((costCenter) => (
						<TableStyle.TableRow key={costCenter.id}>
							<TableStyle.TableData
								style={{ maxWidth: '20rem', whiteSpace: 'nowrap' }}
							>
								{costCenter.title}
							</TableStyle.TableData>
							<TableStyle.TableData>
								{costCenter.users.length}
							</TableStyle.TableData>
							<TableStyle.TableData style={{ textAlign: 'justify' }}>
								{costCenter.description}
							</TableStyle.TableData>
							<TableStyle.TableData>
								<S.OptionsContainer>
									<TableStyle.OptionLink
										data-rh='Detalhes do centro de custo'
										to={`cost-centers/details/${costCenter.id}`}
									>
										<MdNotes />
									</TableStyle.OptionLink>

									<S.DeleteCostCenterBtn
										data-rh={
											costCenter.users.length > 0
												? 'Impossível deletar centro de custo pois ainda há colaboradores associados. Remova-os do centro de custo e tente novamente.'
												: 'Deletar centro de custo'
										}
										disabled={costCenter.users.length > 0}
										onClick={() => {
											deleteCostCenterMutation.mutate(costCenter.id);
										}}
									>
										<RiDeleteBin5Fill />
									</S.DeleteCostCenterBtn>
								</S.OptionsContainer>
							</TableStyle.TableData>
						</TableStyle.TableRow>
					))}
				</TableStyle.TableBody>
			</TableStyle.Table>

			<CostCenterCreationModal />
		</S.Container>
	);
}
