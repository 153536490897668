import { useState } from 'react';
import { Button } from '../../../../../componentsV2/ui/Button';
import styled from 'styled-components';
import Modal from '../../../../../componentsV2/ui/Modal';
import { SelectField } from '../../../../../componentsV2/ui/Form/SelectField';
import { Typography } from '../../../../../componentsV2/ui/Typography';
import { InfoContent } from '../../../../../componentsV2/InfoContent';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { getUsersList } from '../../../../../services/queries/Corpway/User';
import { showErrorMessage } from '../../../../../utils/ErrorHandler';
import { CostCenterDetails } from '../../../../../@types/CorporateExpenses/CostCenter';
import { useForm } from 'react-hook-form';
import { CorpwayUserAccessLevel } from '../../../../../@types/CorporateExpenses/User';
import { parseUserAccessLevel } from '../../../../../utils/corpway/parseUserAccessLevel';
import { useAuth } from '../../../../../hooks/useAuth';
import {
	CORPWAY_COST_CENTER_KEY,
	updateUserToCostCenter,
} from '../../../../../services/queries/Corpway/CostCenters';
import HeaderModal from '../../../../../componentsV2/ui/Modal/HeaderModal';
import { IoPerson } from 'react-icons/io5';
import { FaCheck } from 'react-icons/fa';

interface Props {
	costCenter: CostCenterDetails;
}

interface FormData {
	user: string;
}

const AddUserModal = ({ costCenter }: Props) => {
	const { currentCompany } = useAuth();
	const queryClient = useQueryClient();
	const [isOpen, setOpen] = useState(false);
	const [isAllocated, setAllocated] = useState(false);
	const [allocatedConfirmation, setAllocatedConfirmation] = useState(false);
	const { register, watch, reset } = useForm<FormData>();

	const getUserListQuery = useQuery(
		[CORPWAY_COST_CENTER_KEY],
		() => getUsersList(),
		{
			onError: (err) => {
				showErrorMessage(err as Error, 'Não foi possível buscar os usuários. ');
			},
		}
	);

	const users = getUserListQuery.data?.users;
	const filteredUsers = users?.filter(
		(user) =>
			!costCenter.users_in_cost_center.some(
				(costCenterUser) => costCenterUser.user_id === user.id
			) &&
			user.role !== 'general_admin' &&
			user.role !== 'finance'
	);

	const selectedUserId = watch('user');
	const selectedUser = users?.find((user) => user.id === selectedUserId);

	const updateUserCostCenterMutation = useMutation(
		(data: FormData) =>
			updateUserToCostCenter(
				costCenter.cost_center_data.id,
				data.user,
				currentCompany!.id
			),
		{
			onSuccess: () => {
				setAllocatedConfirmation(true);
			},
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível editar o centro de custo. '
				);
			},
		}
	);

	function handleSubmit() {
		updateUserCostCenterMutation.mutate({ user: selectedUserId });
	}

	function handleOpenModal() {
		setOpen(true);
	}

	function handleClose() {
		setOpen(false);
		setAllocated(false);
		setAllocatedConfirmation(false);
		reset();
		queryClient.invalidateQueries(CORPWAY_COST_CENTER_KEY);
	}

	const AllocateUser = () => {
		return (
			<ContentContainer>
				<HeaderModal
					icon={{
						icon: <IoPerson size={28} color='white' />,
						background: 'var(--primary-blue)',
					}}
					title={[
						{ text: 'Alocar ', isBold: true },
						{ text: 'novo usuário', isBold: false },
					]}
				/>
				<InformationContainer>
					<SelectField
						label='Usuário'
						name='user'
						id='user'
						placeholderText={false}
						required
						register={register}
					>
						<option value='' disabled>
							Selecione um usuário
						</option>

						{filteredUsers?.map((user) => (
							<option key={user.id} value={user.id}>
								{user.name}
							</option>
						))}
					</SelectField>

					<InformationContent>
						<InfoContent label='Papel do usuário'>
							{selectedUser
								? parseUserAccessLevel(
										selectedUser.role as CorpwayUserAccessLevel
								  )
								: '--'}
						</InfoContent>
						<InfoContent label='Centro de custo'>
							{costCenter.cost_center_data.title}
						</InfoContent>
					</InformationContent>

					<Typography
						size='1.4rem'
						color='var(--dark-gray)'
						style={{ textAlign: 'justify', display: 'block' }}
					>
						Usuários que já possuem um centro de custo,{' '}
						<span style={{ fontWeight: 600 }}> não serão removidos </span> dos
						centros antigos, somente serão adicionados também neste.
					</Typography>

					<OptionsBottonContainer>
						<Button
							intent='terciary'
							roundness='lg'
							$outline
							onClick={handleClose}
						>
							Voltar
						</Button>
						<Button
							intent='primary'
							roundness='lg'
							disabled={!selectedUserId}
							onClick={() => setAllocated(true)}
						>
							Alocar usuário
						</Button>
					</OptionsBottonContainer>
				</InformationContainer>
			</ContentContainer>
		);
	};

	const ConfirmationContent = () => {
		return (
			<ContentContainer>
				<HeaderModal
					icon={{
						icon: <FaCheck size={28} color='white' />,
						background: 'var(--primary-blue)',
					}}
					title={[
						{ text: 'Você tem certeza ', isBold: true },
						{ text: 'que deseja:', isBold: false },
					]}
				/>
				<InformationContainer>
					<Typography
						size='1.4rem'
						style={{ textAlign: 'center', display: 'block' }}
					>
						Alocar o usuário <span> “{selectedUser?.name}”</span> ao centro de
						custo <span>“{costCenter.cost_center_data.title}”</span>
					</Typography>
					<OptionsBottonContainer>
						<Button
							intent='terciary'
							roundness='lg'
							$outline
							onClick={() => setAllocated(false)}
						>
							Voltar
						</Button>
						<Button
							intent='primary'
							roundness='lg'
							type='button'
							onClick={handleSubmit}
						>
							Confirmar
						</Button>
					</OptionsBottonContainer>
				</InformationContainer>
			</ContentContainer>
		);
	};

	const SucessContent = () => {
		return (
			<ContentContainer>
				<HeaderModal
					icon={{
						icon: <FaCheck size={28} color='white' />,
						background: 'var(--primary-blue)',
					}}
					title={[
						{ text: 'Usuário alocado ', isBold: true },
						{ text: 'com sucesso', isBold: false },
					]}
				/>
				<InformationContainer>
					<OptionsBottonContainer>
						<Button
							intent='terciary'
							roundness='lg'
							$outline
							onClick={handleClose}
						>
							Voltar
						</Button>
					</OptionsBottonContainer>
				</InformationContainer>
			</ContentContainer>
		);
	};

	const Content = () => {
		if (!isAllocated) {
			return <AllocateUser />;
		} else if (isAllocated && !allocatedConfirmation) {
			return <ConfirmationContent />;
		} else if (allocatedConfirmation) {
			return <SucessContent />;
		}
	};

	return (
		<>
			<ButtonContainer>
				<ButtonComponentStyle
					intent='link'
					type='button'
					onClick={handleOpenModal}
				>
					+ Adicionar usuário
				</ButtonComponentStyle>
			</ButtonContainer>

			<Modal isOpen={isOpen} onRequestClose={handleClose}>
				{Content()}
			</Modal>
		</>
	);
};

export default AddUserModal;

const ButtonContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	padding-top: 2rem;

	@media (max-width: 850px) {
		justify-content: center;
	}
`;

const ButtonComponentStyle = styled(Button)`
	border-bottom: 1px solid var(--primary-blue);
	color: var(--primary-blue);
`;

const InformationContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding: 2rem;
	gap: 1rem;

	& span {
		font-weight: 600;
	}

	& label,
	select {
		color: var(--dark-gray);
	}
`;

const InformationContent = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 1rem 0rem 1rem 0rem;
	gap: 10rem;

	@media (max-width: 850px) {
		gap: 12rem;
	}
`;

const ContentContainer = styled.div`
	width: 44.4rem;
	display: flex;
	flex-direction: column;
	row-gap: 1rem;

	@media (max-width: 1024px) {
		width: 100%;
	}
`;

const OptionsBottonContainer = styled.div`
	display: flex;
	justify-content: space-around;
	margin-top: 2rem;
`;
