import styled from 'styled-components';
import { OptionsButton } from '../../../../components/Button';

export const Container = styled.div`
	width: 50rem;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const Title = styled.h1`
	text-align: center;
`;

export const OptionsContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin: 2rem 0;
	width: 100%;
`;

interface OptionInterface {
	selected: boolean;
}
export const Option = styled.div<OptionInterface>`
	display: flex;
	flex-direction: column;
	font-size: 1.4rem;
	padding: 1.2rem 0.5rem;
	background-color: ${({ selected }) =>
		selected ? '#E5E5E5' : 'var(--white)'};
	border-bottom: 0.1rem solid var(--light-gray);
	cursor: pointer;
	transition: filter 0.2s;

	&:hover {
		filter: brightness(0.9);
	}

	${({ selected }) => (selected ? '&:hover { filter: none; }' : '')}

	& > span {
		color: var(--dark-gray);
	}
`;

export const OptionHeader = styled.div`
	margin-bottom: 0.25rem;
	color: var(--dark-gray);
	display: flex;
	column-gap: 2rem;
	font-weight: 500;
	font-size: 1.8rem;
	align-items: center;
`;

export const FooterOptionsContainer = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
`;

export const MainBtn = styled.button`
	${OptionsButton}
	width: 18rem;
`;

export const BackBtn = styled.button`
	${OptionsButton}
	background-color: var(--white);
	color: var(--primary-blue);
	width: 18rem;
`;

export const CustomAddressOpenBtn = styled.span`
	background: none;
	color: var(--primary-blue);
	font-size: 1.4rem;
`;
