import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
	FaSignOutAlt,
	FaCog,
	FaWallet,
	FaUsers,
	FaUserCog,
	FaUser,
	FaEye,
	FaEyeSlash,
	FaBell,
	FaBuilding,
	FaEdit,
} from 'react-icons/fa';
import logoImg from '../../assets/Bounty.svg';
import { useAuth } from '../../hooks/useAuth';
import * as S from './styles';
import { SidebarLink } from '../SidebarLink';
import { db, firebaseAuth } from '../../services/firebase';
import { child, get, ref, set, update } from 'firebase/database';
import { useNotifications } from '../../hooks/useNotifications';
import { NotificationsList } from '../NotificationsList';
import { HeaderPopover } from './HeaderPopover';
import { signOut as firebaseSignOut } from 'firebase/auth';
import { parseCurrencyToBRLStandard } from '../../utils/parseCurrency';
import { RiListSettingsLine } from 'react-icons/ri';
import { CollaboratorsImportList } from '../CollaboratorsImportList';
import { MdOutlineKeyboardArrowDown } from 'react-icons/md';

type Props = {
	name: string;
	email: string;
	avatar: string;
};

export function Header({ name, email, avatar }: Props) {
	const { pathname } = useLocation();
	const {
		user,
		currentCompany,
		companies,
		changeCurrentCompany,
		signOut,
		companyBalance,
		firebaseAuthConcluded,
	} = useAuth();
	const { unreadNotifications } = useNotifications();
	const [showBalance, setShowBalance] = useState(false);
	const [isOptionsDropdownShowing, setIsOptionsDropdownShowing] =
		useState(false);
	const [isUserDropdownShowing, setIsUserDropdownShowing] = useState(false);
	const [isCompaniesDropdownShowing, setIsCompaniesDropdownShowing] =
		useState(false);
	const [isNotificationDropdownShowing, setIsNotificationDropdownShowing] =
		useState(false);

	const [isProductPopoverOpen, setProductPopoverOpen] = useState(false);
	const isCorporateExpensesAvailable =
		user.corporate_expenses_access &&
		currentCompany?.company_products.product_corporate_expenses_enabled;

	function handleToggleOpenProductPopover() {
		setProductPopoverOpen(!isProductPopoverOpen);
	}

	useEffect(() => {
		const openNotifications = (e: MessageEvent) => {
			if (e.data.type === 'background-notification-click-event')
				setIsNotificationDropdownShowing(true);
		};

		navigator.serviceWorker.addEventListener('message', openNotifications);

		return () => {
			navigator.serviceWorker.removeEventListener('message', openNotifications);
		};
	}, []);

	function getAccessName(access_level: string) {
		let res = access_level;
		if (access_level === 'operator') {
			res = 'Operador';
		} else if (access_level === 'super_admin') {
			res = 'Super Admin';
		} else if (access_level === 'admin') {
			res = 'Admin';
		}
		return res;
	}

	async function logOutFirebase() {
		if (!firebaseAuthConcluded) return;

		await Promise.allSettled(
			companies.map(async (company) => {
				await setStatus('offline', company.id);
				return true;
			})
		);

		await firebaseSignOut(firebaseAuth);
	}

	async function setStatus(status: string, companyId: string) {
		const operatorsRef = ref(db, `${companyId}/operators`);
		try {
			await get(operatorsRef).then((snapshot) => {
				if (!snapshot.hasChild(firebaseAuth.currentUser?.uid!)) {
					const newOperatorRef = child(
						operatorsRef,
						firebaseAuth.currentUser?.uid!
					);
					set(newOperatorRef, {
						id: user.id,
						name: user.name,
						email: user.email,
						jobDescription: user.office,
						profileUrl: user.avatar_url ? user.avatar_url : '',
						status: 'offline',
					});
				} else {
					const newOperatorRef = child(
						operatorsRef,
						firebaseAuth.currentUser?.uid!
					);
					update(newOperatorRef, {
						id: user.id,
						name: user.name,
						email: user.email,
						jobDescription: user.office,
						status: status,
					});
				}
			});
		} catch (err) {
			console.log(err);
		}
	}

	function toggleBalanceVisibility() {
		setShowBalance((oldState) => {
			return !oldState;
		});
	}

	return (
		<S.Container>
			{/* LEFT SIDE */}
			<div>
				<Link
					to={user.access_level !== 'super_admin' ? '/home' : '/super-admin'}
				>
					<S.LogoImage src={logoImg} alt='Logo do Bounty' />
				</Link>

				<HeaderPopover // COMPANIES DROPDOWN
					isOpen={isCompaniesDropdownShowing}
					onClose={() => setIsCompaniesDropdownShowing(false)}
					content={
						<S.DropdownContainer style={{ padding: '0' }}>
							<S.OptionLink
								to={
									user.access_level === 'super_admin'
										? '/super-admin/company'
										: '/home/company'
								}
								style={{ margin: '1rem 0' }}
							>
								<FaEdit />
								Ver detalhes
							</S.OptionLink>

							{companies.length > 1 && (
								<>
									<S.CompaniesListTitle>
										Selecione uma empresa
									</S.CompaniesListTitle>

									<S.CompaniesList>
										{companies
											.filter((c) => c.id !== currentCompany?.id)
											.map((company) => (
												<S.CompanyContainer key={company.id}>
													<button
														onClick={() => {
															changeCurrentCompany(company.id);
														}}
													>
														<FaBuilding />
														{company.name}
													</button>
												</S.CompanyContainer>
											))}
									</S.CompaniesList>
								</>
							)}
						</S.DropdownContainer>
					}
				>
					<S.CompanyDropdowBtn
						onClick={() =>
							setIsCompaniesDropdownShowing(!isCompaniesDropdownShowing)
						}
					>
						<S.CompanyLogo
							src={currentCompany?.avatar_url}
							alt={`Logo da ${currentCompany?.name}`}
						/>
						{currentCompany?.name}
					</S.CompanyDropdowBtn>
				</HeaderPopover>

				{isCorporateExpensesAvailable && (
					<HeaderPopover
						isOpen={isProductPopoverOpen}
						onClose={handleToggleOpenProductPopover}
						content={
							<S.DropdownContainer style={{ padding: '0' }}>
								<S.OptionLink
									to='/corporate-expenses'
									style={{ margin: '1rem ' }}
								>
									Control
								</S.OptionLink>
							</S.DropdownContainer>
						}
					>
						<S.ProductDropdowBtn onClick={handleToggleOpenProductPopover}>
							Benefícios Flexíveis
							<MdOutlineKeyboardArrowDown />
						</S.ProductDropdowBtn>
					</HeaderPopover>
				)}
			</div>

			{/* RIGHT SIDE */}
			<div>
				<S.CompanyBalanceContainer showBalance={showBalance}>
					Saldo de carteira:{' '}
					<span>
						{parseCurrencyToBRLStandard(
							Number(companyBalance.multiflex_balance.toFixed(2))
						)}
					</span>
					<S.ToggleBalanceVisibilityBtn onClick={toggleBalanceVisibility}>
						{showBalance ? <FaEye /> : <FaEyeSlash />}
					</S.ToggleBalanceVisibilityBtn>
				</S.CompanyBalanceContainer>

				<HeaderPopover // NOTIFICATIONS DROPDOWN
					zIndex={"5"}
					isOpen={isNotificationDropdownShowing}
					onClose={() => setIsNotificationDropdownShowing(false)}
					content={() => (
						<S.DropdownNotificationsContainer>
							<NotificationsList open={isNotificationDropdownShowing} />
						</S.DropdownNotificationsContainer>
					)}
				>
					<S.IconBtn
						onClick={() =>
							setIsNotificationDropdownShowing(!isNotificationDropdownShowing)
						}
					>
						<FaBell />{' '}
						{!!unreadNotifications && (
							<span
								data-rh={
									unreadNotifications > 99
										? `${unreadNotifications}`
										: undefined
								}
							>
								{unreadNotifications > 99 ? '99+' : unreadNotifications}
							</span>
						)}
					</S.IconBtn>
				</HeaderPopover>

				{user.access_level !== 'super_admin' && <CollaboratorsImportList />}

				{user.access_level !== 'super_admin' && (
					<HeaderPopover // OPTIONS-MENU DROPDOWN
						isOpen={isOptionsDropdownShowing}
						onClose={() => setIsOptionsDropdownShowing(false)}
						content={
							<S.DropdownContainer>
								<S.OptionsContainer>
									<SidebarLink
										icon={FaWallet}
										path='/home/benefits'
										title='Benefícios'
										current_page={pathname.includes('benefits')}
									/>
									<SidebarLink
										icon={FaUsers}
										path='/home/groups'
										title='Grupos de colaboradores'
										current_page={pathname.includes('groups')}
									/>

									{user.access_level !== 'operator' && (
										<SidebarLink
											icon={FaUserCog}
											path='/home/operators'
											title='Usuários do sistema'
											current_page={pathname.includes('operators')}
										/>
									)}

									<SidebarLink
										icon={RiListSettingsLine}
										path='/home/settings'
										title='Sistema'
										current_page={pathname.includes('settings')}
									/>
								</S.OptionsContainer>
							</S.DropdownContainer>
						}
					>
						<S.IconBtn
							onClick={() =>
								setIsOptionsDropdownShowing(!isOptionsDropdownShowing)
							}
							style={{
								color: 'var(--dark-gray)',
								background: 'var(--light-gray)',
							}}
						>
							<FaCog />
						</S.IconBtn>
					</HeaderPopover>
				)}

				<HeaderPopover // PROFILE DROPDOWN
					isOpen={isUserDropdownShowing}
					onClose={() => setIsUserDropdownShowing(false)}
					content={
						<S.DropdownContainer style={{ marginRight: '1rem' }}>
							{user.avatar_url ? (
								<S.UserImage src={user.avatar_url} alt='Imagem do Usuário' />
							) : (
								<S.UserImage src={avatar} alt='Imagem do Usuário' />
							)}

							<S.UserDetails>
								<S.ProfileTextNameDrop>{name}</S.ProfileTextNameDrop>
								<S.ProfileTextDrop>
									{user.access_level === 'super_admin' ? '' : user.office}
								</S.ProfileTextDrop>
								<S.ProfileTextEmailDrop>{email}</S.ProfileTextEmailDrop>
							</S.UserDetails>

							<S.OptionLink
								to={
									user.access_level === 'super_admin'
										? '/super-admin/profile'
										: '/home/profile'
								}
							>
								<FaUser />
								Editar perfil
							</S.OptionLink>

							<S.Separator />

							<S.ProfileOptionButton
								onClick={async () => {
									await logOutFirebase();
									signOut();
								}}
							>
								<FaSignOutAlt />
								Sair
							</S.ProfileOptionButton>
						</S.DropdownContainer>
					}
				>
					<S.ProfileBtn
						onClick={() => setIsUserDropdownShowing(!isUserDropdownShowing)}
					>
						<S.UserDetails>
							<S.TextName>{name}</S.TextName>
							<S.TextAccessLevel>
								{getAccessName(user.access_level)}
							</S.TextAccessLevel>
						</S.UserDetails>
						{user.avatar_url ? (
							<S.UserImageSmall src={user.avatar_url} alt='Imagem do Usuário' />
						) : (
							<S.UserImageSmall src={avatar} alt='Imagem do Usuário' />
						)}
					</S.ProfileBtn>
				</HeaderPopover>
			</div>
		</S.Container>
	);
}
