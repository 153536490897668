import { create } from 'zustand';
import { FilterParams } from '../components/Filter';
import {
	CollaboratorFormStatusOptionsFilters,
	CollaboratorsWithCardsOptionsFilters,
	ContractTypeOptionsFilters,
	KYCStatusOptionsFilters,
} from '../components/Filter/utils/SelectFilters';

type Store = {
	activeCollabsFiltersParams: FilterParams[];
	setActiveCollabsFiltersParams: (f: FilterParams[]) => void;
	inactiveCollabsFiltersParams: FilterParams[];
	setInactiveCollabsFiltersParams: (f: FilterParams[]) => void;
	resetFilters: () => void;
};

const activeCollabsFiltersParams: FilterParams[] = [
	{
		name: 'Nome',
		filter: 'name',
		value: '',
		type: 'text',
		selected: false,
	},
	{
		name: 'Nome da mãe',
		filter: 'mother_name',
		value: '',
		type: 'text',
		selected: false,
	},
	{ name: 'CPF', filter: 'cpf', value: '', type: 'cpf', selected: false },
	{
		name: 'Email',
		filter: 'email',
		value: '',
		type: 'email',
		selected: false,
	},
	{
		name: 'Cargo',
		filter: 'office',
		value: '',
		type: 'text',
		selected: false,
	},
	{
		name: 'Setor de atuação',
		filter: 'section',
		value: '',
		type: 'text',
		selected: false,
	},
	{ name: 'UF', filter: 'uf', value: '', type: 'uf', selected: false },
	{
		name: 'Contrato',
		filter: 'contract_type',
		value: '',
		type: 'select',
		selectOptions: ContractTypeOptionsFilters,
		selected: false,
	},
	{
		name: 'Centro de custo',
		filter: 'cost_center',
		value: '',
		type: 'cost_center',
		selected: false,
	},
	{
		name: 'Status do formulário',
		filter: 'form_status',
		value: '',
		type: 'select',
		selectOptions: CollaboratorFormStatusOptionsFilters,
		selected: false,
	},
	{
		name: 'Cartões',
		filter: 'card',
		value: '',
		type: 'select',
		selectOptions: CollaboratorsWithCardsOptionsFilters,
		selected: false,
	},
	{
		name: 'Filial',
		filter: 'branch_office',
		value: '',
		type: 'text',
		selected: false,
	},
	{
		name: 'Exclusão solicitada',
		filter: 'request_delete',
		value: '',
		type: 'toggle',
		selected: false,
	},
];

const inactiveCollabsFiltersParams: FilterParams[] = [
	{
		name: 'Nome',
		filter: 'name',
		value: '',
		type: 'text',
		selected: false,
	},
	{
		name: 'Nome da mãe',
		filter: 'mother_name',
		value: '',
		type: 'text',
		selected: false,
	},
	{ name: 'CPF', filter: 'cpf', value: '', type: 'cpf', selected: false },
	{
		name: 'Email',
		filter: 'email',
		value: '',
		type: 'email',
		selected: false,
	},
	{
		name: 'Cargo',
		filter: 'office',
		value: '',
		type: 'text',
		selected: false,
	},
	{
		name: 'Setor de atuação',
		filter: 'section',
		value: '',
		type: 'text',
		selected: false,
	},
	{ name: 'UF', filter: 'uf', value: '', type: 'uf', selected: false },
	{
		name: 'Contrato',
		filter: 'contract_type',
		value: '',
		type: 'select',
		selectOptions: ContractTypeOptionsFilters,
		selected: false,
	},
	{
		name: 'Centro de custo',
		filter: 'cost_center',
		value: '',
		type: 'cost_center',
		selected: false,
	},
	{
		name: 'Status KYC',
		filter: 'kyc_status',
		value: '',
		type: 'select',
		selectOptions: KYCStatusOptionsFilters,
		selected: false,
	},
	{
		name: 'Status do formulário',
		filter: 'form_status',
		value: '',
		type: 'select',
		selectOptions: CollaboratorFormStatusOptionsFilters,

		selected: false,
	},
	{
		name: 'Cartões',
		filter: 'card',
		value: '',
		type: 'select',
		selectOptions: CollaboratorsWithCardsOptionsFilters,
		selected: false,
	},
	{
		name: 'Filial',
		filter: 'branch_office',
		value: '',
		type: 'text',
		selected: false,
	},
];

const useCollaboratorsListStore = create<Store>()((set) => ({
	activeCollabsFiltersParams,
	setActiveCollabsFiltersParams: (f) => set({ activeCollabsFiltersParams: f }),
	inactiveCollabsFiltersParams,
	setInactiveCollabsFiltersParams: (f) =>
		set({ inactiveCollabsFiltersParams: f }),
	resetFilters: () =>
		set({
			activeCollabsFiltersParams,
			inactiveCollabsFiltersParams,
		}),
}));

export { useCollaboratorsListStore };
