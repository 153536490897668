import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Shareholder } from '../../../@types';
import { useCompanyForm } from '../../../hooks/useCompanyForm';
import {
	cepMask,
	cpfMask,
	parseDateToInput,
	phoneMask,
} from '../../../utils/masks';
import Loader from '../../Loader';
import {
	ObrigatoryFieldsIndication,
	ObrigatoryIndicator,
} from '../../ObrigatoryFieldsIndicator';
import * as FormStyles from '../../Form/FormStyles';
import * as S from './styles';
import { subtractYears } from '../../../utils/parseDate';
import {
	KYCFieldsIndication,
	KYCFieldsIndicator,
} from '../../KYCFieldsIndicator';
import { UF_List } from '../../../utils/CheckUF';
import {
	addressesFieldsCollaboratorSWAPPattern,
	addressNumberSWAPPattern,
	cepPattern,
	cpfPattern,
	emailPattern,
	onlyLettersAndSpacesPattern,
	phonePattern,
} from '../../../utils/patterns';
import { toast } from 'react-toastify';
import { trimObjectData } from '../../../utils/trimObjectData';
import { SearchCEPButton } from '../../SearchCEPButton';
import { useGetAddressByCEP } from '../../../hooks/useGetAddressByCEP';
import { ShareholderFormData } from '../../../contexts/CompanyFormContext';

export interface ShareholdersFormProps {
	shareholder: ShareholderFormData;
	goBack: () => void;
}
export function ShareholdersForm({
	shareholder,
	goBack,
}: ShareholdersFormProps) {
	const { company, addShareholder, updateShareholder } = useCompanyForm();
	const [loading, setLoading] = useState(false);

	const { register, handleSubmit, getValues, reset } = useForm({
		defaultValues: {
			...shareholder,
			cpf: cpfMask(shareholder.cpf || ''),
			birth_date: parseDateToInput(shareholder.birth_date || ''),
			phone_number: phoneMask(shareholder.phone_number || ''),
			cep: cepMask(shareholder.cep || ''),
			uf: shareholder.uf || '',
			type: shareholder.type || '',
		},
	});

	const { searchAddressByCEP, isSearchingCEP } = useGetAddressByCEP({
		onAddressFoundCallback: (address) => {
			reset({ ...getValues(), ...address }); // reset the form filling with the fetched address
		},
	});

	async function handleSubmitForm(data: Shareholder) {
		setLoading(true);
		let success = false;

		if (shareholder.id) {
			// update an existing shareholder
			success = await updateShareholder(trimObjectData(data));
		} else if (!shareholder.id && !!company?.id) {
			// adding a shareholder to an existing company
			success = await addShareholder(trimObjectData(data));
		}

		setLoading(false);
		if (success) goBack();
	}

	function onInvalidInput(message: string) {
		toast.error(message, { style: { fontSize: '1.3rem' } });
	}

	if (loading) {
		return <Loader />;
	}

	return (
		<FormStyles.Form
			style={{ marginTop: 0 }}
			onSubmit={(e) => {
				e.preventDefault();
				handleSubmit(handleSubmitForm)();
				e.stopPropagation(); // do not submit the parent form
			}}
			data-testid='shareholder_form_test_id'
		>
			<FormStyles.FieldGroup>
				<FormStyles.Field>
					<FormStyles.Label>
						Nome Completo <ObrigatoryIndicator />
						<KYCFieldsIndicator />
					</FormStyles.Label>
					<FormStyles.Input
						type='text'
						pattern={onlyLettersAndSpacesPattern}
						{...register('full_name')}
						required
						data-testid='fullNameInput_test_id'
					/>
				</FormStyles.Field>
			</FormStyles.FieldGroup>

			<FormStyles.FieldGroup>
				<FormStyles.Field>
					<FormStyles.Label>
						Nome da mãe <ObrigatoryIndicator />
						<KYCFieldsIndicator />
					</FormStyles.Label>
					<FormStyles.Input
						type='text'
						pattern={onlyLettersAndSpacesPattern}
						{...register('mother_name')}
						required
						data-testid='motherNameInput_test_id'
					/>
				</FormStyles.Field>
				<FormStyles.Field style={{ width: '46%' }}>
					<FormStyles.Label>
						CPF <ObrigatoryIndicator />
						<KYCFieldsIndicator />
					</FormStyles.Label>
					<FormStyles.Input
						type='text'
						{...register('cpf')}
						pattern={cpfPattern}
						placeholder='Ex: 999.999.999-99'
						required
						onChange={(event) => {
							const { value } = event.target;
							event.target.value = cpfMask(value);
						}}
						data-testid='cpfInput_test_id'
					/>
				</FormStyles.Field>
				<FormStyles.Field style={{ width: '50%' }}>
					<FormStyles.Label>
						Data de nascimento <ObrigatoryIndicator />
						<KYCFieldsIndicator />
					</FormStyles.Label>
					<FormStyles.Input
						type='date'
						max={subtractYears(new Date(), 9).toISOString().split('T')[0]}
						{...register('birth_date')}
						required
						data-testid='birthInput_test_id'
					/>
				</FormStyles.Field>
			</FormStyles.FieldGroup>

			<FormStyles.FieldGroup>
				<FormStyles.Field style={{ width: '50%' }}>
					<FormStyles.Label>
						Tipo <ObrigatoryIndicator />
						<KYCFieldsIndicator />
					</FormStyles.Label>
					<FormStyles.SelectInput
						{...register('type')}
						required
						data-testid='typeInput_test_id'
					>
						<option disabled value=''>
							Selecione uma opção
						</option>
						<option key={'partner'} value={'partner'}>
							Sócio
						</option>
						<option key={'proxyholder'} value={'proxyholder'}>
							Procurador
						</option>
						<option key={'legal_representative'} value={'legal_representative'}>
							Representante legal
						</option>
						<option key={'other'} value={'other'}>
							Outro
						</option>
					</FormStyles.SelectInput>
				</FormStyles.Field>
			</FormStyles.FieldGroup>

			<FormStyles.FieldGroup>
				<FormStyles.Field>
					<FormStyles.Label>
						Email <ObrigatoryIndicator />
						<KYCFieldsIndicator />
					</FormStyles.Label>
					<FormStyles.Input
						type='email'
						placeholder='Ex: exemplo@email.com'
						pattern={emailPattern}
						{...register('email')}
						required
						data-testid='emailInput_test_id'
					/>
				</FormStyles.Field>
				<FormStyles.Field>
					<FormStyles.Label>
						Telefone <ObrigatoryIndicator />
						<KYCFieldsIndicator />
					</FormStyles.Label>
					<FormStyles.Input
						type='tel'
						{...register('phone_number')}
						placeholder='Ex: +55 81 99999-9999'
						pattern={phonePattern}
						required
						onChange={(event) => {
							const { value } = event.target;
							event.target.value = phoneMask(value);
						}}
						data-testid='phoneInput_test_id'
					/>
				</FormStyles.Field>
			</FormStyles.FieldGroup>

			<FormStyles.FieldGroup>
				<FormStyles.Field>
					<FormStyles.Label>
						Endereço <ObrigatoryIndicator />
						<KYCFieldsIndicator />
					</FormStyles.Label>
					<FormStyles.Input
						type='text'
						{...register('address')}
						required
						pattern={addressesFieldsCollaboratorSWAPPattern}
						onInvalid={(e) => {
							if (e.currentTarget.value === '') return;
							onInvalidInput(
								"Utilize apenas letras, espaços e (.)(,)(')(/)(-) no campo de endereço"
							);
						}}
						data-testid='addressInput_test_id'
					/>
				</FormStyles.Field>
				<FormStyles.Field>
					<FormStyles.Label>
						Número <ObrigatoryIndicator />
						<KYCFieldsIndicator />
					</FormStyles.Label>
					<FormStyles.Input
						type='text'
						maxLength={10}
						{...register('number')}
						pattern={addressNumberSWAPPattern}
						onInvalid={(e) => {
							if (e.currentTarget.value === '') return;
							onInvalidInput(
								"Utilize apenas letras, espaços e (') no campo de número"
							);
						}}
						required
						data-testid='numberInput_test_id'
					/>
				</FormStyles.Field>
			</FormStyles.FieldGroup>

			<FormStyles.FieldGroup>
				<FormStyles.Field>
					<FormStyles.Label>
						Bairro <ObrigatoryIndicator />
						<KYCFieldsIndicator />
					</FormStyles.Label>
					<FormStyles.Input
						type='text'
						{...register('district')}
						required
						pattern={addressesFieldsCollaboratorSWAPPattern}
						onInvalid={(e) => {
							if (e.currentTarget.value === '') return;
							onInvalidInput(
								"Utilize apenas letras, espaços e (.)(,)(')(/)(-) no campo de bairro"
							);
						}}
						data-testid='districtInput_test_id'
					/>
				</FormStyles.Field>
				<FormStyles.Field style={{ maxWidth: '25%' }}>
					<FormStyles.Label>
						CEP <ObrigatoryIndicator />
						<KYCFieldsIndicator />
					</FormStyles.Label>
					<FormStyles.Input
						type='text'
						{...register('cep')}
						pattern={cepPattern}
						placeholder='Ex: 99999-999'
						required
						onChange={(event) => {
							const { value } = event.target;
							event.target.value = cepMask(value);
						}}
						data-testid='cepInput_test_id'
					/>
				</FormStyles.Field>
				<SearchCEPButton
					isLoading={isSearchingCEP}
					onClick={() => searchAddressByCEP(getValues().cep)}
				/>
			</FormStyles.FieldGroup>

			<FormStyles.FieldGroup>
				<FormStyles.Field>
					<FormStyles.Label>Complemento</FormStyles.Label>
					<FormStyles.Input
						type='text'
						{...register('complement')}
						pattern={addressesFieldsCollaboratorSWAPPattern}
						onInvalid={(e) => {
							if (e.currentTarget.value === '') return;
							onInvalidInput(
								"Utilize apenas letras, espaços e (.)(,)(')(/)(-) no campo de complemento"
							);
						}}
						data-testid='complementInput_test_id'
					/>
				</FormStyles.Field>
				<FormStyles.Field style={{ width: '60%' }}>
					<FormStyles.Label>
						Cidade <ObrigatoryIndicator />
						<KYCFieldsIndicator />
					</FormStyles.Label>
					<FormStyles.Input
						type='text'
						{...register('city')}
						required
						pattern={onlyLettersAndSpacesPattern}
						onInvalid={(e) => {
							if (e.currentTarget.value === '') return;
							onInvalidInput(
								'Utilize apenas letras e espaços no campo de cidade'
							);
						}}
						data-testid='cityInput_test_id'
					/>
				</FormStyles.Field>
				<FormStyles.Field style={{ width: '30%' }}>
					<FormStyles.Label>
						UF <ObrigatoryIndicator />
						<KYCFieldsIndicator />
					</FormStyles.Label>
					<FormStyles.SelectInput
						{...register('uf')}
						name='uf'
						id='uf'
						required
						data-testid='ufInput_test_id'
					>
						<option disabled value=''>
							Selecione uma opção
						</option>
						{UF_List.map((uf) => (
							<option key={uf} value={uf}>
								{uf}
							</option>
						))}
					</FormStyles.SelectInput>
				</FormStyles.Field>
			</FormStyles.FieldGroup>

			<ObrigatoryFieldsIndication />
			<KYCFieldsIndication />
			<S.BottomOptionsContainer>
				<S.BackButton type='button' onClick={goBack}>
					Voltar
				</S.BackButton>
				<S.SaveButton type='submit'>Salvar</S.SaveButton>
			</S.BottomOptionsContainer>
		</FormStyles.Form>
	);
}
