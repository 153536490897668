import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useAuth } from '../../../hooks/useAuth';
import { toast } from 'react-toastify';
import ModelImage from '../../../assets/ModelSigInMaleComputer.png';
import ResponsiveModelImage from '../../../assets/ModelSignInFemale.png';
import bountyLogo from '../../../assets/BountyRed.svg';
import * as S from './styles';
import { Button } from '../../../componentsV2/ui/Button';
import { InputField } from '../../../componentsV2/ui/Form/InputField';
import { PasswordInput } from '../../../componentsV2/ui/Form/PasswordInput';
import { Link } from '../../../componentsV2/ui/Link';
import { CorporateExpensesTheme } from '../../../styles/CorporateExpensesTheme';
import styled from 'styled-components';
import { TbMail } from 'react-icons/tb';

type FormData = {
	email: string;
	password: string;
};

export default function SignIn() {
	const { signIn } = useAuth();
	const { push } = useHistory();
	const { register, handleSubmit, formState } = useForm<FormData>();
	const { isSubmitting } = formState;

	async function handleSignIn({ email, password }: FormData) {
		const loginResult = await signIn({ email, password });

		if (loginResult.concludedLogin) {
			loginResult.super_admin ? push('/super-admin') : push('/select-product');
		} else {
			toast.error(loginResult.message);
		}
	}

	return (
		<S.Container>
			<CorporateExpensesTheme />
			<S.LeftContainer>
				<img src={ModelImage} className='modelImage' alt='Men with Computer' />
				<img
					src={ResponsiveModelImage}
					className='ResponsiveModelImage'
					alt='Girl'
				/>
			</S.LeftContainer>

			<S.RightContainer>
				<S.MainContainer>
					<img src={bountyLogo} alt='Logo da Bounty' />

					<div>
						<h2>Bem-vindo</h2>
						<p>Preencha suas credenciais de acesso para se logar</p>
					</div>
					<S.Form onSubmit={handleSubmit(handleSignIn)} autoComplete='off'>
						<InputFieldStyle>
							<TbMail />
							<InputField
								id='email'
								name='email'
								register={register}
								label='Insira o seu e-mail'
								data-testid='emailInput_test_id'
								type='email'
								placeholder='exemplo@email.com.br'
								required
							/>
						</InputFieldStyle>
						<div className='passwordWrapper'>
							<PasswordInput
								id='password'
								name='password'
								register={register}
								label='Insira a sua senha'
								data-testid='passwordInput_test_id'
								placeholder='********'
								required
								showLeftIcon={true}
							/>

							<Link style={{ marginLeft: 'auto', 
								padding: 0 
								}} 
								to='/recover-password'>
								Esqueceu sua senha?
							</Link>
						</div>
						<div className='ButtonWrapper'>
							<Button
								roundness='lg'
								width='full'
								loading={isSubmitting}
								type='submit'
								className='loginButton'
								intent='primary'
							>
								Entrar
							</Button>
						</div>
					</S.Form>
				</S.MainContainer>
			</S.RightContainer>
		</S.Container>
	);
}


const InputFieldStyle = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	
	label {
		color: #929292;
		position: relative;
		bottom: 2.5rem;
	}
		
	& > svg {
		position: absolute;
		bottom: -1rem;
		left: 1.7rem;
		z-index: 1;
		font-size: 2.2rem;
	}

	#email {
		position: absolute;
		padding-left: 5.6rem;
		width: 100%;
		// z-index: -1;
	}
`;