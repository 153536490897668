import { FormEvent, useEffect, useState } from 'react';
import * as S from './styles';
import Modal from '../Modal';
import * as FormStyle from '../Form/FormStyles';
import { ObrigatoryIndicator } from '../ObrigatoryFieldsIndicator';
import Loader from '../Loader';
import { PasswordInput } from '../PasswordInput';

interface ChangePasswordModalProps {
	submit(
		oldPassword: string,
		newPassword: string,
		confirmNewPassword: string
	): Promise<void>;
}

export default function ChangePasswordModal({
	submit,
}: ChangePasswordModalProps) {
	const [oldPassword, setOldPassword] = useState('');
	const [newPassword, setNewPassword] = useState('');
	const [confirmNewPassword, setConfirmNewPassword] = useState('');
	const [loading, setLoading] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);

	async function handleSubmitFeedbackText(e: FormEvent) {
		if (!oldPassword || !newPassword || !confirmNewPassword) return;
		e.preventDefault();
		e.stopPropagation();
		setLoading(true);
		await submit(oldPassword, newPassword, confirmNewPassword);
		setLoading(false);
		setIsModalOpen(false);
	}

	useEffect(() => {
		if (!isModalOpen) {
			if (
				oldPassword !== '' ||
				newPassword !== '' ||
				confirmNewPassword !== ''
			) {
				setOldPassword('');
				setNewPassword('');
				setConfirmNewPassword('');
			}
		}
	}, [isModalOpen]); //eslint-disable-line

	return (
		<>
			<FormStyle.FormButton
				onClick={(e) => {
					e.preventDefault();
					setIsModalOpen(true);
				}}
			>
				Atualizar senha
			</FormStyle.FormButton>
			<Modal
				isOpen={isModalOpen}
				onRequestClose={() => setIsModalOpen(false)}
				enableClose
			>
				<S.ModalContainer>
					<S.ModalForm
						onSubmit={(e) => handleSubmitFeedbackText(e)}
						data-testid='form'
					>
						<S.ModalLabel htmlFor='oldPassword'>
							Senha antiga <ObrigatoryIndicator />
						</S.ModalLabel>
						<PasswordInput
							data-testid='oldPasswordInput_test_id'
							name='oldPassword'
							id='oldPassword'
							value={oldPassword}
							onChange={(e) => {
								setOldPassword(e.target.value);
							}}
							required
						/>

						<S.ModalLabel htmlFor='newPassword'>
							Nova senha <ObrigatoryIndicator />
						</S.ModalLabel>
						<PasswordInput
							data-testid='newPasswordInput_test_id'
							name='newPassword'
							id='newPassword'
							value={newPassword}
							onChange={(e) => {
								setNewPassword(e.target.value);
							}}
							required
						/>

						<S.ModalLabel htmlFor='confirmationPassword'>
							Confirmar nova senha <ObrigatoryIndicator />
						</S.ModalLabel>
						<PasswordInput
							data-testid='confirmationPasswordInput_test_id'
							name='confirmationPassword'
							id='confirmationPassword'
							value={confirmNewPassword}
							onChange={(e) => {
								setConfirmNewPassword(e.target.value);
							}}
							required
						/>

						<FormStyle.FormButton type='submit'>
							{loading ? <Loader color='#fff' size={20} /> : 'Enviar'}
						</FormStyle.FormButton>
					</S.ModalForm>
				</S.ModalContainer>
			</Modal>
		</>
	);
}
