import { Transaction } from '../../../@types/CorporateExpenses/Transaction';
import {
	FilterParams,
	parseFilterParamsToParams,
} from '../../../components/Filter';
import { ReportProps } from '../../../pages/CorporateExpenses/Extract/components/ReportGeneratorModal';
import api from '../../api';

export const CORPWAY_EXTRACT_KEY = 'CORPWAY_EXTRACT_SERVICE_KEY';
export const CORPWAY_CARD_BALANCE_KEY = 'CORPWAY_CARD_BALANCE_SERVICE_KEY';

export async function getExtract(
	cardId: string,
	companyId: string,
	filterParams: FilterParams[]
) {
	const { start_date, end_date } = parseFilterParamsToParams(filterParams);
	const start_date_parsed = new Date(start_date).valueOf();
	const end_date_parsed = new Date(end_date).valueOf();

	const { data } = await api.get<Transaction[]>(
		`api/v1/rh/corpway/companies/${companyId}/cards/${cardId}/extract`,
		{
			params: {
				start_date: start_date_parsed,
				end_date: end_date_parsed,
			},
		}
	);
	return data;
}

export async function getCardBalance(cardId: string, companyId: string) {
	const { data } = await api.get<number>(
		`api/v1/rh/corpway/companies/${companyId}/cards/${cardId}/balance`
	);
	return data;
}

export async function getCardExtractByMonth(
	cardId: string,
	companyId: string,
	monthDate: string,
	filterParams: FilterParams[]
) {
	const [year, month] = monthDate.split('-').map(Number);

	const start_date_parsed = new Date(year, month - 1, 1).valueOf();
	const end_date_parsed = new Date(year, month, 0).valueOf();

	const { data } = await api.get<Transaction[]>(
		`api/v1/rh/corpway/companies/${companyId}/cards/${cardId}/extract`,
		{
			params: {
				start_date: start_date_parsed,
				end_date: end_date_parsed,
			},
		}
	);
	return data;
}

export async function getExtractDetails(transaction_id: string) {
	const { data } = await api.get<Transaction>(
		`api/v1/rh/corpway/extract/${transaction_id}/details`
	);
	return data;
}

export async function getCardExtractByPeriod(
	cardId: string,
	companyId: string,
	start_date: number,
	end_date: number
) {
	const { data } = await api.get<Transaction[]>(
		`api/v1/rh/corpway/companies/${companyId}/cards/${cardId}/extract`,
		{
			params: {
				start_date,
				end_date,
			},
		}
	);
	return data;
}

interface ExtractToReport {
	transactions: Transaction[];
	totalExtracts: number;
	responsible: {
		id: string;
		name: string;
	};
}

export async function getCardExtractByPeriodToReport(
	cardId: string,
	companyId: string,
	start_date: number,
	end_date: number,
	filters?: ReportProps['filters']
) {
	const { data } = await api.post<ExtractToReport>(
		`api/v1/rh/corpway/companies/${companyId}/cards/${cardId}/extract`,
		{
			filters,
		},
		{
			params: {
				start_date,
				end_date,
			},
		}
	);
	return data;
}

export async function getCardExtractToReport(
	cardId: string,
	companyId: string,
	start_date: number,
	end_date: number,
	format: string,
	recipientEmails: string[],
	filters: ReportProps['filters'],
	reportCategory: 'individual' | 'all_cards',
	card_status: 'blocked' | 'active' | 'canceled'
) {
	const { data } = await api.post<Transaction[]>(
		`api/v1/rh/corpway/companies/${companyId}/cards/${cardId}/extract/report/q`,
		{
			recipientEmails,
			filters,
		},
		{
			params: {
				start_date,
				end_date,
				format,
				reportCategory,
				card_status,
			},
		}
	);
	return data;
}

export async function uploadInvoicesFiles(
	transaction_id: string,
	invoices: FormData
) {
	const { data } = await api.patch(
		`api/v1/rh/corpway/transaction/${transaction_id}/invoice`,
		invoices
	);

	return data;
}

export async function updateTransactionObservation(
	transaction_id: string,
	observation: string
) {
	const { data } = await api.patch(
		`api/v1/rh/corpway/transaction/${transaction_id}/observation`,
		{ observation }
	);

	return data;
}
