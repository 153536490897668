import { Group } from '../../@types';
import {
	FilterParams,
	parseFilterParamsToParams,
} from '../../components/Filter';
import {
	SortColumn,
	parseSortColumnToParams,
} from '../../hooks/useSortColumnHook';
import api from '../api';

export interface GroupsListReturn {
	groups: Group[];
	groupsTotalCount: number;
	groupsTotalActive: number;
}

export interface groupBodyQuery {
	title: string;
	description: string;
}

export interface GroupsFilterReturn {
	totalGroups?: number;
	groups?: Group[];
}

export async function getGroups(
	companyId?: string,
	pageNumber?: number,
	sortColumn: SortColumn | null = null
): Promise<GroupsListReturn> {
	const { data } = await api.get(
		`/api/v1/rh/companies/${companyId}/groups/q?page=${pageNumber}`,
		{
			params: {
				...parseSortColumnToParams(sortColumn),
			},
		}
	);
	const groups = [...data.groupsActive, ...data.groupsDesactive];
	const groupsTotalCount = data.totalGroupsActive + data.totalGroupsDesactive;

	return {
		groups,
		groupsTotalCount,
		groupsTotalActive: data.totalGroupsActive,
	};
}

export async function fetchGroup(
	groupId: string,
	companyId?: string
): Promise<Group> {
	const { data } = await api.get<Group>(
		`/api/v1/rh/companies/${companyId}/groups/${groupId}`
	);

	return data;
}

export async function getActiveGroups(companyId?: string): Promise<Group[]> {
	const { data } = await api.get(`/api/v1/rh/companies/${companyId}/groups`);
	const groups = data.groupsActive;

	return groups;
}

export async function getFilteredGroups(
	filtersParams: FilterParams[],
	companyId?: string,
	page?: number,
	sortColumn: SortColumn | null = null
): Promise<GroupsFilterReturn> {
	const { data } = await api.get(
		`/api/v1/rh/companies/${companyId}/groups/filter/q?page=${page}`,
		{
			params: {
				...parseFilterParamsToParams(filtersParams),
				...parseSortColumnToParams(sortColumn),
			},
		}
	);

	return data;
}

export async function setGroupCollaborators(
	groupId: string,
	collaboratorsIds: string[],
	companyId?: string
): Promise<void> {
	return api.put(
		`/api/v1/rh/companies/${companyId}/groups/${groupId}/collaborators`,
		{
			users_id: collaboratorsIds,
		}
	);
}

export async function updateGroupStatus(
	groupId: string,
	status: boolean,
	companyId?: string
) {
	return await api.patch(
		`/api/v1/rh/companies/${companyId}/groups/${groupId}/status`,
		{
			status,
		}
	);
}

export async function createGroup(
	body: groupBodyQuery,
	companyId?: string
): Promise<Group> {
	const { data } = await api.post(
		`api/v1/rh/companies/${companyId}/groups`,
		body
	);

	return data;
}
