import { Text, View } from '@react-pdf/renderer';

interface StatusColor {
	background: string;
	text: string;
	dot: string;
}

const statusColors: { [key: string]: StatusColor } = {
	active: { background: '#defdea', text: '#037847', dot: '#14BA6D' },
	cancelado: { background: '#C6C6C6', text: '#575757', dot: '#575757' },
	blocked: { background: '#ffd3d3', text: '#fd2929', dot: '#f84d4d' },
	default: { background: '#ffffff', text: '#000000', dot: '#000000' },
};

export function parseCardStatusToReport(status: string) {
	const statusText = (() => {
		switch (status) {
			case 'active':
				return 'Ativo';
			case 'blocked':
				return 'Bloqueado';
			case 'canceled':
				return 'Cancelado';
			default:
				return '????';
		}
	})();

	const statusColor = statusColors[status] || statusColors.default;

	return (
		<View
			style={{
				display: 'flex',
				flexDirection: 'row',
				columnGap: 1,
				alignItems: 'center',
				paddingVertical: '1',
				paddingHorizontal: '3',
				borderRadius: '50px',
				backgroundColor: statusColor.background,
				color: statusColor.text,
			}}
		>
			<View
				style={{
					width: '6px',
					height: '6px',
					borderRadius: '50%',
					marginRight: '3px',
					backgroundColor: statusColor.dot,
				}}
			/>
			<Text
				style={{
					fontFamily: 'Poppins',
					fontSize: 9,
				}}
			>
				{statusText}
			</Text>
		</View>
	);
}
