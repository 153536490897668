import { Transfer } from '../../../../@types';
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';
import * as S from '../styles';

export function parseTransferType(type: Transfer['type']) {
	if (type === 'exit') {
		return (
			<S.StatusContainer>
				<BsArrowLeft color='#FB1313' />
				Saída
			</S.StatusContainer>
		);
	}

	if (type === 'entrance') {
		return (
			<S.StatusContainer>
				<BsArrowRight color='#1CCA0D' />
				Entrada
			</S.StatusContainer>
		);
	}
}

export function parseTransferTypeToText(type: Transfer['type']) {
	if (type === 'exit') {
		return 'Saída';
	}

	if (type === 'entrance') {
		return 'Entrada';
	}

	return '????';
}

export function parseTransferCategory(category: Transfer['category']) {
	switch (category) {
		case 'benefit':
			return 'Pagamento de benefício';
		case 'refund':
			return 'Reembolso';
		case 'collaborator_chargeback':
			return 'Estorno de colaborador';
		case 'company_chargeback':
			return 'Estorno de empresa';
		case 'balance_recharge_payment':
			return 'Pagamento de recarga';
		case 'balance_recharge_receipt':
			return 'Recebimento de recarga';
		case 'external_chargeback':
			return 'Recebimento de chargeback';
		case 'first_credit_payment':
			return 'Pagamento de crédito inicial';
   	case 'first_credit_receipt':
			return 'Recebimento de crédito inicial';
	}
}

export function parseTransferCategoryToReport(category: Transfer['category']) {
	switch (category) {
		case 'benefit':
			return 'Pag. de benefício';
		case 'refund':
			return 'Reembolso';
		case 'collaborator_chargeback':
			return 'Est. de colaborador';
		case 'company_chargeback':
			return 'Est. de empresa';
		case 'balance_recharge_payment':
			return 'Pag. de recarga';
		case 'balance_recharge_receipt':
			return 'Receb. de recarga';
    case 'external_chargeback':
			return 'Recebimento de chargeback';
		case 'first_credit_payment':
			return 'Pagamento de crédito inicial';
		case 'first_credit_receipt':
			return 'Recebimento de crédito inicial';
	}
}

export function getTransferDetailsLink(
	category: Transfer['category'],
	resource_id?: string
) {
	switch (category) {
		case 'benefit':
			return `/home/payments/details/${resource_id}`;
		case 'refund':
			return `/home/refunds/${resource_id}`;
		case 'collaborator_chargeback':
			return `/home/chargebacks/${resource_id}`;
		case 'company_chargeback':
			return null;
		case 'balance_recharge_payment':
			return null;
		case 'balance_recharge_receipt':
			return null;
    case 'external_chargeback':
      return null;
		case 'first_credit_payment':
			return null;
		case 'first_credit_receipt':
			return null;
	}
}

export function parseCustomFieldsToParams(fields: object) {
	const fields_param: string[] = [];

	Object.entries(fields).forEach(([field, value]) => {
		if (value) fields_param.push(field);
	});

	return fields_param.join(',');
}
