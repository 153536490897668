import {
	CostCenter,
	CostCenterDetails,
	CostCenterOnlyUsers,
	CreateUpdateCostCenterData,
} from '../../../@types/CorporateExpenses/CostCenter';
import { User } from '../../../@types/CorporateExpenses/User';
import {
	SortColumn,
	parseSortColumnToParams,
} from '../../../hooks/useSortColumnHook';
import { UsersNewCostCenter } from '../../../pages/CorporateExpenses/Management/CostCenters/components/DeleteModal';
import api from '../../api';

export const CORPWAY_COST_CENTER_KEY = 'CORPWAY_COST_CENTERS_SERVICE_KEY';

export interface CostCentersListReturn {
	costCenters: CostCenter[];
	total_costCenters: number;
}

export async function getCostCenters(
	companyId: string,
	page?: number,
	sortColumn: SortColumn | null = null
) {
	const { data } = await api.get<CostCentersListReturn>(
		`/api/v1/rh/corpway/companies/${companyId}/cost-center/select`,
		{
			params: {
				page,
				...parseSortColumnToParams(sortColumn),
			},
		}
	);
	return data;
}

export async function getCostCenterDetails(id: string, companyId: string) {
	const { data } = await api.get<CostCenterDetails>(
		`/api/v1/rh/corpway/companies/${companyId}/cost-center/${id}`
	);
	return data;
}

export async function createCostCenter(
	data: CreateUpdateCostCenterData,
	companyId: string
) {
	return await api.post(
		`/api/v1/rh/corpway/companies/${companyId}/cost-center`,
		{
			...data,
		}
	);
}

export async function updateCostCenter(
	id: string,
	data: CreateUpdateCostCenterData,
	companyId: string
) {
	return await api.put(
		`/api/v1/rh/corpway/companies/${companyId}/cost-center/${id}`,
		{
			...data,
		}
	);
}

export async function getUsersOnlyCostCenter(
	costCenterId: string,
	companyId: string
) {
	const { data } = await api.get<CostCenterOnlyUsers[]>(
		`/api/v1/rh/corpway/companies/${companyId}/cost-center/${costCenterId}/users-only-cost-center`
	);
	return data;
}

export async function deleteCostCenter(
	costCenterId: string,
	companyId: string,
	users: UsersNewCostCenter[]
) {
	const { data } = await api.delete(
		`/api/v1/rh/corpway/companies/${companyId}/cost-center/${costCenterId}`,
		{
			data: {
				users,
			},
		}
	);
	return data;
}

export async function getCostCenterUsers(
	costCenterId: string,
	companyId: string
) {
	const { data } = await api.get<User[]>(
		`/api/v1/rh/corpway/companies/${companyId}/cost-center/${costCenterId}/select`
	);
	return data;
}

export async function updateUserToCostCenter(
	costCenterId: string,
	user: string,
	companyId?: string
) {
	const { data } = await api.patch(
		`/api/v1/rh/corpway/companies/${companyId}/cost-center/${costCenterId}/user`,
		{
			user,
		}
	);
	return data;
}

export async function getCostCenterExpenses(companyId: string, date: Date) {
	const { data } = await api.get<{
		costCenterExpenses: Record<string, number>;
	}>(
		`/api/v1/rh/corpway/companies/${companyId}/cost-center/expenses/${date.getFullYear()}/${date.getMonth()}`
	);
	return data;
}
