import { SectionTitle } from '..';
import { Typography } from '../../../../componentsV2/ui/Typography';
import styled from 'styled-components';
import { useQuery } from 'react-query';
import {
	CORPWAY_CARDS_KEY,
	getCardsYearExpenses,
} from '../../../../services/queries/Corpway/Cards';
import { useAuth } from '../../../../hooks/useAuth';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { AsynchronousContent } from '../../../../componentsV2/AsynchronousContent';
import { convertCentsToFormattedReais } from '../../../../utils/CurrencyConvert';
import { SelectField } from '../../../../componentsV2/ui/Form/SelectField';
import CustomTooltip from './components/CustomTooltip';
import ReactHintFactory from 'react-hint';

const ReactHint = ReactHintFactory(React);

export function YearExpenses() {
	const { user, currentCompany } = useAuth();
	const currentYear = new Date().getFullYear();
	const startOfControlYear = 2024;
	const years = Array.from(
		{ length: currentYear - startOfControlYear + 1 },
		(_, i) => startOfControlYear + i,
	);

	const [selectedYear, setSelectedYear] = useState(currentYear);

	const getCardsYearExpensesQuery = useQuery(
		[CORPWAY_CARDS_KEY, 'expenses', user.id, currentCompany?.id, selectedYear],
		() => getCardsYearExpenses(currentCompany!.id, selectedYear),
		{
			onError: () => {
				toast.error(
					'Ocorreu um problema ao buscar o relatório de total de pagamentos.',
				);
			},
			refetchOnWindowFocus: false,
		},
	);

	function getMonthName(monthRef: number) {
		const monthName = new Date(selectedYear, monthRef, 1)
			.toLocaleString('default', {
				month: 'short',
			})
			.replace('.', '');

		return monthName.charAt(0).toUpperCase() + monthName.slice(1);
	}

	const data = Object.keys(getCardsYearExpensesQuery.data || {}).map((key) => ({
		name: getMonthName(parseInt(key)),
		value: getCardsYearExpensesQuery.data
			? getCardsYearExpensesQuery.data[key]
			: { ted: 0, card: 0 },
	}));

	const total = data.reduce((acc, curr) => acc + curr.value.ted + curr.value.card, 0);

	const max = data.reduce((acc, curr) => Math.max(acc, curr.value.ted, curr.value.card), 0);

	const rounded = max > 100000 ? Math.round(max / 100000) * 100000 : max;


	function getYAxisLabel(max: number, fraction: number) {
		const converted = convertCentsToFormattedReais(max * fraction);

		if (max <= 100000) {
			return convertCentsToFormattedReais( max * fraction).split(",")[0];
		}

		if (converted.includes(".")){
			return convertCentsToFormattedReais( max * fraction).split(".")[0];
		}

		return convertCentsToFormattedReais( max * fraction).split(",")[0];
	}

	return (
		<div style={{ minHeight: '45rem' }}>
			<style>
				{`
						@media (max-width: 768px) {
							.mobileStyle {
								text-align: center !important;
							}
						}
					`}
			</style>
			<HeaderContainer>
				<div style={{ display: 'flex' }}>
					<TitleContainer>
						<SectionTitle primaryText="Total">
							<span style={{ paddingRight: '0.5rem', whiteSpace: 'nowrap' }}>
								{' '}
								de pagamentos
							</span>
						</SectionTitle>
						<Typography
							className="mobileStyle"
							size="1.4rem"
							color="var(--mid-gray)"
							style={{ marginTop: '0.2rem' }}
						>
							Gastos mensais consolidados
						</Typography>
					</TitleContainer>
					<CustomTooltip />
				</div>

				<YearSelectField
					value={selectedYear}
					onChange={(e) => setSelectedYear(Number(e.target.value))}
					placeholderText={false}
				>
					{years.map((year) => (
						<option key={year} value={year}>
							{year}
						</option>
					))}
				</YearSelectField>
			</HeaderContainer>

			<style>
				{`
					.react-hint--top:after {
						top: auto;
						border-bottom: none;
						border-top-color:  var(--dark-gray);
						}
				`}
			</style>

			<ReactHint
				attribute="data-custom-2"
				events
				autoPosition
				onRenderContent={(target) => (
					<TooltipContainer>
						<p>
							<Typography size={'0.8rem'}
													weight={'400'}>
								{target.getAttribute('data-custom-2-title')}
							</Typography>
							<Typography size={'1.2rem'}
													weight={'400'}>
								{target.getAttribute('data-custom-2-value')}
							</Typography>
						</p>
					</TooltipContainer>)}
			/>
			<AsynchronousContent status={getCardsYearExpensesQuery.status}>

				<ChartContainer>
					<YAxesContainer>
						{[0, 0.5, 1].map((fraction) => (
							<Typography
								key={fraction}
								style={{
									fontSize: '1.2rem',
									color: 'var(--light-gray)',
									justifyContent: 'flex-end',
									whiteSpace: 'nowrap',
								}}
							>
								{getYAxisLabel(rounded === 0 ? 1000000 : rounded, fraction)} {(rounded > 100000 || rounded === 0) && 'mil'}
							</Typography>
						))}
					</YAxesContainer>
					<MainContentContainer>
						{data.map((month) => {
							const tedHeight = month.value.ted / rounded;
							const cardHeight = month.value.card / rounded;

							return (
								<ChartElement key={month.name}>
									<BarContainer>
										<div>
											<Bar
												data-custom-2
												data-custom-2-title={'Cartão:'}
												data-custom-2-value={convertCentsToFormattedReais(month.value.card)}
												value={cardHeight}
												style={{ backgroundColor: 'var(--primary-blue)' }}
											/>
										</div>
										<div>
											<Bar
												data-custom-2
												data-custom-2-title={'TED:'}
												data-custom-2-value={convertCentsToFormattedReais(month.value.ted)}
												value={tedHeight}
												style={{  backgroundColor: '#1F59A4' }}
											/>
										</div>
									</BarContainer>
									<Typography style={{ marginTop: '1.5rem' }} weight={'400'} color={'#929292'}>{month.name}</Typography>
								</ChartElement>
							);
						})}
					</MainContentContainer>
				</ChartContainer>

				<LegendContainer>
					<LabelsContainer>
						<LegendItem color={'var(--primary-blue)'}>
							<div />
							<Typography>Cartão</Typography>
						</LegendItem>
						<LegendItem color={'#1F59A4'}>
							<div />
							<Typography>TED</Typography>
						</LegendItem>
					</LabelsContainer>

					<TotalContainer>
						<Typography
							color="var(--light-gray)"
							style={{ justifyContent: 'flex-end' }}
						>
							Total Gasto:
						</Typography>
						<Typography
							color="var(--dark-gray)"
							size="2rem"
							weight="600"
							style={{ justifyContent: 'flex-end' }}
						>
							{convertCentsToFormattedReais(total)}
						</Typography>
					</TotalContainer>
				</LegendContainer>
			</AsynchronousContent>
		</div>
	);
}

const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 2rem;

    @media (max-width: 650px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 1rem;
    }
`;
const ChartContainer = styled.div`
    display: flex;
    gap: 2rem;
    height: 30rem;
    width: 100%;
    @media (max-width: 650px) {
        overflow-x: scroll;
        overflow-y: visible;
    }
`;

const ChartElement = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    position: relative;
    height: 100%;
    @media (max-width: 650px) {
        min-width: 8rem;
    }
`;
const Bar = styled.div<{ value: number }>`
    background-color: var(--primary-blue);
    border-radius: 0.6rem 0.6rem 0 0;
    width: 100%;
    height: ${({ value }) => value * 100}%;
    min-height: ${({ value }) => (value === 0 || isNaN(value)) ? 0 : 5}%;
    @media (max-width: 650px) {
        border-radius: 0.8rem 0.8rem 0 0;
    }
`;
const TitleContainer = styled.div`
    width: 100%;
    @media (max-width: 650px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
`;

const LegendItem = styled.div<{ color: string }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.4rem;

    & > div {
        width: 1.6rem;
        height: 1.6rem;
        border-radius: 20%;
        background: ${({ color }) => color};
    }
`;

const TooltipContainer = styled.div`
    width: 10rem;
    background-color: var(--dark-gray);
    color: #fff;
    font-size: 1.4rem;
    text-align: center;
    border-radius: 0.6rem;
    padding: 1rem;
    pointer-events: none;
`;

const TotalContainer = styled.div`
    display: grid;
    justify-content: flex-end;
    margin-top: 2rem;
`;

const LegendContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 2.4rem;
    justify-content: space-between;`;

const LabelsContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 2.4rem;
`;

const YAxesContainer = styled.div`
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    height: 100%;
    padding-bottom: 30px
`;

const MainContentContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    gap: 2.4rem;
`;

const YearSelectField = styled(SelectField)`
    width: fit-content;
    margin-left: auto;
    margin-top: 0.6rem;
    @media (max-width: 650px) {
        width: auto;
        margin-left: 0;
        margin-top: 1rem;
        align-self: center;
    }
`;

const BarContainer = styled.div`
    flex-direction: row;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: flex-end;

    & > div {
        flex-direction: column;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: flex-end;
        align-items: center;
    }
`;
