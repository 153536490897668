import styled from 'styled-components';
import { OptionsButton } from '../Button';

export const OpenFilterPopoverContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	column-gap: 0.6rem;
	margin: 0 2rem;
`;

export const OpenFilterButton = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	column-gap: 1rem;
	font-size: 1.4rem;
	color: var(--primary-blue);
	background: none;
`;

export const FilterIndicator = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 1rem;
	font-weight: bold;
	width: 1.6rem;
	height: 1.6rem;
	border-radius: 50%;
	color: var(--white);
	background-color: var(--primary-blue);
	user-select: none;

	transition: background-color 0.3s;

	& svg {
		display: none;
		width: 100%;
		height: 100%;
	}
	&:hover {
		filter: none;
		background-color: var(--primary-red);
		& svg {
			display: block;
		}
		& span {
			display: none;
		}
	}
`;

// POPORVER CONTENT
export const Container = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 1rem;
	min-width: 30rem;
	max-width: 40rem;
	max-height: 40rem;
	overflow-y: auto;
	padding: 1rem;
	border-radius: 0.4rem;
	background-color: var(--white);
	border: 0.1rem solid var(--light-gray);
`;

export const Section = styled.div`
	display: flex;
	flex-direction: column;
	padding-bottom: 1rem;
	border-bottom: 0.1rem solid var(--dark-gray);
`;

export const SectionTitle = styled.h2`
	margin-bottom: 0.5rem;
	text-transform: uppercase;
`;

export const FiltersContainer = styled.ul`
	display: flex;
	row-gap: 0.5rem;
	align-items: center;
	flex-wrap: wrap;
	list-style: none;
`;

interface SelectFilterProps {
	selected: boolean;
}
export const SelectFilter = styled.li<SelectFilterProps>`
	font-size: 1.4rem;
	padding: 0.6rem;
	min-width: 6rem;
	text-align: center;
	width: fit-content;
	text-transform: uppercase;
	margin-right: 0.5rem;
	border-radius: 0.4rem;
	cursor: pointer;
	border: 0.1rem solid var(--primary-blue);
	background-color: ${({ selected }) =>
		selected ? 'var(--primary-blue)' : 'var(--white)'};
	color: ${({ selected }) =>
		selected ? 'var(--white)' : 'var(--primary-blue)'};
	user-select: none;

	transition: background 0.2s;
	/* transition: filter 0.3s; */

	&:hover {
		filter: brightness(0.98);
	}
`;

export const FiltersInputContainer = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 0.8rem;
`;

export const InputLabel = styled.label`
	text-transform: capitalize;
	font-size: 1.2rem;
`;

export const FilterButton = styled.button`
	${OptionsButton}
	padding: 0.4rem 0.8rem;
	width: 100%;
	margin-top: 0.8rem;
`;

export const CheckBoxWrapper = styled.div`
	position: relative;
`;

export const CheckBoxLabel = styled.label`
	position: absolute;
	top: 0;
	left: 0;
	width: 42px;
	height: 26px;
	border-radius: 15px;
	background: #bebebe;
	cursor: pointer;
	&::after {
		content: '';
		display: block;
		border-radius: 50%;
		width: 18px;
		height: 18px;
		margin: 3px;
		background: #ffffff;
		box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
		transition: 0.2s;
	}

	&:hover {
		filter: grayscale(50%);
		background: var(--primary-blue);
		transition: 0.5s;
	}
`;

export const CheckBox = styled.input`
	opacity: 0;
	z-index: 1;
	border-radius: 15px;
	width: 42px;
	height: 26px;
	&:checked + ${CheckBoxLabel} {
		background: var(--primary-blue);
		&::after {
			content: '';
			display: block;
			border-radius: 50%;
			width: 18px;
			height: 18px;
			margin-left: 21px;
			transition: 0.2s;
		}

		&:hover {
			filter: grayscale(0%);
			background: var(--primary-blue);
		}
	}
`;
