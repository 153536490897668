import styled from 'styled-components';
import { OptionsButton } from '../../../../../../components/Button';

export const ImportButton = styled.label`
	${OptionsButton}
	font-size: 1.4rem;
	cursor: pointer;
	& input {
		display: none;
	}
`;
