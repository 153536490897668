import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AddressBody, CardBatch, DeliveredExtraInfo } from '../../../../@types';
import Loader from '../../../../components/Loader';
import PageTitle from '../../../../components/PageTitle';
import { useAuth } from '../../../../hooks/useAuth';
import {
	fetchCardBatch,
	finishBatch,
	finishItemFromBatch,
	removeBatch,
	removeItemFromBatch,
} from '../../../../services/queries/CardsBatchs';
import {
	getStatusIcon,
	parseCardStatusString,
	parseStatusString,
} from '../../../../utils/parseCardBatchStatus';
import * as TableStyles from '../../../../components/Table/TableStyles';
import * as FormStyles from '../../../../components/Form/FormStyles';
import * as S from './styles';
import UserCard from '../../../../components/UserCard';
import { AddressModal } from '../../../../components/AddressModal';
import { TrackingModal } from '../../../../components/TrackingModal';
import { useDialogModal } from '../../../../hooks/useDialogModal';
import { RiDeleteBin5Fill } from 'react-icons/ri';
import { FinishDeliveryModal } from './components/FinishDeliveryModal';
import { showErrorMessage } from '../../../../utils/ErrorHandler';
import { parseDeliveryAddressType } from '../utils/parseCardBatchInfo';
import { FaExclamationCircle } from 'react-icons/fa';

interface CardBatchDetailsParams {
	id: string;
}

export function CardBatchDetails() {
	const history = useHistory();
	const queryClient = useQueryClient();
	const { currentCompany } = useAuth();
	const { openOptionsDialog } = useDialogModal();
	const { id } = useParams<CardBatchDetailsParams>();

	const fetchCardBatchQuery = useQuery<CardBatch, Error>(
		['fetchCardBatch', id, currentCompany?.id],
		() => fetchCardBatch(id, currentCompany?.id),
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao buscar a solicitação de envio de remessa de cartões. '
				);
			},
		}
	);

	const finishBatchMutation = useMutation(
		() => finishBatch(id, currentCompany?.id!),
		{
			onSuccess: () => {
				queryClient.invalidateQueries('pendingCardsBatchesList');
				toast.info('Remessa finalizada com sucesso!');
				history.push('/home/cards');
			},
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao finalizar a remessa de cartões. '
				);
			},
		}
	);

	const removeBatchMutation = useMutation(
		() => removeBatch(id, currentCompany?.id!),
		{
			onSuccess: () => {
				queryClient.invalidateQueries('pendingCardsBatchesList');
				toast.info('Remessa cancelada com sucesso!');
				history.push('/home/cards');
			},
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao cancelar o envio da remessa de cartões. '
				);
			},
		}
	);

	const removeItemFromBatchMutation = useMutation(
		({ itemId }: { itemId: string }) =>
			removeItemFromBatch(id, itemId, currentCompany?.id!),
		{
			onSuccess: () => {
				queryClient.invalidateQueries([
					'fetchCardBatch',
					id,
					currentCompany?.id,
				]);
				toast.info('Item removido da remessa com sucesso!');
			},
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema remover o item da remessa da remessa de cartões. '
				);
			},
		}
	);

	const finishItemFromBatchMutation = useMutation(
		({
			itemId,
			delivered_extra_info,
		}: {
			itemId: string;
			delivered_extra_info: DeliveredExtraInfo;
		}) =>
			finishItemFromBatch(
				id,
				itemId,
				delivered_extra_info,
				currentCompany?.id!
			),
		{
			onSuccess: () => {
				queryClient.invalidateQueries([
					'fetchCardBatch',
					id,
					currentCompany?.id,
				]);
				toast.info('Entrega do item da remessa realizada!');
			},
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao finalizar o item da remessa da remessa de cartões. '
				);
			},
		}
	);

	function getCollaboratorName(firstName: string, lastName: string) {
		return `${firstName} ${lastName}`;
	}

	function handleFinishBatch() {
		openOptionsDialog(
			'Tem certeza que deseja finalizar a remessa?',
			'Confirmar',
			() => finishBatchMutation.mutate(),
			'Cancelar',
			() => {}
		);
	}

	function handleDeleteBatch() {
		openOptionsDialog(
			'Tem certeza que deseja cancelar o envio da remessa?',
			'Confirmar',
			() => removeBatchMutation.mutate(),
			'Cancelar',
			() => {}
		);
	}

	function handleRemoveDelivery(itemId: string) {
		if (fetchCardBatchQuery.data?.orders.length === 1) {
			toast.error(
				'Não é possível remover a única entrega. Caso queira, cancele o envio da remessa.'
			);
			return;
		}

		openOptionsDialog(
			'Tem certeza que deseja remover o envio do item da remessa?',
			'Confirmar',
			() => removeItemFromBatchMutation.mutate({ itemId }),
			'Cancelar',
			() => {}
		);
	}

	function handleFinishDelivery(
		itemId: string,
		delivered_extra_info: DeliveredExtraInfo
	) {
		finishItemFromBatchMutation.mutate({ itemId, delivered_extra_info });
	}

	if (
		!fetchCardBatchQuery.data ||
		finishBatchMutation.isLoading ||
		removeBatchMutation.isLoading ||
		removeItemFromBatchMutation.isLoading ||
		finishItemFromBatchMutation.isLoading
	) {
		return (
			<S.Container>
				<PageTitle title='Detalhamento' />
				<Loader />
			</S.Container>
		);
	}
	return (
		<S.Container>
			<S.Header>
				<PageTitle title='Detalhamento' />
				{getStatusIcon(fetchCardBatchQuery.data.status)}
				{parseStatusString(fetchCardBatchQuery.data.status)}
				{fetchCardBatchQuery.data.max_qtde_exceeded && (
					<>
						<FaExclamationCircle color='#FF5900' />
						Limite de cartões por pedido excedido
					</>
				)}
			</S.Header>

			<S.InfoTop>
				<S.InfoContainer>
					<S.InfoTitle>CÓDIGO DA SOLICITAÇÃO</S.InfoTitle>
					{fetchCardBatchQuery.data.order_id}
				</S.InfoContainer>
				{fetchCardBatchQuery.data.delivery_address_type !== 'collaborators' && (
					<S.InfoContainer>
						<S.InfoTitle>ID DA SOLICITAÇÃO</S.InfoTitle>
						{fetchCardBatchQuery.data.request_id !== null
							? fetchCardBatchQuery.data.request_id
							: '-'}
					</S.InfoContainer>
				)}
				<S.InfoContainer>
					<S.InfoTitle>DATA DA SOLICITAÇÃO</S.InfoTitle>
					{new Date(fetchCardBatchQuery.data.created_at).toLocaleDateString()}
				</S.InfoContainer>
				<S.InfoContainer>
					<S.InfoTitle>QNTD DE CARTÕES</S.InfoTitle>
					{fetchCardBatchQuery.data.card_qtde}
				</S.InfoContainer>

				<S.InfoContainer>
					<S.InfoTitle>ENDEREÇO DE ENTREGA</S.InfoTitle>
					{parseDeliveryAddressType(
						fetchCardBatchQuery.data.delivery_address_type
					)}
				</S.InfoContainer>
				{fetchCardBatchQuery.data.delivery_address_type !== 'collaborators' && (
					<>
						<S.InfoContainer>
							<S.InfoTitle>Destino</S.InfoTitle>
							<AddressModal address={fetchCardBatchQuery.data.batch_address!} />
						</S.InfoContainer>
						<S.InfoContainer>
							<S.InfoTitle>Tracking</S.InfoTitle>
							<TrackingModal
								cardBatchId={fetchCardBatchQuery.data.id}
								orderId={fetchCardBatchQuery.data.order_id}
								trackLink={fetchCardBatchQuery.data.track_link!}
							/>
						</S.InfoContainer>
					</>
				)}
			</S.InfoTop>

			{fetchCardBatchQuery.data.delivery_address_type === 'collaborators' && (
				<TableStyles.Table>
					<TableStyles.TableHeader>
						<TableStyles.TableRow>
							<TableStyles.TableHeaderCell>
								DESTINATÁRIO
							</TableStyles.TableHeaderCell>

							<TableStyles.TableHeaderCell>DESTINO</TableStyles.TableHeaderCell>
							<TableStyles.TableHeaderCell>
								TRACKING
							</TableStyles.TableHeaderCell>

							<TableStyles.TableHeaderCell>CÓDIGO</TableStyles.TableHeaderCell>
							<TableStyles.TableHeaderCell>
								ID DA SOLICITAÇÃO
							</TableStyles.TableHeaderCell>
							<TableStyles.TableHeaderCell>STATUS</TableStyles.TableHeaderCell>
							<TableStyles.TableHeaderCell>OPÇÕES</TableStyles.TableHeaderCell>
						</TableStyles.TableRow>
					</TableStyles.TableHeader>

					<TableStyles.TableBody>
						{fetchCardBatchQuery.data.orders.map((order) => (
							<TableStyles.TableRow key={order.id}>
								<TableStyles.TableData>
									<UserCard
										id={order.collaborator!.id}
										name={getCollaboratorName(
											order.collaborator!.first_name!,
											order.collaborator!.last_name!
										)}
										bottomInfo={order.collaborator!.email!}
										avatar_url={order.collaborator!.avatar_url}
									/>
								</TableStyles.TableData>

								<TableStyles.TableData>
									<AddressModal
										address={
											order.collaborator!.address! as unknown as AddressBody
										}
										name={`${order.collaborator!.first_name} ${
											order.collaborator!.last_name
										}`}
									/>
								</TableStyles.TableData>

								<TableStyles.TableData>
									<TrackingModal
										cardBatchId={fetchCardBatchQuery.data.id}
										cardBatchItemId={order.id}
										orderId={order.order_id}
										trackLink={order.track_link}
									/>
								</TableStyles.TableData>
								<TableStyles.TableData>{order.order_id}</TableStyles.TableData>
								<TableStyles.TableData>
									{order.request_id !== null ? order.request_id : '-'}
								</TableStyles.TableData>
								<TableStyles.TableData>
									<TableStyles.StatusContainer>
										{getStatusIcon(
											order.status,
											order.delivered_extra_info === 'with_error'
										)}
										<span>
											{parseCardStatusString(
												order.status,
												order.delivered_extra_info === 'with_error'
											)}
										</span>
									</TableStyles.StatusContainer>
								</TableStyles.TableData>
								<TableStyles.TableData>
									<S.OptionsContainer>
										<S.RemoveCollaboratorBtn
											{...(order.status === 'pending'
												? { 'data-rh': 'Remover entrega da remessa' }
												: {})}
											onClick={() => {
												handleRemoveDelivery(order.id);
											}}
											data-testid={`remove-${order.id}`}
											disabled={order.status !== 'pending'}
										>
											<RiDeleteBin5Fill />
										</S.RemoveCollaboratorBtn>

										<FinishDeliveryModal
											collaboratorName={getCollaboratorName(
												order.collaborator!.first_name!,
												order.collaborator!.last_name!
											)}
											disabled={order.status !== 'processing'}
											onSubmit={(type) => handleFinishDelivery(order.id, type)}
										/>
									</S.OptionsContainer>
								</TableStyles.TableData>
							</TableStyles.TableRow>
						))}
					</TableStyles.TableBody>
				</TableStyles.Table>
			)}

			<S.FooterOptions>
				{fetchCardBatchQuery.data.status === 'processing' &&
					fetchCardBatchQuery.data.delivery_address_type !==
						'collaborators' && (
						<FormStyles.Option onClick={handleFinishBatch}>
							Finalizar envio
						</FormStyles.Option>
					)}
				{fetchCardBatchQuery.data.status === 'pending' && (
					<FormStyles.Option cancel onClick={handleDeleteBatch}>
						Cancelar envio
					</FormStyles.Option>
				)}
			</S.FooterOptions>
		</S.Container>
	);
}
