import { toast } from 'react-toastify';
import { Collaborator } from '../../../../@types';
import CollaboratorForm from '../../../../components/CollaboratorForm';
import PageTitle from '../../../../components/PageTitle';
import * as S from './styles';
import { useHistory } from 'react-router-dom';
import ModalLoader from '../../../../components/ModalLoader';
import { validateCollaborator } from '../../../../utils/validateCollaborator';
import {
	parseInputToDate,
	parseMaskedCEPToRaw,
	parseMaskedCPFToRaw,
	parseMaskedPhoneToRaw,
} from '../../../../utils/masks';
import { useAuth } from '../../../../hooks/useAuth';
import { useMutation } from 'react-query';
import { createCollaborator } from '../../../../services/queries/Collaborators';
import { showErrorMessage } from '../../../../utils/ErrorHandler';

export function CreateCollaborator() {
	const history = useHistory();
	const { currentCompany } = useAuth();
	const createCollaboratorQuery = useMutation((collaborator: Collaborator) =>
		createCollaborator(collaborator, currentCompany?.id)
	);

	async function handleCreateCollaborator(collaborator: Collaborator) {
		collaborator.birth_date = parseInputToDate(collaborator.birth_date!);
		collaborator.cpf = parseMaskedCPFToRaw(collaborator.cpf!);
		collaborator.first_phone = parseMaskedPhoneToRaw(collaborator.first_phone!);
		collaborator.second_phone = parseMaskedPhoneToRaw(
			collaborator.second_phone!
		);
		collaborator.cep = parseMaskedCEPToRaw(collaborator.cep!);

		if (collaborator.cep.length < 8) {
			toast.error('CEP inválido!');
			return;
		}
		if (validateCollaborator(collaborator)) {
			try {
				await createCollaboratorQuery.mutateAsync(collaborator);

				toast.info('Colaborador criado com sucesso');
				history.push('/home/collaborators');
			} catch (err) {
				showErrorMessage(
					err as Error,
					'Não foi possível criar o colaborador. '
				);
			}
		}
	}

	return (
		<S.Container>
			<PageTitle title='Criar Colaborador' />
			<CollaboratorForm onSubmit={handleCreateCollaborator} />
			<ModalLoader loading={createCollaboratorQuery.isLoading} />
		</S.Container>
	);
}
