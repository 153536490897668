import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { OptionsButton } from '../../../components/Button';

export const Container = styled.div`
	flex: 1;
	padding: 4rem 7rem 2rem 7rem;
	margin-bottom: 2rem;
`;

export const CreateButton = styled(Link)`
	text-decoration: none;
	${OptionsButton}
	font-size: 1.4rem;
`;
