import { memo, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FaInfoCircle } from 'react-icons/fa';
import { VscError } from 'react-icons/vsc';
import Modal from '../../../../../components/Modal';
import { usePayment } from '../../../../../hooks/usePaymentV2';
import { addBenefitInputEventListener } from '../../utils/setBenefitInputEventListener';
import { getBenefitValue } from '../../utils/getBenefitValuesInputs';
import { removeBenefitInputEventListener } from '../../utils/setBenefitInputEventListener';
import * as S from './styles';

interface FeedbackForm {
	feedbackText: string;
}

interface Props {
	beneficiaryId: string;
	benefitId: string;
}
function ReviewAdminModalComponent({ beneficiaryId, benefitId }: Props) {
	const { initialBeneficiaries, beneficiaries, setBeneficiaries } =
		usePayment();

	const { register, setValue, handleSubmit } = useForm<FeedbackForm>();

	const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);
	const [canRepprove, setCanRepprove] = useState(true);

	useEffect(() => {
		if (initialBeneficiaries.length === 0) return;

		const input = document.getElementById(
			`${beneficiaryId}-${benefitId}`
		) as HTMLInputElement;
		addBenefitInputEventListener(input, setIfBenefitCanBeRepproved);

		return () => {
			removeBenefitInputEventListener(input, setIfBenefitCanBeRepproved);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initialBeneficiaries, beneficiaryId, benefitId]);

	const setIfBenefitCanBeRepproved = () => {
		setCanRepprove(checkIfValueCanBeReproved);
	};

	// if the value from the benefit has been edited (it's different from the initial value) then,
	// it should not be able to be reproved
	const checkIfValueCanBeReproved = () => {
		const foundInitialBenefit = initialBeneficiaries
			.find((c) => c.id === beneficiaryId)
			?.benefits.find((b) => b.id === benefitId);

		return (
			getBenefitValue(beneficiaryId, benefitId) === foundInitialBenefit?.value
		);
	};

	const handleSubmitFeedbackText = (data: FeedbackForm) => {
		handleCloseDisapprovalModal();

		setBeneficiaries(
			beneficiaries.map((c) => {
				if (c.id === beneficiaryId) {
					let benefits = c.benefits.map((b) => {
						if (b.id === benefitId)
							return { ...b, reason_disapproval: data.feedbackText.trim() };
						return b;
					});
					return { ...c, benefits };
				}
				return c;
			})
		);
	};

	const handleRemoveDisapprovedBenefit = () => {
		handleCloseDisapprovalModal();

		setBeneficiaries(
			beneficiaries.map((c) => {
				if (c.id === beneficiaryId) {
					let benefits = c.benefits.map((b) => {
						if (b.id === benefitId) return { ...b, reason_disapproval: '' };
						return b;
					});
					return { ...c, benefits };
				}
				return c;
			})
		);
	};

	function handleBenefitSelection() {
		setIsFeedbackModalOpen(true);
		const reason = getBenefitReasonDisapproval(beneficiaryId, benefitId) ?? '';
		setValue('feedbackText', reason);
	}

	const getBenefitReasonDisapproval = (
		beneficiaryId: string | undefined,
		benefitId: string | undefined
	) => {
		return beneficiaries
			.find((c) => c.id === beneficiaryId)
			?.benefits.find((b) => b.id === benefitId)?.reason_disapproval;
	};

	function handleCloseDisapprovalModal() {
		setIsFeedbackModalOpen(false);
	}

	return (
		<>
			{canRepprove && (
				<S.ReprovalIcon>
					<VscError onClick={handleBenefitSelection} data-rh='Reprovar' />
				</S.ReprovalIcon>
			)}

			<Modal
				isOpen={isFeedbackModalOpen}
				enableClose
				onRequestClose={handleCloseDisapprovalModal}
			>
				<S.ModalContainer>
					<FaInfoCircle />
					<span>Informe o motivo da reprovação</span>

					<S.DisapprovalForm onSubmit={handleSubmit(handleSubmitFeedbackText)}>
						<S.DisapprovalInput {...register('feedbackText')} required />

						<fieldset style={{ border: 'none' }}>
							{!!getBenefitReasonDisapproval(beneficiaryId, benefitId) && (
								<S.RemoveButton
									type='button'
									onClick={handleRemoveDisapprovedBenefit}
								>
									Remover
								</S.RemoveButton>
							)}
							<S.ConfirmButton type='submit'>Salvar</S.ConfirmButton>
						</fieldset>
					</S.DisapprovalForm>
				</S.ModalContainer>
			</Modal>
		</>
	);
}

export const ReviewAdminModal = memo(ReviewAdminModalComponent);
