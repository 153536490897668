export const GlobalDebug = (function () {
	return function (debugOn: boolean) {
		if (!debugOn) {
			// supress all type of consoles
			console.log = function () {};
			console.info = function () {};
			console.warn = function () {};
			console.error = function () {};
			console.table = function () {};
		}
	};
})();
