import {
	useEffect,
	useState,
	createContext,
	Dispatch,
	SetStateAction,
	ReactNode,
	useCallback,
} from 'react';
import { Benefit, Collaborator } from '../../@types';
import ModalLoader from '../../components/ModalLoader';

export type IndividualFromGroupRepproved = {
	id: string;
	benefitId: string;
	collaborator: Collaborator;
	reason_disapproval: string;
};
export interface Beneficiary {
	id: string;
	name: string;
	benefits: Benefit[];
	selected?: boolean;

	// collaborator properties
	cpf?: string;
	email?: string;
	office?: string;
	avatar_url?: string;

	// Group properties
	isGroup?: boolean;
	collaboratorsAmount?: number;
	individualsRepproved?: IndividualFromGroupRepproved[];
}

export interface PaymentContextData {
	initialBeneficiaries: Beneficiary[];
	beneficiaries: Beneficiary[];
	setBeneficiaries: Dispatch<SetStateAction<Beneficiary[]>>;
	benefits: Benefit[];
	setBenefits: Dispatch<SetStateAction<Benefit[]>>;
	selectedBenefits: Benefit[];
	setSelectedBenefits: Dispatch<SetStateAction<Benefit[]>>;
	isBenefitSelected: (benefitId: string) => boolean;
	schedule: string;
	setSchedule: Dispatch<SetStateAction<string>>;
	submitProgress: string | undefined;
	setSubmitProgress: Dispatch<SetStateAction<string | undefined>>;
}

export const PaymentContext = createContext({} as PaymentContextData);

export function PaymentProvider({ children }: { children: ReactNode }) {
	/* This variable contaims the initial Release values that were read from the file or fetched from an existing Payment
     It is used for example, when the user unselects a benefit, if the benefit is selected again
     We need to know this benefits' initial values to each collaborator, so we access this state 
  */
	const [initialBeneficiaries, setInitialBeneficiaries] = useState<
		Beneficiary[]
	>([]);

	const [beneficiaries, setBeneficiaries] = useState<Beneficiary[]>([]);

	/* This variable contaims the initial benefits existing in the Payment
		It is used to check if a benefit selection should be displayed for the collaborator or not, if the operator 
		adds a new benefit to the payment it should be available for all collaborators, but if a benefit is 
		selected and it wasn't available for a certain collaborator before (because it was already approved or repproved)
		then it should not display the benefit for that especific collaborator
	*/
	const [initialBenefits, setInitialBenefits] = useState<Benefit[]>([]);
	const [benefits, setBenefits] = useState<Benefit[]>([]);
	const [selectedBenefits, setSelectedBenefits] = useState<Benefit[]>([]);

	const [schedule, setSchedule] = useState('');

	const [submitProgress, setSubmitProgress] = useState<string | undefined>(); // loading for when user submits the payment

	// set initial beneficiaries
	useEffect(() => {
		// set the initialBeneficiaries only once (after beneficiaries is first set)
		if (initialBeneficiaries.length || !beneficiaries.length) return;

		setInitialBeneficiaries(beneficiaries);
	}, [beneficiaries, initialBeneficiaries]);

	// set initial benefits
	useEffect(() => {
		// set the initialBenefits only once (after benefits is first set)
		if (initialBenefits.length || !benefits.length) return;

		setInitialBenefits(benefits);
	}, [benefits, initialBenefits]);

	// UPDATE COLLABORATORS BENEFITS WHEN USER SELECTS BENEFITS
	useEffect(() => {
		if (beneficiaries.length === 0) return;

		// LOOP THROUGH ALL beneficiaries
		setBeneficiaries(
			beneficiaries.map((beneficiary) => {
				// LOOP THROUGH ALL SELECTED BENEFITS
				beneficiary.benefits = selectedBenefits.map((benefit) => {
					let benefitAlreadyAddedPos = beneficiary.benefits.findIndex(
						(b) => b.id === benefit.id
					);

					// IF CURRENT SELECTED BENEFIT IS ALREADY INCLUDED THEN RETURN THE ONE ALREADY ADDED
					// THIS WAY THEN ONE ALREADY ADDED WON'T LOSE ITS STATE
					if (benefitAlreadyAddedPos > -1) {
						return beneficiary.benefits[benefitAlreadyAddedPos];
					}

					// OTHERWISE, IT'S A BENEFIT SELECTION THEN ADD THE FROM START

					/* This variable contaims the initial Release values that were read from the file
						When the user unselects a benefit, if the benefit is selected again
						We need to know this benefits' initial values (from file) to each beneficiary, so we access this state 
					*/
					let initialBenefit = undefined;

					initialBenefit = initialBeneficiaries
						.find((collab) => collab.id === beneficiary.id)
						?.benefits.find((b) => b.id === benefit.id);

					if (initialBenefit) {
						return {
							id: benefit.id,
							title: benefit.title,
							value: initialBenefit ? initialBenefit.value : 0,
							reason_disapproval: benefit.reason_disapproval,
							chargeback_value: initialBenefit.chargeback_value,
							chargeback_status: initialBenefit.chargeback_status,
						};
					}
					return {
						id: benefit.id,
						title: benefit.title,
						value: benefit.value,
						reason_disapproval: benefit.reason_disapproval,
						chargeback_value: benefit.chargeback_value,
						chargeback_status: benefit.chargeback_status,
					};
				});

				return beneficiary;
			})
		);
		// eslint-disable-next-line
	}, [selectedBenefits]);

	const isBenefitSelected = useCallback(
		(benefitId: string) => {
			return !!selectedBenefits.find(
				(selectedBenefit) => selectedBenefit.id === benefitId
			);
		},
		[selectedBenefits]
	);

	return (
		<PaymentContext.Provider
			value={{
				beneficiaries,
				setBeneficiaries,
				initialBeneficiaries,
				benefits,
				setBenefits,
				selectedBenefits,
				setSelectedBenefits,
				schedule,
				setSchedule,
				submitProgress,
				setSubmitProgress,
				isBenefitSelected,
			}}
		>
			{children}

			<ModalLoader
				loading={!!submitProgress}
				progressText={
					submitProgress === 'confirmation_loading' ? undefined : submitProgress
				}
			/>
		</PaymentContext.Provider>
	);
}
