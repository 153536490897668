import React from 'react';
import { Typography } from '../../../../../componentsV2/ui/Typography';
import * as S from './styles';

export function InputWithDescription({
	description,
	children,
}: {
	description: string;
	children: React.ReactNode;
	marginTop?: string;
}) {
	return (
		<S.InputContainer>
			<S.Row>{children}</S.Row>
			<S.Description>
				<Typography size={'1.2rem'} color={'#575757'} weight={'400'}>
					{description}
				</Typography>
			</S.Description>
		</S.InputContainer>
	);
}
