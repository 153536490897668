import { ButtonHTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import productIcon from '../assets/ProductIcon.svg';
import { TitleTypography } from './ui/Typography';

type Elements = 'div' | 'button';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
	as?: Elements;
	disabled?: boolean;
	direction?: 'horizontal' | 'vertical';
	baseColor?: string;
	secondaryColor?: string;
	titleComponent?: string | React.ReactNode;
	iconSrc?: string;
}

export function Card({
	as = 'div',
	direction = 'horizontal',
	titleComponent,
	children,
	iconSrc = productIcon,
	...rest
}: Props) {
	const titleContent =
		typeof titleComponent === 'string' ? (
			<TitleTypography color='var(--dark-gray)' size='2.4rem'>
				{titleComponent}
			</TitleTypography>
		) : (
			titleComponent
		);
	return (
		<CardStyle as={as} direction={direction} {...rest}>
			<IconContainer direction={direction} secondaryColor={rest.secondaryColor}>
				<img src={iconSrc} alt={'Gastos corporativos'} />
				{titleContent}
			</IconContainer>
			{children}
		</CardStyle>
	);
}

const CardStyle = styled.div<Props>`
	min-height: ${(props) =>
		props.direction === 'horizontal' ? '14rem' : 'fit-content'};
	width: ${(props) =>
		props.direction === 'horizontal' ? '34rem' : 'fit-content'};
	background-color: ${(props) => props.baseColor ?? '#FFF'};
	border-radius: 1.6rem;
	display: flex;
	flex-direction: ${(props) =>
		props.direction === 'horizontal' ? 'row' : 'column'};
	align-items: center;
	text-align: left;
	column-gap: 2rem;
	row-gap: 2rem;

	${(props) =>
		props.as === 'button' &&
		css`
			&:hover {
				filter: none;
				box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
					0 17px 50px 0 rgba(0, 0, 0, 0.19);
				transform: translateY(-0.1rem);
			}
		`}
`;

const IconContainer = styled.div<Props>`
	width: ${(props) => (props.direction === 'horizontal' ? '14rem' : '100%')};
	min-width: ${(props) =>
		props.direction === 'horizontal' ? '14rem' : '100%'};
	max-width: ${(props) =>
		props.direction === 'horizontal' ? '14rem' : '100%'};
	height: ${(props) => (props.direction === 'horizontal' ? '100%' : '14rem')};
	display: flex;
	flex-direction: column;
	align-items: center;
	row-gap: 1rem;
	justify-content: center;
	background-color: ${(props) => props.secondaryColor ?? '#D2D7E4'};
	border-radius: 1.6rem;
	text-align: center;
	padding: ${(props) => (props.direction === 'horizontal' ? '0' : '0 2rem')};
	& > img {
		border-radius: 1.6rem;
	}
`;
