import { FaInfoCircle } from 'react-icons/fa';
import styled from 'styled-components';
import { Collaborator } from '../../../../../../@types';
import Modal from '../../../../../../components/Modal';

interface props {
	collab: Collaborator | undefined;
	onConfirm: () => void;
	onCancel: () => void;
}
export function ConfirmCollabSelectionModal({
	collab,
	onConfirm,
	onCancel,
}: props) {
	return (
		<Modal
			isOpen={collab !== undefined}
			enableClose
			onRequestClose={() => onCancel()}
		>
			<Container data-testid='confirmation_modal'>
				<FaInfoCircle />
				<Message>
					O colaborador {collab?.first_name} {collab?.last_name} já possui um
					cartão vinculado. Confirmar seleção?
				</Message>
				<OptionsContainer>
					<Option second={false} onClick={() => onConfirm()}>
						Confirmar
					</Option>
					<Option second={true} onClick={() => onCancel()}>
						Cancelar
					</Option>
				</OptionsContainer>
			</Container>
		</Modal>
	);
}

const Container = styled.div`
	width: 30rem;
	display: flex;
	flex-direction: column;
	align-items: center;

	& svg {
		color: var(--terciary);
		width: 4rem;
		height: 4rem;
	}
`;

const Message = styled.p`
	text-align: center;
	font-size: 1.4rem;
	margin: 2rem 0;
`;

const OptionsContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: center;
	margin-top: 1.5rem;
`;

type OptionType = {
	second: boolean;
};

const Option = styled.button<OptionType>`
	border: 0.2rem solid var(--primary-blue);
	color: ${({ second }) => (second ? 'var(--primary-blue)' : 'var(--white)')};
	background-color: ${({ second }) =>
		second ? 'var(--white)' : 'var(--primary-blue)'};
	padding: 1rem 2rem;
	width: 100%;
	border-radius: 0.2rem;
	transition: filter 0.2s;
	margin: 0.5rem 0;

	&:hover {
		filter: brightness(0.9);
	}
`;
