import { useEffect, useState } from 'react';
import Modal from '../../../../../../componentsV2/ui/Modal';
import styled from 'styled-components';
import { Button } from '../../../../../../componentsV2/ui/Button';
import { DIALOG_MODAL_CONTAINER_ID } from '../../../../../../contexts/DialogModalContext';
import { IoMdClose } from 'react-icons/io';

const ErrorMessages = {
	'file-invalid-type': {
		title: 'Formato de arquivo',
		subtitle: true, //'true | string'
		message: 'Por favor escolha um dos formatos aceitos: JPEG, PNG ou PDF.',
	},
	'file-too-large': {
		title: 'Tamanho do arquivo',
		subtitle: true,
		message: 'Por favor escolha um arquivo com no máximo 3MB.',
	},
	'too-many-files': {
		title: 'Número de arquivos',
		subtitle: true,
		message: 'Por favor selecione até dois arquivos por vez.',
	},
	'already-too-many-files': {
		title: 'Número máximo de arquivos anexados',
		subtitle: false,
		message:
			'O limite máximo de 2 arquivos por transação foi atingido. Alguns dos arquivos selecionados não serão carregados.',
	},
};

const DefaultErrorMessage = {
	title: 'Erro desconhecido',
	subtitle: false,
	message: 'Ocorreu um erro desconhecido. Por favor tente novamente.',
};

interface Props {
	setErrors: (file: string | undefined) => void;
	error?: string;
}

export function UploadRejectionModal({ error, setErrors }: Props) {
	const [isOpen, setIsOpen] = useState(false);

	useEffect(() => {
		setIsOpen(!!error);
	}, [error]);

	function closeModal() {
		setErrors(undefined);
		setIsOpen(false);
	}

	const errorMessage =
		ErrorMessages[error as keyof typeof ErrorMessages] || DefaultErrorMessage;

	return (
		<Modal
			isOpen={isOpen}
			onRequestClose={closeModal}
			position='center'
			parentId={DIALOG_MODAL_CONTAINER_ID}
		>
			<Container>
				<ErrorMessageContainer>
					<IconContainer>
						<IoMdClose color='#fff' size={30} />
					</IconContainer>

					{!!error && (
						<>
							<ErrorTitle>
								<p style={{ fontWeight: 600 }}>{errorMessage.title}</p>
								{!!errorMessage.subtitle && (
									<p>
										&nbsp;
										{typeof errorMessage.subtitle === 'boolean'
											? 'não suportado'
											: errorMessage.subtitle}
									</p>
								)}
							</ErrorTitle>
							<ErrorMessage>{errorMessage.message}</ErrorMessage>
						</>
					)}
				</ErrorMessageContainer>

				<CloseBttnContainer>
					<Button
						onClick={() => closeModal()}
						intent='terciary'
						roundness='lg'
						$outline
					>
						Fechar
					</Button>
				</CloseBttnContainer>
			</Container>
		</Modal>
	);
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 40rem;
	min-height: 30rem;
	padding: 3rem;
`;

const ErrorMessageContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	row-gap: 0.8rem;
	font-size: 2rem;
	margin-bottom: 1rem;
`;

const ErrorTitle = styled.div`
	display: flex;
	text-align: center;
	& > p {
		font-size: 1.7rem;
	}
`;

const ErrorMessage = styled.p`
	text-align: center;
	width: 80%;
	font-size: 1.5rem;
	color: var(--gray);
	margin-top: 4rem;
`;

const IconContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: var(--primary-red);
	width: 4rem;
	height: 4rem;
	border-radius: 50px;
`;

const CloseBttnContainer = styled.div`
	margin-top: auto;
`;
