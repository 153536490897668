import styled from 'styled-components';

export const Container = styled.div`
	flex: 1;
	height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: var(--primary-red);
`;

export const LogoImage = styled.img`
	width: 24rem;
`;

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	padding: 2rem;
	background-color: #e3e8eb;
	border-radius: 1rem;
	margin-top: 4rem;
	box-shadow: 0px 13px 27px -5px rgba(50, 50, 93, 0.25),
		0px 8px 16px -8px rgba(0, 0, 0, 0.3),
		0px -6px 16px -6px rgba(0, 0, 0, 0.025);
	color: var(--dark-gray);
	font-size: 1.6rem;
	display: flex;
	align-items: center;
	a {
		color: #5d5fef;
		text-decoration: underline;
	}
`;
