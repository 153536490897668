import styled from 'styled-components';

export const TableContainer = styled.div`
	max-height: 50rem;
	overflow: auto;
	width: 100%;
	padding-right: 0.1rem;
	&::-webkit-scrollbar {
		width: 1rem;
		height: 1rem;
	}

	&::-webkit-scrollbar-track {
		background-color: #e9eaeb;
		border-radius: 0.6rem;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #258bdb;
		border-radius: 0.6rem;
	}
`;

interface Props {
	width : number;
}

export const TableComp = styled.table<Props> `
	width: 100%;
	font-size: 1.3rem;
	border-collapse: collapse;
	th, td {
			width: ${props => props.width}%;
	}
`;


export const TableHeader = styled.thead`
	position: sticky;
	top: 0;
`;

export const TH = styled.th`
	color: var(--dark-gray);
	font-weight: 700;
	text-align: start;
		
	&:nth-child(1) {
			border-radius: 1rem 0 0 0;
	}
	&:nth-last-child(1) {
			border-radius: 0 1rem 0 0;
	}

	& > div {
		display: flex;
		align-items: center;
		column-gap: 1rem;
	}

	&:hover button {
		visibility: visible;
	}
`;

export const TD = styled.td`
	padding: 0.7rem 0 0.7rem 0;
	color: var(--dark-gray);

	& > div {
		display: flex;
		justify-content: flex-end;
	}
`;

export const TR = styled.tr`
	& ~ & {
		border-top: 0.1rem solid #c8d3e0;
	}

	&:last-child {
		border-bottom: 0.1rem solid #c8d3e0;
	}
`;