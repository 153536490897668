import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
	managePartnerCollaborators,
	getPartnersCollaborators,
	ManagePartnerCollaboratorsBody,
	manageAllPartnerCollaborators,
	ManageAllPartnerCollaboratorsAction,
} from '../../../../services/queries/Partners';
import { useAuth } from '../../../../hooks/useAuth';
import { useParams } from 'react-router-dom';
import { showErrorMessage } from '../../../../utils/ErrorHandler';
import UserCard from '../../../../components/UserCard';
import { cpfMask } from '../../../../utils/masks';
import { CollabsSelector } from '../../../../components/CollabsSelector';
import { FilteredCollabsSelector } from '../../../../components/CollabsSelector/FilteredCollabsSelector';
import Loader from '../../../../components/Loader';
import { Collaborator } from '../../../../@types';
import { toast } from 'react-toastify';
import * as S from './styles';
import { Option as FormStyleOption } from '../../../../components/Form/FormStyles';
import PageTitle from '../../../../components/PageTitle';
import { RiDeleteBin5Fill } from 'react-icons/ri';

export function ManagePartnersCollaborators() {
	const { partner_id } = useParams<{ partner_id: string }>();
	const queryClient = useQueryClient();
	const { currentCompany } = useAuth();

	const getPartnersCollaboratorsQuery = useQuery(
		['getPartners', partner_id, currentCompany?.id],
		() => getPartnersCollaborators(currentCompany?.id!, partner_id),
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível buscar os colaboradores do parceiro. '
				);
			},
		}
	);

	const managePartnerCollaboratorsMutation = useMutation(
		({ collaborators }: { collaborators: ManagePartnerCollaboratorsBody[] }) =>
			managePartnerCollaborators(
				currentCompany?.id!,
				partner_id,
				collaborators
			),
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível atualizar os colaboradores do parceiro. '
				);
			},
			onSuccess: onCollaboratorsListUpdated,
		}
	);

	const manageAllPartnerCollaboratorsMutation = useMutation(
		({ action }: { action: ManageAllPartnerCollaboratorsAction }) =>
			manageAllPartnerCollaborators(currentCompany?.id!, partner_id, action),
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível atualizar os colaboradores do parceiro. '
				);
			},
			onSuccess: onCollaboratorsListUpdated,
		}
	);

	function onCollaboratorsListUpdated() {
		toast.info('Colaboradores atualizados com sucesso!');
		queryClient.resetQueries(['getPartners', partner_id, currentCompany?.id]);
	}

	function handleManageCollaborators(
		collaboratorsToAdd: Collaborator[],
		collaboratorsToRemove: Collaborator[]
	) {
		const collaborators = [
			...collaboratorsToAdd.map((c) => {
				return { collaborator_id: c.id!, partner_status: true };
			}),
			...collaboratorsToRemove.map((c) => {
				return { collaborator_id: c.id!, partner_status: false };
			}),
		];

		managePartnerCollaboratorsMutation.mutate({
			collaborators,
		});
	}

	function handleManageAllCollaborators(
		action: ManageAllPartnerCollaboratorsAction
	) {
		manageAllPartnerCollaboratorsMutation.mutate({ action });
	}

	if (
		!getPartnersCollaboratorsQuery.data ||
		managePartnerCollaboratorsMutation.isLoading ||
		manageAllPartnerCollaboratorsMutation.isLoading
	)
		return (
			<S.Container>
				<PageTitle title={`Gerenciar Colaboradores`} />
				<Loader />
			</S.Container>
		);
	return (
		<S.Container>
			<PageTitle
				title={`Gerenciar Colaboradores - ${getPartnersCollaboratorsQuery.data.partner_name}`}
			/>

			<S.CollaboratorsListTitle>
				Colaboradores ({getPartnersCollaboratorsQuery.data.collaborators.length}
				)
			</S.CollaboratorsListTitle>

			<S.CollaboratorsList>
				{getPartnersCollaboratorsQuery.data?.collaborators.map(
					(collaborator) => (
						<S.CollaboratorCard key={collaborator.id}>
							<div>
								<UserCard
									name={collaborator.name}
									nameWidth='100%'
									avatar_url={collaborator.avatar ?? undefined}
									bottomInfo={cpfMask(collaborator.cpf)}
								/>
							</div>

							<S.RemoveCollaboratorBtn
								data-rh='Remover colaborador'
								data-testid={`remove-${collaborator.id}`}
								onClick={() => {
									handleManageCollaborators([], [collaborator as Collaborator]);
								}}
							>
								<RiDeleteBin5Fill />
							</S.RemoveCollaboratorBtn>
						</S.CollaboratorCard>
					)
				)}
			</S.CollaboratorsList>

			<S.BottomOptionsContainer>
				<CollabsSelector
					alreadyAddedCollabs={
						getPartnersCollaboratorsQuery.data?.collaborators as Collaborator[]
					}
					onAddNewCollaborators={(c) => handleManageCollaborators(c, [])}
					onAddAndRemoveCollabs={(toAdd, toRemove) =>
						handleManageCollaborators(toAdd, toRemove)
					}
					onAddCollaboratorsToRemove={(c) => handleManageCollaborators([], c)}
					allowRemoveCollaborators
					buttonText='Adicionar ou Remover Colaborador'
				/>

				<FilteredCollabsSelector
					alreadyAddedCollabs={
						getPartnersCollaboratorsQuery.data?.collaborators as Collaborator[]
					}
					onAddNewCollaborators={(c) => handleManageCollaborators(c, [])}
				/>

				<S.AddAllCollaboratorsBtn
					onClick={() => handleManageAllCollaborators('add')}
				>
					Adicionar todos os colaboradores da empresa
				</S.AddAllCollaboratorsBtn>

				<FormStyleOption
					cancel
					onClick={() => handleManageAllCollaborators('remove')}
					disabled={
						getPartnersCollaboratorsQuery.data.collaborators.length === 0
					}
				>
					Remover todos os colaboradores
				</FormStyleOption>
			</S.BottomOptionsContainer>
		</S.Container>
	);
}
