import styled from 'styled-components';

interface ContentCardContainerProps {
	productScreen?: boolean;
	static?: boolean;
}

export const ContentCardContainer = styled.div<ContentCardContainerProps>`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	max-height: 28.3rem;
	border-radius: 1.6rem;
	transition: all 0.2s ease-in-out;
	background-color: white;
	padding: 2rem 0rem 2rem 2rem;
	> .imgWrapper {
		display: flex;
		border-radius: 1.2rem;
		width: 12rem;
		align-items: center;
		justify-content: flex-end;
		height: ${(props) => (props.productScreen ? '140%' : '100%')};
		object-fit: contain;
		padding: 0;
		background-color: var(--white);

		img {
			transition: all 0.2s ease-in-out;
		}
	}

	${(props) =>
		!props.static &&
		`
      cursor: pointer;
      transform: scale(1.02);

      img {
        transform: scale(1.05);
        transform-origin: bottom right;
      }

      &:hover {
        // Additional hover styles if needed
      }
    `}
`;
