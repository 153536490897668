import { UseFormWatch } from 'react-hook-form';
import {
	CorpwayUserAccessLevel,
	EditUserDetails,
	UserDetails,
} from '../../../../../../@types/CorporateExpenses/User';
import { ACCESS_LEVELS_CHANGE_ACTIONS } from '../../../../../../utils/corpway/accessLevelsMapping';
import { InputField } from '../../../../../../componentsV2/ui/Form/InputField';
import { parseUserAccessLevel } from '../../../../../../utils/corpway/parseUserAccessLevel';
import { MdArrowForward } from 'react-icons/md';
import { Button } from '../../../../../../componentsV2/ui/Button';
import React from 'react';
import * as S from './styles';

export default function WarningAccessChanged({
	watch,
	user,
	setFormStep,
}: {
	watch: UseFormWatch<EditUserDetails>;
	user: UserDetails;
	setFormStep: (step: 1 | 2 | 3 | 4) => void;
}) {
	const selectedAccessLevel = watch('access_level');

	const warningMessage = ACCESS_LEVELS_CHANGE_ACTIONS.find(
		(a) => a.from === user.role && a.to === selectedAccessLevel
	);

	return (
		<S.SmallModalContainer>
			<S.Row
				style={{
					justifyContent: 'space-between',
					gap: '1rem',
					alignItems: 'center',
				}}
			>
				<S.Row>
					<InputField
						disabled
						label={'De:'}
						value={parseUserAccessLevel(user.role as CorpwayUserAccessLevel)}
					/>
				</S.Row>
				<MdArrowForward
					style={{ marginTop: '2.5rem' }}
					size={30}
					color={'var(--primary-red)'}
				/>
				<S.Row>
					<InputField
						disabled
						label={'Para:'}
						value={parseUserAccessLevel(
							selectedAccessLevel as CorpwayUserAccessLevel
						)}
					/>
				</S.Row>
			</S.Row>
			<S.WarningDescription bold style={{ fontSize: '1.4rem' }}>
				<span>Com essa alteração de acesso, este usuário terá</span>{' '}
				<span style={{ color: 'var(--primary-red)' }}>
					{warningMessage?.upgrade ? 'mais visiblidade' : 'menos visibilidade'}
				</span>
			</S.WarningDescription>

			<S.WarningDescription>
				{warningMessage?.message.map((e) => (
					<span
						key={e.text?.toString()}
						style={{ fontWeight: e.isBold ? 'bold' : 'normal' }}
					>
						{e.text}
					</span>
				))}
			</S.WarningDescription>

			<S.ButtonsContainerSmallModal>
				<Button
					onClick={() => setFormStep(1)}
					intent='terciary'
					roundness='lg'
					$outline
				>
					Voltar
				</Button>

				<Button
					intent={'secondary'}
					width='adjusted'
					roundness='lg'
					onClick={() => setFormStep(3)}
				>
					Avançar
				</Button>
			</S.ButtonsContainerSmallModal>
		</S.SmallModalContainer>
	);
}
