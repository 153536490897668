import { useState } from 'react';
import { MdNotes, MdOutlineRecycling } from 'react-icons/md';
import { PaymentsProps } from '..';
import { Payment } from '../../../../@types';
import Loader from '../../../../components/Loader';
import PageTitle from '../../../../components/PageTitle';
import {
	getStatusIcon,
	parseStatusString,
} from '../../../../utils/parsePaymentStatus';
import * as S from '../styles';
import * as TableStyle from '../../../../components/Table/TableStyles';
import { useAuth } from '../../../../hooks/useAuth';
import { parseDate } from '../../../../utils/parseDate';
import { BsFileEarmarkText } from 'react-icons/bs';
import { EmptyContent } from '../../../../components/EmptyContent';
import { Pagination } from '../../../../components/Pagination';
import {
	getDisapprovedPayments,
	getFilteredReleases,
	getFilteredReleasesPendingOperator,
	getFilteredScheduledReleases,
	getOtherPaymentsOperator,
	getScheduledPayments,
	PaymentsListReturn,
	ReleasesFilterReturn,
} from '../../../../services/queries/PaymentsV2';
import { useQuery, useQueryClient } from 'react-query';
import { convertCentsToFormattedReais } from '../../../../utils/CurrencyConvert';
import { showErrorMessage } from '../../../../utils/ErrorHandler';
import IconHover from '../../../../components/IconHover';
import { FaFileExcel, FaFilePdf, FaInfoCircle } from 'react-icons/fa';
import { Filter, FilterParams } from '../../../../components/Filter';
import { usePaymentsListStore } from '../../../../stores/usePaymentListStore';
import { useHistoryNonMatchCallback } from '../../../../hooks/useHistoryNonMatchCallback';
import { useSortColumnHook } from '../../../../hooks/useSortColumnHook';
import { SortColumnButton } from '../../../../components/SortColumnButton';
import { Dropdown, Menu } from 'uiw';

export default function PaymentsOperator({
	handleDownloadPayment,
}: PaymentsProps) {
	const { user, currentCompany, updateCompanyBalance } = useAuth();
	const queryClient = useQueryClient();
	const [showingTable, setShowingTable] = useState<string>();
	const {
		currentSortColumn: currentSortColumnPending,
		toggleSort: toggleSortPending,
	} = useSortColumnHook();
	const {
		currentSortColumn: currentSortColumnNonPending,
		toggleSort: toggleSortNonPending,
	} = useSortColumnHook();
	const {
		currentSortColumn: currentSortColumnScheduled,
		toggleSort: toggleSortScheduled,
	} = useSortColumnHook();
	const [disapprovedPaymentsCurrentPage, setDisapprovedPaymentsCurrentPage] =
		useState(1);
	const [filteredPaymentsCurrentPage, setFilteredPaymentsCurrentPage] =
		useState(1);
	const [scheduledReleasesCurrentPage, setScheduledReleasesCurrentPage] =
		useState(1);
	const [
		disapprovedReleasesFiltersParams,
		setDisapprovedReleasesFiltersParams,
		filteredReleasesParams,
		setFilteredReleasesParams,
		scheduledReleasesFiltersParams,
		setScheduledReleasesFiltersParams,
		resetFilters,
	] = usePaymentsListStore((state) => [
		state.pendingReleasesFiltersParams,
		state.setPendingReleasesFiltersParams,
		state.nonPendingReleasesFiltersParams,
		state.setNonPendingReleasesFiltersParams,
		state.scheduledReleasesFiltersParams,
		state.setScheduledReleasesFiltersParams,
		state.resetFilters,
	]);

	useHistoryNonMatchCallback('payments', resetFilters);

	function updateFilters(
		filters: FilterParams[],
		pending: boolean,
		scheduled: boolean
	) {
		if (pending) {
			setDisapprovedPaymentsCurrentPage(1);
			setDisapprovedReleasesFiltersParams(filters);
		} else if (scheduled) {
			setScheduledReleasesCurrentPage(1);
			setScheduledReleasesFiltersParams(filters);
		} else {
			setFilteredPaymentsCurrentPage(1);
			setFilteredReleasesParams(filters);
		}
	}

	const filteredDisapprovedReleasesQuery = useQuery<
		ReleasesFilterReturn,
		Error
	>(
		[
			'filteredReleases',
			disapprovedReleasesFiltersParams,
			true,
			currentCompany?.id,
			disapprovedPaymentsCurrentPage,
			currentSortColumnPending,
		],
		() => {
			return getFilteredReleasesPendingOperator(
				disapprovedReleasesFiltersParams,
				currentCompany?.id,
				true,
				disapprovedPaymentsCurrentPage,
				currentSortColumnPending
			);
		},
		{
			onError: (error) => {
				showErrorMessage(
					error as Error,
					'Não foi possível buscar os pagamentos. '
				);
			},
			enabled:
				!!disapprovedReleasesFiltersParams.find((f) => !!f.value) &&
				!!disapprovedPaymentsCurrentPage,
		}
	);

	const filteredScheduledReleasesQuery = useQuery<ReleasesFilterReturn, Error>(
		[
			'filteredReleases',
			scheduledReleasesFiltersParams,
			false,
			currentCompany?.id,
			scheduledReleasesCurrentPage,
			currentSortColumnScheduled,
		],
		() => {
			return getFilteredScheduledReleases(
				scheduledReleasesFiltersParams,
				currentCompany?.id,
				true,
				scheduledReleasesCurrentPage,
				currentSortColumnScheduled
			);
		},
		{
			onError: (error) => {
				showErrorMessage(
					error as Error,
					'Não foi possível buscar os pagamentos. '
				);
			},
			enabled:
				!!scheduledReleasesFiltersParams.find((f) => !!f.value) &&
				!!scheduledReleasesCurrentPage,
		}
	);

	const filteredReleasesQuery = useQuery<ReleasesFilterReturn, Error>(
		[
			'filteredReleases',
			filteredReleasesParams,
			false,
			currentCompany?.id,
			filteredPaymentsCurrentPage,
			currentSortColumnNonPending,
		],
		() => {
			return getFilteredReleases(
				filteredReleasesParams,
				currentCompany?.id,
				filteredPaymentsCurrentPage,
				currentSortColumnNonPending
			);
		},
		{
			onError: (error) => {
				showErrorMessage(
					error as Error,
					'Não foi possível buscar os pagamentos. '
				);
			},
			enabled:
				!!filteredReleasesParams.find((f) => !!f.value) &&
				!!filteredPaymentsCurrentPage,
		}
	);

	const otherPaymentsQuery = useQuery<PaymentsListReturn, Error>(
		[
			'otherPaymentsOperatorList',
			currentCompany?.id,
			filteredPaymentsCurrentPage,
			currentSortColumnNonPending,
		],
		() => {
			return getOtherPaymentsOperator(
				filteredPaymentsCurrentPage,
				currentCompany?.id,
				currentSortColumnNonPending
			);
		},
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível buscar os lançamentos. '
				);
			},
			keepPreviousData: true,
		}
	);

	const scheduledPaymentsQuery = useQuery<PaymentsListReturn, Error>(
		[
			'scheduledPaymentsList',
			currentCompany?.id,
			scheduledReleasesCurrentPage,
			currentSortColumnScheduled,
		],
		() => {
			return getScheduledPayments(
				scheduledReleasesCurrentPage,
				currentCompany?.id,
				currentSortColumnScheduled
			);
		},
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível buscar os lançamentos. '
				);
			},
			keepPreviousData: true,
		}
	);

	const disapprovedPaymentsQuery = useQuery<PaymentsListReturn, Error>(
		[
			'disapprovedPaymentsList',
			currentCompany?.id,
			disapprovedPaymentsCurrentPage,
			currentSortColumnPending,
		],
		() => {
			return getDisapprovedPayments(
				disapprovedPaymentsCurrentPage,
				currentCompany?.id,
				currentSortColumnPending
			);
		},
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível buscar os lançamentos. '
				);
			},
			keepPreviousData: true,
		}
	);

	function getTableTotalRecords(tableName: string) {
		if (tableName === 'Pendentes para correção') {
			if (disapprovedReleasesFiltersParams.find((f) => !!f.value)) {
				return filteredDisapprovedReleasesQuery.data?.totalRelease;
			}
			return disapprovedPaymentsQuery.data?.totalDisapproved;
		}

		if (tableName === 'Concluídos') {
			if (filteredReleasesParams.find((f) => !!f.value)) {
				return filteredReleasesQuery.data?.totalRelease;
			}
			return otherPaymentsQuery.data?.totalOther;
		}

		if (tableName === 'Agendados') {
			if (scheduledReleasesFiltersParams.find((f) => !!f.value)) {
				return filteredScheduledReleasesQuery.data?.totalRelease;
			}
			return scheduledPaymentsQuery.data?.totalScheduled;
		}
	}

	function reloadPage() {
		setDisapprovedPaymentsCurrentPage(1);
		setFilteredPaymentsCurrentPage(1);
		setScheduledReleasesCurrentPage(1);
		queryClient.resetQueries('disapprovedPaymentsList');
		queryClient.resetQueries('scheduledPaymentsList');
		queryClient.resetQueries('otherPaymentsOperatorList');
		queryClient.resetQueries('filteredReleases');

		updateCompanyBalance.refetch();
	}

	function isIndividualTableLoading(tableName: string) {
		if (tableName === 'Pendentes para correção')
			return (
				filteredDisapprovedReleasesQuery.isLoading ||
				disapprovedPaymentsQuery.isPreviousData
			);
		else if (tableName === 'Concluídos')
			return (
				filteredReleasesQuery.isLoading || otherPaymentsQuery.isPreviousData
			);

		return false;
	}

	function getSortColumnOrder(columnName: string, tableName: string) {
		if (tableName === 'Pendentes para correção') {
			return currentSortColumnPending?.name === columnName
				? currentSortColumnPending.order
				: null;
		} else if (tableName === 'Concluídos') {
			return currentSortColumnNonPending?.name === columnName
				? currentSortColumnNonPending.order
				: null;
		}

		return currentSortColumnScheduled?.name === columnName
			? currentSortColumnScheduled.order
			: null;
	}
	function handleToggleSortColumn(columnName: string, tableName: string) {
		if (tableName === 'Pendentes para correção') toggleSortPending(columnName);
		else if (tableName === 'Concluídos') toggleSortNonPending(columnName);
		else toggleSortScheduled(columnName);
	}

	function generateRow(payment: Payment) {
		return (
			<TableStyle.TableRow key={payment.id}>
				<TableStyle.TableData>{payment.order_id ?? 'N/A'}</TableStyle.TableData>
				<TableStyle.TableData>
					<TableStyle.DocumentContainer>
						<TableStyle.IconContainer>
							<BsFileEarmarkText />
						</TableStyle.IconContainer>
						{parseDate(payment.created_at, 1, payment.reference_month)}
						{payment.updated ? (
							payment.identify_operator_editor === user.id ? (
								<TableStyle.LabelEdited color={'#0AD1A8'}>
									<p>Você editou</p>
								</TableStyle.LabelEdited>
							) : (
								<TableStyle.LabelEdited>
									<p>Editado por {payment.editor_operator?.name}</p>
								</TableStyle.LabelEdited>
							)
						) : (
							<></>
						)}
					</TableStyle.DocumentContainer>
				</TableStyle.TableData>
				<TableStyle.TableData>
					{convertCentsToFormattedReais(Number(payment.total_value))}
				</TableStyle.TableData>
				<TableStyle.TableData>
					<TableStyle.StatusContainer>
						{getStatusIcon(payment.status)}
						<span>{parseStatusString(payment.status)}</span>
						{payment.status === 'canceled' && (
							<IconHover
								Icon={FaInfoCircle}
								textHover='Pagamento não efetivado. Relançamento disponível.'
								colorIcon='var(--primary-red)'
							/>
						)}
						{payment.status === 'chargeback-partial' && (
							<IconHover
								Icon={FaInfoCircle}
								textHover='Alguns colaboradores nesse pagamento não possuiam saldo suficiente para o estorno completo.'
								colorIcon='var(--primary-red)'
							/>
						)}
					</TableStyle.StatusContainer>
					{(payment.status === 'scheduled' ||
						(payment.scheduledDate && payment.status === 'requested')) && (
						<S.ScheduledDate>
							{new Date(payment.scheduledDate!).toLocaleString()}
						</S.ScheduledDate>
					)}
				</TableStyle.TableData>
				<TableStyle.TableData>
					<S.OptionsContainer>
						{payment.status === 'disapproved' ? (
							<TableStyle.OptionLink
								to={`/home/payments/${payment.id}/review`}
								data-rh='Corrigir pagamento'
							>
								<MdNotes />
							</TableStyle.OptionLink>
						) : (
							<>
								<TableStyle.OptionLink
									data-rh='Relançar este pagamento'
									to={`/home/payments/relaunch/${payment.id}`}
								>
									<MdOutlineRecycling />
								</TableStyle.OptionLink>

								<TableStyle.OptionLink
									data-rh='Detalhamento'
									to={`/home/payments/details/${payment.id}`}
								>
									<MdNotes />
								</TableStyle.OptionLink>

								<Dropdown
									menu={
										<Menu bordered style={{ minWidth: 120 }}>
											<Menu.Item
												key={'details'}
												text={'Detalhado'}
												onClick={async () =>
													await handleDownloadPayment(payment.id, true, true)
												}
											/>
											<Menu.Item
												key={'simplified'}
												text={'Simplificado'}
												onClick={async () =>
													await handleDownloadPayment(payment.id, true, false)
												}
											/>
										</Menu>
									}
									trigger='click'
									placement='bottom'
								>
									<TableStyle.OptionButton data-rh='Baixar relatório PDF'>
										<FaFilePdf />
									</TableStyle.OptionButton>
								</Dropdown>

								<TableStyle.OptionButton
									data-rh='Baixar relatório XLSX'
									onClick={async () =>
										await handleDownloadPayment(payment.id, false)
									}
								>
									<FaFileExcel />
								</TableStyle.OptionButton>
							</>
						)}
					</S.OptionsContainer>
				</TableStyle.TableData>
			</TableStyle.TableRow>
		);
	}

	function getTable(tableName: string, payments?: Payment[]) {
		if (isIndividualTableLoading(tableName) || !payments?.length) {
			return (
				<>
					<TableStyle.TitleWrapper>
						<PageTitle title={tableName} />
						{tableName === 'Pendentes para correção' ? (
							<>
								<Filter
									filterParams={disapprovedReleasesFiltersParams}
									onFiltersChanged={(updatedFilters) => {
										updateFilters(updatedFilters, true, false);
									}}
								/>
							</>
						) : (
							<>
								<Filter
									filterParams={filteredReleasesParams}
									onFiltersChanged={(updatedFilters) => {
										updateFilters(updatedFilters, false, false);
									}}
								/>
							</>
						)}
						{tableName === 'Pendentes para correção' && (
							<TableStyle.ReloadIcon type='reload' onClick={reloadPage} />
						)}
					</TableStyle.TitleWrapper>
					{isIndividualTableLoading(tableName) ? (
						<Loader />
					) : (
						<EmptyContent text='Não há ítens a serem exibidos.' />
					)}
				</>
			);
		}

		if (!payments?.length)
			return (
				<>
					<TableStyle.TitleWrapper>
						{tableName === 'Pendentes para correção' ? (
							<>
								<PageTitle title={tableName} />
								<TableStyle.ReloadIcon type='reload' onClick={reloadPage} />
							</>
						) : (
							<PageTitle title={tableName} />
						)}
					</TableStyle.TitleWrapper>
					<EmptyContent text='Não há ítens a serem exibidos.' />
				</>
			);

		return (
			<>
				<TableStyle.TableHeaderContainer>
					<TableStyle.TitleWrapper>
						<PageTitle
							title={tableName}
							totalRecords={getTableTotalRecords(tableName) ?? 0}
						/>
						{tableName === 'Pendentes para correção' ? (
							<>
								<Filter
									filterParams={disapprovedReleasesFiltersParams}
									onFiltersChanged={(updatedFilters) => {
										updateFilters(updatedFilters, true, false);
									}}
								/>
							</>
						) : (
							<>
								<Filter
									filterParams={filteredReleasesParams}
									onFiltersChanged={(updatedFilters) => {
										updateFilters(updatedFilters, false, false);
									}}
								/>
							</>
						)}
						{showingTable === tableName ? (
							<TableStyle.SeeLess
								onClick={() =>
									showingTable === tableName
										? setShowingTable('')
										: setShowingTable(tableName)
								}
							>
								Ver menos
							</TableStyle.SeeLess>
						) : (
							<TableStyle.SeeMore
								onClick={() =>
									showingTable === tableName
										? setShowingTable('')
										: setShowingTable(tableName)
								}
							>
								Ver mais
							</TableStyle.SeeMore>
						)}
					</TableStyle.TitleWrapper>
					{showingTable === tableName ? (
						tableName === 'Pendentes para correção' ? (
							disapprovedReleasesFiltersParams.find((f) => !!f.value) ? (
								<Pagination
									onPageChange={(page) =>
										setDisapprovedPaymentsCurrentPage(page)
									}
									currentPage={disapprovedPaymentsCurrentPage}
									totalCount={
										filteredDisapprovedReleasesQuery.data
											? filteredDisapprovedReleasesQuery.data.totalRelease!
											: 1
									}
								/>
							) : (
								<Pagination
									onPageChange={(page) =>
										setDisapprovedPaymentsCurrentPage(page)
									}
									currentPage={disapprovedPaymentsCurrentPage}
									totalCount={
										disapprovedPaymentsQuery.data
											? disapprovedPaymentsQuery.data?.totalDisapproved!
											: 1
									}
								/>
							)
						) : filteredReleasesParams.find((f) => !!f.value) ? (
							<Pagination
								onPageChange={(page) => setFilteredPaymentsCurrentPage(page)}
								currentPage={filteredPaymentsCurrentPage}
								totalCount={
									filteredReleasesQuery.data
										? filteredReleasesQuery.data.totalRelease!
										: 1
								}
							/>
						) : (
							<Pagination
								onPageChange={(page) => setFilteredPaymentsCurrentPage(page)}
								currentPage={filteredPaymentsCurrentPage}
								totalCount={
									otherPaymentsQuery.data
										? otherPaymentsQuery.data.totalOther!
										: 1
								}
							/>
						)
					) : (
						<></>
					)}
					{tableName !== 'Concluídos' && (
						<TableStyle.ReloadIcon type='reload' onClick={reloadPage} />
					)}
				</TableStyle.TableHeaderContainer>

				<TableStyle.Table>
					<TableStyle.TableHeader>
						<TableStyle.TableRow>
							<TableStyle.TableHeaderCell>ID</TableStyle.TableHeaderCell>
							<TableStyle.TableHeaderCell>
								<SortColumnButton
									order={getSortColumnOrder('created_at', tableName)}
									onToggleSort={() =>
										handleToggleSortColumn('created_at', tableName)
									}
								/>
								PERÍODO
							</TableStyle.TableHeaderCell>
							<TableStyle.TableHeaderCell>
								<SortColumnButton
									order={getSortColumnOrder('total_value', tableName)}
									onToggleSort={() =>
										handleToggleSortColumn('total_value', tableName)
									}
								/>
								VALOR TOTAL
							</TableStyle.TableHeaderCell>
							<TableStyle.TableHeaderCell>STATUS</TableStyle.TableHeaderCell>
							<TableStyle.TableHeaderCell>OPÇÕES</TableStyle.TableHeaderCell>
						</TableStyle.TableRow>
					</TableStyle.TableHeader>

					<TableStyle.TableBody>
						{showingTable === tableName
							? payments?.map((payment) => generateRow(payment))
							: generateRow(payments[0])}
					</TableStyle.TableBody>
				</TableStyle.Table>
			</>
		);
	}

	function getTableScheduled(tableName: string, payments?: Payment[]) {
		if (isIndividualTableLoading(tableName) || !payments?.length) {
			return (
				<>
					<TableStyle.TitleWrapper>
						<PageTitle title={tableName} />
						{tableName === 'Agendados' && (
							<>
								<Filter
									filterParams={scheduledReleasesFiltersParams}
									onFiltersChanged={(updatedFilters) => {
										updateFilters(updatedFilters, false, true);
									}}
								/>
							</>
						)}
					</TableStyle.TitleWrapper>
					{isIndividualTableLoading(tableName) ? (
						<Loader />
					) : (
						<EmptyContent text='Não há ítens a serem exibidos.' />
					)}
				</>
			);
		}
		return (
			<>
				<TableStyle.TableHeaderContainer>
					<TableStyle.TitleWrapper>
						<PageTitle
							title={tableName}
							totalRecords={getTableTotalRecords(tableName) ?? 0}
						/>
						{tableName === 'Agendados' ? (
							<>
								<Filter
									filterParams={scheduledReleasesFiltersParams}
									onFiltersChanged={(updatedFilters) => {
										updateFilters(updatedFilters, false, true);
									}}
								/>
							</>
						) : (
							<></>
						)}
						{showingTable === tableName ? (
							<TableStyle.SeeLess
								onClick={() =>
									showingTable === tableName
										? setShowingTable('')
										: setShowingTable(tableName)
								}
							>
								Ver menos
							</TableStyle.SeeLess>
						) : (
							<TableStyle.SeeMore
								onClick={() =>
									showingTable === tableName
										? setShowingTable('')
										: setShowingTable(tableName)
								}
							>
								Ver mais
							</TableStyle.SeeMore>
						)}
					</TableStyle.TitleWrapper>

					{showingTable === tableName ? (
						tableName === 'Agendados' ? (
							scheduledReleasesFiltersParams.find((f) => !!f.value) ? (
								<Pagination
									onPageChange={(page) => setScheduledReleasesCurrentPage(page)}
									currentPage={scheduledReleasesCurrentPage}
									totalCount={
										filteredScheduledReleasesQuery.data
											? filteredScheduledReleasesQuery.data?.totalRelease!
											: 1
									}
								/>
							) : (
								<></>
							)
						) : (
							<></>
						)
					) : (
						<></>
					)}
				</TableStyle.TableHeaderContainer>

				<TableStyle.Table>
					<TableStyle.TableHeader>
						<TableStyle.TableRow>
							<TableStyle.TableHeaderCell>ID</TableStyle.TableHeaderCell>
							<TableStyle.TableHeaderCell>
								<SortColumnButton
									order={getSortColumnOrder('created_at', tableName)}
									onToggleSort={() =>
										handleToggleSortColumn('created_at', tableName)
									}
								/>
								PERÍODO
							</TableStyle.TableHeaderCell>
							<TableStyle.TableHeaderCell>
								<SortColumnButton
									order={getSortColumnOrder('total_value', tableName)}
									onToggleSort={() =>
										handleToggleSortColumn('total_value', tableName)
									}
								/>
								VALOR TOTAL
							</TableStyle.TableHeaderCell>
							<TableStyle.TableHeaderCell>STATUS</TableStyle.TableHeaderCell>
							<TableStyle.TableHeaderCell>OPÇÕES</TableStyle.TableHeaderCell>
						</TableStyle.TableRow>
					</TableStyle.TableHeader>

					<TableStyle.TableBody>
						{showingTable === tableName
							? payments.map((payment) => generateRow(payment))
							: generateRow(payments[0])}
					</TableStyle.TableBody>
				</TableStyle.Table>
			</>
		);
	}

	// if (loading && !(disapprovedPayments.length || disapprovedPayments.length)) {
	if (otherPaymentsQuery.isLoading || disapprovedPaymentsQuery.isLoading) {
		return (
			<S.Container>
				<PageTitle title={'Pagamentos'} />
				<Loader />
			</S.Container>
		);
	}
	return (
		<S.Container>
			{getTable(
				'Pendentes para correção',
				disapprovedReleasesFiltersParams.find((f) => !!f.value)
					? filteredDisapprovedReleasesQuery.data?.requestedReleases
					: disapprovedPaymentsQuery.data?.disapprovedReleases
			)}

			{getTable(
				'Concluídos',
				filteredReleasesParams.find((f) => !!f.value)
					? filteredReleasesQuery.data?.requestedReleases
					: otherPaymentsQuery.data?.otherReleases
			)}

			{getTableScheduled(
				'Agendados',
				scheduledReleasesFiltersParams.find((f) => !!f.value)
					? filteredScheduledReleasesQuery.data?.requestedReleases
					: scheduledPaymentsQuery.data?.scheduledReleases
			)}
		</S.Container>
	);
}
