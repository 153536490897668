export function isPaymentEditable(
	status: string,
	userCreatorId: string,
	currentUserId: string
) {
	return (
		(status === 'requested' || status === 'scheduled') &&
		userCreatorId === currentUserId
	);
}
