import { useAuth } from '../../../../../hooks/useAuth';
import { useQuery } from 'react-query';
import { CORPWAY_USERS_KEY } from '../../../../../services/queries/Corpway/User';
import {
	CORPWAY_COST_CENTER_KEY,
	getCostCenters,
	getCostCenterUsers,
} from '../../../../../services/queries/Corpway/CostCenters';
import { showErrorMessage } from '../../../../../utils/ErrorHandler';
import { TitleTypography } from '../../../../../componentsV2/ui/Typography';
import { InputField } from '../../../../../componentsV2/ui/Form/InputField';
import { SelectField } from '../../../../../componentsV2/ui/Form/SelectField';
import { Loader } from '../../../../../componentsV2/ui/Loader';
import { CurrencyInput } from '../../../../../componentsV2/ui/Form/CurrencyInput';
import { useEffect, useState } from 'react';
import { FormStepProps } from './FormStep1';
import * as S from './styles';
import styled from 'styled-components';
import { InputWithEditButton } from './InputWithEditButton';
import { User } from '../../../../../@types/CorporateExpenses/User';

const FormContainer = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	gap: 2rem;
	margin: 3rem 3rem 3rem 0;
`;

const DarkInput = styled(InputField)`
	background-color: #c5c5c5;
	&:focus {
		outline: 0.2rem solid black;
	}
`;

const DarkCurrencyInput = styled(CurrencyInput)`
	background-color: #c5c5c5;
	&:focus {
		outline: 0.2rem solid black;
	}
`;

const DarkSelect = styled(SelectField)`
		background-color: #C5C5C5;
		&:focus {
        outline: 0.2rem solid black;
`;

export function FormStep3({ form }: FormStepProps) {
	const { formState, register, watch, setValue, clearErrors } = form;
	const { currentCompany, user: currentUser } = useAuth();
	const [editingDisabled, setEditingDisabled] = useState([
		true,
		true,
		true,
		true,
		true,
	]);

	const costCenterSelected = watch('cost_center_id');
	const limit_value_alert = watch('limit_value_alert');

	const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);

	useEffect(() => {
		const handleResize = () => setIsMobile(window.innerWidth <= 1024);
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	useEffect(() => {
		if (!limit_value_alert) {
			clearErrors('limit_value');
			setValue('limit_value', '0,00');
		}
	}, [limit_value_alert]); // eslint-disable-line

	const getUsersSelectQuery = useQuery(
		[CORPWAY_USERS_KEY, costCenterSelected, currentCompany?.id],
		() => getCostCenterUsers(costCenterSelected, currentCompany!.id),
		{
			onError: (err) => {
				showErrorMessage(err as Error, 'Não foi possível buscar os usuários. ');
			},
			enabled: !!costCenterSelected,
		}
	);

	const getCostCentersSelectQuery = useQuery(
		[CORPWAY_COST_CENTER_KEY, currentCompany?.id],
		() => getCostCenters(currentCompany!.id),
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível buscar os centros de custo. '
				);
			},
		}
	);

	function enableFields(input: number) {
		let newValues = [...editingDisabled];
		newValues[input] = false;
		setEditingDisabled(newValues);
	}

	function orderUsers(users: User[] | undefined): User[] | undefined {
		return users?.sort((a, b) => a.name.localeCompare(b.name));
	}

	return (
		<>
			<TitleStyle weight='600' primaryText='Revise'>
				as informações do cartão
			</TitleStyle>

			<S.GraySection>
				<S.EditCardSection>
					<S.CardImageComponent isMobile={isMobile} />
					<FormContainer>
						<InputWithEditButton enableFields={() => enableFields(0)}>
							<DarkInput
								disabled={editingDisabled[0]}
								errorMessage={formState.errors.alias?.message}
								label='Qual será o nome deste cartão?'
								name='alias'
								id='alias'
								register={register}
								maxLength={20}
							/>
						</InputWithEditButton>

						<InputWithEditButton enableFields={() => enableFields(1)}>
							<DarkSelect
								disabled={editingDisabled[1]}
								errorMessage={formState.errors.cost_center_id?.message}
								label='Qual área utilizará o cartão?'
								name='cost_center_id'
								id='cost_center_id'
								data-testid='accessInput_test_id'
								register={register}
								required
							>
								{getCostCentersSelectQuery.data?.costCenters.map(
									(costCenter) => (
										<option key={costCenter.id} value={costCenter.id}>
											{costCenter.title}
										</option>
									)
								)}
							</DarkSelect>
						</InputWithEditButton>

						<InputWithEditButton enableFields={() => enableFields(2)}>
							{getUsersSelectQuery.isLoading ? (
								<Loader />
							) : (
								<S.Column>
									<DarkSelect
										errorMessage={formState.errors.user_id?.message}
										label={'Quem vai utilizar o cartão?'}
										name='user_id'
										id='user_id'
										data-testid='inputUser_test_id'
										register={register}
										disabled={!costCenterSelected || editingDisabled[2]}
										required
									>
										{orderUsers(getUsersSelectQuery.data)?.map((user) => (
											<option key={user.id} value={user.id}>
												{user.operator_id === currentUser.id
													? 'Você'
													: user.name}
											</option>
										))}
									</DarkSelect>
								</S.Column>
							)}
						</InputWithEditButton>

						<InputWithEditButton enableFields={() => enableFields(3)}>
							<DarkCurrencyInput
								disabled={editingDisabled[3]}
								label={'Valor inicial'}
								errorMessage={formState.errors.initial_value?.message}
								useCurrencyLabel={false}
								name='initial_value'
								id='initial_value'
								register={register}
								required
							/>
						</InputWithEditButton>

						<InputWithEditButton enableFields={() => enableFields(4)}>
							<DarkCurrencyInput
								label={'Receber alertas à partir de (0,00 para desabilitado):'}
								disabled={editingDisabled[4]}
								errorMessage={formState.errors.limit_value?.message}
								useCurrencyLabel={false}
								name='limit_value'
								id='limit_value'
								register={register}
								required
							/>
						</InputWithEditButton>
					</FormContainer>
				</S.EditCardSection>
			</S.GraySection>
		</>
	);
}

const TitleStyle = styled(TitleTypography)`
	font-size: 2.4rem;

	@media (max-width: 768px) {
		font-size: 2rem;
	}
`;
