import styled from 'styled-components';
import { OptionsButton } from '../../../components/Button';
import { OptionStyles } from '../../../components/Table/TableStyles';

export const Container = styled.div`
	flex: 1;
	padding: 4rem 7rem 2rem 7rem;
	overflow: auto;
`;

export const Header = styled.div`
	display: flex;
	align-items: center;
	font-size: 1.4rem;
	> svg {
		width: 2rem;
		height: 2rem;
		margin-left: 6rem;
		margin-right: 1rem;
	}
`;

export const InfoContainer = styled.div`
	display: flex;
	flex-direction: column;
	font-size: 1.6rem;
	margin-bottom: 3rem;
	max-width: 80%;
	overflow: auto;
`;

export const InfoTitle = styled.h2`
	font-weight: 500;
	font-size: 1.8rem;
	color: var(--light-gray);
	margin-bottom: 1rem;
	text-transform: uppercase;
`;

export const InfoTop = styled.div`
	display: grid;
	grid-template-columns: 50% 50%;
	width: 100%;
	align-items: flex-start;
	margin-top: 3rem;
`;

export const BenefitValueFormContainer = styled.div`
	display: flex;
	column-gap: 0.5rem;
	width: 20rem;
`;

export const EditValueBtn = styled.button`
	${OptionsButton}
	padding: 0;
	width: 10rem;
	height: 3rem;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const AttachmentsContainer = styled.ul`
	display: flex;
`;

export const ImagesList = styled.li`
	list-style: none;
	cursor: pointer;
`;

export const AttachmentImage = styled.img`
	width: 12rem;
	height: 12rem;
	border-radius: 0.6rem;
	margin-right: 1rem;
	object-fit: contain;
`;

export const OptionsContainer = styled.div`
	display: flex;
	float: right;
	margin-top: 2rem;
	margin-right: 6rem;
`;

export const ReproveOption = styled.button`
	${OptionsButton}
	margin-right: 2rem;
	background-color: var(--white);
	color: var(--primary-blue);
`;
export const ApproveOption = styled.button`
	${OptionsButton}
`;

export const AttachmentLink = styled.a`
	${OptionStyles}
	margin: 0;
`;
