import bountyLogo from '../../../../assets/BountyBlue.svg';
import boomerang from '../../../../assets/Boomerang.svg';
import defaultAvatar from '../../../../assets/avatar.svg';
import * as S from './styles';
import { Popover } from 'react-tiny-popover';
import { useState } from 'react';
import { MdOutlineKeyboardArrowDown } from 'react-icons/md';
import { useAuth } from '../../../../hooks/useAuth';
import { Link, useRouteMatch } from 'react-router-dom';
import { Typography } from '../../../../componentsV2/ui/Typography';
import { NotificationsList } from './NotificationsList';
import { Sidebar } from '../Sidebar';

export function Header() {
	const { path } = useRouteMatch();
	const { user, currentCompany, signOut } = useAuth();
	const [isProductPopoverOpen, setProductPopoverOpen] = useState(false);
	const [isUserPopoverOpen, setUserPopoverOpen] = useState(false);

	const isFlexibleBenefitsAvailable =
		user.flexible_benefits_access &&
		currentCompany?.company_products.product_flexible_benefits_enabled;

	function handleToggleOpenProductPopover() {
		setProductPopoverOpen(!isProductPopoverOpen);
	}
	function handleToggleOpenUserPopover() {
		setUserPopoverOpen(!isUserPopoverOpen);
	}

	return (
		<S.Container>
			<S.LogoContainer to='/'>
				<div>
					<img src={bountyLogo} alt='Bounty Control'></img>
					<S.BoomerangSVG
						src={boomerang}
						alt='Boomerang Bounty'
					></S.BoomerangSVG>
				</div>
				<span>Control</span>
			</S.LogoContainer>

			<S.ContextContainer>
				<S.MenuAction>
					<Sidebar />
				</S.MenuAction>
				<S.MainContent>
					<S.PopoverContainer>
						{isFlexibleBenefitsAvailable && (
							<Popover
								isOpen={isProductPopoverOpen}
								positions={['bottom']} // preferred positions by priority
								onClickOutside={handleToggleOpenProductPopover}
								content={
									<S.DropdownContainer>
										<S.DropdownItem>
											<Link to='/home'>Benefícios Flexíveis</Link>
										</S.DropdownItem>
									</S.DropdownContainer>
								}
							>
								<S.OpenProductOptionsBtn
									onClick={handleToggleOpenProductPopover}
								>
									Bounty Control
									<MdOutlineKeyboardArrowDown />
								</S.OpenProductOptionsBtn>
							</Popover>
						)}
					</S.PopoverContainer>
					<S.UserContainer>
						<Typography
							weight='600'
							color='var(--primary-blue)'
							style={{ display: 'flex' }}
						>
							Olá,&nbsp;
							<Typography color='var(--dark-gray)' weight='600'>
								{user.name}
							</Typography>
						</Typography>
						<Popover
							isOpen={isUserPopoverOpen}
							positions={['bottom']} // preferred positions by priority
							onClickOutside={handleToggleOpenUserPopover}
							content={
								<S.DropdownContainer>
									<S.DropdownItem>
										<Link
											to={`${path}/profile`}
											onClick={handleToggleOpenUserPopover}
											style={{ textDecoration: 'none' }}
										>
											Perfil
										</Link>
									</S.DropdownItem>
									<S.Divider />
									<S.DropdownItem>
										<button onClick={signOut}>Sair</button>
									</S.DropdownItem>
								</S.DropdownContainer>
							}
						>
							<S.ActionsButton>
								<S.UserAvatarBtn onClick={handleToggleOpenUserPopover}>
									<img
										src={user.avatar_url ?? defaultAvatar}
										alt='Usuário avatar'
									/>
								</S.UserAvatarBtn>
								<NotificationsList />
							</S.ActionsButton>
						</Popover>
					</S.UserContainer>
				</S.MainContent>
			</S.ContextContainer>
		</S.Container>
	);
}
