import styled from 'styled-components';

export const PageWrapper = styled.div`
	display: flex;
	flex-direction: column;
	min-height: 100vh;
`;

export const ContentWrapper = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	background-color: #fff;
`;

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	background-color: #fff;
	border-radius: 1.6rem;
	width: 100%;
	box-sizing: border-box;
`;

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	margin-top: 1rem;
	font-size: 1.4rem;
	text-align: center;
	width: 100%;
`;

export const Btn = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	background-color: #fff;
	padding: 2rem;
	border-radius: 1.6rem;
`;

export const TopContainer = styled.div`
	width: 100%;
	height: 16rem;
	background-color: var(--primary-red);
	overflow: hidden;
	display: flex;
	justify-content: center;
	border-radius: 0 0 3.2rem 3.2rem;
	& > img {
		height: 30rem;
		margin-top: -2rem;
	}
`;

export const SignOutBtn = styled.button`
	background: none;
	display: flex;
	column-gap: 1rem;
	align-items: center;
	color: #9c9c9c;
	font-weight: 600;
	font-size: 1.6rem;
	width: fit-content;
	& > svg {
		width: 2.2rem;
		height: 2.2rem;
	}
`;

export const Link = styled.a`
	font-weight: bold;
	color: var(--primary-blue);
`;
