import { useState } from 'react';
import Modal from '../../../../../componentsV2/ui/Modal';
import {
	CORPWAY_USERS_KEY,
	CardsOnlyUsers,
	getCorpwayUsersByCostCenter,
} from '../../../../../services/queries/Corpway/User';
import styled from 'styled-components';
import { MdCancel, MdNotes, MdOutlineKeyboardArrowRight } from 'react-icons/md';
import { Button } from '../../../../../componentsV2/ui/Button';
import { RiCloseLine } from 'react-icons/ri';
import { useQuery } from 'react-query';
import { showErrorMessage } from '../../../../../utils/ErrorHandler';
import { InfoContent } from '../../../../../componentsV2/InfoContent';
import { formatCardNumber } from '../../../../../utils/formatCardNumber';
import { SelectField } from '../../../../../componentsV2/ui/Form/SelectField';
import { useForm } from 'react-hook-form';
import HeaderModal from '../../../../../componentsV2/ui/Modal/HeaderModal';

interface Props {
	user: {
		user_id: string;
	} & CardsOnlyUsers;
	newUserId: string | null;
	onCardSelection: (c: string | null, u: string) => void;
	cost_center_id: string;
}

interface FormData {
	newUserId: string;
}

const SelectCardsNewUser = ({ user, newUserId, onCardSelection, cost_center_id }: Props) => {
	const [isOpen, setOpen] = useState(false);
	const { register, handleSubmit } = useForm<FormData>({
		defaultValues: {
			newUserId: newUserId ?? user.user_id,
		},
	});

	const getUserListQuery = useQuery([CORPWAY_USERS_KEY, cost_center_id], () => getCorpwayUsersByCostCenter(cost_center_id), {
		onError: (err) => {
			showErrorMessage(err as Error, 'Não foi possível buscar os usuários. ');
		},
	});

	const users = getUserListQuery.data;
	function handleSubmitForm(d: FormData) {
		if (d.newUserId !== user.user_id) {
			onCardSelection(d.newUserId, user.card_id);
		}
		setOpen(false);
	}

	const OpenButton = () => {
		if (newUserId === null) {
			return (
				<SelectedUserContainer>
					<Button width='contain' intent='ghost' onClick={() => setOpen(true)}>
						<MdNotes />
					</Button>
				</SelectedUserContainer>
			);
		}
		return (
			<SelectedUserContainer>
				<MdOutlineKeyboardArrowRight size={20} color='var(--primary-blue)' />
				<Button
					onClick={() => setOpen(true)}
					intent='link'
					style={{
						color: 'var(--primary-blue)',
						borderColor: 'var(--primary-blue)',
					}}
				>
					{users?.find((c) => c.id === newUserId)?.name}
				</Button>
				<RiCloseLine
					size={20}
					style={{ cursor: 'pointer' }}
					color='var(--primary-red)'
					onClick={() => onCardSelection(null, user.card_id)}
				/>
			</SelectedUserContainer>
		);
	};

	return (
		<div>
			<OpenButton />

			<Modal
				isOpen={isOpen}
				onRequestClose={() => setOpen(false)}
				showClose={false}
			>
				<ContentContainer>
					<HeaderModal
						icon={{
							icon: <MdCancel size={50} color={'red'} />,
							background: '#f0f3f8',
						}}
						title={[
							{ text: 'Cartão ', isBold: false },
							{ text: 'sem responsável', isBold: true },
						]}
					/>
					<InformationContainer>
						<InfoContent label='Apelido'>{user.card_alias}</InfoContent>
						<InfoContent label='Número do Cartão'>
							{formatCardNumber(user.card_pan)}
						</InfoContent>
					</InformationContainer>
					<form
						onSubmit={(e) => {
							e.preventDefault();
							handleSubmit(handleSubmitForm)();
							e.stopPropagation();
						}}
					>
						<SelectField
							label='Novo Responsável'
							name='newUserId'
							id='newUserId'
							placeholderText={false}
							register={register}
							required
						>
							<option value={user.user_id} disabled>
								{users?.find((c) => c.id === user.user_id)?.name}
							</option>

							{users
								?.filter((u) => u.id !== user.user_id)
								.map((u) => (
									<option key={u.id} value={u.id}>
										{u.name}
									</option>
								))}
						</SelectField>
						<OptionsContainer>
							<Button
								type='button'
								onClick={() => setOpen(false)}
								intent='terciary'
								$outline
								roundness='lg'
							>
								Voltar
							</Button>
							<Button type='submit' roundness='lg'>
								Salvar
							</Button>
						</OptionsContainer>
					</form>
				</ContentContainer>
			</Modal>
		</div>
	);
};

export default SelectCardsNewUser;

const ContentContainer = styled.div`
	display: grid;
	width: 40rem;
	row-gap: 2rem;
	& > form {
		width: 100%;
		display: grid;
		row-gap: 2rem;
		padding: 0rem 2rem;
	}

	@media (max-width: 1024px) {
		width: 100%;
	}
`;

const InformationContainer = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 2rem;
`;

const SelectedUserContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 2rem;
`;
const OptionsContainer = styled.div`
	display: flex;
	justify-content: space-around;
	padding: 2rem;
`;
