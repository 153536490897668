import {
	Collaborator,
	CollaboratorUnderageDocument,
	UnderageResponsible,
} from '../../@types';
import {
	FilterParams,
	parseFilterParamsToParams,
} from '../../components/Filter';
import {
	SortColumn,
	parseSortColumnToParams,
} from '../../hooks/useSortColumnHook';
import { parseMaskedCPFToRaw, parseMaskedPhoneToRaw } from '../../utils/masks';
import api from '../api';

export interface CollaboratorsActiveReturn {
	totalActive: number;
	collaboratorStatusActive: Collaborator[];
}

export async function getActiveCollaborators(
	activeCollaboratorsCurrentPage: number,
	companyId?: string,
	sortColumn: SortColumn | null = null
) {
	const { data } = await api.get<CollaboratorsActiveReturn>(
		`/api/v1/rh/companies/${companyId}/collaborators/q?page_active=${activeCollaboratorsCurrentPage}`,
		{
			params: {
				...(sortColumn && { sort_by_active: sortColumn.name }),
				...(sortColumn && { sort_order_active: sortColumn.order }),
			},
		}
	);

	return data;
}

interface CollaboratorsDesactiveReturn {
	totalDesactive: number;
	collaboratorStatusDesactive: Collaborator[];
}
export async function getInactiveCollaborators(
	inactiveCollaboratorsCurrentPage: number,
	companyId?: string,
	sortColumn: SortColumn | null = null
) {
	const { data } = await api.get<CollaboratorsDesactiveReturn>(
		`/api/v1/rh/companies/${companyId}/collaborators/q?page_desactive=${inactiveCollaboratorsCurrentPage}`,
		{
			params: {
				...(sortColumn && { sort_by_desactive: sortColumn.name }),
				...(sortColumn && { sort_order_desactive: sortColumn.order }),
			},
		}
	);

	return data;
}

export interface FilterCollabsReturn {
	totalCount: number;
	collaborators: Collaborator[];
}

export async function getFilteredCollabs(
	filterParams: FilterParams[],
	active?: boolean,
	companyId?: string,
	page?: number,
	sortColumn: SortColumn | null = null
): Promise<FilterCollabsReturn> {
	const URL = page
		? `/api/v1/rh/companies/${companyId}/collaborators/filter/q?page=${page}`
		: `/api/v1/rh/companies/${companyId}/collaborators/filter/q?`;

	const { data } = await api.get(URL, {
		params: {
			...parseFilterParamsToParams(filterParams),
			...parseSortColumnToParams(sortColumn),
			status: active,
		},
	});

	return data;
}

export interface CollabDetailsReturn {
	collaborator: Collaborator;
	document?: CollaboratorUnderageDocument;
}
export async function fetchCollaborator(
	collaboratorId: string,
	companyId?: string
): Promise<CollabDetailsReturn> {
	const { data } = await api.get(
		`/api/v1/rh/companies/${companyId}/collaborators/${collaboratorId}`
	);

	return data;
}

// interface UpdateCollaboratorKYCData
export interface UpdateCollaboratorData {
	data: {
		address?: string;
		birth_date?: string;
		cep?: string;
		city?: string;
		complement?: string;
		contract_type?: string;
		district?: string;
		email?: string;
		first_phone?: string;
		first_name?: string;
		last_name?: string;
		mother_name?: string;
		number?: string;
		office?: string;
		branch_office?: string;
		second_phone?: string;
		section?: string;
		uf?: string;
	};
	id_cost_center?: string;
	responsible?: UnderageResponsible;
	document?: File;
}
export async function updateCollaborator(
	collaboratorId: string,
	updatedCollabData: UpdateCollaboratorData,
	companyId?: string
) {
	// parse collaborator to FormData
	const formData = new FormData();
	const fields = Object.keys(updatedCollabData.data) as Array<
		keyof typeof updatedCollabData.data
	>;

	fields.forEach((key) => {
		formData.append(`data[${key.toString()}]`, updatedCollabData.data[key]!);
	});

	if (updatedCollabData.id_cost_center)
		formData.append('id_cost_center', updatedCollabData.id_cost_center);
	if (updatedCollabData.document)
		formData.append('document', updatedCollabData.document);
	if (updatedCollabData.responsible) {
		const responsible_fields = Object.keys(
			updatedCollabData.responsible
		) as Array<keyof typeof updatedCollabData.responsible>;
		responsible_fields.forEach((key) => {
			formData.append(
				`responsible[${key.toString()}]`,
				updatedCollabData.responsible![key]
			);
		});
	}

	const { data } = await api.put(
		`/api/v1/rh/companies/${companyId}/collaborators/${collaboratorId}`,
		formData
	);

	return data;
}

export async function toggleStatus(
	collaboratorId: string,
	status: boolean,
	companyId?: string
): Promise<Collaborator> {
	const { data } = await api.patch(
		`/api/v1/rh/companies/${companyId}/collaborators/${collaboratorId}/status`,
		{
			status,
		}
	);

	return data;
}

// export async function deleteCollaborator(
// 	collaboratorId: string,
// 	deletionReason: string,
// 	companyId?: string
// ): Promise<void> {
// 	return await api.delete(
// 		`/api/v1/rh/companies/${companyId}/collaborators/${collaboratorId}`,
// 		{ data: { deletionReason } }
// 	);
// }

export async function requestDeleteUserCollaborator(
	user_id: string,
	deletionReason: string,
	company_id?: string
): Promise<void> {
	const { data } = await api.post(
		`/api/v1/rh/companies/${company_id}/collaborators/request-delete/${user_id}`,
		{ deletionReason }
	);

	return data;
}

export async function createCollaborator(
	collaborator: Collaborator,
	companyId?: string
): Promise<void> {
	// parse collaborator to FormData
	const formData = new FormData();
	const fields = Object.keys(collaborator) as Array<keyof typeof collaborator>;
	fields.forEach((key) => {
		if (key === 'responsible' && collaborator.responsible) {
			collaborator.responsible!.phone = parseMaskedPhoneToRaw(
				collaborator.responsible.phone
			);
			collaborator.responsible!.cpf = parseMaskedCPFToRaw(
				collaborator.responsible.cpf
			);

			const responsible_fields = Object.keys(collaborator.responsible) as Array<
				keyof typeof collaborator.responsible
			>;
			responsible_fields.forEach((key) => {
				formData.append(
					`responsible[${key.toString()}]`,
					collaborator.responsible![key]
				);
			});
		} else {
			formData.append(key.toString(), collaborator[key] as string | Blob);
		}
	});

	await api.post(`/api/v1/rh/companies/${companyId}/collaborators`, formData);
}

interface CreateCollaboratorsBatchResponse {
	inconsistentDataUnregisteredUsers: {
		cpf: string;
		name: string;
		error: string;
		email: string;
	}[];
}
export async function createCollaboratorsBatch(
	users: Collaborator[],
	file_name: string,
	companyId: string
) {
	const { data } = await api.post<CreateCollaboratorsBatchResponse>(
		`/api/v1/rh/companies/${companyId}/collaborators/batches`,
		{
			users,
			file_name,
		}
	);
	return data;
}

export async function changePassword(password: string, token: string) {
	const { data } = await api.post(
		`/api/v1/collaborators/password/reset`,
		{ password },
		{
			params: { token },
		}
	);

	return data;
}

export async function getCollaboratorsFilteredByCPFs(
	CPFs: string[],
	companyId: string,
	cardInfo?: boolean
) {
	const params: { cpfs: string; card_info?: boolean } = {
		cpfs: CPFs.join(','),
	};
	if (cardInfo) params.card_info = true;

	const { data } = await api.get<{ collaborators: Collaborator[] }>(
		`/api/v1/rh/companies/${companyId}/collaborators-cpf/q`,
		{
			params,
		}
	);
	return data;
}

interface BatchesProgressResponse {
	dataProgress: {
		id: string;
		file_name: string;
		progress: string;
	}[];
}
export async function getBatchesProgress(companyId: string) {
	const { data } = await api.get<BatchesProgressResponse>(
		`/api/v1/rh/companies/${companyId}/collaborators/batches/progress`
	);
	return data;
}

export async function removeBatchesFiles(
	filesIds: string[],
	companyId: string
) {
	return await api.delete(
		`/api/v1/rh/companies/${companyId}/collaborators/batches/files`,
		{
			data: {
				files: filesIds,
			},
		}
	);
}

interface CardsStats {
	totalCardsRequested: number;
	collabsWithCard: number;
	collabsWithoutCard: number;
	collabsWithCardPending: number;
	collabsWithCardProcessing: number;
}
export async function getCardsStats(companyId: string) {
	const { data } = await api.get<CardsStats>(
		`/api/v1/rh/companies/${companyId}/collaborators/cards-quantitative/q`,
		{
			params: { status: true },
		}
	);
	return data;
}
