import { useEffect, useState, useRef } from 'react';
import avatarImg from '../../../../../../assets/avatar.svg';
import { RiAddCircleLine, RiCloseCircleLine } from 'react-icons/ri';
import * as S from './styles';
import * as TableStyle from '../../../../../../components/Table/TableStyles';
import { useQuery } from 'react-query';
import { Collaborator } from '../../../../../../@types';
import { useAuth } from '../../../../../../hooks/useAuth';
import { Filter, FilterParams } from '../../../../../../components/Filter';
import {
	FilterCollabsReturn,
	getActiveCollaborators,
	getFilteredCollabs,
} from '../../../../../../services/queries/Collaborators';
import Modal from '../../../../../../components/Modal';
import PageTitle from '../../../../../../components/PageTitle';
import { Pagination } from '../../../../../../components/Pagination';
import Loader from '../../../../../../components/Loader';
import { EmptyContent } from '../../../../../../components/EmptyContent';
import { ConfirmCollabSelectionModal } from './ConfirmCollabSelectionModal';
import { showErrorMessage } from '../../../../../../utils/ErrorHandler';
import { ContractTypeOptionsFilters } from '../../../../../../components/Filter/utils/SelectFilters';
import {
	checkOnlyNumbersInComplement,
	warningOnlyNumbersComplementMessage,
} from '../../../../../../utils/validateCollaborator';
import { COLLABORATORS_BATCH_LIMIT } from '../../../utils/COLLABORATORS_BATCH_LIMIT';
import { useDialogModal } from '../../../../../../hooks/useDialogModal';

export interface CardBatchCollabsSelectorProps {
	alreadyAddedCollabs: Collaborator[]; // list of already added collaborators to forbid selecting again
	onAddNewCollaborators(c: Collaborator[]): void;
}

export function CardBatchCollabsSelector({
	alreadyAddedCollabs,
	onAddNewCollaborators,
}: CardBatchCollabsSelectorProps) {
	const { currentCompany } = useAuth();
	const { openOptionsDialog } = useDialogModal();
	const [open, setOpen] = useState(false);
	const listContainer = useRef<HTMLUListElement | null>(null);
	const [selectedCollaborators, setSelectedCollaborators] = useState<
		Collaborator[]
	>([]);

	const [hasMadeChanges, setHasMadeChanges] = useState(false);
	const [collaboratorsCurrentPage, setCollaboratorsCurrentPage] = useState(1);

	const [collabsFiltersParams, setCollabsFiltersParams] = useState<
		FilterParams[]
	>([
		{
			name: 'Nome',
			filter: 'name',
			value: '',
			type: 'text',
			selected: false,
		},
		{
			name: 'Nome da mãe',
			filter: 'mother_name',
			value: '',
			type: 'text',
			selected: false,
		},
		{ name: 'CPF', filter: 'cpf', value: '', type: 'cpf', selected: false },
		{
			name: 'Email',
			filter: 'email',
			value: '',
			type: 'email',
			selected: false,
		},
		{
			name: 'Cargo',
			filter: 'office',
			value: '',
			type: 'text',
			selected: false,
		},
		{
			name: 'Setor de atuação',
			filter: 'section',
			value: '',
			type: 'text',
			selected: false,
		},
		{ name: 'UF', filter: 'uf', value: '', type: 'uf', selected: false },
		{
			name: 'Contrato',
			filter: 'contract_type',
			value: '',
			type: 'select',
			selectOptions: ContractTypeOptionsFilters,
			selected: false,
		},
		{
			name: 'Centro de custo',
			filter: 'cost_center',
			value: '',
			type: 'cost_center',
			selected: false,
		},
	]);
	const [collabToConfirm, setCollabToConfirm] = useState<
		Collaborator | undefined
	>();

	useEffect(() => {
		listContainer.current?.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
	}, [collaboratorsCurrentPage, listContainer]);

	const activeCollabsQuery = useQuery(
		['activeCollabsList', currentCompany?.id, collaboratorsCurrentPage],
		() => {
			return getActiveCollaborators(
				collaboratorsCurrentPage,
				currentCompany?.id
			);
		},
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível buscar os colaboradores. '
				);
			},
			enabled: open,
			keepPreviousData: true,
		}
	);

	const filteredCollabsQuery = useQuery<FilterCollabsReturn, Error>(
		[
			'filteredCollabsQuery',
			collabsFiltersParams,
			true,
			currentCompany?.id,
			collaboratorsCurrentPage,
		],
		() => {
			return getFilteredCollabs(
				collabsFiltersParams,
				true,
				currentCompany?.id,
				collaboratorsCurrentPage
			);
		},
		{
			onError: (error) => {
				showErrorMessage(
					error as Error,
					'Não foi possível buscar os colaboradores. '
				);
			},
			enabled:
				collabsFiltersParams.some((f) => !!f.value) &&
				!!collaboratorsCurrentPage,
		}
	);

	useEffect(() => {
		if (open) {
			// reseting
			setSelectedCollaborators([]);
			setCollaboratorsCurrentPage(1);
			setHasMadeChanges(false);
		}
	}, [open]);

	function handleSubmit() {
		if (selectedCollaborators.length) {
			onAddNewCollaborators(selectedCollaborators);
		}

		setOpen(false);
	}

	function handleClose() {
		if (!hasMadeChanges) {
			setOpen(false);
			return;
		}

		openOptionsDialog(
			'Alterações não salvas. Tem certeza que deseja sair?',
			'Confirmar',
			() => setOpen(false),
			'Cancelar',
			() => {}
		);
	}

	function getFilteredCollabsQueryList() {
		if (collabsFiltersParams.some((f) => !!f.value)) {
			return filteredCollabsQuery.data?.collaborators;
		}
		return activeCollabsQuery.data?.collaboratorStatusActive;
	}

	function isCollabSelected(id: string) {
		return !!selectedCollaborators.find((c) => c.id === id);
	}

	function isCollabAlreadyAdded(collab: Collaborator) {
		return (
			!!alreadyAddedCollabs.find(
				(collabAdded) => collabAdded.id === collab.id
			) || collab.kyc_status !== 'approved'
		);
	}

	function handleSelectCollaborator(collaborator: Collaborator) {
		setHasMadeChanges(true);
		if (collaborator.card_info.card_status === 'linked') {
			setCollabToConfirm(collaborator);
		} else {
			setSelectedCollaborators([...selectedCollaborators, collaborator]);
		}
	}

	function handleDeselectCollaborator(collaborator: Collaborator) {
		setHasMadeChanges(true);
		setSelectedCollaborators(
			selectedCollaborators.filter((c) => c.id !== collaborator.id)
		);
	}

	function updateFilters(filters: FilterParams[]) {
		setCollaboratorsCurrentPage(1);
		setCollabsFiltersParams(filters);
	}

	function getTotalRecords() {
		if (collabsFiltersParams.find((f) => !!f.value)) {
			return filteredCollabsQuery.data?.totalCount;
		}
		return activeCollabsQuery.data?.totalActive;
	}

	// verifications functions
	function checkPendingCardArrival(collaborator: Collaborator) {
		return (
			collaborator.card_batch_items.length > 0 &&
			(collaborator.card_batch_items[0].status === 'pending' ||
				collaborator.card_batch_items[0].status === 'processing')
		);
	}
	function checkDisabledToAddCollaborator(collaborator: Collaborator) {
		if (
			selectedCollaborators.length + alreadyAddedCollabs.length >=
			COLLABORATORS_BATCH_LIMIT
		)
			return true;
		return (
			checkPendingCardArrival(collaborator) ||
			!checkOnlyNumbersInComplement(collaborator.complement)
		);
	}
	function getDisabledToAddCollaboratorMessage(collaborator: Collaborator) {
		if (
			selectedCollaborators.length + alreadyAddedCollabs.length >=
			COLLABORATORS_BATCH_LIMIT
		)
			return `Limite de ${COLLABORATORS_BATCH_LIMIT} colaboradores para a remessa foi atingido`;
		if (checkPendingCardArrival(collaborator))
			return 'Colaborador já possui solicitação de cartão em pendência ou processando';
		return warningOnlyNumbersComplementMessage;
	}

	function handleSelector(collaborator: Collaborator) {
		//Non-selected to add
		if (
			!isCollabAlreadyAdded(collaborator) &&
			!isCollabSelected(collaborator.id!)
		) {
			return (
				<S.Selector
					selected={isCollabSelected(collaborator.id!)}
					onClick={() => handleSelectCollaborator(collaborator)}
					data-testid={`${collaborator.id}-select`}
					disabled={checkDisabledToAddCollaborator(collaborator)}
					title={
						checkDisabledToAddCollaborator(collaborator)
							? getDisabledToAddCollaboratorMessage(collaborator)
							: ''
					}
				>
					<RiAddCircleLine />
				</S.Selector>
			);
		}

		//Already added
		if (
			isCollabAlreadyAdded(collaborator) &&
			!isCollabSelected(collaborator.id!)
		) {
			return (
				<S.Selector
					selected={false}
					remove={true}
					disabled={true}
					title={'Colaborador já adicionado'}
					data-testid={`${collaborator.id}-remove`}
				>
					<RiCloseCircleLine />
				</S.Selector>
			);
		}

		//Selected to add
		return (
			<S.Selector
				selected={isCollabSelected(collaborator.id!)}
				onClick={() => handleDeselectCollaborator(collaborator)}
				data-testid={`${collaborator.id}-unselect`}
			>
				<RiCloseCircleLine />
			</S.Selector>
		);
	}

	if (
		filteredCollabsQuery.isLoading ||
		activeCollabsQuery.isLoading ||
		activeCollabsQuery.isPreviousData ||
		filteredCollabsQuery.isPreviousData
	) {
		return (
			<div>
				<S.MainButton onClick={() => setOpen(true)}>
					Adicionar colaborador
				</S.MainButton>
				<Modal isOpen={open} enableClose onRequestClose={handleClose}>
					<S.Container data-testid='CardBatchCollabsSelector-modal-container'>
						<TableStyle.TableHeaderContainer>
							<PageTitle title='Selecione colaboradores' />
							<Filter
								filterParams={collabsFiltersParams}
								onFiltersChanged={(updatedFilters) => {
									updateFilters(updatedFilters);
								}}
							/>
							{collabsFiltersParams.some((f) => !!f.value) ? (
								<Pagination
									onPageChange={(page) => setCollaboratorsCurrentPage(page)}
									currentPage={collaboratorsCurrentPage}
									totalCount={
										filteredCollabsQuery.data
											? filteredCollabsQuery.data?.totalCount!
											: 1
									}
								/>
							) : (
								<Pagination
									onPageChange={(page) => setCollaboratorsCurrentPage(page)}
									currentPage={collaboratorsCurrentPage}
									totalCount={
										activeCollabsQuery.data
											? activeCollabsQuery.data.totalActive!
											: 1
									}
								/>
							)}
						</TableStyle.TableHeaderContainer>

						<Loader />
					</S.Container>
				</Modal>
			</div>
		);
	}

	return (
		<div>
			<S.MainButton onClick={() => setOpen(true)}>
				Adicionar colaborador
			</S.MainButton>

			<Modal isOpen={open} enableClose onRequestClose={handleClose}>
				<S.Container>
					<TableStyle.TableHeaderContainer>
						<PageTitle
							title='Selecione colaboradores'
							totalRecords={getTotalRecords() ?? 0}
						/>
						<Filter
							filterParams={collabsFiltersParams}
							onFiltersChanged={(updatedFilters) => {
								updateFilters(updatedFilters);
							}}
						/>
						{collabsFiltersParams.some((f) => !!f.value) ? (
							<Pagination
								onPageChange={(page) => setCollaboratorsCurrentPage(page)}
								currentPage={collaboratorsCurrentPage}
								totalCount={
									filteredCollabsQuery.data
										? filteredCollabsQuery.data?.totalCount!
										: 1
								}
							/>
						) : (
							<Pagination
								onPageChange={(page) => setCollaboratorsCurrentPage(page)}
								currentPage={collaboratorsCurrentPage}
								totalCount={
									activeCollabsQuery.data
										? activeCollabsQuery.data.totalActive!
										: 1
								}
							/>
						)}
					</TableStyle.TableHeaderContainer>

					<S.CollaboratorsList ref={listContainer}>
						{getFilteredCollabsQueryList()?.map((collaborator) => (
							<S.CollaboratorContainer key={collaborator.id}>
								<S.CollaboratorCard>
									<S.CollaboratorAvatar
										src={
											collaborator.avatar ? collaborator.avatar_url : avatarImg
										}
									/>

									<S.CollaboratorInfoContainer>
										<S.CollaboratorName>{`${collaborator.first_name} ${collaborator.last_name}`}</S.CollaboratorName>
										<S.CollaboratorInfo>
											{collaborator.office}
										</S.CollaboratorInfo>
										<S.CollaboratorInfo>
											{collaborator.section}
										</S.CollaboratorInfo>
										<S.CollaboratorInfo>
											{collaborator.email}
										</S.CollaboratorInfo>
									</S.CollaboratorInfoContainer>
								</S.CollaboratorCard>

								{handleSelector(collaborator)}
							</S.CollaboratorContainer>
						))}
						{!getFilteredCollabsQueryList()?.length && (
							<EmptyContent text='Nenhum colaborador disponível' />
						)}
					</S.CollaboratorsList>

					<S.MainButton style={{ width: '16rem' }} onClick={handleSubmit}>
						Salvar
					</S.MainButton>

					<ConfirmCollabSelectionModal
						collab={collabToConfirm}
						onConfirm={() => {
							setSelectedCollaborators([
								...selectedCollaborators,
								collabToConfirm!,
							]);
							setCollabToConfirm(undefined);
						}}
						onCancel={() => setCollabToConfirm(undefined)}
					/>
				</S.Container>
			</Modal>
		</div>
	);
}
