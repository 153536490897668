import { IconType } from 'react-icons';

interface IconHoverProps {
	Icon: IconType;
	textHover: string;
	colorIcon?: string;
	sizeIcon?: string;
}

export default function IconHover({
	Icon,
	textHover,
	colorIcon,
	sizeIcon = '2.0rem',
}: IconHoverProps) {
	return <Icon data-rh={textHover} color={colorIcon} size={sizeIcon} />;
}
