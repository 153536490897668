import styled from 'styled-components';
import { Typography } from '../../../../../componentsV2/ui/Typography';
import cardBlack from '../../../../../assets/card-slider.svg';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	border-radius: 1rem 0 0 1rem;
	background-color: var(--white);
	width: 42rem;
	height: 100vh;
	min-height: 76rem;
	overflow: hidden;

	@media (max-width: 500px) {
		width: 34rem;
	}
`;

export const HeaderContainer = styled.div`
	display: flex;
	flex-direction: column;
	min-height: 24rem;
	border-radius: 1rem 0 0 0;
	padding: 2rem;
	overflow: hidden;
	position: relative;
	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: url(${cardBlack}) no-repeat center/cover;
		z-index: 1;
	}

	&.blocked::before,
	&.canceled::before {
		filter: grayscale(100%);
	}

	& > div {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		width: 100%;
		height: 100%;
		position: relative;
		z-index: 2;

		& > svg {
			width: 44rem;
			height: 10rem;
			position: absolute;
			color: var(--primary-red) !important;
			pointer-events: none;
			margin-bottom: 5rem;
			z-index: 1;

			@media (max-width: 550px) {
				width: 30rem;
				margin-bottom: 8rem;
				height: 7rem;
			}
		}
	}
	@media (max-width: 1024px) {
		min-height: 20rem;
	}
`;

export const Children = styled.div`
	height: 32rem;
	padding: 3.2rem 1rem 0 0;
`;

export const CardDataContainer = styled.div`
	flex-direction: column;
	z-index: 1;
	text-wrap: nowrap;
`;
export const CardDataBottom = styled.div`
	display: flex;
	& > div {
		display: flex;
		column-gap: 0.6rem;
		align-items: center;
		flex: 1;
		& > label {
			margin-bottom: 0;
			color: #fff;
			font-size: 1.4rem;
		}
		& > p {
			font-size: 1.6rem;
		}
	}
`;
export const Form = styled.form`
	padding: 2rem 4rem;
	display: flex;
	flex-direction: column;
	row-gap: 2rem;
`;
export const BalanceText = styled(Typography)`
	margin: 0 0 2rem 2rem;
`;
export const HeaderButtons = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	row-gap: 1rem;
	gap: 1rem;
	svg {
		width: 2.4rem;
		height: 2.4rem;
	}
`;
export const BottomExtractOptions = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1rem;
	padding-top: 6.5rem;
`;

export const Row = styled.div`
	display: flex;
	flex-direction: row;
	gap: 1rem;
	align-items: center;
`;
