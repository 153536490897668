import { useQuery } from 'react-query';
import { getPublicPrivacyPolicy } from '../../services/queries/Terms';
import * as S from './styles';
import LogoControl from '../../assets/BountyControl.svg';
import LogoBounty from '../../assets/BountyBlue.svg';
import Loader from '../../components/Loader';
import { showErrorMessage } from '../../utils/ErrorHandler';
import { CorporateExpensesTheme } from '../../styles/CorporateExpensesTheme';
import { useParams } from 'react-router-dom';

interface PublicPrivacyParams {
	privacyType?: string;
}

export function PublicPrivacyPolicy() {
	const { privacyType } = useParams<PublicPrivacyParams>();
	let type: string;
	if (privacyType == 'multiflex') {
		type = 'multiflex';
	} else {
		type = 'corpway';
	}
	const logo = privacyType === 'multiflex' ? LogoBounty : LogoControl;

	const privacyQuery = useQuery(
		['privacyPolicy'],
		() => {
			return getPublicPrivacyPolicy(type);
		},
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível buscar os avisos de privacidade. '
				);
			},
			refetchOnWindowFocus: false,
		}
	);

	if (privacyQuery.isLoading) {
		return (
			<S.Container>
				<S.LogoImage src={logo} alt='Logo do Bounty' />
				<S.TextTitle>Avisos de privacidade</S.TextTitle>
				<div style={{ marginTop: '3rem' }} />
				<Loader />
			</S.Container>
		);
	}
	return (
		<S.Container>
			<CorporateExpensesTheme />
			<S.LogoImage
				className='responsiveImage'
				src={logo}
				alt='Logo do Bounty'
			/>
			<S.TextsContainer>
				<S.InformationContainer>
					<S.TextTitle>Avisos de privacidade</S.TextTitle>
					<S.LogoImage
						className='desktopImage'
						src={logo}
						alt='Logo do Bounty'
					/>
				</S.InformationContainer>
				<S.TermsContainer
					dangerouslySetInnerHTML={{
						__html: privacyQuery.data ? privacyQuery.data : '',
					}}
				/>
			</S.TextsContainer>
		</S.Container>
	);
}
