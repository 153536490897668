export const INPUT_CHANGE_EVENT = 'change';

export function addBenefitInputEventListener(
	input: HTMLInputElement | null,
	callback: () => void
) {
	input?.addEventListener(INPUT_CHANGE_EVENT, callback);
}

export function removeBenefitInputEventListener(
	input: HTMLInputElement | null,
	callback: () => void
) {
	input?.removeEventListener(INPUT_CHANGE_EVENT, callback);
}
