import { useMutation } from 'react-query';
import { getCardExtractByPeriodToReport } from '../../../../../../services/queries/Corpway/Extract';
import { useAuth } from '../../../../../../hooks/useAuth';
import { showErrorMessage } from '../../../../../../utils/ErrorHandler';
import TXN_TYPES from '../../../../constants/TXN_TYPE.json';
import { Card } from '../../../../../../@types/CorporateExpenses/Card';
import { Props } from './PDFGenerator';
import { ReportProps } from '..';

export function OFXReportGenerator() {
	const { currentCompany } = useAuth();

	const getExtractQuery = useMutation(
		({ card, start_date, end_date, filters }: Props) =>
			getCardExtractByPeriodToReport(
				card!.id,
				currentCompany!.id,
				start_date,
				end_date,
				filters
			),
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao buscar as transações para o relatório.'
				);
			},
		}
	);

	function formatOFXDate(date: Date) {
		const year = date.getFullYear();
		const month = String(date.getMonth() + 1).padStart(2, '0');
		const day = String(date.getDate()).padStart(2, '0');
		const hours = String(date.getHours()).padStart(2, '0');
		const minutes = String(date.getMinutes()).padStart(2, '0');
		const seconds = String(date.getSeconds()).padStart(2, '0');

		return `${year}${month}${day}${hours}${minutes}${seconds}`;
	}

	function formatMoneyAmount(amount: number, credit: boolean) {
		const reaisAmount = amount / 100;

		return credit ? reaisAmount : -reaisAmount;
	}

	async function generateOFXContent(
		card: Card,
		start_date: number,
		end_date: number,
		filters: ReportProps['filters']
	) {
		const transactions = await getExtractQuery.mutateAsync({
			card,
			start_date,
			end_date,
			filters,
		});

		if (transactions.transactions.length === 0) {
			throw new Error('empty_transactions');
		}

		const startDateParsed = new Date(start_date);
		const endDateParsed = new Date(end_date);

		const transactionsStartDate = formatOFXDate(startDateParsed);
		const transactionsEndDate = formatOFXDate(endDateParsed);

		// OFX header
		let ofxContent = `OFXHEADER:100
DATA:OFXSGML
VERSION:102
SECURITY:NONE
ENCODING:UTF-8
CHARSET:1252
COMPRESSION:NONE
OLDFILEUID:NONE
NEWFILEUID:NONE
`;

		// OFX transactions
		const transactionsBody = transactions.transactions
			.map(
				(transaction) => `
				<STMTTRN>
					<TRNTYPE>${transaction.financial_impact_type.toUpperCase()}
					<DTPOSTED>${formatOFXDate(new Date(transaction.added_time))}
					<TRNAMT>${formatMoneyAmount(
						transaction.billing_amount,
						transaction.financial_impact_type === 'credit'
					)}
					<FITID>${transaction.internal_transaction_id}
					<MEMO>${TXN_TYPES[String(transaction.txn_type) as keyof typeof TXN_TYPES]}${
					transaction.merchant_name ? ` - ${transaction.merchant_name}` : ''
				}
				</STMTTRN>
				`
			)
			.join(' ');

		// OFX body
		ofxContent += `<OFX>
<CREDITCARDMSGSRSV1>
	<CCSTMTTRNRS>
		<TRNUID>0
		<STATUS>
			<CODE>0
			<SEVERITY>INFO
		</STATUS>
		<CCSTMTRS>
			<CURDEF>BRL                
			<BANKTRANLIST>
				<DTSTART>${transactionsStartDate}
				<DTEND>${transactionsEndDate}   
				${transactionsBody}
			</BANKTRANLIST>                                
		</CCSTMTRS>
	</CCSTMTTRNRS>
</CREDITCARDMSGSRSV1>
<AVAILBAL>
	<BALAMT>${Number(card?.balance) / 100}
	<DTASOF>${formatOFXDate(new Date())}
</AVAILBAL>
</OFX>`;

		return ofxContent;
	}

	async function downloadFile({ card, start_date, end_date, filters }: Props) {
		const content = await generateOFXContent(
			card!,
			start_date,
			end_date,
			filters
		);
		const blob = new Blob([content], { type: 'text/plain' });
		const url = URL.createObjectURL(blob);
		const a = document.createElement('a');
		a.href = url;

		const startDate = new Date(start_date);
		const endDate = new Date(end_date);

		a.download = `Bounty_Control_${card?.alias}_${card?.pan.slice(
			-4
		)}_${startDate.toLocaleDateString('pt-BR', { timeZone: 'UTC' })}_${endDate.toLocaleDateString(
			'pt-BR', { timeZone: 'UTC' }
		)}`;
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
		URL.revokeObjectURL(url);
	}

	return {
		generateOFX: downloadFile,
		loading: getExtractQuery.isLoading,
	};
}
