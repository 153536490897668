import { useContext } from 'react';
import {
	PaymentContext,
	PaymentContextData,
} from '../contexts/PaymentContextV2';

export function usePayment(): PaymentContextData {
	const context = useContext(PaymentContext);

	return context;
}
