import { create } from 'zustand';
import { FilterParams } from '../components/Filter';
import { RefundStatusOptionsFilters } from '../components/Filter/utils/SelectFilters';

type Store = {
	pendingRefundsFiltersParams: FilterParams[];
	setPendingRefundsFiltersParams: (f: FilterParams[]) => void;
	nonPendingRefundsFiltersParams: FilterParams[];
	setNonPendingRefundsFiltersParams: (f: FilterParams[]) => void;
	resetFilters: () => void;
};

const filterParams: FilterParams[] = [
	{
		name: 'Título',
		filter: 'title',
		value: '',
		type: 'text',
		selected: false,
	},
	{
		name: 'Descrição',
		filter: 'description',
		value: '',
		type: 'text',
		selected: false,
	},
	{
		name: 'Nome',
		filter: 'name',
		value: '',
		type: 'text',
		selected: false,
	},
	{
		name: 'Data de solicitação',
		filter: 'date',
		value: '',
		type: 'date_interval',
		selected: false,
	},
];

const nonPendingFilterParams: FilterParams[] = [
	{
		name: 'Título',
		filter: 'title',
		value: '',
		type: 'text',
		selected: false,
	},
	{
		name: 'Descrição',
		filter: 'description',
		value: '',
		type: 'text',
		selected: false,
	},
	{
		name: 'Nome',
		filter: 'name',
		value: '',
		type: 'text',
		selected: false,
	},
	{
		name: 'Data de análise',
		filter: 'date',
		value: '',
		type: 'date_interval',
		selected: false,
	},
	{
		name: 'Status',
		filter: 'status',
		value: '',
		type: 'select',
		selectOptions: RefundStatusOptionsFilters,
		selected: false,
	},
];

const useRefundsListStore = create<Store>()((set) => ({
	pendingRefundsFiltersParams: filterParams,
	setPendingRefundsFiltersParams: (f) =>
		set({ pendingRefundsFiltersParams: f }),
	nonPendingRefundsFiltersParams: nonPendingFilterParams,
	setNonPendingRefundsFiltersParams: (f) =>
		set({ nonPendingRefundsFiltersParams: f }),
	resetFilters: () =>
		set({
			pendingRefundsFiltersParams: filterParams,
			nonPendingRefundsFiltersParams: nonPendingFilterParams,
		}),
}));

export { useRefundsListStore };
