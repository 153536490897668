import styled from 'styled-components';
import { TitleTypography } from '../../../../componentsV2/ui/Typography';
import { Button } from '../../../../componentsV2/ui/Button';
import { useMutation, useQueryClient } from 'react-query';
import { useAuth } from '../../../../hooks/useAuth';
import { showErrorMessage } from '../../../../utils/ErrorHandler';
import {
	CORPWAY_CARDS_KEY,
	createCard,
} from '../../../../services/queries/Corpway/Cards';
import {
	CardBaID,
	CreateCardData,
} from '../../../../@types/CorporateExpenses/Card';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { parseCurrencyStrToNumber } from '../../../../utils/parseCurrency';
import { useDialogModal } from '../../../../hooks/useDialogModal';
import { Breadcrumb } from '../../../../componentsV2/BreadCrumb';
import { SectionContainer } from '../../../../componentsV2/ui/SectionContainer';
import PreventTransitionPrompt from '../../../../components/PreventTransitionPrompt';
import { useEffect, useState } from 'react';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { StepHeader } from './components/FormStepHeader';
import { FormStep1 } from './components/FormStep1';
import { FormStep2 } from './components/FormStep2';
import { FormStep3 } from './components/FormStep3';
import InformationModal from '../../../../componentsV2/ui/Modal/InformationModal';
import { parseMoneyMaskToRaw } from '../../../../utils/masks';
import { MdCheck } from 'react-icons/md';
import { convertReaisToCents } from '../../../../utils/CurrencyConvert';

export type CreateCardFormData = {
	alias: string;
	cost_center_id: string;
	user_id: string;
	initial_value: string;
	ba_id?: CardBaID;
	use_initial_value: boolean;
	limit_value_alert: boolean;
	limit_value: string;
};

const schema = z
	.object({
		initial_value: z.string(),
		use_initial_value: z.boolean(),
		limit_value_alert: z.boolean(),
		limit_value: z.string(),
		alias: z
			.string()
			.trim()
			.min(1, 'O nome do cartão deve ser informado')
			.max(20, 'O nome do cartão deve ter no máximo 20 caracteres'),
		cost_center_id: z
			.string()
			.min(1, 'Selecione em que área o cartão será utilizado'),
		user_id: z.string().min(2, 'Selecione o usuário responsável pelo cartão'),
	})
	.refine(
		({ use_initial_value, initial_value }) => {
			if (use_initial_value) {
				return parseFloat(parseMoneyMaskToRaw(initial_value)) > 0;
			}
			return true;
		},
		{
			message: 'Necessário inserir valor mínimo para o cartão',
			path: ['initial_value'],
		}
	)
	.refine(
		({ limit_value, limit_value_alert }) => {
			if (limit_value_alert) {
				return parseFloat(parseMoneyMaskToRaw(limit_value)) > 0;
			}
			return true;
		},
		{
			message: 'Necessário inserir valor mínimo para alerta',
			path: ['limit_value'],
		}
	)
	.transform((data) => {
		data.alias =
			data.alias.charAt(0).toUpperCase() + data.alias.slice(1).toLowerCase();
		return data;
	});

export function CreateCard() {
	const { currentCompany, companyBalance } = useAuth();
	const { openOptionsDialog } = useDialogModal();
	const queryClient = useQueryClient();
	const history = useHistory();
	const form = useForm<CreateCardFormData>({
		resolver: zodResolver(schema),
		defaultValues: {
			initial_value: '0,00',
		},
	});
	const {
		handleSubmit,
		getValues,
		trigger,
		formState: { dirtyFields },
	} = form;

	const [blockNavigation, setBlockNavigation] = useState(false);
	const [currentSection, setCurrentSection] = useState(1);
	const [informationModalVisible, setInformationModalVisible] = useState(false);
	const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

	useEffect(() => {
		const handleResize = () => setIsMobile(window.innerWidth <= 768);
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	const isFormDirty =
		dirtyFields.alias ||
		dirtyFields.cost_center_id ||
		dirtyFields.user_id ||
		parseCurrencyStrToNumber(getValues('initial_value')) > 0;

	useEffect(() => {
		setBlockNavigation(isFormDirty);
	}, [isFormDirty]);

	const createCardMutation = useMutation(
		(d: CreateCardData) => createCard(d, currentCompany!.id),
		{
			onSuccess: () => {
				setBlockNavigation(false);
				queryClient.resetQueries('corpway-balances');
				queryClient.invalidateQueries([CORPWAY_CARDS_KEY]);
				setInformationModalVisible(true);
			},
			onError: (err) => {
				showErrorMessage(err as Error, 'Não foi possível criar o cartão. ');
			},
		}
	);

	const { isLoading } = createCardMutation;

	function handleCreateCard(d: CreateCardFormData) {
		let initial_value = parseCurrencyStrToNumber(d.initial_value);
		let limit_value = parseCurrencyStrToNumber(d.limit_value);

		const createCardData: CreateCardData = {
			alias: d.alias,
			ba_id: d.ba_id,
			cost_center_id: d.cost_center_id,
			initial_value: convertReaisToCents(initial_value),
			limit_value: convertReaisToCents(limit_value),
			limit_value_alert: d.limit_value_alert,
			user_id: d.user_id,
		};

		if (initial_value > companyBalance.corpway_balance) {
			openOptionsDialog(
				'Saldo inicial do cartão é maior que a quantidade de saldo disponível para a empresa. Continuar com a operação substituindo o valor inicial por R$ 0,00?',
				'Confirmar',
				() =>
					createCardMutation.mutate({ ...createCardData, initial_value: 0 }),
				'Cancelar',
				() => {}
			);

			return;
		}

		createCardMutation.mutate(createCardData);
	}

	function getFormPart(tab: number) {
		switch (tab) {
			case 1:
				return <FormStep1 form={form} />;
			case 2:
				return <FormStep2 form={form} />;
			case 3:
				return <FormStep3 form={form} />;
		}
	}

	function handleOnNextPressed() {
		switch (currentSection) {
			case 1:
				trigger(['alias', 'cost_center_id', 'user_id']).then(
					(validationSuccess) => {
						if (validationSuccess) {
							setCurrentSection(currentSection + 1);
						}
					}
				);
				return;
			case 2:
				trigger(['initial_value', 'limit_value']).then((validationSuccess) => {
					if (validationSuccess) {
						setCurrentSection(currentSection + 1);
					}
				});
				return;
			case 3:
				handleSubmit(handleCreateCard)();
				return;
		}
	}

	function handleOnBackPressed() {
		if (currentSection === 1) {
			history.goBack();
			return;
		}
		setCurrentSection(currentSection - 1);
	}

	return (
		<div style={{ height: '100%' }}>
			{blockNavigation && <PreventTransitionPrompt />}

			<SectionContainer>
				<Breadcrumb
					routes={[
						{ title: 'Cartões', route: '/corporate-expenses/cards' },
						{
							title: 'Criação de cartão',
							route: '/corporate-expenses/cards/create',
						},
					]}
				/>

				<TitleStyle image primaryText='Criação'>
					de cartão
				</TitleStyle>
			</SectionContainer>

			<SectionContainer style={{ height: '100%' }}>
				<Form>
					<FormContainer>
						<StepHeader activeUntil={currentSection} />

						{getFormPart(currentSection)}
						{currentSection === 3 ? (
							<SubmitContainer
								style={{
									marginBottom: '3rem',
									marginTop: '1rem'
								}}
							>	
								<Button
									$outline={true}
									width = {isMobile ? 'contain' : 'adjusted'}
									roundness='lg'
									intent={isMobile ? 'link' : 'terciary'}
									onClick={handleOnBackPressed}
								>
									Voltar
								</Button>
								<Button
									width='adjusted'
									roundness='lg'
									type={'button'}
									loading={isLoading}
									onClick={handleOnNextPressed}
									shrinkOnLoading={false}
								>
									Criar
								</Button>
							</SubmitContainer>	
						): (
							<SubmitContainer
								style={{
									marginBottom: '3rem',
									marginTop: '3rem'
								}}
							>
								<Button
									$outline
									width = {isMobile ? 'contain' : 'adjusted'}
									roundness = 'lg'
									intent={isMobile ? 'link' : 'terciary'}
									onClick = { handleOnBackPressed }
								>
									Voltar
								</Button>
								<Button
									width='adjusted'
									roundness='lg'
									type={'button'}
									loading={isLoading}
									onClick={handleOnNextPressed}
									shrinkOnLoading={false}
								>
									Próximo
								</Button>
							</SubmitContainer>
						)}
					</FormContainer>
				</Form>
			</SectionContainer>

			<InformationModal
				size='40rem'
				title={[
					{ text: 'Cartão ' },
					{ text: 'criado com sucesso', isBold: true },
				]}
				titleIcon={<MdCheck size={30} color={'white'} />}
				color={'primary'}
				backButtonText={'Fechar'}
				onBack={() => {
					setInformationModalVisible(false);
					history.push('/corporate-expenses/cards');
				}}
				isOpen={informationModalVisible}
				onRequestClose={() => {
					setInformationModalVisible(false);
					history.push('/corporate-expenses/cards');
				}}
			>
				<SuccessContainer />
			</InformationModal>
		</div>
	);
}

const Form = styled.form`
	display: flex;
	flex-direction: column;
	row-gap: 2rem;
	margin: 2rem 10rem 0 10rem;
	align-items: center;
	height: 100%;
	@media (max-width: 1024px) {
		margin: 0rem;
		width: 100%;
	}
`;

const SubmitContainer = styled.div`
	display: flex;
	column-gap: 2rem;
	margin-left: auto;
	@media (max-width: 768px) {
		margin: 0rem;
		flex-direction: column-reverse;
		gap: 2rem;
		width: 100%;
		justify-content: center;
		align-items: center;


		button:nth-child(2) {
			width: 100%;
		}

		button:nth-child(1) {
			width: 10%;
		}
	}
`;

const SuccessContainer = styled.div`
	width: 40rem;

	@media (max-width: 1024px) {
		width: 100%;
	}
`;

export const FormContainer = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	gap: 3rem;
	align-items: center;
	max-width: 90rem;
	min-width: 80rem;

	@media (max-width: 1024px) {
		min-width: 80%;
	}
`;

const TitleStyle = styled(TitleTypography)`
	@media (max-width: 1024px) {
		font-size: 3.4rem;
	}
`;
