import styled, { css } from 'styled-components';
import {
	DataStyles,
	HeaderCellStyles,
	TableStyles,
} from '../../../../../components/Table/TableStyles';

export const Container = styled.div`
	overflow: auto;
	max-width: 80vw;
	max-height: 40rem;
`;

export const Table = styled.table`
	${TableStyles}
	margin: 0rem 0;
	border-collapse: collapse;
`;

export const TableHeader = styled.thead`
	position: sticky;
	top: 0;
	background-color: var(--white);
`;

export const TableBody = styled.tbody``;

export const TableRow = styled.tr``;

export const TableHeaderStyles = css`
	${HeaderCellStyles}
	box-shadow: 0px 0px 0 2px #e8e8e8;
	& div {
		display: flex;
		align-items: center;
		width: fit-content;

		/* background-color: red; */
		& > svg,
		img {
			width: 1.8rem;
			height: 1.8rem;
			min-width: 1.8rem;
			min-height: 1.8rem;
			margin-left: 1rem;
			color: var(--dark-gray);
		}
	}
`;

export const TableHeaderCell = styled.th`
	${TableHeaderStyles}
`;

export const TableHeaderCellSticky = styled.th`
	${TableHeaderStyles}

	position: sticky;
	left: 0;
	background-color: white;
	z-index: 1;
`;

export const NumberSpan = styled.span`
	margin-right: 2rem;
	width: 2rem;
`;

export const BenefitInputContainer = styled.div`
	display: flex;
	align-items: center;
	column-gap: 0.5rem;
`;

export const TableDataStyles = css`
	${DataStyles}
	white-space: nowrap;
	color: var(--black);
`;
export const TableData = styled.td`
	${TableDataStyles}
`;

export const TableDataSticky = styled.td`
	${TableDataStyles}

	position: sticky;
	left: 0;
	background-color: white;
	z-index: 1;
`;

export const TableFooter = styled.tfoot`
	position: sticky;
	background-color: var(--white);
	bottom: 0;
`;

export const GroupCard = styled.div`
	display: flex;
	align-items: center;
	font-size: 1.6rem;
	& svg {
		width: 4rem;
		height: 4rem;
		border-radius: 50%;
		margin-right: 1rem;
		color: var(--white);
		background-color: var(--primary-blue);
		padding: 0.4rem;
	}
`;

export const GroupInfoContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

export const GroupCollaborators = styled.span`
	font-size: 1.4rem;
	color: var(--light-gray);
	& span {
		color: var(--primary-blue);
	}
`;

export const UserContainer = styled.div`
	display: flex;
	align-items: center;
	min-width: 30rem;
`;

export const SelectCollabButton = styled.input`
	margin-right: 2rem;
	height: 2rem;
	min-width: 2rem;
	cursor: pointer;
`;

export const ReprovedTooltip = styled.div`
	display: inline-flex;
	float: right;
	margin-top: 0.4rem;
	position: relative;

	> svg {
		color: var(--primary-red);
		width: 2rem;
		height: 2rem;
	}
	& span {
		visibility: hidden;
		width: max-content;
		max-width: 40rem;
		background-color: var(--light-gray);
		color: var(--white);
		text-align: justify;
		border-radius: 0.6rem;
		padding: 1rem;
		position: absolute;
		z-index: 1;
		top: -1rem;
		right: 130%;
	}
	&:hover span {
		visibility: visible;
	}
`;

export const SchedulingContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: fit-content;
	margin-left: auto;
	font-size: 1.7rem;
`;

export const ScheduleCaption = styled.span`
	font-size: 1.2rem;
	& > span {
		font-size: 1.7rem;
	}
`;

export const BottomInfoContainer = styled.div`
	display: flex;
	justify-content: space-between;
	margin-top: 2rem;
`;

export const DeleteButton = styled.button`
	display: flex;
	align-content: center;
	justify-content: center;
	width: 3rem;
	height: 3rem;
	margin-left: 0.55rem;
	visibility: visible;
	background: none;
	> svg {
		width: 3rem;
		height: 3rem;
		padding: 0.2rem;
	}
`;
