import { useEffect, useState, useRef } from 'react';
import { Group } from '../../@types';
import Modal from '../Modal';
import { RiAddCircleLine, RiCloseCircleLine } from 'react-icons/ri';
import * as S from './styles';
import Loader from '../Loader';
import { EmptyContent } from '../EmptyContent';
import { FaUsers } from 'react-icons/fa';
import { CheckGroupCollaborators } from '../CheckGroupCollaborators';
import { useAuth } from '../../hooks/useAuth';
import { Pagination } from '../Pagination';
import { useQuery } from 'react-query';
import {
	getFilteredGroups,
	getGroups,
	GroupsFilterReturn,
	GroupsListReturn,
} from '../../services/queries/Groups';
import { Filter, FilterParams } from '../Filter';
import * as TableStyle from '../Table/TableStyles';
import PageTitle from '../PageTitle';
import { showErrorMessage } from '../../utils/ErrorHandler';

interface GroupSelectorProps {
	alreadyAddedGroups: string[]; // list of already added Groups to forbid selecting again
	onAddNewGroups(c: Group[]): void;
	onRemoveGroups(c: Group[]): void;
	groupSelectorToCollaborator?: boolean;
}

export function GroupSelector({
	alreadyAddedGroups,
	onAddNewGroups,
	onRemoveGroups,
	groupSelectorToCollaborator,
}: GroupSelectorProps) {
	const { currentCompany } = useAuth();
	const [open, setOpen] = useState(false);
	const listContainer = useRef<HTMLUListElement | null>(null);

	const [groupsCurrentPage, setGroupsCurrentPage] = useState(1);
	const [selectedGroups, setSelectedGroups] = useState<Group[]>([]);
	const [groupsToRemove, setGroupsToRemove] = useState<Group[]>([]);
	const [filtersParams, setFiltersParams] = useState<FilterParams[]>([
		{ name: 'Nome', filter: 'title', value: '', type: 'text', selected: false },
		{
			name: 'Descrição',
			filter: 'description',
			value: '',
			type: 'text',
			selected: false,
		},
	]);

	useEffect(() => {
		listContainer.current?.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
	}, [groupsCurrentPage, listContainer]);

	const groupsQuery = useQuery<GroupsListReturn, Error>(
		['groupsList', currentCompany?.id, groupsCurrentPage],
		() => {
			return getGroups(currentCompany?.id, groupsCurrentPage);
		},
		{
			onError: (err) => {
				showErrorMessage(err as Error, 'Não foi possível buscar os grupos. ');
			},
			enabled: open,
			keepPreviousData: true,
		}
	);

	const filteredGroupsQuery = useQuery<GroupsFilterReturn, Error>(
		['filteredGroups', currentCompany?.id, filtersParams, groupsCurrentPage],
		() => {
			return getFilteredGroups(
				filtersParams,
				currentCompany?.id,
				groupsCurrentPage
			);
		},
		{
			onError: (err) => {
				showErrorMessage(err as Error, 'Não foi possível buscar os grupos. ');
			},
			enabled: filtersParams.some((f) => !!f.value) && !!groupsCurrentPage,
		}
	);

	useEffect(() => {
		if (open) {
			// reseting
			setGroupsToRemove([]);
			setSelectedGroups([]);
			setGroupsCurrentPage(1);
		}
	}, [open]);

	useEffect(() => {
		// The modal has closed and there are selected Groups
		if (!open && selectedGroups.length) {
			onAddNewGroups(selectedGroups);
		}

		if (!open && groupsToRemove.length) {
			onRemoveGroups(groupsToRemove);
		}
		// eslint-disable-next-line
	}, [open, selectedGroups, groupsToRemove]);

	function getFilteredGroupsList() {
		let groupsList = filtersParams.some((f) => !!f.value)
			? filteredGroupsQuery.data?.groups!
			: groupsQuery.data?.groups;
		groupsList = groupsList?.filter((g) => g.status);

		return groupsList ? groupsList : [];
	}

	function isGroupSelected(id: string) {
		return !!selectedGroups.find((c) => c.id === id);
	}

	function handleSelectGroup(group: Group) {
		setSelectedGroups([...selectedGroups, group]);
	}
	function handleDeselectGroup(group: Group) {
		setSelectedGroups(selectedGroups.filter((g) => g.id !== group.id));
	}

	function isGroupSelectedToRemove(id: string) {
		return !!groupsToRemove.find((c) => c.id === id);
	}

	function isGroupAlreadyAdded(groupId: string) {
		return !!alreadyAddedGroups.find((id) => id === groupId);
	}

	function handleSelectGroupToRemoveCollab(group: Group) {
		setGroupsToRemove([...groupsToRemove, group]);
	}

	function handleDeselectGroupToRemoveCollab(groupToDeselect: Group) {
		setGroupsToRemove(
			groupsToRemove.filter((group) => group.id !== groupToDeselect.id)
		);
	}

	function getTotalRecords() {
		if (filtersParams.find((f) => !!f.value)) {
			return filteredGroupsQuery.data?.totalGroups;
		}
		return groupsQuery.data?.groupsTotalActive;
	}

	function handleSelector(group: Group) {
		//Non-selected to add
		if (!isGroupAlreadyAdded(group.id) && !isGroupSelected(group.id)) {
			return (
				<S.Selector
					selected={isGroupSelected(group.id!)}
					disabled={
						groupSelectorToCollaborator ? false : !Number(group.total_users)
					}
					title={
						groupSelectorToCollaborator
							? ''
							: !Number(group.total_users)
							? 'Nenhum colaborador no grupo'
							: ''
					}
					onClick={() => handleSelectGroup(group)}
					data-testid={`${group.id}-select`}
				>
					<RiAddCircleLine />
				</S.Selector>
			);
		}

		//Already added non-selected to remove
		if (isGroupAlreadyAdded(group.id) && !isGroupSelectedToRemove(group.id)) {
			return (
				<S.Selector
					selected={false}
					remove={true}
					disabled={groupSelectorToCollaborator ? false : true}
					title={groupSelectorToCollaborator ? '' : 'Grupo já adicionado'}
					onClick={() => handleSelectGroupToRemoveCollab(group)}
					data-testid={`${group.id}-remove`}
				>
					<RiCloseCircleLine />
				</S.Selector>
			);
		}

		//Already added selected to remove
		if (isGroupSelectedToRemove(group.id)) {
			return (
				<S.Selector
					selected={true}
					onClick={() => handleDeselectGroupToRemoveCollab(group)}
				>
					<RiAddCircleLine />
				</S.Selector>
			);
		}

		//Selected to add
		return (
			<S.Selector
				selected={isGroupSelected(group.id!)}
				onClick={() => handleDeselectGroup(group)}
				data-testid={`${group.id}-unselect`}
			>
				<RiCloseCircleLine />
			</S.Selector>
		);
	}

	if (
		filteredGroupsQuery.isLoading ||
		groupsQuery.isLoading ||
		filteredGroupsQuery.isPreviousData ||
		groupsQuery.isPreviousData
	) {
		return (
			<div>
				<S.OpenModalButton type='button' onClick={() => setOpen(true)}>
					Adicionar Grupo
				</S.OpenModalButton>
				<Modal isOpen={open} enableClose onRequestClose={() => setOpen(false)}>
					<TableStyle.TableHeaderContainer>
						<PageTitle title='Selecione grupos' />
						<Filter
							filterParams={filtersParams}
							onFiltersChanged={(updatedFilters) => {
								setGroupsCurrentPage(1);
								setFiltersParams(updatedFilters);
							}}
						/>
						{filtersParams.some((f) => !!f.value) ? (
							<Pagination
								onPageChange={(page) => setGroupsCurrentPage(page)}
								currentPage={groupsCurrentPage}
								totalCount={
									filteredGroupsQuery.data
										? filteredGroupsQuery.data.totalGroups!
										: 1
								}
							/>
						) : (
							<Pagination
								onPageChange={(page) => setGroupsCurrentPage(page)}
								currentPage={groupsCurrentPage}
								totalCount={
									groupsQuery.data ? groupsQuery.data.groupsTotalCount : 1
								}
							/>
						)}
					</TableStyle.TableHeaderContainer>
					<S.Container data-testid='GroupSelector-modal-container'>
						<Loader />
					</S.Container>
				</Modal>
			</div>
		);
	}

	return (
		<div>
			<S.OpenModalButton
				type='button'
				onClick={(e) => {
					e.preventDefault();
					setOpen(true);
				}}
			>
				Adicionar Grupo
			</S.OpenModalButton>

			<Modal isOpen={open} enableClose onRequestClose={() => setOpen(false)}>
				<S.Container data-testid='GroupSelector-modal-container'>
					<TableStyle.TableHeaderContainer>
						<PageTitle
							title='Selecione grupos'
							totalRecords={getTotalRecords() ?? 0}
						/>
						<Filter
							filterParams={filtersParams}
							onFiltersChanged={(updatedFilters) => {
								setGroupsCurrentPage(1);
								setFiltersParams(updatedFilters);
							}}
						/>
						{filtersParams.some((f) => !!f.value) ? (
							<Pagination
								onPageChange={(page) => setGroupsCurrentPage(page)}
								currentPage={groupsCurrentPage}
								totalCount={
									filteredGroupsQuery.data
										? filteredGroupsQuery.data.totalGroups!
										: 1
								}
							/>
						) : (
							<Pagination
								onPageChange={(page) => setGroupsCurrentPage(page)}
								currentPage={groupsCurrentPage}
								totalCount={
									groupsQuery.data ? groupsQuery.data.groupsTotalCount : 1
								}
							/>
						)}
					</TableStyle.TableHeaderContainer>

					<S.GroupsList ref={listContainer}>
						{getFilteredGroupsList().map((group) => (
							<S.GroupContainer key={group.id}>
								<S.GroupCard>
									<FaUsers />

									<S.GroupInfoContainer>
										<S.GroupName>{group.title}</S.GroupName>
										<S.GroupInfo>{group.description}</S.GroupInfo>
										<S.GroupInfo>
											<span>
												{group.total_users ? group.total_users : 0}{' '}
												colaboradores
											</span>{' '}
											<CheckGroupCollaborators groupId={group.id} />
										</S.GroupInfo>
									</S.GroupInfoContainer>
								</S.GroupCard>

								{handleSelector(group)}
							</S.GroupContainer>
						))}
						{!getFilteredGroupsList().length && (
							<EmptyContent text='Nenhum grupo disponível' />
						)}
					</S.GroupsList>
				</S.Container>
			</Modal>
		</div>
	);
}
