import styled, { css } from 'styled-components';

export const Container = styled.div`
	background-color: var(--primary-red);
	height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

const Box = css`
	box-shadow: 0px 13px 27px -5px rgba(50, 50, 93, 0.25),
		0px 8px 16px -8px rgba(0, 0, 0, 0.3),
		0px -6px 16px -6px rgba(0, 0, 0, 0.025);
	/* height: 40rem; */
	width: 40rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	background-color: var(--white);
	border-radius: 0.5rem;
`;

export const Form = styled.form`
	${Box}
	padding: 5rem 2rem;
`;

export const LogoImage = styled.img`
	width: 24rem;
	margin-bottom: 3rem;
`;

export const Title = styled.h1`
	font-weight: 500;
	width: 100%;
	margin-bottom: 1.4rem;
`;

export const ContentForm = styled.div`
	width: 100%;
	& > div + div {
		margin-top: 1.5rem;
	}
`;

export const SubmitButton = styled.button`
	background-color: var(--primary-blue);
	color: var(--white);
	border: 0;
	border-radius: 0.4rem;
	font-weight: bold;
	font-size: 1.6rem;
	height: 4rem;
	width: 50%;
	margin-top: 3rem;
`;

export const InfoText = styled.p`
	font-size: 1.6rem;
	margin-bottom: 2rem;
	color: var(--primary-blue);
	& span {
		font-weight: bold;
	}
`;

export const BoxContainer = styled.div`
	${Box}
	padding: 5rem 2rem;
`;
