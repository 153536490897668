import { InputHTMLAttributes, useState } from 'react';
import { TbLock } from 'react-icons/tb';
import styled, { css } from 'styled-components';
import { InputComp } from '../InputStyle';
import { BaseFieldProps, Field } from '../Field';
import { MdErrorOutline } from 'react-icons/md';
import { FiEye, FiEyeOff } from 'react-icons/fi';

interface PasswordInputProps
	extends InputHTMLAttributes<HTMLInputElement>,
		BaseFieldProps {
	showLeftIcon?: boolean;
}

export function PasswordInput({
	label,
	register,
	showLeftIcon = false,
	...props
}: PasswordInputProps) {
	const [showPassword, setShowPassword] = useState(false);

	const handleToggleShow = () => {
		setShowPassword((oldState) => !oldState);
	};

	return (
		<Field label={label} name={props.name}>
			<PasswordContainer style={{ width: props.style?.width }}>
				<InputComp
					error={!!props.errorMessage}
					type={showPassword ? 'text' : 'password'}
					style={{ padding: showLeftIcon ? '0 1.6rem 0 5.6rem' : '0 1.6rem' }}
					{...(register ? { ...register(props.name!) } : {})}
					{...props}
				/>

				{showLeftIcon && (
					<TbLock
						style={{ position: 'absolute', left: '1.9rem' }}
						size={'2.2rem'}
					/>
				)}

				{!showPassword ? (
					<button
						onClick={handleToggleShow}
						data-testid='eye-slash'
						type='button'
					>
						<FiEyeOff />
					</button>
				) : (
					<button onClick={handleToggleShow} data-testid='eye' type='button'>
						<FiEye />
					</button>
				)}
			</PasswordContainer>
			{props.errorMessage && (
				<span>
					<MdErrorOutline /> {props.errorMessage}
				</span>
			)}
		</Field>
	);
}

const SideContainer = css`
	position: absolute;
	width: 2rem;
	height: 2rem;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const PasswordContainer = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	width: 100%;
	& > button {
		${SideContainer}
		left: calc(100% - 3rem);
		transition: filter 0.2s;
		background: none;
		cursor: pointer;
		& > svg {
			width: 100%;
			height: 100%;
		}
		&:hover {
			filter: brightness(0.9);
		}
	}
`;
