import { ComponentType } from 'react';
import { RouteProps, Route, Redirect } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';

interface ReactRouteProps extends RouteProps {
	isPrivateOperator?: boolean;
	isPrivateSuperAdmin?: boolean;
	isFlexibleBenefitsRoute?: boolean;
	isCorporateExpensesRoute?: boolean;
	component: ComponentType;
}

export function ReactRoute({
	isPrivateOperator = false,
	isPrivateSuperAdmin = false,
	isFlexibleBenefitsRoute = false,
	isCorporateExpensesRoute = false,
	component: Component,
	...rest
}: ReactRouteProps) {
	const { user, currentCompany, companies } = useAuth();

	const isLoggedIn = !!user?.id;
	const isSuperAdmin = user?.access_level === 'super_admin';
	const isFirstAccess = !user?.status_password;

	const properAuthentication =
		(isPrivateOperator && !isSuperAdmin) ||
		(isPrivateSuperAdmin && isSuperAdmin);

	const isAuthorized = () => {
		if (properAuthentication && isFlexibleBenefitsRoute) {
			return (
				user.flexible_benefits_access &&
				currentCompany?.company_products.product_flexible_benefits_enabled
			);
		}
		if (properAuthentication && isCorporateExpensesRoute) {
			return (
				user.corporate_expenses_access &&
				currentCompany?.company_products.product_corporate_expenses_enabled
			);
		}

		return properAuthentication;
	};

	// public route
	if (!isPrivateOperator && !isPrivateSuperAdmin)
		return <Route {...rest} render={() => <Component />} />;

	// private route with no logged in user
	if ((isPrivateOperator || isPrivateSuperAdmin) && !isLoggedIn)
		return <Redirect to={{ pathname: '/session' }} />;

	if (isFirstAccess && rest.path !== '/first-access')
		return <Redirect to={{ pathname: '/first-access' }} />;

	// authenticated but product and company not selected yet or companies empty
	if (properAuthentication && !isSuperAdmin) {
		if (companies.length === 0 && rest.path !== '/support') {
			return <Redirect to={{ pathname: '/support' }} />;
		} else if (
			!currentCompany &&
			companies.length > 0 &&
			rest.path !== '/select-product'
		) {
			return <Redirect to={{ pathname: '/select-product' }} />;
		}
	}

	if (isAuthorized()) return <Route {...rest} render={() => <Component />} />;

	return <Redirect to={{ pathname: '/404' }} />;
}
