import { useState } from 'react';
import { toast } from 'react-toastify';
import Modal from '../Modal';
import {
	ObrigatoryFieldsIndication,
	ObrigatoryIndicator,
} from '../ObrigatoryFieldsIndicator';
import * as S from './styles';
import * as FormStyle from '../Form/FormStyles';
import { useAuth } from '../../hooks/useAuth';
import { useMutation, useQueryClient } from 'react-query';
import {
	CostCenterCreationParams,
	createCostCenter,
} from '../../services/queries/CostCenters';
import { RiAddCircleLine } from 'react-icons/ri';
import { trimObjectData } from '../../utils/trimObjectData';
import { showErrorMessage } from '../../utils/ErrorHandler';
interface CostCenterCreationModalProps {
	smallButtonStyle?: boolean;
}

export function CostCenterCreationModal({
	smallButtonStyle,
}: CostCenterCreationModalProps) {
	const { currentCompany } = useAuth();
	const [modalOpen, setModalOpen] = useState(false);
	const [title, setTitle] = useState('');
	const [description, setDescription] = useState('');
	const queryClient = useQueryClient();

	const createCostCenterQuery = useMutation(
		({ title, description }: CostCenterCreationParams) => {
			return createCostCenter(title, description, currentCompany?.id);
		},
		{
			onSuccess: () => {
				resetFields();
				queryClient.invalidateQueries('costCentersList');
				queryClient.invalidateQueries('costCenterSelect');
				toast.info('Centro de custo criado com sucesso!');
			},
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível criar o centro de custo. '
				);
			},
		}
	);

	async function handleSubmitCostCenterCreation() {
		if (!title.length || !description.length) {
			toast.error('Preencha todos os campos');
			return;
		}
		setModalOpen(false);

		createCostCenterQuery.mutate(trimObjectData({ title, description }));
	}

	function resetFields() {
		setTitle('');
		setDescription('');
	}

	const buttonStyleAttrs: any = {
		smallStyle: !!smallButtonStyle,
	};
	if (smallButtonStyle)
		buttonStyleAttrs['data-rh'] = 'Adicionar centro de custo';

	return (
		<>
			<S.AddCostCenter
				onClick={(e) => {
					e.preventDefault();
					setModalOpen(true);
				}}
				{...buttonStyleAttrs}
			>
				{smallButtonStyle ? (
					<RiAddCircleLine data-testid='openButton_id' />
				) : (
					'Adicionar centro de custo'
				)}
			</S.AddCostCenter>

			<Modal
				isOpen={modalOpen}
				onRequestClose={() => {
					setModalOpen(false);
				}}
				enableClose
			>
				<FormStyle.FormContainer data-testid='cosCenterCreation-modal-container'>
					<FormStyle.FieldGroup>
						<FormStyle.Field>
							<FormStyle.Label style={{ fontSize: '1.4em' }}>
								Título do centro de custo <ObrigatoryIndicator />
							</FormStyle.Label>
							<FormStyle.Input
								data-testid='titleInput_test_id'
								type='text'
								value={title}
								onChange={(e) => {
									setTitle(e.target.value);
								}}
							/>
						</FormStyle.Field>
					</FormStyle.FieldGroup>

					<FormStyle.FieldGroup>
						<FormStyle.Field>
							<FormStyle.Label style={{ fontSize: '1.4em' }}>
								Descrição <ObrigatoryIndicator />
							</FormStyle.Label>
							<FormStyle.InputTextArea
								data-testid='descriptionInput_test_id'
								value={description}
								onChange={(e) => {
									setDescription(e.target.value);
								}}
							/>
						</FormStyle.Field>
					</FormStyle.FieldGroup>

					<ObrigatoryFieldsIndication />
					<FormStyle.ButtonsContainer>
						<FormStyle.FormButton onClick={handleSubmitCostCenterCreation}>
							Salvar
						</FormStyle.FormButton>
						<S.CancelOption
							onClick={() => {
								setModalOpen(false);
							}}
						>
							Cancelar
						</S.CancelOption>
					</FormStyle.ButtonsContainer>
				</FormStyle.FormContainer>
			</Modal>
		</>
	);
}
