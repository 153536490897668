import api from '../../api';

export const CORPWAY_MANAGEMENT_KEY = 'CORPWAY_MANAGEMENT_SERVICE_KEY';

interface ManagementInfo {
	users_quantity: number;
	cost_centers_quantity: number;
	cards_quantity: number;
}

export async function getManagementInfo(companyId: string) {
	const { data } = await api.get<ManagementInfo>(
		`api/v1/rh/corpway/companies/${companyId}/management`
	);
	return data;
}
