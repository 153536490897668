import styled, { css } from 'styled-components';
import { Carousel } from '../../../../../componentsV2/Carousel';
import { renderToStaticMarkup as rtsm } from 'react-dom/server';
import { IoIosArrowDown } from 'react-icons/io';

interface FieldsRowProps {
	align?: boolean;
}

const arrowIcon = css`
	${(rtsm(IoIosArrowDown({ size: 20, color: 'black' })) as string).replace(
		/"/g,
		"'"
	)}
`;

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	height: fit-content;
	max-height: 90vh;
	width: 55rem;
	align-items: center;
	padding: 1rem 3rem;

	@media (max-width: 768px) {
		width: 100%;
	}
`;

export const HeaderContainer = styled.div`
	padding: 3rem 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	row-gap: 1.5rem;
	width: 100%;
`;

export const IconContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 4.5rem;
	height: 4.5rem;
	background-color: var(--primary-blue);
	border-radius: 50px;
`;
export const IconHeader = styled.img``;

export const ReportInfoContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 2rem;
`;

export const FieldsRow = styled.div<FieldsRowProps>`
	display: flex;
	width: 100%;
	column-gap: 1rem;
	justify-content: space-between;

	& + & {
		margin-top: 1rem;
	}

	${(props) =>
		props.align &&
		`
    fieldset {
        align-items: center;
		
		label{
			font-size: 1.5rem;
		}
    }

	div {
		justify-content: center;
	}
    `}

	&.information {
		> fieldset {
			> label {
				margin-bottom: 0rem;
			}
		}
	}
`;

export const FieldValue = styled.p`
	font-size: 1.7rem;
	font-weight: 500;
`;

export const FieldMessage = styled.p`
	margin-top: 2rem;
	font-size: 1.3rem;
	text-align: justify;

	span {
		font-weight: 600;
	}

	@media (max-width: 768px) {
		display: none;
	}
`;

export const TimePeriodChipContainer = styled.div<{ isActive: boolean }>`
	padding: 1rem;
	border-radius: 2rem;
	border: 1px solid
		${({ isActive }) => (isActive ? 'var(--primary-blue)' : 'var(--dark-gray)')};
	width: 12rem;
	text-align: center;
	background-color: ${({ isActive }) =>
		isActive ? 'var(--primary-blue)' : 'transparent'};
	color: ${({ isActive }) => (isActive ? 'var(--white)' : 'var(--dark-gray)')};
	font-size: 1.2rem;
	cursor: pointer;
`;

export const CardSelectContainer = styled.div`
	& select {
		position: relative;
		appearance: none;
	}

	&::before {
		${/* // eslint-disable-next */ ''}
		content: url("data:image/svg+xml; utf8,${arrowIcon}");
		position: absolute;
		z-index: 1;
		pointer-events: none;
		top: 20rem;
		right: 6rem;
		filter: invert(0.3);
	}
`;

export const FormatSelectContainer = styled.div`
	width: 70%;
	margin-left: 5rem;
	position: relative;

	& select {
		position: relative;
		appearance: none;
	}

	&::before {
		${/* // eslint-disable-next */ ''}
		content: url("data:image/svg+xml; utf8,${arrowIcon}");
		position: absolute;
		z-index: 1;
		pointer-events: none;
		top: 4rem;
		right: 2rem;
		filter: invert(0.3);
	}

	@media (max-width: 768px) {
		width: 100%;
		margin: 0;
	}
`;

export const DatesContainer = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;

	& input[type='month'] {
		position: relative;
		font-family: 'Poppins', sans-serif;
		padding-left: 5rem;
	}

	& input[type='month']::-webkit-calendar-picker-indicator {
		position: absolute;
		top: 1rem;
		left: 1rem;
		right: 0;
		bottom: 0;
		width: auto;
		height: 2rem;
		filter: invert(0.5);
	}

	& input[type='month']::-webkit-datetime-edit-month-field {
			text-transform: capitalize;
	}
`;

export const ButtonsContainer = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
	column-gap: 2rem;
	margin-top: 2rem;
	margin-bottom: 2rem;
`;

export const RecipientEmailContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 100%;
	padding: 0 1rem;
`;

export const SelectContainer = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	column-gap: 2rem;

	margin-top: 2rem;
`;

export const LabelContainer = styled.div`
    font-size: 1.3rem;
    width: 100%;

    & > label {
        font-weight: 500;
    }

    & > p {
        color: #606060;
        font-style: italic;
        line-height: normal;
    }
`;

export const AlternativeEmailContainer = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	column-gap: 1rem;
	& > svg {
		width: 3rem;
		height: 3rem;
	}

	margin-top: 1rem;
`;

export const AppendEmailFieldBttn = styled.button`
	color: var(--dark-gray);
	text-decoration: underline;
	margin-top: 2rem;
	border-radius: 5px;
	padding: 0.2rem;
`;

export const RemoveEmailFieldBttn = styled.button`
	width: 5rem;
	height: 4rem;
	border-radius: 50px;
`;

export const MainContentContainer = styled.div`
	margin-bottom: 0.7rem;
	width: 100%;
	padding: 24px;
	border-style: solid;
	border-width: 1px;
	border-color: var(--primary-blue);
	border-radius: 8px;
`;

export const CheckboxesContainer = styled.div`
	margin-top: 1rem;
	width: 100%;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	row-gap: 2rem;

	@media (max-width: 768px) {
		flex-direction: column;

		& > label {
			width: 100%;
		}
	}
`;

export const CarouselStyle = styled(Carousel)`
	& > div {
		gap: 5rem;
	}
`;

export const OpenModalContainer = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 3rem 4rem 3rem 4rem;
	border-radius: 1.6rem;
	border: 1px solid #27ADFF;	
	align-items: center;
	@media (max-width: 1024px) {
		flex-direction: column;
		gap: 2rem;
	}
`;

export const OpenModalButtons = styled.div`
	display: flex;
	column-gap: 2rem;
	align-items: center;
	@media (max-width: 1024px) {
		flex-direction: column;
		gap: 2rem;
		align-items: center;
		button {
			width: 100%;
		}
	}
`;
