import { LabelHTMLAttributes } from 'react';
import styled from 'styled-components';

interface Props extends LabelHTMLAttributes<HTMLLabelElement> {}

export function Label(props: Props) {
	return <LabelComp {...props}>{props.children}</LabelComp>;
}

const LabelComp = styled.label`
	font-family: 'Poppins', sans-serif;
	font-weight: 400;
	color: var(--dark-gray2);
	font-size: 1.2rem;
	margin-bottom: 0.8rem;
`;
