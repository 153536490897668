import { useEffect, useState, useRef, CSSProperties } from 'react';
import { Collaborator } from '../../@types';
import Modal from '../Modal';
import avatarImg from '../../assets/avatar.svg';
import { RiAddCircleLine, RiCloseCircleLine } from 'react-icons/ri';
import * as S from './styles';
import Loader from '../Loader';
import { EmptyContent } from '../EmptyContent';
import * as TableStyle from '../Table/TableStyles';
import { useAuth } from '../../hooks/useAuth';
import { Pagination } from '../Pagination';
import { useQuery } from 'react-query';
import {
	FilterCollabsReturn,
	getActiveCollaborators,
	getFilteredCollabs,
} from '../../services/queries/Collaborators';
import { Filter, FilterParams } from '../Filter';
import PageTitle from '../PageTitle';
import { cpfMask } from '../../utils/masks';
import { showErrorMessage } from '../../utils/ErrorHandler';
import { ContractTypeOptionsFilters } from '../Filter/utils/SelectFilters';
import { useDialogModal } from '../../hooks/useDialogModal';

export interface CollabsSelectorProps {
	alreadyAddedCollabs: Collaborator[]; // list of already added collaborators to forbid selecting again
	onAddNewCollaborators(c: Collaborator[]): void;
	onAddCollaboratorsToRemove(collabsToRemove: Collaborator[]): void;
	onAddAndRemoveCollabs(
		collabsToAdd: Collaborator[],
		collabsToRemove: Collaborator[]
	): void;
	allowRemoveCollaborators?: boolean;
	buttonText?: string;
	maxCollaboratorsAllowed?: number;
	buttonStyle?: CSSProperties;
}

export function CollabsSelector({
	alreadyAddedCollabs,
	onAddNewCollaborators,
	onAddCollaboratorsToRemove,
	onAddAndRemoveCollabs,
	allowRemoveCollaborators,
	buttonText,
	maxCollaboratorsAllowed,
	buttonStyle,
}: CollabsSelectorProps) {
	const { currentCompany } = useAuth();
	const { openOptionsDialog } = useDialogModal();
	const [open, setOpen] = useState(false);
	const listContainer = useRef<HTMLUListElement | null>(null);
	const [selectedCollaborators, setSelectedCollaborators] = useState<
		Collaborator[]
	>([]);
	const [selectedCollabsToRemove, setSelectedCollabsToRemove] = useState<
		Collaborator[]
	>([]);
	const [hasMadeChanges, setHasMadeChanges] = useState(false);
	const [collaboratorsCurrentPage, setCollaboratorsCurrentPage] = useState(1);

	const [collabsFiltersParams, setcollabsFiltersParams] = useState<
		FilterParams[]
	>([
		{
			name: 'Nome',
			filter: 'name',
			value: '',
			type: 'text',
			selected: false,
		},
		{
			name: 'Nome da mãe',
			filter: 'mother_name',
			value: '',
			type: 'text',
			selected: false,
		},
		{ name: 'CPF', filter: 'cpf', value: '', type: 'cpf', selected: false },
		{
			name: 'Email',
			filter: 'email',
			value: '',
			type: 'email',
			selected: false,
		},
		{
			name: 'Cargo',
			filter: 'office',
			value: '',
			type: 'text',
			selected: false,
		},
		{
			name: 'Setor de atuação',
			filter: 'section',
			value: '',
			type: 'text',
			selected: false,
		},
		{ name: 'UF', filter: 'uf', value: '', type: 'uf', selected: false },
		{
			name: 'Contrato',
			filter: 'contract_type',
			value: '',
			type: 'select',
			selectOptions: ContractTypeOptionsFilters,
			selected: false,
		},
		{
			name: 'Centro de custo',
			filter: 'cost_center',
			value: '',
			type: 'cost_center',
			selected: false,
		},
	]);

	useEffect(() => {
		listContainer.current?.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
	}, [collaboratorsCurrentPage, listContainer]);

	const activeCollabsQuery = useQuery(
		['activeCollabsList', currentCompany?.id, collaboratorsCurrentPage],
		() => {
			return getActiveCollaborators(
				collaboratorsCurrentPage,
				currentCompany?.id
			);
		},
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível buscar os colaboradores. '
				);
			},
			enabled: open,
			keepPreviousData: true,
		}
	);

	const filteredCollabsQuery = useQuery<FilterCollabsReturn, Error>(
		[
			'filteredCollabsQuery',
			collabsFiltersParams,
			true,
			currentCompany?.id,
			collaboratorsCurrentPage,
		],
		() => {
			return getFilteredCollabs(
				collabsFiltersParams,
				true,
				currentCompany?.id,
				collaboratorsCurrentPage
			);
		},
		{
			onError: (error) => {
				showErrorMessage(
					error as Error,
					'Não foi possível buscar os colaboradores. '
				);
			},
			enabled:
				collabsFiltersParams.some((f) => !!f.value) &&
				!!collaboratorsCurrentPage,
		}
	);

	useEffect(() => {
		if (open) {
			// reseting
			setSelectedCollaborators([]);
			setSelectedCollabsToRemove([]);
			setCollaboratorsCurrentPage(1);
			setHasMadeChanges(false);
		}
	}, [open]);

	function handleSubmit() {
		if (selectedCollaborators.length && selectedCollabsToRemove.length) {
			onAddAndRemoveCollabs(selectedCollaborators, selectedCollabsToRemove);
		}

		if (selectedCollaborators.length && !selectedCollabsToRemove.length) {
			onAddNewCollaborators(selectedCollaborators);
		}

		if (selectedCollabsToRemove.length && !selectedCollaborators.length) {
			onAddCollaboratorsToRemove(selectedCollabsToRemove);
		}

		setOpen(false);
	}

	function handleClose() {
		if (!hasMadeChanges) {
			setOpen(false);
			return;
		}

		openOptionsDialog(
			'Alterações não salvas. Tem certeza que deseja sair?',
			'Confirmar',
			() => setOpen(false),
			'Cancelar',
			() => {}
		);
	}

	function getFilteredCollabsQueryList() {
		if (collabsFiltersParams.some((f) => !!f.value)) {
			return filteredCollabsQuery.data?.collaborators;
		}
		return activeCollabsQuery.data?.collaboratorStatusActive;
	}

	function isCollabSelected(id: string) {
		return !!selectedCollaborators.find((c) => c.id === id);
	}

	// check for CPF as well because imported collaboradors (in payment) don't have IDs set
	function isCollabAlreadyAdded(collab: Collaborator) {
		return (
			!!alreadyAddedCollabs.find(
				(collabAdded) => collabAdded.cpf === collab.cpf
			) || collab.kyc_status !== 'approved'
		);
	}

	function handleSelectCollaborator(collaborator: Collaborator) {
		setHasMadeChanges(true);
		setSelectedCollaborators([...selectedCollaborators, collaborator]);
	}
	function handleDeselectCollaborator(collaborator: Collaborator) {
		setHasMadeChanges(true);
		setSelectedCollaborators(
			selectedCollaborators.filter((c) => c.id !== collaborator.id)
		);
	}

	function isCollabSelectedToRemove(id: string) {
		return !!selectedCollabsToRemove.find((c) => c.id === id);
	}

	function handleSelectCollabToRemove(collaborator: Collaborator) {
		setHasMadeChanges(true);
		setSelectedCollabsToRemove([...selectedCollabsToRemove, collaborator]);
	}

	function handleDeselectCollabToRemove(collaborator: Collaborator) {
		setHasMadeChanges(true);
		setSelectedCollabsToRemove(
			selectedCollabsToRemove.filter((collab) => collab.id !== collaborator.id)
		);
	}

	function updateFilters(filters: FilterParams[]) {
		setCollaboratorsCurrentPage(1);
		setcollabsFiltersParams(filters);
	}

	function getTotalRecords() {
		if (collabsFiltersParams.find((f) => !!f.value)) {
			return filteredCollabsQuery.data?.totalCount;
		}
		return activeCollabsQuery.data?.totalActive;
	}

	function handleSelector(collaborator: Collaborator) {
		const seletectedToAddAmount = selectedCollaborators.length;
		const seletectedToRemoveAmount = selectedCollabsToRemove.length;
		const maxCollaboratorsAmountReached =
			maxCollaboratorsAllowed !== undefined &&
			seletectedToAddAmount - seletectedToRemoveAmount >=
				maxCollaboratorsAllowed;

		//Non-selected to add
		if (
			!isCollabAlreadyAdded(collaborator) &&
			!isCollabSelected(collaborator.id!)
		) {
			return (
				<S.Selector
					selected={isCollabSelected(collaborator.id!)}
					onClick={() => handleSelectCollaborator(collaborator)}
					data-testid={`${collaborator.id}-select`}
					disabled={maxCollaboratorsAmountReached}
					title={
						maxCollaboratorsAmountReached
							? 'Limite de colaboradores atingido.'
							: ''
					}
				>
					<RiAddCircleLine />
				</S.Selector>
			);
		}

		//Already added non-selected to remove
		if (
			isCollabAlreadyAdded(collaborator) &&
			!isCollabSelectedToRemove(collaborator.id!)
		) {
			return (
				<S.Selector
					selected={false}
					remove={true}
					disabled={allowRemoveCollaborators ? false : true}
					title={allowRemoveCollaborators ? '' : 'Colaborador já adicionado'}
					onClick={() => handleSelectCollabToRemove(collaborator)}
					data-testid={`${collaborator.id}-remove`}
				>
					<RiCloseCircleLine />
				</S.Selector>
			);
		}

		//Already added and selected to remove
		if (isCollabSelectedToRemove(collaborator.id!)) {
			return (
				<S.Selector
					selected={true}
					onClick={() => handleDeselectCollabToRemove(collaborator)}
				>
					<RiAddCircleLine />
				</S.Selector>
			);
		}

		//Selected to add
		return (
			<S.Selector
				selected={isCollabSelected(collaborator.id!)}
				onClick={() => handleDeselectCollaborator(collaborator)}
				data-testid={`${collaborator.id}-unselect`}
			>
				<RiCloseCircleLine />
			</S.Selector>
		);
	}

	if (
		filteredCollabsQuery.isLoading ||
		activeCollabsQuery.isLoading ||
		activeCollabsQuery.isPreviousData ||
		filteredCollabsQuery.isPreviousData
	) {
		return (
			<div>
				<S.MainButton onClick={() => setOpen(true)}>
					{buttonText ?? 'Adicionar Colaborador'}
				</S.MainButton>
				<Modal isOpen={open} enableClose onRequestClose={handleClose}>
					<S.Container data-testid='CollabsSelector-modal-container'>
						<TableStyle.TableHeaderContainer>
							<PageTitle title='Selecione colaboradores' />
							<Filter
								filterParams={collabsFiltersParams}
								onFiltersChanged={(updatedFilters) => {
									updateFilters(updatedFilters);
								}}
							/>
							{collabsFiltersParams.some((f) => !!f.value) ? (
								<Pagination
									onPageChange={(page) => setCollaboratorsCurrentPage(page)}
									currentPage={collaboratorsCurrentPage}
									totalCount={
										filteredCollabsQuery.data
											? filteredCollabsQuery.data?.totalCount!
											: 1
									}
								/>
							) : (
								<Pagination
									onPageChange={(page) => setCollaboratorsCurrentPage(page)}
									currentPage={collaboratorsCurrentPage}
									totalCount={
										activeCollabsQuery.data
											? activeCollabsQuery.data.totalActive!
											: 1
									}
								/>
							)}
						</TableStyle.TableHeaderContainer>
						<Loader />
					</S.Container>
				</Modal>
			</div>
		);
	}

	return (
		<div>
			<S.MainButton onClick={() => setOpen(true)} style={buttonStyle}>
				{buttonText ?? 'Adicionar Colaborador'}
			</S.MainButton>

			<Modal isOpen={open} enableClose onRequestClose={handleClose}>
				<S.Container>
					<TableStyle.TableHeaderContainer>
						<PageTitle
							title='Selecione colaboradores'
							totalRecords={getTotalRecords() ?? 0}
						/>
						<Filter
							filterParams={collabsFiltersParams}
							onFiltersChanged={(updatedFilters) => {
								updateFilters(updatedFilters);
							}}
						/>
						{collabsFiltersParams.some((f) => !!f.value) ? (
							<Pagination
								onPageChange={(page) => setCollaboratorsCurrentPage(page)}
								currentPage={collaboratorsCurrentPage}
								totalCount={
									filteredCollabsQuery.data
										? filteredCollabsQuery.data?.totalCount!
										: 1
								}
							/>
						) : (
							<Pagination
								onPageChange={(page) => setCollaboratorsCurrentPage(page)}
								currentPage={collaboratorsCurrentPage}
								totalCount={
									activeCollabsQuery.data
										? activeCollabsQuery.data.totalActive!
										: 1
								}
							/>
						)}
					</TableStyle.TableHeaderContainer>

					<S.CollaboratorsList ref={listContainer}>
						{getFilteredCollabsQueryList()?.map((collaborator) => (
							<S.CollaboratorContainer key={collaborator.id}>
								<S.CollaboratorCard>
									<S.CollaboratorAvatar
										src={
											collaborator.avatar ? collaborator.avatar_url : avatarImg
										}
									/>

									<S.CollaboratorInfoContainer>
										<S.CollaboratorName>{`${collaborator.first_name} ${collaborator.last_name}`}</S.CollaboratorName>
										<S.CollaboratorInfo>
											{collaborator.office}
										</S.CollaboratorInfo>
										<S.CollaboratorInfo>
											{cpfMask(collaborator.cpf)}
										</S.CollaboratorInfo>
										<S.CollaboratorInfo>
											{collaborator.email}
										</S.CollaboratorInfo>
									</S.CollaboratorInfoContainer>
								</S.CollaboratorCard>

								{handleSelector(collaborator)}
							</S.CollaboratorContainer>
						))}

						{!getFilteredCollabsQueryList()?.length && (
							<EmptyContent text='Nenhum colaborador disponível' />
						)}
					</S.CollaboratorsList>

					<S.MainButton style={{ width: '16rem' }} onClick={handleSubmit}>
						Salvar
					</S.MainButton>
				</S.Container>
			</Modal>
		</div>
	);
}
