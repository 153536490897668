import { OptionsButton } from '../../../../../components/Button';
import styled from 'styled-components';

export const SelectCollaboratorButton = styled.button`
	${OptionsButton}
	padding: 0.5rem 6rem;
	border-radius: 2rem;
`;
export const SelectCollabButtonWithSelection = styled.button`
	padding: 1rem 2rem;
	background-color: var(--white);
	border-radius: 0.4rem;
`;

// MODAL STYLES
interface CollaboratorSelectionProps {
	selected: boolean;
}

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	max-width: 60rem;
	width: 70vw;
	padding-right: 1rem;
	min-height: 10rem;
	justify-content: center;
`;

export const CollaboratorsList = styled.ul`
	display: flex;
	flex-direction: column;
	max-height: 60vh;
	overflow-y: auto;
	margin-top: 2rem;
`;

export const CollaboratorContainer = styled.li`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 1rem;
	padding-bottom: 1rem;
	border-bottom: 0.1rem solid var(--primary-blue);
`;

export const CollaboratorCard = styled.div`
	display: flex;
	align-items: center;
`;

export const CollaboratorAvatar = styled.img`
	border: 0.2rem solid var(--primary-blue);
	border-radius: 50%;
	height: 5rem;
	width: 5rem;
	margin-right: 1rem;
	object-fit: cover;
`;

export const CollaboratorInfoContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

export const CollaboratorName = styled.span`
	color: var(--primary-blue);
	font-size: 1.6rem;
`;

export const CollaboratorInfo = styled.span`
	font-size: 1.4rem;
	color: var(--mid-gray);
`;

export const Selector = styled.button<CollaboratorSelectionProps>`
	width: 3.5rem;
	height: 3.5rem;
	background: none;
	& svg {
		width: 100%;
		height: 100%;
		border-radius: 50%;
		color: ${({ selected }) =>
			selected ? 'var(--light-gray)' : 'var(--primary-blue)'};
	}
`;

export const ErrorLine = styled.span`
	text-align: center;
	font-size: 1.4rem;
	color: var(--red);
`;
