import styled from 'styled-components';

interface Props {
	variant: 'default' | 'light' | 'lightgray' | 'users';
}

const getContainerVariant = (Props: Props) => {
	switch (Props.variant) {
		case 'default':
			return ``;
		case 'light':
			return ``;
		case 'lightgray':
			return `border-radius: 8px, 0px, 0px, 8px`;
		default:
			return ``;
	}
};

export const Container = styled.div<Props>`
	display: flex;
	flex-direction: column;
	row-gap: 1rem;
	@media (max-width: 1024px) {
		text-wrap: nowrap;
	}
	${(Props) => getContainerVariant(Props)}
`;

const getHeaderTitleVariant = (Props: Props) => {
	switch (Props.variant) {
		case 'default':
			return '';
		case 'light':
			return '& span { color: var(--primary-blue); margin-left: 0.5rem;}';
		case 'lightgray':
			return '';
		default:
			return '';
	}
};

export const Header = styled.div<Props>`
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	row-gap: 2.4rem;
	margin: 2rem 0;
	${(props) => getHeaderTitleVariant(props)};
	@media (max-width: 1024px) {
		flex-direction: column;
		padding-left: 0 !important;
	}
`;

export const PaginationContainer = styled.div`
	display: flex;
	margin-left: auto;
	align-items: center;
	column-gap: 1rem;
`;

export const PaginationButtonsContainer = styled.div`
	display: flex;
`;

export const PaginationButton = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0.6rem;
	border-radius: 50%;
	background-color: transparent;
	&:enabled:hover {
		background-color: #f5f5f5;
	}
	color: #393939;
	& > svg {
		width: 2.4rem;
		height: 2.4rem;
	}
`;
