import { useState } from 'react';
import { FaUsers } from 'react-icons/fa';
import { CheckGroupCollaborators } from '../../../../../components/CheckGroupCollaborators';
import {
	sortByName,
	SortIconButton,
	SortOrder,
} from '../../../../../components/SortIconButton';
import UserCard from '../../../../../components/UserCard';
import { usePayment } from '../../../../../hooks/usePaymentV2';
import { getBenefitIcon } from '../../../../../utils/benefits/getBenefitIcon';
import { getBenefitParsedTitle } from '../../../../../utils/benefits/getBenefitParsedTitle';
import { PAYMENT_TABLE_ID } from '../../utils/getBenefitValuesInputs';
import { BenefitsListTotals } from '../BenefitTotals/BenefitsListTotals';
import { BenefitInput } from '../BenefitInput';

import * as S from './styles';
import { ReviewAdminModal } from '../ReviewAdminModal';

export default function PaymentTableReviewAdmin() {
	const { beneficiaries, benefits } = usePayment();

	const [beneficiariesSort, setBeneficiariesSort] = useState<SortOrder>('asc');

	function checkReprovedBenefitSelection(
		beneficiaryId: string,
		benefitId: string
	) {
		return !!beneficiaries.find(
			(beneficiary) =>
				beneficiary.id === beneficiaryId &&
				beneficiary.benefits.find((b) => b.id === benefitId)?.reason_disapproval
		);
	}

	function checkExistentBenefit(benefitId: string) {
		return !!benefits.find((benefit) => benefit.id === benefitId);
	}

	return (
		<>
			<S.Table>
				<S.TableHeader>
					<S.TableRow>
						<S.TableHeaderCell>
							<S.UserContainer>
								<S.NumberSpan>Nº </S.NumberSpan>
								COLABORADOR
								<SortIconButton
									sort={beneficiariesSort}
									onToggleSort={() => {
										setBeneficiariesSort((old) =>
											old === 'asc' ? 'desc' : 'asc'
										);
									}}
								/>
							</S.UserContainer>
						</S.TableHeaderCell>
						{/* HEADER DISPLAYS ALL SELECTED BENEFITS */}
						{benefits.map((benefit) => (
							<S.TableHeaderCell key={benefit.id}>
								<div>
									{getBenefitParsedTitle(benefit.title)}
									{getBenefitIcon(benefit.title)}
								</div>
							</S.TableHeaderCell>
						))}
					</S.TableRow>
				</S.TableHeader>

				<S.TableBody id={PAYMENT_TABLE_ID}>
					{sortByName(beneficiariesSort, beneficiaries).map(
						(beneficiary, index) => (
							<S.TableRow key={beneficiary.id}>
								<S.TableData>
									<S.UserContainer>
										<S.NumberSpan>{index + 1} </S.NumberSpan>
										{beneficiary.isGroup ? (
											<S.GroupCard>
												<FaUsers />
												<S.GroupInfoContainer>
													<span>{beneficiary.name}</span>
													<S.GroupCollaborators>
														{beneficiary.collaboratorsAmount} colaboradores
														<span>
															{' '}
															(
															<CheckGroupCollaborators
																groupId={beneficiary.id}
															/>
															)
														</span>
													</S.GroupCollaborators>
												</S.GroupInfoContainer>
											</S.GroupCard>
										) : (
											<UserCard
												name={beneficiary.name}
												bottomInfo={String(beneficiary.office)}
												avatar_url={beneficiary.avatar_url}
											/>
										)}
									</S.UserContainer>
								</S.TableData>
								{beneficiary.benefits.map((benefit) => {
									if (checkExistentBenefit(benefit.id))
										return (
											<S.TableData
												style={
													checkReprovedBenefitSelection(
														beneficiary.id,
														benefit.id
													)
														? {
																backgroundColor: '#F4B7B7',
																color: 'var(--white)',
														  }
														: { backgroundColor: 'transparent' }
												}
												key={benefit.id + beneficiary.id}
											>
												<S.BenefitInputContainer>
													<BenefitInput
														beneficiaryId={beneficiary.id}
														benefitId={benefit.id}
														initialValue={benefit.value}
														disabled={checkReprovedBenefitSelection(
															beneficiary.id,
															benefit.id
														)} // reproved benefits can't be edited
														showReset={false}
													/>

													<ReviewAdminModal
														beneficiaryId={beneficiary.id}
														benefitId={benefit.id}
													/>
												</S.BenefitInputContainer>
											</S.TableData>
										);
									return (
										<S.TableData
											key={beneficiary.id + benefit.id}
										></S.TableData>
									);
								})}
							</S.TableRow>
						)
					)}
					<S.TableRow>
						<S.TableData style={{ fontWeight: 'bold' }}>
							Valores totais a serem pagos
						</S.TableData>

						<BenefitsListTotals filterDisapproved />
					</S.TableRow>
				</S.TableBody>
			</S.Table>
		</>
	);
}
