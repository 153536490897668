import { Benefit } from '../@types';
import { getBenefitParsedTitle } from './benefits/getBenefitParsedTitle';

export function sortBenefitsByAlfabeticalOrder(benefits: Benefit[]) {
	return benefits.sort((a: Benefit, b: Benefit) => {
		if (getBenefitParsedTitle(a.title) < getBenefitParsedTitle(b.title)) {
			return -1;
		}
		if (getBenefitParsedTitle(a.title) > getBenefitParsedTitle(b.title)) {
			return 1;
		}
		return 0;
	});
}

export function sortArrayObjByAlfabeticalOrder(array: any[], key: string) {
	return array.sort((a: any, b: any) => {
		return a[key] < b[key] ? -1 : 1;
	});
}
