import React from 'react';
import RESPONSE_CODES from '../../constants/RESPONSE_CODE.json';
import TXN_TYPES from '../../constants/TXN_TYPE.json';
import { parseTransactionStatus } from '../../../../utils/parseStatus';

export function getTransactionStatus(response_code: number) {
	 const responseCode = RESPONSE_CODES[response_code.toString() as keyof typeof RESPONSE_CODES]
		.status;

		if (!responseCode) return '--'

		return responseCode
}

export function parseStatusTransaction(response_code: number) {
	const status = getTransactionStatus(response_code);

	switch (status) {
		case 'Approved':
			return 'Aprovada';
		case 'Declined':
			return 'Reprovada';
		default:
			return '--';
	}
}

export function checkDeclineAndStrikeText(
	response_code: number,
	data?: string
): JSX.Element | string {
	if (!data) return '--';

	const status = getTransactionStatus(response_code);

	if (status === 'Declined') {
		return React.createElement('s', null, data);
	}

	return data;
}

export function truncateString(str: string) {
	if (str.length <= 15) {
		return str;
	}
	return str.slice(0, 15) + '...';
}

export function parseExtractTransactionStatus(
	msg_type: number,
	txn_type: number,
	response_code: number,
	cleared: boolean,
	clearing_state: string,
	financial_impact_type: string
) {
	let status = getTransactionStatus(response_code);

	if (status === 'Declined') return `Cancelada`;

	switch (txn_type) {
		case 55:
		case 0:
			if (msg_type === 1400 || msg_type === 1420) {
				return 'Creditada'; // Estorno externo
			}
			if (!cleared && clearing_state === 'PARTIALLY_CLEARED') {
				return 'Cobrança Parcial';
			}
			return 'Debitada';

		case 12:
			return 'Creditada'; // Adição de saldo

		case 13:
		case 14:
		case 68:
			if (msg_type === 8900 || msg_type === 1220) {
				return 'Debitada';
			}
			return 'Creditada'; // Estorno externo

		default:
			if (cleared) {
				return 'Debitada';
			}
			if (!cleared && clearing_state === 'PARTIALLY_CLEARED') {
				return 'Cobrança Parcial';
			}
			if (!cleared && financial_impact_type === 'debit') {
				return 'Debitada';
			}
			return parseTransactionStatus(status);
	}
}

export function parseTransactionType(
	txn_type: number,
	msg_type: number,
	currency: number,
	merchant_country: string
) {
	switch (txn_type) {
		case 55:
		case 0:
			if (msg_type === 1400 || msg_type === 1420) {
				return 'Estorno externo';
			}

			if (currency === 986 && merchant_country === 'BRA') {
				return 'Compra nacional';
			}

			return 'Compra internacional';
		case 12:
			return msg_type === 8900 ? 'Estorno de IOF' : 'Adição de saldo';
		case 13:
		case 14:
		case 68:
			if (msg_type === 8900) return 'IOF';
			if (msg_type === 1220) return 'Estorno interno';
			return 'Estorno externo';
		default:
			return TXN_TYPES[String(txn_type) as keyof typeof TXN_TYPES];
	}
}
