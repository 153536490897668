import { BsArrowDownShort, BsClockFill } from 'react-icons/bs';
import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { Notification } from '../../contexts/NotificationsContext';
import { useAuth } from '../../hooks/useAuth';
import { useNotifications } from '../../hooks/useNotifications';
import {
	getNotifications,
	readAllNotifications,
} from '../../services/queries/Notifications';
import { EmptyContent } from '../EmptyContent';
import Loader from '../Loader';
import * as S from './styles';

interface NotificationListProps {
	open: boolean;
}
export function NotificationsList({ open }: NotificationListProps) {
	const queryClient = useQueryClient();
	const { companies, changeCurrentCompany, currentProduct, user } = useAuth();
	const { toggleNotificationsStatus, unreadNotifications } = useNotifications();

	const fetchNotificationsQuery = useInfiniteQuery(
		['fetchNotifications', currentProduct],
		({ pageParam = 1 }) => getNotifications('multiflex', pageParam),
		{
			getNextPageParam: (lastPage, pages) => {
				const currentPage = pages.length;
				const pagesLimit =
					lastPage.totalNotifications === 0
						? 1
						: Math.ceil(lastPage.totalNotifications / 10);
				// there are still pages? return 'currentPage + 1', there aren't any left? return 'undefined'
				return currentPage < pagesLimit ? currentPage + 1 : undefined;
			},
			onError: () => {
				toast.error('Ocorreu um problema ao buscar as notificações.');
			},
			enabled: open && currentProduct === 'multiflex',
		}
	);

	const readAllNotificationsQuery = useMutation(readAllNotifications, {
		onSuccess: () => {
			queryClient.invalidateQueries('fetchNotifications');
		},
	});

	function getNotificationsList() {
		if (user.access_level === 'super_admin')
			return fetchNotificationsQuery.data?.pages.flatMap((page) =>
				page.notificationsOperator.filter(
					(notification) =>
						notification.event_type === 'termOfUse' ||
						notification.event_type === 'privacyPolicy'
				)
			);

		return fetchNotificationsQuery.data?.pages.flatMap(
			(page) => page.notificationsOperator
		);
	}

	function parseEventTypeToPTBR(eventType: string, title?: string) {
		const categories = {
			payment: 'Pagamento',
			payment_review: 'Revisão de pagamento',
			chargeback: 'Estorno',
			kyc: 'KYC',
			kyc_individual: 'KYC de indivíduo',
			card: 'Cartão',
			card_batch: 'Remessa de cartões',
			termOfUse: 'Termos de uso',
			privacyPolicy: 'Aviso de privacidade',
			relocated_chat: 'Realocação de chat',
			refund: 'Reembolso',
			new_chat: 'Novo chat',
			fundsin_billing_available: 'Boleto de pagamento',
			dynamic_notification: title ?? 'Notificação do sistema Bounty',
			antiFraudPolicy: 'Política antifraude',
		};

		return categories[eventType as keyof typeof categories] ?? eventType;
	}

	function parseDateTimeToBrazil(createdAt: Date) {
		const brazilTimezoneDate = new Date(createdAt);

		return brazilTimezoneDate
			.toLocaleString('pt-BR', {
				day: '2-digit',
				month: '2-digit',
				year: '2-digit',
				hour: '2-digit',
				minute: '2-digit',
			})
			.replace(' ', ' - ');
	}

	function getDetailsLink(notification: Notification) {
		switch (notification.event_type) {
			case 'payment':
				return `/home/payments/details/${notification.resource_id}`;

			case 'payment_review':
				return `/home/payments/${notification.resource_id}/review`;

			case 'chargeback':
				return `/home/chargebacks/${notification.resource_id}`;

			case 'kyc':
				return `/home/collaborators`;

			case 'kyc_individual':
				return `/home/collaborators/manage/${notification.resource_id}`;

			case 'card':
				return `/home/collaborators/manage/${notification.resource_id}`;

			case 'card_batch':
				return `/home/cards/${notification.resource_id}`;

			case 'termOfUse':
			case 'privacyPolicy':
				return `/${
					user.access_level === 'super_admin' ? 'super-admin' : 'home'
				}/terms`;

			case 'relocated_chat':
				return '/home/chat';

			case 'new_chat':
				return '/home/chat';

			case 'refund':
				return `/home/refunds/${notification.resource_id}`;

			case 'fundsin_billing_available':
				return `/home/wallet/${notification.resource_id}`;

			default:
				return '#';
		}
	}

	function renderLoadMoreBtn() {
		if (fetchNotificationsQuery.hasNextPage)
			return (
				<S.FooterBtn
					onClick={() => {
						fetchNotificationsQuery.fetchNextPage();
					}}
					style={
						fetchNotificationsQuery.isFetchingNextPage
							? { display: 'none' }
							: {}
					}
					disabled={fetchNotificationsQuery.isFetchingNextPage}
				>
					Carregar mais
				</S.FooterBtn>
			);
		return <div />;
	}

	function handleSeeNotificationDetails(notification: Notification) {
		if (notification.company_id) changeCurrentCompany(notification.company_id);

		if (!notification.was_read)
			toggleNotificationSeenStatus([notification.id], true);
	}

	function toggleNotificationSeenStatus(ids: string[], status: boolean) {
		toggleNotificationsStatus(ids, status);
	}

	function handleReadAllNotifications() {
		readAllNotificationsQuery.mutate();
	}

	return (
		<S.NotificationsList>
			{getNotificationsList()?.map((notification) => (
				<S.Notification key={notification.id} $unread={!notification.was_read}>
					<S.NotificationTop>
						<S.NotificationDate>
							{parseDateTimeToBrazil(new Date(notification.created_at))}
						</S.NotificationDate>

						<S.NotificationOptionBtn
							onClick={() =>
								toggleNotificationSeenStatus(
									[notification.id],
									!notification.was_read
								)
							}
							$unread={!notification.was_read}
							data-testid={`toggle_read_${notification.id}`}
						>
							<BsClockFill
								data-rh={`Marcar como ${
									notification.was_read ? 'não' : ''
								} lida`}
							/>
						</S.NotificationOptionBtn>
					</S.NotificationTop>

					<S.NotificationCompany>
						{companies.find((c) => c.id === notification.company_id)?.name}
					</S.NotificationCompany>

					<S.NotificationType>
						{parseEventTypeToPTBR(notification.event_type, notification.title)}
					</S.NotificationType>

					<S.NotificationMessage $unread={!notification.was_read}>
						{notification.message}
					</S.NotificationMessage>

					<S.SeeDetailsLink
						to={getDetailsLink(notification)}
						$unread={!notification.was_read}
						onClick={() => handleSeeNotificationDetails(notification)}
					>
						{getDetailsLink(notification) !== '#' &&
							notification.event_type !== 'fundsin_billing_available' && (
								<span>
									Ver mais <BsArrowDownShort />
								</span>
							)}
					</S.SeeDetailsLink>

					<S.Divider $unread={!notification.was_read} />
				</S.Notification>
			))}

			{(fetchNotificationsQuery.isLoading ||
				fetchNotificationsQuery.isFetchingNextPage) && (
				<S.LoadingContainer>
					<Loader size={30} />
				</S.LoadingContainer>
			)}
			{fetchNotificationsQuery.data?.pages[0].totalNotifications === 0 && (
				<EmptyContent text='Nenhuma notificação' />
			)}

			<S.Footer>
				{renderLoadMoreBtn()}
				{unreadNotifications > 0 && (
					<S.FooterBtn onClick={handleReadAllNotifications}>
						Marcar todas como lidas
					</S.FooterBtn>
				)}
			</S.Footer>
		</S.NotificationsList>
	);
}
