import { useEffect, useState } from 'react';
import Modal from '../Modal';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import * as S from './styles';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { BsZoomIn, BsZoomOut } from 'react-icons/bs';

interface ImagesSliderProps {
	initialImg: string | null;
	closeSlide(): void;
	images: string[];
}

export default function ImagesSlider({
	images,
	initialImg,
	closeSlide,
}: ImagesSliderProps) {
	const [currentImg, setCurrentImg] = useState(images[0]);

	useEffect(() => {
		if (initialImg) setCurrentImg(images.find((img) => img === initialImg)!!);
	}, [initialImg, images]);

	function handleChangeImg(orientation: number) {
		const currentImgIndex = images.findIndex((img) => img === currentImg);

		// remember that orientation can be -1 or 1. So it goes to the next image from images array or the previous one
		setCurrentImg(images[currentImgIndex + orientation]);
	}

	return (
		<Modal isOpen={!!initialImg} onRequestClose={closeSlide} enableClose={true}>
			<S.Container>
				{images.findIndex((img) => img === currentImg) === 0 ? (
					<S.LeftArrow disabled>
						<FaArrowLeft />
					</S.LeftArrow>
				) : (
					<S.LeftArrow onClick={() => handleChangeImg(-1)}>
						<FaArrowLeft />
					</S.LeftArrow>
				)}

				<TransformWrapper centerOnInit>
					{({ instance, zoomIn, zoomOut, resetTransform, ...rest }) => (
						<S.ImageContainer>
							<TransformComponent
								contentStyle={{
									display: 'flex',
									justifyContent: 'center',
								}}
							>
								<S.Image src={currentImg} alt='' />
							</TransformComponent>

							<S.ZoomOptionsContainer>
								<S.ZoomOption onClick={() => zoomIn()}>
									<BsZoomIn />
								</S.ZoomOption>
								<S.ZoomOption onClick={() => zoomOut()}>
									<BsZoomOut />
								</S.ZoomOption>
							</S.ZoomOptionsContainer>
						</S.ImageContainer>
					)}
				</TransformWrapper>

				{images.findIndex((img) => img === currentImg) === images.length - 1 ? (
					<S.RightArrow disabled>
						<FaArrowRight />
					</S.RightArrow>
				) : (
					<S.RightArrow onClick={() => handleChangeImg(1)}>
						<FaArrowRight />
					</S.RightArrow>
				)}
			</S.Container>
		</Modal>
	);
}
