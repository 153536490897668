import { NotificationComponentProps } from '.';
import { NotificationPreferenceType } from '../../../../@types/CorporateExpenses/Preferences';
import { SelectField } from '../../../../componentsV2/ui/Form/SelectField';
import {
	TitleTypography,
	Typography,
} from '../../../../componentsV2/ui/Typography';
import { useAuth } from '../../../../hooks/useAuth';
import * as S from './styles';
import { notificationPreferenteTypeParsed } from './utils/parseNotificationPreferenteType';

export function Financial({
	register,
	onChangePref,
	isLoading,
}: NotificationComponentProps) {
	const { user } = useAuth();
	return (
		<S.Container>
			<S.SectionDescriptionContainer>
				<Typography
					className='description'
					style={{
						justifyContent: 'center',
						textAlign: 'center',
						maxWidth: '50rem',
					}}
				>
					Aqui, você pode escolher ativar ou desativar notificações relacionadas
					às movimentações financeiras de carteira e cartões.
				</Typography>
			</S.SectionDescriptionContainer>

			<TitleTypography
				className='headerText'
				size='1.8rem'
				primaryText='Notificações'
				color='var(--primary-blue)'
				color_primary='var(--dark-gray)'
			>
				de financeiro
			</TitleTypography>

			{user.roles.includes('manage-balances') && (
				<S.SectionContainer>
					<div>
						<TitleTypography
							size='1.6rem'
							weight='600'
							color='var(--dark-gray)'
						>
							Solicitação de recarga de carteira
						</TitleTypography>
						<p>
							Você receberá uma notificação sempre que você fizer um pedido de
							recarga de carteira.
						</p>
					</div>

					<S.OptionContainer>
						<SelectField
							label='Notificação:'
							name='funds_in_solicitation'
							id='funds_in_solicitation'
							register={register}
							onChange={(e) =>
								onChangePref({
									funds_in_solicitation: e.target
										.value as NotificationPreferenceType,
								})
							}
							disabled={isLoading}
						>
							<option value={'email'}>
								{notificationPreferenteTypeParsed['email']}
							</option>
							<option value={'both'}>Plataforma e E-mail</option>
						</SelectField>
					</S.OptionContainer>
				</S.SectionContainer>
			)}

			{user.roles.includes('manage-balances') && (
				<S.SectionContainer>
					<div>
						<TitleTypography
							size='1.6rem'
							weight='600'
							color='var(--dark-gray)'
						>
							Saldo disponível após recarga de carteira
						</TitleTypography>
						<p>
							Você receberá uma notificação sempre que o saldo estiver
							disponível na carteira.
						</p>
					</div>

					<S.OptionContainer>
						<SelectField
							label='Notificação:'
							name='funds_in'
							id='funds_in'
							register={register}
							onChange={(e) =>
								onChangePref({
									funds_in: e.target.value as NotificationPreferenceType,
								})
							}
							disabled={isLoading}
						>
							<option value={'email'}>
								{notificationPreferenteTypeParsed['email']}
							</option>
							<option value={'notification'}>
								{notificationPreferenteTypeParsed['notification']}
							</option>
							<option value={'both'}>
								{notificationPreferenteTypeParsed['both']}
							</option>
						</SelectField>
					</S.OptionContainer>
				</S.SectionContainer>
			)}

			<S.SectionContainer>
				<div>
					<TitleTypography size='1.6rem' weight='600' color='var(--dark-gray)'>
						Aviso de saldo baixo
					</TitleTypography>
					<p>
						Você receberá uma notificação sempre que um cartão estiver com saldo
						abaixo do que você estabeleceu como mínimo.
					</p>
				</div>

				<S.OptionContainer>
					<SelectField
						label='Notificação:'
						name='card_limit_reached'
						id='card_limit_reached'
						register={register}
						onChange={(e) =>
							onChangePref({
								card_limit_reached: e.target
									.value as NotificationPreferenceType,
							})
						}
						disabled={isLoading}
					>
						<option value={'email'}>
							{notificationPreferenteTypeParsed['email']}
						</option>
						<option value={'notification'}>
							{notificationPreferenteTypeParsed['notification']}
						</option>
						<option value={'both'}>
							{notificationPreferenteTypeParsed['both']}
						</option>
					</SelectField>
				</S.OptionContainer>
			</S.SectionContainer>

			{user.roles.includes('manage-users') && (
				<S.SectionContainer>
					<div>
						<TitleTypography
							size='1.6rem'
							weight='600'
							color='var(--dark-gray)'
						>
							Usuário sem cartão
						</TitleTypography>
						<p>
							Você receberá uma notificação sempre que um usuário não tiver um
							cartão atribuído
						</p>
					</div>

					<S.OptionContainer>
						<SelectField
							label='Notificação:'
							name='users_without_card'
							id='users_without_card'
							register={register}
							onChange={(e) =>
								onChangePref({
									users_without_card: e.target
										.value as NotificationPreferenceType,
								})
							}
							disabled={isLoading}
						>
							<option value={'email'}>
								{notificationPreferenteTypeParsed['email']}
							</option>
							<option value={'notification'}>
								{notificationPreferenteTypeParsed['notification']}
							</option>
							<option value={'both'}>
								{notificationPreferenteTypeParsed['both']}
							</option>
						</SelectField>
					</S.OptionContainer>
				</S.SectionContainer>
			)}
		</S.Container>
	);
}
