export function getBenefitParsedTitle(title: string) {
	title = title.toUpperCase();
	switch (title) {
		case 'HOME_OFFICE':
			return 'HOME OFFICE';
		case 'JUS':
			return 'ASSISTÊNCIA JURÍDICA';
		case 'CAUSAS':
			return 'DOAÇÃO E CAUSAS';
		case 'PREMIO':
			return 'PREMIAÇÃO SEM SAQUE';
		case 'PREMIO_SAQUE':
			return 'PREMIAÇÃO COM SAQUE';
		case 'MATERNIDADE':
			return 'MATERNIDADE E PATERNIDADE';
		case 'REEMBOLSO':
			return 'REEMBOLSO SEM SAQUE';
		case 'REEMBOLSO_SAQUE':
			return 'REEMBOLSO COM SAQUE';

		default:
			return title;
	}
}
