import { useEffect, useState } from 'react';
import { Button } from '../../../../../componentsV2/ui/Button';
import { PdfReportGenerator } from '../ReportGeneratorModal/ReportGenerators/PDFGenerator/index';
import { SpreadsheetReportGenerator } from '../ReportGeneratorModal/ReportGenerators/SpreadsheetGenerator';
import { Card } from '../../../../../@types/CorporateExpenses/Card';
import {
	TitleTypography,
	Typography,
} from '../../../../../componentsV2/ui/Typography';
import { OFXReportGenerator } from '../ReportGeneratorModal/ReportGenerators/OFXGenerator';
import fileIcon from '../../../../../assets/file-arrow-up-down.svg';
import { SelectField } from '../../../../../componentsV2/ui/Form/SelectField';
import { Field } from '../../../../../componentsV2/ui/Form/Field';
import {
	CORPWAY_CARDS_KEY,
	getCards,
} from '../../../../../services/queries/Corpway/Cards';
import { UseMutationResult, useMutation, useQuery } from 'react-query';
import { useAuth } from '../../../../../hooks/useAuth';
import { showErrorMessage } from '../../../../../utils/ErrorHandler';
import { Loader } from '../../../../../componentsV2/ui/Loader';
import { Carousel } from '../../../../../componentsV2/Carousel';
import { InputComp } from '../../../../../componentsV2/ui/Form/InputStyle';
import { FaArrowRight } from 'react-icons/fa';
import Modal from '../../../../../componentsV2/ui/Modal';
import { MdOutlineEmail } from 'react-icons/md';
import { InputField } from '../../../../../componentsV2/ui/Form/InputField';
import { UseFormReturn, useFieldArray, useForm } from 'react-hook-form';
import { FiMinus } from 'react-icons/fi';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { getCardExtractToReport } from '../../../../../services/queries/Corpway/Extract';
import { CheckboxField } from '../../../../../componentsV2/ui/Form/CheckboxField';
import {
	AlternativeEmailContainer,
	AppendEmailFieldBttn,
	ButtonsContainer,
	CheckboxesContainer,
	Container,
	DatesContainer,
	FieldMessage,
	FieldValue,
	FieldsRow,
	HeaderContainer,
	IconContainer,
	IconHeader,
	LabelContainer,
	MainContentContainer,
	RecipientEmailContainer,
	RemoveEmailFieldBttn,
	ReportInfoContainer,
	SelectContainer,
	TimePeriodChipContainer,
	CardSelectContainer,
	FormatSelectContainer,
	OpenModalContainer,
	OpenModalButtons,
	Label,
	Checkmark,
	Input,
} from './styles';
import axios from 'axios';
import { Transaction } from '../../../../../@types';
import { formatCardNumber } from '../../../../../utils/formatCardNumber';
import { CardListStatus } from '../../../../../stores/corpway/useCardSelectStore';
import { Company } from '../../../../../contexts/AuthContext';
import CustomTooltip from '../../../Home/Charts/components/CustomTooltip';

export interface Props {
	card: Card | null;
	month: string;
	enableAll: boolean;
	status: CardListStatus;
}

export interface ReportProps {
	start_date: number;
	end_date: number;
	format: string;
	recipientEmails: string[];
	filters: {
		balance_adition: boolean;
		national_purchase: boolean;
		international_purchase: boolean;
		external_refund: boolean;
		internal_refund: boolean;
	};
	reportCategory: 'individual' | 'all_cards';
	card_status: 'active' | 'blocked' | 'canceled';
}

interface ReportFormData {
	recipient_emails: { email: string }[];
	balance_adition: boolean;
	national_purchase: boolean;
	international_purchase: boolean;
	external_refund: boolean;
	internal_refund: boolean;
	include_cancelled_purchases: boolean;
}

export interface TimePeriod {
	label: string;
	days: string | number;
}

const TimePeriods: TimePeriod[] = [
	{
		label: 'Mensal',
		days: 'month',
	},
	{
		label: 'Personalizado',
		days: 'custom',
	},
];

const mappedStatus = {
	Ativos: 'active',
	Bloqueados: 'blocked',
	Cancelados: 'canceled',
};

const ReportFormats = ['pdf', 'xlsx', 'ofx'];

type RecipientEmailOption = 'operator' | 'financial' | 'custom';

export function ReportGeneratorModal({
	card,
	month,
	enableAll,
	status,
}: Props) {
	const { generatePDF, loading: pdfLoading } = PdfReportGenerator();
	const { generateOFX, loading: ofxLoading } = OFXReportGenerator();
	const { generateSpreadsheet, loading: spreadsheetLoading } =
		SpreadsheetReportGenerator();

	const reportGeneratorForm = useForm<ReportFormData>({
		defaultValues: {
			recipient_emails: [{ email: '' }],
			balance_adition: false,
			national_purchase: false,
			international_purchase: false,
			external_refund: false,
			internal_refund: false,
			include_cancelled_purchases: false
		},
	});

	const { register, control, reset, getValues, watch } = reportGeneratorForm;

	const watchNationalPurchase = watch("national_purchase");
	const watchInternationalPurchase = watch("international_purchase");

	const { fields, append, remove } = useFieldArray({
		control,
		name: 'recipient_emails',
	});

	const { recipient_emails, ...filtersChecks } = watch();

	const { currentCompany, user } = useAuth();
	const [isOpen, setOpen] = useState(false);
	const [reportCategory, setReportCategory] = useState<
		'individual' | 'all_cards'
	>('individual');
	const [currentStep, setCurrentStep] = useState<0 | 1 | 2 | 3 | 4 | 5>(0);
	const [selectedCard, setSelectedCard] = useState(card);
	const [selectedTimePeriod, setSelectedTimePeriod] = useState(TimePeriods[0]); // month default
	const [selectedReportFormat, setSelectedReportFormat] = useState(
		ReportFormats[0]
	);

	const [recipientEmailOption, setRecipientEmailOption] =
		useState<RecipientEmailOption>('operator');

	const currentDate = new Date();

	const [year, monthDate] = month.split('-');

	let defaultStartDate = new Date(Number(year), Number(monthDate) - 1, 1);
	let defaultEndDate = new Date(
		Number(year),
		Number(monthDate) - 1 === currentDate.getMonth()
			? Number(monthDate) - 1
			: Number(monthDate),
		Number(monthDate) - 1 === currentDate.getMonth() ? currentDate.getDate() : 0
	);

	const eighteenMonthsAgo = new Date();
	const minDate = new Date(2024, 0);
	eighteenMonthsAgo.setMonth(eighteenMonthsAgo.getMonth() - 17);

	function checkMinDate() {
		if (eighteenMonthsAgo < minDate) {
			return minDate;
		} else {
			return eighteenMonthsAgo;
		}
	}

	const [startDate, setStartDate] = useState(defaultStartDate);
	const [endDate, setEndDate] = useState(defaultEndDate);

	const getCardsQuery = useQuery(
		[CORPWAY_CARDS_KEY, user.id, currentCompany?.id],
		() => getCards(currentCompany!.id, {
			name: "alias",
			order: "ASC"
		}),
		{
			onError: (err) => {
				showErrorMessage(err as Error, 'Não foi possível buscar os cartões. ');
			},
			refetchOnWindowFocus: false,
		}
	);

	const allCards = getCardsQuery.data?.cards_active
		?.concat(
			getCardsQuery.data.cards_blocked,
			getCardsQuery.data.cards_canceled
		)

	const getCardExtractToReportQuery = useMutation(
		({
			start_date,
			end_date,
			format,
			recipientEmails,
			filters,
			reportCategory,
			card_status,
		}: ReportProps) =>
			getCardExtractToReport(
				card!.id,
				currentCompany!.id,
				start_date,
				end_date,
				format,
				recipientEmails,
				filters,
				reportCategory,
				card_status,
			),
		{
			onError: (err) => {
				if (
					axios.isAxiosError(err) &&
					err.response?.data?.message === 'Transactions not found'
				) {
					setCurrentStep(5);
					return;
				}
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao gerar o relatório de extrato.'
				);
			},
		}
	);

	function handleCardSelection(card_id: string) {
		const cardSelected = getCardsQuery.data?.cards_active.find(
			(card) => card.id === card_id
		);

		if (cardSelected) setSelectedCard(cardSelected);
	}

	function handleTimePeriodSelection(period: TimePeriod) {
		setSelectedTimePeriod(period);
	}

	function checkFiltersValues(filters: object) {
		for (let key in filters) {
			if (filters[key as keyof typeof filters]) {
				return true;
			}
		}
		return false;
	}

	useEffect(() => {
		if (isOpen) {
			setSelectedCard(card);
			setCurrentStep(0);
		}
	}, [isOpen]); // eslint-disable-line  react-hooks/exhaustive-deps

	useEffect(() => {
		setStartDate(defaultStartDate);
		setEndDate(defaultEndDate);
	}, [month]); // eslint-disable-line

	function handleCloseModal() {
		setOpen(false);
		reset();
		setSelectedTimePeriod(TimePeriods[0]);
		setSelectedReportFormat(ReportFormats[0]);
		setStartDate(defaultStartDate);
		setEndDate(defaultEndDate);
		setReportCategory('individual');
	}

	function handleOpenModal(category: 'individual' | 'all_cards') {
		setReportCategory(category);
		setOpen(true);
	}

	async function handleSubmitModal() {
		if (currentStep === 0) {
			return setCurrentStep(1);
		}

		const { recipient_emails, ...filters } = getValues();

		const dayInMilliseconds = 24 * 60 * 60 * 1000; // Number of milliseconds in a day
		const thirtyDays = 30 * dayInMilliseconds; // Duration of 30 days in milliseconds

		const duration = endDate.getTime() - startDate.getTime();

		if (
			reportCategory === 'all_cards' ||
			(selectedTimePeriod.days === 'custom' && duration > thirtyDays)
		) {
			if (currentStep === 1) return setCurrentStep(3);

			let recipientEmails = [user.email];

			if (recipientEmailOption === 'financial') {
				recipientEmails = [currentCompany!.financial_sector_email];
			}

			if (recipientEmailOption === 'custom') {
				recipientEmails = recipient_emails.map((email) => email.email);
			}

			await getCardExtractToReportQuery.mutateAsync({
				start_date: startDate.valueOf(),
				end_date: endDate.valueOf(),
				format: selectedReportFormat,
				card_status: mappedStatus[status] as 'active' | 'blocked' | 'canceled',
				recipientEmails,
				filters,
				reportCategory,
			});

			return setCurrentStep(4);
		}

		try {
			if (selectedReportFormat === 'pdf')
				await generatePDF({
					card: selectedCard!,
					start_date: startDate.valueOf(),
					end_date: endDate.valueOf(),
					filters,
				});
			else if (selectedReportFormat === 'ofx')
				await generateOFX({
					card: selectedCard!,
					start_date: startDate.valueOf(),
					end_date: endDate.valueOf(),
					filters,
				});
			else if (selectedReportFormat === 'xlsx')
				await generateSpreadsheet({
					card: selectedCard!,
					start_date: startDate.valueOf(),
					end_date: endDate.valueOf(),
					filters,
				});

			setCurrentStep(2);
		} catch (error: any) {
			if (error.message === 'empty_transactions') {
				setCurrentStep(5);
			}
		}
	}

	const CardToReportSelection = () => {
		if (reportCategory === 'all_cards') return null;

		return getCardsQuery.isLoading || !selectedCard ? (
			<Loader />
		) : (
			<>
				<CardSelectContainer>
					<SelectField
						label='Informações do cartão:'
						onChange={(e) => handleCardSelection(e.target.value)}
						defaultValue={selectedCard?.id}
						required
						style={{ padding: '1rem' }}
					>
						{allCards?.map((card) => (
							<option key={card.id} value={card.id}>
								{formatCardNumber(card.pan)} {'|'} {card.alias}
							</option>
						))}
					</SelectField>
				</CardSelectContainer>
				<FieldsRow className='information'>
					<Field label='Centro de custo:'>
						<FieldValue>{selectedCard?.cost_center_data?.title}</FieldValue>
					</Field>

					<Field label='Responsável:'>
						<FieldValue>{selectedCard?.user_data?.name}</FieldValue>
					</Field>
				</FieldsRow>
			</>
		);
	};

	function CustomCheckbox({ watchNationalPurchase, watchInternationalPurchase }: any) {
		const isDisabled = !(watchNationalPurchase || watchInternationalPurchase);

		return (
			<div style={{
				marginTop: '-1.5rem',
				display: 'flex',
				alignItems: 'center',
				alignContent: 'center',
				gap: '3px',
				width: '38rem'
			}}
			>
				<Label disabled={isDisabled}>
					Incluir compras canceladas
					<Input
						type="checkbox"
						disabled={isDisabled}
						{...register('include_cancelled_purchases')}
						name="include_cancelled_purchases"
					/>	
						<Checkmark />
				</Label>
				<span
					style={{
						marginTop: '-.5rem'
					}}
				>
					<CustomTooltip
						showTitle={false}
						description={`Habilitado somente em compras nacionais ou internacionais.`} 
						/>

				</span>
			</div>
		);
	}

	const ReportFilters = ({ filters = false }) => {
		return (
			<>
				<HeaderContainer>
					<IconContainer>
						<IconHeader src={fileIcon} alt='' />
					</IconContainer>

					<TitleTypography
						primaryText='Exportar'
						color_primary='inherit'
						size='1.8rem'
					>
						<Typography size='1.8rem'>
							relatório {reportCategory === 'individual' && ' individual'}
						</Typography>
					</TitleTypography>
					{reportCategory === 'all_cards' && (
						<Typography size='1.8rem' style={{ marginTop: '-1.5rem' }}>
							{' '}
							de todos os cartões
						</Typography>
					)}
				</HeaderContainer>

				<ReportInfoContainer>
					<CardToReportSelection />

					<MainContentContainer>
						{!!filters ? (
							<FieldsRow>
								<Field label='Por favor, selecione um ou mais tipos de lançamentos:'>
									<CheckboxesContainer>
										<CheckboxField
											label='Adição de saldo'
											name='balance_adition'
											register={register}
										/>
										<CheckboxField
											label='Compra nacional'
											name='national_purchase'
											register={register}
										/>
										<CheckboxField
											label='Compra internacional'
											name='international_purchase'
											register={register}
										/>
										<CheckboxField
											label='Estorno interno'
											name='internal_refund'
											register={register}
										/>
										<CheckboxField
											label='Estorno externo'
											name='external_refund'
											register={register}
										/>
										<CheckboxField label='IOF' name='fee' register={register} />
									</CheckboxesContainer>
								</Field>
							</FieldsRow>
						) : (
							<>
								<FieldsRow align={true}>
									<Field label='Período do extrato:'>
										<Carousel mode='draggable' gap='1rem'>
											{TimePeriods.map((period) => (
												<TimePeriodChipContainer
													key={period.label}
													isActive={selectedTimePeriod?.days === period.days}
													onClick={() => handleTimePeriodSelection(period)}
												>
													{period.label}
												</TimePeriodChipContainer>
											))}
										</Carousel>
									</Field>
								</FieldsRow>

								<FieldsRow>
									{selectedTimePeriod.days === 'custom' ? (
										<DatesContainer>
											<InputComp
												disabled={selectedTimePeriod.days !== 'custom'}
												type='date'
												defaultValue={startDate.toISOString().split('T')[0]}
												min={checkMinDate().toISOString().split('T')[0]}
												max={currentDate.toISOString().split('T')[0]}
												onBlur={(e) => setStartDate(new Date(e.target.value))}
												style={{ width: 'fit-content' }}
											/>
											<FaArrowRight
												style={{
													width: '2rem',
													height: '2rem',
													color: 'var(--primary-blue)',
												}}
											/>
											<InputComp
												disabled={selectedTimePeriod.days !== 'custom'}
												type='date'
												defaultValue={endDate.toISOString().split('T')[0]}
												min={checkMinDate().toISOString().split('T')[0]}
												max={currentDate.toISOString().split('T')[0]}
												onBlur={(e) => setEndDate(new Date(e.target.value))}
												style={{ width: 'fit-content' }}
											/>
										</DatesContainer>
									) : (
										<DatesContainer>
											<InputComp
												type='month'
												value={startDate.toISOString().slice(0, 7)}
												defaultValue={currentDate
													.toISOString()
													.split('-')
													.slice(0, 2)
													.join('-')}
												min={checkMinDate()
													.toISOString()
													.split('-')
													.slice(0, 2)
													.join('-')}
												max={currentDate
													.toISOString()
													.split('-')
													.slice(0, 2)
													.join('-')}
												onChange={(e) => {
													const selectedMonth = e.target.value;
													const year = parseInt(selectedMonth.split('-')[0]);
													const month = parseInt(selectedMonth.split('-')[1]);
													setStartDate(new Date(year, month - 1, 1));
													setEndDate(new Date(year, month, 0));
												}}
											/>
										</DatesContainer>
									)}
								</FieldsRow>

								<FieldsRow>
									<FieldMessage>
										Escolha o formato que você deseja exportar o relatório.
									</FieldMessage>
									<FormatSelectContainer>
										<SelectField
											label='Formato:'
											onChange={(e) => setSelectedReportFormat(e.target.value)}
											defaultValue={selectedReportFormat}
											required
											style={{ padding: '1rem' }}
										>
											{ReportFormats.map((format) => (
												<option key={format} value={format}>
													{format.toUpperCase()}
												</option>
											))}
										</SelectField>
									</FormatSelectContainer>
								</FieldsRow>
							</>
						)}
					</MainContentContainer>

					{!!filters && (
						<CustomCheckbox
							watchNationalPurchase={watchNationalPurchase}
							watchInternationalPurchase={watchInternationalPurchase}
					  />
					)}

					<ButtonsContainer>
						<Button
							intent='terciary'
							$outline
							roundness='lg'
							onClick={() => {
								if (currentStep === 1) setCurrentStep(0);
								else handleCloseModal();
							}}
						>
							Voltar
						</Button>

						<Button
							intent='primary'
							roundness='lg'
							onClick={() => handleSubmitModal()}
							disabled={!!filters && !checkFiltersValues(filtersChecks)}
							loading={spreadsheetLoading || pdfLoading || ofxLoading}
							shrinkOnLoading={false}
						>
							{filters ? (
								'Exportar relatório'
							) : (
								'Continuar'
							)}
						</Button>
					</ButtonsContainer>
				</ReportInfoContainer>
			</>
		);
	};

	const ConclusionMessage = ({
		mainMessage,
		secondaryMessage,
	}: {
		mainMessage: string;
		secondaryMessage: string;
	}) => {
		return (
			<>
				<HeaderContainer>
					<IconContainer>
						<IconHeader src={fileIcon} alt='' />
					</IconContainer>

					<TitleTypography
						primaryText={mainMessage}
						color_primary='inherit'
						size='1.8rem'
					>
						<Typography size='1.8rem'>{secondaryMessage}</Typography>
					</TitleTypography>
				</HeaderContainer>

				<ReportInfoContainer>
					<ButtonsContainer>
						<Button intent='primary' roundness='lg' onClick={handleCloseModal}>
							Fechar
						</Button>
					</ButtonsContainer>
				</ReportInfoContainer>
			</>
		);
	};
	const EmptyContent = () => (
		<>
			<HeaderContainer>
				<IconContainer style={{ backgroundColor: 'var(--primary-red)' }}>
					<IconHeader src={fileIcon} alt='' />
				</IconContainer>

				<TitleTypography color='var(--primary-red)' size='1.8rem'>
					Nenhum relatório disponível
				</TitleTypography>
			</HeaderContainer>
			<Typography size='1.4rem'>
				Nenhuma transação encontrada para o período escolhido!
			</Typography>

			<ReportInfoContainer>
				<ButtonsContainer>
					<Button intent='primary' roundness='lg' onClick={handleCloseModal}>
						Fechar
					</Button>
				</ButtonsContainer>
			</ReportInfoContainer>
		</>
	);

	return (
		<>
			<OpenModalContainer>
				<TitleTypography size='2.2rem' primaryText='Exportar'>
					relatório
				</TitleTypography>

				<OpenModalButtons>
					<Button
						intent='terciary'
						$outline
						roundness='lg'
						onClick={() => handleOpenModal('individual')}
					>
						Por cartão
					</Button>

					<Button
						disabled={!enableAll}
						intent='primary'
						roundness='lg'
						onClick={() => handleOpenModal('all_cards')}
					>
						Todos os cartões
					</Button>
				</OpenModalButtons>
			</OpenModalContainer>

			<Modal
				isOpen={isOpen}
				onRequestClose={handleCloseModal}
				position='center'
			>
				<Container>
					{currentStep === 0 && <ReportFilters filters={false} />}
					{currentStep === 1 && <ReportFilters filters={true} />}
					{currentStep === 2 && (
						<ConclusionMessage
							mainMessage='Download'
							secondaryMessage='iniciado'
						/>
					)}
					{currentStep === 3 && (
						<RecipientEmail
							append={append}
							fields={fields}
							handleSubmitModal={handleSubmitModal}
							recipientEmailOption={recipientEmailOption}
							reportGeneratorForm={reportGeneratorForm}
							remove={remove}
							reportCategory={reportCategory}
							setCurrentStep={setCurrentStep}
							setRecipientEmailOption={setRecipientEmailOption}
							user={user}
							getCardExtractToReportQuery={getCardExtractToReportQuery}
							company={currentCompany!}
						/>
					)}
					{currentStep === 4 && (
						<ConclusionMessage
							mainMessage='Relatório enviado'
							secondaryMessage='para o e-mail'
						/>
					)}
					{currentStep === 5 && <EmptyContent />}
				</Container>
			</Modal>
		</>
	);
}

interface RecipientEmailProps {
	recipientEmailOption: RecipientEmailOption;
	setRecipientEmailOption: (option: RecipientEmailOption) => void;
	fields: { id: string; email: string }[];
	append: (value: { email: string }) => void;
	remove: (index: number) => void;
	reportGeneratorForm: UseFormReturn<ReportFormData>;
	handleSubmitModal: () => void;
	getCardExtractToReportQuery: UseMutationResult<
		Transaction[],
		unknown,
		ReportProps,
		unknown
	>;
	user: { email: string };
	reportCategory: string;
	setCurrentStep: (number: 0 | 1 | 2 | 3 | 4 | 5) => void;
	company: Company;
}

const RecipientEmail = ({
	recipientEmailOption,
	setRecipientEmailOption,
	fields,
	append,
	remove,
	reportGeneratorForm,
	handleSubmitModal,
	user,
	reportCategory,
	setCurrentStep,
	getCardExtractToReportQuery,
	company
}: RecipientEmailProps) => {
	const validateEmails = () => {
		const isCustomRecipient = recipientEmailOption === 'custom';

		if (!isCustomRecipient) {
			handleSubmitModal();
			return;
		}
		const customEmails = reportGeneratorForm.getValues('recipient_emails');
		const isValidEmail = (email: string) =>
			yup.string().email().isValidSync(email);

		let emailsValid = true;

		customEmails.forEach((email) => {
			if (email.email === '') {
				toast.error('Todos os campos de email devem ser preenchidos!');
				emailsValid = false;
				return;
			}

			if (!isValidEmail(email.email)) {
				toast.error(`${email.email} não é um email válido!`);
				emailsValid = false;
				return;
			}
		});

		if (emailsValid) {
			handleSubmitModal();
		}
	};

	return (
		<>
			<HeaderContainer>
				<IconContainer>
					<IconHeader src={fileIcon} alt='' />
				</IconContainer>

				<TitleTypography
					primaryText='Exportar'
					color_primary='inherit'
					size='1.8rem'
				>
					<Typography size='1.8rem'>
						Relatório {reportCategory === 'individual' && ' individual'}
					</Typography>
				</TitleTypography>
				{reportCategory === 'all_cards' && (
					<Typography size='1.8rem' style={{ marginTop: '-1.5rem' }}>
						{' '}
						de todos os cartões
					</Typography>
				)}
			</HeaderContainer>

			<ReportInfoContainer>
				<RecipientEmailContainer>
					<FieldMessage>
						{reportCategory === 'individual' ? (
							<>
								Relatórios solicitados com um período maior à{' '}
								<span>31 dias</span> serão enviadas por e-mail:
							</>
						) : (
							<>
								Relatórios solicitados de todos os cartões serão enviados por
								e-mail:
							</>
						)}
					</FieldMessage>

					<SelectContainer>
						<input
							id='operator_select'
							type='radio'
							checked={recipientEmailOption === 'operator'}
							onChange={() => {
								setRecipientEmailOption('operator');
							}}
						/>
						<LabelContainer>
							<label htmlFor='operator_select'>
								Enviar para o e-mail cadastrado:
							</label>
							<p>({user.email})</p>
						</LabelContainer>
					</SelectContainer>

					<SelectContainer>
						<input
							id='finacial_select'
							type='radio'
							checked={recipientEmailOption === 'financial'}
							onChange={() => {
								setRecipientEmailOption('financial');
							}}
						/>
						<LabelContainer>
							<label htmlFor='finacial_select'>
								Financeiro da sua empresa:
							</label>
							<p>({company.financial_sector_email})</p>
						</LabelContainer>
					</SelectContainer>

					<SelectContainer>
						<input
							id='custom_select'
							type='radio'
							checked={recipientEmailOption === 'custom'}
							onChange={() => {
								setRecipientEmailOption('custom');
							}}
						/>
						<LabelContainer>
							<label htmlFor='custom_select'>
								Enviar para um e-mail alternativo:
							</label>
							{fields.map((field, index) => (
								<AlternativeEmailContainer key={field.id}>
									<MdOutlineEmail
										style={{ position: 'absolute', marginLeft: '0.5rem' }}
										color={
											recipientEmailOption === 'custom'
												? 'var(--primary-blue)'
												: 'var(--light-gray)'
										}
									/>
									<InputField
										type='email'
										placeholder='Insira o destinatário'
										disabled={recipientEmailOption !== 'custom'}
										name={`recipient_emails.${index}.email`}
										register={reportGeneratorForm.register}
										style={{
											height: '3rem',
											width: '100%',
											paddingLeft: '4rem',
										}}
									/>
									<RemoveEmailFieldBttn
										onClick={() => remove(index)}
										disabled={
											recipientEmailOption !== 'custom' || fields.length === 1
										}
									>
										<FiMinus
											size={'2rem'}
											color={
												recipientEmailOption === 'custom'
													? 'var(--primary-blue)'
													: 'var(--light-gray)'
											}
										/>
									</RemoveEmailFieldBttn>
								</AlternativeEmailContainer>
							))}
						</LabelContainer>
					</SelectContainer>

					<AppendEmailFieldBttn
						onClick={() => append({ email: '' })}
						disabled={recipientEmailOption !== 'custom'}
					>
						+ Adicionar outro e-mail
					</AppendEmailFieldBttn>
				</RecipientEmailContainer>

				<ButtonsContainer>
					<Button
						intent='terciary'
						$outline
						roundness='lg'
						onClick={() => setCurrentStep(1)}
					>
						Voltar
					</Button>

					<Button
						intent='primary'
						roundness='lg'
						onClick={validateEmails}
						shrinkOnLoading={false}
						loading={getCardExtractToReportQuery.isLoading}>
						Enviar por e-mail
					</Button>
				</ButtonsContainer>
			</ReportInfoContainer>
		</>
	);
};
