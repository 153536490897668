import { HTMLAttributes } from 'react';
import styled from 'styled-components';

export function SectionContainer({
	children,
	...rest
}: HTMLAttributes<HTMLDivElement>) {
	return <SectionContainerStyle {...rest}>{children}</SectionContainerStyle>;
}

const SectionContainerStyle = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 1rem;
	background-color: #fff;
	padding: 2rem;
	border-radius: 1.6rem;
`;
