import styled, { css } from "styled-components";
import { OptionsButton } from "../Button";

interface AddCostCenterProps {
  smallStyle: boolean;
}

const SmallButtonStyles = css`
  padding: 0;
  align-self: center;
  margin-left: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  & > svg {
    width: 3rem;
    height: 3rem;
    color: var(--primary-blue);
  }
`;

export const AddCostCenter = styled.button<AddCostCenterProps>`
  ${OptionsButton}
  ${(props) => props.smallStyle && SmallButtonStyles}
`;

export const CancelOption = styled.button`
  ${OptionsButton}
  color: var(--primary-blue);
  background-color: var(--white);
`;
