import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { Input } from '../../components/Form/FormStyles';

export const Container = styled.div`
	flex: 1;
	height: 100vh;
	display: flex;
	background: linear-gradient(#2dd1fc 0%, #27adff 100%);
	font-family: 'Poppins', sans-serif;
	font-weight: 400;
	& > div {
		flex: 1;
	}

	@media (max-width: 910px) {
		flex-direction: column;
		height: 100%;
	}
`;

export const LeftContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;

	& > img {
		width: 70rem;
	}

	.ResponsiveModelImage {
		display: none;
		margin-top: 2.5rem;
		margin-bottom: -16rem;
		clip-path: inset(0 0.2rem 0 0);
	}

	@media (max-width: 810px) {
		& > img {
			width: 26rem;
		}

		.modelImage {
			display: none;
		}

		.ResponsiveModelImage {
			display: block;
		}
	}
`;

export const RightContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	border-top-left-radius: 3.2rem;
	border-bottom-left-radius: 3.2rem;
	background-color: var(--white);
	z-index: 1;

	@media (max-width: 810px) {
		border-bottom-left-radius: 0rem;
		border-top-right-radius: 3.2rem;
	}
`;

export const MainContainer = styled.main`
	width: 50rem;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 2.4rem;

	& > img {
		width: 17.2rem;
	}

	& h2 {
		color: var(--dark-gray2);
		font-size: 3.2rem;
		font-weight: 600;
	}

	& p {
		color: var(--dark-gray2);
		font-size: 1.5rem;
	}

	.backLogin {
		display: flex;
		justify-content: center;
	}

	@media (max-width: 810px) {
		padding: 4rem 2rem 0rem;
		gap: 2rem;
	}
`;

const Box = css`
	box-shadow: 0px 13px 27px -5px rgba(50, 50, 93, 0.25),
		0px 8px 16px -8px rgba(0, 0, 0, 0.3),
		0px -6px 16px -6px rgba(0, 0, 0, 0.025);
	/* height: 40rem; */
	width: 40rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	background-color: var(--white);
	border-radius: 0.5rem;
`;

export const Form = styled.form`
	display: flex;
	flex-direction: column;
	row-gap: 2.4rem;

	.emailWrapper {
		display: flex;
		flex-direction: column;
		gap: 1.6rem;
		align-items: right;
	}

	> .sendButton {
		border: none;
		font-family: 'Poppins', sans-serif;
		font-size: 1.4rem;
		height: 4.8rem;
		transition: all 0.1s ease-in-out;
		background-color: var(--primary-blue);
	}

	.ButtonWrapper {
		display: flex;
		justify-content: space-evenly;
		gap: 2rem;
	}
`;

export const LogoImage = styled.img`
	width: 24rem;
	margin-bottom: 3rem;
`;

export const Title = styled.h1`
	font-weight: 500;
	width: 100%;
	margin-bottom: 1.4rem;
`;

export const ContentForm = styled.div`
	width: 100%;
`;

export const InfoText = styled.p`
	font-size: 1.2rem;
	margin-bottom: 2rem;

	& span {
		font-weight: bold;
	}
`;

export const FormInput = styled(Input)`
	height: 3.6rem;
	margin-bottom: 3rem;
`;

export const BackToSignInButton = styled(Link)`
	text-decoration: none;
	color: var(--primary-blue);
	float: right;
	margin: 1rem 0;
	font-size: 1.4rem;
`;

const MainOption = css`
	background-color: var(--primary-blue);
	color: var(--white);
	border: 0;
	border-radius: 0.4rem;
	font-weight: bold;
	font-size: 1.6rem;
	height: 4rem;
	width: 50%;
`;

export const SubmitButton = styled.button`
	${MainOption}
`;

export const BoxContainer = styled.div`
	${Box}
	padding: 5rem 2rem;
`;

export const BackToLoginMainLink = styled(Link)`
	text-decoration: none;
	${MainOption}
	display: flex;
	align-items: center;
	justify-content: center;
`;
