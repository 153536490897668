import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import PageTitle from '../../../components/PageTitle';
import PaymentTable from './components/PaymentTable/PaymentTableEdit';
import { useAuth } from '../../../hooks/useAuth';
import { usePayment } from '../../../hooks/usePaymentV2';
import { fetchPayment } from '../../../services/queries/PaymentsV2';
import { AddToTableOptions } from './components/AddToTableOptions';
import * as S from './styles';
import { parsePayment } from '../../../utils/parsePayment';
import Loader from '../../../components/Loader';
import { SubmitPayment } from './components/SubmitPaymentCreation';
import { ScheduleInput } from '../../../components/ScheduleInput';
import { parseDateToInput } from '../../../utils/masks';
import { useEffect, useState } from 'react';
import { calculatePaymentsAmount } from './utils/calculatePaymentsAmout';
import { getBenefitsInputs } from './utils/getBenefitValuesInputs';
import {
	addBenefitInputEventListener,
	removeBenefitInputEventListener,
} from './utils/setBenefitInputEventListener';
import { MAX_PAYMENTS } from './utils/MAX_PAYMENTS';
import { PaymentsAmount } from './components/PaymentsAmount';
import { showErrorMessage } from '../../../utils/ErrorHandler';

type RelaunchPaymentParams = {
	id?: string;
};

// EditPayment is similar to RelaunchPayment but the user can change the date and the API call submition is
// and Edit endpoint and not a creation one
export function EditPayment() {
	const { id } = useParams<RelaunchPaymentParams>();
	const { user, currentCompany } = useAuth();
	const {
		beneficiaries,
		setBeneficiaries,
		setBenefits,
		setSelectedBenefits,
		schedule,
		setSchedule,
	} = usePayment();
	const [paymentsAmount, setPaymentsAmount] = useState(0);

	useEffect(() => {
		if (beneficiaries.length === 0) return;

		const inputs = getBenefitsInputs();

		inputs.forEach((input) => {
			addBenefitInputEventListener(input, setCalculatedPaymentsAmount);
		});

		return () => {
			inputs.forEach((input) => {
				removeBenefitInputEventListener(input, setCalculatedPaymentsAmount);
			});
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [beneficiaries]);

	useEffect(() => {
		setCalculatedPaymentsAmount();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [beneficiaries]);

	const setCalculatedPaymentsAmount = () => {
		setPaymentsAmount(calculatePaymentsAmount(beneficiaries));
	};

	const fetchPaymentQuery = useQuery(
		['fetchPayment', currentCompany?.id, id],
		() => fetchPayment(id!, currentCompany!.id),
		{
			onSuccess: (data) => {
				const { beneficiariesParsed, benefitsParsed } = parsePayment(data);

				setBeneficiaries([
					...beneficiariesParsed.map((beneficiary) => ({
						...beneficiary,
						selected: true,
					})),
				]);
				setBenefits(benefitsParsed);
				setSelectedBenefits(benefitsParsed);
				setSchedule(
					data.release.scheduledDate ? data.release.scheduledDate : ''
				);
			},
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao buscar o lançamento. '
				);
			},
			refetchOnWindowFocus: false,
			refetchOnReconnect: false,
		}
	);

	function isScheduleEditable() {
		return (
			(fetchPaymentQuery.data?.release.status === 'requested' ||
				fetchPaymentQuery.data?.release.status === 'scheduled') &&
			fetchPaymentQuery.data?.release.userOperatorCreator.id === user.id &&
			fetchPaymentQuery.data?.release.scheduledDate
		);
	}

	function parseScheduleToInput(schedule: string) {
		const dateObj = new Date(schedule);
		const date = parseDateToInput(dateObj.toLocaleDateString());
		const time = dateObj.toLocaleTimeString().slice(0, -3); // remove secs

		return `${date}T${time}`;
	}

	if (!fetchPaymentQuery.data || fetchPaymentQuery.isFetching) {
		return (
			<S.Container>
				<PageTitle title='Editar Pagamento' />
				<Loader />
			</S.Container>
		);
	}
	return (
		<S.Container>
			<PageTitle title='Editar Pagamento' />

			{/* TABLE COMPONENT THAT RENDERS COLLABORATORS AND THEIR BENEFITS */}
			<PaymentTable showSchedule={false} />

			{isScheduleEditable() && (
				<div style={{ maxWidth: '20rem', marginTop: '2rem' }}>
					<label>Editar data de agendamento</label>
					<ScheduleInput
						value={parseScheduleToInput(schedule)}
						onChange={(e) => setSchedule(e.target.value)}
					/>
				</div>
			)}
			<PaymentsAmount paymentsAmount={paymentsAmount} />

			<S.FooterContainer>
				{/* INCLUDE COLLABORATORS, GROUPS and BENEFITS OPTIONS*/}
				<AddToTableOptions paymentsAmount={paymentsAmount} />

				{/* Submit component reponsible for all pre-release verifications steps + submitting to API */}
				<SubmitPayment
					editPaymentId={id}
					disabled={paymentsAmount > MAX_PAYMENTS}
				/>
			</S.FooterContainer>
		</S.Container>
	);
}
