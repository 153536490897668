import { MdCheck, MdLock } from 'react-icons/md';
import { Button } from '../../../../componentsV2/ui/Button';
import { PasswordInput } from '../../../../componentsV2/ui/Form/PasswordInput';
import InformationModal from '../../../../componentsV2/ui/Modal/InformationModal';
import { Typography } from '../../../../componentsV2/ui/Typography';
import { UseFormReturn, useForm } from 'react-hook-form';
import { EditUserProfile } from '../../../../@types/CorporateExpenses/User';
import { useAuth } from '../../../../hooks/useAuth';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { editUserPassword } from '../../../../services/queries/Corpway/User';
import { showErrorMessage } from '../../../../utils/ErrorHandler';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import * as S from './styles';
import { InputField } from '../../../../componentsV2/ui/Form/InputField';

const schema = z
	.object({
		password: z
			.string()
			.min(6, { message: 'A senha deve ter pelo menos 6 caracteres' }),
		old_password: z
			.string()
			.min(6, { message: 'A senha antiga deve ter pelo menos 6 caracteres' }),
		confirm_password: z.string(),
	})
	.refine(({ password, confirm_password }) => password === confirm_password, {
		message: 'A confirmação de senha deve ser igual a nova senha',
		path: ['confirm_password'],
	})
	.optional();

export function UpdatePassword() {
	const userProfileForm = useForm<EditUserProfile>({
		resolver: zodResolver(schema),
	});

	const { handleSubmit, reset, watch, setError, clearErrors } = userProfileForm;
	const { user } = useAuth();
	const [isChangePasswordModalVisible, setIsChangePasswordModalVisible] =
		useState(false);
	const [isUpdateInformationModalVisible, setIsUpdateInformationModalVisible] =
		useState(false);

	const passwordInputValue = watch([
		'password',
		'confirm_password',
		'old_password',
	]);

	const updateUserPasswordMutation = useMutation(
		(data: EditUserProfile) =>
			editUserPassword(data.old_password, data.password, data.confirm_password),
		{
			onSuccess: () => {
				setIsChangePasswordModalVisible(false);
				setIsUpdateInformationModalVisible(true);
				reset();
			},
			onError: (err: any) => {
				if (err.response.data.message === 'Old password does not match.') {
					setError('old_password', {
						type: 'manual',
						message: 'Senha incorreta, por favor digite novamente',
					});
				} else {
					showErrorMessage(
						err as Error,
						'Não foi possível atualizar as informações do perfil.'
					);
				}
			},
		}
	);

	function handleUpdateUser(userData: EditUserProfile) {
		updateUserPasswordMutation.mutate(userData);
	}

	return (
		<div
			style={{
				display: 'grid',
				gap: '3rem',
				width: '100%',
			}}
		>
			<S.UserData>
				<InputField value={user.name} label='Nome completo' disabled />
				<InputField value={user.email} label='E-mail' disabled />
			</S.UserData>

			<Button
				width='adjusted'
				roundness='lg'
				onClick={() => {
					clearErrors();
					setIsChangePasswordModalVisible(true);
				}}
				style={{ margin: '0 auto', width: '32rem' }}
			>
				Alterar Senha
			</Button>

			<ChangePasswordModal
				isVisible={isChangePasswordModalVisible}
				modalToggle={() => {
					clearErrors();
					setIsChangePasswordModalVisible(false);
				}}
				handleSubmit={handleSubmit(handleUpdateUser)}
				userProfileForm={userProfileForm}
				loading={updateUserPasswordMutation.isLoading}
				isButtonDisabled={
					!passwordInputValue[0] ||
					!passwordInputValue[1] ||
					!passwordInputValue[2]
				}
			/>

			<UpdatePasswordInformationModal
				isVisible={isUpdateInformationModalVisible}
				modalToggle={() => setIsUpdateInformationModalVisible(false)}
			/>
		</div>
	);
}

interface ChangePasswordModalProps {
	isVisible: boolean;
	modalToggle: () => void;
	handleSubmit: () => void;
	userProfileForm: UseFormReturn<EditUserProfile>;
	isButtonDisabled: boolean;
	loading: boolean;
}

interface UpdateInformationModalProps {
	isVisible: boolean;
	modalToggle: () => void;
}

const UpdatePasswordInformationModal = ({
	modalToggle,
	isVisible,
}: UpdateInformationModalProps) => {
	return (
		<InformationModal
			isOpen={isVisible}
			onRequestClose={modalToggle}
			titleIcon={<MdCheck size={30} color={'white'} />}
			title={[
				{ text: 'Senha alterada', isBold: false },
				{ text: ' com sucesso ', isBold: true },
			]}
			backButtonText={'Fechar'}
			onBack={modalToggle}
			color={'primary'}
		>
			{null}
		</InformationModal>
	);
};

const ChangePasswordModal = ({
	isVisible,
	modalToggle,
	handleSubmit,
	userProfileForm,
	isButtonDisabled,
	loading,
}: ChangePasswordModalProps) => {
	const {
		formState: { errors },
		clearErrors,
		reset,
	} = userProfileForm;

	const handleClose = () => {
		clearErrors();
		reset({
			password: '',
			confirm_password: '',
		});
		modalToggle();
	};

	return (
		<InformationModal
			titleIcon={<MdLock size={30} color={'white'} />}
			isOpen={isVisible}
			onNext={handleSubmit}
			onBack={handleClose}
			onRequestClose={handleClose}
			title={[
				{ text: 'Alterar ', isBold: true },
				{ text: 'senha ', isBold: false },
			]}
			nextButtonText={'Alterar Senha'}
			backButtonText={'Cancelar'}
			color={'primary'}
			isNextButtonLoading={loading}
			isNextButtonDisabled={isButtonDisabled}
		>
			<Typography
				color={'#575757'}
				size={'1.4rem'}
				weight={'600'}
				style={{ textAlign: 'start', width: '100%' }}
			>
				Informe a senha atual e a nova senha abaixo:
			</Typography>

			<PasswordInput
				showLeftIcon
				id='old_password'
				name='old_password'
				label='Senha atual'
				autoComplete='new-password'
				placeholder='********'
				required
				register={userProfileForm.register}
				errorMessage={errors.old_password?.message}
			/>

			<S.Divider direction={'horizontal'} />

			<PasswordInput
				id='password'
				name='password'
				label='Nova senha'
				placeholder='********'
				autoComplete='new-password'
				required
				register={userProfileForm.register}
				errorMessage={userProfileForm.formState.errors.password?.message}
			/>
			<PasswordInput
				id='confirm_password'
				name='confirm_password'
				label='Confirmar nova senha'
				autoComplete='new-password'
				placeholder='********'
				required
				register={userProfileForm.register}
				errorMessage={
					userProfileForm.formState.errors.confirm_password?.message
				}
			/>
		</InformationModal>
	);
};
