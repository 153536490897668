import { useRouteMatch, useLocation, Redirect } from 'react-router-dom';
import { SidebarLink } from '../../components/SidebarLink';
import FlexibleBenefitsRoutes from '../../routes/FlexibleBenefitsRoutes';
import { Header } from '../../components/Header';
import { useAuth } from '../../hooks/useAuth';
import { BiSpreadsheet, BiTransfer } from 'react-icons/bi';
import {
	FaUserFriends,
	FaDollarSign,
	FaCommentAlt,
	FaHandHoldingUsd,
	FaInfoCircle,
	FaStickyNote,
	FaCreditCard,
	FaHandsHelping,
	FaHouseUser,
	FaMoneyBill,
	FaWallet,
} from 'react-icons/fa';
import avatarImg from '../../assets/avatar.svg';
import * as S from './styles';
import { RiExchangeDollarLine } from 'react-icons/ri';
import { useEffect, useState } from 'react';
import { ChatProps } from './Chat/ChatListPage';
import { db, firebaseAuth } from '../../services/firebase';
import { ref, onValue, get, set, child, update } from 'firebase/database';
import { useQuery } from 'react-query';
import { getCompanyPartners } from '../../services/queries/Partners';
import { SidebarDropdown } from '../../components/SidebarDropdown';

const BENEFITS_PAGE_TITLE = 'Bounty | Benefícios Flexíveis';

export default function FlexibleBenefits() {
	const { path } = useRouteMatch();
	const { pathname } = useLocation();
	const { user, currentCompany, firebaseAuthConcluded, companies } = useAuth();
	const [chatsOpenQuantity, setChatsOpenQuantity] = useState<
		undefined | number
	>(undefined);

	const getCompanyPartnersQuery = useQuery(
		['fetch-partners', currentCompany?.id],
		() => getCompanyPartners(currentCompany!.id),
		{
			enabled: !!currentCompany?.id,
			refetchOnWindowFocus: false,
		}
	);

	useEffect(() => {
		document.title = BENEFITS_PAGE_TITLE;
	}, []);

	useEffect(() => {
		if (!firebaseAuthConcluded || !user || !currentCompany || !companies) {
			return;
		}
		try {
			(async () => {
				await Promise.allSettled(
					companies.map(async (company) => {
						const operatorsRef = ref(db, `${company?.id}/operators`);
						await get(operatorsRef)
							.then((snapshot) => {
								if (!snapshot.hasChild(firebaseAuth.currentUser?.uid!)) {
									const newOperatorRef = child(
										operatorsRef,
										firebaseAuth.currentUser?.uid!
									);
									set(newOperatorRef, {
										id: user.id,
										name: user.name,
										email: user.email,
										jobDescription: user.office,
										profileUrl: user.avatar_url ? user.avatar_url : '',
										status: 'online',
									});
								} else {
									const newOperatorRef = child(
										operatorsRef,
										firebaseAuth.currentUser?.uid!
									);
									update(newOperatorRef, {
										id: user.id,
										name: user.name,
										email: user.email,
										jobDescription: user.office,
										status: 'online',
									});
								}
							})
							.catch((err) => {
								console.log(err);
							});
					})
				);
			})();

			const chatsRef = ref(db, `${currentCompany?.id}/chats`);

			let chatsWithoutOperators: string[] = [];
			return onValue(
				chatsRef,
				(snapshot) => {
					snapshot.forEach((snap) => {
						let chatProps: ChatProps = snap.val();
						if (!chatProps.responsable_operator && chatProps.opened) {
							chatsWithoutOperators.push(snap.key!);
						}
					});
					setChatsOpenQuantity(chatsWithoutOperators.length);
					chatsWithoutOperators = [];
				},
				(error) => {
					console.log(
						'🚀 ~ file: index.tsx ~ line 52 ~ returnonValue ~ error',
						error
					);
				}
			);
		} catch (err) {
			console.log(err);
		}
	}, [user, currentCompany, firebaseAuthConcluded, companies]); //eslint-disable-line

	if (
		!user.term_of_use_accepted_multiflex ||
		!user.privacy_policy_accepted_multiflex
	) {
		return <Redirect to='/terms/accept' />;
	}

	return (
		<S.Container>
			<Header name={user.name} email={user.email} avatar={avatarImg} />
			<S.MainContent>
				<S.Sidebar>
					<SidebarDropdown
						title='Cadastro'
						icon={FaUserFriends}
						subMenuItems={[
							{
								path: '/collaborators',
								title: 'Colaboradores',
								icon: FaUserFriends,
							},
							{
								path: '/cost-centers',
								title: 'Centros de custo',
								icon: FaHouseUser,
							},
						]}
					/>
					<SidebarLink
						icon={FaCreditCard}
						title='Cartões'
						path={`${path}/cards`}
						current_page={pathname.includes('cards')}
					/>
					<SidebarDropdown
						title='Pagamentos'
						icon={FaUserFriends}
						subMenuItems={[
							{
								path: '/payment/import',
								title: 'Colaboradores',
								icon: BiSpreadsheet,
							},
							{
								path: '/wallet',
								title: 'Carteira',
								icon: FaMoneyBill,
							},
							{
								path: '/payments',
								title: 'Status',
								icon: FaDollarSign,
							},
							{
								path: '/transfers',
								title: 'Histórico',
								icon: BiTransfer,
							},
						]}
					/>
					<SidebarLink
						icon={FaHandHoldingUsd}
						title='Reembolso'
						path={`${path}/refunds`}
						current_page={pathname.includes('refund')}
					/>
					<SidebarLink
						icon={RiExchangeDollarLine}
						title='Estorno'
						path={`${path}/chargebacks`}
						current_page={pathname.includes('chargebacks')}
					/>
					<SidebarLink
						icon={FaCommentAlt}
						title='Chat'
						path={`${path}/chat`}
						current_page={pathname.includes('chat')}
						chatsOpen={chatsOpenQuantity}
					/>
					<SidebarLink
						icon={FaStickyNote}
						title='Mural'
						path={`${path}/messages`}
						current_page={pathname.includes('messages')}
					/>
					<SidebarLink
						icon={FaWallet}
						title='Remover benefícios'
						path={`${path}/remove-benefit`}
						current_page={pathname.includes('remove-benefit')}
					/>
					{/* conditionally hide */}
					{!!getCompanyPartnersQuery.data &&
						getCompanyPartnersQuery.data.length > 0 && (
							<SidebarLink
								icon={FaHandsHelping}
								title='Parceiros'
								path={`${path}/partners`}
								current_page={pathname.includes('partners')}
							/>
						)}
					<SidebarLink
						icon={FaInfoCircle}
						title='Suporte'
						path={`${path}/support`}
						current_page={pathname.includes('support')}
					/>
				</S.Sidebar>

				<S.PagesContainer>
					{currentCompany && <FlexibleBenefitsRoutes />}
				</S.PagesContainer>
			</S.MainContent>
		</S.Container>
	);
}
