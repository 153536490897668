import React from 'react';
import { Typography } from '../../../../../componentsV2/ui/Typography';
import { Link, LinkBaseStyle } from '../../../../../componentsV2/ui/Link';
import styled from 'styled-components';
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';

interface Props {
	title: string;
	description: string;
	icon: JSX.Element;
	color: string;
	link: string;
	isExternalLink?: boolean;
}

const HelpCard = ({ title, description, icon, link, color, isExternalLink = false }: Props) => {
	return (
		<HelpCardContent>
			<InformationContent>
				<div
					style={{ display: 'flex', flexDirection: 'column', gap: '1.4rem' }}
				>
					<Typography size='2rem' weight='600'>
						{title}
					</Typography>
					<Typography color='var(--mid-gray)'>{description}</Typography>
				</div>
				<BorderIcon>
					<Icon color={color}>{icon}</Icon>
				</BorderIcon>
			</InformationContent>

			<Separator />
			{isExternalLink ? (
				<AnchorStyle
					href={link.startsWith('http') ? link : `https://${link}`}
					target="_blank"
					rel="noopener noreferrer"
					$underline
				>
					Acessar <MdOutlineKeyboardArrowRight />
				</AnchorStyle>
			) : (
				<LinkStyle $underline to={link} icon>
					Acessar
				</LinkStyle>
			)}

		</HelpCardContent>
	);
};

export default HelpCard;

export const HelpCardContent = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 50rem;
	min-width: 18rem;
	min-height: 22rem;
	border: 2px solid var(--light-gray3);
	border-radius: 1.6rem;
	padding: 2rem 4rem;
`;

export const InformationContent = styled.div`
	display: flex;
	gap: 2rem;
`;

export const BorderIcon = styled.div`
	display: flex;
	align-items: center;
`;

export const Icon = styled.div<{ color: string }>`
	display: flex;
	align-items: center;
	border-radius: 3.2rem;
	background-color: ${(props) => props.color};
	width: 4.8rem;
	height: 4.8rem;

	& > svg {
		color: var(--white);
		padding: 1rem;
		width: 4.8rem;
		height: 4.8rem;
	}
`;

export const Separator = styled.div`
	height: 0.2rem;
	width: 100%;
	background-color: var(--light-gray3);
`;

const LinkStyle = styled(Link)`
	text-decoration: none;
	width: 20%;
	border-bottom: 2px solid;
	padding-bottom: 0rem;

	@media (max-width: 500px) {
		width: 30%;
		justify-content: flex-start;
		padding-top: 1.5rem;
	}
`;

const AnchorStyle = styled.a<{ $underline?: boolean }>`
  ${LinkBaseStyle}
  position: relative;
  display: inline-flex;
  align-items: center;
  color: var(--primary-blue);
  text-decoration: none;
  text-align: left;
  justify-content: flex-start;

  &::after {
    content: '';
    position: absolute;
    left: 0;
	text-align: left;
    bottom: -2px;
    width:  20%;
    height: 2px;
    background-color: var(--primary-blue);
    transition: width 0.2s ease-in-out;
  }

  &:hover::after {
    width: 20%;
  }

  & > svg {
    width: 2rem;
    height: 2rem;
    margin-left: 0.5rem;
  }
`;




