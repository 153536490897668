import { Release } from '../../../../@types';
import { useAuth } from '../../../../hooks/useAuth';
import { cnpjMask, cpfMask } from '../../../../utils/masks';
import { paymentsReportStyle } from './styles';
import { Document, Font, Image, Page, Text, View } from '@react-pdf/renderer';
import FlexibleBenefitsLogo from '../../../../assets/flexibleBenefitsReports/FlexibleBenefitsLogo.png';
import reportIcon from '../../../../assets/flexibleBenefitsReports/ReportIcon.png';
import { convertCentsToFormattedReais } from '../../../../utils/CurrencyConvert';
import { parseReleaseStatusToReport } from '../util/parsePaymentStatusToReport';
import { getBenefitParsedTitle } from '../../../../utils/benefits/getBenefitParsedTitle';

Font.register({
	family: 'Poppins',
	fonts: [
		{
			src: 'https://fonts.gstatic.com/s/poppins/v1/hlvAxH6aIdOjWlLzgm0jqg.ttf',
		},
		{
			src: 'https://fonts.gstatic.com/s/poppins/v1/4WGKlFyjcmCFVl8pRsgZ9vesZW2xOQ-xsNqO47m55DA.ttf',
			fontWeight: 500,
		},
		{
			src: 'https://fonts.gstatic.com/s/poppins/v1/-zOABrCWORC3lyDh-ajNnPesZW2xOQ-xsNqO47m55DA.ttf',
			fontWeight: 600,
		},
	],
});

export function PDFReport() {
	const { currentCompany } = useAuth();

	function getPaymentMadeText(data: Release): string {
		//Validate status
		const status = data.release.status;
		if (status === 'disapproved' || status === 'canceled') {
			return 'Pagamento não efetivado';
		}
		if (status === 'processing') {
			return 'Processando';
		}
		const scheduledDate = data.release.scheduledDate;
		let date = scheduledDate ?? data.release.updatedAt;

		return Intl.DateTimeFormat('pt-BR', {
			day: '2-digit',
			month: '2-digit',
			year: 'numeric',
			hour: '2-digit',
			minute: '2-digit',
			hour12: false, // 24-hour format
		}).format(new Date(date));
	}

	async function generatePDF(data: Release, tableData: any, detailed: boolean) {
		return (
			<Document>
				<Page
					size='A4'
					style={paymentsReportStyle.page}
					orientation='landscape'
				>
					<View style={paymentsReportStyle.header} fixed>
						<Image
							src={FlexibleBenefitsLogo}
							style={paymentsReportStyle.logo}
						/>
						<View style={{ ...paymentsReportStyle.headerInfo, width: '120%' }}>
							<Text style={paymentsReportStyle.headerInfoLabel}>Nome:</Text>
							<Text style={paymentsReportStyle.headerInfoData}>
								{currentCompany?.corporateName}
							</Text>
						</View>
						<View style={paymentsReportStyle.headerInfo}>
							<Text style={paymentsReportStyle.headerInfoLabel}>CNPJ:</Text>
							<Text style={paymentsReportStyle.headerInfoData}>
								{cnpjMask(currentCompany?.cnpj!)}
							</Text>
						</View>
						<View style={paymentsReportStyle.headerInfo}>
							<Text style={paymentsReportStyle.headerInfoLabel}>
								N° Página:
							</Text>
							<Text
								style={paymentsReportStyle.headerInfoData}
								render={({ pageNumber, totalPages }) =>
									`${String(pageNumber).padStart(2, '0')}/${String(
										totalPages
									).padStart(2, '0')}`
								}
							/>
						</View>
						<View style={paymentsReportStyle.headerInfo}>
							<Text style={paymentsReportStyle.headerInfoLabel}>
								Data do pedido:
							</Text>
							<Text style={paymentsReportStyle.headerInfoData}>
								{Intl.DateTimeFormat('pt-BR', {
									day: '2-digit',
									month: '2-digit',
									year: 'numeric',
									hour: '2-digit',
									minute: '2-digit',
									hour12: false, // 24-hour format
								}).format(new Date())}
							</Text>
						</View>
						<View style={{ ...paymentsReportStyle.headerInfo, width: '60%' }}>
							<Text style={paymentsReportStyle.headerInfoLabel}>
								N° do pedido:
							</Text>
							<Text style={paymentsReportStyle.headerInfoData}>
								{String(data.totalReports).padStart(7, '0')}
							</Text>
						</View>
					</View>
					<View style={paymentsReportStyle.subHeader}>
						<View style={paymentsReportStyle.titleContainer}>
							<Image src={reportIcon} style={paymentsReportStyle.reportLogo} />
							<View style={paymentsReportStyle.reportTitleContainer}>
								<Text
									style={{
										...paymentsReportStyle.reportTitle,
										color: '#FF0037',
									}}
								>
									Relatório de
								</Text>

								<Text> </Text>

								<Text style={paymentsReportStyle.reportTitle}>Pagamento</Text>
							</View>
						</View>

						<View style={paymentsReportStyle.subheaderDataContainer}>
							<View style={paymentsReportStyle.subheaderDatesSection}>
								<View style={paymentsReportStyle.subheaderInfo}>
									<Text style={paymentsReportStyle.subheaderLabel}>
										Gerado em:
									</Text>
									<Text style={paymentsReportStyle.subheaderData}>
										{Intl.DateTimeFormat('pt-BR', {
											day: '2-digit',
											month: '2-digit',
											year: 'numeric',
											hour: '2-digit',
											minute: '2-digit',
											hour12: false, // 24-hour format
										}).format(new Date(data.release.createdAt))}
									</Text>
								</View>

								<View style={{ ...paymentsReportStyle.subheaderInfo }}>
									<Text style={paymentsReportStyle.subheaderLabel}>
										Efetivado em:
									</Text>
									<Text style={paymentsReportStyle.subheaderData}>
										{getPaymentMadeText(data)}
									</Text>
								</View>
							</View>

							<View style={{ ...paymentsReportStyle.subheaderInfo }}>
								<Text style={paymentsReportStyle.subheaderLabel}>Status:</Text>
								{parseReleaseStatusToReport(data.release.status)}
							</View>

							<View
								style={{
									...paymentsReportStyle.totalValueContainer,
								}}
							>
								<Text
									style={{
										...paymentsReportStyle.totalValueText,
										fontWeight: 600,
										fontSize: 12,
									}}
								>
									Valor Total:
								</Text>
								<Text
									style={{
										...paymentsReportStyle.totalValueText,
										fontSize: 12,
									}}
								>
									{convertCentsToFormattedReais(
										Number(data.release.totalValue)
									)}
								</Text>
							</View>
						</View>
					</View>

					<View style={paymentsReportStyle.tableContainer}>
						<View style={paymentsReportStyle.tableHeader}>
							<View
								style={{
									...paymentsReportStyle.columnTitleContainer,
									width: '125%',
								}}
							>
								<Text style={paymentsReportStyle.columnTitle}>Colaborador</Text>
							</View>
							<View
								style={{
									...paymentsReportStyle.columnTitleContainer,
									width: '75%',
								}}
							>
								<Text style={paymentsReportStyle.columnTitle}>CPF</Text>
							</View>

							{!detailed && (
								<View style={paymentsReportStyle.columnTitleContainer}>
									<Text style={paymentsReportStyle.columnTitle}>Categoria</Text>
								</View>
							)}

							<View style={paymentsReportStyle.columnTitleContainer}>
								<Text style={paymentsReportStyle.columnTitle}>
									Valor {detailed && 'p/ colaborador'}
								</Text>
							</View>
						</View>
						{detailed
							? tableData
									.sort((a: any, b: any) => a.NOME.localeCompare(b.NOME))
									.map((collab: any) => {
										let totalValue = 0;
										let benefitsKeys: string[] = [];
										Object.keys(collab).forEach((key) => {
											const isBenefitKey =
												key !== 'NOME' && key !== 'CPF' && key !== 'EMAIL';

											if (isBenefitKey) {
												totalValue += collab[key];
												benefitsKeys.push(key);
											}
										});

										benefitsKeys.sort();

										return (
											<>
												<View
													style={paymentsReportStyle.tableRow}
													key={collab}
													wrap={false}
												>
													<Text
														style={{
															...paymentsReportStyle.columnData,
															width: '125%',
														}}
													>
														{collab.NOME}
													</Text>

													<Text
														style={{
															...paymentsReportStyle.columnData,
															width: '75%',
														}}
													>
														{cpfMask(collab.CPF)}
													</Text>

													<Text
														style={{
															...paymentsReportStyle.columnData,
															fontWeight: 600,
														}}
													>
														{convertCentsToFormattedReais(totalValue * 100)}
													</Text>
												</View>

												<View
													style={paymentsReportStyle.detailsTableRow}
													wrap={false}
												>
													{benefitsKeys.map((key) => {
														return (
															<View style={paymentsReportStyle.benefitField}>
																<Text
																	style={paymentsReportStyle.subheaderLabel}
																>
																	{key}
																	{': '}
																</Text>
																<Text style={paymentsReportStyle.subheaderData}>
																	{convertCentsToFormattedReais(
																		collab[key] * 100
																	)}
																</Text>
															</View>
														);
													})}
												</View>
											</>
										);
									})
							: data.release.preReleases
									.sort((a, b) =>
										a.collaborator.name.localeCompare(b.collaborator.name)
									)
									.map((preRelease) => {
										return (
											<View
												style={paymentsReportStyle.tableRow}
												key={preRelease.release_payment.id}
												wrap={false}
											>
												<Text
													style={{
														...paymentsReportStyle.columnData,
														width: '125%',
													}}
												>
													{preRelease.collaborator.name}
												</Text>

												<Text
													style={{
														...paymentsReportStyle.columnData,
														width: '75%',
													}}
												>
													{cpfMask(preRelease.collaborator.cpf)}
												</Text>

												<Text style={paymentsReportStyle.columnData}>
													{getBenefitParsedTitle(
														preRelease.release_payment.benefit.title
													)}
												</Text>
												<Text
													style={{
														...paymentsReportStyle.columnData,
														fontWeight: 600,
													}}
												>
													{convertCentsToFormattedReais(
														preRelease.release_payment.benefit.value
													)}
												</Text>
											</View>
										);
									})}
					</View>
				</Page>
			</Document>
		);
	}

	return {
		generatePDF,
	};
}
