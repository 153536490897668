import { useState, useEffect } from 'react';
import * as S from './styles';
import { FaDollarSign } from 'react-icons/fa';
import { showCancelableToast } from '../../../../../../components/CancelableToast';
import Modal from '../../../../../../components/Modal';
import { getBenefitParsedTitle } from '../../../../../../utils/benefits/getBenefitParsedTitle';
import { Benefit } from '../../../../../../@types';
import { moneyMask } from '../../../../../../utils/masks';
import { getBenefitsInputs } from '../../../utils/getBenefitValuesInputs';
import { INPUT_CHANGE_EVENT } from '../../../utils/setBenefitInputEventListener';

interface ChangeAllBenefitValuesModalProps {
	benefit: Benefit;
}
export function ChangeAllBenefitValuesModal({
	benefit,
}: ChangeAllBenefitValuesModalProps) {
	const [open, setOpen] = useState(false);
	const [value, setValue] = useState(0);

	useEffect(() => {
		if (open) setValue(0);
	}, [open]);

	async function changeAllBenefitValues() {
		setOpen(false);

		let previousValues: string[] = [];

		// all the inputs from this benefit
		const benefitInputs = getBenefitsInputs().filter(
			(input) => input.id.endsWith(benefit.id) // the Benefit input id is like this: <beneficiary_id>-<benefit_id>
		);

		benefitInputs.forEach((input) => {
			previousValues.push(input.value);
			input.value = moneyMask(value.toFixed(2));
		});
		benefitInputs[0].dispatchEvent(new Event(INPUT_CHANGE_EVENT));

		const canceled = !(await showCancelableToast(
			'Valor alterado para todos os beneficiados',
			async () => {}
		));

		if (canceled) {
			benefitInputs.forEach((input, index) => {
				input.value = previousValues[index];
			});
			benefitInputs[0].dispatchEvent(new Event(INPUT_CHANGE_EVENT));
		}
	}

	return (
		<>
			<S.OpenModalButton
				onClick={() => setOpen(true)}
				data-rh='Alterar valor para todos os beneficiados'
				data-testid='open-ChangeAllBenefitValuesModal'
			>
				<FaDollarSign />
			</S.OpenModalButton>

			<Modal isOpen={open} enableClose onRequestClose={() => setOpen(false)}>
				<S.Container>
					<S.Title>
						Alterar valor para todos os benefíciados com{' '}
						<span>{getBenefitParsedTitle(benefit.title.toUpperCase())}</span>
					</S.Title>

					<S.CurrencyInput
						onValueChange={({ floatValue }) => {
							setValue(floatValue);
						}}
						value={value}
					/>

					<S.SubmitButton onClick={changeAllBenefitValues}>
						Confirmar
					</S.SubmitButton>
				</S.Container>
			</Modal>
		</>
	);
}
