import { useEffect, useState } from 'react';
import { RiDeleteBin5Fill } from 'react-icons/ri';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Collaborator } from '../../../../@types';
import { AddressModal } from '../../../../components/AddressModal';
import { EmptyContent } from '../../../../components/EmptyContent';
import ModalLoader from '../../../../components/ModalLoader';
import PageTitle from '../../../../components/PageTitle';
import PreventTransitionPrompt from '../../../../components/PreventTransitionPrompt';
import * as TableStyles from '../../../../components/Table/TableStyles';
import UserCard from '../../../../components/UserCard';
import { useAuth } from '../../../../hooks/useAuth';
import { createCardsBatchCollaborators } from '../../../../services/queries/CardsBatchs';
import { CardBatchCollabsSelector } from './components/CardBatchCollabsSelector';
import * as S from './styles';
import { useDialogModal } from '../../../../hooks/useDialogModal';
import { showErrorMessage } from '../../../../utils/ErrorHandler';
import { FilteredCollabsSelector } from '../../../../components/CollabsSelector/FilteredCollabsSelector';
import {
	checkNumberField,
	checkOnlyNumbersInComplement,
	invalidNumberFieldMessage,
	invalidReferenceFieldMessage,
} from '../../../../utils/validateCollaborator';
import {
	NotApprovedCollabsModal,
	ReprovedCollaborators,
} from '../../../../components/NotApprovedCollabsModal';
import { invalidOnlyNumbersComplementMessage } from '../../../../utils/validateCollaborator';
import { ImportCardBatchCollaborators } from './components/ImportCardBatchCollaborators';
import { COLLABORATORS_BATCH_LIMIT } from '../utils/COLLABORATORS_BATCH_LIMIT';
import { cpfMask } from '../../../../utils/masks';

export function CreateCardBatchCollaborators() {
	const { currentCompany } = useAuth();
	const { openOptionsDialog } = useDialogModal();
	const history = useHistory();
	const [selectedCollaborators, setSelectedCollaborators] = useState<
		Collaborator[]
	>([]);
	const [disallowedFilteredCollabs, setDisallowedFilteredCollabs] = useState<
		ReprovedCollaborators[]
	>([]);
	const [allowNavigation, setAllowNavigation] = useState(true);

	useEffect(() => {
		setAllowNavigation(!selectedCollaborators.length);
	}, [selectedCollaborators]);

	const createCardsBatchQuery = useMutation(
		() =>
			createCardsBatchCollaborators(
				{
					collaborators: selectedCollaborators.map((c) => c.id!),
					delivery_address_type: 'collaborators',
				},
				currentCompany!.id
			),
		{
			onSuccess: () => {
				setAllowNavigation(true);
				toast.info(
					'Solicitação de envio de remessa de cartões enviada com sucesso!'
				);
				history.push('/home/cards');
			},
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao tentar enviar a remessa de cartões. '
				);
			},
		}
	);

	function onAddCollaborators(collabs: Collaborator[]) {
		let collaboratorsNotAllowed: ReprovedCollaborators[] = [];

		// verifications
		collabs.forEach((collaborator) => {
			if (
				collaborator.card_batch_items.length > 0 &&
				(collaborator.card_batch_items[0].status === 'pending' ||
					collaborator.card_batch_items[0].status === 'processing')
			) {
				const collab = collaboratorsNotAllowed.find(
					(collab) => collab.id === collaborator.id
				);
				if (collab) {
					(collab.error as string[]).push(invalidReferenceFieldMessage);
				} else {
					collaboratorsNotAllowed.push({
						...collaborator,
						name: `${collaborator.first_name} ${collaborator.last_name}`,
						error: [
							'Colaborador já possui solicitação de cartão em pendência ou processando',
						],
					});
				}
			}

			if (!checkOnlyNumbersInComplement(collaborator.complement)) {
				const collab = collaboratorsNotAllowed.find(
					(collab) => collab.id === collaborator.id
				);
				if (collab) {
					(collab.error as string[]).push(invalidReferenceFieldMessage);
				} else {
					collaboratorsNotAllowed.push({
						...collaborator,
						name: `${collaborator.first_name} ${collaborator.last_name}`,
						error: [invalidOnlyNumbersComplementMessage],
					});
				}
			}

			if (!checkNumberField(collaborator.number)) {
				const collab = collaboratorsNotAllowed.find(
					(collab) => collab.id === collaborator.id
				);
				if (collab) {
					(collab.error as string[]).push(invalidReferenceFieldMessage);
				} else {
					collaboratorsNotAllowed.push({
						...collaborator,
						name: `${collaborator.first_name} ${collaborator.last_name}`,
						error: [invalidNumberFieldMessage],
					});
				}
			}

			if (collaborator.reference && collaborator.reference.length > 30) {
				const collab = collaboratorsNotAllowed.find(
					(collab) => collab.id === collaborator.id
				);
				if (collab) {
					(collab.error as string[]).push(invalidReferenceFieldMessage);
				} else {
					collaboratorsNotAllowed.push({
						...collaborator,
						name: `${collaborator.first_name} ${collaborator.last_name}`,
						error: [invalidReferenceFieldMessage],
					});
				}
			}
		});
		setDisallowedFilteredCollabs(collaboratorsNotAllowed);

		// adding the ones that passed and
		// also, the collaborator could be imported and already be selected, so don't include duplicates
		collabs = collabs.filter(
			(collab) =>
				!collaboratorsNotAllowed.find((c) => c.id === collab.id) &&
				!selectedCollaborators.find((c) => c.id === collab.id)
		);

		addCollaborators(collabs);
	}

	function addCollaborators(collabs: Collaborator[]) {
		setSelectedCollaborators([
			...selectedCollaborators,
			...collabs.map((c) => {
				return { ...c, name: `${c.first_name} ${c.last_name} ` };
			}),
		]);
	}

	function handleRemoveDeliver(id: string) {
		setSelectedCollaborators((oldState) => oldState.filter((c) => c.id !== id));
	}

	function handleSubmit() {
		if (!selectedCollaborators.length) {
			toast.error('Adicione entregas para o envio');
			return;
		}

		openOptionsDialog(
			`Tem certeza que deseja enviar a remessa de cartões para o endereço dos colaboradores?`,
			'Confirmar',
			() => createCardsBatchQuery.mutate(),
			'Cancelar',
			() => {}
		);
	}

	return (
		<S.Container>
			{!allowNavigation && <PreventTransitionPrompt />}

			<PageTitle title='Nova remessa de cartões' />

			<S.HeaderInfoContainer>
				<S.InfoContainer>
					<S.InfoTitle>ENTREGA</S.InfoTitle>
					Endereço dos colaboradores
				</S.InfoContainer>
			</S.HeaderInfoContainer>

			<S.FieldName>Entregas ({selectedCollaborators.length})</S.FieldName>
			{selectedCollaborators.length === 0 ? (
				<EmptyContent text='Nenhuma entrega adicionada nessa remessa' />
			) : (
				<TableStyles.Table>
					<TableStyles.TableHeader>
						<TableStyles.TableRow>
							<TableStyles.TableHeaderCell>
								DESTINATÁRIO
							</TableStyles.TableHeaderCell>
							<TableStyles.TableHeaderCell>DESTINO</TableStyles.TableHeaderCell>
							<TableStyles.TableHeaderCell>OPÇÕES</TableStyles.TableHeaderCell>
						</TableStyles.TableRow>
					</TableStyles.TableHeader>

					<TableStyles.TableBody>
						{selectedCollaborators.map((collaborator) => (
							<TableStyles.TableRow key={collaborator.id!}>
								<TableStyles.TableData>
									<UserCard
										name={collaborator.name}
										bottomInfo={cpfMask(collaborator.cpf)}
										avatar_url={collaborator.avatar_url}
									/>
								</TableStyles.TableData>
								<TableStyles.TableData>
									<AddressModal
										name={collaborator.name}
										address={{
											address: collaborator.address!,
											district: collaborator.district!,
											city: collaborator.city!,
											uf: collaborator.uf!,
											complement: collaborator.complement!,
											reference: collaborator.reference!,
											number: collaborator.number!,
											cep: collaborator.cep!,
										}}
									/>
								</TableStyles.TableData>

								<TableStyles.TableData>
									<S.RemoveCollaboratorBtn
										data-rh='Remover entrega da remessa'
										onClick={() => {
											handleRemoveDeliver(collaborator.id!);
										}}
									>
										<RiDeleteBin5Fill />
									</S.RemoveCollaboratorBtn>
								</TableStyles.TableData>
							</TableStyles.TableRow>
						))}
					</TableStyles.TableBody>
				</TableStyles.Table>
			)}

			<S.AmountsText>
				{`${selectedCollaborators.length}/${COLLABORATORS_BATCH_LIMIT} colaboradores. `}
				Não é possível adicionar mais de {COLLABORATORS_BATCH_LIMIT}{' '}
				colaboradores por remessa!
			</S.AmountsText>

			<S.FooterOptionsContainer>
				<div>
					<ImportCardBatchCollaborators
						alreadyAddedCollaborators={selectedCollaborators}
						onAddedCollaborators={onAddCollaborators}
					/>

					<CardBatchCollabsSelector
						alreadyAddedCollabs={selectedCollaborators}
						onAddNewCollaborators={onAddCollaborators}
					/>

					<FilteredCollabsSelector
						alreadyAddedCollabs={selectedCollaborators}
						maxCollaboratorsAllowed={
							COLLABORATORS_BATCH_LIMIT - selectedCollaborators.length
						}
						onAddNewCollaborators={onAddCollaborators}
					/>
				</div>

				<S.SubmitBtn onClick={handleSubmit}>Enviar solicitação</S.SubmitBtn>
			</S.FooterOptionsContainer>

			<S.ExtensionsInfo>
				<S.InfoIcon />
				Extensões válidas: .xls, .xlsx, .csv.
			</S.ExtensionsInfo>
			<S.ExtensionsInfo>
				<S.InfoIcon />
				<S.ModelLink
					href={`${window.location.origin}/Modelo-Importar-Colaboradores-Cartoes.xlsx`}
					download={'Modelo-Importar-Colaboradores-Cartoes.xlsx'}
				>
					Baixar modelo de planilha de solicitação dos cartões
				</S.ModelLink>
			</S.ExtensionsInfo>

			<NotApprovedCollabsModal
				collabsReproved={disallowedFilteredCollabs}
				title='Colaboradores não permitidos'
			/>

			{createCardsBatchQuery.isLoading && <ModalLoader loading={true} />}
		</S.Container>
	);
}
