import { useState } from 'react';
import ImportFile from './ImportFile';
import {
	Benefit,
	Collaborator as CollaboratorType,
	LineError,
} from '../../../../@types';
import { toast } from 'react-toastify';
import Modal from '../../../../components/Modal';
import { cpf as cpfValidator } from 'cpf-cnpj-validator';
import { LineErrorsCollaboratorsImported } from '../../../../components/LineErrorsPaymentTable';
import { FileParser } from '../../../../utils/FileParser';
import { useQuery } from 'react-query';
import { getCollaboratorsFilteredByCPFs } from '../../../../services/queries/Collaborators';
import { useAuth } from '../../../../hooks/useAuth';
import ModalLoader from '../../../../components/ModalLoader';
import { getBenefits } from '../../../../services/queries/Benefits';
import { getBenefitParsedTitle } from '../../../../utils/benefits/getBenefitParsedTitle';
import { useHistory } from 'react-router-dom';
import { showErrorMessage } from '../../../../utils/ErrorHandler';

interface Collaborator extends CollaboratorType {
	benefits: Benefit[];
	line: number;
}

/*
	ImportPayment STEPS:
		1. Ao upar um arquivo onFileUpload() é chamado;
		2. O loading inicia, e é chamado fetchBenefitsQuery pra pegar os benefícios da empresa;
		3. O parse do arquivo é feito no parseToReleaseFormat() e ao fim seta o estado importedCollaborators;
		4. Quando importedCollaborators é setado, dispara a query getCollaboratorsFilteredByCPFs que pega
			os colaboradores pelo CPFs deles;
		5. Com a finalização da getCollaboratorsFilteredByCPFs, o processo está concluído e pode enviar os colaboradores
			para a tela de RelaunchPayment
*/
export default function ImportPayment() {
	const history = useHistory();
	const { currentCompany } = useAuth();
	const [importedCollaborators, setImportedCollaborators] = useState<
		Collaborator[]
	>([]);
	const [isLoading, setIsLoading] = useState(false);
	const [errors, setErrors] = useState<LineError[]>([]);

	const fetchBenefitsQuery = useQuery(
		['benefits', currentCompany?.id],
		() => {
			return getBenefits(currentCompany?.id);
		},
		{
			enabled: false,
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível buscar os benefícios. '
				);
			},
		}
	);

	const collaboratorsCPFs = importedCollaborators.map((c) => c.cpf);
	useQuery(
		['cpfsCollabsList', collaboratorsCPFs, currentCompany?.id],
		() => getCollaboratorsFilteredByCPFs(collaboratorsCPFs, currentCompany!.id),
		{
			enabled: importedCollaborators.length > 0,
			onSettled: () => {
				setIsLoading(false);
			},
			onSuccess: (data) => {
				let collaboratorsNotFoundErrors: LineError[] = [];
				importedCollaborators.forEach((importedCollaborator) => {
					// if not found include to errors array
					if (
						!data.collaborators.find((c) => c.cpf === importedCollaborator.cpf)
					) {
						collaboratorsNotFoundErrors.push({
							collaborator: importedCollaborator,
							error:
								'Colaborador importado não foi encontrado nos colaboradores ativos da empresa',
							line: importedCollaborator.line,
						});
					}
				});

				const parsedCollaborators = data.collaborators.map((c) => {
					let currentCollaboratorBenefits = importedCollaborators.find(
						(collaborator) => collaborator.cpf === c.cpf
					)!.benefits;

					return {
						id: c.id,
						name: `${c.first_name} ${c.last_name}`,
						cpf: c.cpf,
						benefits: currentCollaboratorBenefits,
					} as Collaborator;
				});

				if (parsedCollaborators.length > 0) {
					history.push('/home/payments/relaunch', {
						collaborators: parsedCollaborators,
						errors: [...errors, ...collaboratorsNotFoundErrors],
					});
				} else {
					setErrors([...errors, ...collaboratorsNotFoundErrors]);
				}
			},
			onError: (err) => {
				toast.error(
					'Ocorreu um problema ao buscar informações dos colaboradores importados.'
				);
			},
			refetchOnWindowFocus: false,
			refetchOnReconnect: false,
		}
	);

	function getCompanyBenefitFromTitle(title: string) {
		return fetchBenefitsQuery.data?.find(
			(benefit) =>
				getBenefitParsedTitle(benefit.title).toLowerCase() ===
				getBenefitParsedTitle(title).toLowerCase()
		);
	}

	async function onFileUpload(file: File) {
		setIsLoading(true);
		try {
			const fileParser = new FileParser(file);

			const result = await fetchBenefitsQuery.refetch();
			if (!result.data) {
				setIsLoading(false);
				return;
			}

			// FileParser parses the file to an array of array of possible values filled in, each array is a line from the
			// file, each line is an array of values (like strings, numbers, dates etc).
			// Then parseToReleaseFormat is called to parse the convertedToArray obj into Release format
			fileParser.parseFile(parseToReleaseFormat, true);
		} catch (err) {
			setIsLoading(false);
			toast.error('Ocorreu um erro ao tentar ler o arquivo. Tente novamente');
		}
	}

	// This function is called from FileParser passing the convertedFile which is an array of arrays of the possible values
	// filled in, each array is a line from the file, each line is an array of values (like strings, numbers, dates etc)
	function parseToReleaseFormat(convertedFile: any[][]) {
		let collaboratorsArray: Collaborator[] = [];

		// FIRST ELEMENT FROM ARRAY IS THE FIELDS ARRAY (CPF, NOME, ... ALIMENTAÇÃO, ...)
		let fields: string[] = convertedFile[0];

		const correctFormat = checkFileFields(fields);

		if (!correctFormat) {
			setIsLoading(false);
			return;
		}

		for (let index = 0; index < convertedFile.length; index++) {
			if (index === 0)
				// Ignore fields
				continue;

			// collaboratorsArray is the collab row with its values ("000.000.000-11", "José da Silva", ... )
			if (convertedFile[index].length) {
				try {
					collaboratorsArray.push(
						parseCollaborator(fields, convertedFile[index], index + 1)
					);
					let collaboratorsWithoutLast = collaboratorsArray.slice(
						0,
						collaboratorsArray.length - 1
					);
					let lastCollaborator =
						collaboratorsArray[collaboratorsArray.length - 1];

					if (!validateCollaborator(lastCollaborator, index + 1)) {
						collaboratorsArray.pop();
					} else if (
						!checkUniqueCollaborator(
							collaboratorsWithoutLast,
							lastCollaborator,
							index + 1
						)
					) {
						collaboratorsArray.pop();
					}
				} catch (err) {
					console.log(err, 'linha: ', index + 1);
				}
			}
		}

		setImportedCollaborators(collaboratorsArray);
	}

	// get the last added collaborator and compare with the ones already added if they are the repeated
	function checkUniqueCollaborator(
		collaborators: Collaborator[],
		lastCollaborator: Collaborator,
		collabRow: number
	) {
		let error = false;

		if (
			collaborators.find(
				(collaborator) => collaborator.cpf === lastCollaborator.cpf
			)
		) {
			setErrors((oldErrors) => [
				...oldErrors,
				{
					error: `CPF ${lastCollaborator.cpf} duplicado.`,
					line: collabRow,
					collaborator: lastCollaborator,
				},
			]);
			error = true;
		}

		if (error) return false;

		return true;
	}

	function validateCollaborator(collaborator: Collaborator, collabRow: number) {
		const collabCPF = collaborator.cpf;
		let error = false;

		if (!cpfValidator.isValid(collabCPF)) {
			error = true;
			setErrors((oldErrors) => [
				...oldErrors,
				{
					error: `Formato de CPF inválido.`,
					line: collabRow,
					collaborator: collaborator,
				},
			]);
		}

		if (error) return false;

		return true;
	}

	function checkFileFields(fields: string[]) {
		// 3 fields includes all collaborator's data and at least one benefit collumn
		if (fields.length < 3) {
			toast.error('Arquivo não está conforme o modelo aceito. Faltam campos.');
			return false;
		}

		const EXPECTED_FIELDS = ['CPF', 'NOME'];

		const collabFields = fields.slice(0, 2);
		const benefitsFields = fields.slice(2, fields.length);

		for (let index = 0; index < collabFields.length; index++) {
			if (
				!(collabFields[index].trim().toUpperCase() === EXPECTED_FIELDS[index])
			) {
				toast.error('Arquivo não está conforme o modelo aceito.');
				return false;
			}
		}

		let includedBenefits: string[] = [];
		for (let index = 0; index < benefitsFields.length; index++) {
			if (benefitsFields[index].trim() === '') {
				toast.error(
					'Arquivo não está conforme o modelo aceito. Campo de benefício nomeado de forma inválida.'
				);
				return false;
			}
			if (typeof benefitsFields[index] === 'number') {
				toast.error(
					'Arquivo não está conforme o modelo aceito. Campo de benefício nomeado de forma inválida.'
				);
				return false;
			}
			if (includedBenefits.includes(benefitsFields[index])) {
				toast.error(
					`Coluna com benefício ${benefitsFields[index]} repetida no pagamento. Inclua uma coluna por benefício.`
				);
				return false;
			}
			const benefitNotFoundInCompaniesList = !getCompanyBenefitFromTitle(
				benefitsFields[index]
			);
			if (benefitNotFoundInCompaniesList) {
				toast.error(
					`Benefício ${benefitsFields[index]} não faz parte dos benefícios disponíveis para sua empresa.`
				);
				return false;
			}

			// Passed all verifications
			includedBenefits.push(benefitsFields[index]);
		}

		return true;
	}

	// Check if fields data are filled in
	function checkRequiredFieldsData(collaboratorData: any[], collabRow: number) {
		// data fields CPF and NAME
		const requiredFieldsIndex = [0, 1];

		if (typeof collaboratorData[0] !== 'string') {
			setErrors((oldErrors) => [
				...oldErrors,
				{
					error: "CPF precisa estar entre áspas. Ex: '01234567890'",
					line: collabRow,
					collaborator: {
						cpf: collaboratorData[0],
						name: collaboratorData[1],
					} as Collaborator,
				},
			]);
			throw new Error('Invalid CPF Format');
		}

		const isEmptyRow = collaboratorData.every((col) => col.trim() === '');
		if (isEmptyRow) throw new Error('Empty row');

		requiredFieldsIndex.forEach((index) => {
			if (collaboratorData[index].toString().length === 0) {
				setErrors((oldErrors) => [
					...oldErrors,
					{
						error: 'Um ou mais campos obrigatórios em branco.',
						line: collabRow,
						collaborator: {
							cpf: collaboratorData[0],
							name: collaboratorData[1],
						} as Collaborator,
					},
				]);
				throw new Error('Required field blank');
			}
		});
	}

	// parse collaborator row (array of values) to Collaborator object
	function parseCollaborator(
		fields: Array<string>,
		collaboratorDataArray: Array<any>,
		collabRow: number
	) {
		let collaborator: Collaborator = {} as Collaborator;
		let collaboratorBenefits: Benefit[] = [];

		checkRequiredFieldsData(collaboratorDataArray, collabRow); // Check if fields data are filled in

		collaborator.cpf = cpfValidator
			.format(collaboratorDataArray[0])
			.replaceAll('.', '')
			.replaceAll('-', '')
			.toString();
		collaborator.name = collaboratorDataArray[1].toString();
		collaborator.id = collaborator.cpf;

		// START FROM INDEX 2 (WHERE THE BENEFITS LIST BEGINS)
		for (let index = 2; index < fields.length; index++) {
			if (fields[index] === '') {
				continue;
			}

			let value = Number(
				collaboratorDataArray[index].toString().replace(',', '.')
			);

			if (value < 0 || isNaN(value)) {
				setErrors((oldErrors) => [
					...oldErrors,
					{
						error: 'Benefício com valor inválido.',
						line: collabRow,
						collaborator: collaborator,
					},
				]);
				throw new Error('Invalid Benefit Value');
			}

			collaboratorBenefits.push({
				id: getCompanyBenefitFromTitle(fields[index])!.id,
				title: fields[index],
				value,
			});
		}
		collaborator.benefits = collaboratorBenefits;
		return { ...collaborator, line: collabRow };
	}

	return (
		<>
			<ImportFile onFileUpload={onFileUpload} />
			<ModalLoader
				progressText={isLoading ? 'Importando pagamento' : undefined}
				loading={isLoading}
			/>

			<Modal
				isOpen={errors.length > 0}
				enableClose
				onRequestClose={() => {
					setErrors([]);
				}}
			>
				<LineErrorsCollaboratorsImported errors={errors} includeEmail />
			</Modal>
		</>
	);
}
