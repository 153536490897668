import { Text, View } from '@react-pdf/renderer';

interface StatusColor {
	background: string;
	text: string;
	dot: string;
}

const statusColors: { [key: string]: StatusColor } = {
	requested: { background: '#FFF6C7', text: '#dba307', dot: '#FEBE14' },
	approved: { background: '#defdea', text: '#037847', dot: '#14BA6D' },
	disapproved: { background: '#ffd3d3', text: '#b30000', dot: '#fd2929' },
	canceled: { background: '#ffecd9', text: '#e25822', dot: '#575757' },
	scheduled: { background: '#c1c2c4', text: '#ffffff', dot: '#656C80' },
	processing: { background: '#D4EFFF', text: '#27ADFF', dot: '#27ADFF' },
	'finished error': { background: '#ffcccc', text: '#b30000', dot: '#fd2929' },
	'chargeback-processing': {
		background: '#f2f2f2',
		text: '#666666',
		dot: '#666666',
	},
	'chargeback-partial': {
		background: '#ffd6cc',
		text: '#e25822',
		dot: '#FF5900',
	},
	'chargeback-success': {
		background: '#defdea',
		text: '#037847',
		dot: '#14BA6D',
	},
	'chargeback-error': {
		background: '#ffcccc',
		text: '#b30000',
		dot: '#fd2929',
	},
};

export function parseReleaseStatusToReport(status: string) {
	const statusText = {
		requested: 'Pendente',
		approved: 'Aprovado',
		disapproved: 'Reprovado',
		canceled: 'Cancelado',
		scheduled: 'Agendado',
		processing: 'Processando',
		'finished error': 'Finalizado com erro',
		'chargeback-processing': 'Processando estorno',
		'chargeback-partial': 'Estornado parcialmente',
		'chargeback-success': 'Estornado',
		'chargeback-error': 'Estornado com erro',
	};

	const statusColor = statusColors[status] || statusColors.default;

	return (
		<View
			style={{
				display: 'flex',
				flexDirection: 'row',
				columnGap: 1,
				alignItems: 'center',
				paddingVertical: '1',
				paddingHorizontal: '3',
				borderRadius: '50px',
				backgroundColor: statusColor.background,
				color: statusColor.text,
			}}
		>
			<View
				style={{
					width: '6px',
					height: '6px',
					borderRadius: '50%',
					marginRight: '3px',
					backgroundColor: statusColor.dot,
				}}
			/>
			<Text
				style={{
					fontFamily: 'Poppins',
					fontSize: 9,
				}}
			>
				{statusText[status as keyof typeof statusText]}
			</Text>
		</View>
	);
}
