import { LineError } from '../../@types';
import { cpfMask } from '../../utils/masks';
import * as S from './styles';

interface LineErrorsPaymentTableProps {
	errors: LineError[];
	includeEmail: boolean;
}

export function LineErrorsCollaboratorsImported({
	errors,
	includeEmail,
}: LineErrorsPaymentTableProps) {
	return (
		<S.Container>
			<S.Title>Erros encontrados</S.Title>

			<S.Table>
				<thead>
					<S.Row>
						<S.Head>Linha</S.Head>
						<S.Head>Erro</S.Head>
						<S.Head>CPF</S.Head>
						<S.Head>NOME</S.Head>
						{includeEmail && <S.Head>EMAIL</S.Head>}
					</S.Row>
				</thead>

				<S.TableBody>
					{errors.map((lineError) => (
						<S.Row key={lineError.line + lineError.error}>
							<S.Data style={{ textAlign: 'center' }}>{lineError.line}</S.Data>
							<S.ErrorData>{lineError.error}</S.ErrorData>
							<S.Data>{cpfMask(lineError.collaborator.cpf)}</S.Data>
							<S.Data>{lineError.collaborator.name}</S.Data>
							{includeEmail && <S.Data>{lineError.collaborator.email}</S.Data>}
						</S.Row>
					))}
				</S.TableBody>
			</S.Table>
		</S.Container>
	);
}
