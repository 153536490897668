import { useState, useEffect } from 'react';
import { usePayment } from '../../hooks/usePaymentV2';
import { cpfMask } from '../../utils/masks';
import { MONTHS } from '../../utils/MONTHS';
import Modal from '../Modal';
import * as S from './styles';

export type Collaborator = {
	id: string;
	name: string;
	cpf: string;
};
export interface Props {
	collaborators: Collaborator[] | undefined;
	submitOption: () => Promise<void>;
}

export function CollaboratorsWithRecentPaymentsModal({
	collaborators,
	submitOption,
}: Props) {
	const [isOpen, setIsOpen] = useState(false);
	const { schedule } = usePayment();

	const paymentMonth = !!schedule
		? MONTHS[new Date(schedule).getMonth()]
		: MONTHS[new Date().getMonth()];
	const paymentYear = !!schedule
		? new Date(schedule).getFullYear()
		: new Date().getFullYear();

	useEffect(() => {
		if (collaborators && collaborators.length > 0) setIsOpen(true);
	}, [collaborators]);

	function closeModal() {
		setIsOpen(false);
	}

	function onSubmit() {
		submitOption();
		closeModal();
	}

	return (
		<Modal isOpen={isOpen} enableClose onRequestClose={closeModal}>
			<S.Container data-testid='CollaboratorsWithRecentPaymentsModal-container'>
				<S.Title>
					{`Colaboradores selecionados com pagamentos agendados ou recebidos no mês de ${paymentMonth} de ${paymentYear}`}
				</S.Title>
				<S.TableContainer>
					<S.Table>
						<thead>
							<S.Row>
								<S.Head>CPF</S.Head>
								<S.Head>NOME</S.Head>
							</S.Row>
						</thead>
						<S.TableBody>
							{collaborators?.map((collaborator) => (
								<S.Row key={collaborator.id}>
									<S.Data>{cpfMask(collaborator.cpf)}</S.Data>
									<S.Data>{collaborator.name}</S.Data>
								</S.Row>
							))}
						</S.TableBody>
					</S.Table>
				</S.TableContainer>

				<S.Information>
					Os colaboradores acima já possuem pagamentos agendados ou recebidos no
					mês de {paymentMonth} de {paymentYear}. Deseja continuar a operação
					mesmo assim?
				</S.Information>

				<S.OptionsContainer>
					<S.CancelOption
						onClick={closeModal}
						data-testid='CollaboratorsWithRecentPaymentsModal-cancel-btn'
					>
						Cancelar
					</S.CancelOption>
					<S.MainOption onClick={onSubmit}>Continuar</S.MainOption>
				</S.OptionsContainer>
			</S.Container>
		</Modal>
	);
}
