import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { Collaborator } from '../../../../../@types';
import { Filter, FilterParams } from '../../../../../components/Filter';
import Modal from '../../../../../components/Modal';
import { getMessageError } from '../../../../../utils/DefaultErrors';
import avatarImg from '../../../../../assets/avatar.svg';
import * as S from './styles';
import * as TableStyle from '../../../../../components/Table/TableStyles';
import { RiAddCircleLine, RiCloseCircleLine } from 'react-icons/ri';
import Loader from '../../../../../components/Loader';
import PageTitle from '../../../../../components/PageTitle';
import { Pagination } from '../../../../../components/Pagination';
import {
	FilterCollabsReturn,
	getActiveCollaborators,
	getFilteredCollabs,
} from '../../../../../services/queries/Collaborators';
import { useAuth } from '../../../../../hooks/useAuth';
import { cpfMask } from '../../../../../utils/masks';
import { ContractTypeOptionsFilters } from '../../../../../components/Filter/utils/SelectFilters';

export interface CollaboratorSelectorProps {
	onSelectCollaborator(collab: Collaborator): void;
	chatsOpened: string[];
}

export function CollaboratorSelectorModal({
	onSelectCollaborator,
	chatsOpened,
}: CollaboratorSelectorProps) {
	const { currentCompany } = useAuth();
	const [isOpen, setIsOpen] = useState(false);
	const listContainer = useRef<HTMLUListElement | null>(null);

	const [collaboratorsCurrentPage, setCollaboratorsCurrentPage] = useState(1);

	const [collabsFiltersParams, setcollabsFiltersParams] = useState<
		FilterParams[]
	>([
		{
			name: 'Nome',
			filter: 'name',
			value: '',
			type: 'text',
			selected: false,
		},
		{
			name: 'Nome da mãe',
			filter: 'mother_name',
			value: '',
			type: 'text',
			selected: false,
		},
		{ name: 'CPF', filter: 'cpf', value: '', type: 'cpf', selected: false },
		{
			name: 'Email',
			filter: 'email',
			value: '',
			type: 'email',
			selected: false,
		},
		{
			name: 'Cargo',
			filter: 'office',
			value: '',
			type: 'text',
			selected: false,
		},
		{
			name: 'Setor de atuação',
			filter: 'section',
			value: '',
			type: 'text',
			selected: false,
		},
		{ name: 'UF', filter: 'uf', value: '', type: 'uf', selected: false },
		{
			name: 'Contrato',
			filter: 'contract_type',
			value: '',
			type: 'select',
			selectOptions: ContractTypeOptionsFilters,
			selected: false,
		},
		{
			name: 'Centro de custo',
			filter: 'cost_center',
			value: '',
			type: 'cost_center',
			selected: false,
		},
	]);

	const collabsQuery = useQuery(
		['activeCollabsList', currentCompany?.id, collaboratorsCurrentPage],
		() => {
			return getActiveCollaborators(
				collaboratorsCurrentPage,
				currentCompany?.id
			);
		},
		{
			onError: (err) => {
				if (axios.isAxiosError(err)) {
					let errorMessage =
						'Não foi possível buscar os colaboradores. ' +
						getMessageError(err.response?.data);
					toast.error(errorMessage);
				} else {
					toast.error('Ocorreu algum problema. Tente novamente');
				}
			},
			enabled: isOpen,
			keepPreviousData: true,
		}
	);

	const filteredCollabs = useQuery<FilterCollabsReturn, Error>(
		[
			'filteredCollabsQuery',
			collabsFiltersParams,
			true,
			currentCompany?.id,
			collaboratorsCurrentPage,
		],
		() => {
			return getFilteredCollabs(
				collabsFiltersParams,
				true,
				currentCompany?.id,
				collaboratorsCurrentPage
			);
		},
		{
			onError: (error) => {
				if (axios.isAxiosError(error)) {
					// Handle usual errors
					let errorMessage =
						'Não foi possível buscar os colaboradores. ' +
						getMessageError(error.response?.data);
					toast.error(errorMessage);
				} else {
					toast.error('Ocorreu algum problema. Tente novamente');
				}
			},
			enabled:
				collabsFiltersParams.some((f) => !!f.value) &&
				!!collaboratorsCurrentPage,
		}
	);

	function getTableTotalRecords() {
		if (collabsFiltersParams.find((f) => !!f.value)) {
			return filteredCollabs.data?.totalCount;
		}
		return collabsQuery.data?.totalActive;
	}

	useEffect(() => {
		listContainer.current?.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
	}, [collaboratorsCurrentPage, listContainer]);

	function handleSelectCollaborator(collab: Collaborator) {
		onSelectCollaborator(collab);
		setIsOpen(false);
	}

	function checkCollabAlreadyInChat(collab_id: string) {
		return chatsOpened.includes(collab_id);
	}

	function updateFilters(filters: FilterParams[]) {
		setCollaboratorsCurrentPage(1);
		setcollabsFiltersParams(filters);
	}

	function getCollaboratorsList() {
		return !!collabsFiltersParams.find((f) => !!f.value)
			? filteredCollabs.data?.collaborators
			: collabsQuery.data?.collaboratorStatusActive;
	}

	if (
		collabsQuery.isLoading ||
		filteredCollabs.isLoading ||
		collabsQuery.isPreviousData ||
		filteredCollabs.isPreviousData
	) {
		return (
			<>
				<S.MainButton onClick={() => setIsOpen(true)}>
					{'Iniciar novo chat'}
				</S.MainButton>
				<Modal
					isOpen={isOpen}
					onRequestClose={() => setIsOpen(false)}
					enableClose={true}
				>
					<S.Container>
						<Loader />
					</S.Container>
				</Modal>
			</>
		);
	}
	return (
		<>
			<S.MainButton onClick={() => setIsOpen(true)}>
				{'Iniciar novo chat'}
			</S.MainButton>

			<Modal
				isOpen={isOpen}
				onRequestClose={() => setIsOpen(false)}
				enableClose={true}
			>
				<S.Container>
					<TableStyle.TableHeaderContainer>
						<PageTitle
							title='Selecione um colaborador para iniciar chat'
							totalRecords={getTableTotalRecords() ?? 0}
						/>
						<Filter
							filterParams={collabsFiltersParams}
							onFiltersChanged={(updatedFilters) => {
								updateFilters(updatedFilters);
							}}
						/>
						{collabsFiltersParams.some((f) => !!f.value) ? (
							<Pagination
								onPageChange={(page) => setCollaboratorsCurrentPage(page)}
								currentPage={collaboratorsCurrentPage}
								totalCount={
									filteredCollabs.data ? filteredCollabs.data.totalCount! : 1
								}
							/>
						) : (
							<Pagination
								onPageChange={(page) => setCollaboratorsCurrentPage(page)}
								currentPage={collaboratorsCurrentPage}
								totalCount={
									collabsQuery.data ? collabsQuery.data.totalActive! : 1
								}
							/>
						)}
					</TableStyle.TableHeaderContainer>

					<S.CollabsList>
						{getCollaboratorsList()?.map((collab) => (
							<S.CollabContainer key={collab.id}>
								<S.CollabCard>
									<S.CollabAvatar
										src={collab.avatar ? collab.avatar_url : avatarImg}
									/>

									<S.CollabInfoContainer>
										<S.CollabName>{`${collab.first_name} ${collab.last_name}`}</S.CollabName>
										<S.CollabInfo>{collab.office}</S.CollabInfo>
										<S.CollabInfo>{cpfMask(collab.cpf)}</S.CollabInfo>
										<S.CollabInfo>{collab.email}</S.CollabInfo>
									</S.CollabInfoContainer>
								</S.CollabCard>

								{checkCollabAlreadyInChat(collab.id!) ? (
									<S.Selector
										selected={true}
										disabled
										data-rh={'Colaborador já está com um chat aberto.'}
									>
										<RiCloseCircleLine />
									</S.Selector>
								) : (
									<S.Selector
										selected={false}
										onClick={() => handleSelectCollaborator(collab)}
										data-testid={`${collab.id}-select`}
									>
										<RiAddCircleLine />
									</S.Selector>
								)}
							</S.CollabContainer>
						))}
					</S.CollabsList>
				</S.Container>
			</Modal>
		</>
	);
}
