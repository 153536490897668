import { useState } from 'react';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { InputField } from '../../../componentsV2/ui/Form/InputField';
import { SectionContainer } from '../../../componentsV2/ui/SectionContainer';
import { Typography } from '../../../componentsV2/ui/Typography';
import { useAuth } from '../../../hooks/useAuth';
import { updateProfileAvatar } from '../../../services/queries/Corpway/User';
import { showErrorMessage } from '../../../utils/ErrorHandler';
import { parseUserAccessLevel } from '../../../utils/corpway/parseUserAccessLevel';
import { Notifications } from './Notifications';
import { UpdatePassword } from './UpdatePassword';
import { Avatar } from './components/Avatar';
import * as S from './styles';

export function Profile() {
	const { user, updateUserInfo } = useAuth();
	const [activeTab, setActiveTab] = useState<'profile' | 'notifications'>(
		'profile'
	);
	const defaultActiveNotificationsTab = (() => {
		if (user.roles.includes('manage-users')) return 'user';
		if (user.roles.includes('manage-balances')) return 'finances';
		return 'transactions';
	})();
	const [activeNotificationsTab, setActiveNotificationsTab] = useState<
		'user' | 'finances' | 'transactions'
	>(defaultActiveNotificationsTab);

	const updateAvatarMutation = useMutation(
		(data: FormData) => updateProfileAvatar(data),
		{
			onSuccess: (url) => {
				updateUserInfo({ ...user, avatar_url: url });
				toast.info('Imagem de perfil atualizada com sucesso!');
			},
			onError: (err) => {
				showErrorMessage(err as Error, 'Não foi atualizar a imagem do perfil.');
			},
		}
	);

	function handleUpdateAvatar(file: File) {
		const data = new FormData();
		data.append('avatar', file);
		updateAvatarMutation.mutate(data);
	}

	const NotificationTabs = () => {
		return (
			<S.NotificationTabs>
				{user.roles.includes('manage-users') && (
					<S.SubTabLink
						onClick={() => setActiveNotificationsTab('user')}
						active={activeNotificationsTab === 'user'}
					>
						Usuário
					</S.SubTabLink>
				)}
				{user.roles.includes('manage-balances') && (
					<S.SubTabLink
						onClick={() => setActiveNotificationsTab('finances')}
						active={activeNotificationsTab === 'finances'}
					>
						Financeiro
					</S.SubTabLink>
				)}
				<S.SubTabLink
					onClick={() => setActiveNotificationsTab('transactions')}
					active={activeNotificationsTab === 'transactions'}
				>
					Transações
				</S.SubTabLink>
			</S.NotificationTabs>
		);
	};

	return (
		<SectionContainer style={{ padding: 0, height: '100%' }}>
			<S.HeaderImage />
			<S.Content>
				<S.LeftNavigator>
					<S.TabLinkContainer>
						<S.TabLink
							active={activeTab === 'profile'}
							onClick={() => setActiveTab('profile')}
						>
							Dados pessoais
						</S.TabLink>

						<S.TabLink
							active={activeTab === 'notifications'}
							onClick={() => setActiveTab('notifications')}
						>
							Notificações
						</S.TabLink>
					</S.TabLinkContainer>

					{activeTab === 'notifications' && <NotificationTabs />}
				</S.LeftNavigator>

				<S.UserFormContainer>
					<S.MainContent>
						<S.UserInfo>
							<Avatar image={user.avatar_url}>
								<InputField
									type='file'
									onChange={(e) => {
										e.target.files && handleUpdateAvatar(e.target.files[0]);
										e.target.value = '';
									}}
									multiple={false}
									accept='.jpg, .jpeg, .png'
									style={{ display: 'none' }}
								/>
							</Avatar>

							<Typography
								color='var(--primary-blue)'
								size='2.3rem'
								weight='600'
							>
								{user.name}
							</Typography>

							<S.RoleContainer>
								<Typography color={'#575757'} size={'1.6rem'} weight={'600'}>
									Acesso: {parseUserAccessLevel(user.corpway_access_level)}
								</Typography>
							</S.RoleContainer>
							{activeTab === 'notifications' && (
								<S.MobileContainer>
									{' '}
									<NotificationTabs />
								</S.MobileContainer>
							)}
						</S.UserInfo>

						{activeTab === 'profile' && (
							<>
								<UpdatePassword />{' '}
								<S.MobileContainer>
									<S.TabLink
										active={false}
										onClick={() => setActiveTab('notifications')}
									>
										Notificações
									</S.TabLink>{' '}
								</S.MobileContainer>
							</>
						)}
						{activeTab === 'notifications' && (
							<>
								{' '}
								<Notifications activeTab={activeNotificationsTab} />
								<S.MobileContainer>
									<S.TabLink
										active={false}
										onClick={() => setActiveTab('profile')}
									>
										Dados pessoais
									</S.TabLink>
								</S.MobileContainer>
							</>
						)}
					</S.MainContent>
				</S.UserFormContainer>
			</S.Content>
		</SectionContainer>
	);
}
