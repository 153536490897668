import { useState } from 'react';
import { toast } from 'react-toastify';
import { Group } from '../../@types';
import Loader from '../Loader';
import avatarImg from '../../assets/avatar.svg';
import Modal from '../Modal';
import * as S from './styles';
import { EmptyContent } from '../EmptyContent';
import { useAuth } from '../../hooks/useAuth';
import { useQuery } from 'react-query';
import { fetchGroup } from '../../services/queries/Groups';

interface CheckGroupCollaboratorsProps {
	groupId: string;
}

export function CheckGroupCollaborators({
	groupId,
}: CheckGroupCollaboratorsProps) {
	const { currentCompany } = useAuth();
	const [isOpen, setIsOpen] = useState(false);

	const fetchGroupQuery = useQuery<Group, Error>(
		['fetchGroup', currentCompany?.id, groupId],
		() => {
			return fetchGroup(groupId, currentCompany?.id);
		},
		{
			onError: () => {
				toast.error(
					'Ocorreu um problema ao tentar buscar os colaboradores do grupo. Tente novamente'
				);
			},
		}
	);

	if (fetchGroupQuery.isLoading || !fetchGroupQuery.data) {
		return (
			<>
				<S.CheckCollabsBtn onClick={() => setIsOpen(true)}>
					Ver colaboradores
				</S.CheckCollabsBtn>
				<Modal
					isOpen={isOpen}
					enableClose
					onRequestClose={() => setIsOpen(false)}
				>
					<S.Container data-testid='CheckGroupCollabs-modal-container'>
						<Loader />
					</S.Container>
				</Modal>
			</>
		);
	}
	return (
		<>
			<S.CheckCollabsBtn onClick={() => setIsOpen(true)}>
				Ver colaboradores
			</S.CheckCollabsBtn>

			<Modal
				isOpen={isOpen}
				enableClose
				onRequestClose={() => setIsOpen(false)}
			>
				<S.Container data-testid='CheckGroupCollabs-modal-container'>
					<S.CollaboratorsList>
						{!fetchGroupQuery.data.total_users ? (
							<EmptyContent text='Nenhum colaborador no grupo' />
						) : (
							fetchGroupQuery.data.users.map((c) => (
								<S.CollaboratorCard key={c.id}>
									<S.CollaboratorAvatarContainer>
										<S.CollaboratorAvatar
											src={c.avatar ? c.avatar_url : avatarImg}
										/>
										<S.CollaboratorName>{`${c.first_name} ${c.last_name}`}</S.CollaboratorName>
									</S.CollaboratorAvatarContainer>
									<S.CollaboratorOffice>{c.office}</S.CollaboratorOffice>
								</S.CollaboratorCard>
							))
						)}
					</S.CollaboratorsList>
				</S.Container>
			</Modal>
		</>
	);
}
