import styled from 'styled-components';

export const OpenButtonContainer = styled.button`
	display: flex;
	align-items: center;
	width: 100%;
	background: none;
	margin: 0.8rem 0;
	padding: 0.5rem;
	border-radius: 0.8rem;
	svg {
		color: #575757;
		height: 2rem;
		width: 2rem;
	}
`;

export const DropdownIndicatorContainer = styled.div`
	svg {
		color: #575757;
		height: 1rem;
		width: 1rem;
		margin-left: 0.5rem;
	}
`;

export const Title = styled.span`
	color: var(--dark-gray);
	font-size: 1.6rem;
	margin-left: 1.5rem;
`;

export const SubMenuItemsContainer = styled.div`
	margin-left: 1.5rem;
`;
