import styled from 'styled-components';
import CardLeft from '../../../../../assets/card-create.svg';
import CardLeftMobile from '../../../../../assets/card-create-mobile.svg';

export const GraySection = styled.div`
	display: flex;
	flex-direction: row;
	gap: 2rem;
	background-color: #f0f3f8;
	border-radius: 1.6rem;
	width: 100%;
	height: 100%;

	@media (max-width: 1024px) {
    	width: 100%;
  }
`;

export const CardImageContainer = styled.div`
	display: flex;
	width: 32rem;
	height: 100%;
	flex-direction: row;
	border-radius: 2rem;
	@media (max-width: 1024px) {
    	width: 100%;
		flex-direction: column;

	}
`;

interface CardImageProps {
	isMobile: boolean;

}

export const CardImageComponent = styled.img.attrs<CardImageProps>(props => ({
	src: props.isMobile ? CardLeftMobile : CardLeft,
}))<CardImageProps>`
	display: flex;
	flex-direction: row;

	width: 37rem;
	min-height: 51rem;
	object-fit: cover;
	border-radius: 2rem;

	@media (max-width: 1024px) {
		width: 100%;
		min-height: 22rem;
	}
`;

export const SwitchContainer = styled.div`
	display: flex;
	flex-direction: row;
	gap: 2rem;
	width: 100%;
	align-items: center;
`;

export const Column = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	gap: 1rem;
`;

export const Row = styled.div`
	display: flex;
	flex-direction: row;
	width: 50%;
	@media (max-width: 1024px) {
		flex-direction: column;
		width: 100%;
	}
`;

export const InputContainer = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	gap: 4rem;
	@media (max-width: 1024px) {
		gap: 1rem;
		flex-direction: column;
	}
`;

export const InputContainer2 = styled.div`
	position: relative;
	display: flex;
	flex-direction: row;
	width: 100%;
	gap: 2rem;
	@media (max-width: 1024px) {
		align-items: center;
		width: 100%;
		padding-left: 3rem;
		gap: 0;
	}
`;

export const Description = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	gap: 2rem;
	margin-top: 2.5rem;

	@media (max-width: 1024px) {
		width: 100%;
		margin-top: 0rem;

		p {
			font-size: 1.3rem;
		}
	}
`;

export const EditCardSection = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	gap: 4rem;

	@media (max-width: 1024px) {
		flex-direction: column;
		gap: 0rem;
	}
`;
