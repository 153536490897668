import { useEffect, useRef, ReactText } from 'react';
import ReactModal from 'react-modal';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import Loader from '../Loader';

interface ModalLoaderProps {
	loading: boolean;
	progressText?: string;
}

export default function ModalLoader({
	loading,
	progressText,
}: ModalLoaderProps) {
	const toastId = useRef<ReactText | null>(null);

	const dismissToast = () => {
		setTimeout(() => {
			toast.dismiss(String(toastId.current));
			toastId.current = null;
		}, 500);
	};

	useEffect(() => {
		if (progressText && !toastId.current && loading) {
			toastId.current = toast.info(
				() => <ProgressText>{progressText}</ProgressText>,
				{
					autoClose: false,
					draggable: false,
					closeOnClick: false,
					closeButton: false,
					icon: () => <Loader size={20} />,
				}
			);
		} else if (progressText && toastId.current) {
			toast.update(toastId.current, {
				render: () => <ProgressText>{progressText}</ProgressText>,
			});
		} else {
			dismissToast();
		}
	}, [progressText, loading]);

	useEffect(() => {
		return () => {
			dismissToast();
		};
	}, []);

	return (
		<ReactModal
			isOpen={loading}
			style={{
				content: {
					backgroundColor: 'transparent',
					border: 'none',
					width: 'min-content',
					height: 'min-content',
					margin: 'auto',
					marginTop: '20rem',
				},
				overlay: {
					backgroundColor: 'rgba(122, 122, 122, 0.8)',
				},
			}}
			ariaHideApp={false}
		>
			<Loader />
		</ReactModal>
	);
}

const ProgressText = styled.h2`
	user-select: none;
`;
