import { useState } from 'react';
import { Button } from '../../../../../componentsV2/ui/Button';
import Modal from '../../../../../componentsV2/ui/Modal';
import { useQuery } from 'react-query';
import {
	CORPWAY_COST_CENTER_KEY,
	getCostCenterDetails,
} from '../../../../../services/queries/Corpway/CostCenters';
import { showErrorMessage } from '../../../../../utils/ErrorHandler';
import { useAuth } from '../../../../../hooks/useAuth';
import styled from 'styled-components';
import { CostCenter } from '../../../../../@types/CorporateExpenses/CostCenter';
import {
	TitleTypography,
	Typography,
} from '../../../../../componentsV2/ui/Typography';
import { createColumnHelper } from '@tanstack/table-core';
import { Card } from '../../../../../@types/CorporateExpenses/Card';
import { CardNotificationsAndTransactionsModals } from '../../../Cards/components/CardDetailsModal/CardNotificationsAndTransactionsModals';
import { Table } from '../../../../../componentsV2/Table';
import { MdChevronRight, MdNotes } from 'react-icons/md';
import CostCenterImage from '../../../../../assets/cost-center-modal.png';
import { formatCardNumber } from '../../../../../utils/formatCardNumber';

const columnHelper = createColumnHelper<Card>();
const columns = [
	columnHelper.display({
		header: 'Cartão',
		cell: (props) => <div>{formatCardNumber(props.row.original.pan)}</div>,
	}),
	columnHelper.accessor('alias', {
		cell: (info) =>
			info.getValue().charAt(0).toUpperCase() +
			info.getValue().slice(1).toLowerCase(),
		header: 'Apelido',
	}),
	columnHelper.display({
		header: 'Ações',
		meta: {
			align: 'center',
		},
		cell: (props) => (
			<CardNotificationsAndTransactionsModals card={props.row.original} />
		),
	}),
];

export function CostCenterDetailsModal({
	costCenter,
}: {
	costCenter: CostCenter;
}) {
	const { currentCompany } = useAuth();
	const [isOpen, setOpen] = useState(false);
	const [animationModal, setAnimationModal] = useState(false);

	const getCostCenterDetailsQuery = useQuery(
		[CORPWAY_COST_CENTER_KEY, costCenter.id, currentCompany?.id],
		() => getCostCenterDetails(costCenter.id, currentCompany!.id),
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível buscar o centro de custo. '
				);
			},
			enabled: isOpen,
		}
	);

	const costCenterCards =
		getCostCenterDetailsQuery.data?.cards_in_cost_center.map((c) => {
			return { ...c, user_data: { id: '', name: c.user_name } };
		});

	return (
		<div>
			<Button
				style={{
					color: 'var(--primary-blue)',
					border: 'none',
					padding: '0',
				}}
				intent='ghost'
				onClick={() => {
					setOpen(true);
					setAnimationModal(true);
				}}
				data-rh='Detalhes do centro de custo'
			>
				<MdNotes />
			</Button>

			<Modal
				isOpen={isOpen}
				onRequestClose={() => setOpen(false)}
				position='right'
				closeBtnStyles={{ color: '#FFF', zIndex: 2 }}
				animateOnClose={animationModal}
			>
				<Container>
					<HeaderContainer>
						<div>
							<div>
								<Typography color='#FFF' size='1.4rem' weight='400'>
									Centro de custo:
								</Typography>
								<TitleTypography
									size='3.2rem'
									color='#FFFFFF'
									weight='600'
									style={{ fontFamily: 'Poppins' }}
								>
									{costCenter.title.charAt(0).toUpperCase() +
										costCenter.title.slice(1).toLowerCase()}
								</TitleTypography>
							</div>
							<Button
								intent='link'
								roundness='lg'
								as='link'
								to={`/corporate-expenses/management/cost-centers/${costCenter.id}`}
								style={{
									fontWeight: 600,
									color: '#FFF',
									borderColor: '#FFF',
									marginBottom: '1.2rem',
								}}
								onClick={() => setOpen(false)}
								iconRight={<MdChevronRight />}
							>
								Detalhes
							</Button>
						</div>
					</HeaderContainer>

					<MainContent>
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								gap: '1.6rem',
							}}
						>
							<Typography size='1.8rem' weight='600'>
								Descrição:
							</Typography>
							<Typography style={{ textAlign: 'justify' }} size='1.4rem'>
								{costCenter.description}
							</Typography>
						</div>

						<Table
							data={costCenterCards ?? []}
							asyncStatus={getCostCenterDetailsQuery.status}
							columns={columns}
							leftComponent={
								<TitleTypography size='2rem' primaryText='Total'>
									de cartões ativos - {costCenterCards?.length ?? ''}
								</TitleTypography>
							}
						/>
					</MainContent>

					<Button
						width='adjusted'
						roundness='lg'
						style={{ margin: '1rem auto 2rem auto' }}
						as='link'
						to='/corporate-expenses/cards'
					>
						Gerenciar cartões
					</Button>
				</Container>
			</Modal>
		</div>
	);
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	border-radius: 1rem 0 0 1rem;
	background-color: var(--white);
	width: 42rem;
	height: 100vh;

	@media (max-width: 500px) {
		width: 34rem;
	}
`;
const HeaderContainer = styled.div`
	display: flex;
	flex-direction: column;
	background-color: var(--dark-gray2);
	border-radius: 1rem 0 0 0;
	padding: 2rem;
	min-height: 22rem;
	position: relative;
	z-index: 1;
	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: url(${CostCenterImage}) no-repeat center/cover;
		filter: opacity(1);
		z-index: -1;
	}

	& > div {
		display: flex;
		justify-content: space-between;
		column-gap: 2rem;
		align-items: flex-end;
		margin-top: auto;
	}

	@media (max-width: 850px) {
		min-height: 20rem;
	}
`;

// const TableContainer = styled.div`
// 	& table {
// 		background-color: white;
// 		font-size: 1.4rem;
// 	}

// 	& thead {
// 		background-color: white;

// 		& th {
// 			color: var(--dark-gray);
// 			padding: 1rem 0rem;
// 			font-weight: 800;
// 		}
// 	}

// 	td {
// 		padding: 1rem 0rem;
// 		color: var(--dark-gray);
// 	}
// `;

const MainContent = styled.div`
	display: flex;
	flex-direction: column;
	padding: 2rem;
	row-gap: 2rem;
`;
