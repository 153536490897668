import { CompanyPartner } from '../../@types';
import api from '../api';

export interface Quantitative {
	id: string;
	company_id: string;
	partner_id: string;
	registration_date: string;
	total_collaborator: number;
}

export async function getCompanyPartners(companyId: string) {
	const { data } = await api.get<CompanyPartner[]>(
		`/api/v1/rh/companies/${companyId}/partners`
	);
	return data;
}

interface PartnerQuantitativeResponse {
	currentRecord: number;
	partnerRecords: Quantitative[];
}
export async function getPartnerQuantitative(
	partnerId: string,
	companyId: string
) {
	const { data } = await api.get<PartnerQuantitativeResponse>(
		`/api/v1/rh/companies/${companyId}/partners/${partnerId}`
	);

	return data;
}

interface PartnersCollaborators {
	partner_name: string;
	collaborators: {
		id: string;
		name: string;
		cpf: string;
		avatar: string | null;
	}[];
}
export async function getPartnersCollaborators(
	companyId: string,
	partnerId: string
) {
	const { data } = await api.get<PartnersCollaborators>(
		`/api/v1/rh/companies/${companyId}/partners/${partnerId}/collaborators`
	);

	return data;
}

export interface ManagePartnerCollaboratorsBody {
	collaborator_id: string;
	partner_status: boolean;
}
export async function managePartnerCollaborators(
	companyId: string,
	partnerId: string,
	collaborators: ManagePartnerCollaboratorsBody[]
): Promise<void> {
	return await api.put(
		`/api/v1/rh/companies/${companyId}/partners/${partnerId}/collaborators`,
		{
			collaborators,
		}
	);
}

export type ManageAllPartnerCollaboratorsAction = 'remove' | 'add';
export async function manageAllPartnerCollaborators(
	companyId: string,
	partnerId: string,
	action: ManageAllPartnerCollaboratorsAction
): Promise<void> {
	return await api.put(
		`/api/v1/rh/companies/${companyId}/partners/${partnerId}/collaborators`,
		{
			all_collaborators: { partner_status: action === 'add' },
		}
	);
}
