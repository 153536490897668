import { useQuery } from 'react-query';
import { getCorpwayTerms } from '../../../../services/queries/Terms';
import { showErrorMessage } from '../../../../utils/ErrorHandler';
import { SectionContainer } from '../../../../componentsV2/ui/SectionContainer';
import { Breadcrumb } from '../../../../componentsV2/BreadCrumb';
import { TitleTypography } from '../../../../componentsV2/ui/Typography';
import styled from 'styled-components';
import { EmptyContent } from '../../../../componentsV2/EmptyContent';

const TermsOfUse = () => {
	const TermsOfUseQuery = useQuery(
		['termsofuse'],
		() => {
			return getCorpwayTerms();
		},
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível buscar os termos de uso '
				);
			},
			refetchOnWindowFocus: false,
		}
	);

	if (!TermsOfUseQuery.data || TermsOfUseQuery.isLoading) {
		return (
			<div>
				<SectionContainer>
					<Breadcrumb
						routes={[
							{ title: 'Suporte', route: '/corporate-expenses/support' },
							{
								title: 'Termos de uso',
								route: '/corporate-expenses/support/terms-of-use',
							},
						]}
					/>
					<TitleTypography image>Termos de uso</TitleTypography>
				</SectionContainer>

				<EmptyContent />
			</div>
		);
	}

	return (
		<div>
			<SectionContainer>
				<Breadcrumb
					routes={[
						{ title: 'Suporte', route: '/corporate-expenses/support' },
						{
							title: 'Termos de uso',
							route: '/corporate-expenses/support/terms-of-use',
						},
					]}
				/>
				<TitleTypography image>Termos de uso</TitleTypography>
			</SectionContainer>

			<TermsContainer
				dangerouslySetInnerHTML={{
					__html: TermsOfUseQuery.data ? TermsOfUseQuery.data : '',
				}}
			/>
		</div>
	);
};

export default TermsOfUse;

export const TermsContainer = styled.div`
	text-align: justify;
	padding: 2rem 4rem;
	margin-top: 1.5rem;
	margin-bottom: 3.5rem;
	width: 100%;
	background-color: white;
	border-radius: 10px;
	font-size: 1.4rem;
	overflow-x: auto;
	font-family: 'Poppins' !important;
	color: #575757;

	p strong {
		color: #393939;
		font-size: 1.6rem;
	}
	p {
		margin-bottom: 1rem;
	}

	h1 {
		margin-bottom: 1rem;
	}

	h1 strong {
		color: #393939;
	}

	li {
		margin-bottom: 1rem;
	}

	ol li {
		margin-top: 1rem;
	}

	p:nth-child(3) {
		padding-top: 3rem;
	}

	& > table,
	td {
		text-align: start;
		padding: 3rem 3rem;
		margin-top: 3rem;
		margin-bottom: 3rem;
		border-collapse: collapse;
	}

	table:first-of-type {
		tr:first-child {
			background-color: var(--dark-gray);
			height: 7.5rem;
			width: 80rem;

			th p strong {
				color: #ffffff;
				font-family: 'Poppins' !important;
				text-align: center !important;
				padding-left: 10px;
			}

			th:nth-child(1) {
				border-radius: 0rem 0 0 0;
				width: 40rem;
				border-radius: 20px 0 0 0;
			}

			th:nth-child(2) {
				border-radius: 0rem 0 0 0;
				width: 40rem;
			}

			th:nth-last-child(1) {
				border-radius: 0 0rem 0 0;
				width: 40rem;
				border-radius: 0 20px 0 0;
			}

			p {
				color: white;
			}
		}

		tr:not(:first-of-type):nth-child(2n) {
			th:first-child[rowspan] {
				background-color: #f5f9ff;
			}

			th {
				background-color: #f5f9ff;
			}
		}

		tr:not(:first-of-type):nth-child(2n + 1) {
			th:first-child[rowspan] {
				background-color: #d6dbe3;
			}

			th {
				background-color: #e5eaf2;
			}
		}

		tr:not(:first-of-type):nth-child(3n + 2) {
			th:first-child[rowspan] {
				background-color: #e9eef6;
			}
		}

		tr:not(:first-of-type):nth-child(3n + 3) {
			th:first-child[rowspan] {
				background-color: #e9eef6;
			}
		}
	}

	table:not(:first-of-type) {
		tr:nth-child(2n + 1) th {
			background-color: #f5f9ff;
		}

		tr:nth-child(2n + 2) th {
			background-color: #e5eaf2;
		}
		tr:nth-child(2n) th {
			background-color: #e5eaf2;
		}
	}

	tr {
		p {
			font-weight: normal;
		}
		h3 {
			font-weight: normal;
		}

		th {
			padding: 3rem 2rem;
			text-align: left;
		}
	}

	a {
		color: var(--dark-gray);
		text-decoration: underline;
		transition: all 0.2s;
	}

	a:hover {
		color: var(--light-gray);
	}

	& > ol,
	ul {
		margin-left: 2rem;
		li {
			margin-left: 2rem;
		}
	}

	& > h1,
	h2 {
		font-size: 2rem;
		margin-top: 2rem;
	}

	&::-webkit-scrollbar {
		width: 1.2rem;
	}

	&::-webkit-scrollbar-track {
		border-radius: 1rem;
		background-color: #e3e8eb;
	}

	&::-webkit-scrollbar-thumb {
		background-color: var(--light-gray);
		border-radius: 1rem;
	}

	& > table:not(:first-of-type) {
		tr:nth-child(odd) td:nth-child(1),
		tr:nth-child(odd) th:nth-child(1) {
			background-color: #e9eef6;
			width: 30rem;
			height: 30rem;
		}
		tr:nth-child(even) td:nth-child(1),
		tr:nth-child(even) th:nth-child(1) {
			background-color: #d6dbe3;
			width: 30rem;
			height: 30rem;
		}

		tr:nth-child(odd) td:nth-child(2),
		tr:nth-child(odd) th:nth-child(2) {
			background-color: #f5f9ff; /* Linhas ímpares da segunda coluna */
		}
		tr:nth-child(even) td:nth-child(2),
		tr:nth-child(even) th:nth-child(2) {
			background-color: #e5eaf2; /* Linhas pares da segunda coluna */
		}
	}
`;
