import { z } from 'zod';
import { moneyMask, parseMoneyMaskToRaw } from '../../../../../utils/masks';
import {
	Card,
	CardPreferences,
} from '../../../../../@types/CorporateExpenses/Card';
import { useAuth } from '../../../../../hooks/useAuth';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation, useQueryClient } from 'react-query';
import { updateCardNotifications } from '../../../../../services/queries/Corpway/Cards';
import { showErrorMessage } from '../../../../../utils/ErrorHandler';
import React, { useEffect, useState } from 'react';
import { SectionContainer } from '../../../../../componentsV2/ui/SectionContainer';
import {
	TitleTypography,
	Typography,
} from '../../../../../componentsV2/ui/Typography';
import ToggleSwitch from '../../../../../components/ToggleSwitch';
import { InputField } from '../../../../../componentsV2/ui/Form/InputField';
import * as S from './styles';
import styled from 'styled-components';

const schema = z
	.object({
		limit_value: z.string().transform((value) => parseMoneyMaskToRaw(value)),
		limit_value_alert: z.literal(true),
	})
	.refine(
		({ limit_value, limit_value_alert }) => {
			if (limit_value_alert) {
				return parseFloat(limit_value) > 0;
			}
			return true;
		},
		{
			message: 'Necessário inserir valor mínimo para alerta',
			path: ['limit_value'],
		}
	);
export function CardNotifications({
	card,
	cardSettings,
}: {
	card: Card;
	cardSettings: CardPreferences;
}) {
	cardSettings.limit_value = moneyMask(cardSettings.limit_value.toString());

	const { currentCompany } = useAuth();
	const queryClient = useQueryClient();

	const {
		register,
		setValue,
		formState,
		getValues,
		watch,
		clearErrors,
		trigger,
	} = useForm<CardPreferences>({
		resolver: zodResolver(schema),
		defaultValues: {
			...cardSettings,
			limit_value: moneyMask(cardSettings.limit_value.toString()),
		},
	});

	const updateCardNotificationsMutation = useMutation(
		(data: CardPreferences) =>
			updateCardNotifications(currentCompany!.id, card.id, data),
		{
			onSuccess: () => {
				queryClient.invalidateQueries([
					'fetchCardNotifications',
					currentCompany!.id,
					card.id,
				]);
				clearErrors();
				if (!limit_value_alert) {
					setValue('limit_value', moneyMask('0'));
				}
			},
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi atualizar as preferências do cartão.'
				);
			},
		}
	);

	const [finishedTyping, setFinishedTyping] = useState(false);

	function handleOnPreferencesChanged(data: CardPreferences) {
		data.limit_value = data.limit_value_alert
			? parseMoneyMaskToRaw(data.limit_value.toString())
			: '0';
		updateCardNotificationsMutation.mutate(data);
	}

	function hasChanges() {
		const values = getValues();
		return (
			values.limit_value !== cardSettings.limit_value ||
			values.limit_value_alert !== cardSettings.limit_value_alert
		);
	}

	function handleOnLimitValueBlur(e: React.FocusEvent<HTMLInputElement>) {
		if (parseFloat(parseMoneyMaskToRaw(e.target.value)) === 0) {
			setValue('limit_value_alert', false);
		}
		setFinishedTyping(true);
	}

	const limit_value_alert = watch('limit_value_alert');
	const limit_value = watch('limit_value');

	async function applyChanges() {
		const validationLimitValue = await trigger('limit_value');

		if (
			validationLimitValue &&
			(cardSettings.limit_value_alert !== limit_value_alert ||
				limit_value_alert)
		) {
			handleOnPreferencesChanged(getValues());
		}
	}

	useEffect(() => {
		if (
			hasChanges() &&
			(finishedTyping || cardSettings.limit_value_alert !== limit_value_alert)
		) {
			applyChanges();
		} else {
			if (!limit_value_alert && finishedTyping) {
				clearErrors();
				setValue('limit_value', moneyMask('0'));
			}
		}

		setFinishedTyping(false);
	}, [limit_value_alert, limit_value, finishedTyping]); // eslint-disable-line

	return (
		<SectionContainerStyles>
			<TitleTypography size='2rem' weight='600'>
				Configurações do cartão
			</TitleTypography>
			<S.Row>
				<ToggleSwitch name={'limit_value_alert'} register={register} />

				<Typography
					color='var(--dark-gray)'
					style={{
						width: '100%',
					}}
				>
					Receber alertas a partir de:
				</Typography>
			</S.Row>
			<InputField
				name='limit_value'
				errorMessage={formState.errors.limit_value?.message}
				placeholder={'R$ 3000,00'}
				register={register}
				onChange={(e) => {
					setValue('limit_value', moneyMask(e.target.value));
				}}
				onBlur={handleOnLimitValueBlur}
			/>
			<Typography
				color='var(--dark-gray)'
				style={{
					margin: '1rem auto',
					width: '100%',
				}}
			>
				Receba alertas quando este cartão atingir o limite mínimo definido
				acima.
			</Typography>
		</SectionContainerStyles>
	);
}

const SectionContainerStyles = styled(SectionContainer)`
	margin-top: -2rem;
`;
