import { useEffect } from 'react';
import { SectionContainer } from '../../../../componentsV2/ui/SectionContainer';
import { Breadcrumb } from '../../../../componentsV2/BreadCrumb';
import { TitleTypography } from '../../../../componentsV2/ui/Typography';
import { getCorpwayPrivacyPolicy } from '../../../../services/queries/Terms';
import { useQuery } from 'react-query';
import { showErrorMessage } from '../../../../utils/ErrorHandler';
import { EmptyContent } from '../../../../componentsV2/EmptyContent';
import { TermsContainer } from '../TermsOfUse';

const PrivacyPolicy = () => {
	const privacyQuery = useQuery(
		['privacyPolicy'],
		() => {
			return getCorpwayPrivacyPolicy();
		},
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível buscar as políticas de privacidade. '
				);
			},
			refetchOnWindowFocus: false,
		}
	);

	useEffect(() => {
		const cells = document.querySelectorAll('td, th');
		const secondTable = document.querySelector('table:nth-of-type(2)');

		cells.forEach((cell) => {
			if (cell.textContent?.includes('Dados de registro:')) {
				(cell as HTMLElement).style.backgroundColor = '#E5EAF2';
			}
			if (cell.textContent?.includes('Registro de pesquisa')) {
				(cell as HTMLElement).style.backgroundColor = '#E5EAF2';
			}
			if (cell.textContent?.includes('Dados para contato')) {
				(cell as HTMLElement).style.backgroundColor = '#F5F9FF';
			}
			if (cell.textContent?.includes('Fale Conosco:')) {
				(cell as HTMLElement).style.backgroundColor = '#F5F9FF';
			}
		});
	}, [privacyQuery.data]);

	if (!privacyQuery.data || privacyQuery.isLoading) {
		return (
			<div>
				<SectionContainer>
					<Breadcrumb
						routes={[
							{ title: 'Suporte', route: '/corporate-expenses/support' },
							{
								title: 'Aviso de Privacidade',
								route: '/corporate-expenses/support/privacy-policy',
							},
						]}
					/>
					<TitleTypography image>Aviso de Privacidade</TitleTypography>
				</SectionContainer>

				<EmptyContent />
			</div>
		);
	}

	return (
		<div>
			<SectionContainer>
				<Breadcrumb
					routes={[
						{ title: 'Suporte', route: '/corporate-expenses/support' },
						{
							title: 'Aviso de Privacidade',
							route: '/corporate-expenses/support/privacy-policy',
						},
					]}
				/>
				<TitleTypography image>Aviso de Privacidade</TitleTypography>
			</SectionContainer>

			<TermsContainer
				dangerouslySetInnerHTML={{
					__html: privacyQuery.data ? privacyQuery.data : '',
				}}
			/>
		</div>
	);
};

export default PrivacyPolicy;
