import { useState } from 'react';
import { FaInfoCircle, FaUsers } from 'react-icons/fa';
import { RiExchangeDollarLine } from 'react-icons/ri';
import { CheckGroupCollaborators } from '../../../../../components/CheckGroupCollaborators';
import {
	sortByName,
	SortIconButton,
	SortOrder,
} from '../../../../../components/SortIconButton';
import UserCard from '../../../../../components/UserCard';
import { usePayment } from '../../../../../hooks/usePaymentV2';
import { getBenefitIcon } from '../../../../../utils/benefits/getBenefitIcon';
import { getBenefitParsedTitle } from '../../../../../utils/benefits/getBenefitParsedTitle';
import { PAYMENT_TABLE_ID } from '../../utils/getBenefitValuesInputs';
import { BenefitInput } from '../BenefitInput';
import { BenefitsListTotals } from '../BenefitTotals/BenefitsListTotals';
import { GroupCollaboratorsRepproved } from './GroupCollaboratorsRepproved';
import * as S from './styles';

export default function PaymentTableStatic() {
	const { beneficiaries, selectedBenefits, isBenefitSelected } = usePayment();
	const [beneficiariesSort, setBeneficiariesSort] = useState<SortOrder>('asc');

	function renderReasonDisapprovalTooltip(benefit: string | undefined) {
		if (benefit && benefit.includes('Beneficiary account blocked')) {
			return (
				<S.ReprovedTooltip>
					<FaInfoCircle />
					<span>
						{
							'Ocorreu um erro no pagamento, conta bloqueada. Contate o suporte.'
						}
					</span>
				</S.ReprovedTooltip>
			);
		} else if (benefit) {
			return (
				<S.ReprovedTooltip>
					<FaInfoCircle />
					<span>{benefit}</span>
				</S.ReprovedTooltip>
			);
		}
	}

	return (
		<>
			<S.Table>
				<S.TableHeader>
					<S.TableRow>
						<S.TableHeaderCellSticky>
							<S.UserContainer>
								<S.NumberSpan>Nº </S.NumberSpan>
								COLABORADOR
								<SortIconButton
									sort={beneficiariesSort}
									onToggleSort={() => {
										setBeneficiariesSort((old) =>
											old === 'asc' ? 'desc' : 'asc'
										);
									}}
								/>
							</S.UserContainer>
						</S.TableHeaderCellSticky>
						{/* HEADER DISPLAYS ALL SELECTED BENEFITS */}
						{selectedBenefits.map((benefit) => (
							<S.TableHeaderCell key={benefit.id + 'header'}>
								<div>
									{getBenefitParsedTitle(benefit.title)}
									{getBenefitIcon(benefit.title)}
								</div>
							</S.TableHeaderCell>
						))}
					</S.TableRow>
				</S.TableHeader>

				<S.TableBody id={PAYMENT_TABLE_ID}>
					{sortByName(beneficiariesSort, beneficiaries).map(
						(beneficiary, index) => (
							<S.TableRow key={beneficiary.id}>
								<S.TableDataSticky>
									<S.UserContainer>
										<S.NumberSpan>{index + 1} </S.NumberSpan>
										{beneficiary.isGroup ? (
											<S.GroupCard>
												<FaUsers />
												<S.GroupInfoContainer>
													<span>{beneficiary.name}</span>
													<S.GroupCollaborators>
														{beneficiary.collaboratorsAmount} colaboradores
														<span>
															{' '}
															(
															<CheckGroupCollaborators
																groupId={beneficiary.id}
															/>
															)
														</span>
													</S.GroupCollaborators>
												</S.GroupInfoContainer>
											</S.GroupCard>
										) : (
											<UserCard
												name={beneficiary.name}
												bottomInfo={String(beneficiary.office)}
												avatar_url={beneficiary.avatar_url}
											/>
										)}
									</S.UserContainer>
								</S.TableDataSticky>
								{beneficiary.benefits.map((benefit) => {
									if (isBenefitSelected(benefit.id)) {
										return (
											<S.TableData key={beneficiary.id + benefit.id}>
												<S.BenefitInputContainer>
													<BenefitInput
														beneficiaryId={beneficiary.id}
														benefitId={benefit.id}
														initialValue={benefit.value}
														displayOnly={true}
													/>

													{benefit.chargeback_status && (
														<>
															<RiExchangeDollarLine
																color={
																	Number(benefit.chargeback_value!) / 100 <
																	benefit.value
																		? '#FF5900'
																		: '#1CCA0D'
																}
																data-rh={'Valor estornado do colaborador'}
															/>
															<BenefitInput
																beneficiaryId={beneficiary.id}
																benefitId={benefit.id}
																initialValue={Number(
																	benefit.chargeback_value! / 100
																)}
																displayOnly={true}
																chargeback_status={benefit.chargeback_status}
																color={
																	Number(benefit.chargeback_value!) / 100 <
																	benefit.value
																		? '#FF5900'
																		: '#1CCA0D'
																}
															/>
															{Number(benefit.chargeback_value!) / 100 <
																benefit.value && (
																<S.ReprovedTooltip>
																	<FaInfoCircle />
																	<span>
																		O colaborador não possuia saldo suficiente.
																	</span>
																</S.ReprovedTooltip>
															)}
														</>
													)}

													{renderReasonDisapprovalTooltip(
														benefit.reason_disapproval
													)}

													{beneficiary.individualsRepproved?.find(
														(i) => i.benefitId === benefit.id
													) && (
														<GroupCollaboratorsRepproved
															individualsRepproved={
																beneficiary.individualsRepproved
															}
															currentBenefit={benefit}
														/>
													)}
												</S.BenefitInputContainer>
											</S.TableData>
										);
									}
									return (
										<S.TableData
											key={beneficiary.id + benefit.id}
										></S.TableData>
									);
								})}
							</S.TableRow>
						)
					)}
					<S.TableRow>
						<S.TableDataSticky style={{ fontWeight: 'bold' }}>
							Valores totais pagos
						</S.TableDataSticky>
						<BenefitsListTotals />
					</S.TableRow>
				</S.TableBody>
			</S.Table>
		</>
	);
}
