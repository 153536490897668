import { FaInfoCircle } from 'react-icons/fa';
import styled from 'styled-components';
import { OptionsButton } from '../../../../components/Button';

export const Container = styled.div`
	flex: 1;
	padding: 4rem 7rem 2rem 7rem;
`;
export const UploadHeader = styled.div`
	width: 70%;
	color: var(--light-gray);
	font-size: 1.6rem;
	padding: 1rem;
	border-bottom: 0.2rem solid #af2626;
	margin: 1rem 0;
`;

export const DropContainer = styled.div`
	width: 70%;
	height: 30rem;
	border: 0.2rem dashed #5d5fef;
	border-radius: 4px;
	cursor: pointer;
	transition: height 0.2s ease;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const getMessageColors = (type: string) => {
	if (type === 'default') return '#D19393';
	else if (type === 'error') return 'var(--red)';
	else return 'var(--terciary)';
};

interface UploadMessageProps {
	type?: string;
}

export const UploadMessage = styled.span<UploadMessageProps>`
	display: flex;
	flex-direction: column;
	font-size: 1.6rem;
	text-align: center;
	color: ${({ type }) =>
		type ? getMessageColors(type) : getMessageColors('default')};
	& span {
		font-size: 1.4rem;
		margin: 1rem 0;
	}
`;

export const UploadButton = styled.button`
	${OptionsButton}
	color: var(--white);
	background-color: var(--primary-blue);
	margin-top: 0.5rem;
`;

export const ExtensionsInfo = styled.div`
	margin: 2rem 0;
	display: flex;
	align-items: center;
	color: #884141;
	font-size: 1.4rem;
`;

export const InfoIcon = styled(FaInfoCircle)`
	margin-right: 0.8rem;
	color: var(--primary-blue);
`;

export const ModelLink = styled.a`
	color: var(--primary-red);
	text-decoration: underline;
	font-style: italic;
`;
