import Dropzone from 'react-dropzone';
import PageTitle from '../../../../components/PageTitle';
import { importFileSupportedExtenstions } from '../../../../utils/supportedImportExtensions';
import * as S from './styles';

interface ImportCollaboratorsProps {
	handleFileUpload(file: File): void;
}

export function ImportCollaborators({
	handleFileUpload,
}: ImportCollaboratorsProps) {
	const renderDragMessage = (isDragActive: boolean, isDragReject: boolean) => {
		if (!isDragActive) {
			return (
				<S.UploadMessage>
					Arraste um arquivo para cá
					<span>ou</span>
					<S.UploadButton>Carregue um arquivo do seu PC</S.UploadButton>
				</S.UploadMessage>
			);
		}

		if (isDragReject) {
			return (
				<S.UploadMessage type='error'>Arquivo não suportado</S.UploadMessage>
			);
		}

		return (
			<S.UploadMessage type='success'>Solte o arquivo aqui</S.UploadMessage>
		);
	};

	return (
		<S.Container>
			<PageTitle title='Importar Colaboradores' />

			<S.UploadHeader>UPLOAD</S.UploadHeader>
			<Dropzone
				accept={importFileSupportedExtenstions}
				onDropAccepted={(files) => handleFileUpload(files[0])}
				multiple={false}
			>
				{({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
					<S.DropContainer
						{...getRootProps()}
						isDragActive={isDragActive}
						isDragReject={isDragReject}
					>
						<input {...getInputProps()} />
						{renderDragMessage(isDragActive, isDragReject)}
					</S.DropContainer>
				)}
			</Dropzone>

			<S.ExtensionsInfo>
				<S.InfoIcon />
				Extensões válidas: .xls, .xlsx, .csv.
			</S.ExtensionsInfo>
			<S.ExtensionsInfo>
				<S.InfoIcon />
				<S.ModelLink
					href={`${window.location.origin}/Modelo-Colaboradores.xlsx`}
					download
				>
					Baixar modelo de planilha de cadastro
				</S.ModelLink>
			</S.ExtensionsInfo>
		</S.Container>
	);
}
