import api from '../../api';

interface BalancesReturn {
	corpway_balance: number;
	cards_balance: number;
	account_balance: number;
}

export async function getCorpwayBalances(companyId: string) {
	const { data } = await api.get<BalancesReturn>(
		`api/v1/rh/corpway/companies/${companyId}/balances`
	);
	return data;
}
