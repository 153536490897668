import styled from 'styled-components';
import { OptionsButton } from '../../../../../components/Button';

export const ReprovalIcon = styled.div`
	cursor: pointer;
	/* opacity: 0; */
`;

// MODAL STYLES
export const ModalContainer = styled.div`
	display: flex;
	min-width: 30rem;
	flex-direction: column;
	align-items: center;
	font-size: 1.4rem;
	text-align: center;
	> svg {
		width: 3rem;
		height: 3rem;
		border-radius: 50%;
		color: var(--terciary);
		margin-bottom: 1.5rem;
	}
`;

export const DisapprovalForm = styled.form`
	display: flex;
	flex-direction: column;
	align-items: center;
	min-width: 100%;
`;

export const DisapprovalInput = styled.textarea`
	margin: 1.5rem 0;
	padding: 0.6rem;
	width: 100%;
	min-height: 6rem;
`;

export const RemoveButton = styled.button`
	${OptionsButton}
	color: var(--primary-blue);
	background: none;
	margin-right: 1.5rem;
	font-weight: normal;
`;

export const ConfirmButton = styled.button`
	${OptionsButton}
	font-weight: normal;
`;
