import { DateTime } from 'luxon';
import { Notification } from '../../../../../../contexts/NotificationsContext';

type CategorizedNotifications = {
	today: Notification[];
	yesterday: Notification[];
	week: Notification[];
	month: Notification[];
	older: Notification[];
};

export function categorizeNotificationsByHumanizedDate(
	notifications: Notification[]
) {
	const categorizedNotifications: CategorizedNotifications = {
		today: [],
		yesterday: [],
		week: [],
		month: [],
		older: [],
	};

	const today = DateTime.now().startOf('day');
	const yesterday = today.minus({ days: 1 });
	const startOfWeek = today.minus({ days: today.weekday });
	const startOfMonth = today.startOf('month');

	notifications.forEach((notification) => {
		const createdAt = DateTime.fromISO(
			notification.created_at as unknown as string
		);
		if (createdAt >= today) {
			categorizedNotifications['today'].push(notification);
		} else if (createdAt >= yesterday) {
			categorizedNotifications['yesterday'].push(notification);
		} else if (createdAt >= startOfWeek) {
			categorizedNotifications['week'].push(notification);
		} else if (createdAt >= startOfMonth) {
			categorizedNotifications['month'].push(notification);
		} else {
			categorizedNotifications['older'].push(notification);
		}
	});

	return categorizedNotifications;
}
