import logoImg from '../../assets/Bounty.svg';
import * as S from './styles';

export default function NotFound() {
	return (
		<S.Container data-test='404-container' data-testid='404-container'>
			<S.LogoImage
				src={logoImg}
				alt='Logo do Bounty'
				data-testid='bounty_logo'
			/>

			<S.StyledPhrase> 404 - PÁGINA NÃO ENCONTRADA </S.StyledPhrase>
		</S.Container>
	);
}
