import styled from 'styled-components';

export const Container = styled.div`
	flex: 1;
	padding: 4rem 7rem 2rem 7rem;
`;

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 2.5rem;
	margin-top: 2rem;
`;

export const InfoContainer = styled.div`
	display: flex;
	flex-direction: column;
	font-size: 1.6rem;
	max-width: 80%;
	overflow: auto;
`;

export const InfoTitle = styled.h2`
	font-weight: 500;
	font-size: 1.8rem;
	color: var(--mid-gray);
	margin-bottom: 1rem;
	text-transform: uppercase;
`;

export const BodyContainer = styled.div`
	max-height: 20rem;
	overflow-y: auto;
`;

export const ShowBodyBtn = styled.button`
	background: none;
	color: var(--primary-blue);
	font-weight: bold;
`;

export const SegmentationContainer = styled.div`
	display: flex;
	align-items: center;
	column-gap: 1rem;

	& > svg {
		width: 2rem;
		height: 2rem;
	}
`;

export const SegmentationTypeContainer = styled.div`
	display: flex;
	flex-direction: column;

	& > h4 {
		font-weight: 400;
		color: var(--dark-gray);
	}
	& > span {
		color: var(--mid-gray);
	}
`;

export const CollaboratorsList = styled.ul`
	margin-top: 2.5rem;
	/* list-style: none; */
	display: flex;
	flex-direction: column;
	border: 0.15rem solid black;
	& :last-child {
		border: none;
	}
`;

export const AvatarContainer = styled.div`
	display: flex;
	align-items: center;

	flex: 1;
`;

export const Avatar = styled.img`
	min-width: 4rem;
	max-width: 4rem;
	min-height: 4rem;
	max-height: 4rem;
	border-radius: 50%;
	border: 0.2rem solid var(--terciary);
	margin-right: 1rem;
	object-fit: cover;
`;

export const CollabName = styled.span`
	font-size: 1.4rem;
	margin-left: 1rem;
`;

export const CollabOffice = styled.span`
	font-size: 1.4rem;
	flex: 1;
	color: var(--mid-gray);
`;

export const CollaboratorCard = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0.5rem 1rem;
	border-bottom: 0.15rem solid black;
`;
