import styled from 'styled-components';
import { BaseInputStyle } from './BaseStyle';

export const TextAreaComp = styled.textarea`
	${BaseInputStyle}
	resize: vertical;
	min-height: 10rem;
	padding: 1rem;

	&::placeholder {
		color: var(--light-gray2);
	}
	&:focus {
		outline: 2px solid var(--dark-gray2);
	}
`;
