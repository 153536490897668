import styled from 'styled-components';
import { InputField } from '../../../../../componentsV2/ui/Form/InputField';
import { Button } from '../../../../../componentsV2/ui/Button';
import { TextAreaField } from '../../../../../componentsV2/ui/Form/TextAreaField';
import { useForm } from 'react-hook-form';
import {
	CostCenter,
	CreateUpdateCostCenterData,
} from '../../../../../@types/CorporateExpenses/CostCenter';
import { Loader } from '../../../../../componentsV2/ui/Loader';

interface Props {
	costCenter?: CostCenter;
	onSubmit: (d: CreateUpdateCostCenterData) => void;
	onCancel: () => void;
	isLoading: boolean;
}
export function CostCenterForm({ costCenter, ...props }: Props) {
	const { register, handleSubmit } = useForm<CreateUpdateCostCenterData>({
		defaultValues: {
			title: costCenter?.title ?? '',
			description: costCenter?.description ?? '',
		},
	});

	if (props.isLoading)
		return (
			<Form>
				<Loader />
			</Form>
		);
	return (
		<Form
			onSubmit={(e) => {
				e.preventDefault();
				handleSubmit(props.onSubmit)();
				e.stopPropagation(); // do not submit the parent form
			}}
		>
			<StyledInputField
				label='Nome do centro de custo:'
				placeholder='Descreva o título'
				id='title'
				required
				maxLength={30}
				name='title'
				register={register}
			/>
			<StyledTextAreaField
				label='Descrição:'
				placeholder='Descreva o propósito do centro de custo e os tipos de despesas autorizadas.'
				id='description'
				name='description'
				required
				register={register}
			/>

			<OptionsContainer>
				<Button
					onClick={props.onCancel}
					intent='terciary'
					$outline
					width='adjusted'
					type='button'
					style={{
						width: '18remx',
						height: '4.5rem',
						padding: '12px 32px 12px 32px',
						gap: '1.2rem',
						border: '1px 0px 0px 0px',
						borderRadius: '5rem',
					}}
				>
					Voltar
				</Button>
				<Button
					type='submit'
					width='adjusted'
					style={{
						width: '13.6rem',
						height: '4.5rem',
						padding: '0px 32px 0px 32px',
						gap: '1.2rem',
						border: '1px 0px 0px 0px',
						borderRadius: '5rem',
					}}
				>
					{costCenter ? 'Editar' : 'Continuar'}
				</Button>
			</OptionsContainer>
		</Form>
	);
}

const Form = styled.form`
	display: flex;
	flex-direction: column;
	row-gap: 1rem;
	width: 37rem;
	height: 40rem;
	align-items: center;
	justify-content: center;
	padding: 0px 24x 0px 24px;
	gap: 1rem;
	label {
		font-family: Poppins;
		font-size: 12px;
		font-weight: 400;
		line-height: 5px;
		letter-spacing: 0.5px;
		color: #929292;
		margin-top: 3rem;
	}
`;

const OptionsContainer = styled.div`
	display: flex;
	column-gap: 2rem;
	justify-content: center;
	margin: 2rem 0;
	width: 100%;
	align-self: center;
`;

const StyledInputField = styled(InputField)`
	width: 100%;
	height: 6rem;
	margin-top: 1rem;
	gap: 40px;
	padding: 8px 16px 8px 16px;
	border-radius: 4px 4px 4px 4px;
	::placeholder {
		color: #929292;
		width: 328px;
		height: 30px;
		padding: 8px 16px 8px 4px;
		gap: 0px;
		border-radius: 4px 0px 0px 0px;
	}
`;

const StyledTextAreaField = styled(TextAreaField)`
	width: 100%;
	height: 16rem;
	align-self: center;
	padding: 8px 16px 8px 16px;
	gap: 40px;
	border-radius: 4px 4px 4px 4px;
	background: #f0f3f8;
	resize: none;
	margin-top: 1rem;
	::placeholder {
		font-family: 'Poppins';
		font-size: 14px;
		font-weight: 400;
		line-height: 21px;
		text-align: left;
		color: #929292;
	}
	&:focus {
		outline: 1px solid var(--light-gray);
	}
`;
