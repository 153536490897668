import { useAuth } from '../../../../../hooks/useAuth';
import { useQuery } from 'react-query';
import { CORPWAY_USERS_KEY } from '../../../../../services/queries/Corpway/User';
import {
	CORPWAY_COST_CENTER_KEY,
	getCostCenters,
	getCostCenterUsers,
} from '../../../../../services/queries/Corpway/CostCenters';
import { showErrorMessage } from '../../../../../utils/ErrorHandler';
import { TitleTypography } from '../../../../../componentsV2/ui/Typography';
import { InputField } from '../../../../../componentsV2/ui/Form/InputField';
import { SelectField } from '../../../../../componentsV2/ui/Form/SelectField';
import { CreateCostCenterModal } from '../../../Management/CostCenters/components/CreateModal';
import { Button } from '../../../../../componentsV2/ui/Button';
import { Loader } from '../../../../../componentsV2/ui/Loader';
import { Link } from '../../../../../componentsV2/ui/Link';
import { UseFormReturn } from 'react-hook-form';
import { CreateCardFormData } from '../index';
import * as S from './styles';
import { InputWithDescription } from './InputWithDescription';
import { User } from '../../../../../@types/CorporateExpenses/User';

export interface FormStepProps {
	form: UseFormReturn<CreateCardFormData>;
}

export function FormStep1({ form }: FormStepProps) {
	const { register, watch, formState } = form;
	const { currentCompany, user: currentUser } = useAuth();

	const costCenterSelected = watch('cost_center_id');

	const getUsersSelectQuery = useQuery(
		[CORPWAY_USERS_KEY, costCenterSelected, currentCompany?.id],
		() => getCostCenterUsers(costCenterSelected, currentCompany!.id),
		{
			onError: (err) => {
				showErrorMessage(err as Error, 'Não foi possível buscar os usuários. ');
			},
			enabled: !!costCenterSelected,
		}
	);

	const getCostCentersSelectQuery = useQuery(
		[CORPWAY_COST_CENTER_KEY, currentCompany?.id],
		() => getCostCenters(currentCompany!.id),
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível buscar os centros de custo. '
				);
			},
		}
	);

	function orderUsers(users: User[] | undefined): User[] | undefined {
		return users?.sort((a, b) => a.name.localeCompare(b.name));
	}

	return (
		<>
			<TitleTypography weight='600' size='2.4rem' primaryText='Informações'>
				do cartão
			</TitleTypography>
			<InputWithDescription
				description={
					'Determine o título que irá aparecer na plataforma sempre ' +
					'que alguém acessar ou utilizar este cartão.'
				}
			>
				<InputField
					errorMessage={formState.errors.alias?.message}
					label='Qual será o nome deste cartão?'
					name='alias'
					id='alias'
					register={register}
					required
					maxLength={20}
				/>
			</InputWithDescription>

			<InputWithDescription
				description={
					'Defina a área que está autorizada a usar este cartão. ' +
					'Por exemplo: se for um cartão para anúncios, selecione a área “Mídia”.'
				}
			>
				<S.Column>
					<SelectField
						errorMessage={formState.errors.cost_center_id?.message}
						label='Qual área utilizará o cartão?'
						name='cost_center_id'
						id='cost_center_id'
						data-testid='accessInput_test_id'
						register={register}
						required
					>
						{getCostCentersSelectQuery.data?.costCenters.map((costCenter) => (
							<option key={costCenter.id} value={costCenter.id}>
								{costCenter.title}
							</option>
						))}
					</SelectField>
					<CreateCostCenterModal
						openBtn={
							<Button intent='link' type='button' style={{ fontWeight: 400, borderBottomWidth: '1px'}}>
								+ Criar novo centro de custo
							</Button>
						}
					/>
				</S.Column>
			</InputWithDescription>

			<InputWithDescription
				description={
					'Selecione o responsável pelo cartão. ' +
					'Ele será o único colaborador autorizado a utilizá-lo.'
				}
			>
				{getUsersSelectQuery.isLoading ? (
					<Loader />
				) : (
					<S.Column>
						<SelectField
							errorMessage={formState.errors.user_id?.message}
							label={'Quem vai utilizar o cartão?'}
							name='user_id'
							id='user_id'
							register={register}
							disabled={!costCenterSelected}
							data-testid='inputUser_test_id'
							required
						>
							{orderUsers(getUsersSelectQuery.data)?.map((user) => (
								<option key={user.id} value={user.id}>
									{user.operator_id === currentUser.id ? 'Você' : user.name}
								</option>
							))}
						</SelectField>
						{!!costCenterSelected && (
							<Link
								style={{ width: 'fit-content', fontWeight: 400, borderBottomWidth: '1px' }}
								$underline
								intent='terciary'
								to={'/corporate-expenses/management/users/create'}
								>
								+ Criar novo usuário
							</Link>
						)}
					</S.Column>
				)}
			</InputWithDescription>
		</>
	);
}
