import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
	CostCenter,
	CostCenterOnlyUsers,
} from '../../../../../@types/CorporateExpenses/CostCenter';
import { Button } from '../../../../../componentsV2/ui/Button';
import { useAuth } from '../../../../../hooks/useAuth';
import { useMemo, useState } from 'react';
import Modal from '../../../../../componentsV2/ui/Modal';
import { Card } from '../../../../../componentsV2/Card';
import styled from 'styled-components';
import { Typography } from '../../../../../componentsV2/ui/Typography';
import { createColumnHelper } from '@tanstack/react-table';
import { Card as CardType } from '../../../../../@types/CorporateExpenses/Card';
import { Table } from '../../../../../componentsV2/Table';
import { convertCentsToFormattedReais } from '../../../../../utils/CurrencyConvert';
import {
	CORPWAY_COST_CENTER_KEY,
	getUsersOnlyCostCenter,
} from '../../../../../services/queries/Corpway/CostCenters';
import { toast } from 'react-toastify';
import { SelectUsersNewCostCenter } from './SelectUsersNewCostCenter';
import { useForm } from 'react-hook-form';
import { useValidateCorpwayUserPassword } from '../../../../../hooks/useValidateCorpwayUserPassword';
import { deleteCostCenter } from '../../../../../services/queries/Corpway/CostCenters';
import { showErrorMessage } from '../../../../../utils/ErrorHandler';
import { formatCardNumber } from '../../../../../utils/formatCardNumber';
import cardRemoveIcon from '../../../../../assets/card-remove-icon.svg';
import userRemoveIcon from '../../../../../assets/user-remove-icon.svg';
import confirmCostCenterExclusionIcon from '../../../../../assets/confirm-cost-center-exclusion.svg';
import { PasswordInputLock } from '../../../../../componentsV2/ui/Form/PasswordInputLock';

interface Props {
	costCenter: CostCenter;
	costCenterCards: CardType[];
	onDeleted: () => void;
}

export interface UsersNewCostCenter {
	user_id: string;
	new_cost_center_id: string;
}

const cardsColumnHelper = createColumnHelper<CardType>();
const cardsColumns = [
	cardsColumnHelper.accessor('pan', {
		header: 'Cartão',
		cell: (info) => formatCardNumber(info.getValue()),
	}),
	cardsColumnHelper.accessor('alias', {
		cell: (info) => info.getValue(),
		meta: {
			align: 'center',
		},
		header: 'Apelido',
	}),
	cardsColumnHelper.accessor('balance', {
		cell: (info) => convertCentsToFormattedReais(info.getValue()),
		meta: {
			align: 'end',
		},
		header: 'Saldo',
	}),
];
const usersColumnHelper = createColumnHelper<CostCenterOnlyUsers>();

interface FormData {
	password: string;
}

export function DeleteCostCenterModal({
	costCenter,
	costCenterCards,
	onDeleted,
}: Props) {
	const queryClient = useQueryClient();
	const { currentCompany } = useAuth();
	const [isOpen, setOpen] = useState(false);
	const [usersNewCostCenter, setUsersNewCostCenter] = useState<
		UsersNewCostCenter[]
	>([]);
	const [allocatedCardsConfirmation, setAllocatedCardsConfirmation] =
		useState(false);
	const [allocatedUsersConfirmation, setAllocatedUsersConfirmation] =
		useState(false);
	const {
		register,
		watch,
		reset,
		formState: { errors },
		setError,
	} = useForm<FormData>({
		defaultValues: {
			password: '',
		},
	});
	const passwordInputValue = watch('password');
	const { validateUserPassword, isPasswordValidationLoading } =
		useValidateCorpwayUserPassword({
			onSuccess: onPasswordValidationSuccess,
			onFailure: () =>
				setError('password', {
					type: 'manual',
					message: 'Senha incorreta, por favor digite novamente',
				}),
		});

	const getUsersOnlyCostCenterQuery = useQuery(
		[CORPWAY_COST_CENTER_KEY, costCenter.id, 'users-only', currentCompany?.id],
		() => getUsersOnlyCostCenter(costCenter.id, currentCompany!.id)
	);
	const areThereCardsAllocated = costCenterCards.length > 0;
	const areThereUsersAllocatedOnlyInTheCostCenter =
		!!getUsersOnlyCostCenterQuery.data?.length;

	const costCenterCardsBalance = costCenterCards.reduce(
		(accumulator, currentCard) => accumulator + currentCard.balance,
		0
	);

	const deleteCostCenterMutation = useMutation(
		(users: UsersNewCostCenter[]) => {
			return deleteCostCenter(costCenter.id, currentCompany!.id, users);
		},
		{
			onSuccess: () => {
				queryClient.resetQueries([CORPWAY_COST_CENTER_KEY]);
				onDeleted();
			},
			onError: (error) => {
				showErrorMessage(
					error as Error,
					'Não foi possível deletar o centro de custo.'
				);
			},
		}
	);

	function handleOpenModal() {
		if (getUsersOnlyCostCenterQuery.data) {
			setOpen(true);
		} else {
			toast.error(
				'Não foi possível buscar os colaboradores do centro de custo. '
			);
		}
	}

	function handleClose() {
		setOpen(false);
		setAllocatedCardsConfirmation(false);
		setAllocatedUsersConfirmation(false);
		setUsersNewCostCenter([]);
		reset();
	}

	function onPasswordValidationSuccess() {
		deleteCostCenterMutation.mutate(usersNewCostCenter);
	}

	function setUserNewCostCenter(userId: string, costCenterId: string | null) {
		if (costCenterId) {
			setUsersNewCostCenter([
				...usersNewCostCenter.filter((u) => u.user_id !== userId),
				{ user_id: userId, new_cost_center_id: costCenterId },
			]);
		} else {
			setUsersNewCostCenter(
				usersNewCostCenter.filter((u) => u.user_id !== userId)
			);
		}
	}

	const usersColumns = useMemo(
		() => [
			usersColumnHelper.accessor('user_name', {
				header: 'Nome',
				cell: (info) => info.getValue(),
			}),
			usersColumnHelper.display({
				header: 'Novo centro de custo',
				meta: {
					align: 'end',
				},
				cell: (props) => (
					<SelectUsersNewCostCenter
						user={{ ...props.row.original, costCenterId: costCenter.id }}
						newCostCenterId={
							usersNewCostCenter.find(
								(u) => u.user_id === props.row.original.user_id
							)?.new_cost_center_id ?? null
						}
						onCostCenterSelection={(c, u) => setUserNewCostCenter(u, c)}
					/>
				),
			}),
		],
		[usersNewCostCenter] // eslint-disable-line react-hooks/exhaustive-deps
	);

	const UsersTable = () => (
		<TableStyle>
			<Table
				columns={usersColumns}
				data={getUsersOnlyCostCenterQuery.data ?? []}
				idPath='user_id'
				maxHeight='22rem'
			/>
		</TableStyle>
	);

	// 1st Content
	const CostCenterCardsContent = () => (
		<ModalContainer>
			<CardContainer
				className='header'
				direction='vertical'
				titleComponent={
					<TitleWrapper>
						<div>
							<span>Você possui&nbsp;</span>
							<span style={{ fontWeight: 600 }}>cartões alocados</span>
						</div>
						<span>neste centro de custo</span>
					</TitleWrapper>
				}
				iconSrc={cardRemoveIcon}
				baseColor='#FFFFFF'
			>
				<div
					style={{
						backgroundColor: '#FFFFFF',
						width: '44rem',
						padding: '0rem 5.0rem 0rem 5.0rem',
					}}
				>
					<Typography
						weight='600'
						size='1.4rem'
						style={{ height: '3rem', fontFamily: 'Poppins' }}
					>
						Os cartões alocados serão&nbsp;
						<span style={{ color: 'var(--primary-red)' }}>cancelados</span>
					</Typography>
					<Typography
						size='1.2rem'
						color='#575757'
						style={{
							width: '32rem',
							paddingBottom: '1rem',
							textAlign: 'justify',
						}}
					>
						O saldo restante no cartão será transferido automaticamente para a
						sua conta principal.
					</Typography>

					<ContentContainer>
						<Typography size='1.6rem ' weight='600'>
							Total de cartões alocados - {costCenterCards.length ?? ''}
						</Typography>

						<TableStyle>
							<Table
								headerBackground={'white'}
								variant={'light'}
								columns={cardsColumns}
								data={costCenterCards}
								maxHeight='18rem'
							/>
						</TableStyle>

						<Typography weight='600'>
							Saldo total a restituir:&nbsp;
							<span style={{ color: 'var(--primary-red)' }}>
								{convertCentsToFormattedReais(costCenterCardsBalance)}
							</span>
						</Typography>
					</ContentContainer>

					<OptionsBottonContainer>
						<Button
							intent='terciary'
							roundness='lg'
							$outline
							onClick={handleClose}
						>
							Voltar
						</Button>
						<Button
							intent='secondary'
							roundness='lg'
							onClick={() => {
								setAllocatedCardsConfirmation(true);
							}}
						>
							Continuar
						</Button>
					</OptionsBottonContainer>
				</div>
			</CardContainer>
		</ModalContainer>
	);

	// 2nd Content
	const CostCentersOnlyUsersContent = () => (
		<ModalContainer>
			<CardContainer
				className='header'
				direction='vertical'
				titleComponent={
					<TitleWrapper>
						<div>
							<span>Você possui&nbsp;</span>
							<span style={{ fontWeight: 600 }}>usuários alocados</span>
						</div>
						<span>neste centro de custo</span>
					</TitleWrapper>
				}
				iconSrc={userRemoveIcon}
			>
				<div
					style={{
						backgroundColor: '#FFFFFF',
						width: '44rem',
						padding: '0rem 5.0rem 0rem 5.0rem',
					}}
				>
					<ContentContainer>
						<Typography weight='600'>
							Os usuários ficarão&nbsp;
							<span style={{ color: 'var(--primary-red)' }}>
								sem centro de custo
							</span>
						</Typography>
						<Typography
							size='1.2rem'
							style={{ width: '32rem', textAlign: 'justify' }}
						>
							Você poderá mover manualmente os usuários para um novo centro de
							custo no menu abaixo ou continuar e realizar essa configuração
							após na seção de colaboradores.
						</Typography>

						<UserTableStyle>
							<Table
								headerBackground={'white'}
								variant={'light'}
								columns={usersColumns}
								data={getUsersOnlyCostCenterQuery.data ?? []}
								idPath='user_id'
								maxHeight='22rem'
							/>
						</UserTableStyle>

						<PasswordContainer>
							<PasswordInputLock
								label='Insira a sua senha'
								id='password'
								name='password'
								register={register}
								autoFocus
								autoComplete='new-password'
							/>
						</PasswordContainer>
					</ContentContainer>
					<OptionsBottonContainer>
						<Button
							intent='terciary'
							roundness='lg'
							$outline
							onClick={
								areThereCardsAllocated
									? () => setAllocatedCardsConfirmation(false)
									: handleClose
							}
						>
							Voltar
						</Button>
						<Button
							intent='secondary'
							roundness='lg'
							onClick={() => {
								setAllocatedUsersConfirmation(true);
							}}
							disabled={!passwordInputValue}
						>
							Continuar
						</Button>
					</OptionsBottonContainer>
				</div>
			</CardContainer>
		</ModalContainer>
	);

	// 3rd Content
	const ConfirmationContent = () => {
		function handleBack() {
			reset();
			if (areThereUsersAllocatedOnlyInTheCostCenter) {
				setAllocatedUsersConfirmation(false);
			} else if (areThereCardsAllocated) {
				setAllocatedCardsConfirmation(false);
			} else {
				handleClose();
			}
		}

		return (
			<ModalContainer>
				<CardContainer
					className='header'
					direction='vertical'
					titleComponent={
						<TitleWrapper>
							<div>
								<span>Você confirma a&nbsp;</span>
								<span style={{ fontWeight: 600 }}>a exclusão</span>
							</div>
							<span style={{ fontWeight: 600 }}>do centro de custo?</span>
						</TitleWrapper>
					}
					iconSrc={confirmCostCenterExclusionIcon}
				>
					<div
						style={{
							backgroundColor: '#FFFFFF',
							width: '44rem',
							padding: '0rem 5.0rem 0rem 5.0rem',
						}}
					>
						<ContentContainer>
							{areThereUsersAllocatedOnlyInTheCostCenter && (
								<Typography weight='600'>
									Os usuários serão realocados para o novo centro de custo
								</Typography>
							)}
							{areThereUsersAllocatedOnlyInTheCostCenter && (
								<Typography size='1.2rem'>
									Caso deseje, você poderá mover manualmente os usuários para um
									novo centro de custo posteriormente na aba de colaboradores.
								</Typography>
							)}
							{areThereUsersAllocatedOnlyInTheCostCenter && <UsersTable />}

							<PasswordContainer>
								<PasswordInputLock
									label='Insira a sua senha'
									id='password'
									name='password'
									register={register}
									autoFocus
									autoComplete='new-password'
									errorMessage={errors.password?.message}
								/>
							</PasswordContainer>
						</ContentContainer>

						<OptionsBottonContainer>
							<Button
								intent='terciary'
								roundness='lg'
								$outline
								onClick={handleBack}
							>
								Voltar
							</Button>
							<Button
								intent='secondary'
								roundness='lg'
								onClick={() => {
									validateUserPassword(passwordInputValue);
								}}
								loading={
									isPasswordValidationLoading ||
									deleteCostCenterMutation.isLoading
								}
								shrinkOnLoading={false}
								disabled={!passwordInputValue}
							>
								Finalizar
							</Button>
						</OptionsBottonContainer>
					</div>
				</CardContainer>
			</ModalContainer>
		);
	};

	const Content = () => {
		if (areThereCardsAllocated && !allocatedCardsConfirmation) {
			return <CostCenterCardsContent />;
		} else if (
			areThereUsersAllocatedOnlyInTheCostCenter &&
			!allocatedUsersConfirmation
		) {
			return <CostCentersOnlyUsersContent />;
		}
		return <ConfirmationContent />;
	};

	return (
		<div>
			<Button intent='link' onClick={handleOpenModal} type='button'>
				Deletar centro de custo
			</Button>

			<Modal isOpen={isOpen} onRequestClose={handleClose}>
				<Content />
			</Modal>
		</div>
	);
}

const ContentContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	row-gap: 1rem;
`;
const OptionsBottonContainer = styled.div`
	display: flex;
	justify-content: space-around;
	padding-top: 2rem;
`;

const ModalContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;
	padding: 0rem 0rem 3.2rem 0rem;
	border-radius: 1.6rem 0rem 0rem 0rem;
	gap: 3.2rem;
	background: #ffffff;
`;

const CardContainer = styled(Card)`
	flex: 1;
	width: 100%;

	&.header {
		& > div:first-child {
			height: 17rem;
		}
	}

	& > div {
		background-color: #f0f3f8;

		@media (max-width: 1024px) {
			width: 100% !important;
		}
	}
`;

const PasswordContainer = styled.div`
	fieldSet > label {
		color: #929292;
		font: 'Poppins', sans-serif;
		font-weight: 400;
		size: 1.2rem;
		line-height: 2.4rem;
	}

	input {
		background-color: #f0f3f8;
		padding: 0.8rem 1.6rem 0.8rem 1.6rem;
		gap: 2.4rem;
		height: 4rem;
	}
`;

const TableStyle = styled.div`
	max-height: 30rem;
	overflow-y: auto;
	td:nth-child(3) {
		color: var(--primary-red);
	}
`;

const UserTableStyle = styled.div`
	max-height: 30rem;
	overflow-y: auto;
`;

const TitleWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;

	& > div,
	& > span {
		font-family: Poppins;
		font-size: 2rem;
		font-weight: 400;
		line-height: 3rem;
		text-align: center;
	}

	& > div {
		display: flex;
		justify-content: center;
		align-items: center;
	}
`;
