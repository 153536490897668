export function parseContractTypeToEnglish(contract: string) {
	if (contract.toLowerCase() === 'estágio') {
		return 'TRAINEE';
	} else if (contract.toLowerCase() === 'clt') return 'CLT';
	else if (contract.toLowerCase() === 'sócio') return 'SHAREHOLDER';
	else if (contract.toLowerCase() === 'pj') return 'PJ';

	throw new Error('Tipo de contrato do colaborador inválido');
}

export function parseContractTypeToPortugues(contract: string) {
	if (contract === 'TRAINEE') {
		return 'Estágio';
	} else if (contract === 'CLT') return 'CLT';
	else if (contract === 'PJ') return 'PJ';
	else if (contract === 'SHAREHOLDER') return 'Sócio';

	throw new Error('Tipo de contrato do colaborador inválido');
}
