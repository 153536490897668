// Typography.tsx
import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';
import Boomerang from '../../assets/Boomerang.svg';

interface Props extends HTMLAttributes<HTMLHeadingElement> {
	size?: string;
	weight?: string;
	color?: string;
	children?: React.ReactNode;
	as?: keyof JSX.IntrinsicElements;
}

export const Typography = ({ children, ...rest }: Props) => {
	return <Text {...rest}>{children}</Text>;
};

interface TitleTypographyProps extends Props {
	primaryText?: string;
	color_primary?: string;
	image?: boolean;
}
export const TitleTypography = ({
	children,
	primaryText,
	color_primary = 'var(--primary-blue)',
	image = false,
	...rest
}: TitleTypographyProps) => {
	return (
		<Title {...rest}>
			{image && <img src={Boomerang} alt='Boomerang Bounty' />}
			{primaryText && (
				<Title {...rest} as='span' color={color_primary}>
					{primaryText}&nbsp;
				</Title>
			)}
			{children}
		</Title>
	);
};

const Text = styled.p<Props>`
	display: flex;
	align-items: center;
	font-size: ${(props) => props.size || '1.4rem'};
	font-weight: ${(props) => props.weight || 'normal'};
	color: ${(props) => props.color || 'inherit'};
`;

const Title = styled.h1<Props>`
	font-size: ${(props) => props.size || '4rem'};
	font-weight: ${(props) => props.weight || 'bold'};
	color: ${(props) => props.color || 'inherit'};
	display: flex;
	align-items: center;
	& > img:first-child {
		margin-right: 1rem;
	}
`;
