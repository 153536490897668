import { useEffect, useRef, useState } from 'react';
import { FaUserCog } from 'react-icons/fa';
import { useQuery } from 'react-query';
import { Operator } from '../../../../../@types';
import { Filter, FilterParams } from '../../../../../components/Filter';
import Modal from '../../../../../components/Modal';
import {
	getFilteredOperatorsChat,
	getOperatorsChat,
	OperatorsListReturn,
} from '../../../../../services/queries/Operators';
import avatarImg from '../../../../../assets/avatar.svg';
import * as S from './styles';
import * as TableStyle from '../../../../../components/Table/TableStyles';
import { RiAddCircleLine, RiCloseCircleLine } from 'react-icons/ri';
import Loader from '../../../../../components/Loader';
import PageTitle from '../../../../../components/PageTitle';
import { Pagination } from '../../../../../components/Pagination';
import { EmptyContent } from '../../../../../components/EmptyContent';
import { showErrorMessage } from '../../../../../utils/ErrorHandler';

export interface OperatorSelectorProps {
	alreadyAddedOperator: string; // already added Operator if selected
	onSelectOperator(operator: Operator): void;
}

export function OperatorSelectorModal({
	alreadyAddedOperator,
	onSelectOperator,
}: OperatorSelectorProps) {
	const [isOpen, setIsOpen] = useState(false);
	const listContainer = useRef<HTMLUListElement | null>(null);

	const [operatorsCurrentPage, setOperatorsCurrentPage] = useState(1);

	const [filtersParams, setFiltersParams] = useState<FilterParams[]>([
		{ name: 'Nome', filter: 'name', value: '', type: 'text', selected: false },
		{ name: 'CPF', filter: 'cpf', value: '', type: 'cpf', selected: false },
		{
			name: 'Email',
			filter: 'email',
			value: '',
			type: 'email',
			selected: false,
		},
		{
			name: 'Cargo',
			filter: 'office',
			value: '',
			type: 'text',
			selected: false,
		},
	]);

	const getOperatorsListQuery = useQuery<OperatorsListReturn, Error>(
		['operatorsList', operatorsCurrentPage],
		() => {
			return getOperatorsChat(operatorsCurrentPage);
		},
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível buscar os operadores. '
				);
			},
			enabled: isOpen,
			refetchOnWindowFocus: false,
			keepPreviousData: true,
		}
	);

	const filteredOperators = useQuery<OperatorsListReturn, Error>(
		['filteredOperators', filtersParams, operatorsCurrentPage],
		() => {
			return getFilteredOperatorsChat(filtersParams, operatorsCurrentPage);
		},
		{
			onError: (error) => {
				showErrorMessage(
					error as Error,
					'Não foi possível buscar os operadores. '
				);
			},
			enabled: !!filtersParams.find((f) => !!f.value) && !!operatorsCurrentPage,
		}
	);

	function getTableTotalRecords() {
		if (filtersParams.find((f) => !!f.value)) {
			return filteredOperators.data?.totalUsersOperator;
		}
		return getOperatorsListQuery.data?.totalUsersOperator;
	}

	useEffect(() => {
		listContainer.current?.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
	}, [operatorsCurrentPage, listContainer]);

	function handleSelectOperator(operator: Operator) {
		onSelectOperator(operator);
		setIsOpen(false);
	}

	function checkSelectedOperator(operatorEmail: string) {
		return !!(alreadyAddedOperator && alreadyAddedOperator! === operatorEmail);
	}

	function updateFilters(filters: FilterParams[]) {
		setOperatorsCurrentPage(1);
		setFiltersParams(filters);
	}

	function getOperatorsList() {
		return !!filtersParams.find((f) => !!f.value)
			? filteredOperators.data?.users
			: getOperatorsListQuery.data?.users;
	}

	if (
		getOperatorsListQuery.isLoading ||
		filteredOperators.isLoading ||
		getOperatorsListQuery.isPreviousData ||
		filteredOperators.isPreviousData
	) {
		return (
			<div>
				<S.SelectOperatorButton
					onClick={(e) => {
						e.preventDefault();
						setIsOpen(true);
					}}
					data-rh='Delegar chat para outro operador'
				>
					<FaUserCog />
				</S.SelectOperatorButton>
				<Modal
					isOpen={isOpen}
					onRequestClose={() => setIsOpen(false)}
					enableClose={true}
				>
					<S.Container>
						<Loader />
					</S.Container>
				</Modal>
			</div>
		);
	}
	return (
		<>
			<S.SelectOperatorButton
				onClick={(e) => {
					e.preventDefault();
					setIsOpen(true);
				}}
				data-rh='Delegar chat para outro operador'
				data-testid='OperatorSelectorModal-button'
			>
				<FaUserCog />
			</S.SelectOperatorButton>

			<Modal
				isOpen={isOpen}
				onRequestClose={() => setIsOpen(false)}
				enableClose={true}
			>
				<S.Container>
					<TableStyle.TableHeaderContainer>
						<PageTitle
							title='Selecione um operador'
							totalRecords={getTableTotalRecords() ?? 0}
						/>
						<Filter
							filterParams={filtersParams}
							onFiltersChanged={(updatedFilters) => {
								updateFilters(updatedFilters);
							}}
						/>
						{filtersParams.some((f) => !!f.value) ? (
							<Pagination
								onPageChange={(page) => setOperatorsCurrentPage(page)}
								currentPage={operatorsCurrentPage}
								totalCount={
									filteredOperators.data
										? filteredOperators.data.totalUsersOperator!
										: 1
								}
							/>
						) : (
							<Pagination
								onPageChange={(page) => setOperatorsCurrentPage(page)}
								currentPage={operatorsCurrentPage}
								totalCount={
									getOperatorsListQuery.data
										? getOperatorsListQuery.data.totalUsersOperator!
										: 1
								}
							/>
						)}
					</TableStyle.TableHeaderContainer>

					<S.OperatorsList>
						{getOperatorsList()?.length ? (
							getOperatorsList()?.map((operator) => (
								<S.OperatorContainer key={operator.id}>
									<S.OperatorCard>
										<S.OperatorAvatar
											src={operator.avatar ? operator.avatar_url : avatarImg}
										/>

										<S.OperatorInfoContainer>
											<S.OperatorName>{`${operator.name}`}</S.OperatorName>
											<S.OperatorInfo>{operator.office}</S.OperatorInfo>
											<S.OperatorInfo>{operator.email}</S.OperatorInfo>
										</S.OperatorInfoContainer>
									</S.OperatorCard>

									{checkSelectedOperator(operator.email!) ? (
										<S.Selector selected={true} disabled>
											<RiCloseCircleLine />
										</S.Selector>
									) : (
										<S.Selector
											selected={false}
											onClick={() => handleSelectOperator(operator)}
											data-testid={`${operator.id}-select`}
										>
											<RiAddCircleLine />
										</S.Selector>
									)}
								</S.OperatorContainer>
							))
						) : (
							<EmptyContent text='Nenhum operador para ser exibido.' />
						)}
					</S.OperatorsList>
				</S.Container>
			</Modal>
		</>
	);
}
