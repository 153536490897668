import { SelectHTMLAttributes } from 'react';
import styled from 'styled-components';
import { BaseFieldProps, Field } from './Field';
import { BaseInputStyle } from './BaseStyle';
import { MdErrorOutline } from 'react-icons/md';

interface Props
	extends SelectHTMLAttributes<HTMLSelectElement>,
		BaseFieldProps {
	placeholderText?: boolean;
	errorMessage?: string;
}

export function SelectField({ placeholderText = true, ...props }: Props) {
	return (
		<Field label={props.label} name={props.name}>
			<SelectInput
				{...(props.register ? { ...props.register(props.name!) } : {})}
				defaultValue=''
				{...props}
			>
				{placeholderText && (
					<option value='' disabled>
						Selecione uma opção
					</option>
				)}
				{props.children}
			</SelectInput>
			{props.errorMessage && (
				<span>
					<MdErrorOutline /> {props.errorMessage}
				</span>
			)}
		</Field>
	);
}

export const SelectInput = styled.select`
	${BaseInputStyle}
	padding: 0 1.6rem;
	border-right: 1.6rem solid transparent;
`;
