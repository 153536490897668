import { useParams } from 'react-router-dom';
import PageTitle from '../../../../components/PageTitle';
import avatarImg from '../../../../assets/avatar.svg';
import * as S from './styles';
import { RiDeleteBin5Fill } from 'react-icons/ri';
import { toast } from 'react-toastify';
import Loader from '../../../../components/Loader';
import { EmptyContent } from '../../../../components/EmptyContent';
import { Collaborator, Group } from '../../../../@types';
import { CollabsSelector } from '../../../../components/CollabsSelector';
import axios from 'axios';
import { getMessageError } from '../../../../utils/DefaultErrors';
import { useAuth } from '../../../../hooks/useAuth';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
	setGroupCollaborators,
	fetchGroup,
	updateGroupStatus,
} from '../../../../services/queries/Groups';
import { FilteredCollabsSelector } from '../../../../components/CollabsSelector/FilteredCollabsSelector';
import { showErrorMessage } from '../../../../utils/ErrorHandler';

interface ManageGroupsParams {
	id: string;
}
const MAX_COLLABORATORS = Number(process.env.REACT_APP_MAX_PAYMENTS);

export function ManageGroups() {
	const { currentCompany } = useAuth();
	const queryClient = useQueryClient();
	const { id } = useParams<ManageGroupsParams>();

	const fetchGroupQuery = useQuery<Group, Error>(
		['fetchGroup', currentCompany?.id, id],
		() => {
			return fetchGroup(id, currentCompany?.id);
		},
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao buscar o grupo. '
				);
			},
		}
	);

	const setGroupCollaboratorsQuery = useMutation(
		async (collaboratorsIds: string[]) => {
			return setGroupCollaborators(
				fetchGroupQuery.data!.id,
				collaboratorsIds,
				currentCompany?.id
			);
		},
		{
			onSuccess: () => {
				queryClient.resetQueries('fetchGroup');
			},
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao tentar atualizar os colaboradores do grupo. '
				);
			},
		}
	);

	function getCollaboratorsToAddIds(collabsToAdd: Collaborator[]) {
		const collabsIds = [
			...collabsToAdd.map((c) => {
				return c.id!;
			}),
			...fetchGroupQuery.data!.users.map((c) => {
				return c.id!;
			}),
		];

		return collabsIds;
	}

	function removeCollaboratorsById(
		collaborators: Collaborator[],
		collabsToRemove: Collaborator[]
	) {
		collabsToRemove.forEach((collabToRemove) => {
			const indexToRemove = collaborators!.findIndex(
				(collab) => collab.id === collabToRemove.id
			);

			if (indexToRemove > -1) {
				collaborators!.splice(indexToRemove, 1);
			}
		});

		return collaborators;
	}

	async function handleAddCollaborators(collaborators: Collaborator[]) {
		const collabsIds = getCollaboratorsToAddIds(collaborators);

		if (isExceedingGroupLimit(collabsIds.length)) {
			toast.error(
				`O número máximo de colaboradores por grupo é ${MAX_COLLABORATORS}. O limite foi ultrapassado em ${getCollabsExceededQuant(
					collabsIds.length
				)}.`
			);
			return;
		}

		try {
			await setGroupCollaboratorsQuery.mutateAsync(collabsIds);
			toast.info('Colaboradores adicionados ao grupo.');
		} catch (err) {
			console.log(err);
		}
	}

	async function handleRemoveCollaborator(collabId: string) {
		const collabsIds = fetchGroupQuery
			.data!.users.filter((c) => c.id !== collabId)
			.map((c) => c.id!);
		try {
			await setGroupCollaboratorsQuery.mutateAsync(collabsIds);
			toast.info('Colaborador removido do grupo.');
		} catch (err) {
			console.log(err);
		}
	}

	async function handleRemoveCollaborators(collabsToRemove: Collaborator[]) {
		let collabs = fetchGroupQuery.data!.users;
		collabs = removeCollaboratorsById(collabs, collabsToRemove);
		const collabsIds = collabs.map((c) => c.id!);

		try {
			await setGroupCollaboratorsQuery.mutateAsync(collabsIds);
			toast.info('Colaboradores removidos do grupo.');
		} catch (err) {
			console.log(err);
		}
	}

	async function handleAddAndRemoveCollabs(
		collabsToAdd: Collaborator[],
		collabsToRemove: Collaborator[]
	) {
		let collabsIds = getCollaboratorsToAddIds(collabsToAdd);

		collabsToRemove.forEach((collabToRemove) => {
			const indexToRemove = collabsIds!.findIndex(
				(collabsIds) => collabsIds === collabToRemove.id
			);

			if (indexToRemove > -1) {
				collabsIds!.splice(indexToRemove, 1);
			}
		});

		if (isExceedingGroupLimit(collabsIds.length)) {
			toast.error(
				`O número máximo de colaboradores por grupo é ${MAX_COLLABORATORS}. O limite foi ultrapassado em ${getCollabsExceededQuant(
					collabsIds.length
				)}.`
			);
			return;
		}

		try {
			await setGroupCollaboratorsQuery.mutateAsync(collabsIds);
			toast.info('Colaboradores adicionados ao grupo.');
			toast.info('Colaboradores removidos do grupo.');
		} catch (err) {
			console.log(err);
		}
	}

	const toggleStatus = useMutation(
		async () => {
			return updateGroupStatus(
				fetchGroupQuery.data!.id,
				!fetchGroupQuery.data!.status,
				currentCompany?.id
			);
		},
		{
			onSuccess: () => {
				toast.info('Status atualizado com sucesso.');
				queryClient.resetQueries('fetchGroup');
			},
			onError: (err) => {
				console.log(err);
				if (axios.isAxiosError(err)) {
					toast.error(
						'Ocorreu um problema ao tentar atualizar o status. ' +
							getMessageError(err.response?.data)
					);
				} else {
					toast.error(
						'Ocorreu um problema ao tentar atualizar o status. Tente novamente.'
					);
				}
			},
		}
	);

	function isExceedingGroupLimit(collabsQuantity: number) {
		return collabsQuantity > MAX_COLLABORATORS ? true : false;
	}

	function getCollabsExceededQuant(collabsQuantity: number) {
		return collabsQuantity - MAX_COLLABORATORS;
	}

	async function handleToggleStatus() {
		toggleStatus.mutate();
	}

	if (
		fetchGroupQuery.isLoading ||
		setGroupCollaboratorsQuery.isLoading ||
		toggleStatus.isLoading ||
		!fetchGroupQuery.data ||
		fetchGroupQuery.isPreviousData
	) {
		return (
			<S.Container data-testid='manage_group_test_id'>
				<Loader />
			</S.Container>
		);
	}
	return (
		<S.Container data-testid='group_info_test_id'>
			<PageTitle title={fetchGroupQuery.data!.title} />
			<S.Description>{fetchGroupQuery.data!.description}</S.Description>

			<S.CollaboratorsContainer>
				<S.CollaboratorsTitle>
					Colaboradores nesse grupo (
					{fetchGroupQuery.data!.total_users
						? fetchGroupQuery.data!.total_users
						: 0}
					)
				</S.CollaboratorsTitle>
				<S.CollaboratorsList>
					{!fetchGroupQuery.data!.users.length ? (
						<EmptyContent text='Nenhum colaborador adicionado ao grupo' />
					) : (
						fetchGroupQuery.data!.users.map((collaborator) => (
							<S.CollaboratorCard key={collaborator.id}>
								<S.AvatarContainer>
									<S.Avatar
										src={
											collaborator.avatar ? collaborator.avatar_url : avatarImg
										}
									/>
									<S.CollabName>{`${collaborator.first_name} ${collaborator.last_name}`}</S.CollabName>
								</S.AvatarContainer>

								<S.CollabOffice>{collaborator.office}</S.CollabOffice>

								<S.RemoveCollabBtn
									data-testid='remove_collab_test_id'
									onClick={() => {
										handleRemoveCollaborator(collaborator.id!!);
									}}
								>
									<RiDeleteBin5Fill />
								</S.RemoveCollabBtn>
							</S.CollaboratorCard>
						))
					)}
				</S.CollaboratorsList>
			</S.CollaboratorsContainer>

			<S.OptionsContainer>
				<CollabsSelector
					alreadyAddedCollabs={fetchGroupQuery.data!.users}
					onAddNewCollaborators={handleAddCollaborators}
					onAddCollaboratorsToRemove={handleRemoveCollaborators}
					onAddAndRemoveCollabs={handleAddAndRemoveCollabs}
					allowRemoveCollaborators={true}
					maxCollaboratorsAllowed={
						MAX_COLLABORATORS - fetchGroupQuery.data.total_users
					}
				/>
				<FilteredCollabsSelector
					alreadyAddedCollabs={fetchGroupQuery.data.users}
					onAddNewCollaborators={handleAddCollaborators}
					maxCollaboratorsAllowed={
						MAX_COLLABORATORS - fetchGroupQuery.data.total_users
					}
				/>

				<S.SecondaryBtn
					onClick={handleToggleStatus}
					data-testid='toggle_status_test_id'
				>
					{fetchGroupQuery.data!.status ? 'Desativar Grupo' : 'Ativar Grupo'}
				</S.SecondaryBtn>
			</S.OptionsContainer>
		</S.Container>
	);
}
