import styled from 'styled-components';
import { Link } from 'react-router-dom';

interface ContainerProps {
	$current_page: boolean;
}

export const Container = styled(Link)<ContainerProps>`
	text-decoration: none;
	display: flex;
	align-items: center;
	margin: 0.8rem 0;
	background-color: ${({ $current_page }) =>
		$current_page ? 'rgba(39, 173, 255, 0.15)' : 'none'};
	padding: 0.5rem;
	border-radius: 0.8rem;
	svg {
		color: ${({ $current_page }) => ($current_page ? '#27ADFF' : '#575757')};
		height: 2rem;
		width: 2rem;
		margin-right: 1.5rem;
	}

	&:hover {
		filter: none;
	}
`;

export const Title = styled.span`
	color: var(--dark-gray);
	font-size: 1.6rem;
`;

export const ChatsOpenIcon = styled.div<ContainerProps>`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 20px;
	height: 20px;
	margin-left: auto;
	border-radius: 50px;
	border: 0.1rem solid;
	border-color: #43f8a7;
	background-color: #43f8a7;
	color: #f0f2ff;
	font-size: 1.2rem;
	font-weight: bolder;
`;
