import ModelImage from '../../../../assets/ModelSigInMaleComputer.png';
import ResponsiveModelImage from '../../../../assets/ModelSignInFemale.png';
import * as S from './styles';
import { useAuth } from '../../../../hooks/useAuth';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import bountyLogo from '../../../../assets/BountyRed.svg';
import { ContentCard } from '../../../../componentsV2/ContentCard';
import { MdKeyboardReturn } from 'react-icons/md';
import { CorporateExpensesTheme } from '../../../../styles/CorporateExpensesTheme';
import benefits3D from '../../../../assets/benefits-card-3D.png';
import control3D from '../../../../assets/control-card-3D.png';

export function SelectProductSingleCompany() {
	const history = useHistory();
	const { user, companies, signOut, changeCurrentCompany } = useAuth();
	const company = companies[0];

	const hasFlexibleBenefitsAccess =
		user.flexible_benefits_access &&
		company.company_products.product_flexible_benefits_enabled;
	const hasControlAccess =
		user.corporate_expenses_access &&
		company.company_products.product_corporate_expenses_enabled;

	useEffect(() => {
		if (companies.length) {
			changeCurrentCompany(companies[0].id);
		}
	}, [companies]); // eslint-disable-line

	function handleFlexibleBenefitsSelection() {
		history.push('/home');
	}
	function handleCorporateExpensesSelection() {
		history.push('/corporate-expenses');
	}

	return (
		<S.Container>
			<CorporateExpensesTheme />
			<S.LeftContainer>
				<img src={ModelImage} className='modelImage' alt='Men with computer' />
				<img
					src={ResponsiveModelImage}
					className='ResponsiveModelImage'
					alt='Girl'
				/>
			</S.LeftContainer>

			<S.RightContainer>
				<S.MainContainer>
					<div style={{ display: 'flex', justifyContent: 'space-between' }}>
						<img src={bountyLogo} alt='Logo da Bounty' />
						<S.SignOutBtn className='responsiveButton' onClick={signOut}>
							<MdKeyboardReturn /> Voltar
						</S.SignOutBtn>
					</div>
					<div>
						<h2>Acesse</h2>
						<p className='textDetails'>
							Selecione a solução para começar a usá-la.
						</p>
					</div>

					<S.OptionsContainer>
						{hasFlexibleBenefitsAccess && (
							<ContentCard
								className='cardBenefitsContainer'
								image={benefits3D}
								onClick={handleFlexibleBenefitsSelection}
								productScreen={true}
							>
								<div className='contentWrapper'>
									<span>Bounty</span>
									<p>Benefícios</p>
									<span className='access'>Acessar {`>`}</span>
								</div>
							</ContentCard>
						)}
						{hasControlAccess && (
							<ContentCard
								className='cardControlContainer'
								image={control3D}
								onClick={handleCorporateExpensesSelection}
								productScreen={true}
							>
								<div className='contentWrapper'>
									<span>Bounty</span>
									<p>Control</p>
									<span className='access'>Acessar {`>`}</span>
								</div>
							</ContentCard>
						)}
					</S.OptionsContainer>
					<S.SignOutBtn className='backButton' onClick={signOut}>
						<MdKeyboardReturn /> Voltar para login
					</S.SignOutBtn>
				</S.MainContainer>
			</S.RightContainer>
		</S.Container>
	);
}
