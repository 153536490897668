import { useState, useEffect } from 'react';
import PaymentTable from './components/PaymentTable/PaymentTableEdit';
import { AddToTableOptions } from './components/AddToTableOptions';
import { SubmitPayment } from './components/SubmitPaymentCreation';
import PageTitle from '../../../components/PageTitle';
import * as S from './styles';
import { usePayment } from '../../../hooks/usePaymentV2';
import { getBenefitsInputs } from './utils/getBenefitValuesInputs';
import {
	addBenefitInputEventListener,
	removeBenefitInputEventListener,
} from './utils/setBenefitInputEventListener';
import { MAX_PAYMENTS } from './utils/MAX_PAYMENTS';
import { PaymentsAmount } from './components/PaymentsAmount';
import { calculatePaymentsAmount } from './utils/calculatePaymentsAmout';

export function CreatePayment() {
	const { beneficiaries } = usePayment();
	const [paymentsAmount, setPaymentsAmount] = useState(0);

	useEffect(() => {
		if (beneficiaries.length === 0) return;

		const inputs = getBenefitsInputs();

		inputs.forEach((input) => {
			addBenefitInputEventListener(input, setCalculatedPaymentsAmount);
		});

		return () => {
			inputs.forEach((input) => {
				removeBenefitInputEventListener(input, setCalculatedPaymentsAmount);
			});
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [beneficiaries]);

	useEffect(() => {
		setCalculatedPaymentsAmount();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [beneficiaries]);

	const setCalculatedPaymentsAmount = () => {
		setPaymentsAmount(calculatePaymentsAmount(beneficiaries));
	};

	return (
		<S.Container>
			<PageTitle title='Detalhes do Pagamento' />

			{/* TABLE COMPONENT THAT RENDERS COLLABORATORS AND THEIR BENEFITS */}
			<PaymentTable />

			<PaymentsAmount paymentsAmount={paymentsAmount} />

			<S.FooterContainer>
				{/* INCLUDE COLLABORATORS, GROUPS and BENEFITS OPTIONS*/}
				<AddToTableOptions paymentsAmount={paymentsAmount} />

				{/* Submit component reponsible for all pre-release verifications steps + submitting to API */}
				<SubmitPayment disabled={paymentsAmount > MAX_PAYMENTS} />
			</S.FooterContainer>
		</S.Container>
	);
}
