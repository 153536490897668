import { useState } from 'react';
import { Popover } from 'react-tiny-popover';
import * as S from './styles';
import { FaCheckCircle, FaFileImport } from 'react-icons/fa';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
	getBatchesProgress,
	removeBatchesFiles,
} from '../../services/queries/Collaborators';
import { useAuth } from '../../hooks/useAuth';
import Loader from '../Loader';
import { EmptyContent } from '../EmptyContent';
import { toast } from 'react-toastify';

export function CollaboratorsImportList() {
	const [isOpen, setOpen] = useState(false);
	return (
		<Popover
			isOpen={isOpen}
			positions={['bottom', 'left', 'right']} // preferred positions by priority
			onClickOutside={() => setOpen(false)}
			content={<BatchesProgressList />}
		>
			<S.IconBtn onClick={() => setOpen((old) => !old)}>
				<FaFileImport />
			</S.IconBtn>
		</Popover>
	);
}

const CONCLUDED_FILE_STR = '100.00%';
export const BatchesProgressList = () => {
	const { currentCompany } = useAuth();
	const queryClient = useQueryClient();

	const batchesProgressQuery = useQuery(
		['getBatchesProgress', currentCompany?.id],
		() => getBatchesProgress(currentCompany!.id),
		{
			enabled: !!currentCompany,
			onError: () => {
				toast.error(
					'Ocorreu um problema ao buscar as importações de colaboradores na fila.'
				);
			},
		}
	);

	const removeBatchFileMutation = useMutation(
		({ filesIds }: { filesIds: string[] }) =>
			removeBatchesFiles(filesIds, currentCompany!.id),
		{
			onError: () => {
				toast.error(
					'Ocorreu um problema ao tentar remover os arquivos da listagem de importações.'
				);
			},
			onSuccess: () => {
				queryClient.invalidateQueries('getBatchesProgress');
			},
		}
	);

	function handleRemoveAllFinishedFiles() {
		const filesIds = batchesProgressQuery
			.data!.dataProgress.filter((d) => d.progress === CONCLUDED_FILE_STR)
			.map((dataProgress) => {
				return dataProgress.id;
			});

		if (filesIds.length > 0) removeBatchFileMutation.mutate({ filesIds });
	}

	if (!batchesProgressQuery.data)
		return (
			<S.Container>
				<S.Title>Importações de colaboradores na fila</S.Title>
				<Loader />
			</S.Container>
		);
	return (
		<S.Container>
			<S.Title>Importações de colaboradores na fila</S.Title>
			{batchesProgressQuery.data.dataProgress.length === 0 && <EmptyContent />}

			<S.List>
				{batchesProgressQuery.data.dataProgress.map((dataProgress) => (
					<li key={dataProgress.id}>
						<S.FileName>{dataProgress.file_name}</S.FileName>
						<S.Progress>
							{dataProgress.progress === CONCLUDED_FILE_STR ? (
								<S.RemoveFileBtn
									onClick={() =>
										removeBatchFileMutation.mutate({
											filesIds: [dataProgress.id],
										})
									}
									disabled={removeBatchFileMutation.isLoading}
									data-testid={`remove-file-${dataProgress.id}`}
									data-rh='Concluir processo finalizado'
								>
									<FaCheckCircle color='#1CCA0D' />
								</S.RemoveFileBtn>
							) : (
								<span>{dataProgress.progress.replace('.00', '')}</span>
							)}
						</S.Progress>
					</li>
				))}

				<S.RemoveAllFilesBtn
					disabled={removeBatchFileMutation.isLoading}
					onClick={handleRemoveAllFinishedFiles}
				>
					{removeBatchFileMutation.isLoading ? (
						<Loader size={20} color='#FFF' />
					) : (
						'Concluir processos finalizados'
					)}
				</S.RemoveAllFilesBtn>
			</S.List>
		</S.Container>
	);
};
