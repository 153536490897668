import { FaInfoCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { OptionsButton } from '../../../../../components/Button';
import importBg from '../../../../../assets/bg-import.png';
import styled from 'styled-components';

export const Container = styled.div`
	flex: 1;
	padding: 4rem 7rem 2rem 7rem;
`;
export const UploadHeader = styled.div`
	width: 74%;
	color: var(--dark-gray);
	font-size: 1.6rem;
	padding: 1rem;
	border-bottom: 0.2rem solid var(--light-gray);
	margin: 1rem 0;
`;

export const DropContainer = styled.div`
	width: 74%;
	height: 30rem;
	margin-top: 1.5rem;
	border: 2px dashed rgba(198, 198, 198, 0.5);
	background: url(${importBg}) no-repeat center;
	border-radius: 4px;
	cursor: pointer;
	transition: height 0.2s ease;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const getMessageColors = (type: string) => {
	if (type === 'default') return 'var(--dark-gray)';
	else if (type === 'error') return 'var(--primary-red)';
	else return 'var(--primary-blue)';
};

interface UploadMessageProps {
	type?: string;
}

export const UploadMessage = styled.span<UploadMessageProps>`
	display: flex;
	flex-direction: column;
	font-size: 1.6rem;
	text-align: center;
	color: ${({ type }) =>
		type ? getMessageColors(type) : getMessageColors('default')};
	font-weight: 500;
	& span {
		margin: 1rem 0;
	}
`;

export const UploadButton = styled.button`
	${OptionsButton}
	padding: .8rem 2rem;
	color: var(--white);
	background-color: var(--primary-blue);
	margin-top: 0.5rem;
`;

export const ExtensionsInfo = styled.div`
	margin: 2rem 0;
	display: flex;
	align-items: center;
	color: var(--dark-gray);
	font-size: 1.4rem;
	font-style: italic;
`;

export const InfoIcon = styled(FaInfoCircle)`
	margin-right: 0.8rem;
	color: var(--primary-blue);
`;

export const ModelLink = styled.a`
	color: var(--primary-red);
	text-decoration: underline;
	font-style: italic;
`;

export const LinkButton = styled(Link)`
	text-decoration: none;
	display: block;
	width: fit-content;
	margin-top: 2rem;
	${OptionsButton}
	font-size: 1.4rem;
`;
