import styled from 'styled-components';
import LoaderIcon from 'react-spinners/ClipLoader';
import BarIcon from 'react-spinners/BarLoader';

interface LoaderProps {
	color?: string;
	size?: number | string;
	style?: React.CSSProperties;
	loaderType?: 'spinner' | 'bar';
}

export function Loader({
	color,
	size,
	style,
	loaderType = 'spinner',
}: LoaderProps) {
	return (
		<Container style={style} data-testid='loading-container'>
			{loaderType === 'spinner' ? (
				<Spinner loading={true} color={color} size={size} />
			) : (
				<BarLoader loading={true} color={color} width={size} />
			)}
		</Container>
	);
}

export const Container = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;
export const Spinner = styled(LoaderIcon).attrs((props) => ({
	color: props.color ? props.color : 'inherit',
	size: props.size ? props.size : 50,
	loading: props.loading,
}))``;

export const BarLoader = styled(BarIcon).attrs((props) => ({
	color: props.color ? props.color : 'inherit',
	width: props.width ? props.width : '100',
	loading: props.loading,
}))``;
