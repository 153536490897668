import { useState } from 'react';
import { RiAddCircleLine, RiCloseCircleLine } from 'react-icons/ri';
import { useMutation, useQuery } from 'react-query';
import * as S from './styles';
import { toast } from 'react-toastify';
import {
	getCollaboratorBenefits,
	UpdateBenefitParams,
	updateCollaboratorBenefits,
} from '../../../../../services/queries/Benefits';
import { showErrorMessage } from '../../../../../utils/ErrorHandler';
import { useDialogModal } from '../../../../../hooks/useDialogModal';
import Modal from '../../../../../components/Modal';
import Loader from '../../../../../components/Loader';
import { getBenefitIcon } from '../../../../../utils/benefits/getBenefitIcon';
import { getBenefitParsedTitle } from '../../../../../utils/benefits/getBenefitParsedTitle';
import { EmptyContent } from '../../../../../components/EmptyContent';

interface Props {
	collaboratorId: string;
}

export function ManageBenefitsModal({ collaboratorId }: Props) {
	const { openOptionsDialog } = useDialogModal();
	const [isOpen, setIsOpen] = useState(false);
	const [hasMadeChanges, setHasMadeChanges] = useState(false);
	const [selectedBenefitsIds, setSelectedBenefitsIds] = useState<string[]>([]);

	const fetchBenefitsQuery = useQuery(
		['fetchBenefits', collaboratorId],
		() => getCollaboratorBenefits(collaboratorId),
		{
			onSuccess: (data) => {
				setSelectedBenefitsIds(
					data.filter((benefit) => benefit.status!).map((b) => b.id)
				);
			},
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao buscar os benefícios'
				);
			},
			enabled: isOpen,
			refetchOnWindowFocus: false,
		}
	);

	const updateCollaboratorBenefitsQuery = useMutation(
		({ benefits, collaboratorId }: UpdateBenefitParams) => {
			return updateCollaboratorBenefits({ benefits, collaboratorId });
		},
		{
			onSuccess: () => {
				toast.info('Benefícios atualizados');
			},
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao tentar atualizar os benefícios da empresa. '
				);
			},
		}
	);

	async function updateCollabBenefits(selectedBenefits: string[]) {
		const benefits = selectedBenefits.map((b) => {
			return { id: b };
		});

		await updateCollaboratorBenefitsQuery.mutateAsync({
			benefits,
			collaboratorId: collaboratorId,
		});

		setHasMadeChanges(false);
	}

	function handleSubmit() {
		if (!fetchBenefitsQuery.data) {
			return;
		}

		if (hasMadeChanges) {
			updateCollabBenefits(selectedBenefitsIds);
		}

		setIsOpen(false);
	}

	function handleClose() {
		if (!hasMadeChanges) {
			setIsOpen(false);
			return;
		}

		openOptionsDialog(
			'Alterações não salvas. Tem certeza que deseja sair?',
			'Confirmar',
			() => setIsOpen(false),
			'Cancelar',
			() => {}
		);
	}

	function checkBenefitSelection(id_baas: string) {
		return !!selectedBenefitsIds.find((b) => b === id_baas);
	}

	function handleToggleBenefitSelection(id_baas: string) {
		if (checkBenefitSelection(id_baas)) {
			setSelectedBenefitsIds([
				...selectedBenefitsIds.filter((b) => b !== id_baas),
			]);
		} else {
			setSelectedBenefitsIds([...selectedBenefitsIds, id_baas]);
		}
		setHasMadeChanges(true);
	}

	if (
		fetchBenefitsQuery.isLoading ||
		fetchBenefitsQuery.isError ||
		!fetchBenefitsQuery.data
	) {
		return (
			<>
				<S.ModalButton
					onClick={(e) => {
						e.preventDefault();
						setIsOpen(true);
					}}
				>
					Gerenciar Benefícios
				</S.ModalButton>

				<Modal
					isOpen={isOpen}
					enableClose={true}
					onRequestClose={() => {
						setIsOpen(false);
					}}
				>
					<S.Container>
						{fetchBenefitsQuery.isLoading ? (
							<Loader />
						) : (
							<EmptyContent text='Nenhum benefício encontrado para o colaborador.' />
						)}
					</S.Container>
				</Modal>
			</>
		);
	}

	return (
		<>
			<S.ModalButton
				onClick={(e) => {
					e.preventDefault();
					setIsOpen(true);
				}}
			>
				Gerenciar Benefícios
			</S.ModalButton>

			<Modal isOpen={isOpen} enableClose={true} onRequestClose={handleClose}>
				<S.Container>
					<S.Title>Selecione os benefícios para o colaborador</S.Title>

					<S.BenefitsList>
						{fetchBenefitsQuery.data.map((benefit) => (
							<S.BenefitContainer key={benefit.id}>
								<S.TitleContainer>
									{getBenefitIcon(benefit.label ?? benefit.title)}{' '}
									{getBenefitParsedTitle(benefit.label ?? benefit.title)}
								</S.TitleContainer>
								<S.SelectBenefit
									onClick={() => handleToggleBenefitSelection(benefit.id)}
									selected={checkBenefitSelection(benefit.id)}
									data-testid={
										checkBenefitSelection(benefit.id)
											? `uncheck-${benefit.id}`
											: `check-${benefit.id}`
									}
								>
									{checkBenefitSelection(benefit.id) ? (
										<RiCloseCircleLine />
									) : (
										<RiAddCircleLine />
									)}
								</S.SelectBenefit>
							</S.BenefitContainer>
						))}
					</S.BenefitsList>
					<S.MainButton onClick={handleSubmit} style={{ width: '16rem' }}>
						Salvar
					</S.MainButton>
				</S.Container>
			</Modal>
		</>
	);
}
