import { Redirect, Route, Switch, useRouteMatch } from 'react-router';
import { PaymentProvider as PaymentProviderV2 } from '../contexts/PaymentContextV2';

import Collaborators from '../pages/FlexibleBenefits/Collaborators';
import { ReviewPaymentAdmin } from '../pages/FlexibleBenefits/PaymentsV2/ReviewPaymentAdmin';
import Payments from '../pages/FlexibleBenefits/Payments';
import ImportPayment from '../pages/FlexibleBenefits/PaymentsV2/ImportPayment';
import RefundRequests from '../pages/FlexibleBenefits/RefundRequests';
import RefundDetails from '../pages/FlexibleBenefits/RefundDetails';
import { RelaunchPayment } from '../pages/FlexibleBenefits/PaymentsV2/RelaunchPayment';
import { useAuth } from '../hooks/useAuth';
import { ReviewPaymentOperator } from '../pages/FlexibleBenefits/PaymentsV2/ReviewPaymentOperator';
import { ManageCollaborators } from '../pages/FlexibleBenefits/Collaborators/ManageCollaborators';
import { DetailPayment } from '../pages/FlexibleBenefits/PaymentsV2/DetailPayment';
import { Groups } from '../pages/FlexibleBenefits/Groups';
import { ManageGroups } from '../pages/FlexibleBenefits/Groups/ManageGroups';
import { CreateCollaborator } from '../pages/FlexibleBenefits/Collaborators/CreateCollaborator';
import { CreateOperator } from '../pages/FlexibleBenefits/Operators/CreateOperators';
import Operators from '../pages/FlexibleBenefits/Operators';
import { ManageOperators } from '../pages/FlexibleBenefits/Operators/ManageOperators';
import Benefits from '../pages/FlexibleBenefits/Benefits';
import { EditProfile } from '../pages/FlexibleBenefits/EditProfile';
import { CompanyDetails } from '../pages/FlexibleBenefits/Company/CompanyDetails';
import { ImportCollaboratorsPage } from '../pages/FlexibleBenefits/Collaborators/ImportCollaborators';

import { TransfersV2 } from '../pages/FlexibleBenefits/TransfersV2';
import { ChatPage } from '../pages/FlexibleBenefits/Chat/ChatPage';
import { ChatListPage } from '../pages/FlexibleBenefits/Chat/ChatListPage';
import { CreatePayment } from '../pages/FlexibleBenefits/PaymentsV2/CreatePayment';
import { CompanyUpdate } from '../pages/FlexibleBenefits/Company/CompanyUpdate';
import { CompanyFormProvider } from '../contexts/CompanyFormContext';
import { CreateChargeback } from '../pages/FlexibleBenefits/Chargebacks/CreateChargeback';
import { Chargebacks } from '../pages/FlexibleBenefits/Chargebacks';
import ChargebackDetails from '../pages/FlexibleBenefits/Chargebacks/ChargebackDetails';
import { CostCenters } from '../pages/FlexibleBenefits/CostCenters';
import { CostCentersDetails } from '../pages/FlexibleBenefits/CostCenters/CostCentersDetails';
import { Messages } from '../pages/FlexibleBenefits/Messages';
import { CreateMessage } from '../pages/FlexibleBenefits/Messages/CreateMessage';
import { MessageDetails } from '../pages/FlexibleBenefits/Messages/MessageDetails';
import { Settings } from '../pages/FlexibleBenefits/Settings';
import { CreateCardBatchCollaborators } from '../pages/FlexibleBenefits/Cards/CreateCardBatchCollaborators';
import { CardsBatches } from '../pages/FlexibleBenefits/Cards';
import { CardBatchDetails } from '../pages/FlexibleBenefits/Cards/CardBatchDetails';
import { EditPayment } from '../pages/FlexibleBenefits/PaymentsV2/EditPayment';
import { Partners } from '../pages/FlexibleBenefits/Partners';
import { PartnerQuantitative } from '../pages/FlexibleBenefits/Partners/PartnerQuantitative';
import Wallet from '../pages/FlexibleBenefits/Wallet';
import { ManagePartnersCollaborators } from '../pages/FlexibleBenefits/Partners/ManagePartnersCollaborators';
import { BenefitGroups } from '../pages/FlexibleBenefits/BenefitGroups';
import { ManageBenefitsGroupCollaborators } from '../pages/FlexibleBenefits/BenefitGroups/ManageBenefitsGroupCollaborators';
import { WalletBilling } from '../pages/FlexibleBenefits/Wallet/Billing';

export default function FlexibleBenefitsRoutes() {
	const { path } = useRouteMatch();
	const { user } = useAuth();

	return (
		<Switch>
			<Route exact path={path}>
				<Redirect to={`${path}/payment/import`} />
			</Route>

			<Route path={`${path}/profile`} component={EditProfile} />

			{/* START PAYMENTS ROUTES */}
			<Route exact path={`${path}/payment/import`} component={ImportPayment} />
			<Route
				exact
				path={`${path}/payment/create`}
				component={() => (
					<PaymentProviderV2>
						<CreatePayment />
					</PaymentProviderV2>
				)}
			/>
			<Route
				path={`${path}/payments/relaunch/:id?`}
				component={() => (
					<PaymentProviderV2>
						<RelaunchPayment />
					</PaymentProviderV2>
				)}
			/>
			<Route
				exact
				path={`${path}/payments/edit/:id`}
				component={() => (
					<PaymentProviderV2>
						<EditPayment />
					</PaymentProviderV2>
				)}
			/>
			<Route
				exact
				path={`${path}/payments/details/:id`}
				component={() => (
					<PaymentProviderV2>
						<DetailPayment />
					</PaymentProviderV2>
				)}
			/>

			<Route
				path={`${path}/payments/:id/review`}
				component={
					user.access_level === 'admin'
						? () => (
								<PaymentProviderV2>
									<ReviewPaymentAdmin />
								</PaymentProviderV2>
						  )
						: () => (
								<PaymentProviderV2>
									<ReviewPaymentOperator />
								</PaymentProviderV2>
						  )
				}
			/>

			<Route path={`${path}/payments`} component={Payments} />

			<Route exact path={`${path}/benefits`} component={Benefits} />

			<Route exact path={`${path}/transfers`} component={TransfersV2} />

			<Route exact path={`${path}/chat`} component={ChatListPage} />

			<Route path={`${path}/chat/:id`} component={ChatPage} />

			{/* END PAYMENTS ROUTES */}
			<Route exact path={`${path}/collaborators`} component={Collaborators} />
			<Route
				path={`${path}/collaborators/manage/:id`}
				component={ManageCollaborators}
			/>
			<Route
				path={`${path}/collaborators/create`}
				component={CreateCollaborator}
			/>
			<Route
				path={`${path}/collaborators/import`}
				component={ImportCollaboratorsPage}
			/>

			<Route exact path={`${path}/chargebacks`} component={Chargebacks} />
			<Route path={`${path}/chargebacks/create`} component={CreateChargeback} />
			<Route path={`${path}/chargebacks/:id`} component={ChargebackDetails} />

			<Route exact path={`${path}/refunds`} component={RefundRequests} />
			<Route path={`${path}/refunds/:id`} component={RefundDetails} />

			<Route exact path={`${path}/groups`} component={Groups} />
			<Route path={`${path}/groups/manage/:id`} component={ManageGroups} />

			<Route exact path={`${path}/cost-centers`} component={CostCenters} />
			<Route
				path={`${path}/cost-centers/details/:id`}
				component={CostCentersDetails}
			/>

			<Route exact path={`${path}/settings`} component={Settings} />
			<Route exact path={`${path}/company`} component={CompanyDetails} />
			{user.access_level === 'admin' && (
				<Route
					exact
					path={`${path}/company/update`}
					component={() => (
						<CompanyFormProvider>
							<CompanyUpdate />
						</CompanyFormProvider>
					)}
				/>
			)}

			{/* OPERATORS RELATED ROUTES ARE ONLY ACCESSIBLE FROM ADMIN ACCOUNTS */}
			{user.access_level === 'admin' && (
				<Route path={`${path}/operators`}>
					<Switch>
						<Route exact path={`${path}/operators`} component={Operators} />
						<Route
							exact
							path={`${path}/operators/manage/:id`}
							component={ManageOperators}
						/>
						<Route
							exact
							path={`${path}/operators/create`}
							component={CreateOperator}
						/>
					</Switch>
				</Route>
			)}

			<Route exact path={`${path}/messages`} component={Messages} />
			<Route path={`${path}/messages/create`} component={CreateMessage} />
			<Route path={`${path}/messages/manage/:id`} component={MessageDetails} />

			<Route exact path={`${path}/cards`} component={CardsBatches} />
			<Route
				path={`${path}/cards/create`}
				component={CreateCardBatchCollaborators}
			/>
			<Route path={`${path}/cards/:id`} component={CardBatchDetails} />

			<Route exact path={`${path}/partners`} component={Partners} />
			<Route
				path={`${path}/partners/quantitative/:partner_id`}
				component={PartnerQuantitative}
				exact
			/>
			<Route
				path={`${path}/partners/:partner_id`}
				exact
				component={ManagePartnersCollaborators}
			/>

			<Route
				path={`${path}/support`}
				render={() => {
					window.location.replace(
						'https://sites.google.com/cartaobounty.com.br/bounty-onboarding/'
					);
					return null;
				}}
			/>

			<Route path={`${path}/wallet`} exact component={Wallet} />
			<Route path={`${path}/wallet/:billing_id`} component={WalletBilling} />

			<Route path={`${path}/remove-benefit`} exact component={BenefitGroups} />
			<Route
				path={`${path}/remove-benefit/:benefits_group_id`}
				exact
				component={ManageBenefitsGroupCollaborators}
			/>

			<Redirect from='*' to='/404' />
		</Switch>
	);
}
