import { create } from 'zustand';
import { FilterParams } from '../components/Filter';
import { DeliveryAddressOptionsFilters } from '../components/Filter/utils/SelectFilters';

type Store = {
	pendingCardsBatchesFiltersParams: FilterParams[];
	setPendingCardsBatchesFiltersParams: (f: FilterParams[]) => void;
	concludedCardsBatchesFiltersParams: FilterParams[];
	setConcludedCardsBatchesFiltersParams: (f: FilterParams[]) => void;
	resetFilters: () => void;
};

const filterParams: FilterParams[] = [
	{
		name: 'Cod. lote',
		filter: 'id',
		selected: false,
		value: '',
		type: 'text',
	},
	{
		name: 'Cod. entrega',
		filter: 'item_id',
		selected: false,
		value: '',
		type: 'text',
	},
	{
		name: 'Email',
		filter: 'email',
		selected: false,
		value: '',
		type: 'email',
	},
	{
		name: 'Nome',
		filter: 'name',
		selected: false,
		value: '',
		type: 'text',
	},
	{
		name: 'Intervalo de datas',
		filter: 'date_interval',
		value: '',
		type: 'date_interval',
		selected: false,
	},
	{
		name: 'Tipo de endereço de envio',
		filter: 'delivery_address_type',
		selected: false,
		value: '',
		type: 'select',
		selectOptions: DeliveryAddressOptionsFilters,
	},
];

const useCardsListStore = create<Store>()((set) => ({
	pendingCardsBatchesFiltersParams: filterParams,
	setPendingCardsBatchesFiltersParams: (f) =>
		set({ pendingCardsBatchesFiltersParams: f }),
	concludedCardsBatchesFiltersParams: filterParams,
	setConcludedCardsBatchesFiltersParams: (f) =>
		set({ concludedCardsBatchesFiltersParams: f }),
	resetFilters: () =>
		set({
			pendingCardsBatchesFiltersParams: filterParams,
			concludedCardsBatchesFiltersParams: filterParams,
		}),
}));

export { useCardsListStore };
