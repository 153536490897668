import React from 'react';
import styled from 'styled-components';
import * as S from './styles';
import { Button } from '../Button';
import Modal from './index';
import { ModalProps } from './index';

export interface ModalHeaderTextProps {
	color?: string;
	text?: string;
	isBold?: boolean;
	width?: string;
}

interface InformationModalProps extends ModalProps {
	title?: ModalHeaderTextProps[];
	backButtonText?: string | null;
	nextButtonText?: string | null;
	onBack?: () => void;
	onNext?: () => void;
	titleIcon?: React.ReactNode;
	size?: string;
	color?: 'primary' | 'secondary';
	width?: string;
	titleWidth?: string;
	isNextButtonDisabled?: boolean;
	isNextButtonLoading?: boolean;
}

export const IconContainer = styled.div<{ color?: string }>`
	display: flex;
	align-items: center;
	justify-content: center;
	background: ${(props) => props.color};
	border-radius: 50%;
	padding: 1rem;
	margin-bottom: 1rem;
`;

export const HeaderText = styled.span<{ isBold?: boolean; color?: string }>`
	color: ${(props) => props.color};
	font-size: 2rem;
	font-weight: ${(props) => (props.isBold ? 'bold' : 'normal')};
`;

export const ModalFooter = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	width: 100%;
	gap: 1rem;
	margin-bottom: 2rem;

	@media (max-width: 1024px) {
		margin: 2rem 0;
	}
`;

function InformationModal({
	isOpen,
	onRequestClose,
	backButtonText = null,
	nextButtonText = null,
	onNext = () => {},
	onBack = () => {},
	color,
	title = [],
	titleIcon = null,
	children,
	width = '40rem',
	titleWidth = '50%',
	isNextButtonDisabled,
	isNextButtonLoading = false
}: InformationModalProps) {
	const mappedColor =
		color === 'secondary' ? 'var(--primary-red)' : 'var(--primary-blue)';

	const Title: React.ReactNode = title.map((text, index) => (
		<HeaderText
			key={text.text ?? '' + index}
			color={text.color}
			isBold={text.isBold}
		>
			{text.text}
		</HeaderText>
	));

	return (
		<Modal isOpen={isOpen} onRequestClose={onRequestClose} showClose={true}>
			<div style={{ width }}>
				<S.ModalHeader>
					{titleIcon && (
						<IconContainer color={mappedColor}>{titleIcon}</IconContainer>
					)}

					{title.length > 0 && (
						<p style={{ width: titleWidth, textAlign: 'center' }}>{Title}</p>
					)}
				</S.ModalHeader>
				<S.ModalContent>{children}</S.ModalContent>
				{(backButtonText || nextButtonText) && (
					<S.ModalFooter>
						{backButtonText && (
							<Button
								onClick={onBack}
								intent='terciary'
								roundness='lg'
								$outline
							>
								{backButtonText}
							</Button>
						)}
						{nextButtonText && (
							<Button
								intent={color}
								width='adjusted'
								roundness='lg'
								onClick={onNext}
								shrinkOnLoading={false}
								loading={isNextButtonLoading}
								disabled={isNextButtonDisabled}
							>
								{nextButtonText}
							</Button>
						)}
					</S.ModalFooter>
				)}
			</div>
		</Modal>
	);
}

export default InformationModal;
