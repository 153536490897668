import styled from 'styled-components';

export const HeaderContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	align-self: stretch;
`;

export const DescriptionContainer = styled.div`
    display: flex;
    max-width: 59rem;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 1.6rem;
`

export const InputContainer = styled.div`
	position: relative;
	min-width: 45%;
	& > svg {
		position: absolute;
		right: 1rem;
		top: 50%;
		width: 2rem;
		height: 2rem;
		color: var(--dark-gray);
		transform: translateY(-50%);
	}

	@media (max-width: 768px) {
		min-width: 30rem;
			width: 100%;
	}
`;


export const SearchContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 4.8rem;
	align-self: stretch;
	justify-content: space-between;

	@media (max-width: 768px) {
			flex-direction: column;
			gap: 2.4rem
	}
`

export const ButtonContainer = styled.div`
    display: flex;
		justify-content: flex-end;
		width: 100%;
		
		@media (max-width: 768px) {
			justify-content: center;
			align-items: center;
			gap: 1.6rem;
				flex-direction: column;
    }
`
