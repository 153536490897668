import { InputHTMLAttributes } from 'react';
import { InputComp } from './InputStyle';
import { BaseFieldProps, Field } from './Field';
import { MdErrorOutline } from 'react-icons/md';

interface Props extends InputHTMLAttributes<HTMLInputElement>, BaseFieldProps {
	errorMessage?: string;
}

export function InputField(props: Props) {
	if (props.register && !props.name)
		throw new Error(`If register is passed it needs the 'name' property!`);

	return (
		<Field label={props.label} name={props.name}>
			<InputComp
				error={!!props.errorMessage}
				{...(props.register ? { ...props.register(props.name!) } : {})}
				{...props}
			/>
			{props.errorMessage && (
				<span>
					<MdErrorOutline /> {props.errorMessage}
				</span>
			)}
		</Field>
	);
}
