import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	border-radius: 1rem 0 0 1rem;
	width: 36rem;
	height: 100vh;
	padding: 2.4rem;
	font-size: 1.6rem;
	font-weight: 600;
	justify-content: center;
	min-height: 64rem;
	margin-top: 3.2rem;

	@media (min-width: 1025px) and (max-width: 1920px) {
		padding: 5rem 2.4rem 0 2.4rem;
	}
`;

export const MainContent = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	.actionButton {
		margin: 2.4rem 0 0 0;
	}
	@media (max-width: 1920px) {
		.actionButton {
			margin: 2.4rem 0 2.4rem 0;
		}
	}
`;

export const ProfileUser = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1rem;
	border-bottom: 1px solid var(--light-gray3);
	padding-bottom: 2.4rem;
	width: 100%;
	padding-bottom: 2.4rem;

	.accessRole {
		display: flex;
		justify-content: center;
		border: 1px solid var(--dark-gray);
		padding: 1rem 2.4rem;
		border-radius: 0.8rem;
	}
`;

export const AvatarImg = styled.img`
	width: 16rem;
	height: 16rem;
	border-radius: 50%;
	border: 0.4rem solid var(--primary-blue);
	background-color: #f0f3f8;
	object-fit: cover;

	@media (min-width: 1025px) and (max-width: 1920px) {
		width: 14rem;
		height: 14rem;
	}
`;

export const InfoContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	row-gap: 1rem;
	border-bottom: 1px solid var(--light-gray3);
	padding: 2.4rem 0;
`;

export const CostCentersList = styled.ul`
	list-style: none;
	display: flex;
	width: 100%;
	flex-wrap: wrap;
	gap: 1rem;
	& > li {
		min-width: max-content;
		max-height: 3.2rem;
		padding: 0.6rem 1.6rem;
		border: 0.1rem solid var(--light-gray);
		border-radius: 3.8rem;
		font-size: 1.4rem;
		line-height: 2rem;
		font-weight: 400;
		color: var(--dark-gray);
		max-width: 30ch;
	}
`;
