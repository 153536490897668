import * as S from './styles';
import PageTitle from '../../../components/PageTitle';
import { useRef, useState } from 'react';
import { useMutation } from 'react-query';
import {
	generateBilling,
	generateQRCode,
	sendQRCodeByEmail,
} from '../../../services/queries/Tranfers';
import { toast } from 'react-toastify';
import { useAuth } from '../../../hooks/useAuth';
import { convertReaisToCents } from '../../../utils/CurrencyConvert';
import * as FormStyles from '../../../components/Form/FormStyles';
import Loader from '../../../components/Loader';
import { parseCurrencyToBRLStandard } from '../../../utils/parseCurrency';
import { showErrorMessage } from '../../../utils/ErrorHandler';
import { RiCloseLine } from 'react-icons/ri';
import { generateQRCodeImage } from '../../../utils/generateQRcodeImage';
import * as yup from 'yup';

type RecipientEmailOption = 'financial' | 'operator' | 'custom';

export default function Wallet() {
	const { currentCompany, user } = useAuth();
	const [value, setValue] = useState(0);
	const [qrCodeImg, setQRCodeImg] = useState('');
	const [billingPDFURL, setBillingPDFURL] = useState('');
	const lastValue = useRef<number>(0);
	const [recipientEmailOption, setRecipientEmailOption] =
		useState<RecipientEmailOption>('operator');
	const [customEmail, setCustomEmail] = useState('');

	const EMAILS = {
		financial: undefined,
		operator: user.email,
		custom: customEmail,
	};
	const recipientEmail = EMAILS[recipientEmailOption as keyof typeof EMAILS];

	const generateQRCodeMutation = useMutation(
		async (amount: number) =>
			generateQRCode(amount, 'multiflex', currentCompany!.id),
		{
			onError: () => {
				toast.error(
					'Ocorreu um problema ao tentar gerar o QRCode. Tente novamente.'
				);
			},
			onSuccess: async (data) => {
				setQRCodeImg(await generateQRCodeImage(data.emv));
			},
		}
	);

	const generateBillingMutation = useMutation(
		async ({ amount, email }: { amount: number; email: string | undefined }) =>
			generateBilling(amount, 'multiflex', currentCompany!.id, email),
		{
			onSuccess: () => {
				toast.info(
					'O boleto ainda não está disponível. O destinatário será notificado quando ele estiver disponível e receberá via email o pdf.'
				);
			},
			onError: () => {
				toast.error(
					'Ocorreu um problema ao tentar gerar o boleto. Tente novamente.'
				);
			},
		}
	);

	const sendQRCodeByEmailMutation = useMutation(
		({ recipientEmail }: { recipientEmail?: string }) =>
			sendQRCodeByEmail(
				generateQRCodeMutation.data?.emv!,
				lastValue.current,
				currentCompany!.id,
				recipientEmail
			),
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao tentar enviar o QRCode. '
				);
			},
			onSuccess: () => {
				toast.info('QR code enviado com sucesso via email.');
			},
		}
	);

	function handleChangeValue(value: number) {
		setValue(value);
	}

	function validateRecipientEmail() {
		if (recipientEmailOption === 'custom') {
			const validEmail = yup.string().email().isValidSync(customEmail);

			if (!customEmail || !validEmail) {
				toast.error('Insira um email válido!');
				return false;
			}
		}

		return true;
	}

	const isDisabled =
		generateQRCodeMutation.isLoading || generateBillingMutation.isLoading;

	function handleSubmit(type: 'billing' | 'pix') {
		if (value <= 0) {
			toast.error('Valor abaixo do mínimo');
			return;
		}

		if (!validateRecipientEmail()) {
			return;
		}

		setQRCodeImg('');
		setBillingPDFURL('');

		const amountInCents = convertReaisToCents(value);
		lastValue.current = value;
		if (type === 'pix') {
			generateQRCodeMutation.mutate(amountInCents);
		} else {
			generateBillingMutation.mutate({
				amount: amountInCents,
				email: recipientEmail,
			});
		}
	}

	function handleSubmitQRCodeToEmail() {
		if (!validateRecipientEmail()) return;

		sendQRCodeByEmailMutation.mutate({ recipientEmail });
	}

	function handleCopyQRCode() {
		navigator.clipboard.writeText(generateQRCodeMutation.data!.emv);

		toast.info('Código PIX copiado!', {
			autoClose: 3500,
		});
	}

	function resetPage() {
		setValue(0);
		setQRCodeImg('');
		setBillingPDFURL('');
		setCustomEmail('');
		setRecipientEmailOption('operator');
	}

	return (
		<S.Container>
			<PageTitle title='Solicitação de pagamento' />

			<S.Content>
				<S.FormContainer>
					<FormStyles.Field>
						<FormStyles.Label>Valor</FormStyles.Label>

						<FormStyles.FormCurrencyInput
							onValueChange={({ floatValue }) => handleChangeValue(floatValue)}
							value={value}
							disabled={isDisabled}
						/>
					</FormStyles.Field>

					<FormStyles.Field>
						<FormStyles.Label>Enviar email para</FormStyles.Label>

						<S.RecipientEmailContainer>
							<label>
								<input
									type='radio'
									checked={recipientEmailOption === 'financial'}
									onChange={() => {
										setRecipientEmailOption('financial');
										setCustomEmail('');
									}}
								/>
								Financeiro da empresa
							</label>
							<label>
								<input
									type='radio'
									checked={recipientEmailOption === 'operator'}
									onChange={() => {
										setRecipientEmailOption('operator');
										setCustomEmail('');
									}}
								/>
								Operador (você)
							</label>
							<label>
								<input
									type='radio'
									checked={recipientEmailOption === 'custom'}
									onChange={() => setRecipientEmailOption('custom')}
								/>
								Customizado
							</label>
							<FormStyles.Input
								placeholder='email_proprio@exemplo.com'
								disabled={recipientEmailOption !== 'custom'}
								value={customEmail}
								type='email'
								onChange={(e) => setCustomEmail(e.target.value)}
							/>
						</S.RecipientEmailContainer>
					</FormStyles.Field>

					<S.SubmitOptionsContainer>
						<S.SubmitButton
							disabled={isDisabled}
							onClick={() => handleSubmit('pix')}
						>
							{generateQRCodeMutation.isLoading ? (
								<Loader color='#fff' size={20} />
							) : (
								'GERAR PIX'
							)}
						</S.SubmitButton>

						<span> ou </span>

						<S.SubmitButton
							onClick={() => handleSubmit('billing')}
							disabled={isDisabled}
						>
							{generateBillingMutation.isLoading ? (
								<Loader color='#fff' size={20} />
							) : (
								'GERAR BOLETO'
							)}
						</S.SubmitButton>

						{(qrCodeImg || billingPDFURL) && (
							<S.ClearButton
								cancel
								data-rh='Limpar'
								type='button'
								onClick={resetPage}
								data-testid='clear-page'
							>
								<RiCloseLine />
							</S.ClearButton>
						)}
					</S.SubmitOptionsContainer>
				</S.FormContainer>

				<S.GeneratedPaymentsContainer>
					{!!qrCodeImg && (
						<S.QRCodeContainer>
							<S.QRCodeFigure>
								<S.QRCodeImg src={qrCodeImg} alt='QRcode gerado' />
								<S.QRcodeCaption>
									Solicitação de pagamento* gerado por QR Code no valor de{' '}
									{parseCurrencyToBRLStandard(lastValue.current)}
								</S.QRcodeCaption>
							</S.QRCodeFigure>

							<S.QRCodeButtonsContainer>
								<S.CopyQRCodeBtn onClick={handleCopyQRCode}>
									Copiar código
								</S.CopyQRCodeBtn>

								<S.CopyQRCodeBtn onClick={handleSubmitQRCodeToEmail}>
									{sendQRCodeByEmailMutation.isLoading ? (
										<Loader color='#fff' size={15} />
									) : (
										'Enviar p/ email'
									)}
								</S.CopyQRCodeBtn>
							</S.QRCodeButtonsContainer>
							<S.Caption>
								<span>*</span>Pagamento efetuado via Pix
							</S.Caption>
						</S.QRCodeContainer>
					)}
				</S.GeneratedPaymentsContainer>
			</S.Content>
		</S.Container>
	);
}
