import styled from 'styled-components';
import type { Card as CardType } from '../../../../../../@types/CorporateExpenses/Card';
import { InfoContent } from '../../../../../../componentsV2/InfoContent';
import mastercardSvg from '../../../../../../assets/mastercard_icon.svg';

const MastercardLogo = styled.img`
	width: 6rem;
	transform: translateY(1.75rem);
`;

interface Props {
	card: CardType;
	isActive: boolean;
	onSelect: (c: CardType) => void;
}

export function Card({ card, isActive, onSelect }: Props) {
	const formatPan = (pan: string): string => {
		if (typeof pan !== 'string') {
			return '';
		}
		const maskedPan = pan.slice(0, -4).replace(/\d/g, '*') + pan.slice(-4);
		return maskedPan.replace(/(.{4})/g, '$1 ').trim();
	};

	return (
		<Container $isActive={isActive} onClick={() => onSelect(card)}>
			<InfoContent label='Apelido' color='#FFF'>
				{card.alias.charAt(0).toUpperCase() + card.alias.slice(1).toLowerCase()}
			</InfoContent>

			<Row>
				<InfoContent label='Número' color='#FFF'>
					{formatPan(card.pan)}
				</InfoContent>

				<MastercardLogo src={mastercardSvg} alt={'Bandeira mastercard'} />
			</Row>
		</Container>
	);
}

const Row = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const Container = styled.div<{ $isActive: boolean }>`
	cursor: pointer;
	user-select: none;
	min-width: 28.5rem;
	min-height: 16rem;
	border-radius: 1rem;
	background: ${({ $isActive }) =>
		$isActive
			? `linear-gradient(to right top, #27AEFA 0, #258BDB 25%, var(--primary-blue) 75%, #33CAFF 100%)`
			: `linear-gradient(to right top, #248ADA 0, #26ACFE 30%, var(--primary-blue) 55%, #2BCCFE 75%, #2EF5FF 100%)`};
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 2rem;
	position: relative;
	transition: all 0.2s ease-in-out;
	margin-bottom: 3rem;
	${({ $isActive }) =>
		$isActive &&
		`
		transform: scale(1.08);
		position: relative; /* Necessário para posicionar o pseudo-elemento */
		color: #333;
		
		&::after {
			content: '';
			position: absolute;
			left: 0;
			bottom: -2rem;
			width: 50%;
			height: .4rem;
			background-color: var(--primary-blue);
			z-index: -1;
			left: 25%;
			border-radius: .3rem .3rem 0 0;
		}
	`}

	& > svg {
		position: absolute;
		bottom: 0;
		right: 0;
	}

	&:hover {
		transform: scale(1.08);
	}

	@media (max-width: 1024px) {
		min-width: 15rem;
		min-height: 15rem;
		position: relative;
		transition: all 0.2s ease-in-out;
		&:hover {
			transform: scale(1.08);
		}
	}
`;
