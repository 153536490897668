import styled from 'styled-components';

export const Container = styled.div``;

export const ContentContainer = styled.div`
	display: flex;
	width: 100%;
	gap: 2.4rem;
	justify-content: flex-start;

	> .sectionContainer {
		padding-bottom: 4rem;
	}

	@media (max-width: 1024px) {
		flex-direction: column;
		align-items: center;
		gap: 2rem;
	}
`;

export const ActionsButtons = styled.div`
	width: 100%;
	display: flex;
	flex: 1;
	padding: 0 2.4rem;
	align-items: flex-end;
	justify-content: flex-end;

	& > div {
		display: flex;
		width: 100%;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		@media (max-width: 1024px) {
			flex-direction: column-reverse;
			gap: 1rem;
		}
	}

	@media (max-width: 1024px) {
		gap: 5rem;
	}
`;

export const TitleContainer = styled.div`
	width: 100%;

	& > div {
		margin: 0 2rem 1rem 2rem;
	}
	@media (max-width: 1024px) {
		display: none;
	}
`;

export const CardContainer = styled.div`
	display: flex;
	flex-direction: column;
	border-radius: 8px;
	width: 36rem;
	background-color: var(--white);
	padding: 4rem 2.4rem;
	justify-content: flex-start;
	align-items: center;

	@media (max-width: 1024px) {
		flex-direction: column;
		width: 100%;
		background-color: var(--white);
		align-items: center;
	}
`;
export const CardHeaderContainer = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;
	background-color: var(--white);
	border-radius: 1rem 1rem 0 0;
	justify-content: center;
	align-items: center;
	row-gap: 1rem;
	padding-bottom: 4rem;
	border-bottom: 1px solid var(--light-gray3);

	.accessRole {
		display: flex;
		justify-content: center;
		border: 1px solid var(--dark-gray);
		width: 100%;
		padding: 1.2rem;
		border-radius: 0.8rem;
		margin-top: 1rem;
	}

	@media (max-width: 1024px) {
		flex-direction: row;
		gap: 2rem;
	}
`;

export const NameContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: center;
`;
export const CardContentContainer = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 1.6rem;
	align-items: left;
	padding: 2.4rem 0;
	padding-bottom: 1.6rem;
	width: 100%;
	& > div {
		width: 100%;
	}
`;

export const PersonalDataContainer = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;
	flex-wrap: wrap;
	row-gap: 3.3rem;
	margin-top: 1.5rem;
	padding: 0 2.4rem;
`;

export const AvatarContainer = styled.label<{ background: string }>`
	width: 14rem;
	height: 14rem;
	border-radius: 100%;
	border: 0.4rem solid var(--primary-blue);
	background: url(${({ background }) => background}) no-repeat center center /
		cover;
	position: relative;
	background-color: #f0f3f8;
	flex-shrink: 0;
	cursor: pointer;
	background-origin: content-box;

	& > svg {
		position: absolute;
		bottom: 0.6rem;
		right: 0;
		width: 4rem;
		height: 4rem;
		border-radius: 100%;
		border: 0.2rem solid var(--primary-blue);
		background-color: #f0f3f8;
		color: var(--primary-blue);
		padding: 0.6rem;
	}
`;

export const CostCentersList = styled.ul`
	list-style: none;
	display: flex;
	width: 100%;
	flex-wrap: wrap;
	gap: 1rem;
	& > li {
		min-width: max-content;
		padding: 0.6rem 1.6rem;
		border: 0.1rem solid var(--light-gray);
		border-radius: 10rem;
		font-size: 1.4rem;
		line-height: 2rem;
		font-weight: 400;

		color: var(--dark-gray);
	}
`;

export const CostCenterSelectorContainer = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-between;
	padding: 1rem;
	background-color: #f2f4f8;
	border-radius: 0.4rem;
	column-gap: 1rem;
	min-height: 4.8rem;

	& > ul {
		display: flex;
		column-gap: 1rem;
		align-items: center;
		overflow-x: auto;
		flex-wrap: nowrap;
		gap: 1rem;
		&::-webkit-scrollbar {
			width: 1rem;
			height: 1rem;
		}

		&::-webkit-scrollbar-track {
			background-color: #e9eaeb;
			border-radius: 0.6rem;
		}

		&::-webkit-scrollbar-thumb {
			background-color: #258bdb;
			border-radius: 0.6rem;
		}
	}
`;

export const CostCenterChip = styled.li`
	display: flex;
	list-style: none;
	align-items: center;
	column-gap: 1rem;
	height: min-content;
	min-width: max-content;
	padding: 0.8rem 1rem;
	background-color: var(--dark-gray);
	border-radius: 10rem;
	font-size: 1.2rem;
	color: #fff;

	& > button {
		background: none;
		display: flex;
		align-items: center;
		justify-content: center;
		& > svg {
			width: 2rem;
			height: 2rem;
			color: #fff;
		}
	}
`;

export const Row = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	gap: 2rem;

	@media (max-width: 1024px) {
		flex-direction: column;
	}
`;

export const AccessDescription = styled.div`
	max-width: 50%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	@media (max-width: 1024px) {
		max-width: 100%;
	}
`;
