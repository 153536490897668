import { CompanyForm } from '../../../../components/CompanyForm';
import Loader from '../../../../components/Loader';
import PageTitle from '../../../../components/PageTitle';
import { useCompanyForm } from '../../../../hooks/useCompanyForm';
import * as S from './styles';

export function CompanyUpdate() {
	const { loading } = useCompanyForm();

	if (loading)
		return (
			<S.Container>
				<PageTitle title='Editar empresa' />
				<Loader />
			</S.Container>
		);

	return (
		<S.Container>
			<PageTitle title='Editar empresa' />

			<CompanyForm />
		</S.Container>
	);
}
