import { Company } from '../../@types';
import api from '../api';

export async function getCompanies() {
	const { data } = await api.get('/api/v1/rh/companies/');

	return data;
}

interface GetCompanyBalanceReturn {
	balance: number;
	corpway_balance: number;
	multiflex_balance: number;
}
export async function getCompanyBalance(companyId?: string) {
	const { data } = await api.get<GetCompanyBalanceReturn>(
		`/api/v1/rh/companies/${companyId}/balances`
	);

	return data;
}

export async function getCompany(companyId?: string) {
	const { data } = await api.get(`/api/v1/rh/companies/${companyId}`);

	return data;
}

export async function updateCompany(
	updatedCompany: object,
	companyId?: string
) {
	const { data } = await api.put<Company>(
		`/api/v1/companies/${companyId}`,
		updatedCompany
	);

	return data;
}

export async function handleUpdateCompanyAvatarQuery(
	avatar: FormData,
	companyId?: string
) {
	const { data } = await api.patch<Company>(
		`/api/v1/rh/companies/${companyId}/avatar`,
		avatar
	);

	return data;
}

export async function getCompanyScheduledPaymentsInAFewDays(
	companyId: string
): Promise<boolean> {
	const { data } = await api.get(
		`/api/v1/companies/${companyId}/releases/retrieves`
	);

	return data;
}
