import { Refund } from '../../@types';
import {
	FilterParams,
	parseFilterParamsToParams,
} from '../../components/Filter';
import {
	SortColumn,
	parseSortColumnToParams,
} from '../../hooks/useSortColumnHook';
import api from '../api';

export interface RefundsListReturn {
	pendingRefunds?: Refund[];
	totalPendingRefunds?: number;

	nonPendingRefunds?: Refund[];
	totalNonPendingRefunds?: number;

	total_pending?: number;
}

export interface FilterRefundsReturn {
	totalRefunds: number;
	refunds: Refund[];
	total_approved: number;
	total_pending: number;
	total_canceled: number;
	totalReports?: number;
}

export async function getRefund(refundId: string, companyId?: string) {
	const { data } = await api.get<Refund>(
		`/api/v1/rh/companies/${companyId}/refunds/${refundId}`
	);

	return data;
}

export async function getPendingRefunds(
	pendingRefundsCurrentPage: number,
	companyId?: string,
	sortColumn: SortColumn | null = null
): Promise<RefundsListReturn> {
	const { data } = await api.get(
		`/api/v1/rh/companies/${companyId}/refunds/q?page_pending=${pendingRefundsCurrentPage}`,
		{
			params: {
				...(sortColumn && { sort_by_pending: sortColumn.name }),
				...(sortColumn && { sort_order_pending: sortColumn.order }),
			},
		}
	);
	const pendingRefunds = data.pendingRefunds;
	const totalPendingRefunds = data.totalPendingRefunds;

	return {
		pendingRefunds,
		totalPendingRefunds,
		total_pending: data.total_pending,
	};
}

export async function getNonPendingRefunds(
	nonPendingRefundsCurrentPage: number,
	companyId?: string,
	sortColumn: SortColumn | null = null
): Promise<RefundsListReturn> {
	const { data } = await api.get(
		`/api/v1/rh/companies/${companyId}/refunds/q?page_other=${nonPendingRefundsCurrentPage}`,
		{
			params: {
				...(sortColumn && { sort_by_other: sortColumn.name }),
				...(sortColumn && { sort_order_other: sortColumn.order }),
			},
		}
	);
	const nonPendingRefunds = data.otherRefunds;
	const totalNonPendingRefunds = data.totalOtherRefunds;

	return {
		nonPendingRefunds,
		totalNonPendingRefunds,
	};
}

export async function getFilteredRefunds(
	filterParams: FilterParams[],
	pending?: boolean,
	companyId?: string,
	page?: number,
	sortColumn: SortColumn | null = null
): Promise<FilterRefundsReturn> {
	const { data } = await api.get(
		`/api/v1/rh/companies/${companyId}/refunds/filter/q?page=${page}`,
		{
			params: {
				status: pending ? 'pending' : 'approved,canceled',
				...parseFilterParamsToParams(filterParams),
				...parseSortColumnToParams(sortColumn),
			},
		}
	);

	return data;
}

export async function getRefundsToReport(
	filterParams: FilterParams[],
	pending?: boolean,
	companyId?: string,
	sortColumn: SortColumn | null = null
): Promise<FilterRefundsReturn> {
	const { data } = await api.get(
		`/api/v1/rh/companies/${companyId}/refunds/report/q`,
		{
			params: {
				status: pending ? 'pending' : 'approved,canceled',
				...parseFilterParamsToParams(filterParams),
				...parseSortColumnToParams(sortColumn),
			},
		}
	);

	return data;
}

export async function reproveRefund(
	feedback: string,
	refundId: string,
	companyId?: string
): Promise<Refund> {
	const { data } = await api.put(
		`/api/v1/rh/companies/${companyId}/refunds/${refundId}`,
		{
			status: 'canceled',
			reason_disapproval: feedback,
		}
	);

	return data;
}

export async function approveRefund(
	refundId: string,
	companyId?: string
): Promise<Refund> {
	const { data } = await api.put(
		`/api/v1/rh/companies/${companyId}/refunds/${refundId}`,
		{
			status: 'approved',
		}
	);

	return data;
}

export async function editBenefitValue(
	refundId: string,
	value: number,
	companyId: string
): Promise<void> {
	return await api.patch(
		`/api/v1/rh/companies/${companyId}/refunds/${refundId}`,
		{
			value,
		}
	);
}

export async function increaseTotalBenefitsReports(companyId: string) {
	return await api.patch(
		`/api/v1/rh/companies/${companyId}/increase-reports?product=benefits`
	);
}
