import { ButtonHTMLAttributes } from 'react';
import styled from 'styled-components';
import { OptionsButton } from '../Button';
import Loader from '../Loader';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
	isLoading: boolean;
}
export function SearchCEPButton({ isLoading, ...props }: Props) {
	return (
		<Container type='button' {...props} disabled={isLoading}>
			{isLoading ? <Loader color='white' size={20} /> : 'BUSCAR CEP'}
		</Container>
	);
}

const Container = styled.button`
	${OptionsButton}
	margin-top: 2rem;
	height: 3rem;
	padding: 0;
	width: 20rem;
	white-space: nowrap;
`;
