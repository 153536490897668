import { useQuery } from 'react-query';
import PageTitle from '../../../../components/PageTitle';
import { useAuth } from '../../../../hooks/useAuth';
import { downloadBilling } from '../../../../services/queries/Tranfers';
import * as S from './styles';
import { useParams } from 'react-router-dom';
import Loader from '../../../../components/Loader';
import { toast } from 'react-toastify';

export function WalletBilling() {
	const { billing_id } = useParams<{ billing_id: string }>();
	const { currentCompany } = useAuth();

	const downloadBillingQuery = useQuery(
		['downloadBilling', billing_id, currentCompany!.id],
		() => downloadBilling(billing_id, currentCompany!.id),
		{
			onError: () => {
				toast.error('Ocorreu um problema ao buscar o boleto. Tente novamente.');
			},
			refetchOnWindowFocus: false,
		}
	);

	const pdfBlob = downloadBillingQuery.data
		? new Blob([downloadBillingQuery.data.valueOf()], {
				type: 'application/pdf',
		  })
		: null;
	const urlPDF = pdfBlob && URL.createObjectURL(pdfBlob);

	if (downloadBillingQuery.isLoading || downloadBillingQuery.isError) {
		return (
			<S.Container>
				<PageTitle title='Boleto de pagamento' />
				<Loader />
			</S.Container>
		);
	}
	return (
		<S.Container>
			<PageTitle title='Boleto de pagamento' />
			{urlPDF !== null && (
				<S.BillingContainer>
					<S.DownloadBillingButton
						href={urlPDF}
						download={'Boleto Bounty - FundsIn'}
					>
						Baixar PDF
					</S.DownloadBillingButton>
					<embed
						src={urlPDF}
						type='application/pdf'
						width='100%'
						height='700px'
						data-testid='embed-pdf'
					/>
				</S.BillingContainer>
			)}
		</S.Container>
	);
}
