import { PaginationState } from '@tanstack/react-table';
import { useState } from 'react';

export const PAGE_SIZE_DEFAULT = 10;

export function usePagination() {
	const [pagination, setPagination] = useState<PaginationState>({
		pageIndex: 0,
		pageSize: PAGE_SIZE_DEFAULT,
	});

	return {
		pagination: {
			page: pagination.pageIndex + 1,
			pageIndex: pagination.pageIndex,
			pageSize: pagination.pageSize,
		},
		setPagination,
	};
}
