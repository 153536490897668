import {
	MdNotes,
	MdOutlineKeyboardArrowLeft,
	MdSwapHoriz,
} from 'react-icons/md';
import { Transaction } from '../../../../../@types/CorporateExpenses/Transaction';
import { Button } from '../../../../../componentsV2/ui/Button';
import { useReducer, useState } from 'react';
import Modal from '../../../../../componentsV2/ui/Modal';
import * as S from './styles';
import { Typography } from '../../../../../componentsV2/ui/Typography';
import {
	convertCentsToCurrency,
	convertCentsToFormattedReais,
} from '../../../../../utils/CurrencyConvert';
import {
	parseExtractTransactionStatus,
	parseTransactionType,
	truncateString,
} from '../../utils/transactionUtils';
import { formatCardNumber } from '../../../../../utils/formatCardNumber';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
	CORPWAY_EXTRACT_KEY,
	getExtractDetails,
	uploadInvoicesFiles,
} from '../../../../../services/queries/Corpway/Extract';
import Dropzone from 'react-dropzone';
import { toast } from 'react-toastify';
import fileIcon from '../../../../../assets/file-arrow-up-down.svg';
import uploadIcon from '../../../../../assets/corpwayExtractReport/UploadIcon.svg';
import trashIcon from '../../../../../assets/corpwayExtractReport/TrashIcon.svg';
import { Loader } from '../../../../../componentsV2/ui/Loader';
import { UploadRejectionModal } from './components/UploadRejectionModal';
import { UploadViewerModal } from './components/UploadViewerModal';
import { FaPen } from 'react-icons/fa';
import { ObservationForm } from './components/ObservationForm';
import { useCardSelectStore } from '../../../../../stores/corpway/useCardSelectStore';
import { parseTransactionStatusToVisualComponent } from '../../utils/parseTransactionStatus';
import { useAuth } from '../../../../../hooks/useAuth';
import { DeleteInvoiceModal } from './components/DeleteInvoiceModal';
import { InvoicesReducer, UploadedInvoice } from './reducer';

interface Props {
	transaction: Transaction;
}

const MAX_FILES_ATTACHED = 2;
const MAX_FILE_SIZE = 3145728; //3mb

export function ExtractDetailsModal({ transaction }: Props) {
	const queryClient = useQueryClient();
	const { currentCompany } = useAuth();

	const [isOpen, setIsOpen] = useState(false);
	const [animationModal, setAnimationModal] = useState(false);
	const [modalContent, setModalContent] = useState<0 | 1>(0);
	const [filesModified, setFilesModified] = useState(false);

	const [fileDragActive, setFileDragActive] = useState(false);

	const [state, dispatch] = useReducer(InvoicesReducer, {
		invoices: transaction.transactionInvoices?.invoices ?? [],
		invoiceView: undefined,
		invoiceDelete: undefined,
		uploadingFiles: [],
		uploadingError: undefined,
	});

	const [selectedCard] = useCardSelectStore((state) => [state.selectedCard]);

	const transactionType = parseTransactionType(
		transaction.txn_type,
		transaction.msg_type,
		transaction.currency,
		transaction.merchant_country
	);

	const checkPartialTransaction =
		transaction.clearing_state === 'PARTIALLY_CLEARED';

	const transactionRefunded = transaction.linked_transactions?.filter(
		(transaction) =>
			transaction.link_type === 'EXPIRES' ||
			transaction.link_type === 'REVERSES' ||
			transaction.link_type === 'REFUNDS'
	);

	const transactionCharged = transaction.linked_transactions?.filter(
		(transaction) => transaction.link_type === 'FEE_FROM'
	);

	const transactionFee = transaction.linked_transactions?.filter(
		(transaction) => transaction.link_type === 'FEE'
	);

	const getTransactionRefundedQuery = useQuery(
		[CORPWAY_EXTRACT_KEY, transactionRefunded?.[0]?.internal_transaction_id],
		() => getExtractDetails(transactionRefunded?.[0]?.internal_transaction_id),
		{
			enabled: transactionType === 'Estorno externo' && isOpen,
		}
	);

	const getTransactionChargedQuery = useQuery(
		[CORPWAY_EXTRACT_KEY, transactionCharged?.[0]?.internal_transaction_id],
		() => getExtractDetails(transactionCharged?.[0]?.internal_transaction_id),
		{
			enabled: transactionType === 'IOF' && isOpen,
		}
	);

	const getTransactionFeeQuery = useQuery(
		[CORPWAY_EXTRACT_KEY, transactionFee?.[0]?.internal_transaction_id],
		() => getExtractDetails(transactionFee?.[0]?.internal_transaction_id),
		{
			enabled:
				transactionType === 'Compra internacional' &&
				isOpen &&
				!!transactionFee.length,
		}
	);

	const uploadInvoicesMutation = useMutation(
		(invoices: FormData) => {
			return uploadInvoicesFiles(transaction.internal_transaction_id, invoices);
		},
		{
			onSuccess: (data) => {
				dispatch({
					invoices: data.invoices,
					uploadingFiles: [],
				});
				transaction.transactionInvoices = data;
				setFilesModified(true);
			},
			onError: (err) => {
				dispatch({
					uploadingFiles: [],
				});
				toast.error('Ocorreu um problema ao tentar carregar o arquivo.');
			},
		}
	);

	function removeInvoice(invoice: string) {
		dispatch({
			invoices: state.invoices.filter((e) => e !== invoice),
			invoiceView: undefined,
		});
		const invoicesFormData = new FormData();
		invoicesFormData.append(`invoiceToRemove`, invoice);

		uploadInvoicesMutation.mutate(invoicesFormData);
	}

	function uploadInvoice(files: File[]) {
		let uploadedFiles = state.invoices.length;
		let uploadingFiles: File[] = [];

		const invoicesFormData = new FormData();
		files.forEach((invoice) => {
			if (uploadedFiles + 1 > MAX_FILES_ATTACHED) {
				dispatch({
					uploadingError: 'already-too-many-files',
				});

				return;
			}

			uploadingFiles.push(invoice);
			invoicesFormData.append(`invoices[]`, invoice);
			uploadedFiles++;
		});

		dispatch({
			uploadingFiles: uploadingFiles,
		});
		uploadInvoicesMutation.mutate(invoicesFormData);
	}

	function handleCloseModal() {
		setIsOpen(false);
		dispatch({
			uploadingError: undefined,
		});
		setModalContent(0);
		setFileDragActive(false);
	}

	const ObservationFormContent = () => {
		return (
			<>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						marginBottom: '2rem',
					}}
				>
					<Typography size='1.6rem' weight='600' color='var(--dark-gray)'>
						Detalhes da transação:
					</Typography>

					<S.ObservationBttn onClick={() => setModalContent(0)}>
						<MdOutlineKeyboardArrowLeft color={'#fff'} size={70} />
					</S.ObservationBttn>
				</div>

				<ObservationForm
					transaction={transaction}
					returnToDetails={setModalContent}
				/>
			</>
		);
	};

	const getHeaderTitle = () => {
		if (
			transactionType === 'Adição de saldo' ||
			transactionType === 'Estorno interno'
		)
			return currentCompany?.corporateName;

		return transaction.merchant_name;
	};

	function renderIOFFields() {
		if (transactionType === 'IOF' && getTransactionChargedQuery.data)
			return (
				<>
					<S.Field>
						<Typography color='var(--dark-gray)'>Valor original:</Typography>

						<Typography color='var(--dark-gray)'>
							{convertCentsToFormattedReais(
								getTransactionChargedQuery.data.billing_amount
							)}
						</Typography>
					</S.Field>

					<S.Field>
						<Typography color='var(--dark-gray)'>Estabelecimento:</Typography>

						<Typography color='var(--dark-gray)'>
							{getTransactionChargedQuery.data.merchant_name}
						</Typography>
					</S.Field>
				</>
			);
	}

	function renderRefundFields() {
		if (
			transactionType === 'Estorno externo' &&
			getTransactionRefundedQuery.data
		)
			return (
				<>
					<S.Field>
						<Typography color='var(--dark-gray)'>Valor original:</Typography>

						<Typography color='var(--dark-gray)'>
							{convertCentsToFormattedReais(
								getTransactionRefundedQuery.data.billing_amount
							)}
						</Typography>
					</S.Field>

					<S.Field>
						<Typography color='var(--dark-gray)'>Data da transação:</Typography>

						<Typography color='var(--dark-gray)'>
							{Intl.DateTimeFormat('pt-BR', {
								day: '2-digit',
								month: '2-digit',
								year: 'numeric',
								hour: '2-digit',
								minute: '2-digit',
								hour12: false, // 24-hour format
							})
								.format(new Date(getTransactionRefundedQuery.data.added_time))
								.replace(',', ' às')}
						</Typography>
					</S.Field>
				</>
			);
	}

	function renderCommonFields() {
		if (transactionType !== 'Estorno externo' && transactionType !== 'IOF') {
			return (
				<>
					{transaction.currency !== 986 && (
						<S.Field>
							<Typography color='var(--dark-gray)'>Valor original:</Typography>

							<Typography color='var(--dark-gray)'>
								{convertCentsToCurrency(
									String(transaction.currency),
									transaction.txn_amount
								)}
							</Typography>
						</S.Field>
					)}

					{checkPartialTransaction && (
						<S.Field>
							<Typography color='var(--dark-gray)'>
								Valor original (R$):
							</Typography>

							<Typography color='var(--dark-gray)'>
								{convertCentsToFormattedReais(transaction.billing_amount)}
							</Typography>
						</S.Field>
					)}

					{transactionType === 'Compra internacional' &&
						getTransactionFeeQuery.data && (
							<S.Field>
								<Typography color='var(--dark-gray)'>IOF:</Typography>
								<Typography color='var(--dark-gray)'>
									{getTransactionFeeQuery.data.fee_configuration_type ===
									'PERCENT'
										? `${
												getTransactionFeeQuery.data.fee_configuration_value /
												100
										  }%`
										: convertCentsToFormattedReais(
												getTransactionFeeQuery.data.fee_configuration_value
										  )}
								</Typography>
							</S.Field>
						)}
				</>
			);
		}
	}

	return (
		<>
			<Button
				style={{
					color: 'var(--primary-blue)',
					border: 'none',
					padding: '0',
				}}
				intent='ghost'
				onClick={() => {
					setIsOpen(true);
					setAnimationModal(true);
				}}
				data-rh='Detalhes da transação'
			>
				<MdNotes />
			</Button>

			<Modal
				isOpen={isOpen}
				onRequestClose={handleCloseModal}
				position='right'
				titleTheme='dark'
				closeBtnStyles={{ zIndex: 3 }}
				animateOnClose={animationModal}
				runAfterClosingAnimation={() => {
					if (filesModified) {
						queryClient.invalidateQueries(CORPWAY_EXTRACT_KEY);
						setFilesModified(false);
					}
				}}
			>
				<S.Container>
					<S.HeaderContent>
						<S.TransactionCategory>
							<Typography color='white' weight='500' size='1.7rem'>
								{getHeaderTitle()}
							</Typography>
						</S.TransactionCategory>
						<S.TransactionAmount>
							<Typography color='white' weight='500' size='1.7rem'>
								{convertCentsToFormattedReais(
									checkPartialTransaction
										? transaction.amount_cleared_net
										: transaction.billing_amount
								)}
							</Typography>
						</S.TransactionAmount>
						{!!selectedCard && (
							<S.HeaderDetailsContainer>
								<S.HeaderDetail>
									<Typography color='white' size='1.4rem'>
										Cartão final:
									</Typography>
									<Typography color='white' weight='300' size='1.4rem'>
										{formatCardNumber(selectedCard?.pan ?? '')}
									</Typography>
								</S.HeaderDetail>
								<S.HeaderDetail style={{ alignItems: 'flex-end' }}>
									<Typography color='white' size='1.4rem'>
										Centro de custo:
									</Typography>
									<Typography color='white' weight='300' size='1.4rem'>
										{truncateString(
											selectedCard?.cost_center_data?.title ?? ''
										)}
									</Typography>
								</S.HeaderDetail>
							</S.HeaderDetailsContainer>
						)}
					</S.HeaderContent>

					<S.MainContent style={{ zIndex: 999 }}>
						{modalContent === 0 && (
							<>
								<S.SubTitleRow>
									<Typography
										size='1.6rem'
										weight='600'
										color='var(--dark-gray)'
									>
										Detalhes da transação:
									</Typography>

									<S.ObservationBttn
										onClick={() => setModalContent(1)}
										observation={!!transaction.transactionInvoices?.observation}
									>
										<FaPen color={'#fff'} size={15} />
									</S.ObservationBttn>
								</S.SubTitleRow>

								<S.FieldsContainer>
									<S.Field>
										<Typography color='var(--dark-gray)'>
											Lançamento:
										</Typography>

										<Typography color='var(--dark-gray)'>
											{transactionType}
										</Typography>
									</S.Field>

									{renderIOFFields()}
									{renderRefundFields()}
									{renderCommonFields()}

									<S.Field>
										<Typography color='var(--dark-gray)'>
											{transactionType === 'Estorno externo'
												? 'Data do estorno:'
												: 'Data da transação:'}
										</Typography>
										<Typography color='var(--dark-gray)'>
											{Intl.DateTimeFormat('pt-BR', {
												day: '2-digit',
												month: '2-digit',
												year: 'numeric',
												hour: '2-digit',
												minute: '2-digit',
												hour12: false, // 24-hour format
											})
												.format(new Date(transaction.added_time))
												.replace(',', ' às')}
										</Typography>
									</S.Field>
								</S.FieldsContainer>

								<S.SubTitleRow style={{ marginTop: '25px' }}>
									<Typography
										size='1.6rem'
										weight='600'
										color='var(--dark-gray)'
									>
										Status da transação:
									</Typography>

									{parseTransactionStatusToVisualComponent(
										parseExtractTransactionStatus(
											transaction.msg_type,
											transaction.txn_type,
											transaction.response_code,
											transaction.cleared,
											transaction.clearing_state,
											transaction.financial_impact_type
										)
									)}
								</S.SubTitleRow>

								<S.InvoiceUploadSection>
									{state.invoices.map((invoice, index) => {
										const [invoiceId, ...invoiceName] = invoice.split('-');

										return (
											<S.UploadedFileContainer
												onClick={() =>
													dispatch({
														invoiceView: {
															name: invoice,
															parsedName: invoiceName.join(''),
															file: transaction.transactionInvoices
																?.invoices_urls[index]!,
														},
													})
												}
												disabled={uploadInvoicesMutation.isLoading}
											>
												<S.UploadIcon src={uploadIcon} alt='' />
												<S.UploadFileText>{invoiceName}</S.UploadFileText>
												<S.DeleteIcon
													src={trashIcon}
													alt=''
													onClick={(e) => {
														e.stopPropagation();
														!uploadInvoicesMutation.isLoading &&
															dispatch({
																invoiceDelete: {
																	name: invoice,
																	parsedName: invoiceName.join(''),
																	file: transaction.transactionInvoices
																		?.invoices_urls[index]!,
																},
															});
													}}
												/>
											</S.UploadedFileContainer>
										);
									})}

									{state.uploadingFiles.map((file) => {
										return (
											<>
												<S.UploadedFileContainer>
													<S.UploadIcon src={uploadIcon} alt='' />
													<S.UploadFileText>{file.name}</S.UploadFileText>
												</S.UploadedFileContainer>
												<Loader
													loaderType={'bar'}
													color='var(--primary-blue)'
													size={'80%'}
												/>
											</>
										);
									})}

									{state.invoices.length < 2 &&
										!['IOF', 'Estorno interno', 'Adição de saldo'].includes(
											transactionType
										) && (
											<Dropzone
												accept={'.jpg, .jpeg, .png, .pdf'}
												onDropAccepted={(acceptedFiles) =>
													uploadInvoice(acceptedFiles)
												}
												onDrop={() => setFileDragActive(false)}
												onDropRejected={(fileRejection) =>
													dispatch({
														uploadingError: fileRejection[0].errors[0].code,
													})
												}
												onDragEnter={() => setFileDragActive(true)}
												onDragLeave={() => setFileDragActive(false)}
												maxFiles={MAX_FILES_ATTACHED}
												maxSize={MAX_FILE_SIZE}
												disabled={uploadInvoicesMutation.isLoading}
											>
												{({ getRootProps, getInputProps }) => {
													return (
														<div
															{...getRootProps()}
															style={{
																display: 'flex',
																justifyContent: 'center',
																marginTop: '20px',
															}}
														>
															<S.DragContainer
																disabled={uploadInvoicesMutation.isLoading}
																drag={fileDragActive}
															>
																<S.IconContainer>
																	<S.IconHeader src={fileIcon} alt='' />
																</S.IconContainer>
																<S.DragTitle>
																	Clique ou arraste o anexo
																</S.DragTitle>
																<S.DragSubtitle>
																	Formato JPEG, JPG, PNG ou PDF até 3mb
																</S.DragSubtitle>
															</S.DragContainer>
															<input {...getInputProps()} />
														</div>
													);
												}}
											</Dropzone>
										)}
								</S.InvoiceUploadSection>
							</>
						)}

						{modalContent === 1 && <ObservationFormContent />}
					</S.MainContent>
				</S.Container>
				<UploadRejectionModal
					error={state.uploadingError}
					setErrors={(file: string | undefined) =>
						dispatch({ uploadingError: file })
					}
				/>
				<UploadViewerModal
					file_data={state.invoiceView}
					setFile={(file: UploadedInvoice | undefined) =>
						dispatch({ invoiceView: file })
					}
					deleteFile={(file: UploadedInvoice | undefined) =>
						dispatch({ invoiceDelete: file })
					}
				/>
				<DeleteInvoiceModal
					file_data={state.invoiceDelete}
					setFile={(file: UploadedInvoice | undefined) =>
						dispatch({ invoiceDelete: file })
					}
					deleteFile={removeInvoice}
				/>
			</Modal>
		</>
	);
}
