import { useForm } from 'react-hook-form';
import {
	Collaborator,
	CollaboratorUnderageDocument,
	Group,
	UnderageResponsible,
} from '../../@types';
import { GroupSelector } from '../GroupSelector';
import { cepMask, cpfMask, phoneMask } from '../../utils/masks';
import {
	ObrigatoryFieldsIndication,
	ObrigatoryIndicator,
} from '../ObrigatoryFieldsIndicator';
import * as FormStyle from '../Form/FormStyles';
import { KYCFieldsIndication, KYCFieldsIndicator } from '../KYCFieldsIndicator';
import { toast } from 'react-toastify';
import { useAuth } from '../../hooks/useAuth';
import { subtractYears } from '../../utils/parseDate';
import { useQuery } from 'react-query';
import { getCostCentersSelect } from '../../services/queries/CostCenters';
import { CostCenterCreationModal } from '../CostCenterCreationModal';
import { useEffect, useState } from 'react';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { UF_List } from '../../utils/CheckUF';
import * as S from './styles';
import {
	FaCheckCircle,
	FaExclamationCircle,
	FaQuestionCircle,
	FaRegClock,
} from 'react-icons/fa';
import PreventTransitionPrompt from '../PreventTransitionPrompt';
import { UnderageResponsibleModal } from './UnderageResponsibleModal';
import {
	cepPattern,
	cpfPattern,
	emailPattern,
	onlyLettersAndSpacesPattern,
	addressesFieldsCollaboratorSWAPPattern,
	phonePattern,
	addressNumberPattern,
} from '../../utils/patterns';
import { trimObjectData } from '../../utils/trimObjectData';
import { showErrorMessage } from '../../utils/ErrorHandler';
import IconHover from '../IconHover';
import { warningOnlyNumbersComplementMessage } from '../../utils/validateCollaborator';
import { useGetAddressByCEP } from '../../hooks/useGetAddressByCEP';
import { SearchCEPButton } from '../SearchCEPButton';
import { ManageBenefitsModal } from '../../pages/FlexibleBenefits/Collaborators/ManageCollaborators/ManageBenefitsModal';
import {
	AlertValidEmail,
	AlertValidEmailText,
} from '../../components/AlertValidEmail';

export interface CollaboratorFormProps {
	collaborator?: Collaborator;
	onSubmit(data: Collaborator): Promise<void>;
	changeStatus?(): Promise<void>;
	addedGroups?: Group[];
	addCollabToTheGroups?(groups: Group[]): void;
	removeCollabFromGroups?(groups: Group[]): void;
	onDelete?(): void;
}

const CollaboratorForm = ({
	collaborator,
	onSubmit,
	changeStatus,
	addedGroups,
	addCollabToTheGroups,
	removeCollabFromGroups,
	onDelete,
}: CollaboratorFormProps) => {
	const { currentCompany } = useAuth();

	const [hasNoNumber, setHasNoNumber] = useState(false);
	const [isUnderage, setIsUnderage] = useState(!!collaborator?.document);
	const [underageDocument, setUnderageDocument] = useState<File | null>(null);
	const [hasDifferentEmailDomain, setHasDifferentEmailDomain] = useState(false);

	const [underageResponsible, setUnderageResponsible] =
		useState<UnderageResponsible | null>(null);

	const { register, handleSubmit, reset, getValues, formState, setValue } =
		useForm({
			defaultValues: {
				...collaborator,
				cpf: cpfMask(collaborator?.cpf || ''),
				cep: cepMask(collaborator?.cep || ''),
				first_phone: phoneMask(collaborator?.first_phone || ''),
				second_phone: phoneMask(collaborator?.second_phone || ''),
				birth_date: parseDateToInput(collaborator?.birth_date),
				id_cost_center: collaborator?.cost_center?.id || '',
				uf: collaborator?.uf || '',
			},
		});

	const { isDirty } = formState;
	const [allowNavigation, setAllowNavigation] = useState(!isDirty);

	useEffect(() => {
		setAllowNavigation(!isDirty);
	}, [isDirty]);

	useEffect(() => {
		reset({
			...collaborator,
			cpf: cpfMask(collaborator?.cpf || ''),
			cep: cepMask(collaborator?.cep || ''),
			first_phone: phoneMask(collaborator?.first_phone || ''),
			second_phone: phoneMask(collaborator?.second_phone || ''),
			birth_date: parseDateToInput(collaborator?.birth_date),
			id_cost_center: collaborator?.cost_center?.id || '',
			uf: collaborator?.uf || '',
		});

		checkUnderageInputChange(parseDateToInput(collaborator?.birth_date));

		if (collaborator?.number === 'Sem número') {
			setHasNoNumber(true);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [collaborator]);

	const fetchCostCentersQuery = useQuery(
		['costCenterSelect', currentCompany?.id],
		() => {
			return getCostCentersSelect(currentCompany?.id);
		},
		{
			onSuccess: () => {
				reset({ ...getValues() });
			},
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um erro ao buscar os centros de custo. '
				);
			},
			refetchOnWindowFocus: false,
		}
	);

	const { searchAddressByCEP, isSearchingCEP } = useGetAddressByCEP({
		onAddressFoundCallback: (address) => {
			toast.info(
				'Verifique os campos de endereço antes de terminar o cadastro, pois serão os dados utilizados para o envio do cartão!'
			);
			reset({ ...getValues(), ...address }); // reset the form filling with the fetched address
		},
	});

	async function handleSubmitForm(data: Collaborator) {
		if (isUnderage) {
			if (underageDocument) data.document = underageDocument;

			if (!underageResponsible && !collaborator?.responsible) {
				toast.error(
					'Preencha e salve as informações da pessoa responsável pelo colaborador.'
				);
				return;
			}

			if (underageResponsible) data.responsible = underageResponsible;
		}

		setAllowNavigation(true);
		await onSubmit(trimObjectData(data)); // Send to the parent (AddCollaborator Page or EditCollaborator)
		setAllowNavigation(false);
	}

	function checkUnderageInputChange(input: string) {
		const today = new Date();
		if (!(Number(input.slice(0, 4)) > 1900)) {
			setIsUnderage(false);
			return;
		}

		const inputDate = new Date(input.replaceAll('-', '/'));
		let eighteenYearsAgo = today;
		eighteenYearsAgo.setFullYear(today.getFullYear() - 18);

		// check if collaborator is underage
		if (inputDate.valueOf() > eighteenYearsAgo.valueOf()) {
			setIsUnderage(true);
		} else {
			setIsUnderage(false);
		}
	}

	function checkEmailDomain(email: string) {
		if (email === '' || email.indexOf('@') === -1) {
			setHasDifferentEmailDomain(false);
			return;
		}

		const email_domain = email.substring(email.indexOf('@'), email.length);

		if (email_domain !== currentCompany?.email_domain) {
			setHasDifferentEmailDomain(true);
		} else {
			setHasDifferentEmailDomain(false);
		}
	}

	function checkNoNumber() {
		setHasNoNumber(!hasNoNumber);

		setValue('number', 'Sem número');
	}

	function onInvalidInput(message: string) {
		toast.error(message, { style: { fontSize: '1.3rem' } });
	}

	function parseDateToInput(date?: string) {
		if (!date) return '';

		const length = date.length;
		try {
			return `${date.substring(length, length - 4)}-${date.substring(
				length - 5,
				length - 7
			)}-${date.substring(0, 2)}`;
		} catch (err) {
			return date;
		}
	}

	function isDocumentEditable(document?: File | CollaboratorUnderageDocument) {
		if (!document) return true;
		return (document as CollaboratorUnderageDocument).status !== 'approved';
		// return document instanceof File || (document as CollaboratorUnderageDocument).status !== "approved";
	}
	function getDocumentStatusIcon(status: string) {
		if (status === 'approved') return <FaCheckCircle color='#1CCA0D' />;

		if (status === 'disapproved')
			// return   <FaTimesCircle color="#000"/>
			return <FaExclamationCircle color='#FB1313' />;

		if (status === 'requested') return <FaRegClock color='#E0E414' />;

		return <FaQuestionCircle />;
	}
	function parseDocumentStatus(status: string) {
		if (status === 'approved') return 'Aprovado';
		if (status === 'disapproved') return 'Desaprovado';
		if (status === 'requested') return 'Em análise';
	}

	function getResponsibleModalButtonStyle() {
		return (
			!!collaborator?.document && isDocumentEditable(collaborator?.document)
		);
	}

	return (
		<FormStyle.Form
			onSubmit={handleSubmit(handleSubmitForm)}
			data-testid='collaborator_form_test_id'
		>
			{!allowNavigation && <PreventTransitionPrompt />}

			<FormStyle.FormContainer>
				{/* COMPANY DATA FIELDSET */}
				<FormStyle.FieldSet>
					<FormStyle.FieldGroup>
						<FormStyle.Field>
							<FormStyle.Label htmlFor='first_name'>
								Primeiro nome <ObrigatoryIndicator />
								<KYCFieldsIndicator />
							</FormStyle.Label>
							<FormStyle.Input
								type='text'
								{...register('first_name')}
								name='first_name'
								data-testid='firstNameInput_test_id'
								pattern={onlyLettersAndSpacesPattern}
								maxLength={30}
								onInvalid={(e) => {
									if (e.currentTarget.value === '') return;
									onInvalidInput(
										'Utilize apenas letras e espaços no campo de primeiro nome'
									);
								}}
								required
							/>
						</FormStyle.Field>

						<FormStyle.Field>
							<FormStyle.Label htmlFor='last_name'>
								Sobrenome completo <ObrigatoryIndicator />
								<KYCFieldsIndicator />
							</FormStyle.Label>
							<FormStyle.Input
								type='text'
								{...register('last_name')}
								name='last_name'
								data-testid='lastNameInput_test_id'
								pattern={onlyLettersAndSpacesPattern}
								maxLength={30}
								onInvalid={(e) => {
									if (e.currentTarget.value === '') return;
									onInvalidInput(
										'Utilize apenas letras e espaços no campo de sobrenome'
									);
								}}
								required
							/>
						</FormStyle.Field>
					</FormStyle.FieldGroup>

					<FormStyle.FieldGroup>
						<FormStyle.Field>
							<FormStyle.Label htmlFor='mother_name'>
								Nome da mãe completo <ObrigatoryIndicator />
								<KYCFieldsIndicator />
							</FormStyle.Label>
							<FormStyle.Input
								type='text'
								{...register('mother_name')}
								name='mother_name'
								data-testid='motherNameInput_test_id'
								pattern={onlyLettersAndSpacesPattern}
								maxLength={255}
								onInvalid={(e) => {
									if (e.currentTarget.value === '') return;
									onInvalidInput(
										'Utilize apenas letras e espaços no campo nome da mãe'
									);
								}}
								required
							/>
						</FormStyle.Field>

						<FormStyle.Field>
							<FormStyle.Label htmlFor='cpf'>
								CPF <ObrigatoryIndicator />
								<KYCFieldsIndicator />
							</FormStyle.Label>
							<FormStyle.Input
								type='text'
								{...register('cpf')}
								name='cpf'
								pattern={cpfPattern}
								placeholder='Ex: 999.999.999-99'
								disabled={!!collaborator?.cpf}
								data-testid='cpfInput_test_id'
								required
								onChange={(event) => {
									const { value } = event.target;
									event.target.value = cpfMask(value);
								}}
							/>
						</FormStyle.Field>
					</FormStyle.FieldGroup>

					<FormStyle.FieldGroup>
						<FormStyle.Field style={{ maxWidth: '25%' }}>
							<FormStyle.Label htmlFor='birth_date'>
								Data de nascimento <ObrigatoryIndicator />
								<KYCFieldsIndicator />
							</FormStyle.Label>
							<FormStyle.Input
								type='date'
								{...register('birth_date')}
								max={subtractYears(new Date(), 10).toISOString().split('T')[0]}
								min='1900-01-01'
								name='birth_date'
								data-testid='birthInput_test_id'
								onChange={(e) => {
									checkUnderageInputChange(e.target.value);
								}}
								required
							/>
						</FormStyle.Field>
						{isUnderage && (
							<FormStyle.Field>
								<FormStyle.Label htmlFor='underage_document'>
									Documento para menores de idade <ObrigatoryIndicator />
									<KYCFieldsIndicator />
									<IconHover
										Icon={AiOutlineQuestionCircle}
										textHover='Colaboradores menores de idade precisam do documento de autorização dos responsáveis'
										sizeIcon='1.4rem'
									/>
								</FormStyle.Label>
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'space-between',
									}}
								>
									{collaborator?.document && (
										<S.DocumentContainer>
											<FormStyle.DocumentLink
												href={
													(
														collaborator.document as CollaboratorUnderageDocument
													).url
												}
												target='_blank'
												rel='noopener noreferrer'
												download
											>
												Documento enviado
											</FormStyle.DocumentLink>
											<S.DocumentStatusContainer>
												{getDocumentStatusIcon(
													(
														collaborator.document as CollaboratorUnderageDocument
													).status
												)}
												{parseDocumentStatus(
													(
														collaborator.document as CollaboratorUnderageDocument
													).status
												)}
											</S.DocumentStatusContainer>
										</S.DocumentContainer>
									)}
									{isDocumentEditable(collaborator?.document) && (
										<>
											<FormStyle.Input
												type='file'
												style={{
													padding: '0.4rem',
													width: '50%',
													height: 'fit-content',
												}}
												accept='application/pdf'
												multiple={false}
												onChange={(e) =>
													setUnderageDocument(
														e.target.files ? e.target.files[0] : null
													)
												}
												name='underage_document'
												data-testid='documentInput_test_id'
												required={!collaborator?.document}
											/>
											<S.ModelLink
												href={`${window.location.origin}/Modelo_Termo_Consentimento.docx`}
												download
											>
												Baixar modelo
											</S.ModelLink>
										</>
									)}

									<UnderageResponsibleModal
										responsible={collaborator?.responsible}
										onSubmit={(responsible) =>
											setUnderageResponsible(responsible)
										}
										smallButton={getResponsibleModalButtonStyle()}
										editable={isDocumentEditable(collaborator?.document)}
									/>
								</div>
							</FormStyle.Field>
						)}
					</FormStyle.FieldGroup>

					<FormStyle.Divider />

					<FormStyle.FieldGroup>
						<FormStyle.Field>
							<FormStyle.Label htmlFor='office'>
								Cargo <ObrigatoryIndicator />
							</FormStyle.Label>
							<FormStyle.Input
								type='text'
								{...register('office')}
								name='office'
								data-testid='officeInput_test_id'
								required
							/>
						</FormStyle.Field>

						<FormStyle.Field>
							<FormStyle.Label htmlFor='section'>
								Setor de atuação <ObrigatoryIndicator />
							</FormStyle.Label>
							<FormStyle.Input
								type='text'
								data-testid='sectionInput_test_id'
								{...register('section')}
								name='section'
								required
							/>
						</FormStyle.Field>
					</FormStyle.FieldGroup>

					<FormStyle.FieldGroup>
						<FormStyle.Field>
							<FormStyle.Label htmlFor='contract_type'>
								Contrato <ObrigatoryIndicator />
							</FormStyle.Label>
							<FormStyle.SelectInput
								data-testid='contractInput_test_id'
								{...register('contract_type')}
								name='contract_type'
								required
							>
								<option value='CLT'>CLT</option>
								<option value='TRAINEE'>Estágio</option>
								<option value='SHAREHOLDER'>Sócio</option>
								<option value='PJ'>PJ</option>
							</FormStyle.SelectInput>
						</FormStyle.Field>

						<FormStyle.Field>
							<FormStyle.Label htmlFor='cost-center'>
								Centro de custo <ObrigatoryIndicator />
							</FormStyle.Label>
							<div style={{ display: 'flex' }}>
								<FormStyle.SelectInput
									data-testid='costCenterInput_test_id'
									{...register('id_cost_center')}
									name='id_cost_center'
									required
								>
									<option disabled value=''>
										Selecione uma opção
									</option>
									{fetchCostCentersQuery.data?.map((costCenter) => (
										<option key={costCenter.id} value={costCenter.id}>
											{costCenter.title}
										</option>
									))}
								</FormStyle.SelectInput>
								<CostCenterCreationModal smallButtonStyle />
							</div>
						</FormStyle.Field>
					</FormStyle.FieldGroup>
					<FormStyle.Field style={{ maxWidth: '49%' }}>
						<FormStyle.Label htmlFor='branch_office'>Filial</FormStyle.Label>
						<FormStyle.Input
							type='text'
							{...register('branch_office')}
							name='branch_office'
							data-testid='branchOfficeInput_test_id'
						/>
					</FormStyle.Field>
					<FormStyle.FieldGroup></FormStyle.FieldGroup>
				</FormStyle.FieldSet>

				<FormStyle.Divider />

				{/* ADDRESS FIELDSET */}
				<FormStyle.FieldSet>
					<FormStyle.FieldGroup>
						<FormStyle.Field>
							<FormStyle.Label htmlFor='address'>
								Endereço <ObrigatoryIndicator />
								<KYCFieldsIndicator />
							</FormStyle.Label>
							<FormStyle.Input
								maxLength={50}
								type='text'
								{...register('address')}
								pattern={addressesFieldsCollaboratorSWAPPattern}
								onInvalid={(e) => {
									if (e.currentTarget.value === '') return;
									onInvalidInput(
										"Utilize apenas letras, espaços e (.)(,)(')(/)(-) no campo de endereço"
									);
								}}
								data-testid='addressInput_test_id'
								name='address'
								required
							/>
						</FormStyle.Field>
						<FormStyle.Field style={{ maxWidth: '25%' }}>
							<FormStyle.Label htmlFor='number'>
								Número <ObrigatoryIndicator />
								<KYCFieldsIndicator />
								<S.CheckBoxContainer>
									<input
										type='checkbox'
										id='checkbox'
										checked={hasNoNumber}
										onChange={checkNoNumber}
									/>
									<S.CheckBoxLabel htmlFor='checkbox'>
										Não possui número
									</S.CheckBoxLabel>
								</S.CheckBoxContainer>
							</FormStyle.Label>
							<FormStyle.Input
								maxLength={10}
								type='text'
								{...register('number')}
								name='number'
								data-testid='numberInput_test_id'
								pattern={addressNumberPattern}
								onInvalid={(e) => {
									if (e.currentTarget.value === '') return;
									onInvalidInput(
										'Utilize apenas caracteres alfanuméricos no campo de número'
									);
								}}
								required
								disabled={hasNoNumber}
							/>
						</FormStyle.Field>
					</FormStyle.FieldGroup>

					<FormStyle.FieldGroup>
						<FormStyle.Field>
							<FormStyle.Label htmlFor='district'>
								Bairro <ObrigatoryIndicator />
								<KYCFieldsIndicator />
							</FormStyle.Label>
							<FormStyle.Input
								maxLength={30}
								type='text'
								{...register('district')}
								name='district'
								placeholder='Ex: Boa Vista'
								pattern={addressesFieldsCollaboratorSWAPPattern}
								onInvalid={(e) => {
									if (e.currentTarget.value === '') return;
									onInvalidInput(
										"Utilize apenas letras, espaços e (.)(,)(')(/)(-) no campo de bairro"
									);
								}}
								data-testid='districtInput_test_id'
								required
							/>
						</FormStyle.Field>

						<FormStyle.Field style={{ maxWidth: '25%' }}>
							<FormStyle.Label htmlFor='cep'>
								CEP <ObrigatoryIndicator />
								<KYCFieldsIndicator />
							</FormStyle.Label>
							<FormStyle.Input
								type='text'
								{...register('cep')}
								name='cep'
								pattern={cepPattern}
								placeholder='Ex: 99999-999'
								required
								data-testid='cepInput_test_id'
								onChange={(e) => {
									const { value } = e.target;
									e.target.value = cepMask(value);
								}}
							/>
						</FormStyle.Field>
						<SearchCEPButton
							isLoading={isSearchingCEP}
							onClick={() => searchAddressByCEP(getValues().cep)}
						/>
					</FormStyle.FieldGroup>

					<FormStyle.FieldGroup>
						<FormStyle.Field>
							<FormStyle.Label htmlFor='complement'>
								<span style={{ marginRight: '1rem' }}>Complemento</span>
								<IconHover
									Icon={AiOutlineQuestionCircle}
									textHover={warningOnlyNumbersComplementMessage}
									sizeIcon='1.4rem'
								/>
							</FormStyle.Label>
							<FormStyle.Input
								data-testid='complementInput_test_id'
								maxLength={30}
								type='text'
								{...register('complement')}
								name='complement'
								pattern={addressesFieldsCollaboratorSWAPPattern}
								placeholder='Ex: Bloco D, Apartamento 02'
								onInvalid={(e) => {
									if (e.currentTarget.value === '') return;
									onInvalidInput(
										"Utilize apenas letras, espaços e (.)(,)(')(/)(-) no campo de complemento"
									);
								}}
							/>
						</FormStyle.Field>

						<FormStyle.Field>
							<FormStyle.Label htmlFor='reference'>
								<span style={{ marginRight: '1rem' }}>Referência</span>
							</FormStyle.Label>
							<FormStyle.Input
								data-testid='referenceInput_test_id'
								maxLength={30}
								type='text'
								{...register('reference')}
								name='reference'
							/>
						</FormStyle.Field>
					</FormStyle.FieldGroup>

					<FormStyle.FieldGroup>
						<FormStyle.Field style={{ maxWidth: '41%' }}>
							<FormStyle.Label htmlFor='city'>
								Cidade <ObrigatoryIndicator />
								<KYCFieldsIndicator />
							</FormStyle.Label>
							<FormStyle.Input
								data-testid='cityInput_test_id'
								maxLength={30}
								type='text'
								{...register('city')}
								name='city'
								pattern={onlyLettersAndSpacesPattern}
								onInvalid={(e) => {
									if (e.currentTarget.value === '') return;
									onInvalidInput(
										'Utilize apenas letras e espaços no campo de cidade'
									);
								}}
								required
							/>
						</FormStyle.Field>

						<FormStyle.Field style={{ maxWidth: '7%' }}>
							<FormStyle.Label htmlFor='uf'>
								UF <ObrigatoryIndicator />
								<KYCFieldsIndicator />
							</FormStyle.Label>
							<FormStyle.SelectInput
								data-testid='ufInput_test_id'
								{...register('uf')}
								name='uf'
								required
							>
								<option disabled value=''>
									Selecione uma opção
								</option>
								{UF_List.map((uf) => (
									<option key={uf} value={uf}>
										{uf}
									</option>
								))}
							</FormStyle.SelectInput>
						</FormStyle.Field>
					</FormStyle.FieldGroup>
				</FormStyle.FieldSet>

				<FormStyle.Divider />

				{/* CONTACT FIELDSET */}
				<FormStyle.FieldSet>
					<FormStyle.FieldGroup>
						<FormStyle.Field style={{ paddingRight: '0.5rem' }}>
							<FormStyle.Label htmlFor='email'>
								Email <ObrigatoryIndicator /> <AlertValidEmail />
							</FormStyle.Label>
							<S.EmailInputContainer>
								<FormStyle.Input
									data-testid='emailInput_test_id'
									type='email'
									{...register('email')}
									name='email'
									placeholder='Ex: exemplo@email.com'
									pattern={emailPattern}
									onChange={(e) => {
										checkEmailDomain(e.target.value);
									}}
									required
								/>
								{hasDifferentEmailDomain && (
									<IconHover
										Icon={FaExclamationCircle}
										textHover='Verificamos que o domínio de email do colaborador não corresponde ao domínio da empresa. Verifique se o endereço de email é o corporativo e não o pessoal. Caso esteja ciente que é o endereço correto você pode ignorar essa mensagem.'
										colorIcon='#FF5900'
									/>
								)}
							</S.EmailInputContainer>
						</FormStyle.Field>
					</FormStyle.FieldGroup>

					<FormStyle.FieldGroup>
						<FormStyle.Field>
							<FormStyle.Label htmlFor='first_phone'>
								Telefone 1 <ObrigatoryIndicator />
								<KYCFieldsIndicator />
							</FormStyle.Label>
							<FormStyle.Input
								data-testid='telInput_test_id'
								type='tel'
								{...register('first_phone')}
								name='first_phone'
								placeholder='Ex: +55 81 99999-9999'
								pattern={phonePattern}
								required
								onChange={(event) => {
									const { value } = event.target;
									event.target.value = phoneMask(value);
								}}
							/>
						</FormStyle.Field>
						<FormStyle.Field>
							<FormStyle.Label htmlFor='second_phone'>
								Telefone 2
							</FormStyle.Label>
							<FormStyle.Input
								data-testid='tel2Input_test_id'
								type='tel'
								{...register('second_phone')}
								name='second_phone'
								placeholder='Ex: +55 81 99999-9999'
								pattern={phonePattern}
								onChange={(event) => {
									const { value } = event.target;
									event.target.value = phoneMask(value);
								}}
							/>
						</FormStyle.Field>
					</FormStyle.FieldGroup>
				</FormStyle.FieldSet>
			</FormStyle.FormContainer>

			<ObrigatoryFieldsIndication />
			<KYCFieldsIndication />
			<AlertValidEmailText />
			<FormStyle.ButtonsContainer>
				<FormStyle.LeftButtonsContainer>
					<FormStyle.FormButton type='submit'>Salvar</FormStyle.FormButton>
					{changeStatus &&
						addCollabToTheGroups &&
						addedGroups &&
						onDelete &&
						removeCollabFromGroups && (
							<div
								style={{ display: 'flex', width: '100%', columnGap: '1rem' }}
							>
								{collaborator?.status && (
									<GroupSelector
										alreadyAddedGroups={
											addedGroups.map((g) => g.id) as string[]
										}
										onAddNewGroups={addCollabToTheGroups}
										onRemoveGroups={removeCollabFromGroups}
										groupSelectorToCollaborator={true}
									/>
								)}
								<ManageBenefitsModal collaboratorId={collaborator!.id!} />
								<FormStyle.Option
									onClick={(e) => {
										e.preventDefault();
										changeStatus();
									}}
									data-testid='changeStatus_button'
								>
									{collaborator!.status ? 'Desativar ' : 'Ativar '}colaborador
								</FormStyle.Option>
								<S.Option
									cancel={true}
									onClick={(e) => {
										e.preventDefault();
										onDelete();
									}}
									style={{ marginLeft: 'auto' }}
									data-testid='deleteCollab_button'
								>
									Remover
								</S.Option>
							</div>
						)}
				</FormStyle.LeftButtonsContainer>
			</FormStyle.ButtonsContainer>
		</FormStyle.Form>
	);
};

export default CollaboratorForm;
