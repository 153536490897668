import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { BeneficiariesWithBenefitsWithoutValueModal } from '../../../../../components/BeneficiariesWithBenefitsWithoutValueModal';
import { showCancelableToast } from '../../../../../components/CancelableToast';
import { CollaboratorsWithRecentPaymentsModal } from '../../../../../components/CollaboratorsWithRecentPaymentsModal';
import { NotApprovedCollabsModal } from '../../../../../components/NotApprovedCollabsModal';
import PreventTransitionPrompt from '../../../../../components/PreventTransitionPrompt';
import { Beneficiary } from '../../../../../contexts/PaymentContextV2';
import { useAuth } from '../../../../../hooks/useAuth';
import { useDialogModal } from '../../../../../hooks/useDialogModal';
import { usePayment } from '../../../../../hooks/usePaymentV2';
import {
	checkCollaboratorsMonthPayments,
	CheckCollaboratorsRecentPaymentsList,
	CheckGroupsRecentPaymentsList,
} from '../../../../../services/queries/PaymentsV2';
import {
	createPayment,
	editPayment,
	GroupReleaseData,
	PreReleaseBenefit,
	PreReleaseData,
} from '../../../../../services/queries/PaymentsV2';
import { isDateAfter } from '../../../../../utils/DateHelper';
import { PaymentSummary } from '../PaymentSummary';
import * as PaymentStyle from '../../styles';
import { parseCurrencyStrToNumber } from '../../../../../utils/parseCurrency';
import { getBenefitsInputs } from '../../utils/getBenefitValuesInputs';
import { convertReaisToCents } from '../../../../../utils/CurrencyConvert';
import { showErrorMessage } from '../../../../../utils/ErrorHandler';

export interface SubmitPaymentParams {
	preReleases: PreReleaseData[];
	groupReleases: GroupReleaseData[];
}

interface Props {
	editPaymentId?: string;
	disabled?: boolean;
}

// CreatePayment, RelaunchPayment and ImportPayment performs several steps before submitting
// this component is responsible for those steps
export function SubmitPayment(props: Props) {
	const history = useHistory();
	const { user, currentCompany, updateCompanyBalance } = useAuth();
	const {
		beneficiaries,
		setBeneficiaries,
		benefits,
		selectedBenefits,
		schedule,
		setSubmitProgress,
	} = usePayment();
	const { openOptionsDialog, openConfirmDialog } = useDialogModal();

	const [showSummary, setShowSummary] = useState(false);
	const [
		beneficiariesWithBenefitsWithoutValue,
		setBeneficiariesWithBenefitsWithoutValue,
	] = useState<Beneficiary[]>([]);

	const isEditSubmition = !!props.editPaymentId;

	// the user has changed the payment then it should prompt confirmation when trying to leave page
	const [allowNavigation, setAllowNavigation] = useState(true);
	useEffect(() => {
		if (beneficiaries.length || benefits.length || schedule)
			setAllowNavigation(false);
	}, [beneficiaries, schedule, benefits]);

	const createPaymentMutation = useMutation(
		({ preReleases, groupReleases }: SubmitPaymentParams) => {
			return createPayment(
				preReleases,
				groupReleases,
				schedule,
				currentCompany!.id
			);
		},
		{
			onSuccess: onSubmitionSuccess,
			onError: (err) => {
				setSubmitProgress(undefined);
				showErrorMessage(err as Error, 'Não foi possível criar o lançamento. ');
			},
		}
	);

	const editPaymentMutation = useMutation(
		({ preReleases, groupReleases }: SubmitPaymentParams) => {
			return editPayment(
				props.editPaymentId!,
				preReleases,
				groupReleases,
				schedule,
				currentCompany!.id
			);
		},
		{
			onSuccess: onSubmitionSuccess,
			onError: (err) => {
				setSubmitProgress(undefined);
				showErrorMessage(
					err as Error,
					'Não foi possível editar o lançamento. '
				);
			},
		}
	);

	const collaboratorsRecentPaymentsQuery = useMutation(
		({
			collaboratorsCPFs,
			groupsIds,
		}: {
			collaboratorsCPFs: CheckCollaboratorsRecentPaymentsList[];
			groupsIds: CheckGroupsRecentPaymentsList[];
		}) =>
			checkCollaboratorsMonthPayments(
				collaboratorsCPFs,
				groupsIds,
				schedule,
				currentCompany!.id
			),
		{
			onSuccess: (data) => {
				setSubmitProgress(undefined);
				// continue the payment operation since there are no payments in the month
				if (data.recentPayments.length === 0) {
					showConfirmationLoading();
				}
			},
			onError: (err) => {
				console.log(err);
				toast.error(
					'Ocorreu um erro ao buscar informações dos colaboradores no lançamento.'
				);
				setSubmitProgress(undefined);
			},
		}
	);

	/*
    PAYMENT SUBMITION FLOW:
      1. Click submit button: "Gerar pagamentos";
      2. Do verifications (is everything selected, schedule ok?, etc);
			3. Set Beneficiaries with inputs values and showPaymentSummary
      4. Submits PaymentSummary;
				4.1 PaymentSummary submition does balances verifications;
      5. checkSelectedCollaboratorsWithBenefitsWithoutValue() --> checks if there are any selected 
        collaborator with no values in all benefits. If there are, then render and stop the submition proccess;
      6. Check collaborators payments in the month --> If there are none, continue (onSuccess), else, 
        show confirm button to continue;
      7. showConfirmationLoading() --> show loading with a last option to cancel the payment;
      8. submitPayment() --> is called from showConfirmationLoading(), if not canceled, and submits payment 
        to the api;
      9. Show success message, and list of collaborators that didn't pass (if any).
  */

	function doPreSubmitionVerifications() {
		// Verifications pre-release
		if (!beneficiaries.length) {
			toast.error('Nenhum beneficiado selecionado para o pagamento');
			return;
		}
		if (!selectedBenefits.length) {
			toast.error('Nenhum benefício selecionado');
			return;
		}

		const thirtyMinFromNow = new Date(Date.now() + 30 * 60000); // current time + 30 min
		if (schedule && !isDateAfter(schedule, thirtyMinFromNow)) {
			toast.error(
				`Certifique-se de agendar com pelo menos 30 minutos em relação ao horário atual.`
			);
			return;
		}

		if (!beneficiaries.some((collab) => collab.selected)) {
			openOptionsDialog(
				'Nenhum beneficiado selecionado. Faremos o lançamento para todos.',
				'Continuar com operação de lançamento para todos',
				() => {
					setBeneficiariesValues(true);
				},
				'Cancelar',
				() => {}
			);

			return;
		}

		setBeneficiariesValues();
	}

	function setBeneficiariesValues(selectAll?: boolean) {
		const benefitsInputs = getBenefitsInputs();

		const updatedBeneficiaries = beneficiaries.map((beneficiary) => {
			// updated beneficiary benefits list with the values from the inputs
			const updatedBenefits = beneficiary.benefits.map((benefit) => {
				const input = benefitsInputs.find(
					(input) => input.id === `${beneficiary.id}-${benefit.id}`
				)!;

				return { ...benefit, value: parseCurrencyStrToNumber(input.value) };
			});

			return {
				...beneficiary,
				benefits: updatedBenefits,
				selected: selectAll ? true : beneficiary.selected,
			};
		});

		setBeneficiaries(updatedBeneficiaries);

		setShowSummary(true);
	}

	function checkSelectedCollaboratorsWithBenefitsWithoutValue() {
		const filteredBenficiaries = beneficiaries.filter(
			(beneficiary) =>
				beneficiary.selected && beneficiary.benefits.every((b) => b.value === 0)
		);

		if (filteredBenficiaries.length) {
			setBeneficiariesWithBenefitsWithoutValue(filteredBenficiaries);
			return;
		}

		checkCollaboratorsPaymentsInTheChosenMonth();
	}

	async function checkCollaboratorsPaymentsInTheChosenMonth() {
		setSubmitProgress('Buscando informações dos colaboradores');
		const collaboratorsCPFs = beneficiaries
			.filter((b) => b.selected && !b.isGroup)
			.map((b) => {
				return { cpf: b.cpf! };
			});
		const groupsIds = beneficiaries
			.filter((b) => b.selected && b.isGroup)
			.map((b) => {
				return { id: b.id };
			});

		collaboratorsRecentPaymentsQuery.mutate({ collaboratorsCPFs, groupsIds });
	}

	async function showConfirmationLoading() {
		setSubmitProgress('confirmation_loading'); // start loading

		const canceled = !(await showCancelableToast('Enviando...', submitPayment));
		if (canceled) {
			setSubmitProgress(undefined); // stop loading
		}
	}

	async function submitPayment() {
		// parse collaborators
		let preReleases: PreReleaseData[] = [];
		const selectedCollaborators = [
			...beneficiaries.filter((collab) => collab.selected && !collab.isGroup),
		];

		selectedCollaborators.forEach((collaborator) => {
			let benefits: PreReleaseBenefit[] = collaborator.benefits
				.filter((b) => b.value > 0)
				.map((benefit) => {
					return {
						benefit_id: benefit.id,
						value: convertReaisToCents(benefit.value),
					}; // converting to cents
				});

			preReleases.push({ user_id: collaborator.id, benefits });
		});

		// parse groups
		let groupReleases: GroupReleaseData[] = [];
		const selectedGroups = [
			...beneficiaries.filter((collab) => collab.selected && collab.isGroup),
		];

		selectedGroups.forEach((group) => {
			let benefits: PreReleaseBenefit[] = group.benefits
				.filter((b) => b.value > 0)
				.map((benefit) => {
					return {
						benefit_id: benefit.id,
						value: convertReaisToCents(benefit.value),
					}; // converting to cents
				});

			groupReleases.push({ group_id: group.id, benefits });
		});

		if (isEditSubmition) {
			editPaymentMutation.mutate({ preReleases, groupReleases });
		} else {
			createPaymentMutation.mutate({ preReleases, groupReleases });
		}
	}

	function onSubmitionSuccess() {
		setAllowNavigation(true);
		setSubmitProgress(undefined);

		let confirmationMessage: string;
		if (isEditSubmition) {
			confirmationMessage = 'Pagamento editado com sucesso!';
		} else {
			if (schedule) {
				confirmationMessage =
					user.access_level === 'admin'
						? 'O agendamento foi criado.'
						: `A solicitação de agendamento de pagamento foi criada.
				Um usuário ADMINISTRADOR deverá aprová-la para que o pagamento seja efetivado na data agendada.`;
			} else {
				confirmationMessage =
					user.access_level === 'admin'
						? 'O pagamento foi realizado.'
						: `A solicitação de pagamento foi criada.
			Um usuário ADMINISTRADOR deverá aprová-la para que os pagamentos sejam efetivados.`;
			}
		}

		openConfirmDialog(
			confirmationMessage,
			'Entendido',
			() => {
				user.access_level === 'admin' && updateCompanyBalance.refetch();
				history.push('/home/payments');
			},
			false
		);
	}

	function goBack() {
		history.push('/home/payments');
	}

	return (
		<>
			{!allowNavigation && <PreventTransitionPrompt />}

			<PaymentStyle.SubmitionOptionsContainer>
				<PaymentStyle.CancellButton onClick={goBack}>
					Cancelar
				</PaymentStyle.CancellButton>

				{/* <PaymentStyle.SubmitButton onClick={showPaymentSummary}> */}
				<PaymentStyle.SubmitButton
					onClick={doPreSubmitionVerifications}
					disabled={props.disabled}
				>
					{isEditSubmition ? 'Salvar alterações' : 'Gerar pagamento'}
				</PaymentStyle.SubmitButton>
			</PaymentStyle.SubmitionOptionsContainer>

			{/* MODALS */}

			<PaymentSummary
				onSubmit={checkSelectedCollaboratorsWithBenefitsWithoutValue}
				isOpenSummary={showSummary}
				closeSummary={() => setShowSummary(false)}
			/>

			<BeneficiariesWithBenefitsWithoutValueModal
				beneficiaries={beneficiariesWithBenefitsWithoutValue}
			/>

			<CollaboratorsWithRecentPaymentsModal
				collaborators={collaboratorsRecentPaymentsQuery.data?.recentPayments}
				submitOption={showConfirmationLoading}
			/>

			<NotApprovedCollabsModal
				collabsReproved={
					isEditSubmition
						? editPaymentMutation.data?.unpaidPayments ?? []
						: createPaymentMutation.data?.unpaidPayments ?? []
				}
			/>
		</>
	);
}
