import { cpf as cpfValidator } from 'cpf-cnpj-validator';
import { parseContractTypeToEnglish } from '../../../../utils/parseContractType';
import moment from 'moment';
import { Collaborator, LineError } from '../../../../@types';
import { toast } from 'react-toastify';
import Modal from '../../../../components/Modal';
import { LineErrorsCollaboratorsImported } from '../../../../components/LineErrorsPaymentTable';
import { ImportCollaborators } from './ImportCollaboratorsFile';
import { useEffect, useState } from 'react';
import ModalLoader from '../../../../components/ModalLoader';
import { useHistory } from 'react-router-dom';
import { getTranslatedErrorMessage } from '../../../../utils/getTranslatedErrorMessage';
import { FileParser } from '../../../../utils/FileParser';
import { useAuth } from '../../../../hooks/useAuth';
import { useMutation, useQuery } from 'react-query';
import { createCollaboratorsBatch } from '../../../../services/queries/Collaborators';
import { getCostCentersSelect } from '../../../../services/queries/CostCenters';
import { checkUF } from '../../../../utils/CheckUF';
import * as yup from 'yup';
import { showErrorMessage } from '../../../../utils/ErrorHandler';
import {
	checkOnlyNumbersInComplement,
	invalidOnlyNumbersComplementMessage,
} from '../../../../utils/validateCollaborator';

interface ParsedCollaboratorFromFile {
	collaborator: Collaborator;
	line: number;
}

export function ImportCollaboratorsPage() {
	const history = useHistory();
	const { currentCompany } = useAuth();
	const [parsedCollaborators, setCollaborators] = useState<
		ParsedCollaboratorFromFile[]
	>([]);
	const [collabInfoErrors, setCollabInfoErrors] = useState<LineError[]>([]);
	const [errors, setErrors] = useState<LineError[]>([]);
	const [progressText, setProgressText] = useState<string | undefined>();
	const [fileName, setFileName] = useState<string | null>(null);

	const importCollaboratorsQuery = useMutation(
		({ users }: { users: Collaborator[] }) =>
			createCollaboratorsBatch(users, fileName!, currentCompany!.id)
	);

	useEffect(() => {
		if (collabInfoErrors.length) {
			toast.warning(
				collabInfoErrors.length +
					' erros foram encontrados. Clique aqui para detalhamento.',
				{
					autoClose: 50000,
					position: 'bottom-right',
					closeOnClick: false,
					onClick: () => {
						setErrors(collabInfoErrors);
					},
				}
			);
		}

		setCollabInfoErrors([]);
		if (parsedCollaborators.length) {
			submitCollaboratorsToApi();
		}
	}, [parsedCollaborators]); //eslint-disable-line

	const costCenters = useQuery(
		['costCenterSelect', currentCompany?.id],
		() => {
			return getCostCentersSelect(currentCompany?.id);
		},
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um erro ao buscar os centros de custo. '
				);
			},
			refetchOnWindowFocus: false,
			enabled: !!currentCompany,
		}
	);

	async function submitCollaboratorsToApi() {
		const users = parsedCollaborators.map(({ collaborator, line }) => {
			// ignore line and remove name from collab obj
			let collabData: any = collaborator;
			delete collabData.name;
			return collabData;
		});

		//send collaborators to API
		try {
			const data = await importCollaboratorsQuery.mutateAsync({
				users,
			});

			setProgressText(undefined); // stop loading

			if (data.inconsistentDataUnregisteredUsers.length > 0) {
				const newErrors = data.inconsistentDataUnregisteredUsers.map(
					(collabWithError) => {
						return {
							error: getTranslatedErrorMessage(collabWithError.error),
							line:
								parsedCollaborators.find(
									(parsedCollab) =>
										parsedCollab.collaborator.cpf === collabWithError.cpf
								)?.line ?? -1,
							collaborator: {
								cpf: collabWithError.cpf,
								name: collabWithError.name,
								email: collabWithError.email,
							} as Collaborator,
						};
					}
				);
				toast.warning(
					newErrors.length +
						' colaboradores não foram cadastrados. Clique aqui para detalhamento.',
					{
						autoClose: 50000,
						position: 'bottom-right',
						closeOnClick: false,
						onClick: () => {
							setErrors(newErrors);
						},
					}
				);

				setCollaborators([]);
			} else {
				toast.info(
					'Colaboradores enviados para a fila de importação. Você receberá uma notificação quando for concluído.'
				);

				// if there was no errors then go to the listing page
				if (!collabInfoErrors.length) history.push('/home/collaborators');
			}
		} catch (err) {
			setProgressText(undefined); // stop loading
			showErrorMessage(
				err as Error,
				'Ocorreu um problema ao tentar cadastrar um colaborador'
			);
			setCollaborators([]);
		}
	}

	function onFileUpload(file: File) {
		setProgressText('Lendo arquivo');
		// This function first parses the file to an Object of arrays and then calls
		// parseToCollaboratorsFormat function to parse this object to parsedCollaborators state
		try {
			const fileParser = new FileParser(file);
			setFileName(fileParser.fileName);
			// FileParser parses the file to an array of array of possible values filled in, each array is a line from the
			// file, each line is an array of values (like strings, numbers, dates etc).
			// Then parseToCollaboratorsFormat is called to parse the convertedToArray obj into Collaborator format
			fileParser.parseFile(parseToCollaboratorsFormat);
		} catch (err) {
			toast.error('Ocorreu um erro ao tentar ler o arquivo. Tente novamente');
			setProgressText(undefined);
		}
	}

	function parseToCollaboratorsFormat(convertedFile: any[][]) {
		let collaboratorsArray: ParsedCollaboratorFromFile[] = [];

		// FIRST ELEMENT FROM ARRAY IS THE FIELDS ARRAY (CPF, NOME, ... ALIMENTAÇÃO, ...)
		let fields: string[] = convertedFile[0];

		const correctFormat = checkFileFields(fields);
		if (!correctFormat) {
			setProgressText(undefined);
			return;
		}

		for (let index = 0; index < convertedFile.length; index++) {
			if (index === 0)
				// Ignore fields
				continue;

			// collaboratorsArray is the collab row with its values ("000.000.000-11", "José da Silva", ... )
			if (convertedFile[index].length) {
				try {
					collaboratorsArray.push({
						collaborator: parseCollaborator(convertedFile[index], index + 1),
						line: index + 1,
					});
					let collaboratorsWithoutLast = collaboratorsArray.slice(
						0,
						collaboratorsArray.length - 1
					);
					let lastCollaborator =
						collaboratorsArray[collaboratorsArray.length - 1];

					if (!validateCollaborator(lastCollaborator.collaborator, index + 1)) {
						collaboratorsArray.pop();
					} else if (
						!checkUniqueCollaborator(
							collaboratorsWithoutLast,
							lastCollaborator.collaborator,
							index + 1
						)
					) {
						collaboratorsArray.pop();
					}
				} catch (err) {
					console.log(err, index + 1);
				}
			}
		}

		if (!collaboratorsArray.length) setProgressText(undefined);

		setCollaborators(collaboratorsArray);
	}

	function checkUniqueCollaborator(
		parsedCollabs: ParsedCollaboratorFromFile[],
		lastCollaborator: Collaborator,
		collabRow: number
	) {
		let error = false;

		if (
			parsedCollabs.find(
				(parsedCollab) => parsedCollab.collaborator.cpf === lastCollaborator.cpf
			)
		) {
			setCollabInfoErrors((oldErrors) => [
				...oldErrors,
				{
					error: `CPF ${lastCollaborator.cpf} duplicado.`,
					line: collabRow,
					collaborator: lastCollaborator,
				},
			]);
			error = true;
		}
		if (
			parsedCollabs.find(
				(parsedCollab) =>
					parsedCollab.collaborator.email === lastCollaborator.email
			)
		) {
			setCollabInfoErrors((oldErrors) => [
				...oldErrors,
				{
					error: `Email ${lastCollaborator.email} duplicado.`,
					line: collabRow,
					collaborator: lastCollaborator,
				},
			]);
			error = true;
		}

		if (error) return false;

		return true;
	}

	function validateCollaborator(collaborator: Collaborator, collabRow: number) {
		const collabEmail =
			typeof collaborator.email === 'undefined' ? '' : collaborator.email;
		const collabCPF = collaborator.cpf;
		const tel_format_regex = /^([1-9]{1})([0-9]{1})([1-9]{2})([0-9]{8,9})$/;
		const onlyNumberAndLetters = /^[0-9a-zA-ZÁ-ÿ\s]*$/;
		let error = false;

		if (!cpfValidator.isValid(collabCPF)) {
			error = true;
			setCollabInfoErrors((oldErrors) => [
				...oldErrors,
				{
					error: `Formato de CPF inválido.`,
					line: collabRow,
					collaborator: collaborator,
				},
			]);
		}

		function onlyLettersAndSpaces(valueToTest: string, field: string) {
			const onlyLetterAndSpaceRegex =
				/^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ\s]*$/;

			if (!onlyLetterAndSpaceRegex.test(valueToTest)) {
				error = true;
				setCollabInfoErrors((oldErrors) => [
					...oldErrors,
					{
						error: `Utilize apenas letras e espaços no campo ${field}.`,
						line: collabRow,
						collaborator: collaborator,
					},
				]);
			}
		}

		function onlyLettersSpacesAndFewSpecialChars(
			valueToTest: string,
			field: string
		) {
			const onlyLetterSpaceAndFewSpecialCharsRegex =
				/^[A-Za-z0-9áàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ.',/-\s]*$/;

			if (!onlyLetterSpaceAndFewSpecialCharsRegex.test(valueToTest)) {
				error = true;
				setCollabInfoErrors((oldErrors) => [
					...oldErrors,
					{
						error: `Utilize apenas letras, espaços e (.)(,)(')(/)(-) no campo de ${field}.`,
						line: collabRow,
						collaborator: collaborator,
					},
				]);
			}
		}

		function onlyLettersSpacesAndNumbers(valueToTest: string, field: string) {
			const regex = /^[A-Za-z0-9\s]*$/;

			if (!regex.test(valueToTest)) {
				error = true;
				setCollabInfoErrors((oldErrors) => [
					...oldErrors,
					{
						error: `Utilize apenas letras, números e espaços no campo de ${field}.`,
						line: collabRow,
						collaborator: collaborator,
					},
				]);
			}
		}

		onlyLettersAndSpaces(collaborator.name, 'NOME');
		onlyLettersAndSpaces(collaborator.mother_name!, 'NOME DA MÃE');
		onlyLettersAndSpaces(collaborator.city!, 'CIDADE');
		onlyLettersSpacesAndFewSpecialChars(collaborator.address!, 'ENDEREÇO');
		onlyLettersSpacesAndFewSpecialChars(collaborator.district!, 'BAIRRO');
		onlyLettersSpacesAndFewSpecialChars(
			collaborator.complement!,
			'COMPLEMENTO'
		);
		onlyLettersSpacesAndFewSpecialChars(collaborator.reference!, 'REFERÊNCIA');
		onlyLettersSpacesAndNumbers(collaborator.branch_office!, 'FILIAL');

		if (!onlyNumberAndLetters.test(collaborator.number!)) {
			error = true;
			setCollabInfoErrors((oldErrors) => [
				...oldErrors,
				{
					error: `Utilize apenas caracteres alfanuméricos no campo de número.`,
					line: collabRow,
					collaborator: collaborator,
				},
			]);
		}

		const emailValidator = yup.object().shape({
			email: yup.string().email(),
		});

		if (!emailValidator.isValidSync({ email: collabEmail })) {
			error = true;
			setCollabInfoErrors((oldErrors) => [
				...oldErrors,
				{
					error: `Formato de EMAIL inválido.`,
					line: collabRow,
					collaborator: collaborator,
				},
			]);
		}

		if (collaborator.uf?.length !== 2) {
			error = true;
			setCollabInfoErrors((oldErrors) => [
				...oldErrors,
				{
					error: `Formato de estado inválido. Insira apenas a sigla de 2 caracteres. Exemplo: SP`,
					line: collabRow,
					collaborator: collaborator,
				},
			]);
		}

		if (collaborator.address!.length > 30) {
			error = true;
			setCollabInfoErrors((oldErrors) => [
				...oldErrors,
				{
					error: `Formato de endereço inválido. O campo deve conter no máximo 30 caracteres.`,
					line: collabRow,
					collaborator: collaborator,
				},
			]);
		}

		if (collaborator.number!.length > 10) {
			error = true;
			setCollabInfoErrors((oldErrors) => [
				...oldErrors,
				{
					error: `Formato de número inválido. O campo deve conter no máximo 10 caracteres.`,
					line: collabRow,
					collaborator: collaborator,
				},
			]);
		}

		if (collaborator.district!.length > 30) {
			error = true;
			setCollabInfoErrors((oldErrors) => [
				...oldErrors,
				{
					error: `Formato de bairro inválido. O campo deve conter no máximo 30 caracteres.`,
					line: collabRow,
					collaborator: collaborator,
				},
			]);
		}

		if (collaborator.city!.length > 30) {
			error = true;
			setCollabInfoErrors((oldErrors) => [
				...oldErrors,
				{
					error: `Formato de cidade inválido. O campo deve conter no máximo 30 caracteres.`,
					line: collabRow,
					collaborator: collaborator,
				},
			]);
		}

		if (collaborator.branch_office!.length > 30) {
			error = true;
			setCollabInfoErrors((oldErrors) => [
				...oldErrors,
				{
					error: `Formato de filial inválido. O campo deve conter no máximo 30 caracteres.`,
					line: collabRow,
					collaborator: collaborator,
				},
			]);
		}

		if (collaborator.complement && collaborator.complement.length > 30) {
			error = true;
			setCollabInfoErrors((oldErrors) => [
				...oldErrors,
				{
					error: `Formato de complemento inválido. O campo deve conter no máximo 30 caracteres.`,
					line: collabRow,
					collaborator: collaborator,
				},
			]);
		}

		if (collaborator.reference && collaborator.reference.length > 30) {
			error = true;
			setCollabInfoErrors((oldErrors) => [
				...oldErrors,
				{
					error: `Formato de referência inválido. O campo deve conter no máximo 30 caracteres.`,
					line: collabRow,
					collaborator: collaborator,
				},
			]);
		}

		if (!checkOnlyNumbersInComplement(collaborator.complement)) {
			error = true;
			setCollabInfoErrors((oldErrors) => [
				...oldErrors,
				{
					error: invalidOnlyNumbersComplementMessage,
					line: collabRow,
					collaborator: collaborator,
				},
			]);
		}

		if (isNaN(Number(collaborator.first_phone))) {
			error = true;
			setCollabInfoErrors((oldErrors) => [
				...oldErrors,
				{
					error: `Formato de telefone inválido. Insira apenas os numeros incluindo código do país e DDD, exemplo: 5581999998888.`,
					line: collabRow,
					collaborator: collaborator,
				},
			]);
		}

		if (!tel_format_regex.test(collaborator.first_phone!.toString())) {
			error = true;
			setCollabInfoErrors((oldErrors) => [
				...oldErrors,
				{
					error: `Formato de telefone inválido. Insira apenas os numeros incluindo código do país e DDD, exemplo: 5581999998888.`,
					line: collabRow,
					collaborator: collaborator,
				},
			]);
		}

		if (
			!!collaborator.second_phone &&
			isNaN(Number(collaborator.second_phone))
		) {
			error = true;
			setCollabInfoErrors((oldErrors) => [
				...oldErrors,
				{
					error: `Formato de segundo telefone inválido. Insira apenas os numeros incluindo código do país e DDD, exemplo: 5581999998888.`,
					line: collabRow,
					collaborator: collaborator,
				},
			]);
		}

		if (
			!!collaborator.second_phone &&
			!tel_format_regex.test(collaborator.second_phone.toString())
		) {
			error = true;
			setCollabInfoErrors((oldErrors) => [
				...oldErrors,
				{
					error: `Formato de segundo telefone inválido. Insira apenas os numeros incluindo código do país e DDD, exemplo: 5581999998888.`,
					line: collabRow,
					collaborator: collaborator,
				},
			]);
		}

		if (
			costCenters.data!.length &&
			!costCenters.data!.find(
				(costCenter) => costCenter.title === String(collaborator.cost_center)
			)
		) {
			error = true;
			setCollabInfoErrors((oldErrors) => [
				...oldErrors,
				{
					error: `Centro de custo inválido. Insira o nome exato do centro de custo desejado.`,
					line: collabRow,
					collaborator: collaborator,
				},
			]);
		}

		function checkUnderageCollaborator() {
			let collaboratorBirthDate = collaborator.birth_date!;
			const length = collaboratorBirthDate.length;
			collaboratorBirthDate = `${collaboratorBirthDate.substring(
				length,
				length - 4
			)}-${collaboratorBirthDate.substring(
				length - 5,
				length - 7
			)}-${collaboratorBirthDate.substring(0, 2)}`;

			let eighteenYearsAgo = new Date();
			eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);
			if (
				new Date(collaboratorBirthDate + 'T00:00:00').valueOf() >
				eighteenYearsAgo.valueOf()
			) {
				error = true;
				setCollabInfoErrors((oldErrors) => [
					...oldErrors,
					{
						error: `Colaboradores menores de idade precisam ser cadastrados manualmente`,
						line: collabRow,
						collaborator: collaborator,
					},
				]);
			}
		}
		checkUnderageCollaborator();

		if (error) return false;

		return true;
	}

	function checkFileFields(fields: string[]) {
		const EXPECTED_FIELDS = [
			'CPF',
			'NOME',
			'NOME DA MÃE',
			'EMAIL',
			'DATA DE NASCIMENTO',
			'CARGO',
			'SETOR',
			'CONTRATO',
			'CENTRO DE CUSTO',
			'FILIAL',
			'CEP',
			'ENDEREÇO',
			'BAIRRO',
			'NÚMERO',
			'COMPLEMENTO',
			'REFERÊNCIA',
			'CIDADE',
			'UF',
			'TELEFONE 1',
			'TELEFONE 2',
		];

		if (fields.length < EXPECTED_FIELDS.length) {
			toast.error(
				'Arquivo não está conforme o modelo aceito. Faltam campos. Veja o arquivo modelo.'
			);
			return false;
		}

		const collabFields = fields.slice(0, 18);
		for (let index = 0; index < collabFields.length; index++) {
			if (
				!(
					collabFields[index].toString().trim().toUpperCase() ===
					EXPECTED_FIELDS[index]
				)
			) {
				toast.error(
					'Arquivo não está conforme o modelo aceito. Veja o arquivo modelo.'
				);
				return false;
			}
		}

		return true;
	}

	// Check if fields data are filled in
	function checkRequiredFieldsData(collaboratorData: any[], collabRow: number) {
		// Fields COMPLEMENT, REFERENCE and SECOND_PHONE aren't required, so their index aren't checked
		// 14 is complement
		// 15 is reference
		// 19 is second phone
		// 9 is branch office
		const requiredFieldsIndex = [
			0, 1, 2, 3, 4, 5, 6, 7, 8, 10, 11, 12, 13, 16, 17, 18,
		];

		const isEmptyRow = collaboratorData.every(
			(col) => col.toString().trim() === ''
		);
		if (isEmptyRow) throw new Error('Empty row');

		if (collaboratorData[13].toString().length === 0)
			collaboratorData[13] = 'Sem número';

		requiredFieldsIndex.forEach((index) => {
			if (collaboratorData[index].toString().length === 0) {
				setCollabInfoErrors((oldErrors) => [
					...oldErrors,
					{
						error: 'Um ou mais campos obrigatórios em branco.',
						line: collabRow,
						collaborator: {
							cpf: collaboratorData[0].toString(),
							name: collaboratorData[1].toString(),
							email: collaboratorData[3].toString(),
						} as Collaborator,
					},
				]);
				throw new Error('Required field blank');
			}
		});

		if (typeof collaboratorData[0] == 'number') {
			setCollabInfoErrors((oldErrors) => [
				...oldErrors,
				{
					error: "CPF precisa estar entre áspas. Ex: '01234567890' ",
					line: collabRow,
					collaborator: {
						cpf: collaboratorData[0].toString(),
						name: collaboratorData[1].toString(),
						email: collaboratorData[3].toString(),
					} as Collaborator,
				},
			]);
			throw new Error('Invalid CPF format');
		}
		if (typeof collaboratorData[10] == 'number') {
			setCollabInfoErrors((oldErrors) => [
				...oldErrors,
				{
					error: "CEP precisa estar entre áspas. Ex: '05555501' ",
					line: collabRow,
					collaborator: {
						cpf: collaboratorData[0].toString(),
						name: collaboratorData[1].toString(),
						email: collaboratorData[3].toString(),
					} as Collaborator,
				},
			]);
			throw new Error('Invalid CEP format');
		}

		if (collaboratorData[1].toString().trim().split(' ').length === 1) {
			setCollabInfoErrors((oldErrors) => [
				...oldErrors,
				{
					error: 'Colaborador sem o nome completo',
					line: collabRow,
					collaborator: {
						cpf: collaboratorData[0].toString(),
						name: collaboratorData[1].toString(),
						email: collaboratorData[3].toString(),
					} as Collaborator,
				},
			]);
			throw new Error('Incomplete name');
		}
	}

	function parseCollaborator(
		collaboratorDataArray: Array<any>,
		collabRow: number
	) {
		let collaborator: Collaborator = {} as Collaborator;
		const only_numbers_regex = /[^0-9]/g;

		checkRequiredFieldsData(collaboratorDataArray, collabRow);

		collaborator.cpf = cpfValidator
			.format(collaboratorDataArray[0])
			.replace(only_numbers_regex, '');

		collaborator.name = collaboratorDataArray[1].toString();

		collaborator.first_name = collaborator.name
			.split(' ')
			.slice(0, 1)
			.join(' ');
		collaborator.last_name = collaborator.name.split(' ').slice(1).join(' ');

		collaborator.mother_name = collaboratorDataArray[2].toString();

		collaborator.email = collaboratorDataArray[3].toString();
		// CSVs AREN'T PARSING TO DATE FORMAT, BUT AS STRINGS
		if (
			typeof collaboratorDataArray[4] === 'string' &&
			moment(collaboratorDataArray[4], 'DD/MM/YYYY', true).isValid()
		) {
			// check if it's a valid string date
			collaborator.birth_date = collaboratorDataArray[4];
		} else if (collaboratorDataArray[4] instanceof Date) {
			collaborator.birth_date = collaboratorDataArray[4].toLocaleDateString();
		} else {
			setCollabInfoErrors((oldErrors) => [
				...oldErrors,
				{
					error: 'Formato de data inválido.',
					line: collabRow,
					collaborator: collaborator,
				},
			]);
			throw new Error('Invalid date format');
		}

		collaborator.office = collaboratorDataArray[5].toString();
		collaborator.section = collaboratorDataArray[6].toString();
		try {
			collaborator.contract_type = parseContractTypeToEnglish(
				collaboratorDataArray[7]
			);
		} catch (err) {
			setCollabInfoErrors((oldErrors) => [
				...oldErrors,
				{
					error: `Valor inválido para contrato (Valores válidos são: Estágio, CLT, Sócio, ou PJ)`,
					line: collabRow,
					collaborator: collaborator,
				},
			]);
			throw err;
		}

		collaborator.cost_center = collaboratorDataArray[8].toString();
		collaborator.branch_office = collaboratorDataArray[9].toString();
		collaborator.cep = collaboratorDataArray[10]
			.toString()
			.replace(only_numbers_regex, '');
		collaborator.address = collaboratorDataArray[11].toString();
		collaborator.district = collaboratorDataArray[12].toString();
		collaborator.number = collaboratorDataArray[13].toString();
		collaborator.complement = collaboratorDataArray[14].toString();
		collaborator.reference = collaboratorDataArray[15].toString();
		collaborator.city = collaboratorDataArray[16].toString();

		try {
			collaborator.uf = checkUF(collaboratorDataArray[17]);
		} catch (err) {
			setCollabInfoErrors((oldErrors) => [
				...oldErrors,
				{
					error: `Valor inválido de UF.`,
					line: collabRow,
					collaborator: collaborator,
				},
			]);
			throw err;
		}

		collaborator.first_phone = collaboratorDataArray[18].toString();
		collaborator.second_phone = collaboratorDataArray[19].toString();

		return collaborator;
	}

	function showLineErrorsTable() {
		if (errors.length) {
			return (
				<Modal
					isOpen={true}
					enableClose
					onRequestClose={() => {
						setErrors([]);
					}}
				>
					<LineErrorsCollaboratorsImported
						errors={errors}
						includeEmail={false}
					/>
				</Modal>
			);
		}
	}

	return (
		<>
			<ImportCollaborators handleFileUpload={onFileUpload} />
			{<ModalLoader loading={!!progressText} progressText={progressText} />}

			{showLineErrorsTable()}
		</>
	);
}
