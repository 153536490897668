import styled from 'styled-components';

export const HeaderContainer = styled.div`
	display: flex;
	gap: 1rem;
	padding-top: 15px;
	align-items: flex-start;
	flex-direction: column;
`;

export const HeaderSubContainer = styled.div`
	display: flex;
	width: 100%;
	gap: 1rem;

	@media (max-width: 768px) {
		align-items: center;
		justify-content: center;
		flex-direction: column;
	}
`;

export const Section = styled.div`
	display: flex;
	flex-direction: column;
	gap: 2rem;
`;

export const DateFilterContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 4rem;
	margin: 0 auto;

	@media (max-width: 768px) {
		flex-wrap: wrap;
		justify-content: center;
		gap: 3rem;

		& > input {
			order: 0;
			padding: 0 10rem;
		}

		.previous-date {
			order: 1;
		}

		.next-date {
			order: 2;
		}
	}

	& input[type='month']::-webkit-datetime-edit-month-field {
		text-transform: capitalize;
	}
`;

export const SelectedCardSection = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 1.6rem;

	.responsavel-status-container {
		display: flex;
		justify-content: space-between;
		gap: 5rem;
	}

	@media (max-width: 1080px) {
		flex-direction: column;
		gap: 1.5rem;
		justify-content: flex-start;
		align-items: flex-start;
		width: 100%;

		& > div {
			width: 100%;
			text-align: left;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			gap: 0.5rem;
		}
		.responsavel-status-container {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			width: 100%;
		}
	}
	@media (max-width: 768px){
			.responsavel-status-container {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: flex-start;
				width: 100%;
				gap: 1.5rem;
			}
	
	}
`;

export const GraySection = styled.div`
	display: flex;
	flex-direction: column;
	padding: 2.7rem 4rem;
	gap: 2rem;
	background-color: #f0f3f8;
	border-radius: 1.6rem;

	@media (max-width: 768px) {
		padding: 1rem;
	}
`;

export const Balance = styled.span`
	color: var(--primary-blue);
	font-size: 2.4rem;
	gap: 2rem;
	width: 100%;
	font-weight: 800;
	text-decoration: underline;
	display: flex;
	align-items: center;
	justify-content: space-between;

	@media (max-width: 768px) {
		gap: 0.5rem;
		width: 50%;
	}
`;

export const StyledParagraph = styled.p`
	font-size: 14px;
	text-align: left;
	gap: 2rem;
	padding-bottom: 4rem;
`;
