import { NotificationComponentProps } from '.';
import { NotificationPreferenceType } from '../../../../@types/CorporateExpenses/Preferences';
import { SelectField } from '../../../../componentsV2/ui/Form/SelectField';
import {
	TitleTypography,
	Typography,
} from '../../../../componentsV2/ui/Typography';
import * as S from './styles';
import { notificationPreferenteTypeParsed } from './utils/parseNotificationPreferenteType';

export function User({
	register,
	onChangePref,
	isLoading,
}: NotificationComponentProps) {
	return (
		<S.Container>
			<S.SectionDescriptionContainer>
				<Typography
					className='description'
					style={{
						justifyContent: 'center',
						textAlign: 'center',
						maxWidth: '50rem',
					}}
				>
					Aqui, você pode escolher ativar ou desativar notificações relacionadas
					à criação e a exclusão de usuários.
				</Typography>
			</S.SectionDescriptionContainer>

			<TitleTypography
				className='headerText'
				size='1.8rem'
				primaryText='Notificações'
				color='var(--primary-blue)'
				color_primary='var(--dark-gray)'
			>
				de usuário
			</TitleTypography>

			<S.SectionContainer>
				<div>
					<TitleTypography size='1.6rem' weight='600' color='var(--dark-gray)'>
						Criação de usuário
					</TitleTypography>
					<p>
						Você receberá uma notificação sempre que você criar um novo usuário.
					</p>
				</div>

				<S.OptionContainer>
					<SelectField
						label='Notificação:'
						name='user_created'
						id='user_created'
						onChange={(e) =>
							onChangePref({
								user_created: e.target.value as NotificationPreferenceType,
							})
						}
						register={register}
						disabled={isLoading}
					>
						<option value={'email'}>
							{notificationPreferenteTypeParsed['email']}
						</option>
						<option value={'notification'}>
							{notificationPreferenteTypeParsed['notification']}
						</option>
						<option value={'both'}>
							{notificationPreferenteTypeParsed['both']}
						</option>
					</SelectField>
				</S.OptionContainer>
			</S.SectionContainer>
			<S.SectionContainer>
				<div>
					<TitleTypography size='1.6rem' weight='600' color='var(--dark-gray)'>
						Exclusão de usuário
					</TitleTypography>
					<p>
						Você receberá uma notificação sempre que um usuário for excluído.
					</p>
				</div>

				<S.OptionContainer>
					<SelectField
						label='Notificação:'
						name='user_deleted'
						id='user_deleted'
						register={register}
						onChange={(e) =>
							onChangePref({
								user_deleted: e.target.value as NotificationPreferenceType,
							})
						}
						disabled={isLoading}
					>
						<option value={'email'}>
							{notificationPreferenteTypeParsed['email']}
						</option>
						<option value={'notification'}>
							{notificationPreferenteTypeParsed['notification']}
						</option>
						<option value={'both'}>
							{notificationPreferenteTypeParsed['both']}
						</option>
					</SelectField>
				</S.OptionContainer>
			</S.SectionContainer>
		</S.Container>
	);
}
