import { FormEvent, useState } from 'react';
import { FaFilter } from 'react-icons/fa';
import { RiCloseLine } from 'react-icons/ri';
import { useQuery } from 'react-query';
import { ArrowContainer, Popover } from 'react-tiny-popover';
import { useAuth } from '../../hooks/useAuth';
import { getCostCentersSelect } from '../../services/queries/CostCenters';
import { UF_List } from '../../utils/CheckUF';
import { cpfMask, parseMaskedCPFToRaw } from '../../utils/masks';
import * as FormStyles from '../Form/FormStyles';
import * as S from './styles';
import { parseCurrencyStrToNumber } from '../../utils/parseCurrency';
import { convertReaisToCents } from '../../utils/CurrencyConvert';
import { showErrorMessage } from '../../utils/ErrorHandler';
import { parseInputToDateMMDD } from '../../utils/parseDate';

export interface SelectOption {
	value: string;
	text: string;
}

export type FilterParams = {
	filter: string;
	name: string;
	value: string;
	second_value?: string;
	type:
		| 'text'
		| 'cpf'
		| 'currency'
		| 'email'
		| 'uf'
		| 'cost_center'
		| 'date_interval'
		| 'select'
		| 'toggle';
	selectOptions?: SelectOption[]; // used when type === 'select'
	selected: boolean;
	alwaysFilled?: boolean;
};

export interface FilterProps {
	filterParams: FilterParams[];
	onFiltersChanged: (updatedFilters: FilterParams[]) => void;
}

export function parseFilterParamsToParams(filterParams: FilterParams[]) {
	const params: any = {};
	filterParams
		.filter((f) => !!f.value)
		.forEach((filter) => {
			if (filter.type === 'currency') {
				const value = parseCurrencyStrToNumber(filter.value);
				params[filter.filter] = convertReaisToCents(value);
			} else if (filter.type === 'date_interval') {
				params['start_date'] = parseInputToDateMMDD(filter.value);
				params['end_date'] = parseInputToDateMMDD(filter.second_value!);
			} else {
				params[filter.filter] = filter.value;
			}
		});
	return params;
}

export function Filter({ filterParams, onFiltersChanged }: FilterProps) {
	const { currentCompany } = useAuth();
	const [isOpen, setIsOpen] = useState(false);
	const [filters, setFilters] = useState(filterParams);

	const fetchCostCentersQuery = useQuery(
		['costCenterSelect', currentCompany?.id],
		() => {
			return getCostCentersSelect(currentCompany?.id);
		},
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um erro ao buscar os centros de custo. '
				);
			},
			enabled: !!filterParams.find((f) => f.type === 'cost_center'),
			refetchOnWindowFocus: false,
		}
	);

	function toggleFilterSelection(filter: string) {
		setFilters(
			filters.map((f) => {
				if (f.alwaysFilled) return f;

				if (f.filter === filter) {
					if (f.alwaysFilled) return f;

					if (f.type === 'date_interval') {
						return { ...f, selected: !f.selected, value: '', second_value: '' };
					}
					// } else if (f.type === 'toggle') {
					// 	return {
					// 		...f,
					// 		selected: !f.selected,
					// 		value: f.selected ? 'false' : 'true',
					// 	};
					// }
					return { ...f, selected: !f.selected, value: '' };
				}
				return f;
			})
		);
	}

	function handleFilterChangeValue(
		filter: string,
		value: string,
		start_date?: boolean
	) {
		setFilters(
			filters.map((f) => {
				if (f.filter === filter) {
					if (f.type === 'date_interval') {
						if (start_date) return { ...f, value };
						return { ...f, second_value: value };
					} else if (f.type === 'toggle') {
						return { ...f, value: value === 'false' ? '' : value };
					}
					return { ...f, value };
				}
				return f;
			})
		);
	}

	function renderProperInput(filter: FilterParams) {
		if (filter.type === 'select') {
			return (
				<FormStyles.SelectInput
					value={filter.value}
					onChange={(e) => {
						handleFilterChangeValue(filter.filter, e.target.value);
					}}
				>
					<option value='' disabled>
						Selecione um tipo
					</option>
					{filter.selectOptions?.map((option) => (
						<option key={option.value} value={option.value}>
							{option.text}
						</option>
					))}
				</FormStyles.SelectInput>
			);
		}

		if (filter.type === 'currency')
			return (
				<FormStyles.FormCurrencyInput
					value={filter.value}
					onValueChange={({ value }) =>
						handleFilterChangeValue(filter.filter, value)
					}
				/>
			);

		if (filter.type === 'cpf')
			return (
				<FormStyles.SearchInput
					value={cpfMask(filter.value)}
					onChange={(e) => {
						const { value } = e.target;
						handleFilterChangeValue(filter.filter, parseMaskedCPFToRaw(value));
					}}
					placeholder={`${filter.name.toUpperCase()}`}
				/>
			);

		if (filter.type === 'cost_center')
			return (
				<FormStyles.SelectInput
					value={filter.value}
					onChange={(e) => {
						handleFilterChangeValue(filter.filter, e.target.value);
					}}
				>
					<option value='' disabled>
						Selecione um centro de custo
					</option>
					{fetchCostCentersQuery.data?.map((costCenter) => (
						<option key={costCenter.id} value={costCenter.title}>
							{costCenter.title}
						</option>
					))}
				</FormStyles.SelectInput>
			);

		if (filter.type === 'uf')
			return (
				<FormStyles.SelectInput
					defaultValue={filter.value || ''}
					onChange={(e) => {
						handleFilterChangeValue(filter.filter, e.target.value);
					}}
				>
					<option disabled value=''>
						Selecione uma opção
					</option>
					{UF_List.map((uf) => (
						<option key={uf} value={uf}>
							{uf}
						</option>
					))}
				</FormStyles.SelectInput>
			);

		if (filter.type === 'date_interval')
			return (
				<div style={{ marginTop: '5px' }}>
					<S.InputLabel>Data inicial</S.InputLabel>
					<FormStyles.Input
						type={'date'}
						value={filter.value}
						onChange={(e) => {
							handleFilterChangeValue(filter.filter, e.target.value, true);
						}}
						data-testid='start_date_input'
						required
					/>
					<div style={{ marginTop: '3px' }}></div>
					<S.InputLabel>Data final</S.InputLabel>
					<FormStyles.Input
						type={'date'}
						value={filter.second_value}
						onChange={(e) => {
							handleFilterChangeValue(filter.filter, e.target.value, false);
						}}
						data-testid='end_date_input'
						required
					/>
				</div>
			);

		if (filter.type === 'toggle') {
			return (
				<S.CheckBoxWrapper>
					<S.CheckBox
						id={`toggle-${filter.filter}`}
						type='checkbox'
						checked={filter.value === 'true' ? true : false}
						onChange={(e) => {
							handleFilterChangeValue(
								filter.filter,
								e.target.checked.toString()
							);
						}}
					/>
					<S.CheckBoxLabel htmlFor={`toggle-${filter.filter}`} />
				</S.CheckBoxWrapper>
			);
		}

		return (
			<FormStyles.SearchInput
				value={filter.value}
				onChange={(e) => {
					handleFilterChangeValue(filter.filter, e.target.value);
				}}
				placeholder={filter.name}
				// those params change based on filter.type as well
				type={filter.type === 'email' ? 'email' : 'text'}
			/>
		);
	}

	function handleClearFilters() {
		const clearedFilters = filters.map((f) => {
			if (f.alwaysFilled) return f;

			return { ...f, selected: false, value: '' };
		});
		setFilters(clearedFilters);
		onFiltersChanged(clearedFilters);
	}

	function handleSubmitFilter(e: FormEvent) {
		e.preventDefault();
		e.stopPropagation();
		onFiltersChanged(filters);
	}

	return (
		<Popover
			isOpen={isOpen}
			onClickOutside={() => setIsOpen(false)}
			positions={['bottom']} // preferred positions by priority
			content={({ position, childRect, popoverRect }) => (
				<ArrowContainer
					position={position}
					childRect={childRect}
					popoverRect={popoverRect}
					arrowColor={'var(--light-gray)'}
					arrowSize={6}
					arrowStyle={{ opacity: 0.7 }}
				>
					<S.Container data-testid='Filter-container-popover'>
						<S.Section>
							<S.SectionTitle>Adicionar filtros</S.SectionTitle>
							<S.FiltersContainer>
								{filters.map((filter) => (
									<S.SelectFilter
										onClick={() => toggleFilterSelection(filter.filter)}
										selected={filter.selected}
										key={filter.filter}
									>
										{filter.name}
									</S.SelectFilter>
								))}
							</S.FiltersContainer>
						</S.Section>

						<form onSubmit={(e) => handleSubmitFilter(e)}>
							<S.Section>
								<S.SectionTitle>Filtros</S.SectionTitle>

								<S.FiltersInputContainer>
									{filters
										.filter((f) => f.selected)
										.map((filter) => (
											<div key={filter.filter}>
												<S.InputLabel>{filter.name}</S.InputLabel>
												{renderProperInput(filter)}
											</div>
										))}
								</S.FiltersInputContainer>
							</S.Section>

							<S.FilterButton type='submit'>FILTRAR</S.FilterButton>
						</form>
						{/* <S.Section>
            <S.SectionTitle>Status</S.SectionTitle>
          </S.Section> */}
					</S.Container>
				</ArrowContainer>
			)}
		>
			<S.OpenFilterPopoverContainer>
				<S.OpenFilterButton onClick={() => setIsOpen(!isOpen)}>
					<FaFilter />
					<span>Filtros</span>
				</S.OpenFilterButton>
				{!!filterParams.filter((f) => !!f.value).length && (
					<S.FilterIndicator
						onClick={handleClearFilters}
						data-rh='Limpar filtros'
						data-testid='clear-filters-btn'
					>
						<span>{filterParams.filter((f) => !!f.value).length}</span>
						<RiCloseLine />
					</S.FilterIndicator>
				)}
			</S.OpenFilterPopoverContainer>
		</Popover>
	);
}
