const errorMessages = [
    //Errors about user creation
    'Email or password incorrect!',
    'User already exists!',
    'CPF invalid!',
    'User does not exists!',
    'Validation failed',
    'Email does not exists!',
    'The RG field or CRNM field must be informed!',
    'RG and CRNM cannot be registered together, register only one!',
    'Invalid CPF!',
    'Data from one or more contributors is inconsistent or duplicate, check the data!',
    'Collaborator already exists!',

    //Users
    'Users not found!',
    'User not found',
    'User email already exists!',
    'The user is not allowed to perform this operation!',
    'Terms for the user have already been approved!',
    'Collaborator not found!',
    'Old password does not match.',
    'You need to inform the old password to set a new password.',
    'E-mail already in use',

    //Errors about release
    'User does not exist!',
    'Inactive user!',
    'Inactive collaborator!',
    'O lançamento não existe!',
    'Não é possível fazer esse lançamento!',
    'Pre Release does not exists!',
    'Release does not exists!',
    'preReleases is required!',
    'Error, release does not end...',
    'The data of one or more collaborators is repeated!',
    'Releases not found!',

    'Operator does not exists!',

    //Errors about groups
    'Group name already exists!',
    'Group does not exists!',
    'All or some users does not exist!',
    'Group not found',
    'Name already in use',
    'Groups not found!',
]

const translatedErrorMessages = [
    'Email ou senha incorretos.',
    'Usuário já existe.',
    'CPF inválido.',
    'Usuário não existe.',
    'Validação falhou.',
    'Email não existe!',
    'O campo de RG ou CRNM deve ser informado!',
    'RG e CRNM não podem ser registrados juntos, informa apenas um!',
    'CPF inválido!',
    'Informações de um ou mais colaboradores estão inconsistentes ou duplicadas, cheque as informações!',
    'Colaborador já existe!',
    
    'Usuários não encontrados!',
    'Usuário não encontrado.',
    'Email do usuário já existe!',
    'O usuário não tem permissão para concluir esta operação!',
    'Os termos para o usuário já foram aprovados!',
    'Colaborador não encontrado!',
    'Senha antiga não é igual.',
    'Você precisa informar a senha antiga para criar uma nova.',
    'Email já está em uso.',

    'O usuário não existe!',
    'O usuário está desativado',
    'Colaborador inativo',
    'O lançamento não existe.',
    'Não é possível fazer esse lançamento!',
    'Pré lançamento não existe.',
    'O lançamento não existe.',
    'Pré lançamento é necessário.',
    'Erro, o lançamento não tem fim...',
    'As informações de um ou mais colaboradores estão repetidas.',
    'Lançamentos não achados.',

    'Operador não existe.',

    'Nome do grupo já existe!',
    'Grupo não existe!',
    'Todos ou alguns usuários não existem!',
    'Grupo não achado.',
    'Nome já em uso.',
    'Grupos não achados',
]

const dynamicErrorMessages = [
    'is duplicated at launch! check groups and listing.',
    'The collaborator of the hh group is inactive.'
]

const dynamicTranslatedErrorMessages = [
    'O colaborador está duplicado no lançamento. Cheque os grupos e a listagem individual.',
    'O colaborador do grupo está inativo.'
]

export function getTranslatedErrorMessage(message: string){
    const dynamicMessageIndex = dynamicErrorMessages.findIndex((dynamicMessage) => message.includes(dynamicMessage))

    if(dynamicMessageIndex !== -1){
        switch(dynamicMessageIndex){
            case 0:
                const collabName = message.replace('The collaborator', '').replace(dynamicErrorMessages[0], '')
                const newMessage = dynamicTranslatedErrorMessages[dynamicMessageIndex].slice(0, 13) + collabName + dynamicTranslatedErrorMessages[dynamicMessageIndex].slice(14, 85)
                return newMessage
            case 1:
                const groupName = message.replace('The collaborator of the', '').replace(' group is inactive.', '')
                const newMessageGroup = dynamicTranslatedErrorMessages[dynamicMessageIndex].slice(0, 22) + groupName + dynamicTranslatedErrorMessages[dynamicMessageIndex].slice(22, 36)
                return newMessageGroup
        }
    }

    const messageIndex = errorMessages.findIndex((elem) => elem === message)
    if(messageIndex === -1){
        return message
    }else{
        return translatedErrorMessages[messageIndex]
    }
}