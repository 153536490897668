import styled from 'styled-components';

export const Container = styled.div`
	background-color: var(--primary-red);
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
`;

export const LogoImage = styled.img`
	width: 24rem;
	margin-bottom: 3rem;
`;

export const StyledPhrase = styled.div`
	background-color: gray;
	padding: 1rem;
	border-radius: 4px;
	font-size: 2rem;
	color: white;
	margin-bottom: 1rem;
`;
