import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import PageTitle from '../../../components/PageTitle';
import PaymentTable from './components/PaymentTable/PaymentTableEdit';
import { useAuth } from '../../../hooks/useAuth';
import { usePayment } from '../../../hooks/usePaymentV2';
import { fetchPayment } from '../../../services/queries/PaymentsV2';
import { AddToTableOptions } from './components/AddToTableOptions';
import * as S from './styles';
import { parsePayment } from '../../../utils/parsePayment';
import Loader from '../../../components/Loader';
import { SubmitPayment } from './components/SubmitPaymentCreation';
import { Benefit, Collaborator, LineError } from '../../../@types';
import { Beneficiary } from '../../../contexts/PaymentContextV2';
import { LineErrorsCollaboratorsImported } from '../../../components/LineErrorsPaymentTable';
import Modal from '../../../components/Modal';
import { getBenefitsInputs } from './utils/getBenefitValuesInputs';
import {
	addBenefitInputEventListener,
	removeBenefitInputEventListener,
} from './utils/setBenefitInputEventListener';
import { calculatePaymentsAmount } from './utils/calculatePaymentsAmout';
import { MAX_PAYMENTS } from './utils/MAX_PAYMENTS';
import { PaymentsAmount } from './components/PaymentsAmount';
import { showErrorMessage } from '../../../utils/ErrorHandler';
import { getBenefits } from '../../../services/queries/Benefits';
import { useDialogModal } from '../../../hooks/useDialogModal';

type RelaunchPaymentParams = {
	id?: string;
};

// optional state passed
interface PageState {
	collaborators?: Collaborator[];
	errors?: LineError[];
}

// Same as Create payment but is populated with another payment (from API or from imported file) as base
export function RelaunchPayment() {
	const { id } = useParams<RelaunchPaymentParams>();
	const location = useLocation<PageState>();
	const history = useHistory();
	const collaborators = location.state?.collaborators;

	const [errors, setErrors] = useState<LineError[] | undefined>(
		location.state?.errors
	);
	const [paymentsAmount, setPaymentsAmount] = useState(0);
	const [checkedBenefitsFinished, setCheckedBenefitsFinished] = useState(false);

	const { currentCompany } = useAuth();
	const { openConfirmDialog } = useDialogModal();
	const { beneficiaries, setBeneficiaries, setBenefits, setSelectedBenefits } =
		usePayment();

	const pageTitle =
		collaborators === undefined
			? 'Relançar este pagamento'
			: 'Detalhes do Pagamento';

	// Collaborators COULD be passed from ImportPayment after reading an excel file (importing functionality)
	// or from Finished with errors payments, if the user wants to release all repproved collaborators
	useEffect(() => {
		if (!collaborators) return;

		let benefits: Benefit[] = [];
		collaborators.forEach((collab) =>
			collab.benefits.forEach((benefit) => {
				if (!benefits.find((addedBenefit) => addedBenefit.id === benefit.id)) {
					benefits.push(benefit);
				}
			})
		);

		setBeneficiaries([
			...collaborators.map((collab) => {
				let collabBenefits = benefits.map((benefit) => {
					const foundBenefit = collab.benefits.find((b) => b.id === benefit.id);

					if (foundBenefit) {
						return foundBenefit;
					}
					// if benefit not present in this collaborator, set it with 0
					return { ...benefit, value: 0 };
				});

				return {
					...collab,
					isGroup: false,
					benefits: collabBenefits,
					selected: true,
				} as Beneficiary;
			}),
		]);

		setBenefits(benefits);
		setSelectedBenefits(benefits);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [collaborators]);

	useEffect(() => {
		if (beneficiaries.length === 0) return;

		const inputs = getBenefitsInputs();

		inputs.forEach((input) => {
			addBenefitInputEventListener(input, setCalculatedPaymentsAmount);
		});

		return () => {
			inputs.forEach((input) => {
				removeBenefitInputEventListener(input, setCalculatedPaymentsAmount);
			});
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [beneficiaries]);

	useEffect(() => {
		setCalculatedPaymentsAmount();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [beneficiaries]);

	const setCalculatedPaymentsAmount = () => {
		setPaymentsAmount(calculatePaymentsAmount(beneficiaries));
	};

	const fetchPaymentQuery = useQuery(
		['fetchPayment', currentCompany?.id, id],
		() => {
			return fetchPayment(id!, currentCompany!.id);
		},
		{
			onSuccess: async (data) => {
				const { beneficiariesParsed, benefitsParsed } = parsePayment(data);

				// check benefits availability
				const benefitsAvailable = await checkBenefitsAvailable(
					benefitsParsed.map((b) => b.id)
				);
				if (!benefitsAvailable) return;

				setBeneficiaries([
					...beneficiariesParsed.map((beneficiary) => ({
						...beneficiary,
						selected: true,
					})),
				]);
				setBenefits(benefitsParsed);
				setSelectedBenefits(benefitsParsed);
			},
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao buscar o lançamento. '
				);
			},
			refetchOnWindowFocus: false,
			refetchOnReconnect: false,
			enabled: !collaborators, // only fetch from API when no collaborators are passed as base
		}
	);

	const getBenefitsQuery = useQuery(
		['benefits', currentCompany?.id],
		() => getBenefits(currentCompany?.id),
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível verificar os benefícios do pagamento. '
				);
			},
			enabled: false, // called when fetchPaymentQuery finishes
			refetchOnWindowFocus: false,
			refetchOnReconnect: false,
		}
	);

	// check if the benefits used are available to the company
	async function checkBenefitsAvailable(paymentBenefitsIds: string[]) {
		const result = await getBenefitsQuery.refetch();
		if (result.isError) {
			history.push('/home/payments');
			return false;
		}

		let allBenefitsAvailable = true;

		paymentBenefitsIds.forEach((benefitId) => {
			if (!result.data!.find((benefit) => benefit.id === benefitId)) {
				allBenefitsAvailable = false;
				return;
			}
		});

		if (!allBenefitsAvailable) {
			openConfirmDialog(
				'Não é possível utilizar esse pagamento como base! Os benefícios liberados para a empresa foram alterados e o pagamento escolhido utiliza benefícios que não estão mais disponíveis.',
				'Entendi',
				() => {
					history.push('/home/payments');
				}
			);
		}

		setCheckedBenefitsFinished(true);
		return allBenefitsAvailable;
	}

	if (
		(!fetchPaymentQuery.data ||
			fetchPaymentQuery.isFetching ||
			!checkedBenefitsFinished) &&
		!collaborators
	) {
		return (
			<S.Container>
				<PageTitle title={pageTitle} />
				<Loader />
			</S.Container>
		);
	}
	return (
		<S.Container>
			<PageTitle title={pageTitle} />

			{/* TABLE COMPONENT THAT RENDERS COLLABORATORS AND THEIR BENEFITS */}
			<PaymentTable />
			<PaymentsAmount paymentsAmount={paymentsAmount} />

			<S.FooterContainer>
				{/* INCLUDE COLLABORATORS, GROUPS and BENEFITS OPTIONS*/}
				<AddToTableOptions paymentsAmount={paymentsAmount} />

				{/* Submit component reponsible for all pre-release verifications steps + submitting to API */}
				<SubmitPayment disabled={paymentsAmount > MAX_PAYMENTS} />
			</S.FooterContainer>

			{/* MODAL with errors that could be passed through the state (eg.: importing errors) */}
			<Modal
				isOpen={!!errors && errors.length > 0}
				enableClose
				onRequestClose={() => {
					setErrors(undefined);
				}}
			>
				<LineErrorsCollaboratorsImported
					errors={errors ?? []}
					includeEmail={true}
				/>
			</Modal>
		</S.Container>
	);
}
