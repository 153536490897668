import { useState } from 'react';
import { Operator } from '../../../../@types';
import * as S from './styles';
import PageTitle from '../../../../components/PageTitle';
import Loader from '../../../../components/Loader';
import InfoCard from '../../../../components/InfoCard';
import UserCard from '../../../../components/UserCard';
import { CgUserList } from 'react-icons/cg';
import * as TableStyle from '../../../../components/Table/TableStyles';
import { cpfMask } from '../../../../utils/masks';
import { Filter } from '../../../../components/Filter';
import { Pagination } from '../../../../components/Pagination';
import { useQuery, useQueryClient } from 'react-query';
import {
	getFilteredOperators,
	getOperators,
	OperatorsListReturn,
} from '../../../../services/queries/Operators';
import { showErrorMessage } from '../../../../utils/ErrorHandler';
import { useHistoryNonMatchCallback } from '../../../../hooks/useHistoryNonMatchCallback';
import { useOperatorsListStore } from '../../../../stores/useOperatorsListStore';
import { useSortColumnHook } from '../../../../hooks/useSortColumnHook';
import { SortColumnButton } from '../../../../components/SortColumnButton';

export default function Operators() {
	const queryClient = useQueryClient();

	const [operatorsCurrentPage, setOperatorsCurrentPage] = useState(1);
	const { currentSortColumn, toggleSort } = useSortColumnHook();
	const [filtersParams, setFiltersParams, resetFilters] = useOperatorsListStore(
		(state) => [state.filtersParams, state.setFiltersParams, state.resetFilters]
	);

	useHistoryNonMatchCallback('operators', resetFilters);

	const { data, isLoading, isPreviousData } = useQuery<
		OperatorsListReturn,
		Error
	>(
		['operatorsList', operatorsCurrentPage, currentSortColumn],
		() => {
			return getOperators(operatorsCurrentPage, currentSortColumn);
		},
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível buscar os operadores. '
				);
			},
			keepPreviousData: true,
			refetchOnWindowFocus: false,
		}
	);

	const filteredOperators = useQuery<OperatorsListReturn, Error>(
		[
			'filteredOperators',
			filtersParams,
			operatorsCurrentPage,
			currentSortColumn,
		],
		() => {
			return getFilteredOperators(
				filtersParams,
				operatorsCurrentPage,
				currentSortColumn
			);
		},
		{
			onError: (error) => {
				showErrorMessage(
					error as Error,
					'Não foi possível buscar os operadores. '
				);
			},
			enabled: !!filtersParams.find((f) => !!f.value) && !!operatorsCurrentPage,
			refetchOnWindowFocus: false,
		}
	);

	function getTableTotalRecords() {
		if (filtersParams.find((f) => !!f.value)) {
			return filteredOperators.data?.totalUsersOperator;
		}
		return data?.totalUsersOperator;
	}

	function refreshPage() {
		setOperatorsCurrentPage(1);
		queryClient.resetQueries('operatorsList');
		queryClient.resetQueries('filteredOperators');
	}

	const operatorsPageContent = () => {
		return filtersParams.some((f) => !!f.value)
			? filteredOperators.data?.users!
			: data?.users;
	};

	function generateRow(operator: Operator) {
		return (
			<TableStyle.TableRow key={operator.id}>
				<TableStyle.TableData>
					<UserCard
						name={operator.name}
						bottomInfo={operator.office}
						avatar_url={operator.avatar_url}
					/>
				</TableStyle.TableData>
				<TableStyle.TableData>{cpfMask(operator.cpf)}</TableStyle.TableData>
				<TableStyle.TableData>{operator.office}</TableStyle.TableData>
				<TableStyle.TableData>
					{operator.access_level === 'admin' ? 'Admin' : 'Operador'}
				</TableStyle.TableData>
				<TableStyle.TableData style={{ textAlign: 'center' }}>
					<TableStyle.OptionLink
						data-rh='Detalhes do operador'
						to={`/super-admin/operators/manage/${operator.id}`}
					>
						<CgUserList />
					</TableStyle.OptionLink>
				</TableStyle.TableData>
			</TableStyle.TableRow>
		);
	}

	function getTable(operators?: Operator[]) {
		if (!operators?.length) {
			return (
				<>
					<TableStyle.TableHeaderContainer>
						<TableStyle.TitleWrapper>
							<PageTitle title='Operadores do sistema' />
							<Filter
								filterParams={filtersParams}
								onFiltersChanged={(updatedFilters) => {
									setFiltersParams(updatedFilters);
								}}
							/>
							<TableStyle.ReloadIcon type='reload' onClick={refreshPage} />
						</TableStyle.TitleWrapper>
					</TableStyle.TableHeaderContainer>

					<InfoCard message={`Não há operadores a serem exibidos.`} />
				</>
			);
		}

		return (
			<>
				<TableStyle.TableHeaderContainer>
					<TableStyle.TitleWrapper>
						<PageTitle
							title='Operadores do sistema'
							totalRecords={getTableTotalRecords() ?? 0}
						/>
						<Filter
							filterParams={filtersParams}
							onFiltersChanged={(updatedFilters) => {
								setFiltersParams(updatedFilters);
							}}
						/>
					</TableStyle.TitleWrapper>

					<Pagination
						onPageChange={(page) => setOperatorsCurrentPage(page)}
						currentPage={operatorsCurrentPage}
						totalCount={
							filtersParams.some((f) => !!f.value)
								? filteredOperators.data?.totalUsersOperator!
								: data!.totalUsersOperator
						}
					/>

					<TableStyle.ReloadIcon type='reload' onClick={refreshPage} />
				</TableStyle.TableHeaderContainer>

				<TableStyle.Table>
					<TableStyle.TableHeader>
						<TableStyle.TableRow>
							<TableStyle.TableHeaderCell>
								<SortColumnButton
									order={
										currentSortColumn?.name === 'name'
											? currentSortColumn.order
											: null
									}
									onToggleSort={() => toggleSort('name')}
								/>
								NOME
							</TableStyle.TableHeaderCell>
							<TableStyle.TableHeaderCell>CPF</TableStyle.TableHeaderCell>
							<TableStyle.TableHeaderCell>
								<SortColumnButton
									order={
										currentSortColumn?.name === 'office'
											? currentSortColumn.order
											: null
									}
									onToggleSort={() => toggleSort('office')}
								/>
								CARGO
							</TableStyle.TableHeaderCell>
							<TableStyle.TableHeaderCell>
								NÍVEL DE ACESSO
							</TableStyle.TableHeaderCell>
							<TableStyle.TableHeaderCell>OPÇÕES</TableStyle.TableHeaderCell>
						</TableStyle.TableRow>
					</TableStyle.TableHeader>
					<TableStyle.TableBody>
						{operators.map((operator) => generateRow(operator))}
					</TableStyle.TableBody>
				</TableStyle.Table>
			</>
		);
	}

	if (filteredOperators.isLoading || isLoading || isPreviousData) {
		return (
			<S.Container>
				<PageTitle title={'Operadores do sistema'} />
				<Loader />
			</S.Container>
		);
	}
	return (
		<S.Container>
			{getTable(operatorsPageContent())}
			<TableStyle.Button to={`/super-admin/operators/create`}>
				Criar operador
			</TableStyle.Button>
		</S.Container>
	);
}
