import { InputHTMLAttributes, useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { MdErrorOutline } from 'react-icons/md';
import styled, { css } from 'styled-components';
import lockIcon from '../../../../assets/lock-check-outline.svg';
import { BaseFieldProps, Field } from '../Field';
import { InputComp } from '../InputStyle';

interface PasswordInputProps
	extends InputHTMLAttributes<HTMLInputElement>,
		BaseFieldProps {}

export function PasswordInputLock({
	label,
	register,
	...props
}: PasswordInputProps) {
	const [showPassword, setShowPassword] = useState(false);

	const handleToggleShow = () => {
		setShowPassword((oldState) => !oldState);
	};

	return (
		<Field label={label} name={props.name}>
			<PasswordContainer style={{ width: props.style?.width }}>
				<LockIcon />
				<InputComp
					error={!!props.errorMessage}
					type={showPassword ? 'text' : 'password'}
					style={{
						flex: 1,
						border: 'none',
						paddingLeft: '4rem',
						paddingRight: '4rem',
					}}
					{...(register ? { ...register(props.name!) } : {})}
					{...props}
				/>
				<button
					onClick={handleToggleShow}
					data-testid={showPassword ? 'eye' : 'eye-slash'}
					type='button'
				>
					{showPassword ? <FaEye /> : <FaEyeSlash />}
				</button>
			</PasswordContainer>
			{props.errorMessage && (
				<span>
					<MdErrorOutline /> {props.errorMessage}
				</span>
			)}
		</Field>
	);
}

const SideContainer = css`
	position: absolute;
	width: 2rem;
	height: 2rem;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const PasswordContainer = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	width: 32rem;
	& > button {
		${SideContainer}
		left: calc(100% - 3rem);
		transition: filter 0.2s;
		background: none;
		cursor: pointer;
		& > svg {
			width: 100%;
			height: 100%;
		}
		&:hover {
			filter: brightness(0.9);
		}
	}
`;

const LockIcon = styled.span`
	width: 2rem;
	height: 2rem;
	background-image: url(${lockIcon});
	background-size: cover;
	margin-left: 0.5rem;
	position: absolute;
	right: calc(100% - 3rem);
	position: absolute;
	display: flex;
	align-items: center;
`;
