import styled from "styled-components"
import { Link } from 'react-router-dom'

export const Container = styled.footer`
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: aliceblue;
`

export const Copyright = styled.span`
  margin: 0 auto;
  color: var(--primary-blue);
`

export const TermsLink = styled(Link)`
	text-decoration: none;
  color: var(--primary-blue);
`