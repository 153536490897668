import { useEffect, useRef } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import { EmojiPicker } from './EmojiPicker';
import MagicUrl from 'quill-magic-url';
import 'react-quill/dist/quill.snow.css';

Quill.register('modules/magicUrl', MagicUrl);
const Link = Quill.import('formats/link');
Link.sanitize = (url: string) => {
	// quill by default creates relative links if scheme is missing.
	if (!url.startsWith('http://') && !url.startsWith('https://')) {
		return `https://${url}`;
	}
	return url;
};

const formats = [
	'header',
	'font',
	'size',

	'bold',
	'italic',
	'underline',
	'strike',
	'blockquote',

	'indent',
	'link',
];

interface Props {
	setEditorRef: React.Dispatch<
		React.SetStateAction<ReactQuill.UnprivilegedEditor | null>
	>;
}

const URL_REGEX = new RegExp('@^(https?|ftp)://[^s/$.?#].[^s]*$@iS');

export function TextEditor({ setEditorRef }: Props) {
	const ref = useRef<ReactQuill | null>(null);

	useEffect(() => {
		if (!ref.current) return;

		const unprivilegedEditor = ref.current.makeUnprivilegedEditor(
			ref.current.getEditor()
		);
		setEditorRef(unprivilegedEditor);
	}, []); // eslint-disable-line

	function addEmojiToText(emoji: any) {
		const editor = ref.current?.getEditor();
		if (!editor) return;

		const EMOJI_CHAR_COUNT = 2;
		const cursorPosition = editor.getSelection(true)?.index ?? 0;
		editor.insertText(cursorPosition, emoji.native);
		editor.setSelection(cursorPosition + EMOJI_CHAR_COUNT, 0);
	}

	return (
		<>
			<div id='toolbar'>
				<span className='ql-formats'>
					<select
						className='ql-header'
						onChange={(e) => e.persist()}
						defaultValue=''
						data-rh='Cabeçalho'
					>
						<option value='1'>Título 1</option>
						<option value='2'>Título 2</option>
						<option></option>
					</select>
				</span>

				<span className='ql-formats'>
					<button type='button' className='ql-bold' data-rh='Negrito'></button>
					<button
						type='button'
						className='ql-italic'
						data-rh='Itálico'
					></button>
					<button
						type='button'
						className='ql-underline'
						data-rh='Sublinhado'
					></button>
					<button
						type='button'
						className='ql-link'
						data-rh='Hiperlink'
					></button>
				</span>

				<span className='ql-formats'>
					<button
						type='button'
						className='ql-clean'
						data-rh='Remover formatação'
					></button>
				</span>

				<span className='ql-formats' style={{ float: 'right' }}>
					<EmojiPicker onPickEmoji={addEmojiToText} />
				</span>
			</div>

			<ReactQuill
				theme='snow'
				ref={(el) => {
					ref.current = el;
				}}
				modules={{
					toolbar: {
						container: '#toolbar',
					},
					magicUrl: {
						mailRegularExpression: URL_REGEX,
						globalMailRegularExpression: URL_REGEX,
					},
				}}
				style={{
					height: '16rem',
				}}
				formats={formats}
			/>
		</>
	);
}
