import api from '../../api';
import {
	BankData,
	Beneficiary,
	BeneficiaryResponse,
	Limits,
	Transfer,
} from '../../../@types/CorporateExpenses/Transfer';
import { parseDateToInput } from '../../../utils/masks';

export async function createTransfer( id: string, body: Transfer) {
	return api.post(`/api/v1/companies/${id}/funds-out`, body);
}

export async function resentToken (id: string) {
	await api.post(`/api/v1/companies/${id}/funds-out/send-token`)
}

export async function getBeneficiaries (id: string) {
	const response = await api.get<BeneficiaryResponse[]>(`/api/v1/companies/${id}/beneficiaries/q`);
	return response.data
};

export async function createBeneficiary (id: string, body: Beneficiary) {
	await api.post(`/api/v1/companies/${id}/beneficiaries`, body);
}

export async function updateBeneficiary (id: string, company_id : string, body: Beneficiary) {
	const response = await api.patch<BeneficiaryResponse>(`/api/v1/companies/${company_id}/beneficiaries/${id}`, body)
	return response.data
}

export async function toggleBeneficiaryStatus (id: string, company_id : string, status : boolean ) {
	await api.patch(`/api/v1/companies/${company_id}/beneficiaries/${id}`, {
		favorite: status
	})
}

export async function getLimits (id: string) {
	const response = await api.get<Limits>(`/api/v1/companies/${id}/funds-out/limits`);
	return response.data
}

export async function updateLimit (id: string, company_id: string, amount: number) {
	await api.put(`/api/v1/companies/${company_id}/funds-out/limits/${id}`, { amount })
}

export async function fetchBankList () {
	const response = await api.get<BankData[]>('/api/v1/funds-out/bank-codes');
	return response.data
}

export async function isNationalHoliday ({date} : {date : Date}) {
	const response = await api.get<{isNationalHoliday : boolean}>('/api/v1/funds-out/national-holiday', {
		params: {
			date: parseDateToInput(date.toLocaleDateString())
		}
	});
	return response.data.isNationalHoliday
}

export async function getNextWorkingDay ({date} : {date : Date}) {
	const {data} = await api.get<Date>('/api/v1/funds-out/next-workday', {
		params: {
			date: parseDateToInput(date.toLocaleDateString())
		}
	});
	return  new Date(data)
}


export async function isBeneficiaryNameValid (company_id: string, body: { name: string, document: string}) {
	await api.post(`/api/v1/companies/${company_id}/beneficiaries/validate-name`, body);
}

export async function isBeneficiaryBankAccountValid (company_id: string, beneficiary: Beneficiary) {
	const {type, name, ...body} = beneficiary

	await api.post(`/api/v1/companies/${company_id}/beneficiaries/validate-account`, body);
}

export async function cancelTransfer(id: string, company_id: string) {
	await api.patch(`/api/v1/companies/${company_id}/funds-out/${id}`);
}