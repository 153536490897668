import { InputHTMLAttributes } from 'react';
import { parseDateToInput } from '../../utils/masks';
import * as FormStyles from '../Form/FormStyles';

export function ScheduleInput({
	value,
	onChange,
	...rest
}: InputHTMLAttributes<HTMLInputElement>) {
	function getMinDateTime() {
		const dateObj = new Date();
		const date = parseDateToInput(dateObj.toLocaleDateString('pt-BR'));
		const time = dateObj.toLocaleTimeString('pt-BR').slice(0, -3); // remove secs

		return `${date}T${time}`;
	}

	return (
		<FormStyles.Input
			value={value}
			max='9999-01-01T00:00'
			onChange={onChange}
			type='datetime-local'
			min={getMinDateTime()}
			data-testid='schedule-input'
			{...rest}
		/>
	);
}
