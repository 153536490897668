import { StyleSheet } from '@react-pdf/renderer';

export const controlReportStyle = StyleSheet.create({
	page: {
		flexDirection: 'column',
		alignItems: 'flex-start',
	},
	header: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		columnGap: 10,
		marginBottom: 15,
		paddingHorizontal: 25,
		paddingTop: 25,
	},
	logo: {
		width: '80%',
		objectFit: 'contain',
		marginRight: 40,
	},
	headerInfo: {
		display: 'flex',
		width: '100%',
		flexDirection: 'column',
	},
	headerInfoLabel: {
		color: '#575757',
		fontFamily: 'Poppins',
		fontSize: 9,
	},
	headerInfoData: {
		color: '#575757',
		fontFamily: 'Poppins',
		fontSize: 9,
		fontWeight: 600,
	},

	subHeader: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		paddingHorizontal: 25,
		paddingVertical: 20,
		backgroundColor: '#F0F3F8',
	},
	extractTitleContainer: {
		width: '30%',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		columnGap: 15,
	},
	extractTitle: {
		color: '#393939',
		fontFamily: 'Poppins',
		fontSize: 16,
		fontWeight: 600,
	},
	extractLogo: {
		width: 35,
		objectFit: 'contain',
	},
	subheaderDataContainer: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		width: '70%',
		columnGap: 5,
	},
	subheaderInfo: {
		width: '33%',
		display: 'flex',
		flexDirection: 'row',
		columnGap: 3,
	},
	subheaderLabel: {
		color: '#575757',
		fontFamily: 'Poppins',
		fontSize: 9,
		fontWeight: 600,
	},
	subheaderData: {
		color: '#575757',
		fontFamily: 'Poppins',
		fontSize: 9,
		maxWidth: '70%',
	},
	cardCreditContainer: {
		height: '25px',
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'row',
		columnGap: 3,
		backgroundColor: '#defdea',
		borderRadius: '50px',
		paddingHorizontal: '8px',
		marginBottom: '16px',
		marginTop: '-6px',
	},
	cardCreditText: {
		color: '#037847',
		fontFamily: 'Poppins',
		fontSize: 9,
	},
	cardDebitContainer: {
		height: '25px',
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'row',
		columnGap: 3,
		backgroundColor: '#ffd3d3',
		borderRadius: '50px',
		paddingHorizontal: '8px',
		marginTop: '-5px',
	},
	cardDebitText: {
		color: '#fd2929',
		fontFamily: 'Poppins',
		fontSize: 9,
	},

	tableContainer: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		paddingHorizontal: 25,
	},
	tableHeader: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		alignSelf: 'center',
		columnGap: 10,
		marginTop: 10,
		marginBottom: 8,
	},
	columnTitleContainer: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		columnGap: 5,
	},
	columnTitle: {
		color: '#575757',
		fontFamily: 'Poppins',
		fontSize: 11,
		fontWeight: 500,
	},
	tableRow: {
		width: '100%',
		height: '25px',
		display: 'flex',
		flexDirection: 'row',
		alignSelf: 'center',
		columnGap: 10,
		marginBottom: 8,
	},
	amountContainer: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
	},
	columnData: {
		width: '100%',
		color: '#575757',
		fontFamily: 'Poppins',
		fontSize: 11,
	},
	originalAmount: {
		color: '#575757',
		fontFamily: 'Poppins',
		fontSize: 8,
	},
});
