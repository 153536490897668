import styled from 'styled-components';

export const CheckCollabsBtn = styled.button`
	text-decoration: underline;
	color: var(--primary-blue);
	background: none;
`;

export const Container = styled.div`
	width: max-content;
	width: 100vw;
	max-width: 35vw;
	padding: 1rem;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const CollaboratorsList = styled.ul`
	display: flex;
	width: 100%;
	min-height: 20rem;
	margin-bottom: 2rem;
	flex-direction: column;
	max-height: 75vh;
`;

export const CollaboratorCard = styled.li`
	list-style: none;
	display: flex;
	margin-bottom: 1rem;
	align-items: center;
	font-size: 1.6rem;
`;

export const CollaboratorAvatarContainer = styled.div`
	display: flex;
	align-items: center;
	flex: 1;
`;

export const CollaboratorAvatar = styled.img`
	width: 4rem;
	height: 4rem;
	border-radius: 50%;
	border: 0.15rem solid var(--primary-blue);
	margin-right: 1.5rem;
	object-fit: cover;
`;

export const CollaboratorName = styled.span``;

export const CollaboratorOffice = styled.span`
	color: var(--mid-gray);
	flex-wrap: nowrap;
`;
