import styled from 'styled-components';
import { OptionsButton } from '../Button';

interface BenefitSelectionProps {
	selected: boolean;
}

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	max-width: 60rem;
	width: 70vw;
	padding-right: 1rem;
	min-height: 10rem;
	justify-content: center;
`;

export const ErrorLine = styled.span`
	text-align: center;
	font-size: 1.4rem;
	color: var(--red);
`;

export const OpenModalButton = styled.button`
	${OptionsButton}
`;

export const BenefitsList = styled.ul`
	display: flex;
	flex-direction: column;
	max-height: 60vh;
	overflow-y: auto;
`;

export const BenefitContainer = styled.li`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 1rem;
	padding-bottom: 1rem;
	border-bottom: 0.1rem solid var(--primary-blue);
`;

export const BenefitCard = styled.div`
	display: flex;
	align-items: center;
`;

export const BenefitInfoContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

export const BenefitTitle = styled.div`
	color: var(--primary-blue);
	font-size: 1.6rem;
	display: flex;
	align-items: center;
	column-gap: 1rem;
	& > img,
	svg {
		width: 2rem;
		height: 2rem;
		filter: invert(45%) sepia(71%) saturate(610%) hue-rotate(166deg)
			brightness(114%) contrast(100%);
	}
`;

export const Selector = styled.button<BenefitSelectionProps>`
	width: 3.5rem;
	height: 3.5rem;
	background: none;
	& svg {
		width: 100%;
		height: 100%;
		border-radius: 50%;
		color: ${({ selected }) =>
			selected ? 'var(--light-gray)' : 'var(--primary-blue)'};
	}
`;

export const MainButton = styled.button`
	${OptionsButton}
	margin: 0 auto;
`;
