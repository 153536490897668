import styled from 'styled-components';

export const Container = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	padding: 4rem 7rem 2rem 7rem;
`;

export const TextsContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 3rem;
`;

export const TermsContainer = styled.div`
	text-align: justify;
	padding: 2rem;
	margin-top: 1.5rem;
	margin-bottom: 3.5rem;
	max-height: 45rem;
	width: 100%;
	background-color: #e3e8eb;
	border-radius: 10px;
	overflow-y: auto;
	font-size: 1.4rem;

	& > table,
	td {
		text-align: start;
		padding: 1rem;
		margin-top: 3rem;
		margin-bottom: 3rem;
		border: solid;
		border-collapse: collapse;
	}

	tr {
		p {
			font-weight: normal;
		}
		h3 {
			font-weight: normal;
		}
		th {
			border: 2px solid;
			padding: 1rem;
			text-align: start;
		}
	}

	a {
		color: var(--dark-gray);
		text-decoration: underline;
		transition: all 0.2s;
	}

	a:hover {
		color: var(--light-gray);
	}

	& > ol,
	ul {
		margin-left: 2rem;
		li {
			margin-left: 2rem;
		}
	}

	& > h1,
	h2 {
		font-size: 2rem;
		margin-top: 2rem;
	}

	&::-webkit-scrollbar {
		width: 1.2rem;
	}

	&::-webkit-scrollbar-track {
		border-radius: 1rem;
		background-color: #e3e8eb;
	}

	&::-webkit-scrollbar-thumb {
		background-color: var(--light-gray);
		border-radius: 1rem;
	}
`;

export const TextTitle = styled.h2`
	font-size: 1.7rem;
`;
