import { create } from 'zustand';
import { FilterParams } from '../components/Filter';
import {
	MonthOptionsFilters,
	PaymentStatusOptionsFilters,
} from '../components/Filter/utils/SelectFilters';

type Store = {
	pendingReleasesFiltersParams: FilterParams[];
	setPendingReleasesFiltersParams: (f: FilterParams[]) => void;
	nonPendingReleasesFiltersParams: FilterParams[];
	setNonPendingReleasesFiltersParams: (f: FilterParams[]) => void;
	scheduledReleasesFiltersParams: FilterParams[];
	setScheduledReleasesFiltersParams: (f: FilterParams[]) => void;
	resetFilters: () => void;
};

const initialPendingReleasesFiltersParams: FilterParams[] = [
	{
		name: 'ID',
		filter: 'order_id',
		value: '',
		type: 'text',
		selected: false,
	},
	{
		name: 'Mês',
		filter: 'reference_month',
		value: '',
		type: 'select',
		selectOptions: MonthOptionsFilters,
		selected: false,
	},
	{
		name: 'Intervalo de datas',
		filter: 'date',
		value: '',
		type: 'date_interval',
		selected: false,
	},
	{
		name: 'Nome',
		filter: 'name',
		value: '',
		type: 'text',
		selected: false,
	},
	{
		name: 'Email',
		filter: 'email',
		value: '',
		type: 'email',
		selected: false,
	},
	{
		name: 'CPF',
		filter: 'cpf',
		value: '',
		type: 'cpf',
		selected: false,
	},
];

const initialScheduledReleasesFiltersParams: FilterParams[] = [
	{
		name: 'ID',
		filter: 'order_id',
		value: '',
		type: 'text',
		selected: false,
	},
	{
		name: 'Mês',
		filter: 'reference_month',
		value: '',
		type: 'select',
		selectOptions: MonthOptionsFilters,
		selected: false,
	},
	{
		name: 'Intervalo de datas',
		filter: 'date',
		value: '',
		type: 'date_interval',
		selected: false,
	},
	{
		name: 'Nome',
		filter: 'name',
		value: '',
		type: 'text',
		selected: false,
	},
	{
		name: 'Email',
		filter: 'email',
		value: '',
		type: 'email',
		selected: false,
	},
	{
		name: 'CPF',
		filter: 'cpf',
		value: '',
		type: 'cpf',
		selected: false,
	},
];

const initialNonPendingReleasesFiltersParams: FilterParams[] = [
	{
		name: 'Status',
		filter: 'status',
		value: '',
		type: 'select',
		selectOptions: PaymentStatusOptionsFilters,
		selected: false,
	},
	{
		name: 'ID',
		filter: 'order_id',
		value: '',
		type: 'text',
		selected: false,
	},
	{
		name: 'Mês',
		filter: 'reference_month',
		value: '',
		type: 'select',
		selectOptions: MonthOptionsFilters,
		selected: false,
	},
	{
		name: 'Intervalo de datas',
		filter: 'date',
		value: '',
		type: 'date_interval',
		selected: false,
	},
	{
		name: 'Nome',
		filter: 'name',
		value: '',
		type: 'text',
		selected: false,
	},
	{
		name: 'Email',
		filter: 'email',
		value: '',
		type: 'email',
		selected: false,
	},
	{
		name: 'CPF',
		filter: 'cpf',
		value: '',
		type: 'cpf',
		selected: false,
	},
];

const usePaymentsListStore = create<Store>()((set) => ({
	pendingReleasesFiltersParams: initialPendingReleasesFiltersParams,
	setPendingReleasesFiltersParams: (f) =>
		set({ pendingReleasesFiltersParams: f }),
	nonPendingReleasesFiltersParams: initialNonPendingReleasesFiltersParams,
	setNonPendingReleasesFiltersParams: (f) =>
		set({ nonPendingReleasesFiltersParams: f }),
	scheduledReleasesFiltersParams: initialScheduledReleasesFiltersParams,
	setScheduledReleasesFiltersParams: (f) =>
		set({ scheduledReleasesFiltersParams: f }),
	resetFilters: () =>
		set({
			pendingReleasesFiltersParams: initialPendingReleasesFiltersParams,
			nonPendingReleasesFiltersParams: initialNonPendingReleasesFiltersParams,
			scheduledReleasesFiltersParams: initialScheduledReleasesFiltersParams,
		}),
}));

export { usePaymentsListStore };
