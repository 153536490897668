import ModelImage from '../../assets/ModelSigInMaleComputer.png';
import ResponsiveModelImage from '../../assets/ModelSignInFemale.png';
import BountyLogo from '../../assets/BountyRed.svg';
import * as S from './styles';
import { Button } from '../../componentsV2/ui/Button';
import { CorporateExpensesTheme } from '../../styles/CorporateExpensesTheme';

type SuccessEmailSentProps = {
	email: string;
};

export default function SuccessEmailSent({ email }: SuccessEmailSentProps) {
	return (
		<S.Container>
			<CorporateExpensesTheme />
			<S.LeftContainer>
				<img src={ModelImage} className='modelImage' alt='Men with computer' />
				<img
					src={ResponsiveModelImage}
					className='ResponsiveModelImage'
					alt='Girl'
				/>
			</S.LeftContainer>
			<S.RightContainer>
				<S.MainContainer>
					<img src={BountyLogo} alt='Logo do Bounty' />

					<h2>Recuperação de Senha</h2>

					<p>
						Enviamos um e-mail com as instruções para <span>{email}</span>{' '}
						Verifique sua caixa de entrada.
					</p>
					<div className='backLogin'>
						<Button
							as='link'
							to={'/session'}
							roundness='lg'
							width='adjusted'
							className='loginButton'
							intent='primary'
						>
							Voltar para o login
						</Button>
					</div>
				</S.MainContainer>
			</S.RightContainer>
		</S.Container>
	);
}
