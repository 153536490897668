import {
	getCoreRowModel,
	useReactTable,
	flexRender,
} from '@tanstack/react-table';
import { createColumnHelper } from '@tanstack/react-table';
import { Transaction } from '../../../../../@types/CorporateExpenses/Transaction';
import {
	checkDeclineAndStrikeText,
	parseTransactionType,
} from '../../../Extract/utils/transactionUtils';
import { convertCentsToFormattedReais } from '../../../../../utils/CurrencyConvert';
import { Card } from '../../../../../@types/CorporateExpenses/Card';
import { useAuth } from '../../../../../hooks/useAuth';
import { useQuery } from 'react-query';
import {
	CORPWAY_CARDS_KEY,
	getCardLastTransactions,
} from '../../../../../services/queries/Corpway/Cards';
import { showErrorMessage } from '../../../../../utils/ErrorHandler';
import { SectionContainer } from '../../../../../componentsV2/ui/SectionContainer';
import { TitleTypography } from '../../../../../componentsV2/ui/Typography';
import * as S from './styles';
import styled from 'styled-components';
import { AsynchronousContent } from '../../../../../componentsV2/AsynchronousContent';
import { Loader } from '../../../../../componentsV2/ui/Loader';

const columnHelper = createColumnHelper<Transaction>();
const columns = [
	columnHelper.accessor('added_time', {
		cell: (info) => {
			const date = new Date(info.getValue());
			const month = String(date.getMonth() + 1).padStart(2, '0');
			const year = String(date.getFullYear()).slice(-2);
			return `${month}/${year}`;
		},
		header: 'Data',
	}),
	columnHelper.accessor((row) => row, {
		cell: (info) => {
			const { txn_type, msg_type, currency, merchant_country } =
				info.getValue();
			return parseTransactionType(
				txn_type,
				msg_type,
				currency,
				merchant_country
			);
		},
		header: 'Título',
	}),
	columnHelper.accessor((row) => row, {
		cell: (info) => {
			const { response_code, billing_amount } = info.getValue();
			const parsedValue = convertCentsToFormattedReais(billing_amount);
			return checkDeclineAndStrikeText(response_code, parsedValue);
		},
		header: 'Valor',
	}),
];

export function CardTransactions({ card }: { card: Card }) {
	const { currentCompany, user } = useAuth();

	const getCardLastTransactionsQuery = useQuery(
		[CORPWAY_CARDS_KEY, user.id, card.id],
		() => getCardLastTransactions(card.id, currentCompany!.id),
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível buscar os detalhes do cartão.'
				);
			},
		}
	);

	const table = useReactTable({
		data: getCardLastTransactionsQuery.data?.last_transactions ?? [],
		columns,
		getCoreRowModel: getCoreRowModel(),
	});

	const hasItems = table.getRowModel().rows.length > 0;

	return (
		<Container>
			<AsynchronousContent
				loadingFallback={<Loader style={{ height: '30rem' }} />}
				status={getCardLastTransactionsQuery.status}
			>
				<TitleTypography
					size='2rem'
					style={{
						fontWeight: 600,
						paddingLeft: '2rem',
					}}
				>
					Últimas transações
				</TitleTypography>
				<SectionContainerStyle>
					<table>
						<thead>
							{table.getHeaderGroups().map((headerGroup) => (
								<tr key={headerGroup.id}>
									{headerGroup.headers.map((header) => (
										<th key={header.id}>
											{header.isPlaceholder
												? null
												: flexRender(
														header.column.columnDef.header,
														header.getContext()
												  )}
										</th>
									))}
								</tr>
							))}
						</thead>
						<tbody>
							{hasItems ? (
								table.getRowModel().rows.map((row) => (
									<tr key={row.id}>
										{row.getVisibleCells().map((cell) => (
											<td key={cell.id}>
												{flexRender(
													cell.column.columnDef.cell,
													cell.getContext()
												)}
											</td>
										))}
									</tr>
								))
							) : (
								<>
									<tr>
										<td
											colSpan={table.getAllColumns().length}
											style={{
												textAlign: 'center',
												padding: '7rem',
												paddingBottom: '9rem',
											}}
										>
											<p
												style={{
													margin: 0,
													fontFamily: 'Poppins, sans-serif',
													fontSize: '1.4rem',
													color: '#575757;',
												}}
											>
												Não há itens a serem exibidos.
											</p>
										</td>
									</tr>
									<div className='table-footer'></div>
								</>
							)}
						</tbody>
					</table>
				</SectionContainerStyle>

				{getCardLastTransactionsQuery.data && (
					<S.BalanceText
						size='1.8rem'
						weight='600'
						style={{
							padding: '0',
							marginTop: '-1.2rem',
							marginLeft: '2rem',
							paddingBottom: '2rem',
						}}
					>
						Saldo do cartão:{' '}
						{convertCentsToFormattedReais(
							getCardLastTransactionsQuery.data.balance
						)}
					</S.BalanceText>
				)}
			</AsynchronousContent>
		</Container>
	);
}

const SectionContainerStyle = styled(SectionContainer)`
	height: 28rem;
	table {
		width: 100%;
		background: #fff !important;
		height: 100%;

		thead {
			background: #fff !important;
			box-shadow: none !important;
			border: none !important;

			tr {
				width: 100%;
				table-layout: fixed;
			}

			th {
				color: #575757;
				font-weight: 600;
				font-size: 1.2rem;
				line-height: 18px;
				text-align: left;
			}
			th:nth-child(1) {
				width: 30%;
			}
			th:nth-child(2) {
				width: 50%;
			}
			th:nth-child(3) {
				text-align: right;
			}
		}

		tbody {
			display: block;
			max-height: calc(28rem - 5rem);
			width: 100%;

			tr {
				display: table;
				width: 100%;
				table-layout: fixed;
				font-size: 1.2rem;
				border-bottom: 1px solid #ccc;
			}

			td {
				padding-top: 1.5rem;
				justify-content: space-between;
				vertical-align: bottom;
			}
			td:nth-child(1) {
				width: 30%;
			}
			td:nth-child(2) {
				width: 50%;
			}
			td:nth-child(3) {
				text-align: right;
			}
		}

		thead,
		tbody tr {
			display: table;
			width: 100%;
			table-layout: fixed;
		}
	}
	.table-footer {
		border-bottom: 1px solid #ccc;
		width: 100%;
		margin-top: -1px;
	}
`;

const Container = styled.div`
	@media (max-width: 760px) {
		max-height: 100%;
	}
`;
