import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { DialogModalProvider } from './contexts/DialogModalContext';
import { AuthProvider } from './contexts/AuthContext';
import Routes from './routes';
import { GlobalTheme } from './styles/GlobalTheme';
import Footer from './components/Footer';
import { ToastContainer } from 'react-toastify';
import ReactHintFactory from 'react-hint';
import 'react-hint/css/index.css';
import { NotificationProvider } from './contexts/NotificationsContext';
import { QueryClient, QueryClientProvider } from 'react-query';
import { GlobalDebug } from './utils/removeConsoles';
const ReactHint = ReactHintFactory(React);

function App() {
	const queryClient = new QueryClient();

	useEffect(() => {
		process.env.REACT_APP_NODE_ENV === 'production' && GlobalDebug(false);
	}, []);

	// used to get card details through swap function call
	useEffect(() => {
		const script = document.createElement('script');

		script.src =
			'https://api.staging.swapcards.com.br/beta_v3/secure-card-viewer/assets/swap.js';
		script.async = true;

		document.body.appendChild(script);

		return () => {
			document.body.removeChild(script);
		};
	}, []);

	const getUserConfirmation = (
		dialogKey: string,
		callback: (ok: boolean) => void
	) => {
		// use "message" as Symbol-based key
		const dialogTrigger = (window as any)[Symbol.for(dialogKey)];

		if (dialogTrigger) {
			// pass the callback to delegate
			// to the invoked dialog
			return dialogTrigger(callback);
		}

		// Fallback to allowing navigation
		callback(true);
	};

	return (
		<QueryClientProvider client={queryClient}>
			<BrowserRouter getUserConfirmation={getUserConfirmation}>
				<ReactHint autoPosition={true} events />
				<DialogModalProvider>
					<AuthProvider>
						<NotificationProvider>
							<Routes />
							<Footer />
							<GlobalTheme />
							<ToastContainer autoClose={8000} />
						</NotificationProvider>
					</AuthProvider>
				</DialogModalProvider>
			</BrowserRouter>
		</QueryClientProvider>
	);
}

export default App;
