import { useState } from 'react';
import { FaCheck, FaCheckCircle, FaExclamationCircle } from 'react-icons/fa';
import { DeliveredExtraInfo } from '../../../../../../@types';
import Modal from '../../../../../../components/Modal';
import * as S from './styles';

export interface Props {
	disabled: boolean;
	collaboratorName: string;
	onSubmit: (type: DeliveredExtraInfo) => void;
}
export function FinishDeliveryModal({
	collaboratorName,
	onSubmit,
	disabled,
}: Props) {
	const [isOpen, setIsOpen] = useState(false);

	const toggleModalOpen = () => {
		setIsOpen((state) => !state);
	};

	function handleSubmition(type: DeliveredExtraInfo) {
		onSubmit(type);
		toggleModalOpen();
	}

	return (
		<>
			<S.OpenModalBtn
				{...(!disabled ? { 'data-rh': 'Finalizar entrega' } : {})}
				disabled={disabled}
				onClick={toggleModalOpen}
				data-testid='open-FinishDeliveryModal'
			>
				<FaCheck />
			</S.OpenModalBtn>

			<Modal isOpen={isOpen} enableClose onRequestClose={toggleModalOpen}>
				<S.Container>
					<S.Title>{`Finalizar entrega do item - ${collaboratorName}`}</S.Title>
					<S.Content>
						<span>Deseja finalizar a entrega do item com sucesso ou erro?</span>

						<S.OptionsContainer>
							<S.Option
								onClick={() => handleSubmition('with_error')}
								error={true}
							>
								<FaExclamationCircle /> Finalizar com erro
							</S.Option>
							<S.Option onClick={() => handleSubmition('ok')} error={false}>
								<FaCheckCircle /> Finalizar com sucesso
							</S.Option>
						</S.OptionsContainer>
					</S.Content>
				</S.Container>
			</Modal>
		</>
	);
}
