import ResponsiveModelImage from '../../../assets/ModelSignInFemale.png';
import bountyLogo from '../../../assets/BountyRed.svg';
import ModelImage from '../../../assets/ModelSigInMaleComputer.png';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import * as S from './styles';
import { useAuth } from '../../../hooks/useAuth';
import { useMutation } from 'react-query';
import {
	firstAccess,
	FirstAccessParams,
} from '../../../services/queries/Session';
import { showErrorMessage } from '../../../utils/ErrorHandler';
import { CorporateExpensesTheme } from '../../../styles/CorporateExpensesTheme';
import { Button } from '../../../componentsV2/ui/Button';
import { PasswordInput } from '../../../componentsV2/ui/Form/PasswordInput';

type FormData = {
	password: string;
	password_confirmation: string;
};

export function FirstAccess() {
	const { register, handleSubmit, formState } = useForm<FormData>();
	const { isSubmitting } = formState;
	const { signIn, user, signOut } = useAuth();
	const { push } = useHistory();

	const confirmPassword = useMutation(
		({ password, password_confirmation }: FirstAccessParams) => {
			return firstAccess({ password, password_confirmation });
		}
	);

	async function handleChangePassword({
		password,
		password_confirmation,
	}: FormData) {
		const email = user.email;
		if (password !== password_confirmation) {
			toast.error('As senhas precisam ser iguais');
			return;
		}

		try {
			await confirmPassword.mutateAsync({ password, password_confirmation });

			const loginResult = await signIn({ email, password });
			if (loginResult.concludedLogin) {
				if (loginResult.termsNotifications!.length > 0) {
					push('/home/terms');
				} else {
					loginResult.super_admin
						? push('/super-admin')
						: push('/select-product');
				}
			} else {
				toast.error(loginResult.message);
			}
			toast.info('Senha atualizada com sucesso');
			return true;
		} catch (err) {
			showErrorMessage(
				err as Error,
				'Ocorreu um problema ao tentar atualizar a senha. '
			);
			return false;
		}
	}

	return (
		<S.Container>
			<CorporateExpensesTheme />
			<S.LeftContainer>
				<img src={ModelImage} className='modelImage' alt='Men with Computer' />
				<img
					src={ResponsiveModelImage}
					className='ResponsiveModelImage'
					alt='Girl'
				/>
			</S.LeftContainer>

			<S.RightContainer>
				<S.MainContainer>
					<img src={bountyLogo} alt='Logo da Bounty' />
					<div>
						<h2>Redefinir a senha</h2>
						<p>Preencha os dados para criar uma nova senha de acesso.</p>
					</div>
					<S.Form
						onSubmit={handleSubmit(handleChangePassword)}
						autoComplete='off'
					>
						<div className='passwordWrapper'>
							<PasswordInput
								id='password'
								label='Nova senha'
								data-testid='passwordInput_test_id'
								placeholder='********'
								name='password'
								register={register}
								showLeftIcon={true}
								required
							/>

							<PasswordInput
								label='Confirmar nova senha'
								data-testid='confirmationInput_test_id'
								placeholder='********'
								name='password_confirmation'
								register={register}
								showLeftIcon={true}
								required
							/>
						</div>
						<S.BottomOptionsContainer>
							<Button
								roundness='lg'
								intent='terciary'
								$outline
								type='button'
								width='full'
								onClick={signOut}
							>
								Voltar
							</Button>
							<Button
								roundness='lg'
								width='full'
								loading={isSubmitting}
								type='submit'
								className='loginButton'
								intent='primary'
							>
								Confirmar
							</Button>
						</S.BottomOptionsContainer>
					</S.Form>
				</S.MainContainer>
			</S.RightContainer>
		</S.Container>
	);
}
