import React, { useEffect, useRef, useState } from 'react';
import { FaBell, FaEnvelopeOpen } from 'react-icons/fa';
import { RiCloseLine } from 'react-icons/ri';
import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { Popover } from 'react-tiny-popover';
import { toast } from 'react-toastify';
import { EmptyContent } from '../../../../../componentsV2/EmptyContent';
import { Button } from '../../../../../componentsV2/ui/Button';
import { Loader } from '../../../../../componentsV2/ui/Loader';
import {
	TitleTypography,
	Typography,
} from '../../../../../componentsV2/ui/Typography';
import { Notification } from '../../../../../contexts/NotificationsContext';
import { useAuth } from '../../../../../hooks/useAuth';
import { useNotifications } from '../../../../../hooks/useNotifications';
import {
	getNotifications,
	readAllNotifications,
} from '../../../../../services/queries/Notifications';
import * as S from './styles';
import { categorizeNotificationsByHumanizedDate } from './utils/categorizeNotificationsByDate';

export function NotificationsList() {
	const [isOpen, setIsOpen] = useState(false);
	const { toggleNotificationsStatus, unreadNotifications } = useNotifications();
	const [notificationsToRead, setNotificationsToRead] = useState<string[]>([]);

	function handleClose() {
		setIsOpen(false);
	}

	function handleOpenNotifications() {
		setIsOpen(true);
		setNotificationsToRead([]);
	}

	useEffect(() => {
		if (!isOpen && notificationsToRead.length > 0) {
			toggleNotificationsStatus(notificationsToRead, true);
		}
	}, [isOpen, notificationsToRead]); // eslint-disable-line

	return (
		<Popover
			containerStyle={window.innerWidth < 768 ? { width: '100%' } : {}}
			isOpen={isOpen}
			positions={['bottom']} // preferred positions by priority
			onClickOutside={handleClose}
			content={
				<NotificationsListContent
					onClose={handleClose}
					notificationsToRead={notificationsToRead}
					setNotificationsToRead={setNotificationsToRead}
				/>
			}
		>
			<S.NotificationBellButton onClick={handleOpenNotifications}>
				<FaBell />{' '}
				{!!unreadNotifications && (
					<span
						data-rh={
							unreadNotifications > 99 ? `${unreadNotifications}` : undefined
						}
					>
						{unreadNotifications > 99 ? '99+' : unreadNotifications}
					</span>
				)}
			</S.NotificationBellButton>
		</Popover>
	);
}

const NotificationDateLabel = ({ label }: { label: string }) => (
	<Typography
		size='1.4rem'
		color='var(--dark-gray)'
		weight='600'
		style={{ marginTop: '1rem' }}
	>
		{label}
	</Typography>
);

interface NotificationsListContentProps {
	onClose: () => void;
	notificationsToRead: string[];
	setNotificationsToRead: React.Dispatch<React.SetStateAction<string[]>>;
}
function NotificationsListContent({
	onClose,
	setNotificationsToRead,
	notificationsToRead,
}: NotificationsListContentProps) {
	const { currentProduct } = useAuth();
	const queryClient = useQueryClient();
	const notificationListRef = useRef<HTMLUListElement | null>(null);

	const fetchNotificationsQuery = useInfiniteQuery(
		['fetchNotifications', currentProduct],
		({ pageParam = 1 }) => getNotifications('corpway', pageParam),
		{
			getNextPageParam: (lastPage, pages) => {
				const currentPage = pages.length;
				const pagesLimit =
					lastPage.totalNotifications === 0
						? 1
						: Math.ceil(lastPage.totalNotifications / 10);
				// there are still pages? return 'currentPage + 1', there aren't any left? return 'undefined'
				return currentPage < pagesLimit ? currentPage + 1 : undefined;
			},
			onError: () => {
				toast.error('Ocorreu um problema ao buscar as notificações.');
			},
			enabled: currentProduct === 'corpway',
		}
	);

	const notifications = fetchNotificationsQuery.data?.pages.flatMap(
		(page) => page.notificationsOperator
	);

	const categorizedNotificationsByDate = categorizeNotificationsByHumanizedDate(
		notifications || []
	);

	const handleNotificationsListScroll = () => {
		const listElement = notificationListRef.current!;

		const scrolledPercentage =
			listElement.scrollTop /
			(listElement.scrollHeight - listElement.offsetHeight);
		const scrolledThreshold = 0.85;

		const isScrolledToBottom = scrolledPercentage >= scrolledThreshold;

		const shouldFetchNextPage =
			fetchNotificationsQuery.hasNextPage &&
			!fetchNotificationsQuery.isFetchingNextPage;

		if (isScrolledToBottom && shouldFetchNextPage) {
			fetchNotificationsQuery.fetchNextPage();
		}
	};

	// notifications list infinite scroll
	useEffect(() => {
		const notificationListComponent = notificationListRef.current;
		if (!notificationListComponent) return;

		notificationListComponent.addEventListener(
			'scroll',
			handleNotificationsListScroll
		);
		return () =>
			notificationListComponent?.removeEventListener(
				'scroll',
				handleNotificationsListScroll
			);
	}, [fetchNotificationsQuery, notificationListRef]); // eslint-disable-line

	// notifications viewport check to set notificatin to be read
	useEffect(() => {
		const observer = new IntersectionObserver(
			(entries) => {
				entries.forEach((entry) => {
					if (entry.isIntersecting) {
						const id = entry.target.id;
						// setNotificationsToRead with the id of the notification that is in the viewport
						if (!notificationsToRead.includes(id)) {
							setNotificationsToRead((prev) => [...prev, id]);
						}
					}
				});
			},
			{ threshold: 0.5 }
		);

		// setup observers for each notification to check if it's in the viewport
		notifications?.forEach((notification) => {
			if (notification.was_read) return;

			const element = document.getElementById(notification.id);
			if (element) {
				observer.observe(element);
			}
		});

		return () => observer.disconnect();
	}, [notifications]); // eslint-disable-line react-hooks/exhaustive-deps

	const readAllNotificationsQuery = useMutation(readAllNotifications, {
		onSuccess: () => {
			queryClient.invalidateQueries('fetchNotifications');
		},
	});

	function handleReadAllNotifications() {
		if (notifications?.some((n) => !n.was_read)) {
			readAllNotificationsQuery.mutate();
			setNotificationsToRead([]);
		}
	}

	return (
		<S.Container>
			<S.Header>
				<TitleTypography size='1.6rem' primaryText='Suas'>
					Notificações
				</TitleTypography>
				<S.CloseModalBtn onClick={onClose}>
					<RiCloseLine size={22} />
				</S.CloseModalBtn>
			</S.Header>

			<div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
				<FaEnvelopeOpen color='var(--primary-blue)' size={22} />
				<Button
					style={{ color: 'var(--dark-gray)', borderColor: 'var(--dark-gray)' }}
					intent='link'
					onClick={handleReadAllNotifications}
				>
					Marcar todas como lidas
				</Button>
			</div>

			<S.List ref={notificationListRef}>
				{categorizedNotificationsByDate.today.map((notification, i) => (
					<React.Fragment key={notification.id}>
						{i === 0 && <NotificationDateLabel label='Hoje' />}
						<NotificationCard notification={notification} />
					</React.Fragment>
				))}
				{categorizedNotificationsByDate.yesterday.map((notification, i) => (
					<React.Fragment key={notification.id}>
						{i === 0 && <NotificationDateLabel label='Ontem' />}
						<NotificationCard notification={notification} />
					</React.Fragment>
				))}
				{categorizedNotificationsByDate.week.map((notification, i) => (
					<React.Fragment key={notification.id}>
						{i === 0 && <NotificationDateLabel label='Esta semana' />}
						<NotificationCard notification={notification} />
					</React.Fragment>
				))}
				{categorizedNotificationsByDate.month.map((notification, i) => (
					<React.Fragment key={notification.id}>
						{i === 0 && <NotificationDateLabel label='Este mês' />}
						<NotificationCard notification={notification} />
					</React.Fragment>
				))}
				{categorizedNotificationsByDate.older.map((notification, i) => (
					<React.Fragment key={notification.id}>
						{i === 0 && <NotificationDateLabel label='Antigas' />}
						<NotificationCard notification={notification} />
					</React.Fragment>
				))}

				{(fetchNotificationsQuery.isLoading ||
					fetchNotificationsQuery.isFetchingNextPage) && <Loader size={30} />}
			</S.List>

			{fetchNotificationsQuery.data?.pages[0].totalNotifications === 0 && (
				<EmptyContent text='Nenhuma notificação...' />
			)}
		</S.Container>
	);
}

const NotificationCard = ({ notification }: { notification: Notification }) => {
	const history = useHistory();
	const { companies, changeCurrentCompany } = useAuth();

	function getDetailsLink(notification: Notification) {
		switch (notification.event_type) {
			case 'card_balance_added':
				return '/corporate-expenses/cards';
			case 'card_balance_chargeback':
				return '/corporate-expenses/cards';
			case 'card-status-updated':
				return '/corporate-expenses/cards';
			case 'funds-in':
				return '/corporate-expenses/extract';

			default:
				return '#';
		}
	}

	function handleNotificationClick(notification: Notification) {
		if (companies.find((c) => c.id === notification.company_id)) {
			changeCurrentCompany(notification.company_id);
			history.push(getDetailsLink(notification));
		}
	}

	return (
		<S.NotificationContainer
			onClick={() => handleNotificationClick(notification)}
			id={notification.id}
			clickable={getDetailsLink(notification) !== '#'}
		>
			<svg
				width='24'
				height='24'
				viewBox='0 0 24 24'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M22 12C22 17.5228 17.5228 22 12 22H2.9937C2.11018 22 1.66771 20.9229 2.29245 20.2929L4.2495 18.3195C2.84334 16.597 2 14.397 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM15.2071 8.79289C15.5976 9.18342 15.5976 9.81658 15.2071 10.2071L13.4142 12L15.2071 13.7929C15.5976 14.1834 15.5976 14.8166 15.2071 15.2071C14.8166 15.5976 14.1834 15.5976 13.7929 15.2071L12 13.4142L10.2071 15.2071C9.81658 15.5976 9.18342 15.5976 8.79289 15.2071C8.40237 14.8166 8.40237 14.1834 8.79289 13.7929L10.5858 12L8.79289 10.2071C8.40237 9.81658 8.40237 9.18342 8.79289 8.79289C9.18342 8.40237 9.81658 8.40237 10.2071 8.79289L12 10.5858L13.7929 8.79289C14.1834 8.40237 14.8166 8.40237 15.2071 8.79289Z'
					fill='var(--primary-blue)'
				/>
			</svg>

			<div>
				<Typography size='1.6rem' weight='600' color='var(--dark-gray2)'>
					{notification.title}
				</Typography>
				<Typography
					size='1.4rem'
					color='var(--dark-gray2)'
					style={{ wordBreak: 'break-word' }}
				>
					{notification.message}
				</Typography>
			</div>

			<Typography size='1.2rem' color='#C5C5C5' style={{ marginTop: 'auto' }}>
				{new Date(notification.created_at).toLocaleDateString()}
			</Typography>

			{!notification.was_read && (
				<S.UnreadMarker>
					<div />
				</S.UnreadMarker>
			)}
		</S.NotificationContainer>
	);
};
