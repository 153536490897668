import { useMutation } from 'react-query';
import { useAuth } from '../../../../../hooks/useAuth';
import {
	MovementsReportProps,
	getBalanceMovementsToReport,
} from '../../../../../services/queries/Corpway/Funds';
import {
	parseMovementDetailsToTextOnly,
	parseMovementFinancialImpact,
	parseMovementType,
} from '../../utils/parseBalanceMovements';

export function OFXReportGenerator() {
	const { companyBalance, currentCompany } = useAuth();

	const getBalanceMovementsQuery = useMutation(
		({
			start_date,
			end_date,
			operationTypes,
			operationStatuses,
			fundsInOrigin,
		}: MovementsReportProps) =>
			getBalanceMovementsToReport(
				currentCompany!.id,
				start_date,
				end_date,
				operationTypes,
				operationStatuses,
				fundsInOrigin
			)
	);

	function formatOFXDate(date: Date) {
		const year = date.getFullYear();
		const month = String(date.getMonth() + 1).padStart(2, '0');
		const day = String(date.getDate()).padStart(2, '0');
		const hours = String(date.getHours()).padStart(2, '0');
		const minutes = String(date.getMinutes()).padStart(2, '0');
		const seconds = String(date.getSeconds()).padStart(2, '0');

		return `${year}${month}${day}${hours}${minutes}${seconds}`;
	}

	function formatMoneyAmount(amount: number, credit: boolean) {
		const reaisAmount = amount / 100;

		return credit ? reaisAmount : -reaisAmount;
	}

	async function generateOFXContent(
		start_date: number,
		end_date: number,
		operationTypes: MovementsReportProps['operationTypes'],
		operationStatuses: MovementsReportProps['operationStatuses'],
		fundsInOrigin?: MovementsReportProps['fundsInOrigin']
	) {
		const movements = await getBalanceMovementsQuery.mutateAsync({
			start_date,
			end_date,
			operationTypes,
			operationStatuses,
			fundsInOrigin,
		});

		if (movements.balanceMovements.length === 0) {
			throw new Error('empty_movements');
		}

		const startDateParsed = new Date(start_date);
		const endDateParsed = new Date(end_date);

		const transactionsStartDate = formatOFXDate(startDateParsed);
		const transactionsEndDate = formatOFXDate(endDateParsed);

		// OFX header
		let ofxContent = `OFXHEADER:100
DATA:OFXSGML
VERSION:102
SECURITY:NONE
ENCODING:UTF-8
CHARSET:1252
COMPRESSION:NONE
OLDFILEUID:NONE
NEWFILEUID:NONE
`;

		// OFX transactions
		const transactionsBody = movements.balanceMovements
			.map(
				(movement) => `
				<STMTTRN>
					<TRNTYPE>${parseMovementFinancialImpact(movement.type, movement.action)}
					<DTPOSTED>${formatOFXDate(new Date(movement.created_at))}
					<TRNAMT>${formatMoneyAmount(
						movement.amount,
						parseMovementFinancialImpact(movement.type, movement.action) ===
							'CREDIT'
					)}
					<FITID>${movement.id}
					<MEMO>${parseMovementType(
						movement.type,
						movement.action
					)} - ${parseMovementDetailsToTextOnly(movement)}
				</STMTTRN>
				`
			)
			.join(' ');

		// OFX body
		ofxContent += `<OFX>
<CREDITCARDMSGSRSV1>
	<CCSTMTTRNRS>
		<TRNUID>0
		<STATUS>
			<CODE>0
			<SEVERITY>INFO
		</STATUS>
		<CCSTMTRS>
			<CURDEF>BRL                
			<BANKTRANLIST>
				<DTSTART>${transactionsStartDate}
				<DTEND>${transactionsEndDate}   
				${transactionsBody}
			</BANKTRANLIST>                                
		</CCSTMTRS>
	</CCSTMTTRNRS>
</CREDITCARDMSGSRSV1>
<AVAILBAL>
	<BALAMT>${Number(companyBalance.corpway_balance)}
	<DTASOF>${formatOFXDate(new Date())}
</AVAILBAL>
</OFX>`;

		return ofxContent;
	}

	async function downloadFile({
		start_date,
		end_date,
		operationTypes,
		operationStatuses,
		fundsInOrigin,
	}: MovementsReportProps) {
		const content = await generateOFXContent(
			start_date,
			end_date,
			operationTypes,
			operationStatuses,
			fundsInOrigin
		);
		const blob = new Blob([content], { type: 'text/plain' });
		const url = URL.createObjectURL(blob);
		const a = document.createElement('a');
		a.href = url;

		const startDate = new Date(start_date);
		const endDate = new Date(end_date);

		a.download = `Bounty_Control_Gestao_${startDate.toLocaleDateString(
			'pt-BR'
		)}_${endDate.toLocaleDateString('pt-BR')}`;
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
		URL.revokeObjectURL(url);
	}

	return {
		generateOFX: downloadFile,
	};
}
