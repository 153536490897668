import styled from "styled-components";

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items:center;
  padding: 2rem;
`

export const Content = styled.div`
`