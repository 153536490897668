import { useState } from 'react';
import { useForm } from 'react-hook-form';
import BountyLogo from '../../assets/BountyRed.svg';
import Loader from '../../components/Loader';
import ModelImage from '../../assets/ModelSigInMaleComputer.png';
import ResponsiveModelImage from '../../assets/ModelSignInFemale.png';
import * as S from './styles';
import SuccessEmailSent from './SuccessEmailSent';
import { useMutation } from 'react-query';
import { forgotPassword } from '../../services/queries/Session';
import { showErrorMessage } from '../../utils/ErrorHandler';
import { InputField } from '../../componentsV2/ui/Form/InputField';
import { Button } from '../../componentsV2/ui/Button';
import { CorporateExpensesTheme } from '../../styles/CorporateExpensesTheme';

type FormData = {
	email: string;
};
export default function RecoverPassword() {
	const { register, handleSubmit, formState } = useForm<FormData>();
	const [email, setEmail] = useState('');
	const [emailSent, setEmailSent] = useState(false);
	const { isSubmitting } = formState;

	const forgotPasswordQuery = useMutation((email: string) =>
		forgotPassword(email)
	);

	async function sendEmail({ email }: FormData) {
		try {
			await forgotPasswordQuery.mutateAsync(email);
			setEmail(email);
			setEmailSent(true);
		} catch (err) {
			showErrorMessage(
				err as Error,
				'Ocorreu um erro na solicitação de esquecimento de senha.',
				false
			);
		}
	}

	// When email is sent, show success message "page"
	if (emailSent) {
		return <SuccessEmailSent email={email} />;
	}
	return (
		<S.Container>
			<CorporateExpensesTheme />
			<S.LeftContainer>
				<img src={ModelImage} className='modelImage' alt='Men with computer' />
				<img
					src={ResponsiveModelImage}
					className='ResponsiveModelImage'
					alt='Girl'
				/>
			</S.LeftContainer>

			<S.RightContainer>
				<S.MainContainer>
					<img src={BountyLogo} alt='Logo do Bounty' />

					<div>
						<h2>Esqueceu sua senha?</h2>
						<p>Preencha os dados para solicitar recuperação de senha.</p>
					</div>
					<S.Form onSubmit={handleSubmit(sendEmail)}>
						<InputField
							data-testid='emailInput_test_id'
							type='email'
							placeholder='Email'
							name='email'
							register={register}
							required
						/>
						<div className='ButtonWrapper'>
							<Button
								disabled={isSubmitting}
								type='button'
								as='link'
								to={'/session'}
								roundness='lg'
								$outline
								width='full'
								intent='terciary'
							>
								Voltar
							</Button>
							<Button
								disabled={isSubmitting}
								type='submit'
								roundness='lg'
								width='full'
								className='loginButton'
								intent='primary'
							>
								{!isSubmitting ? 'ENVIAR' : <Loader color='#fff' size={20} />}
							</Button>
						</div>
					</S.Form>
				</S.MainContainer>
			</S.RightContainer>
		</S.Container>
	);
}
