import logoImg from '../../assets/Bounty.svg';
import * as S from './styles';

export function ChangePasswordSuccess() {
	return (
		<S.Container>
			<S.LogoImage src={logoImg} alt='Logo do Bounty' />
			<S.BoxContainer>
				<S.Title>Recuperação de Senha - Colaborador</S.Title>
				<S.InfoText>Senha atualizada com sucesso!</S.InfoText>
			</S.BoxContainer>
		</S.Container>
	);
}
