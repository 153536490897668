import { useState } from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { MdNotes } from 'react-icons/md';
import { useQuery, useQueryClient } from 'react-query';
import { Message } from '../../../@types';
import { EmptyContent } from '../../../components/EmptyContent';
import { Filter } from '../../../components/Filter';
import Loader from '../../../components/Loader';
import PageTitle from '../../../components/PageTitle';
import { Pagination } from '../../../components/Pagination';
import * as TableStyle from '../../../components/Table/TableStyles';
import { TextWithHint } from '../../../components/TextWithHint';
import {
	getFilteredMessages,
	getMessages,
	ListMessagesResponse,
} from '../../../services/queries/Messages';
import { cnpjMask } from '../../../utils/masks';
import * as S from './styles';
import { showErrorMessage } from '../../../utils/ErrorHandler';
import { useMessagesListStore } from '../../../stores/useMessagesListStore';
import { useHistoryNonMatchCallback } from '../../../hooks/useHistoryNonMatchCallback';
import { useSortColumnHook } from '../../../hooks/useSortColumnHook';
import { SortColumnButton } from '../../../components/SortColumnButton';

export function Messages() {
	const queryClient = useQueryClient();
	const [messagesCurrentPage, setmessagesCurrentPage] = useState(1);
	const { currentSortColumn, toggleSort } = useSortColumnHook();
	const [filtersParams, setFiltersParams, resetFilters] = useMessagesListStore(
		(state) => [state.filtersParams, state.setFiltersParams, state.resetFilters]
	);
	useHistoryNonMatchCallback('messages', resetFilters);

	const fetchMessagesQuery = useQuery(
		['messagesList', messagesCurrentPage, currentSortColumn],
		() => {
			return getMessages(messagesCurrentPage, currentSortColumn);
		},
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível buscar as mensagens. '
				);
			},
		}
	);

	const filteredMessagesQuery = useQuery<ListMessagesResponse, Error>(
		['filteredMessages', filtersParams, messagesCurrentPage, currentSortColumn],
		() => {
			return getFilteredMessages(
				filtersParams,
				messagesCurrentPage,
				currentSortColumn
			);
		},
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível buscar as mensagens. '
				);
			},
			enabled: !!filtersParams.find((f) => !!f.value) && !!messagesCurrentPage,
		}
	);

	function parseSegmentationTitle(message: Message) {
		if (message.segmentation === 'custom') {
			return 'Customizado';
		} else if (message.segmentation === 'group') {
			return 'Grupo corporativo';
		} else if (message.segmentation === 'company') {
			return message.company_data?.name;
		}
		return '';
	}

	function getTableTotalRecords() {
		if (filtersParams.find((f) => !!f.value)) {
			return filteredMessagesQuery.data?.totalMessages;
		}
		return fetchMessagesQuery.data?.totalMessages;
	}

	function refreshPage() {
		setmessagesCurrentPage(1);
		queryClient.resetQueries('messagesList');
		queryClient.resetQueries('filteredMessages');
	}

	function getMessagesContent() {
		if (filtersParams.find((f) => !!f.value))
			return filteredMessagesQuery.data?.messages;
		return fetchMessagesQuery.data?.messages;
	}

	if (fetchMessagesQuery.isLoading || filteredMessagesQuery.isLoading) {
		return (
			<S.Container>
				<PageTitle title='Mensagens enviadas' />
				<Loader />
			</S.Container>
		);
	}
	if (
		filtersParams.find((f) => !!f.value)
			? !filteredMessagesQuery.data?.messages.length
			: !fetchMessagesQuery.data?.messages.length
	) {
		return (
			<S.Container>
				<TableStyle.TableHeaderContainer>
					<TableStyle.TitleWrapper>
						<PageTitle title='Mensagens enviadas' />
						<Filter
							filterParams={filtersParams}
							onFiltersChanged={(updatedFilters) => {
								setmessagesCurrentPage(1);
								setFiltersParams(updatedFilters);
							}}
						/>
					</TableStyle.TitleWrapper>
					<Pagination
						onPageChange={(page) => setmessagesCurrentPage(page)}
						currentPage={messagesCurrentPage}
						totalCount={1}
					/>
					<TableStyle.ReloadIcon type='reload' onClick={refreshPage} />
				</TableStyle.TableHeaderContainer>
				<EmptyContent text='Nenhuma mensagem enviada para ser exibida' />
				<S.CreateButton to='/home/messages/create'>
					Nova mensagem
				</S.CreateButton>
			</S.Container>
		);
	}
	return (
		<S.Container>
			<TableStyle.TableHeaderContainer>
				<TableStyle.TitleWrapper>
					<PageTitle
						title='Mensagens enviadas'
						totalRecords={getTableTotalRecords() ?? 0}
					/>
					<Filter
						filterParams={filtersParams}
						onFiltersChanged={(updatedFilters) => {
							setmessagesCurrentPage(1);
							setFiltersParams(updatedFilters);
						}}
					/>
				</TableStyle.TitleWrapper>
				{filtersParams.find((f) => !!f.value) ? (
					<Pagination
						onPageChange={(page) => setmessagesCurrentPage(page)}
						currentPage={messagesCurrentPage}
						totalCount={
							filteredMessagesQuery.data
								? filteredMessagesQuery.data.totalMessages!
								: 1
						}
					/>
				) : (
					<Pagination
						onPageChange={(page) => setmessagesCurrentPage(page)}
						currentPage={messagesCurrentPage}
						totalCount={
							fetchMessagesQuery.data
								? fetchMessagesQuery.data.totalMessages!
								: 1
						}
					/>
				)}
				<TableStyle.ReloadIcon type='reload' onClick={refreshPage} />
			</TableStyle.TableHeaderContainer>
			<TableStyle.Table>
				<TableStyle.TableHeader>
					<TableStyle.TableRow>
						<TableStyle.TableHeaderCell>
							<SortColumnButton
								order={
									currentSortColumn?.name === 'title'
										? currentSortColumn.order
										: null
								}
								onToggleSort={() => toggleSort('title')}
							/>
							TÍTULO
						</TableStyle.TableHeaderCell>
						<TableStyle.TableHeaderCell>SEGMENTAÇÃO</TableStyle.TableHeaderCell>
						<TableStyle.TableHeaderCell>
							<SortColumnButton
								order={
									currentSortColumn?.name === 'created_at'
										? currentSortColumn.order
										: null
								}
								onToggleSort={() => toggleSort('created_at')}
							/>
							DATA DE ENVIO
						</TableStyle.TableHeaderCell>
						<TableStyle.TableHeaderCell>DETALHES</TableStyle.TableHeaderCell>
					</TableStyle.TableRow>
				</TableStyle.TableHeader>

				<TableStyle.TableBody>
					{getMessagesContent()?.map((message) => (
						<TableStyle.TableRow key={message.id}>
							<TableStyle.TableData>
								<TextWithHint text={message.title} />
							</TableStyle.TableData>
							<TableStyle.TableData>
								<S.SegmentationContainer>
									<FaCheckCircle color='#1CCA0D' />
									<S.SegmentationTypeContainer>
										<h4>{parseSegmentationTitle(message)}</h4>
										{message.company_data && (
											<span>{cnpjMask(message.company_data.cnpj)}</span>
										)}
									</S.SegmentationTypeContainer>
								</S.SegmentationContainer>
							</TableStyle.TableData>
							<TableStyle.TableData>
								{new Date(message.created_at).toLocaleDateString()}
							</TableStyle.TableData>
							<TableStyle.TableData>
								<TableStyle.OptionLink
									to={`/home/messages/manage/${message.id}`}
									data-rh='Detalhes da mensagem'
								>
									<MdNotes />
								</TableStyle.OptionLink>
							</TableStyle.TableData>
						</TableStyle.TableRow>
					))}
				</TableStyle.TableBody>
			</TableStyle.Table>

			<S.CreateButton to='/home/messages/create'>Nova mensagem</S.CreateButton>
		</S.Container>
	);
}
